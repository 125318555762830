import React, { useState } from 'react';

import { Editor as TinyMce } from '@tinymce/tinymce-react';

import './Editor.less';
import Loader from './Loader';

const Editor = (props) => {
    const [isInitialized, setIsInitialized] = useState(false);

    const configuration = {
        autosave_ask_before_unload: true,
        autosave_interval: '30s',
        autosave_prefix: '{path}{query}-{id}-',
        autosave_restore_when_empty: false,
        autosave_retention: '2m',
        contextmenu: 'link image imagetools table',
        height: 400,
        image_advtab: true,
        image_caption: true,
        image_class_list: [
            { title: 'None', value: '' },
            { title: 'Some class', value: 'class-name' },
        ],
        imagetools_cors_hosts: ['picsum.photos'],
        importcss_append: true,
        max_width: 550,
        menubar: 'file edit view insert format tools table help',
        plugins:
            'autoresize print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
        quickbars_selection_toolbar:
            'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        table_default_attributes: {
            border: '2',
        },
        template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
        template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
        toolbar:
            'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
        toolbar_mode: 'sliding',
        toolbar_sticky: true,
    };

    return (
        <>
            <Loader
                style={
                    isInitialized ? { display: 'none' } : { display: 'block', textAlign: 'center' }
                }
            />
            <TinyMce
                apiKey="5hywymlbcmbzewovy27s6vzts8e9xqr1hybxlukaprn8xjcz"
                init={configuration}
                onInit={() => setIsInitialized(true)}
                {...props}
                style={!isInitialized ? { display: 'none' } : { display: 'block' }}
            />
        </>
    );
};

export default Editor;
