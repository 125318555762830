import React from 'react';

import { Modal } from 'antd';

import AddCurrencySetting from './AddCurrencySetting';

const AddCurrencySettingModal = ({ visible, onCancel, handleGetCurrencySettingFilterList }) => {
    return (
        <Modal
            centered
            visible={visible}
            closable={false}
            onCancel={onCancel}
            width="1000px"
            footer={null}
        >
            <AddCurrencySetting
                onCancel={onCancel}
                handleGetCurrencySettingFilterList={handleGetCurrencySettingFilterList}
            />
        </Modal>
    );
};

export default AddCurrencySettingModal;
