import React from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import _ from 'lodash';

import { Loader } from '../../../../common/components';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useStore } from '../../../../store/StateProvider';

const PlatformFilter = ({ value, onFilterChange, loading, name }) => {
    const [state] = useStore();
    const platforms = getStateProp(state, 'bet.betList.filterOptions.platforms', []);
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange(name, value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            className="filter"
            placeholder={getTranslation('Platform')}
            value={value}
            onChange={handleFilterChange}
            allowClear={true}
            suffixIcon={loading ? <Loader size="small" style={{ padding: 0 }} /> : <DownOutlined />}
            mode="multiple"
        >
            {_.map(platforms, (platform) => (
                <Select.Option value={platform.platformCode} key={platform.platformCode}>
                    {platform.platformDesc.toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
};

export default PlatformFilter;
