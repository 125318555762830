import { ACTIONS } from './userSessionsActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS_1000,
} from '../../../common/constants/api';

export default function userSessionsReducer(
    userSessions = {
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        resetPageNo: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.FILTER_SET:
            return {
                ...userSessions,
                filters: {
                    ...userSessions.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };

        case ACTIONS.FILTERS_DROP_ALL:
            return {
                ...userSessions,
                filters: null,
                resetPageNo: true,
            };

        case ACTIONS.USER_SESSIONS_GET_SUCCESS:
            return {
                ...userSessions,
                list: payload.list,
                paging: {
                    ...userSessions.paging,
                    ...payload.paging,
                },
            };

        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...userSessions,
                paging: {
                    ...userSessions.paging,
                    pageNo:
                        payload > userSessions.paging.pageCount
                            ? userSessions.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS_1000[TABLE_PAGE_SIZE_OPTIONS_1000.length - 1],
            );
            return {
                ...userSessions,
                paging: {
                    ...userSessions.paging,
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.USER_SESSIONS_GET: {
            const pageNo = userSessions.resetPageNo
                ? TABLE_PAGE_NO_DEFAULT
                : userSessions.paging.pageNo;

            return {
                ...userSessions,
                paging: { ...userSessions.paging, pageNo: pageNo },
                resetPageNo: false,
            };
        }
        default:
            return userSessions;
    }
}
