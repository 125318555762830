import { Row } from 'antd';
import React from 'react';

import { SearchOutlined, SyncOutlined } from '@ant-design/icons';

import { ButtonGray, ButtonGreen, ButtonRed } from '../../common/components/buttons/Buttons';
import { getTranslation } from '../../common/helpers/locale';

const ActionButtons = ({ handleCreateChatRoom, handleDropFilter, onRefresh }) => {
    return (
        <Row align="middle" justify="end" className="action-buttons__container">
            <ButtonGreen
                icon={<SearchOutlined />}
                label={getTranslation('Search')}
                onClick={onRefresh}
            />
            <ButtonGray
                icon={<SyncOutlined />}
                label={getTranslation('Drop Filters')}
                onClick={handleDropFilter}
            />
            <ButtonRed label={getTranslation('Create Chat Room')} onClick={handleCreateChatRoom} />
        </Row>
    );
};

export default ActionButtons;
