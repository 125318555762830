export const ACTIONS = {
    BONUS_RECORDS_FILTERS_DROP_ALL: 'BONUS_RECORDS_FILTERS_DROP_ALL',
    BONUS_RECORDS_FILTER_SET: 'BONUS_RECORDS_FILTER_SET',
    BONUS_RECORDS_GET: 'BONUS_RECORDS_GET',
    BONUS_RECORDS_GET_FAILURE: 'BONUS_RECORDS_GET_FAILURE',
    BONUS_RECORDS_GET_SUCCESS: 'BONUS_RECORDS_GET_SUCCESS',

    PAGINATION: {
        BONUS_RECORDS_TABLE_PAGE_CHANGE: 'BONUS_RECORDS_TABLE_PAGE_CHANGE',
        BONUS_RECORDS_TABLE_PAGE_SIZE_CHANGE: 'BONUS_RECORDS_TABLE_PAGE_SIZE_CHANGE',
    },

    REMARK_MODAL_CLOSE: 'BONUS_RECORDS_REMARK_MODAL_CLOSE',
    REMARK_MODAL_OPEN: 'BONUS_RECORDS_REMARK_MODAL_OPEN',
};

export const onRemarkModalOpen = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REMARK_MODAL_OPEN,
    });

export const onRemarkModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.REMARK_MODAL_CLOSE,
    });

export const bonusRecordsGet = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_RECORDS_GET,
    });

export const bonusRecordsGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_RECORDS_GET_SUCCESS,
    });

export const bonusRecordsGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_RECORDS_GET_FAILURE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.BONUS_RECORDS_TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.BONUS_RECORDS_TABLE_PAGE_CHANGE,
    });

export const bonusRecordsFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_RECORDS_FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_RECORDS_FILTERS_DROP_ALL,
    });
