import React from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import _ from 'lodash';

import { Loader } from '../../../../common/components';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useStore } from '../../../../store/StateProvider';

const BetStatusFilter = ({ value, onFilterChange, loading }) => {
    const [state] = useStore();
    const statuses = getStateProp(state, 'bet.betList.filterOptions.status', []);

    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('status', value);
    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            className="filter"
            placeholder={getTranslation('Status')}
            value={value}
            allowClear={true}
            onChange={handleFilterChange}
            suffixIcon={loading ? <Loader size="small" style={{ padding: 0 }} /> : <DownOutlined />}
            mode="multiple"
        >
            {_.map(statuses, (status) => (
                <Select.Option value={status.value} key={status.value}>
                    {getTranslation(status.text).toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
};

export default BetStatusFilter;
