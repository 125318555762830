import React, { useState } from 'react';

import { Col, Form, Input, Modal, Row } from 'antd';

import {
    ButtonGray,
    ButtonGreen,
    ButtonRed,
} from '../../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useUpdateRebateTransactionStatusOperationAsyncEndpoint } from '../../../../../services/rebateService';

const { TextArea } = Input;

const VerifyModal = ({ visible, onCancel }) => {
    const [formValues, setFormValues] = useState({});

    const formValuesChange = (name, value) => {
        setFormValues({ ...formValues, [name]: value });
    };

    const onApprove = () => {
        formValuesChange('status', 'submit');
        handleSetRebateTransactionStatus();
    };

    const onReject = () => {
        formValuesChange('status', 'reject');
        handleSetRebateTransactionStatus();
    };

    const [
        updating,
        handleSetRebateTransactionStatus,
    ] = useUpdateRebateTransactionStatusOperationAsyncEndpoint(formValues);
    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            title={getTranslation('Rebate Verification')}
            centered
            closable={false}
            className="rebate__verification-modal"
            onCancel={onCancel}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        label={getTranslation('Approve')}
                        onClick={onApprove}
                        loading={updating}
                    />
                    <ButtonRed
                        label={getTranslation('Reject')}
                        onClick={onReject}
                        loading={updating}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
        >
            <Form name="verify-rebate">
                <Row>
                    <Col span={6}>
                        <label>
                            {getTranslation('Remarks')} <span className="form__required">*</span> :
                        </label>
                    </Col>
                    <Col span={18}>
                        <Form.Item
                            name="remarks"
                            rules={[
                                {
                                    message: getTranslation('Please input reason !'),
                                    required: true,
                                },
                            ]}
                        >
                            <TextArea
                                onChange={(e) => formValuesChange('remarks', e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default VerifyModal;
