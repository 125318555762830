import React from 'react';

import { Form } from 'antd';

import {
    closeMessageDeleteModal,
    closeMessageVerifyModal,
    messageFilterDrop,
} from './personalMessageActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { useGetPersonalMessageAsyncEndpoint } from '../../../../services/messageService';
import { useStore } from '../../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import DeleteMessageModal from './modal/DeleteMessageModal';
import PersonalMessageFilters from './PersonalMessageFilters';
import PersonalMessageResultTable from './PersonalMessageResultTable';
import VerifyMessageModal from './modal/VerifyMessageModal';

const PersonalMessage = () => {
    const [state, dispatch] = useStore();
    const personalMessage = getStateProp(
        state,
        'announcement.announcementVerification.personalMessage',
    );
    const deleteModalVisible = getStateProp(personalMessage, 'deleteModalVisible');
    const verifyModalVisible = getStateProp(personalMessage, 'verifyMessageModalVisible');

    // ============================================
    // METHODS
    // ============================================

    const onCloseDeleteModal = () => closeMessageDeleteModal(dispatch);

    const fetchAll = () => {
        messageFilterDrop(dispatch);
        handleGetPersonalMessage();
    };

    const closeVerifyModal = () => closeMessageVerifyModal(dispatch);

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetPersonalMessage] = useGetPersonalMessageAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <>
            <Form onFinish={handleGetPersonalMessage}>
                <PersonalMessageFilters />
                <ActionButtons fetchAll={fetchAll} />
            </Form>

            <PersonalMessageResultTable loading={loading} />
            <DeleteMessageModal visible={deleteModalVisible} onCancel={onCloseDeleteModal} />
            <VerifyMessageModal visible={verifyModalVisible} onCancel={closeVerifyModal} />
        </>
    );
};

export default PersonalMessage;
