export const ACTIONS = {
    COLUMNS_SELECT: 'AFFILIATE_COLUMNS_SELECT',
    CONFIRM_MODAL_CLOSE: 'CONFIRM_MODAL_CLOSE',
    CONFIRM_MODAL_OPEN: 'CONFIRM_MODAL_OPEN',
    CONFIRM_REJECT_MODAL_CLOSE: 'CONFIRM_REJECT_MODAL_CLOSE',
    CONFIRM_REJECT_MODAL_OPEN: 'CONFIRM_REJECT_MODAL_OPEN',
    FILTERS_DROP_ALL: 'AFFILIATE_VERIFICATION_FILTERS_DROP_ALL',
    FILTER_SET: 'AFFILIATE_VERIFICATION_FILTER_SET',
    MYAFFILIATE_OPERATION: 'MYAFFILIATE_OPERATION',
    MYAFFILIATE_OPERATION_FAILURE: 'MYAFFILIATE_OPERATION_FAILURE',
    MYAFFILIATE_OPERATION_SUCCESS: 'MYAFFILIATE_OPERATION_SUCCESS',
    MYAFFILIATE_PAYOUTS_GET: 'MYAFFILIATE_PAYOUTS_GET',
    MYAFFILIATE_PAYOUTS_GET_FAILURE: 'MYAFFILIATE_PAYOUTS_GET_FAILURE',
    MYAFFILIATE_PAYOUTS_GET_SUCCESS: 'MYAFFILIATE_PAYOUTS_GET_SUCCESS',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'AFFILIATE_VERIFICATION_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'AFFILIATE_VERIFICATION_TABLE_PAGE_SIZE_CHANGE',
    },
    VERIFY_MODAL_CLOSE: 'VERIFY_MODAL_CLOSE',
    VERIFY_MODAL_OPEN: 'VERIFY_MODAL_OPEN',
};

export const openVerifyModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.VERIFY_MODAL_OPEN,
    });

export const closeVerifyModal = (dispatch) =>
    dispatch({
        type: ACTIONS.VERIFY_MODAL_CLOSE,
    });

export const openConfirmModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_MODAL_OPEN,
    });

export const closeConfirmModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_MODAL_CLOSE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const affiliateVerificationFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const openConfirmRejectModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_REJECT_MODAL_OPEN,
    });

export const closeConfirmRejectModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_REJECT_MODAL_CLOSE,
    });

export const selectColumns = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.COLUMNS_SELECT,
    });

export const myAffiliatePayoutsGet = (dispatch) =>
    dispatch({
        type: ACTIONS.MYAFFILIATE_PAYOUTS_GET,
    });

export const myAffiliatePayoutsGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MYAFFILIATE_PAYOUTS_GET_SUCCESS,
    });

export const myAffiliatePayoutsGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MYAFFILIATE_PAYOUTS_GET_FAILURE,
    });

export const myAffiliateOperation = (dispatch) =>
    dispatch({
        type: ACTIONS.MYAFFILIATE_OPERATION,
    });

export const myAffiliateOperationSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.MYAFFILIATE_OPERATION_SUCCESS,
    });

export const myAffiliateOperationFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MYAFFILIATE_OPERATION_FAILURE,
    });
