import React from 'react';

import { Card, Form } from 'antd';
import _ from 'lodash';

import { API } from '../../../common/constants/api';
import { EXPORT_UNMASKED_PHONE } from '../constants';
import { PLAYERS_TABLE_COLUMNS, PLAYER_MODAL_TYPES } from './constants';
import { closePlayersModal, dropFilters, openPlayersModal } from './PlayersListActions';
import { getColumns } from './table/PlayersTableMeta';
import { getCurrentUser } from '../../../common/helpers/api';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useExportAsyncEndpoint } from '../../../services/hooks/useExport';
import {
    // useGetDangerLevelFilterListAsyncEndpoint,
    useGetPlayersAsyncEndpoint,
} from '../../../services/playerService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import PlayerResultsTable from './table/PlayerResultsTable';
import PlayersFilters from './PlayersFilters';
import PlayersModal from './PlayersModal';

const { PLAYERS } = API;

const PlayersList = () => {
    const [state, dispatch] = useStore();
    const filters = getStateProp(state, 'players.playersList.filters');
    const { totalRecordCount } = getStateProp(state, 'players.playersList.paging', {});
    const selectedColumns = getStateProp(
        state,
        'players.playersList.selectedColumns',
        PLAYERS_TABLE_COLUMNS,
    );
    const playersModal = getStateProp(state, 'players.playersList.playersModal', {});
    const isMasked = window.location.href.lastIndexOf('/isMasked') === -1;

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetPlayers();
    };

    const onCreateNewPlayer = () =>
        openPlayersModal(dispatch, { modalType: PLAYER_MODAL_TYPES.CREATE_PLAYER });

    const onColumnSelect = () =>
        openPlayersModal(dispatch, { modalType: PLAYER_MODAL_TYPES.COLUMN_SELECTION });

    const onUpdatePlayerGameAccess = () =>
        openPlayersModal(dispatch, { modalType: PLAYER_MODAL_TYPES.UPDATE_GAME_ACCESS });

    const handleClosePlayersModal = () => {
        closePlayersModal(dispatch);
        if (playersModal.modalType.COMPONENT === PLAYER_MODAL_TYPES.STATUS.COMPONENT) {
            handleGetPlayers();
        }
    };

    // ============================================
    // HOOKS
    // ============================================

    const [playersLoading, handleGetPlayers] = useGetPlayersAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        dataMeta: getColumns(dispatch, selectedColumns),
        filters: {
            isMasked: !(
                _.includes(EXPORT_UNMASKED_PHONE, getCurrentUser().claims.userName) && isMasked
            ),
            ...filters,
        },
        method: 'POST',
        subject: getTranslation('Player List'),
        totalRecordCount,
        url: `${PLAYERS.LIST}`,
    });
    // const [gettingDangerLevelFilterList] = useGetDangerLevelFilterListAsyncEndpoint('player_list');

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card
            title={getTranslation('Player List')}
            // loading={gettingDangerLevelFilterList}
        >
            <Form onFinish={handleGetPlayers}>
                <PlayersFilters />
                <ActionButtons
                    onDropFilters={fetchAll}
                    onExport={handleExportAction}
                    onCreateNewPlayer={onCreateNewPlayer}
                    onColumnSelect={onColumnSelect}
                    onUpdatePlayerGameAccess={onUpdatePlayerGameAccess}
                />
            </Form>

            <PlayerResultsTable loading={playersLoading} />
            {playersModal.isVisible && (
                <PlayersModal playersModal={playersModal} onCancel={handleClosePlayersModal} />
            )}
        </Card>
    );
};

export default PlayersList;
