import React from 'react';

import { Row } from 'antd';

import { PeriodFilter } from '../../../common/components/filters';
import { REFERRAL_STATUSES } from '../constants';
import { SingleSelectionFilter, TextFilter } from '../filters';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { referralManagementFilterSet } from './referralManagementAction';
import { useStore } from '../../../store/StateProvider';

const ReferralManagementFilters = () => {
    const [state, dispatch] = useStore();

    const referralManagement = getStateProp(state, 'referral.referralManagement');
    const filters = getStateProp(referralManagement, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => referralManagementFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <TextFilter
                value={getStateProp(filters, 'code', '')}
                onFilterChange={setFilter}
                name="code"
                placeholder={getTranslation('Program Code')}
            />
            <TextFilter
                value={getStateProp(filters, 'name', '')}
                onFilterChange={setFilter}
                name="name"
                placeholder={getTranslation('Program Name')}
            />
            <SingleSelectionFilter
                value={getStateProp(filters, 'status')}
                onFilterChange={setFilter}
                name="status"
                placeholder={getTranslation('Status')}
                selectionList={REFERRAL_STATUSES}
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(filters, 'dateFrom')}
                dateFromName="dateFrom"
                dateTo={getStateProp(filters, 'dateTo')}
                dateToName="dateTo"
            />
        </Row>
    );
};

export default ReferralManagementFilters;
