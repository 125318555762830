import React from 'react';

import { RenderDateFormat } from '../../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS_1000 } from '../../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../cryptoWithdrawalRecordActions';

import { ButtonGray } from '../../../../../common/components/buttons/Buttons';
import { getStatusColor, sortTableByDataType } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import VerifyButton from '../verification/VerifyButton';
import ViewRemarkButton from './ViewRemarkButton';

export const getColumns = (dispatch, tableWidth) => {
    const columns = [
        {
            dataIndex: 'createdAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Request Time'),
            width: 100,
        },
        {
            dataIndex: 'playerUserName',
            title: getTranslation('Username'),
            width: 120,
        },
        {
            dataIndex: 'transactionNo',
            title: getTranslation('Task Transaction Number'),
            width: 230,
        },
        {
            dataIndex: 'memberLevel',
            title: getTranslation('Member Level'),
            width: 80,
        },
        {
            dataIndex: 'trustLevel',
            title: getTranslation('Trust Level'),
            width: 80,
        },
        {
            dataIndex: 'amount',
            title: getTranslation('Withdrawal Amount'),
            width: 90,
        },
        {
            dataIndex: 'currencyCode',
            title: getTranslation('Currency'),
            width: 80,
        },
        {
            dataIndex: 'cryptoRate',
            render: (value) => <span>{value.toFixed(15).replace(/\.?0+$/, '')}</span>,
            title: getTranslation('Exchange Rate'),
            width: 100,
        },
        {
            dataIndex: 'cryptoCode',
            title: getTranslation('Crypto Currency'),
            width: 80,
        },
        {
            dataIndex: 'cryptoAmt',
            title: getTranslation('Withdrawal Crypto Amount'),
            width: 150,
        },
        {
            dataIndex: 'cryptoAddr',
            render: (value, record) => (
                <div>
                    <span>{value}</span>
                    <br />
                    <span>
                        {record.withdrawStatus} - {record.withdrawMessage}
                    </span>
                </div>
            ),
            title: getTranslation('Receiving Wallet Address'),
            width: 280,
        },
        {
            dataIndex: 'auditedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Audit Time'),
            width: 100,
        },
        {
            dataIndex: 'processedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Process Time'),
            width: 100,
        },
        {
            dataIndex: 'orderStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>
                    {getTranslation(value).toLocaleUpperCase()}
                </span>
            ),
            title: getTranslation('Status'),
            width: 100,
        },
        {
            dataIndex: 'remarks',
            dataType: 'remarks',
            render: (text, record) =>
                record.attendantRemarks && record.verifierRemarks ? (
                    <ViewRemarkButton
                        auditorRemark={record.attendantRemarks}
                        operatorRemark={record.verifierRemarks}
                    />
                ) : (
                    <span>{record.attendantRemarks}</span>
                ),
            title: getTranslation('Remarks'),
            width: 150,
        },
        {
            dataIndex: 'auditorRealName',
            render: (value) =>
                value && <ButtonGray disabled label={value} className="action-btn__verify" />,
            title: getTranslation('Audit'),
            width: tableWidth <= 2000 && 170,
        },
        {
            dataIndex: 'verifierRealName',
            render: (text, record) => <VerifyButton transaction={record} dispatch={dispatch} />,
            title: getTranslation('Operator'),
            width: tableWidth <= 2000 && 170,
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS_1000,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);

export const cryptoWithdrawalRecordsExport = ({ onlyMeta }) => {
    if (onlyMeta) {
        return {
            remarks: [
                {
                    dataIndex: 'attendantRemarks',
                    title: getTranslation('Attendant Remark'),
                },
                {
                    dataIndex: 'verifierRemarks',
                    title: getTranslation('Verifier Remark'),
                },
            ],
        };
    }

    let messageArray = [];
    return messageArray;
};
