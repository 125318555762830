export const VERIFY_MODAL_TYPES = {
    REJECT: 'reject',
    VERIFY: 'verify',
};

export const CRYPTO_ORDER_STATUS = {
    EXPIRED: 'Expired',
    PENDING: 'Pending',
    REJECTED: 'Rejected',
    SUCCESS: 'Success',
};
