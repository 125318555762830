import React from 'react';

import { Modal, Row } from 'antd';

import { ButtonGray, ButtonGreen } from '../../../../../common/components/buttons/Buttons';
import { getStateProp } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useStore } from '../../../../../store/StateProvider';
import SortingTable from './SortingTable';

const SortingModal = ({ loading, visible, onCancel, updating, onUpdate }) => {
    const [state, dispatch] = useStore();

    const { bonusManagement } = getStateProp(state, 'bonus');
    const tableData = getStateProp(bonusManagement, 'sortingList', []);

    return (
        <Modal
            width={780}
            visible={visible}
            title={getTranslation('Bonus Banner Sorting')}
            className="bonus-banner-sorting-modal"
            onCancel={onCancel}
            closable={false}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        label={getTranslation('Confirm')}
                        loading={updating}
                        onClick={onUpdate}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
        >
            <SortingTable loading={loading} tableData={tableData} dispatch={dispatch} />
        </Modal>
    );
};

export default SortingModal;
