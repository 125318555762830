import React from 'react';

import { Row } from 'antd';

import { DomainFilter, MerchantFilter, MerchantIDFilter } from '../filters';
import { MemberLevelFilter, TrustLevelFilter } from '../../../common/components/filters';
import { getStateProp } from '../../../common/helpers/misc';
import { merchantConfigurationFilterSet } from './merchantConfigurationActions';
import { useStore } from '../../../store/StateProvider';

const MerchantConfigurationFilters = () => {
    const [state, dispatch] = useStore();
    const merchantConfiguration = getStateProp(state, 'payments.merchantConfiguration');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) =>
        merchantConfigurationFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <DomainFilter
                onFilterChange={setFilter}
                value={getStateProp(merchantConfiguration, 'filters.merchantDomain')}
            />
            <MerchantIDFilter
                onFilterChange={setFilter}
                value={getStateProp(merchantConfiguration, 'filters.merchantId')}
            />
            <MerchantFilter
                onFilterChange={setFilter}
                value={getStateProp(merchantConfiguration, 'filters.gatewayName')}
            />
            <TrustLevelFilter
                onFilterChange={setFilter}
                value={getStateProp(merchantConfiguration, 'filters.trustLevel')}
            />
            <MemberLevelFilter
                onFilterChange={setFilter}
                value={getStateProp(merchantConfiguration, 'filters.memberLevel')}
            />
        </Row>
    );
};

export default MerchantConfigurationFilters;
