import { MODIFICATION_STATUSES, PLAYER_STATUSES } from './constants';
import { PLAYER_DETAILS_MENU_ITEMS } from './player-details/constants';

export const getPlayerStatus = (playerStatus) => {
    switch (playerStatus) {
        case PLAYER_STATUSES.ACTIVE:
            return 'Active';
        case PLAYER_STATUSES.CLOSED:
            return 'Closed';
        case PLAYER_STATUSES.LOCKED:
            return 'Locked';
        default:
            return playerStatus;
    }
};

export const getPlayerStatusColor = (playerStatus) => {
    switch (playerStatus) {
        case PLAYER_STATUSES.ACTIVE:
            return '#5CCB8D';
        case PLAYER_STATUSES.CLOSED:
            return '#FF3F3F';
        case PLAYER_STATUSES.LOCKED:
            return '#FFA30F';
        default:
            return 'black';
    }
};

export const getPlayerMenuIdByRoute = (route) => {
    const menuItem = PLAYER_DETAILS_MENU_ITEMS.find((item) => item.route === route);
    return menuItem.id;
};

export const getModificationStatusColor = (modificationStatus) => {
    switch (modificationStatus) {
        case MODIFICATION_STATUSES.APPROVED:
            return '#5CCB8D';
        case MODIFICATION_STATUSES.DECLINED:
            return '#FF3F3F';
        case MODIFICATION_STATUSES.PENDING:
            return '#FFA30F';
        default:
            return 'black';
    }
};
