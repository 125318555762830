export const ACTIONS = {
    CATEGORY_LIST_GET: 'CATEGORY_LIST_GET',
    CATEGORY_LIST_GET_FAILURE: 'CATEGORY_LIST_GET_FAILURE',
    CATEGORY_LIST_GET_SUCCESS: 'CATEGORY_LIST_GET_SUCCESS',
};

export const getCategoryList = (dispatch) =>
    dispatch({
        type: ACTIONS.CATEGORY_LIST_GET,
    });

export const getCategoryListSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CATEGORY_LIST_GET_SUCCESS,
    });

export const getCategoryListFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CATEGORY_LIST_GET_FAILURE,
    });
