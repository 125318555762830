import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

const SingleSelectionFilter = ({
    value,
    onFilterChange,
    onRefreshTable,
    name,
    placeholder,
    selectionList,
}) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange(name, value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear
            placeholder={placeholder}
            className="filter"
        >
            {_.map(selectionList, (types) => (
                <Select.Option value={types} key={types}>
                    {types.toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
};

export default SingleSelectionFilter;
