import React from 'react';

import { Table } from 'antd';

import './GroupAuthoritySettingListResultTable.less';
import { getColumns, getPaginationOptions } from './GroupAuthoritySettingListTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const GroupAuthoritySettingListResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const groupAuthoritySetting = getStateProp(state, 'administration.groupAuthoritySetting');
    const tableData = getStateProp(groupAuthoritySetting, 'groupAccessList', []);
    const paging = getStateProp(groupAuthoritySetting, 'paging', {});

    return (
        <Table
            className="user-access-group-table"
            columns={getColumns()}
            pagination={getPaginationOptions(paging, dispatch)}
            scroll={{ x: true }}
            rowKey={(record) => record.accessGroupId}
            bordered
            dataSource={tableData}
            loading={loading}
        />
    );
};

export default GroupAuthoritySettingListResultsTable;
