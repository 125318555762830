import React from 'react';

import { Table } from 'antd';
import { useVT } from 'virtualizedtableforantd4';

import { getColumns, getPaginationOptions } from './SessionsTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const SessionsResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();
    const tableData = getStateProp(state, 'players.userSessions.list', []);
    const paging = getStateProp(state, 'players.userSessions.paging', {});

    // ============================================
    // HOOKS
    // ============================================

    const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

    // ============================================
    // RENDER
    // ============================================
    return (
        <Table
            id="sessions-table"
            columns={getColumns()}
            pagination={getPaginationOptions(paging, dispatch)}
            dataSource={tableData}
            rowKey={(record) => record.id}
            loading={loading}
            size="small"
            align="left"
            scroll={{ y: 600 }}
            components={vt}
            bordered
        />
    );
};

export default SessionsResultsTable;
