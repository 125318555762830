import React, { useState } from 'react';

import { Button, Col, Dropdown, Menu, Row } from 'antd';
import { CaretDownOutlined, FormOutlined } from '@ant-design/icons';
import _ from 'lodash';

import { DAILY_LOGIN_STATUSES } from '../../constants';
import { dailyLoginEditModalOpen } from '../dailyLoginManagementActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { useDispatch } from '../../../../store/StateProvider';
import {
    useGetActiveDailyLoginBonusAsyncEndpoint,
    useUpdateDailyLoginStatusAsyncEndpoint,
} from '../../../../services/rewardMangementService';

const ColumnActionButtons = ({ record }) => {
    const dispatch = useDispatch();
    const [dailyLoginStatus, setDailyLoginStatus] = useState('');
    // ============================================
    // METHODS
    // ============================================
    const onSubmit = (e) => {
        if (record.programStatus !== e.key) {
            if (e.key === DAILY_LOGIN_STATUSES.ACTIVE) {
                handleGetActiveDailyLoginBonus();
            } else {
                setDailyLoginStatus(e.key);
                handleUpdateDailyLoginBonusStatus();
            }
        }
    };

    const openEditModal = () => dailyLoginEditModalOpen(dispatch, { id: record.id });

    // ============================================
    // HOOKS
    // ============================================

    const [checking, handleGetActiveDailyLoginBonus] = useGetActiveDailyLoginBonusAsyncEndpoint(
        record.code,
        record.id,
    );
    const [updating, handleUpdateDailyLoginBonusStatus] = useUpdateDailyLoginStatusAsyncEndpoint(
        record.id,
        dailyLoginStatus,
    );

    // ============================================
    // RENDER
    // ============================================

    const operationsMenu = (
        <Menu onClick={onSubmit}>
            {_.map(DAILY_LOGIN_STATUSES, (status) => (
                <Menu.Item key={status}>
                    <span>{getTranslation(status)}</span>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Row gutter={15} align="middle">
            <Col>
                <Dropdown
                    overlay={operationsMenu}
                    trigger={['click']}
                    disabled={record.programStatus === 'Closed'}
                >
                    <Button
                        className={
                            record.programStatus === DAILY_LOGIN_STATUSES.ACTIVE
                                ? 'btn__green action-btn__operations'
                                : 'btn__gray action-btn__operations'
                        }
                        loading={updating || checking}
                    >
                        {getTranslation(record.programStatus)}
                        <CaretDownOutlined />
                    </Button>
                </Dropdown>
            </Col>

            <Col xs={{ span: 1 }}>
                <FormOutlined
                    style={{ fontSize: '20px' }}
                    className="edit-icon"
                    onClick={openEditModal}
                />
            </Col>
        </Row>
    );
};

export default ColumnActionButtons;
