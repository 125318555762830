import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './ReferrerReportTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const ReferrerReportResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const referrerReport = getStateProp(state, 'referral.referrerReport');
    const paging = getStateProp(referrerReport, 'paging', {});
    const tableData = getStateProp(referrerReport, 'list', []);

    return (
        <Table
            id="referrer-report-table"
            columns={getColumns()}
            size="small"
            scroll={{ x: true }}
            align="left"
            dataSource={tableData}
            rowKey={(record, index) => index}
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
            bordered
        />
    );
};

export default ReferrerReportResultsTable;
