import React from 'react';

import { Card } from 'antd';

import { getTranslation } from '../../../common/helpers/locale';
import {
    useDepositCorrectionOperation,
    useGetBankCardDetailsAsyncEndpoint,
    useGetOfflineDepositsAsyncEndpoint,
    useGetPlayerDetailsAsyncEndpoint,
} from '../../../services/depositCorrectionService';
import DepositCorrectionForm from './DepositCorrectionForm';

const DepositCorrection = () => {
    // ============================================
    // HOOKS
    // ============================================

    const [operationLoading, operationSuccess] = useDepositCorrectionOperation();
    const [getPlayerDetailsLoading] = useGetPlayerDetailsAsyncEndpoint();
    const [getOfflineDepositLoading] = useGetOfflineDepositsAsyncEndpoint();
    const [getBankCardLoading] = useGetBankCardDetailsAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Deposit Correction')} className="deposit-correction-card">
            <DepositCorrectionForm
                operationLoading={operationLoading}
                operationSuccess={operationSuccess}
                playerDetailsLoading={getPlayerDetailsLoading}
                offlineDepositLoading={getOfflineDepositLoading}
                getBankCardLoading={getBankCardLoading}
            />
        </Card>
    );
};

export default DepositCorrection;
