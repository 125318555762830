import React from 'react';

import { RangeDatePicker } from '..';
import { getStateProp } from '../../helpers/misc';
import { useStore } from '../../../store/StateProvider';

const PeriodFilter = ({
    dateTo,
    dateFrom,
    onFilterChange,
    dateFromName,
    dateToName,
    prefix,
    showTime = true,
    picker = 'date',
    ignoreUTC = false,
    disabled = false,
}) => {
    const [state] = useStore();
    const location = getStateProp(state, 'timezone.location');

    // ============================================
    // METHODS
    // ============================================

    const onPeriodChange = ({ dateFrom, dateTo }) => {
        onFilterChange(dateFromName, dateFrom);
        onFilterChange(dateToName, dateTo);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <RangeDatePicker
            dateTo={dateTo}
            dateFrom={dateFrom}
            onPeriodChange={onPeriodChange}
            location={location}
            className="range-date-filter"
            prefix={prefix}
            showTime={showTime}
            picker={picker}
            ignoreUTC={ignoreUTC}
            disabled={disabled}
        />
    );
};

export default PeriodFilter;
