import React from 'react';

import { Form } from 'antd';

import {
    activatedEwalletSetupModalClose,
    activatedEwalletSetupModalOpen,
    ewalletDropFilters,
    ewalletFilterSet,
} from './activeEwalletActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { useGetActivatedEwalletsAsyncEndpoint } from '../../../../services/ewalletService';
import { useStore } from '../../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import ActiveEwalletResultsTable from './table/ActiveEwalletResultsTable';
import ActiveEwalletSetupModal from './ActiveEwalletSetupModal';
import EwalletSetupFilters from '../EwalletSetupFilters';

const ActiveEwalletAccount = () => {
    const [state, dispatch] = useStore();

    const modalData = getStateProp(state, 'payments.activeEwalletAccount.modal');
    const activeEwalletAccount = getStateProp(state, 'payments.activeEwalletAccount');
    // ============================================
    // METHODS
    // ============================================

    const onOpenModal = () => activatedEwalletSetupModalOpen(dispatch);

    const onCloseModal = () => activatedEwalletSetupModalClose(dispatch);

    const setFilter = (filter, value) => ewalletFilterSet(dispatch, { filter, value });

    const refreshTable = getStateProp(state, 'payments.activeEwalletAccount.fetchData', () => {});

    const fetchAll = () => {
        ewalletDropFilters(dispatch);
        handleGetActivatedEwalletAccounts();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetActivatedEwalletAccounts] = useGetActivatedEwalletsAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <div>
            <Form onFinish={handleGetActivatedEwalletAccounts}>
                <EwalletSetupFilters
                    setFilter={setFilter}
                    ewalletAccount={activeEwalletAccount}
                    refreshTable={refreshTable}
                />
                <ActionButtons onOpenModal={onOpenModal} onDropFilters={fetchAll} />
            </Form>
            <ActiveEwalletResultsTable loading={loading} />
            {modalData.visible && (
                <ActiveEwalletSetupModal modalData={modalData} onCancel={onCloseModal} />
            )}
        </div>
    );
};

export default ActiveEwalletAccount;
