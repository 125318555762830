import { ACTIONS } from './currencySettingActions';

export default function currencySettingReducer(currencySetting = {}, { type, payload = null }) {
    switch (type) {
        // For those page having total page balance
        case ACTIONS.CURRENCY_SETTING_FILTERS_GET_SUCCESS:
        case ACTIONS.CURRENCY_SETTING_GET_SUCCESS:
            return {
                ...currencySetting,
                ...payload,
            };

        case ACTIONS.CURRENCY_SETTING_FILTER_SET:
            return {
                ...currencySetting,
                filters: {
                    [payload.filter]: payload.value,
                },
            };

        case ACTIONS.CURRENCY_SETTINIG_INFO_SET:
        case ACTIONS.CURRENCY_SETTING_GET:
        case ACTIONS.CURRENCY_SETTING_FILTERS_GET:
        default:
            return currencySetting;
    }
}
