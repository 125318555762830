import React, { useState } from 'react';

import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Col, Input, Row } from 'antd';

import { ButtonTransparent } from '../../../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../../../common/helpers/locale';

const EWalletVerifyPopupForm = ({ eWalletNo, maskedValue }) => {
    const [eWalletVerifyValue, setEWalletVerifyValue] = useState(null);
    const [isEWalletVerified, setEWalletVerified] = useState(null);

    const verifyEWalletNo = () => {
        if (eWalletVerifyValue.replace(/\s/g, '') === eWalletNo.replace(/\s/g, '')) {
            setEWalletVerified(true);
            handleEWalletVerificationSuccess();
        } else {
            setEWalletVerified(false);
        }
    };

    const handleEWalletValue = (e) => setEWalletVerifyValue(e.target.value);

    const handleEWalletVerificationSuccess = () => {
        setTimeout(() => {
            setEWalletVerified(null);
        }, 3000);
    };

    return (
        <div style={{ width: 350 }}>
            <Row className="form__group">
                <Col span={12}>
                    <span className="form__group__label">{getTranslation('E-Wallet No.')}:</span>
                </Col>
                <Col span={12}>
                    <span>{maskedValue}</span>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={12}>
                    <span className="form__group__label">
                        {getTranslation('Verify E-Wallet No.')}:
                    </span>
                </Col>
                <Col span={12}>
                    <span>
                        <Input onChange={handleEWalletValue} />
                    </span>
                </Col>
            </Row>

            <Row className="form__group">
                <Col span={12}>
                    {isEWalletVerified !== null && (
                        <div>
                            {isEWalletVerified ? (
                                <CheckCircleTwoTone
                                    twoToneColor="#52c41a"
                                    style={{ fontSize: 18 }}
                                />
                            ) : (
                                <CloseCircleTwoTone twoToneColor="red" style={{ fontSize: 18 }} />
                            )}
                        </div>
                    )}
                </Col>
                <Col span={12}>
                    <ButtonTransparent label={getTranslation('Verify')} onClick={verifyEWalletNo} />
                </Col>
            </Row>
        </div>
    );
};

export default EWalletVerifyPopupForm;
