import React from 'react';

import { Col, Row } from 'antd';
import { FormOutlined } from '@ant-design/icons';

import './ColumnActionButtons.less';
import { useDispatch } from '../../../../store/StateProvider';
import OperationsButton from './OperationsButton';

const RowActionButtons = ({ record, openEditModal }) => {
    const dispatch = useDispatch();
    const { programStatus, id, name } = record;

    // ============================================
    // METHODS
    // ============================================

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row gutter={15} align="middle">
            <Col>
                <OperationsButton
                    className="rowaction-button"
                    dispatch={dispatch}
                    id={id}
                    name={name}
                    referralStatus={programStatus}
                />
            </Col>
            {programStatus !== 'Closed' && (
                <Col xs={{ span: 1 }}>
                    <FormOutlined
                        style={{ fontSize: '20px' }}
                        className="edit-icon"
                        onClick={() => openEditModal(id)}
                    />
                </Col>
            )}
        </Row>
    );
};

export default RowActionButtons;
