import { ACTIONS } from './playerWalletBalanceActions';
import { REPORT_TYPES, VIEW_MODES } from '../constants';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS_2000,
} from '../../../common/constants/api';

export default function playerWalletBalanceReducer(
    playerWalletBalance = {
        filters: {
            reportType: REPORT_TYPES.DAILY,
            viewMode: VIEW_MODES.DATE,
        },
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: 500,
            totalRecordCount: 0,
        },
        resetPageNo: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.PLAYER_WALLET_BALANCE_INIT:
            return {
                ...playerWalletBalance,
                filters: {
                    ...playerWalletBalance.filters,
                    ...payload,
                },
            };

        case ACTIONS.FILTER_SET:
            return {
                ...playerWalletBalance,
                filters: {
                    ...playerWalletBalance.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };
        case ACTIONS.FILTERS_DROP:
            return {
                ...playerWalletBalance,
                filters: {
                    reportType: REPORT_TYPES.DAILY,
                    viewMode: VIEW_MODES.DATE,
                    ...payload,
                },
                resetPageNo: true,
            };

        case ACTIONS.PLAYER_WALLET_BALANCE_GET_SUCCESS:
            return {
                ...playerWalletBalance,
                ...payload,
            };

        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...playerWalletBalance,
                paging: {
                    ...playerWalletBalance.paging,
                    pageNo:
                        payload > playerWalletBalance.paging.pageCount
                            ? playerWalletBalance.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE =
                TABLE_PAGE_SIZE_OPTIONS_2000[TABLE_PAGE_SIZE_OPTIONS_2000.length - 1];
            return {
                ...playerWalletBalance,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.PLAYER_WALLET_BALANCE_GET:
            const pageNo = playerWalletBalance.resetPageNo
                ? TABLE_PAGE_NO_DEFAULT
                : playerWalletBalance.paging.pageNo;
            return {
                ...playerWalletBalance,
                paging: {
                    ...playerWalletBalance.paging,
                    pageNo: pageNo,
                },
                resetPageNo: false,
            };

        default:
            return playerWalletBalance;
    }
}
