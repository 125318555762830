import React from 'react';

import { Form } from 'antd';

import { API } from '../../../../common/constants/api';
import { PAYMENT_ORDER_OPERATIONS } from '../../../../common/constants/misc';
import {
    closeConfirmRejectModal,
    cryptoWithdrawalCloseVerifyModal,
    cryptoWithdrawalOperation,
    dropFilters,
} from './cryptoWithdrawalActions';
import {
    cryptocurrencyWithdrawalRecordsExport,
    getColumns,
} from './table/CryptocurrencyWithdrawalTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import {
    useCryptoWithdrawalOperationAsyncEndpoint,
    useGetCryptoWithdrawalsAsyncEndpoint,
} from '../../../../services/cryptoCurrencyService';
import { useExportAsyncEndpoint } from '../../../../services/hooks/useExport';
import { useStore } from '../../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import ConfirmRejectModal from './verification/ConfirmRejectModal';
import CryptocurrencyWithdrawalFilters from './CryptocurrencyWithdrawalFilters';
import CryptocurrencyWithdrawalResultsTable from './table/CryptocurrencyWithdrawalResultsTable';
import VerifyModal from './verification/VerifyModal';
import useInternetStatus from '../../../../services/hooks/useInternetStatus';

const { PAYMENTS } = API;

const CryptocurrencyWithdrawal = () => {
    const [state, dispatch] = useStore();

    const cryptoWithdrawals = getStateProp(state, 'payments.cryptoWithdrawals', {});
    const transaction = getStateProp(cryptoWithdrawals, 'transaction', {});
    const verifyModalVisible = getStateProp(cryptoWithdrawals, 'verifyModalVisible');
    const confirmRejectModalVisible = getStateProp(cryptoWithdrawals, 'confirmRejectModalVisible');
    const selectedTransactionId = getStateProp(cryptoWithdrawals, 'selectedTransactionId', {});
    const filters = getStateProp(cryptoWithdrawals, 'filters', {});
    const { totalRecordCount } = getStateProp(cryptoWithdrawals, 'paging', {});

    // ============================================
    // METHODS
    // ============================================

    const closeOperationModal = () => {
        cryptoWithdrawalOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.RELEASE,
            transaction,
        });
        cryptoWithdrawalCloseVerifyModal(dispatch);
    };

    const onCloseConfirmRejectModal = () => {
        cryptoWithdrawalOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.RELEASE,
            transaction,
        });
        closeConfirmRejectModal(dispatch);
    };

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetCryptoWithdrawals();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [isOnline] = useInternetStatus();
    const [
        cryptoWithdrawalLoading,
        handleGetCryptoWithdrawals,
    ] = useGetCryptoWithdrawalsAsyncEndpoint(true, isOnline);
    const [operationPending] = useCryptoWithdrawalOperationAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        additionalCol: cryptocurrencyWithdrawalRecordsExport,
        dataMeta: getColumns(),
        filters,
        mandatoryFilter: { isPayment: true },
        method: 'GET',
        subject: getTranslation('Cryptocurrency Withdrawal'),
        totalRecordCount,
        url: `${PAYMENTS.CRYPTO_WITHDRAWALS.ROOT}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <div>
            <Form onFinish={handleGetCryptoWithdrawals}>
                <CryptocurrencyWithdrawalFilters />
                <ActionButtons onDropFilters={fetchAll} onExport={handleExportAction} />
            </Form>

            <CryptocurrencyWithdrawalResultsTable loading={cryptoWithdrawalLoading} />
            <VerifyModal
                visible={verifyModalVisible}
                transaction={transaction}
                onCancel={closeOperationModal}
                operationPending={operationPending}
            />
            <ConfirmRejectModal
                visible={confirmRejectModalVisible}
                onCancel={onCloseConfirmRejectModal}
                selectedTransactionId={selectedTransactionId}
            />
        </div>
    );
};

export default CryptocurrencyWithdrawal;
