import React from 'react';

import { Col, Row } from 'antd';

import { ButtonGray, ButtonRed } from '../../../../../../common/components/buttons/Buttons';

import { BANK_CARDS_MODAL_TYPES } from '../../../constants';
import { getTranslation } from '../../../../../../common/helpers/locale';
import { openBankCardsModal } from '../../../playerDetailsActions';
import { useDispatch } from '../../../../../../store/StateProvider';

const DeleteDetails = ({ card, onCancel }) => {
    const dispatch = useDispatch();
    // ============================================
    // METHODS
    // ============================================

    const handleDeleteBankCard = () => {
        onCancel();
        openBankCardsModal(dispatch, {
            card,
            modalType: BANK_CARDS_MODAL_TYPES.DELETE_BANK_CARD_CONFIRMATION,
        });
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col>
            <Row style={{ marginBottom: 15, marginTop: 24 }}>
                <span>{getTranslation('Are you sure want to delete this Bank Card?')}</span>
            </Row>
            <Row style={{ marginBottom: 33 }}>
                <p>{`${card.bankName} - ${card.bankCardNo}`}</p>
            </Row>
            <Row justify="end" align="middle">
                <ButtonRed label={getTranslation('Delete')} onClick={handleDeleteBankCard} />
                <ButtonGray
                    label={getTranslation('Cancel')}
                    onClick={onCancel}
                    style={{ marginLeft: 24 }}
                />
            </Row>
        </Col>
    );
};

export default DeleteDetails;
