import React from 'react';

import { getTranslation } from '../../../../common/helpers/locale';

const ManualManagementSummary = ({ totalDepositAmount, totalBonusAmount }) => {
    return (
        <tr style={{ height: '49px' }}>
            <td colSpan="3"></td>
            <td>{getTranslation('Total Deposit Amount')}</td>
            <td>{totalDepositAmount}</td>
            <td colSpan="2"></td>
            <td>{getTranslation('Total Bonus Amount')}</td>
            <td>{totalBonusAmount}</td>
        </tr>
    );
};

export default ManualManagementSummary;
