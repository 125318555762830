/* eslint-disable react-hooks/exhaustive-deps */
import * as defaultAxios from 'axios';
import { useEffect, useReducer, useState } from 'react';

import { ACTIONS, initialResponse, responseReducer } from './apiReducer';
import { getCookie, getCurrentUser, isUserLoggedIn } from '../../common/helpers/api';
import { isNullOrEmptyString } from '../../common/helpers/misc';
import { refreshTokenInterceptor } from './interceptors';

/**
 * Params
 * @param  {AxiosInstance} [axios] - (optional) The custom axios instance
 * @param  {String} url - The request URL
 * @param  {('GET'|'POST'|'PUT'|'DELETE'|'HEAD'|'OPTIONS'|'PATCH')} method - The request method
 * @param  {Object} [options] - (optional) The config options of Axios.js (https://goo.gl/UPLqaK)
 * @param  {Object|String} trigger - (optional) The conditions for AUTO RUN, refer the concepts of [conditions](https://reactjs.org/docs/hooks-reference.html#conditionally-firing-an-effect) of useEffect, but ONLY support string and plain object. If the value is a constant, it'll trigger ONLY once at the begining
 * @param  {Function} [forceDispatchEffect] - (optional) Trigger filter function, only AUTO RUN when get `true`, leave it unset unless you don't want AUTU RUN by all updates of trigger
 * @param  {Function} [customHandler] - (optional) Custom handler callback, NOTE: `error` and `response` will be set to `null` before request
 */

/**
 * Returns
 * @param  {Object} response - The response of Axios.js (https://goo.gl/dJ6QcV)
 * @param  {Object} error - HTTP error
 * @param  {Boolean} loading - The loading status
 * @param  {Function} executeRequest - MANUAL RUN trigger function for making a request manually
 */

const { CancelToken } = defaultAxios;

export default ({
    axios = defaultAxios,
    url,
    method = 'GET',
    options = {},
    trigger,
    forceDispatchEffect,
    customHandler,
} = {}) => {
    const [results, dispatch] = useReducer(responseReducer, initialResponse);
    const [innerTrigger, setInnerTrigger] = useState(0);

    const outerTrigger = trigger;
    const dispatchEffect = forceDispatchEffect || (() => true);

    const composeHeaders = () => {
        const headers = {
            'Accept-Language': getCookie('lang') || 'en-US',
            'Content-Type': 'application/json',
        };
        if (isUserLoggedIn())
            return {
                ...headers,
                Authorization: `Bearer ${getCurrentUser().accessToken}`,
            };
        else return headers;
    };

    axios.default.interceptors.request.use(refreshTokenInterceptor);

    useEffect(() => {
        const handler = (error, response) => {
            if (customHandler) {
                customHandler(error, response);
            }
        };

        if (!url || !dispatchEffect()) return;

        if (typeof outerTrigger === 'undefined' && !innerTrigger) return;

        handler(null, null);
        dispatch({
            type: ACTIONS.INIT,
        });

        const source = CancelToken.source();

        axios({
            cancelToken: source.token,
            method,
            ...{
                ...options,
                headers: composeHeaders(),
            },
            url,
        })
            .then((response) => {
                if (response.status === 204 && isNullOrEmptyString(response.data)) {
                    handler({ message: 'Something went wrong!' }, null);
                    dispatch({
                        payload: { message: 'Something went wrong!' },
                        type: ACTIONS.FAILURE,
                    });
                } else {
                    handler(null, response);
                    dispatch({
                        payload: response,
                        type: ACTIONS.SUCCESS,
                    });
                }
            })
            .catch((error) => {
                handler(error, null);
                if (!defaultAxios.isCancel(error)) {
                    dispatch({
                        payload: error,
                        type: ACTIONS.FAILURE,
                    });
                }
            });

        return () => {
            source.cancel();
        };
    }, [innerTrigger, outerTrigger]);

    return {
        ...results,
        executeRequest: () => {
            setInnerTrigger(+new Date());
        },
    };
};

export const axios = defaultAxios;
