import React from 'react';

import { Popover, Row } from 'antd';

import './LanguageDropDown.less';
import { getLocaleCodeByLocale, getTranslation } from '../../common/helpers/locale';
import { useStore } from '../../store/StateProvider';
import LanguageMenu from './LanguageMenu';

const LanguageDropdown = () => {
    const [state] = useStore();

    return (
        <Popover
            placement="bottom"
            title={getTranslation('Select Languages')}
            content={<LanguageMenu />}
            trigger="click"
        >
            <Row justify="center" align="middle" className="lang-selector-row">
                <span className="locale__lang-code">
                    {getLocaleCodeByLocale(state.country.language)}
                </span>
            </Row>
        </Popover>
    );
};

export default LanguageDropdown;
