import { ACTIONS } from './TransactionAmountSettingActions';
import { TABLE_PAGE_NO_DEFAULT, TABLE_PAGE_SIZE_DEFAULT } from '../../../common/constants/api';

export default function transactionAmountSettingReducer(
    transactionAmountSetting = {
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        resetPageNo: false,
        verifyModalVisible: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.TRANSACTION_AMOUNT_SETTING_GET_SUCCESS:
            return {
                ...transactionAmountSetting,
                ...payload,
            };

        case ACTIONS.TRANSACTION_AMOUNT_SETTING_SET:
            return {
                ...transactionAmountSetting,
                ...payload,
            };

        case ACTIONS.TRANSACTION_AMOUNT_SETTING_GET:
        default:
            return transactionAmountSetting;
    }
}
