export const ACTIONS = {
    DETAILS_GET: 'DETAILS_GET',
    DETAILS_GET_FAILURE: 'DETAILS_GET_FAILURE',
    DETAILS_GET_SUCCESS: 'DETAILS_GET_SUCCESS',
    DETAILS_SET: 'USER_DETAILS_SET',
    GET_PERMISSION: 'GET_USER_PERMISSION',
    GET_PERMISSION_FAILURE: 'GET_USER_PERMISSION_FAILURE',
    GET_PERMISSION_SUCCESS: 'GET_USER_PERMISSION_SUCCESS',
    LOGIN: 'USER_LOGIN',
    LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
    LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    LOGOUT: 'USER_LOGOUT',
};

export const userLogin = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.LOGIN,
    });

export const userLoginFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.LOGIN_FAILURE,
    });

export const userLoginSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.LOGIN_SUCCESS,
    });

export const getUserPermission = (dispatch) =>
    dispatch({
        type: ACTIONS.USER.GET_PERMISSION,
    });

export const getUserPermissionFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_PERMISSION_FAILURE,
    });

export const getUserPermissionSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.GET_PERMISSION_SUCCESS,
    });
