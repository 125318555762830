import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';

const PlatformFilter = ({ value, onFilterChange }) => {
    const [state] = useStore();
    const platforms = getStateProp(state, 'reports.playerWinLoss.filterOptions.platforms', []);

    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('platformCodes', value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            className="filter"
            placeholder={getTranslation('Platform')}
            value={value}
            onChange={handleFilterChange}
            allowClear={true}
            mode="multiple"
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {_.map(platforms, (platform) => (
                <Select.Option value={platform.productCode} key={platform.platformCode}>
                    {platform.platformDesc.toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
};

export default PlatformFilter;
