import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import React from 'react';

import { Card, Row } from 'antd';

import { ButtonBlue } from '../../../common/components/buttons/Buttons';
import { PrivateRoute } from '../../../common/components';
import { ROUTES } from '../../../common/constants/routing';
import { getTranslation } from '../../../common/helpers/locale';
import GlobalAnnouncement from './global-announcement/GlobalAnnouncement';
import PersonalMessage from './personal-message/PersonalMessage';

const AnnouncementVerification = () => {
    const history = useHistory();
    const location = useLocation();

    // ============================================
    // METHODS
    // ============================================

    const isActive = (subRoute) =>
        location.pathname.replace('/dashboard/announcement/announcement-verification/', '') ===
        subRoute;

    const onTabChange = (key) => history.push(key);

    // ============================================
    // RENDER
    // ============================================

    const AspectButton = (
        <Row align="middle" justify="space-between">
            <ButtonBlue
                active={isActive('global')}
                onClick={() => onTabChange('global')}
                className="tab-button"
            >
                {getTranslation('GLOBAL')}
            </ButtonBlue>

            <ButtonBlue
                active={isActive('personal')}
                onClick={() => onTabChange('personal')}
                className="tab-button"
            >
                {getTranslation('PERSONAL')}
            </ButtonBlue>
        </Row>
    );

    return (
        <Card title={getTranslation('Announcement Verification')} extra={AspectButton}>
            <Switch>
                <PrivateRoute
                    path={ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.ANNOUNCEMENT_VERIFICATION.GLOBAL}
                    component={GlobalAnnouncement}
                />
                <PrivateRoute
                    path={ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.ANNOUNCEMENT_VERIFICATION.PERSONAL}
                    component={PersonalMessage}
                />
                <Redirect
                    to={ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.ANNOUNCEMENT_VERIFICATION.GLOBAL}
                />
            </Switch>
        </Card>
    );
};

export default AnnouncementVerification;
