import { ACTIONS } from './dailyLoginManagementActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function dailyLoginManagementReducer(
    dailyLoginManagement = {
        modalDetails: {
            isVisible: false,
            selectedData: {},
        },
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        resetPageNo: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.EDIT_MODAL_OPEN:
            return {
                ...dailyLoginManagement,
                modalDetails: {
                    isVisible: true,
                    selectedData: payload,
                },
            };

        case ACTIONS.EDIT_MODAL_CLOSE:
            return {
                ...dailyLoginManagement,
                modalDetails: {
                    isVisible: false,
                    selectedData: {},
                },
            };

        case ACTIONS.EDIT_DAILY_LOGIN_INFO:
            return {
                ...dailyLoginManagement,
                modalDetails: {
                    ...dailyLoginManagement.modalDetails,
                    selectedData: {
                        ...dailyLoginManagement.modalDetails.selectedData,
                        [payload.key]: payload.value,
                    },
                },
            };

        case ACTIONS.DAILY_LOGIN_GET_BY_ID_SUCCESS:
            return {
                ...dailyLoginManagement,
                modalDetails: {
                    ...dailyLoginManagement.modalDetails,
                    selectedData: { ...payload },
                },
            };

        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...dailyLoginManagement,
                paging: {
                    ...dailyLoginManagement.paging,
                    pageNo:
                        payload > dailyLoginManagement.paging.pageCount
                            ? dailyLoginManagement.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...dailyLoginManagement,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.DAILY_LOGIN_GET_SUCCESS:
            return {
                ...dailyLoginManagement,
                ...payload,
            };

        case ACTIONS.DAILY_LOGIN_FILTER_SET:
            return {
                ...dailyLoginManagement,
                filters: {
                    ...dailyLoginManagement.filters,
                    [payload.filter]: payload.value,
                },
            };

        case ACTIONS.DAILY_LOGIN_FILTER_DROP:
            return {
                ...dailyLoginManagement,
                filters: {},
            };

        case ACTIONS.ACTIVE_DAILY_LOGIN_GET_SUCCESS:
            return {
                ...dailyLoginManagement,
                activeDailyLoginBonus: payload,
            };

        case ACTIONS.CONFIRM_MODAL_CLOSE:
            return {
                ...dailyLoginManagement,
                activeDailyLoginBonus: {},
            };

        case ACTIONS.DAILY_LOGIN_UPDATE:
        case ACTIONS.DAILY_LOGIN_UPDATE_STATUS:
        case ACTIONS.DAILY_LOGIN_GET:
        default:
            return dailyLoginManagement;
    }
}
