import React, { useEffect, useState } from 'react';

import { Col, Input, Row, Upload } from 'antd';
import _ from 'lodash';

import './PlayersBatchSearch.less';
import { ButtonGreen } from '../buttons/Buttons';
import { getTranslation } from '../../helpers/locale';

import { usePlayerBatchSearchEndpoint } from '../../../services/hooks/usePlayerBatchSearch';
import PlayersSearchResultsTable from './table/PlayersSearchResultsTable';

const { TextArea } = Input;

const PlayersBatchSearch = ({ getValidPlayersList = () => {}, isDownloadedApp, userNames }) => {
    const [playerList, setPlayerList] = useState('');

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleBatchSearch, response] = usePlayerBatchSearchEndpoint(
        _.map(playerList.split(','), (player) => player.trim()),
        isDownloadedApp,
    );

    useEffect(() => {
        if (!_.isEmpty(response)) {
            getValidPlayersList(response.valid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    useEffect(() => {
        if (userNames) {
            setPlayerList(userNames);
        }
    }, [userNames]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col>
            <Row justify="space-between" className="player-batch-search__upload">
                <Col span={2}>Players: </Col>
                <Col span={15}>
                    <TextArea
                        rows={5}
                        value={playerList}
                        onChange={(e) => setPlayerList(e.target.value)}
                    />
                </Col>
                <Col span={4}>
                    <Upload
                        accept=".txt, .csv"
                        showUploadList={false}
                        beforeUpload={(file) => {
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                let result = e.target.result.replace(/\r\n/g, ',');
                                setPlayerList(result);
                            };
                            reader.readAsText(file);

                            // Prevent upload
                            return false;
                        }}
                    >
                        <ButtonGreen
                            label={getTranslation('Upload')}
                            className="player-batch-search__action-btn"
                        />
                    </Upload>
                    <ButtonGreen
                        label={getTranslation('Verify')}
                        className="player-batch-search__action-btn"
                        onClick={handleBatchSearch}
                    />
                </Col>
            </Row>

            {response.invalid && response.invalid.length > 0 && (
                <span className="player-batch-search__warning-msg">
                    {getTranslation('* User(s) {usernames} not found', {
                        usernames: _.join(response.invalid, ', '),
                    })}
                </span>
            )}

            <PlayersSearchResultsTable loading={loading} tableData={response.valid} />
        </Col>
    );
};

export default PlayersBatchSearch;
