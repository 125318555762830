import React from 'react';

import { InputNumber } from 'antd';
import _ from 'lodash';

import { bonusManagementSortingOrderSet } from '../../bonusManagementActions';
import { getTranslation } from '../../../../../common/helpers/locale';

export const getColumns = (dispatch, tableData) => {
    const columns = [
        {
            dataIndex: 'bonusCode',
            title: getTranslation('Bonus Code'),
        },
        {
            dataIndex: 'sortingOrder',
            render: (text, record) => {
                return (
                    <InputNumber
                        value={text}
                        onChange={(value) => onOrderNumberChange({ record, tableData, value })}
                        onBlur={() => updateOrderNumber(dispatch, tableData)}
                    />
                );
            },
            title: getTranslation('Sorting Order'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
    }));
};

const onOrderNumberChange = ({ record, tableData, value }) => {
    _.map(tableData, (data) => {
        if (data.bonusId === record.bonusId) {
            data.sortingOrder = value;
        }
    });
};

const updateOrderNumber = (dispatch, tableData) => {
    bonusManagementSortingOrderSet(dispatch, {
        sortingList: _.orderBy(tableData, ['sortingOrder'], ['asc']),
    });
};
