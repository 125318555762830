import React from 'react';

import { Row } from 'antd';

import {
    CurrencyFilter,
    FullNameFilter,
    PeriodFilter,
    TransactionNumberFilter,
    TransactionStatusFilter,
    UsernameFilter,
} from '../../../common/components/filters';
import { VipRewardTypeFilter } from '../filters';
import { getStateProp } from '../../../common/helpers/misc';
import { useStore } from '../../../store/StateProvider';
import { vipRecordsFilterSet } from './vipRecordsActions';

const VipRecordsFilters = () => {
    const [state, dispatch] = useStore();

    const { vipRecords } = getStateProp(state, 'transactionHistory');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => vipRecordsFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <TransactionNumberFilter
                name="transactionNo"
                onFilterChange={setFilter}
                value={getStateProp(vipRecords, 'filters.transactionNo')}
            />
            <UsernameFilter
                name="playerUserName"
                onFilterChange={setFilter}
                value={getStateProp(vipRecords, 'filters.playerUserName')}
            />
            <FullNameFilter
                name="playerName"
                onFilterChange={setFilter}
                value={getStateProp(vipRecords, 'filters.playerName')}
            />
            <PeriodFilter
                dateFromName="requestedDateFrom"
                dateToName="requestedDateTo"
                onFilterChange={setFilter}
                dateFrom={getStateProp(vipRecords, 'filters.requestedDateFrom')}
                dateTo={getStateProp(vipRecords, 'filters.requestedDateTo')}
            />
            <TransactionStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(vipRecords, 'filters.transactionStatuses')}
            />
            <CurrencyFilter
                name="currencyCode"
                onFilterChange={setFilter}
                value={getStateProp(vipRecords, 'filters.currencyCode')}
            />
            <VipRewardTypeFilter
                value={getStateProp(vipRecords, 'filters.vipRewardTypes')}
                onFilterChange={setFilter}
            />
        </Row>
    );
};

export default VipRecordsFilters;
