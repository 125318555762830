export const TRANSACTION_TYPES = {
    AFFILIATE: 'AffiliatePayout',
    BALANCE_ADDITION: 'BalanceAddition',
    BALANCE_DEDUCTION: 'BalanceDeduction',
    BONUS: 'Bonus',
    CRYPTO_CORRECTION: 'CryptoCorrection',
    DAILY_LOGIN: 'DailyLogin',
    DEPOSIT: 'Deposit',
    DEPOSIT_CORRECTION: 'DepositCorrection',
    EWALLET_CORRECTION: 'EwalletCorrection',
    REBATE_PAYOUT: 'RebatePayout',
    REFERRAL: 'ReferralPayout',
    TRANSFER: 'Transfer',
    VIP_PAYOUT: 'VipPayout',
    WITHDRAWAL: 'Withdrawal',
};

export const DEPOSIT_TYPES = {
    DEPOSIT_CORRECTION: 'DepositCorrection',
    EWALLET: 'Ewallet',
    EWALLET_CORRECTION: 'EwalletCorrection',
    MERCHANTS: 'Merchant',
    ONLINE_BANKING: 'OnlineBanking',
};

export const REFERRAL_TYPES = {
    REFEREE_REWARD: 'RefereeReward',
    REFERRAL_REWARDS: 'ReferrerReward',
    REFERRER_BONUS: 'ReferrerBonus',
};

export const REWARDS_TYPES = {
    DAILY_LOGIN: 'DailyLogin',
};

export const VIP_REWARDS_TYPES = {
    BIRTHDAYREDPACKET: 'BirthdayRedPacket',
    MONTHLYREDPACKET: 'MonthlyRedPacket',
    PROMOTIONBONUS: 'PromotionBonus',
};
