import React from 'react';

import { Input } from 'antd';

import '../styles/AnnouncementVerificationTabs.less';
import Editor from '../../../../common/components/Editor';

const { TextArea } = Input;

const MessageVerificationFormTabs = ({ title, content, messageType }) => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <>
            <Input
                placeholder="Title"
                value={title}
                className="announcement-content__title"
                disabled
            />

            {messageType === 'Inbox' ? (
                <Editor initialValue={content} disabled />
            ) : (
                <TextArea value={content} disabled rows={4} />
            )}
        </>
    );
};

export default MessageVerificationFormTabs;
