import React from 'react';

import { Col, Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import './Fallback.less';
import { ButtonGray } from '../common/components/buttons/Buttons';
import { getTranslation } from '../common/helpers/locale';

const Fallback = () => {
    // ============================================
    // METHODS
    // ============================================

    const goToHomePage = () => (window.location.pathname = '/');

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col className="fallback-page">
            <Row justify="center" className="fallback-page__title">
                <span>{getTranslation('Oops, something went wrong!').toLocaleUpperCase()}</span>
            </Row>
            <Row justify="center" className="fallback-page__image">
                <InfoCircleOutlined className="info-icon" />
            </Row>
            <ButtonGray onClick={goToHomePage} className="fallback-page__btn">
                {getTranslation('Go to home').toLocaleUpperCase()}
            </ButtonGray>
        </Col>
    );
};

export default Fallback;
