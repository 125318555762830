import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import React from 'react';

import { Card, Row } from 'antd';

import './CreatePushNotification.less';
import { ButtonBlue } from '../../../common/components/buttons/Buttons';
import { PrivateRoute } from '../../../common/components';
import { ROUTES } from '../../../common/constants/routing';
import { getTranslation } from '../../../common/helpers/locale';
import GeneralPushNotification from './general/GeneralPushNotification';
import TargetPushNotification from './target/TargetPushNotification';

const CreatePushNotification = () => {
    const history = useHistory();
    const location = useLocation();

    // ============================================
    // METHODS
    // ============================================

    const onTabChange = (key) => history.push(key);

    const isActive = (subRoute) =>
        location.pathname.replace('/dashboard/push-notification/create/', '') === subRoute;

    // ============================================
    // RENDER
    // ============================================

    const AspectButton = (
        <Row align="middle" justify="space-between">
            <ButtonBlue
                active={isActive('general')}
                onClick={() => onTabChange('general')}
                className="tab-button"
            >
                {getTranslation('GENERAL')}
            </ButtonBlue>

            <ButtonBlue
                active={isActive('target')}
                onClick={() => onTabChange('target')}
                className="tab-button"
            >
                {getTranslation('TARGET')}
            </ButtonBlue>
        </Row>
    );

    return (
        <Card title={getTranslation('Create Push Notification')} extra={AspectButton}>
            <Switch>
                <PrivateRoute
                    path={ROUTES.DASHBOARD.PUSH_NOTIFICATION.CREATE.GENERAL}
                    component={GeneralPushNotification}
                />

                <PrivateRoute
                    path={ROUTES.DASHBOARD.PUSH_NOTIFICATION.CREATE.TARGET}
                    component={TargetPushNotification}
                />
                <Redirect to={ROUTES.DASHBOARD.PUSH_NOTIFICATION.CREATE.GENERAL} />
            </Switch>
        </Card>
    );
};

export default CreatePushNotification;
