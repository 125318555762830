import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { DANGER_LEVELS } from '../../constants/misc';
import { getTranslation } from '../../../common/helpers/locale';

function DangerLevelFilter({ value, onFilterChange, name, dangerLevelFilterList }) {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange(name, value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear
            placeholder={getTranslation('Danger Level')}
            className="filter"
        >
            {_.map(DANGER_LEVELS, (danger_level) => (
                <Select.Option key={danger_level} value={danger_level}>
                    {danger_level}
                </Select.Option>
            ))}
            {/* {_.map(dangerLevelFilterList, (e) => (
                <Select.Option key={e.level} value={e.level}>
                    {e.level + ' - ' + e.tagName}
                </Select.Option>
            ))} */}
        </Select>
    );
}

export default DangerLevelFilter;
