import React from 'react';

import { Row } from 'antd';

import { DropButton, ExportButton, SearchButton } from '../../../../common/components/buttons';

const ActionButtons = ({ onExport, onDropFilters }) => {
    return (
        <Row align="middle" justify="end" className="action-buttons__container">
            <SearchButton htmlType="submit" />
            <DropButton onDropFilters={onDropFilters} />
            <ExportButton onExport={onExport} />
        </Row>
    );
};

export default ActionButtons;
