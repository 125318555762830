import { ACTIONS } from './betListActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS_2000,
} from '../../../common/constants/api';

export default function betListReducer(
    betList = {
        filterOptions: {},
        openDetailPage: false,
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        resetPageNo: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.FILTER_SET:
            return {
                ...betList,
                filters: {
                    ...betList.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };

        case ACTIONS.FILTERS_DROP_ALL:
            return {
                ...betList,
                filters: {},
                resetPageNo: true,
            };
        case ACTIONS.BET_LIST_GET_SUCCESS:
            return {
                ...betList,
                ...payload,
                resetPageNo: false,
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...betList,
                paging: {
                    ...betList.paging,
                    pageNo:
                        payload > betList.paging.pageCount ? betList.paging.pageCount - 1 : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS_2000[TABLE_PAGE_SIZE_OPTIONS_2000.length - 1],
            );
            return {
                ...betList,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.SELECTED_SINGLE_DETAIL:
            return {
                ...betList,
                selectedReference: payload,
            };
        case ACTIONS.PLAYER_SINGLE_DETAIL_URL_GET_SUCCESS:
            return {
                ...betList,
                selectedDetailUrl: payload,
            };

        case ACTIONS.PLAYER_SINGLE_DETAIL_PAGE_OPEN:
            return {
                ...betList,
                openDetailPage: true,
            };
        case ACTIONS.PLAYER_SINGLE_DETAIL_PAGE_CLOSE:
            return {
                ...betList,
                openDetailPage: false,
                selectedDetailUrl: undefined,
            };

        case ACTIONS.BET_LIST_GET: {
            const pageNo = betList.resetPageNo ? TABLE_PAGE_NO_DEFAULT : betList.paging.pageNo;
            return {
                ...betList,
                paging: {
                    ...betList.paging,
                    pageNo: pageNo,
                },
                resetPageNo: false,
            };
        }

        case ACTIONS.BET_LIST_FILTER_LIST_GET_SUCCESS:
            return {
                ...betList,
                filterOptions: payload,
            };

        case ACTIONS.BET_LIST_FILTER_LIST_UPDATE_SUCCESS:
            return {
                ...betList,
                filterOptions: {
                    ...betList.filterOptions,
                    ...payload,
                },
            };

        case ACTIONS.EXPORT_BET_LIST:
        case ACTIONS.EXPORT_BET_LIST_SUCCESS:
        case ACTIONS.BET_LIST_FILTER_LIST_UPDATE:
        case ACTIONS.BET_LIST_FILTER_LIST_GET:
        default:
            return betList;
    }
}
