import React from 'react';

import 'moment-timezone';
//import { Collapse } from 'antd';

import './BetListRecordDetails.less';
import { PLATFORMS } from '../../constants';
import { getTranslation } from '../../../../common/helpers/locale';
import FullDetails from './FullDetails';
import ShortDetails from './ShortDetails';

/* const { Panel } = Collapse; */

const BetListRecordDetails = ({ record, handleGetDetailUrl }) => {
    const {
        detail,
        platformDesc,
        productDesc,
        refNo,
        userName,
        marketStatus,
        betTeam,
        showDetailButton,
    } = record;

    if (!detail) {
        return <span>-</span>;
    }

    if (detail.length > 0) {
        const queryDetail = detail.find((x) => x.culture === 'en-US') || detail[0];
        return platformDesc === PLATFORMS.AVIA ||
            platformDesc === 'GGBook Esports' ||
            platformDesc === PLATFORMS.BTI ||
            platformDesc === 'Dire Esports' ? (
            <FullDetails
                detail={queryDetail}
                marketStatus={marketStatus}
                betTeam={betTeam}
                product={productDesc}
            />
        ) : (
            <ShortDetails
                refNo={refNo}
                userName={userName}
                handleGetDetailUrl={handleGetDetailUrl}
                platform={platformDesc}
                detail={queryDetail}
                showDetailButton={showDetailButton}
            />
        );
        /*  } else if (detail.length > 1) {
        return (
            <Collapse className="bet-list-record-panel">
                <Panel
                    className="panel"
                    header={getTranslation('Combo ({number})', { number: detail.length })}
                >
                    {_.map(detail, (detail, index) => (
                        <FullDetails
                            key={index}
                            detail={detail}
                            marketStatus={marketStatus}
                            product={productDesc}
                            betTeam={betTeam}
                            className="esport-details"
                        />
                    ))}
                </Panel>
            </Collapse>
        );
    */
    } else return <p>{getTranslation('No Details')}</p>;
};

export default BetListRecordDetails;
