import React from 'react';

import MessagesResultsTable from './table/MessagesResultsTable';

const HistoryMessages = () => {
    // ============================================
    // RENDER
    // ============================================

    return <MessagesResultsTable />;
};

export default HistoryMessages;
