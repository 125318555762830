import React from 'react';

import { Input } from 'antd';

import { getTranslation } from '../../../common/helpers/locale';

const BonusNameFilter = ({ value, onFilterChange }) => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <Input
            onChange={(e) => onFilterChange('bannerName', e.target.value)}
            value={value}
            className="filter"
            placeholder={getTranslation('Banner Name')}
        />
    );
};

export default BonusNameFilter;
