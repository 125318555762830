import { ACTIONS } from './dailyLoginSetupActions';
import { getInitialDetailList } from './helper';

export default function dailyLoginSetupReducer(
    dailyLoginSetup = {
        code: null,
        loginBonusDetails: getInitialDetailList(),
        repetitiveClaim: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.DAILY_LOGIN_SETUP_INFO_SET:
            return {
                ...dailyLoginSetup,
                [payload.key]: payload.value,
            };

        case ACTIONS.DAILY_LOGIN_SETUP_RESET:
            return {
                code: null,
                loginBonusDetails: getInitialDetailList(),
                repetitiveClaim: false,
            };

        default:
            return dailyLoginSetup;
    }
}
