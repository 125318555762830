import { useHistory } from 'react-router';
import React from 'react';

import { Col, Row } from 'antd';
import { CopyOutlined, FormOutlined } from '@ant-design/icons';
import _ from 'lodash';

import './ColumnActionButtons.less';
import { ROUTES } from '../../../common/constants/routing';
import { bannerDuplicate } from '../create-banner/createBannerActions';
import { setBannerSelected } from './bannerManagementActions';
import OperationsButton from './operations/OperationsButton';

const ColumnActionButtons = ({
    dispatch,
    banner,
    openEditModal,
    handleSetBannerStatus,
    handleGetBannerById,
    updateStatusSuccess,
}) => {
    const history = useHistory();
    // ============================================
    // METHODS
    // ============================================

    const openEditPage = () => {
        setBannerSelected(dispatch, { id: banner.id });
        handleGetBannerById();
        openEditModal();
    };

    const onCopyBanner = () => {
        const { details, startDate, endDate, currencyCode } = banner;

        const bannerContent = _.map(details, (detail) => {
            const { bannerName, remark, languageCode, bannerUrl } = detail;
            return {
                bannerName,
                bannerUrl,
                languageCode,
                remark,
            };
        });

        const currencyArray = _.split(currencyCode, ',');

        bannerDuplicate(dispatch, {
            currencyCode: currencyArray,
            details: bannerContent,
            endDate,
            startDate,
        });

        history.push(ROUTES.DASHBOARD.BANNER.CREATE_BANNER);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row gutter={15} align="middle">
            <Col>
                <OperationsButton
                    className="rowaction-button"
                    dispatch={dispatch}
                    id={banner.id}
                    bannerStatus={banner.bannerStatus}
                    loading={updateStatusSuccess}
                    handleSetBannerStatus={handleSetBannerStatus}
                />
            </Col>
            <Col>
                <FormOutlined className="action-icon" onClick={openEditPage} />
                <CopyOutlined className="action-icon" onClick={onCopyBanner} />
            </Col>
        </Row>
    );
};

export default ColumnActionButtons;
