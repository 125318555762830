import { LANGUAGES } from '../../common/constants/localization';
import { message } from 'antd';
import _ from 'lodash';

import { getSupportedLanguages, getTranslation } from '../../common/helpers/locale';
import { textEditorImageFileSizeCheck } from '../../common/helpers/misc';

export const formValidation = (newsDraft, newsDraftId) => {
    const { newsArticles, ...rest } = newsDraft;

    return validateNewsDraftArticle(newsArticles) && validateNewsDraftInfo(rest, newsDraftId);
};

const validateNewsDraftArticle = (newsArticles) => {
    const imageReg = /<\s*img[^>]*>/g;

    if (_.isEmpty(newsArticles)) {
        message.error(getTranslation('Must have atleast one language!'));
        return false;
    } else {
        return _.every(newsArticles, ({ languageCode, ...content }) => {
            if (_.some(content, _.isEmpty)) {
                message.error(getTranslation('Please fill in the content in all languages'));
                return false;
            } else if (content.content.match(imageReg)) {
                return textEditorImageFileSizeCheck(content.content.match(imageReg));
            } else {
                return true;
            }
        });
    }
};

const validateNewsDraftInfo = (newsInfo, newsDraftId) => {
    if (
        newsDraftId
            ? _.isEmpty(newsInfo.imageInBase64) && _.isEmpty(newsInfo.imageUrl)
            : _.isEmpty(newsInfo.imageInBase64)
    ) {
        message.error(getTranslation('Please upload an image'));
        return false;
    }

    if (_.isEmpty(newsInfo.newsCategory)) {
        message.error(getTranslation('Please choose related game'));
        return false;
    }

    if (_.isEmpty(newsInfo.author)) {
        message.error(getTranslation('Please fill in author'));
        return false;
    }

    return true;
};

export const getDefaultContent = () => {
    return getSupportedLanguages().length === LANGUAGES.length
        ? [
              {
                  content: '',
                  languageCode: 'en-US',
                  title: '',
              },
              {
                  content: '',
                  languageCode: 'zh-CN',
                  title: '',
              },
          ]
        : _.map(getSupportedLanguages(), (language) => {
              return {
                  content: '',
                  languageCode: language.value,
                  title: '',
              };
          });
};
