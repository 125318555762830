import React from 'react';
import _ from 'lodash';

import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../pushNotificationManagementAction';
import { getStatusColor, sortTableByDataType } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import ColumnActionButtons from '../operations/ColumnActionButtons';

export const getColumns = (openEditModal) => {
    const renderTitle = (record) => {
        const englishInfo = _.find(record.notificationDetails, ['languageCode', 'en-US']);
        const value = !_.isEmpty(englishInfo)
            ? englishInfo.title
            : record.notificationDetails[0].title;
        return <span>{value}</span>;
    };

    const columns = [
        {
            dataIndex: 'createdAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Request Time'),
        },
        {
            dataIndex: 'title',
            render: (value, record) => renderTitle(record),
            title: getTranslation('Title'),
        },
        {
            dataIndex: 'targetType',
            render: (value) => (
                <span>
                    {value === 'None'
                        ? getTranslation('General')
                        : _.capitalize(getTranslation(value))}
                </span>
            ),
            title: getTranslation('Target Type'),
        },
        {
            dataIndex: 'notificationStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>
                    {getTranslation(value).toLocaleUpperCase()}
                </span>
            ),
            title: getTranslation('Status'),
        },
        {
            dataIndex: 'applicant',
            title: getTranslation('Applicant Username'),
        },
        {
            dataIndex: 'programStatus',
            render: (value, record) => (
                <ColumnActionButtons record={record} openEditModal={openEditModal} />
            ),
            title: getTranslation('Action'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
