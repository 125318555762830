export const USER_AUTHORITY_SETTING_MODAL_TYPES = {
    ADD_ROLE: {
        component: 'AddRole',
        title: 'User Role',
        width: 700,
    },
    ADD_USER: {
        component: 'AddUser',
        title: 'Add User',
        width: 600,
    },
    CHANGE_CURRENCY: {
        component: 'ChangeCurrency',
        title: 'Change Currency',
        width: 700,
    },
    CHANGE_PASSWORD: {
        component: 'ChangePassword',
        title: 'Change Password',
        width: 700,
    },
    INDIVIDUAL_AUTHORITY: {
        component: 'IndividualAuthority',
        title: 'Individual Authority',
        width: 700,
    },
};
