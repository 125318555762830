import React from 'react';

import { Row } from 'antd';

import { CryptoShortIdFilter } from '../../filters';
import {
    CryptocurrencyFilter,
    CurrencyFilter,
    OperatorFilter,
    OrderStatusFilter,
    PeriodFilter,
    ReceivingWalletFilter,
    TransactionNumberFilter,
    UsernameFilter,
} from '../../../../common/components/filters';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';
import { withdrawalsFilterSet } from './cryptoWithdrawalActions';

const CryptocurrencyWithdrawalFilters = () => {
    const [state, dispatch] = useStore();
    const cryptoWithdrawals = getStateProp(state, 'payments.cryptoWithdrawals');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => {
        withdrawalsFilterSet(dispatch, { filter, value });
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <OrderStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoWithdrawals, 'filters.orderStatus')}
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(cryptoWithdrawals, 'filters.dateFrom')}
                dateTo={getStateProp(cryptoWithdrawals, 'filters.dateTo')}
                dateFromName="dateFrom"
                dateToName="dateTo"
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoWithdrawals, 'filters.currencyCode')}
                name="currencyCode"
            />
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoWithdrawals, 'filters.playerUserName')}
                name="playerUserName"
            />
            <TransactionNumberFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoWithdrawals, 'filters.transactionNo')}
                name="transactionNo"
            />
            <ReceivingWalletFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoWithdrawals, 'filters.cryptoAddr')}
                name="cryptoAddr"
            />
            <CryptocurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoWithdrawals, 'filters.cryptoCode')}
                name="cryptoCode"
            />
            <OperatorFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoWithdrawals, 'filters.verifierUserName')}
                name="verifierUserName"
            />
            <CryptoShortIdFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoWithdrawals, 'filters.shortId')}
                name="shortId"
            />
        </Row>
    );
};

export default CryptocurrencyWithdrawalFilters;
