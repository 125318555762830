import React from 'react';

import { Row } from 'antd';

import './ActionButtons.less';
import { DropButton, SearchButton } from '../../../common/components/buttons';

const ActionButtons = ({ onDropFilters }) => {
    return (
        <Row align="middle" justify="end" className="news-buttons__container">
            <SearchButton htmlType="submit" />
            <DropButton onDropFilters={onDropFilters} />
        </Row>
    );
};

export default ActionButtons;
