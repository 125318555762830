import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { ENABLED_DISABLED } from '../constants';
import { getTranslation } from '../../../../common/helpers/locale';

function DailyLoginFilter({ value, onFilterChange, name }) {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange(name, value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear
            placeholder={getTranslation('Daily Login')}
            className="filter"
        >
            {_.map(ENABLED_DISABLED, (e) => (
                <Select.Option value={e.value} key={e.key}>
                    {e.key.toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
}

export default DailyLoginFilter;
