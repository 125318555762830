import PropTypes from 'prop-types';
import React from 'react';

const ImgIcon = (props) => {
    const styles = {
        color: props.color,
        img: {
            cursor: 'pointer',
            height: `${props.size ? props.size : props.height}px`,
            width: `${props.size ? props.size : props.width}px`,
        },
    };
    return (
        <div
            onClick={props.onClick}
            style={{ background: 'transparent' }}
            className={props.containerClassName}
        >
            <img
                style={styles.img}
                src={props.icon}
                alt={props.alt}
                {...props}
                className={props.imageClassName}
            />
        </div>
    );
};

ImgIcon.propTypes = {
    alt: PropTypes.string,
    icon: PropTypes.string.isRequired,
    size: PropTypes.number,
};

export default ImgIcon;
