import React, { useEffect, useState } from 'react';

import { Col, Form, Input, Modal, Row, Select, message } from 'antd';
import _ from 'lodash';

import { ButtonGray, ButtonGreen, ButtonRed } from '../../../../common/components/buttons/Buttons';
import {
    PROGRESS_STATUS,
    REQUEST_SEARCH_PLAYER_INFO_ACTIONS,
    VERIFY_ACTION,
    VERIFY_STATUSES,
} from '../constants';
import { RenderDateFormat } from '../../../../common/components';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import {
    manualManagementVerifyActionSet,
    updateSelectedVerifyBonusInfo,
} from '../manualManagementActions';
import {
    useCompletedOrExpiredManualManagementRecordAsyncEndpoint,
    useGetCalculatedTurnoverAmountOperationAsyncEndpoint,
    useGetDepositAmountAsyncEndpoint,
    useUpdateManualManagementRecordAsyncEndpoint,
    useVerifyManualManagementRecordAsyncEndpoint,
} from '../../../../services/manualManagementService';
import { useDispatch } from '../../../../store/StateProvider';
import ConfirmModal from './ConfirmModal';
import Loader from '../../../../common/components/Loader';

const { TextArea } = Input;

const VerifyModal = ({
    visible,
    onCancel,
    bonus,
    handleGetManualManagementRecords,
    userName,
    isBatchUpload = false,
}) => {
    const dispatch = useDispatch();
    const [buttonDisable, setButtonDisable] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState({
        content: null,
        onCancel: () => {},
        onConfirm: () => {},
        title: null,
        visible: false,
    });
    const [form] = Form.useForm();
    const { setFieldsValue } = form;
    const actionSearchBy = getStateProp(bonus, 'action');
    const isDisabled = !actionSearchBy;
    const applicantName = getStateProp(bonus, 'applicantName');
    const operatorName = getStateProp(bonus, 'operatorName');
    const isDisabledEdit = !!(operatorName || applicantName) || isBatchUpload;
    const approvalStatus = getStateProp(bonus, 'approvalStatus');
    const progressStatus = getStateProp(bonus, 'progressStatus');

    // ============================================
    // METHODS
    // ============================================

    const setInfo = (inputName, value) => {
        updateSelectedVerifyBonusInfo(dispatch, { inputName, value });
    };

    const onSendAgree = () => {
        manualManagementVerifyActionSet(dispatch, VERIFY_ACTION.SUBMIT);
        handleVerifyReward();
    };

    const onSendReject = () => {
        manualManagementVerifyActionSet(dispatch, VERIFY_ACTION.REJECT);
        handleVerifyReward();
    };

    const handleUpdateBonus = () => {
        if (bonus.noTransaction) {
            message.warning(getTranslation('No Transaction Found'));
        } else if (formValidation()) {
            bonus.bonusAmount === 0
                ? setConfirmationModal({
                      content: 'Are you sure to set bonus amount to 0 ?',
                      onCancel: () => setConfirmationModal({ visible: false }),
                      onConfirm: () => handleUpdateReward(),
                      title: 'Confirmation',
                      visible: true,
                  })
                : handleUpdateReward();
        }
    };

    const onSendComplete = () => {
        manualManagementVerifyActionSet(dispatch, VERIFY_ACTION.COMPLETED);
        setConfirmationModal({
            content: 'Are you sure?',
            onCancel: () => setConfirmationModal({ visible: false }),
            onConfirm: () => onSendConfirm(),
            title: 'Confirmation',
            visible: true,
        });
    };

    const onSendExpire = () => {
        manualManagementVerifyActionSet(dispatch, VERIFY_ACTION.EXPIRED);
        setConfirmationModal({
            content: 'Are you sure?',
            onCancel: () => setConfirmationModal({ visible: false }),
            onConfirm: () => onSendConfirm(),
            title: 'Confirmation',
            visible: true,
        });
    };

    const onSendConfirm = () => {
        //use for when operator want to click complete or expire the progress
        handleCompletedReward();
    };

    const formValidation = () => {
        const bonusAmountTypeValidation = () => {
            const number = parseInt(bonus.bonusAmount);
            if (Number.isInteger(number)) {
                return true;
            }
            message.error(getTranslation('Please put number in Bonus Amount box'));
            return false;
        };

        const bonusAmountValidation = () => {
            if (parseInt(bonus.bonusAmount) >= 0) {
                return true;
            } else {
                message.error(getTranslation('Bonus Amount cannot less than 0'));
                return false;
            }
        };

        return bonusAmountTypeValidation() && bonusAmountValidation();
    };

    const getTransactionCodeTitle = (actionSearchBy) => {
        if (
            actionSearchBy === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.BALANCE_ADDITION ||
            actionSearchBy === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.CRYPTOCURRENCY_CORRECTION ||
            actionSearchBy === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.DEPOSIT_CORRECTION
        ) {
            return 'Transaction Code';
        }
        return 'Deposit Transaction Code';
    };

    // ============================================
    // HOOKS
    // ============================================

    const [
        verifying,
        verified,
        handleVerifyReward,
    ] = useVerifyManualManagementRecordAsyncEndpoint();

    const [
        completing,
        completed,
        handleCompletedReward,
    ] = useCompletedOrExpiredManualManagementRecordAsyncEndpoint();

    const [updating, updated, handleUpdateReward] = useUpdateManualManagementRecordAsyncEndpoint();

    const [calculating] = useGetCalculatedTurnoverAmountOperationAsyncEndpoint(isDisabledEdit);
    const [amountLoading] = useGetDepositAmountAsyncEndpoint();

    useEffect(() => {
        if (visible) {
            setButtonDisable(false);
            setFieldsValue({
                action: getStateProp(bonus, 'action'),
                bonusAmount: getStateProp(bonus, 'bonusAmount'),
                bonusName: getStateProp(bonus, 'bonusName'),
                currencyCode: getStateProp(bonus, 'currencyCode'),
                depositAmount: getStateProp(bonus, 'depositAmount'),
                depositTransactionNo: getStateProp(bonus, 'depositTransactionNo'),
                duration: getStateProp(bonus, 'duration'),
                memberLevel: getStateProp(bonus, 'memberLevel'),
                remarks: getStateProp(bonus, 'remarks'),
                turnoverAmount: getStateProp(bonus, 'turnoverAmount'),
                turnoverRequirement: getStateProp(bonus, 'turnoverCondition'),
                userName: getStateProp(bonus, 'userName'),
            });
        }
    }, [bonus, setFieldsValue, visible]);

    useEffect(() => {
        if (updated) {
            handleGetManualManagementRecords();
            setConfirmationModal({ visible: false });
            setButtonDisable(true);
        }
    }, [handleGetManualManagementRecords, updated]);

    useEffect(() => {
        if (verified) {
            handleGetManualManagementRecords();
        }
    }, [handleGetManualManagementRecords, verified]);

    useEffect(() => {
        if (completed) {
            handleGetManualManagementRecords();
            setConfirmationModal({ visible: false });
            setButtonDisable(true);
        }
    }, [handleGetManualManagementRecords, completed]);

    // ============================================
    // RENDER
    // ============================================

    const OperationButtons = () => {
        if (approvalStatus === VERIFY_STATUSES.PENDING) {
            return !isBatchUpload && !applicantName ? (
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        label={getTranslation('Update')}
                        key="update"
                        loading={updating}
                        onClick={handleUpdateBonus}
                        disabled={buttonDisable}
                    />
                    <ButtonRed
                        key="reject"
                        label={getTranslation('Reject')}
                        loading={verifying}
                        onClick={onSendReject}
                        disabled={buttonDisable}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>
            ) : !isBatchUpload && applicantName !== userName ? (
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        label={getTranslation('Approve')}
                        key="approve"
                        loading={verifying}
                        onClick={onSendAgree}
                        disabled={buttonDisable}
                    />
                    <ButtonRed
                        key="reject"
                        label={getTranslation('Reject')}
                        loading={verifying}
                        onClick={onSendReject}
                        disabled={buttonDisable}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>
            ) : (
                <Row key="actions" justify="end" align="middle">
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>
            );
        } else if (
            approvalStatus === VERIFY_STATUSES.APPROVED &&
            progressStatus === PROGRESS_STATUS.RUNNING
        ) {
            return (
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        label={getTranslation('Completed')}
                        key="approve"
                        loading={verifying}
                        onClick={onSendAgree}
                        disabled={buttonDisable}
                    />
                    <ButtonRed
                        key="reject"
                        label={getTranslation('Reject')}
                        loading={verifying}
                        onClick={onSendExpire}
                        disabled={buttonDisable}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>
            );
        } else if (progressStatus === PROGRESS_STATUS.RUNNING) {
            return (
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        key="completed"
                        label={getTranslation('Completed')}
                        loading={completing}
                        onClick={onSendComplete}
                        disabled={buttonDisable}
                    />
                    <ButtonRed
                        key="expired"
                        label={getTranslation('Expired')}
                        loading={completing}
                        onClick={onSendExpire}
                        disabled={buttonDisable}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>
            );
        } else {
            return (
                <Row key="actions" justify="end" align="middle">
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>
            );
        }
    };

    return (
        <Modal
            visible={visible}
            title={getTranslation('Bonus Check')}
            width={1000}
            centered
            closable={false}
            onCancel={onCancel}
            footer={<OperationButtons />}
            getContainer={false} // solve the useForm not connected warning
        >
            <Form form={form} name="manual-management-bonus" className="request-bonus-form">
                <Row className="request-bonus__search-selection">
                    <Col span={24}>
                        <Row className="form__group">
                            <Col span={5}>
                                <label>{getTranslation('Search By')}</label>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="action">
                                    <Select
                                        allowClear
                                        onChange={(value) => setInfo('action', value)}
                                        disabled={isDisabledEdit}
                                    >
                                        {_.map(REQUEST_SEARCH_PLAYER_INFO_ACTIONS, (action) => (
                                            <Select.Option value={action} key={action}>
                                                {getTranslation(action).toLocaleUpperCase()}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col span={12} className="bonus-request__form-column">
                        <Row className="form__group">
                            <Col span={10}>
                                <label className="form__label">
                                    {getTranslation('Username')}
                                    <span className="form__required">*</span>
                                </label>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="userName"
                                    rules={[
                                        {
                                            message: getTranslation('Please input username!'),
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="form__group">
                            <Col span={10}>
                                <label className="form__label">
                                    {getTranslation('Member Level')}
                                </label>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="memberLevel">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="form__group">
                            <Col span={10}>
                                <label className="form__label">
                                    {getTranslation(getTransactionCodeTitle(actionSearchBy))}
                                </label>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="depositTransactionNo">
                                    <Input
                                        placeholder={getTranslation(
                                            'Search for Transaction Number',
                                        )}
                                        onChange={(e) =>
                                            setInfo('depositTransactionNo', e.target.value)
                                        }
                                        disabled={isDisabled}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="form__group">
                            <Col span={10}>
                                <label className="form__label">
                                    {getTranslation('Deposited Amount')}
                                </label>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="depositAmount">
                                    <Input
                                        disabled
                                        prefix={
                                            amountLoading && (
                                                <Loader size="small" style={{ padding: 0 }} />
                                            )
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="form__group">
                            <Col span={10}>
                                <label className="form__label">
                                    {getTranslation('Duration (days)')}
                                    <span className="form__required">*</span>
                                </label>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="duration"
                                    rules={[
                                        {
                                            message: getTranslation(
                                                'Please input duration (days)!',
                                            ),
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="form__group">
                            <Col span={10}>
                                <label className="form__label">
                                    {getTranslation('Bonus Name')}
                                    <span className="form__required">*</span>
                                </label>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="bonusName"
                                    rules={[
                                        {
                                            message: getTranslation('Please input bonus name!'),
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12} className="bonus-request__form-column">
                        <Row className="form__group">
                            <Col span={10}>
                                <label className="form__label">
                                    {getTranslation('Bonus Amount')}
                                    <span className="form__required">*</span>
                                </label>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="bonusAmount"
                                    rules={[
                                        {
                                            message: getTranslation('Please input bonus amount!'),
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={(e) => setInfo('bonusAmount', e.target.value)}
                                        disabled={isDisabledEdit}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="form__group">
                            <Col span={10}>
                                <label className="form__label">
                                    {getTranslation('Turnover Requirement')}
                                    <span className="form__required">*</span>
                                </label>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="turnoverRequirement">
                                    <Input
                                        disabled={isDisabledEdit}
                                        onChange={(e) =>
                                            setInfo('turnoverCondition', e.target.value)
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="form__group">
                            <Col span={10}>
                                <label className="form__label">
                                    {getTranslation('Turnover Amount')}
                                </label>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="turnoverAmount">
                                    <Input
                                        disabled
                                        prefix={
                                            calculating && (
                                                <Loader size="small" style={{ padding: 0 }} />
                                            )
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="form__group">
                            <Col span={10}>
                                <label className="form__label">{getTranslation('Currency')}</label>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="currencyCode">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="form__group">
                            <Col span={10}>
                                <label className="form__label">
                                    {getTranslation('Expired Date')}
                                </label>
                            </Col>
                            <Col span={12}>
                                <span>
                                    {RenderDateFormat(
                                        getStateProp(bonus, 'expiredDateWritable'),
                                        true,
                                    )}
                                </span>
                            </Col>
                        </Row>
                        <Row className="form__group">
                            <Col span={10}>
                                <label className="form__label">
                                    {getTranslation('Remark')}
                                    <span className="form__required">*</span>
                                </label>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="remarks"
                                    rules={[
                                        {
                                            max: 128,
                                            message: getTranslation(
                                                'Remark must less than 128 words!',
                                            ),
                                        },
                                    ]}
                                >
                                    <TextArea
                                        onChange={(e) => setInfo('remarks', e.target.value)}
                                        disabled={operatorName !== null || isBatchUpload}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>

            <ConfirmModal
                visible={confirmationModal.visible}
                onConfirm={confirmationModal.onConfirm}
                onCancel={confirmationModal.onCancel}
                title={confirmationModal.title}
                content={confirmationModal.content}
                loading={completing || updating}
            />
        </Modal>
    );
};

export default VerifyModal;
