import React from 'react';

import { Button } from 'antd';
import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../riskMonitorActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';

export const getColumns = (
    setTotalRegistrationModalVisible,
    setBonusCodeRedemptionModalVisible,
    handleGetBonusRedeem,
    handleGetBonusRegistration,
) => {
    const columns = [
        {
            dataIndex: 'no',
            render: (value, item, index) => <span>{index + 1}</span>,
            title: 'No',
        },
        {
            dataIndex: 'ipAddress',
            title: getTranslation('Ip Address'),
        },
        {
            dataIndex: 'lastActivatedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Last Activation Date'),
        },
        {
            dataIndex: 'totalRegistrationLogin',
            render: (value, item) => (
                <Button
                    className={value <= 0 && 'risk-monitor__btn--0'}
                    onClick={() => {
                        if (value > 0) {
                            setTotalRegistrationModalVisible(true);
                            handleGetBonusRegistration(item.ipAddress);
                        }
                    }}
                    type="link"
                >
                    {value}
                </Button>
            ),
            title: getTranslation('Total Registration/ login'),
        },
        {
            dataIndex: 'totalBonus',
            title: getTranslation('Total Bonus Claimed'),
        },
        {
            dataIndex: 'totalBonusRedeemed',
            render: (value, item) => (
                <Button
                    className={value <= 0 && 'risk-monitor__btn--0'}
                    onClick={() => {
                        if (value > 0) {
                            setBonusCodeRedemptionModalVisible(true);
                            handleGetBonusRedeem(item.ipAddress);
                        }
                    }}
                    type="link"
                >
                    {value}
                </Button>
            ),
            title: getTranslation('Bonus Code Redemption'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
