import React from 'react';

import { Modal } from 'antd';

import { getTranslation } from '../../../../../common/helpers/locale';
import EditBonusDescription from './EditBonusDesciption';

const EditBonusDescriptionModalContent = ({
    visible,
    onCancel,
    getBannerByIdLoading,
    viewOnly,
}) => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            width={1080}
            visible={visible}
            title={
                viewOnly
                    ? getTranslation('Bonus Description')
                    : getTranslation('Edit Bonus Description')
            }
            className="bonus__edit-modal"
            onCancel={onCancel}
            closable={false}
            footer={null}
        >
            <EditBonusDescription
                onCancel={onCancel}
                getBannerByIdLoading={getBannerByIdLoading}
                viewOnly={viewOnly}
            />
        </Modal>
    );
};

export default EditBonusDescriptionModalContent;
