import React from 'react';

import { Modal } from 'antd';
import { useGetPlayerWinLossDetailAsyncEndpoint } from '../../../../services/companyReportService';
import PlayerWinLossModalContent from './PlayerWinLossModalContent';

const PlayerWinLossModal = ({ visible, onCancel }) => {
    // ============================================
    // HOOKS
    // ============================================

    const [loading] = useGetPlayerWinLossDetailAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            centered
            closable={false}
            footer={null}
            onCancel={onCancel}
            width={1300}
        >
            <PlayerWinLossModalContent loading={loading} />
        </Modal>
    );
};

export default PlayerWinLossModal;
