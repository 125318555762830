export const ACTIONS = {
    BATCH_PLAYER_SEARCH: 'BATCH_PLAYER_SEARCH',
    BATCH_PLAYER_SEARCH_FAILURE: 'BATCH_PLAYER_SEARCH_FAILURE',
    BATCH_PLAYER_SEARCH_SUCCESS: 'BATCH_PLAYER_SEARCH_SUCCESS',
};

export const batchPlayerSearch = (dispatch) =>
    dispatch({
        type: ACTIONS.BATCH_PLAYER_SEARCH,
    });

export const batchPlayerSearchSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.BATCH_PLAYER_SEARCH_SUCCESS,
    });

export const batchPlayerSearchFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BATCH_PLAYER_SEARCH_FAILURE,
    });
