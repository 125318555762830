import React from 'react';

import { Link } from 'react-router-dom';
import { Row } from 'antd';
import _ from 'lodash';

import { MODIFICATION_TYPES, PLAYER_STATUSES } from '../../constants';
import { ROUTES } from '../../../../common/constants/routing';
import { RenderDateFormat } from '../../../../common/components/RenderDateFormat';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../PlayersListActions';
import { getPlayerStatus, getPlayerStatusColor } from '../../helpers';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';
import BalanceForm from './popovers/BalanceForm';
import EditPopover from './popovers/EditPopover';
import FullNameForm from './popovers/FullNameForm';
import GameAccessIcon from '../modals/game-access/GameAccessIcon';
import LevelForm from './popovers/LevelForm';
import MobileNumberForm from './popovers/MobileNumberForm';
import OperationsButton from '../operations/OperationsButton';
import RemarksIcon from '../modals/remarks/RemarksIcon';
import StatusIcon from '../modals/status/StatusIcon';

export const getColumns = (dispatch, selectedColumns, userName) => {
    const renderValue = (value, record) =>
        record.status === PLAYER_STATUSES.CLOSED ? (
            <span style={{ color: '#FF3F3F' }}>{value}</span>
        ) : (
            value
        );

    const renderLevelValue = (value, record, fieldName) => (
        <Row justify="space-between">
            {record.status === PLAYER_STATUSES.CLOSED ? (
                <span style={{ color: '#FF3F3F' }}>{value}</span>
            ) : (
                <span>{value}</span>
            )}
            <EditPopover
                userId={record.id}
                userName={record.userName}
                title={fieldName}
                value={value}
            >
                <LevelForm
                    userId={record.id}
                    userName={record.userName}
                    fieldName={fieldName}
                    defaultValue={value}
                />
            </EditPopover>
        </Row>
    );

    const renderFullNameValue = (value, record) => (
        <Row justify="space-between">
            {record.status === PLAYER_STATUSES.CLOSED ? (
                <span style={{ color: '#FF3F3F' }}>{value}</span>
            ) : (
                <span>{value}</span>
            )}
            <EditPopover userId={record.id} userName={record.userName} value={value}>
                <FullNameForm userId={record.id} userName={record.userName} defaultValue={value} />
            </EditPopover>
        </Row>
    );

    const renderMobileNumberValue = (value, record) => (
        <Row justify="space-between">
            {record.status === PLAYER_STATUSES.CLOSED ? (
                <span style={{ color: '#FF3F3F' }}>{value}</span>
            ) : (
                <span>{value}</span>
            )}

            <EditPopover userId={record.id} userName={record.userName} value={value}>
                <MobileNumberForm
                    userId={record.id}
                    userName={record.userName}
                    defaultValue={value}
                />
            </EditPopover>
        </Row>
    );

    const renderBalanceValue = (value, record) => (
        <Row justify="space-between">
            {record.status === PLAYER_STATUSES.CLOSED ? (
                <span style={{ color: '#FF3F3F' }}>{value}</span>
            ) : (
                <span>{value}</span>
            )}
            <EditPopover userId={record.id} userName={record.userName}>
                <BalanceForm
                    userId={record.id}
                    userName={record.userName}
                    currencyCode={record.currencyCode}
                />
            </EditPopover>
        </Row>
    );

    const getPlayerDetailsRedirectLink = () => {
        return window.location.href.lastIndexOf('/isMasked') === -1
            ? ROUTES.DASHBOARD.PLAYERS.PLAYERS_LIST
            : ROUTES.DASHBOARD.PLAYERS.PLAYERS_LIST_MASKED;
    };

    const columns = [
        {
            dataIndex: 'userName',
            render: (value, record) => (
                <Link
                    to={`${getPlayerDetailsRedirectLink()}/${record.id}`}
                    className={
                        record.status === PLAYER_STATUSES.CLOSED
                            ? 'username-column--closed'
                            : 'username-column'
                    }
                >
                    {value}
                </Link>
            ),
            title: getTranslation('Username'),
        },
        {
            dataIndex: 'realName',
            render: renderFullNameValue,
            title: getTranslation('Full Name'),
        },
        {
            dataIndex: 'autoId',
            render: renderValue,
            title: getTranslation('Auto ID'),
        },
        {
            dataIndex: 'shortId',
            render: renderValue,
            title: getTranslation('Short ID'),
        },
        {
            dataIndex: 'memberLevel',
            render: (value, record) =>
                renderLevelValue(value, record, MODIFICATION_TYPES.MEMBER_LEVEL),
            title: getTranslation('Member Level'),
        },
        {
            dataIndex: 'trustLevel',
            render: (value, record) =>
                renderLevelValue(value, record, MODIFICATION_TYPES.TRUST_LEVEL),
            title: getTranslation('Trust Level'),
        },
        {
            dataIndex: 'dangerLevel',
            render: (value, record) =>
                renderLevelValue(value, record, MODIFICATION_TYPES.DANGER_LEVEL),
            title: getTranslation('Danger Level'),
        },
        {
            dataIndex: 'qualityLevel',
            render: (value, record) =>
                renderLevelValue(value, record, MODIFICATION_TYPES.QUALITY_LEVEL),
            title: getTranslation('Quality Level'),
        },
        {
            dataIndex: 'referrerUserName',
            render: renderValue,
            title: getTranslation('Referral'),
        },
        {
            dataIndex: 'affiliateUpline',
            render: renderValue,
            title: getTranslation('Affiliate Upline'),
        },
        {
            dataIndex: 'isAffiliateApplied',
            dataType: 'boolean',
            render: (value) => <span>{value ? getTranslation('Yes') : getTranslation('No')}</span>,
            title: getTranslation('Is Affiliate'),
        },
        {
            dataIndex: 'affiliateStatus',
            render: renderValue,
            title: getTranslation('Affiliate Status'),
        },
        {
            dataIndex: 'currencyCode',
            render: renderValue,
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'mobileNo',
            dataType: 'mobilePhone',
            render: renderMobileNumberValue,
            title: getTranslation('Mobile Number'),
        },
        {
            dataIndex: 'totalBalance',
            render: renderBalanceValue,
            title: getTranslation('Total Balance'),
        },
        {
            dataIndex: 'createdAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Register Date'),
        },
        {
            dataIndex: 'activatedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Last Login Date'),
        },
        {
            dataIndex: 'createdByName',
            title: getTranslation('Created By'),
        },
        {
            dataIndex: 'remarks',
            render: (value, record) => <RemarksIcon dispatch={dispatch} player={record} />,
            title: getTranslation('Remark'),
        },
        {
            dataIndex: 'gameAccess',
            render: (value, record) => <GameAccessIcon dispatch={dispatch} player={record} />,
            title: getTranslation('Game Access'),
        },
        {
            dataIndex: 'status',
            render: (value, record) => (
                <Row justify="space-between">
                    <span style={{ color: getPlayerStatusColor(value) }}>
                        {getTranslation(getPlayerStatus(value))}
                    </span>
                    <StatusIcon dispatch={dispatch} player={record} />
                </Row>
            ),
            title: getTranslation('Status'),
        },
        {
            dataIndex: 'operations',
            dataType: 'operation',
            render: (value, record) => <OperationsButton dispatch={dispatch} player={record} />,
            title: getTranslation('Operations'),
        },
    ];

    return columns
        .filter((column) => _.map(selectedColumns, 'key').includes(column.dataIndex))
        .map((colProps) => ({
            ...colProps,
            sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
        }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
