import React from 'react';

import { Checkbox, Col, Form, Input, Row } from 'antd';
import _ from 'lodash';

import './BonusFormInputs.less';
import { CURRENCIES } from '../../../common/constants/localization';
import { ImgIcon } from '../../../common/components';
import { getStateProp, isSupportedLanguage } from '../../../common/helpers/misc';
import { getSupportedCurrenciesByCountry, getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';
import ImageEditor from '../../../common/components/ImageEditor';
import RangeDatePicker from '../../../common/components/RangeDatePicker';

const { TextArea } = Input;

const BannerFormInputs = ({
    onBannerInfoChange,
    onContentChange,
    langCode,
    bannerContent,
    bannerInfo,
    isEdit,
}) => {
    const [state] = useStore();
    const location = getStateProp(state, 'timezone.location');
    const supportedCurrency = _.map(
        getSupportedCurrenciesByCountry(),
        (currency) => currency.currency,
    );

    const onPeriodChange = ({ dateFrom, dateTo }) => {
        onBannerInfoChange('startDate', dateFrom);
        onBannerInfoChange('endDate', dateTo);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <>
            <Row className="bonus__form-row">
                <Form.Item>
                    {!isSupportedLanguage(langCode) ? (
                        <ImgIcon
                            icon={bannerContent.imageUrl}
                            containerClassName="image-container"
                            style={{ maxWidth: '100%' }}
                        />
                    ) : (
                        <ImageEditor
                            onImageChanged={(imageInBase64) =>
                                onContentChange('imageInBase64', imageInBase64, langCode)
                            }
                            image={bannerContent.imageInBase64 || bannerContent.imageUrl}
                        />
                    )}
                </Form.Item>
            </Row>

            <Row className="bonus__form-row">
                <Col span={8}>
                    <label>{getTranslation('Currency')}</label>
                </Col>
                <Col span={16}>
                    <Form.Item>
                        <Checkbox.Group
                            style={{ width: '100%' }}
                            onChange={(value) => onBannerInfoChange('currencyCode', value)}
                            value={
                                isEdit
                                    ? _.split(bannerInfo.currencyCode, ',')
                                    : bannerInfo.currencyCode
                            }
                        >
                            <Row style={{ flexFlow: 'row wrap' }}>
                                {_.map(CURRENCIES, (currency) => (
                                    <Col span={8} key={currency.currency}>
                                        <Checkbox
                                            value={currency.currency}
                                            disabled={
                                                !_.includes(supportedCurrency, currency.currency)
                                            }
                                        >
                                            {currency.currency}
                                        </Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row className="bonus__form-row">
                <Col span={8}>
                    <label>{getTranslation('Banner Code')}</label>
                </Col>
                <Col span={16}>
                    <Form.Item>
                        <Input
                            onChange={(e) => onBannerInfoChange('code', e.target.value)}
                            value={bannerInfo.code}
                            disabled={isEdit}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row className="bonus__form-row">
                <Col span={8}>
                    <label>{getTranslation('Banner Name')}</label>
                </Col>
                <Col span={16}>
                    <Form.Item>
                        <Input
                            onChange={(e) =>
                                onContentChange('bannerName', e.target.value, langCode)
                            }
                            value={bannerContent.bannerName}
                            disabled={!isSupportedLanguage(langCode)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="bonus__form-row">
                <Col span={8}>
                    <label>{getTranslation('Banner URL')}</label>
                </Col>
                <Col span={16}>
                    <Form.Item>
                        <Input
                            onChange={(e) => onContentChange('bannerUrl', e.target.value, langCode)}
                            value={bannerContent.bannerUrl}
                            disabled={!isSupportedLanguage(langCode)}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row className="bonus__form-row">
                <Col span={8}>
                    <label>{getTranslation('Remark')}</label>
                </Col>
                <Col span={16}>
                    <Form.Item>
                        <TextArea
                            onChange={(e) => onContentChange('remark', e.target.value, langCode)}
                            value={bannerContent.remark}
                            disabled={!isSupportedLanguage(langCode)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="bonus__form-row">
                <Col span={8}>
                    <label>{getTranslation('Valid Dates')}</label>
                </Col>
                <Col span={16}>
                    <RangeDatePicker
                        onPeriodChange={onPeriodChange}
                        location={location}
                        showTime={false}
                        dateFrom={bannerInfo.startDate}
                        dateTo={bannerInfo.endDate}
                    />
                </Col>
            </Row>
        </>
    );
};

export default BannerFormInputs;
