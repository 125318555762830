import React from 'react';

import { Modal, Row } from 'antd';

import './VerifyModal.less';
import { ButtonGray, ButtonGreen } from '../../../../common/components/buttons/Buttons';
import { PAYMENT_ORDER_OPERATIONS } from '../../../../common/constants/misc';
import {
    closeConfirmModal,
    closeVerifyModal,
    openConfirmModal,
    performMerchantDepositOperation,
} from '../../merchants/merchantActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useStore } from '../../../../store/StateProvider';
import ConfirmModal from '../../merchants/verification/ConfirmModal';

const VerifyModal = ({ onCancel, visible, transaction, operationPending }) => {
    const [state, dispatch] = useStore();
    const confirmModalVisible = getStateProp(
        state,
        'payments.merchantDeposits.confirmModalVisible',
        {},
    );

    const { playerName, providerName } = transaction;

    // ============================================
    // METHODS
    // ============================================
    const showConfirmModal = () => openConfirmModal(dispatch);
    const closeModal = () => closeConfirmModal(dispatch);

    const verifyTransaction = () => {
        performMerchantDepositOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.COMPLETE,
            transaction,
        });
        closeConfirmModal(dispatch);
        closeVerifyModal(dispatch);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            title={getTranslation('Deposit Verification')}
            centered
            closable={false}
            className="deposit__verification-modal"
            onCancel={onCancel}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        label={getTranslation('Approve')}
                        loading={operationPending}
                        onClick={showConfirmModal}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
            destroyOnClose={true}
        >
            <Row justify="start" className="deposit__name-modal">
                <p className="modal-description">{getTranslation('Depositor Name')}</p> :
                <p className="deposit-record">{playerName}</p>
            </Row>

            <Row justify="start" className="deposit__transaction-modal">
                <p className="modal-description">{getTranslation('Transaction Method')}</p> :
                <p className="deposit-record">{providerName}</p>
            </Row>
            <ConfirmModal
                visible={confirmModalVisible}
                onCancel={closeModal}
                onConfirm={verifyTransaction}
            />
        </Modal>
    );
};

export default VerifyModal;
