import React from 'react';

import { Form, Input, Modal, Row } from 'antd';

import './VerifyModal.less';
import { ButtonGray, ButtonGreen, ButtonRed } from '../../../common/components/buttons/Buttons';
import { PAYMENT_ORDER_OPERATIONS } from '../../../common/constants/misc';
import { TRANSACTION_MONITORING_TYPES } from '../misc';
import {
    closeVerifyModal,
    performTransactionMonitoringOperation,
    setTransactionMonitoringVerifyInfo,
} from '../transactionMonitoringActions';
import { getTranslation } from '../../../common/helpers/locale';
import { useDispatch } from '../../../store/StateProvider';

const { TextArea } = Input;

const VerifyModal = ({ visible, transaction, onCancel, loading }) => {
    const dispatch = useDispatch();

    const { playerUserName, amount, transactionDetails, transactionType } = transaction;

    // ============================================
    // METHODS
    // ============================================

    const onValueChange = (verifyInfo, value) =>
        setTransactionMonitoringVerifyInfo(dispatch, { value, verifyInfo });

    const verifyWithdrawal = () => {
        performTransactionMonitoringOperation(dispatch, {
            action:
                transactionType === TRANSACTION_MONITORING_TYPES.WITHDRAWAL
                    ? PAYMENT_ORDER_OPERATIONS.VERIFY
                    : PAYMENT_ORDER_OPERATIONS.COMPLETE,
        });
        closeVerifyModal(dispatch);
    };

    const rejectWithdrawal = () => {
        performTransactionMonitoringOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.REJECT,
        });
        closeVerifyModal(dispatch);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            title={
                transactionDetails
                    ? getTranslation('Balance Management')
                    : getTranslation('Withdrawal Check')
            }
            centered
            visible={visible}
            closable={false}
            className="transaction-monitoring__verification-modal"
            width="750px"
            onCancel={onCancel}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        form="verify-transaction"
                        label={getTranslation('Approve')}
                        loading={loading}
                        htmlType="submit"
                    />
                    <ButtonRed
                        label={getTranslation('Reject')}
                        onClick={rejectWithdrawal}
                        loading={loading}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
        >
            <Form name="verify-transaction" onFinish={verifyWithdrawal}>
                <Row justify="start">
                    <span className="modal-description">{getTranslation('Username')}</span>:
                    <span className="transaction-monitoring-record">{playerUserName}</span>
                </Row>
                {transactionDetails && (
                    <Row justify="start">
                        <span className="modal-description">{getTranslation('Detail')}</span>:
                        <span className="transaction-monitoring-record">{transactionDetails}</span>
                    </Row>
                )}
                <Row justify="start">
                    <span className="modal-description">{getTranslation('Amount')}</span>:
                    <span className="transaction-monitoring-record">{amount}</span>
                </Row>
                <Row justify="start">
                    <span className="modal-description">{getTranslation('Remarks')}</span>
                    <span className="transaction-monitoring-record">
                        <Form.Item name="selectedCard">
                            <TextArea onChange={(e) => onValueChange('remarks', e.target.value)} />
                        </Form.Item>
                    </span>
                </Row>
            </Form>
        </Modal>
    );
};

export default VerifyModal;
