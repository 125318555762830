import React from 'react';

import { Form } from 'antd';

import { API } from '../../../../common/constants/api';
import {
    announcementFilterDrop,
    closeAnnouncementDeleteModal,
    openAnnouncementDeleteModal,
    setAnnouncementDeselected,
} from './globalAnnouncementActions';
import { announcementTitleExport, getColumns } from './GlobalAnnouncementTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useExportAsyncEndpoint } from '../../../../services/hooks/useExport';
import { useGetGlobalAnnouncementHistoryAsyncEndpoint } from '../../../../services/announcementService';
import { useStore } from '../../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import DeleteAnnouncementModal from './modal/DeleteAnnouncementModal';
import GlobalAnnouncementFilters from './GlobalAnnouncementFilters';
import GlobalAnnouncementResultTable from './GlobalAnnouncementResultTable';
import ViewAnnouncementModal from './modal/ViewAnnouncementModal';

const { ANNOUNCEMENT } = API;

const GlobalAnnouncement = () => {
    const [state, dispatch] = useStore();
    const globalAnnouncement = getStateProp(
        state,
        'announcement.announcementHistory.globalAnnouncement',
    );
    const deleteModalVisible = getStateProp(globalAnnouncement, 'deleteModalVisible');
    const viewModalVisible = getStateProp(globalAnnouncement, 'viewModalVisible');
    const selectedAnnouncement = getStateProp(globalAnnouncement, 'selectedAnnouncement', {});
    const filters = getStateProp(globalAnnouncement, 'filters');
    const { totalRecordCount } = getStateProp(globalAnnouncement, 'paging', {});

    // ============================================
    // METHODS
    // ============================================

    const onCloseDeleteModal = () => closeAnnouncementDeleteModal(dispatch);

    const fetchAll = () => {
        announcementFilterDrop(dispatch);
        handleGetGlobalAnnouncementHistory();
    };

    const closeViewModal = () => setAnnouncementDeselected(dispatch);

    const onOpenDeleteModal = () => {
        openAnnouncementDeleteModal(dispatch, { announcementDelete: selectedAnnouncement });
    };

    // ============================================
    // HOOKS
    // ============================================

    const [
        loading,
        handleGetGlobalAnnouncementHistory,
    ] = useGetGlobalAnnouncementHistoryAsyncEndpoint();

    const [handleExportAction] = useExportAsyncEndpoint({
        additionalCol: {
            title: {
                function: announcementTitleExport,
            },
        },
        dataMeta: getColumns(),
        filters,
        mandatoryFilter: { IsHistory: true },
        method: 'GET',
        subject: getTranslation('Announcement History'),
        totalRecordCount,
        url: `${ANNOUNCEMENT.ANNOUNCEMENT_LIST}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <>
            <Form onFinish={handleGetGlobalAnnouncementHistory}>
                <GlobalAnnouncementFilters />
                <ActionButtons onDropFilters={fetchAll} onExport={handleExportAction} />
            </Form>

            <GlobalAnnouncementResultTable loading={loading} />

            <ViewAnnouncementModal
                visible={viewModalVisible}
                onCancel={closeViewModal}
                onOpenDeleteModal={onOpenDeleteModal}
            />

            <DeleteAnnouncementModal visible={deleteModalVisible} onCancel={onCloseDeleteModal} />
        </>
    );
};

export default GlobalAnnouncement;
