import { Redirect, Switch } from 'react-router-dom';
import React from 'react';

import { Overview, PlayerWalletBalance, PlayerWinLoss } from '.';
import { PrivateRoute } from '../../common/components';
import { ROUTES } from '../../common/constants/routing';

const Reports = () => {
    return (
        <Switch>
            <PrivateRoute
                exact
                component={PlayerWalletBalance}
                path={ROUTES.DASHBOARD.REPORTS.PLAYER_WALLET_BALANCE}
            />
            <PrivateRoute
                component={PlayerWinLoss}
                path={ROUTES.DASHBOARD.REPORTS.PLAYER_WIN_LOSS}
            />
            <PrivateRoute component={Overview} path={ROUTES.DASHBOARD.REPORTS.OVERVIEW} />
            <Redirect to={ROUTES.DASHBOARD.REPORTS.PLAYER_WALLET_BALANCE} />
        </Switch>
    );
};

export default Reports;
