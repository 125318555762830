import React from 'react';

import { Row, Select } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import { ButtonGreen } from '../../../common/components/buttons/Buttons';
import { TIMEZONE } from '../../../common/constants/localization';
import { currencySettingFilterSet } from './currencySettingActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';

const CurrencySettingFilters = ({ setModalVisible, filterListLoading }) => {
    const [state, dispatch] = useStore();
    const effectiveDateList = getStateProp(
        state,
        'administration.currencySetting.effectiveDates',
        [],
    );
    const { effectiveDate } = getStateProp(state, 'administration.currencySetting.filters', {});
    const defaultDate = '0001-01-01 00:00:00';

    // ============================================
    // METHODS
    // ============================================

    const onHandleFilterChange = (value) =>
        currencySettingFilterSet(dispatch, { filter: 'effectiveDate', value });

    // ============================================
    // HOOKS
    // ============================================

    // ============================================
    // RENDER
    // ============================================
    return (
        <Row justify="start" align="middle" className="filters-container">
            <Select
                className="filter"
                loading={filterListLoading}
                value={effectiveDate}
                onChange={onHandleFilterChange}
            >
                {_.map(effectiveDateList, (date) => (
                    <Select.Option value={date} key={date}>
                        {moment(date).format('YYYY-MM-DD HH:mm:ss') === defaultDate
                            ? getTranslation('Default')
                            : moment(moment(date).utcOffset(0, true))
                                  .utcOffset(TIMEZONE.MALAYSIA.OFFSET)
                                  .format('YYYY-MM-DD HH:mm:ss')}
                    </Select.Option>
                ))}
            </Select>

            <ButtonGreen
                label={getTranslation('Add New Set')}
                onClick={() => {
                    setModalVisible(true);
                }}
                loading={filterListLoading}
            />
        </Row>
    );
};

export default CurrencySettingFilters;
