import React from 'react';

import { Card, Form, Row, message } from 'antd';

import '../Referral.less';
import { ButtonGray, ButtonGreen } from '../../../common/components/buttons/Buttons';
import {
    changePageNumber,
    changePageSize,
    resetReferralProgram,
    setReferralInfo,
} from './createReferralActions';
import { formValidation } from '../misc';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useSetReferralProgramAsyncEndpoint } from '../../../services/referralService';
import { useStore } from '../../../store/StateProvider';
import ReferralDetail from '../ReferralDetail';

const CreateReferral = () => {
    const [state, dispatch] = useStore();
    const [form] = Form.useForm();

    const referralInfo = getStateProp(state, 'referral.createReferral.referralInfo', {});
    const paging = getStateProp(state, 'referral.createReferral.paging', {});

    // ============================================
    // METHODS
    // ============================================

    const confirmForm = () => {
        let validation = formValidation(referralInfo);
        if (validation.validate) {
            handleCreateReferralProgram();
        } else {
            message.error(validation.message);
        }
    };

    const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

    const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);

    // ============================================
    // HOOKS
    // ============================================
    const [loading, handleCreateReferralProgram, created] = useSetReferralProgramAsyncEndpoint(
        referralInfo,
    );

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Create Referral Program')}>
            <Form
                form={form}
                name="create-referral"
                className="create-referral__form"
                onFinish={confirmForm}
            >
                <ReferralDetail
                    referralInfo={referralInfo}
                    paging={paging}
                    setReferralInfo={setReferralInfo}
                    created={created}
                    onChangePageNo={onChangePageNo}
                    onChangePageSize={onChangePageSize}
                />
                <Row className="form__footer-row" justify="end">
                    <ButtonGreen
                        label="Submit"
                        className="form__submit-button"
                        loading={loading}
                        htmlType="submit"
                    />
                    <ButtonGray
                        label={getTranslation('Cancel')}
                        onClick={() => resetReferralProgram(dispatch)}
                    />
                </Row>
            </Form>
        </Card>
    );
};

export default CreateReferral;
