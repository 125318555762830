import { ACTIONS } from './hotEventsAction';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../common/constants/api';

export default function hotEventsReducer(
    hotEvents = {
        operation: {
            refreshTime: 15,
            refreshTimePause: false,
        },
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.HOT_EVENTS_LIST_GET_SUCCESS:
            return {
                ...hotEvents,
                ...payload,
            };

        case ACTIONS.HOT_EVENTS_RECORD_SELECT:
            return {
                ...hotEvents,
                selectedHotEvents: payload,
            };

        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...hotEvents,
                paging: {
                    ...hotEvents.paging,
                    pageNo:
                        payload > hotEvents.paging.pageCount
                            ? hotEvents.paging.pageCount - 1
                            : payload,
                },
            };

        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...hotEvents,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.REFRESH_TIME_SET:
            return {
                ...hotEvents,
                operation: {
                    refreshTime: payload.timeOut,
                    refreshTimePause: false,
                },
            };
        case ACTIONS.REFRESH_TIME_PAUSE:
            return {
                ...hotEvents,
                operation: {
                    refreshTimePause: true,
                },
            };
        case ACTIONS.OPERATION:
            return {
                ...hotEvents,
                operation: {
                    ...hotEvents.operation,
                    ...payload,
                },
            };
        case ACTIONS.OPERATION_SUCCESS:
        case ACTIONS.OPERATION_FAILURE:
            return {
                ...hotEvents,
                operation: {
                    ...hotEvents.operation,
                    action: null,
                },
                selectedHotEvents: [],
            };
        case ACTIONS.HOT_EVENTS_FILTER_SET:
            return {
                ...hotEvents,
                filters: {
                    ...hotEvents.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };
        case ACTIONS.HOT_EVENTS_DROP_FILTER:
            return {
                ...hotEvents,
                filters: {},
                resetPageNo: true,
            };
        case ACTIONS.HOT_EVENTS:
        case ACTIONS.HOT_EVENTS_LIST_GET:
        default:
            return hotEvents;
    }
}
