import { RenderDateFormat } from '../../../../common/components/RenderDateFormat';
import { TABLE_PAGE_SIZE_OPTIONS_1000 } from '../../../../common/constants/api';
import { changeSessionsPageNumber, changeSessionsPageSize } from '../userSessionsActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';

export const getColumns = () => {
    const columns = [
        {
            dataIndex: 'userName',
            title: getTranslation('Username'),
            width: 120,
        },
        {
            dataIndex: 'domain',
            title: getTranslation('Domain'),
            width: 150,
        },
        {
            dataIndex: 'channel',
            title: getTranslation('Register Channel'),
            width: 100,
        },
        {
            dataIndex: 'browser',
            title: getTranslation('Browser'),
        },
        {
            dataIndex: 'ipAddress',
            title: getTranslation('IP address'),
            width: 200,
        },
        {
            dataIndex: 'activatedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Last Activation Date'),
            width: 100,
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS_1000,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changeSessionsPageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changeSessionsPageSize(dispatch, pageSize);
