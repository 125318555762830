export const ACTIONS = {
    FILTER_DROP: 'DEACTIVATED_MERCHANT_FILTER_DROP',
    FILTER_SET: 'DEACTIVATED_MERCHANT_FILTER_SET',
    MERCHANT_GET: 'DEACTIVATED_MERCHANT_GET',
    MERCHANT_GET_FAILURE: 'DEACTIVATED_MERCHANT_GET_FAILURE',
    MERCHANT_GET_SUCCESS: 'DEACTIVATED_MERCHANT_GET_SUCCESS',
    MERCHANT_SETUP_MODAL_CLOSE: 'DEACTIVATED_MERCHANT_SETUP_MODAL_CLOSE',
    MERCHANT_SETUP_MODAL_OPEN: 'DEACTIVATED_MERCHANT_SETUP_MODAL_OPEN',
    MERCHANT_UPDATE: 'DEACTIVATED_MERCHANT_UPDATE',
    MERCHANT_UPDATE_FAILURE: 'DEACTIVATED_MERCHANT_UPDATE_FAILURE',
    MERCHANT_UPDATE_SUCCESS: 'DEACTIVATED_MERCHANT_UPDATE_SUCCESS',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'DEACTIVATED_MERCHANT_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'DEACTIVATED_MERCHANT_TABLE_PAGE_SIZE_CHANGE',
    },
};

export const deactivatedMerchantSetupModalOpen = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MERCHANT_SETUP_MODAL_OPEN,
    });

export const deactivatedMerchantSetupModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.MERCHANT_SETUP_MODAL_CLOSE,
    });

export const deactivatedMerchantGet = (dispatch) =>
    dispatch({
        type: ACTIONS.MERCHANT_GET,
    });

export const deactivatedMerchantGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MERCHANT_GET_SUCCESS,
    });

export const deactivatedMerchantGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MERCHANT_GET_FAILURE,
    });

export const merchantFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const merchantDropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTER_DROP,
    });

export const deactivatedMerchantUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.MERCHANT_UPDATE,
    });

export const deactivatedMerchantUpdateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.MERCHANT_UPDATE_SUCCESS,
    });

export const deactivatedMerchantUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MERCHANT_UPDATE_FAILURE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });
