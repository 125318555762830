import React from 'react';

import { Col, Input, Row } from 'antd';
import { REFERRAL_INFO_KEYS } from '../../../common/constants/misc';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../common/constants/api';
import { getIntOrFloat } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';

export const getReferralColumns = (
    currency,
    isBonus,
    isFixedPlan,
    detailsLength,
    onDetailsChange,
    isDisabled,
) => {
    const onValueChange = (selectedTier, key, value) =>
        onDetailsChange(currency, selectedTier, key, getIntOrFloat(value));

    const onValueWith2DecimalChange = (selectedTier, key, value) =>
        onDetailsChange(currency, selectedTier, key, getIntOrFloat(value, true));

    const renderReferralPlan = (value, record) =>
        record.tierLevel === detailsLength ? (
            <Row>
                <Col span={10}>
                    <Input
                        value={record.referralLevelFrom}
                        maxLength={9}
                        onChange={(e) =>
                            onValueChange(
                                record.tierLevel,
                                REFERRAL_INFO_KEYS.REFERRAL_LEVEL_FROM,
                                e.target.value,
                            )
                        }
                        disabled={isDisabled}
                    />
                </Col>
                <Col span={4} style={{ textAlign: 'center' }}>
                    <label>++</label>
                </Col>
            </Row>
        ) : (
            <Row>
                <Col span={10}>
                    <Input
                        value={record.referralLevelFrom}
                        maxLength={9}
                        onChange={(e) =>
                            onValueChange(
                                record.tierLevel,
                                REFERRAL_INFO_KEYS.REFERRAL_LEVEL_FROM,
                                e.target.value,
                            )
                        }
                        disabled={isDisabled}
                    />
                </Col>
                <Col span={4} style={{ textAlign: 'center' }}>
                    <label>{getTranslation('To')}</label>
                </Col>
                <Col span={10}>
                    <Input
                        value={record.referralLevelTo}
                        maxLength={9}
                        onChange={(e) =>
                            onValueChange(
                                record.tierLevel,
                                REFERRAL_INFO_KEYS.REFERRAL_LEVEL_TO,
                                e.target.value,
                            )
                        }
                        disabled={isDisabled}
                    />
                </Col>
            </Row>
        );

    const renderDetailsInput = (record, key) => {
        const value = record[key].toFixed(2);
        return (
            <Input
                justify="right"
                value={value}
                onChange={(e) => onValueWith2DecimalChange(record.tierLevel, key, e.target.value)}
                disabled={isDisabled}
            />
        );
    };

    let columns = [
        {
            dataIndex: 'tierLevel',
            key: 'tierLevel',
            title: getTranslation('Tier'),
        },
        {
            dataIndex: 'referralPlan',
            key: 'referralPlan',
            render: renderReferralPlan,
            title: getTranslation('Referral plan'),
        },
    ];

    if (isFixedPlan) {
        columns.push(
            {
                dataIndex: 'rewardAmount',
                key: 'rewardAmount',
                render: (value, record) =>
                    renderDetailsInput(record, REFERRAL_INFO_KEYS.REWARD_AMOUNT),
                title: getTranslation('Rewards'),
            },
            {
                dataIndex: 'rewardTurnoverRequired',
                key: 'rewardTurnoverRequired',
                render: (value, record) =>
                    renderDetailsInput(record, REFERRAL_INFO_KEYS.REWARD_TURNOVER_REQUIRED),
                title: getTranslation('Effective Turnover Required (Rewards)'),
            },
            {
                dataIndex: 'rewardTurnoverAmount',
                key: 'rewardTurnoverAmount',
                title: getTranslation('Total Effective Turnover (Rewards)'),
            },
        );
    }

    if (isBonus) {
        columns.push(
            {
                dataIndex: 'bonusAmount',
                key: 'bonusAmount',
                render: (value, record) =>
                    renderDetailsInput(record, REFERRAL_INFO_KEYS.BONUS_AMOUNT),
                title: getTranslation('Bonus'),
            },
            {
                dataIndex: 'bonusTurnoverRequired',
                key: 'bonusTurnoverRequired',
                render: (value, record) =>
                    renderDetailsInput(record, REFERRAL_INFO_KEYS.BONUS_TURNOVER_REQUIRED),
                title: getTranslation('Effective Turnover Required (Bonus)'),
            },
            {
                dataIndex: 'bonusTurnoverAmount',
                key: 'bonusTurnoverAmount',
                title: getTranslation('Total Effective Turnover (Bonus)'),
            },
        );
    }

    return columns;
};

export const getPaginationOptions = (paging, dispatch, onChangePageNo, onChangePageSize) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};
