import React from 'react';

import _ from 'lodash';

import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize, selectRecord } from '../rebateGeneratorActions';
import { getStatusColor, sortTableByDataType } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import VerifyButton from './VerifyButton';

export const getColumns = (dispatch) => {
    const getProductAmount = (product, record) => {
        const value = _.find(record.rebateTransactionDetail, (rebateDetail) => {
            return rebateDetail.product === product;
        });

        return value ? value.amount : '-';
    };

    const columns = [
        {
            dataIndex: 'createdAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Request Time'),
        },
        {
            dataIndex: 'transactionNo',
            title: getTranslation('Task Transaction No.'),
        },
        {
            dataIndex: 'playerUserName',
            title: getTranslation('Username'),
        },
        {
            dataIndex: 'currencyCode',
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'affiliate',
            title: getTranslation('Affiliate'),
        },
        {
            dataIndex: 'memberLevel',
            title: getTranslation('VIP Level'),
        },
        {
            dataIndex: 'bonusEntitlement',
            render: (value) => <span>{value ? getTranslation('Yes') : getTranslation('No')}</span>,
            title: getTranslation('Bonus Entitlement'),
        },
        {
            dataIndex: 'bonusAmount',
            title: getTranslation('Bonus Amount'),
        },
        {
            dataIndex: 'bonusETORequired',
            title: getTranslation('Bonus Turnover Amount'),
        },
        {
            dataIndex: 'bonusETOCompleted',
            title: getTranslation('ETO when Bonus ETO is completed'),
        },
        {
            dataIndex: 'bonusETOInccured',
            title: getTranslation('Total Effective Turnover'),
        },
        {
            dataIndex: 'rebateAmount',
            title: getTranslation('Rebate Amount'),
        },
        {
            children: [
                {
                    dataIndex: 'esport',
                    render: (value, record) => <span>{getProductAmount('ESPORT', record)}</span>,
                    title: getTranslation('Esport'),
                },

                {
                    dataIndex: 'sport',
                    render: (value, record) => <span>{getProductAmount('SPORT', record)}</span>,
                    title: getTranslation('Sport'),
                },

                {
                    dataIndex: 'liveCasino',
                    render: (value, record) => (
                        <span>{getProductAmount('LIVECASINO', record)}</span>
                    ),
                    title: getTranslation('Live Casino'),
                },

                {
                    dataIndex: 'lottery',
                    render: (value, record) => <span>{getProductAmount('LOTTERY', record)}</span>,
                    title: getTranslation('Lottery'),
                },

                {
                    dataIndex: 'numberGame',
                    render: (value, record) => (
                        <span>{getProductAmount('NUMBERGAME', record)}</span>
                    ),
                    title: getTranslation('Number Game'),
                },

                {
                    dataIndex: 'rng',
                    render: (value, record) => <span>{getProductAmount('RNG', record)}</span>,
                    title: getTranslation('RNG'),
                },

                {
                    dataIndex: 'virtualGame',
                    render: (value, record) => (
                        <span>{getProductAmount('VIRTUALGAME', record)}</span>
                    ),
                    title: getTranslation('Virtual Game'),
                },
                {
                    dataIndex: 'virtualSport',
                    render: (value, record) => (
                        <span>{getProductAmount('VIRTUALSPORT', record)}</span>
                    ),
                    title: getTranslation('Virtual Sport'),
                },
                {
                    dataIndex: 'miniGame',
                    render: (value, record) => <span>{getProductAmount('MINIGAME', record)}</span>,
                    title: getTranslation('Mini Game'),
                },
            ],
            title: getTranslation('Rebate amount per Product'),
        },
        {
            dataIndex: 'remark',
            title: getTranslation('Remark'),
        },
        {
            dataIndex: 'transactionStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>{getTranslation(value)}</span>
            ),
            title: getTranslation('Status'),
        },
        {
            dataIndex: 'creatorUserName',
            title: getTranslation('Applicant'),
        },
        {
            dataIndex: 'updaterUserName',
            title: getTranslation('Operator'),
        },
        {
            dataIndex: 'action',
            render: (value, record) => <VerifyButton rebate={record} dispatch={dispatch} />,
            title: getTranslation('Action'),
        },
    ];

    return columns.map((colProps) => {
        return colProps.children
            ? {
                  ...colProps,
                  children: [
                      ...colProps.children.map((children) => {
                          return {
                              ...children,
                              sorter: (r1, r2) =>
                                  sortTableByDataType(
                                      r1,
                                      r2,
                                      children.dataIndex,
                                      children.dataType,
                                  ),
                          };
                      }),
                  ],
              }
            : {
                  ...colProps,
                  sorter: (r1, r2) =>
                      sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
              };
    });
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);

const onSelectRecord = (dispatch) => (rowKeys) => selectRecord(dispatch, rowKeys);

export const getRowSelectionProps = (dispatch, selectedTransactionId) => ({
    columnWidth: 30,
    fixed: true,
    getCheckboxProps: (record) => ({
        disabled: record.transactionStatus === 'Success' || record.transactionStatus === 'Rejected',
    }),
    onChange: onSelectRecord(dispatch),
    selectedRowKeys: selectedTransactionId,
});
