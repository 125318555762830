import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { TRANSACTION_METHODS } from '../../constants/misc';
import { getTranslation } from '../../helpers/locale';

const TransactionMethodFilter = ({ value, onFilterChange }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('transactionMethods', value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            placeholder={getTranslation('Transaction Method')}
            className="filter"
            value={value}
            onChange={handleFilterChange}
        >
            {_.map(TRANSACTION_METHODS, (provider) => (
                <Select.Option key={provider} value={provider}>
                    {getTranslation(provider).toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
};

export default TransactionMethodFilter;
