import React, { useEffect } from 'react';

import { Card } from 'antd';

import { getEndTime, getStartTime, getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { setAnnouncementInit } from './announcementCreateActions';
import { useStore } from '../../../store/StateProvider';
import AnnouncementForm from './AnnouncementForm';

const AnnouncementCreate = () => {
    const [state, dispatch] = useStore();
    const location = getStateProp(state, 'timezone.location');

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        setAnnouncementInit(dispatch, {
            durationEnd: getEndTime(location),
            durationStart: getStartTime(location),
        });
    }, [dispatch, location]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Create Announcement')}>
            <AnnouncementForm />
        </Card>
    );
};

export default AnnouncementCreate;
