import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Input, Row } from 'antd';

import './MobileNumberForm.less';
import { ButtonGreen } from '../../../../../common/components/buttons/Buttons';
import { MODIFICATION_TYPES } from '../../../constants';
import { getTranslation } from '../../../../../common/helpers/locale';
import {
    useCreateModificationAsyncEndpoint,
    useVerifyPlayerMobileAsyncEndpoint,
} from '../../../../../services/playerService';
import Loader from '../../../../../common/components/Loader';

const MobileNumberForm = ({ userId, userName, defaultValue, onCancel }) => {
    const [form] = Form.useForm();
    const { resetFields, setFields } = form;
    const [isCurrentNumberVerified, setCurrentNumberVerified] = useState(false);
    const [isNewNumberVerified, setNewNumberVerified] = useState(false);
    const [formValues, setFormValues] = useState({});
    // ============================================
    // METHODS
    // ============================================

    const validateCurrentNumber = (e) => {
        const { value } = e.target;
        setFormValues({ ...formValues, currentNumberVerification: value });
        handleVerifyMobile();
    };

    const setNewNumber = (e) => {
        setFormValues({ ...formValues, newNumber: e.target.value });
    };

    const validateNewNumber = (e) => {
        const { value } = e.target;
        setFormValues({ ...formValues, newNumberVerification: value });

        if (formValues.newNumber === value) {
            setNewNumberVerified(true);
        } else {
            setNewNumberVerified(false);

            setFields([
                {
                    errors: [getTranslation('Numbers inconsistent!')],
                    name: 'newNumberVerification',
                    value: value,
                },
            ]);
        }
    };

    const submitForm = (values) => {
        setFormValues(values);
        handleCreateModification();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [
        loading,
        handleCreateModification,
        modificationCreated,
    ] = useCreateModificationAsyncEndpoint({
        currentInfo: defaultValue,
        newInfo: formValues.newNumber,
        requesterRemark: formValues.remark,
        type: MODIFICATION_TYPES.MOBILE_NUMBER,
        userId,
    });

    const [verifying, handleVerifyMobile, verified] = useVerifyPlayerMobileAsyncEndpoint(
        userId,
        formValues.currentNumberVerification,
    );

    useEffect(() => {
        if (modificationCreated) {
            onCancel();
            resetFields();
        }
        // eslint-disable-next-line
    }, [modificationCreated, resetFields]);

    useEffect(() => {
        if (verified) {
            if (verified.data.data) {
                setCurrentNumberVerified(true);
            } else {
                setCurrentNumberVerified(false);

                setFields([
                    {
                        errors: [getTranslation('Incorrect phone number!')],
                        name: 'currentNumberVerification',
                        value: formValues.currentNumberVerification,
                    },
                ]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [verified]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form
            form={form}
            name="mobile-number-modification"
            onFinish={submitForm}
            style={{ width: 430 }}
            initialValues={{ currentNumber: defaultValue }}
        >
            <Row className="form__group__username">
                <Col span={6}>
                    <span className="form__group__label">{getTranslation('Username')}:</span>
                </Col>
                <Col span={18}>
                    <span className="form__group__username__text">{userName}</span>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={6}>
                    <span className="form__group__label">{getTranslation('Current number')}:</span>
                </Col>
                <Col span={9}>
                    <Form.Item name="currentNumber">
                        <Input
                            disabled
                            className={
                                isCurrentNumberVerified ? 'form__input--success' : 'form__input'
                            }
                        />
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item
                        name="currentNumberVerification"
                        rules={[
                            {
                                message: getTranslation('Please input current number!'),
                                required: true,
                            },
                        ]}
                    >
                        <Input
                            className={
                                isCurrentNumberVerified ? 'form__input--success' : 'form__input'
                            }
                            placeholder={getTranslation('Verify Old Number')}
                            onBlur={validateCurrentNumber}
                            prefix={verifying && <Loader size="small" style={{ padding: 0 }} />}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={6}>
                    <span className="form__group__label">{getTranslation('New number')}:</span>
                </Col>
                <Col span={9}>
                    <Form.Item
                        name="newNumber"
                        rules={[
                            {
                                message: getTranslation('Please input new number!'),
                                required: true,
                            },
                        ]}
                    >
                        <Input
                            className={isNewNumberVerified ? 'form__input--success' : 'form__input'}
                            disabled={!isCurrentNumberVerified}
                            onBlur={setNewNumber}
                        />
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item
                        name="newNumberVerification"
                        rules={[
                            {
                                message: getTranslation('Please input new number again!'),
                                required: true,
                            },
                        ]}
                    >
                        <Input
                            className={isNewNumberVerified ? 'form__input--success' : 'form__input'}
                            placeholder={getTranslation('Verify New Number')}
                            disabled={!isCurrentNumberVerified}
                            onBlur={validateNewNumber}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={6}>
                    <span className="form__group__label">
                        {getTranslation('Remark')}
                        <span style={{ color: 'red' }}>*</span>:
                    </span>
                </Col>
                <Col span={18}>
                    <Form.Item
                        name="remark"
                        rules={[
                            {
                                message: getTranslation('Please fill in this field!'),
                                required: true,
                            },
                        ]}
                    >
                        <Input.TextArea rows={3} />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="end" className="form__group">
                <ButtonGreen htmlType="submit" loading={loading}>
                    {getTranslation('Submit')}
                </ButtonGreen>
                <Button onClick={onCancel}>{getTranslation('Cancel')}</Button>
            </Row>
        </Form>
    );
};

export default MobileNumberForm;
