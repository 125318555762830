import React from 'react';

import { Table } from 'antd';
import { useVT } from 'virtualizedtableforantd4';

import { getColumns, getPaginationOptions } from './PersonalMessageTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const PersonalMessageResultTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const personalMessage = getStateProp(
        state,
        'announcement.announcementHistory.personalMessage',
        {},
    );
    const paging = getStateProp(personalMessage, 'paging', {});

    const tableData = getStateProp(personalMessage, 'list', []);

    // ============================================
    // HOOKS
    // ============================================

    const [vt] = useVT(() => ({ scroll: { y: 700 } }), []);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns()}
            rowKey={(record) => record.taskNo}
            dataSource={tableData}
            size="small"
            align="left"
            scroll={{ y: 700 }}
            bordered
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
            component={vt}
        />
    );
};

export default PersonalMessageResultTable;
