import React from 'react';

import { Table } from 'antd';
import _ from 'lodash';

import { getColumns, getPaginationOptions, getRowSelectionProps } from './ActiveBannerTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { updateBannerSorting } from '../bannerSortingActions';
import { useStore } from '../../../../store/StateProvider';

const ActiveBannerResultTable = ({ showSelectedBanners, loading }) => {
    const [state, dispatch] = useStore();

    const bannerType = getStateProp(
        state,
        'bonus.bannerSorting.modalDetails.filters.bannerType',
        null,
    );
    const tableData = getStateProp(state, 'bonus.bannerSorting.modalDetails.list', []);
    const selectedRecords = getStateProp(
        state,
        'bonus.bannerSorting.modalDetails.selectedRecords',
        [],
    );
    const paging = getStateProp(state, 'bonus.bannerSorting.modalDetails.paging', {});
    const selectedKeys = _.map(selectedRecords, (data) => {
        return data.id;
    });

    // ============================================
    // METHODS
    // ============================================
    const updateSorting = (value, id) => {
        _.find(selectedRecords, (records) => records.id === id).sortingOrder = value;
        updateBannerSorting(dispatch, selectedRecords);
    };
    // ============================================
    // RENDER
    // ============================================
    return (
        <Table
            columns={getColumns(selectedRecords, showSelectedBanners, updateSorting)}
            rowKey={(record) => record.id}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            dataSource={showSelectedBanners ? selectedRecords : tableData}
            loading={loading}
            rowSelection={getRowSelectionProps(dispatch, selectedKeys, selectedRecords, bannerType)}
            pagination={showSelectedBanners ? null : getPaginationOptions(paging, dispatch)}
        />
    );
};

export default ActiveBannerResultTable;
