import React from 'react';

import './App.less';
import { StateProvider } from '../../store/StateProvider';
import AppRoutes from './AppRoutes';
import ErrorBoundary from '../../error-reporting/ErrorBoundary';
import IntlProvider from '../internationalisation/IntlProvider';
import appReducer from '../../store/appReducer';
import instantiateErrorLogger from '../../error-reporting/ErrorLogger';

const loggerInstance = instantiateErrorLogger();

const App = () => (
    <ErrorBoundary logger={loggerInstance}>
        <StateProvider reducer={appReducer} initialState={{}}>
            <IntlProvider>
                <AppRoutes />
            </IntlProvider>
        </StateProvider>
    </ErrorBoundary>
);

export default App;
