import React from 'react';

import _ from 'lodash';

import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../referralReportActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';
import ViewButton from './ViewButton';

export const getColumns = () => {
    const segerateObject = (objectValue) =>
        _.map(objectValue, (value, index) => (
            <div key={index} style={{ whiteSpace: 'nowrap' }}>
                <span>{value.currency}</span> : <span>{value.amount}</span>
            </div>
        ));

    const columns = [
        {
            dataIndex: 'programCode',
            title: getTranslation('Program Code'),
        },
        {
            dataIndex: 'programName',
            title: getTranslation('Program Name'),
        },
        {
            dataIndex: 'programType',
            render: (value) => `${_.join(value, ',')}`,
            title: getTranslation('Program Type'),
        },
        {
            dataIndex: 'currency',
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'activeDate',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Active Date'),
        },
        {
            dataIndex: 'endDate',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('End Date'),
        },
        {
            dataIndex: 'totalReferrer',
            title: getTranslation('Total Referrer'),
        },
        {
            dataIndex: 'topTenReferrer',
            render: (text, record) => <ViewButton id={record.programId} />,
            title: getTranslation('Top Ten Referrer'),
        },
        {
            dataIndex: 'totalReferee',
            title: getTranslation('Total Referee'),
        },
        {
            dataIndex: 'totalDepositedReferee',
            title: getTranslation('Qualified Referee'),
        },
        {
            dataIndex: 'lastRefereeDate',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Last Referee Date'),
        },
        {
            dataIndex: 'referralConversionRate',
            title: getTranslation('Referral Conversion Rate'),
        },
        {
            dataIndex: 'totalRewardsPayout',
            title: getTranslation('Total Rewards Payout'),
        },
        {
            dataIndex: 'totalBonusPayout',
            title: getTranslation('Total Bonus Payout'),
        },
        {
            dataIndex: 'totalRefereePayout',
            title: getTranslation('Total Referee Payout'),
        },
        {
            dataIndex: 'averageOfRefereePerDay',
            title: getTranslation('AVG Of Referee per day'),
        },
        {
            dataIndex: 'refereeTotalTurnover',
            render: (value) => segerateObject(value),
            title: getTranslation('Referee Total Turnover'),
        },
        {
            dataIndex: 'totalRefereeDeposit',
            render: (value) => segerateObject(value),
            title: getTranslation('Total Referee Deposit'),
        },
        {
            dataIndex: 'totalRefereeWithdrawal',
            render: (value) => segerateObject(value),
            title: getTranslation('Total Referee Withdrawal'),
        },
        {
            dataIndex: 'refereeWinLoss',
            render: (value) => segerateObject(value),
            title: getTranslation('Referee Win/Loss'),
        },
        {
            dataIndex: 'companyWinLoss',
            render: (value) => segerateObject(value),
            title: getTranslation('Company Win/Loss'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
