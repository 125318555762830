import { CURRENCIES } from '../../../common/constants/localization';
import { VIP_LEVELS } from '../constants';
import _ from 'lodash';

export const getInitialDetailList = () => {
    let initDetail = [];
    _.forEach(CURRENCIES, (currency) => {
        _.forEach(VIP_LEVELS, (vip) => {
            initDetail.push({
                currencyCode: currency.currency,
                loginRewards: _.times(8, (i) => {
                    return { day: i + 1, etoAmount: 0, rewardAmount: 0 };
                }),
                vipLevel: vip.level,
            });
        });
    });

    return initDetail;
};
