import { ACTIONS } from './affiliateRecordsActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function affiliateRecordsReducer(
    affiliateRecords = {
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        resetPageNo: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.FILTER_SET:
            return {
                ...affiliateRecords,
                filters: {
                    ...affiliateRecords.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };
        case ACTIONS.FILTERS_DROP_ALL:
            return {
                ...affiliateRecords,
                filters: null,
                resetPageNo: true,
            };

        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...affiliateRecords,
                paging: {
                    ...affiliateRecords.paging,
                    pageNo:
                        payload > affiliateRecords.paging.pageCount
                            ? affiliateRecords.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...affiliateRecords,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.AFFILIATE_RECORDS_GET:
            const pageNo = affiliateRecords.resetPageNo
                ? TABLE_PAGE_NO_DEFAULT
                : affiliateRecords.paging.pageNo;
            return {
                ...affiliateRecords,
                paging: {
                    ...affiliateRecords.paging,
                    pageNo: pageNo,
                },
                resetPageNo: false,
            };
        case ACTIONS.AFFILIATE_RECORDS_GET_SUCCESS:
            return {
                ...affiliateRecords,
                ...payload,
            };

        default:
            return affiliateRecords;
    }
}
