import React from 'react';

import { Row } from 'antd';
import { getTranslation } from '../../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../../common/helpers/misc';
import BankCardHolderNameEditForm from './popovers/BankCardHolderNameEditForm';
import BankCardVerifyPopupForm from './popovers/BankCardVerifyForm';
import EditPopover from '../../../players-list/table/popovers/EditPopover';
import OperationsButton from './OperationsButton';

export const getColumns = (dispatch, userId) => {
    const renderMaskedBankNo = (value) => (
        <Row justify="space-between">
            <span>{value}</span>
            <EditPopover
                title={getTranslation('Bank Card Verification')}
                value={value}
                aspect="bankVerify"
            >
                <BankCardVerifyPopupForm bankCard={value} maskedValue={value} />
            </EditPopover>
        </Row>
    );

    const renderCardHolderName = (value, record) => (
        <Row justify="space-between">
            <span>{value}</span>
            <EditPopover title={getTranslation('Card Holder Name')} value={value}>
                <BankCardHolderNameEditForm userId={userId} bankCardInfo={record} />
            </EditPopover>
        </Row>
    );

    const columns = [
        {
            dataIndex: 'bankName',
            title: getTranslation('Bank Name'),
        },
        {
            dataIndex: 'cardHolderName',
            render: (value, record) => renderCardHolderName(value, record),
            title: getTranslation('Card Holder Name'),
        },
        {
            dataIndex: 'bankCardNo',
            render: (value, record) => renderMaskedBankNo(value, record),
            title: getTranslation('Card No.'),
        },
        {
            dataIndex: 'province',
            title: getTranslation('Province'),
        },
        {
            dataIndex: 'city',
            title: getTranslation('City'),
        },
        {
            dataIndex: 'area',
            title: getTranslation('Area'),
        },
        {
            dataIndex: 'branchName',
            title: getTranslation('Branch'),
        },
        {
            dataIndex: 'operation',
            dataType: 'operation',
            render: (value, record) => <OperationsButton dispatch={dispatch} card={record} />,
            title: getTranslation('Operations'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};
