import React from 'react';

import _ from 'lodash';

import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../bankAccountConfigurationActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';
import OperationButton from './OperationButton';

export const getColumns = () => {
    const columns = [
        {
            dataIndex: 'passage',
            title: getTranslation('Passage'),
        },
        {
            dataIndex: 'bankName',
            title: getTranslation('Bank'),
        },
        {
            dataIndex: 'cardHolderName',
            title: getTranslation('Bank Account Name'),
        },
        {
            dataIndex: 'bankCardNo',
            title: getTranslation('Bank Account Number'),
        },
        {
            dataIndex: 'trustLevels',
            render: (value) => `${_.join(value, ',')}`,
            title: getTranslation('Trust Level'),
        },
        {
            dataIndex: 'memberLevels',
            render: (value) => `${_.join(value, ',')}`,
            title: getTranslation('Member Level'),
        },
        {
            dataIndex: 'isWebShow',
            render: (value) => <span>{value ? 'Yes' : 'No'}</span>,
            title: getTranslation('Web Show'),
        },
        {
            dataIndex: 'currencyCode',
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'dailyMaximumAmount',
            render: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            title: getTranslation('Max Credit Amount Per Day'),
        },
        {
            dataIndex: 'specialPlayerNames',
            title: getTranslation('Special Member'),
        },
        {
            dataIndex: 'remarks',
            title: getTranslation('Remark'),
        },
        {
            dataIndex: 'operation',
            dataType: 'operation',
            render: (text, record) => <OperationButton accountDetails={record} />,
            title: getTranslation('Operation'),
            width: 80,
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
