import React from 'react';

import { Row } from 'antd';

import LanguageDropdown from '../internationalisation/LanguageDropdown';
import Logout from '../login/Logout';

const NavBarList = () => {
    return (
        <Row justify="space-between" align="middle" className="navbar__container">
            <LanguageDropdown />
            <Logout />
        </Row>
    );
};

export default NavBarList;
