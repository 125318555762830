import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './AffiliateGeneratorHistoryMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const AffiliateGeneratorHistoryTable = ({ loading = false }) => {
    const [state, dispatch] = useStore();

    const { generator = {} } = getStateProp(state, 'affiliate');
    const tableData = getStateProp(generator, 'list', []);
    const paging = getStateProp(generator, 'paging', {});

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns()}
            pagination={getPaginationOptions(paging, dispatch)}
            dataSource={tableData}
            rowKey={(record, idx) => idx}
            loading={loading}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
        />
    );
};

export default AffiliateGeneratorHistoryTable;
