import React from 'react';

import { Card, Col, Input, Row } from 'antd';
import Moment from 'react-moment';

import '../styles/AnnouncementVerificationForm.less';
import { ButtonGray, ButtonGreen, ButtonRed } from '../../../../common/components/buttons/Buttons';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import MessageVerificationFormTabs from './MessageVerificationFormTabs';

const MessageVerificationForm = ({
    onVerify,
    onCancel,
    selectedMessage,
    verifying,
    rejecting,
    onReject,
}) => {
    const messageContents = getStateProp(selectedMessage, 'content', '');
    const messageTitle = getStateProp(selectedMessage, 'title', '');
    const {
        contactMethod,
        messageType,
        userNames,
        phoneNumbers,
        durationEnd,
        durationStart,
    } = selectedMessage;

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card className="announcement-form">
            <Row className="form__group">
                <Col span={8}>
                    <label className="form__label">{getTranslation('Message Type')}</label>
                </Col>
                <Col span={14}>
                    <Input disabled value={messageType} />
                </Col>
            </Row>

            <Row className="form__group">
                <Col span={8}>
                    <label className="form__label">{getTranslation('Contact Method')}</label>
                </Col>
                <Col span={14}>
                    <Input disabled value={contactMethod} />
                </Col>
            </Row>

            <Row className="form__group">
                <Col span={8}>
                    <label className="form__label">{getTranslation('Duration')}</label>
                </Col>
                <Col span={14}>
                    <Moment format="DD/MM/YYYY HH:mm:ss" utc local>
                        {durationStart}
                    </Moment>
                    <span style={{ margin: '3px' }}>-</span>
                    <Moment format="DD/MM/YYYY HH:mm:ss" utc local>
                        {durationEnd}
                    </Moment>
                </Col>
            </Row>

            <Row className="form__group">
                <Col span={8}>
                    <label className="form__label">{getTranslation('Recipients')}</label>
                </Col>
                <Col span={14}>
                    <Input disabled value={userNames || phoneNumbers} />
                </Col>
            </Row>

            <MessageVerificationFormTabs
                content={messageContents}
                title={messageTitle}
                messageType={messageType}
            />

            <Row className="action-buttons" justify="end" align="middle">
                <ButtonGreen
                    label={getTranslation('Verify')}
                    onClick={onVerify}
                    loading={verifying}
                />
                <ButtonRed
                    label={getTranslation('Reject')}
                    onClick={onReject}
                    loading={rejecting}
                />
                <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
            </Row>
        </Card>
    );
};

export default MessageVerificationForm;
