import React from 'react';

import { DownloadOutlined } from '@ant-design/icons';

const ColumnActionButtons = ({ fileInfo }) => {
    return (
        <a href={fileInfo.linkUrl} target="blank">
            <DownloadOutlined className="action-icon" style={{ cursor: 'pointer' }} />
        </a>
    );
};

export default ColumnActionButtons;
