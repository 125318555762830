import React from 'react';

import { Input } from 'antd';

import { getTranslation } from '../../../common/helpers/locale';

const ReferralFilter = ({ value, onFilterChange }) => {
    return (
        <Input
            onChange={(e) => onFilterChange('referralUserName', e.target.value)}
            value={value}
            className="filter"
            placeholder={getTranslation('Referral')}
        />
    );
};

export default ReferralFilter;
