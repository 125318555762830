export const REFERRAL_STATUSES = {
    ACTIVE: 'Active',
    CLOSED: 'Closed',
    INACTIVE: 'Inactive',
};

export const PROGRAM_TYPES = {
    BONUS: 'Bonus',
    BOTH: 'Flat,Bonus',
    FLAT: 'Flat',
};

export const TOTAL_REFEREE_CONDITION = {
    GREATER: {
        label: '>=',
        value: 'totalRefereeMore',
    },
    LOWER: {
        label: '<=',
        value: 'totalRefereeLess',
    },
};

export const WEEKLY_REFEREE_CONDITION = {
    GREATER: {
        label: '>=',
        value: 'totalRefereeWeeklyMore',
    },
    LOWER: {
        label: '<=',
        value: 'totalRefereeWeeklyLess',
    },
};

export const REWARDS_EARNED_CONDITION = {
    GREATER: {
        label: '>=',
        value: 'rewardsEarnedMore',
    },
    LOWER: {
        label: '<=',
        value: 'rewardsEarnedLess',
    },
};

export const REFEREE_DEPOSIT_CONDITION = {
    GREATER: {
        label: '>=',
        value: 'totalRefereeDepositMore',
    },
    LOWER: {
        label: '<=',
        value: 'totalRefereeDepositLess',
    },
};

export const REFEREE_WINLOSS_CONDITION = {
    GREATER: {
        label: '>=',
        value: 'refereeWinLossMore',
    },
    LOWER: {
        label: '<=',
        value: 'refereeWinLossLess',
    },
};

export const REFERRAL_CONVERSTION_RATE_CONDITION = {
    GREATER: {
        label: '>=',
        value: 'referralConversionRateMore',
    },
    LOWER: {
        label: '<=',
        value: 'referralConversionRateLess',
    },
};

export const REFEREE_WITHDRAWAL_CONDITION = {
    GREATER: {
        label: '>=',
        value: 'totalRefereeWithdrawalMore',
    },
    LOWER: {
        label: '<=',
        value: 'totalRefereeWithdrawalLess',
    },
};

export const REFERRAL_BIND_BANK_CARD = {
    NO: 'NO',
    YES: 'YES',
};
