import React, { useEffect } from 'react';

import { Modal, Row } from 'antd';

import { ANNOUNCEMENT_STATUSES } from '../../../constants';
import { ButtonGray, ButtonGreen } from '../../../../../common/components/buttons/Buttons';
import {
    closeAnnouncementDeleteModal,
    setAnnouncementDeselected,
} from '../globalAnnouncementActions';
import { getStateProp } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useStore } from '../../../../../store/StateProvider';
import { useUpdateAnnouncementAsyncEndpoint } from '../../../../../services/announcementService';

const DeleteAnnouncementModal = ({ onCancel, visible }) => {
    const [state, dispatch] = useStore();

    const selectedAnnouncement = getStateProp(
        state,
        'announcement.announcementHistory.globalAnnouncement.announcementDelete',
        {},
    );
    const refreshTable = getStateProp(
        state,
        'announcement.announcementHistory.globalAnnouncement.fetchData',
        () => {},
    );

    // ============================================
    // HOOKS
    // ============================================

    const [
        deleting,
        handleDeleteAnnouncement,
        announcementDeleted,
    ] = useUpdateAnnouncementAsyncEndpoint(selectedAnnouncement, ANNOUNCEMENT_STATUSES.DELETED);

    useEffect(() => {
        if (announcementDeleted) {
            closeAnnouncementDeleteModal(dispatch);
            setAnnouncementDeselected(dispatch);
            refreshTable();
        }
    }, [dispatch, announcementDeleted, refreshTable]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            zIndex={1001}
            visible={visible}
            onCancel={onCancel}
            title={getTranslation('Confirm to Delete?')}
            centered
            closable={false}
            className="announcement__confirm-delete-modal"
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        label={getTranslation('Confirm')}
                        onClick={handleDeleteAnnouncement}
                        loading={deleting}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
        >
            {getTranslation(
                "Be noticed that, you can't get back the record once you delete the global announcement.",
            )}
        </Modal>
    );
};

export default DeleteAnnouncementModal;
