export const WITHDRAWAL_RECORD_TABLE_COLUMNS = [
    {
        key: 'requestedDateTime',
        title: 'Request Time',
    },
    {
        key: 'transactionNo',
        required: true,
        title: 'Task Transaction Number',
    },
    {
        key: 'playerUserName',
        required: true,
        title: 'Username',
    },
    {
        key: 'memberLevel',
        title: 'Member Level',
    },
    {
        key: 'affiliate',
        title: 'Affiliate',
    },
    {
        key: 'referral',
        title: 'Referral',
    },
    {
        key: 'currencyCode',
        required: true,
        title: 'Currency',
    },
    {
        key: 'amount',
        required: true,
        title: 'Amount',
    },
    {
        key: 'bankName',
        title: 'Bank Name',
    },
    {
        key: 'auditDateTime',
        title: 'Audit Time',
    },
    {
        key: 'processedDateTime',
        title: 'Process Time',
    },
    {
        key: 'auditorRemarks',
        title: 'Auditor Remark',
    },
    {
        key: 'operatorRemarks',
        title: 'Operator Remark',
    },
    {
        key: 'orderStatus',
        required: true,
        title: 'Status',
    },
    {
        key: 'auditorName',
        title: 'Audit',
    },
    {
        key: 'operatorName',
        required: true,
        title: 'Operator',
    },
];
