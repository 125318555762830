import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { LANGUAGES } from '../../../../common/constants/localization';
import { getTranslation } from '../../../../common/helpers/locale';

const NewsLanguageFilter = ({ value, onFilterChange }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('languageCode', value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear
            placeholder={getTranslation('Languages')}
            className="filter"
        >
            <Select.Option key={'All'} value={null}>
                {getTranslation('All').toLocaleUpperCase()}
            </Select.Option>
            {_.map(LANGUAGES, (status) => (
                <Select.Option key={status.name} value={status.value}>
                    {getTranslation(status.name).toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
};

export default NewsLanguageFilter;
