import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

import { API } from '../common/constants/api';
import { clearError } from '../common/actions/errorActions';
import { getStateProp } from '../common/helpers/misc';
import {
    newsAdd,
    newsAddFailure,
    newsAddSuccess,
    newsDelete,
    newsDeleteFailure,
    newsDeleteSuccess,
    newsGet,
    newsGetFailure,
    newsGetSuccess,
    newsUpdate,
    newsUpdateFailure,
    newsUpdateSuccess,
} from '../components/news/newsActions';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, NEWS } = API;

export function useGetNewsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { pageNo, pageSize } = getStateProp(state, 'news.paging');
    const filters = getStateProp(state, 'news.filters', {});
    const newsCategoryId = getStateProp(filters, 'newsCategory.key');

    // ============================================
    // METHODS
    // ============================================

    const handleGetNews = useCallback(() => {
        newsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ..._.pickBy(filters, (value, key) => key !== 'newsCategory'),
                isOperator: true,
                newsCategoryId,
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${NEWS.ROOT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            newsGetSuccess(dispatch, {
                fetchData: handleGetNews,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetNews, response]);

    useEffect(() => {
        handleGetNews();
    }, [handleGetNews, pageNo, pageSize]);

    const errorMsg = useError(error, newsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetNews];
}

export function useUpdateNewsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const newsId = getStateProp(state, 'news.newsDraft.id', '');
    const newsDraft = getStateProp(state, 'news.newsDraft');
    const newsCategoryId = getStateProp(newsDraft, 'newsCategory.key');

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateNews = () => {
        newsUpdate(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                ..._.pickBy(newsDraft, (value, key) => key !== 'newsCategory'),
                newsCategoryId,
            },
        },
        trigger,
        url: `${VERSION.V1}${NEWS.ROOT}/${newsId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            newsUpdateSuccess(dispatch);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, newsUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleUpdateNews];
}

export function useAddNewsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const newsDraft = getStateProp(state, 'news.newsDraft');
    const newsCategoryId = getStateProp(newsDraft, 'newsCategory.key');

    // ============================================
    // METHODS
    // ============================================

    const handleAddNews = () => {
        newsAdd(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                ..._.pickBy(newsDraft, (value, key) => key !== 'newsCategory'),
                newsCategoryId,
            },
        },
        trigger,
        url: `${VERSION.V1}${NEWS.ROOT}/`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            newsAddSuccess(dispatch);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, newsAddFailure);

    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleAddNews];
}

export function useDeleteNewsAsyncEndpoint(newsId) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const refreshTable = getStateProp(state, 'news.fetchData', () => {});

    // ============================================
    // METHODS
    // ============================================

    const handleDeleteNews = () => {
        newsDelete(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'DELETE',
        options: {
            data: {
                operatorId: '00000000-0000-0000-0000-000000000000', // add this to resolve .Net bug for del method
            },
        },
        trigger,
        url: `${VERSION.V1}${NEWS.ROOT}/${newsId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            newsDeleteSuccess(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, newsDeleteFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleDeleteNews];
}
