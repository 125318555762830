import { useCallback, useEffect, useState } from 'react';

import { message } from 'antd';
import _ from 'lodash';

import { API } from '../common/constants/api';
import { clearError } from '../common/actions/errorActions';
import {
    getMenuOperationIds,
    getStateProp,
    getSubMenuByOperationIds,
} from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import {
    groupAccessListGet,
    groupAccessListGetFailure,
    groupAccessListGetSuccess,
    groupAccessRecordCreate,
    groupAccessRecordCreateFailure,
    groupAccessRecordCreateSuccess,
    groupAccessRecordDelete,
    groupAccessRecordDeleteFailure,
    groupAccessRecordDeleteSuccess,
    groupAccessRecordGet,
    groupAccessRecordGetFailure,
    groupAccessRecordGetSuccess,
    groupAccessRecordUpdate,
    groupAccessRecordUpdateFailure,
    groupAccessRecordUpdateSuccess,
} from '../components/admin/group-setting/groupAuthoritySettingActions';
import {
    menuListGet,
    menuListGetFailure,
    menuListGetSuccess,
} from '../components/admin/menuActions';
import { useDispatch, useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, IDENTITY, MENUS } = API;

export function useGetMenusAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const menuList = getStateProp(state, 'administration.menus');

    // ============================================
    // METHODS
    // ============================================

    const handleGetMenus = useCallback(() => {
        setTrigger(+new Date());
    }, []);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => _.isEmpty(menuList),
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${MENUS.ROOT}`,
    });

    useEffect(() => {
        if (_.isEmpty(menuList)) {
            menuListGet(dispatch);
            handleGetMenus();
        }
    }, [dispatch, handleGetMenus, menuList]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            menuListGetSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, menuListGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useGetGroupAccessListAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const groupAuthoritySetting = getStateProp(state, 'administration.groupAuthoritySetting');
    const { pageNo, pageSize } = getStateProp(groupAuthoritySetting, 'paging');

    // ============================================
    // METHODS
    // ============================================

    const handleGetGroupAccessList = useCallback(() => {
        groupAccessListGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        method: 'GET',
        options: {
            params: {
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${IDENTITY.GROUP_ACCESS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            groupAccessListGetSuccess(dispatch, {
                fetchData: handleGetGroupAccessList,
                groupAccessList: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetGroupAccessList, response]);

    useEffect(() => {
        handleGetGroupAccessList();
    }, [handleGetGroupAccessList, pageNo, pageSize]);

    const errorMsg = useError(error, groupAccessListGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useGetGroupAccessDetailAsyncEndpoint(accessGroupId) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const menuList = getStateProp(state, 'administration.menus');

    // ============================================
    // METHODS
    // ============================================

    const handleGetGroupAcessRecordDetails = useCallback(() => {
        groupAccessRecordGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!accessGroupId,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${IDENTITY.GROUP_ACCESS}/${accessGroupId}`,
    });

    useEffect(() => {
        handleGetGroupAcessRecordDetails();
    }, [accessGroupId, handleGetGroupAcessRecordDetails]);

    useEffect(() => {
        if (response) {
            const { menuOperationIds, ...rest } = response.data.data;
            clearError(dispatch);

            groupAccessRecordGetSuccess(dispatch, {
                selectedMenus: getSubMenuByOperationIds(menuList, menuOperationIds),
                ...rest,
            });
        }
    }, [dispatch, menuList, response]);

    const errorMsg = useError(error, groupAccessRecordGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useCreateGroupAccessRecordAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const groupAuthoritySetting = getStateProp(state, 'administration.groupAuthoritySetting');
    const menuList = getStateProp(state, 'administration.menus');
    const groupAccessRecord = getStateProp(groupAuthoritySetting, 'groupAccessRecord');

    const { selectedMenus, ...rest } = groupAccessRecord;
    const menuOperationIds = getMenuOperationIds(menuList, selectedMenus);

    // ============================================
    // METHODS
    // ============================================

    const handleCreateGroupAccessRecord = () => {
        groupAccessRecordCreate(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                activatedStatus: 'Activated',
                menuOperationIds,
                ...rest,
            },
        },
        trigger,
        url: `${VERSION.V1}${IDENTITY.GROUP_ACCESS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            groupAccessRecordCreateSuccess(dispatch);
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, groupAccessRecordCreateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleCreateGroupAccessRecord];
}

export function useUpdateGroupAccessRecordAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const groupAuthoritySetting = getStateProp(state, 'administration.groupAuthoritySetting');
    const menuList = getStateProp(state, 'administration.menus');
    const groupAccessRecord = getStateProp(groupAuthoritySetting, 'groupAccessRecord');

    const { accessGroupId, selectedMenus, ...rest } = groupAccessRecord;
    const menuOperationIds = getMenuOperationIds(menuList, selectedMenus);

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateGroupAccessRecord = () => {
        groupAccessRecordUpdate(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                activatedStatus: 'Activated',
                menuOperationIds,
                ...rest,
            },
        },
        trigger,
        url: `${VERSION.V1}${IDENTITY.GROUP_ACCESS}/${accessGroupId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            groupAccessRecordUpdateSuccess(dispatch);
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, groupAccessRecordUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateGroupAccessRecord];
}

export function useDeleteGroupAccessRecordAsyncEndpoint(accessGroupId) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleDeleteGroupAccessRecord = () => {
        groupAccessRecordDelete(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'DELETE',
        options: {
            data: {},
        },
        trigger,
        url: `${VERSION.V1}${IDENTITY.GROUP_ACCESS}/${accessGroupId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            groupAccessRecordDeleteSuccess(dispatch);
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, groupAccessRecordDeleteFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleDeleteGroupAccessRecord];
}
