import React from 'react';

import { getTranslation } from '../../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../../common/helpers/misc';

export const getColumns = () => {
    const columns = [
        { dataIndex: 'No', render: (text, record, index) => <span>{index + 1}</span>, title: '#' },
        { dataIndex: 'userName', title: getTranslation('Usernames') },
        { dataIndex: 'mobileNo', title: getTranslation('Phone Number') },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};
