import { Switch } from 'react-router-dom';
import React from 'react';

import { PrivateRoute } from '../../common/components';
import { ROUTES } from '../../common/constants/routing';
import CreateWhatsNew from './create-whats-new/CreateWhatsNew';

const PushNotification = () => {
    return (
        <Switch>
            <PrivateRoute
                path={ROUTES.DASHBOARD.WHATS_NEW.CREATE}
                component={CreateWhatsNew}
                hasAccessControl={true}
            />
        </Switch>
    );
};

export default PushNotification;
