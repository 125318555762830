import React from 'react';

import _ from 'lodash';

import { RenderDateFormat } from '../../../../common/components';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';

export const getColumns = () => {
    const segerateObject = (objectValue) =>
        _.map(objectValue, (value, index) => (
            <div key={index} style={{ whiteSpace: 'nowrap' }}>
                <span>{value.currency}</span> : <span>{value.amount}</span>
            </div>
        ));

    const columns = [
        {
            dataIndex: 'userName',
            title: getTranslation('User'),
        },
        {
            dataIndex: 'currency',
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'registerDate',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Register Date'),
        },
        {
            dataIndex: 'playerLevel',
            title: getTranslation('Player Level'),
        },
        {
            dataIndex: 'totalReferee',
            title: getTranslation('Total Referee'),
        },
        {
            dataIndex: 'totalDepositedReferee',
            title: getTranslation('Qualified Referee'),
        },
        {
            dataIndex: 'totalRefereeWeekly',
            title: getTranslation('Total Referee in Past 7 days'),
        },
        {
            dataIndex: 'averageRefereePerDay',
            title: getTranslation('AVG Of Referee per day'),
        },
        {
            dataIndex: 'firstRefereeDate',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('First Referee Date'),
        },
        {
            dataIndex: 'lastRefereeDate',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Last Referee Date'),
        },
        {
            dataIndex: 'rewardsEarned',
            title: getTranslation('Rewards Earned'),
        },
        {
            dataIndex: 'conversionRate',
            title: getTranslation('Referral Conversion Rate'),
        },
        {
            dataIndex: 'refereeEffectiveTurnover',
            render: (value) => segerateObject(value),
            title: getTranslation('Referee Effective Turnover'),
        },
        {
            dataIndex: 'refereeTurnover',
            render: (value) => segerateObject(value),
            title: getTranslation('Referee Turnover'),
        },
        {
            dataIndex: 'refereeDeposit',
            render: (value) => segerateObject(value),
            title: getTranslation('Referee Deposit'),
        },
        {
            dataIndex: 'refereeWithdraw',
            render: (value) => segerateObject(value),
            title: getTranslation('Referee Withdrawal'),
        },
        {
            dataIndex: 'refereeWinLoss',
            render: (value) => segerateObject(value),
            title: getTranslation('Referee Win/Loss'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};
