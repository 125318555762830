import React from 'react';

import { ButtonGray } from '../../../../common/components/buttons/Buttons';
import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../walletActions';
import { getStatusColor, sortTableByDataType } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';

export const getColumns = () => {
    const columns = [
        {
            dataIndex: 'requestedDateTime',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Request Time'),
        },
        {
            dataIndex: 'transactionNo',
            title: getTranslation('Task Transaction Number'),
        },
        {
            dataIndex: 'playerUserName',
            title: getTranslation('Username'),
        },
        {
            dataIndex: 'transferFrom',
            title: getTranslation('From'),
        },
        {
            dataIndex: 'currencyCode',
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'amount',
            title: getTranslation('Amount'),
        },
        {
            dataIndex: 'transferTo',
            title: getTranslation('To'),
        },
        {
            dataIndex: 'beforeBalance',
            dataType: 'nanInt',
            render: (value) => <span>{!isNaN(parseInt(value)) ? value : 'NA'}</span>,
            title: getTranslation('Before Balance'),
        },
        {
            dataIndex: 'afterBalance',
            dataType: 'nanInt',
            render: (value) => <span>{!isNaN(parseInt(value)) ? value : 'NA'}</span>,
            title: getTranslation('After Balance'),
        },
        {
            dataIndex: 'totalWalletBalance',
            title: getTranslation('Total Balance'),
        },
        {
            dataIndex: 'currentWalletBalance',
            dataType: 'nanInt',
            render: (value) => <span>{!isNaN(parseInt(value)) ? value : 'NA'}</span>,
            title: getTranslation('GGBook Balance'),
        },
        {
            dataIndex: 'currentOneWorksWalletBalance',
            dataType: 'nanInt',
            render: (value) => <span>{!isNaN(parseInt(value)) ? value : 'NA'}</span>,
            title: getTranslation('Oneworks Balance'),
        },
        {
            dataIndex: 'currentGDWalletBalance',
            dataType: 'nanInt',
            render: (value) => <span>{!isNaN(parseInt(value)) ? value : 'NA'}</span>,
            title: getTranslation('Gold Deluxe Balance'),
        },
        {
            dataIndex: 'currentAGWalletBalance',
            dataType: 'nanInt',
            render: (value) => <span>{!isNaN(parseInt(value)) ? value : 'NA'}</span>,
            title: getTranslation('AG Balance'),
        },
        {
            dataIndex: 'currentBTIWalletBalance',
            dataType: 'nanInt',
            render: (value) => <span>{!isNaN(parseInt(value)) ? value : 'NA'}</span>,
            title: getTranslation('BTi Balance'),
        },
        {
            dataIndex: 'transferWinLoss',
            title: getTranslation('Win Loss'),
        },
        {
            dataIndex: 'transactionStatus',
            render: (value) => (
                <span
                    style={{
                        color: getStatusColor(value),
                    }}
                >
                    {' '}
                    {value.toLocaleUpperCase()}{' '}
                </span>
            ),
            title: getTranslation('Transaction Status'),
        },
        {
            dataIndex: 'operatorName',
            render: (value) => <ButtonGray disabled label={value} className="action-btn__verify" />,
            title: getTranslation('Operator'),
            width: 80,
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
