export const ACTIONS = {
    BET_LIST_FILTER_LIST_GET: 'BET_LIST_FILTER_LIST_GET',
    BET_LIST_FILTER_LIST_GET_FAILURE: 'BET_LIST_FILTER_LIST_GET_FAILURE',
    BET_LIST_FILTER_LIST_GET_SUCCESS: 'BET_LIST_FILTER_LIST_GET_SUCCESS',
    BET_LIST_FILTER_LIST_UPDATE: 'BET_LIST_FILTER_LIST_UPDATE',
    BET_LIST_FILTER_LIST_UPDATE_FAILURE: 'BET_LIST_FILTER_LIST_UPDATE_FAILURE',
    BET_LIST_FILTER_LIST_UPDATE_SUCCESS: 'BET_LIST_FILTER_LIST_UPDATE_SUCCESS',
    BET_LIST_GET: 'BET_LIST_GET',
    BET_LIST_GET_FAILURE: 'BET_LIST_GET_FAILURE',
    BET_LIST_GET_SUCCESS: 'BET_LIST_GET_SUCCESS',
    EXPORT_BET_LIST: 'EXPORT_BET_LIST',
    EXPORT_BET_LIST_FAILURE: 'EXPORT_BET_LIST_FAILURE',
    EXPORT_BET_LIST_SUCCESS: 'EXPORT_BET_LIST_SUCCESS',
    FILTERS_DROP_ALL: 'BET_LIST_FILTERS_DROP_ALL',
    FILTER_SET: 'BET_LIST_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'BET_LIST_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'BET_LIST_TABLE_PAGE_SIZE_CHANGE',
    },
    PLAYER_SINGLE_DETAIL_GET: 'PLAYER_SINGLE_DETAIL_GET',
    PLAYER_SINGLE_DETAIL_PAGE_CLOSE: 'PLAYER_SINGLE_DETAIL_PAGE_CLOSE',
    PLAYER_SINGLE_DETAIL_PAGE_OPEN: 'PLAYER_SINGLE_DETAIL_PAGE_OPEN',
    PLAYER_SINGLE_DETAIL_URL_GET: 'PLAYER_SINGLE_DETAIL_URL_GET',
    PLAYER_SINGLE_DETAIL_URL_GET_FAILURE: 'PLAYER_SINGLE_DETAIL_URL_GET_FAILURE',
    PLAYER_SINGLE_DETAIL_URL_GET_SUCCESS: 'PLAYER_SINGLE_DETAIL_URL_GET_SUCCESS',
    SELECTED_SINGLE_DETAIL: 'SELECTED_SINGLE_DETAIL',
};

export const betListGet = (dispatch) =>
    dispatch({
        type: ACTIONS.BET_LIST_GET,
    });

export const betListGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BET_LIST_GET_SUCCESS,
    });

export const betListGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BET_LIST_GET_FAILURE,
    });

export const betListFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const playerSingleDetailUrlGet = (dispatch) => {
    dispatch({
        type: ACTIONS.PLAYER_SINGLE_DETAIL_URL_GET,
    });
};

export const playerSingleDetailUrlGetSuccess = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.PLAYER_SINGLE_DETAIL_URL_GET_SUCCESS,
    });
};

export const playerSingleDetailUrlGetFailure = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.PLAYER_SINGLE_DETAIL_URL_GET_FAILURE,
    });
};

export const playerSingleDetailGet = (dispatch) => {
    dispatch({
        type: ACTIONS.PLAYER_SINGLE_DETAIL_GET,
    });
};

export const selectedSingleDetail = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.SELECTED_SINGLE_DETAIL,
    });
};

export const openPlayerSingleDetailPage = (dispatch) => {
    dispatch({
        type: ACTIONS.PLAYER_SINGLE_DETAIL_PAGE_OPEN,
    });
};

export const closePlayerSingleDetailPage = (dispatch) => {
    dispatch({
        type: ACTIONS.PLAYER_SINGLE_DETAIL_PAGE_CLOSE,
    });
};

export const betFilterListGet = (dispatch) =>
    dispatch({
        type: ACTIONS.BET_LIST_FILTER_LIST_GET,
    });

export const betFilterListGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BET_LIST_FILTER_LIST_GET_SUCCESS,
    });

export const betFilterListGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BET_LIST_FILTER_LIST_GET_FAILURE,
    });

export const betFilterListUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.BET_LIST_FILTER_LIST_UPDATE,
    });

export const betFilterListUpdateSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BET_LIST_FILTER_LIST_UPDATE_SUCCESS,
    });

export const betFilterListUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BET_LIST_FILTER_LIST_UPDATE_FAILURE,
    });

export const betListExport = (dispatch) =>
    dispatch({
        type: ACTIONS.EXPORT_BET_LIST,
    });

export const betListExportSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.EXPORT_BET_LIST_SUCCESS,
    });

export const betListExportFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.EXPORT_BET_LIST_FAILURE,
    });
