import React from 'react';

import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import {
    changeTotalRegistrationPageNumber,
    changeTotalRegistrationPageSize,
} from '../riskMonitorActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';

export const getColumns = () => {
    const columns = [
        {
            dataIndex: 'no',
            render: (value, item, index) => <span>{index + 1}</span>,
            title: 'No',
        },
        {
            dataIndex: 'ipAddress',
            title: getTranslation('Ip Address'),
        },
        {
            dataIndex: 'userName',
            title: getTranslation('Username'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) =>
    changeTotalRegistrationPageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) =>
    changeTotalRegistrationPageSize(dispatch, pageSize);
