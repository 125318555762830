import { useCallback, useEffect, useState } from 'react';

import { message } from 'antd';
import _ from 'lodash';

import { API } from '../common/constants/api';
import {
    betFilterListGet,
    betFilterListGetFailure,
    betFilterListGetSuccess,
    betFilterListUpdate,
    betFilterListUpdateFailure,
    betFilterListUpdateSuccess,
    betListExport,
    betListExportFailure,
    betListExportSuccess,
    betListGet,
    betListGetFailure,
    betListGetSuccess,
    playerSingleDetailGet,
    playerSingleDetailUrlGet,
    playerSingleDetailUrlGetFailure,
    playerSingleDetailUrlGetSuccess,
} from '../components/bet/bet-list/betListActions';
import { clearError } from '../common/actions/errorActions';
import { filterTrim, getStateProp, isLessThanOneWeek } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import {
    turnoverCheckGet,
    turnoverCheckGetFailure,
    turnoverCheckGetSuccess,
} from '../components/bet/turnover-check/turnoverCheckActions';
import { useDispatch, useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import exportWarningModal from '../common/components/exportWarningModal';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, BET } = API;

export function useGetBetListAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const betList = getStateProp(state, 'bet.betList');
    const filters = getStateProp(betList, 'filters', {});
    const { pageNo, pageSize } = getStateProp(betList, 'paging');
    const {
        userName,
        dateFrom,
        dateTo,
        paidTimeDateFrom,
        paidTimeDateTo,
        statementDateFrom,
        statementDateTo,
        ...restFilter
    } = filters;
    // ============================================
    // METHODS
    // ============================================

    const handleGetBetList = useCallback(() => {
        betListGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !_.every(filters, _.isEmpty),
        method: 'POST',
        options: {
            data: {
                ...filterTrim(restFilter),
                dateFrom: dateFrom || undefined,
                dateTo: dateTo || undefined,
                pageNo,
                pageSize,
                paidTimeDateFrom: paidTimeDateFrom || undefined,
                paidTimeDateTo: paidTimeDateTo || undefined,
                statementDateFrom: statementDateFrom || undefined,
                statementDateTo: statementDateTo || undefined,
                userNames: userName && [userName],
            },
        },
        trigger,
        url: `${VERSION.V1}${BET.BET_LIST}/query`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            betListGetSuccess(dispatch, {
                list: response.data.data[0].data,
                paging: response.data.paging,
                summary: {
                    totalEffectiveTurnover: response.data.data[0].totalEffectiveTurnover,
                    totalHoldStake: response.data.data[0].totalHoldStake,
                    totalPaidStake: response.data.data[0].totalPaidStake,
                    totalStake: response.data.data[0].totalStake,
                    totalTurnover: response.data.data[0].totalTurnover,
                    totalWinLoss: response.data.data[0].totalWinLoss,
                },
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        handleGetBetList();
    }, [handleGetBetList, pageNo, pageSize]);

    const errorMsg = useError(error, betListGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetBetList];
}

export function useGetDetailUrlOperation() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const selectedReference = getStateProp(state, 'bet.betList.selectedReference', '');

    // ============================================
    // METHODS
    // ============================================

    const handleGetDetailUrl = () => {
        playerSingleDetailUrlGet(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        trigger,
        url: `${VERSION.V1}${BET.DETAIL_URL}/${selectedReference.refNo}/${selectedReference.userName}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            playerSingleDetailUrlGetSuccess(dispatch, response.data);
            playerSingleDetailGet(dispatch);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, playerSingleDetailUrlGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetDetailUrl];
}

export function useGetTurnoverCheckAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const turnoverCheck = getStateProp(state, 'bet.turnoverCheck');
    const filters = getStateProp(turnoverCheck, 'filters', {});
    const userName = getStateProp(filters, 'userName');

    // ============================================
    // METHODS
    // ============================================

    const handleGetTurnoverCheck = useCallback(() => {
        turnoverCheckGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!userName,
        method: 'POST',
        options: {
            data: {
                ...filterTrim(filters),
            },
        },
        trigger,
        url: `${VERSION.V1}${BET.TURNOVER_CHECK}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            turnoverCheckGetSuccess(dispatch, {
                list: response.data.data.sportTypeGroups,
                paging: response.data.paging,
                responseData: response.data.data,
                thisMonthEffectiveTurnover: response.data.data.thisMonthEffectiveTurnover,
                totalEffectiveTurnover: response.data.data.totalEffectiveTurnover,
            });
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, turnoverCheckGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetTurnoverCheck];
}

export function useGetBetListFilterOptionsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const filterOptions = getStateProp(state, 'bet.betList.filterOptions');

    // ============================================
    // METHODS
    // ============================================

    const handleGetBetFilterList = useCallback(() => {
        betFilterListGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => _.isEmpty(filterOptions),
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${BET.BET_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            betFilterListGetSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (_.isEmpty(filterOptions)) handleGetBetFilterList();
    }, [handleGetBetFilterList, filterOptions]);

    const errorMsg = useError(error, betFilterListGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useUpdateBetListFilterOptionsAsyncEndpoint(platformCode) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateBetFilterList = () => {
        betFilterListUpdate(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'POST',
        options: {
            data: {
                platformCode,
            },
        },
        trigger,
        url: `${VERSION.V1}${BET.BET_LIST}/onplatformchanged`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            betFilterListUpdateSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, betFilterListUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [handleUpdateBetFilterList];
}

export function useExportBetListAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const betList = getStateProp(state, 'bet.betList');
    const filters = getStateProp(betList, 'filters', {});
    const offset = getStateProp(state, 'timezone.offset');

    const {
        userName,
        dateFrom,
        dateTo,
        paidTimeDateFrom,
        paidTimeDateTo,
        statementDateFrom,
        statementDateTo,
        ...restFilter
    } = filters;

    // ============================================
    // METHODS
    // ============================================

    const handleExportBetList = () => {
        if (
            !isLessThanOneWeek(dateFrom, dateTo) &&
            !isLessThanOneWeek(paidTimeDateFrom, paidTimeDateTo) &&
            !isLessThanOneWeek(statementDateFrom, statementDateTo) &&
            _.isEmpty(filters.userName) &&
            _.isEmpty(filters.refNo) &&
            _.isEmpty(filters.transNo)
        ) {
            message.warning(
                getTranslation(
                    'Please filter by Username, Reference No, or Transaction No, else date range must not be greater than 1 week',
                ),
            );
            return;
        }

        if (!_.isEmpty(filters)) {
            betListExport(dispatch);
            setTrigger(+new Date());
        } else {
            message.warning('Please do filter to before export');
        }
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !_.every(filters, _.isEmpty) && !!trigger,
        isExport: true,
        method: 'POST',
        options: {
            data: {
                ...filterTrim(restFilter),
                dateFrom: dateFrom || undefined,
                dateTo: dateTo || undefined,
                paidTimeDateFrom: paidTimeDateFrom || undefined,
                paidTimeDateTo: paidTimeDateTo || undefined,
                statementDateFrom: statementDateFrom || undefined,
                statementDateTo: statementDateTo || undefined,
                timeZone: parseInt(offset.slice(0, -2)),
                userNames: userName && [userName],
            },
        },
        trigger,
        url: `${VERSION.V1}${BET.BET_LIST_EXPORT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            exportWarningModal(true);
            betListExportSuccess(dispatch);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, betListExportFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleExportBetList];
}
