import { Redirect, Switch } from 'react-router-dom';
import React from 'react';

import { CurrencySetting, GroupAuthoritySetting, MaxBetConfigure, UserAuthoritySetting } from '.';
import { PrivateRoute } from '../../common/components';
import { ROUTES } from '../../common/constants/routing';

const Admin = () => {
    return (
        <Switch>
            <PrivateRoute
                path={ROUTES.DASHBOARD.ADMINISTRATION.GROUP_AUTHORITY.ROOT}
                component={GroupAuthoritySetting}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.ADMINISTRATION.USER_AUTHORITY_SETTING}
                component={UserAuthoritySetting}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.ADMINISTRATION.CURRENCY_SETTING}
                component={CurrencySetting}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.ADMINISTRATION.MAXBET_CONFIGURATION}
                component={MaxBetConfigure}
            />
            <Redirect to={ROUTES.DASHBOARD.ADMINISTRATION.GROUP_AUTHORITY.ROOT} />
        </Switch>
    );
};

export default Admin;
