import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { MEMBER_LEVELS } from '../../constants/misc';
import { getTranslation } from '../../helpers/locale';

function MemberLevelFilter({
    value,
    onFilterChange,
    name = 'memberLevel',
    placeholder = 'Member Level',
    mode = null,
}) {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange(name, value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear
            placeholder={getTranslation(placeholder)}
            className="filter"
            mode={mode}
        >
            {_.map(MEMBER_LEVELS, (member_level) => (
                <Select.Option key={member_level} value={member_level}>
                    {member_level}
                </Select.Option>
            ))}
        </Select>
    );
}

export default MemberLevelFilter;
