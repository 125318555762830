import React from 'react';

import { Input } from 'antd';

import { getTranslation } from '../../../common/helpers/locale';

const LoginIdFilter = ({ value, onFilterChange }) => {
    return (
        <Input
            value={value}
            onChange={(e) => onFilterChange('loginId', e.target.value)}
            placeholder={getTranslation('Login ID')}
            className="filter"
        />
    );
};

export default LoginIdFilter;
