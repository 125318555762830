import { ACTIONS } from './turnoverCheckActions';
import { getGMT_4EndTime, getGMT_4StartTime } from '../../../common/helpers/misc';

export default function turnoverCheckReducer(
    turnoverCheck = {
        filters: {
            dateFrom: getGMT_4StartTime(),
            dateTo: getGMT_4EndTime(),
        },
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.FILTER_SET:
            return {
                ...turnoverCheck,
                filters: {
                    ...turnoverCheck.filters,
                    [payload.filter]: payload.value,
                },
            };

        case ACTIONS.FILTERS_DROP_ALL:
            return {
                ...turnoverCheck,
                filters: {
                    dateFrom: getGMT_4StartTime(),
                    dateTo: getGMT_4EndTime(),
                },
            };
        case ACTIONS.TURNOVER_CHECK_GET_SUCCESS:
            return {
                ...turnoverCheck,
                ...payload,
            };
        default:
            return turnoverCheck;
    }
}
