import React, { useState } from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './BonusCategoryTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const BonusCategoryResultTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const { bonusCategory } = getStateProp(state, 'bonus');
    const tableData = getStateProp(bonusCategory, 'list', []);
    const paging = getStateProp(bonusCategory, 'paging', {});
    const [langCode, setLangCode] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleChange = (pagination, filters) => {
        filters.categoryNames ? setLangCode(filters.categoryNames[0]) : setLangCode(null);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns(langCode, dispatch)}
            rowKey={(record) => record.id}
            loading={loading}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            dataSource={tableData}
            pagination={getPaginationOptions(paging, dispatch)}
            onChange={handleChange}
        />
    );
};

export default BonusCategoryResultTable;
