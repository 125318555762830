import PropTypes from 'prop-types';
import React from 'react';

import { DatePicker, Row } from 'antd';
import classNames from 'classnames';
import moment from 'moment';

import './RangeDatePicker.less';
import { getTranslation } from '../helpers/locale';
import { validateDateTime } from '../helpers/misc';

const { RangePicker } = DatePicker;

const RangeDatePicker = ({
    dateFrom,
    dateTo,
    onPeriodChange,
    className,
    location,
    prefix = null,
    antPickerProps,
    showTime,
    picker,
    ignoreUTC, // For company report use
    disabled,
}) => {
    const dateFormat =
        picker === 'month' ? 'MM/YYYY' : showTime ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY';

    const newDateFrom = ignoreUTC ? dateFrom : validateDateTime(dateFrom);
    const newDateTo = ignoreUTC ? dateTo : validateDateTime(dateTo);

    const RANGES = {
        'Last Month': [
            moment()
                .subtract(1, 'month')
                .startOf('month')
                .utc(),
            moment()
                .subtract(1, 'month')
                .endOf('month')
                .utc(),
        ],
        'Last Week': [
            moment()
                .subtract(1, 'w')
                .startOf('isoWeek')
                .utc(),
            moment()
                .subtract(1, 'w')
                .endOf('isoWeek')
                .utc(),
        ],
        'This Month': [
            moment()
                .startOf('month')
                .utc(),
            moment()
                .endOf('month')
                .utc(),
        ],
        'This Week': [
            moment()
                .startOf('isoWeek')
                .utc(),
            moment()
                .endOf('isoWeek')
                .utc(),
        ],
        Today: [
            moment()
                .startOf('day')
                .utc(),
            moment()
                .startOf('day')
                .utc(),
        ],
        Yesterday: [
            moment()
                .subtract(1, 'd')
                .startOf('day')
                .utc(),
            moment()
                .subtract(1, 'd')
                .endOf('day')
                .utc(),
        ],
    };

    // ============================================
    // METHODS
    // ============================================

    moment.tz.setDefault(location);

    const onChangeSingleDate = (dates) => {
        const startDate = dates[0]
            ? ignoreUTC
                ? dates[0].tz(location).format('YYYY-MM-DDTHH:mm:ss.SSS')
                : dates[0].utc().format()
            : null;

        const endDate = dates[1]
            ? ignoreUTC
                ? dates[1]
                      .tz(location)
                      .millisecond(999)
                      .format('YYYY-MM-DDTHH:mm:ss.SSS')
                : dates[1]
                      .millisecond(999)
                      .utc()
                      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
            : null;

        onPeriodChange({
            dateFrom: startDate,
            dateTo: endDate,
        });

        if (
            dates[0] &&
            dates[1] &&
            dates[0].utc().format('HH:mm:ss') === dates[1].utc().format('HH:mm:ss')
        ) {
            onPeriodChange({
                dateFrom: ignoreUTC
                    ? moment(startDate)
                          .tz(location)
                          .startOf('day')
                          .format('YYYY-MM-DDTHH:mm:ss.SSS')
                    : moment(startDate)
                          .tz(location)
                          .startOf('day')
                          .utc()
                          .format(),
                dateTo: ignoreUTC
                    ? moment(endDate)
                          .tz(location)
                          .endOf('day')
                          .millisecond(999)
                          .format('YYYY-MM-DDTHH:mm:ss.SSS')
                    : moment(endDate)
                          .tz(location)
                          .endOf('day')
                          .millisecond(999)
                          .utc()
                          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            });
        }
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row
            align="middle"
            justify="space-around"
            className={classNames(['date-range-picker', className])}
        >
            <RangePicker
                {...antPickerProps}
                onCalendarChange={onChangeSingleDate}
                value={[dateFrom ? moment(newDateFrom) : null, dateTo ? moment(newDateTo) : null]}
                placeholder={
                    prefix
                        ? [
                              getTranslation(`${prefix}${' Date From'}`),
                              getTranslation(`${prefix}${' Date To'}`),
                          ]
                        : [getTranslation('Date From'), getTranslation('Date To')]
                }
                showTime={false}
                showToday={false}
                format={dateFormat}
                picker={picker}
                ranges={RANGES}
                disabled={disabled}
            />
        </Row>
    );
};

RangeDatePicker.defaultProps = {
    antPickerProps: {
        allowClear: false,
        format: 'DD/MM/YYYY HH:mm:ss',
    },
};

RangeDatePicker.propTypes = {
    antPickerProps: PropTypes.object, //refer to https://ant.design/components/date-picker
    onPeriodChange: PropTypes.func.isRequired,
};

export default RangeDatePicker;
