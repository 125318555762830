import React from 'react';

import { Row } from 'antd';

import './ActionButtons.less';
import { ButtonBlue, ButtonGreen } from '../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../common/helpers/locale';

const ActionButtons = ({ onSortCategory, onCreateCategory }) => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <Row align="middle" justify="end" className="bonus-category-buttons__container">
            <ButtonGreen label={getTranslation('Create Category')} onClick={onCreateCategory} />
            <ButtonBlue label={getTranslation('Sorting')} onClick={onSortCategory} />
        </Row>
    );
};

export default ActionButtons;
