import React from 'react';

import { Table } from 'antd';

import { getColumns } from './BannerSortingTableMeta';
import { getPaginationOptions } from '../modal/ActiveBannerTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const BannerSortingResultTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const tableData = getStateProp(state, 'bonus.bannerSorting.list', []);
    const paging = getStateProp(state, 'bonus.bannerSorting.modalDetails.paging', {});

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns()}
            rowKey={(record) => record.id}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            dataSource={tableData}
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
        />
    );
};

export default BannerSortingResultTable;
