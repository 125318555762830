import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { DownOutlined } from '@ant-design/icons';

import { Loader } from '../../../../common/components';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useStore } from '../../../../store/StateProvider';

const WinLossFilter = ({ onFilterChange, value, loading }) => {
    const [state] = useStore();
    const winLoss = getStateProp(state, 'bet.betList.filterOptions.winLoss', []);

    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('winLoss', value);
    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            className="filter"
            placeholder={getTranslation('Win Loss')}
            allowClear={true}
            suffixIcon={loading ? <Loader size="small" style={{ padding: 0 }} /> : <DownOutlined />}
        >
            {_.map(winLoss, (data) => {
                return (
                    <Select.Option key={data.value} value={data.value}>
                        {data.text.toLocaleUpperCase()}
                    </Select.Option>
                );
            })}
        </Select>
    );
};

export default WinLossFilter;
