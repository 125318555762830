import { useCallback, useEffect, useState } from 'react';

import { message } from 'antd';
import _ from 'lodash';

import { API } from '../common/constants/api';
import { BANNER_TYPES } from '../components/bonus/constants';
import {
    bannerCreate,
    bannerCreateFailure,
    bannerCreateSuccess,
    bannerFormClear,
} from '../components/bonus/create-banner/createBannerActions';
import {
    bannerEditModalClose,
    bannerManagementByIdGet,
    bannerManagementByIdGetFailure,
    bannerManagementByIdGetSuccess,
    bannerManagementClearCache,
    bannerManagementListGetFailure,
    bannerManagementListGetSuccess,
    bannerManagementStatusSetFailure,
    bannerManagementStatusSetSuccess,
    bannerManagementUpdateFailure,
    bannerManagementUpdateSuccess,
} from '../components/bonus/banner-management/bannerManagementActions';
import { clearError } from '../common/actions/errorActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import {
    getActiveBanners,
    getActiveBannersFailure,
    getActiveBannersSuccess,
    getAvailableBanners,
    getAvailableBannersFailure,
    getAvailableBannersSuccess,
    updateActiveBanners,
    updateActiveBannersFailure,
    updateActiveBannersSuccess,
} from '../components/bonus/banner-sorting/bannerSortingActions';
import { getTranslation } from '../common/helpers/locale';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, BANNER, PROMOTIONS } = API;

export function useCreateBannerOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const createBanner = getStateProp(state, 'bonus.createBanner', {});

    const { duration, currencyCode, ...rest } = createBanner;

    // ============================================
    // HOOKS
    // ============================================

    const handleCreateBanner = useCallback(() => {
        bannerCreate(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'POST',
        options: {
            data: {
                currencyCode: _.join(currencyCode, ','),
                ...rest,
            },
        },
        trigger,
        url: `${VERSION.V1}${BANNER.ROOT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bannerCreateSuccess(dispatch);
            bannerFormClear(dispatch);
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, bannerCreateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleCreateBanner, response];
}

export function useGetBannerListOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { bannerManagement } = getStateProp(state, 'bonus');
    const { pageNo, pageSize } = getStateProp(bannerManagement, 'paging');
    const filters = getStateProp(bannerManagement, 'filters', {});

    // ============================================
    // HOOKS
    // ============================================

    const handleGetBannerList = useCallback(() => {
        setTrigger(+new Date());
    }, []);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${BANNER.ROOT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);

            const startingNum = response.data.paging.pageSize * (response.data.paging.pageNo - 1);
            response.data.data.map((item, index) => {
                item.no = startingNum + index + 1;

                // to make english must put at first in list
                const length = item.details.length;
                for (let i = 0, l = length; i < l; i++) {
                    const record = item.details[i];
                    if (record.languageCode === 'en-US') {
                        if (i !== 0) {
                            const removedRecords = item.details.splice(i, 1);
                            item.details.unshift(removedRecords[0]);
                        }
                        break;
                    }
                }

                return item;
            });

            bannerManagementListGetSuccess(dispatch, {
                fetchData: handleGetBannerList,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetBannerList, response]);

    useEffect(() => {
        handleGetBannerList();
    }, [handleGetBannerList, pageNo, pageSize]);

    const errorMsg = useError(error, bannerManagementListGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetBannerList];
}

export function useSetStatusOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { bannerManagement } = getStateProp(state, 'bonus');
    const changeBannerStatus = getStateProp(bannerManagement, 'changeBannerStatus');
    const id = getStateProp(changeBannerStatus, 'id');
    const status = getStateProp(changeBannerStatus, 'status');
    const refreshTable = getStateProp(bannerManagement, 'fetchData', {});

    // ============================================
    // HOOKS
    // ============================================

    const handleSetBannerStatus = useCallback(() => {
        setTrigger(+new Date());
    }, []);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'PUT',
        options: {
            data: {},
        },
        trigger,
        url: `${VERSION.V1}${BANNER.ROOT}/${id}/status/${status}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bannerManagementClearCache(dispatch);
            bannerManagementStatusSetSuccess(dispatch);
            refreshTable();
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, bannerManagementStatusSetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleSetBannerStatus, response];
}

export function useUpdateBannerOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { bannerManagement } = getStateProp(state, 'bonus');
    const {
        id,
        bannerStatus,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy,
        currencyCode,
        ...rest
    } = getStateProp(bannerManagement, 'selectedBanner', {});
    const refreshTable = getStateProp(bannerManagement, 'fetchData', {});

    // ============================================
    // HOOKS
    // ============================================

    const handleUpdateBanner = useCallback(() => {
        setTrigger(+new Date());
    }, []);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'PUT',
        options: {
            data: {
                currencyCode: _.isArray(currencyCode) ? _.join(currencyCode, ',') : currencyCode,
                id,
                ...rest,
            },
        },
        trigger,
        url: `${VERSION.V1}${BANNER.ROOT}/${id}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bannerManagementUpdateSuccess(dispatch);
            bannerEditModalClose(dispatch);
            refreshTable();
            message.success(getTranslation('Updated'));
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, bannerManagementUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateBanner];
}

export function useGetBannerByIdOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const id = getStateProp(state, 'bonus.bannerManagement.selectedBanner.id');

    // ============================================
    // HOOKS
    // ============================================

    const handleGetBannerById = useCallback(() => {
        bannerManagementByIdGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${BANNER.ROOT}/${id}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bannerManagementByIdGetSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, bannerManagementByIdGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetBannerById];
}

export function useGetActiveBannerListAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const bannerSorting = getStateProp(state, 'bonus.bannerSorting', {});
    const { pageNo, pageSize } = getStateProp(bannerSorting, 'paging');
    const filters = getStateProp(bannerSorting, 'filters', {});

    // ============================================
    // HOOKS
    // ============================================

    const handleGetActiveBannerList = useCallback(() => {
        getActiveBanners(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'GET',
        options: {
            params: {
                ...filters,
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${BANNER.ROOT}/active`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);

            getActiveBannersSuccess(dispatch, {
                list: response.data.data,
                paging: response.data.paging,
                refreshTable: handleGetActiveBannerList,
            });
        }
    }, [dispatch, handleGetActiveBannerList, response]);

    useEffect(() => {
        handleGetActiveBannerList();
    }, [handleGetActiveBannerList, pageNo, pageSize]);

    const errorMsg = useError(error, getActiveBannersFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetActiveBannerList];
}

export function useUpdateActiveBannerAsycnEndpoint() {
    const [state, dispatch] = useStore();

    const [trigger, setTrigger] = useState(null);

    const selectedBanners = getStateProp(
        state,
        'bonus.bannerSorting.modalDetails.selectedRecords',
        [],
    );

    // ============================================
    // HOOKS
    // ============================================

    const handleUpdateBannerSorting = () => {
        updateActiveBanners(dispatch);
        setTrigger(+new Date());
    };

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'POST',
        options: {
            data: {
                activeBanners: selectedBanners,
            },
        },
        trigger,
        url: `${VERSION.V1}${BANNER.ROOT}/active`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            updateActiveBannersSuccess(dispatch);
            message.success(getTranslation('Sorting Updated'));
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, updateActiveBannersFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateBannerSorting, response];
}

export function useGetAvailableBannerListAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { bannerSorting } = getStateProp(state, 'bonus');
    const { pageNo, pageSize } = getStateProp(bannerSorting, 'modalDetails.paging');
    const { bannerType, code } = getStateProp(bannerSorting, 'modalDetails.filters', {});

    const url = bannerType === BANNER_TYPES.BANNER ? BANNER.ROOT : PROMOTIONS.QUERY;
    const statusName = bannerType === BANNER_TYPES.BANNER ? 'bannerStatus' : 'bonusStatus';
    const codeName = bannerType === BANNER_TYPES.BONUS ? 'bonusCode' : 'code';
    const method = bannerType === BANNER_TYPES.BONUS ? 'POST' : 'GET';
    const params = method === 'POST' ? 'data' : 'params';

    // ============================================
    // HOOKS
    // ============================================

    const handleGetAvailableBannerList = useCallback(() => {
        getAvailableBanners(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger && !!bannerType,
        method,
        options: {
            [params]: {
                [codeName]: code && code.trim(),
                pageNo,
                pageSize,
                [statusName]: 'Active',
            },
        },
        trigger,
        url: `${VERSION.V1}${url}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);

            const startingNum = response.data.paging.pageSize * (response.data.paging.pageNo - 1);
            response.data.data.map((item, index) => {
                item.no = startingNum + index + 1;

                // to make english must put at first in list
                const length = item.details.length;
                for (let i = 0, l = length; i < l; i++) {
                    const record = item.details[i];
                    if (record.languageCode === 'en-US') {
                        if (i !== 0) {
                            const removedRecords = item.details.splice(i, 1);
                            item.details.unshift(removedRecords[0]);
                        }
                        break;
                    }
                }

                return item;
            });

            getAvailableBannersSuccess(dispatch, {
                fetchData: handleGetAvailableBannerList,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetAvailableBannerList, response]);

    useEffect(() => {
        handleGetAvailableBannerList();
    }, [handleGetAvailableBannerList, pageNo, pageSize]);

    const errorMsg = useError(error, getAvailableBannersFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetAvailableBannerList];
}
