export const ACTIONS = {
    COLUMNS_SELECT: 'WITHDRAWAL_COLUMNS_SELECT',
    FILTERS_DROP_ALL: 'WITHDRAWALS_FILTERS_DROP_ALL',
    FILTER_SET: 'WITHDRAWALS_FILTER_SET',
    OPERATION: 'WITHDRAWALS_OPERATION',
    OPERATION_FAILURE: 'WITHDRAWALS_OPERATION_FAILURE',
    OPERATION_SUCCESS: 'WITHDRAWALS_OPERATION_SUCCESS',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'WITHDRAWALS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'WITHDRAWALS_TABLE_PAGE_SIZE_CHANGE',
    },
    REFRESH_TIME_PAUSE: 'WITHDRAWALS_REFRESH_TABLE_TIME_PAUSE',
    REFRESH_TIME_SET: 'WITHDRAWALS_REFRESH_TABLE_TIME_SET',
    REJECT_INFO_SET: 'WITHDRAWALS_REJECT_INFO_SET',
    REJECT_MODAL_CLOSE: 'WITHDRAWALS_REJECT_MODAL_CLOSE',
    REJECT_MODAL_OPEN: 'WITHDRAWALS_REJECT_MODAL_OPEN',
    VERIFY_INFO_SET: 'WITHDRAWALS_VERIFY_INFO_SET',
    VERIFY_MODAL_CLOSE: 'WITHDRAWALS_VERIFY_MODAL_CLOSE',
    VERIFY_MODAL_OPEN: 'WITHDRAWALS_VERIFY_MODAL_OPEN',
    WITHDRAWALS_DANGER_LEVEL_GET: 'WITHDRAWALS_DANGER_LEVEL_GET',
    WITHDRAWALS_DANGER_LEVEL_GET_FAILURE: 'WITHDRAWALS_DANGER_LEVEL_GET_FAILURE',
    WITHDRAWALS_DANGER_LEVEL_GET_SUCCESS: 'WITHDRAWALS_DANGER_LEVEL_GET_SUCCESS',
    WITHDRAWALS_GET: 'WITHDRAWALS_GET',
    WITHDRAWALS_GET_BANK_CARDS: 'WITHDRAWALS_GET_BANK_CARDS',
    WITHDRAWALS_GET_BANK_CARDS_FAILURE: 'WITHDRAWALS_GET_BANK_CARDS_FAILURE',
    WITHDRAWALS_GET_BANK_CARDS_SUCCESS: 'WITHDRAWALS_GET_BANK_CARDS_SUCCESS',
    WITHDRAWALS_GET_FAILURE: 'WITHDRAWALS_GET_FAILURE',
    WITHDRAWALS_GET_SUCCESS: 'WITHDRAWALS_GET_SUCCESS',
};

export const withdrawalsGet = (dispatch) =>
    dispatch({
        type: ACTIONS.WITHDRAWALS_GET,
    });

export const withdrawalsGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WITHDRAWALS_GET_SUCCESS,
    });

export const withdrawalsGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WITHDRAWALS_GET_FAILURE,
    });

export const withdrawalBankCardsGet = (dispatch) =>
    dispatch({
        type: ACTIONS.WITHDRAWALS_GET_BANK_CARDS,
    });

export const withdrawalBankCardsGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WITHDRAWALS_GET_BANK_CARDS_SUCCESS,
    });

export const withdrawalBankCardsGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WITHDRAWALS_GET_BANK_CARDS_FAILURE,
    });

export const performWithdrawalsOperation = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OPERATION,
    });

export const openVerifyModal = (dispatch) =>
    dispatch({
        type: ACTIONS.VERIFY_MODAL_OPEN,
    });

export const closeVerifyModal = (dispatch) =>
    dispatch({
        type: ACTIONS.VERIFY_MODAL_CLOSE,
    });

export const openRejectModal = (dispatch) =>
    dispatch({
        type: ACTIONS.REJECT_MODAL_OPEN,
    });

export const closeRejectModal = (dispatch) =>
    dispatch({
        type: ACTIONS.REJECT_MODAL_CLOSE,
    });

export const withdrawalsOperationSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.OPERATION_SUCCESS,
    });

export const withdrawalsOperationFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OPERATION_FAILURE,
    });

export const withdrawalVerifyInfoSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.VERIFY_INFO_SET,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const withdrawalsFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const withdrawalsRejectInfoSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REJECT_INFO_SET,
    });

export const setRefreshTableTime = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.REFRESH_TIME_SET,
    });
};

export const pauseRefreshTableTime = (dispatch) => {
    dispatch({
        type: ACTIONS.REFRESH_TIME_PAUSE,
    });
};

export const selectColumns = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.COLUMNS_SELECT,
    });

export const getWithdrawDangerLevel = (dispatch) =>
    dispatch({
        type: ACTIONS.WITHDRAWALS_DANGER_LEVEL_GET,
    });

export const getWithdrawDangerLevelSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WITHDRAWALS_DANGER_LEVEL_GET_SUCCESS,
    });

export const getWithdrawDangerLevelFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WITHDRAWALS_DANGER_LEVEL_GET_FAILURE,
    });
