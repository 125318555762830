import React from 'react';

import { Table } from 'antd';

import { WITHDRAWAL_RECORD_TABLE_COLUMNS } from '../constants';
import { getColumns, getPaginationOptions } from './WithdrawalRecordTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const WithdrawalRecordResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const { withdrawalRecords } = getStateProp(state, 'transactionHistory');
    const tableData = getStateProp(withdrawalRecords, 'list', []);
    const paging = getStateProp(withdrawalRecords, 'paging', {});
    const selectedColumns = getStateProp(
        withdrawalRecords,
        'selectedColumns',
        WITHDRAWAL_RECORD_TABLE_COLUMNS,
    );

    return (
        <Table
            id="withdrawal-record-table"
            columns={getColumns(selectedColumns)}
            size="small"
            scroll={{ x: true }}
            align="left"
            dataSource={tableData}
            rowKey={(record) => record.transactionNo}
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
            bordered
        />
    );
};

export default WithdrawalRecordResultsTable;
