import React, { useEffect } from 'react';

import { Modal, Row } from 'antd';

import { ANNOUNCEMENT_STATUSES } from '../../../constants';
import { ButtonGray, ButtonGreen } from '../../../../../common/components/buttons/Buttons';
import { closeMessageDeleteModal } from '../personalMessageActions';
import { getStateProp } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useStore } from '../../../../../store/StateProvider';
import { useUpdateMessageAsyncEndpoint } from '../../../../../services/messageService';

const DeleteMessageModal = ({ onCancel, visible }) => {
    const [state, dispatch] = useStore();

    const selectedMessage = getStateProp(
        state,
        'announcement.announcementVerification.personalMessage.messageDelete',
        {},
    );
    const refreshTable = getStateProp(
        state,
        'announcement.announcementVerification.personalMessage.fetchData',
        () => {},
    );

    // ============================================
    // HOOKS
    // ============================================

    const [deleting, handleDeleteMessage, messageDeleted] = useUpdateMessageAsyncEndpoint(
        selectedMessage,
        ANNOUNCEMENT_STATUSES.DELETED,
    );

    useEffect(() => {
        if (messageDeleted) {
            closeMessageDeleteModal(dispatch);
            refreshTable();
        }
    }, [dispatch, messageDeleted, refreshTable]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={getTranslation('Confirm to Delete?')}
            centered
            closable={false}
            className="message__confirm-delete-modal"
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        label={getTranslation('Confirm')}
                        onClick={handleDeleteMessage}
                        loading={deleting}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
        >
            {getTranslation(
                "Be noticed that, you can't get back the record once you delete the message.",
            )}
        </Modal>
    );
};

export default DeleteMessageModal;
