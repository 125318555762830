import { ACTIONS } from './messageCreateActions';
import { getCurrentTime } from '../../../common/helpers/misc';

export default function messageCreateReducer(
    messageCreate = {
        durationEnd: getCurrentTime(),
        durationStart: getCurrentTime(),
        verifyRecipientModalVisible: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.MESSAGE_DRAFT_INFO_SET:
            return {
                ...messageCreate,
                [payload.name]: payload.value,
            };
        case ACTIONS.MESSAGE_DRAFT_RESET:
            return {
                messageCreate: null,
            };
        case ACTIONS.MESSAGE_USERNAME_VERIFY_MODAL_OPEN:
            return {
                ...messageCreate,
                verifyRecipientModalVisible: true,
            };
        case ACTIONS.MESSAGE_USERNAME_VERIFY_MODAL_CLOSE:
            return {
                ...messageCreate,
                verifyRecipientModalVisible: false,
            };
        case ACTIONS.MESSAGE_RECIPIENTS_SET:
            return {
                ...messageCreate,
                recipientList: payload,
            };
        case ACTIONS.MESSAGE_VERIFY_USERNAMES_SUCCESS:
            return {
                ...messageCreate,
                ...payload,
            };
        case ACTIONS.ACCEPT_RECIPIENTS:
            return {
                ...messageCreate,
                acceptedRecipients: payload,
            };
        case ACTIONS.DROP_RECIPIENTS:
            return {
                ...messageCreate,
                acceptedRecipients: null,
                verifiedResults: null,
            };
        default:
            return messageCreate;
    }
}
