import React from 'react';

import { Checkbox } from 'antd';
import _ from 'lodash';

import { DEPOSIT_PROHIBITION } from '../constants';
import { RenderDateFormat } from '../../../../common/components';
import { getStateProp, sortTableByDataType } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import SettingColumnActionButtons from './SettingColumnActionButtons';

export const getColumns = (handleDangerLevelSettingModal) => {
    const renderWithdrawalProhibition = (record) => {
        const withdrawalProhibitionStatus = getStateProp(
            record,
            'withdrawal.withdrawalProhibitionStatus',
        );
        return (
            <span
                className={
                    withdrawalProhibitionStatus === 'Restricted' ? 'danger-level__text--red' : ''
                }
            >
                {withdrawalProhibitionStatus}
            </span>
        );
    };

    const renderDisplayText = (key, record, showYesNo) => {
        const status = record.bonus[key];
        return (
            <span className={!status ? 'danger-level__text--red' : ''}>
                {status
                    ? getTranslation(showYesNo ? 'Yes' : 'Enabled')
                    : getTranslation(showYesNo ? 'No' : 'Disabled')}
            </span>
        );
    };

    const renderDepositProhibition = (record) => {
        const deposit = getStateProp(record, 'deposit');
        let value = [];

        _.map(deposit, (e, key) => {
            if (e) value.push(key);
        });

        return (
            <Checkbox.Group value={value} disabled>
                {_.map(DEPOSIT_PROHIBITION, (e) => {
                    return (
                        <div key={record.dangerLevel + '-' + e.key}>
                            <Checkbox value={e.key}>{e.name}</Checkbox>
                        </div>
                    );
                })}
            </Checkbox.Group>
        );
    };

    const columns = [
        {
            dataIndex: 'dangerLevel',
            title: getTranslation('Danger Level'),
        },
        {
            dataIndex: 'dangerLevelTag',
            title: getTranslation('Danger Level Tag'),
        },
        {
            children: [
                {
                    dataIndex: 'joinBonus',
                    render: (value, record) => renderDisplayText('joinBonus', record, false),
                    title: getTranslation('Join Bonus'),
                },
                {
                    dataIndex: 'rebateGeneratorStatus',
                    render: (value, record) =>
                        renderDisplayText('rebateGeneratorStatus', record, false),
                    title: getTranslation('Rebate Generator'),
                },
                {
                    dataIndex: 'dailyLoginStatus',
                    render: (value, record) => renderDisplayText('dailyLoginStatus', record, false),
                    title: getTranslation('Daily Login'),
                },
                {
                    dataIndex: 'vipPayoutStatus',
                    render: (value, record) => renderDisplayText('vipPayoutStatus', record, true),
                    title: getTranslation('VIP Payout'),
                },
            ],
            title: getTranslation('Bonus'),
        },
        {
            children: [
                {
                    dataIndex: 'depositProhibitionStatus',
                    render: (value, record) => renderDepositProhibition(record),
                    title: getTranslation('Deposit Prohibition'),
                },
            ],
            title: getTranslation('Deposit'),
        },
        {
            children: [
                {
                    dataIndex: 'withdrawalProhibitionStatus',
                    render: (value, record) => renderWithdrawalProhibition(record),
                    title: getTranslation('Withdrawal Prohibition'),
                },
            ],
            title: getTranslation('Withdrawal'),
        },
        {
            dataIndex: 'lastUpdatedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Status Last Updated at'),
        },
        {
            render: (value, record) => (
                <SettingColumnActionButtons
                    record={record}
                    handleDangerLevelSettingModal={handleDangerLevelSettingModal}
                />
            ),
            title: getTranslation('Action'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};
