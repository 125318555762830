import { useCallback, useEffect, useState } from 'react';

import { API } from '../common/constants/api';
import { clearError } from '../common/actions/errorActions';
import {
    depositRecordsGet,
    depositRecordsGetFailure,
    depositRecordsGetSuccess,
} from '../components/transaction-history/deposit-records/depositRecordActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, TRANSACTION_HISTORY } = API;

export function useGetDepositRecordsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { depositRecords } = getStateProp(state, 'transactionHistory');
    const { pageNo, pageSize } = getStateProp(depositRecords, 'paging');
    const filters = getStateProp(depositRecords, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetDepositRecords = useCallback(() => {
        depositRecordsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${TRANSACTION_HISTORY.DEPOSIT_RECORDS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            depositRecordsGetSuccess(dispatch, {
                dataTotal: response.data.data[0].dataTotal,
                fetchData: handleGetDepositRecords,
                grandTotal: response.data.data[0].grandTotal,
                list: response.data.data[0].data,
                pageDataTotal: response.data.data[0].pageDataTotal,
                pageTotal: response.data.data[0].pageTotal,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetDepositRecords, response]);

    useEffect(() => {
        handleGetDepositRecords();
    }, [handleGetDepositRecords, pageNo, pageSize]);

    const errorMsg = useError(error, depositRecordsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetDepositRecords];
}
