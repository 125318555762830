import React from 'react';

import { Col } from 'antd';

import { NOTIFICATION_TYPES } from '../../constants';
import { addLanguage, removeLanguage, updatePushNotification } from '../../helpers';
import {
    addTargetPushNotificationLang,
    removeTargetPushNotificationLang,
    updateTargetPushNotificationInfo,
    updateTargetPushNotificationInfoByLang,
} from './targetCreatePushNotificationActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { useCreatePushNotificationAsyncEndpoint } from '../../../../services/pushNotificationService';
import { useStore } from '../../../../store/StateProvider';
import TargetPushNotificationDetails from '../../shared/target/TargetPushNotificationDetails';

const TargetPushNotification = () => {
    const [state, dispatch] = useStore();
    const { pushNotificationInfo, userNames, currency, targetType } = getStateProp(
        state,
        'pushNotification.createPushNotification.targetCreatePushNotification',
        [],
    );

    // ============================================
    // METHODS
    // ============================================
    const handleLanguageSet = (e) => {
        if (e.target.checked) {
            addTargetPushNotificationLang(
                dispatch,
                addLanguage(pushNotificationInfo, e.target.value),
            );
        } else {
            removeTargetPushNotificationLang(
                dispatch,
                removeLanguage(pushNotificationInfo, e.target.value),
            );
        }
    };

    const handleUpdateInfoByLanguage = (value, languageCode, key) => {
        updateTargetPushNotificationInfoByLang(
            dispatch,
            updatePushNotification(pushNotificationInfo, value, languageCode, key),
        );
    };

    const handleUpdateInfo = (value, name) => {
        updateTargetPushNotificationInfo(dispatch, { name, value });
    };

    // ============================================
    // HOOKS
    // ============================================

    const [creating, handleCreatePushNotification] = useCreatePushNotificationAsyncEndpoint(
        NOTIFICATION_TYPES.TARGET,
    );

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col>
            <TargetPushNotificationDetails
                targetAspect={targetType}
                pushNotificationInfo={pushNotificationInfo}
                handleLanguageSet={handleLanguageSet}
                handleUpdateInfoByLanguage={handleUpdateInfoByLanguage}
                handleUpdateInfo={handleUpdateInfo}
                usernames={userNames && userNames.userName}
                currency={currency}
                creating={creating}
                formSubmit={handleCreatePushNotification}
            />
        </Col>
    );
};

export default TargetPushNotification;
