import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './PlayerIpTableMeta';
import { getStateProp } from '../../../../../common/helpers/misc';
import { useStore } from '../../../../../store/StateProvider';

const PlayerIpTable = ({ loading }) => {
    const [state, dispatch] = useStore();
    const playerIpList = getStateProp(state, 'players.registrationDetails.playerIpList', {});
    const tableData = getStateProp(playerIpList, 'list', []);
    const paging = getStateProp(playerIpList, 'paging', {});

    return (
        <Table
            id="results-table"
            columns={getColumns()}
            pagination={getPaginationOptions(paging, dispatch)}
            dataSource={tableData}
            rowKey={(record, index) => index}
            loading={loading}
            size="small"
            align="left"
            bordered
        />
    );
};

export default PlayerIpTable;
