export const ACTIONS = {
    CONFIGURATION_FORM_VALUE_SET: 'MERCHANT_CONFIGURATION_FORM_VALUE_SET',
    CONFIRM_MODAL_CLOSE: 'MERCHANT_CONFIGURATION_CONFIRM_MODAL_CLOSE',
    CONFIRM_MODAL_OPEN: 'MERCHANT_CONFIGURATION_CONFIRM_MODAL_OPEN',
    FILTER_DROP: 'MERCHANT_CONFIGURATION_FILTER_DROP',
    FILTER_SET: 'MERCHANT_CONFIGURATION_FILTER_SET',
    MERCHANT_CONFIGURATION_FORM_SUBMIT: 'MERCHANT_CONFIGURATION_FORM_SUBMIT',
    MERCHANT_CONFIGURATION_FORM_SUBMIT_FAILURE: 'MERCHANT_CONFIGURATION_FORM_SUBMIT_FAILURE',
    MERCHANT_CONFIGURATION_FORM_SUBMIT_SUCCESS: 'MERCHANT_CONFIGURATION_FORM_SUBMIT_SUCCESS',
    MERCHANT_CONFIGURATION_GET: 'MERCHANT_CONFIGURATION_GET',
    MERCHANT_CONFIGURATION_GET_FAILURE: 'MERCHANT_CONFIGURATION_GET_FAILURE',
    MERCHANT_CONFIGURATION_GET_SUCCESS: 'MERCHANT_CONFIGURATION_GET_SUCCESS',
    MERCHANT_CONFIGURATION_PAY_SERVICE_SELECTED: 'MERCHANT_CONFIGURATION_PAY_SERVICE_SELECTED',
    MERCHANT_CONFIGURATION_PAY_SERVICE_UPDATE: 'MERCHANT_CONFIGURATION_PAY_SERVICE_UPDATE',
    MERCHANT_CONFIGURATION_PAY_SERVICE_UPDATE_FAILURE:
        'MERCHANT_CONFIGURATION_PAY_SERVICE_UPDATE_FAILURE',
    MERCHANT_CONFIGURATION_PAY_SERVICE_UPDATE_SUCCESS:
        'MERCHANT_CONFIGURATION_PAY_SERVICE_UPDATE_SUCCESS',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'MERCHANT_CONFIGURATION_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'MERCHANT_CONFIGURATION_TABLE_PAGE_SIZE_CHANGE',
    },
};

export const formValueSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CONFIGURATION_FORM_VALUE_SET,
    });

export const onOpenConfirmModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CONFIRM_MODAL_OPEN,
    });

export const onCloseConfirmModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_MODAL_CLOSE,
    });

export const merchantConfigurationGet = (dispatch) =>
    dispatch({
        type: ACTIONS.MERCHANT_CONFIGURATION_GET,
    });

export const merchantConfigurationGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MERCHANT_CONFIGURATION_GET_SUCCESS,
    });

export const merchantConfigurationGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MERCHANT_CONFIGURATION_GET_FAILURE,
    });

export const merchantConfigurationFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const merchantConfigurationDropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTER_DROP,
    });

export const merchantConfiguration = (dispatch) =>
    dispatch({
        type: ACTIONS.MERCHANT_CONFIGURATION_FORM_SUBMIT,
    });

export const merchantConfigurationFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MERCHANT_CONFIGURATION_FORM_SUBMIT_FAILURE,
    });

export const merchantConfigurationSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.MERCHANT_CONFIGURATION_FORM_SUBMIT_SUCCESS,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const merchantConfigurationPayServiceSelected = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MERCHANT_CONFIGURATION_PAY_SERVICE_SELECTED,
    });

export const merchantConfigurationPayServiceUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.MERCHANT_CONFIGURATION_PAY_SERVICE_UPDATE,
    });

export const merchantConfigurationPayServiceUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MERCHANT_CONFIGURATION_PAY_SERVICE_UPDATE_FAILURE,
    });

export const merchantConfigurationPayServiceUpdateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.MERCHANT_CONFIGURATION_PAY_SERVICE_UPDATE_SUCCESS,
    });
