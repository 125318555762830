import React, { useEffect, useState } from 'react';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Col, Form, Input, List, Row, Skeleton, Switch, Tabs } from 'antd';

import './CryptoMerchantConfigurationForm.less';
import { ButtonGreen } from '../../../common/components/buttons/Buttons';
import { CRYPTO_METHODS } from '../constants';
import { MEMBER_LEVELS, TRUST_LEVELS } from '../../../common/constants/misc';
import { convertStringToArray, getStateProp } from '../../../common/helpers/misc';
import { cryptoMerchantConfigurationCryptoTypeUpdate } from './cryptoMerchantConfigurationActions';
import { getTranslation } from '../../../common/helpers/locale';
import {
    useCryptoMerchantConfigurationOperationAsyncEndpoint,
    useGetCryptoMerchantConfigurationRecordAsyncEndpoint,
} from '../../../services/cryptoMerchantConfigurationServices';
import { useStore } from '../../../store/StateProvider';
import CheckboxInput from '../merchant-configuration/table/input-field/CheckboxInput';

const CryptoMerchantConfigurationForm = () => {
    const [form] = Form.useForm();
    const { resetFields, setFieldsValue } = form;
    const [state, dispatch] = useStore();
    const { cryptoMerchant } = getStateProp(state, 'payments', {});
    const [formValues, setFormValues] = useState({});

    // ============================================
    // METHODS
    // ============================================

    const handleCryptoTypeEnable = (value, index, item) => {
        cryptoMerchantConfigurationCryptoTypeUpdate(dispatch, { index, item, value });
    };

    const submitForm = (values) => {
        setFormValues(values);
        handleCryptoMerchantUpdate();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [
        loading,
        responseSuccess,
        handleCryptoMerchantUpdate,
    ] = useCryptoMerchantConfigurationOperationAsyncEndpoint(formValues);

    const [
        merchantLoading,
        handleCryptoMerchantRecordGet,
    ] = useGetCryptoMerchantConfigurationRecordAsyncEndpoint();

    useEffect(() => {
        setFieldsValue({
            depositMemberLevel: convertStringToArray(
                getStateProp(cryptoMerchant, 'depositMemberLevel', ''),
            ),
            depositShow: getStateProp(cryptoMerchant, 'depositShow', false),
            depositTrustLevel: convertStringToArray(
                getStateProp(cryptoMerchant, 'depositTrustLevel', ''),
            ),
            remark: getStateProp(cryptoMerchant, 'remark', ''),
            withdrawMemberLevel: convertStringToArray(
                getStateProp(cryptoMerchant, 'withdrawMemberLevel', ''),
            ),
            withdrawShow: getStateProp(cryptoMerchant, 'withdrawShow', false),
            withdrawTrustLevel: convertStringToArray(
                getStateProp(cryptoMerchant, 'withdrawTrustLevel', ''),
            ),
        });
    }, [cryptoMerchant, setFieldsValue]);

    useEffect(() => {
        if (responseSuccess) {
            resetFields();
            handleCryptoMerchantRecordGet();
        }
    }, [responseSuccess, resetFields, handleCryptoMerchantRecordGet]);

    // ============================================
    // RENDER
    // ============================================

    return merchantLoading ? (
        <Skeleton active paragraph={{ rows: 10 }} />
    ) : (
        <Form
            form={form}
            name="crypto-merchant-configuration"
            className="crypto-merchant-configuration__form"
            onFinish={submitForm}
        >
            <Row>
                <Col span={24} className="crypto-merchant-configuration__form-column">
                    <Row className="form__group">
                        <Col span={8}>
                            <label>{getTranslation('Crypto Name')}</label>
                        </Col>
                        <Col span={14}>
                            <label>{cryptoMerchant.displayName}</label>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={8}>
                            <label>{getTranslation('Crypto Code')}</label>
                        </Col>
                        <Col span={14}>
                            <label>{cryptoMerchant.gatewayName}</label>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={8}>
                            <label>{getTranslation('Crypto Number')}</label>
                        </Col>
                        <Col span={14}>
                            <label>{cryptoMerchant.apiGateway}</label>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={8}>
                            <label>{getTranslation('Crypto Domain')}</label>
                        </Col>
                        <Col span={14}>
                            <label>{cryptoMerchant.apiDomain}</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Tabs>
                                {CRYPTO_METHODS.map((item) => (
                                    <Tabs.TabPane key={item} tab={getTranslation(item)}>
                                        <Row className="form__group">
                                            <Col span={8}>
                                                <label>{getTranslation('Trust Level')}</label>
                                            </Col>
                                            <Col span={14}>
                                                <CheckboxInput
                                                    options={TRUST_LEVELS}
                                                    name={`${item.toLocaleLowerCase()}TrustLevel`}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="form__group">
                                            <Col span={8}>
                                                <label>{getTranslation('Member Level')}</label>
                                            </Col>
                                            <Col span={14}>
                                                <CheckboxInput
                                                    options={MEMBER_LEVELS}
                                                    name={`${item.toLocaleLowerCase()}MemberLevel`}
                                                />
                                            </Col>
                                        </Row>

                                        <Row style={{ paddingBottom: '15px' }}>
                                            <Col span={8}>
                                                <label>{getTranslation('Crypto Type')}</label>
                                            </Col>
                                            <Col span={14}>
                                                <List
                                                    bordered={true}
                                                    itemLayout="horizontal"
                                                    dataSource={
                                                        item === 'Deposit'
                                                            ? cryptoMerchant.depositList
                                                            : cryptoMerchant.withdrawList
                                                    }
                                                    renderItem={(crypto, index) => (
                                                        <List.Item>
                                                            <Col span={8}>
                                                                <label>{crypto.name}</label>
                                                            </Col>
                                                            <Switch
                                                                span={4}
                                                                checkedChildren={<CheckOutlined />}
                                                                unCheckedChildren={
                                                                    <CloseOutlined />
                                                                }
                                                                checked={crypto.active}
                                                                onChange={(value) =>
                                                                    handleCryptoTypeEnable(
                                                                        value,
                                                                        index,
                                                                        item,
                                                                    )
                                                                }
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{ paddingBottom: '15px' }}>
                                            <Col span={8}>
                                                <label>{getTranslation('Web Show')}</label>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    name={`${item.toLocaleLowerCase()}Show`}
                                                    valuePropName="checked"
                                                >
                                                    <Switch
                                                        checkedChildren={<CheckOutlined />}
                                                        unCheckedChildren={<CloseOutlined />}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Tabs.TabPane>
                                ))}
                            </Tabs>
                        </Col>
                    </Row>

                    <Row className="form__group">
                        <Col span={8}>
                            <label>{getTranslation('Remark')}</label>
                        </Col>
                        <Col span={14}>
                            <Form.Item name="remark">
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="form__footer-row" justify="end">
                <ButtonGreen
                    label={getTranslation('Update')}
                    className="form__submit-button"
                    loading={loading}
                    htmlType="submit"
                />
            </Row>
        </Form>
    );
};

export default CryptoMerchantConfigurationForm;
