import React from 'react';

import { Button, Select, Tooltip } from 'antd';
import { PauseOutlined, ReloadOutlined } from '@ant-design/icons';
import _ from 'lodash';

import './TableRefreshSelector.less';
import { TABLE_REFRESH_TIMES } from '../constants/misc';
import { getTranslation } from '../helpers/locale';

const TableRefreshSelector = ({ onTimeChange, timeOut, onTimePause, onTimeStart }) => {
    return (
        <div className="refresh-time__container">
            <Tooltip placement="top" title={getTranslation('Refresh Table By Every')}>
                <Select
                    className="time-refresh__filter"
                    value={timeOut}
                    onChange={onTimeChange}
                    placeholder={getTranslation('Pause')}
                >
                    {_.map(TABLE_REFRESH_TIMES, (times) => (
                        <Select.Option key={times} value={times}>
                            {times}
                            {'s'}
                        </Select.Option>
                    ))}
                </Select>
            </Tooltip>
            {timeOut ? (
                <Tooltip placement="top" title={getTranslation('Pause')}>
                    <Button
                        icon={<PauseOutlined />}
                        className="pause-action__button"
                        onClick={onTimePause}
                    />
                </Tooltip>
            ) : (
                <Tooltip placement="top" title={getTranslation('Refresh')}>
                    <Button
                        icon={<ReloadOutlined />}
                        className="pause-action__button"
                        onClick={onTimeStart}
                    />
                </Tooltip>
            )}
        </div>
    );
};

export default TableRefreshSelector;
