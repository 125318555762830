import React from 'react';

import { ButtonTransparent } from '../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../common/helpers/locale';
import { openPlayerSingleDetailPage, selectedSingleDetail } from '../betListActions';
import { useDispatch } from '../../../../store/StateProvider';

const ShortDetails = ({
    refNo,
    userName,
    handleGetDetailUrl,
    detail,
    platform,
    showDetailButton,
}) => {
    const dispatch = useDispatch();

    const getDetailUrl = () => {
        selectedSingleDetail(dispatch, { refNo, userName });
        handleGetDetailUrl();
        openPlayerSingleDetailPage(dispatch);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <div style={{ width: '200px' }}>
            <p>{detail.gameName && detail.gameName}</p>
            <p>{detail.betType}</p>
            <p>{detail.sportsType}</p>
            <p>{detail.detail}</p>
            <p>
                {detail.isJackpot
                    ? getTranslation('Is Jackpot')
                    : detail.isPromo
                    ? getTranslation('Is Promo')
                    : null}
            </p>
            {detail.promoEffectiveTurnover && (
                <p>
                    {getTranslation('Promo Effective Turnover')} :{detail.promoEffectiveTurnover}
                </p>
            )}
            {showDetailButton && (
                <ButtonTransparent
                    type="button"
                    onClick={getDetailUrl}
                    style={{ fontSize: '11px', width: '70px' }}
                >
                    {getTranslation('View')}
                </ButtonTransparent>
            )}
        </div>
    );
};

export default ShortDetails;
