import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { AFFILIATE_STATUS } from '../../../common/constants/misc';
import { getTranslation } from '../../../common/helpers/locale';

const AffiliateStatusFilter = ({ value, onFilterChange, name, placeholder }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange(name, value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear
            placeholder={placeholder}
            className="filter"
        >
            {_.map(_.entries(AFFILIATE_STATUS), ([key, status]) => (
                <Select.Option value={status} key={key}>
                    {getTranslation(status).toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
};

export default AffiliateStatusFilter;
