import React, { useState } from 'react';

import { Table } from 'antd';
import { getColumns } from './SortingTableMeta';

const SortingTable = ({ dispatch, tableData, loading }) => {
    const [langCode, setLangCode] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleChange = (pagination, filters) => {
        filters.bonusCategory ? setLangCode(filters.bonusCategory[0]) : setLangCode(null);
    };

    return (
        <Table
            columns={getColumns(dispatch, tableData, langCode)}
            rowKey={(record) => record.id}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            dataSource={tableData}
            loading={loading}
            className="sorting-table"
            onChange={handleChange}
        />
    );
};

export default SortingTable;
