import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { PAYMENT_ACCOUNT_SETUP_TYPES } from '../../../common/constants/misc';
import { getTranslation } from '../../../common/helpers/locale';

const AccountSetupTypeFilter = ({ value, onFilterChange }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('type', value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear={true}
            placeholder={getTranslation('Type')}
            className="filter"
        >
            <Select.Option value="All" key="All">
                {getTranslation('All')}
            </Select.Option>

            {_.map(PAYMENT_ACCOUNT_SETUP_TYPES, (types) => (
                <Select.Option value={types} key={types}>
                    {types.toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
};

export default AccountSetupTypeFilter;
