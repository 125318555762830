import React, { useEffect } from 'react';

import { Checkbox, Col, Divider, Row, message } from 'antd';
import _ from 'lodash';

import './AnnouncementForm.less';
import {
    AnnouncementCategoryFilter,
    AnnouncementOutcomeFilter,
    AnnouncementTypeFilter,
} from '../filters';
import { ButtonGray, ButtonGreen } from '../../../common/components/buttons/Buttons';
import { CURRENCIES, LANGUAGES } from '../../../common/constants/localization';
import { PeriodFilter } from '../../../common/components/filters/.';
import {
    dropAnnouncementDraftLang,
    resetAnnouncementDraft,
    setAnnouncementDraftContent,
    setAnnouncementDraftCurrency,
    setAnnouncementDraftInfo,
    setAnnouncementDraftLang,
} from './announcementCreateActions';
import {
    editorDefaultStylingSizeSet,
    getEndTime,
    getStartTime,
    getStateProp,
    textEditorImageFileSizeCheck,
} from '../../../common/helpers/misc';
import {
    getSupportedCurrenciesByCountry,
    getSupportedLanguages,
    getTranslation,
} from '../../../common/helpers/locale';
import { useCreateAnnouncementAsyncEndpoint } from '../../../services/announcementService';
import { useStore } from '../../../store/StateProvider';
import AnnouncementTabs from './AnnouncementTabs';

const AnnouncementForm = () => {
    const [state, dispatch] = useStore();

    const location = getStateProp(state, 'timezone.location');
    const announcementCreate = getStateProp(state, 'announcement.announcementCreate', {});
    const announcementItems = getStateProp(announcementCreate, 'announcementItems', []);
    const {
        category,
        announcementType,
        outcome,
        durationEnd,
        durationStart,
        announcementDraftId,
    } = announcementCreate;

    const selectedCurrency = _.uniq(
        _.map(announcementItems, (item) => {
            return item.currency;
        }),
    );

    const supportedCurrency = _.map(
        getSupportedCurrenciesByCountry(),
        (currency) => currency.currency,
    );

    // ============================================
    // METHODS
    // ============================================

    const setAnnouncementLang = (e, currency) => {
        e.target.checked
            ? setAnnouncementDraftLang(dispatch, { currency, langCode: e.target.value })
            : dropAnnouncementDraftLang(dispatch, { currency, langCode: e.target.value });
    };

    const setAnnouncementCurrency = (currencies) => {
        let filterCurrencies = _.filter(
            currencies,
            (currency) => !_.includes(selectedCurrency, currency),
        );
        let newAnnouncementCurrency = _.filter(announcementItems, (item) =>
            _.includes(currencies, item.currency),
        );
        _.forEach(filterCurrencies, (currency) => {
            getSupportedLanguages().length === LANGUAGES.length
                ? newAnnouncementCurrency.push(
                      {
                          content: '',
                          currency: currency,
                          languageCode: 'en-US',
                          title: '',
                      },
                      {
                          content: '',
                          currency: currency,
                          languageCode: 'zh-CN',
                          title: '',
                      },
                  )
                : _.forEach(getSupportedLanguages(), (lang) => {
                      newAnnouncementCurrency.push({
                          content: '',
                          currency: currency,
                          languageCode: lang.value,
                          title: '',
                      });
                  });
        });
        setAnnouncementDraftCurrency(dispatch, newAnnouncementCurrency);
    };

    const onContentChange = (dataKey, data, languageCode, currency) => {
        setAnnouncementDraftContent(dispatch, {
            currency,
            data: editorDefaultStylingSizeSet(data),
            dataKey,
            languageCode,
        });
    };

    const onInputChange = (name, value) => setAnnouncementDraftInfo(dispatch, { name, value });

    const resetForm = () =>
        resetAnnouncementDraft(dispatch, {
            durationEnd: getEndTime(location),
            durationStart: getStartTime(location),
        });

    const formValidation = () => {
        const imageReg = /<\s*img[^>]*>/g;

        const currencyValidation = () => {
            if (_.isEmpty(selectedCurrency)) {
                message.error(getTranslation('Please select currency'));
                return false;
            } else {
                return true;
            }
        };

        const contentValidation = _.map(announcementItems, (content) => {
            if (_.some(content, _.isEmpty) || !_.replace(content.content, '<p></p>', '')) {
                message.error(getTranslation('Please fill in the content in all languages'));
                return false;
            } else if (content.content.match(imageReg)) {
                return textEditorImageFileSizeCheck(content.content.match(imageReg));
            } else {
                return true;
            }
        });

        const categoryValidation = () => {
            if (category === undefined) {
                message.error(getTranslation('Please select category'));
                return false;
            } else {
                return true;
            }
        };

        const announcementTypeValidation = () => {
            if (announcementType === undefined) {
                message.error(getTranslation('Please select type'));
                return false;
            } else {
                return true;
            }
        };

        const outcomeValidation = () => {
            if (outcome === undefined) {
                message.error(getTranslation('Please select outcome'));
                return false;
            } else {
                return true;
            }
        };

        return (
            _.every(contentValidation) &&
            outcomeValidation() &&
            announcementTypeValidation() &&
            categoryValidation() &&
            currencyValidation()
        );
    };

    const submitForm = () => {
        if (formValidation()) {
            handleAddAnnouncement();
        }
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleAddAnnouncement, created] = useCreateAnnouncementAsyncEndpoint(
        announcementCreate,
    );

    useEffect(() => {
        if (created) {
            resetAnnouncementDraft(dispatch, {
                durationEnd: getEndTime(location),
                durationStart: getStartTime(location),
            });
        }
    }, [created, dispatch, location]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <div className="announcement-form">
            <Row>
                <Col span={12} className="announcement__form-column">
                    <Row className="form__group">
                        <Col span={8}>
                            <label className="form__label">
                                {getTranslation('Category')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={14} className="filters-container">
                            <AnnouncementCategoryFilter
                                onFilterChange={onInputChange}
                                value={category}
                            />
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={8}>
                            <label className="form__label">
                                {getTranslation('Type')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={14} className="filters-container">
                            <AnnouncementTypeFilter
                                onFilterChange={onInputChange}
                                value={announcementType}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={12} className="announcement__form-column">
                    <Row className="form__group">
                        <Col span={8}>
                            <label className="form__label">
                                {getTranslation('Duration')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={14}>
                            <PeriodFilter
                                onFilterChange={onInputChange}
                                dateFrom={durationStart}
                                dateFromName="durationStart"
                                dateTo={durationEnd}
                                dateToName="durationEnd"
                            />
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={8}>
                            <label className="form__label">
                                {getTranslation('Outcome')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={14} className="filters-container">
                            <AnnouncementOutcomeFilter
                                onFilterChange={onInputChange}
                                value={outcome}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="form__group">
                <Col span={4}>{getTranslation('Currency')}</Col>
                <Col span={20}>
                    <Checkbox.Group
                        style={{ width: '100%' }}
                        onChange={(value) => setAnnouncementCurrency(value)}
                        value={selectedCurrency}
                    >
                        <Row style={{ flexFlow: 'row wrap' }}>
                            {_.map(CURRENCIES, (currency) => (
                                <Col span={8} key={currency.currency}>
                                    <Checkbox
                                        value={currency.currency}
                                        disabled={!_.includes(supportedCurrency, currency.currency)}
                                    >
                                        {currency.currency}
                                    </Checkbox>
                                </Col>
                            ))}
                        </Row>
                    </Checkbox.Group>
                </Col>
            </Row>

            <Divider />

            <AnnouncementTabs
                Content={announcementItems}
                currency={selectedCurrency}
                onContentChanged={onContentChange}
                setAnnouncementLang={setAnnouncementLang}
            />

            <Row className="action-buttons" justify="end" align="middle">
                {announcementDraftId ? (
                    <ButtonGreen label={getTranslation('Edit Announcement')} />
                ) : (
                    <ButtonGreen
                        label={getTranslation('Add Announcement')}
                        onClick={submitForm}
                        loading={loading}
                    />
                )}
                <ButtonGray label={getTranslation('Cancel')} onClick={resetForm} />
            </Row>
        </div>
    );
};

export default AnnouncementForm;
