export const BANK_CARD_STATUSES = {
    ACTIVATED: 'Activated',
    DEACTIVATED: 'Deactivated',
};

export const PAYMENT_ACCEPTANCE_METHODS = {
    ALIPAY_TO_BANK: 'Alipay to Bank',
    ALL: 'All',
    BANK_TO_BANK: 'Bank to Bank',
    WECHAT_TO_BANK: 'Wechat to Bank',
};

export const DEPOSIT_AMOUNT_RANGE = {
    CNY: {
        MAX_BANK_TRANSFER_AMOUNT: 1000000,
        MIN_BANK_TRANSFER_AMOUNT: 20,
    },
    EUR: {
        MAX_BANK_TRANSFER_AMOUNT: 1000000,
        MIN_BANK_TRANSFER_AMOUNT: 20,
    },
    IDR: {
        MAX_BANK_TRANSFER_AMOUNT: 20000,
        MIN_BANK_TRANSFER_AMOUNT: 200,
    },
    JPY: {
        MAX_BANK_TRANSFER_AMOUNT: 1000000,
        MIN_BANK_TRANSFER_AMOUNT: 2000,
    },
    KRW: {
        MAX_BANK_TRANSFER_AMOUNT: 1000000,
        MIN_BANK_TRANSFER_AMOUNT: 20000,
    },
    MYR: {
        MAX_BANK_TRANSFER_AMOUNT: 5000,
        MIN_BANK_TRANSFER_AMOUNT: 10,
    },
    THB: {
        MAX_BANK_TRANSFER_AMOUNT: 50000,
        MIN_BANK_TRANSFER_AMOUNT: 100,
    },
    USD: {
        MAX_BANK_TRANSFER_AMOUNT: 1000000,
        MIN_BANK_TRANSFER_AMOUNT: 30,
    },
    USDT: {
        MAX_BANK_TRANSFER_AMOUNT: 1000000,
        MIN_BANK_TRANSFER_AMOUNT: 30,
    },
    VND: {
        MAX_BANK_TRANSFER_AMOUNT: 30000,
        MIN_BANK_TRANSFER_AMOUNT: 300,
    },
};

export const TRANSIT_AMOUNT_RANGE = {
    MAX_BANK_TRANSFER_AMOUNT: 10000000,
    MIN_BANK_TRANSFER_AMOUNT: 50,
};

export const WITHDRAW_AMOUNT_RANGE = {
    CNY: {
        MAX_BANK_TRANSFER_AMOUNT: 300000,
        MIN_BANK_TRANSFER_AMOUNT: 50,
    },
    EUR: {
        MAX_BANK_TRANSFER_AMOUNT: 50000,
        MIN_BANK_TRANSFER_AMOUNT: 20,
    },
    IDR: {
        MAX_BANK_TRANSFER_AMOUNT: 600000,
        MIN_BANK_TRANSFER_AMOUNT: 200,
    },
    JPY: {
        MAX_BANK_TRANSFER_AMOUNT: 4500000,
        MIN_BANK_TRANSFER_AMOUNT: 2000,
    },
    KRW: {
        MAX_BANK_TRANSFER_AMOUNT: 50000000,
        MIN_BANK_TRANSFER_AMOUNT: 20000,
    },
    MYR: {
        MAX_BANK_TRANSFER_AMOUNT: 200000,
        MIN_BANK_TRANSFER_AMOUNT: 30,
    },
    THB: {
        MAX_BANK_TRANSFER_AMOUNT: 1500000,
        MIN_BANK_TRANSFER_AMOUNT: 300,
    },
    USD: {
        MAX_BANK_TRANSFER_AMOUNT: 50000,
        MIN_BANK_TRANSFER_AMOUNT: 30,
    },
    VND: {
        MAX_BANK_TRANSFER_AMOUNT: 1000000,
        MIN_BANK_TRANSFER_AMOUNT: 300,
    },
};
