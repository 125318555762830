import React from 'react';

import { Row, Tooltip } from 'antd';

import './NavBar.less';
import { ICONS } from '../../common/constants/icons';
import NavBarList from './NavBarList';

const NavBar = ({ onToggleDrawer }) => {
    return (
        <Row justify="space-between" align="middle" className="navbar__container">
            <Tooltip title="Toggle side bar" placement="bottomLeft">
                <img
                    style={{ marginLeft: '70px' }}
                    src={ICONS.LOGO}
                    width={50}
                    onClick={onToggleDrawer}
                    alt="logo"
                />
            </Tooltip>
            <NavBarList />
        </Row>
    );
};

export default NavBar;
