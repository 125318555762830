export const AFFILIATE_TABLE_COLUMNS = [
    {
        key: 'requestedDateTime',
        title: 'Request Time',
    },
    {
        key: 'transactionNo',
        required: true,
        title: 'Task Transaction Number',
    },
    {
        key: 'playerUserName',
        title: 'Username',
    },
    {
        key: 'playerName',
        required: true,
        title: 'Full Name',
    },
    {
        key: 'amount',
        required: true,
        title: 'Request Amount',
    },
    {
        key: 'currencyCode',
        required: true,
        title: 'Currency',
    },
    {
        key: 'verifiedBy',
        title: 'Applicant',
    },
    {
        key: 'processedDateTime',
        title: 'Process Time',
    },
    {
        key: 'transactionStatus',
        title: 'Status',
    },
    {
        key: 'operation',
        required: true,
        title: 'Operation',
    },
];
