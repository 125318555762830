import React from 'react';

import { Row } from 'antd';

import {
    AffiliateFilter,
    AffiliateMemberFilter,
    AffiliateStatusFilter,
    AutoIdFilter,
    MobileNoFilter,
    QualityLevelFilter,
    ReferralFilter,
    ShortIdFilter,
    StatusFilter,
} from '../filters';
import {
    BankCardFilter,
    CurrencyFilter,
    DangerLevelFilter,
    FullNameFilter,
    MemberLevelFilter,
    PeriodFilter,
    TrustLevelFilter,
    UsernameFilter,
} from '../../../common/components/filters/';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { playersFilterSet } from './PlayersListActions';
import { useStore } from '../../../store/StateProvider';

const PlayersFilters = () => {
    const [state, dispatch] = useStore();

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => playersFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.playersList.filters.userNameLike')}
                name="userNameLike"
            />
            <FullNameFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.playersList.filters.realNameLike')}
                name="realNameLike"
            />
            <MobileNoFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.playersList.filters.mobileNoLike')}
            />
            <BankCardFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.playersList.filters.bankCardLike')}
                name="bankCardLike"
            />
            <AffiliateStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.playersList.filters.affiliateStatus')}
                name="affiliateStatus"
                placeholder={getTranslation('Affiliate Status')}
            />
            <AffiliateFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.playersList.filters.affiliateUpline')}
                name="affiliateUpline"
                placeholder={getTranslation('Affiliate Upline')}
            />
            <AffiliateMemberFilter
                value={getStateProp(state, 'players.playersList.filters.isAffiliateApplied')}
                onFilterChange={setFilter}
            />
            <ReferralFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.playersList.filters.referralLike')}
                name="referralLike"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(state, 'players.playersList.filters.dateFrom')}
                dateFromName="dateFrom"
                dateTo={getStateProp(state, 'players.playersList.filters.dateTo')}
                dateToName="dateTo"
            />
            <MemberLevelFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.playersList.filters.memberLevel')}
            />
            <QualityLevelFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.playersList.filters.qualityLevel')}
            />
            <DangerLevelFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.playersList.filters.dangerLevel')}
                name="dangerLevel"
                // dangerLevelFilterList={getStateProp(state, 'players.playersList.dangerLevels', [])}
            />
            <TrustLevelFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.playersList.filters.trustLevel')}
            />
            <StatusFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.playersList.filters.status')}
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.playersList.filters.currencyCode')}
                name="currencyCode"
            />
            <AutoIdFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.playersList.filters.autoId')}
            />
            <ShortIdFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.playersList.filters.shortIds')}
            />
        </Row>
    );
};

export default PlayersFilters;
