export const ACTIONS = {
    BANK_CARD_GET: 'DEACTIVED_BANK_CARD_GET',
    BANK_CARD_GET_FAILURE: 'DEACTIVED_BANK_CARD_GET_FAILURE',
    BANK_CARD_GET_SUCCESS: 'DEACTIVED_BANK_CARD_GET_SUCCESS',
    BANK_CARD_UPDATE_FORM_SUBMIT: 'DEACTIVATED_BANK_CARD_UPDATE_FORM_SUBMIT',
    BANK_CARD_UPDATE_FORM_SUBMIT_FAILURE: 'DEACTIVATED_BANK_CARD_UPDATE_FORM_SUBMIT_FAILURE',
    BANK_CARD_UPDATE_FORM_SUBMIT_SUCCESS: 'DEACTIVATED_BANK_CARD_UPDATE_FORM_SUBMIT_SUCCESS',
    CARD_SETUP_MODAL_CLOSE: 'DEACTIVATED_BANK_CARD_SETUP_MODAL_CLOSE',
    CARD_SETUP_MODAL_OPEN: 'DEACTIVATED_BANK_CARD_SETUP_MODAL_OPEN',
    FILTER_DROP: 'DEACTIVATED_BANK_CARD_FILTER_DROP',
    FILTER_SET: 'DEACTIVATED_BANK_CARD_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'DEACTIVATED_BANK_CARD_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'DEACTIVATED_BANK_CARD_TABLE_PAGE_SIZE_CHANGE',
    },
};

export const deactivatedBankCardSetupModalOpen = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CARD_SETUP_MODAL_OPEN,
    });

export const deactivatedBankCardSetupModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.CARD_SETUP_MODAL_CLOSE,
    });

export const deactivatedBankCardGet = (dispatch) =>
    dispatch({
        type: ACTIONS.BANK_CARD_GET,
    });

export const deactivatedBankCardGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANK_CARD_GET_SUCCESS,
    });

export const deactivatedBankCardGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANK_CARD_GET_FAILURE,
    });

export const bankCardFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const bankCardDropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTER_DROP,
    });

export const deactivatedBankCardUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.BANK_CARD_UPDATE_FORM_SUBMIT,
    });

export const deactivatedBankCardUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANK_CARD_UPDATE_FORM_SUBMIT_FAILURE,
    });

export const deactivatedBankCardUpdateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.BANK_CARD_UPDATE_FORM_SUBMIT_SUCCESS,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });
