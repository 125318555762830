import { RenderDateFormat } from '../../../../../common/components/RenderDateFormat';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../../common/constants/api';
import { changeSessionsPageNumber, changeSessionsPageSize } from '../../playerDetailsActions';
import { getTranslation } from '../../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../../common/helpers/misc';

export const getColumns = (pageNo) => {
    const columns = [
        {
            dataIndex: 'No',
            render: (value, record, index) => (pageNo - 1) * 10 + index + 1,
            title: getTranslation('No.'),
        },
        {
            dataIndex: 'domain',
            title: getTranslation('Domain'),
        },
        {
            dataIndex: 'channel',
            title: getTranslation('Register Channel'),
        },
        {
            dataIndex: 'browser',
            title: getTranslation('Browser'),
        },
        {
            dataIndex: 'ipAddress',
            title: getTranslation('IP address'),
        },
        {
            dataIndex: 'activatedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Last Activation Date'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changeSessionsPageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changeSessionsPageSize(dispatch, pageSize);
