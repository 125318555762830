import React from 'react';

import { Row } from 'antd';

import { ButtonBlue } from '../../../../common/components/buttons/Buttons';
import { DropButton, SearchButton } from '../../../../common/components/buttons';
import { getTranslation } from '../../../../common/helpers/locale';

const ActionButtons = ({ onOpenModal, onDropFilters }) => {
    return (
        <Row align="middle" justify="end" className="action-buttons__container">
            <SearchButton htmlType="submit" />
            <DropButton onDropFilters={onDropFilters} />
            <ButtonBlue label={getTranslation('Add Bank Card')} onClick={onOpenModal} />
        </Row>
    );
};

export default ActionButtons;
