import React from 'react';

import { ButtonGray } from '../../../../common/components/buttons/Buttons';
import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS_1000 } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../bonusRecordsActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';
import ViewButton from './remark/ViewButton';

export const getColumns = (tableWidth) => {
    const columns = [
        {
            dataIndex: 'createdAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Request Time'),
            width: 100,
        },
        {
            dataIndex: 'transNo',
            title: getTranslation('Task Transaction Number'),
            width: 230,
        },
        {
            dataIndex: 'userName',
            title: getTranslation('Username'),
            width: 120,
        },
        {
            dataIndex: 'memberLevel',
            title: getTranslation('Member Level'),
            width: 60,
        },
        {
            dataIndex: 'depositTransactionNo',
            title: getTranslation('Deposit Trans No.'),
            width: 230,
        },
        {
            dataIndex: 'depositAmount',
            title: getTranslation('Deposit Amount'),
            width: 80,
        },
        {
            dataIndex: 'currencyCode',
            title: getTranslation('Currency'),
            width: 80,
        },
        {
            dataIndex: 'bonusName',
            title: getTranslation('Bonus Name'),
            width: 150,
        },
        {
            dataIndex: 'bonusAmount',
            title: getTranslation('Bonus Amount'),
            width: 80,
        },
        {
            dataIndex: 'turnoverCondition',
            title: getTranslation('Turnover Requirement'),
            width: 80,
        },
        {
            dataIndex: 'turnoverAmount',
            title: getTranslation('Turnover Amount'),
            width: 80,
        },
        {
            dataIndex: 'processTime',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Process Time'),
            width: 100,
        },
        {
            dataIndex: 'expiredDate',
            dataType: 'date',
            render: (date) => RenderDateFormat(date, true),
            title: getTranslation('Expired Date'),
            width: 100,
        },
        {
            dataIndex: 'promoCode',
            title: getTranslation('Promo Code'),
            width: 100,
        },
        {
            dataIndex: 'remarks',
            dataType: 'remarks',
            render: (text, record) =>
                record.applicantRemarks && record.operatorRemarks ? (
                    <ViewButton
                        applicantRemark={record.applicantRemarks}
                        operatorRemark={record.operatorRemarks}
                    />
                ) : (
                    <span>{record.applicantRemarks}</span>
                ),
            title: getTranslation('Remark'),
            width: 80,
        },
        {
            dataIndex: 'progressStatus',
            title: getTranslation('Progress Status'),
            width: 80,
        },
        {
            dataIndex: 'applicantName',
            title: getTranslation('Applicant'),
            width: 80,
        },
        {
            dataIndex: 'operatorName',
            dataType: 'operator',
            render: (value) => <ButtonGray disabled label={value} className="action-btn__verify" />,
            title: getTranslation('Operator'),
            width: tableWidth <= 1900 && 170,
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS_1000,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);

export const bonusRecordsExport = ({ onlyMeta }) => {
    if (onlyMeta) {
        return {
            remarks: [
                {
                    dataIndex: 'applicantRemarks',
                    title: getTranslation('Applicant Remark'),
                },
                {
                    dataIndex: 'operatorRemarks',
                    title: getTranslation('Operator Remark'),
                },
            ],
        };
    }

    let messageArray = [];
    return messageArray;
};
