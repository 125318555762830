import { ACTIONS } from './vipSetupActions';
export default function vipSetupReducer(vipSetup = {}, { type, payload = null }) {
    switch (type) {
        case ACTIONS.VIP_SETUP_GET_SUCCESS:
            return { ...payload };

        case ACTIONS.VIP_SETUP_INFO_SET:
        case ACTIONS.VIP_SETUP_GET:
        case ACTIONS.VIP_SETUP_UPDATE:
        case ACTIONS.VIP_SETUP_UPDATE_SUCCESS:
        default:
            return vipSetup;
    }
}
