import generalCreatePushNotificationReducer from './general/generalCreatePushNotificationReducer';
import targetCreatePushNotificationReducer from './target/targetCreatePushNotificationReducer';

export default function createPushNotificationReducer(createPushNotification = {}, action) {
    const { generalCreatePushNotification, targetCreatePushNotification } = createPushNotification;

    return {
        generalCreatePushNotification: generalCreatePushNotificationReducer(
            generalCreatePushNotification,
            action,
        ),
        targetCreatePushNotification: targetCreatePushNotificationReducer(
            targetCreatePushNotification,
            action,
        ),
    };
}
