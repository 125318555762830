export const ACTIONS = {
    FILTERS_DROP_ALL: 'TRANSACTION_RECORDS_FILTERS_DROP_ALL',
    FILTER_SET: 'TRANSACTION_RECORDS_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'TRANSACTION_RECORDS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'TRANSACTION_RECORDS_TABLE_PAGE_SIZE_CHANGE',
    },
    REMARK_MODAL_CLOSE: 'TRANSACTION_RECORDS_REMARK_MODAL_CLOSE',
    REMARK_MODAL_OPEN: 'TRANSACTION_RECORDS_REMARK_MODAL_OPEN',
    TRANSACTION_RECORDS_GET: 'TRANSACTION_RECORDS_GET',
    TRANSACTION_RECORDS_GET_FAILURE: 'TRANSACTION_RECORDS_GET_FAILURE',
    TRANSACTION_RECORDS_GET_SUCCESS: 'TRANSACTION_RECORDS_GET_SUCCESS',
};

export const transactionRecordsGet = (dispatch) =>
    dispatch({
        type: ACTIONS.TRANSACTION_RECORDS_GET,
    });

export const transactionRecordsGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TRANSACTION_RECORDS_GET_SUCCESS,
    });

export const transactionRecordsGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TRANSACTION_RECORDS_GET_FAILURE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const transactionRecordsFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const onRemarkModalOpen = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REMARK_MODAL_OPEN,
    });

export const onRemarkModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.REMARK_MODAL_CLOSE,
    });
