import React, { useState } from 'react';

import { Button, Dropdown, Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import _ from 'lodash';

import './OperationsButton.less';
import { HOT_EVENTS_CHATROOM_STATUSES } from '../../constants';
/* import { hotEventsChatroomStatusUpdate } from '../../hotEventsAction';
import { useDispatch } from '../../../../../store/StateProvider'; */

import { getTranslation } from '../../../../common/helpers/locale';
import { useUpdateChatroomStatusAsync } from '../../../../services/hotEventsService';

const ColumnActionButton = ({ chatRoomInfo }) => {
    //const dispatch = useDispatch();
    const [status, setStatus] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    /*     const onSubmit = (e) => {
        hotEventsChatroomStatusUpdate(dispatch, {
            changeChatRoomStatus: { id:chatRoomInfo.id, status: e.key },
        });
        handleUpdateChatroomStatus();
    }; 
 */
    const onSubmit = (e) => {
        setStatus(e.key);
        handleUpdateChatroomStatus();
    };

    // ============================================
    // HOOKS
    // ============================================
    const [updating, handleUpdateChatroomStatus] = useUpdateChatroomStatusAsync(
        chatRoomInfo.id,
        status,
    );

    // ============================================
    // RENDER
    // ============================================

    const operationsMenu = (
        <Menu onClick={onSubmit}>
            {_.map(HOT_EVENTS_CHATROOM_STATUSES, (status) => (
                <Menu.Item key={status}>
                    <span>{getTranslation(status)}</span>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Dropdown overlay={operationsMenu} trigger={['click']} disabled={!chatRoomInfo.isHotEvent}>
            <Button className="btn__green action-btn__operations" loading={updating}>
                {chatRoomInfo.isPublished
                    ? getTranslation('Activated')
                    : getTranslation('Deactivated')}
                <CaretDownOutlined />
            </Button>
        </Dropdown>
    );
};

export default ColumnActionButton;
