import React from 'react';

import { Row } from 'antd';

import { DropButton, ExportButton, SearchButton } from '../../../common/components/buttons';
import ColumnSelectionButton from '../../../common/components/buttons/ColumnSelectionButton';

const ActionButtons = ({ onDropFilters, onExport, onReject, onColumnSelect }) => {
    return (
        <Row align="middle" justify="end" className="action-buttons__container">
            <SearchButton htmlType="submit" />
            <DropButton onDropFilters={onDropFilters} />
            <ExportButton onExport={onExport} />
            <ColumnSelectionButton onColumnSelect={onColumnSelect} />
        </Row>
    );
};

export default ActionButtons;
