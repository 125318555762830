export const ACTIONS = {
    FILTERS_DROP_ALL: 'REFERRAL_MANAGEMENT_FILTERS_DROP_ALL',
    FILTER_SET: 'REFERRAL_MANAGEMENT_FILTER_SET',
    MODAL_PAGINATION: {
        TABLE_PAGE_CHANGE: 'REFERRAL_MANAGEMENT_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'REFERRAL_MANAGEMENT_TABLE_PAGE_SIZE_CHANGE',
    },
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'REFERRAL_MANAGEMENT_MODAL_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'REFERRAL_MANAGEMENT_MODAL_TABLE_PAGE_SIZE_CHANGE',
    },
    REFERRAL_CONFIRMATION_MODAL_CLOSE: 'REFERRAL_CONFIRMATION_MODAL_CLOSE',
    REFERRAL_EDIT_MODAL_CLOSE: 'REFERRAL_EDIT_MODAL_CLOSE',
    REFERRAL_EDIT_MODAL_OPEN: 'REFERRAL_EDIT_MODAL_OPEN',
    REFERRAL_MANAGEMENT_EDIT: 'REFERRAL_MANAGEMENT_EDIT',
    REFERRAL_MANAGEMENT_GET: 'REFERRAL_MANAGEMENT_GET',
    REFERRAL_MANAGEMENT_GET_ACTIVE: 'REFERRAL_MANAGEMENT_GET_ACTIVE',
    REFERRAL_MANAGEMENT_GET_ACTIVE_FAILURE: 'REFERRAL_MANAGEMENT_GET_ACTIVE_FAILURE',
    REFERRAL_MANAGEMENT_GET_ACTIVE_SUCCESS: 'REFERRAL_MANAGEMENT_GET_ACTIVE_SUCCESS',
    REFERRAL_MANAGEMENT_GET_BY_ID: 'REFERRAL_MANAGEMENT_GET_BY_ID',
    REFERRAL_MANAGEMENT_GET_BY_ID_FAILURE: 'REFERRAL_MANAGEMENT_GET_BY_ID_FAILURE',
    REFERRAL_MANAGEMENT_GET_BY_ID_SUCCESS: 'REFERRAL_MANAGEMENT_GET_BY_ID_SUCCESS',
    REFERRAL_MANAGEMENT_GET_FAILURE: 'REFERRAL_MANAGEMENT_GET_FAILURE',
    REFERRAL_MANAGEMENT_GET_SUCCESS: 'REFERRAL_MANAGEMENT_GET_SUCCESS',
    REFERRAL_MANAGEMENT_UPDATE: 'REFERRAL_MANAGEMENT_UPDATE',
    REFERRAL_MANAGEMENT_UPDATE_FAILURE: 'REFERRAL_MANAGEMENT_UPDATE_FAILURE',
    REFERRAL_MANAGEMENT_UPDATE_SUCCESS: 'REFERRAL_MANAGEMENT_UPDATE_SUCCESS',
    REFERRAL_STATUS_SET: 'REFERRAL_STATUS_SET',
    REFERRAL_STATUS_SET_FAILURE: 'REFERRAL_STATUS_SET_FAILURE',
    REFERRAL_STATUS_SET_SUCCESS: 'REFERRAL_STATUS_SET_SUCCESS',
};

export const referralManagementGet = (dispatch) =>
    dispatch({
        type: ACTIONS.REFERRAL_MANAGEMENT_GET,
    });

export const referralManagementGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRAL_MANAGEMENT_GET_FAILURE,
    });

export const referralManagementGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRAL_MANAGEMENT_GET_SUCCESS,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const changeModalPageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MODAL_PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changeModalPageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MODAL_PAGINATION.TABLE_PAGE_CHANGE,
    });

export const referralManagementFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const referralStatusSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRAL_STATUS_SET,
    });

export const referralStatusSetSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.REFERRAL_STATUS_SET_SUCCESS,
    });

export const referralStatusSetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRAL_STATUS_SET_FAILURE,
    });

export const referralEditModalOpen = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRAL_EDIT_MODAL_OPEN,
    });

export const referralEditModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.REFERRAL_EDIT_MODAL_CLOSE,
    });

export const confirmationModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.REFERRAL_CONFIRMATION_MODAL_CLOSE,
    });

export const referralManagementGetById = (dispatch) =>
    dispatch({
        type: ACTIONS.REFERRAL_MANAGEMENT_GET_BY_ID,
    });

export const referralManagementGetByIdFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRAL_MANAGEMENT_GET_BY_ID_FAILURE,
    });

export const referralManagementGetByIdSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRAL_MANAGEMENT_GET_BY_ID_SUCCESS,
    });

export const editReferralInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRAL_MANAGEMENT_EDIT,
    });

export const referralManagementUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.REFERRAL_MANAGEMENT_UPDATE,
    });

export const referralManagementUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRAL_MANAGEMENT_UPDATE_FAILURE,
    });

export const referralManagementUpdateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.REFERRAL_MANAGEMENT_UPDATE_SUCCESS,
    });

export const getActiveReferral = (dispatch) =>
    dispatch({
        type: ACTIONS.REFERRAL_MANAGEMENT_GET_ACTIVE,
    });

export const getActiveReferralFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRAL_MANAGEMENT_GET_ACTIVE_FAILURE,
    });

export const getActiveReferralSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRAL_MANAGEMENT_GET_ACTIVE_SUCCESS,
    });
