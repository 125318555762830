import React from 'react';

import { InputNumber } from 'antd';

import { RenderDateFormat } from '../../../../common/components/RenderDateFormat';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';

export const getColumns = (isEditMode, onRateChange, isAddNewRate) => {
    const columns = [
        {
            dataIndex: 'currency',
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'rates',
            render: (value, record) =>
                isEditMode ? (
                    <InputNumber
                        value={value}
                        className="currency-setting__rate-input"
                        onChange={(value) => {
                            onRateChange(value, record.currency, record.baseCurrency);
                        }}
                    />
                ) : (
                    value
                ),
            title: getTranslation('Rates'),
        },
        {
            dataIndex: 'createdAt',
            dataType: 'date',
            render: (date) => (isAddNewRate ? '-' : RenderDateFormat(date)),
            title: getTranslation('Created Time'),
        },
        {
            dataIndex: 'updatedAt',
            dataType: 'date',
            render: (date) => (isAddNewRate ? '-' : RenderDateFormat(date)),
            title: getTranslation('Updated Time'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};
