import React from 'react';

import { Card, Form } from 'antd';

import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    merchantConfigurationDropFilters,
    onCloseConfirmModal,
} from './merchantConfigurationActions';
import { useGetMerchantConfigurationAsyncEndpoint } from '../../../services/merchantService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import ConfigurationModal from './modal/ConfigurationModal';
import MerchantConfigurationFilters from './MerchantConfigurationFilters';
import MerchantConfigurationResultsTable from './table/MerchantConfigurationResultsTable';

const MerchantConfiguration = () => {
    const [state, dispatch] = useStore();
    const merchantConfiguration = getStateProp(state, 'payments.merchantConfiguration');
    const confirmModalVisible = getStateProp(merchantConfiguration, 'confirmModalVisible');

    // ============================================
    // METHODS
    // ============================================

    const onCloseModal = () => onCloseConfirmModal(dispatch);

    const fetchAll = () => {
        merchantConfigurationDropFilters(dispatch);
        handleGetMerchantConfiguration();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetMerchantConfiguration] = useGetMerchantConfigurationAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Merchant Configuration')}>
            <Form onFinish={handleGetMerchantConfiguration}>
                <MerchantConfigurationFilters />
                <ActionButtons onDropFilters={fetchAll} />
            </Form>
            <MerchantConfigurationResultsTable loading={loading} />
            {confirmModalVisible && <ConfigurationModal onCancel={onCloseModal} />}
        </Card>
    );
};

export default MerchantConfiguration;
