import React from 'react';

import { Row } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import { CurrencyFilter, PeriodFilter, TimezoneFilter } from '../../../common/components/filters';
import { REPORT_TYPES } from '../constants';
import { ReportTypeFilter } from '../filter';
import { TIMEZONE } from '../../../common/constants/localization';
import { getStateProp } from '../../../common/helpers/misc';
import { overviewDateTypeChange, overviewFilterSet } from './overviewActions';
import { switchTimezone } from '../../../common/actions/timezoneActions';
import { useStore } from '../../../store/StateProvider';

const OverviewFilters = () => {
    const [state, dispatch] = useStore();
    const overview = getStateProp(state, 'reports.overview');
    const { reportType, to, from } = getStateProp(overview, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => {
        if (filter === 'currency') {
            overviewFilterSet(dispatch, {
                filter: 'baseCurrency',
                value: value !== 'ALL' ? undefined : 'MYR',
            });
        }

        if (filter === 'reportType') {
            overviewDateTypeChange(dispatch, {
                from: moment(from).startOf(value === REPORT_TYPES.MONTHLY ? 'month' : 'day'),
                to: moment(to).endOf(value === REPORT_TYPES.MONTHLY ? 'month' : 'day'),
            });
        }

        overviewFilterSet(dispatch, { filter, value });
    };

    const setTimezone = (value) => {
        switchTimezone(dispatch, {
            location: _.find(TIMEZONE, (timezone) => timezone.OFFSET === value).LOCATION,
            offset: value,
        });
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row type="flex" justify="start" align="middle" className="filters-container">
            <ReportTypeFilter
                onFilterChange={setFilter}
                value={getStateProp(overview, 'filters.reportType')}
            />
            <TimezoneFilter
                onFilterChange={setTimezone}
                value={getStateProp(state, 'timezone.offset')}
            />
            <PeriodFilter
                onFilterChange={setFilter}
                name="date"
                dateFrom={getStateProp(overview, 'filters.from')}
                dateTo={getStateProp(overview, 'filters.to')}
                dateFromName="from"
                dateToName="to"
                picker={reportType === REPORT_TYPES.MONTHLY ? 'month' : 'date'}
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(overview, 'filters.currency')}
                name="currency"
                otherSelection={['ALL']}
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(overview, 'filters.baseCurrency')}
                name="baseCurrency"
                placeholder="Base Currency"
                allowClear={false}
                disabled={getStateProp(overview, 'filters.currency') !== 'ALL'}
            />
        </Row>
    );
};

export default OverviewFilters;
