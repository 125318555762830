import React, { useEffect, useState } from 'react';

import { Card, Col, Divider, Row, Switch, Tabs, message } from 'antd';
import _ from 'lodash';

import './VipSetup.less';
import { ButtonGreen, ButtonTransparent } from '../../../common/components/buttons/Buttons';
import { CURRENCIES } from '../../../common/constants/localization';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    useGetVipSetupByCurrencyAsyncEndpoint,
    useUpdateVipSetupAsyncEndpoint,
} from '../../../services/vipService';
import { useStore } from '../../../store/StateProvider';
import { vipSetupInfoSet } from './vipSetupActions';
import Loader from '../../../common/components/Loader';
import VipSetupResultTable from './table/VipSetupResultTable';

const VipSetup = () => {
    const [state, dispatch] = useStore();
    const [activeTab, setActiveTab] = useState('THB');
    const [isEditMode, setEditMode] = useState(false);
    const vipSetup = getStateProp(state, 'vip.vipSetup.list', []);
    const bonusSetup = getStateProp(state, 'vip.vipSetup.setups', []);

    // ============================================
    // METHODS
    // ============================================

    const handleTabClick = (key) => {
        if (isEditMode) {
            message.warning('Please off the edit mode!');
        } else {
            setActiveTab(key);
        }
    };

    const onVipSetupChange = (level, data, value) => {
        const editLevel = _.find(vipSetup, (setup) => {
            return setup.level.level === level;
        });

        _.set(editLevel, data.dataIndex, value);

        vipSetupInfoSet(dispatch);
    };

    const onVipBonusSetupChange = (type, status) => {
        _.map(bonusSetup, (setup) => {
            if (setup.rewardType === type) {
                setup.status = status;
            }
            return setup;
        });

        vipSetupInfoSet(dispatch);
    };

    const handleFormSubmit = () => handleUpdateVipSetup();

    const resetForm = () => handleGetVipSetup();

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetVipSetup] = useGetVipSetupByCurrencyAsyncEndpoint(activeTab);
    const [updating, handleUpdateVipSetup, updated] = useUpdateVipSetupAsyncEndpoint();

    useEffect(() => {
        if (updated) {
            message.success('VIP setup updated');
            setEditMode(false);
        }
    }, [updated]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('VIP Setup')}>
            <Tabs
                type="card"
                onTabClick={handleTabClick}
                activeKey={activeTab}
                tabBarExtraContent={
                    <Switch
                        onChange={(value) => setEditMode(value)}
                        checkedChildren="Edit"
                        unCheckedChildren="Edit"
                        checked={isEditMode}
                    />
                }
            >
                {_.map(CURRENCIES, (currency) => (
                    <Tabs.TabPane tab={currency.currency} key={currency.currency}>
                        <VipSetupResultTable
                            vipSetup={vipSetup}
                            onVipSetupChange={onVipSetupChange}
                            loading={loading}
                            isEditMode={isEditMode}
                        />
                    </Tabs.TabPane>
                ))}
            </Tabs>

            <Divider />

            {loading ? (
                <Loader />
            ) : (
                _.map(bonusSetup, (setup) => (
                    <Row key={setup.rewardType} className="vip-setup__bonus-setup">
                        <Col span={5} className="vip-setup__bonus-setup--label">
                            {setup.rewardType.replace(/([A-Z])/g, ' $1')}
                        </Col>
                        <Col span={12}>
                            {isEditMode ? (
                                <Switch
                                    onChange={(value) =>
                                        onVipBonusSetupChange(setup.rewardType, value)
                                    }
                                    checked={setup.status}
                                />
                            ) : (
                                <span
                                    className={
                                        setup.status
                                            ? 'vip-setup__bonus-setup--enable'
                                            : 'vip-setup__bonus-setup--disable'
                                    }
                                >
                                    {getTranslation(setup.status ? 'Enabled' : 'Disabled')}
                                </span>
                            )}
                        </Col>
                    </Row>
                ))
            )}

            {isEditMode && (
                <>
                    <Divider />

                    <Row justify="end" align="middle" className="action-buttons__container">
                        <ButtonGreen
                            label={getTranslation('Submit')}
                            loading={updating}
                            onClick={handleFormSubmit}
                        />
                        <ButtonTransparent label={getTranslation('Cancel')} onClick={resetForm} />
                    </Row>
                </>
            )}
        </Card>
    );
};

export default VipSetup;
