import { ACTIONS } from './bankActions';

export default function bankReducer(bank = {}, { type, payload = null }) {
    switch (type) {
        case ACTIONS.BANK_GET_SUCCESS:
            return payload;
        case ACTIONS.BANK_CLEAR:
            return null;
        case ACTIONS.BANK_GET:
        default:
            return bank;
    }
}
