import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import React from 'react';

import { Card, Row } from 'antd';

import './EwalletSetup.less';
import { ButtonBlue } from '../../../common/components/buttons/Buttons';
import { PrivateRoute } from '../../../common/components';
import { ROUTES } from '../../../common/constants/routing';
import { getTranslation } from '../../../common/helpers/locale';
import ActiveEwalletAccount from './active-e-wallet/activeEwalletAccount';

import DeactivatedEwallet from './deactivated-e-wallet/DeactivatedEwallet';

const EwalletSetup = () => {
    const history = useHistory();
    const location = useLocation();
    // ============================================
    // METHODS
    // ============================================

    const isActive = (subRoute) =>
        location.pathname.replace('/dashboard/payments/e-wallet-setup/', '') === subRoute;

    const onTabChange = (key) => history.push(key);

    // ============================================
    // RENDER
    // ============================================

    const AspectButton = (
        <Row align="middle" justify="space-between">
            <ButtonBlue
                active={isActive('active')}
                onClick={() => onTabChange('active')}
                className="tab-button"
            >
                {getTranslation('Activate')}
            </ButtonBlue>

            <ButtonBlue
                active={isActive('deactivated')}
                onClick={() => onTabChange('deactivated')}
                className="tab-button"
            >
                {getTranslation('Deactivated')}
            </ButtonBlue>
        </Row>
    );

    return (
        <Card title={getTranslation('E-Wallet Setup')} extra={AspectButton}>
            <Switch>
                <PrivateRoute
                    path={ROUTES.DASHBOARD.PAYMENTS.EWALLET.ACTIVE}
                    component={ActiveEwalletAccount}
                />
                <PrivateRoute
                    path={ROUTES.DASHBOARD.PAYMENTS.EWALLET.DEACTIVATED}
                    component={DeactivatedEwallet}
                />
                <Redirect to={ROUTES.DASHBOARD.PAYMENTS.EWALLET.ACTIVE} />
            </Switch>
        </Card>
    );
};

export default EwalletSetup;
