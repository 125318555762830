import { useCallback, useEffect, useState } from 'react';

import { Modal, message } from 'antd';
import _ from 'lodash';

import { API } from '../common/constants/api';
import {
    activeBonusCategoryGet,
    activeBonusCategoryGetFailure,
    activeBonusCategoryGetSuccess,
    assignedCategoryCheck,
    assignedCategoryCheckFailure,
    assignedCategoryCheckSuccess,
    bonusCategoryCreate,
    bonusCategoryCreateFailure,
    bonusCategoryCreateSuccess,
    bonusCategoryGet,
    bonusCategoryGetFailure,
    bonusCategoryGetSuccess,
    bonusCategorySort,
    bonusCategorySortFailure,
    bonusCategorySortSuccess,
    bonusCategoryUpdate,
    bonusCategoryUpdateFailure,
    bonusCategoryUpdateSuccess,
    closeBonusCategoryModal,
} from '../components/bonus/bonus-category/bonusCategoryActions';
import { clearError } from '../common/actions/errorActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import {
    getCategoryList,
    getCategoryListFailure,
    getCategoryListSuccess,
} from '../components/bonus/shared/categoryActions';
import { getTranslation } from '../common/helpers/locale';
import { useDispatch, useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, BONUS_CATEGORY, PROMOTIONS } = API;

export function useGetBonusCategoryAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { bonusCategory } = getStateProp(state, 'bonus');
    const { pageNo, pageSize } = getStateProp(bonusCategory, 'paging', {});
    const filters = getStateProp(bonusCategory, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetBonusCategory = useCallback(() => {
        bonusCategoryGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS_CATEGORY.ROOT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bonusCategoryGetSuccess(dispatch, {
                fetchData: handleGetBonusCategory,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetBonusCategory, response]);

    useEffect(() => {
        handleGetBonusCategory();
    }, [handleGetBonusCategory, pageNo, pageSize]);

    const errorMsg = useError(error, bonusCategoryGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetBonusCategory];
}

export function useCreateBonusCategoryAsyncEndpoint(formValues) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const refreshTable = getStateProp(state, 'bonus.bonusCategory.fetchData', () => {});

    // ============================================
    // METHODS
    // ============================================

    const handleCreateBonusCategory = () => {
        bonusCategoryCreate(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                categoryNames: formValues,
                name: 'Category',
                sortingOrder: 0,
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS_CATEGORY.ROOT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bonusCategoryCreateSuccess(dispatch);
            closeBonusCategoryModal(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, bonusCategoryCreateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleCreateBonusCategory];
}

export function useUpdateBonusCategoryAsyncEndpoint(formValues) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const refreshTable = getStateProp(state, 'bonus.bonusCategory.fetchData', () => {});
    const categoryAttending = getStateProp(
        state,
        'bonus.bonusCategory.bonusCategoryModal.categoryAttending',
        [],
    );

    const { id, name, sortingOrder } = categoryAttending;

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateBonusCategory = () => {
        bonusCategoryUpdate(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                categoryNames: formValues,
                name,
                sortingOrder,
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS_CATEGORY.ROOT}/${id}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bonusCategoryUpdateSuccess(dispatch);
            closeBonusCategoryModal(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, bonusCategoryUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateBonusCategory];
}

export function useUpdateBonusCategoryStatusAsyncEndpoint(id, status) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateBonusCategoryStatus = useCallback(() => {
        bonusCategoryUpdate(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const [checking, handleChecking, checked] = useCheckAssignedCategoryAsyncEndpoint(id);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                id,
                status,
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS_CATEGORY.ROOT}/${id}/status/${status}`,
    });

    useEffect(() => {
        if (checked) {
            if (checked.data.data.length === 0) handleUpdateBonusCategoryStatus();
            else
                Modal.confirm({
                    content: getTranslation(
                        'This category has been assigned to the bonus(es). Are you sure to change the category status ?',
                    ),
                    onCancel() {
                        return false;
                    },
                    onOk() {
                        handleUpdateBonusCategoryStatus();
                    },
                    title: getTranslation('Warning'),
                });
        }
    }, [checked, dispatch, handleUpdateBonusCategoryStatus]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bonusCategoryUpdateSuccess(dispatch);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, bonusCategoryUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading || checking, handleChecking, response];
}

export function useGetAvailableBonusCategoryAsyncEndpoint() {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetAvailableBonusCategory = useCallback(() => {
        getCategoryList(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                pageSize: 999,
                status: 'Active',
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS_CATEGORY.ROOT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getCategoryListSuccess(dispatch, {
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        handleGetAvailableBonusCategory();
    }, [handleGetAvailableBonusCategory]);

    const errorMsg = useError(error, getCategoryListFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetAvailableBonusCategory];
}

export function useSortBonusCategoryAsycnEndpoint() {
    const [state, dispatch] = useStore();

    const [trigger, setTrigger] = useState(null);

    const sortingList = getStateProp(
        state,
        'bonus.bonusCategory.bonusCategoryModal.categoryAttending',
        [],
    );

    // ============================================
    // HOOKS
    // ============================================

    const handleUpdateCategorySorting = useCallback(() => {
        bonusCategorySort(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'PATCH',
        options: {
            data: {
                sortInfos: _.map(sortingList, (list) => {
                    return {
                        id: list.id,
                        sortOrder: list.sortingOrder,
                    };
                }),
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS_CATEGORY.SORT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bonusCategorySortSuccess(dispatch);
            message.success(getTranslation('Sorting Updated'));
            closeBonusCategoryModal(dispatch);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, bonusCategorySortFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateCategorySorting, response];
}

export function useGetActiveBonusCategoryAsyncEndpoint() {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetActiveBonusCategory = useCallback(() => {
        activeBonusCategoryGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                pageSize: 999,
                status: 'Active',
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS_CATEGORY.ROOT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            activeBonusCategoryGetSuccess(dispatch, {
                list: _.orderBy(response.data.data, ['sortingOrder'], ['asc']),
                paging: response.data.paging,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        handleGetActiveBonusCategory();
    }, [handleGetActiveBonusCategory]);

    const errorMsg = useError(error, activeBonusCategoryGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetActiveBonusCategory];
}

export function useCheckAssignedCategoryAsyncEndpoint(categoryId) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);
    // ============================================
    // HOOKS
    // ============================================

    const handleCheckAssignedCategory = () => {
        assignedCategoryCheck(dispatch);
        setTrigger(+new Date());
    };

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                categoryId,
            },
        },
        trigger,
        url: `${VERSION.V1}${PROMOTIONS.ROOT}/query`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            assignedCategoryCheckSuccess(dispatch);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, assignedCategoryCheckFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleCheckAssignedCategory, response];
}
