import React from 'react';

import { Input } from 'antd';

import { getTranslation } from '../../helpers/locale';

const ReceivingWalletFilter = ({ value, onFilterChange, name }) => {
    return (
        <Input
            value={value}
            onChange={(e) => onFilterChange(name, e.target.value)}
            placeholder={getTranslation('Receiving Wallet Address')}
            className="filter"
        />
    );
};

export default ReceivingWalletFilter;
