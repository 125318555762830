import React, { useEffect, useState } from 'react';

import { Card, Form } from 'antd';

import { closeWinLossDetailModal, playerWinLossFilterDrop } from './playerWinLossActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    useGetPlayerWinLossFilterListAsyncEndpoint,
    useGetPlayerWinLossReportAsyncEndpoint,
} from '../../../services/companyReportService';

import { TIMEZONE } from '../../../common/constants/localization';
import { switchTimezone } from '../../../common/actions/timezoneActions';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import PlayerWinLossFilters from './PlayerWinLossFilters';
import PlayerWinLossModal from './modal/PlayerWinLossModal';
import PlayerWinLossTable from './table/PlayerWinLossTable';

const PlayerWinLoss = () => {
    const [state, dispatch] = useStore();
    const winLossModalVisible = getStateProp(
        state,
        'reports.playerWinLoss.modalDetail.visible',
        false,
    );
    const [isExport, setIsExport] = useState(false);

    // ============================================
    // METHODS
    // ============================================

    const closeModal = () => closeWinLossDetailModal(dispatch);

    const onDropFilters = () => {
        setIsExport(false);

        playerWinLossFilterDrop(dispatch);
        handleGetPlayerWinLoss();
    };

    const searchReport = () => {
        setIsExport(false);

        handleGetPlayerWinLoss();
    };

    const onExport = () => {
        setIsExport(true);
        handleGetPlayerWinLoss();
    };

    // ============================================
    // HOOKS
    // ============================================

    useGetPlayerWinLossFilterListAsyncEndpoint();

    const [loading, handleGetPlayerWinLoss] = useGetPlayerWinLossReportAsyncEndpoint(isExport);

    useEffect(() => {
        switchTimezone(dispatch, {
            location: TIMEZONE.CANADA.LOCATION,
            offset: TIMEZONE.CANADA.OFFSET,
        });

        return () => {
            switchTimezone(dispatch, {
                location: TIMEZONE.BANGKOK.LOCATION,
                offset: TIMEZONE.BANGKOK.OFFSET,
            });
        };
    }, [dispatch]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Player Win Loss Report')}>
            <Form onFinish={searchReport}>
                <PlayerWinLossFilters />
                <ActionButtons
                    onDropFilters={onDropFilters}
                    onExport={onExport}
                    exporting={loading && isExport}
                />
            </Form>

            <PlayerWinLossTable loading={loading && !isExport} />

            <PlayerWinLossModal onCancel={closeModal} visible={winLossModalVisible} />
        </Card>
    );
};

export default PlayerWinLoss;
