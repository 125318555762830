import React, { useEffect } from 'react';

import { Table } from 'antd';

import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';
import { getBankCardColumns } from './DepositsTableMeta';
import { BANK_CARDS_COLUMNS } from '../constants';

import { useGetPlayerByIdAsyncEndpoint } from '../../../../services/playerService';

const BankCardTableContent = ({ playerId }) => {
    const [state] = useStore();
    const [playerInfoLoading, handleGetPlayer] = useGetPlayerByIdAsyncEndpoint(playerId);
    const bankCards = playerInfoLoading
        ? []
        : getStateProp(state, 'players.playerDetails.playerInfo.monetary.bankCards', []).filter(
              (card) => card.status === 'Active',
          );

    useEffect(() => {
        handleGetPlayer();
    }, [handleGetPlayer]);

    return (
        <Table
            columns={getBankCardColumns(BANK_CARDS_COLUMNS)}
            dataSource={bankCards}
            loading={playerInfoLoading}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            pagination={false}
        />
    );
};

export default BankCardTableContent;
