import React from 'react';

import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../bankAccountStatementActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';
import BankAccountStatementTableSummary from './BankAccountStatementTableSummary';

export const getColumns = () => {
    const columns = [
        {
            dataIndex: 'requestedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Request Time'),
        },
        {
            dataIndex: 'processedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Process Time'),
        },
        {
            dataIndex: 'transactionNo',
            title: getTranslation('Task Transaction Number'),
        },
        {
            dataIndex: 'cardHolderName',
            title: getTranslation('Bank Account Name'),
        },
        {
            dataIndex: 'bankCardNo',
            title: getTranslation('Bank Account Number'),
        },

        {
            dataIndex: 'currencyCode',
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'cashIn',
            title: getTranslation('Cash In'),
        },
        {
            dataIndex: 'cashOut',
            title: getTranslation('Cash Out'),
        },
        {
            dataIndex: 'fee',
            title: getTranslation('Fees'),
        },
        {
            dataIndex: 'balance',
            title: getTranslation('Balance'),
        },
        {
            dataIndex: 'type',
            dataType: 'translate',
            render: (value) => getTranslation(value),
            title: getTranslation('Type'),
        },
        {
            dataIndex: 'verifierRealName',
            dataType: 'operator',
            title: getTranslation('Operator'),
            width: 80,
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        onCell: (record) => ({ record }),
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);

export const tableBodyWrapper = (props) => {
    return (
        <tbody {...props}>
            {props.children}
            {props.children.length !== 0 && <BankAccountStatementTableSummary />}
        </tbody>
    );
};
