export const PERIOD_TYPES = {
    ONE_TIME_ONLY: 'OneTime',
    PER_MONTH: 'Monthly',
    PER_WEEK: 'Weekly',
};

export const TABLE_DATA = [
    {
        dataIndex: 'level.name',
        title: 'VIP Level Name',
    },
    {
        dataIndex: 'setting.minDeposit',
        inputType: 'decimal',
        title: 'Deposit',
    },
    {
        dataIndex: 'setting.minETO',
        inputType: 'decimal',
        title: 'Turnover',
    },
    {
        dataIndex: 'setting.minETOToMaintain',
        inputType: 'decimal',
        title: 'To Remain VIP (Effective turnover of 3 months)',
    },
    {
        dataIndex: 'setting.promotionBonus',
        inputType: 'decimal',
        title: 'Promotion bonus (once per promotion)',
    },
    {
        dataIndex: 'setting.monthlyRedPacket',
        inputType: 'decimal',
        title: 'Monthly bonus',
    },
    {
        dataIndex: 'setting.birthdayGift',
        inputType: 'decimal',
        title: 'Birthday Gift',
    },
];
