import { useHistory } from 'react-router-dom';
import React from 'react';

import { Row } from 'antd';
import _ from 'lodash';

import './RowActionButton.less';
import { ButtonRed, ButtonYellow } from '../../../common/components/buttons/Buttons';
import { NEWS_CATEGORIES } from '../constants';
import { ROUTES } from '../../../common/constants/routing';
import { getTranslation } from '../../../common/helpers/locale';
import { openDeleteModal, openNewsEdit } from '../newsActions';

const RowActionButtons = ({ dispatch, news }) => {
    const history = useHistory();

    // ============================================
    // METHODS
    // ============================================

    const openEditNews = () => {
        openNewsEdit(dispatch, {
            ...news,
            newsCategory: _.find(
                NEWS_CATEGORIES,
                (category) => category.key === news.newsCategoryId,
            ),
        });
        history.push(ROUTES.DASHBOARD.NEWS.CREATE);
    };

    const onDeleteNews = () => {
        openDeleteModal(dispatch, { newsDelete: news });
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row span={7} align="middle" className="row-buttons__container">
            <ButtonYellow type="primary" className="rowaction-button" onClick={openEditNews}>
                {getTranslation('Edit')}
            </ButtonYellow>
            <ButtonRed
                type="primary"
                className="rowaction-button"
                onClick={onDeleteNews}
                news={news}
            >
                {getTranslation('Delete')}
            </ButtonRed>
        </Row>
    );
};

export default RowActionButtons;
