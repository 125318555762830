import React from 'react';

import { Row } from 'antd';
import _ from 'lodash';

import {
    CurrencyFilter,
    PeriodFilter,
    TimezoneFilter,
    UsernameFilter,
} from '../../../common/components/filters';
import { PlatformFilter, ProductFilter } from '../filter';
import { TIMEZONE } from '../../../common/constants/localization';
import { getStateProp } from '../../../common/helpers/misc';
import { playerWinLossFilterSet } from './playerWinLossActions';
import { switchTimezone } from '../../../common/actions/timezoneActions';
import { useStore } from '../../../store/StateProvider';

const PlayerWinLossFilters = () => {
    const [state, dispatch] = useStore();
    const playerWinLoss = getStateProp(state, 'reports.playerWinLoss');
    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => playerWinLossFilterSet(dispatch, { filter, value });

    const setTimezone = (value) => {
        switchTimezone(dispatch, {
            location: _.find(TIMEZONE, (timezone) => timezone.OFFSET === value).LOCATION,
            offset: value,
        });
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row type="flex" justify="start" align="middle" className="filters-container">
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(playerWinLoss, 'filters.userName')}
                name="userName"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(playerWinLoss, 'filters.dateFrom')}
                dateTo={getStateProp(playerWinLoss, 'filters.dateTo')}
                dateFromName="dateFrom"
                dateToName="dateTo"
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(playerWinLoss, 'filters.currencies')}
                name="currencies"
                mode="multiple"
            />
            <ProductFilter
                onFilterChange={setFilter}
                value={getStateProp(playerWinLoss, 'filters.productCodes')}
            />
            <PlatformFilter
                onFilterChange={setFilter}
                value={getStateProp(playerWinLoss, 'filters.platformCodes')}
            />
            <TimezoneFilter
                onFilterChange={setTimezone}
                value={getStateProp(state, 'timezone.offset')}
            />
        </Row>
    );
};

export default PlayerWinLossFilters;
