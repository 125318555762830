import React from 'react';

import { Input } from 'antd';

import { getTranslation } from '../../../common/helpers/locale';

const CurrentInfoFilter = ({ value, onFilterChange }) => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <Input
            value={value}
            onChange={(e) => onFilterChange('currentInfo', e.target.value)}
            placeholder={getTranslation('Previous Info')}
            className="filter"
        />
    );
};

export default CurrentInfoFilter;
