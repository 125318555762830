import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import React from 'react';

import { Card, Row } from 'antd';
import _ from 'lodash';

import './Cryptocurrency.less';
import { ButtonBlue } from '../../../common/components/buttons/Buttons';
import { CRYPTOCURRENCY_ASPECTS } from '../../../common/constants/misc';
import { PrivateRoute } from '../../../common/components';
import { ROUTES } from '../../../common/constants/routing';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    pauseCryptoDepositRefreshTableTime,
    setCryptoDepositRefreshTableTime,
} from './deposit/cryptoDepositActions';
import {
    pauseCryptoWithdrawRefreshTableTime,
    setCryptoWithdrawRefreshTableTime,
} from './withdrawal/cryptoWithdrawalActions';
import { useStore } from '../../../store/StateProvider';
import CryptocurrencyDeposit from './deposit/CryptocurrencyDeposit';
import CryptocurrencyWithdrawal from './withdrawal/CryptocurrencyWithdrawal';
import TableRefreshSelector from '../../../common/components/TableRefreshSelector';

const Cryptocurrency = () => {
    const [state, dispatch] = useStore();
    const history = useHistory();
    const location = useLocation();

    const currentRoute = location.pathname.replace('/dashboard/payments/cryptocurrency/', '');
    const aspect =
        currentRoute === CRYPTOCURRENCY_ASPECTS.DEPOSIT
            ? getStateProp(state, 'payments.cryptoDeposits')
            : getStateProp(state, 'payments.cryptoWithdrawals');

    // ============================================
    // METHODS
    // ============================================

    const isActive = (subRoute) =>
        location.pathname.replace('/dashboard/payments/cryptocurrency/', '') === subRoute;

    const onTabChange = (key) => history.push(key);

    const setRefreshTime = (timeOut) => {
        if (currentRoute === CRYPTOCURRENCY_ASPECTS.DEPOSIT)
            setCryptoDepositRefreshTableTime(dispatch, {
                timeOut,
            });
        else
            setCryptoWithdrawRefreshTableTime(dispatch, {
                timeOut,
            });
    };

    const onTimePause = () => {
        if (currentRoute === CRYPTOCURRENCY_ASPECTS.DEPOSIT)
            pauseCryptoDepositRefreshTableTime(dispatch);
        else pauseCryptoWithdrawRefreshTableTime(dispatch);
    };

    const onTimeStart = () => {
        if (currentRoute === CRYPTOCURRENCY_ASPECTS.DEPOSIT)
            setCryptoDepositRefreshTableTime(dispatch, {
                timeOut: 15,
            });
        else
            setCryptoWithdrawRefreshTableTime(dispatch, {
                timeOut: 15,
            });
    };

    // ============================================
    // RENDER
    // ============================================

    const Extra = (
        <Row align="middle" justify="space-between">
            {_.map(_.toPairs(CRYPTOCURRENCY_ASPECTS), ([key, value]) => (
                <ButtonBlue
                    active={isActive(value)}
                    onClick={() => onTabChange(value)}
                    key={key}
                    className="tab-button"
                >
                    {getTranslation(_.capitalize(key))}
                </ButtonBlue>
            ))}

            <TableRefreshSelector
                onTimeChange={setRefreshTime}
                onTimePause={onTimePause}
                onTimeStart={onTimeStart}
                timeOut={getStateProp(aspect, 'operation.refreshTime')}
            />
        </Row>
    );

    return (
        <Card title={getTranslation('Cryptocurrency')} extra={Extra}>
            <Switch>
                <PrivateRoute
                    path={ROUTES.DASHBOARD.PAYMENTS.CRYPTOCURRENCY.DEPOSIT}
                    component={CryptocurrencyDeposit}
                />
                <PrivateRoute
                    path={ROUTES.DASHBOARD.PAYMENTS.CRYPTOCURRENCY.WITHDRAWAL}
                    component={CryptocurrencyWithdrawal}
                />
                <Redirect to={ROUTES.DASHBOARD.PAYMENTS.CRYPTOCURRENCY.DEPOSIT} />
            </Switch>
        </Card>
    );
};

export default Cryptocurrency;
