import { useCallback, useEffect, useState } from 'react';

import { API } from '../common/constants/api';
import { PAYMENT_ORDER_OPERATIONS, PAYMENT_ORDER_STATUSES } from '../common/constants/misc';
import { clearError } from '../common/actions/errorActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import { message } from 'antd';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import {
    withdrawalRecordOperationFailure,
    withdrawalRecordOperationSuccess,
    withdrawalRecordsGet,
    withdrawalRecordsGetFailure,
    withdrawalRecordsGetSuccess,
} from '../components/transaction-history/withdrawal-records/withdrawalRecordsActions';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, TRANSACTION_HISTORY, PAYMENTS } = API;

export function useGetWithdrawalRecordsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { withdrawalRecords } = getStateProp(state, 'transactionHistory');
    const { pageNo, pageSize } = getStateProp(withdrawalRecords, 'paging');
    const filters = getStateProp(withdrawalRecords, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetWithdrawalRecords = useCallback(() => {
        withdrawalRecordsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${TRANSACTION_HISTORY.WITHDRAWAL_RECORDS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            withdrawalRecordsGetSuccess(dispatch, {
                dataTotal: response.data.data[0].dataTotal,
                fetchData: handleGetWithdrawalRecords,
                grandTotal: response.data.data[0].grandTotal,
                list: response.data.data[0].data,
                pageDataTotal: response.data.data[0].pageDataTotal,
                pageTotal: response.data.data[0].pageTotal,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetWithdrawalRecords, response]);

    useEffect(() => {
        handleGetWithdrawalRecords();
    }, [handleGetWithdrawalRecords, pageNo, pageSize]);

    const errorMsg = useError(error, withdrawalRecordsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetWithdrawalRecords];
}

export function useWithdrawalRecordsOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();

    const { withdrawalRecords } = getStateProp(state, 'transactionHistory');
    const refreshTable = getStateProp(withdrawalRecords, 'fetchData', () => {});
    const operation = getStateProp(withdrawalRecords, 'operation');
    const remarks = getStateProp(withdrawalRecords, 'verifyInfo.auditRemark');

    const transactionNo = getStateProp(operation, 'withdrawalRecord.transactionNo');
    const action = getStateProp(operation, 'action');

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!action,
        method: 'POST',
        options: {
            data: {
                isFirstLvl: true,
                remarks,
            },
        },
        trigger: action,
        url: `${VERSION.V1}${PAYMENTS.WITHDRAWALS.ROOT}/${transactionNo}/${action}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            withdrawalRecordOperationSuccess(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    useEffect(() => {
        if (response) {
            if (action === PAYMENT_ORDER_OPERATIONS.VERIFY) {
                message.success(getTranslation('Transaction verified!'));
            } else if (action === PAYMENT_ORDER_STATUSES.REJECT) {
                message.success(getTranslation('Transaction rejected!'));
            }
        }
    }, [response, action]);

    const errorMsg = useError(error, withdrawalRecordOperationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response];
}
