import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

import { API } from '../common/constants/api';
import {
    activatedEwalletSetupModalClose,
    activatedEwalletUpdate,
    activatedEwalletUpdateFailure,
    activatedEwalletUpdateSuccess,
    activeEwalletGet,
    activeEwalletGetFailure,
    activeEwalletGetSuccess,
    ewalletSetup,
    ewalletSetupFailure,
    ewalletSetupSuccess,
} from '../components/payments/e-wallet-setup/active-e-wallet/activeEwalletActions';
import {
    deactivatedEwalletGet,
    deactivatedEwalletGetFailure,
    deactivatedEwalletGetSuccess,
    deactivatedEwalletSetupModalClose,
    deactivatedEwalletUpdate,
    deactivatedEwalletUpdateFailure,
    deactivatedEwalletUpdateSuccess,
} from '../components/payments/e-wallet-setup/deactivated-e-wallet/DeactivatedEwalletActions';
import {
    ewalletGet,
    ewalletGetFailure,
    ewalletGetSuccess,
} from '../components/payments/e-wallet-setup/ewalletActions';
import { getTranslation } from '../common/helpers/locale';
import { message } from 'antd';

import {
    OPERATION_TIME_LIMIT,
    PAYMENT_EWALLET_ACCOUNT_TYPES,
    PAYMENT_ORDER_OPERATIONS,
} from '../common/constants/misc';
import { clearError } from '../common/actions/errorActions';
import {
    closeConfirmModal,
    closeVerifyModal,
    ewalletTransferOperationSuccess,
    ewalletTransfersGet,
    ewalletTransfersGetFailure,
    ewalletTransfersGetSuccess,
    ewalletTransfersOperationFailure,
    performEwalletTransferOperation,
} from '../components/payments/deposits/EwalletTransfersActions';
import {
    ewalletConfiguration,
    ewalletConfigurationFailure,
    ewalletConfigurationGet,
    ewalletConfigurationGetFailure,
    ewalletConfigurationGetSuccess,
    ewalletConfigurationSuccess,
    onCloseConfirmModal,
} from '../components/payments/e-wallet-configuration/ewalletConfigurationActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import { useDispatch, useStore } from '../store/StateProvider';
import { useNotification } from './hooks/useNotification';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, PAYMENTS } = API;

export function useSetupEwalletAsyncEndpoint(ewalletInfo) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { eWalletId } = ewalletInfo;
    const refreshTable = getStateProp(state, 'payments.activeEwalletAccount.fetchData', () => {});

    // ============================================
    // METHODS
    // ============================================

    const handleAddEwallet = () => {
        setTrigger(+new Date());
        ewalletSetup(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: { ...ewalletInfo },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.EWALLET.EWALLET_LIST}/${eWalletId}/${'accounts'}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            ewalletSetupSuccess(dispatch);
            activatedEwalletSetupModalClose(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, ewalletSetupFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleAddEwallet];
}

export function useGetEwalletAsyncEndpoint(countryId) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetEwallet = useCallback(() => {
        ewalletGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!countryId,
        method: 'GET',
        options: {
            params: {
                CountryId: countryId,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.EWALLET.EWALLET_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            ewalletGetSuccess(dispatch, {
                list: response.data.data,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        handleGetEwallet();
    }, [handleGetEwallet, countryId]);

    const errorMsg = useError(error, ewalletGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useGetActivatedEwalletsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const filters = getStateProp(state, 'payments.activeEwalletAccount.filters', {});
    const { pageNo, pageSize } = getStateProp(state, 'payments.activeEwalletAccount.paging');

    // ============================================
    // METHODS
    // ============================================

    const handleGetActivatedEwalletAccounts = useCallback(() => {
        activeEwalletGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                activatedStatus: 'Activated',
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.EWALLET.EWALLET_ACCOUNT_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            activeEwalletGetSuccess(dispatch, {
                fetchData: handleGetActivatedEwalletAccounts,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetActivatedEwalletAccounts, response]);

    useEffect(() => {
        handleGetActivatedEwalletAccounts();
    }, [handleGetActivatedEwalletAccounts, pageNo, pageSize]);

    const errorMsg = useError(error, activeEwalletGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetActivatedEwalletAccounts];
}

export function useGetDeactivatedEwalletsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const filters = getStateProp(state, 'payments.deactivatedEwalletAccount.filters', {});
    const { pageNo, pageSize } = getStateProp(state, 'payments.deactivatedEwalletAccount.paging');

    // ============================================
    // METHODS
    // ============================================

    const handleGetDeactivatedEwalletAccounts = useCallback(() => {
        deactivatedEwalletGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================
    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                activatedStatus: 'Deactivated',
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.EWALLET.EWALLET_ACCOUNT_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            deactivatedEwalletGetSuccess(dispatch, {
                fetchData: handleGetDeactivatedEwalletAccounts,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetDeactivatedEwalletAccounts, response]);

    useEffect(() => {
        handleGetDeactivatedEwalletAccounts();
    }, [handleGetDeactivatedEwalletAccounts, pageNo, pageSize]);

    const errorMsg = useError(error, deactivatedEwalletGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetDeactivatedEwalletAccounts];
}

export function useUpdateActivatedEwalletAsyncEndpoint(eWalletInfo) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const accountDetails = getStateProp(
        state,
        'payments.activeEwalletAccount.modal.accountDetails',
        {},
    );
    const refreshTable = getStateProp(state, 'payments.activeEwalletAccount.fetchData', () => {});

    const {
        eWalletId,
        accountId,
        memberLevels,
        trustLevels,
        specialPlayerNames,
        dailyMaximumAmount,
        isWebShow,
    } = accountDetails;

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateActivatedEwallet = () => {
        setTrigger(+new Date());
        activatedEwalletUpdate(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                ...eWalletInfo,
                dailyMaximumAmount,
                isWebShow,
                memberLevels,
                specialPlayerNames,
                trustLevels,
            },
        },
        trigger,
        url: `${VERSION.V1}${
            PAYMENTS.EWALLET.EWALLET_LIST
        }/${eWalletId}/${'accounts'}/${accountId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            activatedEwalletUpdateSuccess(dispatch);
            activatedEwalletSetupModalClose(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, activatedEwalletUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateActivatedEwallet];
}

export function useUpdateDeactivatedEwalletAsyncEndpoint(eWalletInfo) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const accountDetails = getStateProp(
        state,
        'payments.deactivatedEwalletAccount.modal.accountDetails',
        {},
    );
    const refreshTable = getStateProp(
        state,
        'payments.deactivatedEwalletAccount.fetchData',
        () => {},
    );

    const { eWalletId, accountId } = accountDetails;

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateDeactivatedEwallet = () => {
        setTrigger(+new Date());
        deactivatedEwalletUpdate(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                ...eWalletInfo,
            },
        },
        trigger,
        url: `${VERSION.V1}${
            PAYMENTS.EWALLET.EWALLET_LIST
        }/${eWalletId}/${'accounts'}/${accountId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            deactivatedEwalletUpdateSuccess(dispatch);
            deactivatedEwalletSetupModalClose(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, deactivatedEwalletUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateDeactivatedEwallet];
}

export function useGetEwalletConfigurationAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const filters = getStateProp(state, 'payments.ewalletConfiguration.filters', {});
    const { pageNo, pageSize } = getStateProp(state, 'payments.ewalletConfiguration.paging');

    // ============================================
    // METHODS
    // ============================================

    const handleGetEwalletConfiguration = useCallback(() => {
        ewalletConfigurationGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                activatedStatus: 'Activated',
                pageNo,
                pageSize,
                type: PAYMENT_EWALLET_ACCOUNT_TYPES.TRANSFER_IN,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.EWALLET.EWALLET_ACCOUNT_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            ewalletConfigurationGetSuccess(dispatch, {
                fetchData: handleGetEwalletConfiguration,
                list: response.data.data,
            });
        }
    }, [dispatch, handleGetEwalletConfiguration, response]);

    useEffect(() => {
        handleGetEwalletConfiguration();
    }, [handleGetEwalletConfiguration, pageNo, pageSize]);

    const errorMsg = useError(error, ewalletConfigurationGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetEwalletConfiguration];
}

export function useUpdateEwalletConfigurationAsyncEndpoint({ specialPlayerNames, ...rest }) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const ewalletConfigurationData = getStateProp(state, 'payments.ewalletConfiguration', {});
    const accountDetails = getStateProp(ewalletConfigurationData, 'modalData.accountDetails', {});
    const refreshTable = getStateProp(ewalletConfigurationData, 'fetchData', () => {});

    const {
        memberLevels,
        trustLevels,
        specialPlayerIds,
        dailyMaximumAmount,
        eWalletId,
        accountId,
        remarks,
        isWebShow,
        ...restAccountDetails
    } = accountDetails;

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateEwalletConfiguration = () => {
        setTrigger(+new Date());
        ewalletConfiguration(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                ...rest,
                ...restAccountDetails,
                specialPlayerNames: _.isArray(specialPlayerNames)
                    ? specialPlayerNames
                    : [specialPlayerNames],
            },
        },
        trigger,
        url: `${VERSION.V1}${
            PAYMENTS.EWALLET.EWALLET_LIST
        }/${eWalletId}/${'accounts'}/${accountId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            ewalletConfigurationSuccess(dispatch);
            onCloseConfirmModal(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, ewalletConfigurationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateEwalletConfiguration];
}

export function useGetEwalletTransfersAsyncEndpoint(isOnline) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const ewalletTransfers = getStateProp(state, 'payments.ewalletTransfers');
    const { pageNo, pageSize, totalRecordCountNoFilter } = getStateProp(ewalletTransfers, 'paging');
    const filters = getStateProp(ewalletTransfers, 'filters', {});
    const refreshTime = getStateProp(ewalletTransfers, 'operation.refreshTime');
    const refreshTimePause = getStateProp(ewalletTransfers, 'operation.refreshTimePause');

    // ============================================
    // METHODS
    // ============================================

    const handleGetEwalletTransfers = useCallback(() => {
        ewalletTransfersGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.EWALLET.EWALLET_TRANSFERS}`,
    });

    useEffect(() => {
        let timeInterval;
        if (response || isOnline)
            timeInterval = setTimeout(() => handleGetEwalletTransfers(), refreshTime * 1000);
        if (refreshTimePause) clearTimeout(timeInterval);
        return () => clearTimeout(timeInterval);
    }, [handleGetEwalletTransfers, isOnline, refreshTime, refreshTimePause, response]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            ewalletTransfersGetSuccess(dispatch, {
                dataTotal: response.data.data[0].dataTotal,
                fetchData: handleGetEwalletTransfers,
                grandTotal: response.data.data[0].grandTotal,
                list: response.data.data[0].data,
                pageDataTotal: response.data.data[0].pageDataTotal,
                pageTotal: response.data.data[0].pageTotal,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetEwalletTransfers, response]);

    useEffect(() => {
        handleGetEwalletTransfers();
    }, [handleGetEwalletTransfers, pageNo, pageSize]);

    const errorMsg = useError(error, ewalletTransfersGetFailure);
    useToasterErrorMessage(error, errorMsg);

    useNotification(
        totalRecordCountNoFilter,
        getStateProp(response, 'data.paging.totalRecordCountNoFilter', 0),
        'You have new Ewallet transfer record',
    );

    return [loading, handleGetEwalletTransfers];
}

export function useEwalletTransferOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();

    const ewalletTransfers = getStateProp(state, 'payments.ewalletTransfers');

    const refreshTable = getStateProp(ewalletTransfers, 'fetchData', () => {});
    const operation = getStateProp(ewalletTransfers, 'operation');
    const transactionIds = getStateProp(ewalletTransfers, 'selectedTransactionId');
    const transactionId = getStateProp(operation, 'transaction.transactionId');
    const action = getStateProp(operation, 'action');
    const [timeOutId, setTimeOutId] = useState(null);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!action,
        method: 'POST',
        options: {
            data: {
                remark: '',
                transactionIds,
            },
        },
        trigger: action,
        url:
            action === PAYMENT_ORDER_OPERATIONS.REJECT
                ? `${VERSION.V1}${PAYMENTS.EWALLET.EWALLET_TRANSFERS}/${action}`
                : `${VERSION.V1}${PAYMENTS.EWALLET.EWALLET_TRANSFERS}/${transactionId}/${action}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            ewalletTransferOperationSuccess(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    useEffect(() => {
        if (response) {
            if (action === PAYMENT_ORDER_OPERATIONS.COMPLETE) {
                message.success(getTranslation('Transaction verified!'));
            } else if (action === PAYMENT_ORDER_OPERATIONS.REJECT) {
                message.success(getTranslation('Transaction rejected!'));
            }
        }
    }, [response, action]);

    useEffect(() => {
        if (response && action) {
            clearTimeout(timeOutId);
            if (action === PAYMENT_ORDER_OPERATIONS.ATTEND) {
                setTimeOutId(
                    setTimeout(() => {
                        performEwalletTransferOperation(dispatch, {
                            action: PAYMENT_ORDER_OPERATIONS.RELEASE,
                        });
                        closeConfirmModal(dispatch);
                        closeVerifyModal(dispatch);
                    }, OPERATION_TIME_LIMIT),
                );
            }
        }
    }, [response, action, dispatch, timeOutId]);

    const errorMsg = useError(error, ewalletTransfersOperationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}
