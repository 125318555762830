import React from 'react';

import { RenderBettingDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS_2000 } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../betListActions';
import { getStatusColor, sortTableByDataType } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import BetListRecordDetails from './BetListRecordDetails';

export const getColumns = ({ handleGetDetailUrl, paging, tableWidth, timezone }) => {
    const initStartRowNo = paging ? (paging.pageNo - 1) * paging.pageSize : 0;
    const columns = [
        {
            dataIndex: 'No',
            dataType: 'indexNumber',
            render: (text, record, index) => <span>{initStartRowNo + (index + 1)}</span>,
            title: getTranslation('No.'),
        },
        {
            dataIndex: 'transNo',
            render: (value) => {
                return value !== null ? value : <span>-</span>;
            },
            title: getTranslation('Task Transaction Number'),
        },
        {
            dataIndex: 'refNo',
            render: (value) => {
                return value !== null ? value : <span>-</span>;
            },
            title: getTranslation('Ref No.'),
        },
        {
            dataIndex: 'statementDate',
            dataType: 'date',
            render: (date) => RenderBettingDateFormat({ date, hideTime: true, timezone }),
            title: getTranslation('Statement Date'),
        },
        {
            dataIndex: 'userName',
            title: getTranslation('Username'),
            width: 120,
        },
        {
            dataIndex: 'currency',
            title: getTranslation('Currency'),
            width: 80,
        },
        {
            dataIndex: 'betTime',
            dataType: 'date',
            render: (date) => RenderBettingDateFormat({ date, timezone }),
            title: getTranslation('Bet Time'),
            width: 100,
        },
        {
            dataIndex: 'platformDesc',
            title: getTranslation('Platform'),
        },
        {
            dataIndex: 'productDesc',
            title: getTranslation('Product'),
        },
        {
            dataIndex: 'detail',
            dataType: 'detail',
            render: (text, record) => (
                <BetListRecordDetails record={record} handleGetDetailUrl={handleGetDetailUrl} />
            ),
            title: getTranslation('Detail'),
            width: 250,
        },
        {
            dataIndex: 'odds',
            render: (value) => <span>{value ? value : '-'}</span>,
            title: getTranslation('Odds'),
        },
        {
            dataIndex: 'oddsType',
            render: (value) => {
                return value !== null ? value : <span>-</span>;
            },
            title: getTranslation('Odd Type'),
        },
        {
            dataIndex: 'beforeStake',
            render: (value) => {
                return value !== null ? value : <span>-</span>;
            },
            title: getTranslation('Before Stake'),
        },
        {
            dataIndex: 'stake',
            render: (value) => {
                return value !== null ? value : <span>-</span>;
            },
            title: getTranslation('Stake'),
        },
        {
            dataIndex: 'effectiveTurnover',
            render: (value) => {
                return value !== null ? value : <span>-</span>;
            },
            title: getTranslation('Effective Turnover'),
        },
        {
            dataIndex: 'betStatus',
            render: (value) => <span>{value}</span>,
            title: getTranslation('Win Loss'),
        },
        {
            dataIndex: 'status',
            render: (value) => (
                <span
                    style={{
                        color: getStatusColor(value),
                    }}
                >
                    {value}
                </span>
            ),
            title: getTranslation('Status'),
        },
        {
            dataIndex: 'winLossAmount',
            title: getTranslation('Win Loss Amount'),
        },
        {
            dataIndex: 'paidStake',
            title: getTranslation('Paid Stake'),
        },
        {
            dataIndex: 'holdStake',
            render: (value) => {
                return value !== null ? value : <span>-</span>;
            },
            title: getTranslation('Hold Stake'),
        },
        {
            dataIndex: 'settlementDate',
            dataType: 'date',
            render: (date) => RenderBettingDateFormat({ date, timezone }),
            title: getTranslation('Paid Time'),
            width: tableWidth < 1600 && 100,
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS_2000,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
