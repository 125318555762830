import { LANGUAGES } from '../../common/constants/localization';
import _ from 'lodash';

export const getSelectedCurrencies = (whatsNewInfo) => {
    let selectedCurrencies = [];

    _.forEach(whatsNewInfo.currencies, function(e) {
        selectedCurrencies.push(e.currencyCode);
    });

    return selectedCurrencies;
};

export const getSelectedLanguages = (languages) => {
    let selectedLanguages = [];

    _.forEach(languages, function(e) {
        selectedLanguages.push(e.languageCode);
    });

    return selectedLanguages;
};

export const updateWhatsNewInfo = (whatsNewInfo, e, key, currencyCode, languageCode) => {
    let newInfo = whatsNewInfo;

    if (key === 'currency') {
        e.target.checked
            ? newInfo['currencies'] && newInfo['currencies'].length
                ? newInfo['currencies'].push({
                      currencyCode: e.target.value,
                      languages: [
                          {
                              description: '',
                              imageInBase64: '',
                              languageCode: _.find(LANGUAGES, ['code', 'EN']).value,
                              title: '',
                          },
                      ],
                  })
                : (newInfo['currencies'] = [
                      {
                          currencyCode: e.target.value,
                          languages: [
                              {
                                  description: '',
                                  imageInBase64: '',
                                  languageCode: _.find(LANGUAGES, ['code', 'EN']).value,
                                  title: '',
                              },
                          ],
                      },
                  ])
            : _.remove(newInfo.currencies, function(currency) {
                  return currency.currencyCode === e.target.value;
              });
    } else if (key === 'language' && currencyCode) {
        e.target.checked
            ? _.forEach(newInfo.currencies, function(i) {
                  i.currencyCode === currencyCode &&
                      i.languages.push({
                          description: '',
                          imageInBase64: '',
                          languageCode: e.target.value,
                          title: '',
                      });
              })
            : _.forEach(newInfo.currencies, function(i) {
                  i.currencyCode === currencyCode &&
                      _.remove(i.languages, function(lang) {
                          return lang.languageCode === e.target.value;
                      });
              });
    } else if (languageCode && currencyCode) {
        _.forEach(newInfo.currencies, function(i) {
            i.currencyCode === currencyCode &&
                _.forEach(i.languages, function(lang) {
                    if (lang.languageCode === languageCode) lang[key] = e;
                });
        });
    } else {
        newInfo[key] = e;
    }

    return newInfo;
};
