import React from 'react';

import { Card, Row } from 'antd';
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';

import './TransactionAmountSetting.less';
import { TRANSACTION_AMOUNT_SETTING_ASPECTS } from '../../../common/constants/misc';
import { ButtonBlue } from '../../../common/components/buttons/Buttons';
import { PrivateRoute } from '../../../common/components';
import { ROUTES } from '../../../common/constants/routing';
import { getTranslation } from '../../../common/helpers/locale';
import TransactionAmountSettingBanks from './TransactionAmountSettingBanks';
import TransactionAmountSettingEwallet from './TransactionAmountSettingEwallet';

const TransactionAmountSetting = () => {
    const history = useHistory();
    const location = useLocation();
    const isActive = (subRoute) =>
        location.pathname.replace('/dashboard/payments/transaction-amount-setting/', '') ===
        subRoute;
    const onTabChange = (key) => history.push(key);

    // ============================================
    // RENDER
    // ============================================

    const Extra = (
        <Row align="middle" justify="space-between">
            {_.map(_.toPairs(TRANSACTION_AMOUNT_SETTING_ASPECTS), ([key, value]) => (
                <ButtonBlue
                    active={isActive(value)}
                    onClick={() => onTabChange(value)}
                    key={key}
                    className="tab-button"
                >
                    {getTranslation(_.capitalize(key))}
                </ButtonBlue>
            ))}
        </Row>
    );

    return (
        <Card title={getTranslation('Transaction Amount Setting')} extra={Extra}>
            <Switch>
                <PrivateRoute
                    path={ROUTES.DASHBOARD.PAYMENTS.TRANSACTION_AMOUNT_SETTING.BANK}
                    component={TransactionAmountSettingBanks}
                    hasAccessControl
                />
                <PrivateRoute
                    path={ROUTES.DASHBOARD.PAYMENTS.TRANSACTION_AMOUNT_SETTING.EWALLET}
                    component={TransactionAmountSettingEwallet}
                    hasAccessControl
                />
                <Redirect to={ROUTES.DASHBOARD.PAYMENTS.TRANSACTION_AMOUNT_SETTING.BANK} />
            </Switch>
        </Card>
    );
};

export default TransactionAmountSetting;
