import React, { useEffect, useState } from 'react';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Col, Form, Input, List, Modal, Row, Switch } from 'antd';

import './ConfigurationModal.less';
import { ButtonGray, ButtonGreen } from '../../../../common/components/buttons/Buttons';
import { DEBOUNCE_DELAY_SEC, MEMBER_LEVELS, TRUST_LEVELS } from '../../../../common/constants/misc';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { merchantConfigurationPayServiceSelected } from '../merchantConfigurationActions';
import { useStore } from '../../../../store/StateProvider';
import {
    useUpdateMerchantConfigurationAsyncEndpoint,
    useUpdateMerchantConfigurationPayServiceAsyncEndpoint,
} from '../../../../services/merchantService';
import CheckboxInput from '../table/input-field/CheckboxInput';
import RadioButton from '../table/input-field/RadioButton';

const { TextArea } = Input;

const ConfigurationModal = ({ onCancel }) => {
    const [form] = Form.useForm();
    const { resetFields } = form;
    const [state, dispatch] = useStore();
    const modalData = getStateProp(state, 'payments.merchantConfiguration.modalData', {});
    const merchantDetails = getStateProp(modalData, 'merchantDetails', {});
    const provider = getStateProp(merchantDetails, 'providers[0]', []);
    const [closeModal, setCloseModal] = useState(false);
    const [formValues, setFormValues] = useState({});

    // ============================================
    // METHODS
    // ============================================

    const submitForm = (values) => {
        setFormValues(values);
        handleUpdateActivatedMerchant();
    };

    const deepCopy = (o) => {
        // if not array or object or is null return self
        if (typeof o !== 'object' || o === null) return o;
        let newO, i;
        // handle case: array
        if (o instanceof Array) {
            let l;
            newO = [];
            for (i = 0, l = o.length; i < l; i++) newO[i] = deepCopy(o[i]);
            return newO;
        }
        // handle case: object
        newO = {};
        for (i in o) if (o.hasOwnProperty(i)) newO[i] = deepCopy(o[i]);
        return newO;
    };

    const handlePaymentEnable = (value, item, index, name) => {
        const oldItem = deepCopy(item);

        oldItem.index = index;

        item.index = index;

        name === 'isWebShow'
            ? (item.isWebShow = value)
            : name === 'maximumSupportedAmount'
            ? (item.maximumSupportedAmount = value)
            : (item.minimumSupportedAmount = value);

        setCloseModal(true);
        merchantConfigurationPayServiceSelected(dispatch, {
            item,
            oldItem,
        });

        setTimeout(() => {
            handleUpdateMerchantPayService();
            setCloseModal(false);
        }, DEBOUNCE_DELAY_SEC);
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleUpdateActivatedMerchant] = useUpdateMerchantConfigurationAsyncEndpoint(
        formValues,
    );

    const [
        updateMerchantPayServiceLoading,
        handleUpdateMerchantPayService,
        error,
    ] = useUpdateMerchantConfigurationPayServiceAsyncEndpoint();

    useEffect(() => {
        if (error) {
            resetFields();
        }
    }, [error, resetFields]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            title={getTranslation('Edit Merchant Configuration')}
            visible={true}
            onCancel={closeModal ? () => {} : onCancel}
            className="merchant-configuration-modal"
            centered
            width={750}
            closable={false}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        form="merchant-configuration"
                        label={getTranslation('Confirm')}
                        loading={loading}
                        disabled={updateMerchantPayServiceLoading}
                        htmlType="submit"
                    />

                    <ButtonGray
                        label={getTranslation('Cancel')}
                        onClick={onCancel}
                        disabled={updateMerchantPayServiceLoading}
                    />
                </Row>,
            ]}
        >
            <Form
                form={form}
                name="merchant-configuration"
                onFinish={submitForm}
                initialValues={{
                    isWebShow: merchantDetails.isWebShow,
                    memberLevels: provider.memberLevels,
                    remark: merchantDetails.remark,
                    trustLevels: provider.trustLevels,
                }}
            >
                <div className="merchant-configuration-details-row">
                    <Row className="details-label">
                        <Col span={8}>
                            <label>{getTranslation('Merchant Name')}</label>
                        </Col>
                        <Col span={13}>
                            <span>{merchantDetails.displayName}</span>
                        </Col>
                    </Row>

                    <Row className="details-label">
                        <Col span={8}>
                            <label>{getTranslation('Merchant Code')}</label>
                        </Col>
                        <Col span={13}>
                            <span>{merchantDetails.gatewayName}</span>
                        </Col>
                    </Row>

                    <Row className="details-label">
                        <Col span={8}>
                            <label>{getTranslation('Merchant Number')}</label>
                        </Col>
                        <Col span={13}>
                            <span>{merchantDetails.merchantId}</span>
                        </Col>
                    </Row>

                    <Row className="details-label">
                        <Col span={8}>
                            <label>{getTranslation('Merchant Domain')}</label>
                        </Col>
                        <Col span={13}>
                            <span>{merchantDetails.merchantDomain}</span>
                        </Col>
                    </Row>

                    <Row className="details-label">
                        <Col span={8}>
                            <label>{getTranslation('Trust Level')}</label>
                        </Col>
                        <Col span={13}>
                            <CheckboxInput options={TRUST_LEVELS} name="trustLevels" />
                        </Col>
                    </Row>

                    <Row className="details-label">
                        <Col span={8}>
                            <label>{getTranslation('Member Level')}</label>
                        </Col>
                        <Col span={13}>
                            <CheckboxInput options={MEMBER_LEVELS} name="memberLevels" />
                        </Col>
                    </Row>
                    <Row className="details-label">
                        <Col span={8}>
                            <label>{getTranslation('Remark')}</label>
                        </Col>
                        <Col span={13}>
                            <Form.Item name="remark">
                                <TextArea className="table__input" rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Col className="details-label">
                        <Row style={{ padding: '10px' }}>
                            <Col span={10}>
                                <label>{getTranslation('Payment Methods')}</label>
                            </Col>
                            <Col span={6}>
                                <label>{getTranslation('Minimum')}</label>
                            </Col>
                            <Col span={5}>
                                <label>{getTranslation('Maximum')}</label>
                            </Col>
                            <Col span={3}>
                                <label>{getTranslation('Web Show')}</label>
                            </Col>
                        </Row>
                        <Row>
                            <List
                                bordered={true}
                                itemLayout="horizontal"
                                dataSource={merchantDetails.providers}
                                style={{ width: '100%' }}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <Col span={9}>
                                            <label>{item.providerName}</label>
                                        </Col>
                                        <Col span={6}>
                                            <label>
                                                <Input
                                                    value={item.minimumSupportedAmount}
                                                    onChange={(e) =>
                                                        handlePaymentEnable(
                                                            e.target.value,
                                                            item,
                                                            index,
                                                            'minimumSupportedAmount',
                                                        )
                                                    }
                                                    disabled={updateMerchantPayServiceLoading}
                                                />
                                            </label>
                                        </Col>
                                        <Col span={6}>
                                            <label>
                                                <Input
                                                    value={item.maximumSupportedAmount}
                                                    onChange={(e) =>
                                                        handlePaymentEnable(
                                                            e.target.value,
                                                            item,
                                                            index,
                                                            'maximumSupportedAmount',
                                                        )
                                                    }
                                                    disabled={updateMerchantPayServiceLoading}
                                                />
                                            </label>
                                        </Col>
                                        <Col span={2}>
                                            <Switch
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                                form={form}
                                                checked={item.isWebShow}
                                                onChange={(value) =>
                                                    handlePaymentEnable(
                                                        value,
                                                        item,
                                                        index,
                                                        'isWebShow',
                                                    )
                                                }
                                                loading={updateMerchantPayServiceLoading}
                                            />
                                        </Col>
                                    </List.Item>
                                )}
                            />
                        </Row>
                    </Col>
                </div>

                <div className="merchant-configuration-web-show__row">
                    <Row className="details-label">
                        <Col span={8}>
                            <label>{getTranslation('Web Show')}</label>
                        </Col>
                        <Col span={13}>
                            <RadioButton name="isWebShow" />
                        </Col>
                    </Row>
                </div>
            </Form>
        </Modal>
    );
};

export default ConfigurationModal;
