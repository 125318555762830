export const DANGER_LEVEL_TAG = {
    _1_NORMAL: 'Normal',
    _2_MULTIPLE_ACCOUNTS: 'Multiple Accounts',
    _3_ARBITRAGE: 'Arbitrage',
    _4_BONUS_HUNTER: 'Bonus Hunter',
};

export const YES_NO = [
    {
        key: 'Yes',
        value: true,
    },
    {
        key: 'No',
        value: false,
    },
];

export const ENABLED_DISABLED = [
    {
        key: 'Enabled',
        value: true,
    },
    {
        key: 'Disabled',
        value: false,
    },
];

export const WITHDRAW_FILTERS = {
    _1_NORMAL: 'Normal',
    _2_RESTRICTED: 'Restricted',
};

export const DEPOSIT_FILTERS = {
    _1_BANK_TRANSFER: 'Bank Transfer',
    _2_ONLINE_BANKING: 'Online Banking',
    _3_CRYPTOCURRENCY: 'Cryptocurrency',
};

export const DEPOSIT_PROHIBITION = [
    {
        key: 'bankTransferStatus',
        name: 'Bank Transfer',
    },
    {
        key: 'onlineBankingStatus',
        name: 'Online Banking',
    },
    {
        key: 'cryptoCurrencyStatus',
        name: 'Cryptocurrency',
    },
    {
        key: 'eWalletStatus',
        name: 'E-Wallet',
    },
];

export const DANGER_LEVEL_SETTING_TABS = [
    {
        key: 1,
        name: 'Bonus',
    },
    {
        key: 2,
        name: 'Deposit Method',
    },
    {
        key: 3,
        name: 'Withdrawal',
    },
];
