import React from 'react';

import { Col, Row } from 'antd';
import _ from 'lodash';

import { BANK_CARDS_MODAL_TYPES } from '../constants';
import { ButtonBlue } from '../../../../common/components/buttons/Buttons';
import { closeBankCardsModal, openBankCardsModal } from '../playerDetailsActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useGetPlayerByIdAsyncEndpoint } from '../../../../services/playerService';
import { useStore } from '../../../../store/StateProvider';
import BankCardResultsTable from './table/BankCardResultsTable';
import BankCardsModal from './BankCardsModal';

const BankCards = (props) => {
    const { playerId } = props.match.params;
    const [state, dispatch] = useStore();
    const bankCardsModal = getStateProp(state, 'players.playerDetails.bankCardsModal', {});
    const bankCards = getStateProp(state, 'players.playerDetails.playerInfo.bankCards', []);

    const showAddBankCard = _.takeWhile(bankCards, ['status', 'Active']).length < 3;

    // ============================================
    // METHODS
    // ============================================

    const handleAddBankCard = () =>
        openBankCardsModal(dispatch, { modalType: BANK_CARDS_MODAL_TYPES.ADD_BANK_CARD });

    const handleCloseBankCardsModal = () => {
        closeBankCardsModal(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    const [playerInfoLoading, handleGetPlayerById] = useGetPlayerByIdAsyncEndpoint(playerId);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col span={24}>
            {showAddBankCard && (
                <Row align="middle" justify="end" style={{ paddingBottom: 20 }}>
                    <ButtonBlue
                        label={getTranslation('Add Bank Card')}
                        onClick={handleAddBankCard}
                    />
                </Row>
            )}
            <BankCardResultsTable loading={playerInfoLoading} />
            {bankCardsModal.isVisible && (
                <BankCardsModal
                    bankCardsModal={bankCardsModal}
                    onCancel={handleCloseBankCardsModal}
                    getPlayerInfo={handleGetPlayerById}
                />
            )}
        </Col>
    );
};

export default BankCards;
