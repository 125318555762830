import React from 'react';

import { Modal, Row } from 'antd';

import './ConfirmModal.less';
import { ButtonGray, ButtonGreen } from '../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../common/helpers/locale';

const ConfirmModal = ({ onCancel, onConfirm, visible, dialog, loading }) => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            centered
            closable={false}
            className="check-player-repeatable__confirm-modal"
            footer={
                <Row key="actions" justify="space-between" align="middle">
                    <ButtonGreen
                        label={getTranslation('Confirm')}
                        onClick={onConfirm}
                        loading={loading}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>
            }
            onCancel={onCancel}
        >
            <Row>{dialog}</Row>
        </Modal>
    );
};

export default ConfirmModal;
