import globalAnnouncementReducer from './global-announcement/globalAnnouncementReducer';
import personalMessageAnnouncementReducer from './personal-message/personalMessageReducer';

export default function announcementReducer(announcementVerification = {}, action) {
    const { globalAnnouncement, personalMessage } = announcementVerification;

    return {
        globalAnnouncement: globalAnnouncementReducer(globalAnnouncement, action),
        personalMessage: personalMessageAnnouncementReducer(personalMessage, action),
    };
}
