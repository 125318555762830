import { useCallback, useEffect, useState } from 'react';

import { API } from '../common/constants/api';

import { message } from 'antd';

import { HOT_EVENTS_TYPES, OPERATION_TIME_LIMIT } from '../common/constants/misc';
import { clearError } from '../common/actions/errorActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import {
    hotEventsChatroomStatusUpdate,
    hotEventsChatroomStatusUpdateFailure,
    hotEventsChatroomStatusUpdateSuccess,
    hotEventsListGet,
    hotEventsListGetFailure,
    hotEventsListGetSuccess,
    hotEventsOperationFailure,
    hotEventsOperationSuccess,
    performHotEventsOperation,
} from '../components/hot-events/hotEventsAction';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, HOT_EVENTS } = API;

export function useGetHotEventsList(isOnline) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { pageNo, pageSize } = getStateProp(state, 'hotEvents.paging');
    const filters = getStateProp(state, 'hotEvents.filters', {});
    const eventIds = filters.eventIds ? filters.eventIds.split(',') : null;
    const refreshTime = getStateProp(state, 'hotEvents.operation.refreshTime');
    const refreshTimePause = getStateProp(state, 'hotEvents.operation.refreshTimePause');

    // ============================================
    // METHODS
    // ============================================

    const handleGetHotEvents = useCallback(() => {
        hotEventsListGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'POST',
        options: {
            data: {
                ...filterTrim(filters),
                eventIds,
                pageNo,
                pageSize,
                sourceType: 'OWS',
            },
        },
        trigger,
        url: `${VERSION.V1}${HOT_EVENTS.HOT_EVENTS_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);

            hotEventsListGetSuccess(dispatch, {
                fetchData: handleGetHotEvents,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [handleGetHotEvents, dispatch, response]);

    useEffect(() => {
        let timeInterval;
        if (response || isOnline)
            timeInterval = setTimeout(() => handleGetHotEvents(), refreshTime * 1000);
        if (refreshTimePause) clearTimeout(timeInterval);
        return () => clearTimeout(timeInterval);
    }, [handleGetHotEvents, isOnline, refreshTime, refreshTimePause, response]);

    const errorMsg = useError(error, hotEventsListGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetHotEvents];
}

export function useCreateRoomAsyncEndpoint() {
    const [state, dispatch] = useStore();

    const hotEvents = getStateProp(state, 'hotEvents');

    const refreshTable = getStateProp(hotEvents, 'fetchData', () => {});
    const operation = getStateProp(hotEvents, 'operation');
    const selectedHotEvents = getStateProp(hotEvents, 'selectedHotEvents');
    const action = getStateProp(operation, 'action');
    const [timeOutId, setTimeOutId] = useState(null);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!action,
        method: 'POST',
        options: {
            data: { data: selectedHotEvents },
        },
        trigger: action,
        url: action === HOT_EVENTS_TYPES.CREATE ? `${VERSION.V1}${HOT_EVENTS.CREATE}` : null,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            hotEventsOperationSuccess(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    useEffect(() => {
        if (response) {
            if (response.data) {
                message.success(response.data.message);
            }
        }
    }, [response, action]);

    useEffect(() => {
        if (response && action) {
            if (action === HOT_EVENTS_TYPES.CREATE) {
                setTimeOutId(
                    setTimeout(() => {
                        performHotEventsOperation(dispatch, {
                            action: HOT_EVENTS_TYPES.CREATE,
                        });
                    }, OPERATION_TIME_LIMIT),
                );
            }
        }
    }, [response, action, dispatch, timeOutId]);

    const errorMsg = useError(error, hotEventsOperationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useUpdateChatroomStatusAsync(id, status) {
    const [state, dispatch] = useStore();

    const hotEvents = getStateProp(state, 'hotEvents');
    const [trigger, setTrigger] = useState(null);
    const refreshTable = getStateProp(hotEvents, 'fetchData', () => {});

    // ============================================
    // HOOKS
    // ============================================

    const handleUpdateChatroomStatus = () => {
        setTrigger(+new Date());
        hotEventsChatroomStatusUpdate(dispatch);
    };

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'POST',
        options: {
            data: {
                action: status,
                referenceIds: [id.toString()],
            },
        },
        trigger,
        url: `${VERSION.V1}${HOT_EVENTS.UPDATE_STATUS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            hotEventsChatroomStatusUpdateSuccess(dispatch);
            refreshTable();
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, hotEventsChatroomStatusUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateChatroomStatus, response];
}
