import React from 'react';

import { Table } from 'antd';
import _ from 'lodash';

import './CurrencySettingResultTable.less';
import { currencySettingInfoSet } from '../currencySettingActions';
import { getColumns } from './CurrencySettingTableMeta';
import { useDispatch } from '../../../../store/StateProvider';

const CurrenySettingResultTable = ({ loading, isEditMode, tableData, isAddNewRate }) => {
    const dispatch = useDispatch();

    const onRateChange = (value, currency, baseCurrency) => {
        _.map(tableData, (data) => {
            if (data.currency === currency && data.baseCurrency === baseCurrency) {
                data.rates = value;
            }
            return data;
        });

        currencySettingInfoSet(dispatch);
    };

    return (
        <Table
            loading={loading}
            size="small"
            align="center"
            rowKey={(record, index) => record.currency}
            dataSource={tableData}
            pagination={false}
            bordered
            columns={getColumns(isEditMode, onRateChange, isAddNewRate)}
        />
    );
};

export default CurrenySettingResultTable;
