import createReferralReducer from './create-referral/createReferralReducer';
import referralManagementReducer from './referral-management/referralManagementReducer';
import referralReportReducer from './referral-report/referralReportReducer';
import referrerInquiryReducer from './inquiry/referralInquiryReducer';
import referrerReportReducer from './referrer-report/referrerReportReducer';

export default function referralReducer(referral = {}, action) {
    const {
        referralManagement,
        createReferral,
        referralReport,
        referrerReport,
        referralInquiry,
    } = referral;

    return {
        createReferral: createReferralReducer(createReferral, action),
        referralInquiry: referrerInquiryReducer(referralInquiry, action),
        referralManagement: referralManagementReducer(referralManagement, action),
        referralReport: referralReportReducer(referralReport, action),
        referrerReport: referrerReportReducer(referrerReport, action),
    };
}
