import React from 'react';

import { Row } from 'antd';

import {
    EwalletAccountNameFilter,
    EwalletAccountNumberFilter,
    EwalletNameFilter,
} from '../filters';
import {
    CurrencyFilter,
    MemberLevelFilter,
    OrderStatusFilter,
    PeriodFilter,
    TransactionMethodFilter,
    TransactionNumberFilter,
    TransactionStatusFilter,
    UsernameFilter,
} from '../../../common/components/filters';
import { ewalletTransfersFilterSet } from './EwalletTransfersActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';

const EwalletTransfersFilters = () => {
    const [state, dispatch] = useStore();
    const ewalletTransfers = getStateProp(state, 'payments.ewalletTransfers');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => ewalletTransfersFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <OrderStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(ewalletTransfers, 'filters.orderStatuses')}
                name="orderStatuses"
            />
            <TransactionStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(ewalletTransfers, 'filters.transactionStatuses')}
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(ewalletTransfers, 'filters.dateFrom')}
                dateTo={getStateProp(ewalletTransfers, 'filters.dateTo')}
                dateFromName="dateFrom"
                dateToName="dateTo"
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(ewalletTransfers, 'filters.currencyCode')}
                name="currencyCode"
            />
            <MemberLevelFilter
                onFilterChange={setFilter}
                value={getStateProp(ewalletTransfers, 'filters.memberLevel')}
            />
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(ewalletTransfers, 'filters.playerUserName')}
                name="playerUserName"
            />
            <EwalletNameFilter
                onFilterChange={setFilter}
                value={getStateProp(ewalletTransfers, 'filters.eWalletName')}
                placeholder={getTranslation('E-Wallet Name')}
            />
            <TransactionMethodFilter
                onFilterChange={setFilter}
                value={getStateProp(ewalletTransfers, 'filters.transactionMethods')}
            />
            <EwalletAccountNameFilter
                onFilterChange={setFilter}
                value={getStateProp(ewalletTransfers, 'filters.accountHolderName')}
            />
            <EwalletAccountNumberFilter
                onFilterChange={setFilter}
                value={getStateProp(ewalletTransfers, 'filters.eWalletAccountNo')}
                name="eWalletAccountNo"
            />
            <TransactionNumberFilter
                onFilterChange={setFilter}
                value={getStateProp(ewalletTransfers, 'filters.transactionNo')}
                name="transactionNo"
            />
        </Row>
    );
};

export default EwalletTransfersFilters;
