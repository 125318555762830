export const ACTIONS = {
    CURRENCY_SETTING_FILTERS_GET: 'CURRENCY_SETTING_FILTERS_GET',
    CURRENCY_SETTING_FILTERS_GET_FAILURE: 'CURRENCY_SETTING_FILTERS_GET_FAILURE',
    CURRENCY_SETTING_FILTERS_GET_SUCCESS: 'CURRENCY_SETTING_FILTERS_GET_SUCCESS',

    CURRENCY_SETTING_FILTER_SET: 'CURRENCY_SETTING_FILTER_SET',

    CURRENCY_SETTING_GET: 'CURRENCY_SETTING_GET',
    CURRENCY_SETTING_GET_FAILURE: 'CURRENCY_SETTING_GET_FAILURE',
    CURRENCY_SETTING_GET_SUCCESS: 'CURRENCY_SETTING_GET_SUCCESS',

    CURRENCY_SETTING_SAVE: 'CURRENCY_SETTING_SAVE',
    CURRENCY_SETTING_SAVE_FAILURE: 'CURRENCY_SETTING_SAVE_FAILURE',
    CURRENCY_SETTING_SAVE_SUCCESS: 'CURRENCY_SETTING_SAVE_SUCCESS',

    CURRENCY_SETTINIG_INFO_SET: 'CURRENCY_SETTINIG_INFO_SET',

    PAGINATION: {
        TABLE_PAGE_CHANGE: 'CURRENCY_RECORDS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'CURRENCY_RECORDS_TABLE_PAGE_SIZE_CHANGE',
    },
};

//# region get Currency Setting filter list
export const currencySettingFiltersGet = (dispatch) =>
    dispatch({
        type: ACTIONS.CURRENCY_SETTING_FILTERS_GET,
    });

export const currencySettingFiltersGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CURRENCY_SETTING_FILTERS_GET_SUCCESS,
    });

export const currencySettingFiltersGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CURRENCY_SETTING_FILTERS_GET_FAILURE,
    });
//#endregion

//# region get Currency Setting filter list
export const currencySettingFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CURRENCY_SETTING_FILTER_SET,
    });
//#endregion

//#region Get Currency Setting List
export const currencySettingGet = (dispatch) =>
    dispatch({
        type: ACTIONS.CURRENCY_SETTING_GET,
    });

export const currencySettingGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CURRENCY_SETTING_GET_SUCCESS,
    });

export const currencySettingGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CURRENCY_SETTING_GET_FAILURE,
    });
//#endregion

//#region Get Currency Setting Info Set
export const currencySettingInfoSet = (dispatch) =>
    dispatch({
        type: ACTIONS.CURRENCY_SETTINIG_INFO_SET,
    });
//#endregion

//#region Currency Setting Save
export const currencySettingSave = (dispatch) =>
    dispatch({
        type: ACTIONS.CURRENCY_SETTING_SAVE,
    });

export const currencySettingSaveSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CURRENCY_SETTING_SAVE_SUCCESS,
    });

export const currencySettingSaveFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CURRENCY_SETTING_SAVE_FAILURE,
    });
//#endregion
