import React from 'react';

import { Checkbox, Row } from 'antd';
import _ from 'lodash';

import './MenusSelection.less';
import { getTranslation } from '../../../../../common/helpers/locale';

const MenusSelection = ({ subMenus, title, selectedGroupAccess, selectAction, selectedValue }) => {
    // ============================================
    // METHODS
    // ============================================

    const onChange = (selectedColumnsList) => {
        selectAction(title, _.difference(selectedColumnsList, selectedGroupAccess[title]));
    };

    // ============================================
    // RENDERS
    // ============================================

    return (
        <Row className="individual__menus-selection" justify="space-between">
            <Checkbox.Group
                className="individual__menus-selection-checkbox-group"
                onChange={onChange}
                value={selectedValue[title]}
            >
                {subMenus.map((column) => {
                    return (
                        <Row key={column.menuId} className="individual__menus-selection-checkbox">
                            <Checkbox
                                value={column.menuId}
                                disabled={_.includes(selectedGroupAccess[title], column.menuId)}
                            >
                                <span className="individual__menus-selection__submenu-tile">
                                    {getTranslation(column.menuName)}
                                </span>
                            </Checkbox>
                        </Row>
                    );
                })}
            </Checkbox.Group>
        </Row>
    );
};

export default MenusSelection;
