import { ACTIONS } from './manualManagementActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS_2000,
} from '../../../common/constants/api';

export default function manualManagementReducer(
    manualManagement = {
        batchActionModalVisible: false,
        operation: {
            refreshTime: 15,
            refreshTimePause: false,
        },
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        remarkModalVisible: false,
        resetPageNo: false,
        selectedBonusIds: [],
        verifyModalVisible: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.VERIFY_MODAL_OPEN:
            return {
                ...manualManagement,
                bonusSelected: payload,
                verifyModalVisible: true,
            };
        case ACTIONS.VERIFY_MODAL_CLOSE:
            return {
                ...manualManagement,
                bonusSelected: {},
                verifyAction: '',
                verifyModalVisible: false,
            };
        case ACTIONS.REMARK_MODAL_OPEN:
            return {
                ...manualManagement,
                remarkModalVisible: true,
                remarks: payload,
            };
        case ACTIONS.REMARK_MODAL_CLOSE:
            return { ...manualManagement, remark: null, remarkModalVisible: false };

        case ACTIONS.BONUS_MANUAL_MANAGEMENT_RECORDS_GET_SUCCESS:
            return {
                ...manualManagement,
                ...payload,
            };

        case ACTIONS.PAGINATION.BONUS_MANUAL_MANAGEMENT_TABLE_PAGE_CHANGE:
            return {
                ...manualManagement,
                paging: {
                    ...manualManagement.paging,
                    pageNo:
                        payload > manualManagement.paging.pageCount
                            ? manualManagement.paging.pageCount - 1
                            : payload,
                },
                selectedBonusIds: [],
            };
        case ACTIONS.PAGINATION.BONUS_MANUAL_MANAGEMENT_TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS_2000[TABLE_PAGE_SIZE_OPTIONS_2000.length - 1],
            );
            return {
                ...manualManagement,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
                selectedBonusIds: [],
            };

        case ACTIONS.BONUS_MANUAL_MANAGEMENT_VERIFY_ACTION_SET:
            return {
                ...manualManagement,
                verifyAction: payload,
            };

        case ACTIONS.UPDATE_SELECTED_VERIFY_BONUS_INFO:
        case ACTIONS.TURNOVER_AMOUNT_CALCULATE_SUCCESS:
            return {
                ...manualManagement,
                bonusSelected: {
                    ...manualManagement.bonusSelected,
                    [payload.inputName]: payload.value,
                },
            };

        case ACTIONS.DEPOSIT_AMOUNT_GET_SUCCESS:
            return {
                ...manualManagement,
                bonusSelected: {
                    ...manualManagement.bonusSelected,
                    ...payload,
                },
            };

        case ACTIONS.BONUS_MANUAL_MANAGEMENT_UPDATE_INFO_SUCCESS:
            return {
                ...manualManagement,
                bonusSelected: {
                    ...manualManagement.bonusSelected,
                    action: null,
                },
            };

        case ACTIONS.BONUS_MANUAL_MANAGEMENT_FILTER_SET:
            return {
                ...manualManagement,
                filters: {
                    ...manualManagement.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };
        case ACTIONS.BONUS_MANUAL_MANAGEMENT_FILTERS_DROP_ALL:
            return {
                ...manualManagement,
                bonusCodeList: [],
                filters: {},
                resetPageNo: true,
                selectedBonusIds: [],
            };

        case ACTIONS.BONUS_MANUAL_MANAGEMENT_RECORDS_GET:
            const pageNo = manualManagement.resetPageNo
                ? TABLE_PAGE_NO_DEFAULT
                : manualManagement.paging.pageNo;
            return {
                ...manualManagement,
                paging: {
                    ...manualManagement.paging,
                    pageNo: pageNo,
                },
                resetPageNo: false,
            };

        case ACTIONS.REFRESH_TIME_SET:
            return {
                ...manualManagement,
                operation: {
                    refreshTime: payload.timeOut,
                    refreshTimePause: false,
                },
            };
        case ACTIONS.REFRESH_TIME_PAUSE:
            return {
                ...manualManagement,
                operation: {
                    refreshTimePause: true,
                },
            };
        case ACTIONS.BONUS_MANUAL_MANAGEMENT_GET_BONUS_CODE_SUCCESS:
            return {
                ...manualManagement,
                ...payload,
            };

        case ACTIONS.SET_SELECTED_BONUS_IDS:
            return {
                ...manualManagement,
                selectedBonusIds: payload,
            };

        case ACTIONS.SET_BATCH_ACTION_MODAL:
            return {
                ...manualManagement,
                ...payload,
            };
        case ACTIONS.BONUS_MANUAL_MANAGEMENT_BATCH_UPDATE_SUCCESS:
        case ACTIONS.BONUS_MANUAL_MANAGEMENT_BATCH_SUBMIT_SUCCESS:
        case ACTIONS.BONUS_MANUAL_MANAGEMENT_BATCH_REJECT_SUCCESS:
            return {
                ...manualManagement,
                batchAction: '',
                batchActionModalVisible: false,
                operation: {
                    ...manualManagement.operation,
                    refreshTimePause: false,
                },
                selectedBonusIds: [],
            };
        case ACTIONS.BONUS_MANUAL_MANAGEMENT_RESET:
            return {
                ...manualManagement,
                operation: {
                    refreshTime: 15,
                    refreshTimePause: false,
                },
                paging: {
                    pageNo: TABLE_PAGE_NO_DEFAULT,
                    pageSize: TABLE_PAGE_SIZE_DEFAULT,
                    totalRecordCount: 0,
                },
                selectedBonusIds: [],
            };
        case ACTIONS.BONUS_MANUAL_MANAGEMENT_BATCH_UPDATE:
        case ACTIONS.BONUS_MANUAL_MANAGEMENT_BATCH_SUBMIT:
        case ACTIONS.BONUS_MANUAL_MANAGEMENT_BATCH_REJECT:
        case ACTIONS.BONUS_MANUAL_MANAGEMENT_GET_BONUS_CODE:
        default:
            return manualManagement;
    }
}
