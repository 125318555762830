import React from 'react';

import { Card, Form } from 'antd';

import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';

import { API } from '../../../common/constants/api';
import { getColumns } from './table/SessionsTableMeta';
import { getStateProp } from '../../../common/helpers/misc';
import { useExportAsyncEndpoint } from '../../../services/hooks/useExport';
import { useGetUserSessionsAsyncEndpoint } from '../../../services/playerService';
import { userSessionsDropFilters } from './userSessionsActions';
import ActionButtons from './ActionButtons';
import SessionsFilters from './SessionsFilters';
import SessionsResultsTable from './table/SessionsResultsTable';

const { PLAYERS } = API;

const UserSessions = () => {
    const [state, dispatch] = useStore();
    const filters = getStateProp(state, 'players.userSessions.filters');
    const { totalRecordCount } = getStateProp(state, 'players.userSessions.paging');

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        userSessionsDropFilters(dispatch);
        handleGetSessions();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetSessions] = useGetUserSessionsAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        dataMeta: getColumns(),
        filters,
        method: 'POST',
        subject: 'User Sessions List',
        totalRecordCount,
        url: `${PLAYERS.SESSIONS.ALL}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('User Sessions')}>
            <Form onFinish={handleGetSessions}>
                <SessionsFilters />
                <ActionButtons onDropFilters={fetchAll} onExport={handleExportAction} />
            </Form>

            <SessionsResultsTable loading={loading} />
        </Card>
    );
};

export default UserSessions;
