import React from 'react';

import { Modal } from 'antd';

import './TotalRefereeModal.less';
import { getTranslation } from '../../../../common/helpers/locale';
import { useGetRefereeReportAsyncEndpoint } from '../../../../services/referralService';
import TotalRefereeResultsTable from '../table/TotalRefereeResultsTable';

const TotalRefereeModal = ({ visible, onCancel, paramId, userName, type }) => {
    // ============================================
    // HOOKS
    // ============================================

    const [loading] = useGetRefereeReportAsyncEndpoint(paramId, type);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            className="total-referee__modal"
            onCancel={onCancel}
            footer={null}
            centered={true}
            width="1080px"
            title={`${userName} - ${
                type === 'totalDepositedReferee'
                    ? getTranslation('Qualified Referee')
                    : getTranslation('Total Referee')
            }`}
        >
            <TotalRefereeResultsTable loading={loading} type={type} />
        </Modal>
    );
};

export default TotalRefereeModal;
