import React from 'react';

import { RenderDateFormat } from '../../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS_2000 } from '../../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../../manualManagementActions';
import { getStatusColor, sortTableByDataType } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import ManualManagementSummary from '../../table/ManualManagementSummary';
import OperationButton from '../../verification/OperationButton';
import ViewRemarkButton from '../../table/ViewRemarkButton';

export const getColumns = (dispatch) => {
    const columns = [
        {
            dataIndex: 'createdAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Request Time'),
        },
        {
            dataIndex: 'transNo',
            title: getTranslation('Task Transaction Number'),
        },
        {
            dataIndex: 'userName',
            title: getTranslation('Username'),
        },
        {
            dataIndex: 'depositTransactionNo',
            title: getTranslation('Deposit Trans No.'),
        },
        {
            dataIndex: 'depositAmount',
            title: getTranslation('Deposit Amount'),
        },
        {
            dataIndex: 'currencyCode',
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'bonusCode',
            title: getTranslation('Bonus Code'),
        },
        {
            dataIndex: 'bonusName',
            title: getTranslation('Bonus Name'),
        },
        {
            dataIndex: 'bonusAmount',
            title: getTranslation('Bonus Amount'),
        },
        {
            dataIndex: 'turnoverCondition',
            title: getTranslation('Turnover Requirement'),
        },
        {
            dataIndex: 'turnoverAmount',
            title: getTranslation('Turnover Amount'),
        },
        {
            dataIndex: 'duration',
            title: getTranslation('Duration'),
        },
        {
            dataIndex: 'expiredDate',
            dataType: 'date',
            render: (date) => RenderDateFormat(date, true), // true = hide time.
            title: getTranslation('Expired Date'),
        },
        {
            dataIndex: 'promoCode',
            title: getTranslation('Promo Code'),
        },
        {
            dataIndex: 'isAutoApprove',
            render: (value) =>
                value ? (
                    <span style={{ color: '#2de09f' }}>{getTranslation('Yes')}</span>
                ) : (
                    <span style={{ color: '#c12137' }}>{getTranslation('No')}</span>
                ),
            title: getTranslation('Auto Approve Bonus'),
        },
        {
            dataIndex: 'remarks',
            dataType: 'remarks',
            render: (text, record) =>
                record.applicantRemarks && record.operatorRemarks ? (
                    <ViewRemarkButton
                        applicantRemark={record.applicantRemarks}
                        operatorRemark={record.operatorRemarks}
                    />
                ) : (
                    <span>{record.applicantRemarks || record.operatorRemarks}</span>
                ),
            title: getTranslation('Remark'),
        },
        {
            dataIndex: 'internalRemark',
            title: getTranslation('Internal Remark'),
        },
        {
            dataIndex: 'approvalStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>{value.toLocaleUpperCase()}</span>
            ),
            title: getTranslation('Approval Status'),
        },
        {
            dataIndex: 'progressStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>{value.toLocaleUpperCase()}</span>
            ),
            title: getTranslation('Progress Status'),
        },
        {
            dataIndex: 'processTime',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Processed Time'),
        },
        {
            dataIndex: 'creatorUserName',
            title: getTranslation('Applicant'),
        },
        {
            dataIndex: 'applicantName',
            render: (text, record) => (
                <span>{record.operatorName ? record.operatorName : record.applicantName}</span>
            ),
            title: getTranslation('Operator'),
        },
        {
            dataIndex: 'operation',
            dataType: 'operation',
            render: (text, record) => (
                <OperationButton bonus={record} dispatch={dispatch} isBatchUpload={false} />
            ),
            title: getTranslation('Operation'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS_2000,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);

export const tableBodyWrapper = (props, totalBonusAmount, totalDepositAmount, currency) => {
    return (
        <tbody {...props}>
            {props.children}
            {currency ? (
                <ManualManagementSummary
                    totalDepositAmount={totalDepositAmount}
                    totalBonusAmount={totalBonusAmount}
                />
            ) : null}
        </tbody>
    );
};

export const manualManagementRecordsExport = ({ onlyMeta }) => {
    if (onlyMeta) {
        return {
            operation: [
                {
                    dataIndex: 'operatorName',
                    title: getTranslation('Operation'),
                },
            ],
            remarks: [
                {
                    dataIndex: 'applicantRemarks',
                    title: getTranslation('Applicant Remark'),
                },
                {
                    dataIndex: 'operatorRemarks',
                    title: getTranslation('Operator Remark'),
                },
            ],
        };
    }

    let messageArray = [];
    return messageArray;
};
