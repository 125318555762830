import { ACTIONS } from './dangerLevelActions';
import _ from 'lodash';

export default function dangerLevelReducer(dangerLevel = {}, { type, payload = null }) {
    switch (type) {
        case ACTIONS.FILTERS_DROP_ALL:
            return {
                ...dangerLevel,
                filters: null,
            };
        case ACTIONS.FILTER_SET:
            return {
                ...dangerLevel,
                filters: {
                    ...dangerLevel.filters,
                    [payload.filter]: payload.value,
                },
            };
        case ACTIONS.DANGER_LEVEL_TAG_ADD:
            return {
                ...dangerLevel,
                dangerLevelTagList: [
                    ...dangerLevel.dangerLevelTagList,
                    {
                        description: '',
                        editable: true,
                        lastUpdatedBy: '',
                        level: _.isEmpty(dangerLevel.dangerLevelTagList)
                            ? 0
                            : dangerLevel.dangerLevelTagList.length,
                        showDeleteBtn: true,
                        tagName: '',
                    },
                ],
            };
        case ACTIONS.DANGER_LEVEL_TAG_MODAL_SET:
            return {
                ...dangerLevel,
                dangerLevelTagList: payload ? dangerLevel.dangerLevelTagList : [],
                dangerLevelTagModal: payload,
                isDeleted: payload ? false : dangerLevel.isDeleted,
            };
        case ACTIONS.DANGER_LEVEL_TAG_UPDATE_SUCCESS:
            return {
                ...dangerLevel,
                dangerLevelTagList: [],
                dangerLevelTagModal: false,
            };
        case ACTIONS.DANGER_LEVEL_TAG_GET_SUCCESS:
            let newArray = payload;
            if (!_.isEmpty(newArray)) newArray[newArray.length - 1]['showDeleteBtn'] = true;
            return {
                ...dangerLevel,
                dangerLevelTagList: newArray,
            };
        case ACTIONS.DANGER_LEVEL_TAG_DELETE:
            return {
                ...dangerLevel,
                newAddedTag: _.filter(dangerLevel.dangerLevelTagList, (e) =>
                    _.isEmpty(e.lastUpdatedBy),
                ),
            };
        case ACTIONS.DANGER_LEVEL_TAG_DELETE_SUCCESS:
            if (!_.isEmpty(payload)) payload[payload.length - 1]['showDeleteBtn'] = true;
            _.forEach(dangerLevel.newAddedTag, (e, idx) => {
                e.level = payload.length + idx;
            });
            return {
                ...dangerLevel,
                dangerLevelTagList: [...payload, ...dangerLevel.newAddedTag],
                isDeleted: true,
            };
        case ACTIONS.DANGER_LEVEL_TAG_EDIT:
        case ACTIONS.DANGER_LEVEL_GET_SUCCESS:
        case ACTIONS.DANGER_LEVEL_FILTER_LIST_GET_SUCCESS:
        case ACTIONS.DANGER_LEVEL_SETTING_MODAL_SET:
            return {
                ...dangerLevel,
                ...payload,
            };
        case ACTIONS.UPDATE_SELECTED_DANGER_LEVEL_SETTING:
            return {
                ...dangerLevel,
                selectedDangerLevelSetting: {
                    ...dangerLevel.selectedDangerLevelSetting,
                    ...payload,
                },
            };
        case ACTIONS.DANGER_LEVEL_SETTING_UPDATE_SUCCESS:
            return {
                ...dangerLevel,
                dangerLevelSettingModal: false,
                selectedDangerLevelSetting: {},
            };
        case ACTIONS.DANGER_LEVEL_SETTING_UPDATE:
        case ACTIONS.DANGER_LEVEL_TAG_UPDATE:
        case ACTIONS.DANGER_LEVEL_GET:
        case ACTIONS.DANGER_LEVEL_FILTER_LIST_GET:
        case ACTIONS.DANGER_LEVEL_TAG_GET:
        default:
            return dangerLevel;
    }
}
