import React from 'react';

import { Form } from 'antd';

import {
    bankCardDropFilters,
    bankCardFilterSet,
    deactivatedBankCardSetupModalClose,
} from './deactivatedBankAccountActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { useGetDeactivatedBankCardsAsyncEndpoint } from '../../../../services/bankAccountService';
import { useStore } from '../../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import BankAccountSetupFilters from '../BankAccountSetupFilters';
import DeactivatedBankAccountResultsTable from './table/DeactivatedBankAccountResultsTable';
import DeactivatedBankCardSetupModal from './DeactivatedBankCardSetupModal';

const DeactivatedBankAccount = () => {
    const [state, dispatch] = useStore();

    const modalData = getStateProp(state, 'payments.deactivatedBankAccount.modal');
    const deactivatedBankAccount = getStateProp(state, 'payments.deactivatedBankAccount');

    // ============================================
    // METHODS
    // ============================================

    const onCloseModal = () => deactivatedBankCardSetupModalClose(dispatch);

    const setFilter = (filter, value) => bankCardFilterSet(dispatch, { filter, value });

    const refreshTable = getStateProp(state, 'payments.deactivatedBankAccount.fetchData', () => {});

    const fetchAll = () => {
        bankCardDropFilters(dispatch);
        handleGetDeactivatedBankCards();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetDeactivatedBankCards] = useGetDeactivatedBankCardsAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <div>
            <Form onFinish={handleGetDeactivatedBankCards}>
                <BankAccountSetupFilters
                    setFilter={setFilter}
                    bankAccount={deactivatedBankAccount}
                    refreshTable={refreshTable}
                />
                <ActionButtons onDropFilters={fetchAll} />
            </Form>
            <DeactivatedBankAccountResultsTable loading={loading} />
            {modalData.visible && (
                <DeactivatedBankCardSetupModal modalData={modalData} onCancel={onCloseModal} />
            )}
        </div>
    );
};

export default DeactivatedBankAccount;
