import React, { useState } from 'react';

import { Card, Divider, Form } from 'antd';

import { API } from '../../../common/constants/api';
import { EWALLET_WITHDRAWAL_TABLE_COLUMNS } from './constants';
import { PAYMENT_ORDER_OPERATIONS } from '../../../common/constants/misc';
import {
    closeRejectModal,
    closeVerifyModal,
    dropFilters,
    pauseRefreshTableTime,
    performEwalletWithdrawalsOperation,
    selectColumns,
    setRefreshTableTime,
} from './ewalletWithdrawalsActions';
import { getColumns, withdrawalRecordsExport } from './table/EwalletWithdrawalTableMeta';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    useEwalletWithdrawalOperationAsyncEndpoint,
    useGetEwalletCardsAsyncEndpoint,
    useGetEwalletWithdrawalsAsyncEndpoint,
} from '../../../services/ewalletWithdrawalService';
import { useExportAsyncEndpoint } from '../../../services/hooks/useExport';
// import { useGetDangerLevelFilterListAsyncEndpoint } from '../../../services/playerService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import ColumnSelectionModal from '../../../common/components/column-selection/ColumnSelectionModal';
import RejectModal from './verification/RejectModal';
import TableRefreshSelector from '../../../common/components/TableRefreshSelector';
import TotalBalance from '../../../common/components/TotalBalance';
import VerifyModal from './verification/VerifyModal';
import WithdrawalFilters from './EwalletWithdrawalFilters';
import WithdrawalResultsTable from './table/EwalletWithdrawalResultsTable';
import useInternetStatus from '../../../services/hooks/useInternetStatus';

const { PAYMENTS } = API;

const EwalletWithdrawals = () => {
    const [state, dispatch] = useStore();

    const withdrawals = getStateProp(state, 'payments.ewalletWithdrawals');
    const transactionAttending = getStateProp(withdrawals, 'operation.transaction', {});
    const verifyModalVisible = getStateProp(withdrawals, 'verifyModalVisible', {});
    const rejectModalVisible = getStateProp(withdrawals, 'rejectModalVisible', {});
    const filters = getStateProp(withdrawals, 'filters');
    const { totalRecordCount } = getStateProp(withdrawals, 'paging', {});
    const selectedColumns = getStateProp(
        withdrawals,
        'selectedColumns',
        EWALLET_WITHDRAWAL_TABLE_COLUMNS,
    );
    const pageBalance = getStateProp(withdrawals, 'pageDataTotal', []);
    const totalBalance = getStateProp(withdrawals, 'dataTotal', []);
    const grandTotal = getStateProp(withdrawals, 'grandTotal', 0);
    const pageTotal = getStateProp(withdrawals, 'pageTotal', 0);

    const [columnSelectorModalVisible, setColumnSelectorModalVisible] = useState(false);

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetEwalletWithdrawals();
    };

    const closeOperationModal = (operation) => {
        performEwalletWithdrawalsOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.RELEASE,
        });

        operation === PAYMENT_ORDER_OPERATIONS.COMPLETE
            ? closeVerifyModal(dispatch)
            : closeRejectModal(dispatch);
    };

    const setRefreshTime = (timeOut) => {
        setRefreshTableTime(dispatch, {
            timeOut,
        });
    };

    const onTimePause = () => {
        pauseRefreshTableTime(dispatch);
    };

    const onTimeStart = () => {
        setRefreshTableTime(dispatch, {
            timeOut: 15,
        });
    };

    const closeColumnSelectorModal = () => setColumnSelectorModalVisible(false);

    const openColumnSelectorModal = () => setColumnSelectorModalVisible(true);

    // ============================================
    // HOOKS
    // ============================================

    const [isOnline] = useInternetStatus();
    const [withdrawalLoading, handleGetEwalletWithdrawals] = useGetEwalletWithdrawalsAsyncEndpoint(
        isOnline,
    );
    const [operationPending] = useEwalletWithdrawalOperationAsyncEndpoint();
    useGetEwalletCardsAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        additionalCol: withdrawalRecordsExport,
        dataMeta: getColumns(dispatch, selectedColumns),
        filters,
        mandatoryFilter: {
            verified: true,
        },
        method: 'GET',
        subject: getTranslation('E-Wallet Withdrawal Verification'),
        totalRecordCount,
        url: `${PAYMENTS.WITHDRAWALS.ROOT}`,
    });
    // const [gettingDangerLevelFilterList] = useGetDangerLevelFilterListAsyncEndpoint('withdraw');

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card
            title={getTranslation('E-Wallet Withdrawal Verification')}
            extra={
                <TableRefreshSelector
                    onTimeChange={setRefreshTime}
                    onTimePause={onTimePause}
                    onTimeStart={onTimeStart}
                    timeOut={getStateProp(withdrawals, 'operation.refreshTime')}
                />
            }
            // loading={gettingDangerLevelFilterList}
        >
            <Form onFinish={handleGetEwalletWithdrawals}>
                <WithdrawalFilters />
                <ActionButtons
                    onDropFilters={fetchAll}
                    onExport={handleExportAction}
                    onColumnSelect={openColumnSelectorModal}
                />
            </Form>

            <WithdrawalResultsTable loading={withdrawalLoading} />
            <VerifyModal
                visible={verifyModalVisible}
                onCancel={() => closeOperationModal(PAYMENT_ORDER_OPERATIONS.COMPLETE)}
                operationPending={operationPending}
                transaction={transactionAttending}
            />
            <RejectModal
                visible={rejectModalVisible}
                onCancel={() => closeOperationModal(PAYMENT_ORDER_OPERATIONS.REJECT)}
                operationPending={operationPending}
                transaction={transactionAttending}
            />
            <ColumnSelectionModal
                visible={columnSelectorModalVisible}
                onCancel={closeColumnSelectorModal}
                tableColumnsList={EWALLET_WITHDRAWAL_TABLE_COLUMNS}
                selectAction={selectColumns}
                selectedColumnsState="payments.ewalletWithdrawals.selectedColumns"
            />
            <Divider />
            <TotalBalance
                pageBalance={pageBalance}
                totalBalance={totalBalance}
                grandTotal={grandTotal}
                pageTotal={pageTotal}
            />
        </Card>
    );
};

export default EwalletWithdrawals;
