import React from 'react';

import { Table } from 'antd';
import _ from 'lodash';

import { getColumns, getPaginationOptions } from './PlayerWinLossTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { openWinLossDetailModal } from '../playerWinLossActions';
import { useStore } from '../../../../store/StateProvider';

const PlayerWinLossTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const playerWinLoss = getStateProp(state, 'reports.playerWinLoss');
    const paging = getStateProp(playerWinLoss, 'paging', {});
    const tableData = getStateProp(playerWinLoss, 'list.details', []);
    const totalByCurrencies = getStateProp(playerWinLoss, 'list.totalByCurrencies', []);
    const filters = getStateProp(state, 'reports.playerWinLoss.filters', {});
    const { dateFrom, dateTo, platformCodes, productCodes } = filters;

    // ============================================
    // METHODS
    // ============================================

    const openModal = (userName) =>
        openWinLossDetailModal(dispatch, {
            dateFrom,
            dateTo,
            platformCodes,
            productCodes,
            userName,
        });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns(openModal)}
            pagination={getPaginationOptions(paging, dispatch)}
            dataSource={tableData}
            rowKey={(value, index) => index}
            loading={loading}
            size="small"
            align="left"
            bordered
            summary={() => {
                return _.map(totalByCurrencies, (total) => {
                    return (
                        <tr
                            key={total.currency}
                            style={{ background: '#fafafa', color: 'rgba(0, 0, 0, 0.85)' }}
                        >
                            <td colSpan={2}>{`Total (${total.currency})`}</td>
                            <td>{total.grandTotalRecords}</td>
                            <td>{total.grandTotalTurnover}</td>
                            <td>{total.grandTotalEffectiveTurnover}</td>
                            <td>{total.grandTotalWinLoss}</td>
                        </tr>
                    );
                });
            }}
        />
    );
};

export default PlayerWinLossTable;
