import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { NEWS_CATEGORIES } from '../constants';
import { getTranslation } from '../../../common/helpers/locale';

const CategorySelect = ({ value, onCategoryChange }) => {
    return (
        <Select
            labelInValue
            allowClear
            value={value}
            onChange={onCategoryChange}
            placeholder={getTranslation('Related Game').toLocaleUpperCase()}
            className="filter"
        >
            {_.map(NEWS_CATEGORIES, (category) => (
                <Select.Option key={category.key} value={category.label}>
                    {category.label.toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
};

export default CategorySelect;
