import React, { useEffect, useState } from 'react';

import { Checkbox, Col, Divider, Form, Row, Select } from 'antd';
import _ from 'lodash';

import { ButtonGreen, ButtonTransparent } from '../../../../../common/components/buttons/Buttons';
import { CURRENCIES } from '../../../../../common/constants/localization';
import { getStateProp, isOperatorHasFullAccess } from '../../../../../common/helpers/misc';
import { getSupportedCountries, getTranslation } from '../../../../../common/helpers/locale';
import { useChangeUserCurrencyOperationAsyncEndpoint } from '../../../../../services/adminService';
import { useStore } from '../../../../../store/StateProvider';

const ChangeCurrency = ({ onCancel }) => {
    const [state] = useStore();
    const [form] = Form.useForm();
    const { resetFields, setFieldsValue } = form;
    const [formValues, setFormValues] = useState({});
    const modalData = getStateProp(
        state,
        'administration.userAuthoritySetting.modalDetails.modalData',
        {},
    );
    const allowCountry = getStateProp(modalData, 'allowCountry', []);
    const allowCurrency = getStateProp(modalData, 'allowCurrency', []);
    const [allowAllCountries, setAllowAllCountries] = useState(_.isEmpty(allowCountry));

    // ============================================
    // METHODS
    // ============================================

    const onSave = (values) => {
        setFormValues(values);
        handleChangeUserCurrency();
    };

    const allowAllCountrySet = (e) => {
        if (e.target.checked) {
            setAllowAllCountries(true);
        } else {
            setAllowAllCountries(false);
        }

        setFieldsValue({
            allowCountry: [],
        });
    };

    // ============================================
    // HOOKS
    // ============================================

    const [
        updating,
        updated,
        handleChangeUserCurrency,
    ] = useChangeUserCurrencyOperationAsyncEndpoint(formValues);

    useEffect(() => {
        if (updated) {
            resetFields();
            onCancel();
        }
    }, [onCancel, resetFields, updated]);

    useEffect(() => {
        setFieldsValue({
            allowCountry: !_.isEmpty(allowCountry) ? allowCountry : [],
            allowCurrency,
            isAllowAllCountry: _.isEmpty(allowCountry),
        });
    }, [allowAllCountries, allowCountry, allowCurrency, setFieldsValue]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form form={form} name="edit-currency" className="edit-currency__form" onFinish={onSave}>
            <Row className="form__group">
                <Col span={4}>
                    <label>{getTranslation('Currency')}</label>
                </Col>
                <Col span={20}>
                    <Form.Item
                        name="allowCurrency"
                        rules={[
                            {
                                message: getTranslation('This field is required!'),
                                required: true,
                            },
                        ]}
                    >
                        <Checkbox.Group
                            style={{ display: 'inline-flex' }}
                            disabled={!isOperatorHasFullAccess()}
                        >
                            {_.map(CURRENCIES, (currency) => (
                                <div key={currency.currency}>
                                    <Checkbox value={currency.currency}>
                                        {currency.currency}
                                    </Checkbox>
                                </div>
                            ))}
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row className="form__group">
                <Col span={4}>
                    <label>{getTranslation('Country')}</label>
                </Col>
                <Col span={20}>
                    <Form.Item
                        name="allowCountry"
                        rules={[
                            {
                                message: getTranslation('This field is required!'),
                                required: !allowAllCountries,
                            },
                        ]}
                    >
                        <Select
                            placeholder={getTranslation('Country')}
                            mode="multiple"
                            allowClear={true}
                            showSearch
                            filterOption={(input, option) =>
                                option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={allowAllCountries}
                        >
                            {_.map(getSupportedCountries(), (country) => (
                                <Select.Option
                                    value={country.code}
                                    key={country.name}
                                    label={
                                        <Row
                                            align="middle"
                                            justify="space-between"
                                            style={{ height: '100%' }}
                                        >
                                            <span>{country.name}</span>
                                        </Row>
                                    }
                                >
                                    <span style={{ whiteSpace: 'normal' }}>{`${
                                        country.name
                                    } (${country.code.toLocaleUpperCase()})`}</span>
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row className="form__group">
                <Col span={4}></Col>
                <Col span={20}>
                    <Form.Item name="isAllowAllCountry" valuePropName="checked">
                        <Checkbox
                            onChange={allowAllCountrySet}
                            disabled={!isOperatorHasFullAccess()}
                        >
                            {getTranslation('Allow All Country')}
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>

            <Divider />

            <Row justify="end">
                <ButtonTransparent
                    label={getTranslation('Cancel')}
                    onClick={onCancel}
                    style={{ marginRight: '10px' }}
                />
                <ButtonGreen label={getTranslation('Save')} htmlType="submit" loading={updating} />
            </Row>
        </Form>
    );
};

export default ChangeCurrency;
