import React, { useState } from 'react';

import { Card, Form } from 'antd';

import { AFFILIATE_TABLE_COLUMNS } from './constants';
import { API } from '../../../common/constants/api';
import { affiliatePayoutsExport, getColumns } from './table/AffiliateVerificationMeta';
import {
    closeConfirmModal,
    closeConfirmRejectModal,
    closeVerifyModal,
    dropFilters,
    selectColumns,
} from './affiliateVerificationActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useExportAsyncEndpoint } from '../../../services/hooks/useExport';
import {
    useGetMyAffiliatePayoutsAsyncEndpoint,
    useMyAffiliateOperationAsyncEndpoint,
} from '../../../services/affiliateRecordService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import AffiliateVerificationFilters from './AffiliateVerificationFilters';
import AffiliateVerificationTable from './table/AffiliateVerificationTable';
import ColumnSelectionModal from '../../../common/components/column-selection/ColumnSelectionModal';
import ConfirmModal from './verification/ConfirmModal';
import ConfirmRejectModal from './verification/ConfirmRejectModal';
import VerifyModal from './verification/VerifyModal';

const { TRANSACTION_HISTORY } = API;

const AffiliateVerification = () => {
    const [state, dispatch] = useStore();

    const { verification = {} } = getStateProp(state, 'affiliate');
    const verifyModalVisible = getStateProp(verification, 'verifyModalVisible', {});
    const selectedTransaction = getStateProp(verification, 'operation.transaction', {});
    const selectedColumns = getStateProp(verification, 'selectedColumns', AFFILIATE_TABLE_COLUMNS);
    const confirmRejectModalVisible = getStateProp(
        verification,
        'confirmRejectModalVisible',
        false,
    );
    const confirmModalVisible = getStateProp(verification, 'confirmModalVisible', false);
    const filters = getStateProp(verification, 'filters', {});
    const { totalRecordCount } = getStateProp(verification, 'paging', {});

    const [columnSelectorModalVisible, setColumnSelectorModalVisible] = useState(false);

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetMyAffiliatePayouts();
    };

    const closeRejectModal = () => {
        closeConfirmRejectModal(dispatch);
    };

    const closeOperationModal = () => {
        closeVerifyModal(dispatch);
    };

    const closeColumnSelectorModal = () => setColumnSelectorModalVisible(false);

    const openColumnSelectorModal = () => setColumnSelectorModalVisible(true);

    const verifyTransaction = (e) => {
        handleMyAffiliateOperation(e);
    };

    const closeModal = () => closeConfirmModal(dispatch);

    // ============================================
    // HOOKS
    // ============================================
    const [loading, handleGetMyAffiliatePayouts] = useGetMyAffiliatePayoutsAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        additionalCol: affiliatePayoutsExport,
        dataMeta: getColumns(dispatch, selectedColumns),
        filters,
        method: 'GET',
        subject: getTranslation('Affiliate Payouts'),
        totalRecordCount,
        url: `${TRANSACTION_HISTORY.MYAFFILIATE_PAYOUTS}`,
    });

    const [operating, handleMyAffiliateOperation] = useMyAffiliateOperationAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Affiliate Verification')}>
            <Form onFinish={handleGetMyAffiliatePayouts}>
                <AffiliateVerificationFilters />
                <ActionButtons
                    onDropFilters={fetchAll}
                    onExport={handleExportAction}
                    onColumnSelect={openColumnSelectorModal}
                />
            </Form>

            <AffiliateVerificationTable loading={loading} />
            <VerifyModal
                visible={verifyModalVisible}
                transaction={selectedTransaction}
                onCancel={closeOperationModal}
                operationPending={false}
            />
            <ConfirmModal
                loading={operating}
                visible={confirmModalVisible}
                onCancel={closeModal}
                onConfirm={verifyTransaction}
            />
            <ConfirmRejectModal
                loading={operating}
                visible={confirmRejectModalVisible}
                onCancel={closeRejectModal}
                onReject={verifyTransaction}
            />
            <ColumnSelectionModal
                visible={columnSelectorModalVisible}
                onCancel={closeColumnSelectorModal}
                tableColumnsList={AFFILIATE_TABLE_COLUMNS}
                selectAction={selectColumns}
                selectedColumnsState="affiliate.verification.selectedColumns"
            />
        </Card>
    );
};

export default AffiliateVerification;
