export const ACTIONS = {
    CHECK_PLAYER_REQUESTABLE: 'CHECK_PLAYER_REQUESTABLE',
    CHECK_PLAYER_REQUESTABLE_FAILURE: 'CHECK_PLAYER_REQUESTABLE_FAILURE',
    CHECK_PLAYER_REQUESTABLE_SUCCESS: 'CHECK_PLAYER_REQUESTABLE_SUCCESS',
    CONFIRM_MODAL_CLOSE: 'REQUEST_BONUS_CONFIRM_MODAL_CLOSE',
    CONFIRM_MODAL_OPEN: 'REQUEST_BONUS_CONFIRM_MODAL_OPEN',
    GET_BONUS_CODES: 'GET_BONUS_CODES',
    GET_BONUS_CODES_FAILURE: 'GET_BONUS_CODES_FAILURE',
    GET_BONUS_CODES_SUCCESS: 'GET_BONUS_CODES_SUCCESS',
    REQUEST_BONUS: 'REQUEST_BONUS',
    REQUEST_BONUS_ADJUSTMENT_INFO_SET: 'REQUEST_BONUS_ADJUSTMENT_INFO_SET',
    REQUEST_BONUS_FAILURE: 'REQUEST_BONUS_FAILURE',
    REQUEST_BONUS_FORM_RESET: 'REQUEST_BONUS_FORM_RESET',
    REQUEST_BONUS_SEARCH_PLAYER_INFO: 'REQUEST_BONUS_SEARCH_PLAYER_INFO',
    REQUEST_BONUS_SEARCH_PLAYER_INFO_FAILURE: 'REQUEST_BONUS_SEARCH_PLAYER_INFO_FAILURE',
    REQUEST_BONUS_SEARCH_PLAYER_INFO_SUCCESS: 'REQUEST_BONUS_SEARCH_PLAYER_INFO_SUCCESS',
    REQUEST_BONUS_SUCCESS: 'REQUEST_BONUS_SUCCESS',
    REQUEST_BONUS_TURNOVER: 'REQUEST_BONUS_TURNOVER',
    REQUEST_BONUS_TURNOVER_FAILURE: 'REQUEST_BONUS_TURNOVER_FAILURE',
    REQUEST_BONUS_TURNOVER_SUCCESS: 'REQUEST_BONUS_TURNOVER_SUCCESS',
    UPDATE_BONUS_DETAILS: 'UPDATE_BONUS_DETAILS',
    UPLOAD_EXCEL: 'UPLOAD_EXCEL',
    UPLOAD_EXCEL_FAILURE: 'UPLOAD_EXCEL_FAILURE',
    UPLOAD_EXCEL_MODAL_SET: 'UPLOAD_EXCEL_MODAL_SET',
    UPLOAD_EXCEL_SUCCESS: 'UPLOAD_EXCEL_SUCCESS',
};

export const requestBonus = (dispatch) =>
    dispatch({
        type: ACTIONS.REQUEST_BONUS,
    });

export const requestBonusSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.REQUEST_BONUS_SUCCESS,
    });

export const requestBonusFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REQUEST_BONUS_FAILURE,
    });

export const requestBonusCodesGet = (dispatch) =>
    dispatch({
        type: ACTIONS.GET_BONUS_CODES,
    });

export const requestBonusCodesGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_BONUS_CODES_SUCCESS,
    });

export const requestBonusCodesGetFailure = (dispatch) =>
    dispatch({
        type: ACTIONS.GET_BONUS_CODES_FAILURE,
    });

export const requestBonusPlayerInfoGet = (dispatch) =>
    dispatch({
        type: ACTIONS.REQUEST_BONUS_SEARCH_PLAYER_INFO,
    });

export const requestBonusPlayerInfoGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REQUEST_BONUS_SEARCH_PLAYER_INFO_SUCCESS,
    });

export const requestBonusPlayerInfoGetFailure = (dispatch) =>
    dispatch({
        type: ACTIONS.REQUEST_BONUS_SEARCH_PLAYER_INFO_FAILURE,
    });

export const requestBonusAdjustmentInfoSet = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.REQUEST_BONUS_ADJUSTMENT_INFO_SET,
    });
};

export const requestBonusFormReset = (dispatch) => {
    dispatch({
        type: ACTIONS.REQUEST_BONUS_FORM_RESET,
    });
};

export const requestBonusTurnover = (dispatch) => {
    dispatch({
        type: ACTIONS.REQUEST_BONUS_TURNOVER,
    });
};

export const requestBonusTurnoverSuccess = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.REQUEST_BONUS_TURNOVER_SUCCESS,
    });
};

export const requestBonusTurnoverFailure = (dispatch) => {
    dispatch({
        type: ACTIONS.REQUEST_BONUS_TURNOVER_FAILURE,
    });
};

export const updateBonusDetails = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.UPDATE_BONUS_DETAILS,
    });

export const openConfirmModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CONFIRM_MODAL_OPEN,
    });

export const closeConfirmModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_MODAL_CLOSE,
    });

export const requestCheckPlayerRequestable = (dispatch) =>
    dispatch({
        type: ACTIONS.CHECK_PLAYER_REQUESTABLE,
    });

export const requestCheckPlayerRequestableFailure = (dispatch) =>
    dispatch({
        type: ACTIONS.CHECK_PLAYER_REQUESTABLE_FAILURE,
    });

export const requestCheckPlayerRequestableSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.CHECK_PLAYER_REQUESTABLE_SUCCESS,
    });

export const setUploadModalVisible = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.UPLOAD_EXCEL_MODAL_SET,
    });

export const uploadExcel = (dispatch) =>
    dispatch({
        type: ACTIONS.UPLOAD_EXCEL,
    });

export const uploadExcelSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.UPLOAD_EXCEL_SUCCESS,
    });

export const uploadExcelFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.UPLOAD_EXCEL_FAILURE,
    });
