import { useCallback, useEffect, useState } from 'react';

import { message } from 'antd';
import _ from 'lodash';

import { API } from '../common/constants/api';
import { clearError } from '../common/actions/errorActions';
import {
    currencySettingFiltersGet,
    currencySettingFiltersGetFailure,
    currencySettingFiltersGetSuccess,
    currencySettingGet,
    currencySettingGetFailure,
    currencySettingGetSuccess,
    currencySettingSave,
    currencySettingSaveFailure,
    currencySettingSaveSuccess,
} from '../components/admin/currency-setting/currencySettingActions';
import {
    getMenuOperationIds,
    getStateProp,
    getSubMenuByOperationIds,
    getSubMenuBySubMenuId,
    subMenuGrouping,
} from '../common/helpers/misc';

import {
    ACTIONS,
    userAuhtorityListGet,
    userAuhtorityListGetSuccess,
    userAuthorityDetailGet,
    userAuthorityDetailGetFailure,
    userAuthorityDetailGetSuccess,
    userAuthorityListGetFailure,
    userAuthoritySet,
    userAuthoritySetFailure,
    userAuthoritySetSuccess,
    userCreate,
    userCreateFailure,
    userCurrencyChange,
    userCurrencyChangeFailure,
    userCurrencyChangeSuccess,
    userGroupGet,
    userGroupGetFailure,
    userGroupGetSuccess,
    userIndividualAuthorityDetailGet,
    userIndividualAuthorityDetailGetFailure,
    userIndividualAuthorityDetailGetSuccess,
    userIndividualAuthoritySet,
    userIndividualAuthoritySetFailure,
    userIndividualAuthoritySetSuccess,
    userPasswordChange,
    userPasswordChangeFailure,
    userPasswordChangeSuccess,
    userStatusChange,
    userStatusChangeFailure,
    userStatusChangeSuccess,
} from '../components/admin/user-setting/userAuthoritySettingActions';
import { USER_AUTHORITY_SETTING_MODAL_TYPES } from '../components/admin/user-setting/constants';
import { createOperatorOperation } from '../common/actions/operationsActions';
import { getOperationId } from '../common/helpers/api';
import { getTranslation } from '../common/helpers/locale';
import { useDispatch, useStore } from '../store/StateProvider';
import { useOperationAsyncEndpoint } from './operationsService';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, ADMIN, IDENTITY } = API;

export function useGetUserAuthorityListAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { userAuthoritySetting } = getStateProp(state, 'administration');
    const { pageNo, pageSize } = getStateProp(userAuthoritySetting, 'paging');

    // ============================================
    // METHODS
    // ============================================

    const handleUserAuthorityList = useCallback(() => {
        userAuhtorityListGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${ADMIN.USER_AUTHORITY.AUTHORITY_LIST}`,
    });

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (response) {
            clearError(dispatch);

            userAuhtorityListGetSuccess(dispatch, {
                fetchData: handleUserAuthorityList,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleUserAuthorityList, response]);

    useEffect(() => {
        handleUserAuthorityList();
    }, [handleUserAuthorityList, pageNo, pageSize]);

    const errorMsg = useError(error, userAuthorityListGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useCreateUserOperationAsyncEndpoint(info) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);
    const [requestProcessing, setRequestProcessing] = useState(false);
    const { allowCountry, ...restInfo } = info;

    // ============================================
    // METHODS
    // ============================================

    const handleCreateUser = useCallback(() => {
        userCreate(dispatch);
        setTrigger(+new Date());
        setRequestProcessing(true);
    }, [dispatch]);

    const { response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: { ...restInfo, allowCountry: _.isEmpty(allowCountry) ? null : allowCountry },
        },
        trigger,
        url: `${VERSION.V1}${IDENTITY.ROOT}${IDENTITY.SIGN_UP}`,
    });

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            createOperatorOperation(dispatch, getOperationId(response));
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, userCreateFailure);
    useToasterErrorMessage(error, errorMsg);

    const [operationComplete, operationError] = useOperationAsyncEndpoint(
        'createOperator',
        ACTIONS.USER_CREATE_SUCCESS,
        ACTIONS.USER_CREATE_FAILURE,
        'User successfully created!',
    );

    useEffect(() => {
        if (operationComplete || operationError || error) {
            setRequestProcessing(false);
        }
    }, [error, operationComplete, operationError]);

    return [requestProcessing, operationComplete, handleCreateUser];
}

export function useChangeUserPasswordOperationAsyncEndpoint(info) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const modalDetails = getStateProp(
        state,
        'administration.userAuthoritySetting.modalDetails',
        {},
    );
    const modalData = getStateProp(modalDetails, 'modalData', {});
    const userName = getStateProp(modalData, 'userName', '');

    // ============================================
    // METHODS
    // ============================================

    const handleChangeUserPassword = useCallback(() => {
        userPasswordChange(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                ...info,
            },
        },
        trigger,
        url: `${VERSION.V1}${IDENTITY.ROOT}/${userName}${IDENTITY.PASSWORD}`,
    });

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            userPasswordChangeSuccess(dispatch);
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, userPasswordChangeFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleChangeUserPassword];
}

export function useChangeUserStatusOperationAsyncEndpoint(statusDetails) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);
    const { isChecked, record } = statusDetails;
    const status = isChecked ? 'Active' : 'Lock';
    const userId = record && record.userId;

    const handleStatusChange = useCallback(() => {
        userStatusChange(dispatch);
        setTrigger(new Date());
    }, [dispatch]);
    // ============================================
    // METHODS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        trigger,
        url: `${VERSION.V1}${IDENTITY.OPERATORS}/${userId}/status/${status}`,
    });

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        !_.isEmpty(statusDetails) && handleStatusChange();
    }, [handleStatusChange, statusDetails]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            userStatusChangeSuccess(dispatch);
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, userStatusChangeFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response || error];
}

export function useGetUserGroupFullListAsyncEndpoint(userGroupList) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetUserGroupList = useCallback(() => {
        userGroupGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'GET',
        options: {
            params: {
                includeDeactivated: false,
                pageSize: 50,
            },
        },
        trigger,
        url: `${VERSION.V1}${IDENTITY.GROUP_ACCESS}`,
    });

    useEffect(() => {
        handleGetUserGroupList();
    }, [handleGetUserGroupList]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            userGroupGetSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, userGroupGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useSetUserAuthorityAsyncEndpoint(userAuthority) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState();
    const userId = getStateProp(
        state,
        'administration.userAuthoritySetting.modalDetails.modalData.userId',
    );
    const accessGroupId = getStateProp(
        state,
        'administration.userAuthoritySetting.selectedInfo.accessGroupId',
    );
    const refreshTable = getStateProp(
        state,
        'administration.userAuthoritySetting.fetchData',
        () => {},
    );
    const menuList = getStateProp(state, 'administration.menus');

    // ============================================
    // METHODS
    // ============================================

    const handleSetUserAuthority = () => {
        userAuthoritySet(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!accessGroupId,
        method: userAuthority ? 'PUT' : 'POST',
        options: {
            data: {
                accessGroupId,
            },
        },
        trigger,
        url: userAuthority
            ? `${VERSION.V1}${ADMIN.USER_AUTHORITY.AUTHORITY_LIST}/${'change-role'}/${userId}`
            : `${VERSION.V1}${ADMIN.USER_AUTHORITY.AUTHORITY_LIST}/${userId}`,
    });

    useEffect(() => {
        if (response) {
            const { menuIds, ...rest } = response.data.data;
            clearError(dispatch);
            userAuthoritySetSuccess(dispatch, {
                selectedMenus: getSubMenuBySubMenuId(menuList, menuIds),
                ...rest,
            });
            refreshTable();
        }
    }, [dispatch, menuList, refreshTable, response]);

    const errorMsg = useError(error, userAuthoritySetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleSetUserAuthority];
}

export function useGetUserAuthorityDetailsAsyncEndpoint(modalComponent) {
    const [state, dispatch] = useStore();
    const accessGroupId = getStateProp(
        state,
        'administration.userAuthoritySetting.modalDetails.modalData.accessGroupId',
        null,
    );
    const userId = getStateProp(
        state,
        'administration.userAuthoritySetting.modalDetails.modalData.userId',
        null,
    );

    const menuList = getStateProp(state, 'administration.menus');
    // ============================================
    // METHODS
    // ============================================

    const handleGetUserAuthorityDetail = useCallback(() => {
        userAuthorityDetailGet(dispatch);
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () =>
            !!accessGroupId &&
            modalComponent !== USER_AUTHORITY_SETTING_MODAL_TYPES.CHANGE_CURRENCY.component,
        method: 'GET',
        trigger: accessGroupId,
        url: `${VERSION.V1}${ADMIN.USER_AUTHORITY.AUTHORITY_LIST}/${userId}`,
    });

    useEffect(() => {
        modalComponent !== USER_AUTHORITY_SETTING_MODAL_TYPES.CHANGE_CURRENCY.component &&
            accessGroupId &&
            handleGetUserAuthorityDetail();
    }, [accessGroupId, handleGetUserAuthorityDetail, modalComponent]);

    useEffect(() => {
        if (response) {
            const { menuIds, ...rest } = response.data.data;
            clearError(dispatch);
            userAuthorityDetailGetSuccess(dispatch, {
                selectedMenus:
                    modalComponent === USER_AUTHORITY_SETTING_MODAL_TYPES.ADD_ROLE.component
                        ? getSubMenuBySubMenuId(menuList, menuIds)
                        : subMenuGrouping(menuList, menuIds),
                ...rest,
            });
        }
    }, [dispatch, menuList, modalComponent, response]);

    const errorMsg = useError(error, userAuthorityDetailGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetUserAuthorityDetail];
}

export function useGetUserIndividualAuthorityAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const modalData = getStateProp(
        state,
        'administration.userAuthoritySetting.modalDetails.modalData',
    );
    const [trigger, setTrigger] = useState(null);
    const { userId } = modalData;

    const menuList = getStateProp(state, 'administration.menus');
    // ============================================
    // METHODS
    // ============================================

    const handleGetUserIndividualAuthority = useCallback(() => {
        userIndividualAuthorityDetailGet(dispatch);
        setTrigger(new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${ADMIN.USER_AUTHORITY.INDIVIDUAL_AUTHORITY}/${userId}`,
    });

    useEffect(() => {
        handleGetUserIndividualAuthority();
    }, [handleGetUserIndividualAuthority, userId]);

    useEffect(() => {
        if (response) {
            const { menuOperationIds } = response.data.data;
            clearError(dispatch);
            userIndividualAuthorityDetailGetSuccess(dispatch, {
                selectedIndividualMenus: getSubMenuByOperationIds(menuList, menuOperationIds),
            });
        }
    }, [dispatch, menuList, response]);

    const errorMsg = useError(error, userIndividualAuthorityDetailGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetUserIndividualAuthority];
}

export function useSetUserIndividualAuthorityAsyncEndpoint(isReset) {
    const [state, dispatch] = useStore();
    const modalData = getStateProp(
        state,
        'administration.userAuthoritySetting.modalDetails.modalData',
    );
    const [trigger, setTrigger] = useState(null);
    const { userId } = modalData;
    const selectedMenus = getStateProp(
        state,
        'administration.userAuthoritySetting.selectedInfo.selectedIndividualMenus',
        {},
    );
    const menuList = getStateProp(state, 'administration.menus');
    const menuOperationIds = isReset ? [] : getMenuOperationIds(menuList, selectedMenus);
    // ============================================
    // METHODS
    // ============================================

    const handleSetUserIndividualAuthority = useCallback(() => {
        userIndividualAuthoritySet(dispatch);
        setTrigger(new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                menuOperationIds,
            },
        },
        trigger,
        url: `${VERSION.V1}${ADMIN.USER_AUTHORITY.INDIVIDUAL_AUTHORITY}/${userId}`,
    });

    useEffect(() => {
        if (response) {
            const { menuOperationIds } = response.data.data;
            clearError(dispatch);
            userIndividualAuthoritySetSuccess(dispatch, {
                selectedIndividualMenus: getSubMenuByOperationIds(menuList, menuOperationIds),
            });
        }
    }, [dispatch, menuList, response]);

    const errorMsg = useError(error, userIndividualAuthoritySetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleSetUserIndividualAuthority, response];
}

export function useChangeUserCurrencyOperationAsyncEndpoint(formValues) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const modalDetails = getStateProp(
        state,
        'administration.userAuthoritySetting.modalDetails',
        {},
    );
    const modalData = getStateProp(modalDetails, 'modalData', {});
    const { allowCountry, ...restValues } = formValues;

    // ============================================
    // METHODS
    // ============================================

    const handleChangeUserCurrency = useCallback(() => {
        userCurrencyChange(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                allowCountry: _.isEmpty(allowCountry) ? null : allowCountry,
                id: modalData.userId,
                ...restValues,
            },
        },
        trigger,
        url: `${VERSION.V1}${IDENTITY.CHANGE_CURRENCY}`,
    });

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            userCurrencyChangeSuccess(dispatch);
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, userCurrencyChangeFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleChangeUserCurrency];
}

export function useGetCurrencySettingFiltersOperationAsyncEndpoint() {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetCurrencySettingFilterList = useCallback(() => {
        currencySettingFiltersGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${ADMIN.CURRENCY.FILTER_OPTIONS}`,
    });

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            currencySettingFiltersGetSuccess(dispatch, {
                effectiveDates: response.data.data.effectiveDates,
                filters: { effectiveDate: response.data.data.effectiveDates[0] },
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        handleGetCurrencySettingFilterList();
    }, [handleGetCurrencySettingFilterList]);

    const errorMsg = useError(error, currencySettingFiltersGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetCurrencySettingFilterList];
}

export function useGetCurrencySettingAsyncEndpoint(filters) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);
    const { effectiveDate, baseCurrency } = filters;

    // ============================================
    // METHODS
    // ============================================

    const handleGetCurrencSetting = useCallback(() => {
        currencySettingGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                ...filters,
            },
        },
        trigger,
        url: `${VERSION.V1}${ADMIN.CURRENCY.EXCHANGE_RATE_LIST}`,
    });

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            currencySettingGetSuccess(
                dispatch,
                baseCurrency
                    ? {
                          settingsList: response.data.data,
                      }
                    : {
                          newRateSetting: { latestList: response.data.data },
                      },
            );
        }
    }, [baseCurrency, dispatch, response]);

    useEffect(() => {
        if (effectiveDate && baseCurrency) {
            handleGetCurrencSetting();
        }
    }, [effectiveDate, baseCurrency, handleGetCurrencSetting]);

    const errorMsg = useError(error, currencySettingGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetCurrencSetting];
}

export function useUpdateCurrencySettingAsyncEndpoint(formValues, isAddNewRate) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);
    const { exchangeRates, effectiveDate } = formValues;

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateCurrencSetting = () => {
        currencySettingSave(dispatch);
        setTrigger(+new Date());
    };

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                effectiveDate,
                exchangeRates: isAddNewRate
                    ? _.map(exchangeRates, (rate) => {
                          return {
                              baseCurrency: rate.baseCurrency,
                              currency: rate.currency,
                              rates: rate.rates,
                          };
                      })
                    : exchangeRates,
            },
        },
        trigger,
        url: `${VERSION.V1}${ADMIN.CURRENCY.SAVE}`,
    });

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            currencySettingSaveSuccess(dispatch);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, currencySettingSaveFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateCurrencSetting, response];
}
