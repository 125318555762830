import React from 'react';

import { Table } from 'antd';
import { useVT } from 'virtualizedtableforantd4';

import './VipSetupResultTable.less';
import { TABLE_DATA } from '../misc';
import { getColumns } from './VipSetupTableMeta';

const VipSetupResultTable = ({ vipSetup, onVipSetupChange, loading, isEditMode }) => {
    // ============================================
    // HOOKS
    // ============================================

    const [vt] = useVT(() => ({ scroll: { y: 500 } }), []);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            size="small"
            align="center"
            dataSource={TABLE_DATA}
            rowKey={(record, index) => index}
            pagination={false}
            bordered
            columns={getColumns(vipSetup, onVipSetupChange, isEditMode)}
            scroll={{ x: 1500, y: 500 }}
            loading={loading}
            components={vt}
        />
    );
};

export default VipSetupResultTable;
