import currencySettingReducer from './currency-setting/currencySettingReducer';
import groupAuthoritySettingReducer from './group-setting/groupAuthoritySettingReducer';
import menuReducer from './menuReducer';
import userAuthoritySettingReducer from './user-setting/userAuthoritySettingReducer';

export default function adminReducer(administration = {}, action) {
    const { currencySetting, userAuthoritySetting, groupAuthoritySetting, menus } = administration;

    return {
        currencySetting: currencySettingReducer(currencySetting, action),
        groupAuthoritySetting: groupAuthoritySettingReducer(groupAuthoritySetting, action),
        menus: menuReducer(menus, action),
        userAuthoritySetting: userAuthoritySettingReducer(userAuthoritySetting, action),
    };
}
