import React from 'react';

import { Row } from 'antd';

import {
    AnnouncementCategoryFilter,
    AnnouncementTaskNumberFilter,
    AnnouncementTitleFilter,
    AnnouncementTypeFilter,
    ApplicantFilter,
} from '../../filters';
import { announcementFilterSet } from './globalAnnouncementActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const GlobalAnnouncementFilters = () => {
    const [state, dispatch] = useStore();

    const globalAnnouncement = getStateProp(
        state,
        'announcement.announcementVerification.globalAnnouncement',
        {},
    );
    const filters = getStateProp(globalAnnouncement, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => announcementFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <AnnouncementTaskNumberFilter
                value={getStateProp(filters, 'taskNo', '')}
                onFilterChange={setFilter}
            />
            <AnnouncementTitleFilter
                value={getStateProp(filters, 'title', '')}
                onFilterChange={setFilter}
            />
            <AnnouncementTypeFilter
                value={getStateProp(filters, 'announcementType')}
                onFilterChange={setFilter}
            />
            <AnnouncementCategoryFilter
                value={getStateProp(filters, 'category')}
                onFilterChange={setFilter}
            />
            <ApplicantFilter
                value={getStateProp(filters, 'applicant', '')}
                onFilterChange={setFilter}
            />
        </Row>
    );
};

export default GlobalAnnouncementFilters;
