import React, { useEffect } from 'react';

import { Card, Form, message } from 'antd';
import _ from 'lodash';

import { TIMEZONE } from '../../../common/constants/localization';
import { closePlayerSingleDetailPage, dropFilters } from './betListActions';
import { getStateProp, isLessThanOneWeek } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { switchTimezone } from '../../../common/actions/timezoneActions';
import {
    useExportBetListAsyncEndpoint,
    useGetBetListAsyncEndpoint,
    useGetBetListFilterOptionsAsyncEndpoint,
    useGetDetailUrlOperation,
} from '../../../services/betService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import BetListFilter from './BetListFilter';
import BetListResultsTable from './table/BetListResultsTable';
import BetListSummary from './BetListSummary';
import PlayerDetailModal from './modals/PlayerDetailModal';

const BetList = () => {
    const [state, dispatch] = useStore();

    const openDetailPage = getStateProp(state, 'bet.betList.openDetailPage', false);
    const selectedDetailUrl = getStateProp(state, 'bet.betList.selectedDetailUrl', '');
    const filters = getStateProp(state, 'bet.betList.filters', {});
    const {
        dateFrom,
        dateTo,
        paidTimeDateFrom,
        paidTimeDateTo,
        statementDateFrom,
        statementDateTo,
    } = filters;

    // ============================================
    // METHODS
    // ============================================

    const checkIsFilterEmpty = _.every(filters, _.isEmpty);

    const fetchAll = () => {
        dropFilters(dispatch);
    };

    const searchBetList = () => {
        if (checkIsFilterEmpty) {
            message.warning(getTranslation('Must have atleast one filter!'));
        } else if (
            !isLessThanOneWeek(dateFrom, dateTo) &&
            !isLessThanOneWeek(paidTimeDateFrom, paidTimeDateTo) &&
            !isLessThanOneWeek(statementDateFrom, statementDateTo) &&
            _.isEmpty(filters.userName) &&
            _.isEmpty(filters.refNo) &&
            _.isEmpty(filters.transNo)
        ) {
            message.warning(
                getTranslation(
                    'Please filter by Username, Reference No, or Transaction No, else date range must not be greater than 1 week',
                ),
            );
        } else {
            handleGetBetList();
        }
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetBetList] = useGetBetListAsyncEndpoint();
    const [getDetailUrlLoading, handleGetDetailUrl] = useGetDetailUrlOperation();
    const [filterLoading] = useGetBetListFilterOptionsAsyncEndpoint();
    const [exporting, handleExportBetList] = useExportBetListAsyncEndpoint();

    useEffect(() => {
        switchTimezone(dispatch, {
            location: TIMEZONE.CANADA.LOCATION,
            offset: TIMEZONE.CANADA.OFFSET,
        });

        return () => {
            switchTimezone(dispatch, {
                location: TIMEZONE.BANGKOK.LOCATION,
                offset: TIMEZONE.BANGKOK.OFFSET,
            });
        };
    }, [dispatch]);

    // ============================================
    // METHODS
    // ============================================

    return (
        <Card title={getTranslation('Bet List')}>
            <Form onFinish={searchBetList}>
                <BetListFilter refreshTable={handleGetBetList} loading={filterLoading} />
                <ActionButtons
                    onDropFilters={fetchAll}
                    onExport={handleExportBetList}
                    exporting={exporting}
                />
            </Form>

            <BetListResultsTable loading={loading} handleGetDetailUrl={handleGetDetailUrl} />
            <BetListSummary />

            <PlayerDetailModal
                visible={openDetailPage}
                detailPageUrl={selectedDetailUrl}
                loading={getDetailUrlLoading}
                onCancel={() => {
                    closePlayerSingleDetailPage(dispatch);
                }}
            />
        </Card>
    );
};

export default BetList;
