export const ACTIONS = {
    DEPOSIT_RECORDS_GET: 'CRYPTO_DEPOSIT_RECORDS_GET',
    DEPOSIT_RECORDS_GET_FAILURE: 'CRYPTO_DEPOSIT_RECORDS_GET_FAILURE',
    DEPOSIT_RECORDS_GET_SUCCESS: 'CRYPTO_DEPOSIT_RECORDS_GET_SUCCESS',
    FILTER_DROP: 'CRYPTO_DEPOSIT_RECORDS_FILTER_DROP',
    FILTER_SET: 'CRYPTO_DEPOSIT_RECORDS_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'CRYPTO_DEPOSIT_RECORDS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'CRYPTO_DEPOSIT_RECORDS_TABLE_PAGE_SIZE_CHANGE',
    },
};

export const cryptoDepositRecordFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const cryptoDepositRecordFilterDrop = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTER_DROP,
    });

export const cryptoDepositRecordGet = (dispatch) =>
    dispatch({
        type: ACTIONS.DEPOSIT_RECORDS_GET,
    });

export const cryptoDepositRecordGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DEPOSIT_RECORDS_GET_SUCCESS,
    });

export const cryptoDepositRecordGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DEPOSIT_RECORDS_GET_FAILURE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });
