import React, { useState } from 'react';

import { Table } from 'antd';
import { useVT } from 'virtualizedtableforantd4';
import ResizeObserver from 'rc-resize-observer';

import { getColumns, getPaginationOptions } from './BetListTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const BetListResultsTable = ({ loading, handleGetDetailUrl }) => {
    const [state, dispatch] = useStore();
    const [tableWidth, setTableWidth] = useState(2000);

    const betList = getStateProp(state, 'bet.betList');
    const tableData = getStateProp(betList, 'list', []);
    const paging = getStateProp(betList, 'paging', {});
    const timezone = getStateProp(state, 'timezone');

    // ============================================
    // HOOKS
    // ============================================

    const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

    // ============================================
    // RENDER
    // ============================================

    return (
        <ResizeObserver
            onResize={({ width }) => {
                if (width > 2100) setTableWidth(width);
            }}
        >
            <Table
                columns={getColumns({ handleGetDetailUrl, paging, tableWidth, timezone })}
                pagination={getPaginationOptions(paging, dispatch)}
                size="small"
                align="left"
                rowKey={(record) => record.refNo}
                bordered
                dataSource={tableData}
                loading={loading}
                scroll={{ x: tableWidth, y: 600 }}
                components={vt}
            />
        </ResizeObserver>
    );
};

export default BetListResultsTable;
