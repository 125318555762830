import React from 'react';

import { Card, Form } from 'antd';

import {
    dailyLoginConfirmModalClose,
    dailyLoginEdit,
    dailyLoginEditModalClose,
    dropFilters,
} from './dailyLoginManagementActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    useGetDailyLoginAsyncEndpoint,
    useGetDailyLoginByIdAsyncEndpoint,
} from '../../../services/rewardMangementService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import ConfirmModal from './modal/ConfirmModal';
import DailyLoginManagementFilters from './DailyLoginManagementFilters';
import DailyLoginManagementTable from './table/DailyLoginManagementTable';
import EditModal from './modal/EditModal';

const DailyLoginManagement = () => {
    const [state, dispatch] = useStore();

    const modalDetails = getStateProp(
        state,
        'rewardsManagement.dailyLoginManagement.modalDetails',
        {},
    );
    const activeDailyLoginBonus = getStateProp(
        state,
        'rewardsManagement.dailyLoginManagement.activeDailyLoginBonus',
        {},
    );

    const { isVisible, selectedData } = modalDetails;
    const {
        confirmModalVisible,
        selectedDailyLoginToActive,
        ...activeDailyLogin
    } = activeDailyLoginBonus;

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetDailyLogin();
    };

    const closeEditModal = () => dailyLoginEditModalClose(dispatch);

    const closeConfirmModal = () => dailyLoginConfirmModalClose(dispatch);

    const onFormValueChange = (key, value) => {
        dailyLoginEdit(dispatch, { key, value });
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetDailyLogin] = useGetDailyLoginAsyncEndpoint();
    const [getDailyLoginByIdLoading] = useGetDailyLoginByIdAsyncEndpoint(selectedData.id);
    // ============================================
    // RENDER
    // ============================================
    return (
        <Card title={getTranslation('Daily Login Management')}>
            <Form onFinish={handleGetDailyLogin}>
                <DailyLoginManagementFilters />
                <ActionButtons onDropFilters={fetchAll} />
            </Form>
            <DailyLoginManagementTable loading={loading} />
            <EditModal
                visible={isVisible}
                selectedData={selectedData}
                closeEditModal={closeEditModal}
                onFormValueChange={onFormValueChange}
                loading={getDailyLoginByIdLoading}
            />
            <ConfirmModal
                visible={confirmModalVisible}
                name={selectedDailyLoginToActive && selectedDailyLoginToActive.code}
                activeName={activeDailyLogin.code}
                onCancel={closeConfirmModal}
                id={selectedDailyLoginToActive && selectedDailyLoginToActive.id}
            />
        </Card>
    );
};

export default DailyLoginManagement;
