import React from 'react';

import { Row } from 'antd';

import './ActionButtons.less';
import { ButtonYellow } from '../../../common/components/buttons/Buttons';
import { DropButton, ExportButton, SearchButton } from '../../../common/components/buttons';
import { getTranslation } from '../../../common/helpers/locale';

const ActionButtons = ({ onDropFilters, onExport, onCreateNews }) => {
    return (
        <Row align="middle" justify="end" className="news-buttons__container">
            <SearchButton htmlType="submit" />
            <DropButton onDropFilters={onDropFilters} />
            <ExportButton onExport={onExport} />
            <ButtonYellow type="primary" onClick={onCreateNews}>
                {getTranslation('Create News')}
            </ButtonYellow>
        </Row>
    );
};

export default ActionButtons;
