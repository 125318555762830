import _ from 'lodash';

/**
 * Function to perform validation check in Ant forms against other form's value
 * @param {String} fieldToCompare Form field to compare with current validator applied field.
 * @param {String} message Validation message to display.
 */
export const equalityValidator = (fieldToCompare, message) => {
    return (rule, value) => {
        if (!value || fieldToCompare === value) {
            return Promise.resolve();
        }
        return Promise.reject(message);
    };
};

/**
 * Function to perform validation check in Ant forms against current state value.
 * @param {Object} state App state selector.
 * @param {String} statePropName State property name used as source for comparison
 * with current validator applied field value.
 * @param {String} message Validation message to display.
 */
export const stateEqualityValidator = (state, statePropName, message) => {
    return (rule, value) => {
        if (!_.isMatch(state, { [statePropName]: value })) {
            return Promise.resolve();
        }
        return Promise.reject(message);
    };
};

/**
 * Function to perform validation check in Ant forms against current array state objects value.
 * Other words, it finds out if the array state selector contains object with key-value pair.
 * @param {Object} state App state selector.
 * @param {Array} statePropName State property name used as source for comparison
 * with current validator applied field value.
 * @param {String} message Validation message to display.
 */
export const arrayStateEqualityValidator = (state, statePropName, message) => {
    return (rule, value) => {
        if (
            !_.find(state, (o) => o[statePropName].replace(/\s/g, '') === value.replace(/\s/g, ''))
        ) {
            return Promise.resolve();
        }
        return Promise.reject(message);
    };
};

export const previousBankCardValidator = (bankCards, currentCardNumber) => {
    return _.find(
        bankCards,
        (card) => card.bankCardNo.replace(/\s/g, '') === currentCardNumber.replace(/\s/g, ''),
    );
};

export const lengthValidator = (fieldToValidate, fieldMinLength, fieldMaxLength, message) => {
    const fieldLength = fieldToValidate ? fieldToValidate.replace(/\s/g, '').length : 0;
    return () => {
        if (fieldMinLength <= fieldLength && fieldLength <= fieldMaxLength && fieldLength > 0) {
            return Promise.resolve();
        }
        return Promise.reject(message);
    };
};
