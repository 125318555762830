import React, { useState } from 'react';

import { Card, Col, Form, Input, Row, message } from 'antd';
import { PlusCircleTwoTone, SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';

import './RegistrationDetails.less';
import { ButtonGreen } from '../../../common/components/buttons/Buttons';
import { closePlayerIpModal, openPlayerIpModal, setPlayerIp } from './registrationDetailsActions';
import { getStateProp, hidePhone } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    useGetRegistrationDetailsAsyncEndpoint,
    useGetSearchIpAsyncEndpoint,
} from '../../../services/playerService';
import { useStore } from '../../../store/StateProvider';
import Loader from '../../../common/components/Loader';
import PlayerIpModal from './playerip-list/PlayerIpModal';

const RegistrationDetails = () => {
    const [state, dispatch] = useStore();
    const [formValues, setFormValues] = useState({});

    const regDetails = getStateProp(state, 'players.registrationDetails', {});
    const searchIpList = getStateProp(state, 'players.registrationDetails.searchIpList', {});

    const [ipAddressVisible, setIpAddressVisible] = useState(false);
    const [userNameVisible, setUserNameVisible] = useState(false);

    const playerIpModalVisible = getStateProp(
        state,
        'players.registrationDetails.playerIpModalVisible',
        {},
    );

    const playerIpModalOpen = (ipAddress) => {
        setPlayerIp(dispatch, ipAddress);
        openPlayerIpModal(dispatch);
    };
    const playerIpModalClose = () => closePlayerIpModal(dispatch);

    // ============================================
    // METHODS
    // ============================================

    const handleSearchSubmit = (values) => {
        if (values.userName) {
            setFormValues(values);
            getRegistrationDetails();
            setIpAddressVisible(true);
            setUserNameVisible(false);
        } else message.warning(getTranslation('Please input username!'));
    };

    const handleIpAddressSearchSubmit = (values) => {
        if (values.ipAddress) {
            setFormValues(values);
            handleGetSearchIp();
            setIpAddressVisible(false);
            setUserNameVisible(true);
        } else message.warning(getTranslation('Please input IP address!'));
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loadingIpAddress, getRegistrationDetails] = useGetRegistrationDetailsAsyncEndpoint(
        formValues.userName,
    );

    const [loadingSearchIp, handleGetSearchIp] = useGetSearchIpAsyncEndpoint(formValues.ipAddress);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Registration Details')} className="registration-details__card">
            <Row className="registration-details__search">
                <Form onFinish={handleSearchSubmit} name="search-by-name">
                    <Row>
                        <Col span={16}>
                            <Form.Item name="userName">
                                <Input placeholder={getTranslation('Username')} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <ButtonGreen
                                className="registration-details__search-btn"
                                htmlType="submit"
                            >
                                <SearchOutlined />
                                {getTranslation('Search')}
                            </ButtonGreen>
                        </Col>
                    </Row>
                </Form>
                <Col span={1} />
                <Form onFinish={handleIpAddressSearchSubmit} name="search-by-ip">
                    <Row>
                        <Col span={16}>
                            <Form.Item name="ipAddress">
                                <Input placeholder={getTranslation('IP address')} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <ButtonGreen
                                className="registration-details__search-btn"
                                htmlType="submit"
                            >
                                <SearchOutlined />
                                {getTranslation('Search')}
                            </ButtonGreen>
                        </Col>
                    </Row>
                </Form>
            </Row>

            {loadingIpAddress ? (
                <Row align="middle" justify="center" style={{ height: 260 }}>
                    <Loader size="large" />
                </Row>
            ) : (
                <Row>
                    <Col span={8} className="registration-details__info-card">
                        <Row
                            className="registration-details__info-card__item"
                            style={{ display: ipAddressVisible ? 'block' : 'none' }}
                        >
                            <span className="registration-details__info-card__label">
                                {getTranslation('Username')}:
                            </span>
                            <span className="registration-details__info-card__value">
                                {regDetails.userName}
                            </span>
                        </Row>
                        <Row
                            className="registration-details__info-card__item"
                            style={{ display: ipAddressVisible ? 'block' : 'none' }}
                        >
                            <span className="registration-details__info-card__label">
                                {getTranslation('Full Name')}:
                            </span>
                            <span className="registration-details__info-card__value">
                                {regDetails.fullName}
                            </span>
                        </Row>
                        <Row
                            className="registration-details__info-card__item"
                            style={{ display: ipAddressVisible ? 'block' : 'none' }}
                        >
                            <span className="registration-details__info-card__label">
                                {getTranslation('Bank Card')}:
                            </span>
                            <span className="registration-details__info-card__value">
                                {regDetails.bankCardNo}
                            </span>
                        </Row>
                        <Row
                            className="registration-details__info-card__item"
                            style={{ display: ipAddressVisible ? 'block' : 'none' }}
                        >
                            <span className="registration-details__info-card__label">
                                {getTranslation('Mobile No')}:
                            </span>
                            <span className="registration-details__info-card__value">
                                {hidePhone(regDetails.mobileNo)}
                            </span>
                        </Row>
                        <Row
                            className="registration-details__info-card__item"
                            style={{ display: ipAddressVisible ? 'block' : 'none' }}
                        >
                            <span className="registration-details__info-card__label">
                                {getTranslation('Register IP')}:
                            </span>
                            <span className="registration-details__info-card__value">
                                {regDetails.registerIpv4}
                            </span>
                        </Row>
                        {ipAddressVisible && (
                            <Row className="registration-details__info-card__item">
                                <Col>
                                    <span className="registration-details__info-card__label">
                                        {getTranslation('IP address')}:
                                    </span>
                                </Col>
                                <Col>
                                    {_.map(regDetails.ipAddress, (ipAddress) => (
                                        <Row
                                            align="middle"
                                            className="registration-details__info-card__item"
                                            key={ipAddress}
                                        >
                                            <span className="registration-details__info-card__value">
                                                {ipAddress}
                                            </span>
                                            <PlusCircleTwoTone
                                                className="registration-details__info-card__plus-icon"
                                                onClick={() => playerIpModalOpen(ipAddress)}
                                            />
                                        </Row>
                                    ))}
                                </Col>
                            </Row>
                        )}
                        {loadingSearchIp ? (
                            <Row align="middle" justify="center" style={{ height: 260 }}>
                                <Loader size="large" />
                            </Row>
                        ) : (
                            <Row
                                className="registration-details__info-card__item"
                                style={{ display: userNameVisible ? 'block' : 'none' }}
                            >
                                <Col>
                                    <span className="registration-details__info-card__label">
                                        {getTranslation('Username')}:<br />
                                    </span>
                                </Col>
                                <Col>
                                    {_.map(searchIpList, (ipListItem, index) => (
                                        <Row
                                            align="middle"
                                            className="registration-details__info-card__item"
                                            key={index}
                                        >
                                            <span className="registration-details__info-card__value">
                                                {ipListItem.userName}
                                            </span>
                                        </Row>
                                    ))}
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            )}

            <PlayerIpModal visible={playerIpModalVisible} onCancel={playerIpModalClose} />
        </Card>
    );
};

export default RegistrationDetails;
