export const REQUEST_SEARCH_PLAYER_INFO_ACTIONS = {
    BALANCE_ADDITION: 'Balance Addition',
    CRYPTOCURRENCY_CORRECTION: 'Cryptocurrency Correction',
    CRYPTO_DEPOSIT: 'Crypto Deposit',
    DEPOSIT_CORRECTION: 'Deposit Correction',
    EWALLET_CORRECTION: 'E-Wallet Correction',
    EWALLET_DEPOSIT: 'E-Wallet Deposit',
    MERCHANT_DEPOSIT: 'Merchant Deposit',
    OFFLINE_DEPOSIT: 'Offline Deposit',
    USERNAME: 'Username',
};

export const TEMPLATE_URL = 'https://storage.googleapis.com/gg-images-api/template.xlsx';
