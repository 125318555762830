import React, { useState } from 'react';

import { Card, Divider, Form } from 'antd';

import { API } from '../../../common/constants/api';
import { PAYMENT_ORDER_OPERATIONS } from '../../../common/constants/misc';
import { WITHDRAWAL_RECORD_TABLE_COLUMNS } from './constants';
import {
    closeVerifyModal,
    dropFilters,
    performWithdrawalRecordOperation,
    selectColumns,
} from './withdrawalRecordsActions';
import { getColumns } from './table/WithdrawalRecordTableMeta';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useExportAsyncEndpoint } from '../../../services/hooks/useExport';
import {
    useGetWithdrawalRecordsAsyncEndpoint,
    useWithdrawalRecordsOperationAsyncEndpoint,
} from '../../../services/withdrawalRecordService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import ColumnSelectionModal from '../../../common/components/column-selection/ColumnSelectionModal';
import TotalBalance from '../../../common/components/TotalBalance';
import VerifyModal from './verification/VerifyModal';
import WithdrawalRecordFilters from './WithdrawalRecordFilters';
import WithdrawalRecordResultsTable from './table/WithdrawalRecordResultsTable';
const { TRANSACTION_HISTORY } = API;

const WithdrawalRecords = () => {
    const [state, dispatch] = useStore();

    const { withdrawalRecords } = getStateProp(state, 'transactionHistory');
    const verifyModalVisible = getStateProp(withdrawalRecords, 'verifyModalVisible');
    const withdrawalRecord = getStateProp(withdrawalRecords, 'operation.withdrawalRecord', {});
    const filters = getStateProp(withdrawalRecords, 'filters');
    const { totalRecordCount } = getStateProp(withdrawalRecords, 'paging', {});
    const selectedColumns = getStateProp(
        withdrawalRecords,
        'selectedColumns',
        WITHDRAWAL_RECORD_TABLE_COLUMNS,
    );
    const [columnSelectorModalVisible, setColumnSelectorModalVisible] = useState(false);

    const pageBalance = getStateProp(withdrawalRecords, 'pageDataTotal', []);
    const totalBalance = getStateProp(withdrawalRecords, 'dataTotal', []);
    const grandTotal = getStateProp(withdrawalRecords, 'grandTotal', 0);
    const pageTotal = getStateProp(withdrawalRecords, 'pageTotal', 0);

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetWithdrawalRecords();
    };

    const closeOperationModal = () => {
        performWithdrawalRecordOperation(dispatch, { action: PAYMENT_ORDER_OPERATIONS.RELEASE });
        closeVerifyModal(dispatch);
    };

    const closeColumnSelectorModal = () => setColumnSelectorModalVisible(false);

    const openColumnSelectorModal = () => setColumnSelectorModalVisible(true);

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetWithdrawalRecords] = useGetWithdrawalRecordsAsyncEndpoint();
    useWithdrawalRecordsOperationAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        dataMeta: getColumns(selectedColumns),
        filters,
        innerListKey: 'data',
        method: 'GET',
        subject: getTranslation('Withdrawal Records'),
        totalRecordCount,
        url: `${TRANSACTION_HISTORY.WITHDRAWAL_RECORDS}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Withdrawal Records')}>
            <Form onFinish={handleGetWithdrawalRecords}>
                <WithdrawalRecordFilters />
                <ActionButtons
                    onDropFilters={fetchAll}
                    onExport={handleExportAction}
                    onColumnSelect={openColumnSelectorModal}
                />
            </Form>

            <WithdrawalRecordResultsTable loading={loading} />

            <Divider />

            <TotalBalance
                pageBalance={pageBalance}
                totalBalance={totalBalance}
                grandTotal={grandTotal}
                pageTotal={pageTotal}
            />

            <VerifyModal
                visible={verifyModalVisible}
                onCancel={closeOperationModal}
                withdrawalRecord={withdrawalRecord}
            />
            <ColumnSelectionModal
                visible={columnSelectorModalVisible}
                onCancel={closeColumnSelectorModal}
                tableColumnsList={WITHDRAWAL_RECORD_TABLE_COLUMNS}
                selectAction={selectColumns}
                selectedColumnsState="transactionHistory.withdrawalRecords.selectedColumns"
            />
        </Card>
    );
};

export default WithdrawalRecords;
