import React, { useEffect, useState } from 'react';

import { Col, Form, Input, Row, Select } from 'antd';
import _ from 'lodash';

import './StatusModalContent.less';
import { ButtonGray, ButtonGreen } from '../../../../../common/components/buttons/Buttons';
import { PLAYER_STATUSES } from '../../../constants';
import { getPlayerStatus } from '../../../helpers';
import { getTranslation } from '../../../../../common/helpers/locale';
import {
    useChangeStatusAsyncEndpoint,
    useGetStatusHistoryAsyncEndpoint,
} from '../../../../../services/playerService';
import StatusResultsTable from './StatusResultsTable';

const StatusModalContent = ({ player, onCancel }) => {
    const { id, userName } = player;
    const [status, setStatus] = useState(PLAYER_STATUSES.ACTIVE);
    const [remark, setRemark] = useState({
        errorMsg: null,
        validateStatus: 'validating',
        value: null,
    });

    // ============================================
    // METHODS
    // ============================================

    const validateRemark = (value) => {
        if (!value) {
            return {
                errorMsg: getTranslation('Remark is required!'),
                validateStatus: 'error',
            };
        } else {
            return { errorMsg: null, validateStatus: 'success' };
        }
    };

    const handleRemarkChange = (e) => {
        setRemark({ ...validateRemark(e.target.value), value: e.target.value });
    };

    const handleStatusChange = (value) => {
        setStatus(value);
    };

    const confirmChangeStatus = () => changeStatus();

    // ============================================
    // HOOKS
    // ============================================

    const [statusLoading, getStatusHistory] = useGetStatusHistoryAsyncEndpoint(id);

    const [statusChanging, success, changeStatus] = useChangeStatusAsyncEndpoint({
        remark: remark.value,
        status,
        userId: id,
    });

    useEffect(() => {
        setRemark('');
        getStatusHistory();
    }, [success, getStatusHistory]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col>
            <Row>
                <Col span={4}>
                    <span className="bold-text">{getTranslation('Username')}:</span>
                </Col>
                <Col>
                    <span className="bold-text">{userName}</span>
                </Col>
            </Row>
            <Row align="middle" className="status__select">
                <Col span={4}>
                    <span className="bold-text">{getTranslation('Status')}:</span>
                </Col>
                <Col span={20}>
                    <Select value={status} onChange={handleStatusChange} className="filter">
                        {_.map(PLAYER_STATUSES, (status) => (
                            <Select.Option key={status} value={status}>
                                {getPlayerStatus(status)}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
            </Row>
            <Row className="remarks-table__container">
                <StatusResultsTable loading={statusLoading} />
            </Row>
            <Row>
                <span className="add-remark__title">{getTranslation('Add new remark')}</span>
            </Row>
            <Row>
                <Form style={{ width: '100%' }}>
                    <Form.Item validateStatus={remark.validateStatus} help={remark.errorMsg}>
                        <Input.TextArea
                            value={remark.value}
                            onChange={handleRemarkChange}
                            rows="3"
                        />
                    </Form.Item>
                </Form>
            </Row>
            <Row className="action-buttons" justify="end" align="middle">
                <ButtonGreen
                    label={getTranslation('Confirm')}
                    onClick={confirmChangeStatus}
                    loading={statusChanging}
                    disabled={remark.validateStatus !== 'success'}
                />
                <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
            </Row>
        </Col>
    );
};

export default StatusModalContent;
