import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React from 'react';

import { Dashboard, Login } from '..';
import { NotFoundRoute, PrivateRoute } from '../../common/components';
import { ROUTES } from '../../common/constants/routing';
import useVersioning from '../../services/hooks/useVersioning';

const AppRoutes = () => {
    // ============================================
    // HOOKS
    // ============================================

    useVersioning();

    // ============================================
    // RENDER
    // ============================================
    return (
        <BrowserRouter>
            <Switch>
                <Route path={ROUTES.LOGIN} component={Login} />
                <PrivateRoute path={ROUTES.DASHBOARD.ROOT} component={Dashboard} />
                <NotFoundRoute />
            </Switch>
        </BrowserRouter>
    );
};

export default AppRoutes;
