import React from 'react';

import { Table } from 'antd';

import { getColumns } from './ProhibitionsTableMeta';
import { getStateProp } from '../../../../../common/helpers/misc';
import { useStore } from '../../../../../store/StateProvider';

const ProhibitionsResultsTable = ({ loading, columnTitle }) => {
    const [state] = useStore();
    const tableData = getStateProp(state, 'players.playersList.prohibitions.list', []);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            id="prohibitions-table"
            columns={getColumns(columnTitle)}
            dataSource={tableData}
            pagination={false}
            rowKey={(record) => record.id}
            loading={loading}
            size="small"
            align="left"
            bordered
        />
    );
};

export default ProhibitionsResultsTable;
