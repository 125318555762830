import React from 'react';

import { Table } from 'antd';

import './TransactionMonitoringResultsTable.less';
import { TRANSACTION_MONITORING_TABLE_COLUMNS } from '../constants';
import { getColumns, getPaginationOptions } from './TransactionMonitoringTableMeta';
import { getStateProp } from '../../../common/helpers/misc';
import { useStore } from '../../../store/StateProvider';

const TransactionMonitoringResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const paging = getStateProp(state, 'transactionMonitoring.paging', {});
    const tableData = getStateProp(state, 'transactionMonitoring.list', []);
    const selectedColumns = getStateProp(
        state,
        'transactionMonitoring.selectedColumns',
        TRANSACTION_MONITORING_TABLE_COLUMNS,
    );

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            className="transaction-monitoring-table"
            columns={getColumns(selectedColumns)}
            pagination={getPaginationOptions(paging, dispatch)}
            size="small"
            scroll={{ x: true }}
            align="left"
            bordered
            loading={loading}
            dataSource={tableData}
            rowKey={(record) => record.transactionNo}
        />
    );
};

export default TransactionMonitoringResultsTable;
