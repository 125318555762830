import React from 'react';

import { Row } from 'antd';

import { DomainFilter, MerchantFilter, MerchantIDFilter } from '../filters';

import {
    CurrencyFilter,
    MemberLevelFilter,
    OrderStatusFilter,
    PeriodFilter,
    TransactionNumberFilter,
    TransactionStatusFilter,
    UsernameFilter,
} from '../../../common/components/filters';
import { getStateProp } from '../../../common/helpers/misc';
import { merchantDepositsFilterSet } from './merchantActions';
import { useStore } from '../../../store/StateProvider';

const MerchantsFilters = () => {
    const [state, dispatch] = useStore();

    const merchantDeposits = getStateProp(state, 'payments.merchantDeposits');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => merchantDepositsFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row className="filters-container" justify="start" align="middle">
            <OrderStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(merchantDeposits, 'filters.orderStatuses')}
                name="orderStatuses"
            />
            <TransactionStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(merchantDeposits, 'filters.transactionStatuses')}
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(merchantDeposits, 'filters.dateFrom')}
                dateTo={getStateProp(merchantDeposits, 'filters.dateTo')}
                dateFromName="dateFrom"
                dateToName="dateTo"
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(merchantDeposits, 'filters.currencyCode')}
                name="currencyCode"
            />
            <MemberLevelFilter
                onFilterChange={setFilter}
                value={getStateProp(merchantDeposits, 'filters.memberLevel')}
            />
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(merchantDeposits, 'filters.playerUserName')}
                name="playerUserName"
            />
            <DomainFilter
                onFilterChange={setFilter}
                value={getStateProp(merchantDeposits, 'filters.merchantDomain')}
            />
            <MerchantFilter
                onFilterChange={setFilter}
                value={getStateProp(merchantDeposits, 'filters.gatewayName')}
            />
            <MerchantIDFilter
                onFilterChange={setFilter}
                value={getStateProp(merchantDeposits, 'filters.merchantId')}
            />
            <TransactionNumberFilter
                onFilterChange={setFilter}
                value={getStateProp(merchantDeposits, 'filters.transactionNo')}
                name="transactionNo"
            />
        </Row>
    );
};

export default MerchantsFilters;
