import React, { useEffect, useState } from 'react';

import { Checkbox, Col, Form, Input, Row, Select, message } from 'antd';
import _ from 'lodash';

import './AddUser.less';
import { ButtonGreen, ButtonTransparent } from '../../../../../common/components/buttons/Buttons';
import { CURRENCIES } from '../../../../../common/constants/localization';
import { getStateProp, isOperatorHasFullAccess } from '../../../../../common/helpers/misc';
import {
    getSupportedCountries,
    getSupportedCurrenciesByCountry,
    getTranslation,
} from '../../../../../common/helpers/locale';
import { useCreateUserOperationAsyncEndpoint } from '../../../../../services/adminService';
import { useStore } from '../../../../../store/StateProvider';

const AddUser = ({ onCancel }) => {
    const [form] = Form.useForm();
    const { resetFields, setFieldsValue } = form;
    const [state] = useStore();
    const { userAuthoritySetting } = getStateProp(state, 'administration');
    const refreshTable = getStateProp(userAuthoritySetting, 'fetchData', () => {});
    const [formValues, setFormValues] = useState({});
    const [allowAllCountries, setAllowAllCountries] = useState(isOperatorHasFullAccess());
    const operatorCurrency = _.uniq(
        _.map(
            _.concat(getSupportedCurrenciesByCountry(), {
                country: 'us',
                countryId: '',
                currency: 'USD',
            }),
            (currency) => currency.currency,
        ),
    );

    // ============================================
    // METHODS
    // ============================================

    const onSave = (values) => {
        setFormValues(values);
        if (values.password.length < 7) {
            message.error(getTranslation('Password cannot less than 7!'));
            return;
        }

        handleCreateUser();
    };

    const allowAllCountrySet = (e) => {
        if (e.target.checked) {
            setAllowAllCountries(true);
        } else {
            setAllowAllCountries(false);
        }

        setFieldsValue({
            allowCountry: [],
        });
    };

    // ============================================
    // HOOKS
    // ============================================

    const [userCreateLoading, response, handleCreateUser] = useCreateUserOperationAsyncEndpoint(
        formValues,
    );

    useEffect(() => {
        if (response) {
            resetFields();
            refreshTable();
            setAllowAllCountries(true);
        }
    }, [response, resetFields, refreshTable]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form
            form={form}
            name="add-user"
            className="add-user__form"
            onFinish={onSave}
            initialValues={{
                allowCurrency: _.map(operatorCurrency, (currency) => {
                    return currency;
                }),
            }}
        >
            <Row className="form__group">
                <Col span={4}>
                    <label>{getTranslation('Real Name')}</label>
                </Col>
                <Col span={20}>
                    <Form.Item
                        name="realName"
                        rules={[
                            {
                                message: getTranslation('This field is required!'),
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={4}>
                    <label>{getTranslation('Username')}</label>
                </Col>
                <Col span={20}>
                    <Form.Item
                        name="userName"
                        rules={[
                            {
                                message: getTranslation('This field is required!'),
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={4}>
                    <label>{getTranslation('Password')}</label>
                </Col>
                <Col span={20}>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                message: getTranslation('This field is required!'),
                                required: true,
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={4}>
                    <label>{getTranslation('Email')}</label>
                </Col>
                <Col span={20}>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                message: getTranslation('This field is required!'),
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <Row className="form__group">
                <Col span={4}>
                    <label>{getTranslation('Currency')}</label>
                </Col>
                <Col span={20}>
                    <Form.Item
                        name="allowCurrency"
                        rules={[
                            {
                                message: getTranslation('This field is required!'),
                                required: true,
                            },
                        ]}
                    >
                        <Checkbox.Group
                            style={{ display: 'inline-flex' }}
                            disabled={!isOperatorHasFullAccess()}
                        >
                            {_.map(CURRENCIES, (currency) => (
                                <div key={currency.currency}>
                                    <Checkbox value={currency.currency}>
                                        {currency.currency}
                                    </Checkbox>
                                </div>
                            ))}
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row className="form__group">
                <Col span={4}>
                    <label>{getTranslation('Country')}</label>
                </Col>
                <Col span={20}>
                    <Form.Item
                        name="allowCountry"
                        rules={[
                            {
                                message: getTranslation('This field is required!'),
                                required: !allowAllCountries,
                            },
                        ]}
                    >
                        <Select
                            placeholder={getTranslation('Country')}
                            mode="multiple"
                            allowClear={true}
                            showSearch
                            filterOption={(input, option) =>
                                option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={allowAllCountries}
                        >
                            {_.map(getSupportedCountries(), (country) => (
                                <Select.Option
                                    value={country.code}
                                    key={country.name}
                                    label={
                                        <Row
                                            align="middle"
                                            justify="space-between"
                                            style={{ height: '100%' }}
                                        >
                                            <span>{country.name}</span>
                                        </Row>
                                    }
                                >
                                    <span style={{ whiteSpace: 'normal' }}>{`${
                                        country.name
                                    } (${country.code.toLocaleUpperCase()})`}</span>
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row className="form__group">
                <Col span={4}></Col>
                <Col span={20}>
                    <Checkbox
                        onChange={allowAllCountrySet}
                        checked={allowAllCountries}
                        disabled={!isOperatorHasFullAccess()}
                    >
                        {getTranslation('Allow All Country')}
                    </Checkbox>
                </Col>
            </Row>
            <Row justify="end">
                <ButtonTransparent
                    label={getTranslation('Cancel')}
                    onClick={onCancel}
                    style={{ marginRight: '10px' }}
                />
                <ButtonGreen
                    label={getTranslation('Save')}
                    loading={userCreateLoading}
                    htmlType="submit"
                />
            </Row>
        </Form>
    );
};
export default AddUser;
