export const ACTIONS = {
    AFFILIATE_UPLINE_GET_FAILURE: 'AFFILIATE_UPLINE_INFO_GET_FAILURE',
    AFFILIATE_UPLINE_GET_SUCCESS: 'AFFILIATE_UPLINE_INFO_GET_SUCCESS',
    AFFILIATE_UPLINE_REMOVE: 'AFFILIATE_UPLINE_INFO_REMOVE',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    COLUMNS_SELECT: 'COLUMNS_SELECT',
    CREATE_MODIFICATION: 'CREATE_MODIFICATION',
    CREATE_MODIFICATION_FAILURE: 'CREATE_MODIFICATION_FAILURE',
    CREATE_MODIFICATION_SUCCESS: 'CREATE_MODIFICATION_SUCCESS',
    CREATE_PLAYER: 'CREATE_PLAYER',
    CREATE_PLAYER_FAILURE: 'CREATE_PLAYER_FAILURE',
    CREATE_PLAYER_SUCCESS: 'CREATE_PLAYER_SUCCESS',
    CREATE_PROHIBITION: 'CREATE_PROHIBITION',
    CREATE_PROHIBITION_FAILURE: 'CREATE_PROHIBITION_FAILURE',
    CREATE_PROHIBITION_SUCCESS: 'CREATE_PROHIBITION_SUCCESS',
    CREATE_REMARK: 'CREATE_REMARK',
    CREATE_REMARK_FAILURE: 'CREATE_REMARK_FAILURE',
    CREATE_REMARK_SUCCESS: 'CREATE_REMARK_SUCCESS',
    FILTERS_DROP_ALL: 'PLAYERS_FILTERS_DROP_ALL',
    FILTER_SET: 'PLAYERS_FILTER_SET',
    GAME_PLATFORM_GET: 'GAME_PLATFORM_GET',
    GAME_PLATFORM_GET_FAILURE: 'GAME_PLATFORM_GET_FAILURE',
    GAME_PLATFORM_GET_SUCCESS: 'GAME_PLATFORM_GET_SUCCESS',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'PLAYERS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'PLAYERS_TABLE_PAGE_SIZE_CHANGE',
    },
    PLAYERS_GAME_ACCESS_UPDATE: 'PLAYERS_GAME_ACCESS_UPDATE',
    PLAYERS_GAME_ACCESS_UPDATE_FAILURE: 'PLAYERS_GAME_ACCESS_UPDATE_FAILURE',
    PLAYERS_GAME_ACCESS_UPDATE_SUCCESS: 'PLAYERS_GAME_ACCESS_UPDATE_SUCCESS',
    PLAYERS_GET: 'PLAYERS_GET',
    PLAYERS_GET_FAILURE: 'PLAYERS_GET_FAILURE',
    PLAYERS_GET_SUCCESS: 'PLAYERS_GET_SUCCESS',
    PLAYERS_MODAL_CLOSE: 'PLAYERS_MODAL_CLOSE',
    PLAYERS_MODAL_OPEN: 'PLAYERS_MODAL_OPEN',
    PLAYER_DANGER_LEVEL_GET: 'PLAYER_DANGER_LEVEL_GET',
    PLAYER_DANGER_LEVEL_GET_FAILURE: 'PLAYER_DANGER_LEVEL_GET_FAILURE',
    PLAYER_DANGER_LEVEL_GET_SUCCESS: 'PLAYER_DANGER_LEVEL_GET_SUCCESS',
    PLAYER_GAME_ACCESS_GET: 'PLAYER_GAME_ACCESS_GET',
    PLAYER_GAME_ACCESS_GET_FAILURE: 'PLAYER_GAME_ACCESS_GET_FAILURE',
    PLAYER_GAME_ACCESS_GET_SUCCESS: 'PLAYER_GAME_ACCESS_GET_SUCCESS',
    PLAYER_MOBILE_VERIFY: 'PLAYER_MOBILE_VERIFY',
    PLAYER_MOBILE_VERIFY_FAILURE: 'PLAYER_MOBILE_VERIFY_FAILURE',
    PLAYER_MOBILE_VERIFY_SUCCESS: 'PLAYER_MOBILE_VERIFY_SUCCESS',
    PROHIBITIONS_GET: 'PROHIBITIONS_GET',
    PROHIBITIONS_GET_FAILURE: 'PROHIBITIONS_GET_FAILURE',
    PROHIBITIONS_GET_SUCCESS: 'PROHIBITIONS_GET_SUCCESS',
    REMARKS_GET: 'REMARKS_GET',
    REMARKS_GET_FAILURE: 'REMARKS_GET_FAILURE',
    REMARKS_GET_SUCCESS: 'REMARKS_GET_SUCCESS',
    REMARKS_PAGINATION: {
        TABLE_PAGE_CHANGE: 'REMARKS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'REMARKS_TABLE_PAGE_SIZE_CHANGE',
    },
    SABA_WALLET_BALANCE_GET: 'SABA_WALLET_BALANCE_GET',
    SABA_WALLET_BALANCE_GET_FAILURE: 'SABA_WALLET_BALANCE_GET_FAILURE',
    SABA_WALLET_BALANCE_GET_SUCCESS: 'SABA_WALLET_BALANCE_GET_SUCCESS',
    STATUS_CHANGE: 'STATUS_CHANGE',
    STATUS_CHANGE_FAILURE: 'STATUS_CHANGE_FAILURE',
    STATUS_CHANGE_SUCCESS: 'STATUS_CHANGE_SUCCESS',
    STATUS_HISTORY_GET: 'STATUS_HISTORY_GET',
    STATUS_HISTORY_GET_FAILURE: 'STATUS_HISTORY_GET_FAILURE',
    STATUS_HISTORY_GET_SUCCESS: 'STATUS_HISTORY_GET_SUCCESS',
    STATUS_PAGINATION: {
        TABLE_PAGE_CHANGE: 'STATUS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'STATUS_TABLE_PAGE_SIZE_CHANGE',
    },
    WALLET_BALANCE_GET: 'WALLET_BALANCE_GET',
    WALLET_BALANCE_GET_FAILURE: 'WALLET_BALANCE_GET_FAILURE',
    WALLET_BALANCE_GET_SUCCESS: 'WALLET_BALANCE_GET_SUCCESS',
    WALLET_TRANSFER: 'WALLET_TRANSFER',
    WALLET_TRANSFER_FAILURE: 'WALLET_TRANSFER_FAILURE',
    WALLET_TRANSFER_SUCCESS: 'WALLET_TRANSFER_SUCCESS',
};

export const changePassword = (dispatch) =>
    dispatch({
        type: ACTIONS.CHANGE_PASSWORD,
    });

export const changePasswordSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.CHANGE_PASSWORD_SUCCESS,
    });

export const changePasswordFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CHANGE_PASSWORD_FAILURE,
    });

export const createModification = (dispatch) =>
    dispatch({
        type: ACTIONS.CREATE_MODIFICATION,
    });

export const createModificationSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.CREATE_MODIFICATION_SUCCESS,
    });

export const createModificationFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CREATE_MODIFICATION_FAILURE,
    });

export const createPlayer = (dispatch) =>
    dispatch({
        type: ACTIONS.CREATE_PLAYER,
    });

export const createPlayerSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.CREATE_PLAYER_SUCCESS,
    });

export const createPlayerFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CREATE_PLAYER_FAILURE,
    });

export const createProhibition = (dispatch) =>
    dispatch({
        type: ACTIONS.CREATE_PROHIBITION,
    });

export const createProhibitionSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.CREATE_PROHIBITION_SUCCESS,
    });

export const createProhibitionFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CREATE_PROHIBITION_FAILURE,
    });

export const createRemark = (dispatch) =>
    dispatch({
        type: ACTIONS.CREATE_REMARK,
    });

export const createRemarkSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.CREATE_REMARK_SUCCESS,
    });

export const createRemarkFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CREATE_MODIFICATION_FAILURE,
    });

export const playersFilterSet = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });
};

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const changeRemarksPageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REMARKS_PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changeRemarksPageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REMARKS_PAGINATION.TABLE_PAGE_CHANGE,
    });

export const changeStatusPageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.STATUS_PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changeStatusPageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.STATUS_PAGINATION.TABLE_PAGE_CHANGE,
    });

export const openPlayersModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYERS_MODAL_OPEN,
    });

export const closePlayersModal = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYERS_MODAL_CLOSE,
    });

export const playersGet = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYERS_GET,
    });

export const playersGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYERS_GET_SUCCESS,
    });

export const playersGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYERS_GET_FAILURE,
    });

export const prohibitionsGet = (dispatch) =>
    dispatch({
        type: ACTIONS.PROHIBITIONS_GET,
    });

export const prohibitionsGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PROHIBITIONS_GET_SUCCESS,
    });

export const prohibitionsGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PROHIBITIONS_GET_FAILURE,
    });

export const remarksGet = (dispatch) =>
    dispatch({
        type: ACTIONS.REMARKS_GET,
    });

export const remarksGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REMARKS_GET_SUCCESS,
    });

export const remarksGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REMARKS_GET_FAILURE,
    });

export const statusHistoryGet = (dispatch) =>
    dispatch({
        type: ACTIONS.STATUS_HISTORY_GET,
    });

export const statusHistoryGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.STATUS_HISTORY_GET_SUCCESS,
    });

export const statusHistoryGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.STATUS_HISTORY_GET_FAILURE,
    });

export const changeStatus = (dispatch) =>
    dispatch({
        type: ACTIONS.STATUS_CHANGE,
    });

export const changeStatusSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.STATUS_CHANGE_SUCCESS,
    });

export const changeStatusFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.STATUS_CHANGE_FAILURE,
    });

export const sabaWalletBalanceGet = (dispatch) =>
    dispatch({
        type: ACTIONS.SABA_WALLET_BALANCE_GET,
    });

export const sabaWalletBalanceGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.SABA_WALLET_BALANCE_GET_SUCCESS,
    });

export const sabaWalletBalanceGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.SABA_WALLET_BALANCE_GET_FAILURE,
    });

export const walletBalanceGet = (dispatch) =>
    dispatch({
        type: ACTIONS.WALLET_BALANCE_GET,
    });

export const walletBalanceGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WALLET_BALANCE_GET_SUCCESS,
    });

export const walletBalanceGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WALLET_BALANCE_GET_FAILURE,
    });

export const walletTransfer = (dispatch) =>
    dispatch({
        type: ACTIONS.WALLET_BALANCE_GET,
    });

export const walletTransferSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WALLET_TRANSFER_SUCCESS,
    });

export const walletTransferFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WALLET_TRANSFER_FAILURE,
    });

export const selectColumns = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.COLUMNS_SELECT,
    });

//# region Affiliate
export const getAffiliateUplineInfoFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.AFFILIATE_UPLINE_GET_FAILURE,
    });

export const getAffiliateUplineInfoSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.AFFILIATE_UPLINE_GET_SUCCESS,
    });

export const removeAffiliateUplineInfo = (dispatch) =>
    dispatch({
        type: ACTIONS.AFFILIATE_UPLINE_REMOVE,
    });
//# endregion

//# region verify player mobile no
export const verifyPhone = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYER_MOBILE_VERIFY,
    });

export const verifyPhoneSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYER_MOBILE_VERIFY_SUCCESS,
    });

export const verifyPhoneFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_MOBILE_VERIFY_FAILURE,
    });
//# endregion

//# region get available game platform
export const gamePlatformGet = (dispatch) =>
    dispatch({
        type: ACTIONS.GAME_PLATFORM_GET,
    });

export const gamePlatformGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GAME_PLATFORM_GET_SUCCESS,
    });

export const gamePlatformGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GAME_PLATFORM_GET_FAILURE,
    });
//# endregion

//# region get available game platform
export const playersGameAccessUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYERS_GAME_ACCESS_UPDATE,
    });

export const playersGameAccessUpdateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYERS_GAME_ACCESS_UPDATE_SUCCESS,
    });

export const playersGameAccessUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYERS_GAME_ACCESS_UPDATE_FAILURE,
    });
//# endregion

//# region get player game platform
export const playerGameAccessGet = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYER_GAME_ACCESS_GET,
    });

export const playerGameAccessGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_GAME_ACCESS_GET_SUCCESS,
    });

export const playerGameAccessGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_GAME_ACCESS_GET_FAILURE,
    });
//# endregion

export const getPlayerDangerLevel = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYER_DANGER_LEVEL_GET,
    });

export const getPlayerDangerLevelFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_DANGER_LEVEL_GET_FAILURE,
    });

export const getPlayerDangerLevelSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_DANGER_LEVEL_GET_SUCCESS,
    });
