import { useCallback, useEffect, useState } from 'react';

import { message } from 'antd';

import { API } from '../common/constants/api';
import {
    OPERATION_TIME_LIMIT,
    PAYMENT_ACCOUNT_SETUP_TYPES,
    PAYMENT_ORDER_OPERATIONS,
} from '../common/constants/misc';
import { clearError } from '../common/actions/errorActions';
import {
    closeRejectModal,
    closeVerifyModal,
    performWithdrawalsOperation,
    withdrawalBankCardsGet,
    withdrawalBankCardsGetFailure,
    withdrawalBankCardsGetSuccess,
    withdrawalsGet,
    withdrawalsGetFailure,
    withdrawalsGetSuccess,
    withdrawalsOperationFailure,
    withdrawalsOperationSuccess,
} from '../components/payments/withdrawals/withdrawalsActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import { useDispatch, useStore } from '../store/StateProvider';
import { useNotification } from './hooks/useNotification';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, PAYMENTS } = API;

export function useGetWithdrawalsAsyncEndpoint(isOnline) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const withdrawals = getStateProp(state, 'payments.withdrawals');
    const { pageNo, pageSize, totalRecordCountNoFilter } = getStateProp(withdrawals, 'paging');
    const filters = getStateProp(withdrawals, 'filters', {});
    const refreshTime = getStateProp(withdrawals, 'operation.refreshTime');
    const refreshTimePause = getStateProp(withdrawals, 'operation.refreshTimePause');

    // ============================================
    // METHODS
    // ============================================

    const handleGetWithdrawals = useCallback(() => {
        withdrawalsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
                verified: true,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.WITHDRAWALS.ROOT}`,
    });

    useEffect(() => {
        let timeInterval;
        if (response || isOnline)
            timeInterval = setTimeout(() => handleGetWithdrawals(), refreshTime * 1000);
        if (refreshTimePause) clearTimeout(timeInterval);
        return () => clearTimeout(timeInterval);
    }, [handleGetWithdrawals, isOnline, refreshTime, refreshTimePause, response]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);

            withdrawalsGetSuccess(dispatch, {
                dataTotal: response.data.data[0].dataTotal,
                fetchData: handleGetWithdrawals,
                grandTotal: response.data.data[0].grandTotal,
                list: response.data.data[0].data,
                pageDataTotal: response.data.data[0].pageDataTotal,
                pageTotal: response.data.data[0].pageTotal,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetWithdrawals, response]);

    useEffect(() => {
        handleGetWithdrawals();
    }, [handleGetWithdrawals, pageNo, pageSize]);

    useNotification(
        totalRecordCountNoFilter,
        getStateProp(response, 'data.paging.totalRecordCountNoFilter', 0),
        'You have new withdrawal record',
    );

    const errorMsg = useError(error, withdrawalsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetWithdrawals];
}

export function useWithdrawalOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();

    const withdrawals = getStateProp(state, 'payments.withdrawals');
    const refreshTable = getStateProp(withdrawals, 'fetchData', () => {});
    const operation = getStateProp(withdrawals, 'operation');
    const rejectInfo = getStateProp(withdrawals, 'rejectInfo');
    const verifyInfo = getStateProp(withdrawals, 'verifyInfo');

    const transactionId = getStateProp(operation, 'transaction.transactionId');
    const action = getStateProp(operation, 'action');

    const rejectedMessageType = getStateProp(rejectInfo, 'rejectedMessageType');
    const rejectRemarks = getStateProp(rejectInfo, 'remarks');

    const withdrawalFee = getStateProp(verifyInfo, 'withdrawalFee');
    const assignedAccountNo = getStateProp(verifyInfo, 'selectedCard.bankCardNo');
    const assignedBankName = getStateProp(verifyInfo, 'selectedCard.bankName');
    const assignedAccountName = getStateProp(verifyInfo, 'selectedCard.cardHolderName');
    const verifyRemarks = getStateProp(verifyInfo, 'remarks');

    const [timeOutId, setTimeOutId] = useState(null);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!action,
        method: 'POST',
        options: {
            data: {
                assignedAccountName,
                assignedAccountNo,
                assignedBankName,
                isFirstLvl: false,
                rejectedMessageType,
                remarks: action === PAYMENT_ORDER_OPERATIONS.REJECT ? rejectRemarks : verifyRemarks,
                withdrawalFee,
            },
        },
        trigger: action,
        url: `${VERSION.V1}${PAYMENTS.WITHDRAWALS.ROOT}/${transactionId}/${action}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            withdrawalsOperationSuccess(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    useEffect(() => {
        if (response) {
            if (action === PAYMENT_ORDER_OPERATIONS.COMPLETE) {
                message.success(getTranslation('Transaction verified!'));
            } else if (action === PAYMENT_ORDER_OPERATIONS.REJECT) {
                message.success(getTranslation('Transaction rejected!'));
            }
        }
    }, [response, action]);

    useEffect(() => {
        if (response && action) {
            clearTimeout(timeOutId);
            if (action === PAYMENT_ORDER_OPERATIONS.ATTEND) {
                setTimeOutId(
                    setTimeout(() => {
                        performWithdrawalsOperation(dispatch, {
                            action: PAYMENT_ORDER_OPERATIONS.RELEASE,
                        });
                        closeVerifyModal(dispatch);
                        closeRejectModal(dispatch);
                    }, OPERATION_TIME_LIMIT),
                );
            }
        }
    }, [response, action, dispatch, timeOutId]);

    const errorMsg = useError(error, withdrawalsOperationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response];
}

export function useGetBankCardsAsyncEndpoint() {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetBankCards = useCallback(() => {
        withdrawalBankCardsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                activatedStatus: 'Activated',
                type: PAYMENT_ACCOUNT_SETUP_TYPES.WITHDRAWAL,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.BANK_ACCOUNT.CARD_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            withdrawalBankCardsGetSuccess(dispatch, {
                list: response.data.data,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        handleGetBankCards();
    }, [handleGetBankCards]);

    const errorMsg = useError(error, withdrawalBankCardsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetBankCards];
}
