import React from 'react';

import { Col, Form, Input, Row } from 'antd';

import { Editor } from '../../../common/components';
import { getTranslation } from '../../../common/helpers/locale';
import { isSupportedLanguage } from '../../../common/helpers/misc';

const NewsContentEditor = ({ content, onContentChange, langCode }) => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <Col>
            <Row className="news__form-row">
                <Form.Item>
                    <Input
                        className="news__card__title"
                        placeholder={getTranslation('Add News Title')}
                        value={content.title}
                        onChange={(e) => onContentChange('title', e.target.value, langCode)}
                        disabled={!isSupportedLanguage(langCode)}
                    />
                </Form.Item>
            </Row>

            <Row className="news__form-row">
                <Form.Item>
                    <Editor
                        onBlur={(event) =>
                            onContentChange('content', event.target.getContent(), langCode)
                        }
                        value={content.content}
                        disabled={!isSupportedLanguage(langCode)}
                    />
                </Form.Item>
            </Row>
        </Col>
    );
};

export default NewsContentEditor;
