import { ACTIONS } from './cryptoWithdrawalRecordActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS_1000,
} from '../../../../common/constants/api';

export default function cryptoWithdrawalRecordReducer(
    cryptoWithdrawalRecord = {
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        remarkModalVisible: false,
        resetPageNo: false,
        verifyModalVisible: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.VERIFY_MODAL_OPEN:
            return {
                ...cryptoWithdrawalRecord,
                transaction: payload,
                verifyModalVisible: true,
            };
        case ACTIONS.VERIFY_MODAL_CLOSE:
            return {
                ...cryptoWithdrawalRecord,
                verifyModalVisible: false,
            };
        case ACTIONS.REMARK_MODAL_OPEN:
            return {
                ...cryptoWithdrawalRecord,
                remarkModalVisible: true,
                remarks: payload,
            };
        case ACTIONS.REMARK_MODAL_CLOSE:
            return { ...cryptoWithdrawalRecord, remark: null, remarkModalVisible: false };
        case ACTIONS.FILTER_SET:
            return {
                ...cryptoWithdrawalRecord,
                filters: {
                    ...cryptoWithdrawalRecord.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };
        case ACTIONS.FILTER_DROP:
            return {
                ...cryptoWithdrawalRecord,
                filters: null,
                resetPageNo: true,
            };
        case ACTIONS.VERIFY_INFO_SET:
            return {
                ...cryptoWithdrawalRecord,
                verifyInfo: {
                    ...cryptoWithdrawalRecord.verifyInfo,
                    [payload.name]: payload.value,
                },
            };
        case ACTIONS.WITHDRAWAL_RECORDS_GET_SUCCESS:
            return {
                ...cryptoWithdrawalRecord,
                ...payload,
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...cryptoWithdrawalRecord,
                paging: {
                    ...cryptoWithdrawalRecord.paging,
                    pageNo:
                        payload > cryptoWithdrawalRecord.paging.pageCount
                            ? cryptoWithdrawalRecord.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS_1000[TABLE_PAGE_SIZE_OPTIONS_1000.length - 1],
            );
            return {
                ...cryptoWithdrawalRecord,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };
        case ACTIONS.WITHDRAWAL_RECORDS_OPERATION:
            return {
                ...cryptoWithdrawalRecord,
                operation: {
                    ...cryptoWithdrawalRecord.operation,
                    ...payload,
                },
            };
        case ACTIONS.WITHDRAWAL_RECORDS_OPERATION_SUCCESS:
        case ACTIONS.WITHDRAWAL_RECORDS_OPERATION_FAILURE:
            return {
                ...cryptoWithdrawalRecord,
                operation: null,
            };
        case ACTIONS.WITHDRAWAL_RECORDS_GET:
            const pageNo = cryptoWithdrawalRecord.resetPageNo
                ? TABLE_PAGE_NO_DEFAULT
                : cryptoWithdrawalRecord.paging.pageNo;
            return {
                ...cryptoWithdrawalRecord,
                paging: {
                    ...cryptoWithdrawalRecord.paging,
                    pageNo: pageNo,
                },
                resetPageNo: false,
            };

        default:
            return cryptoWithdrawalRecord;
    }
}
