import React from 'react';

import { Card, Divider, Form } from 'antd';

import { API } from '../../../common/constants/api';
import { dropFilters, onRemarkModalClose } from './transactionRecordActions';
import { getColumns, transactionRecordsExport } from './table/TransactionRecordTableMeta';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useExportAsyncEndpoint } from '../../../services/hooks/useExport';
import { useGetTransactionRecordsAsyncEndpoint } from '../../../services/transactionRecordService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import RemarkModal from '../../../common/components/RemarkModal';
import TotalBalance from '../../../common/components/TotalBalance';
import TransactionRecordFilters from './TransactionRecordFilters';
import TransactionRecordResultsTable from './table/TransactionRecordResultsTable';

const { TRANSACTION_HISTORY } = API;

const TransactionRecords = () => {
    const [state, dispatch] = useStore();
    const { transactionRecords } = getStateProp(state, 'transactionHistory');
    const remarkModalVisible = getStateProp(transactionRecords, 'remarkModalVisible');
    const { applicantRemark, operatorRemark, auditorRemark } = getStateProp(
        transactionRecords,
        'remarks',
        {},
    );
    const filters = getStateProp(transactionRecords, 'filters');
    const { totalRecordCount } = getStateProp(transactionRecords, 'paging', {});
    const pageBalance = getStateProp(transactionRecords, 'pageDataTotal', []);
    const totalBalance = getStateProp(transactionRecords, 'dataTotal', []);
    const grandTotal = getStateProp(transactionRecords, 'grandTotal', 0);
    const pageTotal = getStateProp(transactionRecords, 'pageTotal', 0);

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetTransactionRecords();
    };

    const closeRemarkModal = () => onRemarkModalClose(dispatch);

    // ============================================
    // HOOKS
    // ============================================
    const [
        transactionRecordsLoading,
        handleGetTransactionRecords,
    ] = useGetTransactionRecordsAsyncEndpoint();

    const [handleExportAction] = useExportAsyncEndpoint({
        additionalCol: transactionRecordsExport,
        dataMeta: getColumns(),
        filters,
        innerListKey: 'data',
        method: 'GET',
        subject: getTranslation('Transaction Records'),
        totalRecordCount,
        url: `${TRANSACTION_HISTORY.TRANSACTION_RECORDS}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Transaction Records')}>
            <Form onFinish={handleGetTransactionRecords}>
                <TransactionRecordFilters />
                <ActionButtons onDropFilters={fetchAll} onExport={handleExportAction} />
            </Form>

            <TransactionRecordResultsTable loading={transactionRecordsLoading} />

            <Divider />

            <TotalBalance
                pageBalance={pageBalance}
                totalBalance={totalBalance}
                grandTotal={grandTotal}
                pageTotal={pageTotal}
            />

            <RemarkModal
                visible={remarkModalVisible}
                onCancel={closeRemarkModal}
                applicantRemark={applicantRemark}
                operatorRemark={operatorRemark}
                auditorRemark={auditorRemark}
            />
        </Card>
    );
};

export default TransactionRecords;
