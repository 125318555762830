import React from 'react';

import { Form, message } from 'antd';

import { API } from '../../../../common/constants/api';
import { PAYMENT_ORDER_OPERATIONS } from '../../../../common/constants/misc';
import {
    closeConfirmRejectModal,
    cryptoDepositCloseVerifyModal,
    cryptoDepositOperation,
    dropFilters,
    openConfirmRejectModal,
} from './cryptoDepositActions';
import {
    cryptocurrencyDepositRecordsExport,
    getColumns,
} from './table/CryptocurrencyDepositsTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import {
    useCryptoDepositOperationAsyncEndpoint,
    useGetCryptoDepositsAsyncEndpoint,
} from '../../../../services/cryptoCurrencyService';
import { useExportAsyncEndpoint } from '../../../../services/hooks/useExport';
import { useStore } from '../../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import ConfirmRejectModal from './verification/ConfirmRejectModal';
import CryptocurrencyDepositFilters from './CryptocurrencyDepositFilters';
import CryptocurrencyDepositResultsTable from './table/CryptocurrencyDepositResultsTable';
import VerifyModal from './verification/VerifyModal';
import useInternetStatus from '../../../../services/hooks/useInternetStatus';

const { PAYMENTS } = API;

const CryptocurrencyDeposit = () => {
    const [state, dispatch] = useStore();

    const cryptoDeposits = getStateProp(state, 'payments.cryptoDeposits', {});
    const transaction = getStateProp(cryptoDeposits, 'transaction', {});
    const verifyModalVisible = getStateProp(cryptoDeposits, 'verifyModalVisible');
    const confirmRejectModalVisible = getStateProp(cryptoDeposits, 'confirmRejectModalVisible');
    const selectedTransactionId = getStateProp(cryptoDeposits, 'selectedTransactionId', {});
    const filters = getStateProp(cryptoDeposits, 'filters');
    const { totalRecordCount } = getStateProp(cryptoDeposits, 'paging', {});

    // ============================================
    // METHODS
    // ============================================

    const closeOperationModal = () => {
        cryptoDepositOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.RELEASE,
            transaction,
        });
        cryptoDepositCloseVerifyModal(dispatch);
    };

    const rejectDeposits = () => {
        selectedTransactionId.length >= 1
            ? openConfirmRejectModal(dispatch)
            : message.warning(getTranslation('Please select some rows'));
    };

    const onCloseConfirmRejectModal = () => closeConfirmRejectModal(dispatch);

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetCryptoDeposits();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [isOnline] = useInternetStatus();
    const [cryptoDepositLoading, handleGetCryptoDeposits] = useGetCryptoDepositsAsyncEndpoint(
        isOnline,
    );
    const [operationPending] = useCryptoDepositOperationAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        additionalCol: cryptocurrencyDepositRecordsExport,
        dataMeta: getColumns(),
        filters,
        method: 'GET',
        subject: getTranslation('Cryptocurrency Deposit'),
        totalRecordCount,
        url: `${PAYMENTS.CRYPTO_DEPOSITS.ROOT}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <div>
            <Form onFinish={handleGetCryptoDeposits}>
                <CryptocurrencyDepositFilters />
                <ActionButtons
                    onReject={rejectDeposits}
                    onDropFilters={fetchAll}
                    onExport={handleExportAction}
                />
            </Form>

            <CryptocurrencyDepositResultsTable loading={cryptoDepositLoading} />
            <VerifyModal
                visible={verifyModalVisible}
                transaction={transaction}
                onCancel={closeOperationModal}
                operationPending={operationPending}
            />
            <ConfirmRejectModal
                visible={confirmRejectModalVisible}
                onCancel={onCloseConfirmRejectModal}
                selectedTransactionId={selectedTransactionId}
            />
        </div>
    );
};

export default CryptocurrencyDeposit;
