import React, { useMemo } from 'react';

import { Table } from 'antd';
import { useVT } from 'virtualizedtableforantd4';

import './NormalManualManagementTable.less';
import { getColumns, getPaginationOptions, tableBodyWrapper } from './NormalManualManagementMeta';
import { getStateProp } from '../../../../../common/helpers/misc';
import { useStore } from '../../../../../store/StateProvider';

const NormalManualManagementTable = ({ loading, currency }) => {
    const [state, dispatch] = useStore();

    const { manualManagement } = getStateProp(state, 'bonus');
    const tableData = getStateProp(manualManagement, 'list', []);
    const totalBonusAmount = getStateProp(manualManagement, 'totalBonusAmount', 0);
    const totalDepositAmount = getStateProp(manualManagement, 'totalDepositAmount', 0);
    const paging = getStateProp(manualManagement, 'paging', {});

    // ============================================
    // HOOKS
    // ============================================

    const [vt, setVT] = useVT(() => ({ scroll: { y: 800 } }), []);

    useMemo(
        () =>
            setVT({
                body: {
                    wrapper: (props) =>
                        tableBodyWrapper(props, totalBonusAmount, totalDepositAmount, currency),
                },
            }),
        [currency, setVT, totalBonusAmount, totalDepositAmount],
    );

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns(dispatch)}
            rowKey={(record) => record.id}
            size="small"
            align="left"
            scroll={{ x: 3700, y: 800 }}
            pagination={getPaginationOptions(paging, dispatch)}
            bordered
            dataSource={tableData}
            loading={loading}
            components={vt}
            className="manual-bonus__table"
        />
    );
};

export default NormalManualManagementTable;
