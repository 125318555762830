import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './ReferralInquiryMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const ReferralInquiryTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const referralInquiry = getStateProp(state, 'referral.referralInquiry');
    const paging = getStateProp(referralInquiry, 'paging', {});
    const tableData = getStateProp(referralInquiry, 'list', []);

    return (
        <Table
            id="referral-inquiry-table"
            columns={getColumns()}
            size="small"
            scroll={{ x: true }}
            align="left"
            dataSource={tableData}
            rowKey={(record) => record.userId}
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
            bordered
        />
    );
};

export default ReferralInquiryTable;
