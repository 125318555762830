import { ACTIONS } from '../actions/timezoneActions';
import { TIMEZONE } from '../constants/localization';

export default function timezoneReducer(
    timezone = {
        location: TIMEZONE.BANGKOK.LOCATION,
        offset: TIMEZONE.BANGKOK.OFFSET,
    },
    { type, payload },
) {
    switch (type) {
        case ACTIONS.CURRENT_TIMEZONE_SET:
            return {
                ...payload,
            };
        default:
            return timezone;
    }
}
