import React from 'react';

import { Row } from 'antd';

import {
    ButtonGray,
    ButtonGreen,
    ButtonRed,
} from '../../../../../common/components/buttons/Buttons';
import {
    PAYMENT_ORDER_OPERATIONS,
    TRANSACTION_STATUSES,
} from '../../../../../common/constants/misc';
import { VERIFY_MODAL_TYPES } from '../constants';
import {
    cryptoWithdrawalOperation,
    openConfirmRejectModal,
    openVerifyModal,
} from '../cryptoWithdrawalActions';
import { getCurrentUser } from '../../../../../common/helpers/api';
import { getStateProp } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useStore } from '../../../../../store/StateProvider';

const VerifyButton = ({ transaction, dispatch }) => {
    const [state] = useStore();
    const {
        attendantUserName,
        attendantRealName,
        transactionStatus,
        verifierRealName,
        withdrawStatus,
    } = transaction;
    const disabled = [TRANSACTION_STATUSES.REJECTED, TRANSACTION_STATUSES.SUCCESS].includes(
        transactionStatus,
    );

    const rejectedFromGGPay = [
        TRANSACTION_STATUSES.REJECTED,
        TRANSACTION_STATUSES.FAILURE,
    ].includes(withdrawStatus);

    const operatorName = getStateProp(
        state,
        'user.userName',
        getStateProp(getCurrentUser(), 'claims.userName', 'Anonymus'), //Temp fix
    );
    // ============================================
    // METHODS
    // ============================================

    const getLabel = () => {
        switch (transactionStatus) {
            case TRANSACTION_STATUSES.ATTENDING:
                return `${getTranslation('Reviewing')} ${attendantRealName}`;
            case TRANSACTION_STATUSES.REJECTED:
            case TRANSACTION_STATUSES.SUCCESS:
                return `${getTranslation('Verified')} ${verifierRealName}`;
            default:
                getTranslation('Verify');
        }
    };
    const openOperationModal = (modalType) => {
        cryptoWithdrawalOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.ATTEND,
            transaction,
        });

        modalType === VERIFY_MODAL_TYPES.VERIFY
            ? openVerifyModal(dispatch, transaction)
            : openConfirmRejectModal(dispatch);
    };

    // ============================================
    // RENDER
    // ============================================

    return (attendantUserName !== operatorName &&
        transactionStatus === TRANSACTION_STATUSES.ATTENDING) ||
        disabled ||
        rejectedFromGGPay ? (
        <ButtonGray disabled label={getLabel()} className="action-btn__verify" />
    ) : (
        <Row justify="space-between">
            <ButtonGreen
                label={getTranslation('Verify')}
                className="action-btn__verify action-btn"
                onClick={() => openOperationModal(VERIFY_MODAL_TYPES.VERIFY)}
            />
            <ButtonRed
                className="action-btn__verify action-btn"
                label={getTranslation('Reject')}
                onClick={() => openOperationModal(VERIFY_MODAL_TYPES.REJECT)}
            />
        </Row>
    );
};

export default VerifyButton;
