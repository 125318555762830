export const ACTIONS = {
    CONFIRM_MODAL_CLOSE: 'CRYPTO_WITHDRAWAL_CONFIRM_MODAL_CLOSE',
    CONFIRM_MODAL_OPEN: 'CRYPTO_WITHDRAWAL_CONFIRM_MODAL_OPEN',
    CONFIRM_REJECT_MODAL_CLOSE: 'CRYPTO_WITHDRAWAL_CONFIRM_REJECT_MODAL_CLOSE',
    CONFIRM_REJECT_MODAL_OPEN: 'CRYPTO_WITHDRAWAL_CONFIRM_REJECT_MODAL_OPEN',
    CRYPTO_WITHDRAWALS_GET: 'CRYPTO_WITHDRAWALS_GET',
    CRYPTO_WITHDRAWALS_GET_FAILURE: 'CRYPTO_WITHDRAWALS_GET_FAILURE',
    CRYPTO_WITHDRAWALS_GET_SUCCESS: 'CRYPTO_WITHDRAWALS_GET_SUCCESS',
    CRYPTO_WITHDRAWAL_OPERATION: 'CRYPTO_WITHDRAWAL_OPERATION',
    CRYPTO_WITHDRAWAL_OPERATION_FAILURE: 'CRYPTO_WITHDRAWAL_OPERATION_FAILURE',
    CRYPTO_WITHDRAWAL_OPERATION_SUCCESS: 'CRYPTO_WITHDRAWAL_OPERATION_SUCCESS',
    FILTERS_DROP_ALL: 'CRYPTO_WITHDRAWAL_FILTERS_DROP_ALL',
    FILTER_SET: 'CRYPTO_WITHDRAWAL_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'DEPOSITS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'DEPOSITS_TABLE_PAGE_SIZE_CHANGE',
    },
    REFRESH_TIME_PAUSE: 'CRYPTO_WITHDRAWAL_REFRESH_TABLE_TIME_PAUSE',
    REFRESH_TIME_SET: 'CRYPTO_WITHDRAWAL_REFRESH_TABLE_TIME_SET',
    REJECT_INFO_SET: 'CRYPTO_WITHDRAWAL_REJECT_INFO_SET',
    VERIFY_MODAL_CLOSE: 'CRYPTO_WITHDRAWAL_VERIFY_MODAL_CLOSE',
    VERIFY_MODAL_OPEN: 'CRYPTO_WITHDRAWAL_VERIFY_MODAL_OPEN',
};

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const withdrawalsFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const openVerifyModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.VERIFY_MODAL_OPEN,
    });

export const cryptoWithdrawalCloseVerifyModal = (dispatch) =>
    dispatch({
        type: ACTIONS.VERIFY_MODAL_CLOSE,
    });

export const openConfirmModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_MODAL_OPEN,
    });

export const cryptoWithdrawalCloseConfirmModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_MODAL_CLOSE,
    });

export const openConfirmRejectModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_REJECT_MODAL_OPEN,
    });

export const closeConfirmRejectModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_REJECT_MODAL_CLOSE,
    });

export const cryptoWithdrawalsGet = (dispatch) =>
    dispatch({
        type: ACTIONS.CRYPTO_WITHDRAWALS_GET,
    });

export const cryptoWithdrawalsGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_WITHDRAWALS_GET_SUCCESS,
    });

export const cryptoWithdrawalsGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_WITHDRAWALS_GET_FAILURE,
    });

export const cryptoWithdrawalOperation = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_WITHDRAWAL_OPERATION,
    });

export const cryptoWithdrawalOperationSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.CRYPTO_WITHDRAWAL_OPERATION_SUCCESS,
    });

export const cryptoWithdrawalOperationFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_WITHDRAWAL_OPERATION_FAILURE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const withdrawalsRejectInfoSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REJECT_INFO_SET,
    });

export const setCryptoWithdrawRefreshTableTime = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.REFRESH_TIME_SET,
    });
};

export const pauseCryptoWithdrawRefreshTableTime = (dispatch) => {
    dispatch({
        type: ACTIONS.REFRESH_TIME_PAUSE,
    });
};
