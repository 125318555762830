import React from 'react';

import { Row } from 'antd';

import {
    AffiliateFilter,
    EwalletNameFilter,
    ReferralFilter,
    WithdrawalsAmountFromFilter,
    WithdrawalsAmountToFilter,
} from '../filters';
import {
    CurrencyFilter,
    DangerLevelFilter,
    MemberLevelFilter,
    OrderStatusFilter,
    PeriodFilter,
    TransactionNumberFilter,
    TransactionStatusFilter,
    UsernameFilter,
} from '../../../common/components/filters';
import { ewalletWithdrawalsFilterSet } from './ewalletWithdrawalsActions';
import { getStateProp } from '../../../common/helpers/misc';
import { useStore } from '../../../store/StateProvider';

const WithdrawalFilters = () => {
    const [state, dispatch] = useStore();

    const withdrawals = getStateProp(state, 'payments.withdrawals');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => ewalletWithdrawalsFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row align="middle" className="filters-container" justify="start">
            <OrderStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(withdrawals, 'filters.orderStatuses')}
                name="orderStatuses"
            />
            <TransactionStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(withdrawals, 'filters.transactionStatuses')}
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(withdrawals, 'filters.dateFrom')}
                dateTo={getStateProp(withdrawals, 'filters.dateTo')}
                dateFromName="dateFrom"
                dateToName="dateTo"
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(withdrawals, 'filters.currencyCode')}
                name="currencyCode"
            />
            <MemberLevelFilter
                onFilterChange={setFilter}
                value={getStateProp(withdrawals, 'filters.memberLevel')}
            />
            <TransactionNumberFilter
                onFilterChange={setFilter}
                value={getStateProp(withdrawals, 'filters.transactionNo')}
                name="transactionNo"
            />
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(withdrawals, 'filters.playerUserName')}
                name="playerUserName"
            />
            <WithdrawalsAmountFromFilter
                onFilterChange={setFilter}
                value={getStateProp(withdrawals, 'filters.amountFrom')}
            />
            <WithdrawalsAmountToFilter
                onFilterChange={setFilter}
                value={getStateProp(withdrawals, 'filters.amountTo')}
            />
            <AffiliateFilter
                onFilterChange={setFilter}
                value={getStateProp(withdrawals, 'filters.affiliateUserName')}
            />
            <ReferralFilter
                onFilterChange={setFilter}
                value={getStateProp(withdrawals, 'filters.referralUserName')}
            />
            <EwalletNameFilter
                value={getStateProp(state, 'payments.ewalletConfiguration.filters.eWalletName')}
                onFilterChange={setFilter}
            />
            <DangerLevelFilter
                onFilterChange={setFilter}
                value={getStateProp(withdrawals, 'filters.dangerLevel')}
                name="dangerLevel"
                // dangerLevelFilterList={getStateProp(withdrawals, 'dangerLevels', [])}
            />
        </Row>
    );
};

export default WithdrawalFilters;
