import affiliateRecordsReducer from './affiliate-records/affiliateRecordsReducer';
import bonusRecordsReducer from './bonus-records/bonusRecordsReducer';
import cryptocurrencyRecordsReducer from './cryptocurrency-records/cryptocurrencyRecordsReducer';
import depositRecordsReducer from './deposit-records/depositRecordsReducer';
import rebateRecordsReducer from './rebate-records/rebateRecordsReducer';
import referralRecordsReducer from './referral-records/referralRecordsReducer';
import rewardsRecordsReducer from './rewards-records/rewardsRecordsReducer';
import transactionRecordsReducer from './transaction-records/transactionRecordsReducer';
import turnoverRecordsReducer from './turnover-records/turnoverRecordsReducer';
import vipRecordsReducer from './vip-records/vipRecordsReducer';
import walletTransfersReducer from './wallet-transfers/walletTransfersReducer';
import withdrawalRecordsReducer from './withdrawal-records/withdrawalRecordsReducer';

export default function transactionHistoryReducer(transactionHistory = {}, action) {
    const {
        transactionRecords,
        depositRecords,
        withdrawalRecords,
        walletTransfers,
        cryptocurrencyRecords,
        bonusRecords,
        affiliateRecords,
        referralRecords,
        rewardsRecords,
        vipRecords,
        rebateRecords,
        turnoverRecords,
    } = transactionHistory;

    return {
        affiliateRecords: affiliateRecordsReducer(affiliateRecords, action),
        bonusRecords: bonusRecordsReducer(bonusRecords, action),
        cryptocurrencyRecords: cryptocurrencyRecordsReducer(cryptocurrencyRecords, action),
        depositRecords: depositRecordsReducer(depositRecords, action),
        rebateRecords: rebateRecordsReducer(rebateRecords, action),
        referralRecords: referralRecordsReducer(referralRecords, action),
        rewardsRecords: rewardsRecordsReducer(rewardsRecords, action),
        transactionRecords: transactionRecordsReducer(transactionRecords, action),
        vipRecords: vipRecordsReducer(vipRecords, action),
        walletTransfers: walletTransfersReducer(walletTransfers, action),
        withdrawalRecords: withdrawalRecordsReducer(withdrawalRecords, action),
        turnoverRecords: turnoverRecordsReducer(turnoverRecords, action),
    };
}
