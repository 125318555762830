import React from 'react';

import { ButtonGray, ButtonGreen } from '../../../../../common/components/buttons/Buttons';
import { Col, Row } from 'antd';
import { getStateProp } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import {
    useGetActiveBonusCategoryAsyncEndpoint,
    useSortBonusCategoryAsycnEndpoint,
} from '../../../../../services/bonusCategoryService';
import { useStore } from '../../../../../store/StateProvider';
import SortingTable from './SortingTable';

const SortingForm = ({ onCancel }) => {
    const [state, dispatch] = useStore();
    const { bonusCategory } = getStateProp(state, 'bonus');

    const tableData = getStateProp(bonusCategory, 'bonusCategoryModal.categoryAttending', []);

    // ============================================
    // HOOKS
    // ============================================

    const [updating, handleUpdateCategorySorting] = useSortBonusCategoryAsycnEndpoint();
    const [loading] = useGetActiveBonusCategoryAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col>
            <SortingTable tableData={tableData} dispatch={dispatch} loading={loading} />

            <Row justify="end" className="category-form__btn-row">
                <ButtonGreen
                    label={getTranslation('Submit')}
                    onClick={handleUpdateCategorySorting}
                    loading={updating}
                />
                <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
            </Row>
        </Col>
    );
};

export default SortingForm;
