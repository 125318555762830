import React from 'react';

import { Input } from 'antd';

import { getTranslation } from '../../helpers/locale';

const BonusNameFilter = ({ value, onFilterChange }) => {
    return (
        <Input
            onChange={(e) => onFilterChange('bonusName', e.target.value)}
            value={value}
            className="filter"
            placeholder={getTranslation('Bonus Name')}
        />
    );
};

export default BonusNameFilter;
