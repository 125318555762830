import React from 'react';

import { Modal, Row } from 'antd';
import { getTranslation } from '../../../common/helpers/locale';

const TotalRecordGenratedModal = ({ visible, onCancel, totalRecordGenerated }) => {
    return (
        <Modal centered visible={visible} closable={true} onCancel={onCancel} footer={null}>
            <Row>
                <span>
                    {getTranslation('record(s) has been generated', { totalRecordGenerated })}
                </span>
            </Row>
        </Modal>
    );
};

export default TotalRecordGenratedModal;
