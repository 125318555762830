import React from 'react';

import _ from 'lodash';

import { LANGUAGES } from '../../../../common/constants/localization';
import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../bonusCategoryActions';
import { findTitleByLanguage, getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';
import OperationsButton from './operations/OperationsButton';

export const getColumns = (langCode, dispatch) => {
    const columns = [
        {
            dataIndex: 'categoryNames',
            filterMultiple: false,
            filters: _.map(LANGUAGES, (language) => {
                return {
                    text: language.name,
                    value: language.value,
                };
            }),
            render: (value, record) => {
                return findTitleByLanguage(record.categoryNames, langCode, 'name');
            },
            title: getTranslation('Bonus Category'),
        },
        {
            dataIndex: 'sortingOrder',
            title: getTranslation('Sorting In App'),
        },
        {
            dataIndex: 'createdAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Created At'),
        },
        {
            dataIndex: 'createdBy',
            title: getTranslation('Created By'),
        },
        {
            dataIndex: 'updatedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Last Modified At'),
        },
        {
            dataIndex: 'updatedBy',
            title: getTranslation('Last Modified By'),
        },
        {
            dataIndex: 'action',
            render: (value, record) => (
                <OperationsButton dispatch={dispatch} bonusCategory={record} />
            ),
            title: getTranslation('Action'),
            width: 300,
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
