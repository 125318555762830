import React, { useEffect, useState } from 'react';

import {
    DollarOutlined,
    EditOutlined,
    LockOutlined,
    PlusCircleOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Row, Switch, Table } from 'antd';

import './UserAuthoritySettingTable.less';
import {
    ButtonBlue,
    ButtonLightBlue,
    ButtonRed,
    ButtonYellow,
} from '../../../../common/components/buttons/Buttons';
import { USER_AUTHORITY_SETTING_MODAL_TYPES } from '../constants';
import { getPaginationOptions } from './UserAuthoritySettingTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useChangeUserStatusOperationAsyncEndpoint } from '../../../../services/adminService';
import { useStore } from '../../../../store/StateProvider';

const { ColumnGroup, Column } = Table;

const UserAuthoritySettingTable = ({ openModal, loading }) => {
    const [state, dispatch] = useStore();
    const tableData = getStateProp(state, 'administration.userAuthoritySetting.list');
    const paging = getStateProp(state, 'administration.userAuthoritySetting.paging', {});
    const [statusDetails, setStatusDetails] = useState({});

    // ============================================
    // METHODS
    // ============================================

    const handleStatusSwitch = (isChecked, record) => {
        setStatusDetails({ isChecked, record });
    };

    // ============================================
    // HOOKS
    // ============================================
    const [changing, statusChanged] = useChangeUserStatusOperationAsyncEndpoint(statusDetails);

    useEffect(() => {
        setStatusDetails({});
    }, [statusChanged]);

    // ============================================
    // RENDER
    // ============================================
    return (
        <Table
            className="user-authority-setting-table"
            bordered
            dataSource={tableData}
            rowKey={(record) => record.userId}
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
        >
            <Column title="Username" dataIndex="userName" key="userName" />
            <Column
                title="Enable"
                dataIndex="status"
                key="status"
                render={(value, record) => (
                    <Switch
                        defaultChecked={value === 'Active'}
                        onChange={(checked) => handleStatusSwitch(checked, record)}
                        loading={changing}
                    />
                )}
            />
            <ColumnGroup title="Permission">
                <Column
                    align="center"
                    title="Group"
                    dataIndex="accessGroupName"
                    key="accessGroupName"
                    width="200px"
                    render={(value, record) => (
                        <ButtonYellow
                            label={value || 'Add role'}
                            className={
                                value
                                    ? 'add-group__role-btn'
                                    : 'add-group__role-btn  add-group-add-role-btn'
                            }
                            icon={value ? <EditOutlined /> : <PlusCircleOutlined />}
                            onClick={() =>
                                openModal(USER_AUTHORITY_SETTING_MODAL_TYPES.ADD_ROLE, record)
                            }
                        />
                    )}
                />
                <Column
                    title="Individual"
                    dataIndex="individual"
                    key="individual"
                    width="200px"
                    render={(value, record) => (
                        <ButtonBlue
                            className="add-individual__role-btn"
                            icon={<UserOutlined />}
                            onClick={() =>
                                openModal(
                                    USER_AUTHORITY_SETTING_MODAL_TYPES.INDIVIDUAL_AUTHORITY,
                                    record,
                                )
                            }
                        />
                    )}
                    align="center"
                />
            </ColumnGroup>
            <Column
                title="Actions"
                dataIndex="actions"
                key="actions"
                render={(value, record) => (
                    <Row justify="space-around">
                        <ButtonRed
                            className="change-password-btn"
                            icon={<LockOutlined />}
                            onClick={() => {
                                openModal(
                                    USER_AUTHORITY_SETTING_MODAL_TYPES.CHANGE_PASSWORD,
                                    record,
                                );
                            }}
                        />

                        <ButtonLightBlue
                            className="change-currency-btn"
                            icon={<DollarOutlined />}
                            onClick={() => {
                                openModal(
                                    USER_AUTHORITY_SETTING_MODAL_TYPES.CHANGE_CURRENCY,
                                    record,
                                );
                            }}
                        />
                    </Row>
                )}
            />
        </Table>
    );
};

export default UserAuthoritySettingTable;
