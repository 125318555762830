import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { getTranslation } from '../../../common/helpers/locale';

const SelectFilter = ({ value, onFilterChange, placeholder, optionList, name, mode }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange(name, value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear
            placeholder={getTranslation(placeholder)}
            className="filter"
            mode={mode}
        >
            {_.map(optionList, (list) => (
                <Select.Option key={list} value={list}>
                    {list}
                </Select.Option>
            ))}
        </Select>
    );
};

SelectFilter.defaultProps = {
    mode: null,
};

export default SelectFilter;
