import React from 'react';

import { Row } from 'antd';

import { IpAddressFilter, RiskBonusCodeFilter, UsernameFilter } from '../filters';
import { PeriodFilter } from '../../../common/components/filters';
import { filterSet } from './riskMonitorActions';
import { getStateProp } from '../../../common/helpers/misc';
import { useStore } from '../../../store/StateProvider';

const RiskMonitorFilters = () => {
    const [state, dispatch] = useStore();

    const { riskMonitor } = getStateProp(state, 'bonus');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => filterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(riskMonitor, 'filters.userName')}
            />
            <IpAddressFilter
                onFilterChange={setFilter}
                value={getStateProp(riskMonitor, 'filters.ipAddress')}
            />
            <RiskBonusCodeFilter
                onFilterChange={setFilter}
                value={getStateProp(riskMonitor, 'filters.bonusCode')}
            />
            {/* <RiskLevelFilter
                onFilterChange={setFilter}
                value={getStateProp(riskMonitor, 'filters.riskLevel')}
            /> */}
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(riskMonitor, 'filters.dateFrom')}
                dateFromName="dateFrom"
                dateTo={getStateProp(riskMonitor, 'filters.dateTo')}
                dateToName="dateTo"
            />
        </Row>
    );
};

export default RiskMonitorFilters;
