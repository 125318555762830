import React from 'react';

import { Row } from 'antd';
import { getTranslation } from '../../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../../common/helpers/misc';
import EWalletVerifyPopupForm from './popovers/EWalletVerifyPopupForm';
import EditPopover from '../../../players-list/table/popovers/EditPopover';
import HolderNameEditForm from './popovers/HolderNameEditForm';
import OperationsButton from './OperationsButton';

export const getColumns = (dispatch, userId) => {
    const renderMaskedEWalletNo = (value, record) => (
        <Row justify="space-between">
            <span>{value}</span>
            {record.status !== 'Deleted' && (
                <EditPopover
                    title={getTranslation('Bank Card Verification')}
                    value={value}
                    aspect="bankVerify"
                >
                    <EWalletVerifyPopupForm eWalletNo={value} maskedValue={value} />
                </EditPopover>
            )}
        </Row>
    );

    const renderEWalletHolderName = (value, record) => (
        <Row justify="space-between">
            <span>{value}</span>
            {record.status !== 'Deleted' && (
                <EditPopover title={getTranslation('E-Wallet Holder Name')} value={value}>
                    <HolderNameEditForm userId={userId} eWalletInfo={record} />
                </EditPopover>
            )}
        </Row>
    );

    const columns = [
        {
            dataIndex: 'eWalletName',
            title: getTranslation('E-Wallet Name'),
        },
        {
            dataIndex: 'eWalletAccountHolderName',
            render: (value, record) => renderEWalletHolderName(value, record),
            title: getTranslation('E-Wallet Holder Name'),
        },
        {
            dataIndex: 'eWalletAccountNo',
            render: (value, record) => renderMaskedEWalletNo(value, record),
            title: getTranslation('E-Wallet No.'),
        },
        {
            dataIndex: 'operation',
            dataType: 'operation',
            render: (value, record) => <OperationsButton dispatch={dispatch} eWallet={record} />,
            title: getTranslation('Operations'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};
