import React from 'react';

import { Modal } from 'antd';

import { getTranslation } from '../../../common/helpers/locale';
import BonusCodeRedemptionModalTable from './table/BonusCodeRedemptionModalTable';

const BonusCodeRedemptionModal = ({ loading, visible, onCancel }) => {
    return (
        <Modal
            width={780}
            visible={visible}
            title={getTranslation('Bonus Code Redemption')}
            onCancel={() => onCancel(false)}
            closable={false}
            footer={null}
        >
            <BonusCodeRedemptionModalTable loading={loading} />
        </Modal>
    );
};

export default BonusCodeRedemptionModal;
