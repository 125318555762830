import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';

import { Card, Col, Form, Input, Row, Skeleton } from 'antd';
import _ from 'lodash';

import './GroupAuthoritySettingEditorForm.less';
import { ButtonGray, ButtonGreen } from '../../../../common/components/buttons/Buttons';
import { ROUTES } from '../../../../common/constants/routing';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { setEditorInfo, setSelectedMenu } from '../groupAuthoritySettingActions';
import {
    useCreateGroupAccessRecordAsyncEndpoint,
    useGetGroupAccessDetailAsyncEndpoint,
    useUpdateGroupAccessRecordAsyncEndpoint,
} from '../../../../services/groupAuthoritySettingService';
import { useStore } from '../../../../store/StateProvider';
import MenusSelection from '../shared/MenusSelection';

const GroupAuthoritySettingEditorForm = ({ accessGroupId }) => {
    const [state, dispatch] = useStore();
    const history = useHistory();
    const [form] = Form.useForm();
    const { setFieldsValue } = form;

    const groupAuthoritySetting = getStateProp(state, 'administration.groupAuthoritySetting');
    const groupAccessRecord = getStateProp(groupAuthoritySetting, 'groupAccessRecord', {});
    const menuList = getStateProp(state, 'administration.menus');

    // ============================================
    // METHODS
    // ============================================

    const onEditorInfoSet = (name, value) => {
        setEditorInfo(dispatch, { name, value });
    };

    const handleMenusChecked = (name, value) => {
        setSelectedMenu(dispatch, { name, value });
    };

    const submitForm = () => {
        if (accessGroupId) handleUpdateGroupAccessRecord();
        else handleCreateGroupAccessRecord();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [creating, handleCreateGroupAccessRecord] = useCreateGroupAccessRecordAsyncEndpoint();
    const [updating, handleUpdateGroupAccessRecord] = useUpdateGroupAccessRecordAsyncEndpoint();
    const [loading] = useGetGroupAccessDetailAsyncEndpoint(accessGroupId);

    useEffect(() => {
        setFieldsValue({
            accessGroupName: getStateProp(groupAccessRecord, 'accessGroupName', ''),
            remarks: getStateProp(groupAccessRecord, 'remarks', ''),
        });
    }, [groupAccessRecord, groupAuthoritySetting, setFieldsValue]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form form={form} name="group-authority-settings" onFinish={submitForm}>
            <Card title={getTranslation('Group Authority Setting')} loading={loading}>
                <Row>
                    <Col span={12} className="group-authority-setting-editor__form-column">
                        <Row className="form__group">
                            <Col span={10}>
                                <label className="form__label">
                                    {getTranslation('Group Name')}
                                    <span className="form__required">*</span>
                                </label>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="accessGroupName"
                                    rules={[
                                        {
                                            message: getTranslation('Please input Group Name!'),
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={(e) => {
                                            onEditorInfoSet('accessGroupName', e.target.value);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={12} className="group-authority-setting-editor__form-column">
                        <Row className="form__group">
                            <Col span={10}>
                                <label className="form__label">
                                    {getTranslation('Remark')}
                                    <span className="form__required">*</span>
                                </label>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="remarks"
                                    rules={[
                                        {
                                            message: getTranslation('Please input Remark!'),
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={(e) => {
                                            onEditorInfoSet('remarks', e.target.value);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>

            {!_.isEmpty(menuList) ? (
                _.map(menuList, (list) => (
                    <MenusSelection
                        menus={list.subMenus}
                        title={list.menuName}
                        key={list.menuId}
                        selectAction={handleMenusChecked}
                        loading={loading}
                    />
                ))
            ) : (
                <Skeleton active paragraph={{ rows: 10 }} />
            )}

            <Row className="form__footer-row" justify="end">
                <ButtonGreen
                    type="primary"
                    className="rowaction-button"
                    loading={creating || updating}
                    htmlType="submit"
                >
                    {getTranslation(accessGroupId ? 'Update' : 'Create')}
                </ButtonGreen>
                <ButtonGray
                    type="primary"
                    className="rowaction-button"
                    onClick={() => {
                        history.push(ROUTES.DASHBOARD.ADMINISTRATION.GROUP_AUTHORITY.LIST);
                    }}
                >
                    {getTranslation('Cancel')}
                </ButtonGray>
            </Row>
        </Form>
    );
};

export default GroupAuthoritySettingEditorForm;
