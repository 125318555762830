import React from 'react';

import { Checkbox, Col, Form, Input, Radio, Row, Select, Switch, Tabs, message } from 'antd';
import _ from 'lodash';

import './BonusFormInputs.less';
import { Editor, ImgIcon } from '../../../common/components';
import {
    getLanguageByCode,
    getSupportedCurrenciesByCountry,
    getTranslation,
} from '../../../common/helpers/locale';

import { CURRENCIES } from '../../../common/constants/localization';
import { PeriodFilter } from '../../../common/components/filters';
import { getCurrentLanguage } from '../../../common/helpers/api';
import {
    getIntOrFloat,
    getNegativeInt,
    getStateProp,
    isSupportedLanguage,
} from '../../../common/helpers/misc';
import { useStore } from '../../../store/StateProvider';
import ImageEditor from '../../../common/components/ImageEditor';

const { TextArea } = Input;
const { TabPane } = Tabs;

const BonusFormInputs = ({
    onBonusInfoChange,
    onContentChange,
    bonusInfo,
    details,
    isEdit,
    categoryListLoading,
    viewOnly,
    currentStep,
    selectedLangs,
    platforms = [],
    platformsArrary = [],
    products = [],
    productsArrary = [],
    TabsExtraContent,
}) => {
    const [state] = useStore();
    const categoryList = getStateProp(state, 'bonus.category.list', []);
    const selectedBonusCondition = bonusInfo.isETODeposit
        ? 'isETODeposit'
        : bonusInfo.isETODepositAndBonus
        ? 'isETODepositAndBonus'
        : bonusInfo.isBetAmount
        ? 'isBetAmount'
        : bonusInfo.isWinLoss
        ? 'isWinLoss'
        : null;
    const appliedPlatforms = _.includes(bonusInfo.appliedPlatforms, 'ALL')
        ? platformsArrary
        : bonusInfo.appliedPlatforms;
    const appliedProducts = _.includes(bonusInfo.appliedProducts, 'ALL')
        ? productsArrary
        : bonusInfo.appliedProducts;

    const supportedCurrency = _.map(
        getSupportedCurrenciesByCountry(),
        (currency) => currency.currency,
    );
    // ============================================
    // METHODS
    // ============================================

    const validateAmount = (key, currency, value, arrayData = []) => {
        let amount = getIntOrFloat(value, true);
        let newArray = arrayData;

        if (_.find(newArray, ['currency', currency])) {
            _.forEach(newArray, function(e) {
                if (e.currency === currency) {
                    e.amount = amount.toFixed(2);
                }
            });
        } else {
            newArray.push({
                amount: amount.toFixed(2),
                currency: currency,
            });
        }

        onBonusInfoChange(key, newArray);
    };

    const validateCurrency = (name, checked) => {
        if ((bonusInfo.currencies && bonusInfo.currencies.length) || !checked) {
            onBonusInfoChange(name, checked);
        } else {
            message.error(getTranslation('Please select at least one currency'));
        }
    };

    const onChangedCurrency = (value) => {
        if (bonusInfo.isDepositBonus && value.length === 0) {
            message.error(getTranslation('Currency cannot be empty'));
        } else {
            let newMinimumDeposits = [];
            let newArray = _.isEmpty(bonusInfo.currencies)
                ? []
                : _.filter(bonusInfo.currencies, function(e) {
                      return _.includes(value, e.currencyCode);
                  });
            _.forEach(value, (e) => {
                let minimumDepositsExist = _.find(bonusInfo.minimumDeposits, ['currency', e]);
                if (
                    _.isEmpty(
                        _.find(bonusInfo.currencies, (currency) => {
                            return currency.currencyCode === e;
                        }),
                    )
                ) {
                    newArray.push({
                        betAmount: 0,
                        bonusPercentage: 0,
                        currencyCode: e,
                        etoDepositBonusMultiplier: 0,
                        etoDepositMultiplier: 0,
                        fixedBonusAmount: 0,
                        lossAmount: 0,
                        maxBonusByPercentage: 0,
                        winAmount: 0,
                    });
                }

                if (!_.isEmpty(minimumDepositsExist)) {
                    newMinimumDeposits.push(minimumDepositsExist);
                } else {
                    newMinimumDeposits.push({
                        amount: '0.00',
                        currency: e,
                    });
                }
            });

            onBonusInfoChange('currencies', newArray, newMinimumDeposits);
        }
    };

    const onBonusAmountChanged = (name, currencyCode, value, isDecimal) => {
        let amount = getIntOrFloat(value, isDecimal);
        const newArray = bonusInfo.currencies;
        const currencyNeedUpdate = _.find(newArray, ['currencyCode', currencyCode]);
        currencyNeedUpdate[name] = isDecimal ? amount.toFixed(2) : amount;
        onBonusInfoChange('currencies', newArray);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <>
            {currentStep === 0 && (
                <>
                    <Row className="bonus__form-row">
                        <Col span={8}>
                            <label>{getTranslation('Bonus Code')}</label>
                        </Col>
                        <Col span={16}>
                            <Form.Item>
                                <Input
                                    onChange={(e) => onBonusInfoChange('code', e.target.value)}
                                    value={bonusInfo.code}
                                    disabled={isEdit || viewOnly}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className="bonus__form-row">
                        <Col span={8}>
                            <label>{getTranslation('Currency')}</label>
                        </Col>
                        <Col span={16}>
                            <Form.Item>
                                <Checkbox.Group
                                    style={{ width: '100%' }}
                                    onChange={(value) => onChangedCurrency(value)}
                                    value={_.map(bonusInfo.currencies, (currency) => {
                                        return currency.currencyCode;
                                    })}
                                    disabled={viewOnly || isEdit}
                                >
                                    <Row style={{ flexFlow: 'row wrap' }}>
                                        {_.map(CURRENCIES, (currency) => (
                                            <Col span={8} key={currency.currency}>
                                                <Checkbox
                                                    value={currency.currency}
                                                    disabled={
                                                        !_.includes(
                                                            supportedCurrency,
                                                            currency.currency,
                                                        )
                                                    }
                                                >
                                                    {currency.currency}
                                                </Checkbox>
                                            </Col>
                                        ))}
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className="bonus__form-row">
                        <Col span={8}>
                            <label>{getTranslation('Start Date/ End Date')}</label>
                        </Col>
                        <Col span={16} className="bonus_form_input__date-picker">
                            <PeriodFilter
                                onFilterChange={(key, value) => onBonusInfoChange(key, value)}
                                dateFrom={getStateProp(bonusInfo, 'bonusValidDateFrom')}
                                dateTo={getStateProp(bonusInfo, 'bonusValidDateTo')}
                                dateFromName="bonusValidDateFrom"
                                dateToName="bonusValidDateTo"
                                disabled={viewOnly || isEdit}
                            />
                        </Col>
                    </Row>

                    <Row className="bonus__form-row">
                        <Col span={8}>{getTranslation('Turnover Requirement')}</Col>
                        <Col span={16}>
                            <Form.Item>
                                <Input
                                    className="bonus_form_input__text-align--right"
                                    onChange={(e) =>
                                        onBonusInfoChange(
                                            'turnoverCondition',
                                            getIntOrFloat(e.target.value, false),
                                        )
                                    }
                                    value={bonusInfo.turnoverCondition || 0}
                                    disabled={viewOnly || isEdit}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className="bonus__form-row">
                        <Col span={8}>
                            <label>{getTranslation('Bonus Category')}</label>
                        </Col>
                        <Col span={16}>
                            <Form.Item>
                                <Select
                                    mode="multiple"
                                    value={
                                        isEdit
                                            ? _.map(bonusInfo.categories, (category) => {
                                                  return category.categoryId;
                                              })
                                            : bonusInfo.categories
                                    }
                                    allowClear={true}
                                    showSearch
                                    loading={categoryListLoading}
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(value) => onBonusInfoChange('categories', value)}
                                    disabled={viewOnly}
                                >
                                    {_.map(categoryList, (list) => (
                                        <Select.Option key={list.id}>
                                            {_.find(list.categoryNames, (categoryName) => {
                                                return (
                                                    getCurrentLanguage() ===
                                                    categoryName.languageCode
                                                );
                                            }).name.toLocaleUpperCase()}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className="bonus__form-row">
                        <Col span={8}>
                            <label>{getTranslation('Internal Remark')}</label>
                        </Col>
                        <Col span={16}>
                            <Form.Item valuePropName="string">
                                <TextArea
                                    onChange={(e) =>
                                        onBonusInfoChange('internalRemark', e.target.value)
                                    }
                                    value={bonusInfo.internalRemark}
                                    disabled={viewOnly}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className="bonus__form-row">
                        <Col span={8}>{getTranslation('Display in Client App')}</Col>
                        <Col span={16}>
                            <Form.Item valuePropName="checked">
                                <Switch
                                    onChange={(checked) =>
                                        onBonusInfoChange('isShowOnApp', checked)
                                    }
                                    checked={bonusInfo.isShowOnApp}
                                    disabled={viewOnly}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <br />

                    <Tabs
                        tabBarExtraContent={viewOnly ? null : TabsExtraContent}
                        className="bonus__tabs"
                    >
                        {_.map(selectedLangs, (langCode) => {
                            const tabTitle = getLanguageByCode(langCode);
                            const bonusContent = _.find(details, (content) => {
                                return content.languageCode === langCode;
                            });

                            return (
                                <TabPane tab={tabTitle} key={langCode}>
                                    {bonusInfo.isShowOnApp && (
                                        <Row className="bonus__form-row">
                                            <Form.Item>
                                                {viewOnly || !isSupportedLanguage(langCode) ? (
                                                    <ImgIcon
                                                        icon={bonusContent.imageUrl}
                                                        containerClassName="image-container"
                                                        style={{ maxWidth: '100%' }}
                                                    />
                                                ) : (
                                                    <ImageEditor
                                                        onImageChanged={(imageInBase64) =>
                                                            onContentChange(
                                                                'imageInBase64',
                                                                imageInBase64,
                                                                langCode,
                                                            )
                                                        }
                                                        image={
                                                            bonusContent.imageInBase64 ||
                                                            bonusContent.imageUrl
                                                        }
                                                    />
                                                )}
                                            </Form.Item>
                                        </Row>
                                    )}

                                    <Row className="bonus__form-row">
                                        <Col span={8}>
                                            <label>{getTranslation('Bonus Name')}</label>
                                        </Col>
                                        <Col span={16}>
                                            <Form.Item>
                                                <Input
                                                    onChange={(e) =>
                                                        onContentChange(
                                                            'bonusName',
                                                            e.target.value,
                                                            langCode,
                                                        )
                                                    }
                                                    value={bonusContent.bonusName}
                                                    disabled={
                                                        viewOnly || !isSupportedLanguage(langCode)
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    {bonusInfo.isShowOnApp && (
                                        <>
                                            <Row className="bonus__form-row">
                                                <Col span={8}>
                                                    <label>
                                                        {getTranslation('Mini Description')}
                                                    </label>
                                                </Col>
                                                <Col span={16}>
                                                    <Form.Item>
                                                        <TextArea
                                                            onChange={(e) =>
                                                                onContentChange(
                                                                    'shortDetail',
                                                                    e.target.value,
                                                                    langCode,
                                                                )
                                                            }
                                                            value={bonusContent.shortDetail}
                                                            disabled={
                                                                viewOnly ||
                                                                !isSupportedLanguage(langCode)
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row className="bonus__form-row">
                                                <Col span={8}>
                                                    <label>{getTranslation('Bonus Details')}</label>
                                                </Col>
                                                <Col span={16}>
                                                    <Form.Item valuePropName="string">
                                                        <Editor
                                                            onBlur={(event) =>
                                                                onContentChange(
                                                                    'bonusDetail',
                                                                    event.target.getContent(),
                                                                    langCode,
                                                                )
                                                            }
                                                            value={bonusContent.bonusDetail}
                                                            disabled={
                                                                viewOnly ||
                                                                !isSupportedLanguage(langCode)
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row className="bonus__form-row">
                                                <Col span={8}>
                                                    <label>
                                                        {getTranslation('Term & Condition')}
                                                    </label>
                                                </Col>
                                                <Col span={16}>
                                                    <Form.Item valuePropName="string">
                                                        <Editor
                                                            onBlur={(event) =>
                                                                onContentChange(
                                                                    'termCondition',
                                                                    event.target.getContent(),
                                                                    langCode,
                                                                )
                                                            }
                                                            value={bonusContent.termCondition}
                                                            disabled={
                                                                viewOnly ||
                                                                !isSupportedLanguage(langCode)
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </TabPane>
                            );
                        })}
                    </Tabs>
                </>
            )}
            {currentStep === 1 && (
                <>
                    <Row className="bonus__form-row">
                        <Col span={8}>{getTranslation('Show Join Button')}</Col>
                        <Col span={16}>
                            <Form.Item valuePropName="checked">
                                <Switch
                                    onChange={(checked) =>
                                        onBonusInfoChange('hasJoinButton', checked)
                                    }
                                    checked={bonusInfo.hasJoinButton}
                                    disabled={viewOnly || bonusInfo.joinButtonDisable}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {bonusInfo.hasJoinButton && (
                        <div className="bonus_form_input--child-container">
                            <Row className="bonus__form-row">
                                <Col span={8}>{getTranslation('Require Promo Code')}</Col>
                                <Col span={16}>
                                    <Form.Item valuePropName="checked">
                                        <Switch
                                            onChange={(checked) =>
                                                onBonusInfoChange('hasPromoCode', checked)
                                            }
                                            checked={bonusInfo.hasPromoCode}
                                            disabled={viewOnly}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row className="bonus__form-row">
                                <Col span={8}>{getTranslation('Deposit Bonus')}</Col>
                                <Col span={16}>
                                    <Form.Item valuePropName="checked">
                                        <Switch
                                            onChange={(checked) =>
                                                validateCurrency('isDepositBonus', checked)
                                            }
                                            checked={bonusInfo.isDepositBonus}
                                            disabled={viewOnly || isEdit}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {bonusInfo.isDepositBonus && (
                                <div className="bonus_form_input--child-container">
                                    <Row className="bonus__form-row">
                                        <Col span={8}>{getTranslation('Minimum Deposit')}</Col>
                                        <Col span={16}>
                                            <Form.Item valuePropName="checked">
                                                <Row style={{ flexFlow: 'row wrap' }}>
                                                    {_.map(
                                                        getSupportedCurrenciesByCountry(),
                                                        (currency) =>
                                                            (_.includes(
                                                                bonusInfo.currencies,
                                                                currency.currency,
                                                            ) ||
                                                                _.find(bonusInfo.currencies, [
                                                                    'currencyCode',
                                                                    currency.currency,
                                                                ])) && (
                                                                <div
                                                                    className="bonus_form_input__min_deposit"
                                                                    key={`minDeposit${currency.currency}`}
                                                                >
                                                                    <Input
                                                                        prefix={currency.currency}
                                                                        onChange={(e) =>
                                                                            validateAmount(
                                                                                'minimumDeposits',
                                                                                currency.currency,
                                                                                e.target.value,
                                                                                bonusInfo.minimumDeposits,
                                                                            )
                                                                        }
                                                                        value={
                                                                            _.find(
                                                                                bonusInfo.minimumDeposits,
                                                                                [
                                                                                    'currency',
                                                                                    currency.currency,
                                                                                ],
                                                                            )
                                                                                ? _.find(
                                                                                      bonusInfo.minimumDeposits,
                                                                                      [
                                                                                          'currency',
                                                                                          currency.currency,
                                                                                      ],
                                                                                  ).amount
                                                                                : '0.00'
                                                                        }
                                                                        disabled={
                                                                            viewOnly || isEdit
                                                                        }
                                                                    />
                                                                </div>
                                                            ),
                                                    )}
                                                </Row>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            )}

                            <Row className="bonus__form-row">
                                <Col span={8}>{getTranslation('Bonus Amount')}</Col>
                                <Col span={16}>
                                    <Form.Item valuePropName="checked">
                                        <Radio.Group
                                            onChange={(e) => {
                                                onBonusInfoChange('isFixedBonus', e.target.value);
                                            }}
                                            value={bonusInfo.isFixedBonus}
                                            disabled={viewOnly || isEdit}
                                        >
                                            <Radio value={true}>
                                                {getTranslation('Fixed Bonus Amount')}
                                            </Radio>
                                            <Radio value={false}>
                                                {getTranslation('Bonus Amount By Percentage')}
                                            </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row className="bonus__form-row">
                                <Col span={8}></Col>
                                <Col span={16}>
                                    <Form.Item valuePropName="checked">
                                        <Row style={{ flexFlow: 'row wrap' }}>
                                            {_.map(
                                                getSupportedCurrenciesByCountry(),
                                                (currency) =>
                                                    (_.includes(
                                                        bonusInfo.currencies,
                                                        currency.currency,
                                                    ) ||
                                                        _.find(bonusInfo.currencies, [
                                                            'currencyCode',
                                                            currency.currency,
                                                        ])) &&
                                                    (bonusInfo.isFixedBonus ? (
                                                        <div
                                                            className="bonus_form_input__min_deposit"
                                                            key={`fixedBonus${currency.currency}`}
                                                        >
                                                            <Input
                                                                prefix={currency.currency}
                                                                onChange={(e) =>
                                                                    onBonusAmountChanged(
                                                                        'fixedBonusAmount',
                                                                        currency.currency,
                                                                        e.target.value,
                                                                        true,
                                                                    )
                                                                }
                                                                value={
                                                                    _.find(bonusInfo.currencies, [
                                                                        'currencyCode',
                                                                        currency.currency,
                                                                    ]).fixedBonusAmount || '0.00'
                                                                }
                                                                disabled={viewOnly || isEdit}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <Row
                                                            key={`bonusByPercentage${currency.currency}`}
                                                        >
                                                            <span className="bonus_form_input__bonus__currency">
                                                                {currency.currency} :
                                                            </span>
                                                            <div className="bonus_form_input__bonus__percentage">
                                                                <Input
                                                                    onChange={(e) =>
                                                                        onBonusAmountChanged(
                                                                            'bonusPercentage',
                                                                            currency.currency,
                                                                            e.target.value,
                                                                            true,
                                                                        )
                                                                    }
                                                                    value={
                                                                        _.find(
                                                                            bonusInfo.currencies,
                                                                            [
                                                                                'currencyCode',
                                                                                currency.currency,
                                                                            ],
                                                                        ).bonusPercentage || '0.00'
                                                                    }
                                                                    disabled={viewOnly || isEdit}
                                                                />
                                                            </div>
                                                            <span className="bonus_form_input__bonus__amount-text">
                                                                {getTranslation(
                                                                    '% of deposit amount, up to',
                                                                )}
                                                            </span>
                                                            <div className="bonus_form_input__bonus__amount">
                                                                <Input
                                                                    prefix={currency.currency}
                                                                    onChange={(e) =>
                                                                        onBonusAmountChanged(
                                                                            'maxBonusByPercentage',
                                                                            currency.currency,
                                                                            e.target.value,
                                                                            true,
                                                                        )
                                                                    }
                                                                    value={
                                                                        _.find(
                                                                            bonusInfo.currencies,
                                                                            [
                                                                                'currencyCode',
                                                                                currency.currency,
                                                                            ],
                                                                        ).maxBonusByPercentage ||
                                                                        '0.00'
                                                                    }
                                                                    disabled={viewOnly || isEdit}
                                                                />
                                                            </div>
                                                        </Row>
                                                    )),
                                            )}
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row className="bonus__form-row">
                                <Col span={8}>{getTranslation('Auto Approve Bonus')}</Col>
                                <Col span={16}>
                                    <Form.Item valuePropName="checked">
                                        <Switch
                                            onChange={(checked) =>
                                                onBonusInfoChange('isDepositEtoComplete', checked)
                                            }
                                            checked={bonusInfo.isDepositEtoComplete}
                                            disabled={viewOnly || isEdit}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    )}

                    <Row className="bonus__form-row">
                        <Col span={8}>{getTranslation('Is Repeatable')}</Col>
                        <Col span={16}>
                            <Form.Item valuePropName="checked">
                                <Switch
                                    onChange={(checked) =>
                                        onBonusInfoChange('isRepeatable', checked)
                                    }
                                    checked={bonusInfo.isRepeatable}
                                    disabled={viewOnly}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {bonusInfo.isRepeatable && (
                        <div className="bonus_form_input--child-container">
                            <Row className="bonus__form-row">
                                <Col span={8}>{getTranslation('Interval')}</Col>
                                <Col span={16}>
                                    <Form.Item>
                                        <Input
                                            suffix={getTranslation('days')}
                                            className="bonus_form_input__times"
                                            onChange={(e) =>
                                                onBonusInfoChange(
                                                    'depositInterval',
                                                    getIntOrFloat(e.target.value, false),
                                                )
                                            }
                                            value={bonusInfo.depositInterval || 0}
                                            disabled={viewOnly}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row className="bonus__form-row">
                                <Col span={8}>
                                    <label>{getTranslation('Repeat Limit')}</label>

                                    <span
                                        style={{
                                            color: '#f95800',
                                            fontSize: '10px',
                                            paddingLeft: '5px',
                                        }}
                                    >
                                        ( {getTranslation('-1 = unlimited')} )
                                    </span>
                                </Col>
                                <Col span={16}>
                                    <Form.Item>
                                        <Input
                                            className="bonus_form_input__text-align--right"
                                            onChange={(e) =>
                                                onBonusInfoChange(
                                                    'repeatableCount',
                                                    getNegativeInt(e.target.value),
                                                )
                                            }
                                            value={bonusInfo.repeatableCount}
                                            disabled={viewOnly}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    )}

                    <Row className="bonus__form-row">
                        <Col span={8}>{getTranslation('Require Bank Card')}</Col>
                        <Col span={16}>
                            <Form.Item valuePropName="checked">
                                <Switch
                                    onChange={(checked) =>
                                        onBonusInfoChange('isRequireBankCard', checked)
                                    }
                                    checked={bonusInfo.isRequireBankCard}
                                    disabled={viewOnly}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className="bonus__form-row">
                        <Col span={8}>{getTranslation('Require Bonus Complete')}</Col>
                        <Col span={16}>
                            <Form.Item valuePropName="checked">
                                <Switch
                                    onChange={(checked) =>
                                        onBonusInfoChange('isRequireBonusComplete', checked)
                                    }
                                    checked={bonusInfo.isRequireBonusComplete}
                                    disabled={viewOnly}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className="bonus__form-row">
                        <Col span={8}>{getTranslation('Require Deposit')}</Col>
                        <Col span={16}>
                            <Form.Item valuePropName="checked">
                                <Switch
                                    onChange={(checked) =>
                                        onBonusInfoChange('isRequireDeposit', checked)
                                    }
                                    checked={bonusInfo.isRequireDeposit}
                                    disabled={viewOnly}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            )}
            {currentStep === 2 && (
                <>
                    <Row className="bonus__form-row">
                        <Col span={8}>{getTranslation('Enable Promo Rules')}</Col>
                        <Col span={16}>
                            <Form.Item valuePropName="checked">
                                <Switch
                                    onChange={(checked) =>
                                        onBonusInfoChange('isPromoRule', checked)
                                    }
                                    checked={bonusInfo.isPromoRule || false}
                                    disabled={viewOnly || isEdit}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {bonusInfo.isPromoRule && (
                        <div className="bonus_form_input--child-container">
                            <Radio.Group
                                onChange={(e) => {
                                    onBonusInfoChange('bonusConditionType', e.target.value);
                                }}
                                value={selectedBonusCondition}
                                disabled={viewOnly || isEdit}
                            >
                                <Row className="bonus__form-row">
                                    <Radio value={'isETODeposit'}>
                                        {getTranslation(
                                            'Required Bet Turnover = Deposit Amount * times',
                                        )}
                                    </Radio>
                                </Row>

                                {bonusInfo.isETODeposit && (
                                    <Row className="bonus__form-row">
                                        <Row style={{ flexFlow: 'row wrap' }}>
                                            {_.map(
                                                getSupportedCurrenciesByCountry(),
                                                (currency) =>
                                                    (_.includes(
                                                        bonusInfo.currencies,
                                                        currency.currency,
                                                    ) ||
                                                        _.find(bonusInfo.currencies, [
                                                            'currencyCode',
                                                            currency.currency,
                                                        ])) && (
                                                        <div
                                                            className="bonus_form_input__min_deposit"
                                                            key={`minDeposit${currency.currency}`}
                                                        >
                                                            <Input
                                                                prefix={currency.currency}
                                                                suffix={getTranslation('times')}
                                                                onChange={(e) =>
                                                                    onBonusAmountChanged(
                                                                        'etoDepositMultiplier',
                                                                        currency.currency,
                                                                        e.target.value,
                                                                        false,
                                                                    )
                                                                }
                                                                value={
                                                                    _.find(bonusInfo.currencies, [
                                                                        'currencyCode',
                                                                        currency.currency,
                                                                    ]).etoDepositMultiplier || 0
                                                                }
                                                                disabled={viewOnly || isEdit}
                                                            />
                                                        </div>
                                                    ),
                                            )}
                                        </Row>
                                    </Row>
                                )}

                                <Row className="bonus__form-row">
                                    <Radio value={'isETODepositAndBonus'}>
                                        {getTranslation(
                                            'Required Bet Turnover = (Deposit Amount + Bonus) * times',
                                        )}
                                    </Radio>
                                </Row>

                                {bonusInfo.isETODepositAndBonus && (
                                    <Row className="bonus__form-row">
                                        <Row style={{ flexFlow: 'row wrap' }}>
                                            {_.map(
                                                getSupportedCurrenciesByCountry(),
                                                (currency) =>
                                                    (_.includes(
                                                        bonusInfo.currencies,
                                                        currency.currency,
                                                    ) ||
                                                        _.find(bonusInfo.currencies, [
                                                            'currencyCode',
                                                            currency.currency,
                                                        ])) && (
                                                        <div
                                                            className="bonus_form_input__min_deposit"
                                                            key={`minDeposit${currency.currency}`}
                                                        >
                                                            <Input
                                                                prefix={currency.currency}
                                                                suffix={getTranslation('times')}
                                                                onChange={(e) =>
                                                                    onBonusAmountChanged(
                                                                        'etoDepositBonusMultiplier',
                                                                        currency.currency,
                                                                        e.target.value,
                                                                        false,
                                                                    )
                                                                }
                                                                value={
                                                                    _.find(bonusInfo.currencies, [
                                                                        'currencyCode',
                                                                        currency.currency,
                                                                    ]).etoDepositBonusMultiplier ||
                                                                    0
                                                                }
                                                                disabled={viewOnly || isEdit}
                                                            />
                                                        </div>
                                                    ),
                                            )}
                                        </Row>
                                    </Row>
                                )}

                                <Row className="bonus__form-row">
                                    <Radio value={'isBetAmount'}>
                                        {getTranslation('(≥) Bet Amount')}
                                    </Radio>
                                </Row>

                                {bonusInfo.isBetAmount && (
                                    <Row className="bonus__form-row">
                                        <Row style={{ flexFlow: 'row wrap' }}>
                                            {_.map(
                                                getSupportedCurrenciesByCountry(),
                                                (currency) =>
                                                    (_.includes(
                                                        bonusInfo.currencies,
                                                        currency.currency,
                                                    ) ||
                                                        _.find(bonusInfo.currencies, [
                                                            'currencyCode',
                                                            currency.currency,
                                                        ])) && (
                                                        <div
                                                            className="bonus_form_input__min_deposit"
                                                            key={`minDeposit${currency.currency}`}
                                                        >
                                                            <Input
                                                                prefix={currency.currency}
                                                                onChange={(e) =>
                                                                    onBonusAmountChanged(
                                                                        'betAmount',
                                                                        currency.currency,
                                                                        e.target.value,
                                                                        true,
                                                                    )
                                                                }
                                                                value={
                                                                    _.find(bonusInfo.currencies, [
                                                                        'currencyCode',
                                                                        currency.currency,
                                                                    ]).betAmount || '0.00'
                                                                }
                                                                disabled={viewOnly || isEdit}
                                                            />
                                                        </div>
                                                    ),
                                            )}
                                        </Row>
                                    </Row>
                                )}

                                <Row className="bonus__form-row">
                                    <Col span={5}>
                                        <Radio value={'isWinLoss'}>
                                            {getTranslation('Win Loss')}
                                        </Radio>
                                    </Col>
                                    <Col span={19}>
                                        {bonusInfo.isWinLoss && (
                                            <Radio.Group
                                                onChange={(e) => {
                                                    onBonusInfoChange(
                                                        'winLossType',
                                                        e.target.value,
                                                    );
                                                }}
                                                value={bonusInfo.winLossType}
                                                disabled={viewOnly || isEdit}
                                            >
                                                <Radio value={'Win'}>
                                                    {getTranslation('Win (≥)')}
                                                </Radio>
                                                <Radio value={'Loss'}>
                                                    {getTranslation('Loss (≥)')}
                                                </Radio>
                                            </Radio.Group>
                                        )}
                                    </Col>
                                </Row>

                                {bonusInfo.isWinLoss && (
                                    <Row className="bonus__form-row">
                                        <Row style={{ flexFlow: 'row wrap' }}>
                                            {_.map(
                                                getSupportedCurrenciesByCountry(),
                                                (currency) =>
                                                    (_.includes(
                                                        bonusInfo.currencies,
                                                        currency.currency,
                                                    ) ||
                                                        _.find(bonusInfo.currencies, [
                                                            'currencyCode',
                                                            currency.currency,
                                                        ])) && (
                                                        <div
                                                            className="bonus_form_input__min_deposit"
                                                            key={`minDeposit${currency.currency}`}
                                                        >
                                                            <Input
                                                                prefix={currency.currency}
                                                                onChange={(e) =>
                                                                    onBonusAmountChanged(
                                                                        bonusInfo.winLossType ===
                                                                            'Win'
                                                                            ? 'winAmount'
                                                                            : 'lossAmount',
                                                                        currency.currency,
                                                                        e.target.value,
                                                                        true,
                                                                    )
                                                                }
                                                                value={
                                                                    (bonusInfo.winLossType === 'Win'
                                                                        ? _.find(
                                                                              bonusInfo.currencies,
                                                                              [
                                                                                  'currencyCode',
                                                                                  currency.currency,
                                                                              ],
                                                                          ).winAmount
                                                                        : _.find(
                                                                              bonusInfo.currencies,
                                                                              [
                                                                                  'currencyCode',
                                                                                  currency.currency,
                                                                              ],
                                                                          ).lossAmount) || '0.00'
                                                                }
                                                                disabled={viewOnly || isEdit}
                                                            />
                                                        </div>
                                                    ),
                                            )}
                                        </Row>
                                    </Row>
                                )}
                            </Radio.Group>

                            <br />

                            <Row className="bonus__form-row">
                                <Col span={5}>{getTranslation('From')}</Col>
                                <Col span={19}>
                                    <Radio.Group
                                        onChange={(e) => {
                                            onBonusInfoChange('checkingDateType', e.target.value);
                                        }}
                                        value={bonusInfo.checkingDateType}
                                        disabled={viewOnly || isEdit}
                                    >
                                        <Row className="bonus_form_input__radio__date-from-wrapper">
                                            <Col span={5}>
                                                <Radio value={'Duration'}>
                                                    {getTranslation('Duration (days)')}
                                                </Radio>
                                            </Col>
                                            <Col>
                                                {bonusInfo.checkingDateType === 'Duration' && (
                                                    <Input
                                                        suffix={getTranslation('days')}
                                                        className="bonus_form_input__times"
                                                        onChange={(e) =>
                                                            onBonusInfoChange(
                                                                'duration',
                                                                getIntOrFloat(
                                                                    e.target.value,
                                                                    false,
                                                                ),
                                                            )
                                                        }
                                                        value={bonusInfo.duration || 0}
                                                        disabled={viewOnly || isEdit}
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="bonus_form_input__radio__date-from-wrapper">
                                            <Col span={5}>
                                                <Radio value={'SpecificDate'}>
                                                    {getTranslation('Custom Date')}
                                                </Radio>
                                            </Col>
                                            <Col>
                                                {bonusInfo.checkingDateType === 'SpecificDate' && (
                                                    <PeriodFilter
                                                        onFilterChange={(key, value) =>
                                                            onBonusInfoChange(key, value)
                                                        }
                                                        dateFrom={getStateProp(
                                                            bonusInfo,
                                                            'conditionDateFrom',
                                                        )}
                                                        dateTo={getStateProp(
                                                            bonusInfo,
                                                            'conditionDateTo',
                                                        )}
                                                        dateFromName="conditionDateFrom"
                                                        dateToName="conditionDateTo"
                                                        disabled={viewOnly || isEdit}
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                    </Radio.Group>
                                </Col>
                            </Row>

                            <Row className="bonus__form-row">
                                <Col span={5}>{getTranslation('Applied to')}</Col>
                                <Col span={19}>
                                    <Row>
                                        <Checkbox
                                            indeterminate={
                                                appliedPlatforms &&
                                                appliedPlatforms.length < platformsArrary.length &&
                                                appliedPlatforms.length > 0
                                            }
                                            onChange={(e) =>
                                                onBonusInfoChange(
                                                    'appliedPlatforms',
                                                    e.target.checked ? ['ALL'] : [],
                                                )
                                            }
                                            checked={
                                                appliedPlatforms &&
                                                appliedPlatforms.length === platformsArrary.length
                                            }
                                            disabled={viewOnly || isEdit}
                                        >
                                            {getTranslation('All')}
                                        </Checkbox>
                                    </Row>
                                    <Row>
                                        <Checkbox.Group
                                            style={{ width: '100%' }}
                                            onChange={(value) =>
                                                onBonusInfoChange(
                                                    'appliedPlatforms',
                                                    value.length === platformsArrary.length
                                                        ? ['ALL']
                                                        : value,
                                                )
                                            }
                                            value={appliedPlatforms}
                                            disabled={viewOnly || isEdit}
                                        >
                                            <Row style={{ flexFlow: 'row wrap' }}>
                                                {_.map(platforms, (platform) => (
                                                    <Col span={8} key={platform.platformCode}>
                                                        <Checkbox value={platform.platformCode}>
                                                            {platform.platformDesc}
                                                        </Checkbox>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Checkbox.Group>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="bonus__form-row">
                                <Col span={5}>{getTranslation('Applied to')}</Col>
                                <Col span={19}>
                                    <Row>
                                        <Checkbox
                                            indeterminate={
                                                appliedProducts &&
                                                appliedProducts.length < productsArrary.length &&
                                                appliedProducts.length > 0
                                            }
                                            onChange={(e) =>
                                                onBonusInfoChange(
                                                    'appliedProducts',
                                                    e.target.checked ? ['ALL'] : [],
                                                )
                                            }
                                            checked={
                                                appliedProducts &&
                                                appliedProducts.length === productsArrary.length
                                            }
                                            disabled={viewOnly || isEdit}
                                        >
                                            {getTranslation('All')}
                                        </Checkbox>
                                    </Row>
                                    <Row>
                                        <Checkbox.Group
                                            style={{ width: '100%' }}
                                            onChange={(value) =>
                                                onBonusInfoChange(
                                                    'appliedProducts',
                                                    value.length === productsArrary.length
                                                        ? ['ALL']
                                                        : value,
                                                )
                                            }
                                            value={appliedProducts}
                                            disabled={viewOnly || isEdit}
                                        >
                                            <Row style={{ flexFlow: 'row wrap' }}>
                                                {_.map(products, (product) => (
                                                    <Col span={8} key={product.productCode}>
                                                        <Checkbox value={product.productCode}>
                                                            {product.productDesc}
                                                        </Checkbox>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Checkbox.Group>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default BonusFormInputs;
