import React from 'react';

import _ from 'lodash';

import { ButtonGray } from '../../../common/components/buttons/Buttons';
import { RenderDateFormat } from '../../../common/components/RenderDateFormat';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../common/constants/api';
import { changePageNumber, changePageSize } from '../transactionMonitoringActions';
import { getStatusColor, sortTableByDataType } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import VerifyButton from '../verification/VerifyButton';
import ViewRemarkButton from './ViewRemarkButton';

export const getColumns = (selectedColumns) => {
    const columns = [
        {
            dataIndex: 'No',
            dataType: 'indexNumber',
            render: (text, record, index) => <span>{index + 1}</span>,
            title: getTranslation('No.'),
        },
        {
            dataIndex: 'requestedDateTime',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Request Time'),
        },
        {
            dataIndex: 'transactionNo',
            title: getTranslation('Task Transaction Number'),
        },
        {
            dataIndex: 'playerUserName',
            title: getTranslation('Username'),
        },
        {
            dataIndex: 'memberLevel',
            title: getTranslation('Member Level'),
        },
        {
            dataIndex: 'turnoverCondition',
            title: getTranslation('Turnover Requirement'),
        },
        {
            dataIndex: 'turnoverRequired',
            title: getTranslation('Turnover Amount'),
        },
        {
            dataIndex: 'affiliateUserName',
            title: getTranslation('Affiliate'),
        },
        {
            dataIndex: 'referralUserName',
            title: getTranslation('Referral'),
        },
        {
            dataIndex: 'currencyCode',
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'cryptoCurrencyCode',
            title: getTranslation('Cryptocurrency'),
        },
        {
            dataIndex: 'transactionType',
            title: getTranslation('Type'),
        },
        {
            dataIndex: 'transactionDetails',
            title: getTranslation('Detail'),
        },
        {
            dataIndex: 'amount',
            title: getTranslation('Amount'),
        },
        {
            dataIndex: 'cryptoCurrencyAmount',
            title: getTranslation('Cryptocurrency Amount'),
        },
        {
            dataIndex: 'bankName',
            title: getTranslation('Bank Name'),
        },
        {
            dataIndex: 'auditedDateTime',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Audit Time'),
        },
        {
            dataIndex: 'processedDateTime',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Process Time'),
        },
        {
            dataIndex: 'remarks',
            dataType: 'remarks',
            render: (text, record) =>
                (record.requesterRemarks || record.auditorRemarks) && record.processorRemarks ? (
                    <ViewRemarkButton
                        applicantRemark={record.requesterRemarks}
                        auditorRemark={record.auditorRemarks}
                        operatorRemark={record.processorRemarks}
                    />
                ) : (
                    <span>{record.requesterRemarks || record.auditorRemarks}</span>
                ),
            title: getTranslation('Remarks'),
        },
        {
            dataIndex: 'orderStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>
                    {value && getTranslation(value).toLocaleUpperCase()}
                </span>
            ),
            title: getTranslation('Status'),
        },
        {
            dataIndex: 'requesterName',
            title: getTranslation('Applicant'),
        },
        {
            dataIndex: 'auditorName',
            render: (text) =>
                text && <ButtonGray label={text} disabled className="action-btn__verify" />,
            title: getTranslation('Auditor'),
        },
        {
            dataIndex: 'operatorName',
            render: (text, record) => (
                <VerifyButton
                    operator={record.operatorName}
                    auditor={record.auditorName}
                    type={record.transactionType}
                    transactionMonitoringRecord={record}
                    orderStatus={record.orderStatus}
                />
            ),
            title: getTranslation('Operator'),
        },
    ];

    return columns
        .filter((column) => _.map(selectedColumns, 'key').includes(column.dataIndex))
        .map((colProps) => ({
            ...colProps,
            sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
        }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);

export const transactionMonitoringRecordsExport = ({ onlyMeta }) => {
    if (onlyMeta) {
        return {
            remarks: [
                {
                    dataIndex: 'requesterRemarks',
                    title: getTranslation('Applicant Remark'),
                },
                {
                    dataIndex: 'auditorRemarks',
                    title: getTranslation('Auditor Remark'),
                },
                {
                    dataIndex: 'processorRemarks',
                    title: getTranslation('Operator Remark'),
                },
            ],
        };
    }

    let messageArray = [];
    return messageArray;
};
