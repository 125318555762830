import { isTestEnvironment } from '../common/helpers/misc';
import adminReducer from '../components/admin/adminReducer';
import affiliateReducer from '../components/affiliate/affiliateReducer';
import announcementReducer from '../components/announcement/announcementReducer';
import balanceReducer from '../components/balance/balanceReducer';
import betReducer from '../components/bet/betReducer';
import bonusReducer from '../components/bonus/bonusReducer';
import changePasswordReducer from '../components/profile/changePasswordReducer';
import countryReducer from '../components/internationalisation/countryReducer';
import errorsReducer from '../common/reducers/errorsReducer';
import fileSystemReducer from '../components/file-system/fileSystemReducer';
import newsReducer from '../components/news/newsReducer';
import operationsReducer from '../common/reducers/operationsReducer';
import paymentReducer from '../components/payments/paymentReducer';
import playersReducer from '../components/players/playersReducer';
import pushNotificationReducer from '../components/push-notification/pushNotificationReducer';
import rebateReducer from '../components/rebate/rebateReducer';
import referralReducer from '../components/referral/referralReducer';
import reportReducer from '../components/reports/reportReducer';
import rewardsManagementReducer from '../components/rewards-management/rewardsManagementReducer';
import timezoneReducer from '../common/reducers/timezoneReducer';
import transactionHistoryReducer from '../components/transaction-history/transactionHistoryReducer';
import transactionMonitoringReducer from '../components/transaction-monitoring/transactionMonitoringReducer';
import userReducer from '../common/reducers/userReducer';
import vipReducer from '../components/vip/vipReducer';
import whatsNewReducer from '../components/whats-new/whatsNewReducer';
import hotEventsReducer from '../components/hot-events/hotEventsReducer';

/**
 * @typedef Action
 * @type {object}
 * @property {string} type Action type.
 * @property {*} payload Action payload.
 *
 */

/**
 * Function is super basic analog to Redux's combineReducers().
 * @param {Object} state Current application state.
 * @param {Action} action Dispatched action.
 * @returns New application state.
 */
export default function appReducer(state, action) {
    if (isTestEnvironment()) console.log('ACTION: ', action);
    const {
        operation,
        error,
        user,
        transactionHistory,
        players,
        news,
        transactionMonitoring,
        reports,
        balance,
        payments,
        bet,
        bonus,
        announcement,
        administration,
        country,
        timezone,
        profile,
        referral,
        rewardsManagement,
        pushNotification,
        rebate,
        whatsNew,
        fileSystem,
        vip,
        affiliate,
        hotEvents,
    } = state;

    const updatedState = {
        administration: adminReducer(administration, action),
        affiliate: affiliateReducer(affiliate, action),
        announcement: announcementReducer(announcement, action),
        balance: balanceReducer(balance, action),
        bet: betReducer(bet, action),
        bonus: bonusReducer(bonus, action),
        country: countryReducer(country, action),
        error: errorsReducer(error, action),
        fileSystem: fileSystemReducer(fileSystem, action),
        news: newsReducer(news, action),
        operation: operationsReducer(operation, action),
        payments: paymentReducer(payments, action),
        players: playersReducer(players, action),
        profile: changePasswordReducer(profile, action),
        pushNotification: pushNotificationReducer(pushNotification, action),
        rebate: rebateReducer(rebate, action),
        referral: referralReducer(referral, action),
        reports: reportReducer(reports, action),
        rewardsManagement: rewardsManagementReducer(rewardsManagement, action),
        timezone: timezoneReducer(timezone, action),
        transactionHistory: transactionHistoryReducer(transactionHistory, action),
        transactionMonitoring: transactionMonitoringReducer(transactionMonitoring, action),
        user: userReducer(user, action),
        vip: vipReducer(vip, action),
        whatsNew: whatsNewReducer(whatsNew, action),
        hotEvents: hotEventsReducer(hotEvents, action),
    };
    if (isTestEnvironment()) console.log('UPDATED STATE: ', updatedState);
    return updatedState;
}
