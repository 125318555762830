export const ACTIONS = {
    FILTERS_DROP_ALL: 'REFERRAL_INQUIRY_FILTERS_DROP_ALL',
    FILTER_SET: 'REFERRAL_INQUIRY_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'REFERRAL_INQUIRY_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'REFERRAL_INQUIRY_TABLE_PAGE_SIZE_CHANGE',
    },
    REFERRAL_INQUIRY_GET: 'REFERRAL_INQUIRY_GET',
    REFERRAL_INQUIRY_GET_FAILURE: 'REFERRAL_INQUIRY_GET_FAILURE',
    REFERRAL_INQUIRY_GET_SUCCESS: 'REFERRAL_INQUIRY_GET_SUCCESS',
};

export const referralInquiryGet = (dispatch) =>
    dispatch({
        type: ACTIONS.REFERRAL_INQUIRY_GET,
    });

export const referralInquiryGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRAL_INQUIRY_GET_FAILURE,
    });

export const referralInquiryGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRAL_INQUIRY_GET_SUCCESS,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const referralInquiryFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });
