export const ACTIONS = {
    COLUMNS_SELECT: 'WITHDRAWAL_RECORDS_COLUMNS_SELECT',
    FILTERS_DROP_ALL: 'WITHDRAWAL_RECORDS_FILTERS_DROP_ALL',
    FILTER_SET: 'WITHDRAWAL_RECORDS_FILTER_SET',
    OPERATION: 'WITHDRAWAL_RECORDS_OPERATION',
    OPERATION_FAILURE: 'WITHDRAWAL_RECORDS_OPERATION_FAILURE',
    OPERATION_SUCCESS: 'WITHDRAWAL_RECORDS_OPERATION_SUCCESS',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'WITHDRAWAL_RECORDS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'WITHDRAWAL_RECORDS_TABLE_PAGE_SIZE_CHANGE',
    },
    VERIFY_INFO_SET: 'WITHDRAWAL_RECORDS_VERIFY_INFO_SET',
    VERIFY_MODAL_CLOSE: 'WITHDRAWAL_RECORDS_VERIFY_MODAL_CLOSE',
    VERIFY_MODAL_OPEN: 'WITHDRAWAL_RECORDS_VERIFY_MODAL_OPEN',
    WITHDRAWAL_RECORDS_GET: 'WITHDRAWAL_RECORDS_GET',
    WITHDRAWAL_RECORDS_GET_FAILURE: 'WITHDRAWAL_RECORDS_GET_FAILURE',
    WITHDRAWAL_RECORDS_GET_SUCCESS: 'WITHDRAWAL_RECORDS_GET_SUCCESS',
};

export const withdrawalRecordsGet = (dispatch) =>
    dispatch({
        type: ACTIONS.WITHDRAWAL_RECORDS_GET,
    });

export const withdrawalRecordsGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WITHDRAWAL_RECORDS_GET_SUCCESS,
    });

export const withdrawalRecordsGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WITHDRAWAL_RECORDS_GET_FAILURE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const withdrawalRecordsFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const openVerifyModal = (dispatch) =>
    dispatch({
        type: ACTIONS.VERIFY_MODAL_OPEN,
    });

export const closeVerifyModal = (dispatch) =>
    dispatch({
        type: ACTIONS.VERIFY_MODAL_CLOSE,
    });

export const performWithdrawalRecordOperation = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OPERATION,
    });

export const withdrawalRecordOperationSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.OPERATION_SUCCESS,
    });

export const withdrawalRecordOperationFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OPERATION_FAILURE,
    });

export const setWithdrawalRecordVerifyInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.VERIFY_INFO_SET,
    });

export const selectColumns = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.COLUMNS_SELECT,
    });
