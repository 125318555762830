import React from 'react';

import { Form } from 'antd';

import {
    EwalletDropFilters,
    EwalletFilterSet,
    deactivatedEwalletSetupModalClose,
} from './DeactivatedEwalletActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { useGetDeactivatedEwalletsAsyncEndpoint } from '../../../../services/ewalletService';
import { useStore } from '../../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import EwalletSetupFilters from '../EwalletSetupFilters';
import DeactivatedEwalletAccountResultsTable from './table/DeactivatedEwalletAccountResultsTable';
import DeactivatedEwalletSetupModal from './DeactivatedEwalletSetupModal';

const DeactivatedEwallet = () => {
    const [state, dispatch] = useStore();

    const modalData = getStateProp(state, 'payments.deactivatedEwalletAccount.modal');
    const deactivatedEwallet = getStateProp(state, 'payments.deactivatedEwalletAccount');

    // ============================================
    // METHODS
    // ============================================

    const onCloseModal = () => deactivatedEwalletSetupModalClose(dispatch);

    const setFilter = (filter, value) => EwalletFilterSet(dispatch, { filter, value });

    const refreshTable = getStateProp(
        state,
        'payments.deactivatedEwalletAccount.fetchData',
        () => {},
    );

    const fetchAll = () => {
        EwalletDropFilters(dispatch);
        handleGetDeactivatedBankCards();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetDeactivatedBankCards] = useGetDeactivatedEwalletsAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <div>
            <Form onFinish={handleGetDeactivatedBankCards}>
                <EwalletSetupFilters
                    setFilter={setFilter}
                    bankAccount={deactivatedEwallet}
                    refreshTable={refreshTable}
                />
                <ActionButtons onDropFilters={fetchAll} />
            </Form>
            <DeactivatedEwalletAccountResultsTable loading={loading} />
            {modalData.visible && (
                <DeactivatedEwalletSetupModal modalData={modalData} onCancel={onCloseModal} />
            )}
        </div>
    );
};

export default DeactivatedEwallet;
