export const ACTIONS = {
    DAILY_LOGIN_SETUP: 'DAILY_LOGIN_SETUP',
    DAILY_LOGIN_SETUP_FAILURE: 'DAILY_LOGIN_SETUP_FAILURE',
    DAILY_LOGIN_SETUP_INFO_SET: 'DAILY_LOGIN_SETUP_INFO_SET',
    DAILY_LOGIN_SETUP_RESET: 'DAILY_LOGIN_SETUP_RESET',
    DAILY_LOGIN_SETUP_SUCCESS: 'DAILY_LOGIN_SETUP_SUCCESS',
};

export const onDailyLoginSetupInfoSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DAILY_LOGIN_SETUP_INFO_SET,
    });

export const onDailyLoginSetupReset = (dispatch) =>
    dispatch({
        type: ACTIONS.DAILY_LOGIN_SETUP_RESET,
    });

export const dailyLoginSetup = (dispatch) =>
    dispatch({
        type: ACTIONS.DAILY_LOGIN_SETUP,
    });

export const dailyLoginSetupSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.DAILY_LOGIN_SETUP_SUCCESS,
    });

export const dailyLoginSetupFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DAILY_LOGIN_SETUP_FAILURE,
    });
