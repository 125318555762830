export const ACTIONS = {
    BONUS_DESELECTED: 'BONUS_DESELECTED',
    BONUS_MANAGEMENT_ACTIVE_LIST_GET: 'BONUS_MANAGEMENT_ACTIVE_LIST_GET',
    BONUS_MANAGEMENT_ACTIVE_LIST_GET_FAILURE: 'BONUS_MANAGEMENT_ACTIVE_LIST_GET_FAILURE',
    BONUS_MANAGEMENT_ACTIVE_LIST_GET_SUCCESS: 'BONUS_MANAGEMENT_ACTIVE_LIST_GET_SUCCESS',
    BONUS_MANAGEMENT_BY_ID_GET: 'BONUS_MANAGEMENT_BY_ID_GET',
    BONUS_MANAGEMENT_BY_ID_GET_FAILURE: 'BONUS_MANAGEMENT_BY_ID_GET_FAILURE',
    BONUS_MANAGEMENT_BY_ID_GET_SUCCESS: 'BONUS_MANAGEMENT_BY_ID_GET_SUCCESS',
    BONUS_MANAGEMENT_CLEAR_CACHE: 'BONUS_MANAGEMENT_CLEAR_CACHE',
    BONUS_MANAGEMENT_DUPLICATE_BY_LANGUAGE: 'BONUS_MANAGEMENT_DUPLICATE_BY_LANGUAGE',
    BONUS_MANAGEMENT_FILTERS_DROP_ALL: 'BONUS_MANAGEMENT_FILTERS_DROP_ALL',
    BONUS_MANAGEMENT_FILTER_SET: 'BONUS_MANAGEMENT_FILTER_SET',
    BONUS_MANAGEMENT_INFO_MULTI_SET: 'BONUS_MANAGEMENT_INFO_MULTI_SET',
    BONUS_MANAGEMENT_INFO_SET: 'BONUS_MANAGEMENT_INFO_SET',
    BONUS_MANAGEMENT_INFO_SET_BY_LANGUAGE: 'BONUS_MANAGEMENT_INFO_SET_BY_LANGUAGE',
    BONUS_MANAGEMENT_LIST_GET: 'BONUS_MANAGEMENT_LIST_GET',
    BONUS_MANAGEMENT_LIST_GET_FAILURE: 'BONUS_MANAGEMENT_LIST_GET_FAILURE',
    BONUS_MANAGEMENT_LIST_GET_SUCCESS: 'BONUS_MANAGEMENT_LIST_GET_SUCCESS',
    BONUS_MANAGEMENT_SET_CURRENT_SETP: 'BONUS_MANAGEMENT_SET_CURRENT_SETP',
    BONUS_MANAGEMENT_SORTING_ORDER_SET: 'BONUS_MANAGEMENT_SORTING_ORDER_SET',
    BONUS_MANAGEMENT_SORTING_UPDATE: 'BONUS_MANAGEMENT_SORTING_UPDATE',
    BONUS_MANAGEMENT_SORTING_UPDATE_FAILURE: 'BONUS_MANAGEMENT_SORTING_UPDATE_FAILURE',
    BONUS_MANAGEMENT_SORTING_UPDATE_SUCCESS: 'BONUS_MANAGEMENT_SORTING_UPDATE_SUCCESS',
    BONUS_MANAGEMENT_STATUS_SET: 'BONUS_MANAGEMENT_STATUS_SET',
    BONUS_MANAGEMENT_STATUS_SET_FAILURE: 'BONUS_MANAGEMENT_STATUS_SET_FAILURE',
    BONUS_MANAGEMENT_STATUS_SET_SUCCESS: 'BONUS_MANAGEMENT_STATUS_SET_SUCCESS',
    BONUS_MANAGEMENT_UPDATE_FAILURE: 'BONUS_MANAGEMENT_UPDATE_FAILURE',
    BONUS_MANAGEMENT_UPDATE_SUCCESS: 'BONUS_MANAGEMENT_UPDATE_SUCCESS',
    BONUS_MANGEMENT_EDIT_MODAL_CLOSE: 'BONUS_MANGEMENT_EDIT_MODAL_CLOSE',
    BONUS_MANGEMENT_EDIT_MODAL_OPEN: 'BONUS_MANGEMENT_EDIT_MODAL_OPEN',
    BONUS_MANGEMENT_VIEW_MODAL_CLOSE: 'BONUS_MANGEMENT_VIEW_MODAL_CLOSE',
    BONUS_MANGEMENT_VIEW_MODAL_OPEN: 'BONUS_MANGEMENT_VIEW_MODAL_OPEN',
    BONUS_SELECTED: 'BONUS_SELECTED',
    EDIT_BONUS_MANAGEMENT_LANGUAGE_DROP: 'EDIT_BONUS_MANAGEMENT_LANGUAGE_DROP',
    EDIT_BONUS_MANAGEMENT_LANGUAGE_SET: 'EDIT_BONUS_MANAGEMENT_LANGUAGE_SET',
    PAGINATION: {
        BONUS_MANAGEMENT_TABLE_PAGE_CHANGE: 'BONUS_MANAGEMENT_TABLE_PAGE_CHANGE',
        BONUS_MANAGEMENT_TABLE_PAGE_SIZE_CHANGE: 'BONUS_MANAGEMENT_TABLE_PAGE_SIZE_CHANGE',
    },
};

export const bonusEditModalOpen = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANGEMENT_EDIT_MODAL_OPEN,
    });

export const bonusEditModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANGEMENT_EDIT_MODAL_CLOSE,
    });

export const bonusViewModalOpen = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANGEMENT_VIEW_MODAL_OPEN,
    });

export const bonusViewModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANGEMENT_VIEW_MODAL_CLOSE,
    });

export const setBonusDescriptionInfoByLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANAGEMENT_INFO_SET_BY_LANGUAGE,
    });

export const setBonusDescriptionInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANAGEMENT_INFO_SET,
    });

export const setMultiBonusDescriptionInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANAGEMENT_INFO_MULTI_SET,
    });

//#region select bonus
export const setBonusSelected = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_SELECTED,
    });
export const setBonusDeselected = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_DESELECTED,
    });
//#endregion

//#region Get Banner Record List
export const bonusManagementListGet = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANAGEMENT_LIST_GET,
    });

export const bonusManagementListGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANAGEMENT_LIST_GET_SUCCESS,
    });

export const bonusManagementListGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANAGEMENT_LIST_GET_FAILURE,
    });
//#endregion

//#region Set Banner Status
export const bonusManagementStatusSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANAGEMENT_STATUS_SET,
    });

export const bonusManagementStatusSetSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANAGEMENT_STATUS_SET_SUCCESS,
    });

export const bonusManagementStatusSetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANAGEMENT_STATUS_SET_FAILURE,
    });
//#endregion

//#region Update Banner
export const bonusManagementUpdateSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANAGEMENT_UPDATE_SUCCESS,
    });

export const bonusManagementUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANAGEMENT_UPDATE_FAILURE,
    });
//#endregion

// clear some banner data temporary store
export const bonusManagementClearCache = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANAGEMENT_CLEAR_CACHE,
    });

export const bonusManagementFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANAGEMENT_FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANAGEMENT_FILTERS_DROP_ALL,
    });

//#region banner change page
export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.BONUS_MANAGEMENT_TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.BONUS_MANAGEMENT_TABLE_PAGE_CHANGE,
    });
//#endregion

//#region Get Banner Record By Id
export const bonusManagementByIdGet = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANAGEMENT_BY_ID_GET,
    });

export const bonusManagementByIdGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANAGEMENT_BY_ID_GET_SUCCESS,
    });

export const bonusManagementByIdGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANAGEMENT_BY_ID_GET_FAILURE,
    });
//#endregion

export const setEditBonusDescriptionLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.EDIT_BONUS_MANAGEMENT_LANGUAGE_SET,
    });

export const dropEditBonusDescriptionLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.EDIT_BONUS_MANAGEMENT_LANGUAGE_DROP,
    });

export const bonusManagementActiveListGet = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANAGEMENT_ACTIVE_LIST_GET,
    });

export const bonusManagementActiveListGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANAGEMENT_ACTIVE_LIST_GET_SUCCESS,
    });

export const bonusManagementActiveListGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANAGEMENT_ACTIVE_LIST_GET_FAILURE,
    });

export const bonusManagementSortingOrderSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANAGEMENT_SORTING_ORDER_SET,
    });

export const bonusManagementSortingUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANAGEMENT_SORTING_UPDATE,
    });

export const bonusManagementSortingUpdateSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANAGEMENT_SORTING_UPDATE_SUCCESS,
    });

export const bonusManagementSortingUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANAGEMENT_SORTING_UPDATE_FAILURE,
    });

export const bonusManagementDuplicateByLanguage = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANAGEMENT_DUPLICATE_BY_LANGUAGE,
    });

export const setCurrentStep = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANAGEMENT_SET_CURRENT_SETP,
    });
};
