import React from 'react';

import { RenderDateFormat } from '../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../common/constants/api';
import { changePageNumber, changePageSize, selectRecord } from '../fileSystemActions';
import { getStatusColor, sortTableByDataType } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import ColumnActionButtons from './operations/ColumnActionButtons';

export const getColumns = () => {
    const columns = [
        {
            dataIndex: 'exportType',
            title: getTranslation('Export Type'),
        },
        {
            dataIndex: 'startTime',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Export Start Time'),
        },
        {
            dataIndex: 'endTime',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Export End Time'),
        },
        {
            dataIndex: 'status',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>
                    {getTranslation(value).toLocaleUpperCase()}
                </span>
            ),
            title: getTranslation('Status'),
        },
        {
            dataIndex: 'remarks',
            title: getTranslation('Remark'),
        },
        {
            dateIndex: 'action',
            render: (value, record) => <ColumnActionButtons fileInfo={record} />,
            title: getTranslation('Action'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getRowSelectionProps = (dispatch, selectedFiles) => ({
    columnWidth: 30,
    fixed: true,
    onChange: onSelectRecord(dispatch),
    selectedRowKeys: selectedFiles,
});

const onSelectRecord = (dispatch) => (rowKeys) => {
    console.log(rowKeys, dispatch);
    selectRecord(dispatch, rowKeys);
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
