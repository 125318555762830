import { message } from 'antd';
import _ from 'lodash';

import { FORM_ASPECT } from './constants';
import { LANGUAGES } from '../../common/constants/localization';
import { getSupportedLanguages, getTranslation } from '../../common/helpers/locale';
import { textEditorImageFileSizeCheck } from '../../common/helpers/misc';

export const formValidation = (bonus, currentStep, isShowOnApp, aspect) => {
    if (currentStep === 0) {
        return (
            validateTextEditorImage(bonus.details) &&
            !validateBonusCode(bonus.code) &&
            !validateCurrency(bonus.currencies) &&
            !validateDate(
                bonus.bonusValidDateFrom,
                bonus.bonusValidDateTo,
                'Start Date/ End Date',
            ) &&
            !validateTurnoverRequirement(bonus.turnoverCondition || 0) &&
            validateBonusName(bonus.details) &&
            (isShowOnApp ? validateContent(bonus.details, aspect) : true)
        );
    } else if (currentStep === 1) {
        return (
            validateBonusAmount(bonus.isFixedBonus, bonus.isDepositBonus, bonus.hasJoinButton) &&
            validateRepeatCount(bonus.isRepeatable, bonus.repeatableCount)
        );
    } else if (currentStep === 2) {
        return bonus.checkingDateType === 'SpecificDate'
            ? !validateDate(bonus.conditionDateFrom, bonus.conditionDateTo, 'Custome Date')
            : true;
    }
};

export const validateBonusAmount = (isFixedBonus, isDepositBonus, hasJoinButton) => {
    if (!isFixedBonus && !isDepositBonus && hasJoinButton) {
        message.warning(
            getTranslation(
                'Please enable deposit bonus. If you wish to setup Bonus Amount By Percentage',
            ),
        );
        return false;
    } else return true;
};

export const bannerFormValidation = (banner, aspect) => {
    return (
        validateContent(banner.details, aspect) &&
        !validateCurrency(banner.currencyCode) &&
        validateDateTime(banner.startDate, banner.endDate)
    );
};

const validateContent = (content, aspect) => {
    if (_.isEmpty(content)) {
        message.error(getTranslation('Must have atleast one language!'));
        return false;
    } else {
        return _.every(
            _.map(
                content,
                ({ imageInBase64, imageUrl, imageExtension, imageUrlCN, ...content }) => {
                    if (
                        aspect === FORM_ASPECT.CREATE
                            ? imageInBase64 === null || imageInBase64 === undefined
                            : (imageInBase64 === null || imageInBase64 === undefined) &&
                              imageUrl === null
                    ) {
                        message.error(getTranslation('Please upload image in all languages'));
                        return false;
                    } else if (_.some(content, _.isEmpty)) {
                        message.error(
                            getTranslation('Please fill in the content in all languages'),
                        );
                        return false;
                    } else {
                        return true;
                    }
                },
            ),
        );
    }
};

const validateBonusCode = (bonusCode) =>
    _.isEmpty(bonusCode) && message.error(getTranslation('Please input bonus code'));

const validateRepeatCount = (isRepeatable, repeatableCount) => {
    if (
        isRepeatable &&
        (repeatableCount === '' ||
            isNaN(parseInt(repeatableCount)) ||
            parseInt(repeatableCount) === 0)
    ) {
        message.error(getTranslation('Please input integer for repeat limit'));
        return false;
    } else return true;
};

const validateCurrency = (currencies) =>
    _.isEmpty(currencies) && message.error(getTranslation('Please choose at least one currency'));

const validateBonusName = (bonusContent) => {
    return _.every(
        _.map(bonusContent, (content) => {
            if (!content.bonusName) {
                message.error(getTranslation('Please fill in the bonus name in all languages'));
                return false;
            } else {
                return true;
            }
        }),
    );
};

const validateTextEditorImage = (content) => {
    const imageReg = /<\s*img[^>]*>/g;

    const imageValidation = _.map(content, (bonusContent) => {
        return (
            textEditorImageFileSizeCheck(
                bonusContent.bonusDetail && bonusContent.bonusDetail.match(imageReg),
            ) &&
            textEditorImageFileSizeCheck(
                bonusContent.termCondition && bonusContent.termCondition.match(imageReg),
            )
        );
    });

    return _.every(imageValidation, (flag) => flag === true);
};

export const getDefaultContent = () => {
    return getSupportedLanguages().length === LANGUAGES.length
        ? [
              {
                  bonusDetail: '',
                  imageInBase64: null,
                  languageCode: 'en-US',
                  termCondition: '',
              },
              {
                  bonusDetail: '',
                  imageInBase64: null,
                  languageCode: 'zh-CN',
                  termCondition: '',
              },
          ]
        : _.map(getSupportedLanguages(), (language) => {
              return {
                  bonusDetail: '',
                  imageInBase64: null,
                  languageCode: language.value,
                  termCondition: '',
              };
          });
};

export const getBannerDefaultContent = () => {
    return getSupportedLanguages().length === LANGUAGES.length
        ? [
              {
                  bannerName: '',
                  bannerUrl: '',
                  imageInBase64: null,
                  languageCode: 'en-US',
                  remark: '',
              },
              {
                  bannerName: '',
                  bannerUrl: '',
                  imageInBase64: null,
                  languageCode: 'zh-CN',
                  remark: '',
              },
          ]
        : _.map(getSupportedLanguages(), (language) => {
              return {
                  bannerName: '',
                  bannerUrl: '',
                  imageInBase64: null,
                  languageCode: language.value,
                  remark: '',
              };
          });
};

export const validateDate = (dateFrom, dateTo, title) =>
    (_.isEmpty(dateFrom) || _.isEmpty(dateTo)) &&
    message.error(getTranslation(`Please input ${title}`));

export const validateTurnoverRequirement = (turnoverCondition) =>
    turnoverCondition < 0 &&
    message.error(getTranslation('Turnover Requirement must greater than 0'));

const validateDateTime = (startDate, endDate) => {
    if (_.isEmpty(startDate) && _.isEmpty(endDate)) {
        message.error(getTranslation('Please select valid date'));
        return false;
    }

    return true;
};
