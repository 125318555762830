import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { VIEW_MODES } from '../constants';
import { getTranslation } from '../../../common/helpers/locale';

const ViewModeFilter = ({ onFilterChange, value }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('viewMode', value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            className="filter"
            placeholder={getTranslation('View Mode')}
            onChange={handleFilterChange}
            value={value}
            allowClear={true}
        >
            {_.map(VIEW_MODES, (type) => (
                <Select.Option value={type} key={type}>
                    {type.toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
};

export default ViewModeFilter;
