export const SYSTEM_LANGUAGES = [
    {
        code: 'CN',
        country: 'cn',
        name: '简体中文',
        value: 'zh-CN',
    },
    {
        code: 'EN',
        country: 'my',
        name: 'English',
        value: 'en-US',
    },
    // {
    //     code: 'JA',
    //     country: 'jp',
    //     name: '日本語',
    //     value: 'ja-JP',
    // },
    // {
    //     code: 'KO',
    //     country: 'kr',
    //     name: '한국어',
    //     value: 'ko-KR',
    // },
];

export const CURRENCIES = [
    {
        country: 'th',
        countryId: '215',
        currency: 'THB',
    },
    {
        country: 'id',
        countryId: '100',
        currency: 'IDR',
    },
    {
        country: 'vn',
        countryId: '238',
        currency: 'VND',
    },
    {
        country: 'cn',
        countryId: '48',
        currency: 'CNY',
    },
    // {
    //     country: 'tw',
    //     countryId: '',
    //     currency: 'TWD',
    // },
    {
        country: 'my', //Malaysia
        countryId: '157',
        currency: 'MYR',
    },
    // {
    //     country: 'jp',
    //     countryId: '',
    //     currency: 'JPY',
    // },
    // {
    //     country: 'kr',
    //     countryId: '',
    //     currency: 'KRW',
    // },
    {
        country: 'us',
        countryId: '',
        currency: 'USD',
    },
];

export const CURRENCY_SETTINGS = new Map([
    [
        'UUS',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: 'UUS',
            thousand: ',',
        },
    ],
    [
        'CNY',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: 'Ұ',
            thousand: ',',
        },
    ],
    [
        'JPY',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: '¥',
            thousand: ',',
        },
    ],
    [
        'TWD',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: ' NT$',
            thousand: ',',
        },
    ],
    [
        'KRW',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: '₩',
            thousand: ',',
        },
    ],
    [
        'VND',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: '₫',
            thousand: ',',
        },
    ],
    [
        'IDR',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: 'Rp',
            thousand: ',',
        },
    ],
    [
        'THB',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: '฿',
            thousand: ',',
        },
    ],
    [
        'USD',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: '$',
            thousand: ',',
        },
    ],
    [
        'MYR',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: 'RM',
        },
    ],
    [
        'EUR',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: '€',
            thousand: ',',
        },
    ],
]);

export const TIMEZONE = {
    ADDIS_ABABA: {
        LOCATION: 'Africa/Addis_Ababa',
        OFFSET: '+0300',
    },
    ALMATY: {
        LOCATION: 'Asia/Almaty',
        OFFSET: '+0600',
    },
    APIA: {
        LOCATION: 'Pacific/Apia',
        OFFSET: '+1400',
    },
    ARAGUAINA: {
        LOCATION: 'America/Araguaina',
        OFFSET: '-0300',
    },
    ATIKOKAN: {
        LOCATION: 'America/Atikokan',
        OFFSET: '-0500',
    },
    BAKU: {
        LOCATION: 'Asia/Baku',
        OFFSET: '+0400',
    },
    BANGKOK: {
        LOCATION: 'Asia/Bangkok',
        OFFSET: '+0700',
    },
    BELIZEN: {
        LOCATION: 'America/Belizen',
        OFFSET: '-0600',
    },
    CANADA: {
        LOCATION: 'Brazil/West',
        OFFSET: '-0400',
    },
    CAPE_VERDE: {
        LOCATION: 'Atlantic/Cape_Verde',
        OFFSET: '-0100',
    },
    CRESTON: {
        LOCATION: 'America/Creston',
        OFFSET: '-0700',
    },
    DILI: {
        LOCATION: 'Asia/Dili',
        OFFSET: '+0900',
    },
    DUSHANBE: {
        LOCATION: 'Asia/Dushanbe',
        OFFSET: '+0500',
    },
    ENDERBURY: {
        LOCATION: 'Pacific/Enderbury',
        OFFSET: '+1300',
    },
    GMT_10: {
        LOCATION: 'Etc/GMT+10',
        OFFSET: '-1000',
    },
    GMT_11: {
        LOCATION: 'Etc/GMT+11',
        OFFSET: '-1100',
    },
    GMT_12: {
        LOCATION: 'Etc/GMT+12',
        OFFSET: '-1200',
    },
    GMT_8: {
        LOCATION: 'Etc/GMT+8',
        OFFSET: '-0800',
    },
    GMT_9: {
        LOCATION: 'Etc/GMT+9',
        OFFSET: '-0900',
    },
    KAMCHATKA: {
        LOCATION: 'Asia/Kamchatka',
        OFFSET: '+1200',
    },
    KIGALI: {
        LOCATION: 'Africa/Kigali',
        OFFSET: '+0200',
    },
    MAGADAN: {
        LOCATION: 'Asia/Magadan',
        OFFSET: '+1100',
    },
    MALAYSIA: {
        LOCATION: 'Asia/Kuala_Lumpur',
        OFFSET: '+0800',
    },
    NIAMEY: {
        LOCATION: 'Africa/Niamey',
        OFFSET: '+0100',
    },
    NORONHA: {
        LOCATION: 'America/Noronha',
        OFFSET: '-0200',
    },
    QUEENSLAND: {
        LOCATION: 'Australia/Queensland',
        OFFSET: '+1000',
    },
    UTC: {
        LOCATION: 'Etc/GMT+0',
        OFFSET: '+0000',
    },
};

export const LANGUAGES = [
    {
        code: 'CN',
        country: 'cn',
        name: '简体中文',
        value: 'zh-CN',
    },
    {
        code: 'EN',
        country: 'my',
        name: 'English',
        value: 'en-US',
    },
    {
        code: 'TH',
        country: 'th',
        name: 'ภาษาไทย',
        value: 'tr-TR', // instead of 'th-TH' to fix backend thai language issue
    },
    {
        code: 'ID',
        country: 'id',
        name: 'Bahasa Indonesia',
        value: 'id-ID',
    },
    {
        code: 'VN',
        country: 'vn',
        name: 'Vietnam',
        value: 'vi-VN',
    },
];
