import React, { useEffect, useState } from 'react';

import { Modal } from 'antd';

import { ANNOUNCEMENT_STATUSES } from '../../../constants';
import { closeMessageVerifyModal } from '../personalMessageActions';
import { getStateProp } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useStore } from '../../../../../store/StateProvider';
import { useUpdateMessageAsyncEndpoint } from '../../../../../services/messageService';
import MessageVerificationForm from '../../verify-modal/MessageVerificationForm';

const VerifyMessageModal = ({ visible, onCancel }) => {
    const [state, dispatch] = useStore();
    const [operation, setOperation] = useState(null);

    const selectedMessage = getStateProp(
        state,
        'announcement.announcementVerification.personalMessage.selectedMessage',
        {},
    );
    const refreshTable = getStateProp(
        state,
        'announcement.announcementVerification.personalMessage.fetchData',
        () => {},
    );

    // ============================================
    // METHODS
    // ============================================

    const verifyMessage = () => {
        setOperation(ANNOUNCEMENT_STATUSES.VERIFIED);
        handleUpdateMessage();
    };

    const rejectMessage = () => {
        setOperation(ANNOUNCEMENT_STATUSES.REJECTED);
        handleUpdateMessage();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [verifying, handleUpdateMessage, messageUpdated] = useUpdateMessageAsyncEndpoint(
        selectedMessage,
        operation,
    );

    useEffect(() => {
        if (messageUpdated) {
            closeMessageVerifyModal(dispatch);
            refreshTable();
        }
    }, [dispatch, messageUpdated, refreshTable]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            width={1080}
            visible={visible}
            title={getTranslation('Verify Personal Message')}
            className="message__verify-modal"
            onCancel={onCancel}
            closable={false}
            footer={null}
        >
            <MessageVerificationForm
                onVerify={verifyMessage}
                onCancel={onCancel}
                selectedMessage={selectedMessage}
                verifying={verifying && operation === ANNOUNCEMENT_STATUSES.VERIFIED}
                rejecting={verifying && operation === ANNOUNCEMENT_STATUSES.REJECTED}
                onReject={rejectMessage}
            />
        </Modal>
    );
};

export default VerifyMessageModal;
