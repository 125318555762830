import { ACTIONS } from './groupAuthoritySettingActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function groupAuthoritySettingReducer(
    groupAuthoritySetting = {
        deleteModalVisible: false,
        groupAccessRecord: {
            selectedMenus: {},
        },
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.GROUP_ACCESS_RECORD_RESET:
            return {
                ...groupAuthoritySetting,
                groupAccessRecord: {
                    selectedMenus: {},
                },
            };

        case ACTIONS.EDITOR_INFO_SET: {
            return {
                ...groupAuthoritySetting,
                groupAccessRecord: {
                    ...groupAuthoritySetting.groupAccessRecord,
                    [payload.name]: payload.value,
                },
            };
        }

        case ACTIONS.SELECTED_MENUS_SET:
            return {
                ...groupAuthoritySetting,
                groupAccessRecord: {
                    ...groupAuthoritySetting.groupAccessRecord,
                    selectedMenus: {
                        ...groupAuthoritySetting.groupAccessRecord.selectedMenus,
                        [payload.name]: payload.value,
                    },
                },
            };

        case ACTIONS.GROUP_ACCESS_LIST_GET_SUCCESS:
            return {
                ...groupAuthoritySetting,
                ...payload,
            };

        case ACTIONS.GROUP_ACCESS_RECORD_GET_SUCCESS: {
            return {
                ...groupAuthoritySetting,
                groupAccessRecord: payload,
            };
        }

        case ACTIONS.GROUP_ACCESS_RECORD_DELETE_MODAL_OPEN:
            return {
                ...groupAuthoritySetting,
                deleteAccessGroupId: payload,
                deleteModalVisible: true,
            };
        case ACTIONS.GROUP_ACCESS_RECORD_DELETE_MODAL_CLOSE:
            return {
                ...groupAuthoritySetting,
                deleteAccessGroupId: undefined,
                deleteModalVisible: false,
            };

        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...groupAuthoritySetting,
                paging: {
                    ...groupAuthoritySetting.paging,
                    pageNo:
                        payload > groupAuthoritySetting.paging.pageCount
                            ? groupAuthoritySetting.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...groupAuthoritySetting,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };
        case ACTIONS.GROUP_ACCESS_RECORD_GET:
        case ACTIONS.GROUP_ACCESS_LIST_GET:
        default:
            return groupAuthoritySetting;
    }
}
