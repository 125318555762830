import { ACTIONS } from './userAuthoritySettingActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function userAuthoritySettingReducer(
    userAuthoritySetting = {
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.USER_AUTHORITY_SETTING_MODAL_OPEN:
            return {
                ...userAuthoritySetting,
                modalDetails: {
                    isVisible: true,
                    ...payload,
                },
            };
        case ACTIONS.USER_AUTHORITY_SETTING_MODAL_CLOSE:
            return {
                ...userAuthoritySetting,
                modalDetails: {
                    isVisible: false,
                },
                selectedInfo: null,
            };
        case ACTIONS.USER_AUTHORITY_SETTING_LIST_GET_SUCCESS:
            return {
                ...userAuthoritySetting,
                ...payload,
            };

        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...userAuthoritySetting,
                paging: {
                    ...userAuthoritySetting.paging,
                    pageNo:
                        payload > userAuthoritySetting.paging.pageCount
                            ? userAuthoritySetting.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...userAuthoritySetting,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.USER_GROUP_GET_SUCCESS:
            return {
                ...userAuthoritySetting,
                userGroupList: payload,
            };

        case ACTIONS.USER_STATUS_CHANGE:
        case ACTIONS.USER_STATUS_CHANGE_SUCCESS:
        case ACTIONS.USER_AUTHORITY_SETTING_INFO_SET:
        case ACTIONS.USER_AUTHORITY_SET_SUCCESS:
        case ACTIONS.USER_AUTHORITY_DETAIL_GET_SUCCESS:
        case ACTIONS.USER_INDIVIDUAL_AUTHORITY_GET_SUCCESS:
            return {
                ...userAuthoritySetting,
                selectedInfo: {
                    ...userAuthoritySetting.selectedInfo,
                    ...payload,
                },
            };
        case ACTIONS.USER_INDIVIDUAL_AUTHORITY_SETTING_INFO_SET:
            return {
                ...userAuthoritySetting,
                selectedInfo: {
                    ...userAuthoritySetting.selectedInfo,
                    selectedIndividualMenus: {
                        ...userAuthoritySetting.selectedInfo.selectedIndividualMenus,
                        [payload.name]: payload.value,
                    },
                },
            };
        case ACTIONS.USER_AUTHORITY_DETAIL_GET:
        case ACTIONS.USER_GROUP_GET:
        case ACTIONS.USER_AUTHORITY_SETTING_LIST_GET:
        case ACTIONS.USER_CREATE:
        case ACTIONS.USER_PASSWORD_CHANGE:
        case ACTIONS.USER_INDIVIDUAL_AUTHORITY_GET:
        case ACTIONS.USER_CURRENCY_CHANGE:
        default:
            return userAuthoritySetting;
    }
}
