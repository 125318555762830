import React from 'react';

import { Button, Dropdown, Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import _ from 'lodash';

import './OperationsButton.less';
import { BANNER_STATUSES } from '../../constants';
import { bannerManagementStatusSet } from '../bannerManagementActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { useSetStatusOperationAsyncEndpoint } from '../../../../services/bannerManagementService';

const OperationsButton = ({ dispatch, id, bannerStatus }) => {
    // ============================================
    // METHODS
    // ============================================

    const onSubmit = (e) => {
        bannerManagementStatusSet(dispatch, {
            changeBannerStatus: { id, status: e.key },
        });
        handleSetBannerStatus();
    };

    // ============================================
    // HOOKS
    // ============================================
    const [updating, handleSetBannerStatus] = useSetStatusOperationAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    const operationsMenu = (
        <Menu onClick={onSubmit}>
            {_.map(BANNER_STATUSES, (status) => (
                <Menu.Item key={status}>
                    <span>{getTranslation(status)}</span>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Dropdown overlay={operationsMenu} trigger={['click']} disabled={bannerStatus === 'Closed'}>
            <Button className="btn__green action-btn__operations" loading={updating}>
                {getTranslation(bannerStatus)}
                <CaretDownOutlined />
            </Button>
        </Dropdown>
    );
};

export default OperationsButton;
