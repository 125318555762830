import React, { useEffect } from 'react';

import { Card, Col, Divider, Form, Row, message } from 'antd';
import _ from 'lodash';

import './Overview.less';
import { REPORT_TYPES } from '../constants';
import { dropFilters, overviewInit } from './overviewActions';
import {
    getEndTime,
    getStartTime,
    getStateProp,
    isLessThanHalfYear,
    isLessThanOneWeek,
} from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useGetOverviewReportAsyncEndpoint } from '../../../services/companyReportService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import OverviewFilters from './OverviewFilters';
import OverviewResultsTable from './table/OverviewResultsTable';

const Overview = () => {
    const [state, dispatch] = useStore();
    const { to, from, reportType, currency } = getStateProp(state, 'reports.overview.filters', {});
    const exchangeRates = getStateProp(state, 'reports.overview.exchangeRates', []);
    const location = getStateProp(state, 'timezone.location');

    // ============================================
    // METHODS
    // ============================================

    const searchOverviewRecords = () => {
        if (!currency) {
            message.warning(getTranslation('Currency cannot be empty'));
        }

        if (
            (reportType === REPORT_TYPES.DAILY && isLessThanOneWeek(from, to)) ||
            (reportType === REPORT_TYPES.MONTHLY && isLessThanHalfYear(from, to))
        ) {
            handleGetOverviewReport();
        } else {
            message.warning(
                getTranslation(
                    'Date range not more than 1 week for daily and 6 months for monthly report',
                ),
            );
        }
    };

    const dropDetailFilter = () =>
        dropFilters(dispatch, { from: getStartTime(location), to: getEndTime(location) });

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetOverviewReport] = useGetOverviewReportAsyncEndpoint();

    useEffect(() => {
        overviewInit(dispatch, {
            from: getStartTime(location),
            to: getEndTime(location),
        });
    }, [dispatch, location]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Overview')} className="overview-card">
            <Form onFinish={searchOverviewRecords}>
                <OverviewFilters />
                <ActionButtons onDropFilters={dropDetailFilter} />
            </Form>
            <OverviewResultsTable loading={loading} />
            {!_.isEmpty(exchangeRates) && (
                <Col className="overview-exchange-rate">
                    <Divider />
                    <span className="overview-exchange-rate__header">
                        {getTranslation('Exchange Rate')}
                    </span>
                    <Col className="overview-exchange-rate__content">
                        {_.map(exchangeRates, (rate) => (
                            <Row key={rate.currency}>
                                <Col span={1}>{rate.currency}</Col>
                                <Col span={20}>{rate.rates}</Col>
                            </Row>
                        ))}
                    </Col>
                </Col>
            )}
        </Card>
    );
};

export default Overview;
