import React from 'react';

import { Input } from 'antd';

import { getTranslation } from '../../../common/helpers/locale';

const WithdrawalsAmountToFilter = ({ value, onFilterChange }) => {
    // ============================================
    // METHODS
    // ============================================

    const amountValidation = (withdrawalAmount) => {
        if (!isNaN(withdrawalAmount)) {
            onFilterChange('amountTo', withdrawalAmount);
        }
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Input
            value={value}
            className="filter"
            placeholder={getTranslation('Withdrawal Amount To')}
            onChange={(e) => amountValidation(e.target.value)}
        />
    );
};

export default WithdrawalsAmountToFilter;
