import React, { useEffect, useState } from 'react';

import { Col, Form, Input, Row } from 'antd';

import './RemarksModalContent.less';
import { ButtonGray, ButtonGreen } from '../../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../../common/helpers/locale';
import {
    useCreateRemarkAsyncEndpoint,
    useGetRemarksAsyncEndpoint,
} from '../../../../../services/playerService';
import RemarksResultsTable from './RemarksResultsTable';

const RemarksModalContent = ({ player, onCancel }) => {
    const { id, userName } = player;

    const [remark, setRemark] = useState({
        errorMsg: null,
        validateStatus: 'validating',
        value: null,
    });

    // ============================================
    // METHODS
    // ============================================

    const validateRemark = (value) => {
        if (!value) {
            return {
                errorMsg: getTranslation('Remark is required!'),
                validateStatus: 'error',
            };
        } else {
            return { errorMsg: null, validateStatus: 'success' };
        }
    };

    const handleRemarkChange = (e) => {
        setRemark({ ...validateRemark(e.target.value), value: e.target.value });
    };

    const addRemark = () => createRemark();

    // ============================================
    // HOOKS
    // ============================================

    const [remarksLoading, getRemarks] = useGetRemarksAsyncEndpoint(id);

    const [remarkAdding, success, createRemark] = useCreateRemarkAsyncEndpoint({
        remark: remark.value,
        userId: id,
    });

    useEffect(() => {
        setRemark('');
        getRemarks();
    }, [success, getRemarks]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col>
            <Row>
                <Col span={4}>
                    <span className="bold-text">{getTranslation('Username')}:</span>
                </Col>
                <Col>
                    <span className="bold-text">{userName}</span>
                </Col>
            </Row>
            <Row className="remarks-table__container">
                <RemarksResultsTable loading={remarksLoading} />
            </Row>
            <Row>
                <span className="add-remark__title">{getTranslation('Add new remark')}</span>
            </Row>
            <Row>
                <Form style={{ width: '100%' }}>
                    <Form.Item validateStatus={remark.validateStatus} help={remark.errorMsg}>
                        <Input.TextArea
                            value={remark.value}
                            onChange={handleRemarkChange}
                            rows="3"
                        />
                    </Form.Item>
                </Form>
            </Row>
            <Row className="action-buttons" justify="end" align="middle">
                <ButtonGreen
                    label={getTranslation('Confirm')}
                    onClick={addRemark}
                    loading={remarkAdding}
                    disabled={remark.validateStatus !== 'success'}
                />
                <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
            </Row>
        </Col>
    );
};

export default RemarksModalContent;
