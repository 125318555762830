import { useHistory } from 'react-router';
import React from 'react';

import { Col, Row } from 'antd';
import { CopyOutlined, FormOutlined } from '@ant-design/icons';
import _ from 'lodash';

import './ColumnActionButtons.less';
import { ROUTES } from '../../../common/constants/routing';
import { bonusDuplicate } from '../create-bonus/createBonusActions';
import { setBonusSelected } from './bonusManagementActions';
import OperationsButton from './operations/OperationsButton';

const ColumnActionButtons = ({
    dispatch,
    bonus,
    openEditModal,
    handleSetBannerStatus,
    handleGetBannerById,
    updateStatusSuccess,
}) => {
    const history = useHistory();
    // ============================================
    // METHODS
    // ============================================

    const openEditPage = () => {
        setBonusSelected(dispatch, { id: bonus.id });
        handleGetBannerById();
        openEditModal();
    };

    const onCopyBonus = () => {
        const {
            details,
            turnoverCondition,
            duration,
            isShowOnApp,
            hasJoinButton,
            isRequireDeposit,
            isRequireBankCard,
            isRequireBonusComplete,
            isRepeatable,
            hasPromoCode,
            repeatableCount,
            currencies,
            categories,
            code,
            internalRemark,
        } = bonus;

        const bonusContent = _.map(details, (detail) => {
            const { bonusName, bonusDetail, termCondition, languageCode, shortDetail } = detail;
            return {
                bonusDetail,
                bonusName,
                languageCode,
                shortDetail,
                termCondition,
            };
        });

        const currencyArray = _.map(currencies, (currency) => {
            return currency.currencyCode;
        });

        const categoriesArray = _.map(categories, (category) => {
            return category.categoryId;
        });

        bonusDuplicate(dispatch, {
            categories: categoriesArray,
            code,
            currencies: currencyArray,
            details: bonusContent,
            duration,
            hasJoinButton,
            hasPromoCode,
            internalRemark,
            isFromDuplicate: true,
            isRepeatable,
            isRequireBankCard,
            isRequireBonusComplete,
            isRequireDeposit,
            isShowOnApp,
            repeatableCount,
            turnoverCondition,
        });

        history.push(ROUTES.DASHBOARD.BONUS.CREATE_BONUS);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row gutter={15} align="middle">
            <Col>
                <OperationsButton
                    className="rowaction-button"
                    dispatch={dispatch}
                    id={bonus.id}
                    bonusStatus={bonus.bonusStatus}
                    loading={updateStatusSuccess}
                    handleSetBannerStatus={handleSetBannerStatus}
                />
            </Col>
            <Col>
                <FormOutlined className="action-icon" onClick={openEditPage} />
                <CopyOutlined className="action-icon" onClick={onCopyBonus} />
            </Col>
        </Row>
    );
};

export default ColumnActionButtons;
