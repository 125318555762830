import React from 'react';

import { Modal } from 'antd';

import './PlayersModal.less';
/*eslint import/namespace: ['error', { allowComputed: true }]*/
import * as Modals from './modals';
import { PLAYERS_TABLE_COLUMNS, PLAYER_MODAL_TYPES } from './constants';
import { getTranslation } from '../../../common/helpers/locale';
import { selectColumns } from './PlayersListActions';
import ColumnSelectionModal from '../../../common/components/column-selection/ColumnSelectionModal';

const PlayersModal = ({ playersModal, onCancel }) => {
    const { isVisible, modalType, playerAttending, prohibitionType } = playersModal;

    const title = prohibitionType ? prohibitionType.title : modalType.title;

    // ============================================
    // RENDER
    // ============================================

    const ModalContent = Modals[modalType.component];

    return modalType.component === PLAYER_MODAL_TYPES.COLUMN_SELECTION.component ? (
        <ColumnSelectionModal
            visible={isVisible}
            onCancel={onCancel}
            tableColumnsList={PLAYERS_TABLE_COLUMNS}
            selectAction={selectColumns}
            selectedColumnsState="players.playersList.selectedColumns"
        />
    ) : (
        <Modal
            visible={isVisible}
            title={getTranslation(title)}
            centered
            className="players-modal"
            onCancel={onCancel}
            footer={null}
            width={modalType.width || 700}
        >
            <ModalContent
                player={playerAttending}
                onCancel={onCancel}
                prohibitionType={prohibitionType}
            />
        </Modal>
    );
};

export default PlayersModal;
