import React from 'react';

import { Row } from 'antd';
import _ from 'lodash';

import {
    BetStatusFilter,
    PlatformFilter,
    ProductFilter,
    ReferenceNoFilter,
    WinLossFilter,
} from './filters';
import {
    CurrencyFilter,
    PeriodFilter,
    TimezoneFilter,
    TransactionNumberFilter,
    UsernameFilter,
} from '../../../common/components/filters';
import { TIMEZONE } from '../../../common/constants/localization';
import { betListFilterSet } from './betListActions';
import { getStateProp } from '../../../common/helpers/misc';
import { switchTimezone } from '../../../common/actions/timezoneActions';
import { useStore } from '../../../store/StateProvider';
import { useUpdateBetListFilterOptionsAsyncEndpoint } from '../../../services/betService';
import BetTypesFilter from './filters/BetTypesFilter';
import SportTypesFilter from './filters/SportTypesFilter';

const BetListFilter = ({ loading }) => {
    const [state, dispatch] = useStore();

    const betList = getStateProp(state, 'bet.betList');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => {
        if (filter === 'platform') handleUpdateFilter();

        if (filter === 'dateFrom' || filter === 'dateTo') {
            betListFilterSet(dispatch, { filter: 'paidTimeDateFrom', value: undefined });
            betListFilterSet(dispatch, { filter: 'paidTimeDateTo', value: undefined });
            betListFilterSet(dispatch, { filter: 'statementDateFrom', value: undefined });
            betListFilterSet(dispatch, { filter: 'statementDateTo', value: undefined });
        }

        if (filter === 'paidTimeDateFrom' || filter === 'paidTimeDateTo') {
            betListFilterSet(dispatch, { filter: 'dateFrom', value: undefined });
            betListFilterSet(dispatch, { filter: 'dateTo', value: undefined });
            betListFilterSet(dispatch, { filter: 'statementDateFrom', value: undefined });
            betListFilterSet(dispatch, { filter: 'statementDateTo', value: undefined });
        }

        if (filter === 'statementDateFrom' || filter === 'statementDateTo') {
            betListFilterSet(dispatch, { filter: 'dateFrom', value: undefined });
            betListFilterSet(dispatch, { filter: 'dateTo', value: undefined });
            betListFilterSet(dispatch, { filter: 'paidTimeDateFrom', value: undefined });
            betListFilterSet(dispatch, { filter: 'paidTimeDateTo', value: undefined });
        }
        betListFilterSet(dispatch, { filter, value });
    };

    const setTimezone = (value) => {
        switchTimezone(dispatch, {
            location: _.find(TIMEZONE, (timezone) => timezone.OFFSET === value).LOCATION,
            offset: value,
        });
    };

    // ============================================
    // HOOKS
    // ============================================

    const [handleUpdateFilter] = useUpdateBetListFilterOptionsAsyncEndpoint(
        getStateProp(betList, 'filters.platform'),
    );

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(betList, 'filters.userName')}
                name="userName"
            />
            <TransactionNumberFilter
                onFilterChange={setFilter}
                value={getStateProp(betList, 'filters.transNo')}
                name="transNo"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(betList, 'filters.dateFrom')}
                dateTo={getStateProp(betList, 'filters.dateTo')}
                dateFromName="dateFrom"
                dateToName="dateTo"
                prefix="Bet"
            />
            <ReferenceNoFilter
                onFilterChange={setFilter}
                value={getStateProp(betList, 'filters.refNo')}
            />
            <BetStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(betList, 'filters.status')}
                loading={loading}
            />
            <ProductFilter
                onFilterChange={setFilter}
                value={getStateProp(betList, 'filters.products', [])}
            />
            <WinLossFilter
                onFilterChange={setFilter}
                value={getStateProp(betList, 'filters.winLoss')}
                loading={loading}
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(betList, 'filters.paidTimeDateFrom')}
                dateTo={getStateProp(betList, 'filters.paidTimeDateTo')}
                dateFromName="paidTimeDateFrom"
                dateToName="paidTimeDateTo"
                prefix="Paid Time"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(betList, 'filters.statementDateFrom')}
                dateTo={getStateProp(betList, 'filters.statementDateTo')}
                dateFromName="statementDateFrom"
                dateToName="statementDateTo"
                prefix="Statement"
                showTime={false}
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(betList, 'filters.currencyCodes')}
                name="currencyCodes"
                mode="multiple"
            />
            <PlatformFilter
                onFilterChange={setFilter}
                value={getStateProp(betList, 'filters.platform')}
                loading={loading}
                name="platform"
            />
            <SportTypesFilter
                onFilterChange={setFilter}
                value={getStateProp(betList, 'filters.sportTypes')}
            />
            <BetTypesFilter
                onFilterChange={setFilter}
                value={getStateProp(betList, 'filters.betTypes')}
            />
            <TimezoneFilter
                onFilterChange={setTimezone}
                value={getStateProp(state, 'timezone.offset')}
            />
        </Row>
    );
};

export default BetListFilter;
