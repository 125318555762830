import { ACTIONS } from './bonusCategoryActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function bonusCategoryReducer(
    bonusCategory = {
        bonusCategoryModal: { isVisible: false },
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        resetPageNo: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.PAGINATION.BONUS_CATEGORY_TABLE_PAGE_CHANGE:
            return {
                ...bonusCategory,
                paging: {
                    ...bonusCategory.paging,
                    pageNo:
                        payload > bonusCategory.paging.pageCount
                            ? bonusCategory.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.BONUS_CATEGORY_TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...bonusCategory,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.BONUS_CATEGORY_MODAL_OPEN:
            return {
                ...bonusCategory,
                bonusCategoryModal: {
                    categoryAttending: payload.categoryAttending,
                    isVisible: true,
                    modalType: payload.modalType,
                },
            };
        case ACTIONS.BONUS_CATEGORY_MODAL_CLOSE:
            return {
                ...bonusCategory,
                bonusCategoryModal: {
                    categoryAttending: {},
                    isVisible: false,
                    modalType: null,
                },
            };

        case ACTIONS.BONUS_CATEGORY_ORDER_SET:
            return {
                ...bonusCategory,
                bonusCategoryModal: {
                    ...bonusCategory.bonusCategoryModal,
                    categoryAttending: payload,
                },
            };

        case ACTIONS.BONUS_CATEGORY_ACTIVE_LIST_GET_SUCCESS:
            return {
                ...bonusCategory,
                bonusCategoryModal: {
                    ...bonusCategory.bonusCategoryModal,
                    categoryAttending: payload.list,
                    paging: payload.paging,
                },
            };

        case ACTIONS.BONUS_CATEGORY_GET_SUCCESS:
            return {
                ...bonusCategory,
                ...payload,
            };

        case ACTIONS.BONUS_CATEGORY_ACTIVE_LIST_GET:
        case ACTIONS.BONUS_CATEGORY_SORT:
        case ACTIONS.BONUS_CATEGORY_SORT_SUCCESS:
        case ACTIONS.BONUS_CATEGORY_GET:
        default: {
            return bonusCategory;
        }
    }
}
