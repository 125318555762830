import React from 'react';

import { NOTIFICATION_TYPES } from '../../constants';
import { addLanguage, removeLanguage, updatePushNotification } from '../../helpers';
import {
    addNewPushNotificationLang,
    removePushNotificationLang,
    resetPushNotificationInfo,
    updatePushNotificationInfo,
} from './generalCreatePushNotificationActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { useCreatePushNotificationAsyncEndpoint } from '../../../../services/pushNotificationService';
import { useStore } from '../../../../store/StateProvider';
import PushNotificationDetails from '../../shared/general/PushNotificationDetails';

const GeneralPushNotification = () => {
    const [state, dispatch] = useStore();
    const { pushNotificationInfo } = getStateProp(
        state,
        'pushNotification.createPushNotification.generalCreatePushNotification',
        [],
    );
    // ============================================
    // METHODS
    // ============================================
    const selectNewsLanguages = (e) => {
        if (e.target.checked) {
            addNewPushNotificationLang(dispatch, addLanguage(pushNotificationInfo, e.target.value));
        } else {
            removePushNotificationLang(
                dispatch,
                removeLanguage(pushNotificationInfo, e.target.value),
            );
        }
    };

    const handleUpdatePushNotification = (value, languageCode, key) => {
        updatePushNotificationInfo(
            dispatch,
            updatePushNotification(pushNotificationInfo, value, languageCode, key),
        );
    };

    // ============================================
    // HOOKS
    // ============================================

    const [creating, handleCreatePushNotification] = useCreatePushNotificationAsyncEndpoint(
        NOTIFICATION_TYPES.GENERAL,
    );

    // ============================================
    // RENDER
    // ============================================

    return (
        <PushNotificationDetails
            pushNotificationInfo={pushNotificationInfo}
            submitting={creating}
            selectNewsLanguages={selectNewsLanguages}
            isCreate={true}
            sending={false}
            notificationStatus={''}
            handleUpdatePushNotification={handleUpdatePushNotification}
            handleUpsertPushNotification={handleCreatePushNotification}
            handleSendPushNotification={() => {}}
            resetPushNotificationInfo={resetPushNotificationInfo}
        />
    );
};

export default GeneralPushNotification;
