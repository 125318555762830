import React from 'react';

import { Row } from 'antd';

import { ChannelFilter, IPAddressFilter } from '../filters';
import { PeriodFilter, UsernameFilter } from '../../../common/components/filters/';
import { channelsFilterSet } from './channelsActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';

const ChannelsFilters = () => {
    const [state, dispatch] = useStore();

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => channelsFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.channels.filters.userName')}
                name="userName"
            />
            <ChannelFilter
                onFilterChange={setFilter}
                name={'channelLike'}
                value={getStateProp(state, 'players.channels.filters.channelLike')}
                placeholder={getTranslation('Register Channel')}
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(state, 'players.channels.filters.createdAtFrom')}
                dateFromName="createdAtFrom"
                dateTo={getStateProp(state, 'players.channels.filters.createdAtTo')}
                dateToName="createdAtTo"
                prefix="Created At"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(state, 'players.channels.filters.betWinLossFrom')}
                dateFromName="betWinLossFrom"
                dateTo={getStateProp(state, 'players.channels.filters.betWinLossTo')}
                dateToName="betWinLossTo"
                prefix="Bet Win Loss"
            />
            <IPAddressFilter
                onFilterChange={setFilter}
                name="registeredIpAddress"
                value={getStateProp(state, 'players.channels.filters.registeredIpAddress')}
                placeholder={getTranslation('Registered IP address')}
            />
            <IPAddressFilter
                onFilterChange={setFilter}
                name="ipAddress"
                value={getStateProp(state, 'players.channels.filters.ipAddress')}
                placeholder={getTranslation('Last Login IP address')}
            />
        </Row>
    );
};

export default ChannelsFilters;
