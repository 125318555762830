import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changeRefereePageNumber, changeRefereefPageSize } from '../referrerReportActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';

export const getColumns = (type) => {
    const columns = [
        {
            dataIndex: 'userName',
            title: getTranslation('User'),
        },
        {
            dataIndex: 'registerDate',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Register Date'),
        },
        {
            dataIndex: 'loginDate',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Last Login Date'),
        },
        {
            dataIndex: 'totalDeposit',
            title: getTranslation('Total Deposit'),
        },
        {
            dataIndex: 'totalWithdraw',
            title: getTranslation('Total Withdrawal'),
        },
        {
            dataIndex: 'totalTurnover',
            title: getTranslation('Total Turnover'),
        },
        {
            dataIndex: 'totalWinLoss',
            title: getTranslation('Total Win/Loss'),
        },
    ];

    if (type === 'totalDepositedReferee') {
        columns.push(
            {
                dataIndex: 'refereeReward',
                title: getTranslation('Referee Reward Amount'),
            },
            {
                dataIndex: 'currency',
                title: getTranslation('Currency'),
            },
        );
    }

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changeRefereePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changeRefereefPageSize(dispatch, pageSize);
