import React, { useState } from 'react';

import { Form, Modal, Row } from 'antd';

import { ButtonGray, ButtonGreen } from '../../../../common/components/buttons/Buttons';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import {
    useSetupMerchantAsyncEndpoint,
    useUpdateActivatedMerchantAsyncEndpoint,
} from '../../../../services/merchantService';
import MerchantSetupForm from '../modal-form/MerchantSetupForm';

const MerchantSetupModal = ({ modalData, onCancel }) => {
    const [formValues, setFormValues] = useState({});
    const selectedMerchant = getStateProp(modalData, 'merchantDetails', {});

    // ============================================
    // METHODS
    // ============================================

    const submitForm = (values) => {
        setFormValues(values);
        selectedMerchant.gatewayId ? handleUpdateActivatedMerchant() : handleAddMerchant();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [adding, handleAddMerchant] = useSetupMerchantAsyncEndpoint(formValues);

    const [updating, handleUpdateActivatedMerchant] = useUpdateActivatedMerchantAsyncEndpoint(
        formValues,
    );

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={true}
            title={
                selectedMerchant.gatewayId
                    ? getTranslation('Edit Merchant')
                    : getTranslation('Add Merchant')
            }
            centered
            width={1000}
            closable={false}
            onCancel={onCancel}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        form="active-merchant-setup"
                        label={getTranslation('Confirm')}
                        loading={selectedMerchant.gatewayId ? updating : adding}
                        htmlType="submit"
                    />

                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
        >
            <Form
                name="active-merchant-setup"
                onFinish={submitForm}
                initialValues={{ ...selectedMerchant }}
            >
                <MerchantSetupForm selectedMerchant={selectedMerchant} />
            </Form>
        </Modal>
    );
};

export default MerchantSetupModal;
