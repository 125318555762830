import React from 'react';

import './RiskMonitor.less';
import { Card, Form } from 'antd';

import {
    dropFilters,
    resetBonusRedemptionPage,
    resetTotalRegistrationPage,
    setBonusCodeRedemptionModal,
    setTotalRegistrationModal,
} from './riskMonitorActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    useGetBonusIpAsyncEndpoint,
    useGetBonusRedeemAsyncEndpoint,
    useGetBonusRegistrationAsyncEndpoint,
} from '../../../services/bonusManagementService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import BonusCodeRedemptionModal from './BonusCodeRedemptionModal';
import RiskMonitorFilters from './RiskMonitorFilters';
import RiskMonitorTable from './table/RiskMonitorTable';
import TotalRegistrationModal from './TotalRegistrationModal';

const RiskMonitor = () => {
    const [state, dispatch] = useStore();
    const {
        totalRegistrationModalVisible = false,
        bonusCodeRedemptionModalVisible = false,
    } = getStateProp(state, 'bonus.riskMonitor');

    // ============================================
    // METHODS
    // ============================================

    const dropAll = () => {
        dropFilters(dispatch);
        handleGetBonusIp();
    };

    const setTotalRegistrationModalVisible = (flag) => {
        setTotalRegistrationModal(dispatch, flag);

        if (!flag) {
            resetTotalRegistrationPage(dispatch);
        }
    };

    const setBonusCodeRedemptionModalVisible = (flag) => {
        console.log(flag);
        setBonusCodeRedemptionModal(dispatch, flag);

        if (!flag) {
            resetBonusRedemptionPage(dispatch);
        }
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetBonusIp] = useGetBonusIpAsyncEndpoint();
    const [gettingBonusReddem, handleGetBonusRedeem] = useGetBonusRedeemAsyncEndpoint();
    const [
        gettingBonusRegistration,
        handleGetBonusRegistration,
    ] = useGetBonusRegistrationAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Risk Monitor')} className="risk-monitor">
            <Form onFinish={handleGetBonusIp}>
                <RiskMonitorFilters />
                <ActionButtons onDropFilters={dropAll} />
            </Form>
            <RiskMonitorTable
                loading={loading}
                setTotalRegistrationModalVisible={setTotalRegistrationModalVisible}
                setBonusCodeRedemptionModalVisible={setBonusCodeRedemptionModalVisible}
                handleGetBonusRedeem={handleGetBonusRedeem}
                handleGetBonusRegistration={handleGetBonusRegistration}
            />
            <TotalRegistrationModal
                loading={gettingBonusRegistration}
                visible={totalRegistrationModalVisible}
                onCancel={setTotalRegistrationModalVisible}
            />
            <BonusCodeRedemptionModal
                loading={gettingBonusReddem}
                visible={bonusCodeRedemptionModalVisible}
                onCancel={setBonusCodeRedemptionModalVisible}
            />
        </Card>
    );
};

export default RiskMonitor;
