import React from 'react';

import { Input } from 'antd';
import _ from 'lodash';

import { getIntOrFloat } from '../../../../common/helpers/misc';

export const getColumns = (vipSetup, onVipSetupChange, isEditMode) => {
    // Inputs
    const renderAmountInput = (value, data, level) => (
        <Input
            value={value && value.toFixed(2)}
            className="vip-setup__form-input"
            onChange={(e) => onVipSetupChange(level, data, getIntOrFloat(e.target.value, true))}
        />
    );

    // Inputs

    const vipSetupInfoMapping = (vipSetupInfo, record, level) => {
        let value = _.get(vipSetupInfo, record.dataIndex);

        if (!isEditMode) {
            return <span>{value}</span>;
        } else {
            return record.inputType === 'decimal' ? (
                renderAmountInput(value, record, level)
            ) : (
                <span>{value}</span>
            );
        }
    };

    const columns = [
        {
            dataIndex: 'title',
            fixed: 'left',
            key: 'title',
            render: (value) => {
                return <span className="vip-setup-table-row">{value}</span>;
            },
            width: 250,
        },
        {
            dataIndex: 'levelOne',
            key: 'levelOne',
            render: (text, record, index) => {
                return vipSetupInfoMapping(
                    _.find(vipSetup, (setup) => {
                        return setup.level.level === 1;
                    }),
                    record,
                    1,
                );
            },
            title: '1',
            width: 250,
        },
        {
            dataIndex: 'levelTwo',
            key: 'levelTwo',
            render: (text, record, index) => {
                return vipSetupInfoMapping(
                    _.find(vipSetup, (setup) => {
                        return setup.level.level === 2;
                    }),
                    record,
                    2,
                );
            },
            title: '2',
            width: 250,
        },
        {
            dataIndex: 'levelThree',
            key: 'levelThree',
            render: (text, record, index) => {
                return vipSetupInfoMapping(
                    _.find(vipSetup, (setup) => {
                        return setup.level.level === 3;
                    }),
                    record,
                    3,
                );
            },
            title: '3',
            width: 250,
        },
        {
            dataIndex: 'levelFour',
            key: 'levelFour',
            render: (text, record, index) => {
                return vipSetupInfoMapping(
                    _.find(vipSetup, (setup) => {
                        return setup.level.level === 4;
                    }),
                    record,
                    4,
                );
            },
            title: '4',
            width: 250,
        },
        {
            dataIndex: 'levelFive',
            key: 'levelFive',
            render: (text, record, index) => {
                return vipSetupInfoMapping(
                    _.find(vipSetup, (setup) => {
                        return setup.level.level === 5;
                    }),
                    record,
                    5,
                );
            },
            title: '5',
            width: 250,
        },
        {
            dataIndex: 'levelSix',
            key: 'levelSix',
            render: (text, record, index) => {
                return vipSetupInfoMapping(
                    _.find(vipSetup, (setup) => {
                        return setup.level.level === 6;
                    }),
                    record,
                    6,
                );
            },
            title: '6',
            width: 250,
        },
        {
            dataIndex: 'levelSeven',
            key: 'levelSeven',
            render: (text, record, index) => {
                return vipSetupInfoMapping(
                    _.find(vipSetup, (setup) => {
                        return setup.level.level === 7;
                    }),
                    record,
                    7,
                );
            },
            title: '7',
            width: 250,
        },
        {
            dataIndex: 'levelEight',
            key: 'levelEight',
            render: (text, record, index) => {
                return vipSetupInfoMapping(
                    _.find(vipSetup, (setup) => {
                        return setup.level.level === 8;
                    }),
                    record,
                    8,
                );
            },
            title: '8',
            width: 250,
        },
        {
            dataIndex: 'levelNine',
            key: 'levelNine',
            render: (text, record, index) => {
                return vipSetupInfoMapping(
                    _.find(vipSetup, (setup) => {
                        return setup.level.level === 9;
                    }),
                    record,
                    9,
                );
            },
            title: '9',
            width: 250,
        },
        {
            dataIndex: 'levelTen',
            key: 'levelTen',
            render: (text, record, index) => {
                return vipSetupInfoMapping(
                    _.find(vipSetup, (setup) => {
                        return setup.level.level === 10;
                    }),
                    record,
                    10,
                );
            },
            title: '10',
            width: 250,
        },
    ];

    return columns;
};
