import React from 'react';

import { Divider, Table } from 'antd';
import _ from 'lodash';

import '../Referral.less';
import { getPaginationOptions, getReferralColumns } from './ReferralTableMeta';
import { getRefereeColumns } from './RefereeTableMeta';
import { getSupportedCurrenciesByCountry } from '../../../common/helpers/locale';
import { useDispatch } from '../../../store/StateProvider';

const ReferralContent = ({
    selectedPlans,
    paging,
    isBonus,
    isFixedPlan,
    onDetailsChange,
    onChangePageNo,
    onChangePageSize,
}) => {
    const dispatch = useDispatch();
    const { currency, details } = selectedPlans;
    const detailsLength = details.length;
    const operatorCurrency = _.map(
        getSupportedCurrenciesByCountry(),
        (currency) => currency.currency,
    );
    const isDisabled = !_.includes(operatorCurrency, currency);

    // ============================================
    // METHODS
    // ============================================

    // ============================================
    // HOOKS
    // ============================================

    return (
        <>
            <Table
                className="row__form-item"
                id="referral-create-table"
                columns={getRefereeColumns(currency, onDetailsChange, isDisabled)}
                size="small"
                align="center"
                dataSource={[selectedPlans]}
                rowKey={(record, key) => `refereeIno${key}`}
                pagination={false}
                bordered
            />
            <Divider />
            <Table
                className="row__form-item"
                id="referral-create-table"
                columns={getReferralColumns(
                    currency,
                    isBonus,
                    isFixedPlan,
                    detailsLength,
                    onDetailsChange,
                    isDisabled,
                )}
                size="small"
                align="center"
                dataSource={[...details]}
                rowKey={(record) => record.tierLevel}
                pagination={getPaginationOptions(
                    paging,
                    dispatch,
                    onChangePageNo,
                    onChangePageSize,
                )}
                bordered
            />
        </>
    );
};

export default ReferralContent;
