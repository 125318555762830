import React from 'react';

import { Collapse, Row } from 'antd';
import _ from 'lodash';

import './AddRole.less';
import { ButtonGreen, ButtonTransparent } from '../../../../../common/components/buttons/Buttons';
import { getStateProp } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useSetUserAuthorityAsyncEndpoint } from '../../../../../services/adminService';
import { useStore } from '../../../../../store/StateProvider';
import Loader from '../../../../../common/components/Loader';

const { Panel } = Collapse;

const AddRole = ({ onCancel }) => {
    const state = useStore()[0];

    const selectedMenus = getStateProp(
        state,
        'administration.userAuthoritySetting.selectedInfo.selectedMenus',
        [],
    );

    // ============================================
    // METHODS
    // ============================================

    const onSave = () => handleSetUserAuthority();

    // ============================================
    // HOOKS
    // ============================================

    const [setting, handleSetUserAuthority] = useSetUserAuthorityAsyncEndpoint(selectedMenus);

    // ============================================
    // RENDER
    // ============================================
    return (
        <div>
            {setting ? (
                <Row className="sidebar__loader">
                    <Loader size="small" />
                </Row>
            ) : (
                _.map(selectedMenus, (menus, key) => (
                    <Collapse accordion className="selected-menu__collapse" key={key}>
                        {_.map(menus, (subMenus, key) => (
                            <Panel key={key} header={key}>
                                {_.map(subMenus, (subMenu) => (
                                    <Row
                                        key={subMenu.menuId}
                                        className="selected-menu__submenu-name"
                                    >
                                        {subMenu.menuName}
                                    </Row>
                                ))}
                            </Panel>
                        ))}
                    </Collapse>
                ))
            )}
            <Row justify="end" className="selected-menu__footer-row">
                <ButtonTransparent
                    label={getTranslation('Cancel')}
                    onClick={onCancel}
                    style={{ marginRight: '10px' }}
                />
                <ButtonGreen label={getTranslation('Save')} onClick={onSave} loading={setting} />
            </Row>
        </div>
    );
};

export default AddRole;
