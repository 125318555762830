import { ACTIONS } from './cryptoMerchantConfigurationActions';

export default function cryptoMerchantConfigurationReducer(
    cryptoMerchant = {},
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.CRYPTO_MERCHANT_CONFIGURATION_CRYPTO_TYPE_UPDATE: {
            payload.item === 'Deposit'
                ? (cryptoMerchant.depositList[payload.index].active = payload.value)
                : (cryptoMerchant.withdrawList[payload.index].active = payload.value);
            return {
                ...cryptoMerchant,
            };
        }

        case ACTIONS.CRYPTO_MERCHANT_CONFIGURATION_RECORD_GET_SUCCESS:
            return {
                ...payload,
            };

        default:
            return cryptoMerchant;
    }
}
