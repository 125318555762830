import React from 'react';

import { InputNumber } from 'antd';
import _ from 'lodash';

import { LANGUAGES } from '../../../../../common/constants/localization';
import { bonusCategorySortingOrderSet } from '../../bonusCategoryActions';
import { findTitleByLanguage, getTranslation } from '../../../../../common/helpers/locale';

export const getColumns = (dispatch, tableData, langCode) => {
    const columns = [
        {
            dataIndex: 'categoryNames',
            filterMultiple: false,
            filters: _.map(LANGUAGES, (language) => {
                return {
                    text: language.name,
                    value: language.value,
                };
            }),
            render: (value, record) => {
                return findTitleByLanguage(record.categoryNames, langCode, 'name');
            },
            title: getTranslation('Bonus Category'),
        },
        {
            dataIndex: 'sortingOrder',
            render: (text, record) => {
                return (
                    <InputNumber
                        value={text}
                        onChange={(value) => onOrderNumberChange({ record, tableData, value })}
                        onBlur={() => updateOrderNumber(dispatch, tableData)}
                    />
                );
            },
            title: getTranslation('Sorting Order'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
    }));
};

const onOrderNumberChange = ({ record, tableData, value }) => {
    _.map(tableData, (data) => {
        if (data.id === record.id) {
            data.sortingOrder = value;
        }
    });
};

const updateOrderNumber = (dispatch, tableData) => {
    bonusCategorySortingOrderSet(dispatch, _.orderBy(tableData, ['sortingOrder'], ['asc']));
};
