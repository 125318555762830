import React, { useState } from 'react';

import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Col, Input, Row } from 'antd';

import { ButtonTransparent } from '../../../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../../../common/helpers/locale';

const BankCardVerifyPopupForm = ({ bankCard, maskedValue }) => {
    const [bankCardVerifyValue, setBankCardVerifyValue] = useState(null);
    const [isBankVerified, setBankVerified] = useState(null);

    const verifyBankCard = () => {
        if (bankCardVerifyValue.replace(/\s/g, '') === bankCard.replace(/\s/g, '')) {
            setBankVerified(true);
            handleBankCardVerificationSuccess();
        } else {
            setBankVerified(false);
        }
    };

    const handleBankCardValue = (e) => setBankCardVerifyValue(e.target.value);

    const handleBankCardVerificationSuccess = () => {
        setTimeout(() => {
            setBankVerified(null);
        }, 3000);
    };

    return (
        <div style={{ width: 350 }}>
            <Row className="form__group">
                <Col span={12}>
                    <span className="form__group__label">
                        {getTranslation('Bank Card Number')}:
                    </span>
                </Col>
                <Col span={12}>
                    <span>{maskedValue}</span>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={12}>
                    <span className="form__group__label">
                        {getTranslation('Verify Bank Card Number')}:
                    </span>
                </Col>
                <Col span={12}>
                    <span>
                        <Input onChange={handleBankCardValue} />
                    </span>
                </Col>
            </Row>

            <Row className="form__group">
                <Col span={12}>
                    {isBankVerified !== null && (
                        <div>
                            {isBankVerified ? (
                                <CheckCircleTwoTone
                                    twoToneColor="#52c41a"
                                    style={{ fontSize: 18 }}
                                />
                            ) : (
                                <CloseCircleTwoTone twoToneColor="red" style={{ fontSize: 18 }} />
                            )}
                        </div>
                    )}
                </Col>
                <Col span={12}>
                    <ButtonTransparent label={getTranslation('Verify')} onClick={verifyBankCard} />
                </Col>
            </Row>
        </div>
    );
};

export default BankCardVerifyPopupForm;
