import overviewReducer from './overview/overviewReducer';
import playerWalletBalanceReducer from './player-wallet-balance/playerWalletBalanceReducer';
import playerWinLossReducer from './player-win-loss/playerWinLossReducer';

export default function reportReducer(reports = {}, action) {
    const { playerWalletBalance, playerWinLoss, overview } = reports;

    return {
        overview: overviewReducer(overview, action),
        playerWalletBalance: playerWalletBalanceReducer(playerWalletBalance, action),
        playerWinLoss: playerWinLossReducer(playerWinLoss, action),
    };
}
