import React from 'react';

import { Table } from 'antd';

import { PLAYERS_TABLE_COLUMNS } from '../constants';
import { getColumns, getPaginationOptions } from './PlayersTableMeta';
import { getCurrentUser } from '../../../../common/helpers/api';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const PlayerResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();
    const tableData = getStateProp(state, 'players.playersList.list', []);
    const paging = getStateProp(state, 'players.playersList.paging', {});
    const selectedColumns = getStateProp(
        state,
        'players.playersList.selectedColumns',
        PLAYERS_TABLE_COLUMNS,
    );

    // Used for temp show phone number to selected user
    const userName = getStateProp(
        state,
        'user.userName',
        getStateProp(getCurrentUser(), 'claims.userName', 'Anonymus'), //Temp fix
    );

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            id="players-table"
            columns={getColumns(dispatch, selectedColumns, userName)}
            pagination={getPaginationOptions(paging, dispatch)}
            dataSource={tableData}
            rowKey={(record) => record.id}
            loading={loading}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
        />
    );
};

export default PlayerResultsTable;
