export const ACTIONS = {
    ADD_LANGUAGE: 'ADD_LANGUAGE',
    CREATE_TARGET_PUSH_NOTIFICATION: 'CREATE_TARGET_PUSH_NOTIFICATION',
    CREATE_TARGET_PUSH_NOTIFICATION_FAILURE: 'CREATE_TARGET_PUSH_NOTIFICATION_FAILURE',
    CREATE_TARGET_PUSH_NOTIFICATION_SUCCESS: 'CREATE_TARGET_PUSH_NOTIFICATION_SUCCESS',
    REMOVE_LANGUAGE: 'REMOVE_LANGUAGE',
    RESET_TARGET_PUSH_NOTIFICATION_INFO: 'RESET_TARGET_PUSH_NOTIFICATION_INFO',
    UPDATE_TARGET_PUSH_NOTIFICATION_INFO: 'UPDATE_TARGET_PUSH_NOTIFICATION_INFO',
    UPDATE_TARGET_PUSH_NOTIFICATION_INFO_BY_LANGUAGE:
        'UPDATE_TARGET_PUSH_NOTIFICATION_INFO_BY_LANGUAGE',
};

//# region update language
export const addTargetPushNotificationLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ADD_LANGUAGE,
    });

export const removeTargetPushNotificationLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REMOVE_LANGUAGE,
    });
//# end region

//# region update push notification info
export const updateTargetPushNotificationInfoByLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.UPDATE_TARGET_PUSH_NOTIFICATION_INFO_BY_LANGUAGE,
    });

export const updateTargetPushNotificationInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.UPDATE_TARGET_PUSH_NOTIFICATION_INFO,
    });

export const resetTargetPushNotificationInfo = (dispatch) =>
    dispatch({
        type: ACTIONS.RESET_TARGET_PUSH_NOTIFICATION_INFO,
    });
//# end region

//# region create push notification
export const createTargetPushNotification = (dispatch) =>
    dispatch({
        type: ACTIONS.CREATE_TARGET_PUSH_NOTIFICATION,
    });

export const createTargetPushNotificationSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.CREATE_TARGET_PUSH_NOTIFICATION_SUCCESS,
    });

export const createTargetPushNotificationFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CREATE_TARGET_PUSH_NOTIFICATION_FAILURE,
    });
//# end region
