import React from 'react';

import { Form, Input, Modal, Row } from 'antd';

import './VerifyModal.less';
import { ButtonGray, ButtonGreen, ButtonRed } from '../../../../common/components/buttons/Buttons';
import { PAYMENT_ORDER_OPERATIONS } from '../../../../common/constants/misc';
import {
    closeVerifyModal,
    performWithdrawalRecordOperation,
    setWithdrawalRecordVerifyInfo,
} from '../withdrawalRecordsActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { useDispatch } from '../../../../store/StateProvider';

const { TextArea } = Input;

const VerifyModal = ({ visible, withdrawalRecord, onCancel }) => {
    const dispatch = useDispatch();

    const { playerUserName, amount } = withdrawalRecord;

    // ============================================
    // METHODS
    // ============================================

    const onValueChange = (verifyInfo, value) =>
        setWithdrawalRecordVerifyInfo(dispatch, { value, verifyInfo });

    const verifyWithdrawal = () => {
        performWithdrawalRecordOperation(dispatch, { action: PAYMENT_ORDER_OPERATIONS.VERIFY });
        closeVerifyModal(dispatch);
    };

    const rejectWithdrawal = () => {
        performWithdrawalRecordOperation(dispatch, { action: PAYMENT_ORDER_OPERATIONS.REJECT });
        closeVerifyModal(dispatch);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            title={getTranslation('Withdrawal Check')}
            centered
            visible={visible}
            closable={false}
            className="withdrawal-record__verification-modal"
            width="750px"
            onCancel={onCancel}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen label={getTranslation('Approve')} onClick={verifyWithdrawal} />
                    <ButtonRed label={getTranslation('Reject')} onClick={rejectWithdrawal} />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
        >
            <Form name="verify-withdrawal">
                <Row justify="start">
                    <span className="modal-description">{getTranslation('Username')}</span>:
                    <span className="withdrawal-record">{playerUserName}</span>
                </Row>
                <Row justify="start">
                    <span className="modal-description">{getTranslation('Amount')}</span>:
                    <span className="withdrawal-record">{amount}</span>
                </Row>
                <Row justify="start">
                    <span className="modal-description">{getTranslation('Audit Remark')}</span>
                    <span className="withdrawal-record">
                        <Form.Item name="auditRemark">
                            <TextArea
                                onBlur={(e) => onValueChange('auditRemark', e.target.value)}
                            />
                        </Form.Item>
                    </span>
                </Row>
            </Form>
        </Modal>
    );
};

export default VerifyModal;
