import React, { useEffect, useState } from 'react';

import { Card, DatePicker, Divider, Row, Tabs, message } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import {
    ButtonGray,
    ButtonGreen,
    ButtonTransparent,
} from '../../../../common/components/buttons/Buttons';
import { CURRENCIES } from '../../../../common/constants/localization';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import {
    useGetCurrencySettingAsyncEndpoint,
    useUpdateCurrencySettingAsyncEndpoint,
} from '../../../../services/adminService';
import { useStore } from '../../../../store/StateProvider';
import CurrencySettingResultTable from '../table/CurrenySettingResultTable';

const AddCurrencySetting = ({ onCancel, handleGetCurrencySettingFilterList }) => {
    const [state] = useStore();
    const [activeTab, setActiveTab] = useState('THB');
    const [effectiveDate, setEffectiveDate] = useState();

    const effectiveDateList = getStateProp(
        state,
        'administration.currencySetting.effectiveDates',
        [],
    );
    const newRateSetting = getStateProp(state, 'administration.currencySetting.newRateSetting');
    const latestList = getStateProp(newRateSetting, 'latestList', []);
    const tableData = _.filter(latestList, (list) => list.baseCurrency === activeTab);

    // ============================================
    // METHODS
    // ============================================

    const formSubmit = () => {
        if (!effectiveDate) {
            message.warning(getTranslation('Please select an effective date'));
            return;
        }

        handleUpdateCurrencySetting();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetCurrencySetting] = useGetCurrencySettingAsyncEndpoint({
        effectiveDate: effectiveDateList[0],
    });

    const [updating, handleUpdateCurrencySetting, updated] = useUpdateCurrencySettingAsyncEndpoint(
        {
            effectiveDate: moment(effectiveDate)
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            exchangeRates: latestList,
        },
        true,
    );

    useEffect(() => {
        handleGetCurrencySetting();
    }, [handleGetCurrencySetting]);

    useEffect(() => {
        if (updated) {
            onCancel();
            handleGetCurrencySettingFilterList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updated]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Add New Exchange Rate')} loading={loading}>
            <DatePicker
                showTime
                onChange={(value) => {
                    setEffectiveDate(value);
                }}
            />

            <Tabs
                style={{ marginTop: '15px' }}
                type="card"
                activeKey={activeTab}
                onTabClick={(key) => {
                    setActiveTab(key);
                }}
            >
                {_.map(CURRENCIES, (currency) => (
                    <Tabs.TabPane tab={currency.currency} key={currency.currency}>
                        <CurrencySettingResultTable
                            loading={loading}
                            isEditMode={true}
                            tableData={tableData}
                            isAddNewRate={true}
                        />
                    </Tabs.TabPane>
                ))}
            </Tabs>

            <Divider />

            <Row justify="end" align="middle" className="action-buttons__container">
                <ButtonGreen
                    label={getTranslation('Submit')}
                    onClick={formSubmit}
                    loading={updating}
                />
                <ButtonGray
                    label={getTranslation('Reset')}
                    onClick={handleGetCurrencySetting}
                    loading={updating}
                />
                <ButtonTransparent
                    label={getTranslation('Cancel')}
                    onClick={onCancel}
                    loading={updating}
                />
            </Row>
        </Card>
    );
};

export default AddCurrencySetting;
