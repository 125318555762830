import { ACTIONS } from './targetCreatePushNotificationActions';
import { getInitPushNotification } from '../../helpers';

export default function targetCreatePushNotificationReducer(
    targetCreatePushNotification = {
        pushNotificationInfo: JSON.parse(JSON.stringify(getInitPushNotification())),
        targetType: 'Username',
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.CREATE_TARGET_PUSH_NOTIFICATION_SUCCESS:
        case ACTIONS.RESET_TARGET_PUSH_NOTIFICATION_INFO:
            return {
                pushNotificationInfo: JSON.parse(JSON.stringify(getInitPushNotification())),
                targetType: 'Username',
            };

        case ACTIONS.UPDATE_TARGET_PUSH_NOTIFICATION_INFO:
            return {
                ...targetCreatePushNotification,
                [payload.name]: payload.value,
            };

        case ACTIONS.CREATE_TARGET_PUSH_NOTIFICATION:
        case ACTIONS.UPDATE_TARGET_PUSH_NOTIFICATION_INFO_BY_LANGUAGE:
        case ACTIONS.ADD_LANGUAGE:
        case ACTIONS.REMOVE_LANGUAGE:
        default:
            return targetCreatePushNotification;
    }
}
