import React from 'react';

import { Button } from 'antd';
import classNames from 'classnames';

import './Buttons.less';

const ButtonBlue = ({ label, className, active, children, ...rest }) => {
    return (
        <Button
            className={classNames(active ? 'btn__blue--active' : 'btn__blue', className)}
            {...rest}
        >
            {label}
            {children}
        </Button>
    );
};

const ButtonLightBlue = ({ label, className, active, children, ...rest }) => {
    return (
        <Button
            className={classNames(
                active ? 'btn__light-blue--active' : 'btn__light-blue',
                className,
            )}
            {...rest}
        >
            {label}
            {children}
        </Button>
    );
};

const ButtonGray = ({ label, className, children, ...rest }) => {
    return (
        <Button className={classNames('btn__gray', className)} {...rest}>
            {label}
            {children}
        </Button>
    );
};

const ButtonOrange = ({ label, className, children, ...rest }) => {
    return (
        <Button className={classNames('btn__orange', className)} {...rest}>
            {label}
            {children}
        </Button>
    );
};

const ButtonGreen = ({ label, className, children, ...rest }) => {
    return (
        <Button className={classNames('btn__green', className)} {...rest}>
            {label}
            {children}
        </Button>
    );
};

const ButtonRed = ({ label, className, children, ...rest }) => {
    return (
        <Button className={classNames('btn__red', className)} {...rest}>
            {label}
            {children}
        </Button>
    );
};

const ButtonYellow = ({ label, className, children, ...rest }) => {
    return (
        <Button className={classNames('btn__yellow', className)} {...rest}>
            {label}
            {children}
        </Button>
    );
};

const ButtonTransparent = ({ label, className, children, ...rest }) => {
    return (
        <Button className={classNames('btn__transparent', className)} {...rest}>
            {label}
            {children}
        </Button>
    );
};

export {
    ButtonBlue,
    ButtonGray,
    ButtonOrange,
    ButtonGreen,
    ButtonRed,
    ButtonYellow,
    ButtonTransparent,
    ButtonLightBlue,
};
