import { useEffect, useState } from 'react';

const useInternetStatus = () => {
    const [isOnline, setNetwork] = useState(window.navigator.onLine);
    // ============================================
    // METHODS
    // ============================================

    const updateNetwork = () => {
        setNetwork(window.navigator.onLine);
    };

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        window.addEventListener('offline', updateNetwork);
        window.addEventListener('online', updateNetwork);
        return () => {
            window.removeEventListener('offline', updateNetwork);
            window.removeEventListener('online', updateNetwork);
        };
    });

    return [isOnline];
};

export default useInternetStatus;
