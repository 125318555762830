import { message } from 'antd';
import _ from 'lodash';

import { LANGUAGES } from '../../common/constants/localization';
import { REBATE_FORM_TYPE } from './constants';
import { getSupportedLanguages, getTranslation } from '../../common/helpers/locale';
import { textEditorImageFileSizeCheck } from '../../common/helpers/misc';

export const formValidation = (rebateSetting, aspect) => {
    const {
        currencies,
        details,
        isShowOnApp,
        recurrences,
        product,
        code,
        turnoverCondition,
        startDateTime,
        endDateTime,
    } = rebateSetting;

    return (
        rebateCodeValidation(code) &&
        turnoverConditionValidation(turnoverCondition) &&
        currencyValidation(currencies) &&
        (isShowOnApp ? contentValidation(details, aspect) : rebateNameValidation(details)) &&
        productValidation(product) &&
        recurrencesValidation(recurrences, aspect) &&
        dateRangeValidation(startDateTime, endDateTime)
    );
};

// Date Range Check
const dateRangeValidation = (startDate, endDate) => {
    if (!startDate && !endDate) {
        message.error(getTranslation('Please enter start & end date!'));
        return false;
    }

    return true;
};

// Turnover Requirement Check
const turnoverConditionValidation = (turnoverCondition) => {
    if (!turnoverCondition) {
        message.error(getTranslation('Please enter turnover required!'));
        return false;
    }

    return true;
};

// Rebate Code Check
const rebateCodeValidation = (code) => {
    if (!code || code === null) {
        message.error(getTranslation('Please enter rebate code!'));
        return false;
    }

    return true;
};

// Rebate Currency Check
const currencyValidation = (currencies) => {
    if (!currencies || currencies.length === 0) {
        message.error(getTranslation('Please select at least one currency'));
        return false;
    }

    return _.every(
        _.map(currencies, (currency) => {
            if (
                !typeof currency.minRebateThreshold === 'number' &&
                !currency.minRebateThreshold >= 0
            ) {
                message.error(
                    getTranslation('Please fill in the min rebate threshold in all currencies'),
                );
                return false;
            } else if (
                !typeof currency.rebatePercentage === 'number' &&
                !currency.rebatePercentage >= 0
            ) {
                message.error(
                    getTranslation('Please fill in the rebate percentage in all currencies'),
                );
                return false;
            } else {
                return true;
            }
        }),
    );
};

// Rebate Content Check
const contentValidation = (rebateContent, aspect) => {
    const imageReg = /<\s*img[^>]*>/g;

    if (_.isEmpty(rebateContent)) {
        message.error(getTranslation('Must have atleast one language!'));
        return false;
    } else {
        return _.every(
            _.map(
                rebateContent,
                ({ imageInBase64, imageUrl, imageExtension, imageUrlCN, ...content }) => {
                    if (
                        aspect === REBATE_FORM_TYPE.CREATE
                            ? imageInBase64 === null || imageInBase64 === undefined
                            : (imageInBase64 === null || imageInBase64 === undefined) &&
                              imageUrl === null
                    ) {
                        message.error(getTranslation('Please upload image in all languages'));
                        return false;
                    } else if (_.some(content, _.isEmpty)) {
                        message.error(
                            getTranslation('Please fill in the content in all languages'),
                        );
                        return false;
                    } else if (content.rebateDetails.match(imageReg)) {
                        return textEditorImageFileSizeCheck(content.rebateDetails.match(imageReg));
                    } else {
                        return true;
                    }
                },
            ),
        );
    }
};

// Rebate Name Check
const rebateNameValidation = (rebateContent) => {
    return _.every(
        _.map(rebateContent, (content) => {
            if (!content.rebateName) {
                message.error(getTranslation('Please fill in the rebate name in all languages'));
                return false;
            } else {
                return true;
            }
        }),
    );
};

// Rebate Product Check
const productValidation = (product) => {
    if (!product) {
        message.error(getTranslation('Please select a product'));
        return false;
    }

    return true;
};

// Rebate Recurrence Check
const recurrencesValidation = (recurrences, aspect) => {
    if (recurrences && _.isEmpty(recurrences)) {
        message.error('Please select recurrency day');
        return false;
    }

    return true;
};

export const getDefaultContent = () => {
    return getSupportedLanguages().length === LANGUAGES.length
        ? [
              {
                  imageInBase64: null,
                  languageCode: 'en-US',
                  miniDesc: '',
                  rebateDetails: '',
                  rebateName: '',
              },
              {
                  imageInBase64: null,
                  languageCode: 'zh-CN',
                  miniDesc: '',
                  rebateDetails: '',
                  rebateName: '',
              },
          ]
        : _.map(getSupportedLanguages(), (language) => {
              return {
                  imageInBase64: null,
                  languageCode: language.value,
                  miniDesc: '',
                  rebateDetails: '',
                  rebateName: '',
              };
          });
};
