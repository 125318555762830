import React, { useState } from 'react';

import { Button, Dropdown, Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import _ from 'lodash';

import './ColumnActionButtons.less';
import { REFERRAL_STATUSES } from '../../constants';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { referralStatusSet } from '../referralManagementAction';
import {
    useGetActiveReferralProgramAsyncEndpoint,
    useSetStatusOperationAsyncEndpoint,
} from '../../../../services/referralService';
import { useStore } from '../../../../store/StateProvider';

const OperationsButton = ({ dispatch, id, name, referralStatus }) => {
    const [state] = useStore();
    const { list } = getStateProp(state, 'referral.referralManagement');
    const [programStatus, setProgramStatus] = useState('');
    // ============================================
    // METHODS
    // ============================================

    const onSubmit = (e) => {
        const isStatusSame = _.filter(list, { id: id, programStatus: e.key });

        if (!isStatusSame.length) {
            if (e.key === REFERRAL_STATUSES.ACTIVE) {
                handleGetActiveReferral();
                if (!loading) {
                    referralStatusSet(dispatch, {
                        changeReferralstatus: {
                            confirmModalVisible: true,
                            id: id,
                            name: name,
                            programStatus: e.key,
                        },
                    });
                }
            } else {
                setProgramStatus(e.key);
                handleSetReferralStatus();
            }
        }
    };

    // ============================================
    // HOOKS
    // ============================================
    const [loading, handleGetActiveReferral] = useGetActiveReferralProgramAsyncEndpoint();
    const [updating, handleSetReferralStatus] = useSetStatusOperationAsyncEndpoint(
        id,
        programStatus,
    );

    // ============================================
    // RENDER
    // ============================================

    const operationsMenu = (
        <Menu onClick={onSubmit}>
            {_.map(REFERRAL_STATUSES, (status) => (
                <Menu.Item key={status}>
                    <span>{getTranslation(status)}</span>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Dropdown
            overlay={operationsMenu}
            trigger={['click']}
            disabled={referralStatus === 'Closed'}
        >
            <Button
                className={
                    referralStatus === REFERRAL_STATUSES.ACTIVE
                        ? 'btn__green action-btn__operations'
                        : 'btn__gray action-btn__operations'
                }
                loading={updating || loading}
            >
                {getTranslation(referralStatus)}
                <CaretDownOutlined />
            </Button>
        </Dropdown>
    );
};

export default OperationsButton;
