import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { Card, Form } from 'antd';

import {
    bonusEditModalClose,
    bonusEditModalOpen,
    bonusViewModalClose,
    bonusViewModalOpen,
    dropFilters,
    setBonusDeselected,
} from './bonusManagementActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    useGetActiveBannerListAsyncEndpoint,
    useGetBannerByIdOperationAsyncEndpoint,
    useGetBannerListOperationAsyncEndpoint,
    useUpdateBonusSortingAsycnEndpoint,
} from '../../../services/bonusManagementService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import BonusManagementFilters from './BonusManagementFilters';
import BonusManagementResultsTable from './table/BonusManagementResultsTable';
import EditBonusDescriptionModalContent from './modals/edit-bonus-description/EditBonusDescriptionModalContent';
import SortingModal from './modals/bonus-banner-sorting/SortingModal';

const BonusManagement = () => {
    const [state, dispatch] = useStore();
    const [sortingModalVisible, setSortingModalVisible] = useState(false);
    const location = useLocation();
    const isViewOnly = location.pathname === '/dashboard/bonus/bonus-record';

    const editModalVisible = getStateProp(state, 'bonus.bonusManagement.editModalVisible');
    const viewModalVisible = getStateProp(state, 'bonus.bonusManagement.viewModalVisible');

    // ============================================
    // METHODS
    // ============================================

    const openEditModal = () => bonusEditModalOpen(dispatch);

    const closeEditModal = () => {
        setBonusDeselected(dispatch);
        bonusEditModalClose(dispatch);
    };

    const openViewModal = () => bonusViewModalOpen(dispatch);

    const closeViewModal = () => {
        setBonusDeselected(dispatch);
        bonusViewModalClose(dispatch);
    };

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetBannerList();
    };

    const openSortingModal = () => {
        handleGetActiveBannerList();
        setSortingModalVisible(true);
    };

    const closeSortingModal = () => setSortingModalVisible(false);

    // ============================================
    // HOOKS
    // ============================================

    const [getListLoading, handleGetBannerList] = useGetBannerListOperationAsyncEndpoint();
    const [getBannerByIdLoading, handleGetBannerById] = useGetBannerByIdOperationAsyncEndpoint();
    const [getActiveListLoading, handleGetActiveBannerList] = useGetActiveBannerListAsyncEndpoint();
    const [updating, handleUpdateBonusSorting, updated] = useUpdateBonusSortingAsycnEndpoint();

    useEffect(() => {
        if (updated) setSortingModalVisible(false);
    }, [updated]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation(isViewOnly ? 'Bonus Record' : 'Bonus Management')}>
            <Form onFinish={handleGetBannerList}>
                <BonusManagementFilters />
                <ActionButtons onDropFilters={fetchAll} onOpenSortModal={openSortingModal} />
            </Form>

            <BonusManagementResultsTable
                loading={getListLoading}
                openEditModal={openEditModal}
                openViewModal={openViewModal}
                handleGetBannerById={handleGetBannerById}
                isViewOnly={isViewOnly}
            />

            <EditBonusDescriptionModalContent
                visible={editModalVisible || viewModalVisible}
                onCancel={viewModalVisible ? closeViewModal : closeEditModal}
                getBannerByIdLoading={getBannerByIdLoading}
                viewOnly={viewModalVisible}
            />

            <SortingModal
                visible={sortingModalVisible}
                onCancel={closeSortingModal}
                loading={getActiveListLoading}
                updating={updating}
                onUpdate={handleUpdateBonusSorting}
            />
        </Card>
    );
};

export default BonusManagement;
