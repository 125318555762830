import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Input, Row } from 'antd';

import './ChangePasswordForm.less';
import { ButtonGreen } from '../../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useChangePasswordAsyncEndpoint } from '../../../../../services/playerService';

const ChangePasswordForm = ({ player, onCancel }) => {
    const { id, userName } = player;
    const [form] = Form.useForm();
    const { resetFields, setFields } = form;
    const [isPasswordVerified, setPasswordVerified] = useState(false);
    const [isFinPinVerified, setFinPinVerified] = useState(false);
    const [formValues, setFormValues] = useState({});

    // ============================================
    // METHODS
    // ============================================

    const handleChangeValue = (fieldName, e) => {
        const { value } = e.target;
        setFormValues({ ...formValues, [fieldName]: value });
    };

    const validatePassword = (e) => {
        const { value } = e.target;
        setFormValues({ ...formValues, passwordVerification: value });

        if (formValues.password === value) {
            setPasswordVerified(true);
        } else {
            setPasswordVerified(false);

            setFields([
                {
                    errors: [''],
                    name: 'password',
                    value: formValues.password,
                },
                {
                    errors: [getTranslation('Passwords inconsistent!')],
                    name: 'passwordVerification',
                    value: value,
                },
            ]);
        }
    };

    const validateFinPin = (e) => {
        const { value } = e.target;
        setFormValues({ ...formValues, finPinVerification: value });

        if (formValues.finPin === value) {
            setFinPinVerified(true);
        } else {
            setFinPinVerified(false);

            setFields([
                {
                    errors: [''],
                    name: 'finPin',
                    value: formValues.finPin,
                },
                {
                    errors: [getTranslation('Financial pins inconsistent!')],
                    name: 'finPinVerification',
                    value: value,
                },
            ]);
        }
    };

    const submitForm = (values) => {
        setFormValues(values);
        changePassword();
    };

    const isNotEmptyFields =
        formValues.password ||
        formValues.passwordConfirmation ||
        formValues.finPin ||
        formValues.finPinConfirmation;

    // ============================================
    // HOOKS
    // ============================================

    const [passwordChanging, passwordChanged, changePassword] = useChangePasswordAsyncEndpoint({
        financialPin: formValues.finPin,
        newPassword: formValues.password,
        userId: id,
    });

    useEffect(() => {
        if (passwordChanged) {
            onCancel();
            resetFields();
        }
    }, [passwordChanged, onCancel, resetFields]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form form={form} name="change-password" onFinish={submitForm}>
            <Row className="form__group__username">
                <Col span={6}>
                    <span className="form__group__label">{getTranslation('Username')}:</span>
                </Col>
                <Col span={18}>
                    <span className="form__group__username__text">{userName}</span>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={6}>
                    <span className="form__group__label">{getTranslation('New password')}:</span>
                </Col>
                <Col span={9}>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                message: getTranslation('Please input new password!'),
                                required: true,
                            },
                            {
                                message: getTranslation('Password must be at least 7 symbols!'),
                                min: 7,
                            },
                        ]}
                    >
                        <Input
                            className={isPasswordVerified ? 'form__input--success' : 'form__input'}
                            onBlur={(e) => handleChangeValue('password', e)}
                        />
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item
                        name="passwordVerification"
                        rules={[
                            {
                                message: getTranslation('Please input new password again!'),
                                required: true,
                            },
                            {
                                message: getTranslation('Password must be at least 7 symbols!'),
                                min: 7,
                            },
                        ]}
                    >
                        <Input
                            className={isPasswordVerified ? 'form__input--success' : 'form__input'}
                            placeholder={getTranslation('Verify Password')}
                            onBlur={validatePassword}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={6}>
                    <span className="form__group__label">
                        {getTranslation('New financial pin')}:
                    </span>
                </Col>
                <Col span={9}>
                    <Form.Item
                        name="finPin"
                        rules={[
                            {
                                message: getTranslation('Please input new financial pin!'),
                                required: true,
                            },
                            {
                                max: 6,
                                message: getTranslation('Financial pin must be 6 or less symbols!'),
                            },
                        ]}
                    >
                        <Input
                            className={isFinPinVerified ? 'form__input--success' : 'form__input'}
                            onBlur={(e) => handleChangeValue('finPin', e)}
                        />
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item
                        name="finPinVerification"
                        rules={[
                            {
                                message: getTranslation('Please input new financial pin again!'),
                                required: true,
                            },
                            {
                                max: 6,
                                message: getTranslation('Financial pin must be 6 or less symbols!'),
                            },
                        ]}
                    >
                        <Input
                            className={isFinPinVerified ? 'form__input--success' : 'form__input'}
                            placeholder={getTranslation('Verify Financial Pin')}
                            onBlur={validateFinPin}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="end" className="form__group">
                <ButtonGreen
                    htmlType="submit"
                    disabled={!isNotEmptyFields || !isPasswordVerified || !isFinPinVerified}
                    loading={passwordChanging}
                >
                    {getTranslation('Confirm')}
                </ButtonGreen>
                <Button onClick={onCancel}>{getTranslation('Cancel')}</Button>
            </Row>
        </Form>
    );
};
export default ChangePasswordForm;
