import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Input, Row } from 'antd';

import './MobileNumberForm.less';
import { ButtonGreen } from '../../../../../common/components/buttons/Buttons';
import { MODIFICATION_TYPES } from '../../../constants';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useCreateModificationAsyncEndpoint } from '../../../../../services/playerService';

const FullNameForm = ({ userId, userName, defaultValue, onCancel }) => {
    const [form] = Form.useForm();
    const { resetFields } = form;
    const [formValues, setFormValues] = useState({});

    // ============================================
    // METHODS
    // ============================================

    const submitForm = (values) => {
        setFormValues(values);
        handleCreateModification();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [
        loading,
        handleCreateModification,
        modificationCreated,
    ] = useCreateModificationAsyncEndpoint({
        currentInfo: defaultValue,
        newInfo: formValues.newFullName,
        requesterRemark: formValues.remark,
        type: MODIFICATION_TYPES.FULL_NAME,
        userId,
    });

    useEffect(() => {
        if (modificationCreated) {
            onCancel();
            resetFields();
        }
        // eslint-disable-next-line
    }, [modificationCreated, resetFields]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form
            form={form}
            name="fullname-modification"
            onFinish={submitForm}
            style={{ width: 430 }}
            initialValues={{ currentFullName: defaultValue }}
        >
            <Row className="form__group__username">
                <Col span={6}>
                    <span className="form__group__label">{getTranslation('Username')}:</span>
                </Col>
                <Col span={18}>
                    <span className="form__group__username__text">{userName}</span>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={6}>
                    <span className="form__group__label">
                        {getTranslation('Current full name')}:
                    </span>
                </Col>
                <Col span={18}>
                    <Form.Item name="currentFullName">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={6}>
                    <span className="form__group__label">{getTranslation('New full name')}:</span>
                </Col>
                <Col span={18}>
                    <Form.Item
                        name="newFullName"
                        rules={[
                            {
                                message: getTranslation('Please input new full name!'),
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={6}>
                    <span className="form__group__label">
                        {getTranslation('Remark')}
                        <span style={{ color: 'red' }}>*</span>:
                    </span>
                </Col>
                <Col span={18}>
                    <Form.Item
                        name="remark"
                        reles={[
                            {
                                message: getTranslation('Please fill in this field!'),
                                required: true,
                            },
                        ]}
                    >
                        <Input.TextArea rows={3} />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="end" className="form__group">
                <ButtonGreen htmlType="submit" loading={loading}>
                    {getTranslation('Submit')}
                </ButtonGreen>
                <Button onClick={onCancel}>{getTranslation('Cancel')}</Button>
            </Row>
        </Form>
    );
};

export default FullNameForm;
