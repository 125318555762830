import React from 'react';

import { Divider, Form } from 'antd';

import { API } from '../../../../common/constants/api';
import { PAYMENT_ORDER_OPERATIONS } from '../../../../common/constants/misc';
import {
    cryptoWithdrawalRecordFilterDrop,
    cryptoWithdrawalRecordOperation,
    onRemarkModalClose,
    onVerifyModalClose,
} from './cryptoWithdrawalRecordActions';
import { cryptoWithdrawalRecordsExport, getColumns } from './table/CryptoWithdrawalRecordTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import {
    useCryptoRecordWithdrawalOperationAsyncEndpoint,
    useGetCryptoWithdrawalRecordAsyncEndpoint,
} from '../../../../services/cryptoCurrencyService';
import { useExportAsyncEndpoint } from '../../../../services/hooks/useExport';
import { useStore } from '../../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import CryptoWithdrawalRecordFilters from './CryptoWithdrawalRecordFilters';
import CryptoWithdrawalRecordResultsTable from './table/CryptoWithdrawalRecordResultsTable';
import RemarkModal from '../../../../common/components/RemarkModal';
import TotalBalance from '../../../../common/components/TotalBalance';
import VerifyModal from './verification/VerifyModal';

const { TRANSACTION_HISTORY } = API;

const CryptoWithdrawalRecords = () => {
    const [state, dispatch] = useStore();
    const cryptoWithdrawalRecord = getStateProp(
        state,
        'transactionHistory.cryptocurrencyRecords.cryptoWithdrawalRecord',
    );
    const verifyModalVisible = getStateProp(cryptoWithdrawalRecord, 'verifyModalVisible');
    const remarkModalVisible = getStateProp(cryptoWithdrawalRecord, 'remarkModalVisible');
    const transaction = getStateProp(cryptoWithdrawalRecord, 'transaction', {});
    const remarks = getStateProp(cryptoWithdrawalRecord, 'remarks', {});
    const { auditorRemark, operatorRemark } = remarks;
    const filters = getStateProp(cryptoWithdrawalRecord, 'filters');
    const { totalRecordCount } = getStateProp(cryptoWithdrawalRecord, 'paging', {});
    const pageBalance = getStateProp(cryptoWithdrawalRecord, 'pageDataTotal', []);
    const totalBalance = getStateProp(cryptoWithdrawalRecord, 'dataTotal', []);
    const grandTotal = getStateProp(cryptoWithdrawalRecord, 'grandTotal', 0);
    const pageTotal = getStateProp(cryptoWithdrawalRecord, 'pageTotal', 0);

    // ============================================
    // METHODS
    // ============================================

    const closeVerifyModal = () => {
        cryptoWithdrawalRecordOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.RELEASE,
        });
        onVerifyModalClose(dispatch);
    };

    const closeRemarkModal = () => onRemarkModalClose(dispatch);

    const fetchAll = () => {
        cryptoWithdrawalRecordFilterDrop(dispatch);
        handleGetCryptoWithdrawalRecord();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetCryptoWithdrawalRecord] = useGetCryptoWithdrawalRecordAsyncEndpoint();
    const [operationPending] = useCryptoRecordWithdrawalOperationAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        additionalCol: cryptoWithdrawalRecordsExport,
        dataMeta: getColumns(),
        filters,
        innerListKey: 'data',
        method: 'GET',
        subject: getTranslation('Crypto Withdrawal Records'),
        totalRecordCount,
        url: `${TRANSACTION_HISTORY.CRYPTO_WITHDRAWALS_RECORDS}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <div>
            <Form onFinish={handleGetCryptoWithdrawalRecord}>
                <CryptoWithdrawalRecordFilters />
                <ActionButtons onDropFilters={fetchAll} onExport={handleExportAction} />
            </Form>
            <CryptoWithdrawalRecordResultsTable loading={loading} />

            <Divider />

            <TotalBalance
                pageBalance={pageBalance}
                totalBalance={totalBalance}
                grandTotal={grandTotal}
                pageTotal={pageTotal}
            />

            <VerifyModal
                visible={verifyModalVisible}
                transaction={transaction}
                onCancel={closeVerifyModal}
                operationPending={operationPending}
            />
            <RemarkModal
                visible={remarkModalVisible}
                onCancel={closeRemarkModal}
                auditorRemark={auditorRemark}
                operatorRemark={operatorRemark}
            />
        </div>
    );
};

export default CryptoWithdrawalRecords;
