import React from 'react';

import { Checkbox } from 'antd';
import _ from 'lodash';

import './LanguagesCheckboxes.less';
import { LANGUAGES } from '../constants/localization';
import { getSupportedLanguages } from '../helpers/locale';

const LanguagesCheckboxes = ({ onCheckedLanguages, value, allowAllLanguage = false, disabled }) => {
    const ALLOWED_LANGUAGE = allowAllLanguage ? LANGUAGES : getSupportedLanguages();

    // ============================================
    // RENDER
    // ============================================

    return (
        <div className="languages__checkboxes">
            <Checkbox.Group value={value} disabled={disabled}>
                {_.map(ALLOWED_LANGUAGE, (lang) => {
                    return (
                        <div key={lang.value}>
                            <Checkbox value={lang.value} onChange={onCheckedLanguages}>
                                {lang.name}
                            </Checkbox>
                        </div>
                    );
                })}
            </Checkbox.Group>
        </div>
    );
};

export default LanguagesCheckboxes;
