import React, { useState } from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './GlobalAnnouncementTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const GlobalAnnouncementResultTable = ({ loading }) => {
    const [state, dispatch] = useStore();
    const [langCode, setLangCode] = useState(null);
    const globalAnnouncement = getStateProp(
        state,
        'announcement.announcementVerification.globalAnnouncement',
        {},
    );
    const paging = getStateProp(globalAnnouncement, 'paging', {});
    const tableData = getStateProp(globalAnnouncement, 'list', []);

    // ============================================
    // METHODS
    // ============================================

    const handleChange = (pagination, filters) => {
        filters.title ? setLangCode(filters.title[0]) : setLangCode(null);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns(dispatch, langCode)}
            rowKey={(record) => record.taskNo}
            dataSource={tableData}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
            onChange={handleChange}
        />
    );
};

export default GlobalAnnouncementResultTable;
