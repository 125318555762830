import React from 'react';

import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../turnoverRecordsActions';
import { sortTableByDataType } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';

export const getColumns = () => {
    const columns = [
        {
            dataIndex: 'transactionTime',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Transaction Time'),
        },
        {
            dataIndex: 'transactionNo',
            title: getTranslation('Task Transaction No.'),
        },
        {
            dataIndex: 'userName',
            title: getTranslation('Username'),
        },
        {
            dataIndex: 'typeDesc',
            title: getTranslation('Type'),
        },
        {
            dataIndex: 'currency',
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'amount',
            dataType: 'nanInt',
            render: (value) => <span>{!isNaN(parseInt(value)) ? value : 'NA'}</span>,
            title: getTranslation('Amount'),
        },
        {
            dataIndex: 'etoRequired',
            dataType: 'nanInt',
            render: (value) => <span>{!isNaN(parseInt(value)) ? value : 'NA'}</span>,
            title: getTranslation('ETO Required'),
        },
        {
            dataIndex: 'debit',
            dataType: 'nanInt',
            render: (value) => <span>{!isNaN(parseInt(value)) ? value : 'NA'}</span>,
            title: getTranslation('Debit'),
        },
        {
            dataIndex: 'credit',
            dataType: 'nanInt',
            render: (value) => <span>{!isNaN(parseInt(value)) ? value : 'NA'}</span>,
            title: getTranslation('Credit'),
        },
        {
            dataIndex: 'previousTurnover',
            title: getTranslation('Previous Turnover'),
        },
        {
            dataIndex: 'remainingTurnover',
            title: getTranslation('Remaining Turnover'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
