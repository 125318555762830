import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Row, Spin } from 'antd';

const Loader = ({ size, style }) => (
    <Row align="middle" justify="center" style={{ padding: 20, ...style }}>
        <Spin size={size ? size : 'default'} indicator={<LoadingOutlined spin />} />
    </Row>
);

export default Loader;
