import React from 'react';

import { SearchOutlined } from '@ant-design/icons';

import { ButtonGreen } from './Buttons';
import { getTranslation } from '../../../common/helpers/locale';

const SearchButton = ({ onSearch, htmlType }) => {
    return (
        <ButtonGreen
            type="primary"
            icon={<SearchOutlined />}
            className="search-button"
            onClick={onSearch}
            htmlType={htmlType}
        >
            {getTranslation('Search')}
        </ButtonGreen>
    );
};

export default SearchButton;
