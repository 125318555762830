import React from 'react';

import { FileExcelOutlined } from '@ant-design/icons';

import { ButtonLightBlue } from './Buttons';
import { getTranslation } from '../../../common/helpers/locale';

const ExportButton = ({ onExport, loading }) => {
    return (
        <ButtonLightBlue
            type="primary"
            className="export-button"
            icon={<FileExcelOutlined />}
            onClick={onExport}
            loading={loading}
        >
            {getTranslation('Export')}
        </ButtonLightBlue>
    );
};

export default ExportButton;
