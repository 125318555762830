import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './AffiliateRecordTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const AffiliateRecordResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();
    const { affiliateRecords } = getStateProp(state, 'transactionHistory');
    const tableData = getStateProp(affiliateRecords, 'list', []);
    const paging = getStateProp(affiliateRecords, 'paging', {});

    return (
        <Table
            columns={getColumns()}
            rowKey={(record) => record.transactionNo}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            dataSource={tableData}
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
        />
    );
};

export default AffiliateRecordResultsTable;
