import { RenderDateFormat } from '../../../../common/components/RenderDateFormat';
import { TABLE_PAGE_SIZE_OPTIONS_1000 } from '../../../../common/constants/api';
import { changeChannelsPageNumber, changeChannelsPageSize } from '../channelsActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';

export const getColumns = (pageNo) => {
    const columns = [
        {
            dataIndex: 'No',
            dataType: 'indexNumber',
            render: (value, record, index) => (pageNo - 1) * 10 + index + 1,
            title: getTranslation('No.'),
        },
        {
            dataIndex: 'userName',
            title: getTranslation('Username'),
            width: 120,
        },
        {
            dataIndex: 'registeredIpAddress',
            title: getTranslation('Registered IP address'),
            width: 110,
        },
        {
            dataIndex: 'ipAddress',
            title: getTranslation('Last Login IP address'),
            width: 110,
        },
        {
            dataIndex: 'channel',
            title: getTranslation('Register Channel'),
            // width: 100,
        },
        {
            dataIndex: 'createdAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Register Date'),
            width: 100,
        },
        {
            dataIndex: 'activatedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Last Login Date'),
            width: 100,
        },
        {
            dataIndex: 'totalBonus',
            title: getTranslation('Total Bonus'),
            width: 80,
        },
        {
            dataIndex: 'totalDeposit',
            title: getTranslation('Total Deposit'),
            width: 80,
        },
        {
            dataIndex: 'totalWithdrawal',
            title: getTranslation('Total Withdrawal'),
            width: 90,
        },
        {
            dataIndex: 'totalBalanceAddition',
            title: getTranslation('Total Balance Addition'),
            width: 80,
        },
        {
            dataIndex: 'totalBalanceDeduction',
            title: getTranslation('Total Balance Deduction'),
            width: 80,
        },
        {
            dataIndex: 'totalRebate',
            title: getTranslation('Total Rebate'),
            width: 80,
        },
        {
            dataIndex: 'referralRewards',
            title: getTranslation('Referral Payout'),
            width: 80,
        },
        {
            dataIndex: 'refereeRewards',
            title: getTranslation('Referee Payout'),
            width: 80,
        },
        {
            dataIndex: 'totalAffiliateTransfer',
            title: getTranslation('Total Affiliate Transfer'),
            width: 80,
        },
        {
            dataIndex: 'totalDepositCorrection',
            title: getTranslation('Total Deposit Correction'),
            width: 80,
        },
        {
            dataIndex: 'winLoss',
            title: getTranslation('Win/Loss'),
            width: 80,
        },
        {
            dataIndex: 'esportWinLoss',
            title: getTranslation('Esports'),
            width: 80,
        },
        {
            dataIndex: 'sportWinLoss',
            title: getTranslation('Sport'),
            width: 80,
        },
        {
            dataIndex: 'lotteryWinLoss',
            title: getTranslation('Lottery'),
            width: 80,
        },
        {
            dataIndex: 'numberGameWinLoss',
            title: getTranslation('Number Game'),
            width: 80,
        },
        {
            dataIndex: 'virtualSportsWinLoss',
            title: getTranslation('Virtual Sports'),
            width: 80,
        },
        {
            dataIndex: 'liveCasinoWinLoss',
            title: getTranslation('Live Casino'),
            width: 80,
        },
        {
            dataIndex: 'rngWinLoss',
            title: getTranslation('RNG'),
            width: 80,
        },
        {
            dataIndex: 'virtualGamesWinLoss',
            title: getTranslation('Virtual Games'),
            width: 80,
        },
        {
            dataIndex: 'miniGameWinLoss',
            title: getTranslation('Mini Game'),
            width: 80,
        },
        {
            dataIndex: 'walletBalance',
            title: getTranslation('Wallet balance'),
            width: 80,
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS_1000,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changeChannelsPageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changeChannelsPageSize(dispatch, pageSize);
