import React from 'react';

import { Link } from 'react-router-dom';

import { ROUTES } from '../../../../common/constants/routing';
import { RenderDateFormat } from '../../../../common/components/RenderDateFormat';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import {
    changeModificationsPageNumber,
    changeModificationsPageSize,
} from '../pendingModificationsActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';
import VerifyButton from '../verification/VerifyButton';

export const getColumns = (dispatch) => {
    const columns = [
        {
            dataIndex: 'createdAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Request Time'),
        },
        {
            dataIndex: 'userName',
            name: 'Username',
            render: (value, record) => (
                <Link
                    to={`${ROUTES.DASHBOARD.PLAYERS.PLAYERS_LIST}/${record.userId}`}
                    className="username-column"
                >
                    {value}
                </Link>
            ),
            title: getTranslation('Username'),
        },
        {
            dataIndex: 'transactionCode',
            title: getTranslation('Transaction Code'),
        },
        {
            dataIndex: 'type',
            title: getTranslation('Type'),
        },
        {
            dataIndex: 'currentInfo',
            title: getTranslation('Previous Info'),
        },
        {
            dataIndex: 'newInfo',
            title: getTranslation('Current Info'),
        },
        {
            dataIndex: 'requesterRemark',
            title: getTranslation('Remark'),
        },
        {
            dataIndex: 'createdByName',
            title: getTranslation('Applicant'),
        },
        {
            dataIndex: 'operations',
            dataType: 'operation',
            render: (value, record) => <VerifyButton dispatch={dispatch} modification={record} />,
            title: getTranslation('Operations'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changeModificationsPageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) =>
    changeModificationsPageSize(dispatch, pageSize);
