import React from 'react';

import { ButtonTransparent } from '../../../../common/components/buttons/Buttons';
import { TRANSACTION_STATUSES } from '../../../../common/constants/misc';
import {
    openRejectModal,
    openVerifyModal,
    performEwalletWithdrawalsOperation,
} from '../ewalletWithdrawalsActions';

const ViewRecordButton = ({ dispatch, transaction }) => {
    // ============================================
    // METHODS
    // ============================================

    const openModal = () => {
        performEwalletWithdrawalsOperation(dispatch, {
            action: null,
            isRecord: true,
            transaction,
        });
        transaction.transactionStatus === TRANSACTION_STATUSES.SUCCESS
            ? openVerifyModal(dispatch)
            : openRejectModal(dispatch);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <ButtonTransparent className="hyperlink-button" onClick={openModal}>
            {transaction.transactionNo}
        </ButtonTransparent>
    );
};

export default ViewRecordButton;
