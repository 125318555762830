import React from 'react';

import Moment from 'react-moment';

import { getTranslation } from '../../../../../common/helpers/locale';

export const getColumns = (columnTitle) => {
    return [
        {
            dataIndex: 'createdAt',
            render: (value) => (
                <Moment format="DD/MM/YYYY HH:mm:ss" utc local>
                    {value}
                </Moment>
            ),
            title: getTranslation('Date'),
        },
        { dataIndex: 'remark', title: getTranslation('Remarks') },
        { dataIndex: 'item', title: getTranslation(columnTitle) },
        { dataIndex: 'createdByName', title: getTranslation('Operator') },
    ];
};
