import React from 'react';

import { isUserLoggedIn } from '../helpers/api';

const Authorization = (WrappedComponent, allowedRoles) => (routeProps) => {
    // const role = isUserLoggedIn() ? getCurrentUser().role : undefined;
    // const authorized = _.includes(allowedRoles, role);
    // ** temperary hide this**//

    return isUserLoggedIn() ? (
        <WrappedComponent {...routeProps} />
    ) : (
        <h2 className="element--centered">Oops...You are not authorized to see this page!</h2>
    );
};

export default Authorization;
