import React from 'react';

import { Card, Form } from 'antd';

import {
    dropFilters,
    rebateVerifyModalClose,
    rebateVerifyModalOpen,
} from './rebateGeneratorActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useGetRebateTransactionListAsyncEndpoint } from '../../../services/rebateService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import RebateGeneratorFilters from './RebateGeneratorFilters';
import RebateGeneratorResultsTable from './table/RebateGeneratorResultsTable';
import VerifyModal from './modal/verification-modal/VerifyModal';

const RebateGenerator = () => {
    const [state, dispatch] = useStore();
    const verifyModalVisible = getStateProp(state, 'rebate.rebateGenerator.verifyModalVisible');

    // ============================================
    // METHODS
    // ============================================

    const onCloseVerifyModal = () => {
        rebateVerifyModalClose(dispatch);
    };

    const onOpenVerifyModalForBatchVerify = () => rebateVerifyModalOpen(dispatch);

    const onDropFilters = () => {
        dropFilters(dispatch);
        getRebateTransaction();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, getRebateTransaction] = useGetRebateTransactionListAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Rebate Generator')}>
            <Form onFinish={getRebateTransaction}>
                <RebateGeneratorFilters />
                <ActionButtons
                    onDropFilters={onDropFilters}
                    onOpenModal={onOpenVerifyModalForBatchVerify}
                />
            </Form>
            <RebateGeneratorResultsTable loading={loading} />

            <VerifyModal visible={verifyModalVisible} onCancel={onCloseVerifyModal} />
        </Card>
    );
};

export default RebateGenerator;
