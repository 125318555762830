import React from 'react';

import { Card } from 'antd';

import { getTranslation } from '../../../common/helpers/locale';
import {
    useCryptoCurrencyCorrectionOperation,
    useGetCryptoCurrencyRateAsyncEndpoint,
    useGetPlayerDetailsAsyncEndpoint,
} from '../../../services/cryptoCurrencyCorrectionService';
import CryptocurrencyCorrectionForm from './CryptocurrencyCorrectionForm';

const CryptocurrencyCorrection = () => {
    // ============================================
    // HOOKS
    // ============================================

    const [getPlayerDetailsLoading] = useGetPlayerDetailsAsyncEndpoint();

    const [
        getCryptoCurrencyRateLoading,
        handleGetCryptoCurrencyRate,
    ] = useGetCryptoCurrencyRateAsyncEndpoint();
    const [
        submitCryptoCurrencyConrrectionLoading,
        submitCryptoCurrencyConrrectionResponse,
        handleSubmitCryptoCurrencyCorrectionOperation,
    ] = useCryptoCurrencyCorrectionOperation();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Cryptocurrency Correction')}>
            <CryptocurrencyCorrectionForm
                playerDetailsLoading={getPlayerDetailsLoading}
                handleGetCryptoCurrencyRate={handleGetCryptoCurrencyRate}
                cryptoCurrencyRateLoading={getCryptoCurrencyRateLoading}
                cryptoCurrencyConrrectionLoading={submitCryptoCurrencyConrrectionLoading}
                operationSuccess={submitCryptoCurrencyConrrectionResponse}
                handleSubmitCryptoCurrencyCorrectionOperation={
                    handleSubmitCryptoCurrencyCorrectionOperation
                }
            />
        </Card>
    );
};

export default CryptocurrencyCorrection;
