import React from 'react';

import { Row } from 'antd';

import { PeriodFilter } from '../../../common/components/filters';
import { RebateCodeFilter, RebateNameFilter, RebateStatusFilter } from '../filters';
import { getStateProp } from '../../../common/helpers/misc';
import { rebateManagementFilterSet } from './rebateManagementActions';
import { useStore } from '../../../store/StateProvider';

const RebateManagementFilters = () => {
    const [state, dispatch] = useStore();

    const { rebateManagement } = getStateProp(state, 'rebate');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => rebateManagementFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <RebateCodeFilter
                onFilterChange={setFilter}
                value={getStateProp(rebateManagement, 'filters.code')}
            />
            <RebateNameFilter
                onFilterChange={setFilter}
                value={getStateProp(rebateManagement, 'filters.name')}
            />
            <RebateStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(rebateManagement, 'filters.status')}
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(rebateManagement, 'filters.dateFrom')}
                dateFromName="dateFrom"
                dateTo={getStateProp(rebateManagement, 'filters.dateTo')}
                dateToName="dateTo"
            />
        </Row>
    );
};

export default RebateManagementFilters;
