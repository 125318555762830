import React from 'react';

import { Row } from 'antd';

import {
    BankFilter,
    CurrencyFilter,
    MemberLevelFilter,
    OrderStatusFilter,
    PeriodFilter,
    TransactionNumberFilter,
    UsernameFilter,
} from '../../../common/components/filters';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';
import { withdrawalRecordsFilterSet } from './withdrawalRecordsActions';

const WithdrawalRecordFilters = () => {
    const [state, dispatch] = useStore();

    const { withdrawalRecords } = getStateProp(state, 'transactionHistory');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => withdrawalRecordsFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(withdrawalRecords, 'filters.playerUserName')}
                name="playerUserName"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(withdrawalRecords, 'filters.requestedDateFrom')}
                dateFromName="requestedDateFrom"
                dateTo={getStateProp(withdrawalRecords, 'filters.requestedDateTo')}
                dateToName="requestedDateTo"
            />
            <TransactionNumberFilter
                onFilterChange={setFilter}
                value={getStateProp(withdrawalRecords, 'filters.transactionNo')}
                name="transactionNo"
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(withdrawalRecords, 'filters.currencyCode')}
                name="currencyCode"
            />
            <OrderStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(withdrawalRecords, 'filters.orderStatuses')}
                type="withdrawal"
                name="orderStatuses"
            />
            <MemberLevelFilter
                onFilterChange={setFilter}
                value={getStateProp(withdrawalRecords, 'filters.memberLevel')}
            />
            <BankFilter
                onFilterChange={setFilter}
                value={getStateProp(withdrawalRecords, 'filters.bankName')}
                placeholder={getTranslation('Bank Name')}
            />
        </Row>
    );
};

export default WithdrawalRecordFilters;
