import React from 'react';

import { getStatusColor, sortTableByDataType } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';

export const getColumns = () => {
    const columns = [
        {
            dataIndex: 'transNo',
            title: getTranslation('Task Transaction Number'),
        },
        {
            dataIndex: 'userName',
            title: getTranslation('Username'),
        },
        {
            dataIndex: 'currencyCode',
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'bonusCode',
            title: getTranslation('Bonus Code'),
        },
        {
            dataIndex: 'bonusName',
            title: getTranslation('Bonus Name'),
        },
        {
            dataIndex: 'bonusAmount',
            title: getTranslation('Bonus Amount'),
        },
        {
            dataIndex: 'turnoverCondition',
            title: getTranslation('T. Requirement'),
        },
        {
            dataIndex: 'turnoverAmount',
            title: getTranslation('T. Amount'),
        },
        {
            dataIndex: 'applicantRemarks',
            title: getTranslation('Remark'),
        },
        {
            dataIndex: 'approvalStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>{value.toLocaleUpperCase()}</span>
            ),
            title: getTranslation('Approval Status'),
        },
        {
            dataIndex: 'progressStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>{value.toLocaleUpperCase()}</span>
            ),
            title: getTranslation('Progress Status'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};
