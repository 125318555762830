import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './PushNotificationTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const PushNotificationResultsTable = ({ loading, openEditModal }) => {
    const [state, dispatch] = useStore();

    const tableData = getStateProp(state, 'pushNotification.pushNotificationManagement.list', []);
    const paging = getStateProp(state, 'pushNotification.pushNotificationManagement.paging', {});

    // ============================================
    // RENDER
    // ============================================
    return (
        <Table
            columns={getColumns(openEditModal)}
            rowKey={(record) => record.id}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            dataSource={tableData}
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
        />
    );
};

export default PushNotificationResultsTable;
