import React from 'react';

import { Form } from 'antd';

import { API } from '../../../../common/constants/api';
import {
    dropFilters,
    onRemarkModalClose,
    onVerifyModalClose,
    setBatchActionModal,
    setSelectedBonusIds,
} from '../manualManagementActions';
import { getColumns, manualManagementRecordsExport } from './table/BatchManualManagementMeta';
import { getCurrentUser } from '../../../../common/helpers/api';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useExportAsyncEndpoint } from '../../../../services/hooks/useExport';
import {
    useGetBonusCodeListAsyncEndpoint,
    useGetManualManagementRecordsAsyncEndpoint,
} from '../../../../services/manualManagementService';
import { useStore } from '../../../../store/StateProvider';
import ActionButtons from '../ActionButtons';
import BatchActionModal from '../verification/BatchActionModal';
import BatchManualManagementTable from './table/BatchManualManagementTable';
import ManualManagementFilters from '../ManualManagementFilters';
import RemarkModal from '../../../../common/components/RemarkModal';
import VerifyModal from '../verification/VerifyModal';
import useInternetStatus from '../../../../services/hooks/useInternetStatus';

const { BONUS } = API;

const BatchManualManagement = ({ history }) => {
    const [state, dispatch] = useStore();

    const manualManagement = getStateProp(state, 'bonus.manualManagement');
    const remarkModalVisible = getStateProp(manualManagement, 'remarkModalVisible');
    const verifyModalVisible = getStateProp(manualManagement, 'verifyModalVisible');
    const batchActionModalVisible = getStateProp(manualManagement, 'batchActionModalVisible');
    const remarks = getStateProp(manualManagement, 'remarks', {});
    const bonusSelected = getStateProp(manualManagement, 'bonusSelected', {});
    const userName = getStateProp(
        state,
        'user.userName',
        getStateProp(getCurrentUser(), 'claims.userName', 'Anonymus'), //Temp fix
    );
    const refreshTime = getStateProp(manualManagement, 'operation.refreshTime', 15);
    const { applicantRemark, operatorRemark } = remarks;
    const filters = getStateProp(manualManagement, 'filters');
    const currency = getStateProp(manualManagement, 'filters.currency', '');
    const { totalRecordCount } = getStateProp(manualManagement, 'paging', {});

    // ============================================
    // METHODS
    // ============================================

    const closeRemarkModal = () => onRemarkModalClose(dispatch);

    const closeVerifyModal = () => onVerifyModalClose(dispatch);

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetManualManagementRecords();
    };

    const onSearch = () => {
        setSelectedBonusIds(dispatch, []);
        handleGetManualManagementRecords();
        handleGetBonusCode();
    };

    const handleBatchActionModal = (isVisible, action) => {
        action === 'Cancel'
            ? setBatchActionModal(dispatch, {
                  batchAction: '',
                  batchActionModalVisible: isVisible,
                  operation: {
                      refreshTime: refreshTime,
                      refreshTimePause: true,
                  },
                  selectedBonusIds: [],
              })
            : setBatchActionModal(dispatch, {
                  batchAction: action,
                  batchActionModalVisible: isVisible,
                  operation: {
                      refreshTime: refreshTime,
                      refreshTimePause: true,
                  },
              });
    };

    // ============================================
    // HOOKS
    // ============================================

    const [isOnline] = useInternetStatus();
    const [loading, handleGetManualManagementRecords] = useGetManualManagementRecordsAsyncEndpoint(
        isOnline,
        true,
    );

    const [handleExportAction] = useExportAsyncEndpoint({
        additionalCol: manualManagementRecordsExport,
        dataMeta: getColumns(),
        filters,
        innerListKey: 'data',
        method: 'GET',
        subject: getTranslation('Manual Bonus Management'),
        totalRecordCount,
        url: `${BONUS.LIST}`,
    });

    const [handleGetBonusCode] = useGetBonusCodeListAsyncEndpoint();
    // ============================================
    // RENDER
    // ============================================

    return (
        <>
            <Form onFinish={onSearch}>
                <ManualManagementFilters />
                <ActionButtons
                    isBatchUpload={true}
                    onDropFilters={fetchAll}
                    onExport={handleExportAction}
                    onHandleBatchActionModal={handleBatchActionModal}
                />
            </Form>

            <BatchManualManagementTable loading={loading} currency={currency} />
            <VerifyModal
                visible={verifyModalVisible}
                bonus={bonusSelected}
                onCancel={closeVerifyModal}
                handleGetManualManagementRecords={handleGetManualManagementRecords}
                userName={userName}
                isBatchUpload={true}
            />
            <RemarkModal
                visible={remarkModalVisible}
                onCancel={closeRemarkModal}
                applicantRemark={applicantRemark}
                operatorRemark={operatorRemark}
            />
            <BatchActionModal
                visible={batchActionModalVisible}
                onCancel={handleBatchActionModal}
                loading={false}
            />
        </>
    );
};

export default BatchManualManagement;
