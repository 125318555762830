export const ACTIONS = {
    CREATE_REFERRAL_PROGRAM: 'CREATE_REFERRAL_PROGRAM',
    CREATE_REFERRAL_PROGRAM_FAILURE: 'CREATE_REFERRAL_PROGRAM_FAILURE',
    CREATE_REFERRAL_PROGRAM_SUCCESS: 'CREATE_REFERRAL_PROGRAM_SUCCESS',
    CREATE_REFERRAL_SET: 'CREATE_REFERRAL_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'CREATE_REFERRA_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'CREATE_REFERRA_TABLE_PAGE_SIZE_CHANGE',
    },
    RESET_REFERRAL_PROGRAM: 'RESET_REFERRAL_PROGRAM',
};

export const createReferralProgram = (dispatch) =>
    dispatch({
        type: ACTIONS.CREATE_REFERRAL_PROGRAM,
    });

export const createReferralProgramFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CREATE_REFERRAL_PROGRAM_FAILURE,
    });

export const createReferralProgramSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.CREATE_REFERRAL_PROGRAM_SUCCESS,
    });

export const setReferralInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CREATE_REFERRAL_SET,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const resetReferralProgram = (dispatch) =>
    dispatch({
        type: ACTIONS.RESET_REFERRAL_PROGRAM,
    });
