export const ACTIONS = {
    BONUS_CATEGORY_ACTIVE_LIST_GET: 'BONUS_CATEGORY_ACTIVE_LIST_GET',
    BONUS_CATEGORY_ACTIVE_LIST_GET_FAILURE: 'BONUS_CATEGORY_ACTIVE_LIST_GET_FAILURE',
    BONUS_CATEGORY_ACTIVE_LIST_GET_SUCCESS: 'BONUS_CATEGORY_ACTIVE_LIST_GET_SUCCESS',
    BONUS_CATEGORY_ASSIGNED_CHECK: 'BONUS_CATEGORY_ASSIGNED_CHECK',
    BONUS_CATEGORY_ASSIGNED_CHECK_FAILURE: 'BONUS_CATEGORY_ASSIGNED_CHECK_FAILURE',
    BONUS_CATEGORY_ASSIGNED_CHECK_SUCCESS: 'BONUS_CATEGORY_ASSIGNED_CHECK_SUCCESS',
    BONUS_CATEGORY_CREATE: 'BONUS_CATEGORY_CREATE',
    BONUS_CATEGORY_CREATE_FAILURE: 'BONUS_CATEGORY_CREATE_FAILURE',
    BONUS_CATEGORY_CREATE_SUCCESS: 'BONUS_CATEGORY_CREATE_SUCCESS',
    BONUS_CATEGORY_GET: 'BONUS_CATEGORY_GET',
    BONUS_CATEGORY_GET_FAILURE: 'BONUS_CATEGORY_GET_FAILURE',
    BONUS_CATEGORY_GET_SUCCESS: 'BONUS_CATEGORY_GET_SUCCESS',
    BONUS_CATEGORY_MODAL_CLOSE: 'BONUS_CATEGORY_MODAL_CLOSE',
    BONUS_CATEGORY_MODAL_OPEN: 'BONUS_CATEGORY_MODAL_OPEN',
    BONUS_CATEGORY_ORDER_SET: 'BONUS_CATEGORY_ORDER_SET',
    BONUS_CATEGORY_SORT: 'BONUS_CATEGORY_SORT',
    BONUS_CATEGORY_SORT_FAILURE: 'BONUS_CATEGORY_SORT_FAILURE',
    BONUS_CATEGORY_SORT_SUCCESS: 'BONUS_CATEGORY_SORT_SUCCESS',
    BONUS_CATEGORY_UPDATE: 'BONUS_CATEGORY_UPDATE',
    BONUS_CATEGORY_UPDATE_FAILURE: 'BONUS_CATEGORY_UPDATE_FAILURE',
    BONUS_CATEGORY_UPDATE_SUCCESS: 'BONUS_CATEGORY_UPDATE_SUCCESS',
    PAGINATION: {
        BONUS_CATEGORY_TABLE_PAGE_CHANGE: 'BONUS_CATEGORY_TABLE_PAGE_CHANGE',
        BONUS_CATEGORY_TABLE_PAGE_SIZE_CHANGE: 'BONUS_CATEGORY_TABLE_PAGE_SIZE_CHANGE',
    },
};

//#region banner change page
export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.BONUS_CATEGORY_TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.BONUS_CATEGORY_TABLE_PAGE_CHANGE,
    });
//#endregion

//#region open modal
export const openBonusCategoryModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_CATEGORY_MODAL_OPEN,
    });

export const closeBonusCategoryModal = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_CATEGORY_MODAL_CLOSE,
    });

//#endregion

//#region get category
export const bonusCategoryGet = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_CATEGORY_GET,
    });

export const bonusCategoryGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_CATEGORY_GET_SUCCESS,
    });

export const bonusCategoryGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_CATEGORY_GET_FAILURE,
    });
//#endregion

//#region create category
export const bonusCategoryCreate = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_CATEGORY_CREATE,
    });

export const bonusCategoryCreateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_CATEGORY_CREATE_SUCCESS,
    });

export const bonusCategoryCreateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_CATEGORY_CREATE_FAILURE,
    });
//#endregion

//#region update category
export const bonusCategoryUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_CATEGORY_UPDATE,
    });

export const bonusCategoryUpdateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_CATEGORY_UPDATE_SUCCESS,
    });

export const bonusCategoryUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_CATEGORY_UPDATE_FAILURE,
    });
//#endregion

//#region sort category
export const bonusCategorySort = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_CATEGORY_SORT,
    });

export const bonusCategorySortSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_CATEGORY_SORT_SUCCESS,
    });

export const bonusCategorySortFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_CATEGORY_SORT_FAILURE,
    });
//#endregion

export const bonusCategorySortingOrderSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_CATEGORY_ORDER_SET,
    });

//#region get active category
export const activeBonusCategoryGet = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_CATEGORY_ACTIVE_LIST_GET,
    });

export const activeBonusCategoryGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_CATEGORY_ACTIVE_LIST_GET_SUCCESS,
    });

export const activeBonusCategoryGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_CATEGORY_ACTIVE_LIST_GET_FAILURE,
    });
//#endregion

//#region check assigned category
export const assignedCategoryCheck = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_CATEGORY_ASSIGNED_CHECK,
    });

export const assignedCategoryCheckSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_CATEGORY_ASSIGNED_CHECK_SUCCESS,
    });

export const assignedCategoryCheckFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_CATEGORY_ASSIGNED_CHECK_FAILURE,
    });
//#endregion
