import { ACTIONS } from './bonusRecordsActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS_1000,
} from '../../../common/constants/api';

export default function bonusRecordsReducer(
    bonusRecords = {
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        remarkModalVisible: false,
        resetPageNo: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.REMARK_MODAL_CLOSE:
            return {
                ...bonusRecords,
                remarkModalVisible: false,
            };
        case ACTIONS.REMARK_MODAL_OPEN:
            return {
                ...bonusRecords,
                remarkModalVisible: true,
                remarks: payload,
            };

        case ACTIONS.BONUS_RECORDS_GET_SUCCESS:
            return {
                ...bonusRecords,
                ...payload,
            };

        case ACTIONS.PAGINATION.BONUS_RECORDS_TABLE_PAGE_CHANGE:
            return {
                ...bonusRecords,
                paging: {
                    ...bonusRecords.paging,
                    pageNo:
                        payload > bonusRecords.paging.pageCount
                            ? bonusRecords.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.BONUS_RECORDS_TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS_1000[TABLE_PAGE_SIZE_OPTIONS_1000.length - 1],
            );
            return {
                ...bonusRecords,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.BONUS_RECORDS_FILTER_SET:
            return {
                ...bonusRecords,
                filters: {
                    ...bonusRecords.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };
        case ACTIONS.BONUS_RECORDS_FILTERS_DROP_ALL:
            return {
                ...bonusRecords,
                filters: null,
                resetPageNo: true,
            };

        case ACTIONS.BONUS_RECORDS_GET:
            const pageNo = bonusRecords.resetPageNo
                ? TABLE_PAGE_NO_DEFAULT
                : bonusRecords.paging.pageNo;
            return {
                ...bonusRecords,
                paging: {
                    ...bonusRecords.paging,
                    pageNo: pageNo,
                },
                resetPageNo: false,
            };

        default:
            return bonusRecords;
    }
}
