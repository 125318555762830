import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { MODIFICATION_STATUSES } from '../constants';
import { getTranslation } from '../../../common/helpers/locale';

const ModificationStatusFilter = ({ value, onFilterChange }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('status', value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear
            placeholder={getTranslation('Status')}
            className="filter"
        >
            {_.map(
                _.reject(MODIFICATION_STATUSES, (status) => {
                    return status === MODIFICATION_STATUSES.PENDING;
                }),
                (status) => (
                    <Select.Option key={status} value={status}>
                        {getTranslation(status)}
                    </Select.Option>
                ),
            )}
        </Select>
    );
};

export default ModificationStatusFilter;
