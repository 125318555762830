export const ACTIONS = {
    EDITOR_INFO_SET: 'EDITOR_INFO_SET',
    GROUP_ACCESS_LIST_GET: 'GROUP_ACCESS_LIST_GET',
    GROUP_ACCESS_LIST_GET_FAILURE: 'GROUP_ACCESS_LIST_GET_FAILURE',
    GROUP_ACCESS_LIST_GET_SUCCESS: 'GROUP_ACCESS_LIST_GET_SUCCESS',
    GROUP_ACCESS_RECORD_CREATE: 'GROUP_ACCESS_RECORD_CREATE',
    GROUP_ACCESS_RECORD_CREATE_FAILURE: 'GROUP_ACCESS_RECORD_CREATE_FAILURE',
    GROUP_ACCESS_RECORD_CREATE_SUCCESS: 'GROUP_ACCESS_RECORD_CREATE_SUCCESS',
    GROUP_ACCESS_RECORD_DELETE: 'GROUP_ACCESS_RECORD_DELETE',
    GROUP_ACCESS_RECORD_DELETE_FAILURE: 'GROUP_ACCESS_RECORD_DELETE_FAILURE',
    GROUP_ACCESS_RECORD_DELETE_MODAL_CLOSE: 'GROUP_ACCESS_RECORD_DELETE_MODAL_CLOSE',
    GROUP_ACCESS_RECORD_DELETE_MODAL_OPEN: 'GROUP_ACCESS_RECORD_DELETE_MODAL_OPEN',
    GROUP_ACCESS_RECORD_DELETE_SUCCESS: 'GROUP_ACCESS_RECORD_DELETE_SUCCESS',
    GROUP_ACCESS_RECORD_GET: 'GROUP_ACCESS_RECORD_GET',
    GROUP_ACCESS_RECORD_GET_FAILURE: 'GROUP_ACCESS_RECORD_GET_FAILURE',
    GROUP_ACCESS_RECORD_GET_SUCCESS: 'GROUP_ACCESS_RECORD_GET_SUCCESS',
    GROUP_ACCESS_RECORD_RESET: 'GROUP_ACCESS_RECORD_RESET',
    GROUP_ACCESS_RECORD_UPDATE: 'GROUP_ACCESS_RECORD_UPDATE',
    GROUP_ACCESS_RECORD_UPDATE_FAILURE: 'GROUP_ACCESS_RECORD_UPDATE_FAILURE',
    GROUP_ACCESS_RECORD_UPDATE_SUCCESS: 'GROUP_ACCESS_RECORD_UPDATE_SUCCESS',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'GROUP_ACCESS_LIST_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'GROUP_ACCESS_LIST_TABLE_PAGE_SIZE_CHANGE',
    },
    SELECTED_MENUS_SET: 'SELECTED_MENUS_SET',
};

export const resetGroupAccessRecord = (dispatch) =>
    dispatch({
        type: ACTIONS.GROUP_ACCESS_RECORD_RESET,
    });

export const setEditorInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.EDITOR_INFO_SET,
    });

export const setSelectedMenu = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.SELECTED_MENUS_SET,
    });

//#region
export const groupAccessListGet = (dispatch) =>
    dispatch({
        type: ACTIONS.GROUP_ACCESS_LIST_GET,
    });

export const groupAccessListGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GROUP_ACCESS_LIST_GET_FAILURE,
    });

export const groupAccessListGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GROUP_ACCESS_LIST_GET_SUCCESS,
    });
//#endregion

//#region
export const groupAccessRecordCreate = (dispatch) =>
    dispatch({
        type: ACTIONS.GROUP_ACCESS_RECORD_CREATE,
    });

export const groupAccessRecordCreateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GROUP_ACCESS_RECORD_CREATE_FAILURE,
    });

export const groupAccessRecordCreateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.GROUP_ACCESS_RECORD_CREATE_SUCCESS,
    });
//#endregion

//#region
export const groupAccessRecordGet = (dispatch) =>
    dispatch({
        type: ACTIONS.GROUP_ACCESS_RECORD_GET,
    });

export const groupAccessRecordGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GROUP_ACCESS_RECORD_GET_FAILURE,
    });

export const groupAccessRecordGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GROUP_ACCESS_RECORD_GET_SUCCESS,
    });
//#endregion

//#region
export const groupAccessRecordUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.GROUP_ACCESS_RECORD_UPDATE,
    });

export const groupAccessRecordUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GROUP_ACCESS_RECORD_UPDATE_FAILURE,
    });

export const groupAccessRecordUpdateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.GROUP_ACCESS_RECORD_UPDATE_SUCCESS,
    });
//#endregion

//#region
export const groupAccessRecordDelete = (dispatch) =>
    dispatch({
        type: ACTIONS.GROUP_ACCESS_RECORD_DELETE,
    });

export const groupAccessRecordDeleteFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GROUP_ACCESS_RECORD_DELETE_FAILURE,
    });

export const groupAccessRecordDeleteSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.GROUP_ACCESS_RECORD_DELETE_SUCCESS,
    });

export const openGroupAccessRecordDeleteModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GROUP_ACCESS_RECORD_DELETE_MODAL_OPEN,
    });

export const closeGroupAccessRecordDeleteModal = (dispatch) =>
    dispatch({
        type: ACTIONS.GROUP_ACCESS_RECORD_DELETE_MODAL_CLOSE,
    });
//#endregion

//#region
export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });
//#endregion
