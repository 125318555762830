import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { TIMEZONE } from '../../constants/localization';
import { getTranslation } from '../../helpers/locale';

const TimezoneFilter = ({ onFilterChange, value }) => {
    const sortedTimezone = _.sortBy(TIMEZONE, [
        function(o) {
            return parseInt(o.OFFSET);
        },
    ]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={onFilterChange}
            allowClear={false}
            placeholder={getTranslation('Timezone Offset')}
            className="filter"
        >
            {_.map(sortedTimezone, (timezone) => (
                <Select.Option value={timezone.OFFSET} key={timezone.OFFSET}>
                    {`Timezone  ${timezone.OFFSET.slice(0, -2)}`}
                </Select.Option>
            ))}
        </Select>
    );
};

export default TimezoneFilter;
