import React from 'react';

import { Input } from 'antd';

import { getTranslation } from '../../../common/helpers/locale';

const EwalletAccountNameFilter = ({ value, onFilterChange }) => {
    return (
        <Input
            onChange={(e) => onFilterChange('accountHolderName', e.target.value)}
            value={value}
            className="filter"
            placeholder={getTranslation('Account Holder Name')}
        />
    );
};

export default EwalletAccountNameFilter;
