import React from 'react';

import _ from 'lodash';

import { LANGUAGES } from '../../../../common/constants/localization';
import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS_500 } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from './globalAnnouncementActions';
import { findTitleByLanguage, getTranslation } from '../../../../common/helpers/locale';
import { getStatusColor, sortTableByDataType } from '../../../../common/helpers/misc';
import RowActionButtons from './RowActionButton';

export const getColumns = (langCode, tableWidth) => {
    // ============================================
    // RENDER
    // ============================================

    const columns = [
        {
            dataIndex: 'createdAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Request Time'),
        },
        {
            dataIndex: 'taskNo',
            title: getTranslation('Task Number'),
            width: 80,
        },
        {
            dataIndex: 'announcementType',
            title: getTranslation('Announcement Type'),
            width: 120,
        },
        {
            dataIndex: 'category',
            title: getTranslation('Category'),
            width: 80,
        },
        {
            dataIndex: 'title',
            filterMultiple: false,
            filters: _.map(LANGUAGES, (language) => {
                return {
                    text: language.name,
                    value: language.value,
                };
            }),
            render: (value, record) => {
                return <span>{findTitleByLanguage(record.announcementItems, langCode)}</span>;
            },
            title: getTranslation('Title'),
        },
        {
            dataIndex: 'outcome',
            title: getTranslation('Outcome'),
        },
        {
            dataIndex: 'currency',
            render: (value, record) => (
                <span>
                    {_.join(
                        _.uniq(
                            _.map(record.announcementItems, (item) => {
                                return item.currency;
                            }),
                        ),
                        ', ',
                    )}
                </span>
            ),
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'durationStart',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Duration Start'),
        },
        {
            dataIndex: 'durationEnd',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Duration End'),
        },
        {
            dataIndex: 'announcementStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>{value.toLocaleUpperCase()}</span>
            ),
            title: getTranslation('Status'),
        },
        {
            dataIndex: 'applicant',
            title: getTranslation('Applicant'),
        },
        {
            dataIndex: 'operation',
            dataType: 'operation',
            render: (value, record) => <RowActionButtons announcement={record} />,
            title: getTranslation('Operation'),
            width: tableWidth < 2200 ? 300 : 280,
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS_500,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);

export const announcementTitleExport = ({ record, onlyMeta }) => {
    if (onlyMeta) {
        return [
            {
                dataIndex: 'title',
                title: getTranslation('Title'),
            },
        ];
    }

    let messageArray = [];
    messageArray.push(['title', findTitleByLanguage(record.announcementItems)]);
    return messageArray;
};
