import React from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';
import { Menu, Row } from 'antd';

import { FlagIcon } from '../../common/components';
import { SYSTEM_LANGUAGES } from '../../common/constants/localization';
import { getLocaleByCountry } from '../../common/helpers/locale';
import { setCurrentLanguage } from './actions';
import { setLangCookies } from '../../common/helpers/api';
import { useStore } from '../../store/StateProvider';

const LanguageMenu = () => {
    const [state, dispatch] = useStore();
    // ============================================
    // METHODS
    // ============================================

    const switchLocale = ({ key }) => {
        setLangCookies(key);
        setCurrentLanguage(dispatch, { language: key });
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Menu onClick={switchLocale} selectedKeys={[getLocaleByCountry(state.country)]}>
            {SYSTEM_LANGUAGES.map((lang) => (
                <Menu.Item key={lang.value}>
                    <Row align="middle" justify="space-between">
                        <span style={{ paddingLeft: 6 }}>
                            <FlagIcon code={lang.country} /> {lang.name.toLocaleUpperCase()}
                        </span>
                        {lang.country === state.country && <CheckCircleOutlined />}
                    </Row>
                </Menu.Item>
            ))}
        </Menu>
    );
};

export default LanguageMenu;
