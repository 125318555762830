import { ACTIONS } from './categoryActions';

export default function categoryReducer(category = {}, { type, payload = null }) {
    switch (type) {
        case ACTIONS.CATEGORY_LIST_GET_SUCCESS:
            return payload;

        case ACTIONS.CATEGORY_LIST_GET:
        default:
            return category;
    }
}
