import { Redirect, Switch } from 'react-router-dom';
import React from 'react';

import {
    BalanceManagement,
    CryptocurrencyCorrection,
    DepositCorrection,
    EwalletCorrection,
} from '.';
import { PrivateRoute } from '../../common/components';
import { ROUTES } from '../../common/constants/routing';

const Balance = () => {
    return (
        <Switch>
            <PrivateRoute
                path={ROUTES.DASHBOARD.BALANCE.BALANCE_MANAGEMENT}
                component={BalanceManagement}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.BALANCE.DEPOSIT_CORRECTION}
                component={DepositCorrection}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.BALANCE.CRYPTOCURRENCY_CORRECTION}
                component={CryptocurrencyCorrection}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.BALANCE.EWALLET_CORRECTION}
                component={EwalletCorrection}
            />
            <Redirect to={ROUTES.DASHBOARD.BALANCE.BALANCE_MANAGEMENT} />
        </Switch>
    );
};

export default Balance;
