import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

import { API } from '../common/constants/api';
import { DEBOUNCE_DELAY_SEC } from '../common/constants/misc';
import { REQUEST_SEARCH_PLAYER_INFO_ACTIONS } from '../components/bonus/manual-management/constants';
import { clearError } from '../common/actions/errorActions';
import {
    depositAmountGetFailure,
    depositAmountGetSuccess,
    getBonusCode,
    getBonusCodeFailure,
    getBonusCodeSuccess,
    manualManagementBatchReject,
    manualManagementBatchRejectFailure,
    manualManagementBatchRejectSuccess,
    manualManagementBatchSubmit,
    manualManagementBatchSubmitFailure,
    manualManagementBatchSubmitSuccess,
    manualManagementBatchUpdate,
    manualManagementBatchUpdateFailure,
    manualManagementBatchUpdateSuccess,
    manualManagementRecordsGet,
    manualManagementRecordsGetFailure,
    manualManagementRecordsGetSuccess,
    manualManagementVerifySend,
    manualManagementVerifySendFailure,
    manualManagementVerifySendSuccess,
    onVerifyModalClose,
    turnoverAmountGet,
    turnoverAmountGetFailure,
    turnoverAmountGetSuccess,
    updateRewardInfo,
    updateRewardInfoFailure,
    updateRewardInfoSuccess,
} from '../components/bonus/manual-management/manualManagementActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import { message } from 'antd';
import { useDebounce } from './hooks/useDebounce';
import { useNotification } from './hooks/useNotification';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, BONUS, CRYPTO, PAYMENTS, TRANSACTION_MONITORING, PROMOTIONS } = API;

export function useGetManualManagementRecordsAsyncEndpoint(isOnline, isBatchUpload) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { manualManagement } = getStateProp(state, 'bonus');
    const { pageNo, pageSize, totalRecordCountNoFilter } = getStateProp(
        manualManagement,
        'paging',
        {},
    );
    const filters = getStateProp(manualManagement, 'filters', {});
    const refreshTime = getStateProp(manualManagement, 'operation.refreshTime');
    const refreshTimePause = getStateProp(manualManagement, 'operation.refreshTimePause');

    // ============================================
    // METHODS
    // ============================================

    const handleGetManualManagementRecords = useCallback(() => {
        manualManagementRecordsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                isBatchUpload,
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS.LIST}`,
    });

    useEffect(() => {
        let timeInterval;
        if (response || isOnline)
            timeInterval = setTimeout(() => handleGetManualManagementRecords(), refreshTime * 1000);
        if (refreshTimePause) clearTimeout(timeInterval);
        return () => clearTimeout(timeInterval);
    }, [handleGetManualManagementRecords, isOnline, refreshTime, refreshTimePause, response]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            manualManagementRecordsGetSuccess(dispatch, {
                fetchData: handleGetManualManagementRecords,
                list: response.data.data[0].data,
                paging: response.data.paging,
                totalBonusAmount: response.data.data[0].totalBonusAmount,
                totalDepositAmount: response.data.data[0].totalDepositAmount,
            });
        }
    }, [dispatch, handleGetManualManagementRecords, response]);

    useEffect(() => {
        handleGetManualManagementRecords();
    }, [handleGetManualManagementRecords, pageNo, pageSize]);

    useNotification(
        totalRecordCountNoFilter,
        getStateProp(response, 'data.paging.totalRecordCountNoFilter', 0),
        'You have new bonus request record',
    );

    const errorMsg = useError(error, manualManagementRecordsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetManualManagementRecords];
}

export function useVerifyManualManagementRecordAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { manualManagement } = getStateProp(state, 'bonus');
    const verifyAction = getStateProp(manualManagement, 'verifyAction', '');
    const bonusSelected = getStateProp(manualManagement, 'bonusSelected', {});
    const id = getStateProp(bonusSelected, 'id', '');
    const userId = getStateProp(bonusSelected, 'userId', '');
    const bonusAmount = getStateProp(bonusSelected, 'bonusAmount', 0);
    const depositAmount = getStateProp(bonusSelected, 'depositAmount', 0);
    const remarks = getStateProp(bonusSelected, 'remarks', '');
    const depositTransactionNo = getStateProp(bonusSelected, 'depositTransactionNo', undefined);
    const expiredDateWritable = getStateProp(bonusSelected, 'expiredDateWritable', undefined);
    const turnoverCondition = getStateProp(bonusSelected, 'turnoverCondition', 0);
    const turnoverAmount = getStateProp(bonusSelected, 'turnoverAmount', 0);

    // ============================================
    // METHODS
    // ============================================

    const handleVerifyReward = useCallback(() => {
        manualManagementVerifySend(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                amount: depositAmount,
                bonus: bonusAmount,
                expiredDate: expiredDateWritable,
                operatorRemarks: remarks,
                playerId: userId,
                transactionId: depositTransactionNo ? depositTransactionNo : undefined,
                turnoverAmount,
                turnoverCondition,
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS.ROOT}/${id}/${verifyAction}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            manualManagementVerifySendSuccess(dispatch);
            onVerifyModalClose(dispatch);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, manualManagementVerifySendFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleVerifyReward];
}

export function useUpdateManualManagementRecordAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { manualManagement } = getStateProp(state, 'bonus');
    const bonusSelected = getStateProp(manualManagement, 'bonusSelected', {});

    const { remarks, applicantRemarks, id, ...rest } = bonusSelected;

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateReward = useCallback(() => {
        updateRewardInfo(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                applicantRemarks: remarks,
                ...rest,
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS.ROOT}/${id}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            updateRewardInfoSuccess(dispatch);
            onVerifyModalClose(dispatch);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, updateRewardInfoFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleUpdateReward];
}

export function useGetCalculatedTurnoverAmountOperationAsyncEndpoint(isDisabledEdit) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const manualManagement = getStateProp(state, 'bonus.manualManagement', {});
    const bonusSelected = getStateProp(manualManagement, 'bonusSelected', {});
    const depositAmount = getStateProp(bonusSelected, 'depositAmount', {});
    const bonusAmount = getStateProp(bonusSelected, 'bonusAmount', {});
    const turnoverCondition = getStateProp(bonusSelected, 'turnoverCondition', {});

    const handleGetCalculatedTurnoverAmountRequest = useCallback(() => {
        turnoverAmountGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger && !isDisabledEdit,
        method: 'POST',
        options: {
            data: {
                bonusAmount: parseFloat(bonusAmount),
                depositAmount: parseFloat(depositAmount),
                turnoverCondition: parseFloat(turnoverCondition),
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS.CALCULATE_TURN_OVER}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            turnoverAmountGetSuccess(dispatch, {
                inputName: 'turnoverAmount',
                value: getStateProp(response.data, 'data.turnoverAmount'),
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (
            !isNaN(parseFloat(depositAmount)) &&
            !isNaN(parseFloat(bonusAmount)) &&
            !isNaN(parseFloat(turnoverCondition))
        ) {
            handleGetCalculatedTurnoverAmountRequest();
        }
    }, [depositAmount, bonusAmount, handleGetCalculatedTurnoverAmountRequest, turnoverCondition]);

    const errorMsg = useError(error, turnoverAmountGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useGetDepositAmountAsyncEndpoint() {
    const [state, dispatch] = useStore();

    const transactionId = useDebounce(
        getStateProp(state, 'bonus.manualManagement.bonusSelected.depositTransactionNo'),
        DEBOUNCE_DELAY_SEC,
    );
    const userName = getStateProp(state, 'bonus.manualManagement.bonusSelected.userName');
    const action = getStateProp(state, 'bonus.manualManagement.bonusSelected.action');

    const getURL = (action) => {
        switch (action) {
            case REQUEST_SEARCH_PLAYER_INFO_ACTIONS.CRYPTO_DEPOSIT:
                return `${VERSION.V1}${CRYPTO.DEPOSIT_INFO}/${transactionId}`;
            case REQUEST_SEARCH_PLAYER_INFO_ACTIONS.MERCHANT_DEPOSIT:
                return `${VERSION.V1}${PAYMENTS.MERCHANT_DEPOSITS.ROOT}/${transactionId}`;
            case REQUEST_SEARCH_PLAYER_INFO_ACTIONS.OFFLINE_DEPOSIT:
                return `${VERSION.V1}${PAYMENTS.OFFLINE_DEPOSITS.ROOT}/${transactionId}`;
            case REQUEST_SEARCH_PLAYER_INFO_ACTIONS.BALANCE_ADDITION:
            case REQUEST_SEARCH_PLAYER_INFO_ACTIONS.CRYPTOCURRENCY_CORRECTION:
            case REQUEST_SEARCH_PLAYER_INFO_ACTIONS.DEPOSIT_CORRECTION:
                return `${VERSION.V1}${TRANSACTION_MONITORING}`;
            default:
                return;
        }
    };

    const isParamNeed = (action) => {
        return (
            action === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.BALANCE_ADDITION ||
            action === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.CRYPTOCURRENCY_CORRECTION ||
            action === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.DEPOSIT_CORRECTION
        );
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!action,
        method: 'GET',
        options: isParamNeed(action)
            ? {
                  params: {
                      transactionNo: transactionId,
                  },
              }
            : undefined,

        trigger: transactionId,
        url: getURL(action),
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            const payload =
                action === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.CRYPTO_DEPOSIT
                    ? response.data.data
                    : response.data.data[0];

            const playerUserName = getStateProp(payload, 'playerUserName', '');
            const orderStatus = getStateProp(payload, 'orderStatus', '');

            if (playerUserName === userName && orderStatus === 'Success') {
                depositAmountGetSuccess(dispatch, {
                    depositAmount: payload.amount,
                    noTransaction: false,
                });
            } else {
                !payload || playerUserName !== userName
                    ? message.warning(getTranslation('No Transaction Found'))
                    : message.warning(getTranslation('Transaction Incomplete'));

                depositAmountGetSuccess(dispatch, {
                    depositAmount: 0,
                    noTransaction: true,
                });
            }
        }
        // eslint-disable-next-line
    }, [dispatch, response]);

    const errorMsg = useError(error, depositAmountGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useCompletedOrExpiredManualManagementRecordAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { manualManagement } = getStateProp(state, 'bonus');
    const verifyAction = getStateProp(manualManagement, 'verifyAction', '');
    const bonusSelected = getStateProp(manualManagement, 'bonusSelected', {});
    const id = getStateProp(bonusSelected, 'id', '');

    // ============================================
    // METHODS
    // ============================================

    const handleVerifyReward = useCallback(() => {
        manualManagementVerifySend(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {},
        },
        trigger,
        url: `${VERSION.V1}${BONUS.ROOT}/${id}/status/${_.startCase(verifyAction)}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            manualManagementVerifySendSuccess(dispatch);
            onVerifyModalClose(dispatch);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, manualManagementVerifySendFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleVerifyReward];
}

export function useGetBonusCodeListAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const filters = getStateProp(state, 'bonus.manualManagement.filters', {});
    const { currency, ...restFilters } = filters;

    // ============================================
    // METHODS
    // ============================================

    const handleGetBonusCode = useCallback(() => {
        getBonusCode(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { response, error } = useApi({
        forceDispatchEffect: () => !_.isEmpty(filters),
        method: 'GET',
        options: {
            params: {
                currencyCode: currency,
                ...filterTrim(restFilters),
            },
        },
        trigger,
        url: `${VERSION.V1}${PROMOTIONS.ALL_BONUS_CODES}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);

            getBonusCodeSuccess(dispatch, {
                bonusCodeList: response.data.data.map((item) => {
                    return item.bonusCode;
                }),
            });
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, getBonusCodeFailure);
    useToasterErrorMessage(error, errorMsg);

    return [handleGetBonusCode];
}

export function useBatchRejectManualBonusAsyncEndpoint(setOperatorRemark) {
    const [state, dispatch] = useStore();
    const { manualManagement } = getStateProp(state, 'bonus');
    const fetchData = getStateProp(manualManagement, 'fetchData', () => {});
    const [trigger, setTrigger] = useState(null);
    const [rejectList, setRejectList] = useState([]);

    // ============================================
    // METHODS
    // ============================================

    const handleBatchReject = useCallback(
        (e) => {
            manualManagementBatchReject(dispatch);
            setRejectList(e);
            setTrigger(+new Date());
        },
        [dispatch],
    );

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                depositBonuses: rejectList,
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS.BATCH_REJECT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            manualManagementBatchRejectSuccess(dispatch);
            setOperatorRemark('');
            fetchData();
        }
    }, [dispatch, fetchData, response, setOperatorRemark]);

    const errorMsg = useError(error, manualManagementBatchRejectFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleBatchReject];
}

export function useBatchSubmittManualBonusAsyncEndpoint(setOperatorRemark) {
    const [state, dispatch] = useStore();
    const { manualManagement } = getStateProp(state, 'bonus');
    const fetchData = getStateProp(manualManagement, 'fetchData', () => {});
    const [trigger, setTrigger] = useState(null);
    const [submitList, setSubmitList] = useState([]);

    // ============================================
    // METHODS
    // ============================================

    const handleBatchSubmit = useCallback(
        (e) => {
            manualManagementBatchSubmit(dispatch);
            setSubmitList(e);
            setTrigger(+new Date());
        },
        [dispatch],
    );

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                depositBonuses: submitList,
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS.BATCH_SUBMIT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            manualManagementBatchSubmitSuccess(dispatch);
            setOperatorRemark('');
            fetchData();
        }
    }, [dispatch, fetchData, response, setOperatorRemark]);

    const errorMsg = useError(error, manualManagementBatchSubmitFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleBatchSubmit];
}

export function useBatchUpdateManualBonusAsyncEndpoint(setOperatorRemark) {
    const [state, dispatch] = useStore();
    const { manualManagement } = getStateProp(state, 'bonus');
    const fetchData = getStateProp(manualManagement, 'fetchData', () => {});
    const [trigger, setTrigger] = useState(null);
    const [updateList, setUpdateList] = useState([]);
    const [action, setAction] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleBatchUpdate = useCallback(
        (e, userAction) => {
            manualManagementBatchUpdate(dispatch);
            setUpdateList(e);
            setAction(userAction);
            setTrigger(+new Date());
        },
        [dispatch],
    );

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger && !!action,
        method: 'PUT',
        options: {
            data: {
                depositBonuses: updateList,
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS.BATCH_STATUS}/${_.capitalize(action)}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            manualManagementBatchUpdateSuccess(dispatch);
            setOperatorRemark('');
            fetchData();
        }
    }, [dispatch, fetchData, response, setOperatorRemark]);

    const errorMsg = useError(error, manualManagementBatchUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleBatchUpdate];
}
