import React, { useEffect, useState } from 'react';

import { Card, message } from 'antd';

import { getStateProp } from '../../common/helpers/misc';
import { getTranslation } from '../../common/helpers/locale';
import { pauseRefreshTableTime, setRefreshTableTime } from './fileSystemActions';
import {
    useDeleteFilesAsyncEndpoint,
    useGetFileListAsyncEndpoint,
} from '../../services/fileSystemService';
import { useStore } from '../../store/StateProvider';
import ActionButtons from './ActionButtons';
import DeleteConfirmModal from './modal/DeleteConfirmModal';
import FileSystemResultTable from './table/FileSystemResultTable';
import TableRefreshSelector from '../../common/components/TableRefreshSelector';
import useInternetStatus from '../../services/hooks/useInternetStatus';

const FileSystem = () => {
    const [state, dispatch] = useStore();
    const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState(false);
    const selectedFiles = getStateProp(state, 'fileSystem.selectedFiles', []);
    const refreshTable = getStateProp(state, 'fileSystem.fetchData', () => {});

    // ============================================
    // METHODS
    // ============================================

    const handleFileDelete = () => {
        selectedFiles.length >= 1
            ? setDeleteConfirmModalVisible(true)
            : message.warning(getTranslation('Please select some rows'));
    };

    const onConfirmModalClose = () => setDeleteConfirmModalVisible(false);

    const setRefreshTime = (timeOut) => {
        setRefreshTableTime(dispatch, {
            timeOut,
        });
    };

    const onTimePause = () => pauseRefreshTableTime(dispatch);

    const onTimeStart = () => {
        setRefreshTableTime(dispatch, {
            timeOut: 15,
        });
    };

    // ============================================
    // HOOKS
    // ============================================

    const [isOnline] = useInternetStatus();
    const [loading] = useGetFileListAsyncEndpoint(isOnline);
    const [deleting, handleDeleteFiles, deleted] = useDeleteFilesAsyncEndpoint();

    useEffect(() => {
        if (deleted) {
            setDeleteConfirmModalVisible(false);
            refreshTable();
        }
    }, [deleted, refreshTable]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card
            title={getTranslation('File System')}
            extra={
                <TableRefreshSelector
                    onTimeChange={setRefreshTime}
                    onTimePause={onTimePause}
                    onTimeStart={onTimeStart}
                    timeOut={getStateProp(state, 'fileSystem.operation.refreshTime')}
                />
            }
        >
            <ActionButtons handleFileDelete={handleFileDelete} onRefresh={refreshTable} />
            <FileSystemResultTable loading={loading} />

            <DeleteConfirmModal
                visible={deleteConfirmModalVisible}
                onCancel={onConfirmModalClose}
                deleting={deleting}
                handleDeleteFiles={handleDeleteFiles}
                selectedFiles={selectedFiles}
            />
        </Card>
    );
};

export default FileSystem;
