import { ACTIONS } from './changePasswordActions';

export default function changePasswordReducer(changePassword = {}, { type, payload = null }) {
    switch (type) {
        case ACTIONS.CHANGE_PASSWORD_OPERATION_FAILURE:
            return {
                ...changePassword,
                payload,
            };

        default:
            return changePassword;
    }
}
