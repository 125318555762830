export const ACTIONS = {
    FILTERS_DROP_ALL: 'TURNOVER_CHECK_FILTERS_DROP_ALL',
    FILTER_SET: 'TURNOVER_CHECK_FILTER_SET',
    TURNOVER_CHECK_GET: 'TURNOVER_CHECK_GET',
    TURNOVER_CHECK_GET_FAILURE: 'TURNOVER_CHECK_GET_FAILURE',
    TURNOVER_CHECK_GET_SUCCESS: 'TURNOVER_CHECK_GET_SUCCESS',
};

export const turnoverCheckGet = (dispatch) =>
    dispatch({
        type: ACTIONS.TURNOVER_CHECK_GET,
    });

export const turnoverCheckGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TURNOVER_CHECK_GET_SUCCESS,
    });

export const turnoverCheckGetFailure = (dispatch) =>
    dispatch({
        type: ACTIONS.TURNOVER_CHECK_GET_FAILURE,
    });

export const turnoverCheckFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });
