export const ACTIONS = {
    CHANGE_PASSWORD_OPERATION: 'CHANGE_PASSWORD_OPERATION',
    CHANGE_PASSWORD_OPERATION_FAILURE: 'CHANGE_PASSWORD_OPERATION_FAILURE',
    CHANGE_PASSWORD_OPERATION_SUCCESS: 'CHANGE_PASSWORD_OPERATION_SUCCESS',
};

export const passwordChange = (dispatch) => {
    dispatch({
        type: ACTIONS.CHANGE_PASSWORD_OPERATION,
    });
};

export const passwordChangeSuccess = (dispatch) => {
    dispatch({
        type: ACTIONS.CHANGE_PASSWORD_OPERATION_SUCCESS,
    });
};

export const passwordChangeFailure = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.CHANGE_PASSWORD_OPERATION_FAILURE,
    });
};
