import React, { useEffect, useState } from 'react';

import { Col, Form, Input, Row, Select } from 'antd';
import _ from 'lodash';

import './ProhibitionsModalContent.less';
import { ButtonGray, ButtonGreen } from '../../../../../common/components/buttons/Buttons';

import { getTranslation } from '../../../../../common/helpers/locale';
import {
    useCreateProhibitionAsyncEndpoint,
    useGetProhibitionsAsyncEndpoint,
} from '../../../../../services/playerService';
import ProhibitionsResultsTable from './ProhibitionsResultsTable';

const ProhibitionsModalContent = ({ player, onCancel, prohibitionType }) => {
    const { id, userName } = player;

    const {
        PROHIBITION_VALUES,
        PROHIBITION_VALUE_DEFAULT,
        PROHIBITION_VALUE_TITLE,
        TYPE,
    } = prohibitionType;

    const [prohibitionValue, setProhibitionValue] = useState(PROHIBITION_VALUE_DEFAULT);
    const [remark, setRemark] = useState({
        errorMsg: null,
        validateStatus: 'validating',
        value: null,
    });

    // ============================================
    // METHODS
    // ============================================

    const validateRemark = (value) => {
        if (!value) {
            return {
                errorMsg: getTranslation('Remark is required!'),
                validateStatus: 'error',
            };
        } else {
            return { errorMsg: null, validateStatus: 'success' };
        }
    };

    const handleRemarkChange = (e) => {
        setRemark({ ...validateRemark(e.target.value), value: e.target.value });
    };

    const handleItemChange = (value) => {
        setProhibitionValue(value);
    };

    const handleCreateProhibition = () => createProhibition();

    // ============================================
    // HOOKS
    // ============================================

    const [prohibitionsLoading, getProhibitions] = useGetProhibitionsAsyncEndpoint(id, TYPE);

    const [prohibitionCreating, success, createProhibition] = useCreateProhibitionAsyncEndpoint({
        item: prohibitionValue,
        remark: remark.value,
        type: TYPE,
        userId: id,
    });

    useEffect(() => {
        setRemark('');
        getProhibitions();
    }, [success, getProhibitions]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col>
            <Row>
                <Col span={4}>
                    <span className="bold-text">{getTranslation('Username')}:</span>
                </Col>
                <Col>
                    <span className="bold-text">{userName}</span>
                </Col>
            </Row>
            <Row align="middle" className="item__select">
                <Col span={4}>
                    <span className="bold-text">{getTranslation(PROHIBITION_VALUE_TITLE)}:</span>
                </Col>
                <Col span={20}>
                    <Select value={prohibitionValue} onChange={handleItemChange} className="filter">
                        {_.map(PROHIBITION_VALUES, (value) => (
                            <Select.Option key={value} value={value}>
                                {getTranslation(value)}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
            </Row>
            <Row align="middle">
                <Col span={4}>
                    <span className="bold-text">{getTranslation('Add remark')}:</span>
                </Col>
                <Col span={20}>
                    <Form style={{ width: '100%' }}>
                        <Form.Item validateStatus={remark.validateStatus} help={remark.errorMsg}>
                            <Input value={remark.value} onChange={handleRemarkChange} />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Row className="prohibitions-table__container">
                <ProhibitionsResultsTable
                    loading={prohibitionsLoading}
                    columnTitle={PROHIBITION_VALUE_TITLE}
                />
            </Row>
            <Row className="action-buttons" justify="end" align="middle">
                <ButtonGreen
                    label={getTranslation('Confirm')}
                    onClick={handleCreateProhibition}
                    loading={prohibitionCreating}
                    disabled={remark.validateStatus !== 'success'}
                />
                <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
            </Row>
        </Col>
    );
};

export default ProhibitionsModalContent;
