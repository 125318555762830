import React from 'react';

import { Button, Popover, Row, Tabs } from 'antd';
import _ from 'lodash';

import { getLanguageByCode, getTranslation } from '../../../common/helpers/locale';
import DuplicateTab from './DuplicateTab';
import LanguagesCheckboxes from '../../../common/components/LanguagesCheckboxes';
import RebateFormInputs from './RebateFormInputs';

const { TabPane } = Tabs;

const RebateTabs = ({
    selectedLangs,
    setRebateLanguage,
    isEdit,
    rebateInfo,
    onRebateInfoChange,
    onRebateContentChange,
    onDuplicateRebate,
    onRebateMultiInfoChange,
}) => {
    const { details, ...rest } = rebateInfo;

    const TabsExtraContent = (
        <Row>
            <Popover
                placement="bottom"
                title={getTranslation('Duplicate')}
                content={<DuplicateTab details={details} onDuplicateRebate={onDuplicateRebate} />}
                trigger="click"
            >
                <Button style={{ marginRight: '5px' }}>{getTranslation('Duplicate')}</Button>
            </Popover>
            <Popover
                placement="bottom"
                title={getTranslation('Select Languages')}
                content={
                    <LanguagesCheckboxes
                        onCheckedLanguages={setRebateLanguage}
                        value={selectedLangs}
                    />
                }
                trigger="click"
            >
                <Button>{getTranslation('Select Languages')}</Button>
            </Popover>
        </Row>
    );

    return (
        <Tabs tabBarExtraContent={TabsExtraContent} className="rebate__tabs">
            {_.map(selectedLangs, (value) => {
                const tabTitle = getLanguageByCode(value);
                const sortedContent = _.find(details, (content) => {
                    return content.languageCode === value;
                });

                return (
                    <TabPane tab={tabTitle} key={value}>
                        <RebateFormInputs
                            langCode={value}
                            rebateContent={sortedContent}
                            rebateInfo={rest}
                            isEdit={isEdit}
                            onRebateInfoChange={onRebateInfoChange}
                            onRebateContentChange={onRebateContentChange}
                            onRebateMultiInfoChange={onRebateMultiInfoChange}
                        />
                    </TabPane>
                );
            })}
        </Tabs>
    );
};

export default RebateTabs;
