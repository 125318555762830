export const ACTIONS = {
    TRANSACTION_AMOUNT_SETTING_GET: 'TRANSACTION_AMOUNT_SETTING_GET',
    TRANSACTION_AMOUNT_SETTING_GET_FAILURE: 'TRANSACTION_AMOUNT_SETTING_GET_FAILURE',
    TRANSACTION_AMOUNT_SETTING_GET_SUCCESS: 'TRANSACTION_AMOUNT_SETTING_GET_SUCCESS',
    TRANSACTION_AMOUNT_SETTING_SET: 'TRANSACTION_AMOUNT_SETTING_SET',
    TRANSACTION_AMOUNT_SETTING_UPDATE: 'TRANSACTION_AMOUNT_SETTING_UPDATE',
    TRANSACTION_AMOUNT_SETTING_UPDATE_FAILURE: 'TRANSACTION_AMOUNT_SETTING_UPDATE_FAILURE',
    TRANSACTION_AMOUNT_SETTING_UPDATE_SUCCESS: 'TRANSACTION_AMOUNT_SETTING_UPDATE_SUCCESS',
};

/* Region Get Transaction Amount Setting */
export const transactionAmountSettingGet = (dispatch) =>
    dispatch({
        type: ACTIONS.TRANSACTION_AMOUNT_SETTING_GET,
    });

export const transactionAmountSettingGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TRANSACTION_AMOUNT_SETTING_GET_SUCCESS,
    });

export const transactionAmountSettingGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TRANSACTION_AMOUNT_SETTING_GET_FAILURE,
    });
/* Region End*/

/* Region handle transaction amount setting input change */
export const transactionAmountSettingSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TRANSACTION_AMOUNT_SETTING_SET,
    });
/* Region End*/

/* Region update transaction amount setting */
export const transactionAmountSettingUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.TRANSACTION_AMOUNT_SETTING_UPDATE,
    });

export const transactionAmountSettingUpdateSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TRANSACTION_AMOUNT_SETTING_UPDATE_SUCCESS,
    });

export const transactionAmountSettingUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TRANSACTION_AMOUNT_SETTING_UPDATE_FAILURE,
    });
/* Region End*/
