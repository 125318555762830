import { Switch } from 'react-router-dom';

import React from 'react';

import { PrivateRoute } from '../../common/components';
import { ROUTES } from '../../common/constants/routing';
import VipHistory from './vip-history/VipHistory';
import VipSetup from './vip-setup/VipSetup';

const Vip = () => {
    return (
        <Switch>
            <PrivateRoute
                exact
                path={ROUTES.DASHBOARD.VIP.VIP_SETUP}
                component={VipSetup}
                hasAccessControl
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.VIP.VIP_HISTORY}
                component={VipHistory}
                hasAccessControl
            />
        </Switch>
    );
};

export default Vip;
