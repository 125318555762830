import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { PAYMENT_ORDER_STATUSES, WITHDRAWAL_ORDER_STATUSES } from '../../constants/misc';
import { getTranslation } from '../../helpers/locale';

const OrderStatusFilter = ({ value, onFilterChange, type, name }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange(name, value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear
            placeholder={getTranslation('Status')}
            className="filter"
        >
            {type
                ? _.map(_.entries(WITHDRAWAL_ORDER_STATUSES), ([key, status]) => (
                      <Select.Option value={status} key={key}>
                          {getTranslation(status).toLocaleUpperCase()}
                      </Select.Option>
                  ))
                : _.map(_.entries(PAYMENT_ORDER_STATUSES), ([key, status]) => (
                      <Select.Option value={status} key={key}>
                          {getTranslation(status).toLocaleUpperCase()}
                      </Select.Option>
                  ))}
        </Select>
    );
};

export default OrderStatusFilter;
