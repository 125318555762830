import React from 'react';

import { Form } from 'antd';

import {
    announcementFilterDrop,
    closeAnnouncementDeleteModal,
    closeAnnouncementVerifyModal,
} from './globalAnnouncementActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { useGetGlobalAnnouncementAsyncEndpoint } from '../../../../services/announcementService';
import { useStore } from '../../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import DeleteAnnouncementModal from './modal/DeleteAnnouncementModal';
import GlobalAnnouncementFilters from './GlobalAnnouncementFilters';
import GlobalAnnouncementResultTable from './GlobalAnnouncementResultTable';
import VerifyAnnouncementModal from './modal/VerifyAnnouncementModal';

const GlobalAnnouncement = () => {
    const [state, dispatch] = useStore();
    const globalAnnouncement = getStateProp(
        state,
        'announcement.announcementVerification.globalAnnouncement',
    );
    const deleteModalVisible = getStateProp(globalAnnouncement, 'deleteModalVisible');

    const verifyModalVisible = getStateProp(globalAnnouncement, 'verifyMessageModalVisible');

    // ============================================
    // METHODS
    // ============================================

    const onCloseDeleteModal = () => closeAnnouncementDeleteModal(dispatch);

    const fetchAll = () => {
        announcementFilterDrop(dispatch);
        handleGetGlobalAnnouncemnet();
    };

    const closeVerifyModal = () => closeAnnouncementVerifyModal(dispatch);

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetGlobalAnnouncemnet] = useGetGlobalAnnouncementAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <>
            <Form onFinish={handleGetGlobalAnnouncemnet}>
                <GlobalAnnouncementFilters />
                <ActionButtons fetchAll={fetchAll} />
            </Form>

            <GlobalAnnouncementResultTable loading={loading} />
            <DeleteAnnouncementModal visible={deleteModalVisible} onCancel={onCloseDeleteModal} />
            <VerifyAnnouncementModal visible={verifyModalVisible} onCancel={closeVerifyModal} />
        </>
    );
};

export default GlobalAnnouncement;
