import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { ANNOUNCEMENT_OUTCOMES } from '../constants';
import { getTranslation } from '../../../common/helpers/locale';

const AnnouncementOutcomeFilter = ({ value, onFilterChange }) => {
    return (
        <Select
            value={value}
            onChange={(selectedOutCome) => onFilterChange('outcome', selectedOutCome)}
            placeholder={getTranslation('Outcome')}
            className="filter"
            style={{ margin: '0px' }}
            allowClear
        >
            {_.map(ANNOUNCEMENT_OUTCOMES, (outcome) => (
                <Select.Option key={outcome} value={outcome}>
                    {outcome.toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
};

export default AnnouncementOutcomeFilter;
