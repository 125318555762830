import React from 'react';

import { Row } from 'antd';

import { IdFilter, LeagueNameFilter } from './filters';
import { getStateProp } from '../../common/helpers/misc';
import { hotEventsFilterSet } from './hotEventsAction';
import { useStore } from '../../store/StateProvider';

const HotEventFilters = () => {
    const [state, dispatch] = useStore();

    const hotEvents = getStateProp(state, 'hotEvents');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => hotEventsFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <IdFilter
                onFilterChange={setFilter}
                value={getStateProp(hotEvents, 'filters.eventIds')}
            />
            <LeagueNameFilter
                onFilterChange={setFilter}
                value={getStateProp(hotEvents, 'filters.leagueName')}
            />
        </Row>
    );
};

export default HotEventFilters;
