import React from 'react';

import { Modal, Row } from 'antd';

import { ButtonGray, ButtonGreen } from '../../../../../common/components/buttons/Buttons';
import { getStateProp } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useDeleteNewsAsyncEndpoint } from '../../../../../services/newsService';

const DeleteNewsModal = ({ onCancel, visible, news }) => {
    // ============================================
    // HOOKS
    // ============================================

    const [newsDeleting, handleDeleteNews] = useDeleteNewsAsyncEndpoint(
        getStateProp(news, 'id', ''),
    );

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={getTranslation('Confirm to Delete?')}
            centered
            closable={false}
            className="news__confirm-delete-modal"
            footer={[
                <Row justify="end" align="middle" key="footer">
                    <ButtonGreen
                        label={getTranslation('Confirm')}
                        onClick={handleDeleteNews}
                        loading={newsDeleting}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
        >
            {getTranslation(
                "Be noticed that, you can't get back the record once you delete the news.",
            )}
        </Modal>
    );
};

export default DeleteNewsModal;
