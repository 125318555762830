import { useCallback, useEffect, useState } from 'react';

import { API } from '../common/constants/api';
import {
    addAnnouncement,
    addAnnouncementFailure,
    addAnnouncementSuccess,
} from '../components/announcement/announcement-create/announcementCreateActions';
import {
    announcementGet,
    announcementGetFailure,
    announcementGetSuccess,
    announcementUpdate,
    announcementUpdateFailure,
    announcementUpdateSuccess,
} from '../components/announcement/announcement-verification/global-announcement/globalAnnouncementActions';
import {
    announcementHistoryGet,
    announcementHistoryGetFailure,
    announcementHistoryGetSuccess,
} from '../components/announcement/announcement-history/global-announcement/globalAnnouncementActions';
import { announcementStatusMap } from '../components/announcement/constants';
import { clearError } from '../common/actions/errorActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import { useDispatch, useStore } from '../store/StateProvider';
import { useToasterErrorMessage, useToasterSuccessMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, ANNOUNCEMENT } = API;

export function useGetGlobalAnnouncementAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const globalAnnouncement = getStateProp(
        state,
        'announcement.announcementVerification.globalAnnouncement',
    );
    const { pageNo, pageSize } = getStateProp(globalAnnouncement, 'paging');
    const filters = getStateProp(globalAnnouncement, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetGlobalAnnouncement = useCallback(() => {
        announcementGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                IsHistory: false,
                pageNo,
                pageSize,
                ...filterTrim(filters),
            },
        },
        trigger,
        url: `${VERSION.V1}${ANNOUNCEMENT.ANNOUNCEMENT_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            announcementGetSuccess(dispatch, {
                fetchData: handleGetGlobalAnnouncement,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetGlobalAnnouncement, response]);

    useEffect(() => {
        handleGetGlobalAnnouncement();
    }, [handleGetGlobalAnnouncement, pageNo, pageSize]);

    const errorMsg = useError(error, announcementGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetGlobalAnnouncement];
}

export function useGetGlobalAnnouncementHistoryAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const globalAnnouncement = getStateProp(
        state,
        'announcement.announcementHistory.globalAnnouncement',
    );
    const { pageNo, pageSize } = getStateProp(globalAnnouncement, 'paging');
    const filters = getStateProp(globalAnnouncement, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetGlobalAnnouncementHistory = useCallback(() => {
        announcementHistoryGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                IsHistory: true,
                pageNo,
                pageSize,
                ...filterTrim(filters),
            },
        },
        trigger,
        url: `${VERSION.V1}${ANNOUNCEMENT.ANNOUNCEMENT_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            announcementHistoryGetSuccess(dispatch, {
                fetchData: handleGetGlobalAnnouncementHistory,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetGlobalAnnouncementHistory, response]);

    useEffect(() => {
        handleGetGlobalAnnouncementHistory();
    }, [handleGetGlobalAnnouncementHistory, pageNo, pageSize]);

    const errorMsg = useError(error, announcementHistoryGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetGlobalAnnouncementHistory];
}

export function useCreateAnnouncementAsyncEndpoint(announcement) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleCreateAnnouncement = () => {
        addAnnouncement(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: announcement,
        },
        trigger,
        url: `${VERSION.V1}${ANNOUNCEMENT.ANNOUNCEMENT_LIST}/`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            addAnnouncementSuccess(dispatch);
        }
    }, [dispatch, response]);

    useToasterSuccessMessage(response, getTranslation('Submitted'));

    const errorMsg = useError(error, addAnnouncementFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleCreateAnnouncement, response];
}

export function useUpdateAnnouncementAsyncEndpoint(announcement, status) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);
    const { announcementStatus, id, ...rest } = announcement;

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateAnnouncement = () => {
        announcementUpdate(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: { ...rest, announcementStatus: status },
        },
        trigger,
        url: `${VERSION.V1}${ANNOUNCEMENT.ANNOUNCEMENT_LIST}/${id}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            announcementUpdateSuccess(dispatch);
        }
    }, [dispatch, response]);

    useToasterSuccessMessage(response, getTranslation(announcementStatusMap.get(status)));

    const errorMsg = useError(error, announcementUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateAnnouncement, response];
}
