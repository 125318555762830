import React from 'react';

import { ButtonGreen } from '../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../common/helpers/locale';
import { openVerifyModal } from '../pendingModificationsActions';

const VerifyButton = ({ dispatch, modification }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleVerifyModalOpen = () =>
        openVerifyModal(dispatch, {
            modificationAttending: modification,
        });

    // ============================================
    // RENDER
    // ============================================

    return (
        <ButtonGreen
            label={getTranslation('Verify')}
            onClick={handleVerifyModalOpen}
            className="action-btn__verify"
        />
    );
};

export default VerifyButton;
