import cryptoDepositRecordReducer from './deposits/cryptoDepositRecordReducer';
import cryptoWithdrawalRecordReducer from './withdrawals/cryptoWithdrawalRecordReducer';

export default function cryptocurrencyRecords(cryptocurrencyRecords = {}, action) {
    const { cryptoWithdrawalRecord, cryptoDepositRecord } = cryptocurrencyRecords;

    return {
        cryptoDepositRecord: cryptoDepositRecordReducer(cryptoDepositRecord, action),
        cryptoWithdrawalRecord: cryptoWithdrawalRecordReducer(cryptoWithdrawalRecord, action),
    };
}
