export const ACTIONS = {
    FILTERS_DROP_ALL: 'USER_SESSIONS_FILTERS_DROP_ALL',
    FILTER_SET: 'USER_SESSIONS_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'USER_SESSIONS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'USER_SESSIONS_TABLE_PAGE_SIZE_CHANGE',
    },
    USER_SESSIONS_GET: 'USER_SESSIONS_GET',
    USER_SESSIONS_GET_FAILURE: 'USER_SESSIONS_GET_FAILURE',
    USER_SESSIONS_GET_SUCCESS: 'USER_SESSIONS_GET_SUCCESS',
};

export const userSessionsFilterSet = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });
};

export const userSessionsDropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const userSessionsGet = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_SESSIONS_GET,
    });

export const userSessionsGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_SESSIONS_GET_SUCCESS,
    });

export const userSessionsGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_SESSIONS_GET_FAILURE,
    });

export const changeSessionsPageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changeSessionsPageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });
