import React from 'react';

import { useGetUserSessionsByUserIdAsyncEndpoint } from '../../../../services/playerService';
import UserSessionResultsTable from './table/UserSessionResultsTable';

const UserSessions = (props) => {
    const { playerId } = props.match.params;
    // ============================================
    // HOOKS
    // ============================================

    const [loading] = useGetUserSessionsByUserIdAsyncEndpoint(playerId);

    // ============================================
    // RENDER
    // ============================================

    return <UserSessionResultsTable loading={loading} />;
};

export default UserSessions;
