import React from 'react';

import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../referralInquiryActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';
import ViewButton from '../../referrer-report/table/ViewButton';

export const getColumns = () => {
    const renderBindBankCard = (record) => {
        return (
            <label
                className={
                    record.bindBankCard
                        ? 'referral-inquiry-bind-bank-card__green'
                        : 'referral-inquiry-bind-bank-card__red'
                }
            >
                {record.bindBankCard ? getTranslation('Yes') : getTranslation('No')}
            </label>
        );
    };

    const columns = [
        {
            dataIndex: 'userName',
            title: getTranslation('Username'),
        },
        {
            dataIndex: 'currency',
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'totalDeposit',
            title: getTranslation('Total Deposit'),
        },
        {
            dataIndex: 'totalReferee',
            render: (text, record) => (
                <ViewButton
                    totalValue={text}
                    id={record.userId}
                    userName={record.userName}
                    type="totalReferee"
                />
            ),
            title: getTranslation('Total Referee'),
        },
        {
            dataIndex: 'totalDepositedReferee',
            render: (text, record) => (
                <ViewButton
                    totalValue={text}
                    id={record.userId}
                    userName={record.userName}
                    type="totalDepositedRefereeInquiry"
                />
            ),
            title: getTranslation('Qualified Referee'),
        },
        {
            dataIndex: 'referrer',
            title: getTranslation('Referrer'),
        },
        {
            dataIndex: 'bindBankCard',
            render: (value, record) => renderBindBankCard(record),
            title: getTranslation('Bind Bank Card'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
