//General

export const MAX_TABLE_ROW_SELECT_LIMIT = 10;

export const OPERATION_TIME_LIMIT = 300000;

export const TABLE_REFRESH_TIMES = [15, 30, 60];

export const DEBOUNCE_DELAY_SEC = 750;

export const DEFAULT_THROTTLE_DELAY = 1000;

export const languageCodeMap = new Map([
    ['en-US', 'English'],
    ['zh-CN', 'Chinese'],
    ['tr-TR', 'Thai'], // instead of 'th-TH' to fix backend thai language issue
    ['id-ID', 'Indonesia'],
    ['vi-VN', 'Vietnam'],
]);

export const TRAFFIC_STATUSES = {
    APPROVED: 'APPROVED',
    ATTENDING: 'ATTENDING',
    COMPLETED: 'COMPLETED',
    DELETED: 'DELETED',
    DRAFT: 'DRAFT',
    EXPIRED: 'EXPIRED',
    FAILURE: 'FAILURE',
    ONGOING: 'ONGOING',
    PENDING: 'PENDING',
    PROCESSING: 'PROCESSING',
    PUBLISHED: 'PUBLISHED',
    REJECTED: 'REJECTED',
    RUNNING: 'RUNNING',
    SETTLED: 'SETTLED',
    SUBMITTED: 'SUBMITTED',
    SUCCESS: 'SUCCESS',
    VERIFIED: 'VERIFIED',
};

//Platform

export const PLATFORMS = {
    ALLBET: 'ALLBET',
    ASIA_GAMING: 'Asia Gaming',
    AVIA: 'AVIA',
    BTI: 'BTi',
    GD: 'GD',
    GGBOOK: 'GGBook',
    MG: 'MG',
    PLAYTECH: 'Playtech',
    PP: 'PP',
    SABA: 'SABA',
    SEXYGAMING: 'SEXYGAMING',
    SG: 'SG',
};

export const WALLET_PLATFORMS = {
    AG: 'AG',
    AdvantPlayMiniGame: 'AdvantPlayMiniGame',
    AdvantPlaySlot: 'AdvantPlaySlot',
    BTI: 'BTi',
    GGBOOK: 'GGBook',
    PLAYTECH: 'Playtech',
    SABA: 'SABA',
    SLOT88: 'Slot88',
};

//Player management

export const MEMBER_LEVELS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const TRUST_LEVELS = [-6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export const DANGER_LEVELS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const QUALITY_LEVELS = {
    GOOD: 'Good',
    GREAT: 'Great',
    LOW: 'Low',
    NORMAL: 'Normal',
};

//Payment

export const PAYMENT_ORDER_STATUSES = {
    PROCESSING: 'Processing',
    REJECTED: 'Rejected',
    SUCCESS: 'Success',
};

export const WITHDRAWAL_GGPAY_STATUSES = {
    COMPLETED: 'Completed',
    FAILURE: 'Failure',
    PENDING: 'Pending',
    REJECTED: 'Rejected',
    SUBMITTED: 'Submitted',
};

export const WITHDRAWAL_ORDER_STATUSES = {
    PENDING: 'Pending',
    PROCESSING: 'Processing',
    REJECTED: 'Rejected',
    SUCCESS: 'Success',
};

export const TRANSACTION_STATUSES = {
    ATTENDING: 'Attending',
    FAILURE: 'Failure',
    PENDING: 'Pending',
    REJECTED: 'Rejected',
    SUCCESS: 'Success',
};

export const PAYMENT_ORDER_OPERATIONS = {
    ATTEND: 'attend',
    COMPLETE: 'complete',
    REJECT: 'reject',
    RELEASE: 'release',
    VERIFY: 'verify',
};

export const TRANSACTION_METHODS = {
    ALIPAY: 'AliPay',
    BANK_TO_BANK: 'BankToBank',
    UNION_PAY: 'UnionPay',
    WECHAT_PAY: 'WechatPay',
};

export const PAYMENT_ACCOUNT_SETUP_TYPES = {
    DEPOSIT: 'Deposit',
    MERCHANT: 'Merchant',
    TRANSIT: 'Transit',
    WITHDRAWAL: 'Withdrawal',
};

export const CRYPTOCURRENCY_ASPECTS = {
    DEPOSIT: 'deposit',
    WITHDRAWAL: 'withdrawal',
};

export const TRANSACTION_AMOUNT_SETTING_ASPECTS = {
    BANK: 'bank',
    EWALLET: 'ewallet',
};

export const CRYPTOCURRENCY = {
    BTC: 'BTC',
    ETH: 'ETH',
    USDT: 'USDT',
};

export const PAYMENT_GATEWAY_WEB_SHOW = {
    NO: false,
    YES: true,
};

export const YES_NO_DISPLAY = [
    {
        display: 'YES',
        value: true,
    },
    {
        display: 'NO',
        value: false,
    },
];

// Balance Management

export const BALANCE_MANAGEMENT_TYPES = {
    ARBITRAGE_DEDUCTION: 'Arbitrage Deduction',
    BONUS_DEDUCTION: 'Bonus Deduction',
    DEPOSIT_CORRECTION: 'Deposit Correction',
    FROZEN_DEPOSIT: 'Frozen Deposit',
    MISSING_BALANCE: 'Missing Balance',
    Other: 'Other',
    WITHDRAWAL_CORRECTION: 'Withdrawal Correction',
};

export const DEPOSIT_CORRECTION_TYPES = {
    DEPOSIT_CORRECTION: 'DepositCorrection',
    FROZEN_DEPOSIT: 'FrozenDeposit',
};

// Affiliate
export const AFFILIATE_STATUS = {
    APPROVED: 'Approved',
    PENDING: 'Pending',
    REJECTED: 'Rejected',
};

//create referral type
export const REFERRAL_TYPE = {
    BONUS: 'Bonus',
    REWARDS: 'Rewards',
};

//Initial create referral details
export const INITIAL_REFERRAL_DETAIL = {
    bonusAmount: 0,
    bonusTurnoverAmount: 0,
    bonusTurnoverRequired: 0,
    referralLevelFrom: 0,
    rewardAmount: 0,
    rewardTurnoverAmount: 0,
    rewardTurnoverRequired: 0,
};

export const INITIAL_LAST_REFERRAL_LEVEL_TO = -1;

//Create referral info key
export const REFERRAL_INFO_KEYS = {
    BONUS_AMOUNT: 'bonusAmount',
    BONUS_TURNOVER_AMOUNT: 'bonusTurnoverAmount',
    BONUS_TURNOVER_REQUIRED: 'bonusTurnoverRequired',
    MIN_DEPOSIT: 'minDeposit',
    REFEREE_AMOUNT: 'refereeAmount',
    REFEREE_TURNOVER_AMOUNT: 'refereeTurnoverAmount',
    REFEREE_TURNOVER_REQUIRED: 'refereeTurnoverRequired',
    REFERRAL_LEVEL_FROM: 'referralLevelFrom',
    REFERRAL_LEVEL_TO: 'referralLevelTo',
    REWARD_AMOUNT: 'rewardAmount',
    REWARD_TURNOVER_AMOUNT: 'rewardTurnoverAmount',
    REWARD_TURNOVER_REQUIRED: 'rewardTurnoverRequired',
};

export const TOTAL_BALANCE_TYPES = {
    PAGE_BALANCE: 'PageBalance',
    TOTAL_BALANCE: 'TotalBalance',
};

export const HOT_EVENTS_TYPES = {
    CLOSE: 'close',
    CREATE: 'create',
};

export const PAYMENT_EWALLET_ACCOUNT_TYPES = {
    ALL: 'All',
    TRANSFER_IN: 'TransferIn',
    TRANSFER_OUT: 'TransferOut',
};

export const TURNOVER_ADJUST_ON = {
    TURNOVER_AMOUNT: 'Turnover Amount',
    TURNOVER_REQUIREMENT: 'Turnover Requirement',
};
