import { ACTIONS } from './channelsActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS_1000,
} from '../../../common/constants/api';

export default function channelsReducer(
    channels = {
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        resetPageNo: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.FILTER_SET:
            return {
                ...channels,
                filters: {
                    ...channels.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };

        case ACTIONS.FILTERS_DROP_ALL:
            return {
                ...channels,
                filters: null,
                resetPageNo: true,
            };

        case ACTIONS.CHANNELS_GET_SUCCESS:
            return {
                ...channels,
                fetchData: payload.fetchData,
                list: payload.list,
                paging: {
                    ...channels.paging,
                    ...payload.paging,
                },
            };

        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...channels,
                paging: {
                    ...channels.paging,
                    pageNo:
                        payload > channels.paging.pageCount
                            ? channels.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS_1000[TABLE_PAGE_SIZE_OPTIONS_1000.length - 1],
            );
            return {
                ...channels,
                paging: {
                    ...channels.paging,
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.CHANNELS_GET:
            const pageNo = channels.resetPageNo ? TABLE_PAGE_NO_DEFAULT : channels.paging.pageNo;

            return {
                ...channels,
                paging: { ...channels.paging, pageNo: pageNo },
                resetPageNo: false,
            };
        default:
            return channels;
    }
}
