import React, { useEffect, useState } from 'react';

import { Button, Checkbox, Col, Form, Input, Row, Select, message } from 'antd';
import _ from 'lodash';

import './CreatePlayerForm.less';
import { ButtonGreen } from '../../../../../common/components/buttons/Buttons';
import { Loader } from '../../../../../common/components';
import {
    getCurrencyByCountry,
    getPhonePrefixByCountry,
    getSupportedCountries,
    getTranslation,
    isSupportedCurrencyCountry,
} from '../../../../../common/helpers/locale';
import { getStateProp } from '../../../../../common/helpers/misc';

import { getUserCurrency } from '../../../../../common/helpers/api';
import { removeAffiliateUplineInfo } from '../../PlayersListActions';
import {
    useCreatePlayerAsyncEndpoint,
    useGetAffiliateUplineInfoAsyncEndpoint,
    useGetGamePlatformAsyncEndpoint,
} from '../../../../../services/playerService';
import { useStore } from '../../../../../store/StateProvider';
import FlagIcon from '../../../../../common/components/FlagIcon';

const CreatePlayerForm = ({ onCancel }) => {
    const [form] = Form.useForm();
    const { resetFields, setFieldsValue, getFieldValue } = form;
    const [state, dispatch] = useStore();
    const affiliateInfo = getStateProp(
        state,
        'players.playersList.playersModal.affiliateInfo',
        null,
    );
    const gamePlatform = getStateProp(state, 'players.playersList.playersModal.gamePlatform', []);
    const [formValues, setFormValues] = useState({});
    const [isCurrencyFollowAffiliate, setIsCurrencyFollowAffiliate] = useState(false);
    const [selectedCountryCode, setSelectedCountryCode] = useState();
    const operatorCurrency = getUserCurrency();
    const supportedCountries = getSupportedCountries();

    // ============================================
    // METHODS
    // ============================================

    const submitForm = (values) => {
        if (values.gameAccess.length === gamePlatform.length) values.gameAccess = null;
        else if (values.gameAccess.length === 0) values.gameAccess = [];

        setFormValues(values);
        if (_.isEmpty(values.betfiliateId)) {
            createPlayer();
        } else {
            if (!isCurrencyFollowAffiliate) {
                message.warning(getTranslation('Affiliate Code is invalid or still searching!'));
            } else {
                createPlayer();
            }
        }
    };

    const onChangeAffiliateCode = (e) => {
        setIsCurrencyFollowAffiliate(false);
        removeAffiliateUplineInfo(dispatch);
        setFormValues({
            ...formValues,
            betfiliateId: e.target.value,
        });

        setFieldsValue({
            betfiliateChannel: undefined,
        });
    };

    const onCountryChange = (value) => {
        setSelectedCountryCode(value);
        if (!isCurrencyFollowAffiliate) {
            setFieldsValue({ countryCode: value, currencyCode: getCurrencyByCountry(value) });
        } else {
            setFieldsValue({ countryCode: value });
        }
    };

    // ============================================
    // HOOKS
    // ============================================

    const [playerCreating, createPlayer, playerCreated] = useCreatePlayerAsyncEndpoint({
        ...formValues,
        mobileNo: {
            countryCallingCode: getPhonePrefixByCountry(formValues.countryCode),
            phoneNumber: `${formValues.mobileNo}`.replace(' ', ''),
        },
    });

    const [affiliateInfoGetting, affiliateInfoReceived] = useGetAffiliateUplineInfoAsyncEndpoint(
        formValues.betfiliateId,
    );

    const [gamingPlatformLoading] = useGetGamePlatformAsyncEndpoint();

    useEffect(() => {
        if (playerCreated) {
            resetFields();
            onCancel();
        }
    }, [onCancel, playerCreated, resetFields]);

    useEffect(() => {
        if (affiliateInfoReceived && affiliateInfo && getFieldValue('betfiliateId')) {
            setIsCurrencyFollowAffiliate(true);
            setFieldsValue({
                betfiliateChannel: getFieldValue('betfiliateId') + '_admin',
                currencyCode: affiliateInfo.currencyCode,
            });
        } else if (!affiliateInfo) {
            setFieldsValue({
                betfiliateChannel: undefined,
                currencyCode: getFieldValue('countryCode')
                    ? getCurrencyByCountry(getFieldValue('countryCode'))
                    : null,
            });
        }
    }, [affiliateInfoReceived, affiliateInfo, setFieldsValue, getFieldValue]);

    useEffect(() => {
        if (gamePlatform)
            setFieldsValue({
                gameAccess: _.map(gamePlatform, (game) => game.code),
            });
    }, [gamePlatform, setFieldsValue]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form
            form={form}
            name="create-player"
            className="create-player__form"
            onFinish={submitForm}
        >
            <Row>
                <Col span={12} className="create-player__form__column">
                    <Row className="form__group">
                        <Col span={6} align="end">
                            <span className="create-player__form__label">
                                {getTranslation('Username')}
                                <span style={{ color: 'red' }}>*</span>:
                            </span>
                        </Col>
                        <Col span={18}>
                            <Form.Item
                                name="userName"
                                rules={[
                                    {
                                        message: getTranslation('Please fill in this field!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={6} align="end">
                            <span className="create-player__form__label">
                                {getTranslation('Full Name')}
                                <span style={{ color: 'red' }}>*</span>:
                            </span>
                        </Col>
                        <Col span={18}>
                            <Form.Item
                                name="realName"
                                rules={[
                                    {
                                        message: getTranslation('Please fill in this field!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={6} align="end">
                            <span className="create-player__form__label">
                                {getTranslation('Country')}:
                            </span>
                        </Col>
                        <Col span={18}>
                            <Form.Item name="countryCode">
                                <Select
                                    className="create-player__form__country-select"
                                    onChange={onCountryChange}
                                >
                                    {_.map(supportedCountries, (country) => (
                                        <Select.Option value={country.code} key={country.code}>
                                            <FlagIcon code={country.code} />
                                            <span style={{ marginLeft: 12 }}>
                                                {country.name.toLocaleUpperCase()}
                                            </span>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={6} align="end">
                            <span className="create-player__form__label">
                                {getTranslation('Mobile Number')}
                                <span style={{ color: 'red' }}>*</span>:
                            </span>
                        </Col>
                        <Col span={18}>
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) =>
                                    prevValues.countryCode !== currentValues.countryCode
                                }
                            >
                                {({ getFieldValue }) => {
                                    return (
                                        <Form.Item
                                            name="mobileNo"
                                            rules={[
                                                {
                                                    message: getTranslation(
                                                        'Please fill in this field!',
                                                    ),
                                                    required: true,
                                                },
                                                {
                                                    message: getTranslation(
                                                        'Phone number invalid!',
                                                    ),
                                                    min: 7,
                                                },
                                            ]}
                                            dependencies={['countryCode']}
                                        >
                                            <Input
                                                className="create-player__form__phone-input"
                                                prefix={
                                                    <span className="create-player__form__phone-input__prefix">
                                                        {getPhonePrefixByCountry(
                                                            getFieldValue('countryCode') ||
                                                                supportedCountries[0].code,
                                                        )}
                                                    </span>
                                                }
                                            />
                                        </Form.Item>
                                    );
                                }}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={6} align="end">
                            <span className="create-player__form__label">
                                {getTranslation('Password')}
                                <span style={{ color: 'red' }}>*</span>:
                            </span>
                        </Col>
                        <Col span={18}>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        message: getTranslation('Please fill in this field!'),
                                        required: true,
                                    },
                                    {
                                        message: getTranslation(
                                            'Password must be at least 7 symbols!',
                                        ),
                                        min: 7,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className="form__group">
                        <Col span={6} align="end">
                            <span className="create-player__form__label">
                                {getTranslation('Game Access')}
                                <span style={{ color: 'red' }}>*</span>:
                            </span>
                        </Col>
                        <Col span={18}>
                            {gamingPlatformLoading ? (
                                <Loader />
                            ) : (
                                <Form.Item name="gameAccess">
                                    <Checkbox.Group>
                                        {_.map(gamePlatform, (game) => {
                                            return (
                                                <div key={game.code}>
                                                    <Checkbox value={game.code}>
                                                        {game.desc.toLocaleUpperCase()}
                                                    </Checkbox>
                                                </div>
                                            );
                                        })}
                                    </Checkbox.Group>
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col span={12} className="create-player__form__column">
                    <Row className="form__group">
                        <Col span={6} align="end">
                            <span className="create-player__form__label">
                                {getTranslation('Currency Code')}
                                <span style={{ color: 'red' }}>*</span>:
                            </span>
                        </Col>
                        <Col span={18}>
                            <Form.Item
                                name="currencyCode"
                                rules={[
                                    {
                                        message: getTranslation('Please select currency!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Select loading={affiliateInfoGetting}>
                                    {_.map(operatorCurrency, (currency) => (
                                        <Select.Option
                                            value={currency}
                                            key={currency}
                                            disabled={
                                                isCurrencyFollowAffiliate
                                                    ? currency !== affiliateInfo.currencyCode
                                                    : isSupportedCurrencyCountry(
                                                          selectedCountryCode,
                                                      )
                                                    ? currency !== 'USD' &&
                                                      currency !==
                                                          getCurrencyByCountry(selectedCountryCode)
                                                    : true
                                            }
                                        >
                                            {currency.toLocaleUpperCase()}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={6} align="end">
                            <span className="create-player__form__label">
                                {getTranslation('Affiliate Code')}:
                            </span>
                        </Col>
                        <Col span={18}>
                            <Form.Item name="betfiliateId">
                                <Input maxLength={30} onChange={onChangeAffiliateCode} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={6} align="end">
                            <span className="create-player__form__label">
                                {getTranslation('Invite Code')}:
                            </span>
                        </Col>
                        <Col span={18}>
                            <Form.Item name="inviteCode">
                                <Input maxLength={10} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={6} align="end">
                            <span className="create-player__form__label">
                                {getTranslation('Channel')}:
                            </span>
                        </Col>
                        <Col span={18}>
                            <Form.Item name="betfiliateChannel">
                                <Input disabled maxLength={255} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={6} align="end">
                            <span className="create-player__form__label">
                                {getTranslation('Remark')}
                                <span style={{ color: 'red' }}>*</span>:
                            </span>
                        </Col>
                        <Col span={18}>
                            <Form.Item
                                name="remarks"
                                rules={[
                                    {
                                        message: getTranslation('Please fill in this field!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row justify="end" className="form__group">
                <ButtonGreen htmlType="submit" loading={playerCreating || affiliateInfoGetting}>
                    {getTranslation('Confirm')}
                </ButtonGreen>
                <Button onClick={onCancel}>{getTranslation('Cancel')}</Button>
            </Row>
        </Form>
    );
};
export default CreatePlayerForm;
