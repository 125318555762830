import React from 'react';

import { SyncOutlined } from '@ant-design/icons';

import { ButtonGray } from './Buttons';
import { getTranslation } from '../../../common/helpers/locale';

const DropButton = ({ onDropFilters }) => {
    return (
        <ButtonGray icon={<SyncOutlined />} className="drop-button" onClick={onDropFilters}>
            {getTranslation('Drop Filters')}
        </ButtonGray>
    );
};

export default DropButton;
