export const MERCHANT_DEPOSIT_TABLE_COLUMNS = [
    {
        key: 'requestedAt',
        title: 'Request Time',
    },
    {
        key: 'transactionNo',
        required: true,
        title: 'Task Transaction Number',
    },
    {
        key: 'playerUserName',
        title: 'Username',
    },
    {
        key: 'playerName',
        required: true,
        title: 'Full Name',
    },
    {
        key: 'memberLevel',
        required: true,
        title: 'Member Level',
    },
    {
        key: 'trustLevel',
        required: true,
        title: 'Trust Level',
    },
    {
        key: 'amount',
        required: true,
        title: 'Request Amount',
    },
    {
        key: 'currencyCode',
        required: true,
        title: 'Currency',
    },
    {
        key: 'providerName',
        required: true,
        title: 'Transaction Method',
    },
    {
        key: 'gatewayName',
        required: true,
        title: 'Receiving Merchant',
    },
    {
        key: 'merchantDomain',
        title: 'Merchant Domain',
    },
    {
        key: 'merchantId',
        title: 'Merchant ID',
    },
    {
        key: 'requesterUserName',
        required: true,
        title: 'Applicant',
    },
    {
        key: 'orderStatus',
        required: true,
        title: 'Status',
    },
    {
        key: 'verifiedAt',
        title: 'Process Time',
    },
    {
        key: 'transactionStatus',
        title: 'Transaction Status',
    },
    {
        key: 'operation',
        required: true,
        title: 'Operation',
    },
];
