import React from 'react';

import { Row } from 'antd';

import { BannerCodeFilter, BannerNameFilter, BannerStatusFilter } from '../filters';
import { CurrencyFilter, PeriodFilter } from '../../../common/components/filters';
import { bannerManagementFilterSet } from './bannerManagementActions';
import { getStateProp } from '../../../common/helpers/misc';
import { useStore } from '../../../store/StateProvider';

const BannerManagementFilters = () => {
    const [state, dispatch] = useStore();

    const { bannerManagement } = getStateProp(state, 'bonus');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => bannerManagementFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <BannerCodeFilter
                onFilterChange={setFilter}
                value={getStateProp(bannerManagement, 'filters.code', '')}
            />
            <BannerNameFilter
                onFilterChange={setFilter}
                value={getStateProp(bannerManagement, 'filters.bannerName')}
            />
            <BannerStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(bannerManagement, 'filters.bannerStatus')}
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(bannerManagement, 'filters.currencyCode')}
                name="currencyCode"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(bannerManagement, 'filters.dateFrom')}
                dateFromName="dateFrom"
                dateTo={getStateProp(bannerManagement, 'filters.dateTo')}
                dateToName="dateTo"
            />
        </Row>
    );
};

export default BannerManagementFilters;
