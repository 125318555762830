export const ACTIONS = {
    FILTERS_DROP_ALL: 'REFERRAL_RECORDS_FILTERS_DROP_ALL',
    FILTER_SET: 'REFERRAL_RECORDS_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'REFERRAL_RECORDS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'REFERRAL_RECORDS_TABLE_PAGE_SIZE_CHANGE',
    },
    REFERRAL_RECORDS_GET: 'REFERRAL_RECORDS_GET',
    REFERRAL_RECORDS_GET_FAILURE: 'REFERRAL_RECORDS_GET_FAILURE',
    REFERRAL_RECORDS_GET_SUCCESS: 'REFERRAL_RECORDS_GET_SUCCESS',
};

export const referralRecordsGet = (dispatch) =>
    dispatch({
        type: ACTIONS.REFERRAL_RECORDS_GET,
    });

export const referralRecordsGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRAL_RECORDS_GET_SUCCESS,
    });

export const referralRecordsGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRAL_RECORDS_GET,
    });

export const referralRecordsFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });
