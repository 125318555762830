import React from 'react';

import { ButtonGray, ButtonGreen } from '../../../../common/components/buttons/Buttons';
import { PAYMENT_ORDER_OPERATIONS, TRANSACTION_STATUSES } from '../../../../common/constants/misc';
import { getCurrentUser } from '../../../../common/helpers/api';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { openVerifyModal, performDepositOperation } from '../depositsActions';
import { useStore } from '../../../../store/StateProvider';

const VerifyButton = ({ transaction, dispatch }) => {
    const [state] = useStore();
    const {
        attendantUserName,
        attendantRealName,
        transactionStatus,
        verifierRealName,
    } = transaction;
    const operatorName = getStateProp(
        state,
        'user.userName',
        getStateProp(getCurrentUser(), 'claims.userName', 'Anonymus'), //Temp fix
    );
    const disabled = [TRANSACTION_STATUSES.REJECTED, TRANSACTION_STATUSES.SUCCESS].includes(
        transactionStatus,
    );

    // ============================================
    // METHODS
    // ============================================

    const getLabel = () => {
        switch (transactionStatus) {
            case TRANSACTION_STATUSES.ATTENDING:
                return `${getTranslation('Reviewing')} ${attendantRealName}`;
            case TRANSACTION_STATUSES.REJECTED:
            case TRANSACTION_STATUSES.SUCCESS:
                return `${getTranslation('Verified')} ${verifierRealName}`;
            default:
                getTranslation('Verify');
        }
    };

    const openOperationModal = () => {
        performDepositOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.ATTEND,
            transaction,
        });
        openVerifyModal(dispatch);
    };

    // ============================================
    // RENDER
    // ============================================

    return (attendantUserName !== operatorName &&
        transactionStatus === TRANSACTION_STATUSES.ATTENDING) ||
        disabled ? (
        <ButtonGray disabled label={getLabel()} className="action-btn__verify" />
    ) : (
        <>
            <ButtonGreen
                label={getTranslation('Verify')}
                onClick={openOperationModal}
                className="action-btn__verify"
            />
        </>
    );
};

export default VerifyButton;
