import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './ReferralReportTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const ReferralReportResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const referralReport = getStateProp(state, 'referral.referralReport');
    const paging = getStateProp(referralReport, 'paging', {});
    const tableData = getStateProp(referralReport, 'list', []);

    return (
        <Table
            id="referral-report-table"
            columns={getColumns()}
            size="small"
            scroll={{ x: true }}
            align="left"
            dataSource={tableData}
            rowKey={(record, index) => index}
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
            bordered
        />
    );
};

export default ReferralReportResultsTable;
