import React from 'react';

import { Col, Form, Input, Row, Select } from 'antd';
import _ from 'lodash';

import './MerchantSetupForm.less';
import { MERCHANT_STATUSES, SUBMMISSION_METHODS } from '../constants';
import { getTranslation } from '../../../../common/helpers/locale';

const { TextArea } = Input;

const MerchantSetupForm = ({ selectedMerchant }) => {
    return (
        <Row>
            <Col span={12} className="merchant__form-column">
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Merchant Name')}</label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="displayName"
                            rules={[
                                {
                                    message: getTranslation('Please input merchant name!'),
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                placeholder={getTranslation('Merchant Name Display In Mobile App')}
                                disabled={!_.isEmpty(selectedMerchant)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Merchant Code')}</label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="gatewayName"
                            rules={[
                                {
                                    message: getTranslation('Please input merchant code!'),
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                placeholder={getTranslation('Merchant Name')}
                                disabled={!_.isEmpty(selectedMerchant)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Merchant ID')}</label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="merchantId"
                            rules={[
                                {
                                    message: getTranslation('Please input merchant id!'),
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                placeholder={getTranslation('Gateway Assigned Id')}
                                disabled={!_.isEmpty(selectedMerchant)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Merchant Domain')}</label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="merchantDomain"
                            rules={[
                                {
                                    message: getTranslation('Please input merchant domain!'),
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                placeholder={getTranslation("Gateway's Domain")}
                                disabled={!_.isEmpty(selectedMerchant)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Deposit Endpoint')}</label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="depositEndpoint"
                            rules={[
                                {
                                    message: getTranslation('Please input deposit endpoint!'),
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                placeholder={getTranslation('Endpoint Called To Make Payment')}
                                disabled={!_.isEmpty(selectedMerchant)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Backend URL')}</label>
                    </Col>
                    <Col span={14}>
                        <Form.Item name="backendUrl">
                            <Input
                                placeholder={getTranslation('Call Back Url After Transaction Made')}
                                disabled={!_.isEmpty(selectedMerchant)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>

            <Col span={12} className="merchant__form-column">
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Merchant Key')}</label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="merchantKey"
                            rules={[
                                {
                                    message: getTranslation('Please input merchant key!'),
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                placeholder={getTranslation('Secret Key')}
                                disabled={!_.isEmpty(selectedMerchant)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Balance Limit Notification')}</label>
                    </Col>
                    <Col span={14}>
                        <Form.Item name="balanceLimitNotification">
                            <Input placeholder={getTranslation('Balance Limit Notification')} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Status')}</label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="activatedStatus"
                            rules={[
                                {
                                    message: getTranslation('Please select status!'),
                                    required: true,
                                },
                            ]}
                        >
                            <Select>
                                {_.map(MERCHANT_STATUSES, (status) => (
                                    <Select.Option value={status} key={status}>
                                        {getTranslation(status).toLocaleUpperCase()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Submission Method')}</label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="submissionMethod"
                            rules={[
                                {
                                    message: getTranslation('Please select submission method!'),
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                placeholder={getTranslation('Method Submit the Transaction')}
                                disabled={!_.isEmpty(selectedMerchant)}
                            >
                                {_.map(SUBMMISSION_METHODS, (method) => (
                                    <Select.Option value={method} key={method}>
                                        {getTranslation(method).toLocaleUpperCase()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Remark')}</label>
                    </Col>
                    <Col span={14}>
                        <Form.Item name="remark">
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default MerchantSetupForm;
