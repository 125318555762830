import React from 'react';

import { Row } from 'antd';

import { CurrencyFilter, PeriodFilter, UsernameFilter } from '../../../common/components/filters';
import { REPORT_TYPES, VIEW_MODES } from '../constants';
import { ReportTypeFilter, ViewModeFilter } from '../filter';
import { getStateProp } from '../../../common/helpers/misc';
import { playerWalletBalanceFilterSet } from './playerWalletBalanceActions';
import { useStore } from '../../../store/StateProvider';

const PlayerWalletBalanceFilters = ({ viewMode }) => {
    const [state, dispatch] = useStore();
    const playerWalletBalance = getStateProp(state, 'reports.playerWalletBalance');
    const reportType = getStateProp(playerWalletBalance, 'filters.reportType');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => playerWalletBalanceFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row type="flex" justify="start" align="middle" className="filters-container">
            <ViewModeFilter
                onFilterChange={setFilter}
                value={getStateProp(playerWalletBalance, 'filters.viewMode')}
            />
            <ReportTypeFilter
                onFilterChange={setFilter}
                value={getStateProp(playerWalletBalance, 'filters.reportType')}
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(playerWalletBalance, 'filters.processedDateFrom')}
                dateTo={getStateProp(playerWalletBalance, 'filters.processedDateTo')}
                dateFromName="processedDateFrom"
                dateToName="processedDateTo"
                picker={reportType === REPORT_TYPES.MONTHLY ? 'month' : 'date'}
                ignoreUTC={true}
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(playerWalletBalance, 'filters.currencies')}
                name="currencies"
                mode="multiple"
            />
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(playerWalletBalance, 'filters.userName')}
                name="userName"
                disabled={viewMode === VIEW_MODES.DATE}
            />
        </Row>
    );
};

export default PlayerWalletBalanceFilters;
