import React from 'react';

import { Row } from 'antd';
import _ from 'lodash';

import './NewsFilters.less';
import { NEWS_CATEGORIES } from '../constants';
import { PeriodFilter } from '../../../common/components/filters';
import { getStateProp } from '../../../common/helpers/misc';
import { newsFilterSet } from '../newsActions';
import { useStore } from '../../../store/StateProvider';
import NewsCategoryFilter from './filters/NewsCategoryFilter';
import NewsLanguageFilter from './filters/NewsLanguageFilter';
import NewsStatusFilter from './filters/NewStatusFilter';

const NewsFilters = () => {
    const [state, dispatch] = useStore();

    const currentSelectedCategory = _.find(
        NEWS_CATEGORIES,
        (category) => category === getStateProp(state, 'news.filters.newsCategory'),
    );

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => newsFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="news-filters__container">
            <NewsCategoryFilter value={currentSelectedCategory} onFilterChange={setFilter} />
            <NewsStatusFilter
                value={getStateProp(state, 'news.filters.newsStatus')}
                onFilterChange={setFilter}
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(state, 'news.filters.dateFrom')}
                dateFromName="dateFrom"
                dateTo={getStateProp(state, 'news.filters.dateTo')}
                dateToName="dateTo"
            />
            <NewsLanguageFilter
                value={getStateProp(state, 'news.filters.languageCode')}
                onFilterChange={setFilter}
            />
        </Row>
    );
};

export default NewsFilters;
