import React, { useEffect } from 'react';

import { Divider, Form, Row, Skeleton, Tabs } from 'antd';

import './TransactionAmountSetting.less';
import { ButtonGreen } from '../../../common/components/buttons/Buttons';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    useGetTransactionAmountSettingAsyncEndpoint,
    useUpdateTransactionAmountSettingAsyncEndpoint,
} from '../../../services/transactionAmountSettingService';
import { useStore } from '../../../store/StateProvider';
import DepositAmountSetting from './DepositAmountSetting';

const { TabPane } = Tabs;
const TransactionAmountSettingEwallet = () => {
    const [state] = useStore();
    const { deposit } = getStateProp(state, 'payments.transactionAmountSetting', {});

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetSetting] = useGetTransactionAmountSettingAsyncEndpoint(true);
    const [updating, handleUpdate, updated] = useUpdateTransactionAmountSettingAsyncEndpoint(true);

    useEffect(() => {
        if (updated) handleGetSetting();
    }, [handleGetSetting, updated]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <>
            {loading ? (
                <Skeleton />
            ) : (
                <Form onFinish={handleUpdate}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Deposit" key="deposit">
                            <DepositAmountSetting depositAmountSetting={deposit} />
                        </TabPane>
                    </Tabs>
                    <Divider />
                    <Row justify="end">
                        <ButtonGreen
                            label={getTranslation('Update')}
                            htmlType="submit"
                            loading={updating}
                        />
                    </Row>
                </Form>
            )}
        </>
    );
};

export default TransactionAmountSettingEwallet;
