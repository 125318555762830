import { ACTIONS } from './pushNotificationManagementAction';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function pushNotificationManagementReducer(
    pushNotificationManagement = {
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        resetPageNo: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.FILTER_SET:
            return {
                ...pushNotificationManagement,
                filters: {
                    ...pushNotificationManagement.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };
        case ACTIONS.FILTERS_DROP_ALL:
            return {
                ...pushNotificationManagement,
                filters: null,
                resetPageNo: true,
            };
        case ACTIONS.GET_NOTIFICATION_INFO_SUCCESS:
            return {
                ...pushNotificationManagement,
                ...payload,
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...pushNotificationManagement,
                paging: {
                    ...pushNotificationManagement.paging,
                    pageNo:
                        payload > pushNotificationManagement.paging.pageCount
                            ? pushNotificationManagement.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...pushNotificationManagement,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };
        case ACTIONS.PUSH_NOTIFICATION_MODAL_OPEN:
            return {
                ...pushNotificationManagement,
                modalvisible: true,
                selectedId: payload,
            };
        case ACTIONS.SEND_PUSH_NOTIFICATION_INFO_SUCCESS:
        case ACTIONS.UPSERT_PUSH_NOTIFICATION_INFO_SUCCESS:
        case ACTIONS.PUSH_NOTIFICATION_MODAL_CLOSE:
            return {
                ...pushNotificationManagement,
                modalvisible: false,
                selectedId: '',
                selectedPushNotification: {},
            };
        case ACTIONS.GET_NOTIFICATION_INFO_BY_ID_SUCCESS:
            return {
                ...pushNotificationManagement,
                selectedPushNotification: payload,
            };

        case ACTIONS.UPDATE_TARGET_INFO:
            return {
                ...pushNotificationManagement,
                selectedPushNotification: {
                    ...pushNotificationManagement.selectedPushNotification,
                    [payload.key]: payload.value,
                },
            };

        case ACTIONS.SEND_PUSH_NOTIFICATION_INFO:
        case ACTIONS.UPSERT_PUSH_NOTIFICATION_INFO:
        case ACTIONS.UPDATE_PUSH_NOTIFICATION_INFO:
        case ACTIONS.GET_NOTIFICATION_INFO_BY_ID:
        case ACTIONS.GET_NOTIFICATION_INFO:
        case ACTIONS.ADD_LANGUAGE:
        case ACTIONS.REMOVE_LANGUAGE:
        default:
            return pushNotificationManagement;
    }
}
