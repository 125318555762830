import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';

import { Popover, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import './Logout.less';
import { ACTIONS } from '../../common/actions/userActions';
import { ROUTES } from '../../common/constants/routing';
import { deleteUserCookies, getCurrentUser } from '../../common/helpers/api';
import { getStateProp } from '../../common/helpers/misc';
import { getTranslation } from '../../common/helpers/locale';
import { useStore } from '../../store/StateProvider';
import packageJson from '../../../package.json';

const Logout = () => {
    const [state, dispatch] = useStore();
    const [popoverVisible, setPopoverVisible] = useState(false);
    const userName = getStateProp(
        state,
        'user.userName',
        getStateProp(getCurrentUser(), 'claims.userName', 'Anonymus'), //Temp fix
    );
    const history = useHistory();

    // ============================================
    // METHODS
    // ============================================

    const logout = () => {
        dispatch({ type: ACTIONS.LOGOUT });
        deleteUserCookies();
        localStorage.clear();
        history.push(ROUTES.LOGIN);
    };

    const toChangePasswordPage = () => {
        history.push(ROUTES.DASHBOARD.CHANGE_PASSWORD);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Popover
            className="logout"
            content={
                <span>
                    <p className="logout__action" onClick={toChangePasswordPage}>
                        {getTranslation('Change Password')}
                    </p>
                    <p className="logout__action" onClick={logout}>
                        {getTranslation('Sign Out')}
                    </p>

                    <p className="logout__app-version">
                        <span>App version: {packageJson.version}</span>
                    </p>
                </span>
            }
            title={getTranslation('Action')}
            trigger="click"
            visible={popoverVisible}
            onVisibleChange={setPopoverVisible}
            arrowPointAtCenter
            placement="bottom"
        >
            <Row justify="center" align="middle">
                <UserOutlined style={{ fontSize: 24 }} />
                <span className="logout__user-name">{userName}</span>
            </Row>
        </Popover>
    );
};

export default Logout;
