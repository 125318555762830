import createPushNotificationReducer from './create-push-notification/createPushNotificationReducer';
import pushNotificationManagementReducer from './push-notification-management/pushNotificationManagementReducer';

export default function referralReducer(pushNotification = {}, action) {
    const { createPushNotification, pushNotificationManagement } = pushNotification;

    return {
        createPushNotification: createPushNotificationReducer(createPushNotification, action),
        pushNotificationManagement: pushNotificationManagementReducer(
            pushNotificationManagement,
            action,
        ),
    };
}
