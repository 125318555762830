import React from 'react';

import { Card, Form } from 'antd';

import './ReferralManagement.less';
import { dropFilters, referralEditModalOpen } from './referralManagementAction';
import { getTranslation } from '../../../common/helpers/locale';

import { getStateProp } from '../../../common/helpers/misc';
import {
    useGetReferralProgramAsyncEndpoint,
    useGetReferralProgramByIdAsyncEndpoint,
} from '../../../services/referralService';
import { useStore } from '../../../store/StateProvider';

import ActionButtons from './ActionButtons';
import ConfirmationModal from './ConfirmationModal';
import EditReferralContentModal from './EditReferralContentModal';
import ReferralManagementFilters from './ReferralManagementFilters';
import ReferralManagementResultsTable from './table/ReferralManagementResultsTable';

const ReferralManagement = () => {
    const [state, dispatch] = useStore();
    const { editModalvisible, selectedId } = getStateProp(state, 'referral.referralManagement');
    const { confirmModalVisible, id, name, programStatus } = getStateProp(
        state,
        'referral.referralManagement.changeReferralstatus',
        {},
    );
    const activeName = getStateProp(state, 'referral.referralManagement.activeReferral.name');
    const isLoading = getStateProp(state, 'referral.referralManagement.activeReferral.isLoading');

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetReferral();
    };

    const openEditModal = (id) => {
        referralEditModalOpen(dispatch, id);
        handleGetReferralById(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================
    const [loading, handleGetReferral] = useGetReferralProgramAsyncEndpoint();
    const [
        getReferralByIdLoading,
        handleGetReferralById,
    ] = useGetReferralProgramByIdAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Referral Management')}>
            <Form onFinish={handleGetReferral}>
                <ReferralManagementFilters />
                <ActionButtons fetchAll={fetchAll} />
            </Form>

            <ReferralManagementResultsTable loading={loading} openEditModal={openEditModal} />
            <EditReferralContentModal
                visible={editModalvisible}
                id={selectedId}
                getReferralByIdLoading={getReferralByIdLoading}
            />
            <ConfirmationModal
                confirmModalVisible={confirmModalVisible}
                id={id}
                name={name}
                activeName={activeName}
                isLoading={isLoading}
                programStatus={programStatus}
            />
        </Card>
    );
};

export default ReferralManagement;
