import PropTypes from 'prop-types';
import React from 'react';

import { Modal } from 'antd';

import './ColumnSelectionModal.less';
import { getTranslation } from '../../../common/helpers/locale';
import ColumnSelection from './ColumnSelection';

const ColumnSelectionModal = ({
    visible,
    onCancel,
    tableColumnsList,
    selectAction,
    selectedColumnsState,
}) => {
    return (
        <Modal
            visible={visible}
            title={getTranslation('Select Column')}
            centered
            className="column-selection-modal"
            onCancel={onCancel}
            footer={null}
            width={800}
        >
            <ColumnSelection
                tableColumnsList={tableColumnsList}
                selectAction={selectAction}
                selectedColumnsState={selectedColumnsState}
            />
        </Modal>
    );
};

ColumnSelectionModal.defaultProps = {
    selectedColumnsState: '',
    tableColumnsList: [],
    visible: false,
};

ColumnSelectionModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    selectAction: PropTypes.func.isRequired,
    selectedColumnsState: PropTypes.string.isRequired,
    tableColumnsList: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            required: PropTypes.bool,
            title: PropTypes.string.isRequired,
        }),
    ).isRequired,
    visible: PropTypes.bool.isRequired,
};

export default ColumnSelectionModal;
