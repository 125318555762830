import { useCallback, useEffect, useState } from 'react';

import { API } from '../common/constants/api';
import { PAYMENT_ORDER_OPERATIONS } from '../common/constants/misc';
import { TRANSACTION_MONITORING_TYPES } from '../components/transaction-monitoring/misc';
import { clearError } from '../common/actions/errorActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import { message } from 'antd';
import {
    transactionMonitoringGet,
    transactionMonitoringGetFailure,
    transactionMonitoringGetSuccess,
    transactionMonitoringOperationFailure,
    transactionMonitoringOperationSuccess,
} from '../components/transaction-monitoring/transactionMonitoringActions';
import { useNotification } from './hooks/useNotification';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, TRANSACTION_MONITORING, PAYMENTS, BALANCE_MANAGEMENT } = API;

export function useGetTransactionMonitoringAsyncEndpoint(isOnline) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { pageNo, pageSize, totalRecordCountNoFilter } = getStateProp(
        state,
        'transactionMonitoring.paging',
    );
    const filters = getStateProp(state, 'transactionMonitoring.filters', {});
    const refreshTime = getStateProp(state, 'transactionMonitoring.operation.refreshTime');
    const refreshTimePause = getStateProp(
        state,
        'transactionMonitoring.operation.refreshTimePause',
    );

    // ============================================
    // METHODS
    // ============================================

    const handleGetTransactionMonitoring = useCallback(() => {
        transactionMonitoringGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${TRANSACTION_MONITORING}`,
    });

    useEffect(() => {
        let timeInterval;
        if (response || isOnline)
            timeInterval = setTimeout(() => handleGetTransactionMonitoring(), refreshTime * 1000);
        if (refreshTimePause) clearTimeout(timeInterval);
        return () => clearTimeout(timeInterval);
    }, [handleGetTransactionMonitoring, isOnline, refreshTime, refreshTimePause, response]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);

            transactionMonitoringGetSuccess(dispatch, {
                dataTotal: response.data.data[0].dataTotal,
                fetchData: handleGetTransactionMonitoring,
                grandTotal: response.data.data[0].grandTotal,
                list: response.data.data[0].data,
                pageDataTotal: response.data.data[0].pageDataTotal,
                pageTotal: response.data.data[0].pageTotal,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetTransactionMonitoring, response]);

    useEffect(() => {
        handleGetTransactionMonitoring();
    }, [handleGetTransactionMonitoring, pageNo, pageSize]);

    const errorMsg = useError(error, transactionMonitoringGetFailure);
    useToasterErrorMessage(error, errorMsg);

    useNotification(
        totalRecordCountNoFilter,
        getStateProp(response, 'data.paging.totalRecordCountNoFilter', 0),
        'You have new record',
    );

    return [loading, handleGetTransactionMonitoring];
}

export function useTransactionMonitoringOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();

    const refreshTable = getStateProp(state, 'transactionMonitoring.fetchData', () => {});
    const operation = getStateProp(state, 'transactionMonitoring.operation');
    const remarks = getStateProp(state, 'transactionMonitoring.verifyInfo.auditRemark');

    const transactionNo = getStateProp(operation, 'transactionMonitoringRecord.transactionNo');
    const transactionDetails = getStateProp(
        operation,
        'transactionMonitoringRecord.transactionDetails',
    );

    const cryptocurrency = getStateProp(
        operation,
        'transactionMonitoringRecord.cryptoCurrencyCode',
    );
    const action = getStateProp(operation, 'action');
    const aspect = getStateProp(operation, 'aspect');
    const firstLvl = cryptocurrency ? 'isLevel1' : 'isFirstLvl';

    const params = aspect === 'Withdrawal' ? { [firstLvl]: true, remarks } : { remarks };

    // ============================================
    // HOOKS
    // ============================================
    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!action,
        method: 'POST',
        options: {
            data: {
                ...params,
            },
        },
        trigger: action,
        url: cryptocurrency
            ? `${VERSION.V1}${PAYMENTS.CRYPTO_WITHDRAWALS.SINGLE}/${transactionNo}/${action}`
            : aspect === TRANSACTION_MONITORING_TYPES.WITHDRAWAL
            ? `${VERSION.V1}${
                  transactionDetails === 'EWallet'
                      ? PAYMENTS.EWALLET_WITHDRAWALS.ROOT
                      : PAYMENTS.WITHDRAWALS.ROOT
              }/${transactionNo}/${action}`
            : `${VERSION.V1}${BALANCE_MANAGEMENT}/${transactionNo}/${action}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            transactionMonitoringOperationSuccess(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    useEffect(() => {
        if (response) {
            if (action === PAYMENT_ORDER_OPERATIONS.VERIFY) {
                message.success(getTranslation('Transaction verified!'));
            } else if (action === PAYMENT_ORDER_OPERATIONS.REJECT) {
                message.success(getTranslation('Transaction rejected!'));
            }
        }
    }, [response, action]);

    const errorMsg = useError(error, transactionMonitoringOperationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response];
}
