import { ACTIONS } from './depositCorrectionActions';

export default function depositCorrectionReducer(
    depositCorrection = {
        depositCorrectionInfo: {
            turnoverRequirement: 1,
        },
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.DEPOSIT_CORRECTION_INFO_SET:
            return {
                ...depositCorrection,
                depositCorrectionInfo: {
                    ...depositCorrection.depositCorrectionInfo,
                    [payload.inputName]: payload.value,
                },
            };
        case ACTIONS.PLAYER_DETAILS_GET_SUCCESS:
            return {
                ...depositCorrection,
                depositCorrectionInfo: {
                    ...depositCorrection.depositCorrectionInfo,
                    ...payload,
                },
            };
        case ACTIONS.TRANSACTION_GET_SUCCESS:
            return {
                ...depositCorrection,
                depositCorrectionInfo: {
                    ...depositCorrection.depositCorrectionInfo,
                    ...payload,
                },
            };
        case ACTIONS.TRANSACTION_GET_FAILURE:
            return {
                ...depositCorrection,
                depositCorrectionInfo: null,
            };
        case ACTIONS.FORM_RESET:
            return {
                ...depositCorrection,
                depositCorrectionInfo: {
                    turnoverRequirement: 1,
                },
            };
        case ACTIONS.OPERATION:
            return {
                ...depositCorrection,
                ...payload,
            };
        case ACTIONS.BANK_CARD_DETAILS_GET_SUCCESS:
            return {
                ...depositCorrection,
                depositCorrectionInfo: {
                    ...depositCorrection.depositCorrectionInfo,
                    ...payload,
                },
            };
        case ACTIONS.OPERATION_SUCCESS:
        case ACTIONS.OPERATION_FAILURE:
            return {
                ...depositCorrection,
                operation: null,
            };
        default:
            return depositCorrection;
    }
}
