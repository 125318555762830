import { getStateProp } from './misc';
import _ from 'lodash';

/**
 * Gets the browser's cookie.
 *
 * @param {String} cname The name of the cookie.
 * @returns {(String|undefined)} The cookie value, or undefined if not found.
 */
export function getCookie(cname) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${cname}=`);

    if (parts.length === 2) {
        return JSON.parse(
            decodeURIComponent(
                parts
                    .pop()
                    .split(';')
                    .shift(),
            ),
        );
    }

    return null;
}

/**
 * Sets the browser's cookie.
 *
 * @param {String} cname The name of the cookie.
 * @param {String} value The value of the cookie.
 * @param {Number} days The expiration delay in days.
 */
export function setCookie(cname, value, days) {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie =
        cname + '=' + (encodeURIComponent(JSON.stringify(value)) || '') + expires + '; path=/';
}

/**
 * Deletes the requested cookie.
 * @param {String} cname  The cookie name to delete.
 */
export function deleteCookie(cname) {
    document.cookie = cname + '=; Max-Age=-99999999;';
}

//#region User
export const getCurrentUser = () => getCookie('operator');

export const setUserCookies = (value, days = 30) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    setCookie('operator', value, {
        expires,
    });
};

export const getUserCurrency = () =>
    _.split(getStateProp(getCurrentUser(), 'claims.allowCurrency', []), ',');

export const getUserCountry = () =>
    _.split(getStateProp(getCurrentUser(), 'claims.allowCountry', []), ',');

export const isUserTokenExpired = () => {
    const user = getCurrentUser();
    if (!user) return false;

    const expires = user.expires;
    const now = +new Date();

    return expires - now <= 10000;
};

export const isUserLoggedIn = () => !!getCookie('operator');

export const deleteUserCookies = () => deleteCookie('operator');
//#endregion
//#region Language
export const getCurrentLanguage = () => getCookie('lang');

export const setLangCookies = (value) => setCookie('lang', value);

export const deleteLangCookies = () => deleteCookie('lang');
//#endregion Language

export const getResponseBodyProp = (response, propName, defaultValue) =>
    _.get(response, `data.data.${propName}`, defaultValue);

export const getOperationId = (res) => res.headers['x-operation'].replace('operations/', '');

export const getErrorStatus = (error) => getStateProp(error, 'response.status');

export const getErrorMessage = (error) => {
    const messageInResponseData = getStateProp(error, 'response.data.error.message');
    const messageInResponseStatusText = getStateProp(error, 'response.statusText');
    return messageInResponseData
        ? messageInResponseData
        : messageInResponseStatusText
        ? messageInResponseStatusText
        : error.message;
};
