import React from 'react';

import _ from 'lodash';

import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize, selectRecord } from '../merchantActions';
import { getStatusColor, sortTableByDataType } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import VerifyButton from '../verification/VerifyButton';

export const getColumns = (dispatch, selectedColumns) => {
    const columns = [
        {
            dataIndex: 'requestedAt',
            dataType: 'date',
            render: (value) => RenderDateFormat(value),
            title: getTranslation('Request Time'),
        },
        {
            dataIndex: 'transactionNo',
            render: (value) => <span style={{ wordBreak: 'break-all' }}>{value}</span>,
            title: getTranslation('Task Transaction Number'),
        },
        { dataIndex: 'playerUserName', title: getTranslation('Username') },
        { dataIndex: 'playerName', title: getTranslation('Full Name') },
        { dataIndex: 'memberLevel', title: getTranslation('Member Level') },
        { dataIndex: 'trustLevel', title: 'Trust Level' },
        { dataIndex: 'amount', title: getTranslation('Request Amount') },
        { dataIndex: 'currencyCode', title: getTranslation('Currency') },
        { dataIndex: 'providerName', title: getTranslation('Transaction Method') },
        { dataIndex: 'gatewayName', title: getTranslation('Merchant Code') },
        { dataIndex: 'merchantDomain', title: getTranslation('Merchant Domain') },
        { dataIndex: 'merchantId', title: getTranslation('Merchant ID') },
        { dataIndex: 'requesterUserName', title: getTranslation('Applicant') },
        {
            dataIndex: 'orderStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>
                    {getTranslation(value).toLocaleUpperCase()}
                </span>
            ),
            title: getTranslation('Status'),
        },
        {
            dataIndex: 'verifiedAt',
            dataType: 'date',
            render: (value) => RenderDateFormat(value),
            title: getTranslation('Process Time'),
        },
        {
            dataIndex: 'transactionStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>
                    {getTranslation(value).toLocaleUpperCase()}
                </span>
            ),
            title: getTranslation('Transaction Status'),
        },
        {
            dataIndex: 'operation',
            dataType: 'operation',
            render: (text, record) => <VerifyButton transaction={record} dispatch={dispatch} />,
            title: getTranslation('Operation'),
            width: 80,
        },
    ];

    return columns
        .filter((column) => _.map(selectedColumns, 'key').includes(column.dataIndex))
        .map((colProps) => ({
            ...colProps,
            sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
        }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

export const getRowSelectionProps = (dispatch, selectedTransactionId) => ({
    columnWidth: 30,
    fixed: true,
    getCheckboxProps: (record) => ({
        disabled: record.transactionStatus === 'Success' || record.transactionStatus === 'Rejected',
    }),
    onChange: onSelectRecord(dispatch),
    selectedRowKeys: selectedTransactionId,
});

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);

const onSelectRecord = (dispatch) => (rowKeys) => selectRecord(dispatch, rowKeys);

export const merchantRecordsExport = ({ onlyMeta }) => {
    if (onlyMeta) {
        return {
            operation: [
                {
                    dataIndex: 'verifierRealName',
                    title: getTranslation('Operation'),
                },
            ],
        };
    }

    let messageArray = [];
    return messageArray;
};
