import _ from 'lodash';

import { ACTIONS } from './bannerManagementActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function bannerManagementReducer(
    bannerDescription = {
        editModalVisible: false,
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        resetPageNo: false,
        selectedBanner: {},
        viewModalVisible: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.EDIT_BANNER_MANAGEMENT_LANGUAGE_SET:
        case ACTIONS.EDIT_BANNER_MANAGEMENT_LANGUAGE_DROP:
            return {
                ...bannerDescription,
                selectedBanner: { ...bannerDescription.selectedBanner, details: payload },
            };

        case ACTIONS.BANNER_MANAGEMENT_INFO_SET_BY_LANGUAGE: {
            return {
                ...bannerDescription,
                selectedBanner: {
                    ...bannerDescription.selectedBanner,
                    details: _.map(bannerDescription.selectedBanner.details, (value) => {
                        if (value.languageCode === payload.langCode) {
                            value[payload.name] = payload.value;
                        }
                        return value;
                    }),
                },
            };
        }

        case ACTIONS.BANNER_MANAGEMENT_INFO_SET:
            return {
                ...bannerDescription,
                selectedBanner: {
                    ...bannerDescription.selectedBanner,
                    [payload.name]: payload.value,
                },
            };

        case ACTIONS.BANNER_SELECTED:
            return {
                ...bannerDescription,
                selectedBanner: payload,
            };

        case ACTIONS.BANNER_DESELECTED:
            return {
                ...bannerDescription,
                selectedBanner: {},
            };

        case ACTIONS.BANNER_MANAGEMENT_LIST_GET_SUCCESS:
        case ACTIONS.BANNER_MANAGEMENT_STATUS_SET:
            return {
                ...bannerDescription,
                ...payload,
            };

        case ACTIONS.BANNER_MANAGEMENT_CLEAR_CACHE:
            return {
                ...bannerDescription,
                changeBannerStatus: null,
            };

        case ACTIONS.PAGINATION.BANNER_MANAGEMENT_TABLE_PAGE_CHANGE:
            return {
                ...bannerDescription,
                paging: {
                    ...bannerDescription.paging,
                    pageNo:
                        payload > bannerDescription.paging.pageCount
                            ? bannerDescription.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.BANNER_MANAGEMENT_TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...bannerDescription,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.BANNER_MANAGEMENT_FILTER_SET:
            return {
                ...bannerDescription,
                filters: {
                    ...bannerDescription.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };
        case ACTIONS.BANNER_MANAGEMENT_FILTERS_DROP_ALL:
            return {
                ...bannerDescription,
                filters: null,
                resetPageNo: true,
            };

        case ACTIONS.BANNER_MANAGEMENT_BY_ID_GET_SUCCESS: {
            return {
                ...bannerDescription,
                selectedBanner: {
                    ...payload,
                },
            };
        }
        case ACTIONS.BANNER_MANGEMENT_EDIT_MODAL_OPEN:
            return {
                ...bannerDescription,
                editModalVisible: true,
            };
        case ACTIONS.BANNER_MANGEMENT_EDIT_MODAL_CLOSE:
            return {
                ...bannerDescription,
                editModalVisible: false,
            };
        case ACTIONS.BANNER_MANAGEMENT_LIST_GET:
            const pageNo = bannerDescription.resetPageNo
                ? TABLE_PAGE_NO_DEFAULT
                : bannerDescription.paging.pageNo;
            return {
                ...bannerDescription,
                paging: {
                    ...bannerDescription.paging,
                    pageNo: pageNo,
                },
                resetPageNo: false,
            };

        case ACTIONS.BANNER_MANAGEMENT_DUPLICATE_BY_LANGUAGE:
            return {
                ...bannerDescription,
                selectedBanner: {
                    ...bannerDescription.selectedBanner,
                    details: payload,
                },
            };

        case ACTIONS.BANNER_MANGEMENT_VIEW_MODAL_OPEN:
            return {
                ...bannerDescription,
                viewModalVisible: true,
            };
        case ACTIONS.BANNER_MANGEMENT_VIEW_MODAL_CLOSE:
            return {
                ...bannerDescription,
                viewModalVisible: false,
            };

        default: {
            return bannerDescription;
        }
    }
}
