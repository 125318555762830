export const ACTIONS = {
    BANK_CARD_GET: 'ACTIVE_BANK_CARD_GET',
    BANK_CARD_GET_FAILURE: 'ACTIVE_BANK_CARD_GET_FAILURE',
    BANK_CARD_GET_SUCCESS: 'ACTIVE_BANK_CARD_GET_SUCCESS',
    BANK_CARD_SETUP_FORM_SUBMIT: 'BANK_CARD_SETUP_FORM_SUBMIT',
    BANK_CARD_SETUP_FORM_SUBMIT_FAILURE: 'BANK_CARD_SETUP_FORM_SUBMIT_FAILURE',
    BANK_CARD_SETUP_FORM_SUBMIT_SUCCESS: 'BANK_CARD_SETUP_FORM_SUBMIT_SUCCESS',
    BANK_CARD_UPDATE_FORM_SUBMIT: 'ACTIVE_BANK_CARD_UPDATE_FORM_SUBMIT',
    BANK_CARD_UPDATE_FORM_SUBMIT_FAILURE: 'ACTIVE_BANK_CARD_UPDATE_FORM_SUBMIT_FAILURE',
    BANK_CARD_UPDATE_FORM_SUBMIT_SUCCESS: 'ACTIVE_BANK_CARD_UPDATE_FORM_SUBMIT_SUCCESS',
    CARD_SETUP_MODAL_CLOSE: 'ACTIVE_BANK_CARD_SETUP_MODAL_CLOSE',
    CARD_SETUP_MODAL_OPEN: 'ACTIVE_BANK_CARD_SETUP_MODAL_OPEN',
    FILTER_DROP: 'ACTIVE_BANK_CARD_FILTER_DROP',
    FILTER_SET: 'ACTIVE_BANK_CARD_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'ACTIVE_BANK_CARD_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'ACTIVE_BANK_CARD_TABLE_PAGE_SIZE_CHANGE',
    },
};

export const activatedBankCardSetupModalOpen = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CARD_SETUP_MODAL_OPEN,
    });

export const activatedBankCardSetupModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.CARD_SETUP_MODAL_CLOSE,
    });

export const bankCardGet = (dispatch) =>
    dispatch({
        type: ACTIONS.BANK_CARD_GET,
    });

export const bankCardGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANK_CARD_GET_SUCCESS,
    });

export const bankCardGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANK_CARD_GET_FAILURE,
    });

export const bankCardFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const bankCardDropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTER_DROP,
    });

export const bankCardSetup = (dispatch) =>
    dispatch({
        type: ACTIONS.BANK_CARD_SETUP_FORM_SUBMIT,
    });

export const bankCardSetupFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANK_CARD_SETUP_FORM_SUBMIT_FAILURE,
    });

export const bankCardSetupSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.BANK_CARD_SETUP_FORM_SUBMIT_SUCCESS,
    });

export const activatedBankCardUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.BANK_CARD_UPDATE_FORM_SUBMIT,
    });

export const activatedBankCardUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANK_CARD_UPDATE_FORM_SUBMIT_FAILURE,
    });

export const activatedBankCardUpdateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.BANK_CARD_UPDATE_FORM_SUBMIT_SUCCESS,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });
