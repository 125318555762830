import React from 'react';

import { Modal, Row } from 'antd';

import '../AffiliateVerification.less';
import { ButtonGray, ButtonGreen, ButtonRed } from '../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../common/helpers/locale';
import { openConfirmModal, openConfirmRejectModal } from '../affiliateVerificationActions';
import { useDispatch } from '../../../../store/StateProvider';

const VerifyModal = ({ onCancel, visible, transaction, operationPending }) => {
    const dispatch = useDispatch();
    const { playerName, amount } = transaction;

    // ============================================
    // METHODS
    // ============================================

    const showConfirmModal = () => openConfirmModal(dispatch);
    const ConfirmRejectModal = () => openConfirmRejectModal(dispatch);

    // ============================================
    // HOOKS
    // ============================================

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            title={getTranslation('Affiliate Verification')}
            centered
            closable={false}
            className="affiliate-verification__verification-modal"
            onCancel={onCancel}
            footer={[
                <Row key="actions" justify="space-between">
                    <Row align="middle">
                        <ButtonRed
                            label={getTranslation('Reject')}
                            onClick={ConfirmRejectModal}
                            loading={operationPending}
                        />
                    </Row>
                    <Row align="middle">
                        <ButtonGreen
                            label={getTranslation('Approve')}
                            onClick={showConfirmModal}
                            loading={operationPending}
                        />
                        <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                    </Row>
                </Row>,
            ]}
        >
            <Row justify="start" className="deposit__name-modal">
                <p className="modal-description">{getTranslation('Full Name')}</p> :
                <p className="deposit-record">{playerName}</p>
            </Row>
            <Row justify="start" className="deposit__amount-modal">
                <p className="modal-description">{getTranslation('Amount')}</p> :
                <p className="deposit-record">{amount}</p>
            </Row>
        </Modal>
    );
};

export default VerifyModal;
