import _ from 'lodash';

import { ACTIONS } from './newsActions';
import { NEWS_STATUSES } from './constants';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../common/constants/api';
import { getDefaultContent } from './helpers';

export default function newsReducer(
    news = {
        deleteModalVisible: false,
        newsDraft: {
            newsArticles: getDefaultContent(),
            newsStatus: NEWS_STATUSES.PENDING,
        },
        operation: {
            action: null,
        },
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        resetPageNo: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.RECORD_SELECT:
            return {
                ...news,
                selectedNewsId: payload,
            };

        case ACTIONS.OPERATION:
            return {
                ...news,
                operation: {
                    ...news.operation,
                    ...payload,
                },
            };

        case ACTIONS.FILTER_SET:
            return {
                ...news,
                filters: {
                    ...news.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };

        case ACTIONS.FILTERS_DROP_ALL:
            return {
                ...news,
                filters: null,
                resetPageNo: true,
            };

        case ACTIONS.NEWS_GET_SUCCESS:
            return {
                ...news,
                ...payload,
            };
        case ACTIONS.NEWS_EDIT:
            return {
                ...news,
                newsDraft: payload,
            };
        case ACTIONS.NEWS_UPDATE:
            return {
                ...news,
            };
        case ACTIONS.NEWS_CREATE:
            return {
                ...news,
            };

        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...news,
                paging: {
                    ...news.paging,
                    pageNo: payload > news.paging.pageCount ? news.paging.pageCount - 1 : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...news,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };
        case ACTIONS.DELETE_MODAL_OPEN:
            return {
                ...news,
                ...payload,
                deleteModalVisible: true,
            };
        case ACTIONS.DELETE_MODAL_CLOSE:
            return {
                ...news,
                deleteModalVisible: false,
            };
        case ACTIONS.NEWS_DELETE_SUCCESS:
            return {
                ...news,
                deleteModalVisible: false,
                newsDelete: null,
            };
        case ACTIONS.OPERATION_SUCCESS:
            return {
                ...news,
                operation: {
                    ...news.operation,
                    action: null,
                },
                selectedNewsId: null,
            };
        case ACTIONS.NEWS_GET:
            const pageNo = news.resetPageNo ? TABLE_PAGE_NO_DEFAULT : news.paging.pageNo;
            return {
                ...news,
                paging: {
                    ...news.paging,
                    pageNo: pageNo,
                },
                resetPageNo: false,
            };

        //

        case ACTIONS.NEWS_SET_LANGUAGE:
            return {
                ...news,
                newsDraft: {
                    ...news.newsDraft,
                    newsArticles: payload,
                },
            };

        case ACTIONS.NEWS_ARTICLE_SAVE: {
            _.map(news.newsDraft.newsArticles, (value) => {
                if (value.languageCode === payload.langCode) {
                    value[payload.name] = payload.value;
                }
                return value;
            });

            return {
                ...news,
                newsDraft: {
                    ...news.newsDraft,
                    newsArticles: [...news.newsDraft.newsArticles],
                },
            };
        }

        case ACTIONS.NEWS_DRAFT_SAVE:
            return {
                ...news,
                newsDraft: {
                    ...news.newsDraft,
                    [payload.name]: payload.value,
                },
            };

        case ACTIONS.NEWS_DRAFT_CLEAR:
            return {
                ...news,
                newsDraft: {
                    newsArticles: getDefaultContent(),
                    newsStatus: NEWS_STATUSES.PENDING,
                },
            };
        default:
            return news;
    }
}
