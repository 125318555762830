import React from 'react';

import { InputNumber } from 'antd';
import _ from 'lodash';

import { BANNER_TYPES } from '../../constants';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import {
    changeAvailableBannerPageNumber,
    changeAvailableBannerPageSize,
    onBannerSelect,
} from '../bannerSortingActions';
import { getTranslation } from '../../../../common/helpers/locale';
import ImgIcon from '../../../../common/components/ImgIcon';

export const getColumns = (list, showSelectedBanners, updateSorting) => {
    const columns = [
        {
            dataIndex: 'code',
            title: getTranslation('Banner Code'),
        },
        {
            dataIndex: 'banner',
            render: (value, record) => {
                const imageUrl = showSelectedBanners
                    ? record.activeBannerDetails[0]?.imageUrl
                    : record.details[0]?.imageUrl;

                return imageUrl && <ImgIcon icon={imageUrl} style={{ maxWidth: '100%' }} />;
            },
            title: getTranslation('Banner'),
            width: 300,
        },
        {
            dataIndex: 'type',
            title: getTranslation('Type'),
        },
        {
            dataIndex: 'sortingOrder',
            render: (value, record) => {
                return (
                    _.find(list, (data) => data.id === record.id) && (
                        <InputNumber
                            value={_.find(list, (data) => data.id === record.id).sortingOrder}
                            min={1}
                            onChange={(value) => updateSorting(value, record.id)}
                        />
                    )
                );
            },
            title: getTranslation('Sorting'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
    }));
};

export const getRowSelectionProps = (dispatch, selectedKeys, selectedRecords, bannerType) => ({
    columnWidth: 30,
    fixed: true,
    onSelect: (record, selected, selectedRows) => {
        const payload = _.map(_.compact(selectedRows), (row) => {
            const selected = _.find(selectedRecords, (record) => record.id === row.id);
            if (selected) {
                return selected;
            } else {
                return {
                    activeBannerDetails: _.map(row.details, (detail) => {
                        return {
                            imageUrl: detail.imageUrl,
                            imageUrlCN: detail.imageUrlCN,
                            languageCode: detail.languageCode,
                            name:
                                bannerType === BANNER_TYPES.BANNER
                                    ? detail.bannerName
                                    : bannerType === BANNER_TYPES.BONUS
                                    ? detail.bonusName
                                    : detail.rebateName,
                            url: detail.bannerUrl,
                        };
                    }),
                    bannerType,
                    code: row.code,
                    id: row.id,
                    sortingOrder: selectedRecords.length + 1,
                };
            }
        });
        const allSelectedRecords = _.unionBy(selectedRecords, payload, (row) => row.id);

        onBannerSelect(
            dispatch,
            selected
                ? allSelectedRecords
                : _.reject(allSelectedRecords, (row) => {
                      return row.id === record.id;
                  }),
        );
    },
    selectedRowKeys: selectedKeys,
});

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changeAvailableBannerPageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) =>
    changeAvailableBannerPageSize(dispatch, pageSize);
