import React from 'react';

import { Input, Row, Select } from 'antd';
import _ from 'lodash';

import { NOTIFICATION_STATUSES, TARGET_NOTIFICATION_TYPES } from '../constants';
import { PeriodFilter } from '../../../common/components/filters';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { pushNotificationFilterSet } from './pushNotificationManagementAction';
import { useStore } from '../../../store/StateProvider';

const DailyLoginManagementFilters = () => {
    const [state, dispatch] = useStore();
    const pushNotificationManagement = getStateProp(
        state,
        'pushNotification.pushNotificationManagement',
    );
    const refreshTable = getStateProp(pushNotificationManagement, 'fetchData', () => {});
    const filters = getStateProp(pushNotificationManagement, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => {
        pushNotificationFilterSet(dispatch, { filter, value });
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <Input
                value={getStateProp(filters, 'title')}
                onChange={(e) => setFilter('title', e.target.value)}
                placeholder={'Title'}
                className="filter"
            />
            <Select
                value={getStateProp(filters, 'notificationStatus')}
                onChange={(e) => setFilter('notificationStatus', e)}
                allowClear
                placeholder={getTranslation('Status')}
                className="filter"
            >
                {_.map(NOTIFICATION_STATUSES, (types) => (
                    <Select.Option value={types} key={types}>
                        {types.toLocaleUpperCase()}
                    </Select.Option>
                ))}
            </Select>
            <Select
                value={getStateProp(filters, 'targetType')}
                onChange={(value) => setFilter('targetType', value)}
                placeholder={getTranslation('Type')}
                className="filter"
            >
                {_.map({ ...TARGET_NOTIFICATION_TYPES, GENERAL: 'general' }, (type) => (
                    <Select.Option key={type} value={type}>
                        {type.toLocaleUpperCase()}
                    </Select.Option>
                ))}
            </Select>
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(filters, 'dateFrom')}
                dateFromName="dateFrom"
                dateTo={getStateProp(filters, 'dateTo')}
                dateToName="dateTo"
                onRefreshTable={refreshTable}
            />
        </Row>
    );
};

export default DailyLoginManagementFilters;
