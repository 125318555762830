import { ACTIONS } from '../actions/userActions';

export default function userReducer(user, { type, payload = null }) {
    switch (type) {
        case ACTIONS.LOGIN:
        case ACTIONS.DETAILS_SET:
            return {
                ...user,
                ...payload,
            };
        case ACTIONS.LOGIN_SUCCESS:
            return {
                ...payload,
            };
        case ACTIONS.LOGOUT:
            return undefined;
        case ACTIONS.GET_PERMISSION:
        case ACTIONS.GET_PERMISSION_SUCCESS:
        default:
            return user;
    }
}
