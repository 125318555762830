import React, { useEffect } from 'react';

import { Modal, Row } from 'antd';

import { ButtonGray, ButtonGreen } from '../../../../common/components/buttons/Buttons';
import { DAILY_LOGIN_STATUSES } from '../../constants';
import { getTranslation } from '../../../../common/helpers/locale';
import { useUpdateDailyLoginStatusAsyncEndpoint } from '../../../../services/rewardMangementService';

const ConfirmModal = ({ visible, activeName, name, onCancel, id }) => {
    const [
        updating,
        handleUpdateDailyLoginBonusStatus,
        updated,
    ] = useUpdateDailyLoginStatusAsyncEndpoint(id, DAILY_LOGIN_STATUSES.ACTIVE);

    useEffect(() => {
        if (updated) {
            onCancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updated]);

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            centered
            className="daily-login-setup__confirm-modal"
            closable={false}
            footer={
                <Row key="actions" justify="space-between" align="middle">
                    <ButtonGreen
                        label={getTranslation('Confirm')}
                        className="form__submit-button"
                        onClick={handleUpdateDailyLoginBonusStatus}
                        loading={updating}
                    />
                    <ButtonGray
                        label={getTranslation('Cancel')}
                        className="form__reset-button"
                        onClick={onCancel}
                    />
                </Row>
            }
        >
            <Row>
                {
                    <label>
                        {activeName
                            ? getTranslation(
                                  'Are you sure you want to active {name} ? Activating this will result in deactivating {activeName}.',
                                  {
                                      activeName,
                                      name,
                                  },
                              )
                            : getTranslation('Are you sure you want to active {name} ?', {
                                  name,
                              })}
                    </label>
                }
            </Row>
        </Modal>
    );
};

export default ConfirmModal;
