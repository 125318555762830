import React from 'react';

import { Checkbox, Col, Form, Modal, Row, Tabs } from 'antd';
import _ from 'lodash';

import {
    BonusAutomationFilter,
    DailyLoginFilter,
    RebateGeneratorFilter,
    VipPayoutFilter,
    WithdrawFilter,
} from './filters';
import { ButtonGray, ButtonGreen } from '../../../common/components/buttons/Buttons';
import { DANGER_LEVEL_SETTING_TABS, DEPOSIT_PROHIBITION } from './constants';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { updateSelectedDangerLevelSetting } from './dangerLevelActions';
import { useStore } from '../../../store/StateProvider';

const { TabPane } = Tabs;

const DangerLevelSettingModal = ({
    visible,
    updating,
    onSubmit,
    handleDangerLevelSettingModal,
}) => {
    const [state, dispatch] = useStore();

    const { selectedDangerLevelSetting = {} } = getStateProp(state, 'players.dangerLevel');
    const dangerLevel = getStateProp(selectedDangerLevelSetting, 'dangerLevel', '');
    const dangerLevelTag = getStateProp(selectedDangerLevelSetting, 'dangerLevelTag', '');
    const bonus = getStateProp(selectedDangerLevelSetting, 'bonus', {});
    const withdrawal = getStateProp(selectedDangerLevelSetting, 'withdrawal', {});
    const deposit = getStateProp(selectedDangerLevelSetting, 'deposit', {});
    // ============================================
    // METHODS
    // ============================================
    const handleCancel = () => {
        handleDangerLevelSettingModal(false, {});
    };

    const handleUpdateWithdrawal = (key, value) => {
        updateSelectedDangerLevelSetting(dispatch, {
            withdrawal: {
                [key]: value,
            },
        });
    };

    const handleUpdateDeposit = (value) => {
        let newObj = {};
        _.map(DEPOSIT_PROHIBITION, (e) => {
            newObj[e.key] = _.includes(value, e.key);
        });

        updateSelectedDangerLevelSetting(dispatch, {
            deposit: {
                ...newObj,
            },
        });
    };

    const handleUpdateBonus = (key, value) => {
        updateSelectedDangerLevelSetting(dispatch, {
            bonus: {
                ...bonus,
                [key]: value,
            },
        });
    };

    // ============================================
    // HOOKS
    // ============================================

    // ============================================
    // RENDER
    // ============================================
    const renderWithdrawal = () => {
        return (
            <Row className="danger-level__setting-modal__form__row">
                <Col span={8}>
                    <label>{getTranslation('Withdrawal Prohibition')}</label>
                </Col>
                <Col span={16} className="filters-container">
                    <WithdrawFilter
                        onFilterChange={(e, value) => handleUpdateWithdrawal(e, value)}
                        value={withdrawal.withdrawalProhibitionStatus}
                        name="withdrawalProhibitionStatus"
                    />
                </Col>
            </Row>
        );
    };

    const renderDeposit = () => {
        let value = [];
        _.map(deposit, (e, key) => {
            if (e) value.push(key);
        });

        return (
            <Checkbox.Group
                className="danger-level__setting-modal__form__checkbox-group"
                onChange={handleUpdateDeposit}
                value={value}
            >
                {_.map(DEPOSIT_PROHIBITION, (e) => {
                    return (
                        <Row className="danger-level__setting-modal__form__row" key={e.key}>
                            <Col span={8}>
                                <label>{getTranslation(e.name)}</label>
                            </Col>
                            <Col span={16} className="filters-container">
                                <Checkbox value={e.key} />
                            </Col>
                        </Row>
                    );
                })}
            </Checkbox.Group>
        );
    };

    const renderBonus = () => {
        return (
            <>
                <Row className="danger-level__setting-modal__form__row">
                    <Col span={8}>
                        <label>{getTranslation('Join Bonus')}</label>
                    </Col>
                    <Col span={16} className="filters-container">
                        <BonusAutomationFilter
                            onFilterChange={(e, value) => handleUpdateBonus(e, value)}
                            value={bonus.joinBonus}
                            name="joinBonus"
                        />
                    </Col>
                </Row>
                <Row className="danger-level__setting-modal__form__row">
                    <Col span={8}>
                        <label>{getTranslation('Rebate Generator')}</label>
                    </Col>
                    <Col span={16} className="filters-container">
                        <RebateGeneratorFilter
                            onFilterChange={(e, value) => handleUpdateBonus(e, value)}
                            value={bonus.rebateGeneratorStatus}
                            name="rebateGeneratorStatus"
                        />
                    </Col>
                </Row>
                <Row className="danger-level__setting-modal__form__row">
                    <Col span={8}>
                        <label>{getTranslation('Daily Login')}</label>
                    </Col>
                    <Col span={16} className="filters-container">
                        <DailyLoginFilter
                            onFilterChange={(e, value) => handleUpdateBonus(e, value)}
                            value={bonus.dailyLoginStatus}
                            name="dailyLoginStatus"
                        />
                    </Col>
                </Row>
                <Row className="danger-level__setting-modal__form__row">
                    <Col span={8}>
                        <label>{getTranslation('VIP Payout')}</label>
                    </Col>
                    <Col span={16} className="filters-container">
                        <VipPayoutFilter
                            onFilterChange={(e, value) => handleUpdateBonus(e, value)}
                            value={bonus.vipPayoutStatus}
                            name="vipPayoutStatus"
                        />
                    </Col>
                </Row>
            </>
        );
    };

    return (
        <Modal
            className="danger-level__setting-modal"
            width={780}
            visible={visible}
            title={getTranslation('Edit Danger Level Description')}
            onCancel={handleCancel}
            closable={false}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        label={getTranslation('Save Settings')}
                        onClick={onSubmit}
                        loading={updating}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={handleCancel} />
                </Row>,
            ]}
        >
            <Form name="danger-level__setting-modal" className="danger-level__setting-modal__form">
                <Row className="danger-level__setting-modal__form__row">
                    <Col span={8}>
                        <label>{getTranslation('Danger Level')}</label>
                    </Col>
                    <Col span={16}>
                        <label>{dangerLevel}</label>
                    </Col>
                </Row>
                <Row className="danger-level__setting-modal__form__row">
                    <Col span={8}>
                        <label>{getTranslation('Danger Level Tag')}</label>
                    </Col>
                    <Col span={16}>
                        <label>{dangerLevelTag}</label>
                    </Col>
                </Row>
                <Tabs className="tab_form" type="card" hideAdd>
                    {_.map(DANGER_LEVEL_SETTING_TABS, (e) => {
                        return (
                            <TabPane tab={getTranslation(e.name)} key={e.key}>
                                {e.key === 1
                                    ? renderBonus()
                                    : e.key === 2
                                    ? renderDeposit()
                                    : renderWithdrawal()}
                            </TabPane>
                        );
                    })}
                </Tabs>
            </Form>
        </Modal>
    );
};

export default DangerLevelSettingModal;
