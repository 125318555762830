import React from 'react';

import { Col, Row } from 'antd';

import './ActionButtons.less';
import { DropButton, ExportButton, SearchButton } from '../../../common/components/buttons';
import { getTranslation } from '../../../common/helpers/locale';

const ActionButtons = ({ onDropFilters, onExport, exporting }) => {
    return (
        <Row align="middle" justify="space-between" className="action-buttons__container">
            <span className="bet-list--search-note">
                {getTranslation(
                    'Note : For data which is more than 1 week please contact customer service',
                )}
            </span>
            <Col>
                <Row>
                    <SearchButton htmlType="submit" />
                    <DropButton onDropFilters={onDropFilters} />
                    <ExportButton onExport={onExport} loading={exporting} />
                </Row>
            </Col>
        </Row>
    );
};

export default ActionButtons;
