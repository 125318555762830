export const ACTIONS = {
    ADJUSTMENT_INFO_SET: 'ADJUSTMENT_INFO_SET',
    FORM_RESET: 'BALANCE_MANAGEMENT_FORM_RESET',
    OPERATION: 'BALANCE_MANAGEMANT_OPERATION',
    OPERATION_FAILURE: 'BALANCE_MANAGEMANT_OPERATION_FAILURE',
    OPERATION_SUCCESS: 'BALANCE_MANAGEMANT_OPERATION_SUCCESS',
    PLAYER_DETAILS_GET: 'PLAYER_DETAILS_GET',
    PLAYER_DETAILS_GET_FAILURE: 'PLAYER_DETAILS_GET_FAILURE',
    PLAYER_DETAILS_GET_SUCCESS: 'PLAYER_DETAILS_GET_SUCCESS',
};

export const adjustmentInfoSet = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.ADJUSTMENT_INFO_SET,
    });
};

export const playerDetailsGet = (dispatch) => {
    dispatch({
        type: ACTIONS.PLAYER_DETAILS_GET,
    });
};

export const playerDetailsGetSuccess = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.PLAYER_DETAILS_GET_SUCCESS,
    });
};

export const playerDetailsGetFailure = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.PLAYER_DETAILS_GET_FAILURE,
    });
};

export const performBalanceManagementOperation = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.OPERATION,
    });
};

export const performBalanceManagementOperationSuccess = (dispatch) => {
    dispatch({ type: ACTIONS.OPERATION_SUCCESS });
};

export const balanceManagementOperationFailure = (dispatch, payload) => {
    dispatch({ payload, type: ACTIONS.OPERATION_FAILURE });
};

export const balanceManagementFormReset = (dispatch) => {
    dispatch({ type: ACTIONS.FORM_RESET });
};
