import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { NEWS_STATUSES } from '../../constants';
import { getTranslation } from '../../../../common/helpers/locale';

const NewsStatusFilter = ({ value, onFilterChange }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('newsStatus', value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear
            placeholder={getTranslation('News Status')}
            className="filter"
        >
            {_.map(NEWS_STATUSES, (status) => (
                <Select.Option key={status} value={status}>
                    {getTranslation(status).toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
};

export default NewsStatusFilter;
