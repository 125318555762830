import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { QUALITY_LEVELS } from '../../../common/constants/misc';
import { getTranslation } from '../../../common/helpers/locale';

const QualityLevelFilter = ({ value, onFilterChange }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('qualityLevel', value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear
            placeholder={getTranslation('Quality Level')}
            className="filter"
        >
            {_.map(QUALITY_LEVELS, (qualityLevel) => (
                <Select.Option key={qualityLevel} value={qualityLevel}>
                    {getTranslation(qualityLevel)}
                </Select.Option>
            ))}
        </Select>
    );
};

export default QualityLevelFilter;
