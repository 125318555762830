export const ACTIONS = {
    ADD_LANGUAGE: 'ADD_LANGUAGE',
    CREATE_GENERAL_PUSH_NOTIFICATION: 'CREATE_GENERAL_PUSH_NOTIFICATION',
    CREATE_GENERAL_PUSH_NOTIFICATION_FAILURE: 'CREATE_GENERAL_PUSH_NOTIFICATION_FAILURE',
    CREATE_GENERAL_PUSH_NOTIFICATION_SUCCESS: 'CREATE_GENERAL_PUSH_NOTIFICATION_SUCCESS',
    REMOVE_LANGUAGE: 'REMOVE_LANGUAGE',
    RESET_GENERAL_PUSH_NOTIFICATION_INFO: 'RESET_GENERAL_PUSH_NOTIFICATION_INFO',
    UPDATE_GENERAL_PUSH_NOTIFICATION_INFO: 'UPDATE_GENERAL_PUSH_NOTIFICATION_INFO',
};

//# region update language
export const addNewPushNotificationLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ADD_LANGUAGE,
    });

export const removePushNotificationLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REMOVE_LANGUAGE,
    });
//# end region

//# region update push notification info
export const updatePushNotificationInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.UPDATE_GENERAL_PUSH_NOTIFICATION_INFO,
    });

export const resetPushNotificationInfo = (dispatch) =>
    dispatch({
        type: ACTIONS.RESET_GENERAL_PUSH_NOTIFICATION_INFO,
    });
//# end region

//# region create push notification
export const createPushNotification = (dispatch) =>
    dispatch({
        type: ACTIONS.CREATE_GENERAL_PUSH_NOTIFICATION,
    });

export const createPushNotificationSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.CREATE_GENERAL_PUSH_NOTIFICATION_SUCCESS,
    });

export const createPushNotificationFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CREATE_GENERAL_PUSH_NOTIFICATION_FAILURE,
    });
//# end region
