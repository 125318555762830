import React from 'react';

import { Button, Dropdown, Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import './OperationsButton.less';
import { PLAYER_MODAL_TYPES, PROHIBITION_TYPES } from '../constants';
import { getTranslation } from '../../../../common/helpers/locale';
import { openPlayersModal } from '../PlayersListActions';

const OperationsButton = ({ dispatch, player }) => {
    // ============================================
    // METHODS
    // ============================================

    const openProhibitionsModal = (prohibitionType) =>
        openPlayersModal(dispatch, {
            modalType: PLAYER_MODAL_TYPES.PROHIBITION,
            player,
            prohibitionType,
        });

    const openDepositProhibitionsModal = () => openProhibitionsModal(PROHIBITION_TYPES.DEPOSIT);

    const openWithdrawalProhibitionsModal = () =>
        openProhibitionsModal(PROHIBITION_TYPES.WITHDRAWAL);

    const openLoginProhibitionsModal = () => openProhibitionsModal(PROHIBITION_TYPES.LOGIN);

    const openBonusProhibitionsModal = () => openProhibitionsModal(PROHIBITION_TYPES.BONUS);

    const openChangePasswordForm = () =>
        openPlayersModal(dispatch, {
            modalType: PLAYER_MODAL_TYPES.CHANGE_PASSWORD,
            player,
        });

    // ============================================
    // RENDER
    // ============================================

    const operationsMenu = (
        <Menu>
            <Menu.Item key="0" onClick={openDepositProhibitionsModal}>
                <span>{getTranslation('Deposit Prohibition')}</span>
            </Menu.Item>
            <Menu.Item key="1" onClick={openWithdrawalProhibitionsModal}>
                <span>{getTranslation('Withdrawal Prohibition')}</span>
            </Menu.Item>
            <Menu.Item key="2" onClick={openLoginProhibitionsModal}>
                <span>{getTranslation('Login Prohibition')}</span>
            </Menu.Item>
            <Menu.Item key="3" onClick={openBonusProhibitionsModal}>
                <span>{getTranslation('Bonus Prohibition')}</span>
            </Menu.Item>
            <Menu.Item key="4" onClick={openChangePasswordForm}>
                <span>{getTranslation('Reset Password')}</span>
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={operationsMenu} trigger={['click']}>
            <Button className="btn__green action-btn__operations">
                {getTranslation('Operations')}
                <CaretDownOutlined />
            </Button>
        </Dropdown>
    );
};

export default OperationsButton;
