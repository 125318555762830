import React, { useCallback, useEffect } from 'react';

import { Col, Form, Input, Row, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';

import './DepositCorrectionForm.less';
import { BANK_CARD_STATUSES } from './constants';
import { ButtonGreen, ButtonTransparent } from '../../../common/components/buttons/Buttons';
import { DEPOSIT_CORRECTION_TYPES, TRANSACTION_METHODS } from '../../../common/constants/misc';
import {
    depositCorrectionFormReset,
    depositCorrectionInfoSet,
    performDepositCorrectionOperation,
} from './depositCorrectionActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';
import Loader from '../../../common/components/Loader';

const DepositCorrectionForm = ({
    operationLoading,
    operationSuccess,
    playerDetailsLoading,
    offlineDepositLoading,
    getBankCardLoading,
}) => {
    const [form] = Form.useForm();
    const { resetFields, setFieldsValue } = form;
    const [state, dispatch] = useStore();
    const { depositCorrectionInfo } = getStateProp(state, 'balance.depositCorrection', {});

    // ============================================
    // METHODS
    // ============================================

    const setDepositCorrectionInfo = (inputName, value) => {
        depositCorrectionInfoSet(dispatch, { inputName, value });
    };

    const resetForm = useCallback(() => {
        depositCorrectionFormReset(dispatch);
        resetFields();
    }, [dispatch, resetFields]);

    const submitForm = () => {
        performDepositCorrectionOperation(dispatch, { operation: 'submit' });
    };

    const setDeactivateClassName = () => {
        if (
            getStateProp(depositCorrectionInfo, 'activateStatus') === BANK_CARD_STATUSES.DEACTIVATED
        )
            return 'deactivated-bank-card';
    };

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (operationSuccess) {
            resetForm();
        }
    }, [operationSuccess, resetForm]);

    useEffect(() => {
        setFieldsValue({
            currency: getStateProp(depositCorrectionInfo, 'currencyCode'),
            depositAccountName: getStateProp(depositCorrectionInfo, 'cardHolderName'),
            depositAccountNo: getStateProp(depositCorrectionInfo, 'bankCardNo'),
            depositAmount: getStateProp(depositCorrectionInfo, 'amount'),
            depositBankName: getStateProp(depositCorrectionInfo, 'bankName'),
            name: getStateProp(depositCorrectionInfo, 'realName'),
            remarks: getStateProp(depositCorrectionInfo, 'remarks'),
            transactionMethod: getStateProp(depositCorrectionInfo, 'transactionMethod'),
            transactionType: getStateProp(depositCorrectionInfo, 'transactionType'),
            turnoverRequirement: getStateProp(depositCorrectionInfo, 'turnoverRequirement'),
            userNameLike: getStateProp(depositCorrectionInfo, 'userNameLike'),
        });
    }, [depositCorrectionInfo, setFieldsValue]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form
            form={form}
            name="deposit-correction"
            className="deposit-correction-form"
            onFinish={submitForm}
            initialValues={{
                memberLevel: getStateProp(depositCorrectionInfo, 'memberLevel'),
                transactionNo: getStateProp(depositCorrectionInfo, 'transactionNo'),
                trustLevel: getStateProp(depositCorrectionInfo, 'trustLevel'),
            }}
        >
            <Row>
                <Col span={12} className="deposit-correction__form-column">
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Deposit Transaction Code')}
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="transactionNo">
                                <Input
                                    prefix={<SearchOutlined />}
                                    placeholder={getTranslation('Search for Transaction Number')}
                                    onChange={(e) =>
                                        setDepositCorrectionInfo('transactionNo', e.target.value)
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Currency')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="currency"
                                rules={[
                                    {
                                        message: getTranslation('Please select currency!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    disabled
                                    prefix={
                                        (playerDetailsLoading || offlineDepositLoading) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Deposit Amount')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="depositAmount"
                                rules={[
                                    {
                                        message: getTranslation('Please input deposit amount!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) =>
                                        setDepositCorrectionInfo('amount', e.target.value)
                                    }
                                    prefix={
                                        offlineDepositLoading && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Deposit Account Number')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="depositAccountNo"
                                rules={[
                                    {
                                        message: getTranslation('Please input account number!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) =>
                                        setDepositCorrectionInfo('bankCardNo', e.target.value)
                                    }
                                    prefix={
                                        offlineDepositLoading || getBankCardLoading ? (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        ) : (
                                            <SearchOutlined />
                                        )
                                    }
                                    placeholder={getTranslation('Search for bank')}
                                    className={setDeactivateClassName()}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Deposit Bank Name')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="depositBankName"
                                rules={[
                                    {
                                        message: getTranslation('Please select bank!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) =>
                                        setDepositCorrectionInfo('bankName', e.target.value)
                                    }
                                    className={setDeactivateClassName()}
                                    prefix={
                                        (offlineDepositLoading || getBankCardLoading) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Deposit Account Name')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="depositAccountName"
                                rules={[
                                    {
                                        message: getTranslation('Please input account name!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) =>
                                        setDepositCorrectionInfo('cardHolderName', e.target.value)
                                    }
                                    className={setDeactivateClassName()}
                                    prefix={
                                        (offlineDepositLoading || getBankCardLoading) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Deposit Method')}
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="transactionMethod">
                                <Select
                                    allowClear
                                    onChange={(value) =>
                                        setDepositCorrectionInfo('transactionMethod', value)
                                    }
                                    loading={offlineDepositLoading}
                                >
                                    {_.map(TRANSACTION_METHODS, (method) => (
                                        <Select.Option value={method} key={method}>
                                            {method.toLocaleUpperCase()}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">{getTranslation('Remark')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="remarks"
                                rules={[
                                    {
                                        max: 128,
                                        message: getTranslation('Remark must less than 128 words!'),
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) =>
                                        setDepositCorrectionInfo('remarks', e.target.value)
                                    }
                                    prefix={
                                        offlineDepositLoading && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>

                <Col span={12} className="deposit-correction__form-column">
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Transaction Type')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="transactionType"
                                rules={[
                                    {
                                        message: getTranslation('Please select Transaction Type!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    onChange={(value) =>
                                        setDepositCorrectionInfo('transactionType', value)
                                    }
                                >
                                    {_.map(DEPOSIT_CORRECTION_TYPES, (type) => (
                                        <Select.Option value={type} key={type}>
                                            {type.toLocaleUpperCase()}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Username')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="userNameLike"
                                rules={[
                                    {
                                        message: getTranslation('Please input username!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    prefix={
                                        offlineDepositLoading ? (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        ) : (
                                            <SearchOutlined />
                                        )
                                    }
                                    placeholder={getTranslation('Search for username')}
                                    onChange={(e) =>
                                        setDepositCorrectionInfo('userNameLike', e.target.value)
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Name')} <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        message: getTranslation('Please input name!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) =>
                                        setDepositCorrectionInfo('realName', e.target.value)
                                    }
                                    prefix={
                                        (offlineDepositLoading || playerDetailsLoading) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">{getTranslation('Member Level')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="memberLevel">
                                <Input
                                    disabled
                                    prefix={
                                        (offlineDepositLoading || playerDetailsLoading) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">{getTranslation('Trust Level')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="trustLevel">
                                <Input
                                    prefix={
                                        (offlineDepositLoading || playerDetailsLoading) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Turnover Requirement')}
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="turnoverRequirement">
                                <Input
                                    onChange={(e) =>
                                        setDepositCorrectionInfo(
                                            'turnoverRequirement',
                                            e.target.value,
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Turnover Amount')}
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item>
                                <Input
                                    value={
                                        getStateProp(depositCorrectionInfo, 'turnoverRequirement') *
                                            getStateProp(depositCorrectionInfo, 'amount') || 0
                                    }
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="form__footer-row" justify="end">
                <ButtonGreen
                    label="Submit"
                    className="form__submit-button"
                    loading={operationLoading}
                    htmlType="submit"
                />
                <ButtonTransparent
                    label="Cancel"
                    className="form__reset-button"
                    onClick={resetForm}
                />
            </Row>
        </Form>
    );
};

export default DepositCorrectionForm;
