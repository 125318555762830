import React from 'react';

import { Card, Form } from 'antd';

import { activeBannerFilterDrop, modalClose, modalOpen } from './bannerSortingActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useGetActiveBannerListAsyncEndpoint } from '../../../services/bannerManagementService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import BannerListingModal from './modal/BannerListingModal';
import BannerSortingFilters from './BannerSortingFilters';
import BannerSortingResultTable from './table/BannerSortingResultTable';

const BannerSorting = () => {
    const [state, dispatch] = useStore();
    const modalVisible = getStateProp(state, 'bonus.bannerSorting.modalDetails.visible', false);
    const activeBanners = getStateProp(state, 'bonus.bannerSorting.list', []);

    // ============================================
    // METHODS
    // ============================================

    const openModal = () => modalOpen(dispatch, { activeBanners });

    const closeModal = () => modalClose(dispatch);

    const dropFilters = () => {
        activeBannerFilterDrop(dispatch);
        handleGetActiveBanner();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetActiveBanner] = useGetActiveBannerListAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Banner Sorting')}>
            <Form onFinish={handleGetActiveBanner}>
                <BannerSortingFilters />
                <ActionButtons
                    openModal={openModal}
                    loading={loading}
                    onDropFilters={dropFilters}
                />
            </Form>
            <BannerSortingResultTable loading={loading} />
            <BannerListingModal
                visible={modalVisible}
                onCancel={closeModal}
                refreshTable={handleGetActiveBanner}
            />
        </Card>
    );
};

export default BannerSorting;
