import React from 'react';

import { Modal } from 'antd';

import './TopTenReferrerModal.less';
import { getTranslation } from '../../../../common/helpers/locale';
import TopTenReferrerResultsTable from '../table/TopTenReferrerResultsTable';

const TopTenReferrerModal = ({ visible, onCancel, loading }) => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            className="top-ten-referrer__modal"
            onCancel={onCancel}
            footer={null}
            centered={true}
            width="1500px"
            title={getTranslation('Top 10 Referrer')}
        >
            <TopTenReferrerResultsTable loading={loading} />
        </Modal>
    );
};

export default TopTenReferrerModal;
