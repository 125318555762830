import React from 'react';

import { Card, Form } from 'antd';

import { getTranslation } from '../../../common/helpers/locale';

import { dropFilters, openNotificationModal } from './pushNotificationManagementAction';
import { getStateProp } from '../../../common/helpers/misc';
import {
    useGetPushNotificationInfoAsyncEndpoint,
    useGetPushNotificationInfoByIdAsyncEndpoint,
} from '../../../services/pushNotificationService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import PushNotificationManagementFilters from './PushNotificationManagementFilters';
import PushNotificationModal from './PushNotificationModal';
import PushNotificationResultsTable from './table/PushNotificationResultsTable';

function PushNotificationManagement() {
    const [state, dispatch] = useStore();
    const { modalvisible = false } = getStateProp(
        state,
        'pushNotification.pushNotificationManagement',
    );
    // ============================================
    // METHODS
    // ============================================
    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetPushNotificationInfo();
    };

    const openPushNotificationModal = (id) => {
        openNotificationModal(dispatch, id);
        handleGetPushNotificationInfoById();
    };

    // ============================================
    // HOOKS
    // ============================================
    const [loading, handleGetPushNotificationInfo] = useGetPushNotificationInfoAsyncEndpoint();
    const [
        gettingDataById,
        handleGetPushNotificationInfoById,
    ] = useGetPushNotificationInfoByIdAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================
    return (
        <Card
            title={getTranslation('Push Notification Management')}
            className="create-push-notification"
            // extra={isTestEnvironment() && AspectButton} // Hide from prod
        >
            <Form onFinish={handleGetPushNotificationInfo}>
                <PushNotificationManagementFilters />
                <ActionButtons onDropFilters={fetchAll} />
            </Form>
            <PushNotificationResultsTable
                loading={loading}
                openEditModal={openPushNotificationModal}
            />
            <PushNotificationModal visible={modalvisible} gettingDataById={gettingDataById} />;
        </Card>
    );
}

export default PushNotificationManagement;
