import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../overviewActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';

export const getColumns = () => {
    const columns = [
        {
            dataIndex: 'startDate',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Date'),
        },
        {
            dataIndex: 'newRegister',
            title: getTranslation('New Register'),
        },
        {
            dataIndex: 'activePlayer',
            title: getTranslation('Active Player'),
        },
        {
            dataIndex: 'yesterdayRegisteredActivePlayer',
            title: getTranslation('Yesterday Registered Player Active'),
        },
        {
            dataIndex: 'firstDepositPlayer',
            title: getTranslation('First Deposit Player'),
        },
        {
            dataIndex: 'repeatDepositPlayer',
            title: getTranslation('Repeat Deposit Player'),
        },
        {
            dataIndex: 'depositPlayer',
            title: getTranslation('Deposit Player'),
        },
        {
            dataIndex: 'totalDeposit',
            title: getTranslation('Total Deposit Player'),
        },
        {
            dataIndex: 'firstWithdrawalPlayer',
            title: getTranslation('First Withdrawal Player'),
        },
        {
            dataIndex: 'withdrawalPlayer',
            title: getTranslation('Withdrawal Player'),
        },
        {
            dataIndex: 'totalWithdrawal',
            title: getTranslation('Total Withdrawal'),
        },
        {
            dataIndex: 'referralReward',
            title: getTranslation('Referral Reward'),
        },
        {
            dataIndex: 'bonus',
            title: getTranslation('Bonus'),
        },

        {
            dataIndex: 'cashback',
            title: getTranslation('Cashback'),
        },
        {
            dataIndex: 'rebate',
            title: getTranslation('Rebate'),
        },
        {
            dataIndex: 'dailyCheckIn',
            title: getTranslation('Daily Check-In'),
        },
        {
            dataIndex: 'customerTurnover',
            title: getTranslation('Total Turnover'),
        },
        {
            dataIndex: 'customerWinLoss',
            title: getTranslation('Total Win/Lose'),
        },
        {
            dataIndex: 'companyGrossProfit',
            title: getTranslation('Company Gross Profit'),
        },
        {
            dataIndex: 'balanceAddition',
            title: getTranslation('Balance Addition'),
        },
        {
            dataIndex: 'balanceDeduction',
            title: getTranslation('Balance Deduction'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
