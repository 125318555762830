import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';

import { Button, Col, Dropdown, Menu, Row } from 'antd';
import { CaretDownOutlined, CopyOutlined, FormOutlined } from '@ant-design/icons';
import _ from 'lodash';

import { REBATE_STATUSES } from '../../../constants';
import { ROUTES } from '../../../../../common/constants/routing';
import { getTranslation } from '../../../../../common/helpers/locale';
import { rebateDuplicate } from '../../../setting/rebateSettingActions';
import { setRebateSelected } from '../../rebateManagementActions';
import { useDispatch } from '../../../../../store/StateProvider';
import { useUpdateRebateStatusOperationAsyncEndpoint } from '../../../../../services/rebateService';

const ColumnActionButton = ({ rebateInfo, openEditModal }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [status, setStatus] = useState(null);
    // ============================================
    // METHODS
    // ============================================

    const openEditPage = () => {
        setRebateSelected(dispatch, { id: rebateInfo.id });
        openEditModal();
    };

    const onCopyRebate = () => {
        const {
            code,
            currencies,
            details,
            endDateTime,
            startDateTime,
            isShowOnApp,
            product,
            recurrences,
            turnoverCondition,
        } = rebateInfo;

        const rebateContent = _.map(details, (detail) => {
            const { rebateName, rebateDetails, languageCode, miniDesc } = detail;
            return {
                languageCode,
                miniDesc,
                rebateDetails,
                rebateName,
            };
        });

        const currenciesArray = _.map(currencies, (currency) => {
            const { currencyCode, minRebateThreshold, rebatePercentage } = currency;
            return {
                currencyCode,
                minRebateThreshold,
                rebatePercentage,
            };
        });

        rebateDuplicate(dispatch, {
            code,
            currencies: currenciesArray,
            details: rebateContent,
            endDateTime,
            isShowOnApp,
            product,
            recurrences,
            startDateTime,
            turnoverCondition,
        });

        history.push(ROUTES.DASHBOARD.REBATE.SETTING);
    };

    const onSubmit = (e) => {
        setStatus(e.key);
        handleUpdateRebateStatus();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [updating, handleUpdateRebateStatus] = useUpdateRebateStatusOperationAsyncEndpoint(
        rebateInfo.id,
        status,
    );
    // ============================================
    // RENDER
    // ============================================

    const operationsMenu = (
        <Menu onClick={onSubmit}>
            {_.map(REBATE_STATUSES, (status) => (
                <Menu.Item key={status}>
                    <span>{getTranslation(status)}</span>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Row gutter={15} align="middle">
            <Col>
                <Dropdown
                    overlay={operationsMenu}
                    trigger={['click']}
                    disabled={rebateInfo.rebateStatus === 'Closed'}
                >
                    <Button className="btn__green action-btn__operations" loading={updating}>
                        {getTranslation(rebateInfo.rebateStatus)}
                        <CaretDownOutlined />
                    </Button>
                </Dropdown>
            </Col>
            <Col>
                <FormOutlined
                    className="action-icon"
                    style={{ cursor: 'pointer' }}
                    onClick={openEditPage}
                />
                <CopyOutlined
                    className="action-icon"
                    style={{ cursor: 'pointer' }}
                    onClick={onCopyRebate}
                />
            </Col>
        </Row>
    );
};

export default ColumnActionButton;
