import React, { useEffect, useState } from 'react';

import { CaretDownOutlined } from '@ant-design/icons';
import { Checkbox, Col, Collapse, Row, message } from 'antd';
import _ from 'lodash';

import './IndividualAuthority.less';
import {
    ButtonBlue,
    ButtonGray,
    ButtonGreen,
} from '../../../../../common/components/buttons/Buttons';
import { getStateProp } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import {
    useGetUserIndividualAuthorityAsyncEndpoint,
    useSetUserIndividualAuthorityAsyncEndpoint,
} from '../../../../../services/adminService';
import { useStore } from '../../../../../store/StateProvider';
import { userIndividualAuthoritySetting } from '../../userAuthoritySettingActions';
import Loader from '../../../../../common/components/Loader';
import MenusSelection from './MenusSelection';

const { Panel } = Collapse;

const IndividualAuthority = ({ groupAuthorityLoading }) => {
    const [state, dispatch] = useStore();
    const [isReset, setIsReset] = useState(false);

    const menuList = getStateProp(state, 'administration.menus');
    const selectedGroupMenus = getStateProp(
        state,
        'administration.userAuthoritySetting.selectedInfo.selectedMenus',
        {},
    );
    const selectedInvididualMenus = getStateProp(
        state,
        'administration.userAuthoritySetting.selectedInfo.selectedIndividualMenus',
        {},
    );

    // ============================================
    // METHODS
    // ============================================

    const handleMenusChecked = (name, value) => {
        userIndividualAuthoritySetting(dispatch, { name, value });
    };

    const mergeCustomizer = (objValue, srcValue) => {
        if (_.isArray(objValue)) {
            return _.uniq(_.concat(objValue, srcValue));
        }
    };
    const combinedValue = _.mergeWith(
        _.cloneDeep(selectedGroupMenus),
        selectedInvididualMenus,
        mergeCustomizer,
    );

    const handleResetSetting = () => {
        setIsReset(true);
        handleSetUserIndividualAuthority();
    };

    const selectAll = (e, subMenus, name) => {
        e.stopPropagation();
        userIndividualAuthoritySetting(dispatch, {
            name,
            value: e.target.checked
                ? _.difference(
                      subMenus.map((column) => {
                          return column.menuId;
                      }),
                      selectedGroupMenus[name],
                  )
                : null,
        });
    };

    // ============================================
    // HOOKS
    // ============================================

    const [
        individualAuthorityLoading,
        handleGetUserIndividualAuthority,
    ] = useGetUserIndividualAuthorityAsyncEndpoint();

    const [
        setting,
        handleSetUserIndividualAuthority,
        response,
    ] = useSetUserIndividualAuthorityAsyncEndpoint(isReset);

    useEffect(() => {
        if (response) message.success(getTranslation('Individual Authority Assigned'));
        setIsReset(false);
        handleGetUserIndividualAuthority();
    }, [handleGetUserIndividualAuthority, response]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <div>
            {individualAuthorityLoading || groupAuthorityLoading ? (
                <Loader />
            ) : (
                _.map(menuList, (menus) => (
                    <Collapse
                        accordion
                        key={menus.menuId}
                        className="individual__menus_selection-container"
                    >
                        <Panel
                            key={menus.menuId}
                            header={
                                <>
                                    <Checkbox
                                        onClick={(e) =>
                                            selectAll(e, menus.subMenus, menus.menuName)
                                        }
                                        checked={
                                            _.size(menus.subMenus) ===
                                            _.size(_.compact(combinedValue[menus.menuName]))
                                        }
                                        indeterminate={
                                            _.size(_.compact(combinedValue[menus.menuName])) !==
                                                0 &&
                                            _.size(menus.subMenus) >
                                                _.size(_.compact(combinedValue[menus.menuName]))
                                        }
                                    />
                                    <span className="individual__menu-panel-header">
                                        {menus.menuName}
                                    </span>
                                </>
                            }
                            className={
                                selectedGroupMenus[menus.menuName]
                                    ? 'individual__menu_selected-group-title'
                                    : 'individual__menu_title'
                            }
                            showArrow={false}
                            extra={<CaretDownOutlined />}
                        >
                            <MenusSelection
                                subMenus={menus.subMenus}
                                title={menus.menuName}
                                selectedGroupAccess={selectedGroupMenus}
                                selectAction={handleMenusChecked}
                                selectedValue={combinedValue}
                            />
                        </Panel>
                    </Collapse>
                ))
            )}

            <Row className="form__footer-row" justify="end">
                <Col span={12}>
                    <ButtonBlue
                        className="rowaction-button individual__reset-to-group-button"
                        loading={setting}
                        onClick={handleResetSetting}
                    >
                        {getTranslation('Reset to Group Setting')}
                    </ButtonBlue>
                </Col>
                <ButtonGreen
                    type="primary"
                    className="rowaction-button"
                    onClick={handleSetUserIndividualAuthority}
                    loading={setting}
                >
                    {getTranslation('Save')}
                </ButtonGreen>
                <ButtonGray type="primary" className="rowaction-button">
                    {getTranslation('Cancel')}
                </ButtonGray>
            </Row>
        </div>
    );
};

export default IndividualAuthority;
