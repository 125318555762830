import * as axios from 'axios';

import { API } from '../../common/constants/api';
import { getCurrentUser, isUserTokenExpired, setUserCookies } from '../../common/helpers/api';

const { VERSION, IDENTITY, TOKENS } = API;

export const refreshTokenInterceptor = async (request) => {
    if (request.headers.Authorization && isUserTokenExpired()) {
        const refreshResult = await axios.default.post(
            `${VERSION.V1}${IDENTITY.ROOT}${TOKENS}/${getCurrentUser().refreshToken}/refresh`,
            {
                token: '',
            },
        );
        setUserCookies(refreshResult.data);
        request.headers.Authorization = `Bearer ${refreshResult.data.accessToken}`;
    }
    return request;
};
