import { useCallback, useEffect, useState } from 'react';

import { API } from '../common/constants/api';
import { clearError } from '../common/actions/errorActions';
import {
    fileDelete,
    fileDeleteFailure,
    fileDeleteSuccess,
    fileListGet,
    fileListGetFailure,
    fileListGetSuccess,
} from '../components/file-system/fileSystemActions';
import { getStateProp } from '../common/helpers/misc';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, FILE_SYSTEM } = API;

export function useGetFileListAsyncEndpoint(isOnline) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const fileSystem = getStateProp(state, 'fileSystem', {});
    const { pageNo, pageSize } = getStateProp(fileSystem, 'paging');
    const refreshTime = getStateProp(fileSystem, 'operation.refreshTime');
    const refreshTimePause = getStateProp(fileSystem, 'operation.refreshTimePause');

    // ============================================
    // METHODS
    // ============================================

    const handleGetFileList = useCallback(() => {
        fileListGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'POST',
        options: {
            data: {
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${FILE_SYSTEM.LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            fileListGetSuccess(dispatch, {
                fetchData: handleGetFileList,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetFileList, response]);

    useEffect(() => {
        let timeInterval;
        if (response || isOnline)
            timeInterval = setTimeout(() => handleGetFileList(), refreshTime * 1000);
        if (refreshTimePause) clearTimeout(timeInterval);
        return () => clearTimeout(timeInterval);
    }, [handleGetFileList, isOnline, refreshTime, refreshTimePause, response]);

    useEffect(() => {
        handleGetFileList();
    }, [handleGetFileList, pageNo, pageSize]);

    const errorMsg = useError(error, fileListGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetFileList];
}

export function useDeleteFilesAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const selectedFiles = getStateProp(state, 'fileSystem.selectedFiles', []);

    // ============================================
    // METHODS
    // ============================================

    const handleDeleteFiles = () => {
        fileDelete(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                ids: selectedFiles,
            },
        },
        trigger,
        url: `${VERSION.V1}${FILE_SYSTEM.DELETE_FILE}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            fileDeleteSuccess(dispatch);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, fileDeleteFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleDeleteFiles, response];
}
