import { message } from 'antd';

import { MAX_TABLE_ROW_SELECT_LIMIT } from '../../../common/constants/misc';
import { getTranslation } from '../../../common/helpers/locale';

export const ACTIONS = {
    COLUMNS_SELECT: 'EWALLET_TRANSFER_COLUMNS_SELECT',
    CONFIRM_MODAL_CLOSE: 'CONFIRM_MODAL_CLOSE',
    CONFIRM_MODAL_OPEN: 'CONFIRM_MODAL_OPEN',
    CONFIRM_REJECT_MODAL_CLOSE: 'CONFIRM_REJECT_MODAL_CLOSE',
    CONFIRM_REJECT_MODAL_OPEN: 'CONFIRM_REJECT_MODAL_OPEN',
    EWALLET_TRANSFER_GET: 'EWALLET_TRANSFER_GET',
    EWALLET_TRANSFER_GET_FAILURE: 'EWALLET_TRANSFER_GET_FAILURE',
    EWALLET_TRANSFER_GET_SUCCESS: 'EWALLET_TRANSFER_GET_SUCCESS',
    FILTERS_DROP_ALL: 'EWALLET_TRANSFER_FILTERS_DROP_ALL',
    FILTER_SET: 'EWALLET_TRANSFER_FILTER_SET',
    OPERATION: 'EWALLET_TRANSFER_OPERATION',
    OPERATION_FAILURE: 'EWALLET_TRANSFER_OPERATION_FAILURE',
    OPERATION_SUCCESS: 'EWALLET_TRANSFER_OPERATION_SUCCESS',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'EWALLET_TRANSFER_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'EWALLET_TRANSFER_TABLE_PAGE_SIZE_CHANGE',
    },
    RECORD_SELECT: 'EWALLET_TRANSFER_RECORD_SELECT',
    REFRESH_TIME_PAUSE: 'EWALLET_TRANSFER_REFRESH_TABLE_TIME_PAUSE',
    REFRESH_TIME_SET: 'EWALLET_TRANSFER_REFRESH_TABLE_TIME_SET',
    VERIFY_MODAL_CLOSE: 'VERIFY_MODAL_CLOSE',
    VERIFY_MODAL_OPEN: 'VERIFY_MODAL_OPEN',
};

export const ewalletTransfersGet = (dispatch) =>
    dispatch({
        type: ACTIONS.EWALLET_TRANSFER_GET,
    });

export const ewalletTransfersGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.EWALLET_TRANSFER_GET_SUCCESS,
    });

export const ewalletTransfersGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.EWALLET_TRANSFER_GET_FAILURE,
    });

export const performEwalletTransferOperation = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OPERATION,
    });

export const openVerifyModal = (dispatch) =>
    dispatch({
        type: ACTIONS.VERIFY_MODAL_OPEN,
    });

export const closeVerifyModal = (dispatch) =>
    dispatch({
        type: ACTIONS.VERIFY_MODAL_CLOSE,
    });

export const openConfirmModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_MODAL_OPEN,
    });

export const closeConfirmModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_MODAL_CLOSE,
    });

export const ewalletTransferOperationSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.OPERATION_SUCCESS,
    });

export const ewalletTransfersOperationFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OPERATION_FAILURE,
    });

export const selectRecord = (dispatch, payload) =>
    payload.length <= MAX_TABLE_ROW_SELECT_LIMIT
        ? dispatch({
              payload,
              type: ACTIONS.RECORD_SELECT,
          })
        : message.warning(
              getTranslation('You cannot select more than {limit} rows', {
                  limit: MAX_TABLE_ROW_SELECT_LIMIT,
              }),
          );

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const ewalletTransfersFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const openConfirmRejectModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_REJECT_MODAL_OPEN,
    });

export const closeConfirmRejectModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_REJECT_MODAL_CLOSE,
    });

export const setRefreshTableTime = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.REFRESH_TIME_SET,
    });
};

export const pauseRefreshTableTime = (dispatch) => {
    dispatch({
        type: ACTIONS.REFRESH_TIME_PAUSE,
    });
};

export const selectColumns = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.COLUMNS_SELECT,
    });
