import React, { useEffect, useState } from 'react';

import { Col, Form, Input, Modal, Row } from 'antd';

import './VerifyModal.less';
import { ButtonGray, ButtonGreen, ButtonRed } from '../../../../common/components/buttons/Buttons';
import { MODIFICATION_ACTION_TYPES } from '../../../../common/constants/api';
import { closeVerifyModal } from '../pendingModificationsActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { useDispatch } from '../../../../store/StateProvider';
import { useVerifyModificationAsyncEndpoint } from '../../../../services/playerService';

const VerifyModal = ({ onCancel, getModifications, visible, modification }) => {
    const { userName, type } = modification;

    const dispatch = useDispatch();

    const [remark, setRemark] = useState({
        errorMsg: null,
        validateStatus: 'validating',
        value: null,
    });

    // ============================================
    // METHODS
    // ============================================

    const validateRemark = (value) => {
        if (!value) {
            return {
                errorMsg: getTranslation('Remark is required!'),
                validateStatus: 'error',
            };
        } else {
            return { errorMsg: null, validateStatus: 'success' };
        }
    };

    const handleRemarkChange = (e) => {
        setRemark({ ...validateRemark(e.target.value), value: e.target.value });
    };

    const handleApproveModification = () => approveModification();

    const handleRejectModification = () => rejectModification();

    // ============================================
    // HOOKS
    // ============================================

    const [
        approvePending,
        approveModification,
        modificationApproved,
    ] = useVerifyModificationAsyncEndpoint(remark.value, MODIFICATION_ACTION_TYPES.APPROVE);

    const [
        rejectPending,
        rejectModification,
        modificationRejected,
    ] = useVerifyModificationAsyncEndpoint(remark.value, MODIFICATION_ACTION_TYPES.REJECT);

    useEffect(() => {
        closeVerifyModal(dispatch);
        getModifications();
        setRemark({
            errorMsg: null,
            validateStatus: 'validating',
            value: null,
        });
    }, [modificationApproved, modificationRejected, getModifications, dispatch]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            title={getTranslation(type)}
            onCancel={onCancel}
            centered
            className="modification-verification__modal"
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
        >
            <Row className="modification-verification__modal__group">
                <Col span={4}>
                    <span className="modification-verification__modal__label">
                        {getTranslation('Username')} :
                    </span>
                </Col>
                <Col span={20}>
                    <span className="modification-verification__modal__value">{userName}</span>
                </Col>
            </Row>
            <Row align="middle" className="modification-verification__modal__group">
                <Col span={4}>
                    <span className="modification-verification__modal__label">
                        {getTranslation('Action')} :
                    </span>
                </Col>
                <Col span={20}>
                    <Row>
                        <ButtonGreen
                            loading={approvePending}
                            label={getTranslation('Approve')}
                            onClick={handleApproveModification}
                            disabled={remark.validateStatus !== 'success'}
                        />
                        <ButtonRed
                            loading={rejectPending}
                            label={getTranslation('Reject')}
                            onClick={handleRejectModification}
                            disabled={remark.validateStatus !== 'success'}
                        />
                    </Row>
                </Col>
            </Row>
            <Row className="modification-verification__modal__group">
                <Col span={4}>
                    <span className="modification-verification__modal__label">
                        {getTranslation('Remark')}
                        <span style={{ color: 'red' }}>*</span> :
                    </span>
                </Col>
                <Col span={20}>
                    <Form style={{ width: '100%' }}>
                        <Form.Item validateStatus={remark.validateStatus} help={remark.errorMsg}>
                            <Input.TextArea
                                value={remark.value}
                                onChange={handleRemarkChange}
                                rows="3"
                            />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Modal>
    );
};

export default VerifyModal;
