import React from 'react';

import { Row } from 'antd';

import {
    EwalletAccountNameFilter,
    EwalletAccountIdFilter,
    EwalletIdFilter,
    EwalletNameFilter,
    EwalletSetupTypeFilter,
} from '../filters';
import { CurrencyFilter } from '../../../common/components/filters';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';

const EwalletSetupFilters = ({ setFilter, ewalletAccount }) => {
    return (
        <Row justify="start" align="middle" className="filters-container">
            <EwalletAccountNameFilter
                onFilterChange={setFilter}
                value={getStateProp(ewalletAccount, 'filters.accountHolderName')}
                placeholder={getTranslation('Account Holder Name')}
            />
            <EwalletAccountIdFilter
                onFilterChange={setFilter}
                value={getStateProp(ewalletAccount, 'filters.accountId')}
                name="accountId"
            />
            <EwalletIdFilter
                value={getStateProp(ewalletAccount, 'filters.eWalletId')}
                onFilterChange={setFilter}
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(ewalletAccount, 'filters.currencyCode')}
                name="currencyCode"
            />
            <EwalletNameFilter
                value={getStateProp(ewalletAccount, 'filters.eWalletName')}
                onFilterChange={setFilter}
            />
            <EwalletSetupTypeFilter
                onFilterChange={setFilter}
                value={getStateProp(ewalletAccount, 'filters.type')}
            />
            {/*     <PassageFilter
                value={getStateProp(ewalletAccount, 'filters.passage')}
                onFilterChange={setFilter}
            /> */}
        </Row>
    );
};

export default EwalletSetupFilters;
