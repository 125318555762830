import { useCallback, useEffect, useState } from 'react';

import { API } from '../common/constants/api';
import { clearError } from '../common/actions/errorActions';
import {
    cryptoMerchantConfigurationRecordGet,
    cryptoMerchantConfigurationRecordGetFailure,
    cryptoMerchantConfigurationRecordGetSuccess,
    cryptoMerchantConfigurationUpdate,
    cryptoMerchantConfigurationUpdateFailure,
    cryptoMerchantConfigurationUpdateSuccess,
} from '../components/payments/crypto-merchant-configuration/cryptoMerchantConfigurationActions';
import { getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import { message } from 'antd';
import { useDispatch, useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, PAYMENTS } = API;

export function useCryptoMerchantConfigurationOperationAsyncEndpoint(crytoMerchantSetupInputs) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const cryptoMerchant = getStateProp(state, 'payments.cryptoMerchant');
    const depositMemberLevel = getStateProp(crytoMerchantSetupInputs, 'depositMemberLevel', '');
    let withdrawMemberLevel = getStateProp(crytoMerchantSetupInputs, 'withdrawMemberLevel', '');
    const depositTrustLevel = getStateProp(crytoMerchantSetupInputs, 'depositTrustLevel', '');
    let withdrawTrustLevel = getStateProp(crytoMerchantSetupInputs, 'withdrawTrustLevel', '');
    const depositShow = getStateProp(crytoMerchantSetupInputs, 'depositShow', false);
    const withdrawShow = getStateProp(crytoMerchantSetupInputs, 'withdrawShow', false);

    withdrawMemberLevel =
        withdrawMemberLevel === null ||
        withdrawMemberLevel === '' ||
        withdrawMemberLevel === undefined
            ? getStateProp(cryptoMerchant, 'withdrawMemberLevel')
            : withdrawMemberLevel;
    withdrawTrustLevel =
        withdrawTrustLevel === null || withdrawTrustLevel === '' || withdrawTrustLevel === undefined
            ? getStateProp(cryptoMerchant, 'withdrawTrustLevel')
            : withdrawTrustLevel;

    const depositList = getStateProp(cryptoMerchant, 'depositList', []);

    const withdrawList = getStateProp(cryptoMerchant, 'withdrawList', []);

    // ============================================
    // HOOKS
    // ============================================

    const handleCryptoMerchantSetup = useCallback(() => {
        cryptoMerchantConfigurationUpdate(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                depositList,
                depositMemberLevel: depositMemberLevel ? depositMemberLevel.toString() : '',
                depositShow: depositShow,
                depositTrustLevel: depositTrustLevel ? depositTrustLevel.toString() : '',
                remark: crytoMerchantSetupInputs.remark,
                withdrawList,
                withdrawMemberLevel: withdrawMemberLevel ? withdrawMemberLevel.toString() : '',
                withdrawShow: withdrawShow,
                withdrawTrustLevel: withdrawTrustLevel ? withdrawTrustLevel.toString() : '',
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.CRYPTO_MERCHANT.RECORD}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            cryptoMerchantConfigurationUpdateSuccess(dispatch);
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, cryptoMerchantConfigurationUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleCryptoMerchantSetup];
}

export function useGetCryptoMerchantConfigurationRecordAsyncEndpoint() {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    const handleCryptoMerchantRecordGet = useCallback(() => {
        cryptoMerchantConfigurationRecordGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${PAYMENTS.CRYPTO_MERCHANT.RECORD}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            cryptoMerchantConfigurationRecordGetSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, cryptoMerchantConfigurationRecordGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleCryptoMerchantRecordGet];
}
