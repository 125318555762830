export const API = {
    ADMIN: {
        CURRENCY: {
            EXCHANGE_RATE_LIST: '/backoffice/ExchangeRate/query',
            FILTER_OPTIONS: '/backoffice/ExchangeRate',
            SAVE: '/backoffice/ExchangeRate/save',
        },
        USER_AUTHORITY: {
            AUTHORITY_LIST: '/Permissions/user-access-groups',
            INDIVIDUAL_AUTHORITY: '/Permissions/users',
        },
    },
    AFFILIATE: {
        APPROVE: '/approve',
        GENERATE: '/MyAffiliate/affiliate/payout/generate',
        HISTORY: '/MyAffiliate/affiliate/payout/generate/history',
        PAYOPUT: '/MyAffiliate/affiliate/payout',
        REJECT: '/reject',
    },
    ANNOUNCEMENT: {
        ANNOUNCEMENT_LIST: '/Announcement',
        PERSONAL_MESSAGE_LIST: '/PersonalMessage',
    },
    BALANCE_MANAGEMENT: '/Wallets/adjustments',
    BANNER: {
        ROOT: '/Banners',
    },
    BET: {
        BET_LIST: '/backoffice/betlist',
        BET_LIST_EXPORT: '/backoffice/betlist/export',
        DETAIL_URL: '/platforms/Bets/url',
        TURNOVER_CHECK: '/platforms/Bets/turnover/query',
    },
    BONUS: {
        BATCH_REJECT: '/bonus/batch-reject',
        BATCH_STATUS: '/bonus/batch-status',
        BATCH_SUBMIT: '/bonus/batch-submit',
        CALCULATE_TURN_OVER: '/bonus/turnover',
        CHECK_REPEAT: '/bonus/repeat',
        IMPORT: '/bonus/import',
        IP: '/bonus/ip',
        LIST: '/bonus/all',
        REDEEM: '/bonus/ip/bonus-redeem',
        ROOT: '/bonus',
    },
    BONUS_CATEGORY: {
        ROOT: '/category',
        SORT: '/category/sort-order',
    },
    CRYPTO: {
        CURRENCY_RATE: '/GGPay/rates',
        DEPOSIT_INFO: '/GGPay/bo/deposit',
        WITHDRWAL_INFO: '/GGPay/bo/withdraw',
    },
    FILE_SYSTEM: {
        DELETE_FILE: '/Reporting/export/queue/delete',
        LIST: '/Reporting/export/my-queue',
    },
    HOT_EVENTS: {
        CREATE: '/Platforms/chatroom/create',
        HOT_EVENTS_LIST: '/Platforms/hotevents',
        UPDATE_STATUS: '/Platforms/chatroom/update-status',
    },
    IDENTITY: {
        CHANGE_CURRENCY: '/identity/operator/allowcurrency/update',
        CHANGE_PASSWORD: '/identity/me/password',
        CREATE_PLAYER: '/identity/assist-sign-up/player',
        GROUP_ACCESS: '/Permissions/access-groups',
        OPERATORS: '/identity/operators',
        PASSWORD: '/password',
        PERMISSION: '/Permissions',
        ROOT: '/identity',
        SIGN_UP: '/sign-up/operator',
        VERIFY_USERNAME: '/identity/verify-usernames',
    },
    MENUS: {
        ROOT: '/Menus',
    },
    NEWS: {
        ROOT: '/news',
    },
    NOTIFICATION: {
        ROOT: '/Notification',
        SEND: '/Notification/send',
        TOPIC: '/pushy/topics',
    },
    OPERATIONS: '/Operations',
    PAYMENTS: {
        BANK_ACCOUNT: {
            BANK_LIST: '/Payments/banks',
            CARD_LIST: '/Payments/cards',
            STATEMENT: '/Payments/cards/accountStatement',
        },
        CRYPTO_DEPOSITS: {
            ROOT: '/GGPay/bo/deposits',
            SINGLE: '/GGPay/bo/deposit',
        },
        CRYPTO_DEPOSIT_CORRECTION: '/GGPay/bo/deposit/correction',
        CRYPTO_MERCHANT: {
            RECORD: '/GGPay/bo/settings',
        },
        CRYPTO_WITHDRAWALS: {
            ROOT: '/GGPay/bo/withdraws',
            SINGLE: '/GGPay/bo/withdraw',
        },
        CRYPTO_WITHDRWAL_CORRECTION: '/GGPay/bo/withdraw/correction',
        DEPOSIT_CORRECTION: '/Payments/offlineDepositCorrections',
        EWALLET: {
            EWALLET_ACCOUNT_LIST: '/Payments/eWallets/accounts',
            EWALLET_LIST: '/Payments/eWallets',
            EWALLET_TRANSFERS: '/Payments/eWalletTransfers',
        },
        EWALLET_CORRECTION: '/Payments/eWalletTransferCorrections',
        EWALLET_WITHDRAWALS: {
            ROOT: '/Payments/ewallets/withdrawals',
        },
        MERCHANTS: {
            MERCHANT_LIST: '/Payments/gateways',
            MERCHANT_SETUP: '/Payments/gateways',
        },
        MERCHANT_DEPOSITS: {
            ROOT: '/Payments/deposits',
            VERIFICATION: '/Payments/deposits/pending-orders',
        },
        OFFLINE_DEPOSITS: {
            ROOT: '/Payments/offlineDeposits',
            VERIFICATION: '/Payments/offlineDeposits/pending-orders',
        },
        ONLINE_DEPOSITS: '/Payments/deposits',
        ROOT: '/Payments',
        TRANSACTION_AMOUNT_SETTING: {
            EWALLET: '/Payments/eWallets/settings',
            ROOT: '/Payments/settings',
        },
        TRANSFERS: '/Payments/transfers',
        WITHDRAWALS: {
            ROOT: '/Payments/withdrawals',
            VERIFICATION: '/Payments/withdrawals/pending-orders',
        },
    },
    PLATFORM: {
        LIST: '/platforms/products/query',
    },
    PLAYERS: {
        BANK_CARDS: '/Players/bankcards',
        CHANGE_PASSWORD: '/Players/password',
        CHANNELS: '/backoffice/channelinquiry/query',
        DANGER_LEVEL: {
            DELETE_DANGER_LEVEL_TAG: '/Players/dangerLevel/tag/delete',
            GET: '/Players/dangerLevel/setting/query',
            GET_DANGER_LEVEL_FILTER_LSIT: '/Players/dangerLevel/tag/filter/get',
            GET_DANGER_LEVEL_TAG: '/Players/dangerLevel/tag/get',
            UPDATE: '/Players/dangerLevel/setting/update',
            UPDATE_DANGER_LEVEL_TAG: '/Players/dangerLevel/tag/update',
        },
        E_WALLET: '/Players/eWalletAccounts',
        GAME_ACCESS: {
            GET_PLAYER_GAME_ACCESS: '/Players/game-access/query',
            UPDATE_PLAYER_GAME_ACCESS: '/Players/game-access/mass-update',
        },
        GET_AFFILIATE_CURRENCY_CODE: '/monetary',
        GET_BY_USERNAME: '/Players/username',
        IP_ADDRESS: '/Players/ipAddress',
        LIST: '/backoffice/players/query',
        MODIFICATION: '/Players/modification',
        MODIFICATION_LIST: '/Players/modification/query',
        PROHIBITIONS: {
            CREATE: '/Players/prohibition',
            LIST: '/Players/prohibition/query',
        },
        REGISTRATION_DETAILS: '/Players/regDetail',
        REMARKS: {
            CREATE: '/Players/remark',
            LIST: '/Players/remark/query',
        },
        ROOT: '/Players',
        SEARCH_IP: '/Players/searchIp',
        SESSIONS: {
            ALL: '/Players/session/query',
            BY_USER_ID: '/Players/session/userId/query',
        },
        STATUS_HISTORY: '/Players/remarkStatus/query',
        UPDATE_PLAYER: '/Players/operator/',
        VERIFY_PHONE_NO: '/Players/mobile/verification',
        VERIFY_USERNAMES: '/Players/notification-verify-usernames',
        WALLETS: '/Platforms/Wallets/userdetails',
    },
    PROMOTIONS: {
        ACTIVE_CODES: '/promotions/codes',
        ALL_BONUS_CODES: '/promotions/bonus-codes',
        NAMES: '/promotions/names',
        QUERY: '/promotions/query',
        ROOT: '/promotions',
        SORT: '/promotions/sort-order',
    },
    REBATE: {
        FILTER_LIST: '/Rebate/init',
        ROOT: '/Rebate',
        TRANSACTION: '/Rebate/transactions',
    },
    REFERRAL: {
        ACTIVE_REFERRAL: '/Referral/code',
        REFEREE_REPORT: '/Referral/referee-report',
        REFERRAL: '/Referral',
        REFERRAL_INQUIRY: '/Referral/inquiry',
        REFERRAL_REPORT: '/Referral/referral-report',
        REFERRER_REPORT: '/Referral/referrer-report',
        STATUS: '/Referral/status',
    },
    REFRESH: '/refresh',
    REPORTS: {
        OVERVIEW: '/backoffice/overviewreport/query',
        PLAYER_WALLET_BALANCE: '/backoffice/companyReport/playerWalletBalance',
        PLAYER_WIN_LOSS: {
            FILTER_LIST: '/backoffice/playerwinloss',
            QUERY: '/backoffice/playerwinloss/query',
        },
    },
    REWARDS_MANAGEMENT: {
        ACTIVE_DAILY_LOGIN: '/LoginBonus/code',
        DAILY_LOGIN_BONUS: '/LoginBonus',
    },
    SABA: {
        PLAYERS: '/saba/players',
    },
    SIGN_IN: '/sign-in',
    TOKENS: '/access-tokens',
    TRANSACTION_HISTORY: {
        AFFILIATE_RECORDS: '/Payments/histories/affiliatePayouts',
        CRYPTO_DEPOSITS_RECORDS: '/GGPay/bo/deposits/query',
        CRYPTO_WITHDRAWALS_RECORDS: '/GGPay/bo/withdraws/query',
        DEPOSIT_RECORDS: '/Payments/histories/deposits',
        MYAFFILIATE_PAYOUTS: '/Payments/histories/myAffiliatePayouts',
        REBATE_RECORDS: '/Payments/histories/rebatePayouts',
        REFERRAL_RECORDS: '/Payments/histories/referralPayouts',
        REWARDS_RECORDS: '/Payments/histories/dailyLoginPayouts',
        TRANSACTION_RECORDS: '/Payments/histories',
        TURNOVER_RECORDS: '/backoffice/ETOTransaction/query',
        VIP_RECORDS: '/Payments/histories/vipPayouts',
        WALLET_TRANFERS: '/Payments/histories/transfers',
        WITHDRAWAL_RECORDS: '/Payments/histories/withdrawals',
    },
    TRANSACTION_MONITORING: '/Payments/monitorings',
    VERSION: {
        V1: '/api/v1',
        V2: '/api/v2',
    },
    VIP: {
        HISTORY: '/Players/vip/history/query',
        SETUP: {
            LIST: '/players/VIP/setting/query',
            UPDATE: '/players/VIP/setting/edit',
        },
    },
    WALLET: '/wallet',
    WALLETS: '/Wallets',
};

export const TABLE_PAGE_SIZE_DEFAULT = 10;
export const TABLE_PAGE_NO_DEFAULT = 1;
export const TABLE_PAGE_SIZE_OPTIONS = ['5', '10', '20', '30', '50'];
export const TABLE_PAGE_SIZE_OPTIONS_500 = ['5', '50', '100', '300', '500'];
export const TABLE_PAGE_SIZE_OPTIONS_1000 = ['5', '50', '100', '300', '500', '1000'];
export const TABLE_PAGE_SIZE_OPTIONS_2000 = ['5', '50', '100', '300', '500', '1000', '2000'];

export const TRANSFER_DIRECTIONS = {
    IN: '/transferIn',
    OUT: '/transferOut',
};

export const OPERATION_STATES = {
    COMPLETED: 'completed',
    PENDING: 'pending',
    REJECTED: 'rejected',
};

export const MODIFICATION_ACTION_TYPES = {
    APPROVE: '/approve',
    REJECT: '/reject',
};
