import React from 'react';

import { Table } from 'antd';

import { MERCHANT_DEPOSIT_TABLE_COLUMNS } from '../constants';
import { getColumns, getPaginationOptions, getRowSelectionProps } from './MerchantsTableMeta.jsx';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const MerchantsResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const merchantDeposits = getStateProp(state, 'payments.merchantDeposits');
    const tableData = getStateProp(merchantDeposits, 'list', []);
    const paging = getStateProp(merchantDeposits, 'paging', {});
    const selectedTransactionId = getStateProp(merchantDeposits, 'selectedTransactionId', []);
    const selectedColumns = getStateProp(
        merchantDeposits,
        'selectedColumns',
        MERCHANT_DEPOSIT_TABLE_COLUMNS,
    );

    return (
        <Table
            loading={loading}
            size="small"
            align="left"
            scroll={{ x: true }}
            pagination={getPaginationOptions(paging, dispatch)}
            rowSelection={getRowSelectionProps(dispatch, selectedTransactionId)}
            bordered
            columns={getColumns(dispatch, selectedColumns)}
            dataSource={tableData}
            rowKey={(record) => record.transactionId}
        />
    );
};

export default MerchantsResultsTable;
