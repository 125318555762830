import { ACTIONS } from './overviewActions';
import { REPORT_TYPES } from '../constants';

export default function overviewReducer(
    overview = {
        filters: {
            baseCurrency: 'MYR',
            currency: 'ALL',
            reportType: REPORT_TYPES.DAILY,
        },
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.OVERVIEW_INIT:
            return {
                ...overview,
                filters: {
                    ...overview.filters,
                    ...payload,
                },
            };

        case ACTIONS.FILTER_SET:
            return {
                ...overview,
                filters: {
                    ...overview.filters,
                    [payload.filter]: payload.value,
                },
            };

        case ACTIONS.FILTERS_DROP_ALL:
            return {
                ...overview,
                filters: {
                    baseCurrency: 'MYR',
                    currency: 'ALL',
                    reportType: REPORT_TYPES.DAILY,
                    ...payload,
                },
            };

        case ACTIONS.OVERVIEW_GET_SUCCESS:
            return {
                ...overview,
                ...payload,
            };

        case ACTIONS.OVERVIEW_FILTER_DATE_TYPE_CHANGE:
            return {
                ...overview,
                filters: {
                    ...overview.filters,
                    ...payload,
                },
            };
        case ACTIONS.OVERVIEW_GET:
        default:
            return overview;
    }
}
