import React from 'react';

import { Checkbox, Col, Form } from 'antd';
import _ from 'lodash';

const CheckboxInput = ({ options, name }) => {
    return (
        <Form.Item name={name}>
            <Checkbox.Group style={{ display: 'flex', flexFlow: 'row wrap' }}>
                {_.map(options, (option) => {
                    return (
                        <Col span={8} key={option}>
                            <Checkbox value={option}>{option}</Checkbox>
                        </Col>
                    );
                })}
            </Checkbox.Group>
        </Form.Item>
    );
};

export default CheckboxInput;
