import { ACTIONS } from './bannerSortingActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function bannerSortingReducer(
    bannerSorting = {
        filters: {},
        modalDetails: {
            filters: {},
            paging: {
                pageNo: TABLE_PAGE_NO_DEFAULT,
                pageSize: TABLE_PAGE_SIZE_DEFAULT,
                totalRecordCount: 0,
            },
            selectedRecords: [],
            visible: false,
        },
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.BANNER_MANAGEMENT_MODAL_OPEN:
            return {
                ...bannerSorting,
                modalDetails: {
                    ...bannerSorting.modalDetails,
                    selectedRecords: payload.activeBanners,
                    visible: true,
                },
            };

        case ACTIONS.BANNER_MANAGEMENT_MODAL_CLOSE:
            return {
                ...bannerSorting,
                modalDetails: {
                    ...bannerSorting.modalDetails,
                    selectedRecords: [],
                    visible: false,
                },
            };

        case ACTIONS.BANNER_MANAGEMENT_SELECT:
            return {
                ...bannerSorting,
                modalDetails: {
                    ...bannerSorting.modalDetails,
                    selectedRecords: payload,
                },
            };

        case ACTIONS.AVAILABLE_BANNERS_FILTERS_SET:
            return {
                ...bannerSorting,
                modalDetails: {
                    ...bannerSorting.modalDetails,
                    filters: {
                        ...bannerSorting.modalDetails.filters,
                        [payload.filter]: payload.value,
                    },
                },
            };

        case ACTIONS.AVAILABLE_BANNERS_FILTERS_DROP:
            return {
                ...bannerSorting,
                modalDetails: {
                    ...bannerSorting.modalDetails,
                    filters: {},
                },
            };

        case ACTIONS.ACTIVE_BANNERS_FILTERS_SET:
            return {
                ...bannerSorting,
                filters: {
                    ...bannerSorting.filters,
                    [payload.filter]: payload.value,
                },
            };

        case ACTIONS.ACTIVE_BANNERS_FILTERS_DROP:
            return {
                ...bannerSorting,
                filters: {},
            };

        case ACTIONS.AVAILABLE_BANNERS_GET_SUCCESS:
        case ACTIONS.BANNER_SORTING_UPDATE:
            return {
                ...bannerSorting,
                modalDetails: {
                    ...bannerSorting.modalDetails,
                    ...payload,
                },
            };

        case ACTIONS.PAGINATION.AVAILABLE_BANNER_TABLE_PAGE_CHANGE:
            return {
                ...bannerSorting,
                modalDetails: {
                    ...bannerSorting.modalDetails,
                    paging: {
                        ...bannerSorting.modalDetails.paging,
                        pageNo:
                            payload > bannerSorting.modalDetails.paging.pageCount
                                ? bannerSorting.modalDetails.paging.pageCount - 1
                                : payload,
                    },
                },
            };

        case ACTIONS.PAGINATION.AVAILABLE_BANNER_TABLE_PAGE_SIZE_CHANGE:
            const AVAILABLE_BANNER_MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...bannerSorting,
                modalDetails: {
                    ...bannerSorting.modalDetails,

                    paging: {
                        pageNo: 1,
                        pageSize:
                            payload < 1
                                ? TABLE_PAGE_SIZE_DEFAULT
                                : payload > AVAILABLE_BANNER_MAX_PER_PAGE
                                ? AVAILABLE_BANNER_MAX_PER_PAGE
                                : payload,
                    },
                },
            };

        case ACTIONS.PAGINATION.ACTIVE_BANNER_TABLE_PAGE_CHANGE:
            return {
                ...bannerSorting,
                paging: {
                    ...bannerSorting.paging,
                    pageNo:
                        payload > bannerSorting.paging.pageCount
                            ? bannerSorting.paging.pageCount - 1
                            : payload,
                },
            };

        case ACTIONS.PAGINATION.ACTIVE_BANNER_TABLE_PAGE_SIZE_CHANGE:
            const ACTIVE_BANNER_MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...bannerSorting,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > ACTIVE_BANNER_MAX_PER_PAGE
                            ? ACTIVE_BANNER_MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.ACTIVE_BANNERS_GET_SUCCESS:
            return {
                ...bannerSorting,
                ...payload,
            };

        case ACTIONS.AVAILABLE_BANNERS_GET:
        case ACTIONS.ACTIVE_BANNERS_GET:
        default:
            return bannerSorting;
    }
}
