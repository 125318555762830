export const ACTIONS = {
    BANNER_CREATE: 'BANNER_CREATE',
    BANNER_CREATE_FAILURE: 'BANNER_CREATE_FAILURE',
    BANNER_CREATE_SUCCESS: 'BANNER_CREATE_SUCCESS',
    BANNER_DUPLICATE: 'BANNER_DUPLICATE',
    BANNER_DUPLICATE_BY_LANGUAGE: 'BANNER_DUPLICATE_BY_LANGUAGE',
    BANNER_FORM_CLEAR: 'BANNER_FORM_CLEAR',
    BANNER_LANGUAGE_DROP: 'BANNER_LANGUAGE_DROP',
    BANNER_LANGUAGE_SET: 'BANNER_LANGUAGE_SET',
    SET_BANNER_INFO: 'SET_BANNER_INFO',
    SET_BANNER_INFO_BY_LANGUAGE: 'SET_BANNER_INFO_BY_LANGUAGE',
};

//#region Create Banner
export const bannerCreateSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_CREATE_SUCCESS,
    });

export const bannerCreateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_CREATE_FAILURE,
    });

export const bannerCreate = (dispatch) =>
    dispatch({
        type: ACTIONS.BANNER_CREATE,
    });
//#endregion

//#region Banner Language Setting
export const setBannerLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_LANGUAGE_SET,
    });

export const dropBannerLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_LANGUAGE_DROP,
    });
//#endregion

//#region Banner Info Update
export const setBannerInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.SET_BANNER_INFO,
    });

export const setBannerInfoByLanguage = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.SET_BANNER_INFO_BY_LANGUAGE,
    });

export const bannerFormClear = (dispatch) =>
    dispatch({
        type: ACTIONS.BANNER_FORM_CLEAR,
    });
//#endregion

//#region Banner Duplicate
export const bannerDuplicate = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_DUPLICATE,
    });

export const bannerDuplicateByLanguage = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_DUPLICATE_BY_LANGUAGE,
    });
//#endregion
