import { Redirect, Switch } from 'react-router-dom';
import React from 'react';

import {
    ChannelInquiry,
    DangerLevel,
    ModificationsHistory,
    PendingModifications,
    PlayerDetails,
    PlayersList,
    RegistrationDetails,
    UserSessions,
} from './index';
import { ROUTES } from '../../common/constants/routing';
import PrivateRoute from '../../common/components/PrivateRoute';

const Players = () => {
    return (
        <Switch>
            <PrivateRoute
                exact
                path={ROUTES.DASHBOARD.PLAYERS.PLAYERS_LIST}
                component={PlayersList}
            />
            <PrivateRoute
                exact
                path={ROUTES.DASHBOARD.PLAYERS.PLAYERS_LIST_MASKED}
                component={PlayersList}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.PLAYERS.REGISTRATION_DETAILS}
                component={RegistrationDetails}
            />
            <PrivateRoute path={ROUTES.DASHBOARD.PLAYERS.CHANNELS} component={ChannelInquiry} />
            <PrivateRoute path={ROUTES.DASHBOARD.PLAYERS.SESSIONS} component={UserSessions} />
            <PrivateRoute path={ROUTES.DASHBOARD.PLAYERS.DANGER_LEVEL} component={DangerLevel} />
            <PrivateRoute
                path={ROUTES.DASHBOARD.PLAYERS.PENDING_MODIFICATIONS}
                component={PendingModifications}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.PLAYERS.MODIFICATION_HISTORY}
                component={ModificationsHistory}
            />
            {/* PLAYER_DETAILS root MUST be last match before Redirect as it contains id*/}
            <PrivateRoute
                path={ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.ROOT_MASKED}
                component={PlayerDetails}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.ROOT}
                component={PlayerDetails}
            />
            <Redirect to={ROUTES.DASHBOARD.PLAYERS.PLAYERS_LIST} />
        </Switch>
    );
};

export default Players;
