import _ from 'lodash';

import { ACTIONS } from './announcementCreateActions';

export default function announcementCreateReducer(
    announcementCreate = {
        announcementItems: [],
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.ANNNOUNCEMENT_INIT:
            return {
                ...announcementCreate,
                ...payload,
            };

        case ACTIONS.ANNOUNCEMENT_DRAFT_LANGUAGE_SET:
            return {
                ...announcementCreate,
                announcementItems: [
                    ...announcementCreate.announcementItems,
                    {
                        content: null,
                        currency: payload.currency,
                        languageCode: payload.langCode,
                        title: null,
                    },
                ],
            };

        case ACTIONS.ANNOUNCEMENT_DRAFT_LANGUAGE_DROP:
            return {
                ...announcementCreate,
                announcementItems: _.filter(announcementCreate.announcementItems, (o) => {
                    return o.currency === payload.currency
                        ? o.languageCode !== payload.langCode
                        : o;
                }),
            };

        case ACTIONS.ANNOUNCEMENT_DRAFT_CONTENT_SET: {
            const currentAnnouncementItems = announcementCreate.announcementItems;
            for (let i = 0, l = currentAnnouncementItems.length; i < l; i++) {
                if (
                    currentAnnouncementItems[i].languageCode === payload.languageCode &&
                    currentAnnouncementItems[i].currency === payload.currency
                ) {
                    currentAnnouncementItems[i][payload.dataKey] = payload.data;
                }
            }

            return {
                ...announcementCreate,
                announcementItems: currentAnnouncementItems,
            };
        }

        case ACTIONS.ANNOUNCEMENT_DRAFT_INFO_SET:
            return {
                ...announcementCreate,
                [payload.name]: payload.value,
            };

        case ACTIONS.ANNOUNCEMENT_DRAFT_RESET:
            return {
                announcementItems: [],
                ...payload,
            };

        case ACTIONS.ANNOUNCEMENT_CURRENCY_SET:
            return {
                ...announcementCreate,
                announcementItems: payload,
            };

        default:
            return announcementCreate;
    }
}
