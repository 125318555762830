export const ACTIONS = {
    FILTERS_DROP_ALL: 'REFERRER_REPORT_FILTERS_DROP_ALL',
    FILTER_SET: 'REFERRER_REPORT_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'REFERRER_REPORT_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'REFERRER_REPORT_TABLE_PAGE_SIZE_CHANGE',
    },
    REFEREE_PAGINATION: {
        TABLE_PAGE_CHANGE: 'REFEREE_REPORT_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'REFEREE_REPORT_TABLE_PAGE_SIZE_CHANGE',
    },
    REFEREE_REPORT_GET: 'REFEREE_REPORT_GET',
    REFEREE_REPORT_GET_FAILURE: 'REFEREE_REPORT_GET_FAILURE',
    REFEREE_REPORT_GET_SUCCESS: 'REFEREE_REPORT_GET_SUCCESS',
    REFERRER_REPORT_GET: 'REFERRER_REPORT_GET',
    REFERRER_REPORT_GET_FAILURE: 'REFERRER_REPORT_GET_FAILURE',
    REFERRER_REPORT_GET_SUCCESS: 'REFERRER_REPORT_GET_SUCCESS',
};

export const referrerReportGet = (dispatch) =>
    dispatch({
        type: ACTIONS.REFERRER_REPORT_GET,
    });

export const referrerReportGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRER_REPORT_GET_FAILURE,
    });

export const referrerReportGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRER_REPORT_GET_SUCCESS,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const refereeReportGet = (dispatch) =>
    dispatch({
        type: ACTIONS.REFEREE_REPORT_GET,
    });

export const refereeReportGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFEREE_REPORT_GET_FAILURE,
    });

export const refereeReportGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFEREE_REPORT_GET_SUCCESS,
    });

export const changeRefereefPageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFEREE_PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changeRefereePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFEREE_PAGINATION.TABLE_PAGE_CHANGE,
    });

export const referrerReportFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });
