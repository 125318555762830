import React from 'react';

import { ButtonTransparent } from '../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../common/helpers/locale';
import { onRemarkModalOpen } from '../transactionMonitoringActions';
import { useDispatch } from '../../../store/StateProvider';

const ViewRemarkButton = ({ operatorRemark, applicantRemark, auditorRemark }) => {
    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ============================================

    const openRemarkModal = () =>
        onRemarkModalOpen(dispatch, { applicantRemark, auditorRemark, operatorRemark });

    // ============================================
    // RENDER
    // ============================================

    return (
        <ButtonTransparent className="hyperlink-button" onClick={openRemarkModal}>
            {getTranslation('View')}
        </ButtonTransparent>
    );
};

export default ViewRemarkButton;
