export const ACTIONS = {
    BANK_CARD_DETAILS_GET: 'DEPOSIT_CORRECTION_BANK_CARD_DETAILS_GET',
    BANK_CARD_DETAILS_GET_FAILURE: 'DEPOSIT_CORRECTION_BANK_CARD_DETAILS_GET_FAILURE',
    BANK_CARD_DETAILS_GET_SUCCESS: 'DEPOSIT_CORRECTION_BANK_CARD_DETAILS_GET_SUCCESS',
    DEPOSIT_CORRECTION_INFO_SET: 'DEPOSIT_CORRECTION_INFO_SET',
    FORM_RESET: 'DEPOSIT_CORRECTION_FORM_RESET',
    OPERATION: 'DEPOSIT_CORRECTION_OPERATION',
    OPERATION_FAILURE: 'DEPOSIT_CORRECTION_OPERATION_FAILURE',
    OPERATION_SUCCESS: 'DEPOSIT_CORRECTION_OPERATION_SUCCESS',
    PLAYER_DETAILS_GET: 'DEPOSIT_CORRECTION_PLAYER_DETAILS_GET',
    PLAYER_DETAILS_GET_FAILURE: 'DEPOSIT_CORRECTION_PLAYER_DETAILS_GET_FAILURE',
    PLAYER_DETAILS_GET_SUCCESS: 'DEPOSIT_CORRECTION_PLAYER_DETAILS_GET_SUCCESS',
    TRANSACTION_GET: 'DEPOSIT_CORRECTION_TRANSACTION_GET',
    TRANSACTION_GET_FAILURE: 'DEPOSIT_CORRECTION_TRANSACTION_GET_FAILURE',
    TRANSACTION_GET_SUCCESS: 'DEPOSIT_CORRECTION_TRANSACTION_GET_SUCCESS',
};

export const depositCorrectionInfoSet = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.DEPOSIT_CORRECTION_INFO_SET,
    });
};

export const depositCorrectionFormReset = (dispatch) => {
    dispatch({ type: ACTIONS.FORM_RESET });
};

export const playerDetailsGetSuccess = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.PLAYER_DETAILS_GET_SUCCESS,
    });
};

export const playerDetailsGetFailure = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.PLAYER_DETAILS_GET_FAILURE,
    });
};

export const bankCardDetailsGetSuccess = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.BANK_CARD_DETAILS_GET_SUCCESS,
    });
};

export const bankCardDetailsGetFailure = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.BANK_CARD_DETAILS_GET_FAILURE,
    });
};

export const offlineDepositDetailsGetSuccess = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.TRANSACTION_GET_SUCCESS,
    });
};

export const offlineDepositDetailsGetFailure = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.TRANSACTION_GET_FAILURE,
    });
};

export const performDepositCorrectionOperation = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.OPERATION,
    });
};

export const performDepositCorrectionOperationSuccess = (dispatch) => {
    dispatch({
        type: ACTIONS.OPERATION_SUCCESS,
    });
};

export const depositCorrectionOperationFailure = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.OPERATION_FAILURE,
    });
};
