import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { NEWS_STATUSES } from '../constants';
import { getTranslation } from '../../../common/helpers/locale';

function NewsStatusSelect({ value, onStatusChange, disabled }) {
    return (
        <Select
            value={value}
            onChange={(value) => onStatusChange(value)}
            allowClear
            placeholder="News Status"
            className="filter"
            disabled={disabled}
        >
            {_.map(NEWS_STATUSES, (status) => (
                <Select.Option key={status} value={status}>
                    {getTranslation(status).toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
}

export default NewsStatusSelect;
