import channelsReducer from './channel-inquiry/channelsReducer';
import dangerLevelReducer from './danger-level/dangerLevelReducer';
import modificationsHistoryReducer from './modifications-history/modificationsHistoryReducer';
import pendingModificationsReducer from './pending-modifications/pendingModificationsReducer';
import playerDetailsReducer from './player-details/playerDetailsReducer';
import playersListReducer from './players-list/playersListReducer';
import registrationDetailsReducer from './registration-details/registrationDetailsReducer';
import userSessionsReducer from './user-sessions/userSessionsReducer';

export default function playersReducer(players = {}, action) {
    const {
        channels,
        modificationsHistory,
        pendingModifications,
        playerDetails,
        playersList,
        registrationDetails,
        userSessions,
        dangerLevel,
    } = players;

    return {
        channels: channelsReducer(channels, action),
        dangerLevel: dangerLevelReducer(dangerLevel, action),
        modificationsHistory: modificationsHistoryReducer(modificationsHistory, action),
        pendingModifications: pendingModificationsReducer(pendingModifications, action),
        playerDetails: playerDetailsReducer(playerDetails, action),
        playersList: playersListReducer(playersList, action),
        registrationDetails: registrationDetailsReducer(registrationDetails, action),
        userSessions: userSessionsReducer(userSessions, action),
    };
}
