import { ACTIONS } from './referralReportActions';
import {
    REFEREE_DEPOSIT_CONDITION,
    REFEREE_WINLOSS_CONDITION,
    REFEREE_WITHDRAWAL_CONDITION,
    REFERRAL_CONVERSTION_RATE_CONDITION,
    TOTAL_REFEREE_CONDITION,
} from '../constants';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function referralReportReducer(
    referralReport = {
        filters: {
            refereeDeposit: { condition: REFEREE_DEPOSIT_CONDITION.GREATER.value },
            refereeWinLoss: { condition: REFEREE_WINLOSS_CONDITION.GREATER.value },
            refereeWithdrawal: { condition: REFEREE_WITHDRAWAL_CONDITION.GREATER.value },
            referralConversionRate: {
                condition: REFERRAL_CONVERSTION_RATE_CONDITION.GREATER.value,
            },
            totalReferee: { condition: TOTAL_REFEREE_CONDITION.GREATER.value },
        },
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        resetPageNo: false,
    },

    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.FILTER_SET:
            return {
                ...referralReport,
                filters: {
                    ...referralReport.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };
        case ACTIONS.FILTERS_DROP_ALL:
            return {
                ...referralReport,
                filters: {
                    refereeDeposit: { condition: REFEREE_DEPOSIT_CONDITION.GREATER.value },
                    refereeWinLoss: { condition: REFEREE_WINLOSS_CONDITION.GREATER.value },
                    refereeWithdrawal: { condition: REFEREE_WITHDRAWAL_CONDITION.GREATER.value },
                    referralConversionRate: {
                        condition: REFERRAL_CONVERSTION_RATE_CONDITION.GREATER.value,
                    },
                    totalReferee: { condition: TOTAL_REFEREE_CONDITION.GREATER.value },
                },
                resetPageNo: true,
            };

        case ACTIONS.REFERRAL_REPORT_GET_SUCCESS:
            return {
                ...referralReport,
                ...payload,
            };

        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...referralReport,
                paging: {
                    ...referralReport.paging,
                    pageNo:
                        payload > referralReport.paging.pageCount
                            ? referralReport.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...referralReport,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.REFERRAL_REPORT_GET:
            const pageNo = referralReport.resetPageNo
                ? TABLE_PAGE_NO_DEFAULT
                : referralReport.paging.pageNo;
            return {
                ...referralReport,
                paging: {
                    ...referralReport.paging,
                    pageNo: pageNo,
                },
                resetPageNo: false,
            };

        case ACTIONS.TOP_TEN_REFERRER_GET_SUCCESS:
            return {
                ...referralReport,
                topTenReferrer: { ...payload },
            };

        default:
            return referralReport;
    }
}
