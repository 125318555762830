export const ACTIONS = {
    EWALLET_CORRECTION_INFO_SET: 'EWALLET_CORRECTION_INFO_SET',
    EWALLET_CORRECTION_MULTI_INFO_SET: 'EWALLET_CORRECTION_MULTI_INFO_SET',
    EWALLET_DETAILS_GET: 'EWALLET_CORRECTION_EWALLET_DETAILS_GET',
    EWALLET_DETAILS_GET_FAILURE: 'EWALLET_CORRECTION_EWALLET_DETAILS_GET_FAILURE',
    EWALLET_DETAILS_GET_SUCCESS: 'EWALLET_CORRECTION_EWALLET_DETAILS_GET_SUCCESS',
    FORM_RESET: 'EWALLET_CORRECTION_FORM_RESET',
    OPERATION: 'EWALLET_CORRECTION_OPERATION',
    OPERATION_FAILURE: 'EWALLET_CORRECTION_OPERATION_FAILURE',
    OPERATION_SUCCESS: 'EWALLET_CORRECTION_OPERATION_SUCCESS',
    PLAYER_DETAILS_GET: 'EWALLET_CORRECTION_PLAYER_DETAILS_GET',
    PLAYER_DETAILS_GET_FAILURE: 'EWALLET_CORRECTION_PLAYER_DETAILS_GET_FAILURE',
    PLAYER_DETAILS_GET_SUCCESS: 'EWALLET_CORRECTION_PLAYER_DETAILS_GET_SUCCESS',
    TRANSACTION_GET: 'EWALLET_CORRECTION_TRANSACTION_GET',
    TRANSACTION_GET_FAILURE: 'EWALLET_CORRECTION_TRANSACTION_GET_FAILURE',
    TRANSACTION_GET_SUCCESS: 'EWALLET_CORRECTION_TRANSACTION_GET_SUCCESS',
};

export const ewalletCorrectionInfoSet = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.EWALLET_CORRECTION_INFO_SET,
    });
};

export const ewalletCorrectionMultiInfoSet = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.EWALLET_CORRECTION_MULTI_INFO_SET,
    });
};

export const ewalletCorrectionFormReset = (dispatch) => {
    dispatch({ type: ACTIONS.FORM_RESET });
};

export const playerDetailsGetSuccess = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.PLAYER_DETAILS_GET_SUCCESS,
    });
};

export const playerDetailsGetFailure = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.PLAYER_DETAILS_GET_FAILURE,
    });
};

export const ewalletDetailsGetSuccess = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.EWALLET_DETAILS_GET_SUCCESS,
    });
};

export const ewalletDetailsGetFailure = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.EWALLET_DETAILS_GET_FAILURE,
    });
};

export const ewalletTransactionDetailsGetSuccess = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.TRANSACTION_GET_SUCCESS,
    });
};

export const ewalletTransactionDetailsGetFailure = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.TRANSACTION_GET_FAILURE,
    });
};

export const performEwalletCorrectionOperation = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.OPERATION,
    });
};

export const performEwalletCorrectionOperationSuccess = (dispatch) => {
    dispatch({
        type: ACTIONS.OPERATION_SUCCESS,
    });
};

export const ewalletCorrectionOperationFailure = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.OPERATION_FAILURE,
    });
};
