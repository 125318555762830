import React, { useEffect } from 'react';

import { Modal, Row } from 'antd';

import { ButtonGray, ButtonGreen } from '../../../../../common/components/buttons/Buttons';
import { closeGroupAccessRecordDeleteModal } from '../../groupAuthoritySettingActions';
import { getStateProp } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useDeleteGroupAccessRecordAsyncEndpoint } from '../../../../../services/groupAuthoritySettingService';
import { useStore } from '../../../../../store/StateProvider';

const DeleteGroupAuthorityRecordModal = () => {
    const [state, dispatch] = useStore();

    const groupAuthoritySetting = getStateProp(state, 'administration.groupAuthoritySetting');
    const refreshTable = getStateProp(groupAuthoritySetting, 'fetchData');
    const visible = getStateProp(groupAuthoritySetting, 'deleteModalVisible');
    const accessGroupId = getStateProp(groupAuthoritySetting, 'deleteAccessGroupId');

    // ============================================
    // METHODS
    // ============================================

    const deleteGroupAccessRecord = () => handleDeleteGroupAccessRecord();
    const onCancel = () => closeGroupAccessRecordDeleteModal(dispatch);

    // ============================================
    // HOOKS
    // ============================================

    const [
        deleting,
        response,
        handleDeleteGroupAccessRecord,
    ] = useDeleteGroupAccessRecordAsyncEndpoint(accessGroupId);

    useEffect(() => {
        if (response) {
            refreshTable();
            closeGroupAccessRecordDeleteModal(dispatch);
        }
    }, [response, dispatch, refreshTable]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={getTranslation('Confirm to Delete?')}
            centered
            closable={false}
            className="group-authority-record__confirm-delete-modal"
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        label={getTranslation('Confirm')}
                        onClick={deleteGroupAccessRecord}
                        loading={deleting}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
        >
            {getTranslation(
                "Be noticed that, you can't get back the record once you delete the group access record.",
            )}
        </Modal>
    );
};

export default DeleteGroupAuthorityRecordModal;
