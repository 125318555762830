import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './DeactivatedEwalletAccountTableMeta';
import { getStateProp } from '../../../../../common/helpers/misc';
import { useStore } from '../../../../../store/StateProvider';

const DeactivatedEwalletAccountResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const deactivatedEwallet = getStateProp(state, 'payments.deactivatedEwalletAccount');
    const tableData = getStateProp(deactivatedEwallet, 'list', []);
    const paging = getStateProp(deactivatedEwallet, 'paging', {});

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns(paging)}
            loading={loading}
            size="small"
            align="left"
            dataSource={tableData}
            rowKey={(record) => record.accountId}
            pagination={getPaginationOptions(paging, dispatch)}
            scroll={{ x: true }}
            bordered
        />
    );
};

export default DeactivatedEwalletAccountResultsTable;
