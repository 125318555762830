import React, { useCallback, useEffect, useState } from 'react';

import { Col, Form, Input, Row, message } from 'antd';

import './ChangePasswordForm.less';
import { ButtonGreen, ButtonTransparent } from '../../common/components/buttons/Buttons';
import { getTranslation } from '../../common/helpers/locale';
import { useChangePasswordOperation } from '../../services/userService';

const ChangePasswordForm = () => {
    const [form] = Form.useForm();
    const { resetFields } = form;
    const [formValues, setFormValues] = useState({});
    // ============================================
    // METHODS
    // ============================================

    const submitForm = (values) => {
        setFormValues(values);
        if (values.newPassword.length < 7) {
            message.error(getTranslation('New Password cannot less than 7!'));
            return;
        }

        if (
            values.newPassword.localeCompare(values.confirmNewPassword, 'en', {
                sensitivity: 'case',
            }) === 0
        ) {
            // new password and confirm new password is same
            handleChangePassword();
        } else {
            message.error(getTranslation('New Password and Confirm New Password is not the same!'));
        }
    };

    const resetForm = useCallback(() => {
        resetFields();
    }, [resetFields]);

    // ============================================
    // HOOKS
    // ============================================

    const [operationLoading, operationSuccess, handleChangePassword] = useChangePasswordOperation(
        formValues,
    );

    useEffect(() => {
        if (operationSuccess) {
            resetForm();
        }
    }, [operationSuccess, resetForm]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form
            form={form}
            name="change-password"
            className="change-password-form"
            onFinish={submitForm}
        >
            <Row>
                <Col span={12} className="change-password__form-column">
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">{getTranslation('Old Password')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="currentPassword"
                                rules={[
                                    {
                                        message: getTranslation('Please input old password!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col span={12} className="change-password__form-column">
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">{getTranslation('New Password')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="newPassword"
                                rules={[
                                    {
                                        message: getTranslation('Please input new password!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Confirm New Password')}
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="confirmNewPassword"
                                rules={[
                                    {
                                        message: getTranslation(
                                            'Please input confirm new password!',
                                        ),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="form__footer-row" justify="end">
                <ButtonGreen
                    label="Submit"
                    className="form__submit-button"
                    loading={operationLoading}
                    htmlType="submit"
                />
                <ButtonTransparent
                    label="Cancel"
                    className="form__reset-button"
                    onClick={resetForm}
                />
            </Row>
        </Form>
    );
};

export default ChangePasswordForm;
