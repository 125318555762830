import React from 'react';

import moment from 'moment';

import {
    ButtonBlue,
    ButtonGreen,
    ButtonTransparent,
} from '../../../../common/components/buttons/Buttons';
import { VERIFY_STATUSES } from '../constants';
import { getCurrentUser } from '../../../../common/helpers/api';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { onVerifyModalOpen } from '../manualManagementActions';
import { useStore } from '../../../../store/StateProvider';

const OperationButton = ({ bonus, dispatch, isBatchUpload }) => {
    const [state] = useStore();

    const location = getStateProp(state, 'timezone.location');
    const applicantName = getStateProp(bonus, 'applicantName');
    const userName = getStateProp(
        state,
        'user.userName',
        getStateProp(getCurrentUser(), 'claims.userName', 'Anonymus'), //Temp fix
    );
    const approvalStatus = getStateProp(bonus, 'approvalStatus');

    // ============================================
    // METHODS
    // ============================================

    const getExpiredDate = (days) => {
        return moment()
            .tz(location)
            .add(days, 'd')
            .endOf('day')
            .utc()
            .toISOString();
    };

    const updateEstimateExpiredDate = () => {
        const expiredDate = getStateProp(bonus, 'expiredDate');
        const duration = getStateProp(bonus, 'duration');
        const approvalStatus = getStateProp(bonus, 'approvalStatus');

        bonus.expiredDateWritable =
            approvalStatus === 'Rejected' ? '' : expiredDate || getExpiredDate(duration);
    };

    const openVerifyModal = () => {
        updateEstimateExpiredDate();
        onVerifyModalOpen(dispatch, bonus);
    };

    // ============================================
    // RENDER
    // ============================================

    return !isBatchUpload && approvalStatus === VERIFY_STATUSES.PENDING ? (
        !applicantName ? (
            <ButtonBlue className="action-btn__verify" onClick={openVerifyModal}>
                {getTranslation('Update')}
            </ButtonBlue>
        ) : applicantName !== userName ? (
            <ButtonGreen className="action-btn__verify" onClick={openVerifyModal}>
                {getTranslation('Verify')}
            </ButtonGreen>
        ) : (
            <ButtonTransparent className="action-btn__verify" onClick={openVerifyModal}>
                {getTranslation('View')}
            </ButtonTransparent>
        )
    ) : (
        <ButtonTransparent className="action-btn__verify" onClick={openVerifyModal}>
            {`${getTranslation('Verified')} ${bonus.operatorName}`}
        </ButtonTransparent>
    );
};

export default OperationButton;
