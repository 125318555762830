import React from 'react';

import { Row } from 'antd';

import { DangerLevelFilter } from '../../../common/components/filters';
import { filterSet } from './dangerLevelActions';
import { getStateProp } from '../../../common/helpers/misc';
// import { getTranslation } from '../../../common/helpers/locale';
import {
    BonusAutomationFilter,
    DailyLoginFilter,
    DepositFilter,
    RebateGeneratorFilter,
    VipPayoutFilter,
    WithdrawFilter,
} from './filters';
import { useStore } from '../../../store/StateProvider';

const DangerLevelFilters = () => {
    const [state, dispatch] = useStore();

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => filterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <DangerLevelFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.dangerLevel.filters.dangerLevels')}
                name="dangerLevels"
                dangerLevelFilterList={getStateProp(
                    state,
                    'players.dangerLevel.dangerLevelFilterList',
                    [],
                )}
            />
            <BonusAutomationFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.dangerLevel.filters.bonusAutomation')}
                name="bonusAutomation"
            />
            <RebateGeneratorFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.dangerLevel.filters.rebateGenerator')}
                name="rebateGenerator"
            />
            <DailyLoginFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.dangerLevel.filters.dailyLogin')}
                name="dailyLogin"
            />
            <DepositFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.dangerLevel.filters.deposit')}
                name="deposit"
            />
            <WithdrawFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.dangerLevel.filters.withdrawal')}
                name="withdrawal"
            />
            <VipPayoutFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.dangerLevel.filters.vipPayout')}
                name="vipPayout"
            />
        </Row>
    );
};

export default DangerLevelFilters;
