import React from 'react';

import { Card, Form } from 'antd';

import { API } from '../../../common/constants/api';
import { dropFilters } from './vipHistoryActions';
import { getColumns } from './table/VipHistoryTableMeta';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useExportAsyncEndpoint } from '../../../services/hooks/useExport';
import { useGetVipHistoryAsyncEndpoint } from '../../../services/vipService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import VipHistoryFilters from './VipHistoryFilters';
import VipHistoryResultsTable from './table/VipHistoryResultsTable';

const { VIP } = API;

const VipHistory = () => {
    const [state, dispatch] = useStore();
    const vipHistory = getStateProp(state, 'vip.vipHistory', {});
    const filters = getStateProp(vipHistory, 'filters');
    const { totalRecordCount } = getStateProp(vipHistory, 'paging', {});
    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetVipHistory();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetVipHistory] = useGetVipHistoryAsyncEndpoint();

    const [handleExportAction] = useExportAsyncEndpoint({
        dataMeta: getColumns(),
        filters,
        method: 'POST',
        subject: getTranslation('Vip Records'),
        totalRecordCount,
        url: `${VIP.HISTORY}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Vip History')}>
            <Form onFinish={handleGetVipHistory}>
                <VipHistoryFilters />
                <ActionButtons onDropFilters={fetchAll} onExport={handleExportAction} />
            </Form>
            <VipHistoryResultsTable loading={loading} />
        </Card>
    );
};

export default VipHistory;
