import React from 'react';

import { ButtonGray } from '../../../../../common/components/buttons/Buttons';
import { Col, Modal, Row } from 'antd';
import { getTranslation } from '../../../../../common/helpers/locale';

const ManualManagementRemarkModal = ({ visible, onCancel, remarks }) => {
    const { operatorRemark, applicantRemark } = remarks;
    return (
        <Modal
            title={getTranslation('Remarks')}
            centered
            visible={visible}
            closable={false}
            onCancel={onCancel}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
        >
            <Row>
                <Col span={10}>
                    <label>{getTranslation('Applicant Remark')}</label>
                </Col>
                <Col span={10}>{applicantRemark}</Col>
            </Row>
            <Row>
                <Col span={10}>
                    <label>{getTranslation('Operator Remark')}</label>
                </Col>
                <Col span={10}>{operatorRemark}</Col>
            </Row>
        </Modal>
    );
};

export default ManualManagementRemarkModal;
