import React from 'react';

import { ButtonYellow } from '../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../common/helpers/locale';

const CreatePlayerButton = ({ onCreateNewPlayer }) => {
    return (
        <ButtonYellow type="primary" onClick={onCreateNewPlayer}>
            {getTranslation('Create New Member')}
        </ButtonYellow>
    );
};

export default CreatePlayerButton;
