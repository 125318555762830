import React from 'react';

import _ from 'lodash';

import { LANGUAGES } from '../../../../common/constants/localization';
import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../rebateManagementActions';
import { findTitleByLanguage, getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';
import ColumnActionButton from './operations/ColumnActionButton';

export const getColumns = (dispatch, openEditModal, langCode) => {
    const columns = [
        {
            dataIndex: 'code',
            title: getTranslation('Rebate Code'),
        },
        {
            dataIndex: 'name',
            filters: _.map(LANGUAGES, (language) => {
                return {
                    text: language.name,
                    value: language.value,
                };
            }),
            render: (value, record) => {
                return findTitleByLanguage(record.details, langCode, 'rebateName');
            },
            title: getTranslation('Rebate Name'),
        },
        {
            dataIndex: 'startDateTime',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Start Date'),
        },
        {
            dataIndex: 'endDateTime',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('End Date'),
        },
        {
            dataIndex: 'isShowOnApp',
            render: (value) => <span>{value ? getTranslation('Yes') : getTranslation('No')}</span>,
            title: getTranslation('Display in Client App'),
        },
        {
            dataIndex: 'createdAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Created Date'),
        },
        {
            dataIndex: 'createdBy',
            title: getTranslation('Created By'),
        },
        {
            dataIndex: 'activatedName',
            title: getTranslation('Activated/Approved By'),
        },
        {
            dataIndex: 'updatedBy',
            title: getTranslation('Last Modified By'),
        },
        {
            dataIndex: 'updatedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Last Modified Date'),
        },
        {
            dataIndex: 'action',
            render: (value, record) => (
                <ColumnActionButton rebateInfo={record} openEditModal={openEditModal} />
            ),
            title: getTranslation('Action'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
