import React from 'react';

import _ from 'lodash';

import { getTranslation } from '../../../../common/helpers/locale';

export const tableBodyWrapper = (props, tableData) => {
    const platforms = _.map(tableData, (data) => {
        return data.platforms;
    });

    return (
        tableData.length > 0 && (
            <tbody {...props}>
                <tr className="products-table-row">
                    <td className="text-bold">{getTranslation('Product').toLocaleUpperCase()}</td>
                    {_.map(tableData, (data, key) => (
                        <td key={key} colSpan={data.platforms.length} className="text-bold">
                            {data.sportTypeGroupName}
                        </td>
                    ))}
                </tr>
                <tr className="platform-table-row">
                    <td className="text-bold">{getTranslation('Platforms').toLocaleUpperCase()}</td>
                    {_.map(platforms, (platform) => {
                        return _.map(platform, (detail, key) => (
                            <td key={key}>{detail.platformName}</td>
                        ));
                    })}
                </tr>
                <tr className="turnover-table-row">
                    <td className="text-bold">{getTranslation('Turnover').toLocaleUpperCase()}</td>
                    {_.map(platforms, (platform) => {
                        return _.map(platform, (detail, key) => (
                            <td key={key}>{detail.turnover}</td>
                        ));
                    })}
                </tr>
                <tr className="effective-turnover-table-row">
                    <td className="text-bold">
                        {getTranslation('Effective Turnover').toLocaleUpperCase()}
                    </td>
                    {_.map(platforms, (platform) => {
                        return _.map(platform, (detail, key) => (
                            <td key={key}>{detail.effectiveTurnover}</td>
                        ));
                    })}
                </tr>
            </tbody>
        )
    );
};
