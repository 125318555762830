import React from 'react';

import { Row } from 'antd';

import '../styles/RowActionButton.less';
import { ANNOUNCEMENT_STATUSES } from '../../constants';
import { ButtonGray, ButtonRed } from '../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../common/helpers/locale';
import { openMessageDeleteModal, setMessageSelected } from './personalMessageActions';
import { useDispatch } from '../../../../store/StateProvider';

const RowActionButtons = ({ message }) => {
    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ============================================

    const openViewModal = () => setMessageSelected(dispatch, message);

    const onOpenDeleteModal = () => {
        openMessageDeleteModal(dispatch, { messageDelete: message });
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row span={7} align="middle" className="row-buttons__container">
            <ButtonGray type="primary" className="rowaction-button" onClick={openViewModal}>
                {getTranslation('View')}
            </ButtonGray>

            {message.pmStatus !== ANNOUNCEMENT_STATUSES.DELETED && (
                <ButtonRed type="primary" className="rowaction-button" onClick={onOpenDeleteModal}>
                    {getTranslation('Delete')}
                </ButtonRed>
            )}
        </Row>
    );
};

export default RowActionButtons;
