export const ACTIONS = {
    EWALLET_CONFIGURATION_FORM_SUBMIT: 'EWALLET_CONFIGURATION_FORM_SUBMIT',
    EWALLET_CONFIGURATION_FORM_SUBMIT_FAILURE: 'EWALLET_CONFIGURATION_FORM_SUBMIT_FAILURE',
    EWALLET_CONFIGURATION_FORM_SUBMIT_SUCCESS: 'EWALLET_CONFIGURATION_FORM_SUBMIT_SUCCESS',
    EWALLET_CONFIGURATION_GET: 'EWALLET_CONFIGURATION_GET',
    EWALLET_CONFIGURATION_GET_FAILURE: 'EWALLET_CONFIGURATION_GET_FAILURE',
    EWALLET_CONFIGURATION_GET_SUCCESS: 'EWALLET_CONFIGURATION_GET_SUCCESS',
    CONFIRM_MODAL_CLOSE: 'EWALLET_CONFIGURATION_CONFIRM_MODAL_CLOSE',
    CONFIRM_MODAL_OPEN: 'EWALLET_CONFIGURATION_CONFIRM_MODAL_OPEN',
    FILTER_DROP: 'EWALLET_CONFIGURATION_FILTER_DROP',
    FILTER_SET: 'EWALLET_CONFIGURATION_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'EWALLET_CONFIGURATION_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'EWALLET_CONFIGURATION_TABLE_PAGE_SIZE_CHANGE',
    },
};

export const onOpenConfirmModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CONFIRM_MODAL_OPEN,
    });

export const onCloseConfirmModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_MODAL_CLOSE,
    });

export const ewalletConfigurationGet = (dispatch) =>
    dispatch({
        type: ACTIONS.EWALLET_CONFIGURATION_GET,
    });

export const ewalletConfigurationGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.EWALLET_CONFIGURATION_GET_SUCCESS,
    });

export const ewalletConfigurationGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.EWALLET_CONFIGURATION_GET_FAILURE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const ewalletConfigurationFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const ewalletConfigurationDropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTER_DROP,
    });

export const ewalletConfiguration = (dispatch) =>
    dispatch({
        type: ACTIONS.EWALLET_CONFIGURATION_FORM_SUBMIT,
    });

export const ewalletConfigurationFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.EWALLET_CONFIGURATION_FORM_SUBMIT_FAILURE,
    });

export const ewalletConfigurationSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.EWALLET_CONFIGURATION_FORM_SUBMIT_SUCCESS,
    });
