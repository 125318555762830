import { Redirect, Switch } from 'react-router-dom';
import React from 'react';

import {
    BankAccountConfiguration,
    BankAccountSetup,
    BankAccountStatement,
    CryptoMerchantConfiguration,
    Cryptocurrency,
    Deposits,
    EwalletAccountConfiguration,
    EwalletSetup,
    EwalletTransfers,
    EwalletWithdrawals,
    MerchantConfiguration,
    MerchantSetup,
    Merchants,
    TransactionAmountSetting,
    Withdrawals,
} from '.';
import { PrivateRoute } from '../../common/components';
import { ROUTES } from '../../common/constants/routing';

const Payments = () => {
    return (
        <Switch>
            <PrivateRoute path={ROUTES.DASHBOARD.PAYMENTS.OFFLINE_DEPOSITS} component={Deposits} />
            <PrivateRoute
                path={ROUTES.DASHBOARD.PAYMENTS.CRYPTOCURRENCY.MERCHANT_CONFIGURATION}
                component={CryptoMerchantConfiguration}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.PAYMENTS.MERCHANT_DEPOSITS}
                component={Merchants}
            />
            <PrivateRoute path={ROUTES.DASHBOARD.PAYMENTS.WITHDRAWALS} component={Withdrawals} />
            <PrivateRoute
                path={ROUTES.DASHBOARD.PAYMENTS.MERCHANT_ACCOUNT.ROOT}
                component={MerchantSetup}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.PAYMENTS.BANK_ACCOUNT.ROOT}
                component={BankAccountSetup}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.PAYMENTS.BANK_ACCOUNT.CONFIGURATION}
                component={BankAccountConfiguration}
            />
            <PrivateRoute path={ROUTES.DASHBOARD.PAYMENTS.EWALLET.ROOT} component={EwalletSetup} />
            <PrivateRoute
                path={ROUTES.DASHBOARD.PAYMENTS.EWALLET.CONFIGURATION}
                component={EwalletAccountConfiguration}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.PAYMENTS.EWALLET_TRANSFERS}
                component={EwalletTransfers}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.PAYMENTS.CRYPTOCURRENCY.ROOT}
                component={Cryptocurrency}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.PAYMENTS.MERCHANT_ACCOUNT.CONFIGURATION}
                component={MerchantConfiguration}
            />

            <PrivateRoute
                path={ROUTES.DASHBOARD.PAYMENTS.BANK_ACCOUNT.STATEMENT}
                component={BankAccountStatement}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.PAYMENTS.MERCHANT_ACCOUNT.CONFIGURATION}
                component={MerchantConfiguration}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.PAYMENTS.TRANSACTION_AMOUNT_SETTING.ROOT}
                component={TransactionAmountSetting}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.PAYMENTS.EWALLET_WITHDRAWALS}
                component={EwalletWithdrawals}
                hasAccessControl={true}
            />
            <Redirect to={ROUTES.DASHBOARD.PAYMENTS.OFFLINE_DEPOSITS} />
        </Switch>
    );
};

export default Payments;
