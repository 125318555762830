import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './CryptocurrencyWithdrawalTableMeta';
import { getStateProp } from '../../../../../common/helpers/misc';
import { useStore } from '../../../../../store/StateProvider';

const CryptocurrencyWithdrawalResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const tableData = getStateProp(state, 'payments.cryptoWithdrawals.list', []);
    const paging = getStateProp(state, 'payments.cryptoWithdrawals.paging', {});

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            dataSource={tableData}
            columns={getColumns(dispatch)}
            pagination={getPaginationOptions(paging, dispatch)}
            rowKey={(record) => record.transactionId}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            loading={loading}
        />
    );
};

export default CryptocurrencyWithdrawalResultsTable;
