export const ACTIONS = {
    DETAILS_PAGINATION: {
        TABLE_PAGE_CHANGE: 'PLAYER_WIN_LOSS_DETAILS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'PLAYER_WIN_LOSS_DETAILS_TABLE_PAGE_SIZE_CHANGE',
    },
    FILTERS_DROP: 'PLAYER_WIN_LOSS_FILTER_DROP',
    FILTER_OPTION_GET: 'PLAYER_WIN_LOSS_FILTER_OPTION_GET',
    FILTER_OPTION_GET_FAILURE: 'FILTER_OPTION_GET_FAILURE',
    FILTER_OPTION_GET_SUCCESS: 'FILTER_OPTION_GET_SUCCESS',
    FILTER_SET: 'PLAYER_WIN_LOSS_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'PLAYER_WIN_LOSS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'PLAYER_WIN_LOSS_TABLE_PAGE_SIZE_CHANGE',
    },
    PLAYER_WIN_LOSS_DETAILS_GET: 'PLAYER_WIN_LOSS_DETAILS_GET',
    PLAYER_WIN_LOSS_DETAILS_GET_FAILURE: 'PLAYER_WIN_LOSS_DETAILS_GET_FAILURE',
    PLAYER_WIN_LOSS_DETAILS_GET_SUCCESS: 'PLAYER_WIN_LOSS_DETAILS_GET_SUCCESS',
    PLAYER_WIN_LOSS_EXPORT: 'PLAYER_WIN_LOSS_EXPORT',
    PLAYER_WIN_LOSS_EXPORT_FAILURE: 'PLAYER_WIN_LOSS_EXPORT_FAILURE',
    PLAYER_WIN_LOSS_EXPORT_SUCCESS: 'PLAYER_WIN_LOSS_EXPORT_SUCCESS',
    PLAYER_WIN_LOSS_REPORT_GET: 'PLAYER_WIN_LOSS_REPORT_GET',
    PLAYER_WIN_LOSS_REPORT_GET_FAILURE: 'PLAYER_WIN_LOSS_REPORT_GET_FAILURE',
    PLAYER_WIN_LOSS_REPORT_GET_SUCCESS: 'PLAYER_WIN_LOSS_REPORT_GET_SUCCESS',
    WIN_LOSS_DETAIL_MODAL_CLOSE: 'WIN_LOSS_DETAIL_MODAL_CLOSE',
    WIN_LOSS_DETAIL_MODAL_OPEN: 'WIN_LOSS_DETAIL_MODAL_OPEN',
};

export const filterOptionGet = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTER_OPTION_GET,
    });

export const filterOptionGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_OPTION_GET_SUCCESS,
    });

export const filterOptionGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_OPTION_GET_FAILURE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const changeDetailsPageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DETAILS_PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changeDetailsPageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DETAILS_PAGINATION.TABLE_PAGE_CHANGE,
    });

export const openWinLossDetailModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WIN_LOSS_DETAIL_MODAL_OPEN,
    });

export const closeWinLossDetailModal = (dispatch) =>
    dispatch({
        type: ACTIONS.WIN_LOSS_DETAIL_MODAL_CLOSE,
    });

export const playerWinLossFilterSet = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });
};

export const playerWinLossFilterDrop = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP,
    });

export const playerWinLossReportGet = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYER_WIN_LOSS_REPORT_GET,
    });

export const playerWinLossReportGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_WIN_LOSS_REPORT_GET_SUCCESS,
    });

export const playerWinLossReportGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_WIN_LOSS_REPORT_GET_FAILURE,
    });

export const playerWinLossDetailsGet = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYER_WIN_LOSS_DETAILS_GET,
    });

export const playerWinLossDetailsGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_WIN_LOSS_DETAILS_GET_SUCCESS,
    });

export const playerWinLossDetailsGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_WIN_LOSS_DETAILS_GET_FAILURE,
    });

export const playerWinLossExport = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYER_WIN_LOSS_EXPORT,
    });

export const playerWinLossExportSuccess = (dispatch, payload) =>
    dispatch({
        type: ACTIONS.PLAYER_WIN_LOSS_EXPORT_SUCCESS,
    });

export const playerWinLossExportFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_WIN_LOSS_EXPORT_FAILURE,
    });
