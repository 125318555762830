import React from 'react';

import { Card } from 'antd';

import { getTranslation } from '../../../common/helpers/locale';
import MessageForm from './MessageForm';

const MessageCreate = () => {
    return (
        <Card title={getTranslation('Create Message')}>
            <MessageForm />
        </Card>
    );
};

export default MessageCreate;
