import React from 'react';

import { Row } from 'antd';

import {
    DropButton,
    ExportButton,
    RejectButton,
    SearchButton,
} from '../../../../common/components/buttons';

const ActionButtons = ({ onReject, onExport, onDropFilters }) => {
    return (
        <Row align="middle" justify="end" className="action-buttons__container">
            <SearchButton htmlType="submit" />
            <DropButton onDropFilters={onDropFilters} />
            <RejectButton onReject={onReject} />
            <ExportButton onExport={onExport} />
        </Row>
    );
};

export default ActionButtons;
