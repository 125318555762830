import React from 'react';

import { Input } from 'antd';

import '../styles/AnnouncementViewTabs.less';
import Editor from '../../../../common/components/Editor';

const { TextArea } = Input;

const MessageViewFormTabs = ({ title, content, messageType }) => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <>
            <Input
                disabled
                placeholder="Title"
                value={title}
                className="announcement-content__title"
            />
            {messageType === 'Inbox' ? (
                <Editor initialValue={content} disabled />
            ) : (
                <TextArea value={content} disabled rows={4} />
            )}
        </>
    );
};

export default MessageViewFormTabs;
