import React, { useEffect, useState } from 'react';

import { Col, Form, Input, Row, message } from 'antd';

import { ButtonGreen, ButtonTransparent } from '../../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useChangeUserPasswordOperationAsyncEndpoint } from '../../../../../services/adminService';

const ChangePassword = ({ onCancel }) => {
    const [form] = Form.useForm();
    const { resetFields } = form;
    const [formValues, setFormValues] = useState({});

    // ============================================
    // METHODS
    // ============================================

    const onSave = (values) => {
        setFormValues(values);
        if (values.newPassword.length < 7) {
            message.error(getTranslation('New Password cannot less than 7!'));
            return;
        }
        handleChangeUserPassword();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [
        passwordChangeLoading,
        response,
        handleChangeUserPassword,
    ] = useChangeUserPasswordOperationAsyncEndpoint(formValues);

    useEffect(() => {
        if (response) {
            resetFields();
            onCancel();
        }
    }, [response, resetFields, onCancel]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form form={form} name="change-password" onFinish={onSave}>
            <Row className="form__group">
                <Col span={4}>
                    <label>{getTranslation('New Password')}</label>
                </Col>
                <Col span={20}>
                    <Form.Item
                        name="newPassword"
                        rules={[
                            {
                                message: getTranslation('This field is required!'),
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="end">
                <ButtonTransparent
                    label={getTranslation('Cancel')}
                    onClick={onCancel}
                    style={{ marginRight: '10px' }}
                />
                <ButtonGreen
                    label={getTranslation('Save')}
                    loading={passwordChangeLoading}
                    htmlType="submit"
                />
            </Row>
        </Form>
    );
};

export default ChangePassword;
