import React, { useEffect } from 'react';

import { Col, Modal, Row } from 'antd';

import './PlayerIpModal.less';
import { ButtonGreen, ButtonTransparent } from '../../../../common/components/buttons/Buttons';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useGetPlayerIpAsyncEndpoint } from '../../../../services/playerService';
import { useStore } from '../../../../store/StateProvider';
import PlayerIpTable from './table/PlayerIpTable';

const PlayerIpModal = ({ visible, onCancel }) => {
    const [state] = useStore();
    const registrationDetails = getStateProp(state, 'players.registrationDetails');

    const ipAddr = getStateProp(registrationDetails, 'ipAddr', {});

    // ============================================
    // HOOKS
    // ============================================

    const [loading, getPlayerIp] = useGetPlayerIpAsyncEndpoint(ipAddr);

    useEffect(() => {
        getPlayerIp();
    }, [getPlayerIp]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            title={getTranslation('Player IP')}
            centered
            width={700}
            closable={false}
            onCancel={onCancel}
            footer={null}
            className="playerip__verify-modal"
        >
            <Row className="playerip__verify-row">
                <Col span={7}>
                    <label className="modal-description">{getTranslation('Player IP')}</label>
                </Col>
                <Col span={1}>:</Col>
                <Col span={8}>
                    <span>{ipAddr}</span>
                </Col>
                <Col span={8}>
                    <ButtonGreen label="Show" onClick={getPlayerIp} />
                </Col>
            </Row>
            <Row className="results-table__container">
                <PlayerIpTable loading={loading} />
            </Row>

            <Row justify="end">
                <ButtonTransparent
                    className="action-button"
                    label={getTranslation('Cancel')}
                    onClick={onCancel}
                />
            </Row>
        </Modal>
    );
};

export default PlayerIpModal;
