import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { BALANCE_MANAGEMENT_TYPES, DEPOSIT_CORRECTION_TYPES } from '../../../common/constants/misc';
import { getTranslation } from '../../../common/helpers/locale';

function DetailsFilter({ value, onFilterChange }) {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('transactionDetails', value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear
            placeholder={getTranslation('Details')}
            className="filter"
        >
            {_.map({ ...BALANCE_MANAGEMENT_TYPES, ...DEPOSIT_CORRECTION_TYPES }, (types) => (
                <Select.Option key={types} value={types}>
                    {getTranslation(types).toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
}

export default DetailsFilter;
