import React from 'react';

import { Card, Form } from 'antd';

import { API } from '../../../common/constants/api';
import { dropFilters } from './vipRecordsActions';
import { getColumns } from './table/VipRecordTableMeta';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useExportAsyncEndpoint } from '../../../services/hooks/useExport';
import { useGetVipRecordsAsyncEndpoint } from '../../../services/vipService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import VipRecordResultsTable from './table/VipRecordResultsTable';
import VipRecordsFilters from './VipRecordsFilters';

const { TRANSACTION_HISTORY } = API;

const VipRecords = () => {
    const [state, dispatch] = useStore();
    const { vipRecords } = getStateProp(state, 'transactionHistory');
    const filters = getStateProp(vipRecords, 'filters');
    const { totalRecordCount } = getStateProp(vipRecords, 'paging', {});
    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetVipRecords();
    };

    // ============================================
    // HOOKS
    // ============================================
    const [loading, handleGetVipRecords] = useGetVipRecordsAsyncEndpoint();

    const [handleExportAction] = useExportAsyncEndpoint({
        dataMeta: getColumns(),
        filters,
        method: 'GET',
        subject: getTranslation('Vip Records'),
        totalRecordCount,
        url: `${TRANSACTION_HISTORY.VIP_RECORDS}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title="Vip Records">
            <Form onFinish={handleGetVipRecords}>
                <VipRecordsFilters />
                <ActionButtons onDropFilters={fetchAll} onExport={handleExportAction} />
            </Form>
            <VipRecordResultsTable loading={loading} />
        </Card>
    );
};

export default VipRecords;
