import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './RiskMonitorMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const RiskMonitorTable = ({
    loading,
    setTotalRegistrationModalVisible,
    setBonusCodeRedemptionModalVisible,
    handleGetBonusRedeem,
    handleGetBonusRegistration,
}) => {
    const [state, dispatch] = useStore();

    const { riskMonitor } = getStateProp(state, 'bonus');
    const tableData = getStateProp(riskMonitor, 'list');
    const paging = getStateProp(riskMonitor, 'paging', {});

    // ============================================
    // METHODS
    // ============================================

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns(
                setTotalRegistrationModalVisible,
                setBonusCodeRedemptionModalVisible,
                handleGetBonusRedeem,
                handleGetBonusRegistration,
            )}
            rowKey={(record, idx) => `risk-monitor-${idx}`}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            dataSource={tableData}
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
        />
    );
};

export default RiskMonitorTable;
