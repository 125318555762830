import React from 'react';

import { Row } from 'antd';

import {
    CurrencyFilter,
    MemberLevelFilter,
    TrustLevelFilter,
} from '../../../common/components/filters';
import {
    EwalletAccountIdFilter,
    EwalletAccountNameFilter,
    EwalletIdFilter,
    EwalletNameFilter,
    WebShowFilter,
} from '../filters';
import { ewalletConfigurationFilterSet } from './ewalletConfigurationActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';

const EwalletConfigurationFilters = () => {
    const [state, dispatch] = useStore();

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => ewalletConfigurationFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <EwalletAccountNameFilter
                onFilterChange={setFilter}
                value={getStateProp(
                    state,
                    'payments.ewalletConfiguration.filters.accountHolderName',
                )}
                placeholder={getTranslation('Account Holder Name')}
            />
            <EwalletAccountIdFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'payments.ewalletConfiguration.filters.accountId')}
                name="accountId"
            />
            <EwalletIdFilter
                value={getStateProp(state, 'payments.ewalletConfiguration.filters.eWalletId')}
                onFilterChange={setFilter}
            />
            <EwalletNameFilter
                value={getStateProp(state, 'payments.ewalletConfiguration.filters.eWalletName')}
                onFilterChange={setFilter}
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'payments.ewalletConfiguration.filters.currencyCode')}
                name="currencyCode"
            />
            <MemberLevelFilter
                value={getStateProp(state, 'payments.ewalletConfiguration.filters.memberLevel')}
                onFilterChange={setFilter}
            />
            <TrustLevelFilter
                value={getStateProp(state, 'payments.ewalletConfiguration.filters.trustLevel')}
                onFilterChange={setFilter}
            />
            <WebShowFilter
                value={getStateProp(state, 'payments.ewalletConfiguration.filters.isWebShow')}
                onFilterChange={setFilter}
            />
        </Row>
    );
};

export default EwalletConfigurationFilters;
