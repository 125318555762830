import React from 'react';

import { Modal, Row } from 'antd';

import './VerifyModal.less';
import { ButtonGray, ButtonGreen } from '../../../../common/components/buttons/Buttons';
import { PAYMENT_ORDER_OPERATIONS } from '../../../../common/constants/misc';
import {
    closeConfirmModal,
    closeVerifyModal,
    openConfirmModal,
    performDepositOperation,
} from '../depositsActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useStore } from '../../../../store/StateProvider';
import ConfirmModal from './ConfirmModal';

const VerifyModal = ({ onCancel, visible, transaction, operationPending }) => {
    const [state, dispatch] = useStore();
    const confirmModalVisible = getStateProp(state, 'payments.deposits.confirmModalVisible', false);
    const { realName, transactionMethod, refCode, amount } = transaction;

    // ============================================
    // METHODS
    // ============================================

    const showConfirmModal = () => openConfirmModal(dispatch);

    const verifyTransaction = () => {
        performDepositOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.COMPLETE,
            transaction,
        });
        closeConfirmModal(dispatch);
        closeVerifyModal(dispatch);
    };

    const closeModal = () => closeConfirmModal(dispatch);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            title={getTranslation('Deposit Verification')}
            centered
            closable={false}
            className="offline-deposit__verification-modal"
            onCancel={onCancel}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        label={getTranslation('Approve')}
                        onClick={showConfirmModal}
                        loading={operationPending}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
            destroyOnClose={true}
        >
            <Row justify="start" className="deposit__name-modal">
                <p className="modal-description">{getTranslation('Full Name')}</p> :
                <p className="deposit-record">{realName}</p>
            </Row>

            <Row justify="start" className="deposit__transaction-modal">
                <p className="modal-description">{getTranslation('Transaction Method')}</p> :
                <p className="deposit-record">{transactionMethod}</p>
            </Row>
            <Row justify="start" className="deposit__amount-modal">
                <p className="modal-description">{getTranslation('Amount')}</p> :
                <p className="deposit-record">{amount}</p>
            </Row>
            <Row justify="start" className="deposit__remark-modal">
                <p className="modal-description">{getTranslation('Ref Code')}</p> :
                <p className="deposit-record">{refCode}</p>
            </Row>
            <ConfirmModal
                visible={confirmModalVisible}
                onCancel={closeModal}
                onConfirm={verifyTransaction}
            />
        </Modal>
    );
};

export default VerifyModal;
