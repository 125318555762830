import React from 'react';

import { Row } from 'antd';

import {
    PeriodFilter,
    TransactionNumberFilter,
    TransactionStatusFilter,
    UsernameFilter,
} from '../../../common/components/filters';
import { TransferFromFilter, TransferToFilter } from '../filters';
import { getStateProp } from '../../../common/helpers/misc';
import { useStore } from '../../../store/StateProvider';
import { walletTransfersFilterSet } from './walletActions';

const WalletTransfersFilters = () => {
    const [state, dispatch] = useStore();

    const { walletTransfers } = getStateProp(state, 'transactionHistory');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => walletTransfersFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(walletTransfers, 'filters.playerUserName')}
                name="playerUserName"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(walletTransfers, 'filters.requestedDateFrom')}
                dateFromName="requestedDateFrom"
                dateTo={getStateProp(walletTransfers, 'filters.requestedDateTo')}
                dateToName="requestedDateTo"
            />
            <TransactionNumberFilter
                onFilterChange={setFilter}
                value={getStateProp(walletTransfers, 'filters.transactionNo')}
                name="transactionNo"
            />
            <TransactionStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(walletTransfers, 'filters.transactionStatuses')}
            />
            <TransferFromFilter
                onFilterChange={setFilter}
                value={getStateProp(walletTransfers, 'filters.transferFrom')}
            />
            <TransferToFilter
                onFilterChange={setFilter}
                value={getStateProp(walletTransfers, 'filters.transferTo')}
            />
        </Row>
    );
};

export default WalletTransfersFilters;
