import React from 'react';

import { Input, Modal, Row } from 'antd';

import './VerifyModal.less';
import { ButtonGray, ButtonGreen, ButtonRed } from '../../../../common/components/buttons/Buttons';
import { NEWS_STATUSES } from '../../constants';
import { getTranslation } from '../../../../common/helpers/locale';
import { updateNewsInfo } from '../../newsActions';
import { useDispatch } from '../../../../store/StateProvider';

const { TextArea } = Input;

const VerifyModal = ({ visible, onCancel, loading, handleVerifyNews }) => {
    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ============================================

    const addNewsVerifyRemark = (e) => {
        updateNewsInfo(dispatch, {
            name: 'remarks',
            value: e.target.value,
        });
    };

    const verifyNews = (value) => {
        updateNewsInfo(dispatch, { name: 'newsStatus', value });
        handleVerifyNews();
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            title={getTranslation('News Verification')}
            centered
            closable={false}
            visible={visible}
            onCancel={onCancel}
            className="news__verification-modal"
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        label="Publish"
                        onClick={() => verifyNews(NEWS_STATUSES.PUBLISHED)}
                        loading={loading}
                        htmlType="submit"
                    />
                    <ButtonRed
                        label={getTranslation('Reject')}
                        onClick={() => verifyNews(NEWS_STATUSES.REJECTED)}
                        loading={loading}
                        htmlType="submit"
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
        >
            <Row>
                <label className="modal-description">{getTranslation('Remarks')}</label>
                <TextArea onChange={addNewsVerifyRemark} />
            </Row>
        </Modal>
    );
};

export default VerifyModal;
