import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './TotalRefereeTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const TotalRefereeResultsTable = ({ loading, type }) => {
    const [state, dispatch] = useStore();

    const tableData = getStateProp(state, 'referral.referrerReport.refereeReport.list', []);
    const paging = getStateProp(state, 'referral.referrerReport.refereeReport.paging', {});

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            id="total-referee-table"
            columns={getColumns(type)}
            size="small"
            scroll={{ x: true }}
            align="left"
            dataSource={tableData}
            rowKey={(record) => record.id}
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
            bordered
        />
    );
};

export default TotalRefereeResultsTable;
