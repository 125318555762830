import { Redirect, Switch } from 'react-router-dom';
import React from 'react';

import { ROUTES } from '../../common/constants/routing';
import CreateReferral from './create-referral/CreateReferral';
import PrivateRoute from '../../common/components/PrivateRoute';
import ReferralInquiry from './inquiry/ReferralInquiry';
import ReferralManagement from './referral-management/ReferralManagement';
import ReferralReport from './referral-report/ReferralReport';
import ReferrerReport from './referrer-report/ReferrerReport';

const Referral = () => {
    return (
        <Switch>
            <PrivateRoute path={ROUTES.DASHBOARD.REFERRAL.CREATE} component={CreateReferral} />
            <PrivateRoute path={ROUTES.DASHBOARD.REFERRAL.LIST} component={ReferralManagement} />
            <PrivateRoute
                path={ROUTES.DASHBOARD.REFERRAL.REFERRER_REPORT}
                component={ReferrerReport}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.REFERRAL.REFERRAL_REPORT}
                component={ReferralReport}
            />
            <PrivateRoute path={ROUTES.DASHBOARD.REFERRAL.INQUIRY} component={ReferralInquiry} />
            <Redirect to={ROUTES.DASHBOARD.REFERRAL.CREATE} />
        </Switch>
    );
};

export default Referral;
