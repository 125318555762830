import React from 'react';

import { Card, Form } from 'antd';

import './DangerLevel.less';
import {
    dropFilters,
    setDangerLevelSettingModal,
    setDangerLevelTagModal,
} from './dangerLevelActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    useDeleteDangerLevelTagAsyncEndpoint,
    useGetDangerLevelFilterListAsyncEndpoint,
    useGetDangerLevelTagAsyncEndpoint,
    useGetDangerLevelsAsyncEndpoint,
    useUpdateDangerLevelSettingAsyncEndpoint,
    useUpdateDangerLevelTagAsyncEndpoint,
} from '../../../services/playerService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import DangerLevelFilters from './DangerLevelFilters';
import DangerLevelSettingModal from './DangerLevelSettingModal';
import DangerLevelSettingTable from './table/DangerLevelSettingTable';
import DangerLevelTagModal from './DangerLevelTagModal';

const PlayersList = () => {
    const [state, dispatch] = useStore();
    const {
        dangerLevelTagModal = false,
        list = [],
        dangerLevelSettingModal = false,
        fetchData = () => {},
        isDeleted = false,
    } = getStateProp(state, 'players.dangerLevel');
    // const [dangerLevelTagModal, setDangerLevelTagModal] = useState(false);
    // ============================================
    // METHODS
    // ============================================
    const handleDropFilters = () => {
        dropFilters(dispatch);
        handleGetDangerLevels();
    };

    const handleDangerLevelTagModalCloase = () => {
        if (isDeleted) fetchData();
        setDangerLevelTagModal(dispatch, false);
    };

    const openEditDangerLevelTagModal = () => {
        handleGetDangerLevelTag();
        setDangerLevelTagModal(dispatch, true);
    };

    const handleDangerLevelSettingModal = (isVisible, selectedRecord) => {
        setDangerLevelSettingModal(dispatch, {
            dangerLevelSettingModal: isVisible,
            selectedDangerLevelSetting: selectedRecord,
        });
    };

    // ============================================
    // HOOKS
    // ============================================
    const [gettingDangerLevelFilterList] = useGetDangerLevelFilterListAsyncEndpoint('');
    const [loading, handleGetDangerLevels] = useGetDangerLevelsAsyncEndpoint();
    const [gettingDangerLevelTag, handleGetDangerLevelTag] = useGetDangerLevelTagAsyncEndpoint();
    const [
        updatingDangerLevelTag,
        handleUpdateDangerLevelTag,
    ] = useUpdateDangerLevelTagAsyncEndpoint();
    const [deleting, handleDeleteDangerLevelTag] = useDeleteDangerLevelTagAsyncEndpoint();
    const [
        updatingDangerLevelSetting,
        handleUpdateDangerLevelSetting,
    ] = useUpdateDangerLevelSettingAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card
            loading={gettingDangerLevelFilterList}
            title={getTranslation('Danger Level Management')}
        >
            <Form onFinish={handleGetDangerLevels}>
                <DangerLevelFilters />
                <ActionButtons
                    onDropFilters={handleDropFilters}
                    onEditDangerLevelTag={openEditDangerLevelTagModal}
                />
            </Form>
            <DangerLevelSettingTable
                loading={loading}
                tableData={list}
                handleDangerLevelSettingModal={handleDangerLevelSettingModal}
            />
            <DangerLevelTagModal
                gettingDangerLevelTag={gettingDangerLevelTag || deleting}
                visible={dangerLevelTagModal}
                updating={updatingDangerLevelTag}
                onCancel={handleDangerLevelTagModalCloase}
                onSubmit={handleUpdateDangerLevelTag}
                onDelete={handleDeleteDangerLevelTag}
            />
            <DangerLevelSettingModal
                visible={dangerLevelSettingModal}
                updating={updatingDangerLevelSetting}
                onSubmit={handleUpdateDangerLevelSetting}
                handleDangerLevelSettingModal={handleDangerLevelSettingModal}
            />
        </Card>
    );
};

export default PlayersList;
