import React from 'react';

import 'moment-timezone';
import Moment from 'react-moment';

import { TIMEZONE } from '../constants/localization';
import { getStateProp } from '../helpers/misc';
import { getTranslation } from '../helpers/locale';
import { useStore } from '../../store/StateProvider';

const RenderDateFormat = (date, hideTime = false, isUtc = true) => {
    const [state] = useStore();
    const location = getStateProp(state, 'timezone.location', TIMEZONE.BANGKOK.LOCATION);

    return date ? (
        <Moment format={hideTime ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm:ss'} utc={isUtc} tz={location}>
            {date}
        </Moment>
    ) : (
        <span>{getTranslation('Not processed')}</span>
    );
};

export { RenderDateFormat };
