import { ACTIONS } from './ewalletCorrectionActions';

export default function ewalletCorrectionReducer(
    ewalletCorrection = {
        ewalletCorrectionInfo: {
            turnoverRequirement: 1,
        },
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.EWALLET_CORRECTION_INFO_SET:
            return {
                ...ewalletCorrection,
                ewalletCorrectionInfo: {
                    ...ewalletCorrection.ewalletCorrectionInfo,
                    [payload.inputName]: payload.value,
                },
            };
        case ACTIONS.EWALLET_CORRECTION_MULTI_INFO_SET:
        case ACTIONS.PLAYER_DETAILS_GET_SUCCESS:
            return {
                ...ewalletCorrection,
                ewalletCorrectionInfo: {
                    ...ewalletCorrection.ewalletCorrectionInfo,
                    ...payload,
                },
            };
        case ACTIONS.TRANSACTION_GET_SUCCESS:
            return {
                ...ewalletCorrection,
                ewalletCorrectionInfo: {
                    ...ewalletCorrection.ewalletCorrectionInfo,
                    ...payload,
                },
            };
        case ACTIONS.TRANSACTION_GET_FAILURE:
            return {
                ...ewalletCorrection,
                ewalletCorrectionInfo: null,
            };
        case ACTIONS.FORM_RESET:
            return {
                ...ewalletCorrection,
                ewalletCorrectionInfo: {
                    turnoverRequirement: 1,
                },
            };
        case ACTIONS.OPERATION:
            return {
                ...ewalletCorrection,
                ...payload,
            };
        case ACTIONS.EWALLET_DETAILS_GET_SUCCESS:
            return {
                ...ewalletCorrection,
                eWalletList: payload,
                ewalletCorrectionInfo: {
                    ...ewalletCorrection.ewalletCorrectionInfo,
                    accountHolderName: '',
                    eWalletName: '',
                },
            };
        case ACTIONS.OPERATION_SUCCESS:
        case ACTIONS.OPERATION_FAILURE:
            return {
                ...ewalletCorrection,
                operation: null,
            };
        default:
            return ewalletCorrection;
    }
}
