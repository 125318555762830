import { Redirect, Switch } from 'react-router-dom';
import React from 'react';

import { Card, Row } from 'antd';
import _ from 'lodash';

import { ButtonBlue } from '../../../common/components/buttons/Buttons';
import { MANUAL_BONUS_ASPECTS } from './constants';
import { ROUTES } from '../../../common/constants/routing';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    manualManagementReset,
    pauseRefreshTableTime,
    setRefreshTableTime,
} from './manualManagementActions';
import { useStore } from '../../../store/StateProvider';
import BatchManualManagement from './batch-manual-management/BatchManualManagement';
import NormalManualManagement from './normal-manual-management/NormalManualManagement';
import PrivateRoute from '../../../common/components/PrivateRoute';
import TableRefreshSelector from '../../../common/components/TableRefreshSelector';

const ManualManagement = ({ history }) => {
    const [state, dispatch] = useStore();
    const manualManagement = getStateProp(state, 'bonus.manualManagement');

    // ============================================
    // METHODS
    // ============================================
    const setRefreshTime = (timeOut) => {
        setRefreshTableTime(dispatch, {
            timeOut,
        });
    };

    const onTimePause = () => pauseRefreshTableTime(dispatch);

    const onTimeStart = () => {
        setRefreshTableTime(dispatch, {
            timeOut: 15,
        });
    };

    const isActive = (subRoute) =>
        history.location.pathname.replace('/dashboard/bonus/manual-management/', '') === subRoute;

    const onTabChange = (key) => {
        manualManagementReset(dispatch);
        history.push(key);
    };

    // ============================================
    // HOOKS
    // ============================================
    // ============================================
    // RENDER
    // ============================================

    return (
        <Card
            title={getTranslation('Manual Bonus Management')}
            extra={
                <Row align="middle" justify="space-between">
                    <Row align="middle" justify="space-between">
                        {_.map(_.toPairs(MANUAL_BONUS_ASPECTS), ([key, value]) => (
                            <ButtonBlue
                                active={isActive(value)}
                                onClick={() => onTabChange(value)}
                                key={key}
                                className="tab-button"
                            >
                                {getTranslation(_.capitalize(value))}
                            </ButtonBlue>
                        ))}
                    </Row>
                    <TableRefreshSelector
                        onTimeChange={setRefreshTime}
                        onTimePause={onTimePause}
                        onTimeStart={onTimeStart}
                        timeOut={getStateProp(manualManagement, 'operation.refreshTime')}
                    />
                </Row>
            }
        >
            <Switch>
                <PrivateRoute
                    path={ROUTES.DASHBOARD.BONUS.MANUAL_BONUS_MANAGEMENT.NORMAL}
                    component={NormalManualManagement}
                />
                <PrivateRoute
                    path={ROUTES.DASHBOARD.BONUS.MANUAL_BONUS_MANAGEMENT.BATCH}
                    component={BatchManualManagement}
                />
                <Redirect to={ROUTES.DASHBOARD.BONUS.MANUAL_BONUS_MANAGEMENT.NORMAL} />
            </Switch>
        </Card>
    );
};

export default ManualManagement;
