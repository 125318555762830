import React from 'react';

import { Row } from 'antd';

import {
    CurrencyFilter,
    MemberLevelFilter,
    PeriodFilter,
    UsernameFilter,
} from '../../../common/components/filters';
import { getStateProp } from '../../../common/helpers/misc';
import { useStore } from '../../../store/StateProvider';
import { vipHistoryFilterSet } from './vipHistoryActions';

const VipHistoryFilters = () => {
    const [state, dispatch] = useStore();

    const { vipHistory } = getStateProp(state, 'vip');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => vipHistoryFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <UsernameFilter
                name="userName"
                onFilterChange={setFilter}
                value={getStateProp(vipHistory, 'filters.userName')}
            />
            <PeriodFilter
                dateFromName="lastLevelUpDateFrom"
                dateToName="lastLevelUpDateTo"
                onFilterChange={setFilter}
                dateFrom={getStateProp(vipHistory, 'filters.lastLevelUpDateFrom')}
                dateTo={getStateProp(vipHistory, 'filters.lastLevelUpDateTo')}
            />
            <CurrencyFilter
                name="currencies"
                mode="multiple"
                onFilterChange={setFilter}
                value={getStateProp(vipHistory, 'filters.currencies')}
            />
            <MemberLevelFilter
                name="previousLevels"
                mode="multiple"
                onFilterChange={setFilter}
                value={getStateProp(vipHistory, 'filters.previousLevels')}
                placeholder="Previous Level"
            />
            <MemberLevelFilter
                name="currentLevels"
                mode="multiple"
                onFilterChange={setFilter}
                value={getStateProp(vipHistory, 'filters.currentLevels')}
                placeholder="Current Level"
            />
        </Row>
    );
};

export default VipHistoryFilters;
