import React from 'react';

import { Card, Col, Input, Row } from 'antd';
import Moment from 'react-moment';

import '../styles/AnnouncementViewForm.less';
import { ANNOUNCEMENT_STATUSES } from '../../constants';
import { ButtonGray, ButtonRed } from '../../../../common/components/buttons/Buttons';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useStore } from '../../../../store/StateProvider';
import MessageViewFormTabs from './MessageViewFormTabs';

const MessageViewForm = ({ onDelete, onCancel }) => {
    const [state] = useStore();

    const selectedMessage = getStateProp(
        state,
        'announcement.announcementHistory.personalMessage.selectedMessage',
        {},
    );
    const {
        contactMethod,
        messageType,
        userNames,
        phoneNumbers,
        durationEnd,
        durationStart,
        pmStatus,
    } = selectedMessage;
    const messageContents = getStateProp(selectedMessage, 'content', '');
    const messageTitle = getStateProp(selectedMessage, 'title', '');

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card className="announcement-form">
            <Row className="form__group">
                <Col span={8}>
                    <label className="form__label">{getTranslation('Message Type')}</label>
                </Col>
                <Col span={14}>
                    <Input disabled value={messageType} />
                </Col>
            </Row>

            <Row className="form__group">
                <Col span={8}>
                    <label className="form__label">{getTranslation('Contact Method')}</label>
                </Col>
                <Col span={14}>
                    <Input disabled value={contactMethod} />
                </Col>
            </Row>

            <Row className="form__group">
                <Col span={8}>
                    <label className="form__label">{getTranslation('Duration')}</label>
                </Col>
                <Col span={14}>
                    <Moment format="DD/MM/YYYY HH:mm:ss" utc local>
                        {durationStart}
                    </Moment>
                    <span style={{ margin: '3px' }}>-</span>
                    <Moment format="DD/MM/YYYY HH:mm:ss" utc local>
                        {durationEnd}
                    </Moment>
                </Col>
            </Row>

            <Row className="form__group">
                <Col span={8}>
                    <label className="form__label">{getTranslation('Recipients')}</label>
                </Col>
                <Col span={14}>
                    <Input disabled value={userNames || phoneNumbers} />
                </Col>
            </Row>

            <MessageViewFormTabs
                content={messageContents}
                title={messageTitle}
                messageType={messageType}
            />

            <Row className="action-buttons" justify="end" align="middle">
                {pmStatus !== ANNOUNCEMENT_STATUSES.DELETED && (
                    <ButtonRed label={getTranslation('Delete')} onClick={onDelete} />
                )}
                <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
            </Row>
        </Card>
    );
};

export default MessageViewForm;
