export const ANNOUNCEMENT_CATEGORIES = {
    ALL: 'All',
    BONUS: 'Bonus',
    DEPOSIT: 'Deposit',
    GAME: 'Game',
    MAINTENANCE: 'Maintenance',
    PLATFORM: 'Platform',
    REPUTATION: 'Reputation',
    WITHDRAWAL: 'Withdrawal',
};

export const ANNOUNCEMENT_TYPES = {
    EVENT: 'Event',
    GENERAL: 'General',
};

export const ANNOUNCEMENT_OUTCOMES = {
    // ALL: 'All',
    FLOATINGBANNER: 'FloatingBanner',
    // POPUP: 'Popup',
};

export const MESSAGE_TYPES = {
    INBOX: 'Inbox',
    SMS: 'SMS',
};

export const CONTACT_METHODS = {
    MOBILE: 'Mobile',
    USERNAME: 'Username',
};

export const ANNOUNCEMENT_STATUSES = {
    DELETED: 'Deleted',
    PENDING: 'Pending',
    REJECTED: 'Rejected',
    VERIFIED: 'Verified',
};

export const messageStatusMap = new Map([
    ['Deleted', 'Message deleted!'],
    ['Rejected', 'Message rejected!'],
    ['Verified', 'Message verified!'],
]);

export const announcementStatusMap = new Map([
    ['Deleted', 'Annoucement deleted!'],
    ['Rejected', 'Annoucement rejected!'],
    ['Verified', 'Annoucement verified!'],
]);
