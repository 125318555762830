import { Switch } from 'react-router-dom';
import React from 'react';

import { PrivateRoute } from '../../common/components';
import { ROUTES } from '../../common/constants/routing';
import DailyLoginManagement from './daily-login-management/DailyLoginManagement';
import DailyLoginSetup from './daily-login-setup/DailyLoginSetup';

const RewardsManagement = () => {
    return (
        <Switch>
            <PrivateRoute
                path={ROUTES.DASHBOARD.REWARDS_MANAGEMENT.DAILY_LOGIN_SETUP}
                component={DailyLoginSetup}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.REWARDS_MANAGEMENT.DAILY_LOGIN_MANAGEMENT}
                component={DailyLoginManagement}
            />
        </Switch>
    );
};

export default RewardsManagement;
