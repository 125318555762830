import { Redirect, Switch } from 'react-router-dom';
import React from 'react';

import { Card, Row } from 'antd';
import _ from 'lodash';

import { ButtonBlue } from '../../../common/components/buttons/Buttons';
import { CRYPTOCURRENCY_ASPECTS } from '../../../common/constants/misc';
import { ROUTES } from '../../../common/constants/routing';
import { getTranslation } from '../../../common/helpers/locale';
import CryptoDepositRecords from './deposits/CryptoDepositRecords';
import CryptoWithdrawalRecords from './withdrawals/CryptoWithdrawalRecords';
import PrivateRoute from '../../../common/components/PrivateRoute';

const CryptocurrencyRecords = ({ history }) => {
    // ============================================
    // METHODS
    // ============================================

    const isActive = (subRoute) =>
        history.location.pathname.replace(
            '/dashboard/transaction-history/cryptocurrency-records/',
            '',
        ) === subRoute;

    const onTabChange = (key) => history.push(key);

    // ============================================
    // RENDER
    // ============================================

    const AspectButton = (
        <Row align="middle" justify="space-between">
            <Row align="middle" justify="space-between">
                {_.map(_.toPairs(CRYPTOCURRENCY_ASPECTS), ([key, value]) => (
                    <ButtonBlue
                        active={isActive(value)}
                        onClick={() => onTabChange(value)}
                        key={key}
                        className="tab-button"
                    >
                        {getTranslation(_.capitalize(key))}
                    </ButtonBlue>
                ))}
            </Row>
        </Row>
    );

    return (
        <Card title={getTranslation('Cryptocurrency Record')} extra={AspectButton}>
            <Switch>
                <PrivateRoute
                    path={ROUTES.DASHBOARD.TRANSACTION_HISTORY.CRYPTOCURRENCY_RECORDS.DEPOSIT}
                    component={CryptoDepositRecords}
                />
                <PrivateRoute
                    path={ROUTES.DASHBOARD.TRANSACTION_HISTORY.CRYPTOCURRENCY_RECORDS.WITHDRAWAL}
                    component={CryptoWithdrawalRecords}
                />
                <Redirect
                    to={ROUTES.DASHBOARD.TRANSACTION_HISTORY.CRYPTOCURRENCY_RECORDS.DEPOSIT}
                />
            </Switch>
        </Card>
    );
};

export default CryptocurrencyRecords;
