import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { BANNER_STATUSES } from '../constants';
import { getTranslation } from '../../../common/helpers/locale';

const BannerStatusFilter = ({ value, onFilterChange }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('bannerStatus', value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear
            placeholder={getTranslation('Status')}
            className="filter"
        >
            {_.map(BANNER_STATUSES, (types) => (
                <Select.Option value={types} key={types}>
                    {types.toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
};

export default BannerStatusFilter;
