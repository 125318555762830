import React from 'react';

import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from './personalMessageActions';
import { getStatusColor, sortTableByDataType } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import RowActionButtons from './RowActionButton';

export const getColumns = () => {
    const columns = [
        {
            dataIndex: 'createdAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Request Time'),
        },
        {
            dataIndex: 'taskNo',
            title: getTranslation('Task Number'),
        },
        {
            dataIndex: 'messageType',
            title: getTranslation('Message Type'),
        },
        {
            dataIndex: 'contactMethod',
            title: getTranslation('Contact Method'),
        },
        {
            dataIndex: 'title',
            title: getTranslation('Title'),
            width: '400px',
        },
        {
            dataIndex: 'durationStart',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Duration Start'),
        },
        {
            dataIndex: 'durationEnd',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Duration End'),
        },
        {
            dataIndex: 'pmStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>{value.toLocaleUpperCase()}</span>
            ),
            title: getTranslation('Status'),
        },
        {
            dataIndex: 'applicant',
            title: getTranslation('Applicant'),
        },
        {
            dataIndex: 'operation',
            dataType: 'operation',
            render: (value, record) => <RowActionButtons message={record} />,
            title: getTranslation('Operation'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
