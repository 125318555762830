import React, { useRef, useState } from 'react';

import { Input, Tag } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';

import { getTranslation } from '../../../common/helpers/locale';

const TagInput = ({ value, onTagsChanged }) => {
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const textInput = useRef(null);

    // ============================================
    // METHODS
    // ============================================

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && value.indexOf(inputValue) === -1) {
            onTagsChanged([...value, inputValue]);
        }
        setInputVisible(false);
        setInputValue('');
    };

    const handleClose = (removedTag) => {
        let tags = value;
        tags = tags.filter((tag) => tag !== removedTag);
        value = tags;
        onTagsChanged(value);
    };

    // ============================================
    // HOOKS
    // ============================================

    return (
        <div>
            {_.map(value, (v) => (
                <Tag key={v} data-id={v} closable={true} onClose={() => handleClose(v)}>
                    {v}
                </Tag>
            ))}
            {inputVisible && (
                <Input
                    ref={textInput}
                    type="text"
                    size="small"
                    style={{ width: 100 }}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                />
            )}

            {!inputVisible && (
                <Tag onClick={showInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
                    <PlusCircleOutlined style={{ paddingRight: 6 }} />
                    <span>{getTranslation('New Tag')}</span>
                </Tag>
            )}
        </div>
    );
};

export default TagInput;
