import { ACTIONS } from './actions';

export default function countryReducer(country, { type, payload }) {
    switch (type) {
        case ACTIONS.CURRENT_COUNTRY_SET:
        case ACTIONS.CURRENT_LANGUAGE_SET:
            return {
                ...country,
                ...payload,
            };
        default:
            return country;
    }
}
