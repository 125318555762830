import React from 'react';

import { Input } from 'antd';

const BankFilter = ({ value, onFilterChange, placeholder }) => {
    return (
        <Input
            onChange={(e) => onFilterChange('bankName', e.target.value)}
            value={value}
            className="filter"
            placeholder={placeholder}
        />
    );
};

export default BankFilter;
