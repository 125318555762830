import { Row } from 'antd';
import React from 'react';

import { ButtonGreen, ButtonRed } from '../../common/components/buttons/Buttons';
import { getTranslation } from '../../common/helpers/locale';

const ActionButtons = ({ handleFileDelete, onRefresh }) => {
    return (
        <Row align="middle" justify="end" className="action-buttons__container">
            <ButtonGreen label={getTranslation('Refresh')} onClick={onRefresh} />
            <ButtonRed label={getTranslation('Delete')} onClick={handleFileDelete} />
        </Row>
    );
};

export default ActionButtons;
