class ErrorLogger {
    constructor(config) {
        const { init, logError, showReportDialog } = config;
        this.init = init;
        this.logError = logError;
        this.showReportDialog = showReportDialog;
    }
}

const SentryConfig = {
    init: () => {},

    logError: (error, errorInfo, exportErrorId) => {},

    showReportDialog: (eventId) => {},
};

export default () => new ErrorLogger(SentryConfig);
