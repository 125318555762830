import { useCallback, useEffect, useState } from 'react';

import { message } from 'antd';
import _ from 'lodash';

import { API } from '../common/constants/api';
import { BONUS_STATUSES } from '../components/bonus/constants';
import {
    bonusCreate,
    bonusCreateFailure,
    bonusCreateSuccess,
    bonusFormClear,
} from '../components/bonus/create-bonus/createBonusActions';
import {
    bonusEditModalClose,
    bonusManagementActiveListGet,
    bonusManagementActiveListGetFailure,
    bonusManagementActiveListGetSuccess,
    bonusManagementByIdGet,
    bonusManagementByIdGetFailure,
    bonusManagementByIdGetSuccess,
    bonusManagementClearCache,
    bonusManagementListGetFailure,
    bonusManagementListGetSuccess,
    bonusManagementSortingUpdate,
    bonusManagementSortingUpdateFailure,
    bonusManagementSortingUpdateSuccess,
    bonusManagementStatusSetFailure,
    bonusManagementStatusSetSuccess,
    bonusManagementUpdateFailure,
    bonusManagementUpdateSuccess,
} from '../components/bonus/bonus-management/bonusManagementActions';
import { clearError } from '../common/actions/errorActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import {
    getBonusIp,
    getBonusIpFailure,
    getBonusIpSuccess,
    getBonusRedeem,
    getBonusRedeemFailure,
    getBonusRedeemSuccess,
    getBonusRegistration,
    getBonusRegistrationFailure,
    getBonusRegistrationSuccess,
} from '../components/bonus/risk-monitor/riskMonitorActions';
import { getTranslation } from '../common/helpers/locale';
import { useDispatch, useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, PROMOTIONS, BONUS, PLAYERS } = API;

export function useCreateBannerOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const createBonus = getStateProp(state, 'bonus.createBonus', {});

    const {
        duration,
        isShowOnApp,
        isRequireDeposit,
        isRequireBankCard,
        isRequireBonusComplete,
        isRepeatable,
        hasPromoCode,
        categories,
        isFixedBonus,
        appliedPlatforms = [],
        appliedProducts = [],
        ...rest
    } = createBonus;

    // ============================================
    // HOOKS
    // ============================================

    const handleCreateBanner = useCallback(() => {
        bonusCreate(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'POST',
        options: {
            data: {
                appliedPlatforms: appliedPlatforms,
                appliedProducts: appliedProducts,
                bonusStatus: BONUS_STATUSES.INACTIVE,
                categories: _.map(categories, (category) => {
                    return {
                        categoryId: category,
                    };
                }),
                duration: duration || 0,
                hasPromoCode: !!hasPromoCode,
                isFixedBonus: !!isFixedBonus,
                isRepeatable: !!isRepeatable,
                isRequireBankCard: !!isRequireBankCard,
                isRequireBonusComplete: !!isRequireBonusComplete,
                isRequireDeposit: !!isRequireDeposit,
                isShowOnApp: !!isShowOnApp,
                ...rest,
            },
        },
        trigger,
        url: `${VERSION.V1}${PROMOTIONS.ROOT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bonusCreateSuccess(dispatch);
            bonusFormClear(dispatch);
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, bonusCreateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleCreateBanner, response];
}

export function useGetBannerListOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { bonusManagement } = getStateProp(state, 'bonus');
    const { pageNo, pageSize } = getStateProp(bonusManagement, 'paging');
    const filters = getStateProp(bonusManagement, 'filters', {});

    // ============================================
    // HOOKS
    // ============================================

    const handleGetBannerList = useCallback(() => {
        setTrigger(+new Date());
    }, []);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'POST',
        options: {
            data: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${PROMOTIONS.ROOT}/query`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);

            const startingNum = response.data.paging.pageSize * (response.data.paging.pageNo - 1);
            response.data.data.map((item, index) => {
                item.no = startingNum + index + 1;

                // to make english must put at first in list
                const length = item.details.length;
                for (let i = 0, l = length; i < l; i++) {
                    const record = item.details[i];
                    if (record.languageCode === 'en-US') {
                        if (i !== 0) {
                            const removedRecords = item.details.splice(i, 1);
                            item.details.unshift(removedRecords[0]);
                        }
                        break;
                    }
                }

                return item;
            });

            bonusManagementListGetSuccess(dispatch, {
                fetchData: handleGetBannerList,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetBannerList, response]);

    useEffect(() => {
        handleGetBannerList();
    }, [handleGetBannerList, pageNo, pageSize]);

    const errorMsg = useError(error, bonusManagementListGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetBannerList];
}

export function useSetStatusOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { bonusManagement } = getStateProp(state, 'bonus');
    const changeBannerStatus = getStateProp(bonusManagement, 'changeBannerStatus');
    const id = getStateProp(changeBannerStatus, 'id');
    const status = getStateProp(changeBannerStatus, 'status');
    const refreshTable = getStateProp(bonusManagement, 'fetchData', {});

    // ============================================
    // HOOKS
    // ============================================

    const handleSetBannerStatus = useCallback(() => {
        setTrigger(+new Date());
    }, []);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'PUT',
        options: {
            data: {
                id,
            },
        },
        trigger,
        url: `${VERSION.V1}${PROMOTIONS.ROOT}/${id}/status/${status}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bonusManagementClearCache(dispatch);
            bonusManagementStatusSetSuccess(dispatch);
            refreshTable();
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, bonusManagementStatusSetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleSetBannerStatus, response];
}

export function useUpdateBannerOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { bonusManagement } = getStateProp(state, 'bonus');
    const selectedBonus = getStateProp(bonusManagement, 'selectedBonus', {});
    const refreshTable = getStateProp(bonusManagement, 'fetchData', {});

    // ============================================
    // HOOKS
    // ============================================

    const handleUpdateBanner = useCallback(() => {
        delete selectedBonus['activateBy'];
        delete selectedBonus['activatedName'];
        delete selectedBonus['createdAt'];
        delete selectedBonus['createdBy'];
        delete selectedBonus['createdName'];
        delete selectedBonus['sortingOrder'];
        delete selectedBonus['updatedAt'];
        delete selectedBonus['updatedBy'];
        delete selectedBonus['updatedName'];
        delete selectedBonus['joinButtonDisable'];
        setTrigger(+new Date());
    }, [selectedBonus]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'PUT',
        options: {
            data: {
                ...selectedBonus,
                appliedPlatforms: selectedBonus.appliedPlatforms || [],
                appliedProducts: selectedBonus.appliedProducts || [],
                categories: _.map(selectedBonus.categories, (category) => {
                    return category.id
                        ? {
                              categoryId: category.categoryId,
                              id: category.id,
                          }
                        : {
                              categoryId: category.categoryId,
                          };
                }),
                duration: selectedBonus.duration || 0,
                repeatableCount: selectedBonus.isRepeatable
                    ? parseInt(selectedBonus.repeatableCount)
                    : undefined,
            },
        },
        trigger,
        url: `${VERSION.V1}${PROMOTIONS.ROOT}/${selectedBonus.id}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bonusManagementUpdateSuccess(dispatch);
            bonusEditModalClose(dispatch);
            refreshTable();
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, bonusManagementUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateBanner];
}

export function useGetBannerByIdOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const id = getStateProp(state, 'bonus.bonusManagement.selectedBonus.id');

    // ============================================
    // HOOKS
    // ============================================

    const handleGetBannerById = useCallback(() => {
        bonusManagementByIdGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${PROMOTIONS.ROOT}/${id}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bonusManagementByIdGetSuccess(dispatch, {
                ...response.data.data,
                currencies: _.map(response.data.data.currencies, (e) => {
                    return {
                        ...e,
                        betAmount: e.betAmount ? e.betAmount.toFixed(2) : '0.00',
                        bonusPercentage: e.bonusPercentage ? e.bonusPercentage.toFixed(2) : '0.00',
                        fixedBonusAmount: e.fixedBonusAmount
                            ? e.fixedBonusAmount.toFixed(2)
                            : '0.00',
                        lossAmount: e.lossAmount ? e.lossAmount.toFixed(2) : '0.00',
                        maxBonusByPercentage: e.maxBonusByPercentage
                            ? e.maxBonusByPercentage.toFixed(2)
                            : '0.00',
                        winAmount: e.winAmount ? e.winAmount.toFixed(2) : '0.00',
                    };
                }),
                joinButtonDisable: response.data.data.hasJoinButton || false,
                minimumDeposits: _.map(response.data.data.minimumDeposits, (e) => {
                    return {
                        ...e,
                        amount: e.amount.toFixed(2),
                    };
                }),
            });
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, bonusManagementByIdGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetBannerById];
}

export function useGetActiveBannerListAsyncEndpoint() {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // HOOKS
    // ============================================

    const handleGetActiveBannerList = useCallback(() => {
        bonusManagementActiveListGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'POST',
        options: {
            data: {
                bonusStatus: 'Active',
                isShowOnApp: 'true',
                pageSize: 999,
            },
        },
        trigger,
        url: `${VERSION.V1}${PROMOTIONS.ROOT}/query`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);

            bonusManagementActiveListGetSuccess(dispatch, {
                sortingList: response.data.data.map((item) => {
                    return {
                        bonusCode: item.code,
                        bonusId: item.id,
                        sortingOrder: item.sortingOrder,
                    };
                }),
            });
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, bonusManagementActiveListGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetActiveBannerList];
}

export function useUpdateBonusSortingAsycnEndpoint() {
    const [state, dispatch] = useStore();

    const [trigger, setTrigger] = useState(null);

    const sortingList = getStateProp(state, 'bonus.bonusManagement.sortingList', []);

    // ============================================
    // HOOKS
    // ============================================

    const handleUpdateBonusSorting = useCallback(() => {
        bonusManagementSortingUpdate(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'PATCH',
        options: {
            data: {
                sortInfos: _.map(sortingList, (list) => {
                    return {
                        id: list.bonusId,
                        sortOrder: list.sortingOrder,
                    };
                }),
            },
        },
        trigger,
        url: `${VERSION.V1}${PROMOTIONS.SORT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bonusManagementSortingUpdateSuccess(dispatch);
            message.success(getTranslation('Sorting Updated'));
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, bonusManagementSortingUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateBonusSorting, response];
}

export function useGetBonusIpAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { riskMonitor } = getStateProp(state, 'bonus');
    const { pageNo, pageSize } = getStateProp(riskMonitor, 'paging');
    const filters = getStateProp(riskMonitor, 'filters', {});

    // ============================================
    // HOOKS
    // ============================================

    const handleGetBonusIp = useCallback(() => {
        getBonusIp(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS.IP}`,
    });

    useEffect(() => {
        handleGetBonusIp();
    }, [handleGetBonusIp]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getBonusIpSuccess(dispatch, {
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, getBonusIpFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetBonusIp];
}

export function useGetBonusRedeemAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const [ipAddress, setIpAddress] = useState('');
    const { riskMonitor } = getStateProp(state, 'bonus');
    const { pageNo, pageSize } = getStateProp(riskMonitor, 'bonusRedemptionPaging');

    // ============================================
    // HOOKS
    // ============================================
    const handleGetBonusRedeem = (e) => {
        getBonusRedeem(dispatch);
        setIpAddress(e);
        setTrigger(+new Date());
    };

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger && ipAddress,
        method: 'GET',
        options: {
            params: {
                ipAddress,
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS.REDEEM}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getBonusRedeemSuccess(dispatch, {
                bonusRedemptionList: response.data.data,
                bonusRedemptionPaging: response.data.paging,
            });
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, getBonusRedeemFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetBonusRedeem];
}

export function useGetBonusRegistrationAsyncEndpoint() {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);
    const [ipAddress, setIpAddress] = useState('');

    // ============================================
    // METHODS
    // ============================================
    const handleGetBonusRegistration = (e) => {
        getBonusRegistration(dispatch);
        setIpAddress(e);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger && ipAddress,
        method: 'GET',
        options: {
            params: {
                ipAddress,
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.SEARCH_IP}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getBonusRegistrationSuccess(dispatch, {
                totalRegistrationList: response.data.data,
            });
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, getBonusRegistrationFailure);
    useToasterErrorMessage(error, errorMsg);
    return [loading, handleGetBonusRegistration];
}
