import React from 'react';

import { Card, Form } from 'antd';

import {
    bankCardConfigurationDropFilters,
    onCloseConfirmModal,
} from './bankAccountConfigurationActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useGetBankCardConfigurationAsyncEndpoint } from '../../../services/bankAccountService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import BankAccountConfigurationFilters from './BankAccountConfigurationFilters';
import BankAccountConfigurationResultsTable from './table/BankAccountConfigurationResultsTable';
import ConfigurationModal from './modal/ConfigurationModal';

const BankAccountConfiguration = () => {
    const [state, dispatch] = useStore();
    const bankAccountConfiguration = getStateProp(state, 'payments.bankAccountConfiguration');
    const confirmModalVisible = getStateProp(bankAccountConfiguration, 'confirmModalVisible');

    // ============================================
    // METHODS
    // ============================================

    const onCloseModal = () => onCloseConfirmModal(dispatch);

    const fetchAll = () => {
        bankCardConfigurationDropFilters(dispatch);
        handleGetBankCardConfiguration();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetBankCardConfiguration] = useGetBankCardConfigurationAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Bank Account Configuration')}>
            <Form onFinish={handleGetBankCardConfiguration}>
                <BankAccountConfigurationFilters />
                <ActionButtons onDropFilters={fetchAll} />
            </Form>

            <BankAccountConfigurationResultsTable loading={loading} />
            {confirmModalVisible && <ConfigurationModal onCancel={onCloseModal} />}
        </Card>
    );
};

export default BankAccountConfiguration;
