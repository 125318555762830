import React from 'react';

import { Table } from 'antd';

import { getColumns } from './VerifiedResultTableMeta';
import { getStateProp } from '../../../../../common/helpers/misc';
import { useStore } from '../../../../../store/StateProvider';

const VerifiedResultTable = ({ loading }) => {
    const [state] = useStore();

    const tableData = getStateProp(state, 'announcement.messageCreate.verifiedResults.valid', []);

    return (
        <Table
            id="results-table"
            columns={getColumns()}
            dataSource={tableData}
            rowKey={(record) => record.userName}
            loading={loading}
            size="small"
            align="left"
            bordered
        />
    );
};

export default VerifiedResultTable;
