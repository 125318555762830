export const ROUTES = {
    DASHBOARD: {
        ADMINISTRATION: {
            CURRENCY_SETTING: '/dashboard/admin/currency-setting',
            GROUP_AUTHORITY: {
                ADD: '/dashboard/admin/group-setting/add',
                EDIT: '/dashboard/admin/group-setting/:accessGroupId',
                LIST: '/dashboard/admin/group-setting/list',
                ROOT: '/dashboard/admin/group-setting',
            },
            MAXBET_CONFIGURATION: '/dashboard/admin/maxbet-configure',
            ROOT: '/dashboard/admin',
            USER_AUTHORITY_SETTING: '/dashboard/admin/user-setting',
        },
        AFFILIATE: {
            AFFILIATE_GENERATOR: '/dashboard/affiliate/generator',
            AFFILIATE_VERIFICATION: '/dashboard/affiliate/verification',
            ROOT: '/dashboard/affiliate',
        },
        ANNOUNCEMENT_SYSTEM: {
            ANNOUNCEMENT_CREATE: '/dashboard/announcement/announcement-create',
            ANNOUNCEMENT_HISTORY: {
                GLOBAL: '/dashboard/announcement/announcement-history/global',
                PERSONAL: '/dashboard/announcement/announcement-history/personal',
                ROOT: '/dashboard/announcement/announcement-history',
            },
            ANNOUNCEMENT_VERIFICATION: {
                GLOBAL: '/dashboard/announcement/announcement-verification/global',
                PERSONAL: '/dashboard/announcement/announcement-verification/personal',
                ROOT: '/dashboard/announcement/announcement-verification',
            },
            MESSAGE_CREATE: '/dashboard/announcement/message-create',
            ROOT: '/dashboard/announcement',
        },
        BALANCE: {
            BALANCE_MANAGEMENT: '/dashboard/balance/balance-management',
            CRYPTOCURRENCY_CORRECTION: '/dashboard/balance/cryptocurrency-correction',
            DEPOSIT_CORRECTION: '/dashboard/balance/deposit-correction',
            EWALLET_CORRECTION: '/dashboard/balance/ewallet-correction',
            ROOT: '/dashboard/balance',
        },
        BANNER: {
            BANNER_MANAGEMENT: '/dashboard/banner/banner-management',
            BANNER_SORTING: '/dashboard/banner/banner-sorting',
            CREATE_BANNER: '/dashboard/banner/create-banner',
            ROOT: '/dashboard/banner',
        },
        BET: {
            BET_LIST: '/dashboard/bet/bet-list',
            ROOT: '/dashboard/bet',
            TURNOVER_CHECK: '/dashboard/bet/turnover-check',
        },
        BONUS: {
            BONUS_CATEGORY: '/dashboard/bonus/bonus-category',
            BONUS_MANAGEMENT: '/dashboard/bonus/bonus-management',
            BONUS_RECORDS: '/dashboard/bonus/bonus-record',
            CREATE_BONUS: '/dashboard/bonus/create-bonus',
            MANUAL_BONUS_MANAGEMENT: {
                BATCH: '/dashboard/bonus/manual-management/batch',
                NORMAL: '/dashboard/bonus/manual-management/normal',
                ROOT: '/dashboard/bonus/manual-management',
            },
            REQUEST_BONUS: '/dashboard/bonus/request-bonus',
            RISK_MONITOR: '/dashboard/bonus/risk-monitor',
            ROOT: '/dashboard/bonus',
        },
        CHANGE_PASSWORD: '/dashboard/change-password',
        FILE_SYSTEM: '/dashboard/file-system',
        HOT_EVENTS: '/dashboard/hot-events',
        NEWS: {
            CREATE: '/dashboard/news/create',
            LIST: '/dashboard/news/list',
            ROOT: '/dashboard/news',
        },
        PAYMENTS: {
            BANK_ACCOUNT: {
                ACTIVE: '/dashboard/payments/bank-account-setup/active',
                CONFIGURATION: '/dashboard/payments/bank-account-configuration',
                DEACTIVATED: '/dashboard/payments/bank-account-setup/deactivated',
                ROOT: '/dashboard/payments/bank-account-setup',
                STATEMENT: '/dashboard/payments/bank-account-statement',
            },
            CRYPTOCURRENCY: {
                DEPOSIT: '/dashboard/payments/cryptocurrency/deposit',
                MERCHANT_CONFIGURATION: '/dashboard/payments/crypto-merchant-configuration',
                ROOT: '/dashboard/payments/cryptocurrency',
                WITHDRAWAL: '/dashboard/payments/cryptocurrency/withdrawal',
            },
            EWALLET: {
                ACTIVE: '/dashboard/payments/e-wallet-setup/active',
                CONFIGURATION: '/dashboard/payments/e-wallet-configuration',
                DEACTIVATED: '/dashboard/payments/e-wallet-setup/deactivated',
                ROOT: '/dashboard/payments/e-wallet-setup',
            },
            EWALLET_TRANSFERS: '/dashboard/payments/ewalletTransfers',
            EWALLET_WITHDRAWALS: '/dashboard/payments/ewallet-withdrawals',
            MERCHANT_ACCOUNT: {
                ACTIVE: '/dashboard/payments/merchant-setup/active',
                CONFIGURATION: '/dashboard/payments/merchant-configuration',
                DEACTIVATED: '/dashboard/payments/merchant-setup/deactivated',
                ROOT: '/dashboard/payments/merchant-setup',
            },
            MERCHANT_DEPOSITS: '/dashboard/payments/deposits',
            OFFLINE_DEPOSITS: '/dashboard/payments/offlineDeposits',
            ROOT: '/dashboard/payments',
            TRANSACTION_AMOUNT_SETTING: {
                BANK: '/dashboard/payments/transaction-amount-setting/bank',
                EWALLET: '/dashboard/payments/transaction-amount-setting/ewallet',
                ROOT: '/dashboard/payments/transaction-amount-setting',
            },
            WITHDRAWALS: '/dashboard/payments/withdrawals',
        },
        PLAYERS: {
            CHANNELS: '/dashboard/players/channels',
            DANGER_LEVEL: '/dashboard/players/danger-level',
            LOGIN: '/dashboard/players/login-summary',
            MODIFICATION_HISTORY: '/dashboard/players/modification-history',
            PENDING_MODIFICATIONS: '/dashboard/players/pending-modifications',
            PLAYERS_LIST: '/dashboard/players/list',
            PLAYERS_LIST_MASKED: '/dashboard/players/list/isMasked',
            PLAYER_DETAILS: {
                BANK_CARDS: '/bank-cards',
                E_WALLET: '/e-wallet',
                MEMBER_INFO: '/member-info',
                MESSAGES: '/messages',
                ROOT: '/dashboard/players/list/:playerId',
                ROOT_MASKED: '/dashboard/players/list/isMasked/:playerId',
                USER_SESSIONS: '/session-log',
            },
            REGISTRATION_DETAILS: '/dashboard/players/registration-details',
            ROOT: '/dashboard/players',
            SESSIONS: '/dashboard/players/sessions',
        },
        PUSH_NOTIFICATION: {
            CREATE: {
                GENERAL: '/dashboard/push-notification/create/general',
                ROOT: '/dashboard/push-notification/create',
                TARGET: '/dashboard/push-notification/create/target',
            },
            MANAGEMENT: {
                GENERAL: '/dashboard/push-notification/management/general',
                ROOT: '/dashboard/push-notification/management',
                TARGET: '/dashboard/push-notification/management/target',
            },
            ROOT: '/dashboard/push-notification',
        },
        REBATE: {
            GENERATOR: '/dashboard/rebate/generator',
            MANAGEMENT: '/dashboard/rebate/management',
            ROOT: '/dashboard/rebate',
            SETTING: '/dashboard/rebate/setting',
        },
        REFERRAL: {
            CREATE: '/dashboard/referral/create',
            INQUIRY: '/dashboard/referral/inquiry',
            LIST: '/dashboard/referral/list',
            REFERRAL_REPORT: '/dashboard/referral/referral-report',
            REFERRER_REPORT: '/dashboard/referral/referrer-report',
            ROOT: '/dashboard/referral',
        },
        REPORTS: {
            OVERVIEW: '/dashboard/reports/overview',
            PLAYER_WALLET_BALANCE: '/dashboard/reports/player-wallet-balance',
            PLAYER_WIN_LOSS: '/dashboard/reports/player-win-loss',
            ROOT: '/dashboard/reports',
        },
        REWARDS_MANAGEMENT: {
            DAILY_LOGIN_MANAGEMENT: '/dashboard/rewards-management/daily-login-management',
            DAILY_LOGIN_SETUP: '/dashboard/rewards-management/daily-login-setup',
            ROOT: '/dashboard/rewards-management',
        },
        ROOT: '/dashboard',
        TRANSACTION_HISTORY: {
            AFFILIATE_RECORDS: '/dashboard/transaction-history/affiliate-records',
            BONUS_RECORDS: '/dashboard/transaction-history/bonus-records',
            CRYPTOCURRENCY_RECORDS: {
                DEPOSIT: '/dashboard/transaction-history/cryptocurrency-records/deposit',
                ROOT: '/dashboard/transaction-history/cryptocurrency-records',
                WITHDRAWAL: '/dashboard/transaction-history/cryptocurrency-records/withdrawal',
            },
            DEPOSIT_RECORDS: '/dashboard/transaction-history/deposit-records',
            REBATE_RECORDS: '/dashboard/transaction-history/rebates-records',
            REFERRAL_RECORDS: '/dashboard/transaction-history/referral-records',
            REWARDS_RECORDS: '/dashboard/transaction-history/rewards-records',
            ROOT: '/dashboard/transaction-history',
            TRANSACTION_RECORDS: '/dashboard/transaction-history/transaction-records',
            TURNOVER_RECORDS: '/dashboard/transaction-history/turnover-records',
            VIP_RECORDS: '/dashboard/transaction-history/vip-records',
            WALLET_TRANSFERS: '/dashboard/transaction-history/wallet-transfers',
            WITHDRAWAL_RECORDS: '/dashboard/transaction-history/withdrawal-records',
        },
        TRANSACTION_MONITORING: '/dashboard/transaction-monitoring',
        VIP: {
            ROOT: '/dashboard/vip',
            VIP_HISTORY: '/dashboard/vip/vip-history',
            VIP_SETUP: '/dashboard/vip/vip-setup',
        },
        WHATS_NEW: {
            CREATE: '/dashboard/whats-new/create',
            ROOT: '/dashboard/whats-new',
        },
    },
    LOGIN: '/login',
    ROOT: '/',
};

export const ROLES = {
    OPERATOR: 'operator',
};

export const MENU = [
    {
        children: [
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Players List',
                path: ROUTES.DASHBOARD.PLAYERS.PLAYERS_LIST,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Registration Details',
                path: ROUTES.DASHBOARD.PLAYERS.REGISTRATION_DETAILS,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Channel Inquiry',
                path: ROUTES.DASHBOARD.PLAYERS.CHANNELS,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'User Sessions',
                path: ROUTES.DASHBOARD.PLAYERS.SESSIONS,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Pending Modifications',
                path: ROUTES.DASHBOARD.PLAYERS.PENDING_MODIFICATIONS,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Modifications History',
                path: ROUTES.DASHBOARD.PLAYERS.MODIFICATION_HISTORY,
            },
        ],
        icon: 'user',
        key: 'Player Management',
        path: ROUTES.DASHBOARD.PLAYERS.ROOT,
    },
    {
        children: [
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Deposit Verification',
                path: ROUTES.DASHBOARD.PAYMENTS.OFFLINE_DEPOSITS,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Merchant Verification',
                path: ROUTES.DASHBOARD.PAYMENTS.MERCHANT_DEPOSITS,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Withdrawal Verification',
                path: ROUTES.DASHBOARD.PAYMENTS.WITHDRAWALS,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'E-Wallet Withdrawal Verification',
                path: ROUTES.DASHBOARD.PAYMENTS.EWALLET_WITHDRAWALS,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Bank Account Setup',
                path: ROUTES.DASHBOARD.PAYMENTS.BANK_ACCOUNT.ROOT,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Bank Account Configuration',
                path: ROUTES.DASHBOARD.PAYMENTS.BANK_ACCOUNT.CONFIGURATION,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Merchant Setup',
                path: ROUTES.DASHBOARD.PAYMENTS.MERCHANT_ACCOUNT.ROOT,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Merchant Configuration',
                path: ROUTES.DASHBOARD.PAYMENTS.MERCHANT_ACCOUNT.CONFIGURATION,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Crypto Merchant Configuration',
                path: ROUTES.DASHBOARD.PAYMENTS.CRYPTOCURRENCY.MERCHANT_CONFIGURATION,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Bank Account Statement',
                path: ROUTES.DASHBOARD.PAYMENTS.BANK_ACCOUNT.STATEMENT,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Cryptocurrency',
                path: ROUTES.DASHBOARD.PAYMENTS.CRYPTOCURRENCY.ROOT,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Transaction Amount Setting',
                path: ROUTES.DASHBOARD.PAYMENTS.TRANSACTION_AMOUNT_SETTING.ROOT,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'E-Wallet Setup',
                path: ROUTES.DASHBOARD.PAYMENTS.EWALLET.ROOT,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'E-Wallet Configuration',
                path: ROUTES.DASHBOARD.PAYMENTS.EWALLET.CONFIGURATION,
            },
        ],
        icon: 'transaction',
        key: 'Payments',
        path: ROUTES.DASHBOARD.PAYMENTS.ROOT,
    },
    {
        children: [
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Transaction Records',
                path: ROUTES.DASHBOARD.TRANSACTION_HISTORY.TRANSACTION_RECORDS,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Deposit Records',
                path: ROUTES.DASHBOARD.TRANSACTION_HISTORY.DEPOSIT_RECORDS,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Withdrawal Records',
                path: ROUTES.DASHBOARD.TRANSACTION_HISTORY.WITHDRAWAL_RECORDS,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Turnover Records',
                path: ROUTES.DASHBOARD.TRANSACTION_HISTORY.TURNOVER_RECORDS,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Cryptocurrency Records',
                path: ROUTES.DASHBOARD.TRANSACTION_HISTORY.CRYPTOCURRENCY_RECORDS.ROOT,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Bonus Records',
                path: ROUTES.DASHBOARD.TRANSACTION_HISTORY.BONUS_RECORDS,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Wallet Transfers',
                path: ROUTES.DASHBOARD.TRANSACTION_HISTORY.WALLET_TRANSFERS,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Affiliate Records',
                path: ROUTES.DASHBOARD.TRANSACTION_HISTORY.AFFILIATE_RECORDS,
            },
        ],
        icon: 'history',
        key: 'Transaction History',
        path: ROUTES.DASHBOARD.TRANSACTION_HISTORY.ROOT,
    },
    {
        allowedRoles: [ROLES.OPERATOR],
        icon: 'monitor',
        key: 'Transaction Monitoring',
        path: ROUTES.DASHBOARD.TRANSACTION_MONITORING,
    },
    {
        children: [
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Balance Management',
                path: ROUTES.DASHBOARD.BALANCE.BALANCE_MANAGEMENT,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Deposit Correction',
                path: ROUTES.DASHBOARD.BALANCE.DEPOSIT_CORRECTION,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Cryptocurrency Correction',
                path: ROUTES.DASHBOARD.BALANCE.CRYPTOCURRENCY_CORRECTION,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'E-Wallet Correction',
                path: ROUTES.DASHBOARD.BALANCE.EWALLET_CORRECTION,
            },
        ],
        icon: 'dollar',
        key: 'Balance Management',
        path: ROUTES.DASHBOARD.BALANCE.ROOT,
    },
    {
        children: [
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Turnover Check',
                path: ROUTES.DASHBOARD.BET.TURNOVER_CHECK,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Bet List',
                path: ROUTES.DASHBOARD.BET.BET_LIST,
            },
        ],
        icon: 'gold',
        key: 'Betting Center',
        path: ROUTES.DASHBOARD.BET.ROOT,
    },
    {
        allowedRoles: [ROLES.OPERATOR],
        children: [
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Create News',
                path: ROUTES.DASHBOARD.NEWS.CREATE,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'News Management',
                path: ROUTES.DASHBOARD.NEWS.LIST,
            },
        ],
        icon: 'read',
        key: 'News',
        path: ROUTES.DASHBOARD.NEWS.ROOT,
    },
    {
        children: [
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Create Bonus',
                path: ROUTES.DASHBOARD.BONUS.CREATE_BONUS,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Bonus Management',
                path: ROUTES.DASHBOARD.BONUS.BONUS_MANAGEMENT,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Request Bonus',
                path: ROUTES.DASHBOARD.BONUS.REQUEST_BONUS,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Manual Bonus Management',
                path: ROUTES.DASHBOARD.BONUS.MANUAL_BONUS_MANAGEMENT.ROOT,
            },
        ],
        icon: 'percentage',
        key: 'Bonus',
        path: ROUTES.DASHBOARD.BONUS.ROOT,
    },
    {
        children: [
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Create Announcement',
                path: ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.ANNOUNCEMENT_CREATE,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Create Message',
                path: ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.MESSAGE_CREATE,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Announcement Verification',
                path: ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.ANNOUNCEMENT_VERIFICATION.ROOT,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Announcement History',
                path: ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.ANNOUNCEMENT_HISTORY.ROOT,
            },
        ],
        icon: 'read',
        key: 'Announcement System',
        path: ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.ROOT,
    },
    {
        children: [
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Group Authority Setting',
                path: ROUTES.DASHBOARD.ADMINISTRATION.GROUP_AUTHORITY.ROOT,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'User Authority Setting',
                path: ROUTES.DASHBOARD.ADMINISTRATION.USER_AUTHORITY_SETTING,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Currency Setting',
                path: ROUTES.DASHBOARD.ADMINISTRATION.CURRENCY_SETTING,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Maxbet Configuration',
                path: ROUTES.DASHBOARD.ADMINISTRATION.MAXBET_CONFIGURATION,
            },
        ],
        icon: 'hdd',
        key: 'Administration',
        path: ROUTES.DASHBOARD.ADMINISTRATION.ROOT,
    },
    {
        allowedRoles: [ROLES.OPERATOR],
        children: [
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Create Referral',
                path: ROUTES.DASHBOARD.REFERRAL.CREATE,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Referral Management',
                path: ROUTES.DASHBOARD.REFERRAL.LIST,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Referrer Report',
                path: ROUTES.DASHBOARD.REFERRAL.REFERRER_REPORT,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Referral Report',
                path: ROUTES.DASHBOARD.REFERRAL.REFERRAL_REPORT,
            },
            {
                allowedRoles: [ROLES.OPERATOR],
                key: 'Referral Inquiry',
                path: ROUTES.DASHBOARD.REFERRAL.INQUIRY,
            },
        ],
        icon: 'read',
        key: 'Referral',
        path: ROUTES.DASHBOARD.REFERRAL.ROOT,
    },
];

export const routeNamesMap = new Map([
    [ROUTES.DASHBOARD.PLAYERS.PLAYERS_LIST, 'Player List (Admin)'],
    [ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.ROOT, 'Player List (Admin)'],
    [ROUTES.DASHBOARD.PLAYERS.PLAYERS_LIST_MASKED, 'Player List'],
    [ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.ROOT_MASKED, 'Player List'],
    [ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.BANK_CARDS, 'Player List'],
    [ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.MEMBER_INFO, 'Player List'],
    [ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.MESSAGES, 'Player List'],
    [ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.USER_SESSIONS, 'Player List'],
    [ROUTES.DASHBOARD.PLAYERS.REGISTRATION_DETAILS, 'Registration Detail'],
    [ROUTES.DASHBOARD.PLAYERS.CHANNELS, 'Channel Inquiry'],
    [ROUTES.DASHBOARD.PLAYERS.SESSIONS, 'User Sessions'],
    [ROUTES.DASHBOARD.PLAYERS.PENDING_MODIFICATIONS, 'Pending Modification'],
    [ROUTES.DASHBOARD.PLAYERS.MODIFICATION_HISTORY, 'Modification History'],
    [ROUTES.DASHBOARD.PLAYERS.DANGER_LEVEL, 'Danger Level Management'],
    [ROUTES.DASHBOARD.PAYMENTS.OFFLINE_DEPOSITS, 'Deposit Verification'],
    [ROUTES.DASHBOARD.PAYMENTS.MERCHANT_DEPOSITS, 'Merchant Verification'],
    [ROUTES.DASHBOARD.PAYMENTS.WITHDRAWALS, 'Withdrawal Verification'],
    [ROUTES.DASHBOARD.PAYMENTS.EWALLET_WITHDRAWALS, 'Ewallet Withdrawal Verification'],
    [ROUTES.DASHBOARD.PAYMENTS.BANK_ACCOUNT.ROOT, 'Bank Account Setup'],
    [ROUTES.DASHBOARD.PAYMENTS.BANK_ACCOUNT.ACTIVE, 'Bank Account Setup'],
    [ROUTES.DASHBOARD.PAYMENTS.BANK_ACCOUNT.DEACTIVATED, 'Bank Account Setup'],
    [ROUTES.DASHBOARD.PAYMENTS.BANK_ACCOUNT.CONFIGURATION, 'Bank Account Configuration'],
    [ROUTES.DASHBOARD.PAYMENTS.EWALLET.ROOT, 'E-Wallet Setup'],
    [ROUTES.DASHBOARD.PAYMENTS.EWALLET.ACTIVE, 'E-Wallet Setup'],
    [ROUTES.DASHBOARD.PAYMENTS.EWALLET.DEACTIVATED, 'E-Wallet Setup'],
    [ROUTES.DASHBOARD.PAYMENTS.EWALLET.CONFIGURATION, 'E-Wallet Configuration'],
    [ROUTES.DASHBOARD.PAYMENTS.EWALLET_TRANSFERS, 'E-Wallet Verification'],
    [ROUTES.DASHBOARD.PAYMENTS.MERCHANT_ACCOUNT.ROOT, 'Merchant Setup'],
    [ROUTES.DASHBOARD.PAYMENTS.MERCHANT_ACCOUNT.ACTIVE, 'Merchant Setup'],
    [ROUTES.DASHBOARD.PAYMENTS.MERCHANT_ACCOUNT.DEACTIVATED, 'Merchant Setup'],
    [ROUTES.DASHBOARD.PAYMENTS.MERCHANT_ACCOUNT.CONFIGURATION, 'Merchant Configuration'],
    [
        ROUTES.DASHBOARD.PAYMENTS.CRYPTOCURRENCY.MERCHANT_CONFIGURATION,
        'Crypto Merchant Configuration',
    ],
    [ROUTES.DASHBOARD.PAYMENTS.BANK_ACCOUNT.STATEMENT, 'Bank Account Statement'],
    [ROUTES.DASHBOARD.PAYMENTS.CRYPTOCURRENCY.ROOT, 'Cryptocurrency'],
    [ROUTES.DASHBOARD.PAYMENTS.CRYPTOCURRENCY.DEPOSIT, 'Cryptocurrency'],
    [ROUTES.DASHBOARD.PAYMENTS.CRYPTOCURRENCY.WITHDRAWAL, 'Cryptocurrency'],
    [ROUTES.DASHBOARD.PAYMENTS.TRANSACTION_AMOUNT_SETTING.ROOT, 'Transaction Amount Setting'],
    [ROUTES.DASHBOARD.PAYMENTS.TRANSACTION_AMOUNT_SETTING.BANK, 'Transaction Amount Setting'],
    [ROUTES.DASHBOARD.PAYMENTS.TRANSACTION_AMOUNT_SETTING.EWALLET, 'Transaction Amount Setting'],
    [ROUTES.DASHBOARD.TRANSACTION_HISTORY.TRANSACTION_RECORDS, 'Transaction Records'],
    [ROUTES.DASHBOARD.TRANSACTION_HISTORY.DEPOSIT_RECORDS, 'Deposit Records'],
    [ROUTES.DASHBOARD.TRANSACTION_HISTORY.WITHDRAWAL_RECORDS, 'Withdrawal Records'],
    [ROUTES.DASHBOARD.TRANSACTION_HISTORY.TURNOVER_RECORDS, 'Turnover Records'],
    [ROUTES.DASHBOARD.TRANSACTION_HISTORY.CRYPTOCURRENCY_RECORDS.ROOT, 'Cryptocurrency Records'],
    [ROUTES.DASHBOARD.TRANSACTION_HISTORY.CRYPTOCURRENCY_RECORDS.DEPOSIT, 'Cryptocurrency Records'],
    [
        ROUTES.DASHBOARD.TRANSACTION_HISTORY.CRYPTOCURRENCY_RECORDS.WITHDRAWAL,
        'Cryptocurrency Records',
    ],
    [ROUTES.DASHBOARD.TRANSACTION_HISTORY.BONUS_RECORDS, 'Bonus Records'],
    [ROUTES.DASHBOARD.TRANSACTION_HISTORY.WALLET_TRANSFERS, 'Wallet Transfers'],
    [ROUTES.DASHBOARD.TRANSACTION_HISTORY.AFFILIATE_RECORDS, 'Affiliate Records'],
    [ROUTES.DASHBOARD.TRANSACTION_HISTORY.VIP_RECORDS, 'VIP Records'],
    [ROUTES.DASHBOARD.TRANSACTION_HISTORY.REBATE_RECORDS, 'Rebates Records'],
    [ROUTES.DASHBOARD.TRANSACTION_MONITORING, 'Transaction Monitoring'],
    [ROUTES.DASHBOARD.BALANCE.BALANCE_MANAGEMENT, 'Balance Management'],
    [ROUTES.DASHBOARD.BALANCE.DEPOSIT_CORRECTION, 'Deposit Correction'],
    [ROUTES.DASHBOARD.BALANCE.CRYPTOCURRENCY_CORRECTION, 'Cryptocurrency Correction'],
    [ROUTES.DASHBOARD.BALANCE.EWALLET_CORRECTION, 'E-Wallet Correction'],
    [ROUTES.DASHBOARD.BET.TURNOVER_CHECK, 'Turnover Check'],
    [ROUTES.DASHBOARD.BET.BET_LIST, 'Bet List'],
    [ROUTES.DASHBOARD.NEWS.CREATE, 'Create News'],
    [ROUTES.DASHBOARD.NEWS.LIST, 'News Management'],
    [ROUTES.DASHBOARD.BONUS.CREATE_BONUS, 'Create Bonus'],
    [ROUTES.DASHBOARD.BONUS.BONUS_MANAGEMENT, 'Bonus Management'],
    [ROUTES.DASHBOARD.BONUS.REQUEST_BONUS, 'Request Bonus'],
    [ROUTES.DASHBOARD.BONUS.MANUAL_BONUS_MANAGEMENT.ROOT, 'Manual Bonus Management'],
    [ROUTES.DASHBOARD.BONUS.MANUAL_BONUS_MANAGEMENT.NORMAL, 'Manual Bonus Management'],
    [ROUTES.DASHBOARD.BONUS.MANUAL_BONUS_MANAGEMENT.BATCH, 'Manual Bonus Management'],
    [ROUTES.DASHBOARD.BONUS.BONUS_CATEGORY, 'Bonus Category'],
    [ROUTES.DASHBOARD.BONUS.BONUS_RECORDS, 'Bonus Record'],
    [ROUTES.DASHBOARD.BONUS.RISK_MONITOR, 'Risk Monitor'],
    [ROUTES.DASHBOARD.BANNER.CREATE_BANNER, 'Create Banner'],
    [ROUTES.DASHBOARD.BANNER.BANNER_MANAGEMENT, 'Banner Management'],
    [ROUTES.DASHBOARD.BANNER.BANNER_SORTING, 'Banner Sorting'],
    [ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.ANNOUNCEMENT_CREATE, 'Create Announcement'],
    [ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.MESSAGE_CREATE, 'Create Message'],
    [
        ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.ANNOUNCEMENT_VERIFICATION.ROOT,
        'Announcement Verification',
    ],
    [
        ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.ANNOUNCEMENT_VERIFICATION.GLOBAL,
        'Announcement Verification',
    ],
    [
        ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.ANNOUNCEMENT_VERIFICATION.PERSONAL,
        'Announcement Verification',
    ],
    [ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.ANNOUNCEMENT_HISTORY.ROOT, 'Announcement History'],
    [ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.ANNOUNCEMENT_HISTORY.PERSONAL, 'Announcement History'],
    [ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.ANNOUNCEMENT_HISTORY.GLOBAL, 'Announcement History'],
    [ROUTES.DASHBOARD.ADMINISTRATION.GROUP_AUTHORITY.ROOT, 'Group Authority Setting'],
    [ROUTES.DASHBOARD.ADMINISTRATION.GROUP_AUTHORITY.LIST, 'Group Authority Setting'],
    [ROUTES.DASHBOARD.ADMINISTRATION.GROUP_AUTHORITY.EDIT, 'Group Authority Setting'],
    [ROUTES.DASHBOARD.ADMINISTRATION.GROUP_AUTHORITY.ADD, 'Group Authority Setting'],
    [ROUTES.DASHBOARD.ADMINISTRATION.USER_AUTHORITY_SETTING, 'User Authority Setting'],
    [ROUTES.DASHBOARD.ADMINISTRATION.CURRENCY_SETTING, 'Currency Setting'],
    [ROUTES.DASHBOARD.ADMINISTRATION.MAXBET_CONFIGURATION, 'Maxbet Configuration'],
    [ROUTES.DASHBOARD.CHANGE_PASSWORD, 'Change Password'],
    [ROUTES.DASHBOARD.REPORTS.PLAYER_WALLET_BALANCE, 'Player Wallet Balance'],
    [ROUTES.DASHBOARD.REPORTS.PLAYER_WIN_LOSS, 'Player Win Loss'],
    [ROUTES.DASHBOARD.REFERRAL.CREATE, 'Create Referral'],
    [ROUTES.DASHBOARD.REFERRAL.LIST, 'Referral Management'],
    [ROUTES.DASHBOARD.REFERRAL.REFERRER_REPORT, 'Referrer Report'],
    [ROUTES.DASHBOARD.REFERRAL.REFERRAL_REPORT, 'Referral Report'],
    [ROUTES.DASHBOARD.REFERRAL.INQUIRY, 'Referral Inquiry'],
    [ROUTES.DASHBOARD.REWARDS_MANAGEMENT.DAILY_LOGIN_SETUP, 'Daily Login Setup'],
    [ROUTES.DASHBOARD.REWARDS_MANAGEMENT.DAILY_LOGIN_MANAGEMENT, 'Daily Login Management'],
    [ROUTES.DASHBOARD.REBATE.SETTING, 'Rebate Setting'],
    [ROUTES.DASHBOARD.REBATE.MANAGEMENT, 'Rebate Management'],
    [ROUTES.DASHBOARD.PUSH_NOTIFICATION.CREATE.ROOT, 'Create Push Notification'],
    [ROUTES.DASHBOARD.PUSH_NOTIFICATION.CREATE.GENERAL, 'Create Push Notification'],
    [ROUTES.DASHBOARD.PUSH_NOTIFICATION.CREATE.TARGET, 'Create Push Notification'],
    [ROUTES.DASHBOARD.PUSH_NOTIFICATION.MANAGEMENT.ROOT, 'Push Notification Management'],
    [ROUTES.DASHBOARD.PUSH_NOTIFICATION.MANAGEMENT.GENERAL, 'Push Notification Management'],
    [ROUTES.DASHBOARD.PUSH_NOTIFICATION.MANAGEMENT.TARGET, 'Push Notification Management'],
    [ROUTES.DASHBOARD.REBATE.GENERATOR, 'Rebate Generator'],
    [ROUTES.DASHBOARD.TRANSACTION_HISTORY.REFERRAL_RECORDS, 'Referral Records'],
    [ROUTES.DASHBOARD.TRANSACTION_HISTORY.REWARDS_RECORDS, 'Rewards Records'],
    [ROUTES.DASHBOARD.WHATS_NEW.CREATE, `Create What's New`],
    [ROUTES.DASHBOARD.FILE_SYSTEM, 'File System'],
    [ROUTES.DASHBOARD.VIP.VIP_SETUP, 'VIP Setup'],
    [ROUTES.DASHBOARD.VIP.VIP_HISTORY, 'VIP History'],
    [ROUTES.DASHBOARD.REPORTS.OVERVIEW, 'Overview'],
    [ROUTES.DASHBOARD.AFFILIATE.AFFILIATE_VERIFICATION, 'Affiliate Verification'],
    [ROUTES.DASHBOARD.AFFILIATE.AFFILIATE_GENERATOR, 'Affiliate Generator'],
    [ROUTES.DASHBOARD.HOT_EVENTS, 'Hot Events'],
]);

// For New Menu
// {
//     displayOrder: 13,
//     menuName: 'Rewards Management',
//     menuUrl: 'dashboard/rewards-management',
//     subMenus: [
//         {
//             displayOrder: 0,
//             menuName: 'Daily Login Setup',
//             menuUrl: 'dashboard/rewards-management/daily-login-setup',
//         },
//         {
//             displayOrder: 0,
//             menuName: null,
//             menuUrl: null,
//         },
//     ],
// },
export const newMenuHaventRelease = [
    {
        displayOrder: 13,
        menuName: 'Hot Events',
        menuUrl: 'dashboard/hot-events',
        subMenus: [
            {
                displayOrder: 0,
                menuName: 'Hot Events Management',
                menuUrl: 'dashboard/hot-events',
            },
        ],
    },
];

// For SubMenu

// {
//     menuName: 'Bonus',
//     subMenus: [
//         {
//             displayOrder: 5,
//             menuName: 'Bonus Category',
//             menuUrl: 'dashboard/bonus/bonus-category',
//         },
//     ],
// },
export const newSubMenuHaventRelease = [];
