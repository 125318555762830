export const DAILY_LOGIN_STATUSES = {
    ACTIVE: 'Active',
    CLOSED: 'Closed',
    INACTIVE: 'Inactive',
};

export const VIP_LEVELS = [
    {
        displayName: 'VIP 0',
        level: 0,
    },
    {
        displayName: 'VIP 1',
        level: 1,
    },
    {
        displayName: 'VIP 2',
        level: 2,
    },
    {
        displayName: 'VIP 3',
        level: 3,
    },
    {
        displayName: 'VIP 4',
        level: 4,
    },
    {
        displayName: 'VIP 5',
        level: 5,
    },
    {
        displayName: 'VIP 6',
        level: 6,
    },
    {
        displayName: 'VIP 7',
        level: 7,
    },
    {
        displayName: 'VIP 8',
        level: 8,
    },
    {
        displayName: 'VIP 9',
        level: 9,
    },
];
