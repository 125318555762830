import React, { useCallback, useEffect, useState } from 'react';

import { Col, Form, Input, Row, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';

import './BalanceManagementForm.less';
import { BALANCE_MANAGEMENT_ACTIONS } from './constants';
import {
    BALANCE_MANAGEMENT_TYPES,
    TURNOVER_ADJUST_ON,
    WALLET_PLATFORMS,
} from '../../../common/constants/misc';
import { ButtonGreen, ButtonTransparent } from '../../../common/components/buttons/Buttons';
import {
    adjustmentInfoSet,
    balanceManagementFormReset,
    performBalanceManagementOperation,
} from './balanceManagementActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';
import Loader from '../../../common/components/Loader';

const BalanceManagementForm = ({ operationLoading, operationSuccess, playerDetailsLoading }) => {
    const [form] = Form.useForm();
    const { resetFields, setFieldsValue } = form;
    const [state, dispatch] = useStore();
    const { adjustmentInfo } = getStateProp(state, 'balance.balanceManagement', {});
    const [turnOverDisable, setTurnOverDisable] = useState(false);
    const [turnoverAmountDisable, setTurnoverAmountDisable] = useState(true);

    // ============================================
    // METHODS
    // ============================================

    const getTurnoverAmount = () => {
        const adjustOn = getStateProp(
            adjustmentInfo,
            'adjustOn',
            TURNOVER_ADJUST_ON.TURNOVER_REQUIREMENT,
        );

        if (adjustOn === TURNOVER_ADJUST_ON.TURNOVER_REQUIREMENT) {
            return (
                getStateProp(adjustmentInfo, 'turnOverRequirement') *
                    getStateProp(adjustmentInfo, 'amount') || 0
            );
        } else {
            return getStateProp(adjustmentInfo, 'turnoverAmount');
        }
    };

    const setAdjustmentInfo = (inputName, value) => {
        adjustmentInfoSet(dispatch, { inputName, value });

        if (inputName === 'adjustOn') {
            setTurnOverInputDisable(value);
            adjustmentInfoSet(dispatch, { inputName: 'turnOverRequirement', value: 1 });
            adjustmentInfoSet(dispatch, { inputName: 'turnoverAmount', value: null });
        }
    };

    const setTurnOverInputDisable = (value) => {
        if (value === TURNOVER_ADJUST_ON.TURNOVER_AMOUNT) {
            setTurnOverDisable(true);
            setTurnoverAmountDisable(false);
        } else {
            setTurnOverDisable(false);
            setTurnoverAmountDisable(true);
        }
    };

    const setColor = (value) => {
        if (value === BALANCE_MANAGEMENT_ACTIONS.BALANCE_DEDUCTION) return 'red';
        else return 'black';
    };

    const submitForm = () => {
        if (getStateProp(adjustmentInfo, 'action') === BALANCE_MANAGEMENT_ACTIONS.BALANCE_ADDITION)
            performBalanceManagementOperation(dispatch, {
                operation: 'addition',
            });
        else if (
            getStateProp(adjustmentInfo, 'action') === BALANCE_MANAGEMENT_ACTIONS.BALANCE_DEDUCTION
        )
            performBalanceManagementOperation(dispatch, {
                operation: 'deduction',
            });
    };

    const resetForm = useCallback(() => {
        balanceManagementFormReset(dispatch);
        setTurnOverDisable(true);
        resetFields();
    }, [dispatch, resetFields]);

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (operationSuccess) {
            resetForm();
        }
    }, [operationSuccess, resetForm]);

    useEffect(() => {
        setFieldsValue({
            action: getStateProp(adjustmentInfo, 'action'),
            adjustOn: getStateProp(
                adjustmentInfo,
                'adjustOn',
                TURNOVER_ADJUST_ON.TURNOVER_REQUIREMENT,
            ),
            amount: getStateProp(adjustmentInfo, 'amount'),
            currency: getStateProp(adjustmentInfo, 'currency'),
            currentTurnover: getStateProp(adjustmentInfo, 'currentTurnover', 0),
            memberLevel: getStateProp(adjustmentInfo, 'memberLevel'),
            missingBalanceNo: getStateProp(adjustmentInfo, 'missingBalanceNo'),
            remarks: getStateProp(adjustmentInfo, 'remarks'),
            turnoverRequirement: getStateProp(adjustmentInfo, 'turnOverRequirement'),
            type: getStateProp(adjustmentInfo, 'type'),
            userNameLike: getStateProp(adjustmentInfo, 'userNameLike'),
        });
    }, [adjustmentInfo, setFieldsValue]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form
            form={form}
            name="balance-management"
            className="balance-management-form"
            onFinish={submitForm}
        >
            <Row>
                <Col span={12} className="balance-management__form-column">
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Username')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="userNameLike"
                                rules={[
                                    {
                                        message: getTranslation('Please input username!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<SearchOutlined />}
                                    placeholder={getTranslation('Search for username')}
                                    onChange={(e) =>
                                        setAdjustmentInfo('userNameLike', e.target.value)
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">{getTranslation('Member Level')}</label>{' '}
                        </Col>
                        <Col span={12}>
                            <Form.Item name="memberLevel">
                                <Input
                                    disabled
                                    prefix={
                                        playerDetailsLoading && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Current Effective Turnover Required')}
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="currentTurnover">
                                <Input
                                    disabled
                                    prefix={
                                        playerDetailsLoading && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Action')} <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="action"
                                rules={[
                                    {
                                        message: getTranslation('Please select action!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    onChange={(value) => setAdjustmentInfo('action', value)}
                                    style={{
                                        color: setColor(getStateProp(adjustmentInfo, 'action')),
                                    }}
                                >
                                    {_.map(BALANCE_MANAGEMENT_ACTIONS, (action) => (
                                        <Select.Option value={action} key={action}>
                                            {action.toLocaleUpperCase()}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Type')} <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="type"
                                rules={[
                                    {
                                        message: getTranslation('Please select type!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    onChange={(value) => setAdjustmentInfo('type', value)}
                                >
                                    {_.map(BALANCE_MANAGEMENT_TYPES, (type) => (
                                        <Select.Option value={type} key={type}>
                                            {getTranslation(type).toLocaleUpperCase()}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Wallet')} <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="walletType"
                                rules={[
                                    {
                                        message: getTranslation('Please select wallet type!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    onChange={(value) => setAdjustmentInfo('walletType', value)}
                                >
                                    {_.map(WALLET_PLATFORMS, (types) => (
                                        <Select.Option
                                            value={_.replace(types, ' ', '')}
                                            key={types}
                                        >
                                            {_.replace(types, ' ', '')}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>

                <Col span={12} className="balance-management__form-column">
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Currency')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="currency">
                                <Input
                                    disabled
                                    prefix={
                                        playerDetailsLoading && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Amount')} <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="amount"
                                rules={[
                                    {
                                        message: getTranslation('Please input amount!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) => setAdjustmentInfo('amount', e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Adjust On')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="adjustOn">
                                <Select
                                    onChange={(value) => setAdjustmentInfo('adjustOn', value)}
                                    style={{
                                        color: setColor(getStateProp(adjustmentInfo, 'adjustOn')),
                                    }}
                                >
                                    {_.map(TURNOVER_ADJUST_ON, (method) => (
                                        <Select.Option value={method} key={method}>
                                            {method.toLocaleUpperCase()}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Turnover Requirement')}
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="turnoverRequirement">
                                <Input
                                    onChange={(e) =>
                                        setAdjustmentInfo('turnOverRequirement', e.target.value)
                                    }
                                    disabled={turnOverDisable}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Turnover Amount')}
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item>
                                <Input
                                    onChange={(e) =>
                                        setAdjustmentInfo('turnoverAmount', e.target.value)
                                    }
                                    value={getTurnoverAmount()}
                                    disabled={turnoverAmountDisable}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Remark')} <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="remarks"
                                rules={[
                                    {
                                        message: getTranslation('Please input remark!'),
                                        required: true,
                                    },
                                    {
                                        max: 128,
                                        message: getTranslation('Remark must less than 128 words!'),
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) => setAdjustmentInfo('remarks', e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="form__footer-row" justify="end">
                <ButtonGreen
                    label="Submit"
                    className="form__submit-button"
                    loading={operationLoading}
                    htmlType="submit"
                />
                <ButtonTransparent
                    label="Cancel"
                    className="form__reset-button"
                    onClick={resetForm}
                />
            </Row>
        </Form>
    );
};

export default BalanceManagementForm;
