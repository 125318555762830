import React from 'react';

import { ButtonGray, ButtonGreen } from '../../../common/components/buttons/Buttons';
import { PAYMENT_ORDER_OPERATIONS, TRANSACTION_STATUSES } from '../../../common/constants/misc';
import { TRANSACTION_MONITORING_TYPES } from '../misc';
import { getCurrentUser } from '../../../common/helpers/api';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    openVerifyModal,
    performTransactionMonitoringOperation,
} from '../transactionMonitoringActions';
import { useStore } from '../../../store/StateProvider';

const VerifyButton = ({ type, transactionMonitoringRecord }) => {
    const [state, dispatch] = useStore();

    const {
        transactionStatus,
        auditorName,
        attendantUserName,
        attendantRealName,
        operatorName,
    } = transactionMonitoringRecord;

    const operator = getStateProp(
        state,
        'user.userName',
        getStateProp(getCurrentUser(), 'claims.userName', 'Anonymus'), //Temp fix
    );

    const disabled = [TRANSACTION_STATUSES.REJECTED, TRANSACTION_STATUSES.SUCCESS].includes(
        transactionStatus,
    );

    // ============================================
    // METHODS
    // ============================================

    const getLabel = () => {
        switch (transactionStatus) {
            case TRANSACTION_STATUSES.ATTENDING:
                return `${getTranslation('Reviewing')} ${attendantRealName}`;
            case TRANSACTION_STATUSES.REJECTED:
            case TRANSACTION_STATUSES.SUCCESS:
                return `${getTranslation('Verified')} ${operatorName}`;
            default:
                getTranslation('Verify');
        }
    };

    const openOperationModal = () => {
        performTransactionMonitoringOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.ATTEND,
            aspect: type,
            transactionMonitoringRecord,
        });
        openVerifyModal(dispatch);
    };

    // ============================================
    // RENDER
    // ============================================

    switch (type) {
        case TRANSACTION_MONITORING_TYPES.WITHDRAWAL:
            return (attendantUserName !== operator &&
                transactionStatus === TRANSACTION_STATUSES.ATTENDING) ||
                disabled ? (
                <ButtonGray disabled label={getLabel()} className="action-btn__verify" />
            ) : (
                !auditorName && (
                    <ButtonGreen
                        label={getTranslation('Verify')}
                        onClick={openOperationModal}
                        className="action-btn__verify"
                    />
                )
            );
        case TRANSACTION_MONITORING_TYPES.BALANCE_ADDITION:
        case TRANSACTION_MONITORING_TYPES.BALANCE_DEDUCTION:
            return (attendantUserName !== operator &&
                transactionStatus === TRANSACTION_STATUSES.ATTENDING) ||
                disabled ? (
                <ButtonGray disabled label={getLabel()} className="action-btn__verify" />
            ) : (
                <ButtonGreen
                    label={getTranslation('Verify')}
                    onClick={openOperationModal}
                    className="action-btn__verify"
                />
            );
        case TRANSACTION_MONITORING_TYPES.DEPOSIT_CORRECTION:
            return (
                operatorName && (
                    <ButtonGray disabled label={operatorName} className="action-btn__verify" />
                )
            );
        case TRANSACTION_MONITORING_TYPES.BONUS:
            return (
                operatorName && (
                    <ButtonGray disabled label={getLabel()} className="action-btn__verify" />
                )
            );
        default:
            return '';
    }
};

export default VerifyButton;
