import { Switch } from 'react-router-dom';
import React from 'react';

import { PrivateRoute } from '../../common/components';
import { ROUTES } from '../../common/constants/routing';
import CreatePushNotification from './create-push-notification/CreatePushNotification';
import PushNotificationManagement from './push-notification-management/PushNotificationManagement';

const PushNotification = () => {
    return (
        <Switch>
            <PrivateRoute
                path={ROUTES.DASHBOARD.PUSH_NOTIFICATION.CREATE.ROOT}
                component={CreatePushNotification}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.PUSH_NOTIFICATION.MANAGEMENT.ROOT}
                component={PushNotificationManagement}
            />
        </Switch>
    );
};

export default PushNotification;
