export const ACTIONS = {
    BANNER_DESELECTED: 'BANNER_DESELECTED',
    BANNER_MANAGEMENT_BY_ID_GET: 'BANNER_MANAGEMENT_BY_ID_GET',
    BANNER_MANAGEMENT_BY_ID_GET_FAILURE: 'BANNER_MANAGEMENT_BY_ID_GET_FAILURE',
    BANNER_MANAGEMENT_BY_ID_GET_SUCCESS: 'BANNER_MANAGEMENT_BY_ID_GET_SUCCESS',
    BANNER_MANAGEMENT_CLEAR_CACHE: 'BANNER_MANAGEMENT_CLEAR_CACHE',
    BANNER_MANAGEMENT_DUPLICATE_BY_LANGUAGE: 'BANNER_MANAGEMENT_DUPLICATE_BY_LANGUAGE',
    BANNER_MANAGEMENT_FILTERS_DROP_ALL: 'BANNER_MANAGEMENT_FILTERS_DROP_ALL',
    BANNER_MANAGEMENT_FILTER_SET: 'BANNER_MANAGEMENT_FILTER_SET',
    BANNER_MANAGEMENT_INFO_SET: 'BANNER_MANAGEMENT_INFO_SET',
    BANNER_MANAGEMENT_INFO_SET_BY_LANGUAGE: 'BANNER_MANAGEMENT_INFO_SET_BY_LANGUAGE',
    BANNER_MANAGEMENT_LIST_GET: 'BANNER_MANAGEMENT_LIST_GET',
    BANNER_MANAGEMENT_LIST_GET_FAILURE: 'BANNER_MANAGEMENT_LIST_GET_FAILURE',
    BANNER_MANAGEMENT_LIST_GET_SUCCESS: 'BANNER_MANAGEMENT_LIST_GET_SUCCESS',
    BANNER_MANAGEMENT_STATUS_SET: 'BANNER_MANAGEMENT_STATUS_SET',
    BANNER_MANAGEMENT_STATUS_SET_FAILURE: 'BANNER_MANAGEMENT_STATUS_SET_FAILURE',
    BANNER_MANAGEMENT_STATUS_SET_SUCCESS: 'BANNER_MANAGEMENT_STATUS_SET_SUCCESS',
    BANNER_MANAGEMENT_UPDATE_FAILURE: 'BANNER_MANAGEMENT_UPDATE_FAILURE',
    BANNER_MANAGEMENT_UPDATE_SUCCESS: 'BANNER_MANAGEMENT_UPDATE_SUCCESS',
    BANNER_MANGEMENT_EDIT_MODAL_CLOSE: 'BANNER_MANGEMENT_EDIT_MODAL_CLOSE',
    BANNER_MANGEMENT_EDIT_MODAL_OPEN: 'BANNER_MANGEMENT_EDIT_MODAL_OPEN',
    BANNER_MANGEMENT_VIEW_MODAL_CLOSE: 'BANNER_MANGEMENT_VIEW_MODAL_CLOSE',
    BANNER_MANGEMENT_VIEW_MODAL_OPEN: 'BANNER_MANGEMENT_VIEW_MODAL_OPEN',
    BANNER_SELECTED: 'BANNER_SELECTED',
    EDIT_BANNER_MANAGEMENT_LANGUAGE_DROP: 'EDIT_BANNER_MANAGEMENT_LANGUAGE_DROP',
    EDIT_BANNER_MANAGEMENT_LANGUAGE_SET: 'EDIT_BANNER_MANAGEMENT_LANGUAGE_SET',
    PAGINATION: {
        BANNER_MANAGEMENT_TABLE_PAGE_CHANGE: 'BANNER_MANAGEMENT_TABLE_PAGE_CHANGE',
        BANNER_MANAGEMENT_TABLE_PAGE_SIZE_CHANGE: 'BANNER_MANAGEMENT_TABLE_PAGE_SIZE_CHANGE',
    },
};

//#region modal
export const bannerEditModalOpen = (dispatch) =>
    dispatch({
        type: ACTIONS.BANNER_MANGEMENT_EDIT_MODAL_OPEN,
    });

export const bannerEditModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.BANNER_MANGEMENT_EDIT_MODAL_CLOSE,
    });

export const bannerViewModalOpen = (dispatch) =>
    dispatch({
        type: ACTIONS.BANNER_MANGEMENT_VIEW_MODAL_OPEN,
    });

export const bannerViewModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.BANNER_MANGEMENT_VIEW_MODAL_CLOSE,
    });
//#end region

//#region update form info
export const setBannerDescriptionInfoByLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_MANAGEMENT_INFO_SET_BY_LANGUAGE,
    });

export const setBannerDescriptionInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_MANAGEMENT_INFO_SET,
    });

export const setEditBannerDescriptionLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.EDIT_BANNER_MANAGEMENT_LANGUAGE_SET,
    });

export const dropEditBannerDescriptionLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.EDIT_BANNER_MANAGEMENT_LANGUAGE_DROP,
    });
//#end region

//#region select banner
export const setBannerSelected = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_SELECTED,
    });
export const setBannerDeselected = (dispatch) =>
    dispatch({
        type: ACTIONS.BANNER_DESELECTED,
    });
//#endregion

//#region Get Banner Record List
export const bannerManagementListGet = (dispatch) =>
    dispatch({
        type: ACTIONS.BANNER_MANAGEMENT_LIST_GET,
    });

export const bannerManagementListGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_MANAGEMENT_LIST_GET_SUCCESS,
    });

export const bannerManagementListGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_MANAGEMENT_LIST_GET_FAILURE,
    });
//#endregion

//#region Set Banner Status
export const bannerManagementStatusSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_MANAGEMENT_STATUS_SET,
    });

export const bannerManagementStatusSetSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.BANNER_MANAGEMENT_STATUS_SET_SUCCESS,
    });

export const bannerManagementStatusSetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_MANAGEMENT_STATUS_SET_FAILURE,
    });
//#endregion

//#region Update Banner
export const bannerManagementUpdateSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_MANAGEMENT_UPDATE_SUCCESS,
    });

export const bannerManagementUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_MANAGEMENT_UPDATE_FAILURE,
    });
//#endregion

// clear some banner data temporary store
export const bannerManagementClearCache = (dispatch) =>
    dispatch({
        type: ACTIONS.BANNER_MANAGEMENT_CLEAR_CACHE,
    });

export const bannerManagementFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_MANAGEMENT_FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.BANNER_MANAGEMENT_FILTERS_DROP_ALL,
    });

//#region banner change page
export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.BANNER_MANAGEMENT_TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.BANNER_MANAGEMENT_TABLE_PAGE_CHANGE,
    });
//#endregion

//#region Get Banner Record By Id
export const bannerManagementByIdGet = (dispatch) =>
    dispatch({
        type: ACTIONS.BANNER_MANAGEMENT_BY_ID_GET,
    });

export const bannerManagementByIdGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_MANAGEMENT_BY_ID_GET_SUCCESS,
    });

export const bannerManagementByIdGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_MANAGEMENT_BY_ID_GET_FAILURE,
    });
//#endregion

export const bannerManagementDuplicateByLanguage = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_MANAGEMENT_DUPLICATE_BY_LANGUAGE,
    });
