import { Redirect } from 'react-router-dom';
import React from 'react';

import { ROUTES } from '../constants/routing';
import { isUserLoggedIn } from '../helpers/api';

const NotFoundRoute = () => {
    return !isUserLoggedIn() ? (
        <Redirect to={ROUTES.LOGIN} />
    ) : (
        <Redirect to={ROUTES.DASHBOARD.ROOT} />
    );
};

export default NotFoundRoute;
