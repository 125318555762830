import { useHistory } from 'react-router';
import React, { useEffect } from 'react';

import { Card, Form, Modal, Row, message } from 'antd';
import _ from 'lodash';

import { ButtonGreen, ButtonTransparent } from '../../../common/components/buttons/Buttons';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FORM_ASPECT } from '../constants';
import { ROUTES } from '../../../common/constants/routing';
import {
    bannerDuplicateByLanguage,
    bannerFormClear,
    dropBannerLang,
    setBannerInfo,
    setBannerInfoByLanguage,
    setBannerLang,
} from './createBannerActions';
import { bannerFormValidation } from '../helpers';
import { editorDefaultStylingSizeSet, getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useCreateBannerOperationAsyncEndpoint } from '../../../services/bannerManagementService';
import { useStore } from '../../../store/StateProvider';
import BannerTabs from '../shared/BannerTabs';

const CreateBanner = () => {
    const [state, dispatch] = useStore();
    const history = useHistory();
    const banner = getStateProp(state, 'bonus.createBanner', {});
    const details = getStateProp(state, 'bonus.createBanner.details', []);
    const selectedLanguage = _.map(details, (value) => {
        return value.languageCode;
    }).sort();

    // ============================================
    // METHODS
    // ============================================

    const submitForm = () => {
        if (bannerFormValidation(banner, FORM_ASPECT.CREATE)) handleCreateBanner();
    };

    const resetForm = () => bannerFormClear(dispatch);

    const setBannerLanguage = (e) => {
        if (e.target.checked) {
            details.push({
                imageInBase64: null,
                languageCode: e.target.value,
            });

            setBannerLang(dispatch, details);
        } else {
            dropBannerLang(
                dispatch,
                _.reject(details, (content) => {
                    return content.languageCode === e.target.value;
                }),
            );
        }
    };

    const onContentChange = (name, value, langCode) => {
        setBannerInfoByLanguage(dispatch, {
            langCode,
            name,
            value: editorDefaultStylingSizeSet(value),
        });
    };

    const onBannerInfoChange = (name, value) => setBannerInfo(dispatch, { name, value });

    const duplicateBannerTabs = (values) => {
        const { languageCode, ...rest } = _.find(details, (detail) => {
            return detail.languageCode === values.from;
        });

        const detailsCopyTo = _.find(details, (detail) => {
            return detail.languageCode === values.to;
        });

        const { languageCode: langCode, ...restCopyTo } = detailsCopyTo;

        if (_.every(restCopyTo, _.isEmpty)) {
            _.assign(detailsCopyTo, rest);
            bannerDuplicateByLanguage(dispatch, details);
            message.success('Copied!');
        } else {
            Modal.confirm({
                content: getTranslation(
                    'Destination copy to is not empty! Are you sure want to copy ?',
                ),
                icon: <ExclamationCircleOutlined />,
                onCancel() {
                    return false;
                },
                onOk() {
                    _.assign(detailsCopyTo, rest);
                    bannerDuplicateByLanguage(dispatch, details);
                    message.success('Copied!');
                },
                title: getTranslation('Are you sure want to copy ?'),
            });
        }
    };

    // ============================================
    // HOOKS
    // ============================================

    const [creating, handleCreateBanner, created] = useCreateBannerOperationAsyncEndpoint();

    useEffect(() => {
        if (created) {
            history.push(ROUTES.DASHBOARD.BONUS.BANNER_MANAGEMENT);
        }
    }, [created, history]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Create Banner')}>
            <Form name="create-banner" className="create-banner__form" onFinish={submitForm}>
                <BannerTabs
                    setBannerLang={setBannerLanguage}
                    selectedLangs={selectedLanguage}
                    onContentChange={onContentChange}
                    onBannerInfoChange={onBannerInfoChange}
                    onDuplicateBanner={duplicateBannerTabs}
                    banner={banner}
                    isEdit={false}
                />
                <Row className="form__footer-row" justify="end">
                    <ButtonGreen
                        label={getTranslation('Submit')}
                        className="create-banner__form__submit-button"
                        htmlType="submit"
                        loading={creating}
                        style={{ marginRight: '5px' }}
                    />

                    <ButtonTransparent
                        label={getTranslation('Cancel')}
                        className="create-banner__form__reset-button"
                        onClick={resetForm}
                    />
                </Row>
            </Form>
        </Card>
    );
};

export default CreateBanner;
