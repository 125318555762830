import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './BonusCodeRedemptionModalMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const BonusCodeRedemptionModalTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const { riskMonitor } = getStateProp(state, 'bonus');
    const tableData = getStateProp(riskMonitor, 'bonusRedemptionList', []);
    const paging = getStateProp(riskMonitor, 'bonusRedemptionPaging', {});

    // ============================================
    // METHODS
    // ============================================

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns()}
            rowKey={(record, idx) => `bonus-code-redemption-${idx}`}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            dataSource={tableData}
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
        />
    );
};

export default BonusCodeRedemptionModalTable;
