import {
    DANGER_LEVELS,
    MEMBER_LEVELS,
    QUALITY_LEVELS,
    TRUST_LEVELS,
} from '../../../common/constants/misc';

export const PLAYERS_TABLE_COLUMNS = [
    {
        key: 'userName',
        required: true,
        title: 'Username',
    },
    {
        key: 'realName',
        title: 'Full Name',
    },
    {
        key: 'autoId',
        title: 'Auto ID',
    },
    {
        key: 'shortId',
        title: 'Short ID',
    },
    {
        key: 'memberLevel',
        title: 'Level',
    },
    {
        key: 'dangerLevel',
        title: 'Danger Level',
    },
    {
        key: 'qualityLevel',
        title: 'Quality Level',
    },
    {
        key: 'trustLevel',
        title: 'Trust Level',
    },
    {
        key: 'referrerUserName',
        title: 'Referral',
    },
    {
        key: 'affiliateUpline',
        title: 'Affiliate Upline',
    },
    {
        key: 'isAffiliateApplied',
        title: 'Is Affiliate',
    },
    {
        key: 'affiliateStatus',
        title: 'Affiliate Status',
    },
    {
        key: 'currencyCode',
        title: 'Currency',
    },
    {
        key: 'mobileNo',
        title: 'Mobile Number',
    },
    {
        key: 'totalBalance',
        title: 'Total Balance',
    },
    {
        key: 'createdAt',
        title: 'Register Date',
    },
    {
        key: 'activatedAt',
        title: 'Last Login Date',
    },
    {
        key: 'createdByName',
        title: 'Created By',
    },
    {
        key: 'remarks',
        title: 'Remarks',
    },
    {
        key: 'gameAccess',
        title: 'Game Access',
    },
    {
        key: 'status',
        title: 'Status',
    },
    {
        key: 'operations',
        required: true,
        title: 'Operations',
    },
];

export const PLAYER_MODAL_TYPES = {
    CHANGE_PASSWORD: {
        component: 'ChangePasswordForm',
        title: 'Reset Password',
    },
    COLUMN_SELECTION: {
        component: 'ColumnSelection',
    },
    CREATE_PLAYER: {
        component: 'CreatePlayerForm',
        title: 'Create New Member',
        width: 1000,
    },
    GAME_ACCESS: {
        component: 'UpdatePlayerGameAccess',
        title: 'Game Access',
    },
    PROHIBITION: {
        component: 'ProhibitionsModalContent',
    },
    REMARKS: {
        component: 'RemarksModalContent',
        title: 'Remarks',
    },
    STATUS: {
        component: 'StatusModalContent',
        title: 'Status',
    },
    UPDATE_GAME_ACCESS: {
        component: 'MassUpdateGameAccess',
        title: 'Update Players Game Access',
        width: 1000,
    },
};

export const PROHIBITION_TYPES = {
    BONUS: {
        PROHIBITION_VALUES: {
            ALL: 'All',
            DEPOSIT: 'Deposit Bonus',
            NORMAL: 'Normal',
            REFERRAL: 'Referral Bonus',
            WELCOME: 'Welcome Bonus',
        },
        PROHIBITION_VALUE_DEFAULT: 'All',
        PROHIBITION_VALUE_TITLE: 'Bonus type',
        TITLE: 'Bonus Prohibition',
        TYPE: 'Bonus',
    },
    DEPOSIT: {
        PROHIBITION_VALUES: {
            ALIPAY: 'AliPay',
            ALL: 'All',
            BITCOIN: 'Bitcoin',
            NORMAL: 'Normal',
            ONLINE_TRANSFER: 'Online Transfer',
            QR_CODE: 'QR Code',
            THIRD_PARTY: 'Third Party',
        },
        PROHIBITION_VALUE_DEFAULT: 'All',
        PROHIBITION_VALUE_TITLE: 'Deposit method',
        TITLE: 'Deposit Prohibition',
        TYPE: 'Deposit',
    },
    LOGIN: {
        PROHIBITION_VALUES: {
            ALL: 'All',
            GGBOOK: 'GGBOOK',
            NORMAL: 'Normal',
            OW: 'OneWorks',
        },
        PROHIBITION_VALUE_DEFAULT: 'All',
        PROHIBITION_VALUE_TITLE: 'Platforms',
        TITLE: 'Login Prohibition',
        TYPE: 'Login',
    },
    WITHDRAWAL: {
        PROHIBITION_VALUES: {
            NORMAL: 'Normal',
            RESTRICTED: 'Restricted',
        },
        PROHIBITION_VALUE_DEFAULT: 'Restricted',
        PROHIBITION_VALUE_TITLE: 'Withdrawal method',
        TITLE: 'Withdrawal Prohibition',
        TYPE: 'Withdrawal',
    },
};

export const userLevelsMap = new Map([
    ['Member Level', MEMBER_LEVELS],
    ['Trust Level', TRUST_LEVELS],
    ['Danger Level', DANGER_LEVELS],
    ['Quality Level', QUALITY_LEVELS],
]);

export const SHOW_FULL_PHONE_TO_OPERATORS = [
    'kelvin',
    'andrea',
    'allan',
    'darren',
    'roger',
    'nathan',
    'jimmy',
    'Wayne',
    'Amelia',
    'kikie',
];
