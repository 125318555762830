import React from 'react';

import { Row } from 'antd';

import { BrowserFilter, ChannelFilter, DomainFilter, IPAddressFilter } from '../filters';
import { UsernameFilter } from '../../../common/components/filters/';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';
import { userSessionsFilterSet } from './userSessionsActions';

const SessionsFilters = () => {
    const [state, dispatch] = useStore();

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => userSessionsFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.userSessions.filters.userNameLike')}
                name="userNameLike"
            />
            <DomainFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.userSessions.filters.domain')}
            />
            <ChannelFilter
                onFilterChange={setFilter}
                name={'channel'}
                value={getStateProp(state, 'players.userSessions.filters.channel')}
                placeholder={getTranslation('Channel')}
            />
            <BrowserFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.userSessions.filters.browser')}
            />
            <IPAddressFilter
                onFilterChange={setFilter}
                name="ipAddress"
                value={getStateProp(state, 'players.userSessions.filters.ipAddress')}
                placeholder={getTranslation('IP address')}
            />
        </Row>
    );
};

export default SessionsFilters;
