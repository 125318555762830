import React, { useState } from 'react';

import { FormOutlined, MonitorOutlined } from '@ant-design/icons';
import { Popover } from 'antd';

import './EditPopover.less';
import { getTranslation } from '../../../../../common/helpers/locale';

const EditPopover = ({ title, children, aspect }) => {
    const [editingModalVisible, setEditingModalVisible] = useState(false);

    // ============================================
    // METHODS
    // ============================================

    const closeModal = () => setEditingModalVisible(false);

    const handleVisibleChange = (visible) => {
        setEditingModalVisible(visible);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <>
            <Popover
                content={
                    editingModalVisible && React.cloneElement(children, { onCancel: closeModal })
                }
                title={getTranslation(title)}
                trigger="click"
                placement="bottomLeft"
                visible={editingModalVisible}
                onVisibleChange={handleVisibleChange}
            >
                {aspect === 'bankVerify' ? (
                    <MonitorOutlined className="edit-icon" />
                ) : (
                    <FormOutlined className="edit-icon" />
                )}
            </Popover>
        </>
    );
};

export default EditPopover;
