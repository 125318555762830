import React from 'react';

import { Card, Col, Form, Modal, Row } from 'antd';

import './EditModal.less';
import { ButtonGreen, ButtonTransparent } from '../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../common/helpers/locale';
import { useEditDailyLoginBonusAsyncEndpoint } from '../../../../services/rewardMangementService';
import { validateVipDatas } from './helper';
import DailyLoginDetails from '../../shared/DailyLoginDetails';

const EditModal = ({ visible, closeEditModal, selectedData, onFormValueChange, loading }) => {
    validateVipDatas(selectedData);
    // ============================================
    // METHODS
    // ============================================

    const updateForm = () => handleUpdateDailyLogin();

    const [updating, handleUpdateDailyLogin] = useEditDailyLoginBonusAsyncEndpoint(
        selectedData.id,
        selectedData,
    );

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            width={1080}
            visible={visible}
            className="daily-login-setup__edit-modal"
            onCancel={closeEditModal}
            closable={false}
            footer={null}
        >
            <Card title={getTranslation('Edit Daily Login')} loading={loading}>
                <Form
                    name="edit-daily-login"
                    className="edit-daily-login__form"
                    onFinish={updateForm}
                >
                    <DailyLoginDetails
                        dailyLoginSetup={selectedData}
                        onFormValueChange={onFormValueChange}
                    />
                    <Row className="form__footer-row" justify="end">
                        <Col>
                            <ButtonGreen
                                label={getTranslation('Update')}
                                className="form__submit-button"
                                htmlType="submit"
                                loading={updating}
                            />
                        </Col>
                        <Col>
                            <ButtonTransparent
                                label={getTranslation('Cancel')}
                                className="form__reset-button"
                                onClick={closeEditModal}
                            />
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Modal>
    );
};

export default EditModal;
