import React from 'react';

import { Input } from 'antd';

import { getTranslation } from '../../helpers/locale';

const TransactionNumberFilter = ({ value, onFilterChange, name }) => {
    return (
        <Input
            onChange={(e) => onFilterChange(name, e.target.value)}
            value={value}
            className="filter"
            placeholder={getTranslation('Task Transaction Number')}
        />
    );
};

export default TransactionNumberFilter;
