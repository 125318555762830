import React from 'react';

import { Card, Col, Divider, Input, Row } from 'antd';
import Moment from 'react-moment';
import _ from 'lodash';

import '../styles/AnnouncementVerificationForm.less';
import { ButtonGray, ButtonGreen, ButtonRed } from '../../../../common/components/buttons/Buttons';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import AnnouncementVerificationFormTabs from './AnnouncementVerificationFormTabs';

const AnnouncementVerificationForm = ({
    onVerify,
    onCancel,
    selectedAnnouncement,
    rejecting,
    verifying,
    onReject,
}) => {
    const announcementContents = _.orderBy(
        getStateProp(selectedAnnouncement, 'announcementItems', []),
        ['languageCode'],
        ['asc'],
    );
    const {
        category,
        announcementType,
        outcome,
        durationEnd,
        durationStart,
    } = selectedAnnouncement;

    const selectedCurrency = _.uniq(
        _.map(announcementContents, (item) => {
            return item.currency;
        }),
    );

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card className="announcement-form">
            <Row className="announcement__form-column">
                <Col span={24}>
                    <Row className="form__group">
                        <Col span={8}>
                            <label className="form__label">{getTranslation('Category')}</label>
                        </Col>
                        <Col span={14}>
                            <Input disabled value={category} />
                        </Col>
                    </Row>

                    <Row className="form__group">
                        <Col span={8}>
                            <label className="form__label">{getTranslation('Type')}</label>
                        </Col>
                        <Col span={14}>
                            <Input disabled value={announcementType} />
                        </Col>
                    </Row>

                    <Row className="form__group">
                        <Col span={8}>
                            <label className="form__label">{getTranslation('Duration')}</label>
                        </Col>
                        <Col span={14}>
                            <Moment format="DD/MM/YYYY HH:mm:ss" utc local>
                                {durationStart}
                            </Moment>
                            <span style={{ margin: '3px' }}>-</span>
                            <Moment format="DD/MM/YYYY HH:mm:ss" utc local>
                                {durationEnd}
                            </Moment>
                        </Col>
                    </Row>

                    <Row className="form__group">
                        <Col span={8}>
                            <label className="form__label">{getTranslation('Outcome')}</label>
                        </Col>
                        <Col span={14}>
                            <Input disabled value={outcome} />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Divider />

            <AnnouncementVerificationFormTabs
                content={announcementContents}
                currency={selectedCurrency}
            />

            <Row className="action-buttons" justify="end" align="middle">
                <ButtonGreen
                    label={getTranslation('Verify')}
                    onClick={onVerify}
                    loading={verifying}
                />
                <ButtonRed
                    label={getTranslation('Reject')}
                    loading={rejecting}
                    onClick={onReject}
                />
                <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
            </Row>
        </Card>
    );
};

export default AnnouncementVerificationForm;
