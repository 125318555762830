export const ACTIONS = {
    ANNOUNCEMENT_DELETE_MODAL_CLOSE: 'ANNOUNCEMENT_HISTORY_DELETE_MODAL_CLOSE',
    ANNOUNCEMENT_DELETE_MODAL_OPEN: 'ANNOUNCEMENT_HISTORY_DELETE_MODAL_OPEN',
    ANNOUNCEMENT_DESELECTED: 'ANNOUNCEMENT_HISTORY_DESELECTED',
    ANNOUNCEMENT_GET: 'ANNOUNCEMENT_HISTORY_GET',
    ANNOUNCEMENT_GET_FAILURE: 'ANNOUNCEMENT_HISTORY_GET_FAILURE',
    ANNOUNCEMENT_GET_SUCCESS: 'ANNOUNCEMENT_HISTORY_GET_SUCCESS',
    ANNOUNCEMENT_SELECTED: 'ANNOUNCEMENT_HISTORY_SELECTED',
    FILTER_DROP: 'ANNOUNCEMENT_HISTORY_FILTER_DROP',
    FILTER_SET: 'ANNOUNCEMENT_HISTORY_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'ANNOUNCEMENT_HISTORY_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'ANNOUNCEMENT_HISTORY_TABLE_PAGE_SIZE_CHANGE',
    },
};

//#region Announcement Delete
export const openAnnouncementDeleteModal = (dispatch, payload) => {
    dispatch({ payload, type: ACTIONS.ANNOUNCEMENT_DELETE_MODAL_OPEN });
};
export const closeAnnouncementDeleteModal = (dispatch) => {
    dispatch({ type: ACTIONS.ANNOUNCEMENT_DELETE_MODAL_CLOSE });
};
//#endregion

//#region Announcement Verify
export const setAnnouncementSelected = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ANNOUNCEMENT_SELECTED,
    });
export const setAnnouncementDeselected = (dispatch) =>
    dispatch({
        type: ACTIONS.ANNOUNCEMENT_DESELECTED,
    });
//#endregion

export const announcementHistoryGet = (dispatch) =>
    dispatch({
        type: ACTIONS.ANNOUNCEMENT_GET,
    });

export const announcementHistoryGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ANNOUNCEMENT_GET_FAILURE,
    });

export const announcementHistoryGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ANNOUNCEMENT_GET_SUCCESS,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const announcementFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const announcementFilterDrop = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTER_DROP,
    });
