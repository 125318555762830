export const ACTIONS = {
    CURRENT_COUNTRY_SET: 'CURRENT_COUNTRY_SET',
    CURRENT_LANGUAGE_SET: 'CURRENT_LANGUAGE_SET',
};

/**
 * Set current country action
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Country code
 */
export const setCurrentCountry = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CURRENT_COUNTRY_SET,
    });

/**
 * Set current language action
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Language code
 */
export const setCurrentLanguage = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CURRENT_LANGUAGE_SET,
    });
