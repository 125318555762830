import { ACTIONS } from './merchantActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function merchantDepositsReducer(
    merchantDeposits = {
        confirmModalVisible: false,
        confirmRejectModalVisible: false,
        operation: {
            action: null,
            refreshTime: 15,
            refreshTimePause: false,
        },
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        resetPageNo: false,
        verifyModalVisible: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.FILTER_SET:
            return {
                ...merchantDeposits,
                filters: {
                    ...merchantDeposits.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };
        case ACTIONS.FILTERS_DROP_ALL:
            return {
                ...merchantDeposits,
                filters: null,
                resetPageNo: true,
            };
        case ACTIONS.DEPOSITS_GET_SUCCESS:
            return {
                ...merchantDeposits,
                ...payload,
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...merchantDeposits,
                paging: {
                    ...merchantDeposits.paging,
                    pageNo:
                        payload > merchantDeposits.paging.pageCount
                            ? merchantDeposits.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...merchantDeposits,
                paging: {
                    ...merchantDeposits.paging,
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };
        case ACTIONS.RECORD_SELECT:
            return {
                ...merchantDeposits,
                selectedTransactionId: payload,
            };
        case ACTIONS.OPERATION:
            return {
                ...merchantDeposits,
                operation: {
                    ...merchantDeposits.operation,
                    ...payload,
                },
            };
        case ACTIONS.VERIFY_MODAL_OPEN:
            return {
                ...merchantDeposits,
                verifyModalVisible: true,
            };
        case ACTIONS.VERIFY_MODAL_CLOSE:
            return {
                ...merchantDeposits,
                verifyModalVisible: false,
            };
        case ACTIONS.OPERATION_SUCCESS:
        case ACTIONS.OPERATION_FAILURE:
            return {
                ...merchantDeposits,
                operation: {
                    ...merchantDeposits.operation,
                    action: null,
                },
                selectedTransactionId: [],
            };
        case ACTIONS.REFRESH_TIME_SET:
            return {
                ...merchantDeposits,
                operation: {
                    ...merchantDeposits.operation,
                    refreshTime: payload.timeOut,
                    refreshTimePause: false,
                },
            };
        case ACTIONS.REFRESH_TIME_PAUSE:
            return {
                ...merchantDeposits,
                operation: {
                    refreshTimePause: true,
                },
            };
        case ACTIONS.CONFIRM_MODAL_OPEN:
            return {
                ...merchantDeposits,
                confirmModalVisible: true,
            };
        case ACTIONS.CONFIRM_MODAL_CLOSE:
            return {
                ...merchantDeposits,
                confirmModalVisible: false,
            };
        case ACTIONS.CONFIRM_REJECT_MODAL_OPEN:
            return {
                ...merchantDeposits,
                confirmRejectModalVisible: true,
            };
        case ACTIONS.CONFIRM_REJECT_MODAL_CLOSE:
            return {
                ...merchantDeposits,
                confirmRejectModalVisible: false,
            };
        case ACTIONS.COLUMNS_SELECT:
            return {
                ...merchantDeposits,
                selectedColumns: payload,
            };
        case ACTIONS.DEPOSITS_GET:
            const pageNo = merchantDeposits.resetPageNo
                ? TABLE_PAGE_NO_DEFAULT
                : merchantDeposits.paging.pageNo;
            return {
                ...merchantDeposits,
                paging: {
                    ...merchantDeposits.paging,
                    pageNo: pageNo,
                },
                resetPageNo: false,
            };
        default:
            return {
                ...merchantDeposits,
            };
    }
}
