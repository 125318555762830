import { useEffect } from 'react';

import { API } from '../common/constants/api';
import { DEBOUNCE_DELAY_SEC } from '../common/constants/misc';
import {
    balanceManagementOperationFailure,
    performBalanceManagementOperationSuccess,
    playerDetailsGetFailure,
    playerDetailsGetSuccess,
} from '../components/balance/balance-management/balanceManagementActions';
import { clearError } from '../common/actions/errorActions';
import { getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import { message } from 'antd';
import { useDebounce } from './hooks/useDebounce';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, PLAYERS, BALANCE_MANAGEMENT } = API;

export function useGetPlayerDetailsAsyncEndpoint() {
    const [state, dispatch] = useStore();

    const userNameLike = useDebounce(
        getStateProp(state, 'balance.balanceManagement.adjustmentInfo.userNameLike'),
        DEBOUNCE_DELAY_SEC,
    );

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!userNameLike,
        method: 'GET',
        trigger: userNameLike,
        url: `${VERSION.V1}${PLAYERS.GET_BY_USERNAME}/${userNameLike}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            playerDetailsGetSuccess(dispatch, {
                currency: getStateProp(response.data, 'data.monetary.currencyCode'),
                currentTurnover: getStateProp(response.data, 'data.turnoverRequired'),
                memberLevel: getStateProp(response.data, 'data.profile.memberLevel'),
                playerId: getStateProp(response.data, 'data.id'),
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (response) {
            if (response.data.data === null) message.warning(getTranslation('User Not Found'));
        }
    }, [response]);

    const errorMsg = useError(error, playerDetailsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useBalanceManagementOperation() {
    const [state, dispatch] = useStore();

    const adjustmentInfo = getStateProp(state, 'balance.balanceManagement.adjustmentInfo');
    const action = getStateProp(state, 'balance.balanceManagement.operation');
    const playerId = getStateProp(adjustmentInfo, 'playerId');
    const remarks = getStateProp(adjustmentInfo, 'remarks');
    const balanceAdjustmentReason = getStateProp(adjustmentInfo, 'type');
    const referenceNumber = getStateProp(adjustmentInfo, 'missingBalanceNo');
    const turnoverCondition = getStateProp(adjustmentInfo, 'turnOverRequirement');
    const turnoverAmount = getStateProp(adjustmentInfo, 'turnoverAmount');
    const amount = getStateProp(adjustmentInfo, 'amount');
    const gamingPlatform = getStateProp(adjustmentInfo, 'walletType');

    // ============================================
    // METHODS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!action,
        method: 'POST',
        options: {
            data: {
                amount,
                balanceAdjustmentReason,
                gamingPlatform,
                playerId,
                referenceNumber,
                remarks,
                turnoverAmount,
                turnoverCondition,
            },
        },
        trigger: action,
        url: `${VERSION.V1}${BALANCE_MANAGEMENT}/${action}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            performBalanceManagementOperationSuccess(dispatch);
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, balanceManagementOperationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response];
}
