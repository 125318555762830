import React from 'react';

import { Input } from 'antd';

import { getTranslation } from '../../helpers/locale';

const UsernameFilter = ({ value, onFilterChange, name, ...props }) => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <Input
            value={value}
            onChange={(e) => onFilterChange(name, e.target.value)}
            placeholder={getTranslation('Username')}
            className="filter"
            {...props}
        />
    );
};

export default UsernameFilter;
