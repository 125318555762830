import React from 'react';

import { Modal, Row } from 'antd';

import { ButtonGray, ButtonGreen } from '../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../common/helpers/locale';

const ConfirmModal = ({ visible, title, onCancel, loading, onConfirm, content }) => {
    return (
        <Modal
            visible={visible}
            title={getTranslation(title)}
            centered
            closable={false}
            onCancel={onCancel}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        label={getTranslation('Confirm')}
                        loading={loading}
                        onClick={onConfirm}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
        >
            <Row justify="start">{content}</Row>
        </Modal>
    );
};

export default ConfirmModal;
