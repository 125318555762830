import React from 'react';

import { Row } from 'antd';

import {
    CurrencyFilter,
    FullNameFilter,
    PeriodFilter,
    RemarkFilter,
    TransactionNumberFilter,
    TransactionStatusFilter,
    UsernameFilter,
} from '../../../common/components/filters';
import { getStateProp } from '../../../common/helpers/misc';
import { referralRecordsFilterSet } from './referralRecordsActions';
import { useStore } from '../../../store/StateProvider';
import ReferralTypeFilter from '../filters/ReferralTypeFilter';

const ReferralRecordFilters = () => {
    const [state, dispatch] = useStore();

    const { referralRecords } = getStateProp(state, 'transactionHistory');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => referralRecordsFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <TransactionNumberFilter
                onFilterChange={setFilter}
                value={getStateProp(referralRecords, 'filters.transactionNo')}
                name="transactionNo"
            />
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(referralRecords, 'filters.playerUserName')}
                name="playerUserName"
            />
            <FullNameFilter
                onFilterChange={setFilter}
                value={getStateProp(referralRecords, 'filters.playerName')}
                name="playerName"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(referralRecords, 'filters.processedDateFrom')}
                dateFromName="processedDateFrom"
                dateTo={getStateProp(referralRecords, 'filters.processedDateTo')}
                dateToName="processedDateTo"
            />
            <TransactionStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(referralRecords, 'filters.transactionStatuses')}
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(referralRecords, 'filters.currencyCode')}
                name="currencyCode"
            />
            <ReferralTypeFilter
                onFilterChange={setFilter}
                value={getStateProp(referralRecords, 'filters.referralType')}
            />
            <RemarkFilter
                onFilterChange={setFilter}
                value={getStateProp(referralRecords, 'filters.remark')}
                name="remark"
            />
        </Row>
    );
};

export default ReferralRecordFilters;
