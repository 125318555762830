export const CRYPTO_CURRENCY_CODE = {
    BTC: 'BTC',
    ETH: 'ETH',
    USDT: 'USDT',
};

export const CRYPTO_CORRECTION_TYPE = {
    DEPOSIT: 'Deposit',
    WITHDRAWAL: 'Withdrawal',
};
