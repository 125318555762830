import React, { useState } from 'react';

import { Card, Divider, Form, message } from 'antd';

import { API } from '../../../common/constants/api';
import { DEPOSIT_TABLE_COLUMNS } from './constants';
import { PAYMENT_ORDER_OPERATIONS } from '../../../common/constants/misc';
import {
    closeConfirmRejectModal,
    closeVerifyModal,
    dropFilters,
    openConfirmRejectModal,
    pauseRefreshTableTime,
    performDepositOperation,
    selectColumns,
    setRefreshTableTime,
} from './depositsActions';
import { depositRecordsExport, getColumns } from './table/DepositsTableMeta';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    useDepositOperationAsyncEndpoint,
    useGetDepositsAsyncEndpoint,
} from '../../../services/depositService';
import { useExportAsyncEndpoint } from '../../../services/hooks/useExport';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import ColumnSelectionModal from '../../../common/components/column-selection/ColumnSelectionModal';
import ConfirmRejectModal from './verification/ConfirmRejectModal';
import DepositResultsTable from './table/DepositResultsTable';
import DepositsFilters from './DepositsFilters';
import TableRefreshSelector from '../../../common/components/TableRefreshSelector';
import TotalBalance from '../../../common/components/TotalBalance';
import VerifyModal from './verification/VerifyModal';
import useInternetStatus from '../../../services/hooks/useInternetStatus';

const { PAYMENTS } = API;

const Deposits = () => {
    const [state, dispatch] = useStore();

    const deposits = getStateProp(state, 'payments.deposits');
    const verifyModalVisible = getStateProp(deposits, 'verifyModalVisible', {});
    const transactionAttending = getStateProp(deposits, 'operation.transaction', {});
    const confirmRejectModalVisible = getStateProp(deposits, 'confirmRejectModalVisible', {});
    const selectedTransactionId = getStateProp(deposits, 'selectedTransactionId', []);
    const selectedColumns = getStateProp(deposits, 'selectedColumns', DEPOSIT_TABLE_COLUMNS);
    const filters = getStateProp(deposits, 'filters');
    const { totalRecordCount } = getStateProp(deposits, 'paging', {});

    const pageBalance = getStateProp(deposits, 'pageDataTotal', []);
    const totalBalance = getStateProp(deposits, 'dataTotal', []);
    const grandTotal = getStateProp(deposits, 'grandTotal', 0);
    const pageTotal = getStateProp(deposits, 'pageTotal', 0);

    const [columnSelectorModalVisible, setColumnSelectorModalVisible] = useState(false);

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetDeposits();
    };

    const rejectDeposits = () => {
        selectedTransactionId.length >= 1
            ? openConfirmRejectModal(dispatch)
            : message.warning(getTranslation('Please select some rows'));
    };

    const closeRejectModal = () => {
        closeConfirmRejectModal(dispatch);
    };

    const closeOperationModal = () => {
        performDepositOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.RELEASE,
        });
        closeVerifyModal(dispatch);
    };

    const setRefreshTime = (timeOut) => {
        setRefreshTableTime(dispatch, {
            timeOut,
        });
    };

    const onTimePause = () => pauseRefreshTableTime(dispatch);

    const onTimeStart = () => {
        setRefreshTableTime(dispatch, {
            timeOut: 15,
        });
    };

    const closeColumnSelectorModal = () => setColumnSelectorModalVisible(false);

    const openColumnSelectorModal = () => setColumnSelectorModalVisible(true);

    // ============================================
    // HOOKS
    // ============================================

    const [isOnline] = useInternetStatus();
    const [depositLoading, handleGetDeposits] = useGetDepositsAsyncEndpoint(isOnline);
    const [operationPending] = useDepositOperationAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        additionalCol: depositRecordsExport,
        dataMeta: getColumns(dispatch, selectedColumns),
        filters,
        method: 'GET',
        subject: getTranslation('Deposit Verification'),
        totalRecordCount,
        url: `${PAYMENTS.OFFLINE_DEPOSITS.ROOT}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card
            title={getTranslation('Deposit Verification')}
            extra={
                <TableRefreshSelector
                    onTimeChange={setRefreshTime}
                    onTimePause={onTimePause}
                    onTimeStart={onTimeStart}
                    timeOut={getStateProp(deposits, 'operation.refreshTime')}
                />
            }
        >
            <Form onFinish={handleGetDeposits}>
                <DepositsFilters />
                <ActionButtons
                    onDropFilters={fetchAll}
                    onExport={handleExportAction}
                    onReject={rejectDeposits}
                    onColumnSelect={openColumnSelectorModal}
                />
            </Form>

            <DepositResultsTable loading={depositLoading} />
            <VerifyModal
                visible={verifyModalVisible}
                transaction={transactionAttending}
                onCancel={closeOperationModal}
                operationPending={operationPending}
            />
            <ConfirmRejectModal
                visible={confirmRejectModalVisible}
                selectedTransactionId={selectedTransactionId}
                onCancel={closeRejectModal}
                operationAction={performDepositOperation}
                closeConfirmRejectModal={closeConfirmRejectModal}
            />
            <ColumnSelectionModal
                visible={columnSelectorModalVisible}
                onCancel={closeColumnSelectorModal}
                tableColumnsList={DEPOSIT_TABLE_COLUMNS}
                selectAction={selectColumns}
                selectedColumnsState="payments.deposits.selectedColumns"
            />
            <Divider />
            <TotalBalance
                pageBalance={pageBalance}
                totalBalance={totalBalance}
                grandTotal={grandTotal}
                pageTotal={pageTotal}
            />
        </Card>
    );
};

export default Deposits;
