import { Switch } from 'react-router-dom';
import React from 'react';

import { PrivateRoute } from '../../common/components';
import { ROUTES } from '../../common/constants/routing';
import { RebateGenerator, RebateManagement, RebateSetting } from '.';

const Rebate = () => {
    return (
        <Switch>
            <PrivateRoute path={ROUTES.DASHBOARD.REBATE.SETTING} component={RebateSetting} />
            <PrivateRoute path={ROUTES.DASHBOARD.REBATE.MANAGEMENT} component={RebateManagement} />
            <PrivateRoute path={ROUTES.DASHBOARD.REBATE.GENERATOR} component={RebateGenerator} />
        </Switch>
    );
};

export default Rebate;
