import React from 'react';

import { Table } from 'antd';
import { getColumns, getPaginationOptions } from './RebateRecordsTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const RebateRecordsResultTable = ({ loading }) => {
    const [state, dispatch] = useStore();
    const { rebateRecords } = getStateProp(state, 'transactionHistory');
    const tableData = getStateProp(rebateRecords, 'list', []);
    const paging = getStateProp(rebateRecords, 'paging', {});

    // ============================================
    // RENDER
    // ============================================
    return (
        <Table
            id="deposit-record-table"
            columns={getColumns()}
            size="small"
            scroll={{ x: true }}
            align="left"
            dataSource={tableData}
            rowKey={(record) => record.transactionNo}
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
            bordered
        />
    );
};

export default RebateRecordsResultTable;
