import { Switch } from 'react-router-dom';
import React from 'react';

import './Bonus.less';
import {
    BonusCategory,
    BonusManagement,
    CreateBonus,
    ManualManagement,
    RequestBonus,
    RiskMonitor,
} from '.';
import { PrivateRoute } from '../../common/components';
import { ROUTES } from '../../common/constants/routing';

const Bonus = () => {
    return (
        <Switch>
            <PrivateRoute path={ROUTES.DASHBOARD.BONUS.REQUEST_BONUS} component={RequestBonus} />
            <PrivateRoute
                path={ROUTES.DASHBOARD.BONUS.MANUAL_BONUS_MANAGEMENT.ROOT}
                component={ManualManagement}
            />
            <PrivateRoute path={ROUTES.DASHBOARD.BONUS.BONUS_RECORDS} component={BonusManagement} />
            <PrivateRoute
                path={ROUTES.DASHBOARD.BONUS.BONUS_MANAGEMENT}
                component={BonusManagement}
            />
            <PrivateRoute path={ROUTES.DASHBOARD.BONUS.CREATE_BONUS} component={CreateBonus} />
            <PrivateRoute path={ROUTES.DASHBOARD.BONUS.BONUS_CATEGORY} component={BonusCategory} />
            <PrivateRoute path={ROUTES.DASHBOARD.BONUS.RISK_MONITOR} component={RiskMonitor} />
        </Switch>
    );
};

export default Bonus;
