import bannerManagementReducer from './banner-management/bannerManagementReducer';
import bannerSortingReducer from './banner-sorting/bannerSortingReducer';
import bonusCategoryReducer from './bonus-category/bonusCategoryReducer';
import bonusManagementReducer from './bonus-management/bonusManagementReducer';
import categoryReducer from './shared/categoryReducer';
import createBannerReducer from './create-banner/createBannerReducer';
import createBonusReducer from './create-bonus/createBonusReducer';
import manualManagementReducer from './manual-management/manualManagementReducer';
import requestBonusReducer from './request-bonus/requestBonusReducer';
import riskMonitorReducer from './risk-monitor/riskMonitorReducer';

export default function bonusReducer(bonus = {}, action) {
    const {
        manualManagement,
        createBonus,
        bonusManagement,
        requestBonus,
        bonusCategory,
        category,
        riskMonitor,
        createBanner,
        bannerManagement,
        bannerSorting,
    } = bonus;

    return {
        bannerManagement: bannerManagementReducer(bannerManagement, action),
        bannerSorting: bannerSortingReducer(bannerSorting, action),
        bonusCategory: bonusCategoryReducer(bonusCategory, action),
        bonusManagement: bonusManagementReducer(bonusManagement, action),
        category: categoryReducer(category, action),
        createBanner: createBannerReducer(createBanner, action),
        createBonus: createBonusReducer(createBonus, action),
        manualManagement: manualManagementReducer(manualManagement, action),
        requestBonus: requestBonusReducer(requestBonus, action),
        riskMonitor: riskMonitorReducer(riskMonitor, action),
    };
}
