import { useCallback, useEffect, useState } from 'react';

import { API } from '../common/constants/api';
import { CRYPTO_CORRECTION_TYPE } from '../components/balance/cryptocurrency-correction/constants';
import { DEBOUNCE_DELAY_SEC } from '../common/constants/misc';
import { clearError } from '../common/actions/errorActions';
import {
    cryptoCurrencyCorrectionNewRateGet,
    cryptoCurrencyCorrectionNewRateGetFailure,
    cryptoCurrencyCorrectionNewRateGetSuccess,
    cryptoCurrencyTransactionGetFailure,
    cryptoCurrencyTransactionGetSuccess,
    cryptoCurrencyUsernameGetFailure,
    cryptoCurrencyUsernameGetSuccess,
    performCryptoCurrencyCorrectionOperation,
    performCryptoCurrencyCorrectionOperationFailure,
    performCryptoCurrencyCorrectionOperationSuccess,
} from '../components/balance/cryptocurrency-correction/cryptocurrencyCorrectionActions';
import { getFiatAmount, getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import { message } from 'antd';
import { useDebounce } from './hooks/useDebounce';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, PLAYERS, CRYPTO, PAYMENTS } = API;

export function useGetPlayerDetailsAsyncEndpoint() {
    const [state, dispatch] = useStore();

    // ============================================
    // HOOKS
    // ============================================

    const userNameLike = useDebounce(
        getStateProp(
            state,
            'balance.cryptoCurrencyCorrection.cryptoCurrencyCorrectionInfo.userNameLike',
        ),
        DEBOUNCE_DELAY_SEC,
    );

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!userNameLike,
        method: 'GET',
        trigger: userNameLike,
        url: `${VERSION.V1}${PLAYERS.GET_BY_USERNAME}/${userNameLike}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            cryptoCurrencyUsernameGetSuccess(dispatch, {
                currencyCode: getStateProp(response.data, 'data.monetary.currencyCode'),
                memberLevel: getStateProp(response.data, 'data.profile.memberLevel'),
                playerId: getStateProp(response.data, 'data.id'),
                realName: getStateProp(response.data, 'data.profile.realName'),
                trustLevel: getStateProp(response.data, 'data.profile.trustLevel'),
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (response) {
            if (response.data.data === null) message.warning(getTranslation('User Not Found'));
        }
    }, [response]);

    const errorMsg = useError(error, cryptoCurrencyUsernameGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useGetCryptoCurrencyTransactionAsyncEndpoint(transactionType) {
    const [state, dispatch] = useStore();

    // ============================================
    // HOOKS
    // ============================================

    const transactionNo = useDebounce(
        getStateProp(
            state,
            'balance.cryptoCurrencyCorrection.cryptoCurrencyCorrectionInfo.transactionNo',
        ),
        DEBOUNCE_DELAY_SEC,
    );

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!transactionNo,
        method: 'GET',
        trigger: transactionNo,
        url:
            transactionType === CRYPTO_CORRECTION_TYPE.WITHDRAWAL
                ? `${VERSION.V1}${CRYPTO.WITHDRWAL_INFO}/${transactionNo}`
                : `${VERSION.V1}${CRYPTO.DEPOSIT_INFO}/${transactionNo}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            cryptoCurrencyTransactionGetSuccess(dispatch, {
                amount: getStateProp(response.data, 'data.amount'),
                cryptoAddr: getStateProp(response.data, 'data.cryptoAddr'),
                cryptoAmt: getStateProp(response.data, 'data.cryptoAmt'),
                cryptoCode: getStateProp(response.data, 'data.cryptoCode'),
                cryptoRate: getStateProp(response.data, 'data.cryptoRate')
                    ? getStateProp(response.data, 'data.cryptoRate').toFixed(15)
                    : getStateProp(response.data, 'data.cryptoRate'),
                currencyCode: getStateProp(response.data, 'data.currencyCode'),
                memberLevel: getStateProp(response.data, 'data.memberLevel'),
                originPath: getStateProp(response.data, 'data.originPath'),
                playerId: getStateProp(response.data, 'data.playerId'),
                realName: getStateProp(response.data, 'data.realName'),
                remoteIpAddress: getStateProp(response.data, 'data.remoteIpAddress'),
                shortId: getStateProp(response.data, 'data.shortId'),
                transactionRef: getStateProp(response.data, 'data.transactionRef'),
                trustLevel: getStateProp(response.data, 'data.trustLevel'),
                userNameLike: getStateProp(response.data, 'data.playerUserName'),
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (response) {
            if (response.data.data === null)
                message.warning(getTranslation('Transaction Not Found'));
        }
    }, [response]);

    const errorMsg = useError(error, cryptoCurrencyTransactionGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useGetCryptoCurrencyRateAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const cryptoCurrencyCorrection = getStateProp(
        state,
        'balance.cryptoCurrencyCorrection.cryptoCurrencyCorrectionInfo',
        {},
    );

    const { currencyCode, cryptoCode, fiatAmount, transactionType } = cryptoCurrencyCorrection;

    // ============================================
    // METHODS
    // ============================================

    const handleGetCryptoCurrencyRate = useCallback(() => {
        cryptoCurrencyCorrectionNewRateGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'GET',
        options: {
            params: {
                amount: fiatAmount || 1000,
                fromCurrency: currencyCode,
                toCurrency: cryptoCode,
                type: transactionType === 'Deposit' ? 'BUY' : 'SELL',
            },
        },
        trigger,
        url: `${VERSION.V1}${CRYPTO.CURRENCY_RATE}`,
    });

    useEffect(() => {
        if (response) {
            const rate = getStateProp(response.data, 'data.inverseRate') * 1000;

            clearError(dispatch);
            cryptoCurrencyCorrectionNewRateGetSuccess(dispatch, {
                cryptoRate: rate.toFixed(8),
            });
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, cryptoCurrencyCorrectionNewRateGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetCryptoCurrencyRate];
}

export function useCryptoCurrencyCorrectionOperation() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { cryptoCurrencyCorrectionInfo } = getStateProp(
        state,
        'balance.cryptoCurrencyCorrection',
        {},
    );

    const {
        userNameLike,
        realName,
        amount,
        cryptoAmt,
        cryptoRate,
        transactionType,
        currencyCode,
        ...rest
    } = cryptoCurrencyCorrectionInfo;

    // ============================================
    // METHODS
    // ============================================

    const handleGetCryptoCurrencyCorrectionOperation = useCallback(() => {
        performCryptoCurrencyCorrectionOperation(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger && !!transactionType,
        method: 'POST',
        options: {
            data: {
                amount: getFiatAmount(cryptoAmt, cryptoRate, currencyCode),
                callBackData: undefined,
                cryptoAmt,
                cryptoRate: cryptoRate,
                currencyCode,
                playerRealName: realName,
                ...rest,
                playerUserName: userNameLike,
                remarks: undefined,
                transactionType,
            },
        },
        trigger,
        url:
            transactionType === CRYPTO_CORRECTION_TYPE.DEPOSIT
                ? `${VERSION.V1}${PAYMENTS.CRYPTO_DEPOSIT_CORRECTION}`
                : `${VERSION.V1}${PAYMENTS.CRYPTO_WITHDRWAL_CORRECTION}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            performCryptoCurrencyCorrectionOperationSuccess(dispatch);
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (response) {
            if (response.data.data === null)
                message.warning(getTranslation('Transaction Not Found'));
        }
    }, [response]);

    const errorMsg = useError(error, performCryptoCurrencyCorrectionOperationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleGetCryptoCurrencyCorrectionOperation];
}
