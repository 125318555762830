import React from 'react';

import { FormOutlined } from '@ant-design/icons';

import { PLAYER_MODAL_TYPES } from '../../constants';
import { openPlayersModal } from '../../PlayersListActions';

const GameAccessIcon = ({ player, dispatch }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleOpenModal = () =>
        openPlayersModal(dispatch, { modalType: PLAYER_MODAL_TYPES.GAME_ACCESS, player });

    // ============================================
    // RENDER
    // ============================================

    return <FormOutlined className="edit-icon" onClick={handleOpenModal} />;
};

export default GameAccessIcon;
