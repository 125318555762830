import React, { useCallback, useEffect } from 'react';

import { Col, Form, Input, Row, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';

import './EwalletCorrectionForm.less';
import { BANK_CARD_STATUSES } from './constants';
import { ButtonGreen, ButtonTransparent } from '../../../common/components/buttons/Buttons';
import {
    ewalletCorrectionFormReset,
    ewalletCorrectionInfoSet,
    ewalletCorrectionMultiInfoSet,
    performEwalletCorrectionOperation,
} from './ewalletCorrectionActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';
import Loader from '../../../common/components/Loader';

const EwalletCorrectionForm = ({
    operationLoading,
    operationSuccess,
    playerDetailsLoading,
    ewalletTransfersLoading,
    getEwalletLoading,
}) => {
    const [form] = Form.useForm();
    const { resetFields, setFieldsValue } = form;
    const [state, dispatch] = useStore();
    const { ewalletCorrectionInfo, eWalletList = [] } = getStateProp(
        state,
        'balance.ewalletCorrection',
        {},
    );

    // ============================================
    // METHODS
    // ============================================

    const setDepositCorrectionInfo = (inputName, value) => {
        ewalletCorrectionInfoSet(dispatch, { inputName, value });
    };

    const handleEWalletChanged = (e) => {
        ewalletCorrectionMultiInfoSet(dispatch, {
            accountHolderName: _.find(eWalletList, {
                eWalletName: e,
            }).accountHolderName,
            eWalletName: e,
        });
    };

    const resetForm = useCallback(() => {
        ewalletCorrectionFormReset(dispatch);
        resetFields();
    }, [dispatch, resetFields]);

    const submitForm = () => {
        performEwalletCorrectionOperation(dispatch, { operation: 'submit' });
    };

    const setDeactivateClassName = () => {
        if (
            getStateProp(ewalletCorrectionInfo, 'activateStatus') === BANK_CARD_STATUSES.DEACTIVATED
        )
            return 'deactivated-ewallet';
    };

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (operationSuccess) {
            resetForm();
        }
    }, [operationSuccess, resetForm]);

    useEffect(() => {
        setFieldsValue({
            accountHolderName: getStateProp(ewalletCorrectionInfo, 'accountHolderName'),
            currency: getStateProp(ewalletCorrectionInfo, 'currencyCode'),
            depositAmount: getStateProp(ewalletCorrectionInfo, 'amount'),
            eWalletAccountNo: getStateProp(ewalletCorrectionInfo, 'eWalletAccountNo'),
            eWalletName: getStateProp(ewalletCorrectionInfo, 'eWalletName'),
            name: getStateProp(ewalletCorrectionInfo, 'realName'),
            remarks: getStateProp(ewalletCorrectionInfo, 'remarks'),
            transactionMethod: getStateProp(ewalletCorrectionInfo, 'transactionMethod'),
            transactionType: getStateProp(ewalletCorrectionInfo, 'transactionType'),
            turnoverRequirement: getStateProp(ewalletCorrectionInfo, 'turnoverRequirement'),
            userNameLike: getStateProp(ewalletCorrectionInfo, 'userNameLike'),
        });
    }, [ewalletCorrectionInfo, setFieldsValue]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form
            form={form}
            name="ewallet-correction"
            className="ewallet-correction-form"
            onFinish={submitForm}
            initialValues={{
                memberLevel: getStateProp(ewalletCorrectionInfo, 'memberLevel'),
                transactionNo: getStateProp(ewalletCorrectionInfo, 'transactionNo'),
                trustLevel: getStateProp(ewalletCorrectionInfo, 'trustLevel'),
            }}
        >
            <Row>
                <Col span={12} className="ewallet-correction__form-column">
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('E-Wallet Transaction Code')}
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="transactionNo">
                                <Input
                                    prefix={<SearchOutlined />}
                                    placeholder={getTranslation('Search for Transaction Number')}
                                    onChange={(e) =>
                                        setDepositCorrectionInfo('transactionNo', e.target.value)
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Currency')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="currency"
                                rules={[
                                    {
                                        message: getTranslation('Please select currency!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    disabled
                                    prefix={
                                        (playerDetailsLoading || ewalletTransfersLoading) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Deposit Amount')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="depositAmount"
                                rules={[
                                    {
                                        message: getTranslation('Please input deposit amount!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) =>
                                        setDepositCorrectionInfo('amount', e.target.value)
                                    }
                                    prefix={
                                        ewalletTransfersLoading && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('E-Wallet Account Number')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="eWalletAccountNo"
                                rules={[
                                    {
                                        message: getTranslation('Please input account number!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) =>
                                        setDepositCorrectionInfo('eWalletAccountNo', e.target.value)
                                    }
                                    prefix={
                                        ewalletTransfersLoading || getEwalletLoading ? (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        ) : (
                                            <SearchOutlined />
                                        )
                                    }
                                    placeholder={getTranslation('Search for E-Wallet')}
                                    className={setDeactivateClassName()}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('E-Wallet Name')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="eWalletName"
                                rules={[
                                    {
                                        message: getTranslation('Please select E-Wallet!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    className="time-refresh__filter"
                                    onChange={handleEWalletChanged}
                                    placeholder={getTranslation('E-Wallet Name')}
                                    disabled={
                                        _.isEmpty(eWalletList) ||
                                        ewalletTransfersLoading ||
                                        getEwalletLoading
                                    }
                                >
                                    {_.map(eWalletList, (e) => (
                                        <Select.Option
                                            key={`${e.eWalletName}-${e.eWalletAccountNo}`}
                                            value={e.eWalletName}
                                        >
                                            {e.eWalletName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('E-Wallet Account Name')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="accountHolderName"
                                rules={[
                                    {
                                        message: getTranslation('Please input account name!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) =>
                                        setDepositCorrectionInfo(
                                            'accountHolderName',
                                            e.target.value,
                                        )
                                    }
                                    className={setDeactivateClassName()}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">{getTranslation('Remark')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="remarks"
                                rules={[
                                    {
                                        max: 128,
                                        message: getTranslation('Remark must less than 128 words!'),
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) =>
                                        setDepositCorrectionInfo('remarks', e.target.value)
                                    }
                                    prefix={
                                        ewalletTransfersLoading && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>

                <Col span={12} className="ewallet-correction__form-column">
                    {/*  <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Transaction Type')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="transactionType"
                                rules={[
                                    {
                                        message: getTranslation('Please select Transaction Type!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    onChange={(value) =>
                                        setDepositCorrectionInfo('transactionType', value)
                                    }
                                >
                                    {_.map(DEPOSIT_CORRECTION_TYPES, (type) => (
                                        <Select.Option value={type} key={type}>
                                            {type.toLocaleUpperCase()}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                     */}
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Username')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="userNameLike"
                                rules={[
                                    {
                                        message: getTranslation('Please input username!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    prefix={
                                        ewalletTransfersLoading ? (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        ) : (
                                            <SearchOutlined />
                                        )
                                    }
                                    placeholder={getTranslation('Search for username')}
                                    onChange={(e) =>
                                        setDepositCorrectionInfo('userNameLike', e.target.value)
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Name')} <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        message: getTranslation('Please input name!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) =>
                                        setDepositCorrectionInfo('realName', e.target.value)
                                    }
                                    prefix={
                                        (ewalletTransfersLoading || playerDetailsLoading) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">{getTranslation('Member Level')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="memberLevel">
                                <Input
                                    disabled
                                    prefix={
                                        (ewalletTransfersLoading || playerDetailsLoading) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">{getTranslation('Trust Level')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="trustLevel">
                                <Input
                                    prefix={
                                        (ewalletTransfersLoading || playerDetailsLoading) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Turnover Requirement')}
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="turnoverRequirement">
                                <Input
                                    onChange={(e) =>
                                        setDepositCorrectionInfo(
                                            'turnoverRequirement',
                                            e.target.value,
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Turnover Amount')}
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item>
                                <Input
                                    value={
                                        getStateProp(ewalletCorrectionInfo, 'turnoverRequirement') *
                                            getStateProp(ewalletCorrectionInfo, 'amount') || 0
                                    }
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="form__footer-row" justify="end">
                <ButtonGreen
                    label="Submit"
                    className="form__submit-button"
                    loading={operationLoading}
                    htmlType="submit"
                />
                <ButtonTransparent
                    label="Cancel"
                    className="form__reset-button"
                    onClick={resetForm}
                />
            </Row>
        </Form>
    );
};

export default EwalletCorrectionForm;
