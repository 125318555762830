import React, { useEffect } from 'react';

import { Card, Form } from 'antd';

import { API } from '../../../common/constants/api';
import { getColumns } from './table/PlayerWalletBalanceTableMeta';
import { getEndTime, getStartTime, getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    playerWalletBalanceFilterDrop,
    playerWalletBalanceInit,
} from './playerWalletBalanceActions';
import { useExportAsyncEndpoint } from '../../../services/hooks/useExport';
import { useGetPlayerWalletBalanceAsyncEndpoint } from '../../../services/companyReportService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import PlayerWalletBalanceFilters from './PlayerWalletBalanceFilters';
import PlayerWalletBalanceTable from './table/PlayerWalletBalanceTable';

const { REPORTS } = API;

const PlayerWalletBalance = () => {
    const [state, dispatch] = useStore();
    const viewMode = getStateProp(state, 'reports.playerWalletBalance.filters.viewMode');
    const filters = getStateProp(state, 'reports.playerWalletBalance.filters');
    const { totalRecordCount } = getStateProp(state, 'reports.playerWalletBalance.paging', {});
    const location = getStateProp(state, 'timezone.location');

    // ============================================
    // METHODS
    // ============================================

    const dropFilter = () => {
        playerWalletBalanceFilterDrop(dispatch, {
            processedDateFrom: getStartTime(location, true),
            processedDateTo: getEndTime(location, true),
        });
        handleGetPlayerWalletBalance();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetPlayerWalletBalance] = useGetPlayerWalletBalanceAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        dataMeta: getColumns(viewMode),
        filters,
        innerListKey: 'data',
        method: 'POST',
        subject: getTranslation('Player Wallet Balance'),
        totalRecordCount,
        url: `${REPORTS.PLAYER_WALLET_BALANCE}`,
    });

    useEffect(() => {
        playerWalletBalanceInit(dispatch, {
            processedDateFrom: getStartTime(location, true),
            processedDateTo: getEndTime(location, true),
        });
    }, [dispatch, location]);

    // ============================================
    // RENDER
    // ============================================
    return (
        <Card title={getTranslation('Player Wallet Balance')}>
            <Form onFinish={handleGetPlayerWalletBalance}>
                <PlayerWalletBalanceFilters viewMode={viewMode} />
                <ActionButtons onDropFilters={dropFilter} onExport={handleExportAction} />
            </Form>
            <PlayerWalletBalanceTable loading={loading} />
        </Card>
    );
};

export default PlayerWalletBalance;
