import { useCallback, useEffect, useState } from 'react';

import { API } from '../common/constants/api';
import {
    bonusRecordsGet,
    bonusRecordsGetFailure,
    bonusRecordsGetSuccess,
} from '../components/transaction-history/bonus-records/bonusRecordsActions';
import { clearError } from '../common/actions/errorActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, BONUS } = API;

export function useGetBonusRecordsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { bonusRecords } = getStateProp(state, 'transactionHistory');
    const { pageNo, pageSize } = getStateProp(bonusRecords, 'paging', {});
    const filters = getStateProp(bonusRecords, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetManualManagementRecords = useCallback(() => {
        bonusRecordsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS.LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bonusRecordsGetSuccess(dispatch, {
                dataTotal: response.data.data[0].dataTotal,
                fetchData: handleGetManualManagementRecords,
                grandTotal: response.data.data[0].grandTotal,
                list: response.data.data[0].data,
                pageDataTotal: response.data.data[0].pageDataTotal,
                pageTotal: response.data.data[0].pageTotal,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetManualManagementRecords, response]);

    useEffect(() => {
        handleGetManualManagementRecords();
    }, [handleGetManualManagementRecords, pageNo, pageSize]);

    const errorMsg = useError(error, bonusRecordsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetManualManagementRecords];
}
