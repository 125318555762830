import React from 'react';

import { Form, Input } from 'antd';
import _ from 'lodash';

import { getIntOrFloat } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';

export const getColumns = (
    currency,
    details,
    detailsWithCurrency,
    onFormValueChange,
    isDisabled,
) => {
    const onInputValueChange = (value, day, key) => {
        _.find(details, (detail) => {
            return detail.day === day;
        })[key] = getIntOrFloat(value, true);

        onFormValueChange('loginBonusDetails', detailsWithCurrency);
    };

    const renderAmountInput = (value, record) => {
        return (
            <Form.Item>
                <Input
                    justify="right"
                    value={value.toFixed(2)}
                    onChange={(e) => onInputValueChange(e.target.value, record.day, 'rewardAmount')}
                    disabled={isDisabled}
                />
            </Form.Item>
        );
    };

    const renderEffectiveTurnoverRequiredInput = (value, record) => {
        return (
            <Form.Item>
                <Input
                    justify="right"
                    value={value.toFixed(2)}
                    onChange={(e) => onInputValueChange(e.target.value, record.day, 'etoAmount')}
                    disabled={isDisabled}
                />
            </Form.Item>
        );
    };

    let columns = [
        {
            dataIndex: 'day',
            key: 'day',
            render: (value) => <span>{value === 8 ? '7 Days Bonus Streak' : value}</span>,
            title: getTranslation('Day'),
        },
        {
            dataIndex: 'rewardAmount',
            key: 'rewardAmount',
            render: (value, record) => renderAmountInput(value, record),
            title: getTranslation('Amount ({currency})', { currency }),
        },
        {
            dataIndex: 'etoAmount',
            key: 'etoAmount',
            render: (value, record) => renderEffectiveTurnoverRequiredInput(value, record),
            title: getTranslation('Effective Turnover Required ({currency})', { currency }),
        },
    ];

    return columns;
};
