import { CURRENCIES } from '../../common/constants/localization';
import {
    INITIAL_LAST_REFERRAL_LEVEL_TO,
    INITIAL_REFERRAL_DETAIL,
    REFERRAL_INFO_KEYS,
    REFERRAL_TYPE,
} from '../../common/constants/misc';
import { getTranslation } from '../../common/helpers/locale';
import _ from 'lodash';

export function getReferralType(isFixedPlan, isBonus) {
    let selectedReferralType = [];
    if (isFixedPlan) selectedReferralType.push(REFERRAL_TYPE.REWARDS);
    if (isBonus) selectedReferralType.push(REFERRAL_TYPE.BONUS);
    return selectedReferralType;
}

export function validateReferralPlan(referralDetails, selectedDetail, selectedTier = '', key = '') {
    if (selectedTier) {
        let referralPlanFromValidation = false;
        let referralPlanToValidation = false;
        let message = '';
        if (key === REFERRAL_INFO_KEYS.REFERRAL_LEVEL_FROM) {
            if (
                selectedTier > 1 &&
                selectedDetail[key] <= referralDetails[selectedTier - 2].referralLevelTo
            ) {
                referralPlanFromValidation =
                    selectedDetail[key] <= referralDetails[selectedTier - 2].referralLevelTo;
                message = getTranslation(
                    'Tier {selectedTier} referral plan from cannot smaller than tier {tierBefore} referral plan to',
                    { selectedTier: selectedTier, tierBefore: selectedTier - 1 },
                );
            } else if (!selectedDetail[key]) {
                referralPlanFromValidation = !selectedDetail[key];
                message = getTranslation('Tier 1 referral plan from cannot be 0');
            }
        } else if (
            key === REFERRAL_INFO_KEYS.REFERRAL_LEVEL_TO &&
            selectedDetail[key] <= selectedDetail[REFERRAL_INFO_KEYS.REFERRAL_LEVEL_FROM]
        ) {
            referralPlanToValidation =
                selectedDetail[key] <= selectedDetail[REFERRAL_INFO_KEYS.REFERRAL_LEVEL_FROM];
            message = getTranslation('Referral plan to cannot smaller than referral plan from');
        }
        return {
            message: message,
            validate: !referralPlanToValidation && !referralPlanFromValidation,
        };
    } else {
        let planDetailValidation = true;
        let validationCurrency = '';
        _.forIn(referralDetails.plans, (value) => {
            if (planDetailValidation) {
                validationCurrency = value.currency;
                _.forIn(value.details, (detail) => {
                    if (detail.tierLevel === 1 && !detail[REFERRAL_INFO_KEYS.REFERRAL_LEVEL_FROM]) {
                        planDetailValidation = false;
                    } else if (
                        detail.tierLevel > 1 &&
                        detail[REFERRAL_INFO_KEYS.REFERRAL_LEVEL_FROM] <=
                            value.details[detail.tierLevel - 2].referralLevelTo
                    ) {
                        planDetailValidation = false;
                    } else if (
                        detail.tierLevel !== value.details.length &&
                        detail[REFERRAL_INFO_KEYS.REFERRAL_LEVEL_TO] <=
                            detail[REFERRAL_INFO_KEYS.REFERRAL_LEVEL_FROM]
                    ) {
                        planDetailValidation = false;
                    }
                });
            }
        });
        return {
            message: getTranslation('Please complete the referral plan for currency,{currency}', {
                currency: validationCurrency,
            }),
            validate: !planDetailValidation,
        };
    }
}

export function formValidation(referralInfo) {
    let message = '';
    let programCodeValidation = false;
    let programNameValidation = false;
    let isNotEmptyPlansValidation = false;
    let isNotEmptyReferralType = false;
    let referralPlanValidation = validateReferralPlan(referralInfo);

    if (!referralInfo.code) {
        programCodeValidation = true;
        message = getTranslation('Please input program code');
    } else if (!referralInfo.name) {
        programNameValidation = true;
        message = getTranslation('Please input program name');
    } else if (_.isEmpty(referralInfo.plans)) {
        isNotEmptyPlansValidation = true;
        message = getTranslation('Please choose at least one currency');
    } else if (!referralInfo.isBonus && !referralInfo.isFixedPlan) {
        isNotEmptyReferralType = true;
        message = getTranslation('Please choose at least one referral type');
    } else if (referralPlanValidation.validate) {
        message = referralPlanValidation.message;
    }

    return {
        message,
        validate:
            !programCodeValidation &&
            !isNotEmptyPlansValidation &&
            !programNameValidation &&
            !isNotEmptyReferralType &&
            !referralPlanValidation.validate,
    };
}

export function getInitReferralPlan() {
    let initPlan = [];
    _.forIn(CURRENCIES, (e) => {
        initPlan.push({
            currency: e.currency,
            details: [
                {
                    ...INITIAL_REFERRAL_DETAIL,
                    referralLevelFrom: 1,
                    referralLevelTo: INITIAL_LAST_REFERRAL_LEVEL_TO,
                    tierLevel: 1,
                },
            ],
            minDeposit: 0,
            refereeAmount: 0,
            refereeTurnoverAmount: 0,
            refereeTurnoverRequired: 0,
        });
    });
    return initPlan;
}
