import { Link, useLocation } from 'react-router-dom';
import React, { useState } from 'react';

import { Col, Menu, Row } from 'antd';
import {
    ContainerOutlined,
    CrownOutlined,
    DeploymentUnitOutlined,
    DollarOutlined,
    FileDoneOutlined,
    FireOutlined,
    GoldOutlined,
    HddOutlined,
    HistoryOutlined,
    MonitorOutlined,
    NotificationOutlined,
    PercentageOutlined,
    PropertySafetyOutlined,
    ReadOutlined,
    RedEnvelopeOutlined,
    StockOutlined,
    TransactionOutlined,
    UserOutlined,
    UsergroupAddOutlined,
} from '@ant-design/icons';
import _ from 'lodash';

import './SideBar.less';
import {
    ROUTES,
    newMenuHaventRelease,
    newSubMenuHaventRelease,
    routeNamesMap,
} from '../../common/constants/routing';
import { getTranslation } from '../../common/helpers/locale';
import { isTestEnvironment } from '../../common/helpers/misc';
import { useGetUserPermissionAsyncEndpoint } from '../../services/userService';
import Loader from '../../common/components/Loader';

const { SubMenu, Item } = Menu;

const iconNamesMap = new Map([
    ['Player Management', <UserOutlined />],
    ['Payments', <TransactionOutlined />],
    ['Transaction History', <HistoryOutlined />],
    ['Transaction Monitoring', <MonitorOutlined />],
    ['Balance Management', <DollarOutlined />],
    ['Betting Center', <GoldOutlined />],
    ['News', <ReadOutlined />],
    ['Bonus', <PercentageOutlined />],
    ['Company Reports', <StockOutlined />],
    ['Announcement System', <ReadOutlined />],
    ['Administration', <HddOutlined />],
    ['Referral', <UsergroupAddOutlined />],
    ['Rewards Management', <PropertySafetyOutlined />],
    ['Rebate', <RedEnvelopeOutlined />],
    ['Push Notification', <NotificationOutlined />],
    [`What's New`, <ContainerOutlined />],
    ['File System', <FileDoneOutlined />],
    ['VIP System', <CrownOutlined />],
    ['Affiliate', <DeploymentUnitOutlined />],
    ['Hot Events', <FireOutlined />],
    ['Banner', <ReadOutlined />],
]);

const SideBar = () => {
    let menus = JSON.parse(localStorage.getItem('menus'));

    const mergeSubMenus = (objValue, srcValue) => {
        if (_.isArray(objValue)) {
            return objValue.concat(srcValue);
        }
    };

    // Check for main menu
    if (menus && isTestEnvironment()) {
        menus = _.concat(menus, newMenuHaventRelease);
    }

    // Check for sub menu
    if (menus && isTestEnvironment() && newSubMenuHaventRelease) {
        menus = _.map(menus, (menu) => {
            const matchMenus = _.find(newSubMenuHaventRelease, (subMenu) => {
                return subMenu.menuName === menu.menuName;
            });
            return matchMenus ? _.mergeWith(menu, matchMenus, mergeSubMenus) : menu;
        });
    }

    // const getRoot = (path) => path.substring(0, path.lastIndexOf('/'));
    const location = useLocation();

    // ============================================
    // METHODS
    // ============================================

    const isSubmenuActive = () => {
        if (location.pathname.split(ROUTES.DASHBOARD.PLAYERS.PLAYERS_LIST)[1]) {
            return location.pathname.split(`${ROUTES.DASHBOARD.PLAYERS.PLAYERS_LIST}/isMasked`)[1]
                ? 'Player List'
                : 'Player List (Admin)';
        }

        return routeNamesMap.get(location.pathname);
    };

    const isActive = (pathname) => {
        return location.pathname === pathname;
    };

    const selectedKey =
        _.get(
            _.find(_.flatten(_.map(menus, (item) => item.subMenus)), (item) =>
                isActive(`/${item.menuUrl}`),
            ),
            'menuName',
        ) || isSubmenuActive();

    const openedSubMenuKey = _.get(
        _.find(menus, (item) =>
            _.includes(
                _.map(item.subMenus, (child) => child.menuName),
                selectedKey,
            ),
        ),
        'menuName',
    );

    const [openedMenu, setOpenedMenu] = useState(openedSubMenuKey);

    const onOpenChange = (openKeys) => {
        const openedMenuKey = _.find(openKeys, (value, key) => {
            return key === openKeys.length - 1 && value;
        });

        if (openedMenuKey) setOpenedMenu(openedMenuKey);
        else setOpenedMenu(null);
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading] = useGetUserPermissionAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col span={4} className="sidebar">
            {loading ? (
                <Row className="sidebar__loader">
                    <Loader size="large" />
                </Row>
            ) : (
                <Menu
                    theme="dark"
                    className="sidebar__menu"
                    mode="inline"
                    selectedKeys={[selectedKey]}
                    openKeys={[openedMenu]}
                    onOpenChange={onOpenChange}
                >
                    {_.map(menus, (item) =>
                        item.subMenus.length > 1 ? (
                            <SubMenu
                                key={item.menuName}
                                title={
                                    <Row align="middle">
                                        {iconNamesMap.get(item.menuName)}
                                        <span className="submenu__title">
                                            {getTranslation(item.menuName)}
                                        </span>
                                    </Row>
                                }
                            >
                                {item.subMenus.map((sub) => (
                                    <Item key={sub.menuName}>
                                        <span style={{ paddingLeft: 24 }}>
                                            {getTranslation(sub.menuName)}
                                        </span>
                                        <Link to={`/${sub.menuUrl}`} />
                                    </Item>
                                ))}
                            </SubMenu>
                        ) : (
                            item.subMenus.map((sub) => (
                                <Item key={sub.menuName}>
                                    <Link to={`/${sub.menuUrl}`}>
                                        <Row align="middle">
                                            {iconNamesMap.get(item.menuName)}
                                            <span className="menu__item">
                                                {getTranslation(item.menuName)}
                                            </span>
                                        </Row>
                                    </Link>
                                </Item>
                            ))
                        ),
                    )}
                </Menu>
            )}
        </Col>
    );
};

export default SideBar;
