import React, { useState } from 'react';

import { Form, Modal, Row } from 'antd';
import moment from 'moment';

import { ButtonGray, ButtonGreen } from '../../../../common/components/buttons/Buttons';
import { PAYMENT_ACCEPTANCE_METHODS } from '../constants';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useUpdateDeactivatedEwalletAsyncEndpoint } from '../../../../services/ewalletService';
import EwalletSetupForm from '../modal-form/EwalletSetupForm';

const DeactivatedEwalletSetupModal = ({ modalData, onCancel }) => {
    const [form] = Form.useForm();
    const selectedAccount = getStateProp(modalData, 'accountDetails', {});
    const [formValues, setFormValues] = useState({});

    // ============================================
    // METHODS
    // ============================================

    const submitForm = (values) => {
        setFormValues(values);
        handleUpdateDeactivatedEwallet();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleUpdateDeactivatedEwallet] = useUpdateDeactivatedEwalletAsyncEndpoint(
        formValues,
    );

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={true}
            title={getTranslation('Edit Bank Card')}
            centered
            width={1000}
            closable={false}
            onCancel={onCancel}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        form="deactivated-ewallet-setup"
                        label={getTranslation('Confirm')}
                        loading={loading}
                        htmlType="submit"
                    />

                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
        >
            <Form
                name="deactivated-ewallet-setup"
                onFinish={submitForm}
                initialValues={{
                    ...selectedAccount,
                    eWalletId: selectedAccount.eWalletName,
                    openAt: moment(selectedAccount.openAt),
                    paymentMethodAcceptance:
                        selectedAccount.paymentMethodAcceptance || PAYMENT_ACCEPTANCE_METHODS.ALL,
                }}
                form={form}
            >
                <EwalletSetupForm selectedAccount={selectedAccount} form={form} />
            </Form>
        </Modal>
    );
};

export default DeactivatedEwalletSetupModal;
