import React, { useEffect, useState } from 'react';

import { Button, Col, DatePicker, Input, Row } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone, EditFilled } from '@ant-design/icons';
import moment from 'moment';

import './PlayerInformation.less';
import { ButtonTransparent } from '../../../../common/components/buttons/Buttons';
import { PLAYER_STATUSES } from '../../constants';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useStore } from '../../../../store/StateProvider';
import { useUpdatePlayerInfoAsyncEndpoint } from '../../../../services/playerService';

const PlayerInformation = () => {
    const [state] = useStore();
    const player = getStateProp(state, 'players.playerDetails.playerInfo', { profile: {} });

    const [isVerifyInputShown, setVerifyInputShown] = useState(false);
    const [isPhoneVerified, setPhoneVerified] = useState(null);
    const [isProfileEditing, setProfileEditing] = useState(false);
    const [verifyPhoneValue, setVerifyPhoneValue] = useState(null);

    const initialState = {
        dangerLevel: player.profile.dangerLevel,
        dob: player.profile.dob,
        email: player.profile.email,
        qqAccount: player.profile.qqAccount,
        realName: player.profile.realName,
        weChatAccount: player.profile.weChatAccount,
    };
    const [editableInfo, setEditableInfo] = useState(initialState);
    const location = getStateProp(state, 'timezone.location');

    // ============================================
    // METHODS
    // ============================================

    const showVerifyInput = () => setVerifyInputShown(true);

    const hideVerifyInput = () => setVerifyInputShown(false);

    const handlePhoneVerificationChange = (e) => {
        setVerifyPhoneValue(e.target.value);
    };

    const handlePhoneVerificationSuccess = () => {
        setTimeout(() => {
            hideVerifyInput();
            setPhoneVerified(null);
        }, 3000);
    };

    const handleVerifyPhone = () => {
        if (verifyPhoneValue === player.mobileNo) {
            setPhoneVerified(true);
            handlePhoneVerificationSuccess();
        } else {
            setPhoneVerified(false);
        }
    };

    const handleEditBtnClick = () => setProfileEditing(true);

    const handleSaveBtnClick = () => updatePlayerInfo();

    const handleCancelBtnClick = () => {
        setEditableInfo(initialState);
        setProfileEditing(false);
    };

    const handleEditableInputChange = (e) => {
        const { id, value } = e.target;
        setEditableInfo({
            ...editableInfo,
            [id]: value,
        });
    };

    const handleDateChange = (m) =>
        setEditableInfo({
            ...editableInfo,
            dob: m.startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        });

    // ============================================
    // HOOKS
    // ============================================

    const [infoUpdating, infoUpdated, updatePlayerInfo] = useUpdatePlayerInfoAsyncEndpoint(
        player.id,
        editableInfo,
    );

    useEffect(() => {
        setEditableInfo({
            dangerLevel: player.profile.dangerLevel,
            dob: player.profile.dob,
            email: player.profile.email,
            qqAccount: player.profile.qqAccount,
            qualityLevel: player.profile.qualityLevel,
            realName: player.profile.realName,
            weChatAccount: player.profile.weChatAccount,
        });
    }, [
        player.profile.dangerLevel,
        player.profile.dob,
        player.profile.email,
        player.profile.qqAccount,
        player.profile.qualityLevel,
        player.profile.realName,
        player.profile.weChatAccount,
    ]);

    useEffect(() => {
        if (infoUpdated) setProfileEditing(false);
    }, [infoUpdated]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col span={24}>
            <Row justify="space-around">
                <Col className="player-info__card">
                    <Row className="player-info__card__item">
                        <Col span={24}>
                            <span className="player-info__card__item__label">
                                {getTranslation('Username')}
                            </span>
                            <Input
                                disabled
                                className="player-info__card__item__input"
                                value={player.userName}
                            />
                        </Col>
                    </Row>
                    <Row className="player-info__card__item">
                        <Col span={24}>
                            <span className="player-info__card__item__label">
                                {getTranslation('Register Date')}
                            </span>
                            <DatePicker
                                disabled
                                value={moment(player.createdAt)
                                    .utcOffset(+0, true)
                                    .tz(location)}
                                className="datepicker"
                                format="YYYY-MM-DD HH:mm:ss"
                            />
                        </Col>
                    </Row>
                    <Row align="middle" justify="space-between" className="player-info__card__item">
                        <Col span={24}>
                            <Row>
                                <span className="status-item__label">
                                    {getTranslation('Status')}
                                </span>
                            </Row>
                            <Row className="status-item">
                                <span
                                    className={
                                        player.status === PLAYER_STATUSES.ACTIVE
                                            ? 'status-item__value status-item__value--active'
                                            : 'status-item__value'
                                    }
                                >
                                    {getTranslation('Active')}
                                </span>
                                <span
                                    className={
                                        player.status === PLAYER_STATUSES.LOCKED
                                            ? 'status-item__value status-item__value--locked'
                                            : 'status-item__value'
                                    }
                                >
                                    {getTranslation('Locked')}
                                </span>
                                <span
                                    className={
                                        player.status === PLAYER_STATUSES.CLOSED
                                            ? 'status-item__value status-item__value--closed'
                                            : 'status-item__value'
                                    }
                                >
                                    {getTranslation('Closed')}
                                </span>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="player-info__card__item">
                        <Col span={24}>
                            <span className="player-info__card__item__label">
                                {getTranslation('Member Level')}
                            </span>
                            <Input
                                disabled
                                className="player-info__card__item__input"
                                value={player.profile.memberLevel}
                            />
                        </Col>
                    </Row>
                    <Row className="player-info__card__item">
                        <Col span={24}>
                            <span className="player-info__card__item__label">
                                {getTranslation('Trust Level')}
                            </span>
                            <Input
                                disabled
                                className="player-info__card__item__input"
                                value={player.profile.trustLevel}
                            />
                        </Col>
                    </Row>
                    <Row className="player-info__card__item">
                        <Col span={24}>
                            <span className="player-info__card__item__label">
                                {getTranslation('Quality Level')}
                            </span>
                            <Input
                                disabled
                                className="player-info__card__item__input"
                                value={getTranslation(player.profile.qualityLevel)}
                            />
                        </Col>
                    </Row>
                    <Row className="player-info__card__item">
                        <Col span={24}>
                            <span className="player-info__card__item__label">
                                {getTranslation('Danger Level')}
                            </span>
                            <Input
                                disabled
                                className="player-info__card__item__input"
                                value={player.profile.dangerLevel}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col className="player-info__card">
                    <Row className="player-info__card__item">
                        <Col span={24}>
                            <span className="player-info__card__item__label">
                                {getTranslation('Full Name')}
                            </span>
                            <Input
                                id="realName"
                                onChange={handleEditableInputChange}
                                disabled
                                className="player-info__card__item__input"
                                value={editableInfo.realName}
                            />
                        </Col>
                    </Row>
                    <Row className="player-info__card__item">
                        <Col span={24}>
                            <span className="player-info__card__item__label">
                                {getTranslation('Register IP')}
                            </span>
                            <Input
                                disabled
                                className="player-info__card__item__input"
                                value={player.registerIpv4}
                            />
                        </Col>
                    </Row>
                    <Row className="player-info__card__item">
                        <Col span={24}>
                            <span className="player-info__card__item__label">
                                {getTranslation('Latest Login IP')}
                            </span>
                            <Input
                                disabled
                                className="player-info__card__item__input"
                                value={player.loginIPAddress}
                            />
                        </Col>
                    </Row>
                    <Row className="player-info__card__item">
                        <Col span={24}>
                            <span className="player-info__card__item__label">
                                {getTranslation('Email')}
                            </span>
                            <Row align="middle" justify="space-between">
                                <Input
                                    id="email"
                                    onChange={handleEditableInputChange}
                                    disabled={!isProfileEditing}
                                    className="player-info__card__item__input"
                                    value={editableInfo.email}
                                />
                                {isProfileEditing && <EditFilled style={{ marginLeft: 12 }} />}
                            </Row>
                        </Col>
                    </Row>
                    <Row className="player-info__card__item">
                        <Col span={24}>
                            <span className="player-info__card__item__label">
                                {getTranslation('Birthdate')}
                            </span>
                            <Row align="middle" justify="space-between">
                                <DatePicker
                                    onChange={handleDateChange}
                                    disabled={!isProfileEditing}
                                    value={editableInfo.dob ? moment(editableInfo.dob) : null}
                                    className="datepicker"
                                    format="YYYY-MM-DD"
                                />
                                {isProfileEditing && <EditFilled style={{ marginLeft: 12 }} />}
                            </Row>
                        </Col>
                    </Row>
                    <Row className="player-info__card__item">
                        <Col span={24}>
                            <span className="player-info__card__item__label">
                                {getTranslation('Wechat')}
                            </span>
                            <Row align="middle" justify="space-between">
                                <Input
                                    id="weChatAccount"
                                    className="player-info__card__item__input"
                                    value={editableInfo.weChatAccount}
                                    onChange={handleEditableInputChange}
                                    disabled={!isProfileEditing}
                                />
                                {isProfileEditing && <EditFilled style={{ marginLeft: 12 }} />}
                            </Row>
                        </Col>
                    </Row>
                    <Row className="player-info__card__item">
                        <Col span={24}>
                            <span className="player-info__card__item__label">
                                {getTranslation('QQ')}
                            </span>
                            <Row align="middle" justify="space-between">
                                <Input
                                    id="qqAccount"
                                    onChange={handleEditableInputChange}
                                    disabled={!isProfileEditing}
                                    className="player-info__card__item__input"
                                    value={editableInfo.qqAccount}
                                />
                                {isProfileEditing && <EditFilled style={{ marginLeft: 12 }} />}
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col className="player-info__card">
                    <Row className="player-info__card__item">
                        <Col span={24}>
                            <span className="player-info__card__item__label">
                                {getTranslation('Referral Code')}
                            </span>
                            <Input
                                disabled
                                className="player-info__card__item__input"
                                value={player.profile.inviteCode}
                            />
                        </Col>
                    </Row>
                    <Row className="player-info__card__item">
                        <Col span={24}>
                            <span className="player-info__card__item__label">
                                {getTranslation('Affiliate')}
                            </span>
                            <Input
                                disabled
                                className="player-info__card__item__input"
                                value={player.profile.signUpFromAffiliate}
                            />
                        </Col>
                    </Row>
                    <Row className="player-info__card__item">
                        <Col span={24}>
                            <span className="player-info__card__item__label">
                                {getTranslation('Referral')}
                            </span>
                            <Input
                                disabled
                                className="player-info__card__item__input"
                                value={player.profile.referrerUserName}
                            />
                        </Col>
                    </Row>
                    <Row className="player-info__card__item">
                        <Col span={24}>
                            <Row>
                                <span className="player-info__card__item__label">
                                    {getTranslation('Phone Number')}
                                </span>
                            </Row>
                            <Row align="middle" justify="space-between" gutter={16}>
                                <Col>
                                    <Input
                                        disabled
                                        className="player-info__card__item__input"
                                        value={player.profile.mobileNo}
                                    />
                                </Col>
                                {isVerifyInputShown && isPhoneVerified !== null && (
                                    <Col>
                                        {isPhoneVerified ? (
                                            <CheckCircleTwoTone
                                                twoToneColor="#52c41a"
                                                style={{ fontSize: 18 }}
                                            />
                                        ) : (
                                            <CloseCircleTwoTone
                                                twoToneColor="red"
                                                style={{ fontSize: 18 }}
                                            />
                                        )}
                                    </Col>
                                )}
                                {player.mobileNo && (
                                    <Col>
                                        <ButtonTransparent
                                            size="small"
                                            className="btn-verify"
                                            onClick={
                                                isVerifyInputShown
                                                    ? handleVerifyPhone
                                                    : showVerifyInput
                                            }
                                        >
                                            {getTranslation('Verify')}
                                        </ButtonTransparent>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                    {isVerifyInputShown && (
                        <Row>
                            <Input onChange={handlePhoneVerificationChange} />
                        </Row>
                    )}
                    <Row className="player-info__card__item">
                        <Col span={24}>
                            <span className="player-info__card__item__label">
                                {getTranslation('Short Id')}
                            </span>
                            <Input
                                disabled
                                className="player-info__card__item__input"
                                value={player.shortId}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row justify="end">
                {isProfileEditing ? (
                    <Button.Group>
                        <Button
                            className="btn-save"
                            onClick={handleSaveBtnClick}
                            loading={infoUpdating}
                        >
                            {getTranslation('Save')}
                        </Button>
                        <Button className="btn-cancel" onClick={handleCancelBtnClick}>
                            {getTranslation('Cancel')}
                        </Button>
                    </Button.Group>
                ) : (
                    <Button className="btn-edit" type="button" onClick={handleEditBtnClick}>
                        <EditFilled />
                        {getTranslation('Edit')}
                    </Button>
                )}
            </Row>
        </Col>
    );
};

export default PlayerInformation;
