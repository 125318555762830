import { ACTIONS } from './createWhatsNewActions';

export default function createWhatsNewReducer(
    createWhatsNew = { whatsNewInfo: {} },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.UPDATE_WHATS_NEW_INFO:
        default:
            return createWhatsNew;
    }
}
