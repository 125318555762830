import React from 'react';

import { Modal, Row } from 'antd';

import './ConfirmModal.less';
import { ButtonGray, ButtonRed } from '../../../../common/components/buttons/Buttons';
import { PAYMENT_ORDER_OPERATIONS } from '../../../../common/constants/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useDispatch } from '../../../../store/StateProvider';

const ConfirmRejectModal = ({
    onCancel,
    visible,
    selectedTransactionId,
    operationAction,
    closeConfirmRejectModal,
}) => {
    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ============================================

    const confirmReject = () => {
        operationAction(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.REJECT,
        });

        closeConfirmRejectModal(dispatch);
    };
    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            title={getTranslation('Confirm to Reject?')}
            centered
            closable={false}
            className="ewallet-transfer__confirm-modal"
            onCancel={onCancel}
            footer={
                <Row key="actions" justify="space-between" align="middle">
                    <ButtonRed label={getTranslation('Reject')} onClick={confirmReject} />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>
            }
        >
            {getTranslation("You've selected {selectedRows} records", {
                selectedRows: selectedTransactionId && selectedTransactionId.length,
            })}
        </Modal>
    );
};

export default ConfirmRejectModal;
