import React from 'react';

import { Table } from 'antd';
import _ from 'lodash';

import { getColumns } from './DailyLoginSetupTableMeta';
import { getSupportedCurrenciesByCountry } from '../../../../common/helpers/locale';

const DailyLoginSetupTable = ({ currency, details, onFormValueChange, vipLevel }) => {
    const data = details
        ? _.find(
              details,
              (setupDetail) =>
                  setupDetail.currencyCode === currency && setupDetail.vipLevel === vipLevel,
          ).loginRewards
        : [];

    const operatorCurrency = _.map(
        getSupportedCurrenciesByCountry(),
        (currency) => currency.currency,
    );
    const isDisabled = !_.includes(operatorCurrency, currency);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            className="row__form-item"
            id="daily-login-setup-table"
            columns={getColumns(currency, data, details, onFormValueChange, isDisabled)}
            size="small"
            align="center"
            dataSource={data}
            rowKey={(record, index) => index}
            pagination={false}
            bordered
        />
    );
};

export default DailyLoginSetupTable;
