import React from 'react';

import { Divider, Form } from 'antd';

import { API } from '../../../../common/constants/api';
import { cryptoDepositRecordFilterDrop } from './cryptoDepositRecordActions';
import { getColumns } from './table/CryptoDepositRecordTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useExportAsyncEndpoint } from '../../../../services/hooks/useExport';
import { useGetCryptoDepositRecordAsyncEndpoint } from '../../../../services/cryptoCurrencyService';
import { useStore } from '../../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import CryptoDepositRecordFilters from './CryptoDepositRecordFilters';
import CryptoDepositRecordResultsTable from './table/CryptoDepositRecordResultsTable';
import TotalBalance from '../../../../common/components/TotalBalance';

const { TRANSACTION_HISTORY } = API;

const CryptoDepositRecords = () => {
    const [state, dispatch] = useStore();

    const cryptoDepositRecord = getStateProp(
        state,
        'transactionHistory.cryptocurrencyRecords.cryptoDepositRecord',
        {},
    );
    const filters = getStateProp(cryptoDepositRecord, 'filters');
    const { totalRecordCount } = getStateProp(cryptoDepositRecord, 'paging', {});
    const pageBalance = getStateProp(cryptoDepositRecord, 'pageDataTotal', []);
    const totalBalance = getStateProp(cryptoDepositRecord, 'dataTotal', []);
    const grandTotal = getStateProp(cryptoDepositRecord, 'grandTotal', 0);
    const pageTotal = getStateProp(cryptoDepositRecord, 'pageTotal', 0);

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        cryptoDepositRecordFilterDrop(dispatch);
        handleGetCryptoDepositRecord();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetCryptoDepositRecord] = useGetCryptoDepositRecordAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        dataMeta: getColumns(),
        filters,
        innerListKey: 'data',
        method: 'GET',
        subject: getTranslation('Crypto Deposit Records'),
        totalRecordCount,
        url: `${TRANSACTION_HISTORY.CRYPTO_DEPOSITS_RECORDS}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <div>
            <Form onFinish={handleGetCryptoDepositRecord}>
                <CryptoDepositRecordFilters />
                <ActionButtons onDropFilters={fetchAll} onExport={handleExportAction} />
            </Form>

            <CryptoDepositRecordResultsTable loading={loading} />

            <Divider />

            <TotalBalance
                pageBalance={pageBalance}
                totalBalance={totalBalance}
                grandTotal={grandTotal}
                pageTotal={pageTotal}
            />
        </div>
    );
};

export default CryptoDepositRecords;
