import React from 'react';

import { RenderDateFormat } from '../../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../DeactivatedEwalletActions';
import { getTranslation } from '../../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../../common/helpers/misc';
import OperationButton from './OperationButton';

export const getColumns = (paging) => {
    const initStartRowNo = paging ? (paging.pageNo - 1) * paging.pageSize : 0;
    // ============================================
    // METHODS
    // ============================================

    const getNotificationColor = (record) => {
        if (record.dailyBalance > record.balanceLimitNotification) {
            return 'red';
        }
    };

    // ============================================
    // RENDER
    // ============================================

    const columns = [
        {
            dataIndex: 'no',
            render: (text, record, index) => <span>{initStartRowNo + (index + 1)}</span>,
            title: getTranslation('No.'),
        },
        {
            dataIndex: 'accountId',
            title: getTranslation('Account Id'),
        },
        {
            dataIndex: 'eWalletId',
            title: getTranslation('E-Wallet Id'),
        },
        {
            dataIndex: 'eWalletName',
            title: getTranslation('E-Wallet Name'),
        },
        {
            dataIndex: 'accountHolderName',
            title: getTranslation('Account Holder Name'),
        },
        {
            dataIndex: 'eWalletAccountNo',
            title: getTranslation('E-Wallet Account Number'),
        },
        {
            dataIndex: 'activatedStatus',
            render: (value) => getTranslation(value),
            title: getTranslation('Status'),
        },
        {
            dataIndex: 'type',
            render: (value) => getTranslation(value),
            title: getTranslation('Type'),
        },
        {
            dataIndex: 'currencyCode',
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'balanceLimitNotification',
            render: (text, record) => (
                <span style={{ color: getNotificationColor(record) }}>
                    {record.balanceLimitNotification}
                </span>
            ),
            title: getTranslation('Balance Limit Notification'),
        },
        {
            dataIndex: 'balance',
            title: getTranslation('Balance'),
        },
        {
            dataIndex: 'updatedAt',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Last Modified'),
        },
        {
            dataIndex: 'remarks',
            title: getTranslation('Remark'),
        },
        {
            dataIndex: 'operation',
            dataType: 'operation',
            render: (text, record) => <OperationButton accountDetails={record} />,
            title: getTranslation('Operation'),
            width: 80,
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
