export const ACTIONS = {
    FILTERS_DROP_ALL: 'MODIFICATIONS_HISTORY_FILTERS_DROP_ALL',
    FILTER_SET: 'MODIFICATIONS_HISTORY_FILTER_SET',
    MODIFICATIONS_GET: 'MODIFICATIONS_HISTORY_GET',
    MODIFICATIONS_GET_FAILURE: 'MODIFICATIONS_HISTORY_GET_FAILURE',
    MODIFICATIONS_GET_SUCCESS: 'MODIFICATIONS_HISTORY_GET_SUCCESS',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'MODIFICATIONS_HISTORY_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'MODIFICATIONS_HISTORY_TABLE_PAGE_SIZE_CHANGE',
    },
};

export const modificationsFilterSet = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });
};

export const modificationsDropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const modificationsHistoryGet = (dispatch) =>
    dispatch({
        type: ACTIONS.MODIFICATIONS_GET,
    });

export const modificationsHistoryGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MODIFICATIONS_GET_SUCCESS,
    });

export const modificationsHistoryGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MODIFICATIONS_GET_FAILURE,
    });

export const changeModificationsPageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changeModificationsPageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });
