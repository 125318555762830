import React from 'react';
import _ from 'lodash';

import './WhatsNew.less';
import { Button, Checkbox, Col, Form, Input, Popover, Row, Tabs } from 'antd';
// import {
//     ButtonGreen,
//     ButtonLightBlue,
//     ButtonTransparent,
// } from '../../common/components/buttons/Buttons';
import { CURRENCIES } from '../../common/constants/localization';
import { getLanguageByCode, getTranslation } from '../../common/helpers/locale';
import { getSelectedLanguages } from './helper';
import ImageEditor from '../../common/components/ImageEditor';
import LanguagesCheckboxes from '../../common/components/LanguagesCheckboxes';

const { TabPane } = Tabs;
const { TextArea } = Input;

const WhatsNewDetails = ({ whatsNewInfo, selectedCurrencies, handleUpdateWhatsNewInfo }) => {
    // ============================================
    // METHODS
    // ============================================
    const submitForm = () => {};

    // ============================================
    // HOOKS
    // ============================================

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form onFinish={submitForm}>
            <Row className="whats-new__form-row">
                <Col span={6}>
                    <label>{getTranslation('Code')}</label>
                </Col>
                <Col span={12}>
                    <Form.Item name="code" valuePropName="code">
                        <Input
                            value={whatsNewInfo.code}
                            onChange={(e) =>
                                handleUpdateWhatsNewInfo(e.target.value, 'code', null, null)
                            }
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="whats-new__form-row">
                <Col span={6}>
                    <label>{getTranslation('Currency')}</label>
                </Col>
                <Col span={16}>
                    <Form.Item>
                        <Checkbox.Group style={{ width: '100%' }} value={selectedCurrencies}>
                            <Row style={{ flexFlow: 'row wrap' }}>
                                {_.map(CURRENCIES, (currency) => (
                                    <Col span={8} key={currency.currency}>
                                        <Checkbox
                                            value={currency.currency}
                                            onChange={(e) =>
                                                handleUpdateWhatsNewInfo(e, 'currency', null, null)
                                            }
                                        >
                                            {currency.currency}
                                        </Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Tabs className="whats-new__width--100" tabPosition="left" hideAdd>
                    {_.map(_.sortBy(whatsNewInfo.currencies, ['currencyCode']), (info) => (
                        <TabPane key={info.currencyCode} tab={info.currencyCode}>
                            <Tabs
                                tabBarExtraContent={
                                    <Row>
                                        <Popover
                                            placement="bottom"
                                            title={getTranslation('Select Languages')}
                                            content={
                                                <LanguagesCheckboxes
                                                    onCheckedLanguages={(e) =>
                                                        handleUpdateWhatsNewInfo(
                                                            e,
                                                            'language',
                                                            info.currencyCode,
                                                            null,
                                                        )
                                                    }
                                                    value={getSelectedLanguages(info.languages)}
                                                />
                                            }
                                            trigger="click"
                                        >
                                            <Button>{getTranslation('Select Languages')}</Button>
                                        </Popover>
                                    </Row>
                                }
                            >
                                {_.map(info.languages, (languageInfo) => (
                                    <TabPane
                                        key={languageInfo.languageCode}
                                        tab={getLanguageByCode(languageInfo.languageCode)}
                                        className="whats-new__content-tabs"
                                    >
                                        <Row className="whats-new__form-row">
                                            <Form.Item name="code" valuePropName="code">
                                                <ImageEditor
                                                    onImageChanged={(imageInBase64) =>
                                                        handleUpdateWhatsNewInfo(
                                                            imageInBase64,
                                                            'imageInBase64',
                                                            info.currencyCode,
                                                            languageInfo.languageCode,
                                                        )
                                                    }
                                                    image={languageInfo.imageInBase64}
                                                />
                                            </Form.Item>
                                        </Row>
                                        <Row className="whats-new__form-row">
                                            <Col span={6}>
                                                <label>{getTranslation('Title')}</label>
                                            </Col>
                                            <Col span={16}>
                                                <Form.Item name="title" valuePropName="title">
                                                    <Input
                                                        value={languageInfo.title}
                                                        onChange={(e) =>
                                                            handleUpdateWhatsNewInfo(
                                                                e.target.value,
                                                                'title',
                                                                info.currencyCode,
                                                                languageInfo.languageCode,
                                                            )
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="whats-new__form-row">
                                            <Col span={6}>
                                                <label>{getTranslation('Description')}</label>
                                            </Col>
                                            <Col span={16}>
                                                <Form.Item
                                                    name="description"
                                                    valuePropName="description"
                                                >
                                                    <TextArea
                                                        value={languageInfo.description}
                                                        onChange={(e) =>
                                                            handleUpdateWhatsNewInfo(
                                                                e.target.value,
                                                                'description',
                                                                info.currencyCode,
                                                                languageInfo.languageCode,
                                                            )
                                                        }
                                                        rows={4}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                ))}
                            </Tabs>
                        </TabPane>
                    ))}
                </Tabs>
            </Row>
        </Form>
    );
};

export default WhatsNewDetails;
