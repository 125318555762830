import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { PAYMENT_GATEWAY_WEB_SHOW } from '../../../common/constants/misc';
import { getTranslation } from '../../../common/helpers/locale';

const WebShowFilter = ({ value, onFilterChange }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('isWebShow', value);
    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear={true}
            placeholder={getTranslation('Web Show')}
            className="filter"
        >
            {_.map(PAYMENT_GATEWAY_WEB_SHOW, (value, index) => (
                <Select.Option value={value} key={index}>
                    {index}
                </Select.Option>
            ))}
        </Select>
    );
};

export default WebShowFilter;
