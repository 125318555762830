import { ACTIONS } from './riskMonitorActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function RiskMonitorReducer(
    riskMonitor = {
        bonusRedemptionPaging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        resetPageNo: false,
        totalRegistrationPaging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.PAGINATION.RIKS_MONITOR_TABLE_PAGE_CHANGE:
            return {
                ...riskMonitor,
                paging: {
                    ...riskMonitor.paging,
                    pageNo:
                        payload > riskMonitor.paging.pageCount
                            ? riskMonitor.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.RIKS_MONITOR_TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...riskMonitor,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TOTAL_REGISTRATION_TABLE_PAGE_CHANGE:
            return {
                ...riskMonitor,
                totalRegistrationPaging: {
                    ...riskMonitor.totalRegistrationPaging,
                    pageNo:
                        payload > riskMonitor.totalRegistrationPaging.pageCount
                            ? riskMonitor.totalRegistrationPaging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TOTAL_REGISTRATION_TABLE_PAGE_SIZE_CHANGE:
            const MAX_TOTAL_REGISTRATION_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...riskMonitor,
                totalRegistrationPaging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_TOTAL_REGISTRATION_PER_PAGE
                            ? MAX_TOTAL_REGISTRATION_PER_PAGE
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.RESET_TOTAL_REGISTRATION_TABLE_PAGE:
            return {
                ...riskMonitor,
                totalRegistrationPaging: {
                    pageNo: TABLE_PAGE_NO_DEFAULT,
                    pageSize: TABLE_PAGE_SIZE_DEFAULT,
                    totalRecordCount: 0,
                },
            };
        case ACTIONS.PAGINATION.BONUS_REDEMPTION_TABLE_PAGE_CHANGE:
            return {
                ...riskMonitor,
                bonusRedemptionPaging: {
                    ...riskMonitor.bonusRedemptionPaging,
                    pageNo:
                        payload > riskMonitor.bonusRedemptionPaging.pageCount
                            ? riskMonitor.bonusRedemptionPaging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.BONUS_REDEMPTION_TABLE_PAGE_SIZE_CHANGE:
            const MAX_BONUS_REDEMNPTION_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...riskMonitor,
                bonusRedemptionPaging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_BONUS_REDEMNPTION_PER_PAGE
                            ? MAX_BONUS_REDEMNPTION_PER_PAGE
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.RESET_BONUS_REDEMPTION_TABLE_PAGE:
            return {
                ...riskMonitor,
                bonusRedemptionPaging: {
                    pageNo: TABLE_PAGE_NO_DEFAULT,
                    pageSize: TABLE_PAGE_SIZE_DEFAULT,
                    totalRecordCount: 0,
                },
            };
        case ACTIONS.RIKS_MONITOR_FILTER_SET:
            return {
                ...riskMonitor,
                filters: {
                    ...riskMonitor.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };
        case ACTIONS.RIKS_MONITOR_FILTERS_DROP_ALL:
            return {
                ...riskMonitor,
                filters: null,
                resetPageNo: true,
            };
        case ACTIONS.BONUS_CODE_REDEMPTION_MODAL_VISIBLE:
            return {
                ...riskMonitor,
                bonusCodeRedemptionModalVisible: payload,
            };
        case ACTIONS.TOTAL_REGISTRATION_MODAL_VISIBLE:
            return {
                ...riskMonitor,
                totalRegistrationModalVisible: payload,
            };
        case ACTIONS.GET_BONUS_IP_SUCCESS:
        case ACTIONS.GET_BONUS_REDDEM_SUCCESS:
        case ACTIONS.GET_BONUS_REGISTRATION_SUCCESS:
            return {
                ...riskMonitor,
                ...payload,
            };
        case ACTIONS.GET_BONUS_REGISTRATION:
        case ACTIONS.GET_BONUS_REDDEM:
        case ACTIONS.GET_BONUS_IP:
        default: {
            return riskMonitor;
        }
    }
}
