import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './DeactivatedMerchantTableMeta';
import { getStateProp } from '../../../../../common/helpers/misc';
import { useStore } from '../../../../../store/StateProvider';

const DeactivatedMerchantResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();
    const tableData = getStateProp(state, 'payments.deactivatedMerchant.list', []);
    const paging = getStateProp(state, 'payments.deactivatedMerchant.paging', {});

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns(paging)}
            loading={loading}
            size="small"
            align="left"
            dataSource={tableData}
            rowKey={(record) => record.gatewayId}
            pagination={getPaginationOptions(paging, dispatch)}
            scroll={{ x: true }}
            bordered
        />
    );
};

export default DeactivatedMerchantResultsTable;
