import React from 'react';

import { REPORT_TYPES, VIEW_MODES } from '../../constants';
import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS_2000 } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../playerWalletBalanceActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';
import PlayerWalletBalanceGrandTotalRow from './PlayerWalletBalanceGrandTotalRow';
import RenderMonthFormat from '../../../../common/components/RenderMonthFormat';

export const getColumns = (viewMode, reportType) => {
    const columns = [
        {
            dataIndex: 'date',
            dataType: 'date',
            render:
                reportType === REPORT_TYPES.DAILY
                    ? (date) => RenderDateFormat(date, true)
                    : (date) => RenderMonthFormat(date),
            title: getTranslation('Date'),
        },
        {
            dataIndex: 'username',
            title: getTranslation('Username'),
        },
        {
            dataIndex: 'currency',
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'deposit',
            title: getTranslation('Deposit'),
        },
        {
            dataIndex: 'withdrawal',
            title: getTranslation('Withdrawal'),
        },
        {
            dataIndex: 'affiliate',
            title: getTranslation('Affiliate Transfer'),
        },
        {
            dataIndex: 'bonus',
            title: getTranslation('Bonus'),
        },
        {
            dataIndex: 'rebate',
            title: getTranslation('Rebate'),
        },
        {
            dataIndex: 'depositCorrection',
            title: getTranslation('Deposit Correction'),
        },
        {
            dataIndex: 'balanceAddition',
            title: getTranslation('Balance Addition'),
        },
        {
            dataIndex: 'balanceDeduction',
            title: getTranslation('Balance Deduction'),
        },
        {
            dataIndex: 'pendingBet',
            title: getTranslation('Pending Bets'),
        },
        {
            dataIndex: 'walletBalance',
            title: getTranslation('Wallet Balance'),
        },
        {
            dataIndex: 'playerWinLoss',
            title: getTranslation('Player Win/Loss'),
        },
    ];

    return viewMode === VIEW_MODES.DATE
        ? columns
              .filter((column) => column.dataIndex !== 'username')
              .map((colProps) => ({
                  ...colProps,
                  sorter: (r1, r2) =>
                      sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
              }))
        : columns.map((colProps) => ({
              ...colProps,
              sorter: (r1, r2) =>
                  sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
          }));
};

export const tableBodyWrapper = (props, grandTotal, viewMode) => {
    return (
        <tbody {...props}>
            {props.children}
            <PlayerWalletBalanceGrandTotalRow tableData={grandTotal} viewMode={viewMode} />
        </tbody>
    );
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS_2000,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
