import React, { useEffect, useState } from 'react';

import { Form, Modal, Row } from 'antd';

import { ButtonGray, ButtonGreen } from '../../../../common/components/buttons/Buttons';
import { availableBannerFilterDrop } from '../bannerSortingActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { useDispatch } from '../../../../store/StateProvider';
import {
    useGetAvailableBannerListAsyncEndpoint,
    useUpdateActiveBannerAsycnEndpoint,
} from '../../../../services/bannerManagementService';
import ActionButtons from './ActionButtons';
import ActiveBannerFilters from './ActiveBannerFilters';
import ActiveBannerResultTable from './ActiveBannerResultTable';

const BannerListingModal = ({ onCancel, visible, refreshTable }) => {
    const dispatch = useDispatch();
    const [showSelectedBanners, setShowSelectedBanners] = useState(false);

    // ============================================
    // METHODS
    // ============================================
    const dropFilters = () => {
        availableBannerFilterDrop(dispatch);
        handleGetAvailableBanner();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetAvailableBanner] = useGetAvailableBannerListAsyncEndpoint();
    const [updating, handleUpdateActiveBanners, updated] = useUpdateActiveBannerAsycnEndpoint();

    useEffect(() => {
        if (updated) {
            onCancel();
            refreshTable();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updated]);

    // ============================================
    // RENDER
    // ============================================
    return (
        <Modal
            visible={visible}
            title="Banner Listing"
            width={1000}
            onCancel={onCancel}
            closable={false}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        label={getTranslation('Submit')}
                        onClick={handleUpdateActiveBanners}
                        loading={updating}
                    />
                    <ButtonGray
                        label={getTranslation('Cancel')}
                        onClick={onCancel}
                        loading={updating}
                    />
                </Row>,
            ]}
        >
            <Form onFinish={handleGetAvailableBanner}>
                <ActiveBannerFilters />
                <ActionButtons
                    setShowSelectedBanners={setShowSelectedBanners}
                    showSelectedBanners={showSelectedBanners}
                    onDropFilters={dropFilters}
                />
            </Form>
            <ActiveBannerResultTable showSelectedBanners={showSelectedBanners} loading={loading} />
        </Modal>
    );
};

export default BannerListingModal;
