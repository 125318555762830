import React from 'react';

import { Card, Form } from 'antd';

import { API } from '../../../common/constants/api';
import { dropFilters } from './rebateRecordsActions';
import { getColumns } from './table/RebateRecordsTableMeta';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useExportAsyncEndpoint } from '../../../services/hooks/useExport';
import { useGetRebateRecordsAsyncEndpoint } from '../../../services/rebateService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import RebateRecordsFilters from './RebateRecordsFilters';
import RebateRecordsResultTable from './table/RebateRecordsResultTable';

const { TRANSACTION_HISTORY } = API;

const RebateRecords = () => {
    const [state, dispatch] = useStore();
    const { rebateRecords } = getStateProp(state, 'transactionHistory');
    const filters = getStateProp(rebateRecords, 'filters');
    const { totalRecordCount } = getStateProp(rebateRecords, 'paging', {});

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetRebateRecords();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetRebateRecords] = useGetRebateRecordsAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        dataMeta: getColumns(),
        filters,
        method: 'GET',
        subject: getTranslation('Rebate Records'),
        totalRecordCount,
        url: `${TRANSACTION_HISTORY.REBATE_RECORDS}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Rebate Records')}>
            <Form onFinish={handleGetRebateRecords}>
                <RebateRecordsFilters />
                <ActionButtons onExport={handleExportAction} onDropFilters={fetchAll} />
            </Form>
            <RebateRecordsResultTable loading={loading} />
        </Card>
    );
};

export default RebateRecords;
