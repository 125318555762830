export const ACTIONS = {
    PAGINATION: {
        REBATE_MANAGEMENT_TABLE_PAGE_CHANGE: 'REBATE_MANAGEMENT_TABLE_PAGE_CHANGE',
        REBATE_MANAGEMENT_TABLE_PAGE_SIZE_CHANGE: 'REBATE_MANAGEMENT_TABLE_PAGE_SIZE_CHANGE',
    },
    REBATE_MANAGEMENT_DUPLICATE_BY_LANGUAGE: 'REBATE_MANAGEMENT_DUPLICATE_BY_LANGUAGE',
    REBATE_MANAGEMENT_EDIT_MODAL_CLOSE: 'REBATE_MANAGEMENT_EDIT_MODAL_CLOSE',
    REBATE_MANAGEMENT_EDIT_MODAL_OPEN: 'REBATE_MANAGEMENT_EDIT_MODAL_OPEN',
    REBATE_MANAGEMENT_FILTERS_DROP_ALL: 'REBATE_MANAGEMENT_FILTERS_DROP_ALL',
    REBATE_MANAGEMENT_FILTER_SET: 'REBATE_MANAGEMENT_FILTER_SET',
    REBATE_MANAGEMENT_GET: 'REBATE_MANAGEMENT_GET',
    REBATE_MANAGEMENT_GET_BY_ID: 'REBATE_MANAGEMENT_GET_BY_ID',
    REBATE_MANAGEMENT_GET_BY_ID_FAILURE: 'REBATE_MANAGEMENT_GET_BY_ID_FAILURE',
    REBATE_MANAGEMENT_GET_BY_ID_SUCCESS: 'REBATE_MANAGEMENT_GET_BY_ID_SUCCESS',
    REBATE_MANAGEMENT_GET_FAILURE: 'REBATE_MANAGEMENT_GET_FAILURE',
    REBATE_MANAGEMENT_GET_SUCCESS: 'REBATE_MANAGEMENT_GET_SUCCESS',
    REBATE_MANAGEMENT_INFO_UPDATE: 'REBATE_MANAGEMENT_INFO_UPDATE',
    REBATE_MANAGEMENT_INFO_UPDATE_BY_LANGUAGE: 'REBATE_MANAGEMENT_INFO_UPDATE_BY_LANGUAGE',
    REBATE_MANAGEMENT_LANGUAGE_DROP: 'REBATE_MANAGEMENT_LANGUAGE_DROP',
    REBATE_MANAGEMENT_LANGUAGE_SET: 'REBATE_MANAGEMENT_LANGUAGE_SET',
    REBATE_MANAGEMENT_MULTI_INFO_UPDATE: 'REBATE_MANAGEMENT_MULTI_INFO_UPDATE',
    REBATE_MANAGEMENT_SELECTED: 'REBATE_MANAGEMENT_SELECTED',
    REBATE_MANAGEMENT_STATUS_UPDATE: 'REBATE_MANAGEMENT_STATUS_UPDATE',
    REBATE_MANAGEMENT_STATUS_UPDATE_FAILURE: 'REBATE_MANAGEMENT_STATUS_UPDATE_FAILURE',
    REBATE_MANAGEMENT_STATUS_UPDATE_SUCCESS: 'REBATE_MANAGEMENT_STATUS_UPDATE_SUCCESS',
    REBATE_MANAGEMENT_UPDATE: 'REBATE_MANAGEMENT_UPDATE',
    REBATE_MANAGEMENT_UPDATE_FAILURE: 'REBATE_MANAGEMENT_UPDATE_FAILURE',
    REBATE_MANAGEMENT_UPDATE_SUCCESS: 'REBATE_MANAGEMENT_UPDATE_SUCCESS',
};

//#region select rebate
export const setRebateSelected = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_MANAGEMENT_SELECTED,
    });
//#endregion

//#region open edit rebate modal
export const rebateEditModalOpen = (dispatch) =>
    dispatch({
        type: ACTIONS.REBATE_MANAGEMENT_EDIT_MODAL_OPEN,
    });

export const rebateEditModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.REBATE_MANAGEMENT_EDIT_MODAL_CLOSE,
    });
//#endregion

//#region Rebate Info Setting
export const setRebateManagementInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_MANAGEMENT_INFO_UPDATE,
    });

export const setMultiRebateManagementInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_MANAGEMENT_MULTI_INFO_UPDATE,
    });

export const setRebateManagementInfoByLanguage = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_MANAGEMENT_INFO_UPDATE_BY_LANGUAGE,
    });
//#end region

//#region Rebate Management Filter
export const rebateManagementFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_MANAGEMENT_FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.REBATE_MANAGEMENT_FILTERS_DROP_ALL,
    });
//#end region

//#region Rebate Management language set
export const setRebateManagementLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_MANAGEMENT_LANGUAGE_SET,
    });

export const dropRebateManagementLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_MANAGEMENT_LANGUAGE_DROP,
    });
//#end region

//#region Rebate Management Get
export const rebateManagementGet = (dispatch) =>
    dispatch({
        type: ACTIONS.REBATE_MANAGEMENT_GET,
    });

export const rebateManagementGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_MANAGEMENT_GET_SUCCESS,
    });

export const rebateManagementGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_MANAGEMENT_GET_FAILURE,
    });
//#endregion

//#region Rebate Management change page
export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.REBATE_MANAGEMENT_TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.REBATE_MANAGEMENT_TABLE_PAGE_CHANGE,
    });
//#endregion

//#region Rebate Management By Id Get
export const rebateManagementByIdGet = (dispatch) =>
    dispatch({
        type: ACTIONS.REBATE_MANAGEMENT_GET_BY_ID,
    });

export const rebateManagementByIdGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_MANAGEMENT_GET_BY_ID_SUCCESS,
    });

export const rebateManagementByIdGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_MANAGEMENT_GET_BY_ID_FAILURE,
    });
//#endregion

//#region Update Rebate Management
export const rebateManagementUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.REBATE_MANAGEMENT_UPDATE,
    });

export const rebateManagementUpdateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.REBATE_MANAGEMENT_UPDATE_SUCCESS,
    });

export const rebateManagementUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_MANAGEMENT_UPDATE_FAILURE,
    });
//#endregion

//#region Status Update Rebate Management
export const rebateManagementStatusUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.REBATE_MANAGEMENT_UPDATE,
    });

export const rebateManagementStatusUpdateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.REBATE_MANAGEMENT_UPDATE_SUCCESS,
    });

export const rebateManagementStatusUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_MANAGEMENT_UPDATE_FAILURE,
    });
//#endregion

export const rebateManagementDuplicateByLanguage = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_MANAGEMENT_DUPLICATE_BY_LANGUAGE,
    });
