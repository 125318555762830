import React from 'react';

import _ from 'lodash';

import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../merchantConfigurationActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';
import OperationButton from './OperationButton';

export const getColumns = () => {
    // ============================================
    // METHODS
    // ============================================

    const getMemberLevels = (providers) => {
        return !_.isEmpty(providers) ? `${_.join(providers[0].memberLevels, ',')}` : null;
    };

    const getTrustLevels = (providers) => {
        return !_.isEmpty(providers) ? `${_.join(providers[0].trustLevels, ',')}` : null;
    };

    // ============================================
    // RENDER
    // ============================================

    const columns = [
        {
            dataIndex: 'displayName',
            title: getTranslation('Merchant Name'),
        },
        {
            dataIndex: 'gatewayName',
            title: getTranslation('Merchant Code'),
        },
        {
            dataIndex: 'merchantDomain',
            title: getTranslation('Merchant Domain'),
        },
        {
            dataIndex: 'merchantId',
            title: getTranslation('Merchant ID'),
        },
        {
            dataIndex: 'trustLevel',
            render: (text, record) => getTrustLevels(record.providers),
            title: getTranslation('Trust Level'),
        },
        {
            dataIndex: 'memberLevel',
            render: (text, record) => getMemberLevels(record.providers),
            title: getTranslation('Member Level'),
            width: 200,
        },
        {
            dataIndex: 'isWebShow',
            render: (value) => <span>{value ? 'Yes' : 'No'}</span>,
            title: getTranslation('Web Show'),
        },
        {
            dataIndex: 'amountToday',
            render: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            title: getTranslation('Amount Today'),
        },
        {
            dataIndex: 'remark',
            title: getTranslation('Remark'),
        },
        {
            dataIndex: 'operation',
            dataType: 'operation',
            render: (text, record) => <OperationButton merchantDetails={record} />,
            title: getTranslation('Operation'),
            width: 80,
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
