import React from 'react';

import { AutoComplete } from 'antd';
import _ from 'lodash';

import { getTranslation } from '../../../common/helpers/locale';

const BonusCodeFilter = ({ value, onFilterChange, bonusCodeList }) => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <AutoComplete
            className="filter"
            placeholder={getTranslation('Bonus Code')}
            value={value}
            onChange={(value) => onFilterChange('bonusCode', value)}
        >
            {_.map(bonusCodeList, (value) => (
                <AutoComplete.Option key={value}>{value}</AutoComplete.Option>
            ))}
        </AutoComplete>
    );
};

export default BonusCodeFilter;
