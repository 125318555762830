import React from 'react';

import { EditOutlined } from '@ant-design/icons';

import { ButtonBlue } from '../../../../../common/components/buttons/Buttons';
import { deactivatedEwalletSetupModalOpen } from '../DeactivatedEwalletActions';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useDispatch } from '../../../../../store/StateProvider';

const OperationButton = ({ accountDetails }) => {
    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ============================================

    const openModal = () => deactivatedEwalletSetupModalOpen(dispatch, { accountDetails });

    // ============================================
    // RENDER
    // ============================================

    return (
        <ButtonBlue className="action-btn__verify" icon={<EditOutlined />} onClick={openModal}>
            {getTranslation('Edit')}
        </ButtonBlue>
    );
};

export default OperationButton;
