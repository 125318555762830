import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

import {
    ACTIONS,
    changePassword,
    changePasswordFailure,
    changePasswordSuccess,
    changeStatus,
    changeStatusFailure,
    createModification,
    createModificationFailure,
    createModificationSuccess,
    createPlayer,
    createPlayerFailure,
    createProhibition,
    createProhibitionFailure,
    createProhibitionSuccess,
    createRemark,
    createRemarkFailure,
    createRemarkSuccess,
    gamePlatformGet,
    gamePlatformGetFailure,
    gamePlatformGetSuccess,
    getAffiliateUplineInfoFailure,
    getAffiliateUplineInfoSuccess,
    getPlayerDangerLevel,
    getPlayerDangerLevelFailure,
    getPlayerDangerLevelSuccess,
    playerGameAccessGet,
    playerGameAccessGetFailure,
    playerGameAccessGetSuccess,
    playersGameAccessUpdate,
    playersGameAccessUpdateFailure,
    playersGameAccessUpdateSuccess,
    playersGet,
    playersGetFailure,
    playersGetSuccess,
    prohibitionsGet,
    prohibitionsGetFailure,
    prohibitionsGetSuccess,
    remarksGet,
    remarksGetFailure,
    remarksGetSuccess,
    sabaWalletBalanceGet,
    sabaWalletBalanceGetFailure,
    sabaWalletBalanceGetSuccess,
    statusHistoryGet,
    statusHistoryGetFailure,
    statusHistoryGetSuccess,
    verifyPhone,
    verifyPhoneFailure,
    verifyPhoneSuccess,
    walletBalanceGet,
    walletBalanceGetFailure,
    walletBalanceGetSuccess,
    walletTransfer,
    walletTransferFailure,
    walletTransferSuccess,
} from '../components/players/players-list/PlayersListActions';
import { API, MODIFICATION_ACTION_TYPES, TRANSFER_DIRECTIONS } from '../common/constants/api';
import { DEBOUNCE_DELAY_SEC } from '../common/constants/misc';
import { MODIFICATION_STATUSES } from '../components/players/constants';
import {
    addBankCard,
    addBankCardFailure,
    addBankCardSuccess,
    addEWallet,
    addEWalletFailure,
    addEWalletSuccess,
    deleteBankCard,
    deleteBankCardFailure,
    deleteBankCardSuccess,
    deleteEWallet,
    deleteEWalletFailure,
    deleteEWalletSuccess,
    eWalletWithdrawalRequest,
    eWalletWithdrawalRequestFailure,
    eWalletWithdrawalRequestSuccess,
    playerGetById,
    playerGetByIdFailure,
    playerGetByIdSuccess,
    updatePlayerInfo,
    updatePlayerInfoFailure,
    updatePlayerInfoSuccess,
    userSessionsGetByUserId,
    userSessionsGetByUserIdFailure,
    userSessionsGetByUserIdSuccess,
    withdrawalRequest,
    withdrawalRequestFailure,
    withdrawalRequestSuccess,
} from '../components/players/player-details/playerDetailsActions';
import {
    channelsGet,
    channelsGetFailure,
    channelsGetSuccess,
} from '../components/players/channel-inquiry/channelsActions';
import { clearError } from '../common/actions/errorActions';
import {
    createChangeStatusOperation,
    createPlayerOperation,
} from '../common/actions/operationsActions';
import {
    deleteDangerLevelTag,
    deleteDangerLevelTagFailure,
    deleteDangerLevelTagSuccess,
    getDangerLevelFilterList,
    getDangerLevelFilterListFailure,
    getDangerLevelFilterListSuccess,
    getDangerLevelTag,
    getDangerLevelTagFailure,
    getDangerLevelTagSuccess,
    getDangerLevels,
    getDangerLevelsFailure,
    getDangerLevelsSuccess,
    updateDangerLevelSetting,
    updateDangerLevelSettingFailure,
    updateDangerLevelSettingSuccess,
    updateDangerLevelTag,
    updateDangerLevelTagFailure,
    updateDangerLevelTagSuccess,
} from '../components/players/danger-level/dangerLevelActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import { getOperationId } from '../common/helpers/api';
import { getTranslation } from '../common/helpers/locale';
import {
    getWithdrawDangerLevel,
    getWithdrawDangerLevelFailure,
    getWithdrawDangerLevelSuccess,
} from '../components/payments/withdrawals/withdrawalsActions';
import {
    modificationsHistoryGet,
    modificationsHistoryGetFailure,
    modificationsHistoryGetSuccess,
} from '../components/players/modifications-history/modificationsHistoryActions';
import {
    pendingModificationsGet,
    pendingModificationsGetFailure,
    pendingModificationsGetSuccess,
    verifyPendingModification,
    verifyPendingModificationFailure,
    verifyPendingModificationSuccess,
} from '../components/players/pending-modifications/pendingModificationsActions';
import {
    playerIpGet,
    playerIpGetFailure,
    playerIpGetSuccess,
    registrationDetailsGet,
    registrationDetailsGetFailure,
    registrationDetailsGetSuccess,
    searchIpGet,
    searchIpGetFailure,
    searchIpGetSuccess,
} from '../components/players/registration-details/registrationDetailsActions';
import { useDebounce } from './hooks/useDebounce';
import { useDispatch, useStore } from '../store/StateProvider';
import { useOperationAsyncEndpoint } from './operationsService';
import { useToasterErrorMessage, useToasterSuccessMessage } from './hooks/useToasterMessage';
import {
    userSessionsGet,
    userSessionsGetFailure,
    userSessionsGetSuccess,
} from '../components/players/user-sessions/userSessionsActions';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, IDENTITY, PAYMENTS, PLAYERS, SABA, WALLET, PLATFORM } = API;

const isFilterValueValid = (value) => {
    if (value) {
        if (!_.isEmpty(value.replace(/\s/g, ''))) {
            return true;
        }
    }
    return false;
};

export function useGetPlayersAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { pageNo, pageSize } = getStateProp(state, 'players.playersList.paging');
    const { shortIds, ...restFilter } = getStateProp(state, 'players.playersList.filters', {});
    const isMasked = window.location.href.lastIndexOf('/isMasked') === -1;

    // ============================================
    // METHODS
    // ============================================

    const handleGetPlayers = useCallback(() => {
        playersGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'POST',
        options: {
            data: {
                ...filterTrim(restFilter),
                affiliateUpline: isFilterValueValid(restFilter?.affiliateUpline)
                    ? restFilter?.affiliateUpline
                    : undefined,
                isMasked: !isMasked,
                pageNo,
                pageSize,
                shortIds: shortIds && _.compact(shortIds.split(',').map((item) => item.trim())),
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            playersGetSuccess(dispatch, {
                fetchData: handleGetPlayers,
                list: _.map(response.data.data, (listItem) =>
                    _.pickBy(
                        {
                            ..._.merge(listItem, listItem.monetary),
                            ..._.merge(listItem, listItem.profile),
                        },
                        (value, key) => !_.includes(['profile', 'monetary'], key),
                    ),
                ),
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetPlayers, response]);

    useEffect(() => {
        if (pageNo && pageSize) handleGetPlayers();
    }, [handleGetPlayers, pageNo, pageSize]);

    const errorMsg = useError(error, playersGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetPlayers];
}

export function useGetPlayerByIdAsyncEndpoint(playerId) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetPlayerById = useCallback(() => {
        playerGetById(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                isMasked: !!window.location.href.split('/isMasked')[1],
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.ROOT}/${playerId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            playerGetByIdSuccess(dispatch, {
                playerInfo: response.data.data
                    ? response.data.data
                    : {
                          profile: {},
                      },
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (playerId) handleGetPlayerById();
    }, [playerId, handleGetPlayerById]);

    const errorMsg = useError(error, playerGetByIdFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetPlayerById];
}

export function useGetRegistrationDetailsAsyncEndpoint(userName) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetRegistrationDetails = useCallback(() => {
        registrationDetailsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${PLAYERS.REGISTRATION_DETAILS}/${userName}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            registrationDetailsGetSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, registrationDetailsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetRegistrationDetails];
}

export function useGetPlayerIpAsyncEndpoint(ipAddress) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { pageNo, pageSize } = getStateProp(
        state,
        'players.registrationDetails.playerIpList.paging',
    );

    // ============================================
    // METHODS
    // ============================================

    const handleGetPlayerIp = useCallback(() => {
        playerIpGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'GET',
        options: {
            params: {
                ipAddress,
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.IP_ADDRESS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            playerIpGetSuccess(dispatch, {
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (pageNo && pageSize) handleGetPlayerIp();
    }, [handleGetPlayerIp, pageNo, pageSize]);

    const errorMsg = useError(error, playerIpGetFailure);
    useToasterErrorMessage(error, errorMsg);
    return [loading, handleGetPlayerIp];
}

export function useGetSearchIpAsyncEndpoint(ipAddress) {
    const dispatch = useStore()[1];
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetSearchIp = useCallback(() => {
        searchIpGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'GET',
        options: {
            params: {
                ipAddress,
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.SEARCH_IP}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            searchIpGetSuccess(dispatch, {
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        handleGetSearchIp();
    }, [handleGetSearchIp]);

    const errorMsg = useError(error, searchIpGetFailure);
    useToasterErrorMessage(error, errorMsg);
    return [loading, handleGetSearchIp];
}

export function useGetProhibitionsAsyncEndpoint(userId, type) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetProhibitions = useCallback(() => {
        prohibitionsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'POST',
        options: {
            data: {
                type,
                userId,
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.PROHIBITIONS.LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            prohibitionsGetSuccess(dispatch, {
                list: response.data.data,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (userId && type) handleGetProhibitions();
    }, [handleGetProhibitions, userId, type]);

    const errorMsg = useError(error, prohibitionsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetProhibitions];
}

export function useGetRemarksAsyncEndpoint(userId) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { pageNo, pageSize } = getStateProp(state, 'players.playersList.remarks.paging');

    // ============================================
    // METHODS
    // ============================================

    const handleGetRemarks = useCallback(() => {
        remarksGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'POST',
        options: {
            data: {
                pageNo,
                pageSize,
                userId,
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.REMARKS.LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            remarksGetSuccess(dispatch, {
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (pageNo && pageSize && userId) handleGetRemarks();
    }, [handleGetRemarks, pageNo, pageSize, userId]);

    const errorMsg = useError(error, remarksGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetRemarks];
}

export function useGetStatusHistoryAsyncEndpoint(userId) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { pageNo, pageSize } = getStateProp(state, 'players.playersList.status.paging');

    // ============================================
    // METHODS
    // ============================================

    const handleGetStatusHistory = useCallback(() => {
        statusHistoryGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'POST',
        options: {
            data: {
                pageNo,
                pageSize,
                userId,
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.STATUS_HISTORY}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            statusHistoryGetSuccess(dispatch, {
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (pageNo && pageSize && userId) handleGetStatusHistory();
    }, [handleGetStatusHistory, pageNo, pageSize, userId]);

    const errorMsg = useError(error, statusHistoryGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetStatusHistory];
}

export function useGetUserSessionsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { pageNo, pageSize } = getStateProp(state, 'players.userSessions.paging');
    const filters = getStateProp(state, 'players.userSessions.filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetUserSessions = useCallback(() => {
        userSessionsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'POST',
        options: {
            data: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.SESSIONS.ALL}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            userSessionsGetSuccess(dispatch, {
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (pageNo && pageSize) handleGetUserSessions();
    }, [pageNo, pageSize, handleGetUserSessions]);

    const errorMsg = useError(error, userSessionsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetUserSessions];
}

export function useGetUserSessionsByUserIdAsyncEndpoint(userId) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { pageNo, pageSize } = getStateProp(state, 'players.playerDetails.userSessions.paging');

    // ============================================
    // METHODS
    // ============================================

    const handleGetUserSessionsByUserId = useCallback(() => {
        userSessionsGetByUserId(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'POST',
        options: {
            data: {
                pageNo,
                pageSize,
                userId,
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.SESSIONS.BY_USER_ID}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            userSessionsGetByUserIdSuccess(dispatch, {
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (pageNo && pageSize) handleGetUserSessionsByUserId();
    }, [pageNo, pageSize, handleGetUserSessionsByUserId]);

    const errorMsg = useError(error, userSessionsGetByUserIdFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useGetChannelsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { pageNo, pageSize } = getStateProp(state, 'players.channels.paging');
    const filters = getStateProp(state, 'players.channels.filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetChannels = useCallback(() => {
        channelsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'POST',
        options: {
            data: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.CHANNELS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            channelsGetSuccess(dispatch, {
                fetchData: handleGetChannels,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetChannels, response]);

    useEffect(() => {
        if (pageNo && pageSize) handleGetChannels();
    }, [pageNo, pageSize, handleGetChannels]);

    const errorMsg = useError(error, channelsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetChannels];
}

export function useGetPendingModificationsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { pageNo, pageSize } = getStateProp(state, 'players.pendingModifications.paging');
    const filters = getStateProp(state, 'players.pendingModifications.filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetModifications = useCallback(() => {
        pendingModificationsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'POST',
        options: {
            data: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
                status: [MODIFICATION_STATUSES.PENDING],
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.MODIFICATION_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            pendingModificationsGetSuccess(dispatch, {
                fetchData: handleGetModifications,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetModifications, response]);

    useEffect(() => {
        if (pageNo && pageSize) handleGetModifications();
    }, [pageNo, pageSize, handleGetModifications]);

    const errorMsg = useError(error, pendingModificationsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetModifications];
}

export function useGetModificationsHistoryAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { pageNo, pageSize } = getStateProp(state, 'players.modificationsHistory.paging');
    const filters = getStateProp(state, 'players.modificationsHistory.filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetModifications = useCallback(() => {
        modificationsHistoryGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'POST',
        options: {
            data: {
                pageNo,
                ...filterTrim(filters),
                pageSize,
                status:
                    filters && filters.status
                        ? [filters.status]
                        : [MODIFICATION_STATUSES.APPROVED, MODIFICATION_STATUSES.DECLINED],
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.MODIFICATION_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            modificationsHistoryGetSuccess(dispatch, {
                fetchData: handleGetModifications,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetModifications, response]);

    useEffect(() => {
        if (pageNo && pageSize) handleGetModifications();
    }, [pageNo, pageSize, handleGetModifications]);

    const errorMsg = useError(error, modificationsHistoryGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetModifications];
}

export function useCreateModificationAsyncEndpoint(modification) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleCreateModification = () => {
        createModification(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: modification,
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.MODIFICATION}/`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            createModificationSuccess(dispatch);
        }
    }, [dispatch, response]);

    useToasterSuccessMessage(response, getTranslation('Modification created!'));

    const errorMsg = useError(error, createModificationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleCreateModification, response];
}

export function useVerifyModificationAsyncEndpoint(attendantRemark, actionType) {
    const [state, dispatch] = useStore();
    const id = getStateProp(state, 'players.pendingModifications.modificationAttending.id', '');
    const [trigger, setTrigger] = useState(null);

    const successMessage =
        actionType === MODIFICATION_ACTION_TYPES.APPROVE
            ? 'Modification approved!'
            : 'Modification rejected!';

    // ============================================
    // METHODS
    // ============================================

    const handleVerifyModification = () => {
        verifyPendingModification(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                attendantRemark,
                id,
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.MODIFICATION}${actionType}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            verifyPendingModificationSuccess(dispatch);
        }
    }, [dispatch, response]);

    useToasterSuccessMessage(response, getTranslation(successMessage));

    const errorMsg = useError(error, verifyPendingModificationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleVerifyModification, response];
}

export function useCreateProhibitionAsyncEndpoint(prohibition) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleCreateProhibition = () => {
        createProhibition(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: prohibition,
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.PROHIBITIONS.CREATE}/`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            createProhibitionSuccess(dispatch);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, createProhibitionFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleCreateProhibition];
}

export function useCreateRemarkAsyncEndpoint(remark) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleCreateRemark = () => {
        createRemark(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: remark,
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.REMARKS.CREATE}/`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            createRemarkSuccess(dispatch);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, createRemarkFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleCreateRemark];
}

export function useGetAffiliateUplineInfoAsyncEndpoint(playerInfo) {
    const dispatch = useDispatch();

    const betfiliateId = useDebounce(playerInfo, DEBOUNCE_DELAY_SEC);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!betfiliateId,
        method: 'GET',
        options: {
            params: {
                Program: 'Betfiliate',
            },
        },
        trigger: betfiliateId,
        url: `${VERSION.V1}${PLAYERS.ROOT}/${betfiliateId}${PLAYERS.GET_AFFILIATE_CURRENCY_CODE}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getAffiliateUplineInfoSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, getAffiliateUplineInfoFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response];
}

export function useCreatePlayerAsyncEndpoint(playerInfo) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);
    const [requestProcessing, setRequestProcessing] = useState(false);

    // ============================================
    // METHODS
    // ============================================

    const handleCreatePlayer = () => {
        createPlayer(dispatch);
        setTrigger(+new Date());
        setRequestProcessing(true);
    };

    // ============================================
    // HOOKS
    // ============================================

    const { error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                ...playerInfo,
                betfiliateId: playerInfo.betfiliateChannel ? playerInfo.betfiliateId : undefined,
                inviteCode: !_.isEmpty(playerInfo.inviteCode) ? playerInfo.inviteCode : undefined,
            },
        },
        trigger,
        url: `${VERSION.V1}${IDENTITY.CREATE_PLAYER}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            createPlayerOperation(dispatch, getOperationId(response));
        }
    }, [dispatch, response]);

    const [operationComplete, operationError] = useOperationAsyncEndpoint(
        'createPlayer',
        ACTIONS.CREATE_PLAYER_SUCCESS,
        ACTIONS.CREATE_PLAYER_FAILURE,
        'Player successfully created!',
    );

    useEffect(() => {
        if (operationComplete || operationError || error) {
            setRequestProcessing(false);
        }
    }, [error, operationComplete, operationError]);

    const errorMsg = useError(error, createPlayerFailure);
    useToasterErrorMessage(error, errorMsg);

    return [requestProcessing, handleCreatePlayer, operationComplete];
}

export function useUpdatePlayerInfoAsyncEndpoint(playerId, updatedInfo) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    const formattedInfo = _.filter(
        _.map(Object.keys(updatedInfo), (key) => {
            if (updatedInfo[key] !== null) {
                return {
                    op: 'replace',
                    path: `/${key}`,
                    value: updatedInfo[key],
                };
            }
        }),
        (item) => item,
    );

    // ============================================
    // METHODS
    // ============================================

    const handleUpdatePlayerInfo = () => {
        updatePlayerInfo(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PATCH',
        options: {
            data: formattedInfo,
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.UPDATE_PLAYER}${playerId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            updatePlayerInfoSuccess(dispatch, updatedInfo);
        }
    }, [dispatch, response, updatedInfo]);

    useToasterSuccessMessage(response, getTranslation('Player information successfully updated!'));

    const errorMsg = useError(error, updatePlayerInfoFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleUpdatePlayerInfo];
}

export function useAddBankCardAsyncEndpoint(bankCard) {
    const [state, dispatch] = useStore();
    const userId = getStateProp(state, 'players.playerDetails.playerInfo.id', '');
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleAddBankCard = () => {
        addBankCard(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: { ...bankCard, userId },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.BANK_CARDS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            addBankCardSuccess(dispatch);
        }
    }, [dispatch, response]);

    useToasterSuccessMessage(response, 'Bank card successfully added!');

    const errorMsg = useError(error, addBankCardFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleAddBankCard, response];
}

export function useDeleteBankCardAsyncEndpoint(bankCardId) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleDeleteBankCard = () => {
        deleteBankCard(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'DELETE',
        trigger,
        url: `${VERSION.V1}${PLAYERS.BANK_CARDS}/${bankCardId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            deleteBankCardSuccess(dispatch);
        }
    }, [dispatch, response]);

    useToasterSuccessMessage(response, 'Bank card successfully deleted!');

    const errorMsg = useError(error, deleteBankCardFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleDeleteBankCard, response];
}

export function useChangePasswordAsyncEndpoint(updatedInfo) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleChangePassword = () => {
        changePassword(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: updatedInfo,
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.CHANGE_PASSWORD}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            changePasswordSuccess(dispatch);
        }
    }, [dispatch, response]);

    useToasterSuccessMessage(response, getTranslation('Password successfully changed!'));

    const errorMsg = useError(error, changePasswordFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleChangePassword];
}

export function useChangeStatusAsyncEndpoint(status) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);
    const [requestProcessing, setRequestProcessing] = useState(false);

    // ============================================
    // METHODS
    // ============================================

    const handleChangeStatus = () => {
        changeStatus(dispatch);
        setTrigger(+new Date());
        setRequestProcessing(true);
    };

    // ============================================
    // HOOKS
    // ============================================

    const { error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: status,
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.REMARKS.CREATE}/`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            createChangeStatusOperation(dispatch, getOperationId(response));
        }
    }, [dispatch, response]);

    const [operationComplete, operationError] = useOperationAsyncEndpoint(
        'changePlayerStatus',
        ACTIONS.STATUS_CHANGE_SUCCESS,
        ACTIONS.STATUS_CHANGE_FAILURE,
        'Player status successfully!',
    );

    useEffect(() => {
        if (operationComplete || operationError || error) {
            setRequestProcessing(false);
        }
    }, [error, operationComplete, operationError]);

    const errorMsg = useError(error, changeStatusFailure);
    useToasterErrorMessage(error, errorMsg);

    return [requestProcessing, operationComplete, handleChangeStatus];
}

export function useGetWalletsAsyncEndpoint(userName) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    const handleGetWallet = useCallback(() => {
        walletBalanceGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!userName,
        method: 'POST',
        options: {
            data: {
                userName,
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.WALLETS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);

            let walletsObject = {};

            _.map(response.data.data.wallets, (wallet) => {
                _.assign(walletsObject, {
                    [`${_.camelCase(wallet.wallet)}${'Balance'}`]: wallet.balance,
                });
            });

            walletBalanceGetSuccess(dispatch, {
                walletCurrencyCode: response.data.data.currencyCode,
                wallets: response.data.data.wallets,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (userName) handleGetWallet();
    }, [handleGetWallet, userName]);

    const errorMsg = useError(error, walletBalanceGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetWallet];
}

export function useGetSabaWalletAsyncEndpoint(userName, currencyCode) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    const handleGetSabaWallet = useCallback(() => {
        sabaWalletBalanceGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${SABA.PLAYERS}/${userName}${WALLET}/${currencyCode}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            sabaWalletBalanceGetSuccess(dispatch, {
                oneWorksBalance: _.get(response, 'data.data.balance', 0),
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (userName) handleGetSabaWallet();
    }, [handleGetSabaWallet, userName]);

    const errorMsg = useError(error, sabaWalletBalanceGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetSabaWallet];
}

export function useWalletTransferAsyncEndpoint(playerId, fromWallet, toWallet, amount) {
    const dispatch = useDispatch();
    const direction = fromWallet === 'GGBook' ? 'transferIn' : 'transferOut';

    // ============================================
    // METHODS
    // ============================================

    const handleSubmitTransfer = () => {
        clearError(dispatch);
        walletTransfer(dispatch);
        executeRequest();
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response, executeRequest } = useApi({
        method: 'POST',
        options: {
            data: {
                amount,
                gamingPlatform: `/${direction}` === TRANSFER_DIRECTIONS.IN ? toWallet : fromWallet,
                playerId,
                remarks: `${
                    direction === TRANSFER_DIRECTIONS.IN ? 'Transfer In' : 'Transfer Out'
                } GGBook wallet`,
            },
        },
        url: `${VERSION.V1}${PAYMENTS.ROOT}/${direction}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            walletTransferSuccess(dispatch);
        }
    }, [dispatch, response]);

    useToasterSuccessMessage(response, getTranslation('Transfer successfully completed!'));

    const errorMsg = useError(error, walletTransferFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleSubmitTransfer];
}

export function useWithdrawalRequestAsyncEndpoint(withdrawalData) {
    const [state, dispatch] = useStore();
    const playerId = getStateProp(state, 'players.playerDetails.playerInfo.id', '');
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleWithdrawalRequest = () => {
        withdrawalRequest(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: { ...withdrawalData, playerId },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.WITHDRAWALS.ROOT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            withdrawalRequestSuccess(dispatch);
        }
    }, [dispatch, response]);

    useToasterSuccessMessage(response, 'Withdrawal requested!');

    const errorMsg = useError(error, withdrawalRequestFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleWithdrawalRequest, response];
}

export function useVerifyPlayerMobileAsyncEndpoint(userId, mobileNo) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleVerifyPlayerMobile = () => {
        verifyPhone(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger && !!mobileNo,
        method: 'POST',
        options: {
            data: {
                mobileNo,
                userId,
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.VERIFY_PHONE_NO}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            verifyPhoneSuccess(dispatch, {
                verified: response.data.data,
            });
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, verifyPhoneFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleVerifyPlayerMobile, response];
}

export function useGetGamePlatformAsyncEndpoint() {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetGamePlatform = useCallback(() => {
        gamePlatformGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${PLATFORM.LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            gamePlatformGetSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    useEffect(() => {
        handleGetGamePlatform();
    }, [handleGetGamePlatform]);

    const errorMsg = useError(error, gamePlatformGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetGamePlatform];
}

export function useUpdatePlayerGamePlatformAsyncEndpoint(formValues) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleUpdatePlayerGamePlatform = () => {
        playersGameAccessUpdate(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: formValues,
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.GAME_ACCESS.UPDATE_PLAYER_GAME_ACCESS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            playersGameAccessUpdateSuccess(dispatch);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, playersGameAccessUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdatePlayerGamePlatform, response];
}

export function useGetPlayerGameAccessAsyncEndpoint(userName) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetPlayerGameAccess = useCallback(() => {
        playerGameAccessGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'POST',
        options: {
            data: {
                players: [userName],
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.GAME_ACCESS.GET_PLAYER_GAME_ACCESS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            playerGameAccessGetSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    useEffect(() => {
        handleGetPlayerGameAccess();
    }, [handleGetPlayerGameAccess]);

    const errorMsg = useError(error, playerGameAccessGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response];
}

export function useGetDangerLevelsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const filters = getStateProp(state, 'players.dangerLevel.filters', {});
    const depositObj =
        _.isEmpty(filters) || _.isEmpty(filters.deposit)
            ? {}
            : {
                  bankTransfer: _.includes(filters.deposit, 'bankTransferStatus'),
                  cryptoCurrency: _.includes(filters.deposit, 'cryptoCurrencyStatus'),
                  onlineBanking: _.includes(filters.deposit, 'onlineBankingStatus'),
              };
    const dangerLevelsObj =
        _.isEmpty(filters) || filters.dangerLevels === undefined
            ? {}
            : {
                  dangerLevels: [filters.dangerLevels],
              };

    // ============================================
    // METHODS
    // ============================================

    const handleGetDangerLevels = useCallback(() => {
        getDangerLevels(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'POST',
        options: {
            data: {
                ...filterTrim(
                    _.pickBy(filters, (e, key) => {
                        return key !== 'deposit';
                    }),
                ),
                ..._.pickBy(depositObj, (value) => {
                    return value;
                }),
                ...dangerLevelsObj,
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.DANGER_LEVEL.GET}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getDangerLevelsSuccess(dispatch, {
                fetchData: handleGetDangerLevels,
                list: response.data.data,
            });
        }
    }, [dispatch, handleGetDangerLevels, response]);

    const errorMsg = useError(error, getDangerLevelsFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetDangerLevels];
}

export function useGetDangerLevelFilterListAsyncEndpoint(fromPage) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetDangerLevelFilterList = useCallback(() => {
        if (fromPage === 'withdraw') {
            getWithdrawDangerLevel(dispatch);
        } else if (fromPage === 'player_list') {
            getPlayerDangerLevel(dispatch);
        } else {
            getDangerLevelFilterList(dispatch);
        }

        setTrigger(+new Date());
    }, [dispatch, fromPage]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {},
        trigger,
        url: `${VERSION.V1}${PLAYERS.DANGER_LEVEL.GET_DANGER_LEVEL_FILTER_LSIT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            if (fromPage === 'withdraw') {
                getWithdrawDangerLevelSuccess(dispatch, response.data.data);
            } else if (fromPage === 'player_list') {
                getPlayerDangerLevelSuccess(dispatch, response.data.data);
            } else {
                getDangerLevelFilterListSuccess(dispatch, {
                    dangerLevelFilterList: response.data.data,
                    fetchDangerLevelFilterList: handleGetDangerLevelFilterList,
                });
            }
        }
    }, [dispatch, fromPage, handleGetDangerLevelFilterList, response]);

    const errorMsg = useError(
        error,
        fromPage === 'withdraw'
            ? getWithdrawDangerLevelFailure
            : fromPage === 'player_list'
            ? getPlayerDangerLevelFailure
            : getDangerLevelFilterListFailure,
    );
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetDangerLevelFilterList];
}

export function useGetDangerLevelTagAsyncEndpoint() {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetDangerLevelTag = useCallback(() => {
        getDangerLevelTag(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'GET',
        options: {},
        trigger,
        url: `${VERSION.V1}${PLAYERS.DANGER_LEVEL.GET_DANGER_LEVEL_TAG}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getDangerLevelTagSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, getDangerLevelTagFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetDangerLevelTag];
}

export function useUpdateDangerLevelTagAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const {
        dangerLevelTagList = {},
        fetchData = () => {},
        fetchDangerLevelFilterList = () => {},
    } = getStateProp(state, 'players.dangerLevel', {});

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateDangerLevelTag = useCallback(() => {
        updateDangerLevelTag(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                dangerLevelTagList,
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.DANGER_LEVEL.UPDATE_DANGER_LEVEL_TAG}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            updateDangerLevelTagSuccess(dispatch);
            fetchData();
            fetchDangerLevelFilterList();
        }
    }, [dispatch, fetchDangerLevelFilterList, fetchData, response]);

    const errorMsg = useError(error, updateDangerLevelTagFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateDangerLevelTag];
}

export function useDeleteDangerLevelTagAsyncEndpoint() {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);
    const [selectedDangerLevel, setSelectedDangerLevel] = useState('');

    // ============================================
    // METHODS
    // ============================================

    const handleDeleteDangerLevelTag = useCallback(
        (selectedDangerLevel) => {
            deleteDangerLevelTag(dispatch);
            setTrigger(+new Date());
            setSelectedDangerLevel(selectedDangerLevel);
        },
        [dispatch],
    );

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                dangerLevels: [selectedDangerLevel],
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.DANGER_LEVEL.DELETE_DANGER_LEVEL_TAG}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            deleteDangerLevelTagSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, deleteDangerLevelTagFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleDeleteDangerLevelTag];
}

export function useUpdateDangerLevelSettingAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { selectedDangerLevelSetting = {}, fetchData = () => {} } = getStateProp(
        state,
        'players.dangerLevel',
        {},
    );

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateDangerLevelSetting = useCallback(() => {
        updateDangerLevelSetting(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                ...selectedDangerLevelSetting,
            },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.DANGER_LEVEL.UPDATE}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            updateDangerLevelSettingSuccess(dispatch);
            fetchData();
        }
    }, [dispatch, fetchData, response]);

    const errorMsg = useError(error, updateDangerLevelSettingFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateDangerLevelSetting];
}

export function useAddEWalletAsyncEndpoint(eWallet) {
    const [state, dispatch] = useStore();
    const userId = getStateProp(state, 'players.playerDetails.playerInfo.id', '');
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleAddEWallet = () => {
        addEWallet(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: { ...eWallet, isOperator: true, userId },
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS.E_WALLET}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            addEWalletSuccess(dispatch);
        }
    }, [dispatch, response]);

    useToasterSuccessMessage(response, 'E-Wallet successfully added!');

    const errorMsg = useError(error, addEWalletFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleAddEWallet, response];
}

export function useDeleteEWalletAsyncEndpoint(eWalletId) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleDeleteEWallet = () => {
        deleteEWallet(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'DELETE',
        trigger,
        url: `${VERSION.V1}${PLAYERS.E_WALLET}/${eWalletId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            deleteEWalletSuccess(dispatch);
        }
    }, [dispatch, response]);

    useToasterSuccessMessage(response, 'E-Wallet successfully deleted!');

    const errorMsg = useError(error, deleteEWalletFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleDeleteEWallet, response];
}

export function useEWalletWithdrawalRequestAsyncEndpoint(withdrawalData) {
    const [state, dispatch] = useStore();
    const playerId = getStateProp(state, 'players.playerDetails.playerInfo.id', '');
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleEWalletWithdrawalRequest = () => {
        eWalletWithdrawalRequest(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: { ...withdrawalData, playerId },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.EWALLET_WITHDRAWALS.ROOT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            eWalletWithdrawalRequestSuccess(dispatch);
        }
    }, [dispatch, response]);

    useToasterSuccessMessage(response, 'Withdrawal requested!');

    const errorMsg = useError(error, eWalletWithdrawalRequestFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleEWalletWithdrawalRequest, response];
}
