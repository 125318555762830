import { Switch } from 'react-router-dom';
import React from 'react';

import './Bonus.less';
import { BannerManagement, BannerSorting, CreateBanner } from '.';
import { PrivateRoute } from '../../common/components';
import { ROUTES } from '../../common/constants/routing';

const Banner = () => {
    return (
        <Switch>
            <PrivateRoute path={ROUTES.DASHBOARD.BANNER.CREATE_BANNER} component={CreateBanner} />
            <PrivateRoute
                path={ROUTES.DASHBOARD.BANNER.BANNER_MANAGEMENT}
                component={BannerManagement}
            />
            <PrivateRoute path={ROUTES.DASHBOARD.BANNER.BANNER_SORTING} component={BannerSorting} />
        </Switch>
    );
};

export default Banner;
