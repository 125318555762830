import React from 'react';

import { Form, Input, Modal, Row } from 'antd';

import './VerifyModal.less';
import {
    ButtonGray,
    ButtonGreen,
    ButtonRed,
} from '../../../../../common/components/buttons/Buttons';
import { PAYMENT_ORDER_OPERATIONS } from '../../../../../common/constants/misc';
import {
    cryptoWithdrawalRecordOperation,
    cryptoWithdrawalRecordVerifyInfo,
    onVerifyModalClose,
} from '../cryptoWithdrawalRecordActions';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useDispatch } from '../../../../../store/StateProvider';

const { TextArea } = Input;

const VerifyModal = ({ visible, transaction, onCancel, operationPending }) => {
    const { playerUserName, transactionNo, cryptoCode, cryptoAmt, cryptoAddr } = transaction;

    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ============================================

    const onValueChange = (name, value) =>
        cryptoWithdrawalRecordVerifyInfo(dispatch, { name, value });

    const verifyTransaction = () => {
        cryptoWithdrawalRecordOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.VERIFY,
        });
        onVerifyModalClose(dispatch);
    };

    const rejectTransaction = () => {
        cryptoWithdrawalRecordOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.REJECT,
        });
        onVerifyModalClose(dispatch);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            title={getTranslation('Crypto Withdrawal Check')}
            centered
            visible={visible}
            closable={false}
            className="crypto-withdrawal-record__verification-modal"
            width="750px"
            onCancel={onCancel}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        label={getTranslation('Approve')}
                        onClick={verifyTransaction}
                        loading={operationPending}
                    />
                    <ButtonRed
                        label={getTranslation('Reject')}
                        onClick={rejectTransaction}
                        loading={operationPending}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
        >
            <Form name="verify-crypto-withdrawal">
                <Row justify="start">
                    <span className="modal-description">{getTranslation('Username')}</span>:
                    <span className="crypto-withdrawal-record">{playerUserName}</span>
                </Row>
                <Row justify="start">
                    <span className="modal-description">
                        {getTranslation('Task Transaction Number')}
                    </span>
                    :<span className="crypto-withdrawal-record">{transactionNo}</span>
                </Row>
                <Row justify="start">
                    <span className="modal-description">{getTranslation('Crypto Currency')}</span>:
                    <span className="crypto-withdrawal-record">{cryptoCode}</span>
                </Row>
                <Row justify="start">
                    <span className="modal-description">{getTranslation('Crypto Amount')}</span>:
                    <span className="crypto-withdrawal-record">{cryptoAmt}</span>
                </Row>
                <Row justify="start">
                    <span className="modal-description">
                        {getTranslation('Receiving Wallet Address')}
                    </span>
                    :<span className="crypto-withdrawal-record">{cryptoAddr}</span>
                </Row>
                <Row justify="start">
                    <span className="modal-description">{getTranslation('Audit Remark')}</span>
                    <span className="crypto-withdrawal-record">
                        <Form.Item name="remarks">
                            <TextArea onChange={(e) => onValueChange('remarks', e.target.value)} />
                        </Form.Item>
                    </span>
                </Row>
            </Form>
        </Modal>
    );
};

export default VerifyModal;
