import React from 'react';

import { Row } from 'antd';

import { DeleteOutlined, FormOutlined } from '@ant-design/icons';

const ColumnActionButtons = ({
    record,
    updateSelectedDangerLevelTag,
    handleDeleteDangerLevelTag,
}) => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <Row gutter={15} align="middle" justify="space-around">
            {record.showDeleteBtn && (
                <DeleteOutlined
                    className="danger-level__tag-modal__delete-btn"
                    onClick={() => handleDeleteDangerLevelTag(record.level, record.lastUpdatedBy)}
                />
            )}
            <FormOutlined
                className={
                    record.editable
                        ? 'danger-level__tag-modal__edit-btn--disabled'
                        : 'danger-level__tag-modal__edit-btn'
                }
                onClick={() =>
                    updateSelectedDangerLevelTag(
                        'editable',
                        true,
                        record.level,
                        record.lastUpdatedBy,
                    )
                }
                disabled={record.editable}
            />
        </Row>
    );
};

export default ColumnActionButtons;
