import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Checkbox, Col, Row } from 'antd';
import _ from 'lodash';

import './ColumnSelection.less';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';

const ColumnSelection = ({ tableColumnsList, selectedColumnsState, selectAction }) => {
    const [store, dispatch] = useStore();
    const selectedColumns = getStateProp(store, selectedColumnsState, tableColumnsList);
    const [allSelected, setAllSelected] = useState(
        selectedColumns.length === tableColumnsList.length,
    );

    const requiredColumns = _.filter(tableColumnsList, (column) => column.required);

    // ============================================
    // METHODS
    // ============================================

    const onChange = (selectedColumnsList) => {
        selectAction(dispatch, selectedColumnsList);
        setAllSelected(selectedColumnsList.length === tableColumnsList.length);
    };

    const onSelectAllChange = (e) => {
        selectAction(dispatch, e.target.checked ? tableColumnsList : requiredColumns);
        setAllSelected(e.target.checked);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col className="column-selection">
            <Row className="column-selection__select-all">
                <Checkbox onChange={onSelectAllChange} checked={allSelected}>
                    {getTranslation('Select All')}
                </Checkbox>
            </Row>

            <Checkbox.Group onChange={onChange} value={selectedColumns}>
                <Row style={{ flexFlow: 'row wrap' }}>
                    {tableColumnsList.map((column) => {
                        return (
                            <Col span={8} key={column.key}>
                                <Checkbox value={column} disabled={column.required}>
                                    {getTranslation(column.title)}
                                </Checkbox>
                            </Col>
                        );
                    })}
                </Row>
            </Checkbox.Group>
        </Col>
    );
};

ColumnSelection.defaultProps = {
    selectedColumnsState: '',
    tableColumnsList: [],
};

ColumnSelection.propTypes = {
    selectAction: PropTypes.func.isRequired,
    selectedColumnsState: PropTypes.string.isRequired,
    tableColumnsList: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            required: PropTypes.bool,
            title: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

export default ColumnSelection;
