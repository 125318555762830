import React from 'react';

import _ from 'lodash';

import { VIEW_MODES } from '../../constants';
import { getTranslation } from '../../../../common/helpers/locale';

const PlayerWalletBalanceGrandTotalRow = ({ tableData, viewMode }) => {
    // ============================================
    // RENDER
    // ============================================

    const GrandTotal = _.map(tableData, (data) => (
        <tr key={data.currency}>
            <td
                colSpan={viewMode === VIEW_MODES.DATE ? 2 : 3}
                style={{ background: 'rgb(237,125,49)', color: 'black' }}
            >{`${getTranslation('Total')} (${data.currency})`}</td>
            <td>{data.deposit}</td>
            <td>{data.withdrawal}</td>
            <td>{data.affiliate}</td>
            <td>{data.bonus}</td>
            <td>{data.rebate}</td>
            <td>{data.depositCorrection}</td>
            <td>{data.balanceAddition}</td>
            <td>{data.balanceDeduction}</td>
            <td>{data.pendingBet}</td>
            <td>{data.walletBalance}</td>
            <td>{data.playerWinLoss}</td>
        </tr>
    ));

    return GrandTotal;
};

export default PlayerWalletBalanceGrandTotalRow;
