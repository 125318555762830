import React from 'react';

import { Row } from 'antd';

import { DetailsFilter, TypeFilter } from './filters';
import {
    OrderStatusFilter,
    PeriodFilter,
    TransactionNumberFilter,
    UsernameFilter,
} from '../../common/components/filters';
import { getStateProp } from '../../common/helpers/misc';
import { transactionMonitoringFilterSet } from './transactionMonitoringActions';
import { useStore } from '../../store/StateProvider';

const TransactionMonitoringFilters = () => {
    const [state, dispatch] = useStore();

    const transactionMonitoring = getStateProp(state, 'transactionMonitoring');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) =>
        transactionMonitoringFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(transactionMonitoring, 'filters.playerUserName')}
                name="playerUserName"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(transactionMonitoring, 'filters.requestedDateFrom')}
                dateFromName="requestedDateFrom"
                dateTo={getStateProp(transactionMonitoring, 'filters.requestedDateTo')}
                dateToName="requestedDateTo"
            />
            <TransactionNumberFilter
                onFilterChange={setFilter}
                value={getStateProp(transactionMonitoring, 'filters.transactionNo')}
                name="transactionNo"
            />
            <TypeFilter
                onFilterChange={setFilter}
                value={getStateProp(transactionMonitoring, 'filters.transactionTypes')}
            />
            <DetailsFilter
                onFilterChange={setFilter}
                value={getStateProp(transactionMonitoring, 'filters.transactionDetails')}
            />
            <OrderStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(transactionMonitoring, 'filters.orderStatuses')}
                type="monitoring"
                name="orderStatuses"
            />
        </Row>
    );
};

export default TransactionMonitoringFilters;
