import React, { useState } from 'react';

import { Table } from 'antd';
import { useVT } from 'virtualizedtableforantd4';
import ResizeObserver from 'rc-resize-observer';

import { getColumns, getPaginationOptions } from './GlobalAnnouncementTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';

import { useStore } from '../../../../store/StateProvider';

const GlobalAnnouncementResultTable = ({ loading }) => {
    const [state, dispatch] = useStore();
    const [langCode, setLangCode] = useState(null);
    const [tableWidth, setTableWidth] = useState(1800);

    const globalAnnouncementHistory = getStateProp(
        state,
        'announcement.announcementHistory.globalAnnouncement',
        {},
    );
    const paging = getStateProp(globalAnnouncementHistory, 'paging', {});
    const tableData = getStateProp(globalAnnouncementHistory, 'list', []);

    // ============================================
    // METHODS
    // ============================================

    const handleChange = (pagination, filters) => {
        filters.title ? setLangCode(filters.title[0]) : setLangCode(null);
    };

    // ============================================
    // HOOKS
    // ============================================

    const [vt] = useVT(() => ({ scroll: { y: 700 } }), []);

    // ============================================
    // RENDER
    // ============================================

    return (
        <ResizeObserver
            onResize={({ width }) => {
                if (width > 2000) setTableWidth(width);
            }}
        >
            <Table
                columns={getColumns(langCode, tableWidth)}
                rowKey={(record) => record.taskNo}
                dataSource={tableData}
                size="small"
                align="left"
                scroll={{ x: tableWidth, y: 700 }}
                bordered
                pagination={getPaginationOptions(paging, dispatch)}
                loading={loading}
                onChange={handleChange}
                component={vt}
            />
        </ResizeObserver>
    );
};

export default GlobalAnnouncementResultTable;
