import { useCallback, useEffect, useState } from 'react';

import { API } from '../common/constants/api';
import {
    affiliateGenerator,
    affiliateGeneratorDropFilters,
    affiliateGeneratorFailure,
    affiliateGeneratorHistoryGet,
    affiliateGeneratorHistoryGetFailure,
    affiliateGeneratorHistoryGetSuccess,
    affiliateGeneratorSuccess,
} from '../components/affiliate/affiliate-generator/affiliateGeneratorActions';
import {
    affiliateRecordsGet,
    affiliateRecordsGetFailure,
    affiliateRecordsGetSuccess,
} from '../components/transaction-history/affiliate-records/affiliateRecordsActions';
import { clearError } from '../common/actions/errorActions';
import {
    closeConfirmModal,
    closeConfirmRejectModal,
    closeVerifyModal,
    myAffiliateOperation,
    myAffiliateOperationFailure,
    myAffiliateOperationSuccess,
    myAffiliatePayoutsGet,
    myAffiliatePayoutsGetFailure,
    myAffiliatePayoutsGetSuccess,
} from '../components/affiliate/affiliate-verification/affiliateVerificationActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, TRANSACTION_HISTORY, AFFILIATE } = API;

export function useGetAffiliateRecordsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { affiliateRecords } = getStateProp(state, 'transactionHistory');
    const { pageNo, pageSize } = getStateProp(affiliateRecords, 'paging');
    const filters = getStateProp(affiliateRecords, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetAffiliateRecords = useCallback(() => {
        affiliateRecordsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${TRANSACTION_HISTORY.AFFILIATE_RECORDS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            affiliateRecordsGetSuccess(dispatch, {
                fetchData: handleGetAffiliateRecords,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetAffiliateRecords, response]);

    useEffect(() => {
        handleGetAffiliateRecords();
    }, [handleGetAffiliateRecords, pageNo, pageSize]);

    const errorMsg = useError(error, affiliateRecordsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetAffiliateRecords];
}

export function useGetMyAffiliatePayoutsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { verification } = getStateProp(state, 'affiliate');
    const { pageNo, pageSize } = getStateProp(verification, 'paging');
    const filters = getStateProp(verification, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetMyAffiliatePayouts = useCallback(() => {
        myAffiliatePayoutsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${TRANSACTION_HISTORY.MYAFFILIATE_PAYOUTS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            myAffiliatePayoutsGetSuccess(dispatch, {
                fetchData: handleGetMyAffiliatePayouts,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetMyAffiliatePayouts, response]);

    const errorMsg = useError(error, myAffiliatePayoutsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetMyAffiliatePayouts];
}

export function useMyAffiliateOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const [isApprove, setIsApprove] = useState(true);
    const { fetchData = () => {} } = getStateProp(state, 'affiliate.verification');
    const { transactionId = '', amount = 0 } = getStateProp(
        state,
        'affiliate.verification.operation.transaction',
        {},
    );

    // ============================================
    // METHODS
    // ============================================
    const handleMyAffiliateOperation = (e) => {
        myAffiliateOperation(dispatch);
        setIsApprove(e);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger && !!transactionId,
        method: 'POST',
        options: {
            data: {
                amount,
            },
        },
        trigger,
        url: `${VERSION.V1}${AFFILIATE.PAYOPUT}/${transactionId}${
            isApprove ? AFFILIATE.APPROVE : AFFILIATE.REJECT
        }`,
    });

    useEffect(() => {
        if (response) {
            if (isApprove) {
                closeConfirmModal(dispatch);
            } else {
                closeConfirmRejectModal(dispatch);
            }
            clearError(dispatch);
            closeVerifyModal(dispatch);
            myAffiliateOperationSuccess(dispatch);
            fetchData();
        }
    }, [dispatch, fetchData, isApprove, response]);

    const errorMsg = useError(error, myAffiliateOperationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleMyAffiliateOperation];
}

export function useGetAffiliateGeneratorHistoryAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { generator } = getStateProp(state, 'affiliate');
    const { pageNo, pageSize } = getStateProp(generator, 'paging');

    // ============================================
    // METHODS
    // ============================================
    const handleGetAffiliateGeneratorHistory = useCallback(() => {
        affiliateGeneratorHistoryGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'POST',
        options: {
            data: {
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${AFFILIATE.HISTORY}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            affiliateGeneratorHistoryGetSuccess(dispatch, {
                fetchData: handleGetAffiliateGeneratorHistory,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetAffiliateGeneratorHistory, response]);

    const errorMsg = useError(error, affiliateGeneratorHistoryGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetAffiliateGeneratorHistory];
}

export function useGenerateAffiliateAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { generator } = getStateProp(state, 'affiliate');
    const filters = getStateProp(generator, 'filters', {});
    const fecthData = getStateProp(generator, 'fetchData', () => {});

    // ============================================
    // METHODS
    // ============================================
    const handleGenerateAffiliate = useCallback(() => {
        affiliateGenerator(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'POST',
        options: {
            data: {
                ...filterTrim(filters),
            },
        },
        trigger,
        url: `${VERSION.V1}${AFFILIATE.GENERATE}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            affiliateGeneratorSuccess(dispatch, response.data.paging.totalRecordCount);
            affiliateGeneratorDropFilters(dispatch);
            fecthData();
        }
    }, [dispatch, fecthData, response]);

    const errorMsg = useError(error, affiliateGeneratorFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGenerateAffiliate];
}
