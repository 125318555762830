import { ACTIONS } from './balanceManagementActions';

export default function balanceManagementReducer(
    balanceManagement = { adjustmentInfo: { turnOverRequirement: 1, userNameLike: null } },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.ADJUSTMENT_INFO_SET:
            return {
                ...balanceManagement,
                adjustmentInfo: {
                    ...balanceManagement.adjustmentInfo,
                    [payload.inputName]: payload.value,
                },
            };
        case ACTIONS.PLAYER_DETAILS_GET_SUCCESS:
            return {
                ...balanceManagement,
                adjustmentInfo: {
                    ...balanceManagement.adjustmentInfo,
                    ...payload,
                },
            };
        case ACTIONS.OPERATION:
            return {
                ...balanceManagement,
                ...payload,
            };
        case ACTIONS.FORM_RESET:
            return { adjustmentInfo: { turnOverRequirement: 1, userNameLike: null } };
        case ACTIONS.OPERATION_SUCCESS:
        case ACTIONS.OPERATION_FAILURE:
            return {
                ...balanceManagement,
                operation: null,
            };
        default:
            return balanceManagement;
    }
}
