import React from 'react';

import { Card } from 'antd';

import { getTranslation } from '../../../common/helpers/locale';
import CryptoMerchantConfigurationForm from './CryptoMerchantConfigurationForm';

const CryptoMerchantConfiguration = () => {
    return (
        <Card title={getTranslation('Crypto Merchant Configuration')}>
            <CryptoMerchantConfigurationForm />
        </Card>
    );
};

export default CryptoMerchantConfiguration;
