import { useCallback, useEffect, useState } from 'react';

import { API } from '../common/constants/api';
import {
    activeDailyLoginGet,
    activeDailyLoginGetFailure,
    activeDailyLoginGetSuccess,
    dailyLoginByIdGet,
    dailyLoginByIdGetFailure,
    dailyLoginByIdGetSuccess,
    dailyLoginEditModalClose,
    dailyLoginListGet,
    dailyLoginListGetFailure,
    dailyLoginListGetSuccess,
    dailyLoginStatusUpdate,
    dailyLoginStatusUpdateFailure,
    dailyLoginStatusUpdateSuccess,
    dailyLoginUpdate,
    dailyLoginUpdateFailure,
    dailyLoginUpdateSuccess,
} from '../components/rewards-management/daily-login-management/dailyLoginManagementActions';
import { clearError } from '../common/actions/errorActions';
import {
    dailyLoginSetup,
    dailyLoginSetupFailure,
    dailyLoginSetupSuccess,
    onDailyLoginSetupReset,
} from '../components/rewards-management/daily-login-setup/dailyLoginSetupActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import { message } from 'antd';
import {
    rewardsRecordsGet,
    rewardsRecordsGetFailure,
    rewardsRecordsGetSuccess,
} from '../components/transaction-history/rewards-records/rewardsRecordsActions';
import { useDispatch, useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, REWARDS_MANAGEMENT } = API;

export function useCreateDailyLoginBonusAsyncEndpoint(dailyLoginInfo) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);
    // ============================================
    // METHODS
    // ============================================

    const handleCreateDailyLoginBonus = () => {
        dailyLoginSetup(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: { ...dailyLoginInfo },
        },
        trigger,
        url: `${VERSION.V1}${REWARDS_MANAGEMENT.DAILY_LOGIN_BONUS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            dailyLoginSetupSuccess(dispatch);
            onDailyLoginSetupReset(dispatch);
            message.success(getTranslation('Daily Login Created!'));
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, dailyLoginSetupFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleCreateDailyLoginBonus, response];
}

export function useGetDailyLoginAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const dailyLoginManagement = getStateProp(state, 'rewardsManagement.dailyLoginManagement');
    const { pageNo, pageSize } = getStateProp(dailyLoginManagement, 'paging');
    const filters = getStateProp(dailyLoginManagement, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetDailyLogin = useCallback(() => {
        dailyLoginListGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${REWARDS_MANAGEMENT.DAILY_LOGIN_BONUS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            dailyLoginListGetSuccess(dispatch, {
                fetchData: handleGetDailyLogin,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetDailyLogin, response]);

    useEffect(() => {
        handleGetDailyLogin();
    }, [handleGetDailyLogin, pageNo, pageSize]);

    const errorMsg = useError(error, dailyLoginListGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetDailyLogin];
}

export function useGetDailyLoginByIdAsyncEndpoint(id) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetDailyLogin = useCallback(() => {
        dailyLoginByIdGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!id,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${REWARDS_MANAGEMENT.DAILY_LOGIN_BONUS}/${id}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            dailyLoginByIdGetSuccess(dispatch, response.data.data);
        }
    }, [dispatch, handleGetDailyLogin, response]);

    useEffect(() => {
        if (id) handleGetDailyLogin();
    }, [handleGetDailyLogin, id]);

    const errorMsg = useError(error, dailyLoginByIdGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetDailyLogin];
}

export function useEditDailyLoginBonusAsyncEndpoint(id, dailyLoginInfo) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const refreshTable = getStateProp(
        state,
        'rewardsManagement.dailyLoginManagement.fetchData',
        () => {},
    );
    // ============================================
    // METHODS
    // ============================================

    const handleUpdateDailyLoginBonus = () => {
        dailyLoginUpdate(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: { ...dailyLoginInfo },
        },
        trigger,
        url: `${VERSION.V1}${REWARDS_MANAGEMENT.DAILY_LOGIN_BONUS}/${id}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            dailyLoginUpdateSuccess(dispatch);
            dailyLoginEditModalClose(dispatch);
            refreshTable();
            message.success(getTranslation('Daily Login Updated!'));
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, dailyLoginUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateDailyLoginBonus];
}

export function useUpdateDailyLoginStatusAsyncEndpoint(id, programStatus) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const refreshTable = getStateProp(
        state,
        'rewardsManagement.dailyLoginManagement.fetchData',
        () => {},
    );

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateDailyLoginBonusStatus = () => {
        dailyLoginStatusUpdate(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PATCH',
        options: {
            data: { id, programStatus },
        },
        trigger,
        url: `${VERSION.V1}${REWARDS_MANAGEMENT.DAILY_LOGIN_BONUS}/status`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            dailyLoginStatusUpdateSuccess(dispatch);
            refreshTable();
            message.success(getTranslation('Daily Login Status Changed!'));
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, dailyLoginStatusUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateDailyLoginBonusStatus, response];
}

export function useGetRewardsRecordsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { rewardsRecords } = getStateProp(state, 'transactionHistory');
    const { pageNo, pageSize } = getStateProp(rewardsRecords, 'paging');
    const filters = getStateProp(rewardsRecords, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetRewardsRecords = useCallback(() => {
        rewardsRecordsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${API.TRANSACTION_HISTORY.REWARDS_RECORDS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            rewardsRecordsGetSuccess(dispatch, {
                fetchData: handleGetRewardsRecords,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetRewardsRecords, response]);

    useEffect(() => {
        handleGetRewardsRecords();
    }, [handleGetRewardsRecords, pageNo, pageSize]);

    const errorMsg = useError(error, rewardsRecordsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetRewardsRecords];
}

export function useGetActiveDailyLoginBonusAsyncEndpoint(code, id) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetActiveDailyLoginBonus = useCallback(() => {
        activeDailyLoginGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${REWARDS_MANAGEMENT.ACTIVE_DAILY_LOGIN}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            activeDailyLoginGetSuccess(dispatch, {
                ...response.data.data,
                confirmModalVisible: true,
                selectedDailyLoginToActive: {
                    code,
                    id,
                },
            });
        }
    }, [code, dispatch, id, loading, response]);

    const errorMsg = useError(error, activeDailyLoginGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetActiveDailyLoginBonus];
}
