import React, { useEffect } from 'react';

import { Card } from 'antd';

import { closeConfirmModal, openConfirmModal, setUploadModalVisible } from './requestBonusActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    useCreateBonusRequestOperationAsyncEndpoint,
    useValidateJoinBonusAsyncEndpoint,
} from '../../../services/requestBonusService';
import { useStore } from '../../../store/StateProvider';
import ConfirmModal from './verification/ConfirmModal';
import RequestBonusForm from './RequestBonusForm';
import UploadModal from './UploadModal';

const RequestBonus = () => {
    const [state, dispatch] = useStore();

    const requestBonus = getStateProp(state, 'bonus.requestBonus', {});
    const modalDetails = getStateProp(requestBonus, 'modalDetails', {});
    const uploadModalVisible = getStateProp(requestBonus, 'uploadModalVisible', false);
    const bonusAmount = getStateProp(state, 'bonus.requestBonus.adjustmentInfo.bonusAmount');

    // ============================================
    // METHODS
    // ============================================

    const onClose = () => closeConfirmModal(dispatch);

    const handleOnFormSubmit = () => {
        parseInt(bonusAmount) === 0
            ? openConfirmModal(dispatch, {
                  message: 'Are you sure to set bonus amount to 0 ?',
                  onConfirm: () => {
                      handleValidateJoin();
                      closeConfirmModal(dispatch);
                  },
              })
            : handleValidateJoin();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [
        creating,
        created,
        handleCreateBonusRequest,
    ] = useCreateBonusRequestOperationAsyncEndpoint();

    const [validating, validated, handleValidateJoin] = useValidateJoinBonusAsyncEndpoint();

    useEffect(() => {
        if (created) {
            closeConfirmModal(dispatch);
        }
    }, [created, dispatch]);

    useEffect(() => {
        if (validated) {
            if (validated.data.data.valid) {
                handleCreateBonusRequest();
            } else {
                openConfirmModal(dispatch, {
                    message: validated.data.data.warningMessage,
                    onConfirm: () => handleCreateBonusRequest(),
                });
            }
        }
    }, [handleCreateBonusRequest, dispatch, validated]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Request Bonus')} className="request-bonus-card">
            <RequestBonusForm
                loading={creating || validating}
                onSubmit={handleOnFormSubmit}
                setUploadModalVisible={() => setUploadModalVisible(dispatch, true)}
            />
            <ConfirmModal
                visible={modalDetails.visible}
                onConfirm={modalDetails.onConfirm}
                onCancel={onClose}
                dialog={modalDetails.warningMessage}
                loading={creating}
            />
            <UploadModal
                visible={uploadModalVisible}
                onCancel={() => setUploadModalVisible(dispatch, false)}
            />
        </Card>
    );
};

export default RequestBonus;
