import { useHistory } from 'react-router-dom';
import React from 'react';

import { Card, Form } from 'antd';

import './NewsList.less';
import { closeDeleteModal, dropFilters } from '../newsActions';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import NewsFilters from './NewsFilters';
import NewsResultsTable from './table/NewsResultsTable';

import { useGetNewsAsyncEndpoint } from '../../../services/newsService';

import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import DeleteNewsModal from './modals/delete-news/DeleteNewsModal';

const NewsList = () => {
    const [state, dispatch] = useStore();
    const deleteModalVisible = getStateProp(state, 'news.deleteModalVisible');
    const newsDelete = getStateProp(state, 'news.newsDelete', {});
    const history = useHistory();

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetNews();
    };

    const exportData = () => {};

    const createNews = () => history.push('/dashboard/news/create/');

    const onCloseDeleteModal = () => closeDeleteModal(dispatch);

    // ============================================
    // HOOKS
    // ============================================

    const [newsLoading, handleGetNews] = useGetNewsAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('News Management')} className="news-list">
            <Form onFinish={handleGetNews}>
                <NewsFilters />
                <ActionButtons
                    onDropFilters={fetchAll}
                    onExport={exportData}
                    onCreateNews={createNews}
                />
            </Form>

            <NewsResultsTable loading={newsLoading} />
            <DeleteNewsModal
                visible={deleteModalVisible}
                onCancel={onCloseDeleteModal}
                news={newsDelete}
            />
        </Card>
    );
};

export default NewsList;
