import { useCallback, useEffect, useState } from 'react';

import { message } from 'antd';
import _ from 'lodash';

import { API } from '../common/constants/api';
import { clearError } from '../common/actions/errorActions';
import { filterTrim, getEndTime, getStartTime, getStateProp } from '../common/helpers/misc';
import {
    getRebateFilterOptionFailure,
    getRebateFilterOptionSuccess,
    getRebateFilterOptions,
} from '../components/rebate/shared/rebateFilterActions';
import { getTranslation } from '../common/helpers/locale';
import {
    rebateCreate,
    rebateCreateFailure,
    rebateCreateSuccess,
    rebateFormReset,
} from '../components/rebate/setting/rebateSettingActions';
import {
    rebateEditModalClose,
    rebateManagementByIdGet,
    rebateManagementByIdGetFailure,
    rebateManagementByIdGetSuccess,
    rebateManagementGet,
    rebateManagementGetFailure,
    rebateManagementGetSuccess,
    rebateManagementStatusUpdate,
    rebateManagementStatusUpdateFailure,
    rebateManagementStatusUpdateSuccess,
    rebateManagementUpdate,
    rebateManagementUpdateFailure,
    rebateManagementUpdateSuccess,
} from '../components/rebate/management/rebateManagementActions';
import {
    rebateRecordsGet,
    rebateRecordsGetFailure,
    rebateRecordsGetSuccess,
} from '../components/transaction-history/rebate-records/rebateRecordsActions';
import {
    rebateTransactionGet,
    rebateTransactionGetFailure,
    rebateTransactionGetSuccess,
    rebateTransactionStatusSet,
    rebateTransactionStatusSetFailure,
    rebateTransactionStatusSetSuccess,
    rebateVerifyModalClose,
} from '../components/rebate/generator/rebateGeneratorActions';
import { useDispatch, useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, REBATE, TRANSACTION_HISTORY } = API;

export function useGetRebateFilterListAsyncEndpoint() {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetRebateFilterList = useCallback(() => {
        getRebateFilterOptions(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${REBATE.FILTER_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getRebateFilterOptionSuccess(dispatch, {
                platforms: response.data.data.platforms,
                products: response.data.data.products,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        handleGetRebateFilterList();
    }, [handleGetRebateFilterList]);

    const errorMsg = useError(error, getRebateFilterOptionFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useCreateRebateAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const rebateSetting = getStateProp(state, 'rebate.rebateSetting', {});
    const location = getStateProp(state, 'timezone.location');

    // ============================================
    // METHODS
    // ============================================

    const handleCreateRebate = () => {
        rebateCreate(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: { ...rebateSetting },
        },
        trigger,
        url: `${VERSION.V1}${REBATE.ROOT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            rebateCreateSuccess(dispatch);
            rebateFormReset(dispatch, {
                endDateTime: getEndTime(location),
                startDateTime: getStartTime(location),
            });
            message.success(getTranslation('Rebate Created'));
        }
    }, [dispatch, location, response]);

    const errorMsg = useError(error, rebateCreateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleCreateRebate, response];
}

export function useGetRebateManagementListAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { rebateManagement } = getStateProp(state, 'rebate');
    const { pageNo, pageSize } = getStateProp(rebateManagement, 'paging');
    const filters = getStateProp(rebateManagement, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetRebateManagement = useCallback(() => {
        rebateManagementGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'GET',
        options: {
            params: { ...filterTrim(filters), pageNo, pageSize },
        },
        trigger,
        url: `${VERSION.V1}${REBATE.ROOT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            rebateManagementGetSuccess(dispatch, {
                fetchData: handleGetRebateManagement,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetRebateManagement, response]);

    useEffect(() => {
        handleGetRebateManagement();
    }, [handleGetRebateManagement, pageNo, pageSize]);

    const errorMsg = useError(error, rebateManagementGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetRebateManagement];
}

export function useGetRebateManagementByIdAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const id = getStateProp(state, 'rebate.rebateManagement.selectedRebate.id');

    // ============================================
    // METHODS
    // ============================================

    const handleGetRebateManagementById = useCallback(() => {
        rebateManagementByIdGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${REBATE.ROOT}/${id}`,
    });

    useEffect(() => {
        if (response) {
            const { recurrences, ...rest } = response.data.data;
            clearError(dispatch);
            rebateManagementByIdGetSuccess(dispatch, {
                recurrences: _.isEmpty(recurrences) ? null : recurrences,
                ...rest,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (id) handleGetRebateManagementById();
    }, [handleGetRebateManagementById, id]);

    const errorMsg = useError(error, rebateManagementByIdGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useUpdateRebateManagementAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { rebateManagement } = getStateProp(state, 'rebate');
    const {
        id,
        details,
        currencies,
        code,
        endDateTime,
        startDateTime,
        turnoverCondition,
        product,
        isShowOnApp,
        recurrences,
        rebatePeriodType,
        payout,
    } = getStateProp(rebateManagement, 'selectedRebate', {});
    const refreshTable = getStateProp(rebateManagement, 'fetchData', {});

    // ============================================
    // HOOKS
    // ============================================

    const handleUpdateRebate = useCallback(() => {
        setTrigger(+new Date());
        rebateManagementUpdate(dispatch);
    }, [dispatch]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'PUT',
        options: {
            data: {
                code,
                currencies,
                details,
                endDateTime,
                id,
                isShowOnApp: !!isShowOnApp,
                payout,
                product,
                rebatePeriodType,
                recurrences,
                startDateTime,
                turnoverCondition,
            },
        },
        trigger,
        url: `${VERSION.V1}${REBATE.ROOT}/${id}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            rebateManagementUpdateSuccess(dispatch);
            rebateEditModalClose(dispatch);
            refreshTable();
            message.success(getTranslation('Updated'));
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, rebateManagementUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateRebate];
}

export function useUpdateRebateStatusOperationAsyncEndpoint(id, status) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const refreshTable = getStateProp(state, 'rebate.rebateManagement.fetchData', {});

    // ============================================
    // HOOKS
    // ============================================

    const handleSetRebateStatus = () => {
        setTrigger(+new Date());
        rebateManagementStatusUpdate(dispatch);
    };

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'PUT',
        options: {
            data: {
                id,
                rebateStatus: status,
            },
        },
        trigger,
        url: `${VERSION.V1}${REBATE.ROOT}/status`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            rebateManagementStatusUpdateSuccess(dispatch);
            refreshTable();
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, rebateManagementStatusUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleSetRebateStatus, response];
}

export function useGetRebateTransactionListAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { rebateGenerator } = getStateProp(state, 'rebate');
    const { pageNo, pageSize } = getStateProp(rebateGenerator, 'paging');
    const filters = getStateProp(rebateGenerator, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetRebateTransaction = useCallback(() => {
        rebateTransactionGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'GET',
        options: {
            params: { ...filterTrim(filters), pageNo, pageSize },
        },
        trigger,
        url: `${VERSION.V1}${REBATE.TRANSACTION}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            rebateTransactionGetSuccess(dispatch, {
                fetchData: handleGetRebateTransaction,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetRebateTransaction, response]);

    useEffect(() => {
        handleGetRebateTransaction();
    }, [handleGetRebateTransaction, pageNo, pageSize]);

    const errorMsg = useError(error, rebateTransactionGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetRebateTransaction];
}

export function useUpdateRebateTransactionStatusOperationAsyncEndpoint({ status, remarks }) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const refreshTable = getStateProp(state, 'rebate.rebateGenerator.fetchData', {});
    const selectedId = getStateProp(state, 'rebate.rebateGenerator.selectedId', []);

    // ============================================
    // HOOKS
    // ============================================

    const handleSetRebateTransactionStatus = () => {
        setTrigger(+new Date());
        rebateTransactionStatusSet(dispatch);
    };

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'PUT',
        options: {
            data: {
                ids: selectedId,
                remarks,
            },
        },
        trigger,
        url: `${VERSION.V1}${REBATE.ROOT}/${status}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            rebateTransactionStatusSetSuccess(dispatch);
            rebateVerifyModalClose(dispatch);
            refreshTable();
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, rebateTransactionStatusSetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleSetRebateTransactionStatus, response];
}

export function useGetRebateRecordsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { rebateRecords } = getStateProp(state, 'transactionHistory');
    const { pageNo, pageSize } = getStateProp(rebateRecords, 'paging', {});
    const filters = getStateProp(rebateRecords, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetRebateRecords = useCallback(() => {
        rebateRecordsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${TRANSACTION_HISTORY.REBATE_RECORDS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            rebateRecordsGetSuccess(dispatch, {
                fetchData: handleGetRebateRecords,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetRebateRecords, response]);

    useEffect(() => {
        handleGetRebateRecords();
    }, [handleGetRebateRecords, pageNo, pageSize]);

    const errorMsg = useError(error, rebateRecordsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetRebateRecords];
}
