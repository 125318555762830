import { ACTIONS } from './registrationDetailsActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function registrationDetailsReducer(
    registrationDetails = {
        playerIpList: {
            paging: {
                pageNo: TABLE_PAGE_NO_DEFAULT,
                pageSize: TABLE_PAGE_SIZE_DEFAULT,
                totalRecordCount: 0,
            },
        },
        playerIpModalVisible: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.REGISTRATION_DETAILS_GET_SUCCESS:
            return {
                ...registrationDetails,
                ...payload,
            };

        case ACTIONS.PLAYER_IP_OPEN_MODAL:
            return {
                ...registrationDetails,
                playerIpModalVisible: true,
            };

        case ACTIONS.PLAYER_IP_SET:
            return {
                ...registrationDetails,
                ipAddr: payload,
            };

        case ACTIONS.PLAYER_IP_CLOSE_MODAL:
            return {
                ...registrationDetails,
                ipAddr: payload,
                playerIpList: {
                    paging: {
                        pageNo: TABLE_PAGE_NO_DEFAULT,
                        pageSize: TABLE_PAGE_SIZE_DEFAULT,
                        totalRecordCount: 0,
                    },
                },
                playerIpModalVisible: false,
            };

        case ACTIONS.PLAYER_IP_GET_SUCCESS:
            return {
                ...registrationDetails,
                playerIpList: payload,
            };
        case ACTIONS.SEARCH_IP_GET_SUCCESS:
            return {
                ...registrationDetails,
                searchIpList: { ...payload.list },
            };
        case ACTIONS.PLAYER_IP_PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...registrationDetails,
                playerIpList: {
                    ...registrationDetails.playerIpList,
                    paging: {
                        ...registrationDetails.playerIpList.paging,
                        pageNo:
                            payload > registrationDetails.playerIpList.paging.pageCount
                                ? registrationDetails.playerIpList.paging.pageCount - 1
                                : payload,
                    },
                },
            };
        case ACTIONS.PLAYER_IP_PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_REMARKS_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...registrationDetails,
                playerIpList: {
                    ...registrationDetails.playerIpList,
                    paging: {
                        ...registrationDetails.playerIpList.paging,
                        pageNo: 1,
                        pageSize:
                            payload < 1
                                ? TABLE_PAGE_SIZE_DEFAULT
                                : payload > MAX_REMARKS_PER_PAGE
                                ? MAX_REMARKS_PER_PAGE
                                : payload,
                    },
                },
            };

        case ACTIONS.REGISTRATION_DETAILS_GET:
        default:
            return registrationDetails;
    }
}
