import React from 'react';

import { Col, Row, Skeleton } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import _ from 'lodash';

import './WalletInformation.less';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useStore } from '../../../../store/StateProvider';

const WalletInformation = ({ userName, walletLoading, handleWalletRefresh }) => {
    const [state] = useStore();
    const wallets = getStateProp(state, 'players.playersList.wallets', []);

    // ============================================
    // METHODS
    // ============================================

    const handleRefresh = () => handleWalletRefresh();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row className="wallet-info__card">
            <Col span={24}>
                <Row align="middle" className="wallet-info__card__row">
                    <span className="wallet-info__card__title">
                        {getTranslation('Wallet').toLocaleUpperCase()}
                    </span>
                    <SyncOutlined className="wallet-info__card__refresh" onClick={handleRefresh} />
                </Row>
                {walletLoading ? (
                    <Row style={{ padding: 24 }}>
                        <Skeleton active paragraph={{ rows: 1 }} />
                    </Row>
                ) : (
                    _.map(wallets, (wallet) => (
                        <Row align="middle" className="wallet-info__card__row" key={wallet.wallet}>
                            <Col span={4}>
                                <Row>
                                    <span className="wallet-info__card__wallet-name">
                                        {wallet.wallet}
                                    </span>
                                </Row>
                                <Row>
                                    <span className="wallet-info__card__wallet-value">
                                        {wallet.balance}
                                    </span>
                                </Row>
                            </Col>
                        </Row>
                    ))
                )}
            </Col>
        </Row>
    );
};

export default WalletInformation;
