import React from 'react';

import { Row } from 'antd';

import { FormOutlined } from '@ant-design/icons';

import './ColumnActionButtons.less';

const RowActionButtons = ({ record, openEditModal }) => {
    const { id } = record;
    // ============================================
    // RENDER
    // ============================================

    return (
        <Row gutter={15} align="middle">
            <FormOutlined
                style={{ fontSize: '20px' }}
                className="edit-icon"
                onClick={() => openEditModal(id)}
            />
        </Row>
    );
};

export default RowActionButtons;
