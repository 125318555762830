import { useEffect } from 'react';

import { API } from '../common/constants/api';
import { DEBOUNCE_DELAY_SEC } from '../common/constants/misc';
import {
    bankCardDetailsGetFailure,
    bankCardDetailsGetSuccess,
    depositCorrectionOperationFailure,
    offlineDepositDetailsGetFailure,
    offlineDepositDetailsGetSuccess,
    performDepositCorrectionOperationSuccess,
    playerDetailsGetFailure,
    playerDetailsGetSuccess,
} from '../components/balance/deposit-correction/depositCorrectionActions';
import { clearError } from '../common/actions/errorActions';
import { getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import { message } from 'antd';
import { useDebounce } from './hooks/useDebounce';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, PLAYERS, PAYMENTS } = API;

export function useGetPlayerDetailsAsyncEndpoint() {
    const [state, dispatch] = useStore();

    const userNameLike = useDebounce(
        getStateProp(state, 'balance.depositCorrection.depositCorrectionInfo.userNameLike'),
        DEBOUNCE_DELAY_SEC,
    );

    const transactionNo = getStateProp(
        state,
        'balance.depositCorrection.depositCorrectionInfo.transactionNo',
    );

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!userNameLike && !transactionNo,
        method: 'GET',
        trigger: userNameLike,
        url: `${VERSION.V1}${PLAYERS.GET_BY_USERNAME}/${userNameLike}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            playerDetailsGetSuccess(dispatch, {
                currencyCode: getStateProp(response.data, 'data.monetary.currencyCode'),
                memberLevel: getStateProp(response.data, 'data.profile.memberLevel'),
                playerId: getStateProp(response.data, 'data.id'),
                realName: getStateProp(response.data, 'data.profile.realName'),
                trustLevel: getStateProp(response.data, 'data.profile.trustLevel'),
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (response) {
            if (response.data.data === null) message.warning(getTranslation('User Not Found'));
        }
    }, [response]);

    const errorMsg = useError(error, playerDetailsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useGetOfflineDepositsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const transactionNo = useDebounce(
        getStateProp(state, 'balance.depositCorrection.depositCorrectionInfo.transactionNo'),
        DEBOUNCE_DELAY_SEC,
    );

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!transactionNo,
        method: 'GET',
        trigger: transactionNo,
        url: `${VERSION.V1}${PAYMENTS.OFFLINE_DEPOSITS.ROOT}/${transactionNo}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            offlineDepositDetailsGetSuccess(dispatch, {
                amount: getStateProp(response.data, 'data[0].data[0].amount'),
                bankCardNo: getStateProp(response.data, 'data[0].data[0].bankCardNo'),
                bankName: getStateProp(response.data, 'data[0].data[0].bankName'),
                cardHolderName: getStateProp(response.data, 'data[0].data[0].cardHolderName'),
                cardId: getStateProp(response.data, 'data[0].data[0].cardId'),
                currencyCode: getStateProp(response.data, 'data[0].data[0].currencyCode'),
                memberLevel: getStateProp(response.data, 'data[0].data[0].memberLevel'),
                playerId: getStateProp(response.data, 'data[0].data[0].playerId'),
                realName: getStateProp(response.data, 'data[0].data[0].realName'),
                transactionMethod: getStateProp(response.data, 'data[0].data[0].transactionMethod'),
                trustLevel: getStateProp(response.data, 'data[0].data[0].trustLevel'),
                userNameLike: getStateProp(response.data, 'data[0].data[0].playerUserName'),
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (response) {
            if (response.data.data[0].data[0] === undefined)
                message.warning(getTranslation('Transaction Not Found'));
        }
    }, [response]);

    const errorMsg = useError(error, offlineDepositDetailsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useGetBankCardDetailsAsyncEndpoint() {
    const [state, dispatch] = useStore();

    const bankCardNo = useDebounce(
        getStateProp(state, 'balance.depositCorrection.depositCorrectionInfo.bankCardNo'),
        DEBOUNCE_DELAY_SEC,
    );

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!bankCardNo,
        method: 'GET',
        options: {
            params: {
                activatedStatus: 'Activated',
                bankCardNo,
            },
        },
        trigger: bankCardNo,
        url: `${VERSION.V1}${PAYMENTS.BANK_ACCOUNT.CARD_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bankCardDetailsGetSuccess(dispatch, {
                activateStatus: getStateProp(response.data, 'data[0].activatedStatus'),
                bankName: getStateProp(response.data, 'data[0].bankName'),
                cardHolderName: getStateProp(response.data, 'data[0].cardHolderName'),
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (response) {
            if (response.data.data.length === 0) message.warning(getTranslation('Bank Not Found'));
        }
    }, [response]);

    const errorMsg = useError(error, bankCardDetailsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useDepositCorrectionOperation() {
    const [state, dispatch] = useStore();
    const action = getStateProp(state, 'balance.depositCorrection.operation');
    const depositCorrectionInfo = getStateProp(
        state,
        'balance.depositCorrection.depositCorrectionInfo',
    );

    const playerId = getStateProp(depositCorrectionInfo, 'playerId');
    const realName = getStateProp(depositCorrectionInfo, 'realName');
    const transactionType = getStateProp(depositCorrectionInfo, 'transactionType');
    const amount = getStateProp(depositCorrectionInfo, 'amount');
    const turnoverCondition = getStateProp(depositCorrectionInfo, 'turnoverRequirement');
    const bankName = getStateProp(depositCorrectionInfo, 'bankName');
    const bankCardNo = getStateProp(depositCorrectionInfo, 'bankCardNo');
    const cardHolderName = getStateProp(depositCorrectionInfo, 'cardHolderName');
    const transactionMethod = getStateProp(depositCorrectionInfo, 'transactionMethod');
    const remarks = getStateProp(depositCorrectionInfo, 'remarks');

    // ============================================
    // METHODS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!action,
        method: 'POST',
        options: {
            data: {
                amount,
                bankCardNo,
                bankName,
                cardHolderName,
                playerId,
                realName,
                remarks,
                transactionMethod,
                transactionType,
                turnoverCondition,
            },
        },
        trigger: action,
        url: `${VERSION.V1}${PAYMENTS.DEPOSIT_CORRECTION}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            performDepositCorrectionOperationSuccess(dispatch);
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, depositCorrectionOperationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response];
}
