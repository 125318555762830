import React from 'react';

import { Input, Tabs } from 'antd';
import _ from 'lodash';

import '../styles/AnnouncementViewTabs.less';
import { getLanguageByCode } from '../../../../common/helpers/locale';
import Editor from '../../../../common/components/Editor';

const { TabPane } = Tabs;

const AnnouncementViewFormTabs = ({ onContentChanged, currency, content, ...rest }) => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <Tabs className="announcement__currency-tabs" tabPosition="left">
            {_.map(currency, (currency) => (
                <TabPane key={currency} tab={currency} className="announcement-content__tabs">
                    <Tabs className="announcement__content-tabs">
                        {_.map(
                            _.filter(content, (content) => {
                                return content.currency === currency;
                            }),
                            (item) => (
                                <Tabs.TabPane
                                    key={item.languageCode}
                                    tab={getLanguageByCode(item.languageCode)}
                                    {...rest}
                                    className="announcement-content__tabs"
                                >
                                    <Input
                                        placeholder="Title"
                                        disabled
                                        value={item.title}
                                        className="announcement-content__title"
                                    />

                                    <Editor disabled initialValue={item.content} />
                                </Tabs.TabPane>
                            ),
                        )}
                    </Tabs>
                </TabPane>
            ))}
        </Tabs>
    );
};

export default AnnouncementViewFormTabs;
