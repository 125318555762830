import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { NEWS_CATEGORIES } from '../../constants';
import { getTranslation } from '../../../../common/helpers/locale';

const NewsCategoryFilter = ({ value, onFilterChange }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (selectedCategory) =>
        onFilterChange(
            'newsCategory',
            _.find(NEWS_CATEGORIES, (category) => category.label === selectedCategory.label),
        );

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            labelInValue
            value={value}
            onChange={handleFilterChange}
            placeholder={getTranslation('Related Games')}
            className="filter"
        >
            {_.map(NEWS_CATEGORIES, (category) => (
                <Select.Option key={category.key} value={category.label}>
                    {category.label}
                </Select.Option>
            ))}
        </Select>
    );
};

export default NewsCategoryFilter;
