import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { YES_NO } from '../constants';
import { getTranslation } from '../../../../common/helpers/locale';

function VipPayoutFilter({ value, onFilterChange, name }) {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange(name, value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear
            placeholder={getTranslation('VIP Payout')}
            className="filter"
        >
            {_.map(YES_NO, (e) => (
                <Select.Option value={e.value} key={e.key}>
                    {e.key.toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
}

export default VipPayoutFilter;
