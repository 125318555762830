export const ACTIONS = {
    CRYPTO_CURRENCY_CORRECTION_FORM_RESET: 'CRYPTO_CURRENCY_CORRECTION_FORM_RESET',
    CRYPTO_CURRENCY_CORRECTION_INFO_SET: 'CRYPTO_CURRENCY_CORRECTION_INFO_SET',

    CRYPTO_CURRENCY_CORRECTION_NEW_RATE_GET: 'CRYPTO_CURRENCY_CORRECTION_NEW_RATE_GET',
    CRYPTO_CURRENCY_CORRECTION_NEW_RATE_GET_FAILURE:
        'CRYPTO_CURRENCY_CORRECTION_NEW_RATE_GET_FAILURE',
    CRYPTO_CURRENCY_CORRECTION_NEW_RATE_GET_SUCCESS:
        'CRYPTO_CURRENCY_CORRECTION_NEW_RATE_GET_SUCCESS',

    CRYPTO_CURRENCY_CORRECTION_OPERATION: 'CRYPTO_CURRENCY_CORRECTION_OPERATION',
    CRYPTO_CURRENCY_CORRECTION_OPERATION_FAILURE: 'CRYPTO_CURRENCY_CORRECTION_OPERATION_FAILURE',
    CRYPTO_CURRENCY_CORRECTION_OPERATION_SUCCESS: 'CRYPTO_CURRENCY_CORRECTION_OPERATION_SUCCESS',

    CRYPTO_CURRENCY_CORRECTION_TRANSACTION_GET: 'CRYPTO_CURRENCY_CORRECTION_TRANSACTION_GET',
    CRYPTO_CURRENCY_CORRECTION_TRANSACTION_GET_FAILURE:
        'CRYPTO_CURRENCY_CORRECTION_TRANSACTION_GET_FAILURE',
    CRYPTO_CURRENCY_CORRECTION_TRANSACTION_GET_SUCCESS:
        'CRYPTO_CURRENCY_CORRECTION_TRANSACTION_GET_SUCCESS',

    CRYPTO_CURRENCY_CORRECTION_USERNAME_GET: 'CRYPTO_CURRENCY_CORRECTION_USERNAME_GET',
    CRYPTO_CURRENCY_CORRECTION_USERNAME_GET_FAILURE:
        'CRYPTO_CURRENCY_CORRECTION_USERNAME_GET_FAILURE',
    CRYPTO_CURRENCY_CORRECTION_USERNAME_GET_SUCCESS:
        'CRYPTO_CURRENCY_CORRECTION_USERNAME_GET_SUCCESS',
};

export const cryptoCurrencyCorrectionInfoSet = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_CURRENCY_CORRECTION_INFO_SET,
    });
};

export const cryptoCurrencyCorrectionFormReset = (dispatch) => {
    dispatch({ type: ACTIONS.CRYPTO_CURRENCY_CORRECTION_FORM_RESET });
};

export const cryptoCurrencyUsernameGetSuccess = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_CURRENCY_CORRECTION_USERNAME_GET_SUCCESS,
    });
};

export const cryptoCurrencyUsernameGetFailure = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_CURRENCY_CORRECTION_USERNAME_GET_FAILURE,
    });
};

export const cryptoCurrencyTransactionGetSuccess = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_CURRENCY_CORRECTION_TRANSACTION_GET_SUCCESS,
    });
};

export const cryptoCurrencyTransactionGetFailure = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_CURRENCY_CORRECTION_TRANSACTION_GET_FAILURE,
    });
};

export const performCryptoCurrencyCorrectionOperation = (dispatch) => {
    dispatch({
        type: ACTIONS.CRYPTO_CURRENCY_CORRECTION_OPERATION,
    });
};

export const performCryptoCurrencyCorrectionOperationSuccess = (dispatch) => {
    dispatch({
        type: ACTIONS.CRYPTO_CURRENCY_CORRECTION_OPERATION_SUCCESS,
    });
};

export const performCryptoCurrencyCorrectionOperationFailure = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_CURRENCY_CORRECTION_OPERATION_FAILURE,
    });
};

export const cryptoCurrencyCorrectionNewRateGet = (dispatch) => {
    dispatch({
        type: ACTIONS.CRYPTO_CURRENCY_CORRECTION_NEW_RATE_GET,
    });
};

export const cryptoCurrencyCorrectionNewRateGetSuccess = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_CURRENCY_CORRECTION_NEW_RATE_GET_SUCCESS,
    });
};

export const cryptoCurrencyCorrectionNewRateGetFailure = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_CURRENCY_CORRECTION_NEW_RATE_GET_FAILURE,
    });
};
