import React from 'react';

import { Row } from 'antd';

import {
    ApplicantFilter,
    BonusNameFilter,
    CurrencyFilter,
    OperatorFilter,
    PeriodFilter,
    PromoCodeFilter,
    TransactionNumberFilter,
    UsernameFilter,
} from '../../../common/components/filters';
import { VerifyStatusFilter } from '../../bonus/filters';
import { bonusRecordsFilterSet } from './bonusRecordsActions';
import { getStateProp } from '../../../common/helpers/misc';
import { useStore } from '../../../store/StateProvider';

const BonusRecordFilters = () => {
    const [state, dispatch] = useStore();

    const bonusRecords = getStateProp(state, 'transactionHistory.bonusRecords');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => bonusRecordsFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(bonusRecords, 'filters.userName')}
                name="userName"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(bonusRecords, 'filters.dateFrom')}
                dateFromName="dateFrom"
                dateTo={getStateProp(bonusRecords, 'filters.dateTo')}
                dateToName="dateTo"
            />
            <TransactionNumberFilter
                onFilterChange={setFilter}
                value={getStateProp(bonusRecords, 'filters.transNo')}
                name="transNo"
            />
            <BonusNameFilter
                onFilterChange={setFilter}
                value={getStateProp(bonusRecords, 'filters.bonusName')}
            />
            <VerifyStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(bonusRecords, 'filters.status')}
            />
            <ApplicantFilter
                onFilterChange={setFilter}
                value={getStateProp(bonusRecords, 'filters.applicantName')}
                name="applicantName"
            />
            <OperatorFilter
                onFilterChange={setFilter}
                value={getStateProp(bonusRecords, 'filters.operatorName')}
                name="operatorName"
            />
            <PromoCodeFilter
                onFilterChange={setFilter}
                value={getStateProp(bonusRecords, 'filters.promoCode')}
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(bonusRecords, 'filters.currency')}
                name="currency"
            />
        </Row>
    );
};

export default BonusRecordFilters;
