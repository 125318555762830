import React, { useState } from 'react';

import { Layout as AntLayout, Row } from 'antd';

import './Layout.less';
import NavBar from './NavBar';
import SideBar from './SideBar';

const { Header, Content } = AntLayout;

const Layout = ({ children }) => {
    const [sideBarVisible, setSideBarVisible] = useState(window.innerWidth >= 576);

    // ============================================
    // HOOKS
    // ============================================

    // ============================================
    // METHODS
    // ============================================

    const toggleDrawer = () => setSideBarVisible(!sideBarVisible);

    // ============================================
    // RENDER
    // ============================================

    return (
        <AntLayout>
            <Header className="layout__header">
                <NavBar onToggleDrawer={toggleDrawer} />
            </Header>
            <Row>
                {sideBarVisible && <SideBar />}
                <Content className="layout__content">{children}</Content>
            </Row>
        </AntLayout>
    );
};

export default Layout;
