import React, { useEffect, useState } from 'react';

import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import _ from 'lodash';

import './BankCardSetupForm.less';
import { BANK_CARD_STATUSES, PAYMENT_ACCEPTANCE_METHODS } from '../constants';
import { PAYMENT_ACCOUNT_SETUP_TYPES } from '../../../../common/constants/misc';
import { bankClear } from '../bankActions';
import { getCountryIdByCurrency, getTranslation } from '../../../../common/helpers/locale';
import { getStateProp } from '../../../../common/helpers/misc';
import { getUserCurrency } from '../../../../common/helpers/api';
import { lengthValidator } from '../../../../common/helpers/validators';
import { useGetBankAsyncEndpoint } from '../../../../services/bankAccountService';
import { useStore } from '../../../../store/StateProvider';

const BankCardSetupForm = ({ selectedAccount, form }) => {
    const [state, dispatch] = useStore();
    const [currencyCode, setCurrencyCode] = useState(null);

    const bankList = getStateProp(state, 'payments.bank.list');
    const countryId = getCountryIdByCurrency(currencyCode);
    const operatorCurrency = getUserCurrency();

    const { setFieldsValue } = form;

    // ============================================
    // METHODS
    // ============================================

    const setCurrency = (value) => setCurrencyCode(value);

    const updateBankName = (bankId) => {
        setFieldsValue({
            bankName: selectedAccount.bankName,
        });
    };

    // ============================================
    // HOOKS
    // ============================================

    const [gettingBank] = useGetBankAsyncEndpoint(countryId);

    useEffect(() => {
        if (!currencyCode || countryId === '') {
            bankClear(dispatch);
        }
    }, [countryId, currencyCode, dispatch]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row>
            <Col span={12} className="bank-card__form-column">
                <Row className="form__group">
                    <Col span={10}>
                        <label> {getTranslation('Currency')} </label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="currencyCode"
                            rules={[
                                {
                                    message: getTranslation('Please select currency!'),
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                allowClear={true}
                                onChange={setCurrency}
                                disabled={!_.isEmpty(selectedAccount)}
                            >
                                {_.map(operatorCurrency, (currency) => (
                                    <Select.Option value={currency} key={currency}>
                                        {currency.toLocaleUpperCase()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {!_.isEmpty(selectedAccount) ? (
                    <Row className="form__group">
                        <Col span={10}>
                            <label>{getTranslation('Bank')}</label>
                            <span className="form__required">*</span>
                        </Col>
                        <Col span={14}>
                            <Form.Item name="bankName">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                ) : (
                    <Row className="form__group">
                        <Col span={10}>
                            <label>{getTranslation('Bank')}</label>
                            <span className="form__required">*</span>
                        </Col>
                        <Col span={14}>
                            <Form.Item
                                name="bankId"
                                rules={[
                                    {
                                        message: getTranslation('Please input bank name!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    disabled={!bankList}
                                    loading={gettingBank}
                                    onChange={updateBankName}
                                >
                                    {_.map(bankList, (bank) => (
                                        <Select.Option value={bank.bankId} key={bank.bankId}>
                                            {bank.bankName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Account Name')}</label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="cardHolderName"
                            rules={[
                                {
                                    message: getTranslation('Please input card holder name!'),
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Bank Account Number')}</label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="bankCardNo"
                            validateFirst
                            rules={[
                                {
                                    message: getTranslation('Please input bank card number!'),
                                    required: true,
                                },
                                ({ getFieldValue }) => ({
                                    validator: lengthValidator(
                                        getFieldValue('bankCardNo'),
                                        6,
                                        19,
                                        getTranslation('Bank card number must be 6-19 digits!'),
                                    ),
                                }),
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Passage')}</label>
                    </Col>
                    <Col span={14}>
                        <Form.Item name="passage">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Province')}</label>
                    </Col>
                    <Col span={14}>
                        <Form.Item name="province">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('City')}</label>
                    </Col>
                    <Col span={14}>
                        <Form.Item name="city">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Branch (Open Account)')}</label>
                    </Col>
                    <Col span={14}>
                        <Form.Item name="branchName">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Balance Limit Notification')}</label>
                    </Col>
                    <Col span={14}>
                        <Form.Item name="balanceLimitNotification">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
            <Col span={12} className="bank-card__form-column">
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Opening Date')}</label>
                    </Col>
                    <Col span={14}>
                        <Form.Item name="openAt">
                            <DatePicker
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                disabled={!_.isEmpty(selectedAccount)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Login ID')}</label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="loginId"
                            rules={[
                                {
                                    message: getTranslation('Please input login ID!'),
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('U-Key Number')}</label>
                    </Col>
                    <Col span={14}>
                        <Form.Item name="uKey">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label> {getTranslation('Type')} </label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="type"
                            rules={[
                                {
                                    message: getTranslation('Please select type!'),
                                    required: true,
                                },
                            ]}
                        >
                            <Select allowClear={true}>
                                {_.map(PAYMENT_ACCOUNT_SETUP_TYPES, (type) => (
                                    <Select.Option value={type} key={type}>
                                        {getTranslation(type).toLocaleUpperCase()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label> {getTranslation('Status')} </label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="activatedStatus"
                            rules={[
                                {
                                    message: getTranslation('Please select status!'),
                                    required: true,
                                },
                            ]}
                        >
                            <Select allowClear={true}>
                                {_.map(BANK_CARD_STATUSES, (status) => (
                                    <Select.Option value={status} key={status}>
                                        {getTranslation(status).toLocaleUpperCase()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label> {getTranslation('Payment Method Acceptance')} </label>
                    </Col>
                    <Col span={14}>
                        <Form.Item name="paymentMethodAcceptance">
                            <Select allowClear={true}>
                                {_.map(PAYMENT_ACCEPTANCE_METHODS, (method) => (
                                    <Select.Option value={method} key={method}>
                                        {getTranslation(method).toLocaleUpperCase()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label> {getTranslation('Remark')} </label>
                    </Col>
                    <Col span={14}>
                        <Form.Item name="remarks">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default BankCardSetupForm;
