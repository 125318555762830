import React from 'react';

import { Input } from 'antd';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';
import ColumnActionButtons from './ColumnActionButtons';

export const getColumns = (updateSelectedDangerLevelTag, handleDeleteDangerLevelTag) => {
    const renderEditableInput = (key, value, record) => {
        return record.editable ? (
            <Input
                onChange={(e) =>
                    updateSelectedDangerLevelTag(
                        key,
                        e.target.value,
                        record.level,
                        record.lastUpdatedBy,
                    )
                }
                value={value}
            />
        ) : (
            <span>{value}</span>
        );
    };

    const columns = [
        {
            dataIndex: 'level',
            title: getTranslation('Danger Level'),
        },
        {
            dataIndex: 'tagName',
            render: (value, record) => renderEditableInput('tagName', value, record),
            title: getTranslation('Tag Name'),
        },
        {
            dataIndex: 'description',
            render: (value, record) => renderEditableInput('description', value, record),
            title: getTranslation('Description'),
        },
        {
            dataIndex: 'lastUpdatedBy',
            title: getTranslation('Last Edited By'),
        },
        {
            render: (value, record) => (
                <ColumnActionButtons
                    record={record}
                    updateSelectedDangerLevelTag={updateSelectedDangerLevelTag}
                    handleDeleteDangerLevelTag={handleDeleteDangerLevelTag}
                />
            ),
            title: getTranslation('Action'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};
