import React from 'react';

import { Card } from 'antd';

import { BONUS_CATEGORY_MODAL_TYPES } from '../constants';
import { closeBonusCategoryModal, openBonusCategoryModal } from './bonusCategoryActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useGetBonusCategoryAsyncEndpoint } from '../../../services/bonusCategoryService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import BonusCategoryModal from './modal/BonusCategoryModal';
import BonusCategoryResultTable from './table/BonusCategoryResultTable';

const BonusCategory = () => {
    const [state, dispatch] = useStore();

    const bonusCategory = getStateProp(state, 'bonus.bonusCategory');
    const { bonusCategoryModal } = bonusCategory;
    // ============================================
    // METHODS
    // ============================================

    const onCreateCategory = () =>
        openBonusCategoryModal(dispatch, { modalType: BONUS_CATEGORY_MODAL_TYPES.CREATE_CATEGORY });

    const onSortCategory = () =>
        openBonusCategoryModal(dispatch, {
            modalType: BONUS_CATEGORY_MODAL_TYPES.CATEGORY_SORTING,
        });

    const closeModal = () => closeBonusCategoryModal(dispatch);

    // ============================================
    // HOOKS
    // ============================================

    const [loading] = useGetBonusCategoryAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================
    return (
        <Card title={getTranslation('Bonus Category')}>
            <ActionButtons onCreateCategory={onCreateCategory} onSortCategory={onSortCategory} />
            <BonusCategoryResultTable loading={loading} />

            {bonusCategoryModal.isVisible && (
                <BonusCategoryModal bonusCategoryModal={bonusCategoryModal} onCancel={closeModal} />
            )}
        </Card>
    );
};

export default BonusCategory;
