import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './DepositRecordTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const DepositRecordResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();
    const { depositRecords } = getStateProp(state, 'transactionHistory');
    const tableData = getStateProp(depositRecords, 'list', []);
    const paging = getStateProp(depositRecords, 'paging', {});

    return (
        <Table
            id="deposit-record-table"
            columns={getColumns()}
            size="small"
            scroll={{ x: true }}
            align="left"
            dataSource={tableData}
            rowKey={(record) => record.transactionNo}
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
            bordered
        />
    );
};

export default DepositRecordResultsTable;
