import React, { useEffect } from 'react';

import { Card, Form, message } from 'antd';
import _ from 'lodash';

import { TIMEZONE } from '../../../common/constants/localization';
import { dropFilters } from './turnoverCheckActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { switchTimezone } from '../../../common/actions/timezoneActions';
import { useExportHTMLTableAsyncEndpoint } from '../../../services/hooks/useExport';
import {
    useGetBetListFilterOptionsAsyncEndpoint,
    useGetTurnoverCheckAsyncEndpoint,
} from '../../../services/betService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import TimezoneReminder from '../../../common/components/TimezoneReminder';
import TurnoverCheckFilter from './TurnoverCheckFilter';
import TurnoverCheckResultsTable from './table/TurnoverCheckResultsTable';
import TurnoverCheckSummary from './TurnoverCheckSummary';

const TurnoverCheck = () => {
    const [state, dispatch] = useStore();

    const turnoverCheck = getStateProp(state, 'bet.turnoverCheck');
    const filters = getStateProp(turnoverCheck, 'filters', {});
    const tableData = getStateProp(turnoverCheck, 'list', []);

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
    };

    const searchTurnoverCheck = () => {
        if (!filters.userName) {
            message.warning('Please input username');
        }
        handleGetTurnoverCheck();
    };

    const exportTurnoverCheck = () => {
        !_.isEmpty(tableData)
            ? handleExportAction(filters.userName)
            : message.warning(getTranslation('No records found'));
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetTurnoverCheck] = useGetTurnoverCheckAsyncEndpoint();
    const [filterLoading] = useGetBetListFilterOptionsAsyncEndpoint();

    useEffect(() => {
        switchTimezone(dispatch, {
            location: TIMEZONE.CANADA.LOCATION,
            offset: TIMEZONE.CANADA.OFFSET,
        });

        return () => {
            switchTimezone(dispatch, {
                location: TIMEZONE.BANGKOK.LOCATION,
                offset: TIMEZONE.BANGKOK.OFFSET,
            });
        };
    }, [dispatch]);

    const [handleExportAction] = useExportHTMLTableAsyncEndpoint({
        additionalRow: [
            [
                getTranslation('Total Effective Turnover'),
                getStateProp(turnoverCheck, 'totalEffectiveTurnover', 0),
            ],
            [
                getTranslation('This month Effective Turnover'),
                getStateProp(turnoverCheck, 'thisMonthEffectiveTurnover', 0),
            ],
        ],
        htmlTableId: 'turnover-check-table-id',
        subject: `${getTranslation('Turnover Check')} - ${filters.userName}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Turnover Check')} extra={<TimezoneReminder />}>
            <Form onFinish={searchTurnoverCheck}>
                <TurnoverCheckFilter loading={filterLoading} />
                <ActionButtons onDropFilters={fetchAll} onExport={exportTurnoverCheck} />
            </Form>

            <TurnoverCheckResultsTable loading={loading} />
            <TurnoverCheckSummary />
        </Card>
    );
};

export default TurnoverCheck;
