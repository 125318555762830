export const VERIFY_ACTION = {
    COMPLETED: 'completed',
    EXPIRED: 'expired',
    REJECT: 'reject',
    SUBMIT: 'submit',
};

export const VERIFY_STATUSES = {
    APPROVED: 'Approved',
    PENDING: 'Pending',
    REJECT: 'Rejected',
    SUCCESS: 'Success',
};

export const REQUEST_SEARCH_PLAYER_INFO_ACTIONS = {
    BALANCE_ADDITION: 'Balance Addition',
    CRYPTOCURRENCY_CORRECTION: 'Cryptocurrency Correction',
    CRYPTO_DEPOSIT: 'Crypto Deposit',
    DEPOSIT_CORRECTION: 'Deposit Correction',
    MERCHANT_DEPOSIT: 'Merchant Deposit',
    OFFLINE_DEPOSIT: 'Offline Deposit',
};

export const PROGRESS_STATUS = {
    COMPLETED: 'Completed',
    EXPIRED: 'Expired',
    RUNNING: 'Running',
};

export const MANUAL_BONUS_ASPECTS = {
    _1_NORMAL: 'normal',
    _2_BATCH: 'batch',
};
