import React from 'react';

import Moment from 'react-moment';

import { PRODUCTS } from '../../constants';
import { TIMEZONE } from '../../../../common/constants/localization';
import { getTranslation } from '../../../../common/helpers/locale';

const FullDetails = ({ detail, marketStatus, betTeam, orderType, product, ...props }) => {
    const getTeams = detail.matchName
        ? detail.matchName
        : detail.homeName && detail.awayName
        ? `${detail.homeName} VS ${detail.awayName}`
        : null;
    const getPoint = detail.point !== 0 && detail.point !== null ? `(${detail.point})` : '';
    const getMarketStatus = marketStatus ? `(${marketStatus.toLocaleUpperCase()})` : '';
    const getOrderType = detail.orderType ? `(${detail.orderType.toLocaleUpperCase()})` : '';
    const getOdds = detail.odds ? `Odds - ${detail.odds}` : '';
    const getBetTeam = betTeam !== null ? betTeam : detail.betTeam !== null ? detail.betTeam : '';
    const isRNGProduct = product === PRODUCTS.RNG || product === PRODUCTS.SMART;

    // ============================================
    // RENDER
    // ============================================

    return (
        <div style={{ width: '200px' }} {...props}>
            <h3>{`${getBetTeam} ${getPoint} ${getMarketStatus} ${getOrderType}`}</h3>
            <p>{detail.betType}</p>
            <p>{detail.gameName || detail.leagueName}</p>
            {!isRNGProduct && (
                <>
                    <p>{getTeams}</p>
                    <p>
                        {`${getTranslation('Kick Off Time')}${' - '}`}
                        <Moment utc tz={TIMEZONE.BANGKOK.LOCATION} format="DD/MM/YYYY HH:mm:ss">
                            {detail.globalShowTime}
                        </Moment>
                    </p>
                </>
            )}
            <p>{detail.sportsType}</p>
            <p>{getOdds}</p>
        </div>
    );
};

export default FullDetails;
