import { Switch } from 'react-router-dom';
import React from 'react';

import { BetList, TurnoverCheck } from '.';
import { PrivateRoute } from '../../common/components';
import { ROUTES } from '../../common/constants/routing';

const Bet = () => {
    return (
        <Switch>
            <PrivateRoute path={ROUTES.DASHBOARD.BET.BET_LIST} component={BetList} />
            <PrivateRoute path={ROUTES.DASHBOARD.BET.TURNOVER_CHECK} component={TurnoverCheck} />
        </Switch>
    );
};

export default Bet;
