import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Input, Row, Select } from 'antd';
import _ from 'lodash';

import './AddEWalletForm.less';
import { ButtonGreen } from '../../../../../../common/components/buttons/Buttons';
import { EWALLET } from '../../../constants';
import { equalityValidator } from '../../../../../../common/helpers/validators';
import { getStateProp } from '../../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../../common/helpers/locale';
import { useAddEWalletAsyncEndpoint } from '../../../../../../services/playerService';
import { useStore } from '../../../../../../store/StateProvider';
import ImgIcon from '../../../../../../common/components/ImgIcon';

const AddEWalletForm = ({ onCancel, getPlayerInfo }) => {
    const [form] = Form.useForm();
    const { resetFields, setFieldsValue } = form;
    const [state] = useStore();
    const userName = getStateProp(state, 'players.playerDetails.playerInfo.userName', '');
    const realName = getStateProp(state, 'players.playerDetails.playerInfo.profile.realName', '');
    const [formValues, setFormValues] = useState({});

    // ============================================
    // METHODS
    // ============================================

    const submitForm = (values) => {
        setFormValues(values);
        handleAddEWallet();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [eWalletAdding, handleAddEWallet, eWalletAdded] = useAddEWalletAsyncEndpoint(formValues);

    useEffect(() => {
        if (eWalletAdded) {
            resetFields();
            onCancel();
            getPlayerInfo();
        }
    }, [onCancel, eWalletAdded, resetFields, getPlayerInfo]);

    useEffect(() => {
        setFieldsValue({
            eWalletHolderName: realName,
        });
    }, [realName, setFieldsValue]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form form={form} name="add-e-wallet" className="add-e-wallet__form" onFinish={submitForm}>
            <Row>
                <Col span={8}>
                    <span className="bold-text">{getTranslation('Username')}:</span>
                </Col>
                <Col span={16}>
                    <span className="bold-text">{userName}</span>
                </Col>
            </Row>
            <Row className="add-e-wallet__form__title">
                <span>{getTranslation('Add E-Wallet')}</span>
            </Row>
            <Row className="form__group">
                <Col span={8} align="start">
                    <span className="add-e-wallet__form__label">
                        {getTranslation('E-Wallet')}
                        <span style={{ color: 'red' }}>*</span>:
                    </span>
                </Col>
                <Col span={16}>
                    <Form.Item
                        name="eWalletName"
                        rules={[
                            {
                                message: getTranslation('Please select your E-Wallet!'),
                                required: true,
                            },
                        ]}
                    >
                        <Select>
                            {_.map(EWALLET, (e) => (
                                <Select.Option value={e.name} key={e.name}>
                                    <Row>
                                        <Col span={2}>
                                            <ImgIcon icon={e.icon} size={20} />
                                        </Col>
                                        <Col span={22} style={{ paddingLeft: '20px' }}>
                                            <span>{e.name}</span>
                                        </Col>
                                    </Row>
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row className="form__group">
                <Col span={8} align="start">
                    <span className="add-e-wallet__form__label">
                        {getTranslation('E-Wallet Account')}
                        <span style={{ color: 'red' }}>*</span>:
                    </span>
                </Col>
                <Col span={16}>
                    <Form.Item
                        name="eWalletAccountNo"
                        rules={[
                            {
                                message: getTranslation('Please input E-Wallet Account!'),
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={8} align="start">
                    <span className="add-e-wallet__form__label">
                        {getTranslation('Confirm E-Wallet Account')}
                        <span style={{ color: 'red' }}>*</span>:
                    </span>
                </Col>
                <Col span={16}>
                    <Form.Item
                        name="confirmeWalletAccountNo"
                        dependencies={['eWalletAccountNo']}
                        rules={[
                            {
                                message: getTranslation('Please confirm your E-Wallet Account!'),
                                required: true,
                            },
                            ({ getFieldValue }) => ({
                                validator: equalityValidator(
                                    getFieldValue('eWalletAccountNo'),
                                    getTranslation('Confirm E-Wallet Account inconsistent!'),
                                ),
                            }),
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={8} align="start">
                    <span className="add-e-wallet__form__label">
                        {getTranslation('E-Wallet Holder Name')}
                    </span>
                </Col>
                <Col span={16}>
                    <Form.Item name="eWalletHolderName">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="end" className="form__group">
                <ButtonGreen htmlType="submit" loading={eWalletAdding}>
                    {getTranslation('Submit')}
                </ButtonGreen>
                <Button onClick={onCancel}>{getTranslation('Cancel')}</Button>
            </Row>
        </Form>
    );
};
export default AddEWalletForm;
