import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';

import { Card, Form, Row, message } from 'antd';

import './DailyLoginSetup.less';
import { ButtonGreen, ButtonTransparent } from '../../../common/components/buttons/Buttons';
import { ROUTES } from '../../../common/constants/routing';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { onDailyLoginSetupInfoSet, onDailyLoginSetupReset } from './dailyLoginSetupActions';
import { useCreateDailyLoginBonusAsyncEndpoint } from '../../../services/rewardMangementService';
import { useStore } from '../../../store/StateProvider';
import DailyLoginDetails from '../shared/DailyLoginDetails';

const DailyLoginSetup = () => {
    const [state, dispatch] = useStore();
    const history = useHistory();
    const dailyLoginSetup = getStateProp(state, 'rewardsManagement.dailyLoginSetup');

    // ============================================
    // METHODS
    // ============================================

    const submitForm = () => {
        if (dailyLoginSetup.code === '' || dailyLoginSetup.code === null) {
            message.error(getTranslation('Please input program code!'));
            return false;
        }
        handleCreateDailyLoginBonus();
    };

    const onFormValueChange = (key, value) => {
        onDailyLoginSetupInfoSet(dispatch, { key, value });
    };

    const onFormReset = () => onDailyLoginSetupReset(dispatch);

    // ============================================
    // HOOKS
    // ===========================================

    const [creating, handleCreateDailyLoginBonus, created] = useCreateDailyLoginBonusAsyncEndpoint(
        dailyLoginSetup,
    );

    useEffect(() => {
        if (created) {
            history.push(ROUTES.DASHBOARD.REWARDS_MANAGEMENT.DAILY_LOGIN_MANAGEMENT);
        }
    }, [created, history]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Daily Login Setup')} className="daily-login-setup">
            <Form name="daily-login-setup-form" onFinish={submitForm} initialValues={{}}>
                <DailyLoginDetails
                    dailyLoginSetup={dailyLoginSetup}
                    onFormValueChange={onFormValueChange}
                />

                <Row justify="end" className="daily-login-setup__form-btn-row">
                    <ButtonGreen
                        htmlType="submit"
                        label={getTranslation('Submit')}
                        loading={creating}
                    />
                    <ButtonTransparent label={getTranslation('Cancel')} onClick={onFormReset} />
                </Row>
            </Form>
        </Card>
    );
};

export default DailyLoginSetup;
