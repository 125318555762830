import React from 'react';

import { Table } from 'antd';

//import { DEPOSIT_TABLE_COLUMNS } from '../constants';
import { getColumns, getPaginationOptions, getRowSelectionProps } from './HotEventsTableMeta';
import { getStateProp } from '../../../common/helpers/misc';
import { useStore } from '../../../store/StateProvider';

const HotEventsTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const hotEvents = getStateProp(state, 'hotEvents');
    const tableData = getStateProp(hotEvents, 'list', []);
    const paging = getStateProp(hotEvents, 'paging', {});
    const selectedHotEvents = getStateProp(hotEvents, 'selectedHotEvents', []);
    // ============================================
    // RENDER
    // ============================================
    return (
        <Table
            columns={getColumns()}
            pagination={getPaginationOptions(paging, dispatch)}
            dataSource={tableData}
            rowSelection={getRowSelectionProps(dispatch, selectedHotEvents, tableData)}
            rowKey={(record) => record.id}
            loading={loading}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
        />
    );
};

export default HotEventsTable;
