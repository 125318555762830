import React from 'react';

import { Modal, Select } from 'antd';
import _ from 'lodash';

/*eslint import/namespace: ['error', { allowComputed: true }]*/
import './UserAuthorityModal.less';
import * as Modals from '.';
import { USER_AUTHORITY_SETTING_MODAL_TYPES } from '../constants';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useGetUserAuthorityDetailsAsyncEndpoint } from '../../../../services/adminService';
import { useStore } from '../../../../store/StateProvider';
import { userAuthoritySettingInfoSet } from '../userAuthoritySettingActions';

const UserAuthorityModal = ({ onCancel, modalDetails, userGroupList, getUserGroupListLoading }) => {
    const [state, dispatch] = useStore();

    const selectedAccessGroupId = getStateProp(
        state,
        'administration.userAuthoritySetting.selectedInfo.accessGroupId',
    );
    const { modalType, isVisible, modalData } = modalDetails;
    const ModalContent = Modals[modalType.component];

    // ============================================
    // METHODS
    // ============================================

    const handleUserRoleChange = (value) => {
        userAuthoritySettingInfoSet(dispatch, {
            accessGroupId: value,
        });
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading] = useGetUserAuthorityDetailsAsyncEndpoint(modalType.component);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={isVisible}
            onCancel={onCancel}
            footer={null}
            title={
                <span>
                    {getTranslation(modalType.title)}

                    {modalType.component ===
                        USER_AUTHORITY_SETTING_MODAL_TYPES.ADD_ROLE.component && (
                        <Select
                            placeholder={getTranslation('Group Access')}
                            className="group-role-selector"
                            onChange={handleUserRoleChange}
                            loading={getUserGroupListLoading || loading}
                            value={selectedAccessGroupId}
                            disabled={loading}
                        >
                            {_.map(userGroupList, (role) => (
                                <Select.Option value={role.accessGroupId} key={role.accessGroupId}>
                                    {role.accessGroupName.toLocaleUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </span>
            }
            centered
            width={modalType.width || 700}
            getContainer={false} // solve the useForm not connected warning
        >
            <ModalContent
                modalData={modalData}
                onCancel={onCancel}
                groupAuthorityLoading={loading}
            />
        </Modal>
    );
};

export default UserAuthorityModal;
