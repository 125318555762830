export const BANK_CARDS_MODAL_TYPES = {
    ADD_BANK_CARD: {
        component: 'AddBankCardForm',
        title: 'Bank Card',
        width: 600,
    },
    DELETE_BANK_CARD: {
        component: 'DeleteDetails',
        title: 'Delete Bank Card',
        width: 400,
    },
    DELETE_BANK_CARD_CONFIRMATION: {
        component: 'DeleteConfirmation',
        title: 'Delete Bank Card Confirmation',
        width: 400,
    },
    WITHDRAWAL_REQUEST: {
        component: 'WithdrawalRequestForm',
        title: 'Withdrawal Request',
        width: 600,
    },
};

export const BANKS = {
    CNY: [
        {
            icon: require('../../../assets/images/banks/china/icbc.png'),
            name: 'Industrial and Commercial Bank of China',
        },
        {
            icon: require('../../../assets/images/banks/china/ccb.png'),
            name: 'China Construction Bank',
        },
        {
            icon: require('../../../assets/images/banks/china/abc.png'),
            name: 'Agricultural Bank of China',
        },
        {
            icon: require('../../../assets/images/banks/china/cmb.png'),
            name: 'China Merchants Bank',
        },
        { icon: require('../../../assets/images/banks/china/boc.png'), name: 'Bank of China' },
        {
            icon: require('../../../assets/images/banks/china/bocom.png'),
            name: 'Bank of Communications',
        },
        {
            icon: require('../../../assets/images/banks/china/spdb.png'),
            name: 'Shanghai Pudong Development Bank',
        },
        { icon: require('../../../assets/images/banks/china/citic.png'), name: 'China CITIC Bank' },
        {
            icon: require('../../../assets/images/banks/china/cmsb.png'),
            name: 'China MinSheng Bank',
        },
        {
            icon: require('../../../assets/images/banks/china/psbc.png'),
            name: 'Postal Savings Bank of China',
        },
    ],
    IDR: [
        {
            icon: require('../../../assets/images/banks/indo/bri.png'),
            name: 'Bank Rakyat Indonesia',
        },
        {
            icon: require('../../../assets/images/banks/indo/mandiri.png'),
            name: 'Bank Mandiri',
        },
        {
            icon: require('../../../assets/images/banks/indo/bca.png'),
            name: 'Bank Central Asia',
        },
        {
            icon: require('../../../assets/images/banks/indo/bni.png'),
            name: 'Bank Negara Indonesia',
        },
        {
            icon: require('../../../assets/images/banks/indo/btn.png'),
            name: 'Bank Tabungan Negara',
        },
        {
            icon: require('../../../assets/images/banks/indo/cimb.png'),
            name: 'Bank CIMB Niaga',
        },
        {
            icon: require('../../../assets/images/banks/indo/btpn.png'),
            name: 'Bank BTPN',
        },
        {
            icon: require('../../../assets/images/banks/indo/paninbank.png'),
            name: 'Panin Bank',
        },
        {
            icon: require('../../../assets/images/banks/indo/ocbc.png'),
            name: 'Bank OCBC NISP',
        },
        {
            icon: require('../../../assets/images/banks/indo/maybank.png'),
            name: 'Bank Maybank Indonesia',
        },
    ],
    MYR: [
        {
            icon: require('../../../assets/images/banks/malaysia/maybank.png'),
            name: 'Maybank',
        },
        {
            icon: require('../../../assets/images/banks/malaysia/cimb.png'),
            name: 'CIMB Bank',
        },
        {
            icon: require('../../../assets/images/banks/malaysia/pbb.png'),
            name: 'Public Bank Berhad',
        },
        {
            icon: require('../../../assets/images/banks/malaysia/rhb.png'),
            name: 'RHB Bank',
        },
        {
            icon: require('../../../assets/images/banks/malaysia/hongleong.png'),
            name: 'Hong Leong Bank',
        },
        {
            icon: require('../../../assets/images/banks/malaysia/ambank.png'),
            name: 'AmBank',
        },
        {
            icon: require('../../../assets/images/banks/malaysia/hsbc.png'),
            name: 'HSBC Bank Malaysia',
        },
        {
            icon: require('../../../assets/images/banks/malaysia/affin.png'),
            name: 'Affin Bank',
        },
        {
            icon: require('../../../assets/images/banks/malaysia/bim.png'),
            name: 'Bank Islam Malaysia',
        },
        {
            icon: require('../../../assets/images/banks/malaysia/bsn.png'),
            name: 'Bank Simpanan Nasional',
        },
        {
            icon: require('../../../assets/images/banks/malaysia/uob.png'),
            name: 'United Overseas Bank (UOB)',
        },
        {
            icon: require('../../../assets/images/banks/malaysia/bank-rakyat.png'),
            name: 'Bank Rakyat',
        },
        {
            icon: require('../../../assets/images/banks/malaysia/ocbc.png'),
            name: 'OCBC Malaysia Bank',
        },
        {
            icon: require('../../../assets/images/banks/malaysia/standard-chartered.png'),
            name: 'Standard Chartered Bank Malaysia',
        },
        {
            icon: require('../../../assets/images/banks/malaysia/citibank.png'),
            name: 'CitiBank Malaysia',
        },
        {
            icon: require('../../../assets/images/banks/malaysia/alliance.png'),
            name: 'Alliance Bank',
        },
        {
            icon: require('../../../assets/images/banks/malaysia/agro.png'),
            name: 'Agrobank',
        },
    ],
    THB: [
        {
            icon: require('../../../assets/images/banks/thai/siam-scb.png'),
            name: 'Siam Commercial Bank',
        },
        {
            icon: require('../../../assets/images/banks/thai/kasikorn.png'),
            name: 'Kasikorn Bank',
        },
        {
            icon: require('../../../assets/images/banks/thai/krungthai.png'),
            name: 'Krung Thai Bank',
        },
        {
            icon: require('../../../assets/images/banks/thai/bbok.png'),
            name: 'Bangkok Bank',
        },
        {
            icon: require('../../../assets/images/banks/thai/krungsri-Ayudhya.png'),
            name: 'Bank of Ayudhya Bank',
        },
        {
            icon: require('../../../assets/images/banks/thai/tmb.png'),
            name: 'TMB Bank',
        },
        {
            icon: require('../../../assets/images/banks/thai/cimb.png'),
            name: 'CIMB Thai',
        },
        {
            icon: require('../../../assets/images/banks/thai/govhob.png'),
            name: 'Government Housing Bank',
        },
        {
            icon: require('../../../assets/images/banks/thai/kiatnakin.png'),
            name: 'Kiatnakin Bank',
        },
        {
            icon: require('../../../assets/images/banks/thai/gsb.png'),
            name: 'Government Saving Bank',
        },
    ],
    VND: [
        {
            icon: require('../../../assets/images/banks/vietnam/vietabank.png'),
            name: 'VietABank',
        },
        {
            icon: require('../../../assets/images/banks/vietnam/sacombank.png'),
            name: 'Sacombank',
        },
        {
            icon: require('../../../assets/images/banks/vietnam/saigom.png'),
            name: 'Saigon Bank',
        },
        {
            icon: require('../../../assets/images/banks/vietnam/agribank.png'),
            name: 'Agribank',
        },
        {
            icon: require('../../../assets/images/banks/vietnam/techcombank.png'),
            name: 'Techcombank',
        },
        {
            icon: require('../../../assets/images/banks/vietnam/vietcombank.png'),
            name: 'Vietcombank',
        },
        {
            icon: require('../../../assets/images/banks/vietnam/bidv.png'),
            name: 'BIDV',
        },
        {
            icon: require('../../../assets/images/banks/vietnam/vietinbank.png'),
            name: 'Vietinbank',
        },
    ],
};

export const PLAYER_DETAILS_MENU_ITEMS = [
    {
        id: 'MEMBER_INFO',
        name: 'Member Information',
        route: 'member-info',
    },
    {
        id: 'MESSAGES',
        name: 'History messages',
        route: 'messages',
    },
    {
        id: 'USER_SESSIONS',
        name: 'User Session log',
        route: 'session-log',
    },
    {
        id: 'BANK_CARDS',
        name: 'Bank Cards',
        route: 'bank-cards',
    },
    {
        id: 'E_WALLET',
        name: 'E-Wallet',
        route: 'e-wallet',
    },
];

export const EWALLET = [
    {
        icon: require('../../../assets/images/e-wallet/go-pay.png'),
        name: 'GoPay',
    },
    {
        icon: require('../../../assets/images/e-wallet/ovo.png'),
        name: 'OVO',
    },
    {
        icon: require('../../../assets/images/e-wallet/LinkAja.png'),
        name: 'LinkAja',
    },
    {
        icon: require('../../../assets/images/e-wallet/DANA.png'),
        name: 'DANA',
    },
    {
        icon: require('../../../assets/images/e-wallet/Sakuku.png'),
        name: 'Sakuku',
    },
];

export const E_WALLET_MODAL_TYPES = {
    ADD_E_WALLET: {
        component: 'AddEWalletForm',
        title: 'Add E-Wallet',
        width: 600,
    },
    DELETE_E_WALLET: {
        component: 'DeleteEWalletForm',
        title: 'Delete E-Wallet',
        width: 400,
    },
    WITHDRAWAL_REQUEST: {
        component: 'WithdrawalRequestForm',
        title: 'Withdrawal Request',
        width: 600,
    },
};
