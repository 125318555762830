import { ACTIONS } from './withdrawalsActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function withdrawalsReducer(
    withdrawals = {
        operation: {
            action: null,
            isRecord: false,
            refreshTime: 15,
            refreshTimePause: false,
        },
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        rejectModalVisible: false,
        resetPageNo: false,
        verifyModalVisible: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.FILTER_SET:
            return {
                ...withdrawals,
                filters: {
                    ...withdrawals.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };
        case ACTIONS.FILTERS_DROP_ALL:
            return {
                ...withdrawals,
                filters: null,
                resetPageNo: true,
            };
        case ACTIONS.WITHDRAWALS_GET_SUCCESS:
            return {
                ...withdrawals,
                ...payload,
            };
        case ACTIONS.REJECT_INFO_SET:
            return {
                ...withdrawals,
                rejectInfo: {
                    ...withdrawals.rejectInfo,
                    [payload.rejectInfo]: payload.value,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...withdrawals,
                paging: {
                    ...withdrawals.paging,
                    pageNo:
                        payload > withdrawals.paging.pageCount
                            ? withdrawals.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...withdrawals,
                paging: {
                    ...withdrawals.paging,
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };
        case ACTIONS.OPERATION:
            return {
                ...withdrawals,
                operation: {
                    ...withdrawals.operation,
                    ...payload,
                },
            };
        case ACTIONS.WITHDRAWALS_GET_BANK_CARDS_SUCCESS:
            return {
                ...withdrawals,
                bankCards: {
                    ...payload.list,
                },
            };
        case ACTIONS.VERIFY_INFO_SET:
            return {
                ...withdrawals,
                verifyInfo: {
                    ...withdrawals.verifyInfo,
                    [payload.verifyInfo]: payload.value,
                },
            };
        case ACTIONS.VERIFY_MODAL_OPEN:
            return {
                ...withdrawals,
                verifyModalVisible: true,
            };
        case ACTIONS.VERIFY_MODAL_CLOSE:
            return {
                ...withdrawals,
                operation: {
                    ...withdrawals.operation,
                    isRecord: false,
                },
                verifyModalVisible: false,
            };
        case ACTIONS.REJECT_MODAL_OPEN:
            return {
                ...withdrawals,
                rejectModalVisible: true,
            };
        case ACTIONS.REJECT_MODAL_CLOSE:
            return {
                ...withdrawals,
                operation: {
                    ...withdrawals.operation,
                    isRecord: false,
                },
                rejectModalVisible: false,
            };
        case ACTIONS.REFRESH_TIME_SET:
            return {
                ...withdrawals,
                operation: {
                    ...withdrawals.operation,
                    refreshTime: payload.timeOut,
                    refreshTimePause: false,
                },
            };
        case ACTIONS.REFRESH_TIME_PAUSE:
            return {
                ...withdrawals,
                operation: {
                    refreshTimePause: true,
                },
            };
        case ACTIONS.COLUMNS_SELECT:
            return {
                ...withdrawals,
                selectedColumns: payload,
            };
        case ACTIONS.WITHDRAWALS_GET:
            const pageNo = withdrawals.resetPageNo
                ? TABLE_PAGE_NO_DEFAULT
                : withdrawals.paging.pageNo;
            return {
                ...withdrawals,
                paging: {
                    ...withdrawals.paging,
                    pageNo: pageNo,
                },
                resetPageNo: false,
            };
        case ACTIONS.OPERATION_SUCCESS:
        case ACTIONS.OPERATION_FAILURE:
            return {
                ...withdrawals,
                operation: {
                    ...withdrawals.operation,
                    action: null,
                },
            };
        case ACTIONS.WITHDRAWALS_DANGER_LEVEL_GET_SUCCESS:
            return {
                ...withdrawals,
                dangerLevels: payload,
            };
        case ACTIONS.WITHDRAWALS_DANGER_LEVEL_GET:
        default:
            return withdrawals;
    }
}
