import React from 'react';

import { Table } from 'antd';

import './TurnoverCheckResultsTable.less';
import { getStateProp } from '../../../../common/helpers/misc';
import { tableBodyWrapper } from './TurnoverCheckTableMeta';
import { useStore } from '../../../../store/StateProvider';

const TurnoverCheckResultsTable = ({ loading }) => {
    const [state] = useStore();

    const turnoverCheck = getStateProp(state, 'bet.turnoverCheck');
    const tableData = getStateProp(turnoverCheck, 'list', []);

    return (
        <Table
            id={'turnover-check-table-id'}
            bordered
            dataSource={tableData}
            size="middle"
            align="left"
            loading={loading}
            scroll={{ x: true }}
            rowKey={(record) => record.sportTypeGroupName}
            components={{
                body: { wrapper: (props) => tableBodyWrapper(props, tableData) },
            }}
            pagination={false}
            showHeader={false}
        />
    );
};

export default TurnoverCheckResultsTable;
