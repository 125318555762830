import React from 'react';

import { Row } from 'antd';

import {
    CurrencyFilter,
    PeriodFilter,
    TransactionNumberFilter,
    TransactionStatusFilter,
    UsernameFilter,
} from '../../../common/components/filters';
import { getStateProp } from '../../../common/helpers/misc';
import { rebateRecordsFilterSet } from './rebateRecordsActions';
import { useStore } from '../../../store/StateProvider';

const RebateRecordsFilters = () => {
    const [state, dispatch] = useStore();

    const { rebateRecords } = getStateProp(state, 'transactionHistory');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => rebateRecordsFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(rebateRecords, 'filters.playerUserName')}
                name="playerUserName"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(rebateRecords, 'filters.processedDateFrom')}
                dateFromName="processedDateFrom"
                dateTo={getStateProp(rebateRecords, 'filters.processedDateTo')}
                dateToName="processedDateTo"
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(rebateRecords, 'filters.currencyCode')}
                name="currencyCode"
            />
            <TransactionNumberFilter
                onFilterChange={setFilter}
                value={getStateProp(rebateRecords, 'filters.transactionNo')}
                name="transactionNo"
            />
            <TransactionStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(rebateRecords, 'filters.transactionStatuses')}
            />
        </Row>
    );
};

export default RebateRecordsFilters;
