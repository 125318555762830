export const ACTIONS = {
    BANK_ACCOUNT_STATEMENT_GET: 'BANK_ACCOUNT_STATEMENT_GET',
    BANK_ACCOUNT_STATEMENT_GET_FAILURE: 'BANK_ACCOUNT_STATEMENT_GET_FAILURE',
    BANK_ACCOUNT_STATEMENT_GET_SUCCESS: 'BANK_ACCOUNT_STATEMENT_GET_SUCCESS',
    FILTERS_DROP_ALL: 'BANK_ACCOUNT_STATEMENT_FILTERS_DROP_ALL',
    FILTER_SET: 'BANK_ACCOUNT_STATEMENT_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'BANK_ACCOUNT_STATEMENT_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'BANK_ACCOUNT_STATEMENT_TABLE_PAGE_SIZE_CHANGE',
    },
};

export const bankAccountStatementGet = (dispatch) =>
    dispatch({
        type: ACTIONS.BANK_ACCOUNT_STATEMENT_GET,
    });

export const bankAccountStatementGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANK_ACCOUNT_STATEMENT_GET_SUCCESS,
    });

export const bankAccountStatementGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANK_ACCOUNT_STATEMENT_GET_FAILURE,
    });

export const filterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });
