import React from 'react';

import { Modal, Row } from 'antd';
import _ from 'lodash';

import {
    ButtonGray,
    ButtonGreen,
    ButtonLightBlue,
} from '../../../common/components/buttons/Buttons';
import { addDangerLevelTag, editDangerLevelTag } from './dangerLevelActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';
import DangerLevelTagTable from './table/DangerLevelTagTable';

const DangerLevelTagModal = ({
    gettingDangerLevelTag,
    updating,
    visible,
    onCancel,
    onSubmit,
    onDelete,
}) => {
    const [state, dispatch] = useStore();

    const { dangerLevel } = getStateProp(state, 'players');
    const tableData = getStateProp(dangerLevel, 'dangerLevelTagList', []);
    // ============================================
    // METHODS
    // ============================================
    const handleAddDangerLevelTag = () => {
        addDangerLevelTag(dispatch);
    };

    const handleEditDangerLevelTag = (key, value, selectedDangerLevel, lastUpdatedBy) => {
        let newArray = tableData;
        let selectedDangerLevelTag = _.find(newArray, {
            lastUpdatedBy: lastUpdatedBy,
            level: selectedDangerLevel,
        });
        selectedDangerLevelTag[key] = value;
        editDangerLevelTag(dispatch, { dangerLevelTagList: newArray });
    };

    const handleDeleteDangerLevelTag = (selectedDangerLevel, lastUpdatedBy = null) => {
        if (_.isEmpty(lastUpdatedBy)) {
            let newArray = _.filter(tableData, (e) => e.level !== selectedDangerLevel);
            _.forEach(newArray, (e, idx) => {
                if (e.level !== idx) e.level = idx;
            });
            editDangerLevelTag(dispatch, { dangerLevelTagList: newArray });
        } else {
            onDelete(selectedDangerLevel);
        }
    };

    // ============================================
    // HOOKS
    // ============================================

    // ============================================
    // RENDER
    // ============================================
    return (
        <Modal
            className="danger-level__tag-modal"
            width={780}
            visible={visible}
            title={getTranslation('Danger Level Tag')}
            onCancel={() => onCancel(false)}
            closable={false}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        label={getTranslation('Save Settings')}
                        onClick={onSubmit}
                        loading={updating}
                        disabled={gettingDangerLevelTag}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
        >
            <DangerLevelTagTable
                loading={gettingDangerLevelTag}
                tableData={tableData}
                handleEditDangerLevelTag={handleEditDangerLevelTag}
                handleDeleteDangerLevelTag={handleDeleteDangerLevelTag}
            />
            {tableData.length <= 19 && (
                <ButtonLightBlue
                    className="danger-level__tag-modal__add-btn"
                    label="Add Danger Level Tag"
                    onClick={handleAddDangerLevelTag}
                    disabled={gettingDangerLevelTag || updating}
                />
            )}
        </Modal>
    );
};

export default DangerLevelTagModal;
