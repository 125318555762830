import React from 'react';

import GroupAuthoritySettingEditorForm from './GroupAuthoritySettingEditorForm';

const GroupAuthoritySettingEditor = ({ match }) => {
    const { accessGroupId } = match.params;

    // ============================================
    // RENDER
    // ============================================

    return <GroupAuthoritySettingEditorForm accessGroupId={accessGroupId} />;
};

export default GroupAuthoritySettingEditor;
