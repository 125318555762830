import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

import { API } from '../common/constants/api';
import {
    activatedBankCardSetupModalClose,
    activatedBankCardUpdate,
    activatedBankCardUpdateFailure,
    activatedBankCardUpdateSuccess,
    bankCardGet,
    bankCardGetFailure,
    bankCardGetSuccess,
    bankCardSetup,
    bankCardSetupFailure,
    bankCardSetupSuccess,
} from '../components/payments/bank-account-setup/active-bank-account/activeBankAccountActions';
import {
    bankGet,
    bankGetFailure,
    bankGetSuccess,
} from '../components/payments/bank-account-setup/bankActions';
import { clearError } from '../common/actions/errorActions';
import {
    deactivatedBankCardGet,
    deactivatedBankCardGetFailure,
    deactivatedBankCardGetSuccess,
    deactivatedBankCardSetupModalClose,
    deactivatedBankCardUpdate,
    deactivatedBankCardUpdateFailure,
    deactivatedBankCardUpdateSuccess,
} from '../components/payments/bank-account-setup/deactivated-bank-account/deactivatedBankAccountActions';

import { PAYMENT_ACCOUNT_SETUP_TYPES } from '../common/constants/misc';
import {
    bankAccountStatementGet,
    bankAccountStatementGetFailure,
    bankAccountStatementGetSuccess,
} from '../components/payments/bank-account-statement/bankAccountStatementActions';
import {
    bankCardConfiguration,
    bankCardConfigurationFailure,
    bankCardConfigurationGet,
    bankCardConfigurationGetFailure,
    bankCardConfigurationGetSuccess,
    bankCardConfigurationSuccess,
    onCloseConfirmModal,
} from '../components/payments/bank-account-configuration/bankAccountConfigurationActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import { useDispatch, useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, PAYMENTS } = API;

export function useGetActivatedBankCardsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const filters = getStateProp(state, 'payments.activeBankAccount.filters', {});
    const { pageNo, pageSize } = getStateProp(state, 'payments.activeBankAccount.paging');

    // ============================================
    // METHODS
    // ============================================

    const handleGetActivatedBankCards = useCallback(() => {
        bankCardGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                activatedStatus: 'Activated',
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.BANK_ACCOUNT.CARD_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bankCardGetSuccess(dispatch, {
                fetchData: handleGetActivatedBankCards,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetActivatedBankCards, response]);

    useEffect(() => {
        handleGetActivatedBankCards();
    }, [handleGetActivatedBankCards, pageNo, pageSize]);

    const errorMsg = useError(error, bankCardGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetActivatedBankCards];
}

export function useGetDeactivatedBankCardsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const filters = getStateProp(state, 'payments.deactivatedBankAccount.filters', {});
    const { pageNo, pageSize } = getStateProp(state, 'payments.deactivatedBankAccount.paging');

    // ============================================
    // METHODS
    // ============================================

    const handleGetDeactivatedBankCards = useCallback(() => {
        deactivatedBankCardGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                activatedStatus: 'Deactivated',
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.BANK_ACCOUNT.CARD_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            deactivatedBankCardGetSuccess(dispatch, {
                fetchData: handleGetDeactivatedBankCards,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetDeactivatedBankCards, response]);

    useEffect(() => {
        handleGetDeactivatedBankCards();
    }, [handleGetDeactivatedBankCards, pageNo, pageSize]);

    const errorMsg = useError(error, deactivatedBankCardGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetDeactivatedBankCards];
}

export function useSetupBankCardAsyncEndpoint(bankCardInfo) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { bankId } = bankCardInfo;
    const refreshTable = getStateProp(state, 'payments.activeBankAccount.fetchData', () => {});

    // ============================================
    // METHODS
    // ============================================

    const handleAddBankCard = () => {
        setTrigger(+new Date());
        bankCardSetup(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: { ...bankCardInfo },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.BANK_ACCOUNT.BANK_LIST}/${bankId}/${'cards'}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bankCardSetupSuccess(dispatch);
            activatedBankCardSetupModalClose(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, bankCardSetupFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleAddBankCard];
}

export function useUpdateActivatedBankCardAsyncEndpoint(bankCardInfo) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const accountDetails = getStateProp(
        state,
        'payments.activeBankAccount.modal.accountDetails',
        {},
    );
    const refreshTable = getStateProp(state, 'payments.activeBankAccount.fetchData', () => {});

    const {
        bankId,
        cardId,
        memberLevels,
        trustLevels,
        specialPlayerNames,
        dailyMaximumAmount,
        isWebShow,
    } = accountDetails;

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateActivatedBankCard = () => {
        setTrigger(+new Date());
        activatedBankCardUpdate(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                ...bankCardInfo,
                dailyMaximumAmount,
                isWebShow,
                memberLevels,
                specialPlayerNames,
                trustLevels,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.BANK_ACCOUNT.BANK_LIST}/${bankId}/${'cards'}/${cardId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            activatedBankCardUpdateSuccess(dispatch);
            activatedBankCardSetupModalClose(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, activatedBankCardUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateActivatedBankCard];
}

export function useUpdateDeactivatedBankCardAsyncEndpoint(bankCardInfo) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const cardId = getStateProp(
        state,
        'payments.deactivatedBankAccount.modal.accountDetails.cardId',
        '',
    );
    const bankId = getStateProp(
        state,
        'payments.deactivatedBankAccount.modal.accountDetails.bankId',
        {},
    );
    const refreshTable = getStateProp(state, 'payments.deactivatedBankAccount.fetchData', () => {});

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateDeactivatedBankCard = () => {
        setTrigger(+new Date());
        deactivatedBankCardUpdate(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                ...bankCardInfo,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.BANK_ACCOUNT.BANK_LIST}/${bankId}/${'cards'}/${cardId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            deactivatedBankCardUpdateSuccess(dispatch);
            deactivatedBankCardSetupModalClose(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, deactivatedBankCardUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateDeactivatedBankCard];
}

export function useGetBankAsyncEndpoint(countryId) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetBank = useCallback(() => {
        bankGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!countryId,
        method: 'GET',
        options: {
            params: { countryId },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.BANK_ACCOUNT.BANK_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bankGetSuccess(dispatch, {
                list: response.data.data,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        handleGetBank();
    }, [handleGetBank, countryId]);

    const errorMsg = useError(error, bankGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useGetBankCardConfigurationAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const filters = getStateProp(state, 'payments.bankAccountConfiguration.filters', {});
    const { pageNo, pageSize } = getStateProp(state, 'payments.bankAccountConfiguration.paging');

    // ============================================
    // METHODS
    // ============================================

    const handleGetBankCardConfiguration = useCallback(() => {
        bankCardConfigurationGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                activatedStatus: 'Activated',
                pageNo,
                pageSize,
                type: PAYMENT_ACCOUNT_SETUP_TYPES.DEPOSIT,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.BANK_ACCOUNT.CARD_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bankCardConfigurationGetSuccess(dispatch, {
                fetchData: handleGetBankCardConfiguration,
                list: response.data.data,
            });
        }
    }, [dispatch, handleGetBankCardConfiguration, response]);

    useEffect(() => {
        handleGetBankCardConfiguration();
    }, [handleGetBankCardConfiguration, pageNo, pageSize]);

    const errorMsg = useError(error, bankCardConfigurationGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetBankCardConfiguration];
}

export function useUpdateBankCardConfigurationAsyncEndpoint({ specialPlayerNames, ...rest }) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const bankAccountConfiguration = getStateProp(state, 'payments.bankAccountConfiguration', {});
    const accountDetails = getStateProp(bankAccountConfiguration, 'modalData.accountDetails', {});
    const refreshTable = getStateProp(bankAccountConfiguration, 'fetchData', () => {});

    const {
        memberLevels,
        trustLevels,
        specialPlayerIds,
        dailyMaximumAmount,
        bankId,
        cardId,
        remarks,
        isWebShow,
        ...restAccountDetails
    } = accountDetails;

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateBankCardConfiguration = () => {
        setTrigger(+new Date());
        bankCardConfiguration(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                ...rest,
                ...restAccountDetails,
                specialPlayerNames: _.isArray(specialPlayerNames)
                    ? specialPlayerNames
                    : [specialPlayerNames],
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.BANK_ACCOUNT.BANK_LIST}/${bankId}/${'cards'}/${cardId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bankCardConfigurationSuccess(dispatch);
            onCloseConfirmModal(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, bankCardConfigurationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateBankCardConfiguration];
}

export function useGetBankAccountStatementAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const filters = getStateProp(state, 'payments.bankAccountStatement.filters', null);
    const { pageNo, pageSize } = getStateProp(state, 'payments.bankAccountStatement.paging');

    // ============================================
    // METHODS
    // ============================================

    const handleGetBankAccountStatement = useCallback(() => {
        bankAccountStatementGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!filters,
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.BANK_ACCOUNT.STATEMENT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            bankAccountStatementGetSuccess(dispatch, {
                fetchData: handleGetBankAccountStatement,
                list: response.data.data[0].paymentBankCardAccountStatementList,
                paging: response.data.paging,
                totalBalance: response.data.data[0].totalBalance,
                totalCashIn: response.data.data[0].totalCashIn,
                totalCashOut: response.data.data[0].totalCashOut,
                totalFee: response.data.data[0].totalFee,
            });
        }
    }, [dispatch, handleGetBankAccountStatement, response]);

    useEffect(() => {
        handleGetBankAccountStatement();
    }, [handleGetBankAccountStatement, pageSize, pageNo]);

    const errorMsg = useError(error, bankAccountStatementGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetBankAccountStatement];
}
