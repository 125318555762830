import React from 'react';

import { Col, Input, Row } from 'antd';

import './TurnoverCheckSummary.less';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';

const TurnoverCheckSummary = () => {
    const [state] = useStore();

    const totalEffectiveTurnover = getStateProp(
        state,
        'bet.turnoverCheck.totalEffectiveTurnover',
        0,
    );

    const thisMonthEffectiveTurnover = getStateProp(
        state,
        'bet.turnoverCheck.thisMonthEffectiveTurnover',
        0,
    );

    return (
        <div className="turnover-check-summary">
            <Row align="middle" justify="end" className="turnover-check-summary-row">
                <Col span={5}>
                    <span className="summary-label">
                        {getTranslation('Total Effective Turnover')}
                    </span>
                </Col>
                <Col span={6}>
                    <Input
                        disabled
                        className="total-effective-turnover__input"
                        value={totalEffectiveTurnover}
                    />
                </Col>
            </Row>
            <Row align="middle" justify="end" className="turnover-check-summary-row">
                <Col span={5}>
                    <span className="summary-label">
                        {getTranslation('This month Effective Turnover')}
                    </span>
                </Col>
                <Col span={6}>
                    <Input
                        disabled
                        className="monthly-effective-turnover__input"
                        value={thisMonthEffectiveTurnover}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default TurnoverCheckSummary;
