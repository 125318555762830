import React from 'react';

import { Col, Row } from 'antd';
import classNames from 'classnames';

import { PLAYER_DETAILS_MENU_ITEMS } from './constants';
import { getTranslation } from '../../../common/helpers/locale';

const PlayerDetailsMenu = ({ selectedItem, onSelectionChanged }) => {
    // ============================================
    // RENDER
    // ============================================

    const MenuItem = ({ item }) => (
        <Col
            span={4}
            className={classNames(
                'player-details__menu__item',
                selectedItem === item.id ? 'active' : '',
            )}
            onClick={() => onSelectionChanged(item.id)}
        >
            {getTranslation(item.name)}
        </Col>
    );

    return (
        <Row align="middle" justify="start" className="player-details__menu">
            {PLAYER_DETAILS_MENU_ITEMS.map((item) => (
                <MenuItem item={item} key={item.id} />
            ))}
        </Row>
    );
};

export default PlayerDetailsMenu;
