import React from 'react';

import { Table } from 'antd';
import { getColumns } from './PlayersSearchTableMeta';

const PlayersSearchResultsTable = ({ loading, tableData }) => {
    return (
        <Table
            id="results-table"
            columns={getColumns()}
            dataSource={tableData}
            rowKey={(record) => record.userName}
            size="small"
            align="left"
            bordered
            loading={loading}
        />
    );
};

export default PlayersSearchResultsTable;
