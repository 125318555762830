import React from 'react';

import { Input } from 'antd';

import { getTranslation } from '../../../../common/helpers/locale';

const ReferenceNoFilter = ({ value, onFilterChange }) => {
    return (
        <Input
            className="filter"
            placeholder={getTranslation('Ref No.')}
            onChange={(e) => onFilterChange('refNo', e.target.value)}
            value={value}
            allowClear={true}
        />
    );
};

export default ReferenceNoFilter;
