import { useCallback, useEffect, useState } from 'react';

import { Modal, message } from 'antd';
import _ from 'lodash';

import { API } from '../../common/constants/api';
import { clearError } from '../../common/actions/errorActions';
import {
    exportDataMapping,
    exportTable,
    exportTableByHTMLTable,
    getStateProp,
} from '../../common/helpers/misc';
import {
    exportTableData,
    exportTableDataFailure,
    exportTableDataSuccess,
} from '../../common/actions/exportActions';
import { getTranslation } from '../../common/helpers/locale';
import { useStore } from '../../store/StateProvider';
import { useToasterErrorMessage } from './useToasterMessage';
import exportWarningModal from '../../common/components/exportWarningModal';
import useApi from './useApi';
import useError from './useError';

const { VERSION } = API;

/**
 *
 * @param {String} url The endpoint URL
 * @param {String} method HTTP Method
 * @param {[{}]} dataMeta Table meta, use to map with the response payload
 * @param {*} filters Object with filter values. Example: {dangerLevel: 1, userNameLike: "jagger"}
 * @param {Number} totalRecordCount
 * @param {String} subject Title of the table
 * @param {*} mandatoryFilter The object of all the mandatory filter.
 * @param {String} innerListKey The key in the object to export if the respond payload include other field.
 * @param {[[]]} additionalRow Array of Array with the value to export at last row. Example: [['Total amount', 666],['Total Page Amount',888]].
 * @param {*} additionalCol Object with function to get additional column, combination of multiple fields to export. Example in bet list: betListRecordDetailsExport
 * @param {*} getFromDifferentPathList Object with values from different path Example: {realName: 'profile.realName'}
 * @param {*} changeTitles Object with values to change title on fields Example: {gameName: getTranslation('Game Name')}
 */
export function useExportAsyncEndpoint({
    url,
    method,
    dataMeta,
    filters,
    totalRecordCount,
    subject,
    mandatoryFilter,
    innerListKey,
    additionalCol,
    additionalRow,
    getFromDifferentPathList,
    changeTitles,
}) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const author = getStateProp(state, 'user.userName');
    const offset = getStateProp(state, 'timezone.offset');

    const options =
        method === 'POST'
            ? {
                  data: {
                      ...filters,
                      isExport: true,
                      ...mandatoryFilter,
                      pageSize: totalRecordCount,
                  },
              }
            : {
                  params: {
                      ...filters,
                      isExport: true,
                      ...mandatoryFilter,
                      pageSize: totalRecordCount,
                  },
              };

    // ============================================
    // METHODS
    // ============================================

    const handleExportAction = useCallback(() => {
        if (filters) {
            exportTableData(dispatch);
            setTrigger(+new Date());
            exportWarningModal();
        } else {
            message.warning('Please do filter to before export');
        }
    }, [dispatch, filters]);

    // ============================================
    // HOOKS
    // ============================================

    const { response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method,
        options,
        trigger,
        url: `${VERSION.V1}${url}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            exportTableDataSuccess(dispatch);
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (response) {
            Modal.destroyAll();
            _.isEmpty(innerListKey ? response.data.data[0][innerListKey] : response.data.data)
                ? message.warning(getTranslation('No records found'))
                : exportTable(
                      exportDataMapping(
                          innerListKey ? response.data.data[0][innerListKey] : response.data.data,
                          dataMeta,
                          additionalCol,
                          getFromDifferentPathList,
                          offset,
                      ),
                      {
                          additionalCol,
                          additionalRow,
                          author,
                          changeTitles,
                          subject,
                      },
                  );
        }
        // eslint-disable-next-line
    }, [author, response]);

    const errorMsg = useError(error, exportTableDataFailure);
    useToasterErrorMessage(error, errorMsg);

    return [handleExportAction];
}

/**
 *
 * @param {String} subject Title of the table
 * @param {[[]]} additionalRow Array of Array with the value to export at last row. Example: [['Total amount', 666],['Total Page Amount',888]].
 * @param {String} htmlTableId The table id to export
 */
export function useExportHTMLTableAsyncEndpoint({ subject, additionalRow, htmlTableId }) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(false);
    const author = getStateProp(state, 'user.userName');

    // ============================================
    // METHODS
    // ============================================

    const handleExportAction = (filters) => {
        if (filters) {
            exportTableData(dispatch);
            setTrigger(true);
        } else {
            message.warning('Please do filter to before export');
        }
    };

    const exportTable = () => {
        exportTableDataSuccess(dispatch);

        exportTableByHTMLTable(htmlTableId, {
            additionalRow,
            author,
            subject,
        });

        setTrigger(false);
    };

    useEffect(() => {
        if (trigger) {
            exportTable();
        }
    });

    return [handleExportAction];
}
