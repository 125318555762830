import { ACTIONS } from '../actions/operationsActions';

export default function operationsReducer(operation, { type, payload = null }) {
    switch (type) {
        case ACTIONS.OPERATIONS_CREATE_PLAYER:
            return {
                ...operation,
                createPlayer: payload,
            };
        case ACTIONS.OPERATIONS_CHANGE_PLAYER_STATUS:
            return {
                ...operation,
                changePlayerStatus: payload,
            };
        case ACTIONS.OPERATIONS_CREATE_OPERATOR:
            return {
                ...operation,
                createOperator: payload,
            };
        case ACTIONS.OPERATIONS_CLEAR:
            return null;
        default:
            return operation;
    }
}
