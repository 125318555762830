import React, { useState } from 'react';

import { Col, Form, Input, InputNumber, Modal, Radio, Row } from 'antd';
import _ from 'lodash';

import './ConfigurationModal.less';
import { ButtonGray, ButtonGreen } from '../../../../common/components/buttons/Buttons';
import {
    MEMBER_LEVELS,
    PAYMENT_GATEWAY_WEB_SHOW,
    TRUST_LEVELS,
} from '../../../../common/constants/misc';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useStore } from '../../../../store/StateProvider';
import { useUpdateBankCardConfigurationAsyncEndpoint } from '../../../../services/bankAccountService';
import CheckboxInput from '../table/input-field/CheckboxInput';

const { TextArea } = Input;

const ConfigurationModal = ({ onCancel }) => {
    const [state] = useStore();
    const modalData = getStateProp(state, 'payments.bankAccountConfiguration.modalData', {});
    const accountDetails = getStateProp(modalData, 'accountDetails', {});
    const [formValues, setFormValues] = useState({});

    // ============================================
    // METHODS
    // ============================================

    const submitForm = (values) => {
        setFormValues(values);
        handleUpdateBankCardConfiguration();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [
        loading,
        handleUpdateBankCardConfiguration,
    ] = useUpdateBankCardConfigurationAsyncEndpoint(formValues);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={true}
            title={getTranslation('Edit Bank Card Configuration')}
            onCancel={onCancel}
            className="bank-account-configuration-modal"
            centered
            width={750}
            closable={false}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        form="bank-card-configuration"
                        label={getTranslation('Confirm')}
                        loading={loading}
                        htmlType="submit"
                    />

                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
        >
            <Form
                name="bank-card-configuration"
                onFinish={submitForm}
                initialValues={{ ...accountDetails }}
            >
                <div className="bank-account-configuration-details-row">
                    <Row className="details-label">
                        <Col span={8}>
                            <label>{getTranslation('Bank')}</label>
                        </Col>
                        <Col span={13}>
                            <span>{accountDetails.bankName}</span>
                        </Col>
                    </Row>

                    <Row className="details-label">
                        <Col span={8}>
                            <label>{getTranslation('Account Name')}</label>
                        </Col>
                        <Col span={13}>
                            <span>{accountDetails.cardHolderName}</span>
                        </Col>
                    </Row>

                    <Row className="details-label">
                        <Col span={8}>
                            <label>{getTranslation('Bank Account Number')}</label>
                        </Col>
                        <Col span={13}>
                            <span>{accountDetails.bankCardNo}</span>
                        </Col>
                    </Row>

                    <Row className="details-label">
                        <Col span={8}>
                            <label>{getTranslation('Passage')}</label>
                        </Col>
                        <Col span={13}>
                            <span>{accountDetails.passage}</span>
                        </Col>
                    </Row>

                    <Row className="details-label">
                        <Col span={8}>
                            <label>{getTranslation('Trust Level')}</label>
                        </Col>
                        <Col span={13}>
                            <CheckboxInput options={TRUST_LEVELS} name="trustLevels" />
                        </Col>
                    </Row>

                    <Row className="details-label">
                        <Col span={8}>
                            <label>{getTranslation('Member Level')}</label>
                        </Col>
                        <Col span={13}>
                            <CheckboxInput options={MEMBER_LEVELS} name="memberLevels" />
                        </Col>
                    </Row>

                    <Row className="details-label">
                        <Col span={8}>
                            <label>{getTranslation('Currency')}</label>
                        </Col>
                        <Col span={13}>
                            <span>{accountDetails.currencyCode}</span>
                        </Col>
                    </Row>

                    <Row className="details-label">
                        <Col span={8}>
                            <label>{getTranslation('Max Credit Amount Per Day')}</label>
                        </Col>
                        <Col span={13}>
                            <Form.Item name="dailyMaximumAmount">
                                <InputNumber
                                    formatter={(value) =>
                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    }
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    min={0}
                                    max={999999999999}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className="details-label">
                        <Col span={8}>
                            <label>{getTranslation('Special Member')}</label>
                        </Col>
                        <Col span={13}>
                            <Form.Item name="specialPlayerNames">
                                <Input className="table__input" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className="details-label">
                        <Col span={8}>
                            <label>{getTranslation('Remark')}</label>
                        </Col>
                        <Col span={13}>
                            <Form.Item name="remarks">
                                <TextArea className="table__input" rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>

                <div className="bank-account-configuration-web-show__row">
                    <Row className="details-label">
                        <Col span={8}>
                            <label>{getTranslation('Web Show')}</label>
                        </Col>
                        <Col span={13}>
                            <Form.Item name="isWebShow">
                                <Radio.Group style={{ display: 'flex', flexFlow: 'row wrap' }}>
                                    {_.map(PAYMENT_GATEWAY_WEB_SHOW, (value, index) => {
                                        return (
                                            <Col span={12} key={value}>
                                                <Radio value={value}>{index}</Radio>
                                            </Col>
                                        );
                                    })}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
        </Modal>
    );
};

export default ConfigurationModal;
