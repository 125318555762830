import _ from 'lodash';

import { ACTIONS } from './createBannerActions';
import { getBannerDefaultContent } from '../helpers';

export default function bannerReducer(
    createBanner = {
        details: getBannerDefaultContent(),
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.BANNER_LANGUAGE_SET:
        case ACTIONS.BANNER_LANGUAGE_DROP:
            return {
                ...createBanner,
                details: payload,
            };

        case ACTIONS.SET_BANNER_INFO_BY_LANGUAGE: {
            _.map(createBanner.details, (value) => {
                if (value.languageCode === payload.langCode) {
                    value[payload.name] = payload.value;
                }
                return value;
            });

            return {
                ...createBanner,
                details: [...createBanner.details],
            };
        }
        case ACTIONS.SET_BANNER_INFO:
            return {
                ...createBanner,
                [payload.name]: payload.value,
            };

        case ACTIONS.BANNER_FORM_CLEAR:
            return {
                details: getBannerDefaultContent(),
            };

        case ACTIONS.BANNER_DUPLICATE:
            return payload;

        case ACTIONS.BANNER_DUPLICATE_BY_LANGUAGE:
            return {
                ...createBanner,
                details: payload,
            };

        default:
            return createBanner;
    }
}
