import React from 'react';

import { Input, Select } from 'antd';
import _ from 'lodash';

const InputGroupFilter = ({ placeholder, onFilterChange, name, value, optionList }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value, title) => {
        onFilterChange(name, { [title]: value });
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Input.Group compact className="filter">
            <Select
                onChange={(value) => handleFilterChange(value, 'condition')}
                value={value && value.condition}
            >
                {_.map(optionList, (list) => (
                    <Select.Option value={list.value} key={list.value}>
                        {list.label}
                    </Select.Option>
                ))}
            </Select>
            <Input
                style={{ width: '164px' }}
                placeholder={placeholder}
                onChange={(e) => handleFilterChange(e.target.value, 'value')}
                value={value && value.value}
            />
        </Input.Group>
    );
};

export default InputGroupFilter;
