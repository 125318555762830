import React from 'react';

import { Col, Modal, Row } from 'antd';

import { ButtonGray } from './buttons/Buttons';
import { getTranslation } from '../helpers/locale';

const RemarkModal = ({ visible, onCancel, applicantRemark, auditorRemark, operatorRemark }) => {
    return (
        <Modal
            title={getTranslation('Remarks')}
            centered
            visible={visible}
            closable={false}
            onCancel={onCancel}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
        >
            {auditorRemark ? (
                <Row>
                    <Col span={10}>
                        <label>{getTranslation('Auditor Remark')}</label>
                    </Col>
                    <Col span={10}>{auditorRemark}</Col>
                </Row>
            ) : (
                <Row>
                    <Col span={10}>
                        <label>{getTranslation('Applicant Remark')}</label>
                    </Col>
                    <Col span={10}>{applicantRemark}</Col>
                </Row>
            )}

            <Row>
                <Col span={10}>
                    <label>{getTranslation('Operator Remark')}</label>
                </Col>
                <Col span={10}>{operatorRemark}</Col>
            </Row>
        </Modal>
    );
};

export default RemarkModal;
