import React from 'react';

import { Table } from 'antd';

import { AFFILIATE_TABLE_COLUMNS } from '../constants';
import { getColumns, getPaginationOptions } from './AffiliateVerificationMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const AffiliateVerificationTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const { verification = {} } = getStateProp(state, 'affiliate');
    const tableData = getStateProp(verification, 'list', []);
    const paging = getStateProp(verification, 'paging', {});
    const selectedColumns = getStateProp(verification, 'selectedColumns', AFFILIATE_TABLE_COLUMNS);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns(dispatch, selectedColumns)}
            pagination={getPaginationOptions(paging, dispatch)}
            dataSource={tableData}
            rowKey={(record) => record.transactionNo}
            loading={loading}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
        />
    );
};

export default AffiliateVerificationTable;
