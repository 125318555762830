import React from 'react';

import { Table } from 'antd';

import {
    getColumns,
    getPaginationOptions,
    tableBodyWrapper,
} from './BankAccountStatementTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const BankAccountStatementResultTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const bankAccountStatement = getStateProp(state, 'payments.bankAccountStatement');
    const tableData = getStateProp(bankAccountStatement, 'list', []);
    const paging = getStateProp(bankAccountStatement, 'paging', {});

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            scroll={{ x: true }}
            bordered
            size="small"
            align="left"
            loading={loading}
            columns={getColumns()}
            pagination={getPaginationOptions(paging, dispatch)}
            dataSource={tableData}
            rowKey={(record) => record.transactionNo}
            components={{
                body: { wrapper: tableBodyWrapper },
            }}
        />
    );
};

export default BankAccountStatementResultTable;
