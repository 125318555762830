import _ from 'lodash';

import { ACTIONS } from './rebateManagementActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function rebateManagementReducer(
    rebateManagement = {
        editModalVisible: false,
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        resetPageNo: false,
        selectedRebate: {},
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.REBATE_MANAGEMENT_SELECTED:
            return {
                ...rebateManagement,
                selectedRebate: payload,
            };

        case ACTIONS.REBATE_MANAGEMENT_EDIT_MODAL_OPEN:
            return {
                ...rebateManagement,
                editModalVisible: true,
            };

        case ACTIONS.REBATE_MANAGEMENT_EDIT_MODAL_CLOSE:
            return {
                ...rebateManagement,
                editModalVisible: false,
                selectedRebate: {},
            };

        case ACTIONS.REBATE_MANAGEMENT_INFO_UPDATE:
            return {
                ...rebateManagement,
                selectedRebate: {
                    ...rebateManagement.selectedRebate,
                    [payload.name]: payload.value,
                },
            };

        case ACTIONS.REBATE_MANAGEMENT_INFO_UPDATE_BY_LANGUAGE: {
            _.map(rebateManagement.selectedRebate.details, (value) => {
                if (value.languageCode === payload.langCode) {
                    value[payload.name] = payload.value;
                }
                return value;
            });
            return {
                ...rebateManagement,
                selectedRebate: {
                    ...rebateManagement.selectedRebate,
                    details: [...rebateManagement.selectedRebate.details],
                },
            };
        }

        case ACTIONS.REBATE_MANAGEMENT_FILTER_SET:
            return {
                ...rebateManagement,
                filters: {
                    ...rebateManagement.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };
        case ACTIONS.REBATE_MANAGEMENT_FILTERS_DROP_ALL:
            return {
                ...rebateManagement,
                filters: null,
                resetPageNo: true,
            };

        case ACTIONS.REBATE_MANAGEMENT_LANGUAGE_DROP:
        case ACTIONS.REBATE_MANAGEMENT_LANGUAGE_SET:
            return {
                ...rebateManagement,
                selectedRebate: { ...rebateManagement.selectedRebate, details: payload },
            };

        case ACTIONS.PAGINATION.REBATE_MANAGEMENT_TABLE_PAGE_CHANGE:
            return {
                ...rebateManagement,
                paging: {
                    ...rebateManagement.paging,
                    pageNo:
                        payload > rebateManagement.paging.pageCount
                            ? rebateManagement.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.REBATE_MANAGEMENT_TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...rebateManagement,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.REBATE_MANAGEMENT_GET_SUCCESS:
            return {
                ...rebateManagement,
                ...payload,
            };

        case ACTIONS.REBATE_MANAGEMENT_GET_BY_ID_SUCCESS: {
            return {
                ...rebateManagement,
                selectedRebate: {
                    ...payload,
                },
            };
        }

        case ACTIONS.REBATE_MANAGEMENT_DUPLICATE_BY_LANGUAGE:
            return {
                ...rebateManagement,
                selectedRebate: {
                    ...rebateManagement.selectedRebate,
                    details: payload,
                },
            };

        case ACTIONS.REBATE_MANAGEMENT_GET:
            const pageNo = rebateManagement.resetPageNo
                ? TABLE_PAGE_NO_DEFAULT
                : rebateManagement.paging.pageNo;
            return {
                ...rebateManagement,
                paging: {
                    ...rebateManagement.paging,
                    pageNo: pageNo,
                },
                resetPageNo: false,
            };

        case ACTIONS.REBATE_MANAGEMENT_MULTI_INFO_UPDATE:
            return {
                ...rebateManagement,
                selectedRebate: {
                    ...rebateManagement.selectedRebate,
                    ...payload,
                },
            };

        default:
            return rebateManagement;
    }
}
