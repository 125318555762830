import rebateFilterReducer from './shared/rebateFilterReducer';
import rebateGeneratorReducer from './generator/rebateGeneratorReducer';
import rebateManagementReducer from './management/rebateManagementReducer';
import rebateSettingReducer from './setting/rebateSettingReducer';

export default function rebateReducer(rebate = {}, action) {
    const { rebateSetting, rebateFilterOptions, rebateManagement, rebateGenerator } = rebate;

    return {
        rebateFilterOptions: rebateFilterReducer(rebateFilterOptions, action),
        rebateGenerator: rebateGeneratorReducer(rebateGenerator, action),
        rebateManagement: rebateManagementReducer(rebateManagement, action),
        rebateSetting: rebateSettingReducer(rebateSetting, action),
    };
}
