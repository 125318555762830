import { useHistory } from 'react-router-dom';
import React from 'react';

import { Col, Row } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { ButtonRed, ButtonYellow } from '../../../../common/components/buttons/Buttons';
import { ROUTES } from '../../../../common/constants/routing';
import { getTranslation } from '../../../../common/helpers/locale';
import { openGroupAccessRecordDeleteModal } from '../groupAuthoritySettingActions';
import { useDispatch } from '../../../../store/StateProvider';

const RowActionButtons = ({ record }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ============================================

    const openEditModal = () => {
        history.push(
            `${ROUTES.DASHBOARD.ADMINISTRATION.GROUP_AUTHORITY.ROOT}/${record.accessGroupId}`,
        );
    };

    const openDeleteModal = () => openGroupAccessRecordDeleteModal(dispatch, record.accessGroupId);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row span={7} align="middle" className="row-buttons__container">
            <Col>
                <ButtonYellow
                    type="primary"
                    className="rowaction-button"
                    disabled={record.activatedStatus === 'Deactivated'}
                    onClick={openEditModal}
                    icon={<EditOutlined />}
                >
                    {getTranslation('Edit')}
                </ButtonYellow>
            </Col>
            <Col>
                <ButtonRed
                    type="primary"
                    className="rowaction-button"
                    onClick={openDeleteModal}
                    icon={<DeleteOutlined />}
                >
                    {getTranslation('Delete')}
                </ButtonRed>
            </Col>
        </Row>
    );
};

export default RowActionButtons;
