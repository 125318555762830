import { Redirect, Switch } from 'react-router-dom';
import React from 'react';

import { ROUTES } from '../../common/constants/routing';

import {
    AnnouncementCreate,
    AnnouncementHistory,
    AnnouncementVerification,
    MessageCreate,
} from '.';
import PrivateRoute from '../../common/components/PrivateRoute';

const Announcement = () => {
    return (
        <Switch>
            <PrivateRoute
                path={ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.ANNOUNCEMENT_CREATE}
                component={AnnouncementCreate}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.ANNOUNCEMENT_VERIFICATION.ROOT}
                component={AnnouncementVerification}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.MESSAGE_CREATE}
                component={MessageCreate}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.ANNOUNCEMENT_HISTORY.ROOT}
                component={AnnouncementHistory}
            />
            <Redirect to={ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.ROOT} />
        </Switch>
    );
};

export default Announcement;
