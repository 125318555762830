import React from 'react';

import { Card, Form } from 'antd';

import { dropFilters } from './referralReportActions';
import { getTranslation } from '../../../common/helpers/locale';
import { useDispatch } from '../../../store/StateProvider';
import { useGetReferralReportAsyncEndpoint } from '../../../services/referralService';
import ActionButtons from './ActionButtons';
import ReferralReportFilters from './ReferralReportFilters';
import ReferralReportResultsTable from './table/ReferralReportResultsTable';

const ReferralReport = () => {
    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetReferralReport();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetReferralReport] = useGetReferralReportAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Referral Report')}>
            <Form onFinish={handleGetReferralReport}>
                <ReferralReportFilters />
                <ActionButtons fetchAll={fetchAll} />
            </Form>

            <ReferralReportResultsTable loading={loading} />
        </Card>
    );
};

export default ReferralReport;
