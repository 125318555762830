import React from 'react';

import { Button, Input, Popover, Row, Tabs } from 'antd';
import _ from 'lodash';

import { Editor } from '../../../common/components';
import { getLanguageByCode, getTranslation } from '../../../common/helpers/locale';
import LanguagesCheckboxes from '../../../common/components/LanguagesCheckboxes';

const { TabPane } = Tabs;

const AnnouncementTabs = ({
    onContentChanged,
    currency,
    Content,
    setAnnouncementLang,
    ...rest
}) => {
    // ============================================
    // METHODS
    // ============================================

    const onInputChange = (e, langCode, currency) =>
        onContentChanged('title', e.target.value, langCode, currency);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Tabs className="announcement__currency-tabs" tabPosition="left">
            {_.map(currency, (currency) => (
                <TabPane key={currency} tab={currency} className="announcement-content__tabs">
                    <Tabs
                        className="announcement__content-tabs"
                        tabBarExtraContent={
                            <Row className="form__group">
                                <Popover
                                    placement="bottom"
                                    title={getTranslation('Select Languages')}
                                    content={
                                        <LanguagesCheckboxes
                                            onCheckedLanguages={(e) =>
                                                setAnnouncementLang(e, currency)
                                            }
                                            value={_.compact(
                                                _.map(Content, (item) => {
                                                    return (
                                                        item.currency === currency &&
                                                        item.languageCode
                                                    );
                                                }),
                                            )}
                                        />
                                    }
                                    trigger="click"
                                >
                                    <Button className="popover-language-select-button">
                                        {getTranslation('Select Languages')}
                                    </Button>
                                </Popover>
                            </Row>
                        }
                    >
                        {_.map(
                            _.filter(Content, (content) => {
                                return content.currency === currency;
                            }),
                            (item) => (
                                <Tabs.TabPane
                                    key={item.languageCode}
                                    tab={getLanguageByCode(item.languageCode)}
                                    {...rest}
                                    className="announcement-content__tabs"
                                >
                                    <Input
                                        placeholder="Title"
                                        onChange={(e) =>
                                            onInputChange(e, item.languageCode, currency)
                                        }
                                        value={item.title}
                                        className="announcement-content__title"
                                    />

                                    <Editor
                                        onBlur={(event) =>
                                            onContentChanged(
                                                'content',
                                                event.target.getContent(),
                                                item.languageCode,
                                                currency,
                                            )
                                        }
                                        value={item.content}
                                    />
                                </Tabs.TabPane>
                            ),
                        )}
                    </Tabs>
                </TabPane>
            ))}
        </Tabs>
    );
};

export default AnnouncementTabs;
