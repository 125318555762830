import React from 'react';

import { RenderDateFormat } from '../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../common/constants/api';
import { changePageNumber, changePageSize, selectRecord } from '../hotEventsAction';
import { getTranslation } from '../../../common/helpers/locale';
import ColumnActionButton from './operations/ColumnActionButton';

export const getColumns = (dispatch) => {
    const columns = [
        {
            dataIndex: 'id',
            title: getTranslation('id'),
        },
        {
            dataIndex: 'gameCode',
            dataType: 'string',
            title: getTranslation('Game Code'),
        },
        {
            dataIndex: 'sourceType',
            title: getTranslation('Source Type'),
        },
        {
            dataIndex: 'startTime',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Start Time'),
        },
        {
            dataIndex: 'groupName',
            title: getTranslation('Group Name'),
        },
        {
            dataIndex: 'leagueId',
            title: getTranslation('League Id'),
        },
        {
            dataIndex: 'leagueName',
            title: getTranslation('League Name'),
        },
        {
            dataIndex: 'homeId',
            title: getTranslation('Home Id'),
        },
        {
            dataIndex: 'homeName',
            title: getTranslation('Home Name'),
        },
        {
            dataIndex: 'awayId',
            title: getTranslation('Away Id'),
        },
        {
            dataIndex: 'awayName',
            title: getTranslation('Away Name'),
        },
        {
            dataIndex: 'status',
            title: getTranslation('Status'),
        },
        {
            dataIndex: 'action',
            render: (value, record) => <ColumnActionButton chatRoomInfo={record} />,
            title: getTranslation('Action'),
        },
    ];

    return columns;
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

export const getRowSelectionProps = (dispatch, selectedEventIds, tableData) => ({
    columnWidth: 30,
    fixed: true,
    getCheckboxProps: (record) => ({
        disabled: record.isHotEvent,
    }),
    onChange: onSelectRecord(dispatch, tableData),
    selectedRowKeys: selectedEventIds.map((data) => data.eventId),
});

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);

const onSelectRecord = (dispatch, tableData) => (rowKeys) => {
    const getEventData = rowKeys.map((ids) => {
        const getEvent = tableData.find((data) => ids === data.id);
        return { provider: getEvent?.sourceType, eventId: ids };
    });
    selectRecord(dispatch, getEventData);
};
