import { CURRENCIES } from '../../../../common/constants/localization';
import { VIP_LEVELS } from '../../constants';
import _ from 'lodash';

export const validateVipDatas = (vipData) => {
    if (!_.isEmpty(vipData) && !_.isEmpty(vipData.loginBonusDetails)) {
        if (vipData.loginBonusDetails.length !== 60) {
            let newVip = vipData;
            _.forEach(CURRENCIES, (currency) => {
                _.forEach(VIP_LEVELS, (vip) => {
                    if (
                        !_.find(vipData.loginBonusDetails, {
                            currencyCode: currency.currency,
                            vipLevel: vip.level,
                        })
                    ) {
                        newVip.loginBonusDetails.push({
                            currencyCode: currency.currency,
                            loginRewards: _.times(8, (i) => {
                                return { day: i + 1, etoAmount: 0, rewardAmount: 0 };
                            }),
                            vipLevel: vip.level,
                        });
                    }
                });
            });
            return newVip;
        }
    }
};
