import React from 'react';

import { Row } from 'antd';

import { DomainFilter, MerchantFilter, MerchantIDFilter } from '../filters';
import { getStateProp } from '../../../common/helpers/misc';

const MerchantSetupFilters = ({ setFilter, merchant }) => {
    return (
        <Row justify="start" align="middle" className="filters-container">
            <MerchantFilter
                onFilterChange={setFilter}
                value={getStateProp(merchant, 'filters.gatewayName')}
            />
            <MerchantIDFilter
                onFilterChange={setFilter}
                value={getStateProp(merchant, 'filters.merchantId')}
            />
            <DomainFilter
                onFilterChange={setFilter}
                value={getStateProp(merchant, 'filters.merchantDomain')}
            />
        </Row>
    );
};

export default MerchantSetupFilters;
