import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions, getRowSelectionProps } from './FileSystemTableMeta';
import { getStateProp } from '../../../common/helpers/misc';
import { useStore } from '../../../store/StateProvider';

const FileSystemResultTable = ({ loading }) => {
    const [state, dispatch] = useStore();
    const tableData = getStateProp(state, 'fileSystem.list', []);
    const selectedFiles = getStateProp(state, 'fileSystem.selectedFiles', []);
    const { paging } = getStateProp(state, 'fileSystem');
    console.log('selectedFiles', selectedFiles);
    return (
        <Table
            columns={getColumns()}
            rowKey={(record) => record.id}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            dataSource={tableData}
            loading={loading}
            rowSelection={getRowSelectionProps(dispatch, selectedFiles)}
            pagination={getPaginationOptions(paging, dispatch)}
        />
    );
};

export default FileSystemResultTable;
