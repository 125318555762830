export const ACTIONS = {
    COLUMNS_SELECT: 'TRANSACTION_MONITORING_COLUMNS_SELECT',
    FILTERS_DROP_ALL: 'TRANSACTION_MONITORING_FILTERS_DROP_ALL',
    FILTER_SET: 'TRANSACTION_MONITORING_FILTER_SET',
    OPERATION: 'TRANSACTION_MONITORING_OPERATION',
    OPERATION_FAILURE: 'TRANSACTION_MONITORING_OPERATION_FAILURE',
    OPERATION_SUCCESS: 'TRANSACTION_MONITORING_OPERATION_SUCCESS',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'TRANSACTION_MONITORING_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'TRANSACTION_MONITORING_TABLE_PAGE_SIZE_CHANGE',
    },
    REFRESH_TIME_PAUSE: 'TRANSACTION_MONITORING_REFRESH_TABLE_TIME_PAUSE',
    REFRESH_TIME_SET: 'TRANSACTION_MONITORING_REFRESH_TABLE_TIME_SET',
    REMARK_MODAL_CLOSE: 'TRANSACTION_MONITORING_REMARK_MODAL_CLOSE',
    REMARK_MODAL_OPEN: 'TRANSACTION_MONITORING_REMARK_MODAL_OPEN',
    TRANSACTION_MONITORING_GET: 'TRANSACTION_MONITORING_GET',
    TRANSACTION_MONITORING_GET_FAILURE: 'TRANSACTION_MONITORING_GET_FAILURE',
    TRANSACTION_MONITORING_GET_SUCCESS: 'TRANSACTION_MONITORING_GET_SUCCESS',
    VERIFY_INFO_SET: 'TRANSACTION_MONITORING_VERIFY_INFO_SET',
    VERIFY_MODAL_CLOSE: 'TRANSACTION_MONITORING_VERIFY_MODAL_CLOSE',
    VERIFY_MODAL_OPEN: 'TRANSACTION_MONITORING_VERIFY_MODAL_OPEN',
};

export const transactionMonitoringGet = (dispatch) =>
    dispatch({
        type: ACTIONS.TRANSACTION_MONITORING_GET,
    });

export const transactionMonitoringGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TRANSACTION_MONITORING_GET_SUCCESS,
    });

export const transactionMonitoringGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TRANSACTION_MONITORING_GET_FAILURE,
    });

export const openVerifyModal = (dispatch) =>
    dispatch({
        type: ACTIONS.VERIFY_MODAL_OPEN,
    });

export const closeVerifyModal = (dispatch) =>
    dispatch({
        type: ACTIONS.VERIFY_MODAL_CLOSE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const transactionMonitoringFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const performTransactionMonitoringOperation = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OPERATION,
    });

export const transactionMonitoringOperationSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.OPERATION_SUCCESS,
    });

export const transactionMonitoringOperationFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OPERATION_FAILURE,
    });

export const setTransactionMonitoringVerifyInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.VERIFY_INFO_SET,
    });

export const setRefreshTableTime = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.REFRESH_TIME_SET,
    });
};

export const pauseRefreshTableTime = (dispatch) => {
    dispatch({
        type: ACTIONS.REFRESH_TIME_PAUSE,
    });
};

export const selectColumns = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.COLUMNS_SELECT,
    });

export const onRemarkModalOpen = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REMARK_MODAL_OPEN,
    });

export const onRemarkModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.REMARK_MODAL_CLOSE,
    });
