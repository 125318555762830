import React from 'react';

import { Button, Popover, Row } from 'antd';

import './BonusTabs.less';
import { getTranslation } from '../../../common/helpers/locale';
import BonusFormInputs from './BonusFormInputs';
import DuplicateTab from './DuplicateTab';
import LanguagesCheckboxes from '../../../common/components/LanguagesCheckboxes';

const BonusTabs = ({
    setBonusLang,
    selectedLangs,
    onContentChange,
    onBonusInfoChange,
    onDuplicateBonus,
    bonus,
    isEdit,
    categoryListLoading,
    viewOnly,
    currentStep,
    platforms,
    platformsArrary,
    products,
    productsArrary,
}) => {
    const { details, ...rest } = bonus;

    // ============================================
    // RENDER
    // ============================================

    const TabsExtraContent = (
        <Row>
            <Popover
                placement="bottom"
                title={getTranslation('Duplicate')}
                content={<DuplicateTab details={details} onDuplicateContent={onDuplicateBonus} />}
                trigger="click"
            >
                <Button style={{ marginRight: '5px' }}>{getTranslation('Duplicate')}</Button>
            </Popover>

            <Popover
                placement="bottom"
                title={getTranslation('Select Languages')}
                content={
                    <LanguagesCheckboxes
                        onCheckedLanguages={setBonusLang}
                        value={selectedLangs}
                        allowAllLanguage={false}
                    />
                }
                trigger="click"
            >
                <Button>{getTranslation('Select Languages')}</Button>
            </Popover>
        </Row>
    );

    return (
        <BonusFormInputs
            onContentChange={onContentChange}
            onBonusInfoChange={onBonusInfoChange}
            bonusInfo={rest}
            details={details}
            isEdit={isEdit}
            categoryListLoading={categoryListLoading}
            viewOnly={viewOnly}
            currentStep={currentStep}
            selectedLangs={selectedLangs}
            platforms={platforms}
            platformsArrary={platformsArrary}
            products={products}
            productsArrary={productsArrary}
            TabsExtraContent={TabsExtraContent}
        />
    );
};

export default BonusTabs;
