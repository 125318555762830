import React from 'react';

import { ButtonGray } from '../../../../../common/components/buttons/Buttons';
import { RenderDateFormat } from '../../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS_1000 } from '../../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../cryptoDepositRecordActions';
import { getStatusColor, sortTableByDataType } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';

export const getColumns = (tableWidth) => {
    const columns = [
        {
            dataIndex: 'createdAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Request Time'),
            width: 100,
        },
        {
            dataIndex: 'playerUserName',
            title: getTranslation('Username'),
            width: 120,
        },
        {
            dataIndex: 'transactionNo',
            title: getTranslation('Task Transaction Number'),
            width: 230,
        },
        {
            dataIndex: 'transactionRef',
            title: getTranslation('GGPay Transaction Number'),
            width: 150,
        },
        {
            dataIndex: 'affiliate',
            title: getTranslation('Affiliate'),
            width: 80,
        },
        {
            dataIndex: 'referral',
            title: getTranslation('Referral'),
            width: 80,
        },
        {
            dataIndex: 'cryptoCode',
            title: getTranslation('Crypto Currency'),
            width: 80,
        },
        {
            dataIndex: 'cryptoAmt',
            title: getTranslation('Deposit Crypto Amount'),
            width: 100,
        },
        {
            dataIndex: 'currencyCode',
            title: getTranslation('Currency'),
            width: 80,
        },
        {
            dataIndex: 'cryptoRate',
            render: (value) => <span>{value.toFixed(15).replace(/\.?0+$/, '')}</span>,
            title: getTranslation('Exchange Rate'),
            width: 80,
        },
        {
            dataIndex: 'amount',
            title: getTranslation('Deposit Amount'),
            width: 80,
        },
        {
            dataIndex: 'cryptoAddr',
            title: getTranslation('Receiving Wallet Address'),
            width: 250,
        },
        {
            dataIndex: 'verifiedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Process Time'),
            width: 100,
        },
        {
            dataIndex: 'transactionStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>
                    {getTranslation(value).toLocaleUpperCase()}
                </span>
            ),
            title: getTranslation('Transaction Status'),
            width: 90,
        },
        {
            dataIndex: 'orderStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>
                    {getTranslation(value).toLocaleUpperCase()}
                </span>
            ),
            title: getTranslation('Deposit Status'),
            width: 80,
        },
        {
            dataIndex: 'verifierRealName',
            render: (value) => <ButtonGray disabled label={value} className="action-btn__verify" />,
            title: getTranslation('Operator'),
            width: tableWidth <= 1900 && 165,
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS_1000,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
