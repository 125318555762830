import React from 'react';

import { Table } from 'antd';
import { getColumns, getPaginationOptions } from './PlayerWinLossDetailTableMeta';
import { getStateProp } from '../../../../../common/helpers/misc';
import { useStore } from '../../../../../store/StateProvider';

const PlayerWinLossDetailTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const winLossDetail = getStateProp(state, 'reports.playerWinLoss.winLossDetail', {});
    const paging = getStateProp(winLossDetail, 'paging', {});
    const tableData = getStateProp(winLossDetail, 'list', []);
    const timezone = getStateProp(state, 'timezone');

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns(timezone)}
            pagination={getPaginationOptions(paging, dispatch)}
            dataSource={tableData}
            rowKey={(value, index) => index}
            loading={loading}
            size="small"
            align="left"
            bordered
            scroll={{ x: true }}
        />
    );
};

export default PlayerWinLossDetailTable;
