import React from 'react';

import { Col, Input, Row } from 'antd';

import './BetListSummary.less';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';

const BetListSummary = () => {
    const [state] = useStore();
    const betListSummary = getStateProp(state, 'bet.betList.summary', {});

    const {
        totalTurnover,
        totalStake,
        totalHoldStake,
        totalPaidStake,
        totalEffectiveTurnover,
        totalWinLoss,
    } = betListSummary;

    return (
        <>
            <Row align="middle" justify="end" className="bet-list-summary">
                <Col span={3}>
                    <span className="summary-label">{getTranslation('Total Hold Stake')}</span>
                </Col>
                <Col span={5}>
                    <Input disabled className="input-summary" value={totalHoldStake} />
                </Col>
                <Col span={3}>
                    <span className="summary-label">{getTranslation('Total Turnover')}</span>
                </Col>
                <Col span={5}>
                    <Input disabled className="input-summary" value={totalTurnover} />
                </Col>
                <Col span={3}>
                    <span className="summary-label">{getTranslation('Total Paid Stake')}</span>
                </Col>
                <Col span={5}>
                    <Input disabled className="input-summary" value={totalPaidStake} />
                </Col>
            </Row>
            <Row align="middle" justify="end" className="bet-list-summary">
                <Col span={3}>
                    <span className="summary-label">{getTranslation('Total Stake')}</span>
                </Col>
                <Col span={5}>
                    <Input disabled className="input-summary" value={totalStake} />
                </Col>
                <Col span={3}>
                    <span className="summary-label">
                        {getTranslation('Total Effective Turnover')}
                    </span>
                </Col>
                <Col span={5}>
                    <Input disabled className="input-summary" value={totalEffectiveTurnover} />
                </Col>
                <Col span={3}>
                    <span className="summary-label">{getTranslation('WinLoss')}</span>
                </Col>
                <Col span={5}>
                    <Input disabled className="input-summary" value={totalWinLoss} />
                </Col>
            </Row>
        </>
    );
};

export default BetListSummary;
