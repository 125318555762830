import React from 'react';

import { DepositTypeFilter } from '../filters';
import { Row } from 'antd';

import {
    CurrencyFilter,
    OrderStatusFilter,
    PeriodFilter,
    TransactionMethodFilter,
    TransactionNumberFilter,
    TransactionStatusFilter,
    UsernameFilter,
} from '../../../common/components/filters';
import { depositRecordsFilterSet } from './depositRecordActions';
import { getStateProp } from '../../../common/helpers/misc';
import { useStore } from '../../../store/StateProvider';

const DepositRecordFilters = () => {
    const [state, dispatch] = useStore();

    const { depositRecords } = getStateProp(state, 'transactionHistory');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => depositRecordsFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(depositRecords, 'filters.playerUserName')}
                name="playerUserName"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(depositRecords, 'filters.requestedDateFrom')}
                dateFromName="requestedDateFrom"
                dateTo={getStateProp(depositRecords, 'filters.requestedDateTo')}
                dateToName="requestedDateTo"
            />
            <TransactionNumberFilter
                onFilterChange={setFilter}
                value={getStateProp(depositRecords, 'filters.transactionNo')}
                name="transactionNo"
            />
            <DepositTypeFilter
                onFilterChange={setFilter}
                value={getStateProp(depositRecords, 'filters.depositTypes')}
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(depositRecords, 'filters.currencyCode')}
                name="currencyCode"
            />
            <TransactionStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(depositRecords, 'filters.transactionStatuses')}
            />
            <TransactionMethodFilter
                onFilterChange={setFilter}
                value={getStateProp(depositRecords, 'filters.transactionMethods')}
            />
            <OrderStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(depositRecords, 'filters.orderStatuses')}
                name="orderStatuses"
            />
        </Row>
    );
};

export default DepositRecordFilters;
