import { ACTIONS } from './rebateFilterActions';

export default function rebateFilterReducer(rebateFilterOptions = {}, { type, payload = null }) {
    switch (type) {
        case ACTIONS.REBATE_FILTER_OPTIONS_GET_SUCCESS:
            return {
                ...payload,
            };

        case ACTIONS.REBATE_FILTER_OPTIONS_GET:
        default:
            return rebateFilterOptions;
    }
}
