export const ACTIONS = {
    FILTER_DROP: 'MESSAGE_HISTORY_FILTER_DROP',
    FILTER_SET: 'MESSAGE_HISTORY_FILTER_SET',
    MESSAGE_DELETE_MODAL_CLOSE: 'MESSAGE_HISTORY_DELETE_MODAL_CLOSE',
    MESSAGE_DELETE_MODAL_OPEN: 'MESSAGE_HISTORY_DELETE_MODAL_OPEN',
    MESSAGE_DESELECTED: 'MESSAGE_HISTORY_DESELECTED',
    MESSAGE_GET: 'MESSAGE_HISTORY_GET',
    MESSAGE_GET_FAILURE: 'MESSAGE_HISTORY_GET_FAILURE',
    MESSAGE_GET_SUCCESS: 'MESSAGE_HISTORY_GET_SUCCESS',
    MESSAGE_SELECTED: 'MESSAGE_HISTORY_SELECTED',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'MESSAGE_HISTORY_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'MESSAGE_HISTORY_TABLE_PAGE_SIZE_CHANGE',
    },
};

//#region Message Delete
export const openMessageDeleteModal = (dispatch, payload) => {
    dispatch({ payload, type: ACTIONS.MESSAGE_DELETE_MODAL_OPEN });
};
export const closeMessageDeleteModal = (dispatch) => {
    dispatch({ type: ACTIONS.MESSAGE_DELETE_MODAL_CLOSE });
};
//#endregion

//#region Message Verify
export const setMessageSelected = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MESSAGE_SELECTED,
    });
export const setMessageDeselected = (dispatch) =>
    dispatch({
        type: ACTIONS.MESSAGE_DESELECTED,
    });
//#endregion

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const messageHistoryGet = (dispatch) =>
    dispatch({
        type: ACTIONS.MESSAGE_GET,
    });

export const messageHistoryGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MESSAGE_GET_FAILURE,
    });

export const messageHistoryGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MESSAGE_GET_SUCCESS,
    });

export const messageFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const messageFilterDrop = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTER_DROP,
    });
