export const ACTIONS = {
    ACCEPT_RECIPIENTS: 'MESSAGE_ACCEPT_RECIPIENTS',
    DROP_RECIPIENTS: 'MESSAGE_DROP_RECIPIENTS',
    MESSAGE_ADD: 'MESSAGE_ADD',
    MESSAGE_ADD_FAILURE: 'MESSAGE_ADD_FAILURE',
    MESSAGE_ADD_SUCCESS: 'MESSAGE_ADD_SUCCESS',
    MESSAGE_DRAFT_INFO_SET: 'MESSAGE_DRAFT_INFO_SET',
    MESSAGE_DRAFT_RESET: 'MESSAGE_DRAFT_RESET',
    MESSAGE_RECIPIENTS_SET: 'MESSAGE_RECIPIENTS_SET',
    MESSAGE_USERNAME_VERIFY_MODAL_CLOSE: 'MESSAGE_USERNAME_VERIFY_MODAL_CLOSE',
    MESSAGE_USERNAME_VERIFY_MODAL_OPEN: 'MESSAGE_USERNAME_VERIFY_MODAL_OPEN',
    MESSAGE_VERIFY_USERNAMES: 'MESSAGE_VERIFY_USERNAMES',
    MESSAGE_VERIFY_USERNAMES_FAILURE: 'MESSAGE_VERIFY_USERNAMES_FAILURE',
    MESSAGE_VERIFY_USERNAMES_SUCCESS: 'MESSAGE_VERIFY_USERNAMES_SUCCESS',
};

export const setMessageDraftInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MESSAGE_DRAFT_INFO_SET,
    });

export const resetMessageDraftInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MESSAGE_DRAFT_RESET,
    });

export const openVerifyModal = (dispatch) =>
    dispatch({
        type: ACTIONS.MESSAGE_USERNAME_VERIFY_MODAL_OPEN,
    });

export const closeVerifyModal = (dispatch) =>
    dispatch({
        type: ACTIONS.MESSAGE_USERNAME_VERIFY_MODAL_CLOSE,
    });

export const setRecipients = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MESSAGE_RECIPIENTS_SET,
    });

export const verifyRecipient = (dispatch) =>
    dispatch({
        type: ACTIONS.MESSAGE_VERIFY_USERNAMES,
    });

export const verifyRecipientSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MESSAGE_VERIFY_USERNAMES_SUCCESS,
    });

export const verifyRecipientFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MESSAGE_VERIFY_USERNAMES_FAILURE,
    });

export const addMessage = (dispatch) =>
    dispatch({
        type: ACTIONS.MESSAGE_ADD,
    });

export const addMessageSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.MESSAGE_ADD_SUCCESS,
    });

export const addMessageFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MESSAGE_ADD_FAILURE,
    });

export const accecptRecipientList = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ACCEPT_RECIPIENTS,
    });

export const dropRecipientList = (dispatch) =>
    dispatch({
        type: ACTIONS.DROP_RECIPIENTS,
    });
