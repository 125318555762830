import React from 'react';

import { RenderBettingDateFormat } from '../../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../../common/constants/api';
import { changeDetailsPageNumber, changeDetailsPageSize } from '../../playerWinLossActions';
import { getStatusColor, sortTableByDataType } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import { message } from 'antd';
import BetListRecordDetails from '../../../../bet/bet-list/table/BetListRecordDetails';

export const getColumns = (timezone) => {
    const columns = [
        {
            dataIndex: 'transNo',
            render: (value) => {
                return value !== null ? value : <span>-</span>;
            },
            title: getTranslation('Task Transaction Number'),
        },
        {
            dataIndex: 'refNo',
            render: (value) => {
                return value !== null ? value : <span>-</span>;
            },
            title: getTranslation('Ref No.'),
        },
        {
            dataIndex: 'statementDate',
            dataType: 'date',
            render: (date) => RenderBettingDateFormat({ date, hideTime: true, timezone }),
            title: getTranslation('Statement Date'),
        },
        {
            dataIndex: 'userName',
            title: getTranslation('Username'),
            width: 120,
        },
        {
            dataIndex: 'currency',
            title: getTranslation('Currency'),
            width: 80,
        },
        {
            dataIndex: 'betTime',
            dataType: 'date',
            render: (date) => RenderBettingDateFormat({ date, timezone }),
            title: getTranslation('Bet Time'),
            width: 100,
        },
        {
            dataIndex: 'platformDesc',
            title: getTranslation('Platform'),
        },
        {
            dataIndex: 'productDesc',
            title: getTranslation('Product'),
        },
        {
            dataIndex: 'detail',
            dataType: 'detail',
            render: (text, record) => (
                <BetListRecordDetails
                    record={record}
                    handleGetDetailUrl={() => {
                        message.warning('Please go to bet list to view the details');
                    }}
                />
            ),
            title: getTranslation('Detail'),
            width: 250,
        },
        {
            dataIndex: 'odds',
            render: (value) => <span>{value ? value : '-'}</span>,
            title: getTranslation('Odds'),
        },
        {
            dataIndex: 'oddsType',
            render: (value) => {
                return value !== null ? value : <span>-</span>;
            },
            title: getTranslation('Odd Type'),
        },
        {
            dataIndex: 'beforeStake',
            render: (value) => {
                return value !== null ? value : <span>-</span>;
            },
            title: getTranslation('Before Stake'),
        },
        {
            dataIndex: 'stake',
            render: (value) => {
                return value !== null ? value : <span>-</span>;
            },
            title: getTranslation('Stake'),
        },
        {
            dataIndex: 'effectiveTurnover',
            render: (value) => {
                return value !== null ? value : <span>-</span>;
            },
            title: getTranslation('Effective Turnover'),
        },
        {
            dataIndex: 'betStatus',
            render: (value) => <span>{value}</span>,
            title: getTranslation('Win Loss'),
        },
        {
            dataIndex: 'status',
            render: (value) => (
                <span
                    style={{
                        color: getStatusColor(value),
                    }}
                >
                    {value}
                </span>
            ),
            title: getTranslation('Status'),
        },
        {
            dataIndex: 'winLossAmount',
            title: getTranslation('Win Loss Amount'),
        },
        {
            dataIndex: 'paidStake',
            title: getTranslation('Paid Stake'),
        },
        {
            dataIndex: 'holdStake',
            render: (value) => {
                return value !== null ? value : <span>-</span>;
            },
            title: getTranslation('Hold Stake'),
        },
        {
            dataIndex: 'settlementDate',
            dataType: 'date',
            render: (date) => RenderBettingDateFormat({ date, timezone }),
            title: getTranslation('Paid Time'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changeDetailsPageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changeDetailsPageSize(dispatch, pageSize);
