import React, { useState } from 'react';

import { Form, Modal, Row } from 'antd';

import { ButtonGray, ButtonGreen } from '../../../../common/components/buttons/Buttons';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useUpdateDeactivatedMerchantAsyncEndpoint } from '../../../../services/merchantService';
import MerchantSetupForm from '../modal-form/MerchantSetupForm';

const MerchantSetupModal = ({ modalData, onCancel }) => {
    const [formValues, setFormValues] = useState({});
    const selectedMerchant = getStateProp(modalData, 'merchantDetails', {});

    // ============================================
    // METHODS
    // ============================================

    const submitForm = (values) => {
        setFormValues(values);
        handleUpdateDeactivatedMerchant();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [adding, handleUpdateDeactivatedMerchant] = useUpdateDeactivatedMerchantAsyncEndpoint(
        formValues,
    );

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={true}
            title={getTranslation('Edit Bank Card')}
            centered
            width={1000}
            closable={false}
            onCancel={onCancel}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        name="deactivated-merchant-setup"
                        label={getTranslation('Confirm')}
                        loading={adding}
                        htmlType="submit"
                    />

                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
        >
            <Form
                name="deactivated-merchant-setup"
                onFinish={submitForm}
                initialValues={{ ...selectedMerchant }}
            >
                <MerchantSetupForm selectedMerchant={selectedMerchant} />
            </Form>
        </Modal>
    );
};

export default MerchantSetupModal;
