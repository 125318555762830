import React from 'react';

import { Card, Form } from 'antd';

import { API } from '../../../common/constants/api';
import { MODIFICATION_STATUSES } from '../../../components/players/constants';
import { closeVerifyModal, modificationsDropFilters } from './pendingModificationsActions';
import { getColumns } from './table/PendingModificationsTableMeta';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useExportAsyncEndpoint } from '../../../services/hooks/useExport';
import { useGetPendingModificationsAsyncEndpoint } from '../../../services/playerService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import PendingModificationsFilters from './PendingModificationsFilters';
import PendingModificationsResultsTable from './table/PendingModificationsResultsTable';
import VerifyModal from './verification/VerifyModal';

const { PLAYERS } = API;

const PendingModifications = () => {
    const [state, dispatch] = useStore();
    const verifyModalVisible = getStateProp(
        state,
        'players.pendingModifications.verifyModalVisible',
        {},
    );
    const modificationAttending = getStateProp(
        state,
        'players.pendingModifications.modificationAttending',
        {},
    );
    const filters = getStateProp(state, 'players.pendingModifications.filters');
    const { totalRecordCount } = getStateProp(state, 'players.pendingModifications.paging', {});

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        modificationsDropFilters(dispatch);
        handleGetModifications();
    };

    const handleCancel = () => closeVerifyModal(dispatch);

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetModifications] = useGetPendingModificationsAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        dataMeta: getColumns(),
        filters,
        mandatoryFilter: { status: [MODIFICATION_STATUSES.PENDING] },
        method: 'POST',
        subject: getTranslation('Pending Modification List'),
        totalRecordCount,
        url: `${PLAYERS.MODIFICATION_LIST}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Pending Modification')}>
            <Form onFinish={handleGetModifications}>
                <PendingModificationsFilters />
                <ActionButtons onDropFilters={fetchAll} onExport={handleExportAction} />
            </Form>

            <PendingModificationsResultsTable loading={loading} />
            <VerifyModal
                visible={verifyModalVisible}
                modification={modificationAttending}
                getModifications={handleGetModifications}
                onCancel={handleCancel}
            />
        </Card>
    );
};

export default PendingModifications;
