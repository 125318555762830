import React from 'react';

import { Card } from 'antd';

import { getTranslation } from '../../common/helpers/locale';
import ChangePasswordForm from './ChangePasswordForm';

const ChangePassword = () => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Change Password')} className="balance-management-card">
            <ChangePasswordForm />
        </Card>
    );
};

export default ChangePassword;
