import React from 'react';

import { Row } from 'antd';

import { BannerCodeFilter } from '../filters';
import { activeBannerFilterSet } from './bannerSortingActions';
import { getStateProp } from '../../../common/helpers/misc';
import { useStore } from '../../../store/StateProvider';

const BannerSortingFilters = () => {
    const [state, dispatch] = useStore();

    const { bannerSorting } = getStateProp(state, 'bonus');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => activeBannerFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <BannerCodeFilter
                onFilterChange={setFilter}
                value={getStateProp(bannerSorting, 'filters.code', '')}
            />
        </Row>
    );
};

export default BannerSortingFilters;
