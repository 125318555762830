export const ACTIONS = {
    VIP_SETUP_GET: 'VIP_SETUP_GET',
    VIP_SETUP_GET_FAILURE: 'VIP_SETUP_GET_FAILURE',
    VIP_SETUP_GET_SUCCESS: 'VIP_SETUP_GET_SUCCESS',
    VIP_SETUP_INFO_SET: 'VIP_SETUP_INFO_SET',
    VIP_SETUP_UPDATE: 'VIP_SETUP_UPDATE',
    VIP_SETUP_UPDATE_FAILURE: 'VIP_SETUP_UPDATE_FAILURE',
    VIP_SETUP_UPDATE_SUCCESS: 'VIP_SETUP_UPDATE_SUCCESS',
};

//#region VIP info set
export const vipSetupInfoSet = (dispatch) =>
    dispatch({
        type: ACTIONS.VIP_SETUP_INFO_SET,
    });
//#endregion

//#region VIP setup get
export const vipSetupGet = (dispatch) =>
    dispatch({
        type: ACTIONS.VIP_SETUP_GET,
    });

export const vipSetupGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.VIP_SETUP_GET_SUCCESS,
    });

export const vipSetupGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.VIP_SETUP_GET_FAILURE,
    });
//#endregion

//#region VIP setup get
export const vipSetupUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.VIP_SETUP_UPDATE,
    });

export const vipSetupUpdateSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.VIP_SETUP_UPDATE_SUCCESS,
    });

export const vipSetupUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.VIP_SETUP_UPDATE_FAILURE,
    });
//#endregion
