import React from 'react';

import { Table } from 'antd';
import { getColumns } from './BatchActionMeta';

const BatchActionTable = ({ dataSource }) => {
    // ============================================
    // HOOKS
    // ============================================
    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns()}
            rowKey={(record) => record.id}
            size="small"
            align="center"
            dataSource={dataSource}
            pagination={false}
            bordered
            scroll={{ x: true }}
        />
    );
};

export default BatchActionTable;
