import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { TRUST_LEVELS } from '../../constants/misc';
import { getTranslation } from '../../helpers/locale';

const TrustLevelFilter = ({ value, onFilterChange }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('trustLevel', value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear
            placeholder={getTranslation('Trust Level')}
            className="filter"
        >
            {_.map(TRUST_LEVELS, (trustLevel) => (
                <Select.Option key={trustLevel} value={trustLevel}>
                    {trustLevel}
                </Select.Option>
            ))}
        </Select>
    );
};

export default TrustLevelFilter;
