import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { CRYPTOCURRENCY } from '../../constants/misc';
import { getTranslation } from '../../helpers/locale';

const CryptocurrencyFilter = ({ value, onFilterChange }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('cryptoCode', value);
    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear={true}
            placeholder={getTranslation('Cryptocurrency')}
            className="filter"
        >
            {_.map(CRYPTOCURRENCY, (crypto) => (
                <Select.Option value={crypto} key={crypto}>
                    {crypto.toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
};

export default CryptocurrencyFilter;
