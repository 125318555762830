import { LANGUAGES } from '../../common/constants/localization';
import { getSupportedLanguages } from '../../common/helpers/locale';
import _ from 'lodash';

export const getPushNotificationLang = (pushNotificationInfo) => {
    let lang = [];
    _.forEach(pushNotificationInfo, (e) => {
        lang.push(e.languageCode);
    });
    return lang;
};

export const addLanguage = (pushNotificationInfo, lang) => {
    let newPushNotificationInfo = pushNotificationInfo;
    return newPushNotificationInfo.push({
        content: '',
        languageCode: lang,
        languageName: _.find(LANGUAGES, ['value', lang]).name,
        title: '',
    });
};

export const removeLanguage = (pushNotificationInfo, lang) => {
    return _.remove(pushNotificationInfo, function(e) {
        return e.languageCode === lang;
    });
};

export const updatePushNotification = (pushNotificationInfo, value, languageCode, key) => {
    return _.forEach(pushNotificationInfo, function(e) {
        if (e.languageCode === languageCode) {
            e[key] = value;
        }
    });
};

export const getInitPushNotification = () => {
    return getSupportedLanguages().length === LANGUAGES.length
        ? [
              {
                  content: '',
                  languageCode: 'en-US',
                  languageName: _.find(LANGUAGES, ['value', 'en-US']).name,
                  title: '',
              },
              {
                  content: '',
                  languageCode: 'zh-CN',
                  languageName: _.find(LANGUAGES, ['value', 'zh-CN']).name,
                  title: '',
              },
          ]
        : _.map(getSupportedLanguages(), (language) => {
              return {
                  content: '',
                  languageCode: language.value,
                  languageName: language.name,
                  title: '',
              };
          });
};
