import { useCallback, useEffect, useState } from 'react';

import { message } from 'antd';

import { API } from '../common/constants/api';
import { PAYMENT_ORDER_OPERATIONS } from '../common/constants/misc';
import { clearError } from '../common/actions/errorActions';
import {
    cryptoDepositOperationFailure,
    cryptoDepositOperationSuccess,
    cryptoDepositsGet,
    cryptoDepositsGetFailure,
    cryptoDepositsGetSuccess,
} from '../components/payments/cryptocurrency/deposit/cryptoDepositActions';

import {
    cryptoWithdrawalOperationFailure,
    cryptoWithdrawalOperationSuccess,
    cryptoWithdrawalsGet,
    cryptoWithdrawalsGetFailure,
    cryptoWithdrawalsGetSuccess,
} from '../components/payments/cryptocurrency/withdrawal/cryptoWithdrawalActions';

import {
    cryptoDepositRecordGet,
    cryptoDepositRecordGetFailure,
    cryptoDepositRecordGetSuccess,
} from '../components/transaction-history/cryptocurrency-records/deposits/cryptoDepositRecordActions';
import {
    cryptoWithdrawalRecordGet,
    cryptoWithdrawalRecordGetFailure,
    cryptoWithdrawalRecordGetSuccess,
    cryptoWithdrawalRecordOperationFailure,
    cryptoWithdrawalRecordOperationSuccess,
} from '../components/transaction-history/cryptocurrency-records/withdrawals/cryptoWithdrawalRecordActions';

import { filterTrim, getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import { useNotification } from './hooks/useNotification';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, PAYMENTS, TRANSACTION_HISTORY } = API;

export function useGetCryptoDepositsAsyncEndpoint(isOnline) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const cryptoDeposits = getStateProp(state, 'payments.cryptoDeposits');
    const { pageNo, pageSize, totalRecordCountNoFilter } = getStateProp(cryptoDeposits, 'paging');
    const filters = getStateProp(cryptoDeposits, 'filters', {});
    const refreshTime = getStateProp(cryptoDeposits, 'operation.refreshTime');
    const refreshTimePause = getStateProp(cryptoDeposits, 'operation.refreshTimePause');

    // ============================================
    // METHODS
    // ============================================

    const handleGetCryptoDeposits = useCallback(() => {
        cryptoDepositsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.CRYPTO_DEPOSITS.ROOT}`,
    });

    useEffect(() => {
        let timeInterval;
        if (response || isOnline)
            timeInterval = setTimeout(() => handleGetCryptoDeposits(), refreshTime * 1000);
        if (refreshTimePause) clearTimeout(timeInterval);
        return () => clearTimeout(timeInterval);
    }, [handleGetCryptoDeposits, isOnline, refreshTime, refreshTimePause, response]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            cryptoDepositsGetSuccess(dispatch, {
                fetchData: handleGetCryptoDeposits,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetCryptoDeposits, response]);

    useEffect(() => {
        handleGetCryptoDeposits();
    }, [handleGetCryptoDeposits, pageNo, pageSize]);

    useNotification(
        totalRecordCountNoFilter,
        getStateProp(response, 'data.paging.totalRecordCountNoFilter', 0),
        'You have new crypto deposit record',
    );

    const errorMsg = useError(error, cryptoDepositsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetCryptoDeposits];
}

export function useCryptoDepositOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();

    const cryptoDeposits = getStateProp(state, 'payments.cryptoDeposits');
    const refreshTable = getStateProp(cryptoDeposits, 'fetchData', () => {});
    const operation = getStateProp(cryptoDeposits, 'operation');
    const transactionIds = getStateProp(cryptoDeposits, 'selectedTransactionId');
    const transactionId = getStateProp(operation, 'transaction.transactionId');
    const action = getStateProp(operation, 'action');
    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!action,
        method: 'POST',
        options: {
            data: {
                remark: '',
                transactionIds,
            },
        },
        trigger: action,
        url:
            action === PAYMENT_ORDER_OPERATIONS.REJECT
                ? `${VERSION.V1}${PAYMENTS.CRYPTO_DEPOSITS.ROOT}/${action}`
                : `${VERSION.V1}${PAYMENTS.CRYPTO_DEPOSITS.SINGLE}/${transactionId}/${action}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            cryptoDepositOperationSuccess(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    useEffect(() => {
        if (response) {
            if (action === PAYMENT_ORDER_OPERATIONS.COMPLETE) {
                message.success(getTranslation('Transaction verified!'));
            } else if (action === PAYMENT_ORDER_OPERATIONS.REJECT) {
                message.success(getTranslation('Transaction rejected!'));
            }
        }
    }, [response, action]);

    const errorMsg = useError(error, cryptoDepositOperationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useGetCryptoWithdrawalsAsyncEndpoint(isPayment, isOnline) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const cryptoWithdrawals = getStateProp(state, 'payments.cryptoWithdrawals');
    const { pageNo, pageSize, totalRecordCountNoFilter } = getStateProp(
        cryptoWithdrawals,
        'paging',
    );
    const filters = getStateProp(cryptoWithdrawals, 'filters', {});
    const refreshTime = getStateProp(cryptoWithdrawals, 'operation.refreshTime');
    const refreshTimePause = getStateProp(cryptoWithdrawals, 'operation.refreshTimePause');

    // ============================================
    // METHODS
    // ============================================

    const handleGetCryptoWithdrawals = useCallback(() => {
        cryptoWithdrawalsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                isPayment,
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.CRYPTO_WITHDRAWALS.ROOT}`,
    });

    useEffect(() => {
        let timeInterval;
        if (response || isOnline)
            timeInterval = setTimeout(() => handleGetCryptoWithdrawals(), refreshTime * 1000);
        if (refreshTimePause) clearTimeout(timeInterval);
        return () => clearTimeout(timeInterval);
    }, [handleGetCryptoWithdrawals, isOnline, refreshTime, refreshTimePause, response]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            cryptoWithdrawalsGetSuccess(dispatch, {
                fetchData: handleGetCryptoWithdrawals,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetCryptoWithdrawals, response]);

    useEffect(() => {
        handleGetCryptoWithdrawals();
    }, [handleGetCryptoWithdrawals, pageNo, pageSize]);

    const errorMsg = useError(error, cryptoWithdrawalsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    useNotification(
        totalRecordCountNoFilter,
        getStateProp(response, 'data.paging.totalRecordCountNoFilter', 0),
        'You have new crypto withdrawal record',
    );

    return [loading, handleGetCryptoWithdrawals];
}

export function useCryptoWithdrawalOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();

    const cryptoWithdrawals = getStateProp(state, 'payments.cryptoWithdrawals');

    const refreshTable = getStateProp(cryptoWithdrawals, 'fetchData', () => {});
    const operation = getStateProp(cryptoWithdrawals, 'operation');
    const transactionId = getStateProp(operation, 'transaction.transactionId');
    const remarks = getStateProp(operation, 'rejectInfo.remarks', '');
    const action = getStateProp(operation, 'action');

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!action,
        method: 'POST',
        options: {
            data: {
                remarks,
            },
        },
        trigger: action,
        url: `${VERSION.V1}${PAYMENTS.CRYPTO_WITHDRAWALS.SINGLE}/${transactionId}/${action}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            cryptoWithdrawalOperationSuccess(dispatch);
            refreshTable();
        }

        if (error) {
            refreshTable();
        }
    }, [dispatch, refreshTable, response, error]);

    useEffect(() => {
        if (response) {
            if (action === PAYMENT_ORDER_OPERATIONS.COMPLETE) {
                message.success(getTranslation('Transaction verified!'));
            } else if (action === PAYMENT_ORDER_OPERATIONS.REJECT) {
                message.success(getTranslation('Transaction rejected!'));
            }
        }
    }, [response, action]);

    const errorMsg = useError(error, cryptoWithdrawalOperationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useGetCryptoDepositRecordAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const cryptoDepositRecord = getStateProp(
        state,
        'transactionHistory.cryptocurrencyRecords.cryptoDepositRecord',
    );
    const { pageNo, pageSize } = getStateProp(cryptoDepositRecord, 'paging');
    const filters = getStateProp(cryptoDepositRecord, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetCryptoDepositRecord = useCallback(() => {
        cryptoDepositRecordGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${TRANSACTION_HISTORY.CRYPTO_DEPOSITS_RECORDS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            cryptoDepositRecordGetSuccess(dispatch, {
                dataTotal: response.data.data[0].dataTotal,
                fetchData: handleGetCryptoDepositRecord,
                grandTotal: response.data.data[0].grandTotal,
                list: response.data.data[0].data,
                pageDataTotal: response.data.data[0].pageDataTotal,
                pageTotal: response.data.data[0].pageTotal,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetCryptoDepositRecord, response]);

    useEffect(() => {
        handleGetCryptoDepositRecord();
    }, [handleGetCryptoDepositRecord, pageNo, pageSize]);

    const errorMsg = useError(error, cryptoDepositRecordGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetCryptoDepositRecord];
}

export function useGetCryptoWithdrawalRecordAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const cryptoWithdrawalRecord = getStateProp(
        state,
        'transactionHistory.cryptocurrencyRecords.cryptoWithdrawalRecord',
    );
    const { pageNo, pageSize } = getStateProp(cryptoWithdrawalRecord, 'paging');
    const filters = getStateProp(cryptoWithdrawalRecord, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetCryptoWithdrawalRecord = useCallback(() => {
        cryptoWithdrawalRecordGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${TRANSACTION_HISTORY.CRYPTO_WITHDRAWALS_RECORDS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            cryptoWithdrawalRecordGetSuccess(dispatch, {
                dataTotal: response.data.data[0].dataTotal,
                fetchData: handleGetCryptoWithdrawalRecord,
                grandTotal: response.data.data[0].grandTotal,
                list: response.data.data[0].data,
                pageDataTotal: response.data.data[0].pageDataTotal,
                pageTotal: response.data.data[0].pageTotal,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetCryptoWithdrawalRecord, response]);

    useEffect(() => {
        handleGetCryptoWithdrawalRecord();
    }, [handleGetCryptoWithdrawalRecord, pageNo, pageSize]);

    const errorMsg = useError(error, cryptoWithdrawalRecordGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetCryptoWithdrawalRecord];
}

export function useCryptoRecordWithdrawalOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const cryptoWithdrawalRecord = getStateProp(
        state,
        'transactionHistory.cryptocurrencyRecords.cryptoWithdrawalRecord',
    );

    const refreshTable = getStateProp(cryptoWithdrawalRecord, 'fetchData', () => {});
    const operation = getStateProp(cryptoWithdrawalRecord, 'operation');
    const transactionId = getStateProp(cryptoWithdrawalRecord, 'transaction.transactionId');
    const action = getStateProp(operation, 'action');
    const remarks = getStateProp(cryptoWithdrawalRecord, 'verifyInfo.remarks');

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!action,
        method: 'POST',
        options: {
            data: {
                isLevel1: true,
                remarks,
            },
        },
        trigger: action,
        url: `${VERSION.V1}${PAYMENTS.CRYPTO_WITHDRAWALS.SINGLE}/${transactionId}/${action}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            cryptoWithdrawalRecordOperationSuccess(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    useEffect(() => {
        if (response) {
            if (action === PAYMENT_ORDER_OPERATIONS.COMPLETE) {
                message.success(getTranslation('Transaction verified!'));
            } else if (action === PAYMENT_ORDER_OPERATIONS.REJECT) {
                message.success(getTranslation('Transaction rejected!'));
            }
        }
    }, [response, action]);

    const errorMsg = useError(error, cryptoWithdrawalRecordOperationFailure);
    useToasterErrorMessage(error, errorMsg);
    return [loading];
}
