export const PLAYER_STATUSES = {
    ACTIVE: 'Active',
    CLOSED: 'Close',
    LOCKED: 'Lock',
};

export const MODIFICATION_STATUSES = {
    APPROVED: 'Approved',
    DECLINED: 'Declined',
    PENDING: 'Pending',
};

export const MODIFICATION_TYPES = {
    ADD_BANK_CARD: 'Add Bank Card',
    ASSIST_SIGNUP_PLAYER: 'Assist SignUp Player',
    BONUS_PROHIBITION: 'Bonus Prohibition',
    CARD_HOLDER_NAME: 'Card Holder Name',
    DANGER_LEVEL: 'Danger Level',
    DEPOSIT_PROHIBITION: 'Deposit Prohibition',
    DOB: 'DOB',
    EMAIL: 'Email',
    E_WALLET_HOLDER_NAME: 'E-Wallet Holder Name',
    FULL_NAME: 'Full Name',
    GAME_ACCESS: 'Game Access',
    LOGIN_PROHIBITION: 'Login Prohibition',
    MEMBER_LEVEL: 'Member Level',
    MOBILE_NUMBER: 'Mobile Number',
    PROFILE_REMARK: 'Profile Remark',
    PROFILE_STATUS: 'Profile Status',
    QQ_ACCOUNT: 'QQAccount',
    QUALITY_LEVEL: 'Quality Level',
    RESET_PASSWORD_FINANCIAL_PIN: 'Reset Password & Financial Pin',
    TRUST_LEVEL: 'Trust Level',
    WECHAT_ACCOUNT: 'WeChatAccount',
    WITHDRAW_PROHIBITION: 'Withdrawal Prohibition',
};

export const EXPORT_UNMASKED_PHONE = ['andrea', 'kelvin', 'operator02', 'nic777'];
