import React from 'react';

import { Card, Form, Row, message } from 'antd';

import './AffiliateGenerator.less';
import { ButtonGreen } from '../../../common/components/buttons/Buttons';
import { PeriodFilter, UsernameFilter } from '../../../common/components/filters';
import {
    affiliateGeneratorFilterSet,
    closeTotalRecordsGeneratedModal,
} from './affiliateGeneratorActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    useGenerateAffiliateAsyncEndpoint,
    useGetAffiliateGeneratorHistoryAsyncEndpoint,
} from '../../../services/affiliateRecordService';
import { useStore } from '../../../store/StateProvider';
import AffiliateGeneratorHistoryTable from './table/AffiliateGeneratorHistoryTable';
import TotalRecordGenratedModal from './TotalRecordGenratedModal';

const AffiliateGenerator = () => {
    const [state, dispatch] = useStore();
    const { generator = {} } = getStateProp(state, 'affiliate');

    // ============================================
    // METHODS
    // ============================================
    const setFilter = (filter, value) => affiliateGeneratorFilterSet(dispatch, { filter, value });

    const generateAffiliate = () => {
        let fromDate = getStateProp(generator, 'filters.fromDate');
        let toDate = getStateProp(generator, 'filters.toDate');
        if (!fromDate && !toDate) {
            message.error(getTranslation('Date From and Date To cannot be empty'));
        } else {
            handleGenerateAffiliate();
        }
    };

    // ============================================
    // HOOKS
    // ============================================
    const [
        loading,
        handleGetAffiliateGeneratorHistory,
    ] = useGetAffiliateGeneratorHistoryAsyncEndpoint();

    const [generating, handleGenerateAffiliate] = useGenerateAffiliateAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Affiliate Generator')} className="affiliate-generator">
            <Form
                className="affiliate-generator__padding-bottom--20"
                onFinish={handleGetAffiliateGeneratorHistory}
            >
                <Row justify="start" align="middle" className="filters-container">
                    <PeriodFilter
                        onFilterChange={setFilter}
                        dateFrom={getStateProp(generator, 'filters.fromDate')}
                        dateTo={getStateProp(generator, 'filters.toDate')}
                        dateFromName="fromDate"
                        dateToName="toDate"
                        ignoreUTC={true}
                    />
                    <UsernameFilter
                        onFilterChange={setFilter}
                        value={getStateProp(generator, 'filters.userName')}
                        name="userName"
                    />
                    <ButtonGreen
                        label={getTranslation('Generate')}
                        onClick={generateAffiliate}
                        loading={generating}
                    />
                </Row>
            </Form>
            <AffiliateGeneratorHistoryTable loading={loading} />
            <TotalRecordGenratedModal
                visible={generator.totalRecordGeneratedModal}
                onCancel={() => closeTotalRecordsGeneratedModal(dispatch)}
                totalRecordGenerated={generator.totalRecordGenerated}
            />
        </Card>
    );
};

export default AffiliateGenerator;
