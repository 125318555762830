import { useEffect, useState } from 'react';

import { API } from '../../common/constants/api';
import {
    batchPlayerSearch,
    batchPlayerSearchFailure,
    batchPlayerSearchSuccess,
} from '../../common/actions/generalActions';
import { clearError } from '../../common/actions/errorActions';
import { useDispatch } from '../../store/StateProvider';
import { useToasterErrorMessage } from './useToasterMessage';
import useApi from './useApi';
import useError from './useError';

const { VERSION, IDENTITY, PLAYERS } = API;

export function usePlayerBatchSearchEndpoint(userNames, isDownloadedApp) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);
    const [result, setResult] = useState({});
    const url = isDownloadedApp ? PLAYERS.VERIFY_USERNAMES : IDENTITY.VERIFY_USERNAME;
    const dataPayload = isDownloadedApp ? { provider: 'Pushy', userNames } : { userNames };
    // ============================================
    // METHODS
    // ============================================

    const handlePlayerBatchSearch = () => {
        batchPlayerSearch(dispatch);
        setTrigger(+new Date());
    };

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: dataPayload,
        },
        trigger,
        url: `${VERSION.V1}${url}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            batchPlayerSearchSuccess(dispatch);

            setResult({
                invalid: response.data.data.invalid,
                valid: response.data.data.valid,
            });
        } else {
            setResult({});
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, batchPlayerSearchFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handlePlayerBatchSearch, result];
}
