import { ACTIONS } from './requestBonusActions';

export default function bonusManagementReducer(
    bonusDescription = {
        adjustmentInfo: { transactionNo: undefined, userNameLike: undefined },
        bonusCodeDetailList: [],
        bonusCodeList: [],
        modalDetails: {
            onConfirm: () => {},
            visible: false,
            warningMessage: null,
        },
        playerInfo: {},
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.REQUEST_BONUS_ADJUSTMENT_INFO_SET:
            return {
                ...bonusDescription,
                adjustmentInfo: {
                    ...bonusDescription.adjustmentInfo,
                    [payload.inputName]: payload.value,
                },
            };

        case ACTIONS.UPDATE_BONUS_DETAILS:
            return {
                ...bonusDescription,
                adjustmentInfo: {
                    ...bonusDescription.adjustmentInfo,
                    ...payload,
                },
            };

        case ACTIONS.REQUEST_BONUS_SEARCH_PLAYER_INFO_SUCCESS:
            return {
                ...bonusDescription,
                adjustmentInfo: {
                    ...bonusDescription.adjustmentInfo,
                    ...payload,
                },
            };

        case ACTIONS.GET_BONUS_CODES_SUCCESS:
            return {
                ...bonusDescription,
                ...payload,
            };

        case ACTIONS.REQUEST_BONUS_FORM_RESET:
            return {
                ...bonusDescription,
                adjustmentInfo: { transactionNo: undefined, userNameLike: undefined },
                bonusCodeDetailList: [],
                bonusCodeList: [],
                playerInfo: {},
            };

        case ACTIONS.REQUEST_BONUS_TURNOVER_SUCCESS:
            return {
                ...bonusDescription,
                adjustmentInfo: {
                    ...bonusDescription.adjustmentInfo,
                    ...payload,
                },
            };

        case ACTIONS.CONFIRM_MODAL_OPEN:
            return {
                ...bonusDescription,
                modalDetails: {
                    onConfirm: payload.onConfirm,
                    visible: true,
                    warningMessage: payload.message,
                },
            };
        case ACTIONS.CONFIRM_MODAL_CLOSE:
            return {
                ...bonusDescription,
                modalDetails: {
                    onConfirm: () => {},
                    visible: false,
                    warningMessage: null,
                },
            };

        case ACTIONS.UPLOAD_EXCEL_MODAL_SET:
            return {
                ...bonusDescription,
                downloadExcelResultUrl: payload ? bonusDescription.downloadExcelResultUrl : '',
                uploadModalVisible: payload,
            };

        case ACTIONS.UPLOAD_EXCEL_SUCCESS:
            return {
                ...bonusDescription,
                downloadExcelResultUrl: payload,
            };

        default: {
            return bonusDescription;
        }
    }
}
