import React from 'react';

import { Row } from 'antd';

import { DropButton, SearchButton } from '../../../../common/components/buttons';

const ActionButtons = ({ fetchAll }) => {
    return (
        <Row align="middle" justify="end" className="action-buttons__container">
            <SearchButton htmlType="submit" />
            <DropButton onDropFilters={fetchAll} />
        </Row>
    );
};

export default ActionButtons;
