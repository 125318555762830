export const ACTIONS = {
    REBATE_FILTER_OPTIONS_GET: 'REBATE_FILTER_OPTIONS_GET',
    REBATE_FILTER_OPTIONS_GET_FAILURE: 'REBATE_FILTER_OPTIONS_GET_FAILURE',
    REBATE_FILTER_OPTIONS_GET_SUCCESS: 'REBATE_FILTER_OPTIONS_GET_SUCCESS',
};

//#region Rebate Filter Option Get
export const getRebateFilterOptions = (dispatch) =>
    dispatch({
        type: ACTIONS.REBATE_FILTER_OPTIONS_GET,
    });

export const getRebateFilterOptionSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_FILTER_OPTIONS_GET_SUCCESS,
    });

export const getRebateFilterOptionFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_FILTER_OPTIONS_GET_FAILURE,
    });
//#end region
