import React from 'react';

import { Card, Divider, Form, Modal, Row, Steps, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';

import './EditBonusDescription.less';
import { BONUS_STEP, FORM_ASPECT } from '../../../constants';
import { ButtonGreen, ButtonTransparent } from '../../../../../common/components/buttons/Buttons';
import {
    bonusManagementDuplicateByLanguage,
    dropEditBonusDescriptionLang,
    setBonusDescriptionInfo,
    setBonusDescriptionInfoByLang,
    setCurrentStep,
    setEditBonusDescriptionLang,
    setMultiBonusDescriptionInfo,
} from '../../bonusManagementActions';
import { editorDefaultStylingSizeSet, getStateProp } from '../../../../../common/helpers/misc';
import { formValidation } from '../../../helpers';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useGetAvailableBonusCategoryAsyncEndpoint } from '../../../../../services/bonusCategoryService';
import { useGetBetListFilterOptionsAsyncEndpoint } from '../../../../../services/betService';
import { useStore } from '../../../../../store/StateProvider';
import { useUpdateBannerOperationAsyncEndpoint } from '../../../../../services/bonusManagementService';
import BonusTabs from '../../../shared/BonusTabs';

const { Step } = Steps;

const EditBonusDescription = ({ onCancel, getBannerByIdLoading, viewOnly }) => {
    const [state, dispatch] = useStore();
    const bonusManagement = getStateProp(state, 'bonus.bonusManagement', {});
    const currentStep = getStateProp(bonusManagement, 'currentStep');
    const selectedBonus = getStateProp(bonusManagement, 'selectedBonus');
    const { platforms = [], products = [] } = getStateProp(state, 'bet.betList.filterOptions', []);

    const {
        currencies,
        categories,
        details,
        isDepositEtoComplete = false,
        isDepositBonus = false,
    } = selectedBonus;

    const selectedLanguage = _.map(details, (value) => {
        return value.languageCode;
    }).sort();
    const platformsArrary = _.map(platforms, (e) => {
        return e.platformCode;
    });
    const productsArrary = _.map(products, (e) => {
        return e.productCode;
    });

    // ============================================
    // METHODS
    // ============================================

    const setBonusLanguage = (e) => {
        if (e.target.checked) {
            details.push({
                bonusDetail: '',
                imageInBase64: null,
                languageCode: e.target.value,
                termCondition: '',
            });

            setEditBonusDescriptionLang(dispatch, details);
        } else {
            dropEditBonusDescriptionLang(
                dispatch,
                _.reject(details, (content) => {
                    return content.languageCode === e.target.value;
                }),
            );
        }
    };

    const onContentChange = (name, value, langCode) => {
        if (name === 'imageInBase64' && value === null)
            setBonusDescriptionInfoByLang(dispatch, {
                langCode,
                name: 'imageUrl',
                value,
            });

        setBonusDescriptionInfoByLang(dispatch, {
            langCode,
            name,
            value: editorDefaultStylingSizeSet(value),
        });
    };

    const onBonusInfoChange = (name, value, newMinimumDeposits = []) => {
        let newCurrencies = currencies || [];
        if (name === 'hasJoinButton' && !value) {
            setMultiBonusDescriptionInfo(dispatch, {
                hasPromoCode: value,
                [name]: value,
            });
        } else if (name === 'isFixedBonus') {
            if (!value && !isDepositBonus) {
                message.warning(
                    getTranslation(
                        'Please enable deposit bonus. If you wish to setup Bonus Amount By Percentage',
                    ),
                );
            } else {
                _.forEach(newCurrencies, (e) => {
                    e.bonusPercentage = 0;
                    e.fixedBonusAmount = 0;
                    e.maxBonusByPercentage = 0;
                });
                setMultiBonusDescriptionInfo(dispatch, {
                    currencies: newCurrencies,
                    [name]: value,
                });
            }
        } else if (name === 'isDepositBonus') {
            setMultiBonusDescriptionInfo(dispatch, {
                minimumDeposits: [],
                [name]: value,
            });
        } else if (name === 'isRepeatable') {
            setMultiBonusDescriptionInfo(dispatch, {
                depositInterval: 0,
                [name]: value,
                repeatableCount: 0,
            });
        } else if (name === 'currencies' && newMinimumDeposits.length) {
            setMultiBonusDescriptionInfo(dispatch, {
                minimumDeposits: newMinimumDeposits,
                [name]: value,
            });
        } else if (name === 'categories') {
            setBonusDescriptionInfo(dispatch, {
                name,
                value: _.map(value, (selectedCategory) => {
                    return (
                        _.find(categories, (category) => {
                            return category.categoryId === selectedCategory;
                        }) || { categoryId: selectedCategory }
                    );
                }),
            });
        } else if (name === 'bonusConditionType') {
            if (!isDepositBonus && (value === 'isETODeposit' || value === 'isETODepositAndBonus')) {
                message.warning(getTranslation('Please enable deposit bonus'));
            } else {
                _.forEach(newCurrencies, (e) => {
                    e.etoDepositMultiplier = 0;
                    e.etoDepositBonusMultiplier = 0;
                    e.betAmount = 0;
                    e.winAmount = 0;
                    e.lossAmount = 0;
                });
                setMultiBonusDescriptionInfo(dispatch, {
                    currencies: newCurrencies,
                    isBetAmount: value === 'isBetAmount' ? true : false,
                    isETODeposit: value === 'isETODeposit' ? true : false,
                    isETODepositAndBonus: value === 'isETODepositAndBonus' ? true : false,
                    isWinLoss: value === 'isWinLoss' ? true : false,
                    winLossType: value === 'isWinLoss' ? 'Win' : 'None',
                });
            }
        } else if (name === 'winLossType') {
            _.forEach(newCurrencies, (e) => {
                e.winAmount = 0;
                e.lossAmount = 0;
            });
            setMultiBonusDescriptionInfo(dispatch, {
                currencies: newCurrencies,
                [name]: value,
            });
        } else if (name === 'checkingDateType') {
            setMultiBonusDescriptionInfo(dispatch, {
                conditionDateFrom: null,
                conditionDateTo: null,
                duration: 0,
                [name]: value,
            });
        } else if (name === 'isPromoRule') {
            if (!isDepositEtoComplete && value) {
                message.warning(getTranslation('Please enable auto approve bonus'));
            } else {
                _.forEach(newCurrencies, (e) => {
                    e.etoDepositMultiplier = 0;
                    e.etoDepositBonusMultiplier = 0;
                    e.betAmount = 0;
                    e.winAmount = 0;
                    e.lossAmount = 0;
                });
                setMultiBonusDescriptionInfo(dispatch, {
                    appliedPlatforms: value ? ['ALL'] : [],
                    appliedProducts: value ? ['ALL'] : [],
                    checkingDateType: 'Duration',
                    currencies: newCurrencies,
                    duration: 0,
                    isBetAmount: false,
                    isETODeposit: value ? true : false,
                    isETODepositAndBonus: false,
                    isWinLoss: false,
                    [name]: value,
                    winLossType: 'None',
                });
            }
        } else {
            setBonusDescriptionInfo(dispatch, { name, value });
        }
    };

    const submitForm = () => {
        if (
            formValidation(selectedBonus, currentStep, selectedBonus.isShowOnApp, FORM_ASPECT.EDIT)
        ) {
            if (currentStep < BONUS_STEP.length - 1) {
                setCurrentStep(dispatch, currentStep + 1);
            } else {
                if (!selectedBonus.isDepositEtoComplete && selectedBonus.isPromoRule) {
                    message.warning(getTranslation('Please enable auto approve bonus'));
                } else if (
                    !selectedBonus.isDepositBonus &&
                    (selectedBonus.isETODeposit || selectedBonus.isETODepositAndBonus)
                ) {
                    message.warning(getTranslation('Please enable deposit bonus'));
                } else {
                    handleUpdateBanner();
                }
            }
        }
    };

    const duplicateBonusTabs = (values) => {
        const { languageCode, id, imageUrlCN, imageUrl, imageExtension, ...rest } = _.find(
            details,
            (detail) => {
                return detail.languageCode === values.from;
            },
        );

        const detailsCopyTo = _.find(details, (detail) => {
            return detail.languageCode === values.to;
        });

        const { languageCode: langCode, ...restCopyTo } = detailsCopyTo;

        if (_.every(restCopyTo, _.isEmpty)) {
            _.assign(detailsCopyTo, rest);
            bonusManagementDuplicateByLanguage(dispatch, details);
            message.success('Copied!');
        } else {
            Modal.confirm({
                content: getTranslation(
                    'Destination copy to is not empty! Are you sure want to copy ?',
                ),
                icon: <ExclamationCircleOutlined />,
                onCancel() {
                    return false;
                },
                onOk() {
                    _.assign(detailsCopyTo, rest);
                    bonusManagementDuplicateByLanguage(dispatch, details);
                    message.success('Copied!');
                },
                title: getTranslation('Are you sure want to copy ?'),
            });
        }
    };

    // ============================================
    // HOOKS
    // ============================================

    const [bannerUpdating, handleUpdateBanner] = useUpdateBannerOperationAsyncEndpoint();
    const [getingList] = useGetAvailableBonusCategoryAsyncEndpoint();
    const [gettingBetList] = useGetBetListFilterOptionsAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card
            title={
                viewOnly
                    ? getTranslation('Bonus Description')
                    : getTranslation('Edit Bonus Description')
            }
            loading={getBannerByIdLoading || gettingBetList}
        >
            <Form name="update-bonus" className="update-bonus__form">
                <Steps current={currentStep}>
                    {_.map(BONUS_STEP, (e) => (
                        <Step key={e.title} title={e.title} />
                    ))}
                </Steps>
                <Divider />
                <BonusTabs
                    selectedLangs={selectedLanguage}
                    setBonusLang={setBonusLanguage}
                    bonus={selectedBonus}
                    onContentChange={onContentChange}
                    onBonusInfoChange={onBonusInfoChange}
                    onDuplicateBonus={duplicateBonusTabs}
                    isEdit={true}
                    categoryListLoading={getingList}
                    viewOnly={viewOnly}
                    currentStep={currentStep}
                    platforms={platforms}
                    platformsArrary={platformsArrary}
                    products={products}
                    productsArrary={productsArrary}
                />
                <Row className="form__footer-row" justify="space-between">
                    <div>
                        {currentStep > 0 && (
                            <ButtonTransparent
                                label={getTranslation('Previous')}
                                onClick={() => setCurrentStep(dispatch, currentStep - 1)}
                            />
                        )}
                    </div>
                    <div>
                        <Row justify="end">
                            <ButtonGreen
                                label={getTranslation(
                                    currentStep < BONUS_STEP.length - 1 ? 'Next' : 'Update',
                                )}
                                loading={bannerUpdating}
                                style={{ marginRight: '5px' }}
                                onClick={submitForm}
                                disabled={viewOnly}
                            />
                            <ButtonTransparent
                                label={getTranslation('Cancel')}
                                onClick={onCancel}
                            />
                        </Row>
                    </div>
                </Row>
            </Form>
        </Card>
    );
};

export default EditBonusDescription;
