import React from 'react';

import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../vipRecordsActions';
import { getStatusColor, sortTableByDataType } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';

export const getColumns = () => {
    const columns = [
        {
            dataIndex: 'requestedDateTime',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Request Time'),
        },
        {
            dataIndex: 'transactionNo',
            title: getTranslation('Task Transaction No.'),
        },
        {
            dataIndex: 'playerUserName',
            title: getTranslation('Username'),
        },
        {
            dataIndex: 'playerName',
            title: getTranslation('Player Name'),
        },
        {
            dataIndex: 'transactionType',
            title: getTranslation('Type'),
        },
        {
            dataIndex: 'vipRewardType',
            title: getTranslation('Reward Type'),
        },
        {
            dataIndex: 'currencyCode',
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'amount',
            dataType: 'nanInt',
            render: (value) => <span>{!isNaN(parseInt(value)) ? value : 'NA'}</span>,
            title: getTranslation('Amount'),
        },
        {
            dataIndex: 'previousWalletBalance',
            dataType: 'nanInt',
            render: (value) => <span>{!isNaN(parseInt(value)) ? value : 'NA'}</span>,
            title: getTranslation('Previous Balance'),
        },
        {
            dataIndex: 'currentWalletBalance',
            dataType: 'nanInt',
            render: (value) => <span>{!isNaN(parseInt(value)) ? value : 'NA'}</span>,
            title: getTranslation('Current Balance'),
        },
        {
            dataIndex: 'transactionStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>
                    {value ? value.toLocaleUpperCase() : null}
                </span>
            ),
            title: getTranslation('Transaction Status'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
