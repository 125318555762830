import { ACTIONS } from './referralInquiryActions';
import { REFEREE_DEPOSIT_CONDITION, TOTAL_REFEREE_CONDITION } from '../constants';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function referralInquiryReducer(
    referrerInquiry = {
        filters: {
            totalDepositedReferee: { condition: REFEREE_DEPOSIT_CONDITION.GREATER.value },
            totalReferee: { condition: TOTAL_REFEREE_CONDITION.GREATER.value },
        },
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        resetPageNo: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.REFERRAL_INQUIRY_GET_SUCCESS:
            return {
                ...referrerInquiry,
                ...payload,
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...referrerInquiry,
                paging: {
                    ...referrerInquiry.paging,
                    pageNo:
                        payload > referrerInquiry.paging.pageCount
                            ? referrerInquiry.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...referrerInquiry,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };
        case ACTIONS.FILTER_SET:
            return {
                ...referrerInquiry,
                filters: {
                    ...referrerInquiry.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };
        case ACTIONS.FILTERS_DROP_ALL:
            return {
                ...referrerInquiry,
                filters: {
                    totalDepositedReferee: { condition: REFEREE_DEPOSIT_CONDITION.GREATER.value },
                    totalReferee: { condition: TOTAL_REFEREE_CONDITION.GREATER.value },
                },
                resetPageNo: true,
            };
        case ACTIONS.REFERRAL_INQUIRY_GET:
        default:
            return referrerInquiry;
    }
}
