import React from 'react';

import { Modal, Row } from 'antd';

import { ButtonTransparent } from '../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../common/helpers/locale';
import PlayersBatchSearch from '../../../../common/components/player-batch-search/PlayersBatchSearch';

const PlayerSearchModal = ({
    visible,
    onCancel,
    setValidPlayers,
    isDownloadedApp = false,
    playerList,
}) => {
    // ============================================
    // METHODS
    // ============================================

    const getValidPlayersList = (userNames) => {
        userNames && setValidPlayers(userNames);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            centered
            width={700}
            closable={false}
            onCancel={onCancel}
            footer={null}
        >
            <PlayersBatchSearch
                getValidPlayersList={getValidPlayersList}
                isDownloadedApp={isDownloadedApp}
                userNames={playerList}
            />

            <Row justify="end">
                <ButtonTransparent
                    className="action-button"
                    label={getTranslation('Cancel')}
                    onClick={onCancel}
                />
            </Row>
        </Modal>
    );
};

export default PlayerSearchModal;
