import { MAX_TABLE_ROW_SELECT_LIMIT } from '../../common/constants/misc';
import { getTranslation } from '../../common/helpers/locale';
import { message } from 'antd';

export const ACTIONS = {
    HOT_EVENTS: 'HOT_EVENTS',
    HOT_EVENTS_CHATROOM_STATUS_UPDATE: 'HOT_EVENTS_CHATROOM_STATUS_UPDATE',
    HOT_EVENTS_CHATROOM_STATUS_UPDATE_FAILURE: 'HOT_EVENTS_CHATROOM_STATUS_UPDATE_FAILURE',
    HOT_EVENTS_CHATROOM_STATUS_UPDATE_SUCCESS: 'HOT_EVENTS_CHATROOM_STATUS_UPDATE_SUCCESS',
    HOT_EVENTS_DROP_FILTER: 'HOT_EVENTS_DROP_FILTER',
    HOT_EVENTS_FAILURE: 'HOT_EVENTS_FAILURE',
    HOT_EVENTS_FILTER_SET: 'HOT_EVENTS_FILTER_SET',
    HOT_EVENTS_LIST_GET: 'HOT_EVENTS_LIST_GET',
    HOT_EVENTS_LIST_GET_FAILURE: 'HOT_EVENTS_LIST_GET_FAILURE',
    HOT_EVENTS_LIST_GET_SUCCESS: 'HOT_EVENTS_LIST_GET_SUCCESS',
    HOT_EVENTS_RECORD_SELECT: 'HOT_EVENTS_RECORD_SELECT',
    HOT_EVENTS_SUCCESS: 'HOT_EVENTS_SUCCESS',
    OPERATION: 'HOT_EVENTS_OPERATION',
    OPERATION_FAILURE: 'HOT_EVENTS_OPERATION_FAILURE',
    OPERATION_SUCCESS: 'HOT_EVENTS_OPERATION_SUCCESS',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'FILES_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'FILES_TABLE_PAGE_SIZE_CHANGE',
    },
    REFRESH_TIME_PAUSE: 'HOT_EVENTS_LIST_REFRESH_TABLE_TIME_PAUSE',
    REFRESH_TIME_SET: 'HOT_EVENTS_LIST_REFRESH_TABLE_TIME_SET',
};

export const hotEventsGet = (dispatch) =>
    dispatch({
        type: ACTIONS.HOT_EVENTS,
    });

export const hotEventsListGet = (dispatch) =>
    dispatch({
        type: ACTIONS.HOT_EVENTS_LIST_GET,
    });

export const hotEventsListGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.HOT_EVENTS_LIST_GET_SUCCESS,
    });

export const hotEventsListGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.HOT_EVENTS_LIST_GET_FAILURE,
    });

export const selectRecord = (dispatch, payload) =>
    payload.length <= MAX_TABLE_ROW_SELECT_LIMIT
        ? dispatch({
              payload,
              type: ACTIONS.HOT_EVENTS_RECORD_SELECT,
          })
        : message.warning(
              getTranslation('You cannot select more than {limit} rows', {
                  limit: MAX_TABLE_ROW_SELECT_LIMIT,
              }),
          );

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const setRefreshTableTime = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.REFRESH_TIME_SET,
    });
};

export const pauseRefreshTableTime = (dispatch) => {
    dispatch({
        type: ACTIONS.REFRESH_TIME_PAUSE,
    });
};

export const performHotEventsOperation = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OPERATION,
    });

export const hotEventsOperationSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.OPERATION_SUCCESS,
    });

export const hotEventsOperationFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OPERATION_FAILURE,
    });

export const hotEventsChatroomStatusUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.HOT_EVENTS_CHATROOM_STATUS_UPDATE,
    });

export const hotEventsChatroomStatusUpdateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.HOT_EVENTS_CHATROOM_STATUS_UPDATE_SUCCESS,
    });

export const hotEventsChatroomStatusUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.HOT_EVENTS_CHATROOM_STATUS_UPDATE_FAILURE,
    });

export const hotEventsFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.HOT_EVENTS_FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.HOT_EVENTS_DROP_FILTER,
    });
