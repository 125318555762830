import React from 'react';

import _ from 'lodash';

import { LANGUAGES } from '../../../../common/constants/localization';
import { NEWS_CATEGORIES } from '../../constants';
import { RenderDateFormat } from '../../../../common/components/RenderDateFormat';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../../newsActions';
import { findTitleByLanguage, getTranslation } from '../../../../common/helpers/locale';
import { getStatusColor, sortTableByDataType } from '../../../../common/helpers/misc';
import { languageCodeMap } from '../../../../common/constants/misc';
import RowActionButtons from '../RowActionButton';

export const getColumns = (dispatch, langCode) => {
    // ============================================
    // METHODS
    // ============================================

    const getCurrentTags = (value) => {
        if (value) {
            return value.split(',');
        } else {
            return '';
        }
    };

    // ============================================
    // RENDER
    // ============================================

    const columns = [
        {
            dataIndex: 'publishedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Published Time'),
        },
        {
            dataIndex: 'newsStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>{value.toLocaleUpperCase()}</span>
            ),
            title: getTranslation('News Status'),
        },
        {
            dataIndex: 'title',
            filterMultiple: false,
            filters: _.map(LANGUAGES, (language) => {
                return {
                    text: language.name,
                    value: language.value,
                };
            }),
            render: (value, record) => {
                return (
                    <span>
                        {findTitleByLanguage(record.newsArticles, langCode)}
                        <br />
                        {record.newsArticles.map((lang) => {
                            return (
                                <span className="tag-border" key={lang.languageCode}>
                                    {languageCodeMap.get(lang.languageCode)}
                                </span>
                            );
                        })}
                    </span>
                );
            },
            title: getTranslation('Title'),
            width: '200px',
        },
        {
            dataIndex: 'hashTags',
            render: (value) =>
                _.map(getCurrentTags(value), (tags) => {
                    return (
                        <span className="tag-border" key={tags}>
                            {tags}
                        </span>
                    );
                }),
            title: getTranslation('Tags'),
        },
        {
            dataIndex: 'newsCategoryId',
            render: (value) => (
                <span>{_.find(NEWS_CATEGORIES, (category) => category.key === value).label}</span>
            ),
            title: getTranslation('Related Games'),
        },
        {
            dataIndex: 'author',
            title: getTranslation('Author'),
        },
        {
            dataIndex: 'rowaction',
            render: (value, record) => <RowActionButtons dispatch={dispatch} news={record} />,
            title: getTranslation('Action'),
            width: 260,
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
