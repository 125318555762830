import React from 'react';

import { ButtonTransparent } from '../../../../common/components/buttons/Buttons';
import { setBonusSelected } from '../bonusManagementActions';

const ViewRecordButton = ({ bonus, handleGetBannerById, openViewModal, dispatch }) => {
    // ============================================
    // METHODS
    // ============================================

    const openModal = () => {
        setBonusSelected(dispatch, { id: bonus.id });
        handleGetBannerById();
        openViewModal();
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <ButtonTransparent className="hyperlink-button" onClick={openModal}>
            {bonus.code}
        </ButtonTransparent>
    );
};

export default ViewRecordButton;
