import React from 'react';

import 'moment-timezone';
import Moment from 'react-moment';

import { getTranslation } from '../helpers/locale';

const RenderBettingDateFormat = ({ date, hideTime, timezone }) => {
    return date ? (
        <Moment format={hideTime ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm:ss'} utc tz={timezone.location}>
            {date}
        </Moment>
    ) : (
        <span>{getTranslation('Not processed')}</span>
    );
};

export { RenderBettingDateFormat };
