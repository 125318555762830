import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';

import { Card, Form, Modal, Row, message } from 'antd';
import _ from 'lodash';

import { ButtonGreen, ButtonTransparent } from '../../../common/components/buttons/Buttons';
import { REBATE_FORM_TYPE } from '../constants';
import {
    dropRebateLang,
    rebateDuplicateByLanguage,
    rebateFormReset,
    setMultiRebateInfo,
    setRebateInfo,
    setRebateInfoByLanguage,
    setRebateInit,
    setRebateLang,
} from './rebateSettingActions';
import {
    editorDefaultStylingSizeSet,
    getEndTime,
    getStartTime,
    getStateProp,
} from '../../../common/helpers/misc';
import { formValidation } from '../helpers';
import { getTranslation } from '../../../common/helpers/locale';
import { useCreateRebateAsyncEndpoint } from '../../../services/rebateService';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ROUTES } from '../../../common/constants/routing';
import { useStore } from '../../../store/StateProvider';
import RebateTabs from '../shared/RebateTabs';

const RebateSetting = () => {
    const [state, dispatch] = useStore();
    const history = useHistory();
    const rebateSetting = getStateProp(state, 'rebate.rebateSetting', {});
    const details = getStateProp(rebateSetting, 'details', []);
    const selectedLanguage = _.map(details, (value) => {
        return value.languageCode;
    }).sort();
    const location = getStateProp(state, 'timezone.location');

    // ============================================
    // METHODS
    // ============================================

    const onRebateInfoChange = (name, value) => {
        setRebateInfo(dispatch, { name, value });
    };

    const onRebateMultiInfoChange = (payload) => {
        setMultiRebateInfo(dispatch, payload);
    };

    const onContentChange = (name, value, langCode) => {
        setRebateInfoByLanguage(dispatch, {
            langCode,
            name,
            value: editorDefaultStylingSizeSet(value),
        });
    };

    const setRebateLanguage = (e) => {
        if (e.target.checked) {
            details.push({
                imageInBase64: null,
                languageCode: e.target.value,
                miniDesc: '',
                rebateDetails: '',
                rebateName: '',
            });

            setRebateLang(dispatch, details);
        } else {
            dropRebateLang(
                dispatch,
                _.reject(details, (content) => {
                    return content.languageCode === e.target.value;
                }),
            );
        }
    };

    const formReset = () =>
        rebateFormReset(dispatch, {
            endDateTime: getEndTime(location),
            startDateTime: getStartTime(location),
        });

    const submitForm = () =>
        formValidation(rebateSetting, REBATE_FORM_TYPE.CREATE) && handleCreateRebate();

    const duplicateRebateTabs = (values) => {
        const { languageCode, ...rest } = _.find(details, (detail) => {
            return detail.languageCode === values.from;
        });

        const detailsCopyTo = _.find(details, (detail) => {
            return detail.languageCode === values.to;
        });

        const { languageCode: langCode, ...restCopyTo } = detailsCopyTo;

        if (_.every(restCopyTo, _.isEmpty)) {
            _.assign(detailsCopyTo, rest);
            rebateDuplicateByLanguage(dispatch, details);
            message.success('Copied!');
        } else {
            Modal.confirm({
                content: getTranslation(
                    'Destination copy to is not empty! Are you sure want to copy ?',
                ),
                icon: <ExclamationCircleOutlined />,
                onCancel() {
                    return false;
                },
                onOk() {
                    _.assign(detailsCopyTo, rest);
                    rebateDuplicateByLanguage(dispatch, details);
                    message.success('Copied!');
                },
                title: getTranslation('Are you sure want to copy ?'),
            });
        }
    };

    // ============================================
    // HOOKS
    // ============================================

    const [creating, handleCreateRebate, created] = useCreateRebateAsyncEndpoint();

    useEffect(() => {
        if (created) {
            history.push(ROUTES.DASHBOARD.REBATE.MANAGEMENT);
        }
    }, [created, history]);

    useEffect(() => {
        setRebateInit(dispatch, {
            endDateTime: getEndTime(location),
            startDateTime: getStartTime(location),
        });
    }, [dispatch, location]);
    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Rebate Setting')}>
            <Form name="rebate-setting" className="rebate-setting__form" onFinish={submitForm}>
                <RebateTabs
                    setRebateLanguage={setRebateLanguage}
                    selectedLangs={selectedLanguage}
                    rebateInfo={rebateSetting}
                    isEdit={false}
                    onRebateInfoChange={onRebateInfoChange}
                    onRebateContentChange={onContentChange}
                    onDuplicateRebate={duplicateRebateTabs}
                    onRebateMultiInfoChange={onRebateMultiInfoChange}
                />
                <Row className="form__footer-row" justify="end">
                    <ButtonGreen
                        label={getTranslation('Submit')}
                        className="rebate-setting__form__submit-button"
                        htmlType="submit"
                        style={{ marginRight: '5px' }}
                        loading={creating}
                    />

                    <ButtonTransparent
                        label={getTranslation('Cancel')}
                        className="rebate-setting__form__reset-button"
                        onClick={formReset}
                    />
                </Row>
            </Form>
        </Card>
    );
};

export default RebateSetting;
