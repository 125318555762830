import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Input, Row } from 'antd';

import './WithdrawalRequestForm.less';
import { ButtonGreen } from '../../../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../../../common/helpers/locale';
import { useWithdrawalRequestAsyncEndpoint } from '../../../../../../services/playerService';

const { TextArea } = Input;

const WithdrawalRequestForm = ({ onCancel, card }) => {
    const [form] = Form.useForm();
    const { resetFields } = form;
    const [formValues, setFormValues] = useState({});
    // ============================================
    // METHODS
    // ============================================

    const submitForm = (values) => {
        setFormValues(values);
        requestWithdrawal();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [
        withdrawalRequesting,
        requestWithdrawal,
        withdrawalRequested,
    ] = useWithdrawalRequestAsyncEndpoint({ ...formValues, ...card });

    useEffect(() => {
        if (withdrawalRequested) {
            resetFields();
            onCancel();
        }
    }, [onCancel, withdrawalRequested, resetFields]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form
            form={form}
            name="withdrawal-request"
            className="withdrawal-request__form"
            onFinish={submitForm}
        >
            <Row className="form__group">
                <Col span={6} align="start">
                    <span className="withdrawal-request__form__label">
                        {getTranslation('Withdrawal Amount')}
                        <span style={{ color: 'red' }}>*</span>:
                    </span>
                </Col>
                <Col span={18}>
                    <Form.Item
                        name="amount"
                        rules={[
                            {
                                message: getTranslation('Please fill in this field!'),
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={6} align="start">
                    <span className="withdrawal-request__form__label">
                        {getTranslation('Remark')}
                        <span style={{ color: 'red' }}>*</span>:
                    </span>
                </Col>
                <Col span={18}>
                    <Form.Item
                        name="remarks"
                        rules={[
                            {
                                message: getTranslation('Please fill in this field!'),
                                required: true,
                            },
                        ]}
                    >
                        <TextArea rows={3} />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="end" className="form__group">
                <ButtonGreen htmlType="submit" loading={withdrawalRequesting}>
                    {getTranslation('Submit')}
                </ButtonGreen>
                <Button onClick={onCancel}>{getTranslation('Cancel')}</Button>
            </Row>
        </Form>
    );
};
export default WithdrawalRequestForm;
