import React from 'react';

import { Row } from 'antd';

import {
    BankAccountNameFilter,
    PassageFilter,
    SpecialMemberFilter,
    WebShowFilter,
} from '../filters';
import {
    BankCardFilter,
    BankFilter,
    CurrencyFilter,
    MemberLevelFilter,
    TrustLevelFilter,
} from '../../../common/components/filters';
import { bankCardConfigurationFilterSet } from './bankAccountConfigurationActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';

const BankAccountConfigurationFilters = () => {
    const [state, dispatch] = useStore();

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) =>
        bankCardConfigurationFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <BankFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'payments.bankAccountConfiguration.filters.bankName')}
                placeholder={getTranslation('Bank')}
            />
            <BankAccountNameFilter
                value={getStateProp(
                    state,
                    'payments.bankAccountConfiguration.filters.cardHolderName',
                )}
                onFilterChange={setFilter}
            />
            <BankCardFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'payments.bankAccountConfiguration.filters.bankCardNo')}
                name="bankCardNo"
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(
                    state,
                    'payments.bankAccountConfiguration.filters.currencyCode',
                )}
                name="currencyCode"
            />
            <SpecialMemberFilter
                value={getStateProp(
                    state,
                    'payments.bankAccountConfiguration.filters.specialMember',
                )}
                onFilterChange={setFilter}
            />
            <MemberLevelFilter
                value={getStateProp(state, 'payments.bankAccountConfiguration.filters.memberLevel')}
                onFilterChange={setFilter}
            />
            <TrustLevelFilter
                value={getStateProp(state, 'payments.bankAccountConfiguration.filters.trustLevel')}
                onFilterChange={setFilter}
            />
            <WebShowFilter
                value={getStateProp(state, 'payments.bankAccountConfiguration.filters.isWebShow')}
                onFilterChange={setFilter}
            />
            <PassageFilter
                value={getStateProp(state, 'payments.bankAccountConfiguration.filters.passage')}
                onFilterChange={setFilter}
            />
        </Row>
    );
};

export default BankAccountConfigurationFilters;
