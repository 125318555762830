export const ACTIONS = {
    BANK_CLEAR: 'BANK_CLEAR',
    BANK_GET: 'BANK_GET',
    BANK_GET_FAILURE: 'BANK_GET_FAILURE',
    BANK_GET_SUCCESS: 'BANK_GET_SUCCESS',
};

export const bankGet = (dispatch) =>
    dispatch({
        type: ACTIONS.BANK_GET,
    });

export const bankClear = (dispatch) =>
    dispatch({
        type: ACTIONS.BANK_CLEAR,
    });

export const bankGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANK_GET_SUCCESS,
    });

export const bankGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANK_GET_FAILURE,
    });
