import React from 'react';

import { Col, Row } from 'antd';
import _ from 'lodash';

import { ButtonBlue } from '../../../../common/components/buttons/Buttons';
import { E_WALLET_MODAL_TYPES } from '../constants';
import { closeEWalletModal, openEWalletModal } from '../playerDetailsActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useGetPlayerByIdAsyncEndpoint } from '../../../../services/playerService';
import { useStore } from '../../../../store/StateProvider';
import EWalletModal from './EWalletModal';
import EWalletResultsTable from './table/EWalletResultsTable';

const EWallet = (props) => {
    const { playerId } = props.match.params;
    const [state, dispatch] = useStore();
    const eWalletModal = getStateProp(state, 'players.playerDetails.eWalletModal', {});
    const eWalletAccounts = getStateProp(
        state,
        'players.playerDetails.playerInfo.monetary.eWalletAccounts',
        [],
    );

    const showAddEWallet = _.takeWhile(eWalletAccounts, ['status', 'Active']).length < 3;

    // ============================================
    // METHODS
    // ============================================
    const handleAddEWallet = () =>
        openEWalletModal(dispatch, { modalType: E_WALLET_MODAL_TYPES.ADD_E_WALLET });

    const handleCloseEWalletModal = () => {
        closeEWalletModal(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    const [playerInfoLoading, handleGetPlayerById] = useGetPlayerByIdAsyncEndpoint(playerId);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col span={24}>
            {showAddEWallet && (
                <Row align="middle" justify="end" style={{ paddingBottom: 20 }}>
                    <ButtonBlue label={getTranslation('Add E-Wallet')} onClick={handleAddEWallet} />
                </Row>
            )}
            <EWalletResultsTable loading={playerInfoLoading} />
            {eWalletModal.isVisible && (
                <EWalletModal
                    eWalletModal={eWalletModal}
                    onCancel={handleCloseEWalletModal}
                    getPlayerInfo={handleGetPlayerById}
                />
            )}
        </Col>
    );
};

export default EWallet;
