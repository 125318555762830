import { ACTIONS } from './cryptocurrencyCorrectionActions';

export default function cryptocurrencyCorrectionReducer(
    cryptoCurrencyCorrection = {
        cryptoCurrencyCorrectionInfo: { amount: 0, turnoverRequirement: 1 },
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.CRYPTO_CURRENCY_CORRECTION_INFO_SET:
            return {
                ...cryptoCurrencyCorrection,
                cryptoCurrencyCorrectionInfo: {
                    ...cryptoCurrencyCorrection.cryptoCurrencyCorrectionInfo,
                    [payload.inputName]: payload.value,
                },
            };
        case ACTIONS.CRYPTO_CURRENCY_CORRECTION_USERNAME_GET_SUCCESS:
            return {
                ...cryptoCurrencyCorrection,
                cryptoCurrencyCorrectionInfo: {
                    ...cryptoCurrencyCorrection.cryptoCurrencyCorrectionInfo,
                    ...payload,
                },
            };

        case ACTIONS.CRYPTO_CURRENCY_CORRECTION_NEW_RATE_GET_SUCCESS:
        case ACTIONS.CRYPTO_CURRENCY_CORRECTION_TRANSACTION_GET_SUCCESS:
            return {
                ...cryptoCurrencyCorrection,
                cryptoCurrencyCorrectionInfo: {
                    ...cryptoCurrencyCorrection.cryptoCurrencyCorrectionInfo,
                    ...payload,
                },
            };
        case ACTIONS.CRYPTO_CURRENCY_CORRECTION_FORM_RESET:
            return {
                ...cryptoCurrencyCorrection,
                cryptoCurrencyCorrectionInfo: {
                    turnoverRequirement: 1,
                },
            };

        case ACTIONS.CRYPTO_CURRENCY_CORRECTION_OPERATION:
            return {
                ...cryptoCurrencyCorrection,
                ...payload,
            };
        case ACTIONS.CRYPTO_CURRENCY_CORRECTION_OPERATION_SUCCESS:
            return {
                ...cryptoCurrencyCorrection,
                operation: null,
            };

        default:
            return cryptoCurrencyCorrection;
    }
}
