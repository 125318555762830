import React, { useEffect, useState } from 'react';

import { Card, Col, Form, Input, Row } from 'antd';
import _ from 'lodash';

import './NewsCreate.less';
import {
    ButtonBlue,
    ButtonGreen,
    ButtonTransparent,
} from '../../../common/components/buttons/Buttons';
import { NEWS_CATEGORIES, NEWS_STATUSES } from '../constants';
import { clearNewsDraft, setNewsLanguage, updateNewsArticle, updateNewsInfo } from '../newsActions';
import { editorDefaultStylingSizeSet, getStateProp } from '../../../common/helpers/misc';
import { formValidation } from '../helpers';
import { getTranslation } from '../../../common/helpers/locale';
import { useAddNewsAsyncEndpoint, useUpdateNewsAsyncEndpoint } from '../../../services/newsService';

import { ROUTES } from '../../../common/constants/routing';
import { useHistory } from 'react-router';
import { useStore } from '../../../store/StateProvider';
import CategorySelect from '../controls/CategorySelect';
import ImageEditor from '../../../common/components/ImageEditor';
import LanguagesCheckboxes from '../../../common/components/LanguagesCheckboxes';
import NewsStatusSelect from '../controls/NewsStatusSelect';
import NewsTabs from './NewsTabs';
import TagInput from '../controls/TagInput';
import VerifyModal from './modal/VerifyModal';

const NewsCreate = () => {
    const [state, dispatch] = useStore();
    const [verifyModalVisible, setVerifyModalVisible] = useState(false);
    const history = useHistory();
    const newsDraftId = getStateProp(state, 'news.newsDraft.id');
    const newsDraft = getStateProp(state, 'news.newsDraft', {});
    const newsArticles = getStateProp(state, 'news.newsDraft.newsArticles', []);
    const selectedCategory = _.find(
        NEWS_CATEGORIES,
        (category) => category === getStateProp(newsDraft, 'newsCategory'),
    );
    const selectedLanguage = _.map(newsArticles, (value) => {
        return value.languageCode;
    }).sort();

    // ============================================
    // METHODS
    // ============================================

    const selectNewsLanguages = (e) => {
        if (e.target.checked) {
            newsArticles.push({
                content: '',
                languageCode: e.target.value,
                title: '',
            });

            setNewsLanguage(dispatch, newsArticles);
        } else {
            setNewsLanguage(
                dispatch,
                _.reject(newsArticles, (content) => {
                    return content.languageCode === e.target.value;
                }),
            );
        }
    };

    const onContentChange = (name, value, langCode) => {
        updateNewsArticle(dispatch, {
            langCode,
            name,
            value: editorDefaultStylingSizeSet(value),
        });
    };

    const getNewsInfoValue = (name, value) => {
        switch (name) {
            case 'hashTags':
                return value.join(',');
            case 'newsCategory':
                return _.find(NEWS_CATEGORIES, (category) => category.label === value);
            default:
                return value;
        }
    };

    const onNewsInfoChange = (name, value) => {
        if (name === 'imageInBase64' && value === null)
            updateNewsInfo(dispatch, {
                name: 'imageUrl',
                value,
            });

        updateNewsInfo(dispatch, {
            name,
            value: getNewsInfoValue(name, value),
        });
    };

    const getCurrentTags = () => {
        const draftTags = getStateProp(state, 'news.newsDraft.hashTags');
        if (draftTags) return draftTags.split(',');
        else return '';
    };

    const submitForm = () => {
        if (formValidation(newsDraft, newsDraftId))
            newsDraftId ? handleUpdateNews() : handleAddNews();
    };

    const resetForm = () => clearNewsDraft(dispatch);

    const openVerifyModal = () => {
        if (formValidation(newsDraft, newsDraftId)) setVerifyModalVisible(true);
    };

    const closeVerifyModal = () => setVerifyModalVisible(false);

    const onBackToList = () => history.push(ROUTES.DASHBOARD.NEWS.LIST);

    // ============================================
    // HOOKS
    // ============================================

    const [adding, added, handleAddNews] = useAddNewsAsyncEndpoint();
    const [updating, updated, handleUpdateNews] = useUpdateNewsAsyncEndpoint();

    useEffect(() => {
        if (added || updated) {
            history.push(ROUTES.DASHBOARD.NEWS.LIST);
        }
        return () => clearNewsDraft(dispatch);
    }, [added, dispatch, history, updated]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card
            title={newsDraftId ? getTranslation('Edit News') : getTranslation('Create News')}
            className="news-card"
        >
            <Form name="news-form" className="news__form" onFinish={submitForm}>
                <Row className="news__form-row">
                    <Col span={6}>
                        <label className="news-filter">
                            {getTranslation('Status')} <span className="form__required">*</span>
                        </label>
                    </Col>
                    <Col span={8}>
                        <Form.Item>
                            <NewsStatusSelect
                                className="news-filter"
                                value={newsDraft.newsStatus}
                                onStatusChange={(value) => onNewsInfoChange('newsStatus', value)}
                                disabled={!newsDraftId}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row className="news__form-row">
                    <Col span={6}>
                        <label className="news-filter">
                            {getTranslation('Related Game')}
                            <span className="form__required">*</span>
                        </label>
                    </Col>
                    <Col span={8}>
                        <Form.Item>
                            <CategorySelect
                                className="news-filter"
                                onCategoryChange={(value) =>
                                    onNewsInfoChange('newsCategory', value.value)
                                }
                                value={selectedCategory}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row className="news__form-row">
                    <Col span={6}>
                        <label className="news-filter">
                            {getTranslation('Change Picture')}
                            <span className="form__required">*</span>
                        </label>
                    </Col>
                    <Col span={8}>
                        <Form.Item>
                            <ImageEditor
                                onImageChanged={(imageInBase64) =>
                                    onNewsInfoChange('imageInBase64', imageInBase64)
                                }
                                image={newsDraft.imageInBase64 || newsDraft.imageUrl}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row className="news__form-row">
                    <Col span={6}>
                        <label className="news-filter">
                            {getTranslation('Author')} <span className="form__required">*</span>
                        </label>
                    </Col>
                    <Col span={8}>
                        <Form.Item>
                            <Input
                                onChange={(e) => onNewsInfoChange('author', e.target.value)}
                                value={newsDraft.author}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row className="news__form-row">
                    <Col span={6}>
                        <label className="news-filter">{getTranslation('Tags')}</label>
                    </Col>
                    <Col span={8}>
                        <TagInput
                            value={getCurrentTags()}
                            onTagsChanged={(value) => onNewsInfoChange('hashTags', value)}
                        />
                    </Col>
                </Row>

                <Row className="news__form-row">
                    <Col span={6}>
                        <label className="news-filter">{getTranslation('Languages')}</label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={16}>
                        <Form.Item>
                            <LanguagesCheckboxes
                                onCheckedLanguages={selectNewsLanguages}
                                value={selectedLanguage}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row className="news__form-row">
                    <NewsTabs
                        newsArticles={newsArticles}
                        selectedLanguage={selectedLanguage}
                        onContentChange={onContentChange}
                    />
                </Row>

                <Row className="news__form-footer-row" justify="end">
                    {newsDraftId && (
                        <ButtonBlue
                            label={getTranslation('Verify')}
                            onClick={openVerifyModal}
                            disabled={newsDraft.newsStatus === NEWS_STATUSES.PUBLISHED}
                            style={{ marginRight: '5px' }}
                        />
                    )}

                    <ButtonGreen
                        label={getTranslation(newsDraftId ? 'Edit News' : 'Add News')}
                        className="news__form__submit-button"
                        htmlType="submit"
                        loading={adding || updating}
                        style={{ marginRight: '5px' }}
                    />

                    <ButtonTransparent
                        label={getTranslation('Cancel')}
                        className="news__form__reset-button"
                        onClick={newsDraftId ? onBackToList : resetForm}
                    />
                </Row>
            </Form>

            <VerifyModal
                visible={verifyModalVisible}
                onCancel={closeVerifyModal}
                loading={updating}
                handleVerifyNews={handleUpdateNews}
            />
        </Card>
    );
};

export default NewsCreate;
