import React from 'react';

import { Modal } from 'antd';

import { getTranslation } from '../../../../../common/helpers/locale';
import AnnouncementViewForm from '../../view-modal/AnnouncementViewForm';

const ViewAnnouncementModal = ({ visible, onCancel, onOpenDeleteModal }) => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            width={1080}
            visible={visible}
            title={getTranslation('View Global Announcement')}
            className="announcement__view-modal"
            onCancel={onCancel}
            closable={false}
            footer={null}
        >
            <AnnouncementViewForm onDelete={onOpenDeleteModal} onCancel={onCancel} />
        </Modal>
    );
};

export default ViewAnnouncementModal;
