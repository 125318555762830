import React from 'react';

import { Card } from 'antd';

import { getTranslation } from '../../../common/helpers/locale';
import {
    useBalanceManagementOperation,
    useGetPlayerDetailsAsyncEndpoint,
} from '../../../services/balanceManagementService';
import BalanceManagementForm from './BalanceManagementForm';

const BalanceManagement = () => {
    // ============================================
    // HOOKS
    // ============================================

    const [getPlayerDetailsLoading] = useGetPlayerDetailsAsyncEndpoint();
    const [operationLoading, operationSuccess] = useBalanceManagementOperation();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Balance Management')} className="balance-management-card">
            <BalanceManagementForm
                operationLoading={operationLoading}
                operationSuccess={operationSuccess}
                playerDetailsLoading={getPlayerDetailsLoading}
            />
        </Card>
    );
};

export default BalanceManagement;
