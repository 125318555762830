import { useCallback, useEffect, useState } from 'react';

import { API } from '../common/constants/api';
import { clearError } from '../common/actions/errorActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import {
    referralRecordsGet,
    referralRecordsGetFailure,
    referralRecordsGetSuccess,
} from '../components/transaction-history/referral-records/referralRecordsActions';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, TRANSACTION_HISTORY } = API;

export function useGetReferralRecordsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { referralRecords } = getStateProp(state, 'transactionHistory');
    const { pageNo, pageSize } = getStateProp(referralRecords, 'paging');
    const filters = getStateProp(referralRecords, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetReferralRecords = useCallback(() => {
        referralRecordsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${TRANSACTION_HISTORY.REFERRAL_RECORDS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            referralRecordsGetSuccess(dispatch, {
                fetchData: handleGetReferralRecords,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetReferralRecords, response]);

    useEffect(() => {
        handleGetReferralRecords();
    }, [handleGetReferralRecords, pageNo, pageSize]);

    const errorMsg = useError(error, referralRecordsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetReferralRecords];
}
