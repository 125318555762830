import React from 'react';

import { Row } from 'antd';

import {
    AccountSetupTypeFilter,
    BankAccountNameFilter,
    LoginIdFilter,
    PassageFilter,
} from '../filters';
import { BankCardFilter, BankFilter, CurrencyFilter } from '../../../common/components/filters';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';

const BankAccountSetupFilters = ({ setFilter, bankAccount }) => {
    return (
        <Row justify="start" align="middle" className="filters-container">
            <BankFilter
                onFilterChange={setFilter}
                value={getStateProp(bankAccount, 'filters.bankName')}
                placeholder={getTranslation('Bank')}
            />
            <BankCardFilter
                onFilterChange={setFilter}
                value={getStateProp(bankAccount, 'filters.bankCardNo')}
                name="bankCardNo"
            />
            <BankAccountNameFilter
                value={getStateProp(bankAccount, 'filters.cardHolderName')}
                onFilterChange={setFilter}
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(bankAccount, 'filters.currencyCode')}
                name="currencyCode"
            />
            <LoginIdFilter
                value={getStateProp(bankAccount, 'filters.loginId')}
                onFilterChange={setFilter}
            />
            <AccountSetupTypeFilter
                onFilterChange={setFilter}
                value={getStateProp(bankAccount, 'filters.type')}
            />
            <PassageFilter
                value={getStateProp(bankAccount, 'filters.passage')}
                onFilterChange={setFilter}
            />
        </Row>
    );
};

export default BankAccountSetupFilters;
