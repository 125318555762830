import React, { useEffect } from 'react';
import _ from 'lodash';

import { Card, Col, Form, Modal, Row, message } from 'antd';

import { ButtonGreen, ButtonTransparent } from '../../../common/components/buttons/Buttons';
import {
    changeModalPageNumber,
    changeModalPageSize,
    editReferralInfo,
    referralEditModalClose,
} from './referralManagementAction';
import { formValidation } from '../misc';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';
import { useUpdateReferralAsyncEndpoint } from '../../../services/referralService';
import ReferralDetail from '../ReferralDetail';

const EditReferralContentModal = ({ visible, id, getReferralByIdLoading }) => {
    const [state, dispatch] = useStore();
    const [form] = Form.useForm();

    const referralInfo = getStateProp(state, 'referral.referralManagement.selectedReferral', {});
    const paging = getStateProp(state, 'referral.referralManagement.modalPaging', {});

    // ============================================
    // METHODS
    // ============================================

    const updateForm = () => {
        let validation = formValidation(referralInfo);
        if (validation.validate) {
            handleUpdateReferral();
        } else {
            message.error(validation.message);
        }
    };

    const closeEditModal = () => referralEditModalClose(dispatch);
    const onChangePageNo = (dispatch) => (pageNo) => changeModalPageNumber(dispatch, pageNo);
    const onChangePageSize = (dispatch) => (_, pageSize) => changeModalPageSize(dispatch, pageSize);

    // ============================================
    // HOOKS
    // ============================================
    const [loading, handleUpdateReferral, updated] = useUpdateReferralAsyncEndpoint();

    useEffect(() => {
        if (updated) {
            referralEditModalClose(dispatch);
        }
    }, [dispatch, updated]);

    useEffect(() => {
        _.map(referralInfo.plans, (e) => {
            e.details = _.sortBy(e.details, ['tierLevel']);
        });
    }, [referralInfo]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            width={1080}
            visible={visible}
            className="referral__edit-modal"
            onCancel={closeEditModal}
            closable={false}
            footer={null}
        >
            <Card title={getTranslation('Edit Referral Program')} loading={getReferralByIdLoading}>
                <Form
                    form={form}
                    name="create-referral"
                    className="create-referral__form"
                    onFinish={updateForm}
                >
                    <ReferralDetail
                        referralInfo={referralInfo}
                        paging={paging}
                        setReferralInfo={editReferralInfo}
                        onChangePageNo={onChangePageNo}
                        onChangePageSize={onChangePageSize}
                    />
                    <Row className="form__footer-row" justify="end">
                        <Col>
                            <ButtonGreen
                                label={getTranslation('Update')}
                                className="form__submit-button"
                                loading={loading}
                                htmlType="submit"
                            />
                        </Col>
                        <Col>
                            <ButtonTransparent
                                label={getTranslation('Cancel')}
                                className="form__reset-button"
                                onClick={closeEditModal}
                            />
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Modal>
    );
};

export default EditReferralContentModal;
