import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import React from 'react';

import { Card, Row } from 'antd';

import './MerchantSetup.less';
import { ButtonBlue } from '../../../common/components/buttons/Buttons';
import { PrivateRoute } from '../../../common/components';
import { ROUTES } from '../../../common/constants/routing';
import { getTranslation } from '../../../common/helpers/locale';
import ActiveMerchant from './active-merchant/ActiveMerchant';
import DeactivatedMerchant from './deactivated-merchant/DeactivatedMerchant';

const MerchantSetup = () => {
    const history = useHistory();
    const location = useLocation();
    // ============================================
    // METHODS
    // ============================================

    const isActive = (subRoute) =>
        location.pathname.replace('/dashboard/payments/merchant-setup/', '') === subRoute;

    const onTabChange = (key) => history.push(key);

    // ============================================
    // RENDER
    // ============================================

    const AspectButton = (
        <Row align="middle" justify="space-between">
            <ButtonBlue
                active={isActive('active')}
                onClick={() => onTabChange('active')}
                className="tab-button"
            >
                {getTranslation('Activate')}
            </ButtonBlue>

            <ButtonBlue
                active={isActive('deactivated')}
                onClick={() => onTabChange('deactivated')}
                className="tab-button"
            >
                {getTranslation('Deactivated')}
            </ButtonBlue>
        </Row>
    );

    return (
        <Card title={getTranslation('Merchant Setup')} extra={AspectButton}>
            <Switch>
                <PrivateRoute
                    path={ROUTES.DASHBOARD.PAYMENTS.MERCHANT_ACCOUNT.ACTIVE}
                    component={ActiveMerchant}
                />
                <PrivateRoute
                    path={ROUTES.DASHBOARD.PAYMENTS.MERCHANT_ACCOUNT.DEACTIVATED}
                    component={DeactivatedMerchant}
                />
                <Redirect to={ROUTES.DASHBOARD.PAYMENTS.MERCHANT_ACCOUNT.ACTIVE} />
            </Switch>
        </Card>
    );
};

export default MerchantSetup;
