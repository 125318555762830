import React from 'react';

import { Table } from 'antd';

import { EWALLET_WITHDRAWAL_TABLE_COLUMNS } from '../constants';
import { getColumns, getPaginationOptions } from './EwalletWithdrawalTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const WithdrawalResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const withdrawals = getStateProp(state, 'payments.ewalletWithdrawals');
    const tableData = getStateProp(withdrawals, 'list', []);
    const paging = getStateProp(withdrawals, 'paging', {});
    const selectedColumns = getStateProp(
        withdrawals,
        'selectedColumns',
        EWALLET_WITHDRAWAL_TABLE_COLUMNS,
    );

    return (
        <Table
            columns={getColumns(dispatch, selectedColumns)}
            scroll={{ x: true }}
            rowKey={(record) => record.transactionId}
            dataSource={tableData}
            size="small"
            align="left"
            pagination={getPaginationOptions(paging, dispatch)}
            loading={loading}
            bordered
        />
    );
};

export default WithdrawalResultsTable;
