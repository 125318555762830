import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './UserSessionsTableMeta';
import { getStateProp } from '../../../../../common/helpers/misc';
import { useStore } from '../../../../../store/StateProvider';

const UserSessionResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();
    const tableData = getStateProp(state, 'players.playerDetails.userSessions.list', []);
    const paging = getStateProp(state, 'players.playerDetails.userSessions.paging', {});

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            id="user-sessions-table"
            columns={getColumns(paging.pageNo)}
            pagination={getPaginationOptions(paging, dispatch)}
            dataSource={tableData}
            rowKey={(record) => record.activatedAt}
            loading={loading}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
        />
    );
};

export default UserSessionResultsTable;
