import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';

import _ from 'lodash';

import { ROUTES, routeNamesMap } from '../../common/constants/routing';
import { isUserLoggedIn } from '../helpers/api';

const PrivateRoute = ({ component: Component, path, hasAccessControl = false, ...rest }) => {
    const menus = JSON.parse(localStorage.getItem('menus'));

    const isUserHaveAccess = () => {
        const routeKey = routeNamesMap.get(path);

        return (
            _.find(menus, { subMenus: [{ menuName: routeKey }] }) !== undefined ||
            _.find(menus, { menuUrl: _.replace(path, '/', '') }) !== undefined ||
            path === '/dashboard' ||
            hasAccessControl
        );
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Route
            {...rest}
            render={(routeProps) =>
                isUserLoggedIn() ? (
                    isUserHaveAccess() ? (
                        <Component {...routeProps} />
                    ) : (
                        <Redirect to={ROUTES.DASHBOARD.ROOT} />
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: ROUTES.LOGIN,
                            state: { from: routeProps.location },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
