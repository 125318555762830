import _ from 'lodash';

import { ACTIONS } from './rebateSettingActions';
import { getDefaultContent } from '../helpers';

export default function rebateSettingReducer(
    rebateSetting = {
        details: getDefaultContent(),
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.REBATE_INIT:
            return {
                ...rebateSetting,
                ...payload,
            };

        case ACTIONS.REBATE_FORM_RESET:
            return {
                details: getDefaultContent(),
                ...payload,
            };

        case ACTIONS.REBATE_INFO_SETTING:
            return {
                ...rebateSetting,
                [payload.name]: payload.value,
            };

        case ACTIONS.SET_REBATE_INFO_BY_LANGUAGE: {
            _.map(rebateSetting.details, (value) => {
                if (value.languageCode === payload.langCode) {
                    value[payload.name] = payload.value;
                }
                return value;
            });

            return {
                ...rebateSetting,
                details: [...rebateSetting.details],
            };
        }

        case ACTIONS.REBATE_DUPLICATE:
            return payload;

        case ACTIONS.REBATE_LANGUAGE_SET:
        case ACTIONS.REBATE_LANGUAGE_DROP:
        case ACTIONS.REBATE_DUPLICATE_BY_LANGUAGE:
            return {
                ...rebateSetting,
                details: payload,
            };

        case ACTIONS.REBATE_MULTI_INFO_SETTING:
            return {
                ...rebateSetting,
                ...payload,
            };

        case ACTIONS.REBATE_CREATE:
        default:
            return rebateSetting;
    }
}
