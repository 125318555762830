import React from 'react';

import { ButtonGray, ButtonGreen } from '../../../../common/components/buttons/Buttons';
import { PAYMENT_ORDER_OPERATIONS, TRANSACTION_STATUSES } from '../../../../common/constants/misc';
import { getCurrentUser } from '../../../../common/helpers/api';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { openVerifyModal, performWithdrawalRecordOperation } from '../withdrawalRecordsActions';
import { useStore } from '../../../../store/StateProvider';

const VerifyButton = ({ withdrawalRecord }) => {
    const [state, dispatch] = useStore();

    const {
        transactionStatus,
        attendantRealName,
        attendantUserName,
        operatorName,
        auditorName,
    } = withdrawalRecord;

    const disabled = [TRANSACTION_STATUSES.REJECTED, TRANSACTION_STATUSES.SUCCESS].includes(
        transactionStatus,
    );

    const operator = getStateProp(
        state,
        'user.userName',
        getStateProp(getCurrentUser(), 'claims.userName', 'Anonymus'), //Temp fix
    );

    // ============================================
    // METHODS
    // ============================================

    const getLabel = () => {
        switch (transactionStatus) {
            case TRANSACTION_STATUSES.ATTENDING:
                return `${getTranslation('Reviewing')} ${attendantRealName}`;
            case TRANSACTION_STATUSES.REJECTED:
            case TRANSACTION_STATUSES.SUCCESS:
                return `${getTranslation('Verified')} ${operatorName}`;
            default:
                getTranslation('Verify');
        }
    };

    const openOperationModal = () => {
        performWithdrawalRecordOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.ATTEND,
            withdrawalRecord,
        });
        openVerifyModal(dispatch);
    };

    // ============================================
    // RENDER
    // ============================================

    return (attendantUserName !== operator &&
        transactionStatus === TRANSACTION_STATUSES.ATTENDING) ||
        (disabled && operatorName) ? (
        <ButtonGray disabled label={getLabel()} className="action-btn__verify" />
    ) : auditorName ? (
        ''
    ) : (
        <ButtonGreen
            label={getTranslation('Verify')}
            onClick={openOperationModal}
            className="action-btn__verify"
        />
    );
};

export default VerifyButton;
