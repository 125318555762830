export const PRODUCTS = {
    ESPORT: 'ESPORT',
    LIVE_CASINO: 'LIVE CASINO',
    LOTTERY: 'LOTTERY',
    NUMBER_GAME: 'NUMBER GAME',
    RNG: 'RNG',
    SMART: 'SMART',
    SPORT: 'SPORT',
    VIRTUAL_GAME: 'VIRTUAL GAME',
    VIRTUAL_SPORT: 'VIRTUAL SPORT',
};

export const PLATFORMS = {
    ALLBET: 'AllBet',
    ASIA_GAMING: 'Asia Gaming',
    AVIA: 'AVIA',
    BTI: 'BTi Sport',
    DIRESPORT: 'Dire Esports',
    GD: 'Gold Deluxe',
    GGBOOK: 'GGBook',
    MG: 'Macau Games',
    PLAYTECH: 'Playtech',
    PP: 'Pragmatic Play',
    SABA: 'SABA',
    SEXYGAMING: 'Sexy Gaming',
    SG: 'Spade Gaming',
};
