import React from 'react';

import { Card, Form } from 'antd';

import { dropFilters } from './referrerReportActions';
import { getTranslation } from '../../../common/helpers/locale';
import { useDispatch } from '../../../store/StateProvider';
import { useGetReferrerReportAsyncEndpoint } from '../../../services/referralService';
import ActionButtons from './ActionButtons';
import ReferrerReportFilters from './ReferrerReportFilters';
import ReferrerReportResultsTable from './table/ReferrerReportResultsTable';

const ReferrerReport = () => {
    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetReferrerReport();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetReferrerReport] = useGetReferrerReportAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Referrer Report')}>
            <Form onFinish={handleGetReferrerReport}>
                <ReferrerReportFilters />
                <ActionButtons fetchAll={fetchAll} />
            </Form>

            <ReferrerReportResultsTable loading={loading} />
        </Card>
    );
};

export default ReferrerReport;
