import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './VipHistoryTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const VipHistoryResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const { vipHistory } = getStateProp(state, 'vip');
    const tableData = getStateProp(vipHistory, 'list', []);
    const paging = getStateProp(vipHistory, 'paging', {});

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns()}
            size="small"
            scroll={{ x: true }}
            align="left"
            dataSource={tableData}
            rowKey={(record, index) => index}
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
            bordered
        />
    );
};

export default VipHistoryResultsTable;
