import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Input, Row, Select } from 'antd';
import _ from 'lodash';

import './LevelForm.less';
import { ButtonGreen } from '../../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useCreateModificationAsyncEndpoint } from '../../../../../services/playerService';
import { userLevelsMap } from '../../constants';

const LevelForm = ({ userId, userName, fieldName, defaultValue, onCancel }) => {
    const [form] = Form.useForm();
    const { resetFields } = form;
    const [formValues, setFormValues] = useState({});

    // ============================================
    // METHODS
    // ============================================

    const submitForm = (values) => {
        setFormValues(values);
        handleCreateModification();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [
        loading,
        handleCreateModification,
        modificationCreated,
    ] = useCreateModificationAsyncEndpoint({
        currentInfo: defaultValue,
        newInfo: formValues[fieldName],
        requesterRemark: formValues.remark,
        type: fieldName,
        userId,
    });

    useEffect(() => {
        if (modificationCreated) {
            onCancel();
            resetFields();
        }
        // eslint-disable-next-line
    }, [modificationCreated, resetFields]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form
            form={form}
            name="level-modification-form"
            onFinish={submitForm}
            initialValues={{ [fieldName]: defaultValue }}
            style={{ width: 320 }}
        >
            <Row className="form__group">
                <Col span={8}>
                    <span className="form__group__label">{getTranslation('Username')}:</span>
                </Col>
                <Col span={16}>
                    <span className="username-text">{userName}</span>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={8}>
                    <span className="form__group__label">{getTranslation(fieldName)}:</span>
                </Col>
                <Col span={16}>
                    <Form.Item
                        name={fieldName}
                        rules={[
                            { message: getTranslation('Please select level!'), required: true },
                        ]}
                    >
                        <Select>
                            {_.map(userLevelsMap.get(fieldName), (level) => (
                                <Select.Option key={level} value={level}>
                                    {fieldName === 'Quality Level' ? getTranslation(level) : level}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={8}>
                    <span className="form__group__label">
                        {getTranslation('Remark')}
                        <span style={{ color: 'red' }}>*</span>:
                    </span>
                </Col>
                <Col span={16}>
                    <Form.Item
                        name="remark"
                        rules={[
                            {
                                message: getTranslation('Please fill in this field!'),
                                required: true,
                            },
                        ]}
                    >
                        <Input.TextArea rows={3} />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="end" className="form__group">
                <ButtonGreen htmlType="submit" loading={loading}>
                    {getTranslation('Submit')}
                </ButtonGreen>
                <Button onClick={onCancel}>{getTranslation('Cancel')}</Button>
            </Row>
        </Form>
    );
};

export default LevelForm;
