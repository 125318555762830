import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './PersonalMessageTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const PersonalMessageResultTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const personalMessage = getStateProp(
        state,
        'announcement.announcementVerification.personalMessage',
        {},
    );
    const paging = getStateProp(personalMessage, 'paging', {});

    const tableData = getStateProp(personalMessage, 'list', []);

    return (
        <Table
            columns={getColumns()}
            rowKey={(record) => record.taskNo}
            dataSource={tableData}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
        />
    );
};

export default PersonalMessageResultTable;
