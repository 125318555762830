export const ACTIONS = {
    CONFIRM_MODAL_CLOSE: 'CRYPTO_WITHDRAWAL_RECORDS_CONFIRM_MODAL_CLOSE',
    CONFIRM_MODAL_OPEN: 'CRYPTO_WITHDRAWAL_RECORDS_CONFIRM_MODAL_OPEN',
    CONFIRM_REJECT_MODAL_CLOSE: 'CRYPTO_WITHDRAWAL_CONFIRM_REJECT_MODAL_CLOSE',
    CONFIRM_REJECT_MODAL_OPEN: 'CRYPTO_WITHDRAWAL_CONFIRM_REJECT_MODAL_OPEN',
    FILTER_DROP: 'CRYPTO_WITHDRAWAL_RECORDS_FILTER_DROP',
    FILTER_SET: 'CRYPTO_WITHDRAWAL_RECORDS_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'CRYPTO_WITHDRAWAL_RECORDS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'CRYPTO_WITHDRAWAL_RECORDS_TABLE_PAGE_SIZE_CHANGE',
    },
    REMARK_MODAL_CLOSE: 'CRYPTO_WITHDRAWAL_RECORD_REMARK_MODAL_CLOSE',
    REMARK_MODAL_OPEN: 'CRYPTO_WITHDRAWAL_RECORD_REMARK_MODAL_OPEN',
    VERIFY_INFO_SET: 'CRYPTO_WITHDRAWAL_RECORDS_VERIFY_INFO_SET',
    VERIFY_MODAL_CLOSE: 'CRYPTO_WITHDRAWAL_RECORD_VERIFY_MODAL_CLOSE',
    VERIFY_MODAL_OPEN: 'CRYPTO_WITHDRAWAL_RECORD_VERIFY_MODAL_OPEN',
    WITHDRAWAL_RECORDS_GET: 'CRYPTO_WITHDRAWAL_RECORDS_GET',
    WITHDRAWAL_RECORDS_GET_FAILURE: 'CRYPTO_WITHDRAWAL_RECORDS_GET_FAILURE',
    WITHDRAWAL_RECORDS_GET_SUCCESS: 'CRYPTO_WITHDRAWAL_RECORDS_GET_SUCCESS',
    WITHDRAWAL_RECORDS_OPERATION: 'CRYPTO_WITHDRAWAL_RECORDS_OPERATION',
    WITHDRAWAL_RECORDS_OPERATION_FAILURE: 'CRYPTO_WITHDRAWAL_RECORDS_OPERATION_FAILURE',
    WITHDRAWAL_RECORDS_OPERATION_SUCCESS: 'CRYPTO_WITHDRAWAL_RECORDS_OPERATION_SUCCESS',
};

export const onVerifyModalOpen = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.VERIFY_MODAL_OPEN,
    });

export const onVerifyModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.VERIFY_MODAL_CLOSE,
    });

export const onRemarkModalOpen = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REMARK_MODAL_OPEN,
    });

export const onRemarkModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.REMARK_MODAL_CLOSE,
    });
export const cryptoWithdrawalRecordFilterSet = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });
};

export const cryptoWithdrawalRecordFilterDrop = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTER_DROP,
    });

export const cryptoWithdrawalRecordGet = (dispatch) =>
    dispatch({
        type: ACTIONS.WITHDRAWAL_RECORDS_GET,
    });

export const cryptoWithdrawalRecordGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WITHDRAWAL_RECORDS_GET_SUCCESS,
    });

export const cryptoWithdrawalRecordGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WITHDRAWAL_RECORDS_GET_FAILURE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });
export const cryptoWithdrawalRecordVerifyInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.VERIFY_INFO_SET,
    });
export const cryptoWithdrawalRecordOperation = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WITHDRAWAL_RECORDS_OPERATION,
    });
export const cryptoWithdrawalRecordOperationSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.WITHDRAWAL_RECORDS_OPERATION_SUCCESS,
    });

export const cryptoWithdrawalRecordOperationFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WITHDRAWAL_RECORDS_OPERATION_FAILURE,
    });
export const openConfirmModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_MODAL_OPEN,
    });
export const closeConfirmModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_MODAL_CLOSE,
    });
export const openConfirmRejectModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_REJECT_MODAL_OPEN,
    });
export const closeConfirmRejectModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_REJECT_MODAL_CLOSE,
    });
