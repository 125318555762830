export const ACTIONS = {
    REBATE_CREATE: 'REBATE_CREATE',
    REBATE_CREATE_FAILURE: 'REBATE_CREATE_FAILURE',
    REBATE_CREATE_SUCCESS: 'REBATE_CREATE_SUCCESS',
    REBATE_DUPLICATE: 'REBATE_DUPLICATE',
    REBATE_DUPLICATE_BY_LANGUAGE: 'REBATE_DUPLICATE_BY_LANGUAGE',
    REBATE_FORM_RESET: 'REBATE_FORM_RESET',
    REBATE_INFO_SETTING: 'REBATE_INFO_SETTING',
    REBATE_INIT: 'REBATE_INIT',
    REBATE_LANGUAGE_DROP: 'REBATE_LANGUAGE_DROP',
    REBATE_LANGUAGE_SET: 'REBATE_LANGUAGE_SET',
    REBATE_MULTI_INFO_SETTING: 'REBATE_MULTI_INFO_SETTING',
    SET_REBATE_INFO_BY_LANGUAGE: 'SET_REBATE_INFO_BY_LANGUAGE',
};

//#region Rebate Init
export const setRebateInit = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_INIT,
    });
//#end region

//#region Rebate Info Setting
export const setRebateInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_INFO_SETTING,
    });

export const setMultiRebateInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_MULTI_INFO_SETTING,
    });

export const setRebateInfoByLanguage = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.SET_REBATE_INFO_BY_LANGUAGE,
    });
//#end region

//#region Rebate Duplicate
export const rebateDuplicate = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_DUPLICATE,
    });

export const rebateDuplicateByLanguage = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_DUPLICATE_BY_LANGUAGE,
    });
//#endregion

//#region Rebate Form  Reset
export const rebateFormReset = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_FORM_RESET,
    });
//#end region

//#region Rebate Language Setting
export const setRebateLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_LANGUAGE_SET,
    });

export const dropRebateLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_LANGUAGE_DROP,
    });
//#endregion

//#region Rebate Create
export const rebateCreate = (dispatch) =>
    dispatch({
        type: ACTIONS.REBATE_CREATE,
    });

export const rebateCreateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.REBATE_CREATE_SUCCESS,
    });

export const rebateCreateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_CREATE_FAILURE,
    });
//#endregion
