import { useEffect, useState } from 'react';

import { notification } from 'antd';

export function useNotification(currentCount, nextCount, message) {
    const [play, setPlay] = useState(false);
    const notificationSound = require('../../assets/notification.mp3');
    const audio = new Audio(notificationSound);
    let browserNotification;

    if (currentCount > 0 && nextCount > currentCount) {
        if (Notification.permission === 'granted') {
            new Notification('BACKOFFICE', { body: message });
        } else {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    new Notification('BACKOFFICE', { body: message });
                }
            });
        }

        browserNotification = new Date();
    }

    useEffect(() => {
        if (browserNotification) {
            notification.open({
                description: message,
                duration: 0,
                message: 'BACKOFFICE',
                onClick: () => {
                    setPlay(false);
                },
            });

            if (!play) setPlay(true);
        }
    }, [audio, browserNotification, message, play]);

    useEffect(() => {
        play ? audio.play() : audio.pause();
    }, [audio, play]);

    useEffect(() => {
        audio.addEventListener('ended', () => setPlay(false));

        return () => {
            audio.addEventListener('ended', () => setPlay(false));
        };
    });
}
