import React, { useEffect } from 'react';

import { Col, Row } from 'antd';

import { ButtonGray, ButtonRed } from '../../../../../../common/components/buttons/Buttons';

import { getTranslation } from '../../../../../../common/helpers/locale';
import { useDeleteEWalletAsyncEndpoint } from '../../../../../../services/playerService';

const DeleteEWalletForm = ({ eWallet, onCancel, getPlayerInfo }) => {
    // ============================================
    // METHODS
    // ============================================

    // ============================================
    // HOOKS
    // ============================================

    const [eWalletDeleting, handleDeleteEWallet, eWalletDeleted] = useDeleteEWalletAsyncEndpoint(
        eWallet.id,
    );

    useEffect(() => {
        if (eWalletDeleted) {
            onCancel();
            getPlayerInfo();
        }
    }, [eWalletDeleted, getPlayerInfo, onCancel]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col>
            <Row style={{ marginBottom: 15, marginTop: 24 }}>
                <span>{getTranslation('Are you sure want to delete this E-Wallet?')}</span>
            </Row>
            <Row style={{ marginBottom: 33 }}>
                <p>{`${eWallet.eWalletName} - ${eWallet.eWalletAccountNo}`}</p>
            </Row>
            <Row justify="end" align="middle">
                <ButtonRed
                    label={getTranslation('Delete')}
                    onClick={handleDeleteEWallet}
                    loading={eWalletDeleting}
                />
                <ButtonGray
                    label={getTranslation('Cancel')}
                    onClick={onCancel}
                    style={{ marginLeft: 24 }}
                />
            </Row>
        </Col>
    );
};

export default DeleteEWalletForm;
