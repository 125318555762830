import React from 'react';

import { Card, Divider, Form } from 'antd';

import { API } from '../../../common/constants/api';
import { bonusRecordsExport, getColumns } from './table/BonusRecordTableMeta';
import { dropFilters, onRemarkModalClose } from './bonusRecordsActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useExportAsyncEndpoint } from '../../../services/hooks/useExport';
import { useGetBonusRecordsAsyncEndpoint } from '../../../services/bonusRecordService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import BonusRecordFilters from './BonusRecordFilters';
import BonusRecordRemarkModal from './table/remark/BonusRecordRemarkModal';
import BonusRecordResultsTable from './table/BonusRecordResultsTable';
import TotalBalance from '../../../common/components/TotalBalance';

const { BONUS } = API;

const BonusRecords = () => {
    const [state, dispatch] = useStore();

    const bonusRecords = getStateProp(state, 'transactionHistory.bonusRecords', {});

    const remarkModalVisible = getStateProp(bonusRecords, 'remarkModalVisible');
    const remarks = getStateProp(bonusRecords, 'remarks', {});
    const filters = getStateProp(bonusRecords, 'filters');
    const { totalRecordCount } = getStateProp(bonusRecords, 'paging', {});
    const pageBalance = getStateProp(bonusRecords, 'pageDataTotal', []);
    const totalBalance = getStateProp(bonusRecords, 'dataTotal', []);
    const grandTotal = getStateProp(bonusRecords, 'grandTotal', 0);
    const pageTotal = getStateProp(bonusRecords, 'pageTotal', 0);

    // ============================================
    // METHODS
    // ============================================

    const closeRemarkModal = () => onRemarkModalClose(dispatch);

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetBonusRecords();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetBonusRecords] = useGetBonusRecordsAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        additionalCol: bonusRecordsExport,
        dataMeta: getColumns(),
        filters,
        innerListKey: 'data',
        method: 'GET',
        subject: getTranslation('Bonus Records'),
        totalRecordCount,
        url: `${BONUS.LIST}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Bonus Record')}>
            <Form onFinish={handleGetBonusRecords}>
                <BonusRecordFilters />
                <ActionButtons onDropFilters={fetchAll} onExport={handleExportAction} />
            </Form>

            <BonusRecordResultsTable loading={loading} />

            <Divider />

            <TotalBalance
                pageBalance={pageBalance}
                totalBalance={totalBalance}
                grandTotal={grandTotal}
                pageTotal={pageTotal}
            />

            <BonusRecordRemarkModal
                visible={remarkModalVisible}
                remarks={remarks}
                onCancel={closeRemarkModal}
            />
        </Card>
    );
};

export default BonusRecords;
