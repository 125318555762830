import React from 'react';

import { Modal } from 'antd';

import { getTranslation } from '../../../../common/helpers/locale';
import EditRebateSetting from './EditRebateSetting';

const EditRebateModal = ({ visible, onCancel, loading }) => {
    return (
        <Modal
            width={1080}
            visible={visible}
            title={getTranslation('Edit Rebate Setting')}
            className="rebate__edit-modal"
            onCancel={onCancel}
            closable={false}
            footer={null}
        >
            <EditRebateSetting onCancel={onCancel} loading={loading} />
        </Modal>
    );
};

export default EditRebateModal;
