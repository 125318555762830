import _ from 'lodash';

import { ACTIONS } from './bonusManagementActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function bonusManagementReducer(
    bonusDescription = {
        currentStep: 0,
        editModalVisible: false,
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        resetPageNo: false,
        selectedBonus: {},
        viewModalVisible: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.EDIT_BONUS_MANAGEMENT_LANGUAGE_SET:
        case ACTIONS.EDIT_BONUS_MANAGEMENT_LANGUAGE_DROP:
            return {
                ...bonusDescription,
                selectedBonus: { ...bonusDescription.selectedBonus, details: payload },
            };

        case ACTIONS.BONUS_MANAGEMENT_INFO_SET_BY_LANGUAGE: {
            _.map(bonusDescription.selectedBonus.details, (value) => {
                if (value.languageCode === payload.langCode) {
                    value[payload.name] = payload.value;
                }
                return value;
            });
            return {
                ...bonusDescription,
                selectedBonus: {
                    ...bonusDescription.selectedBonus,
                    bonusContent: {
                        ...bonusDescription.selectedBonus.details,
                    },
                },
            };
        }

        case ACTIONS.BONUS_MANAGEMENT_INFO_MULTI_SET:
            return {
                ...bonusDescription,
                selectedBonus: {
                    ...bonusDescription.selectedBonus,
                    ...payload,
                },
            };

        case ACTIONS.BONUS_MANAGEMENT_INFO_SET:
            return {
                ...bonusDescription,
                selectedBonus: {
                    ...bonusDescription.selectedBonus,
                    [payload.name]: payload.value,
                },
            };

        case ACTIONS.BONUS_SELECTED:
            return {
                ...bonusDescription,
                selectedBonus: payload,
            };

        case ACTIONS.BONUS_DESELECTED:
            return {
                ...bonusDescription,
                selectedBonus: {},
            };

        case ACTIONS.BONUS_MANAGEMENT_LIST_GET_SUCCESS:
        case ACTIONS.BONUS_MANAGEMENT_STATUS_SET:
            return {
                ...bonusDescription,
                ...payload,
            };

        case ACTIONS.BONUS_MANAGEMENT_CLEAR_CACHE:
            return {
                ...bonusDescription,
                changeBannerStatus: null,
            };

        case ACTIONS.PAGINATION.BONUS_MANAGEMENT_TABLE_PAGE_CHANGE:
            return {
                ...bonusDescription,
                paging: {
                    ...bonusDescription.paging,
                    pageNo:
                        payload > bonusDescription.paging.pageCount
                            ? bonusDescription.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.BONUS_MANAGEMENT_TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...bonusDescription,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.BONUS_MANAGEMENT_FILTER_SET:
            return {
                ...bonusDescription,
                filters: {
                    ...bonusDescription.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };
        case ACTIONS.BONUS_MANAGEMENT_FILTERS_DROP_ALL:
            return {
                ...bonusDescription,
                filters: null,
                resetPageNo: true,
            };

        case ACTIONS.BONUS_MANAGEMENT_BY_ID_GET_SUCCESS: {
            return {
                ...bonusDescription,
                selectedBonus: {
                    ...payload,
                },
            };
        }
        case ACTIONS.BONUS_MANGEMENT_EDIT_MODAL_OPEN:
            return {
                ...bonusDescription,
                editModalVisible: true,
            };
        case ACTIONS.BONUS_MANGEMENT_EDIT_MODAL_CLOSE:
            return {
                ...bonusDescription,
                currentStep: 0,
                editModalVisible: false,
            };
        case ACTIONS.BONUS_MANAGEMENT_LIST_GET:
            const pageNo = bonusDescription.resetPageNo
                ? TABLE_PAGE_NO_DEFAULT
                : bonusDescription.paging.pageNo;
            return {
                ...bonusDescription,
                paging: {
                    ...bonusDescription.paging,
                    pageNo: pageNo,
                },
                resetPageNo: false,
            };

        case ACTIONS.BONUS_MANAGEMENT_ACTIVE_LIST_GET_SUCCESS:
            return {
                ...bonusDescription,
                ...payload,
            };

        case ACTIONS.BONUS_MANAGEMENT_SORTING_ORDER_SET:
            return {
                ...bonusDescription,
                ...payload,
            };

        case ACTIONS.BONUS_MANAGEMENT_DUPLICATE_BY_LANGUAGE:
            return {
                ...bonusDescription,
                selectedBonus: {
                    ...bonusDescription.selectedBonus,
                    details: payload,
                },
            };

        case ACTIONS.BONUS_MANGEMENT_VIEW_MODAL_OPEN:
            return {
                ...bonusDescription,
                viewModalVisible: true,
            };
        case ACTIONS.BONUS_MANGEMENT_VIEW_MODAL_CLOSE:
            return {
                ...bonusDescription,
                viewModalVisible: false,
            };
        case ACTIONS.BONUS_MANAGEMENT_SET_CURRENT_SETP:
            return {
                ...bonusDescription,
                currentStep: payload,
            };
        case ACTIONS.BONUS_MANAGEMENT_SORTING_UPDATE:
        case ACTIONS.BONUS_MANAGEMENT_SORTING_UPDATE_SUCCESS:
        case ACTIONS.BONUS_MANAGEMENT_ACTIVE_LIST_GET:
        default: {
            return bonusDescription;
        }
    }
}
