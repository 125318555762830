import React from 'react';

import { RenderDateFormat } from '../../../../../common/components/RenderDateFormat';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../../common/constants/api';
import { changeStatusPageNumber, changeStatusPageSize } from '../../PlayersListActions';
import { getPlayerStatus, getPlayerStatusColor } from '../../../helpers';
import { getTranslation } from '../../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../../common/helpers/misc';

export const getColumns = () => {
    const columns = [
        {
            dataIndex: 'createdAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Date'),
        },
        { dataIndex: 'remarks', title: getTranslation('Remarks') },
        {
            dataIndex: 'status',
            render: (value) => (
                <span style={{ color: getPlayerStatusColor(value) }}>{getPlayerStatus(value)}</span>
            ),
            title: getTranslation('Status'),
        },
        { dataIndex: 'createdByName', title: getTranslation('Operator') },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changeStatusPageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changeStatusPageSize(dispatch, pageSize);
