export const ACTIONS = {
    AFFILIATE_RECORDS_GET: 'AFFILIATE_RECORDS_GET',
    AFFILIATE_RECORDS_GET_FAILURE: 'AFFILIATE_RECORDS_GET_FAILURE',
    AFFILIATE_RECORDS_GET_SUCCESS: 'AFFILIATE_RECORDS_GET_SUCCESS',
    FILTERS_DROP_ALL: 'AFFILIATE_RECORDS_FILTERS_DROP_ALL',
    FILTER_SET: 'AFFILIATE_RECORDS_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'AFFILIATE_RECORDS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'AFFILIATE_RECORDS_TABLE_PAGE_SIZE_CHANGE',
    },
};

export const affiliateRecordsGet = (dispatch) =>
    dispatch({
        type: ACTIONS.AFFILIATE_RECORDS_GET,
    });

export const affiliateRecordsGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.AFFILIATE_RECORDS_GET_FAILURE,
    });

export const affiliateRecordsGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.AFFILIATE_RECORDS_GET_SUCCESS,
    });

export const affiliateRecordsFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });
