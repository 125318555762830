import { Modal } from 'antd';

export default function exportWarningModal(isExportBetList = false) {
    Modal.warning({
        autoFocusButton: null,
        content: isExportBetList
            ? 'Your request have been added into queue. Please download the file in File System module'
            : 'Exporting time depending on network and number of records export',
        title: 'Exporting...',
    });
}
