import React from 'react';

import { Card, Form } from 'antd';

import { HOT_EVENTS_TYPES } from '../../common/constants/misc';
import { getStateProp } from '../../common/helpers/misc';
import { getTranslation } from '../../common/helpers/locale';

import {
    dropFilters,
    pauseRefreshTableTime,
    performHotEventsOperation,
    setRefreshTableTime,
} from './hotEventsAction';
import { useCreateRoomAsyncEndpoint, useGetHotEventsList } from '../../services/hotEventsService';
import ActionButtons from './ActionButtons';
import HotEventFilters from './HotEventFilters';
import HotEventsTable from './table/HotEventsTable';
import TableRefreshSelector from '../../common/components/TableRefreshSelector';
import useInternetStatus from '../../services/hooks/useInternetStatus';

import { useStore } from '../../store/StateProvider';

const HotEvents = () => {
    const [state, dispatch] = useStore();
    const refreshTable = getStateProp(state, 'hotEvents.fetchData', () => {});
    // const selectedHotEvents = getStateProp(state, 'hotEvents.selectedHotEvents', []);

    // ============================================
    // METHODS
    // ============================================

    const onTimePause = () => pauseRefreshTableTime(dispatch);

    const onTimeStart = () => {
        setRefreshTableTime(dispatch, {
            timeOut: 15,
        });
    };

    const setRefreshTime = (timeOut) => {
        setRefreshTableTime(dispatch, {
            timeOut,
        });
    };

    const handleCreateChatRoom = () => {
        performHotEventsOperation(dispatch, {
            action: HOT_EVENTS_TYPES.CREATE,
        });
    };

    const handleDropFilter = () => {
        dropFilters(dispatch);
        handleGetHotEvents();
    };

    // ============================================
    // HOOKS
    // ============================================
    useCreateRoomAsyncEndpoint();
    const [isOnline] = useInternetStatus();
    const [hotEventsLoading, handleGetHotEvents] = useGetHotEventsList(isOnline);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card
            title={getTranslation('Hot Events')}
            extra={
                <TableRefreshSelector
                    onTimeChange={setRefreshTime}
                    onTimePause={onTimePause}
                    onTimeStart={onTimeStart}
                    timeOut={getStateProp(state, 'hotEvents.operation.refreshTime')}
                />
            }
        >
            <Form onFinish={handleGetHotEvents}>
                <HotEventFilters />
                <ActionButtons
                    handleCreateChatRoom={handleCreateChatRoom}
                    handleDropFilter={handleDropFilter}
                    onRefresh={refreshTable}
                />
            </Form>
            <HotEventsTable loading={hotEventsLoading} />
        </Card>
    );
};

export default HotEvents;
