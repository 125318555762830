import { useEffect } from 'react';

import { clearError } from '../../common/actions/errorActions';
import { getErrorMessage } from '../../common/helpers/api';
import { getStateProp } from '../../common/helpers/misc';
import { useStore } from '../../store/StateProvider';

export default function useError(error, failureAction) {
    const [state, dispatch] = useStore();

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (error) {
            clearError(dispatch);
            const message = getErrorMessage(error);
            failureAction(dispatch, {
                message,
            });
        }
    }, [dispatch, error, failureAction]);

    return getStateProp(state, 'error.message');
}
