import { MAX_TABLE_ROW_SELECT_LIMIT } from '../../../common/constants/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { message } from 'antd';

export const ACTIONS = {
    PAGINATION: {
        REBATE_GENERATOR_TABLE_PAGE_CHANGE: 'REBATE_GENERATOR_TABLE_PAGE_CHANGE',
        REBATE_GENERATOR_TABLE_PAGE_SIZE_CHANGE: 'REBATE_GENERATOR_TABLE_PAGE_SIZE_CHANGE',
    },
    REBATE_GENERATOR_FILTERS_DROP_ALL: 'REBATE_GENERATOR_FILTERS_DROP_ALL',
    REBATE_GENERATOR_FILTER_SET: 'REBATE_GENERATOR_FILTER_SET',
    REBATE_GENERATOR_GET: 'REBATE_GENERATOR_GET',
    REBATE_GENERATOR_GET_FAILURE: 'REBATE_GENERATOR_GET_FAILURE',
    REBATE_GENERATOR_GET_SUCCESS: 'REBATE_GENERATOR_GET_SUCCESS',
    REBATE_GENERATOR_STATUS_SET: 'REBATE_GENERATOR_STATUS_SET',
    REBATE_GENERATOR_STATUS_SET_FAILURE: 'REBATE_GENERATOR_STATUS_SET_FAILURE',
    REBATE_GENERATOR_STATUS_SET_SUCCESS: 'REBATE_GENERATOR_STATUS_SET_SUCCESS',
    REBATE_GENERATOR_VERIFY_MODAL_CLOSE: 'REBATE_GENERATOR_VERIFY_MODAL_CLOSE',
    REBATE_GENERATOR_VERIFY_MODAL_OPEN: 'REBATE_GENERATOR_VERIFY_MODAL_OPEN',
    RECORD_SELECT: 'REBATE_GENERATOR_RECORD_SELECT',
};

//#region open verify rebate modal
export const rebateVerifyModalOpen = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_GENERATOR_VERIFY_MODAL_OPEN,
    });

export const rebateVerifyModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.REBATE_GENERATOR_VERIFY_MODAL_CLOSE,
    });
//#endregion

//#region Rebate generator filter
export const rebateGeneratorFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_GENERATOR_FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.REBATE_GENERATOR_FILTERS_DROP_ALL,
    });
//#end region

//#region Rebate generator filter
export const rebateTransactionGet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_GENERATOR_GET,
    });

export const rebateTransactionGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_GENERATOR_GET_SUCCESS,
    });

export const rebateTransactionGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_GENERATOR_GET_FAILURE,
    });
//#end region

//#region Rebate Management change page
export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.REBATE_GENERATOR_TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.REBATE_GENERATOR_TABLE_PAGE_CHANGE,
    });
//#endregion

//#region Rebate generator status set
export const rebateTransactionStatusSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_GENERATOR_STATUS_SET,
    });

export const rebateTransactionStatusSetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_GENERATOR_STATUS_SET_SUCCESS,
    });

export const rebateTransactionStatusSetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_GENERATOR_STATUS_SET_FAILURE,
    });
//#end region

//#region Rebate generator table row select
export const selectRecord = (dispatch, payload) =>
    payload.length <= MAX_TABLE_ROW_SELECT_LIMIT
        ? dispatch({
              payload,
              type: ACTIONS.RECORD_SELECT,
          })
        : message.warning(
              getTranslation('You cannot select more than {limit} rows', {
                  limit: MAX_TABLE_ROW_SELECT_LIMIT,
              }),
          );
//#end region
