import globalAnnouncementReducer from './global-announcement/globalAnnouncementReducer';
import personalMessageAnnouncementReducer from './personal-message/personalMessageReducer';

export default function announcementHistoryReducer(announcementHistory = {}, action) {
    const { globalAnnouncement, personalMessage } = announcementHistory;

    return {
        globalAnnouncement: globalAnnouncementReducer(globalAnnouncement, action),
        personalMessage: personalMessageAnnouncementReducer(personalMessage, action),
    };
}
