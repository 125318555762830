import React from 'react';

import { ButtonGray, ButtonGreen } from '../../../../common/components/buttons/Buttons';
import { TRAFFIC_STATUSES } from '../../../../common/constants/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { rebateVerifyModalOpen } from '../rebateGeneratorActions';

const VerifyButton = ({ rebate, dispatch }) => {
    const status = rebate.transactionStatus.toLocaleUpperCase();

    const disabled = [
        TRAFFIC_STATUSES.REJECTED,
        TRAFFIC_STATUSES.SUCCESS,
        TRAFFIC_STATUSES.PROCESSING,
    ].includes(status);

    // ============================================
    // METHODS
    // ============================================

    const openOperationModal = () => rebateVerifyModalOpen(dispatch, { selectedId: [rebate.id] });

    // ============================================
    // RENDER
    // ============================================

    return disabled ? (
        <ButtonGray disabled label={getTranslation('Verified')} className="action-btn__verify" />
    ) : (
        <>
            <ButtonGreen
                label={getTranslation('Verify')}
                onClick={openOperationModal}
                className="action-btn__verify"
            />
        </>
    );
};

export default VerifyButton;
