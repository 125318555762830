import React from 'react';

import { Card, Form } from 'antd';

import { API } from '../../../common/constants/api';
import { dropFilters } from './walletActions';
import { getColumns } from './table/WalletTransfersTableMeta';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useExportAsyncEndpoint } from '../../../services/hooks/useExport';
import { useGetWalletTransfersAsyncEndpoint } from '../../../services/walletTransferService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import WalletTransfersFilters from './WalletTransfersFilters';
import WalletTransfersResultsTable from './table/WalletTransfersResultsTable';

const { TRANSACTION_HISTORY } = API;

const WalletTransfers = () => {
    const [state, dispatch] = useStore();
    const { walletTransfers } = getStateProp(state, 'transactionHistory');
    const filters = getStateProp(walletTransfers, 'filters');
    const { totalRecordCount } = getStateProp(walletTransfers, 'paging', {});

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetWalletTransfers();
    };

    // ============================================
    // HOOKS
    // ============================================
    const [walletTransfersLoading, handleGetWalletTransfers] = useGetWalletTransfersAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        dataMeta: getColumns(),
        filters,
        method: 'GET',
        subject: getTranslation('Wallet Transfers'),
        totalRecordCount,
        url: `${TRANSACTION_HISTORY.WALLET_TRANFERS}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Wallet Transfer')}>
            <Form onFinish={handleGetWalletTransfers}>
                <WalletTransfersFilters />
                <ActionButtons onDropFilters={fetchAll} onExport={handleExportAction} />
            </Form>

            <WalletTransfersResultsTable loading={walletTransfersLoading} />
        </Card>
    );
};

export default WalletTransfers;
