import React, { useEffect, useState } from 'react';

import { Button, Checkbox, Col, Divider, Form, Row, message } from 'antd';
import _ from 'lodash';

import { ButtonGreen } from '../../../../../common/components/buttons/Buttons';
import { Loader } from '../../../../../common/components';
import { getStateProp } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import {
    useGetGamePlatformAsyncEndpoint,
    useUpdatePlayerGamePlatformAsyncEndpoint,
} from '../../../../../services/playerService';
import { useStore } from '../../../../../store/StateProvider';
import PlayersBatchSearch from '../../../../../common/components/player-batch-search/PlayersBatchSearch';

const UpdatePlayersGameAccess = ({ onCancel }) => {
    const [state] = useStore();
    const [formValues, setFormValues] = useState({});
    let validPlayers = [];

    const gamePlatform = getStateProp(state, 'players.playersList.playersModal.gamePlatform', []);

    // ============================================
    // METHODS
    // ============================================

    const submitForm = (values) => {
        if (validPlayers.length === 0) {
            message.warning(
                getTranslation(
                    "There is no player(s) to update. Please fill in and verify the player's username.",
                ),
            );
            return;
        }

        if (values.gameAccess.length === gamePlatform.length) values.gameAccess = null;
        else if (values.gameAccess.length === 0) values.gameAccess = [];

        setFormValues({
            gameAccess: values.gameAccess,
            players: validPlayers,
        });

        handleUpdatePlayerGamePlatform();
    };

    const getValidPlayersList = (userNames) => {
        validPlayers = _.map(userNames, (userName) => userName.id);
    };

    // ============================================
    // HOOKS
    // ============================================

    const [gamePlatformLoading] = useGetGamePlatformAsyncEndpoint();

    const [
        updating,
        handleUpdatePlayerGamePlatform,
        updated,
    ] = useUpdatePlayerGamePlatformAsyncEndpoint(formValues);

    useEffect(() => {
        if (updated) {
            message.success('Players Game Access Updated!');
            onCancel();
        }
    }, [onCancel, updated]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col>
            <PlayersBatchSearch getValidPlayersList={getValidPlayersList} />

            <Divider />

            <Form onFinish={submitForm}>
                {gamePlatformLoading ? (
                    <Loader />
                ) : (
                    <Form.Item name="gameAccess" label={getTranslation('Game Access')}>
                        <Checkbox.Group>
                            <Row style={{ flexFlow: 'row wrap' }}>
                                {_.map(gamePlatform, (game) => {
                                    return (
                                        <Col span={4} key={game.code}>
                                            <Checkbox value={game.code}>
                                                {game.desc.toLocaleUpperCase()}
                                            </Checkbox>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                )}

                <Divider />

                <Row justify="end" className="form__group">
                    <ButtonGreen htmlType="submit" loading={updating}>
                        {getTranslation('Update')}
                    </ButtonGreen>
                    <Button onClick={onCancel}>{getTranslation('Cancel')}</Button>
                </Row>
            </Form>
        </Col>
    );
};

export default UpdatePlayersGameAccess;
