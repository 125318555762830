export const ACTIONS = {
    ANNOUNCEMENT_DELETE_MODAL_CLOSE: 'ANNOUNCEMENT_DELETE_MODAL_CLOSE',
    ANNOUNCEMENT_DELETE_MODAL_OPEN: 'ANNOUNCEMENT_DELETE_MODAL_OPEN',
    ANNOUNCEMENT_DESELECTED: 'ANNOUNCEMENT_DESELECTED',
    ANNOUNCEMENT_GET: 'ANNOUNCEMENT_GET',
    ANNOUNCEMENT_GET_FAILURE: 'ANNOUNCEMENT_GET_FAILURE',
    ANNOUNCEMENT_GET_SUCCESS: 'ANNOUNCEMENT_GET_SUCCESS',
    ANNOUNCEMENT_SELECTED: 'ANNOUNCEMENT_SELECTED',
    ANNOUNCEMENT_UPDATE: 'ANNOUNCEMENT_UPDATE',
    ANNOUNCEMENT_UPDATE_FAILURE: 'ANNOUNCEMENT_UPDATE_FAILURE',
    ANNOUNCEMENT_UPDATE_SUCCESS: 'ANNOUNCEMENT_UPDATE_SUCCESS',
    ANNOUNCEMENT_VERIFY_MODAL_CLOSE: 'ANNOUNCEMENT_VERIFY_MODAL_CLOSE',
    ANNOUNCEMENT_VERIFY_MODAL_OPEN: 'ANNOUNCEMENT_VERIFY_MODAL_OPEN',
    FILTER_DROP: 'ANNOUNCEMENT_FILTER_DROP',
    FILTER_SET: 'ANNOUNCEMENT_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'ANNOUNCEMENT_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'ANNOUNCEMENT_TABLE_PAGE_SIZE_CHANGE',
    },
};

//#region Announcement Delete
export const openAnnouncementDeleteModal = (dispatch, payload) => {
    dispatch({ payload, type: ACTIONS.ANNOUNCEMENT_DELETE_MODAL_OPEN });
};
export const closeAnnouncementDeleteModal = (dispatch) => {
    dispatch({ type: ACTIONS.ANNOUNCEMENT_DELETE_MODAL_CLOSE });
};
//#endregion

//#region Announcement Verify
export const openAnnouncementVerifyModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ANNOUNCEMENT_VERIFY_MODAL_OPEN,
    });
export const closeAnnouncementVerifyModal = (dispatch) =>
    dispatch({
        type: ACTIONS.ANNOUNCEMENT_VERIFY_MODAL_CLOSE,
    });
//#endregion

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const announcementGet = (dispatch) =>
    dispatch({
        type: ACTIONS.ANNOUNCEMENT_GET,
    });

export const announcementGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ANNOUNCEMENT_GET_FAILURE,
    });

export const announcementGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ANNOUNCEMENT_GET_SUCCESS,
    });

export const announcementFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const announcementFilterDrop = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTER_DROP,
    });

export const announcementUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.ANNOUNCEMENT_UPDATE,
    });

export const announcementUpdateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.ANNOUNCEMENT_UPDATE_SUCCESS,
    });

export const announcementUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ANNOUNCEMENT_UPDATE_FAILURE,
    });
