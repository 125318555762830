import { useCallback, useEffect, useState } from 'react';

import { API } from '../common/constants/api';
import { clearError } from '../common/actions/errorActions';

import {
    NOTIFICATION_TYPES,
    TARGET_NOTIFICATION_TYPES,
} from '../components/push-notification/constants';
import {
    createPushNotification,
    createPushNotificationFailure,
    createPushNotificationSuccess,
} from '../components/push-notification/create-push-notification/general/generalCreatePushNotificationActions';
import {
    createTargetPushNotification,
    createTargetPushNotificationFailure,
    createTargetPushNotificationSuccess,
} from '../components/push-notification/create-push-notification/target/targetCreatePushNotificationActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import {
    getNotificationInfo,
    getNotificationInfoById,
    getNotificationInfoByIdFailure,
    getNotificationInfoByIdSuccess,
    getNotificationInfoFailure,
    getNotificationInfoSuccess,
    sendPushNotification,
    sendPushNotificationFailure,
    sendPushNotificationSuccess,
    upsertPushNotification,
    upsertPushNotificationFailure,
    upsertPushNotificationSuccess,
} from '../components/push-notification/push-notification-management/pushNotificationManagementAction';
import { getTranslation } from '../common/helpers/locale';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage, useToasterSuccessMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { NOTIFICATION, VERSION } = API;

export function useCreatePushNotificationAsyncEndpoint(aspect) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { pushNotificationInfo, targetType, currency, tokens, userNames } = getStateProp(
        state,
        aspect === NOTIFICATION_TYPES.GENERAL
            ? 'pushNotification.createPushNotification.generalCreatePushNotification'
            : 'pushNotification.createPushNotification.targetCreatePushNotification',
        {},
    );
    const currencies =
        targetType === TARGET_NOTIFICATION_TYPES.CURRENCY && currency
            ? {
                  currencyCode: currency,
              }
            : undefined;
    const users = targetType === TARGET_NOTIFICATION_TYPES.USERNAME && {
        tokens,
        userNames,
    };

    // ============================================
    // METHODS
    // ============================================

    const handleCreatePushNotification = () => {
        setTrigger(+new Date());
        aspect === NOTIFICATION_TYPES.GENERAL
            ? createPushNotification(dispatch)
            : createTargetPushNotification(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                currencies,
                notificationDetails: pushNotificationInfo,
                notificationType: 'Pushy',
                targetType,
                ...users,
            },
        },
        trigger,
        url: `${VERSION.V1}${NOTIFICATION.ROOT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            aspect === NOTIFICATION_TYPES.GENERAL
                ? createPushNotificationSuccess(dispatch)
                : createTargetPushNotificationSuccess(dispatch);
        }
    }, [aspect, dispatch, response]);

    useToasterSuccessMessage(response, getTranslation('Push notification successfully added!'));

    const errorMsg = useError(
        error,
        aspect === NOTIFICATION_TYPES.GENERAL
            ? createPushNotificationFailure
            : createTargetPushNotificationFailure,
    );
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleCreatePushNotification];
}

export function useGetPushNotificationInfoAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const filters = getStateProp(state, 'pushNotification.pushNotificationManagement.filters', {});
    const { pageNo, pageSize } = getStateProp(
        state,
        'pushNotification.pushNotificationManagement.paging',
        {},
    );
    const { targetType, ...restFilter } = filters;

    // ============================================
    // METHODS
    // ============================================

    const handleGetPushNotificationInfo = useCallback(() => {
        getNotificationInfo(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(restFilter),
                pageNo,
                pageSize,
                targetType: targetType === 'general' ? 'None' : targetType,
            },
        },
        trigger,
        url: `${VERSION.V1}${NOTIFICATION.ROOT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getNotificationInfoSuccess(dispatch, {
                fetchData: handleGetPushNotificationInfo,
                list: response.data.data,
            });
        }
    }, [dispatch, handleGetPushNotificationInfo, response]);

    const errorMsg = useError(error, getNotificationInfoFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetPushNotificationInfo];
}

export function useGetPushNotificationInfoByIdAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const selectedId = getStateProp(
        state,
        'pushNotification.pushNotificationManagement.selectedId',
        '',
    );

    // ============================================
    // METHODS
    // ============================================

    const handleGetPushNotificationInfoById = useCallback(() => {
        getNotificationInfoById(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'GET',
        options: {},
        trigger,
        url: `${VERSION.V1}${NOTIFICATION.ROOT}/${selectedId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getNotificationInfoByIdSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, getNotificationInfoByIdFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetPushNotificationInfoById];
}

export function useUpsertPushNotificationAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { selectedPushNotification, selectedId, fetchData } = getStateProp(
        state,
        'pushNotification.pushNotificationManagement',
    );

    // ============================================
    // METHODS
    // ============================================

    const handleUpsertPushNotification = () => {
        setTrigger(+new Date());
        upsertPushNotification(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: { ...selectedPushNotification },
        },
        trigger,
        url: `${VERSION.V1}${NOTIFICATION.ROOT}/${selectedId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            upsertPushNotificationSuccess(dispatch);
            fetchData();
        }
    }, [dispatch, fetchData, response]);

    useToasterSuccessMessage(response, getTranslation('Push notification successfully updated!'));

    const errorMsg = useError(error, upsertPushNotificationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpsertPushNotification];
}

export function useSendPushNotificationAsyncEndpoint(selectedId) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { fetchData } = getStateProp(state, 'pushNotification.pushNotificationManagement');

    // ============================================
    // METHODS
    // ============================================

    const handleSendPushNotification = () => {
        setTrigger(+new Date());
        sendPushNotification(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: { id: selectedId },
        },
        trigger,
        url: `${VERSION.V1}${NOTIFICATION.SEND}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            sendPushNotificationSuccess(dispatch);
            fetchData();
        }
    }, [dispatch, fetchData, response]);

    useToasterSuccessMessage(response, getTranslation('Push notification successfully sent!'));

    const errorMsg = useError(error, sendPushNotificationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleSendPushNotification];
}
