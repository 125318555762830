import { message } from 'antd';
import { saveAs } from 'file-saver';
import XLSX from 'xlsx';
import _ from 'lodash';
import moment from 'moment';

import { CURRENCIES } from '../constants/localization';
import { TRAFFIC_STATUSES } from '../constants/misc';
import { getSupportedCurrenciesByCountry, getSupportedLanguages, getTranslation } from './locale';

export const getStateProp = (state, path, defaultValue) => _.get(state, path, defaultValue);

export const exportTable = (
    tableData,
    { author, subject, additionalRow, additionalCol, changeTitles },
) => {
    const saveFile = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    if (tableData.length !== 0) {
        const newBook = XLSX.utils.book_new();
        const workSheet = XLSX.utils.json_to_sheet(tableData);
        if (additionalRow) {
            XLSX.utils.sheet_add_aoa(workSheet, additionalRow, { origin: -1 }); //last of record add another column
        }

        if (changeTitles) {
            _.map(changeTitles, function(value, key) {
                _.map(workSheet, function(o) {
                    if (o.v && typeof o.v === 'string' && o.v.includes(key)) {
                        o.v = value;
                    }
                });
            });
        }

        const workBook = {
            ...newBook,
            Props: {
                Author: author,
                CreatedDate: new Date(),
                Subject: subject,
                Title: subject,
            },
            SheetNames: [...newBook.SheetNames, subject],
            Sheets: {
                ...newBook.Sheets,
                [subject]: workSheet,
            },
        };

        const NOW = new Date();
        saveAs(
            new Blob(
                [
                    saveFile(
                        XLSX.write(workBook, {
                            bookType: 'xlsx',
                            type: 'binary',
                        }),
                    ),
                ],
                {
                    type: 'application/octet-stream',
                },
            ),
            `${subject}-${NOW.toUTCString()}.xlsx`,
        );
    }
};

export const exportTableByHTMLTable = (htmlTableId, { author, subject, additionalRow }) => {
    const saveFile = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const newBook = XLSX.utils.book_new();
    var tbl = document.getElementById(htmlTableId);
    const workSheet = XLSX.utils.table_to_sheet(tbl);
    if (additionalRow) {
        XLSX.utils.sheet_add_aoa(workSheet, additionalRow, { origin: -1 }); //last of record add another column
    }

    const workBook = {
        ...newBook,
        Props: {
            Author: author,
            CreatedDate: new Date(),
            Subject: subject,
            Title: subject,
        },
        SheetNames: [...newBook.SheetNames, subject],
        Sheets: {
            ...newBook.Sheets,
            [subject]: workSheet,
        },
    };

    const NOW = new Date();
    saveAs(
        new Blob(
            [
                saveFile(
                    XLSX.write(workBook, {
                        bookType: 'xlsx',
                        type: 'binary',
                    }),
                ),
            ],
            {
                type: 'application/octet-stream',
            },
        ),
        `${subject}-${NOW.toUTCString()}.xlsx`,
    );
};

export const hidePhone = (phoneString) => {
    if (phoneString) return phoneString.replace(phoneString.substr(-8, 4), '****');
};

export const hideBankCard = (cardString) => {
    if (cardString) {
        const trimmedCardString = cardString.replace(/\s/g, '');
        return trimmedCardString.replace(
            trimmedCardString.substr(0, trimmedCardString.length - 6),
            '**** **** **',
        );
    }
};

export const sortTableByDataType = (row1, row2, dataIndex, dataType) => {
    const DATA_TYPES = {
        DATE: 'date',
        NUMBER: 'number',
        OPERATION: 'operation',
    };

    const value1 = row1[dataIndex];
    const value2 = row2[dataIndex];

    switch (dataType) {
        case DATA_TYPES.DATE:
            return new Date(value2) - new Date(value1);
        case DATA_TYPES.OPERATION:
            return row1.nextAction > row2.nextAction
                ? 1
                : row1.nextAction < row2.nextAction
                ? -1
                : 0;
        case DATA_TYPES.NUMBER:
        default:
            if (value1 < value2) {
                return -1;
            }
            if (value1 > value2) {
                return 1;
            }
            return 0;
    }
};

export const getCurrentTime = () => {
    return moment()
        .utc()
        .format();
};

export const getGMT_4StartTime = () => {
    return moment('00:00:00', 'HH:mm:ss')
        .utcOffset(-4, true)
        .utc()
        .format();
};

export const getGMT_4EndTime = () => {
    return moment('23:59:59', 'HH:mm:ss')
        .utcOffset(-4, true)
        .utc()
        .format();
};

export const getStartTime = (location, ignoreUTC = false) => {
    return ignoreUTC
        ? moment()
              .tz(location)
              .startOf('day')
              .format('YYYY-MM-DDTHH:mm:ss.SSS')
        : moment()
              .tz(location)
              .startOf('day')
              .utc()
              .format();
};

export const getEndTime = (location, ignoreUTC = false) => {
    return ignoreUTC
        ? moment()
              .tz(location)
              .endOf('day')
              .format('YYYY-MM-DDTHH:mm:ss.SSS')
        : moment()
              .tz(location)
              .endOf('day')
              .utc()
              .format();
};

export const getStatusColor = (status) => {
    status = status ? status.toLocaleUpperCase() : status;
    switch (status) {
        case TRAFFIC_STATUSES.COMPLETED:
        case TRAFFIC_STATUSES.PUBLISHED:
        case TRAFFIC_STATUSES.SETTLED:
        case TRAFFIC_STATUSES.SUCCESS:
        case TRAFFIC_STATUSES.VERIFIED:
        case TRAFFIC_STATUSES.APPROVED:
            return '#2de09f';

        case TRAFFIC_STATUSES.ATTENDING:
        case TRAFFIC_STATUSES.DRAFT:
        case TRAFFIC_STATUSES.ONGOING:
        case TRAFFIC_STATUSES.PROCESSING:
        case TRAFFIC_STATUSES.RUNNING:
        case TRAFFIC_STATUSES.SUBMITTED:
            return '#efaf41';

        case TRAFFIC_STATUSES.DELETED:
        case TRAFFIC_STATUSES.EXPIRED:
        case TRAFFIC_STATUSES.FAILURE:
        case TRAFFIC_STATUSES.REJECTED:
            return '#c12137';

        case TRAFFIC_STATUSES.PENDING:
            return '#4f61d2';

        default:
            return 'black';
    }
};

export const getBooleanColor = (boolean) => {
    if (boolean === true) {
        return '#2de09f';
    } else if (boolean === false) {
        return '#c12137';
    } else {
        return 'black';
    }
};

export const isTestEnvironment = () => {
    const currentAddress = window.location.host;
    return (
        (process.env.NODE_ENV !== 'production' && !currentAddress.includes('stg')) ||
        currentAddress.includes('dev') ||
        currentAddress.includes('sit') ||
        currentAddress.includes('uat')
    );
};

/**
 * Get list of all operation id's for selected menu items
 *
 * @param {MenuItem[]} menuList The array of all menu items
 * @param {{String: String[]}} selectedMenus Object containing selected menu item id's // { Player Management: [...]}
 * @returns {String[]} Array of operation id's.
 */
export const getMenuOperationIds = (menuList, selectedMenus) => {
    const subMenuIds = _.flatten(
        _.map(selectedMenus, (value) => {
            return value;
        }),
    );

    const menuOperation = _.filter(
        _.flatten(
            _.map(menuList, (list) => {
                return _.map(list.subMenus, (submenu) => {
                    return submenu;
                });
            }),
        ),
        (subMenuDetails) => {
            return _.includes(subMenuIds, subMenuDetails.menuId);
        },
    );

    return _.flatten(
        _.map(menuOperation, (menu) => {
            return _.map(menu.menuOperations, (operation) => {
                return operation.menuOperationId;
            });
        }),
    );
};

/**
 * Get object with all submenu items according to selected operation id's
 *
 * @param {MenuItem[]} menuList The array of all menu items
 * @param {String[]} menuOperationIds Array of operation id's
 * @returns {{String: String[]}} Object containing selected menu item id's // { Player Management: [...]}.
 */
export const getSubMenuByOperationIds = (menuList, menuOperationIds) => {
    let subMenusObject = {};

    const subMenus = _.compact(
        _.map(menuList, (menu) => {
            const subMenuIds = _.compact(
                _.map(menu.subMenus, (subMenus) => {
                    const operationMenus = _.map(subMenus, (sub) => {
                        return sub;
                    })[3];

                    const selectedOperation = _.map(operationMenus, (operation) => {
                        return _.includes(menuOperationIds, operation.menuOperationId) && operation;
                    });

                    return _.isMatch(subMenus.menuOperations, selectedOperation) && subMenus.menuId;
                }),
            );

            return !_.isEmpty(subMenuIds) && { [menu.menuName]: subMenuIds };
        }),
    );

    _.reduce(
        subMenus,
        function(obj, val) {
            _.assign(subMenusObject, val);
        },
        {},
    );

    return subMenusObject;
};

/**
 * Get array with all submenu items according to selected submenu id's
 *
 * @param {MenuItem[]} menuList The array of all menu items
 * @param {String[]} subMenuIds Array of submenu id's
 * @returns {{String[]}} Array containing selected menu item id's // [Player Management: [...]].
 */
export const getSubMenuBySubMenuId = (menuList, subMenuIds) => {
    return _.compact(
        _.map(menuList, (menu) => {
            const selectedSubMenus = _.compact(
                _.map(menu.subMenus, (subMenus) => {
                    return _.includes(subMenuIds, subMenus.menuId) && subMenus;
                }),
            );
            return !_.isEmpty(selectedSubMenus) && { [menu.menuName]: selectedSubMenus };
        }),
    );
};

export const subMenuGrouping = (menuList, subMenuIds) => {
    let convertToObject = {};

    const selectedMenuId = _.compact(
        _.map(menuList, (menu) => {
            const selectedSubMenus = _.compact(
                _.map(menu.subMenus, (subMenus) => {
                    return _.includes(subMenuIds, subMenus.menuId) && subMenus.menuId;
                }),
            );
            return !_.isEmpty(selectedSubMenus) && { [menu.menuName]: selectedSubMenus };
        }),
    );

    _.reduce(
        selectedMenuId,
        function(obj, val) {
            _.assign(convertToObject, val);
        },
        {},
    );

    return convertToObject;
};

export const exportDataMapping = (data, meta, additionalCol, getFromDifferentPathList, offset) => {
    if (additionalCol) {
        let messageArray = [];
        for (let i = 0; i < data.length; i++) {
            const record = data[i];
            messageArray = additionalCol({ record });
            for (let j = 0; j < messageArray.length; j++) {
                record[messageArray[j][0]] = messageArray[j][1];
            }
        }

        const extractedAdditionalCol = additionalCol({ onlyMeta: true, wholeData: data });
        _.map(extractedAdditionalCol, (item, key) => {
            for (let i = meta.length - 1; i >= 0; i--) {
                if (meta[i].dataType === key) {
                    meta.splice(i, 1);
                    for (let j = 0; j < item.length; j++) {
                        meta.splice(i + j, 0, item[j]);
                    }
                }
            }
        });
    }

    const getNestedObject = (nestedObj, pathArr) => {
        return pathArr.reduce(
            (obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined),
            nestedObj,
        );
    };

    let count = 1;
    return data.map((el) => {
        const arrObj = meta.map((f) => {
            const a = {};
            if (f.dataType === 'date' && !el.modified) {
                a[f.title] = moment(el[f.dataIndex]);
                a[f.title] = moment(el[f.dataIndex])
                    .utcOffset('+0000', true)
                    .utcOffset(f.isGMT_4 ? '-0400' : offset)
                    .format('DD/MM/YYYY HH:mm:ss');
            } else {
                let convertedDataIndex = f.dataIndex;
                if (getFromDifferentPathList) {
                    const newKey = getFromDifferentPathList[f.dataIndex];
                    if (!_.isEmpty(newKey)) {
                        convertedDataIndex = newKey;
                    }
                }
                const subStringArray = convertedDataIndex.split('.');
                let dataValue = getNestedObject(el, subStringArray);

                if (f.dataType === 'translate') {
                    dataValue = getTranslation(dataValue);
                } else if (f.dataType === 'indexNumber' && !el.modified) {
                    dataValue = count;
                    count++;
                } else if (f.dataType === 'nanInt') {
                    dataValue = !isNaN(parseInt(dataValue)) ? dataValue : 'NA';
                } else if (f.dataType === 'boolean') {
                    dataValue = dataValue ? getTranslation('Yes') : getTranslation('No');
                }
                a[f.title] = dataValue;
            }

            return a;
        });
        return arrObj.reduce(function(result, current) {
            return Object.assign(result, current);
        }, {});
    });
};

export const convertStringToArray = (value) => {
    if (value) {
        value = typeof value == 'string' ? (value.length > 0 ? value.split(',') : []) : value;
        value = value.length > 0 ? _.map(value, (value) => parseInt(value)) : value;
        return value;
    } else {
        return null;
    }
};

export const roundDownMonetaryValue = (value) => {
    const roundDownValue = Math.floor(Number(value || '0') * 100) / 100;
    return roundDownValue.toFixed(2);
};

export const getFiatAmount = (amount, rate, currencyCode) => {
    return currencyCode === 'IDR' || currencyCode === 'VND'
        ? _.round(amount / rate / 1000)
        : _.round(amount / rate);
};

export const editorDefaultStylingSizeSet = (value) => {
    const image = /<\s*img[^>]*>/g;
    const iframe = /<\s*iframe[^>]*>/g;
    let modifiedContent = value;

    if (!value) return null;

    if (value.match(image)) {
        modifiedContent = value.replace(image, (match) => {
            if (match.includes('style')) {
                return match;
            } else {
                match = match.replace(
                    '/>',
                    'style="margin:auto; max-width:100%; display:block;" />',
                );
                return match;
            }
        });
    }

    if (value.match(iframe)) {
        modifiedContent = value.replace(iframe, (match) => {
            match = match.replace('>', ' style="margin:auto; max-width:100%;  max-height:300px" >');
            return match;
        });
    }

    return modifiedContent;
};

export const filterTrim = (filters) => {
    const trimmedFilter = _.cloneDeep(filters);

    _.each(
        trimmedFilter,
        (filter, index) =>
            (trimmedFilter[index] =
                filter && !_.isArray(filter) && !_.isNumber(filter) && !_.isBoolean(filter)
                    ? filter.trim()
                    : filter),
    );

    return trimmedFilter;
};

export const textEditorImageFileSizeCheck = (images) => {
    const isFileSmallThanLimit = _.map(images, (image) => {
        // eslint-disable-next-line no-useless-escape
        const srcWithQuotes = image.match(/src\=([^\s]*)\s/)[1];

        const src = srcWithQuotes.substring(1, srcWithQuotes.length - 1);

        const fileSize = ((src.length * 3) / 4 - 1) / 1000;

        if (fileSize > 210) {
            message.warning('Each image maximum size in editor is 200kb');
            return false;
        } else return true;
    });

    return _.every(isFileSmallThanLimit, (flag) => flag === true);
};

export const isNullOrEmptyString = (value) => {
    if (!value) return true;
    else return !value.length;
};

export const isOperatorHasFullAccess = () => {
    return CURRENCIES.length === getSupportedCurrenciesByCountry().length;
};

export function getNegativeInt(value) {
    return value.replace(/(?!^-)[^0-9]/g, '');
}

export function getIntOrFloat(value, isDecimal = false, xPow10 = 2) {
    let newValue = value.replace(/[^0-9]/g, '');
    let divide = Math.pow(10, xPow10);
    if (newValue > 0) {
        isDecimal ? (newValue = parseInt(newValue) / divide) : (newValue = parseInt(newValue));
    } else {
        newValue = 0;
    }
    return newValue;
}

export const isLessThanOneWeek = (startDate, endDate) => {
    if (startDate && endDate) {
        return moment(endDate).diff(moment(startDate), 'days', true) <= 7;
    } else {
        return false;
    }
};

export const isLessThanHalfYear = (startDate, endDate) => {
    if (startDate && endDate) {
        return moment(endDate).diff(moment(startDate), 'months', true) <= 6;
    } else {
        return false;
    }
};

export const validateDateTime = (dateTime) => {
    if (dateTime && _.isString(dateTime) && dateTime.slice(-1) !== 'Z') {
        return moment(dateTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    } else {
        return dateTime;
    }
};

export const isSupportedLanguage = (langCode) => {
    const supportedLangCode = _.map(
        getSupportedLanguages(),
        (supportedLanguage) => supportedLanguage.value,
    );

    return _.includes(supportedLangCode, langCode);
};
