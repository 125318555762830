import { ACTIONS } from './playerWinLossActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function playerWalletBalanceReducer(
    playerWinLoss = {
        filters: {},
        modalDetail: {
            filters: {},
            visible: false,
        },
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        resetPageNo: false,
        winLossDetail: {
            paging: {
                pageNo: TABLE_PAGE_NO_DEFAULT,
                pageSize: TABLE_PAGE_SIZE_DEFAULT,
                totalRecordCount: 0,
            },
        },
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...playerWinLoss,
                paging: {
                    ...playerWinLoss.paging,
                    pageNo:
                        payload > playerWinLoss.paging.pageCount
                            ? playerWinLoss.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1];
            return {
                ...playerWinLoss,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.DETAILS_PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...playerWinLoss,
                winLossDetail: {
                    ...playerWinLoss.winLossDetail,
                    paging: {
                        ...playerWinLoss.winLossDetail.paging,
                        pageNo:
                            payload > playerWinLoss.winLossDetail.paging.pageCount
                                ? playerWinLoss.winLossDetail.paging.pageCount - 1
                                : payload,
                    },
                },
            };
        case ACTIONS.DETAILS_PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_DETAILS_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...playerWinLoss,
                winLossDetail: {
                    ...playerWinLoss.winLossDetail,
                    paging: {
                        ...playerWinLoss.winLossDetail.paging,
                        pageNo: 1,
                        pageSize:
                            payload < 1
                                ? TABLE_PAGE_SIZE_DEFAULT
                                : payload > MAX_DETAILS_PER_PAGE
                                ? MAX_DETAILS_PER_PAGE
                                : payload,
                    },
                },
            };

        case ACTIONS.WIN_LOSS_DETAIL_MODAL_OPEN:
            return {
                ...playerWinLoss,
                modalDetail: {
                    filters: payload,
                    visible: true,
                },
            };

        case ACTIONS.WIN_LOSS_DETAIL_MODAL_CLOSE:
            return {
                ...playerWinLoss,
                modalDetail: {
                    filters: {},
                    visible: false,
                },
            };

        case ACTIONS.FILTER_OPTION_GET_SUCCESS:
            return {
                ...playerWinLoss,
                filterOptions: payload,
            };

        case ACTIONS.FILTER_SET:
            return {
                ...playerWinLoss,
                filters: {
                    ...playerWinLoss.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };

        case ACTIONS.FILTERS_DROP:
            return {
                ...playerWinLoss,
                filters: {},
                resetPageNo: true,
            };

        case ACTIONS.PLAYER_WIN_LOSS_REPORT_GET_SUCCESS:
            return {
                ...playerWinLoss,
                ...payload,
            };

        case ACTIONS.PLAYER_WIN_LOSS_DETAILS_GET_SUCCESS:
            return {
                ...playerWinLoss,
                winLossDetail: {
                    ...playerWinLoss.winLossDetail,
                    ...payload,
                },
            };
        case ACTIONS.PLAYER_WIN_LOSS_REPORT_GET:
            const pageNo = playerWinLoss.resetPageNo
                ? TABLE_PAGE_NO_DEFAULT
                : playerWinLoss.paging.pageNo;
            return {
                ...playerWinLoss,
                paging: {
                    ...playerWinLoss.paging,
                    pageNo: pageNo,
                },
                resetPageNo: false,
            };

        case ACTIONS.PLAYER_WIN_LOSS_EXPORT:
        case ACTIONS.PLAYER_WIN_LOSS_EXPORT_SUCCESS:
        case ACTIONS.PLAYER_WIN_LOSS_DETAILS_GET:
        case ACTIONS.FILTER_OPTION_GET:
        default:
            return playerWinLoss;
    }
}
