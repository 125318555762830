import React from 'react';

import { Row } from 'antd';

import './OperationButton.less';
import { ButtonGray, ButtonGreen, ButtonRed } from '../../../../common/components/buttons/Buttons';
import { PAYMENT_ORDER_OPERATIONS, TRANSACTION_STATUSES } from '../../../../common/constants/misc';
import { getCurrentUser } from '../../../../common/helpers/api';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import {
    openRejectModal,
    openVerifyModal,
    performEwalletWithdrawalsOperation,
} from '../ewalletWithdrawalsActions';
import { useStore } from '../../../../store/StateProvider';

const OperationButton = ({ transaction, dispatch }) => {
    const [state] = useStore();
    const {
        attendantUserName,
        attendantRealName,
        transactionStatus,
        processorUserName,
    } = transaction;
    const disabled = [TRANSACTION_STATUSES.REJECTED, TRANSACTION_STATUSES.SUCCESS].includes(
        transactionStatus,
    );
    const operatorName = getStateProp(
        state,
        'user.userName',
        getStateProp(getCurrentUser(), 'claims.userName', 'Anonymus'), //Temp fix
    );

    // ============================================
    // METHODS
    // ============================================

    const getLabel = () => {
        switch (transactionStatus) {
            case TRANSACTION_STATUSES.ATTENDING:
                return `${getTranslation('Reviewing')} ${attendantRealName}`;
            case TRANSACTION_STATUSES.REJECTED:
            case TRANSACTION_STATUSES.SUCCESS:
                return `${getTranslation('Verified')} ${processorUserName}`;
            default:
                getTranslation('Verify');
        }
    };

    const openOperationModal = (operation) => {
        performEwalletWithdrawalsOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.ATTEND,
            transaction,
        });

        operation === PAYMENT_ORDER_OPERATIONS.COMPLETE
            ? openVerifyModal(dispatch)
            : openRejectModal(dispatch);
    };

    // ============================================
    // RENDER
    // ============================================

    return (attendantUserName !== operatorName &&
        transactionStatus === TRANSACTION_STATUSES.ATTENDING) ||
        disabled ? (
        <ButtonGray disabled label={getLabel()} className="action-btn__verify" />
    ) : (
        <>
            <Row justify="space-between">
                <ButtonGreen
                    className="action-btn__verify action-btn"
                    label={getTranslation('Process')}
                    onClick={() => openOperationModal(PAYMENT_ORDER_OPERATIONS.COMPLETE)}
                />
                <ButtonRed
                    className="action-btn__verify action-btn"
                    label={getTranslation('Reject')}
                    onClick={() => openOperationModal(PAYMENT_ORDER_OPERATIONS.REJECT)}
                />
            </Row>
        </>
    );
};

export default OperationButton;
