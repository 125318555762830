import React from 'react';

import { ButtonTransparent } from '../../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../../common/helpers/locale';
import { onRemarkModalOpen } from '../cryptoWithdrawalRecordActions';
import { useDispatch } from '../../../../../store/StateProvider';

const ViewRemarkButton = ({ operatorRemark, auditorRemark }) => {
    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ============================================

    const openRemarkModal = () => onRemarkModalOpen(dispatch, { auditorRemark, operatorRemark });

    // ============================================
    // RENDER
    // ============================================

    return (
        <ButtonTransparent className="hyperlink-button" onClick={openRemarkModal}>
            {getTranslation('View')}
        </ButtonTransparent>
    );
};

export default ViewRemarkButton;
