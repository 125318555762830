export const ACTIONS = {
    MENU_LIST_GET: 'MENU_LIST_GET',
    MENU_LIST_GET_FAILURE: 'MENU_LIST_GET_FAILURE',
    MENU_LIST_GET_SUCCESS: 'MENU_LIST_GET_SUCCESS',
};

//#region
export const menuListGet = (dispatch) =>
    dispatch({
        type: ACTIONS.MENU_LIST_GET,
    });

export const menuListGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MENU_LIST_GET_FAILURE,
    });

export const menuListGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MENU_LIST_GET_SUCCESS,
    });
//#endregion
