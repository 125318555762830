import React from 'react';

import { Table } from 'antd';

import './SortingTable.less';
import { getColumns } from './SortingTableMeta';

const SortingTable = ({ loading, tableData, dispatch }) => {
    return (
        <Table
            columns={getColumns(dispatch, tableData)}
            rowKey={(record) => record.bonusId}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            dataSource={tableData}
            loading={loading}
            className="sorting-table"
        />
    );
};

export default SortingTable;
