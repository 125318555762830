import React from 'react';

import { Row } from 'antd';
import _ from 'lodash';

import { InputGroupFilter, SelectFilter, TextFilter } from '../filters';
import { MEMBER_LEVELS } from '../../../common/constants/misc';
import { PeriodFilter } from '../../../common/components/filters';
import {
    REFEREE_DEPOSIT_CONDITION,
    REFEREE_WINLOSS_CONDITION,
    REFEREE_WITHDRAWAL_CONDITION,
    REFERRAL_CONVERSTION_RATE_CONDITION,
    REWARDS_EARNED_CONDITION,
    WEEKLY_REFEREE_CONDITION,
} from '../constants';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { referrerReportFilterSet } from './referrerReportActions';
import { useStore } from '../../../store/StateProvider';

const ReferrerReportFilters = () => {
    const [state, dispatch] = useStore();

    const { referrerReport } = getStateProp(state, 'referral');
    const filters = getStateProp(referrerReport, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => referrerReportFilterSet(dispatch, { filter, value });

    const setFilterGroup = (filter, value) => {
        const valueObject = getStateProp(filters, filter, {});
        referrerReportFilterSet(dispatch, { filter, value: _.merge(valueObject, value) });
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <TextFilter
                value={getStateProp(filters, 'userName')}
                onFilterChange={setFilter}
                name="userName"
                placeholder={getTranslation('User')}
            />
            <SelectFilter
                value={getStateProp(filters, 'playerLevel')}
                onFilterChange={setFilter}
                name="playerLevel"
                placeholder={getTranslation('Player Level')}
                optionList={MEMBER_LEVELS}
            />
            <InputGroupFilter
                value={getStateProp(filters, 'totalRefereeWeekly')}
                placeholder={getTranslation('Total Referee in past 7 days')}
                onFilterChange={setFilterGroup}
                name="totalRefereeWeekly"
                optionList={WEEKLY_REFEREE_CONDITION}
            />
            <InputGroupFilter
                value={getStateProp(filters, 'rewardsEarned')}
                onFilterChange={setFilterGroup}
                placeholder={getTranslation('Rewards Earn')}
                name="rewardsEarned"
                optionList={REWARDS_EARNED_CONDITION}
            />
            <InputGroupFilter
                value={getStateProp(filters, 'refereeDeposit')}
                onFilterChange={setFilterGroup}
                placeholder={getTranslation('Referee Deposit')}
                name="refereeDeposit"
                optionList={REFEREE_DEPOSIT_CONDITION}
            />
            <InputGroupFilter
                value={getStateProp(filters, 'refereeWinLoss')}
                onFilterChange={setFilterGroup}
                placeholder={getTranslation('Referee Win/Loss')}
                name="refereeWinLoss"
                optionList={REFEREE_WINLOSS_CONDITION}
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(filters, 'registerDateFrom')}
                dateFromName="registerDateFrom"
                dateTo={getStateProp(filters, 'registerDateTo')}
                dateToName="registerDateTo"
                prefix="Create"
            />
            <TextFilter
                value={getStateProp(filters, 'averageRefereePerDay')}
                onFilterChange={setFilter}
                name="averageRefereePerDay"
                placeholder={getTranslation('AVG of Referee per day')}
            />
            <InputGroupFilter
                value={getStateProp(filters, 'conversionRate')}
                onFilterChange={setFilterGroup}
                placeholder={getTranslation('Referral Conversion Rate')}
                name="conversionRate"
                optionList={REFERRAL_CONVERSTION_RATE_CONDITION}
            />
            <InputGroupFilter
                value={getStateProp(filters, 'refereeWithdraw')}
                onFilterChange={setFilterGroup}
                placeholder={getTranslation('Referee Withdrawal')}
                name="refereeWithdraw"
                optionList={REFEREE_WITHDRAWAL_CONDITION}
            />
        </Row>
    );
};

export default ReferrerReportFilters;
