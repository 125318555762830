import React, { useEffect } from 'react';

import { Col, Form, Input, Modal, Row, Select } from 'antd';
import _ from 'lodash';

import './VerifyModal.less';
import { ButtonGray, ButtonGreen } from '../../../../common/components/buttons/Buttons';
import { PAYMENT_ORDER_OPERATIONS } from '../../../../common/constants/misc';
import {
    closeVerifyModal,
    performEwalletWithdrawalsOperation,
    withdrawalVerifyInfoSet,
} from '../ewalletWithdrawalsActions';
import { equalityValidator } from '../../../../common/helpers/validators';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useStore } from '../../../../store/StateProvider';

const VerifyModal = ({ visible, onCancel, transaction, operationPending }) => {
    const {
        transactionNo,
        playerUserName,
        eWalletName,
        eWalletHolderName,
        eWalletAccountNo,
        amount = 0,
        currencyCode,
        assignedAccountName,
        assignedAccountNo,
        assignedEWalletName,
        withdrawalFee,
    } = transaction;

    const [state, dispatch] = useStore();
    const withdrawals = getStateProp(state, 'payments.ewalletWithdrawals');
    const ewalletCards = _.filter(getStateProp(withdrawals, 'ewalletCards', []), (item) => {
        return (
            item.currencyCode === currencyCode
            // item.maximumAmount >= amount &&
            // item.minimumAmount <= amount
        );
    });
    const withdrawalVerifyInfo = getStateProp(withdrawals, 'verifyInfo.selectedCard');
    const isRecord = getStateProp(withdrawals, 'operation.isRecord', false);
    const [form] = Form.useForm();
    const { setFieldsValue } = form;

    // ============================================
    // METHODS
    // ============================================

    const setWithdrawalVerifyInfo = (verifyInfo, value) => {
        withdrawalVerifyInfoSet(dispatch, {
            value: verifyInfo === 'selectedCard' ? getSelectedBankCardDetails(value) : value,
            verifyInfo,
        });
    };

    const getSelectedBankCardDetails = (accountId) => {
        return _.find(ewalletCards, ['accountId', accountId]);
    };

    const verifyWithdrawal = () => {
        performEwalletWithdrawalsOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.COMPLETE,
        });
        closeVerifyModal(dispatch);
    };

    const closeModal = () => closeVerifyModal(dispatch);

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        setFieldsValue({
            confirmedAmount: isRecord ? amount : null,
            withdrawalFee: isRecord ? withdrawalFee : null,
        });
    }, [amount, isRecord, setFieldsValue, withdrawalFee]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            title={getTranslation('Verify E-Wallet Withdrawal Request')}
            getContainer={false} // solve the useForm not connected warning
            centered
            visible={visible}
            closable={false}
            className="withdrawal__verification-modal"
            width="900px"
            onCancel={isRecord ? closeModal : onCancel}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    {!isRecord && (
                        <ButtonGreen
                            form="verify-withdrawal"
                            label={getTranslation('Process')}
                            loading={operationPending}
                            htmlType="submit"
                        />
                    )}

                    <ButtonGray
                        label={getTranslation('Cancel')}
                        onClick={isRecord ? closeModal : onCancel}
                    />
                </Row>,
            ]}
        >
            <Form form={form} name="verify-withdrawal" onFinish={verifyWithdrawal}>
                <div className="withdrawals__details-section">
                    <Row className="withdrawals__verification-row">
                        <Col span={7}>
                            <label className="modal-description">
                                {getTranslation('Task Transaction Number')}
                            </label>
                        </Col>
                        <Col span={1}>:</Col>
                        <Col span={16}>{transactionNo}</Col>
                    </Row>

                    <Row className="withdrawals__verification-row">
                        <Col span={7}>
                            <label className="modal-description">
                                {getTranslation('Player Username')}
                            </label>
                        </Col>
                        <Col span={1}>:</Col>
                        <Col span={16}>{playerUserName}</Col>
                    </Row>

                    <Row className="withdrawals__verification-row">
                        <Col span={7}>
                            <label className="modal-description">
                                {getTranslation('Player E-Wallet')}
                            </label>
                        </Col>
                        <Col span={1}>:</Col>
                        <Col span={16}>{eWalletName}</Col>
                    </Row>

                    <Row className="withdrawals__verification-row">
                        <Col span={7}>
                            <label className="modal-description">
                                {getTranslation('Player Account Name')}
                            </label>
                        </Col>
                        <Col span={1}>:</Col>
                        <Col span={16}>{eWalletHolderName}</Col>
                    </Row>

                    <Row className="withdrawals__verification-row">
                        <Col span={7}>
                            <label className="modal-description">
                                {getTranslation('Player Account Number')}
                            </label>
                        </Col>
                        <Col span={1}>:</Col>
                        <Col span={16}>{eWalletAccountNo}</Col>
                    </Row>

                    {/*      <Row className="withdrawals__verification-row">
                        <Col span={7}>
                            <label className="modal-description">
                                {getTranslation('Province')}
                            </label>
                        </Col>
                        <Col span={1}>:</Col>
                        <Col span={6}>{province}</Col>

                        <Col span={4}>
                            <label className="modal-description">{getTranslation('Branch')}</label>
                        </Col>
                        <Col span={1}>:</Col>
                        <Col span={5}>{branchName}</Col>
                    </Row>

                    <Row className="withdrawals__verification-row">
                        <Col span={7}>
                            <label className="modal-description">{getTranslation('City')}</label>
                        </Col>
                        <Col span={1}>:</Col>
                        <Col span={6}>{city}</Col>
                    </Row> */}

                    <Row className="withdrawals__verification-row">
                        <Col span={7}>
                            <label className="modal-description">
                                {getTranslation('Withdrawal Request Amount')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={1}>:</Col>
                        <Col span={16}>{amount}</Col>
                    </Row>
                </div>
                <div className="withdrawals__verification-section">
                    <Row className="withdrawals__verification-row">
                        <Col span={7}>
                            <label className="modal-description">
                                {getTranslation('Withdrawal Amount Confirmed')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={7}>
                            <Form.Item
                                name="confirmedAmount"
                                rules={[
                                    {
                                        message: getTranslation('Please input amount!'),
                                        required: true,
                                    },
                                    {
                                        validator: equalityValidator(
                                            amount.toString(),
                                            getTranslation('Confirmed amount is incorrect!'),
                                        ),
                                    },
                                ]}
                            >
                                <Input
                                    style={{ width: '180px' }}
                                    onChange={(e) =>
                                        setWithdrawalVerifyInfo('withdrawalAmount', e.target.value)
                                    }
                                    disabled={isRecord}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <label className="modal-description">
                                {getTranslation('Assigner')}
                            </label>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="selectedCard"
                                rules={[
                                    {
                                        message: getTranslation('Please select E-Wallet card'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    className="filter"
                                    onChange={(value) =>
                                        setWithdrawalVerifyInfo('selectedCard', value)
                                    }
                                    disabled={isRecord}
                                >
                                    {_.map(ewalletCards, (cards) => (
                                        <Select.Option
                                            value={cards.accountId}
                                            key={cards.accountId}
                                        >
                                            {cards.eWalletAccountNo.toLocaleUpperCase()}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className="withdrawals__verification-row">
                        <Col span={7}>
                            <label className="modal-description">
                                {getTranslation('Withdrawal Fee Confirmed')}
                            </label>
                        </Col>
                        <Col span={7}>
                            <Form.Item
                                name="withdrawalFee"
                                rules={[
                                    {
                                        message: getTranslation('Please input number!'),
                                        pattern: '[0-9]',
                                    },
                                ]}
                            >
                                <Input
                                    style={{ width: '180px' }}
                                    onChange={(e) =>
                                        setWithdrawalVerifyInfo('withdrawalFee', e.target.value)
                                    }
                                    disabled={isRecord}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <label className="modal-description">
                                {getTranslation('Assigned Ewallet')}
                            </label>
                        </Col>
                        <Col span={6}>
                            <span>
                                {isRecord
                                    ? assignedEWalletName
                                    : withdrawalVerifyInfo && withdrawalVerifyInfo.eWalletName}
                            </span>
                        </Col>
                    </Row>

                    <Row className="withdrawals__verification-row">
                        <Col span={7}>
                            {!isRecord && (
                                <label className="modal-description">
                                    {getTranslation('E-Wallet Balance')}
                                </label>
                            )}
                        </Col>
                        <Col span={7}>
                            <span>
                                {withdrawalVerifyInfo && !isRecord && withdrawalVerifyInfo.balance}
                            </span>
                        </Col>

                        <Col span={4}>
                            <label className="modal-description">
                                {getTranslation('Assigned Account')}
                            </label>
                        </Col>

                        <Col span={6}>
                            <span>
                                {isRecord
                                    ? assignedAccountName
                                    : withdrawalVerifyInfo &&
                                      withdrawalVerifyInfo.accountHolderName}
                            </span>
                        </Col>
                    </Row>

                    <Row className="withdrawals__verification-row">
                        <Col span={14} />
                        <Col span={4}>
                            <label className="modal-description">
                                {getTranslation('Assigned Account No.')}
                            </label>
                        </Col>
                        <Col span={6}>
                            <span>
                                {isRecord
                                    ? assignedAccountNo
                                    : withdrawalVerifyInfo && withdrawalVerifyInfo.eWalletAccountNo}
                            </span>
                        </Col>
                    </Row>
                </div>
            </Form>
        </Modal>
    );
};

export default VerifyModal;
