import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { WITHDRAW_FILTERS } from '../constants';
import { getTranslation } from '../../../../common/helpers/locale';

function WithdrawFilter({ value, onFilterChange, name }) {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange(name, value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear
            placeholder={getTranslation('Withdraw')}
            className="filter"
        >
            {_.map(WITHDRAW_FILTERS, (e) => (
                <Select.Option value={e} key={e}>
                    {e.toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
}

export default WithdrawFilter;
