import React, { useState } from 'react';
import _ from 'lodash';

import { Button, Col, Modal, Row, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { ButtonBlue, ButtonGray, ButtonGreen } from '../../../common/components/buttons/Buttons';
import { TEMPLATE_URL } from './constants';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';
import { useUploadExcelAsyncEndpoint } from '../../../services/requestBonusService';

const UploadModal = ({ onCancel, visible }) => {
    const [state] = useStore();
    const [selectedFile, setSelectedFile] = useState([]);
    const downloadExcelResultUrl = getStateProp(
        state,
        'bonus.requestBonus.downloadExcelResultUrl',
        '',
    );

    // ============================================
    // METHOD
    // ============================================
    const handleOnCancel = () => {
        setSelectedFile([]);
        onCancel();
    };

    const handleRemoveSelectedFile = (file) => {
        setSelectedFile([]);
    };

    const handleBeforeUpload = (file) => {
        setSelectedFile([file]);
        return false;
    };

    const handleConfirmUpload = () => {
        if (!_.isEmpty(selectedFile)) {
            let reader = new FileReader();
            reader.readAsDataURL(selectedFile[0]);
            reader.onloadend = () => {
                handleUploadExcel(reader.result.split(';base64,')[1]);
            };
        }
    };

    const handleDownload = () => {
        // Create blob link to download
        const link = document.createElement('a');
        link.href = downloadExcelResultUrl;
        link.setAttribute('download', `FileName.xlsx`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    };

    const handleDownloadTemplate = () => {
        // Create blob link to download
        const templateLink = document.createElement('a');
        templateLink.href = TEMPLATE_URL;
        templateLink.setAttribute('download', `template.xlsx`);

        // Append to html link element page
        document.body.appendChild(templateLink);

        // Start download
        templateLink.click();

        // Clean up and remove the link
        templateLink.parentNode.removeChild(templateLink);
    };

    // ============================================
    // HOOKS
    // ============================================
    const [uploading, handleUploadExcel] = useUploadExcelAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            title={getTranslation('Upload File')}
            visible={visible}
            centered
            closable={false}
            footer={
                downloadExcelResultUrl ? (
                    <Row justify="space-between" align="middle">
                        <ButtonBlue
                            label={getTranslation('Download File')}
                            onClick={handleDownload}
                        />
                        <ButtonGray label={getTranslation('Cancel')} onClick={handleOnCancel} />
                    </Row>
                ) : (
                    <Row key="actions" justify="space-between" align="middle">
                        <ButtonGreen
                            label={getTranslation('Confirm')}
                            onClick={handleConfirmUpload}
                            loading={uploading}
                        />
                        <ButtonBlue
                            label={getTranslation('Download Template')}
                            onClick={handleDownloadTemplate}
                            disabled={uploading}
                        />
                        <ButtonGray
                            label={getTranslation('Cancel')}
                            onClick={handleOnCancel}
                            disabled={uploading}
                        />
                    </Row>
                )
            }
            onCancel={handleOnCancel}
        >
            {downloadExcelResultUrl ? (
                <Row align={'middle'} justify={'center'}>
                    <span>{getTranslation('Please download the excel file for the result')}</span>
                </Row>
            ) : (
                <>
                    <Row align={'middle'}>
                        <Col span={6}>
                            <label>{getTranslation('Select File:')}</label>
                        </Col>
                        <Col span={16}>
                            <Upload
                                accept=".xlsx"
                                onRemove={handleRemoveSelectedFile}
                                beforeUpload={handleBeforeUpload}
                                fileList={selectedFile}
                            >
                                {_.isEmpty(selectedFile) && (
                                    <Button icon={<UploadOutlined />}>Select File</Button>
                                )}
                            </Upload>
                        </Col>
                    </Row>
                </>
            )}
        </Modal>
    );
};

export default UploadModal;
