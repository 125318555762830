import React from 'react';

import {
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Radio,
    Row,
    Select,
    Switch,
    Tabs,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';

import './RebateFormInputs.less';
import { CURRENCIES } from '../../../common/constants/localization';
import { DAYS_IN_WEEK, DAYS_IN_WEEK_FULL_VALUE, MIN_THRESHOLD, REBATE_TYPE } from '../constants';
import { VIP_LEVELS } from '../../rewards-management/constants';
import { getStateProp, isSupportedLanguage } from '../../../common/helpers/misc';
import { getSupportedCurrenciesByCountry, getTranslation } from '../../../common/helpers/locale';
import { useGetRebateFilterListAsyncEndpoint } from '../../../services/rebateService';
import { useStore } from '../../../store/StateProvider';
const { TabPane } = Tabs;

const RebateFormInputs = ({
    isEdit,
    onRebateInfoChange,
    onRebateContentChange,
    rebateInfo,
    rebateContent,
    langCode,
    onRebateMultiInfoChange,
}) => {
    const [state] = useStore();
    const filterOptions = getStateProp(state, 'rebate.rebateFilterOptions', {});
    const location = getStateProp(state, 'timezone.location');
    const uniqueCurrencies = _.uniq(_.map(rebateInfo.currencies, 'currencyCode'));
    const supportedCurrency = _.map(
        getSupportedCurrenciesByCountry(),
        (currency) => currency.currency,
    );

    // ============================================
    // METHODS
    // ============================================

    const onRebateCurrencyChange = (value) => {
        let tempRebateVip = rebateInfo.currencies
            ? _.filter([...rebateInfo.currencies], (currencyInfo) =>
                  value.includes(currencyInfo.currencyCode),
              )
            : [];

        _.map(value, (currency) => {
            const existedCurrency = _.find(tempRebateVip, (currencyInfo) => {
                return currencyInfo.currencyCode === currency;
            });
            if (!existedCurrency) {
                _.map(VIP_LEVELS, (vip) => {
                    tempRebateVip.push({
                        currencyCode: currency,
                        minRebateThreshold: _.find(MIN_THRESHOLD, (threshold) => {
                            return threshold.currency === currency;
                        }).amount,
                        rebatePercentage: 0,
                        vipLevel: vip.level,
                    });
                });
            }
        });

        onRebateInfoChange('currencies', tempRebateVip);
    };

    const onRebateCurrencyRelatedChange = (value, currencyCode, name, vipLevel) => {
        const toUpdate = _.find(rebateInfo.currencies, (currency) => {
            return currency.currencyCode === currencyCode && currency.vipLevel === vipLevel;
        });

        if (toUpdate) {
            toUpdate[name] = value;
        } else {
            let newObj = {
                currencyCode: currencyCode,
                vipLevel: vipLevel,
            };
            newObj[name] = value;
            rebateInfo.currencies.push(newObj);
        }
        onRebateInfoChange('currencies', rebateInfo.currencies);
    };

    const onRebateRecurrenceChange = (value, name) => {
        onRebateMultiInfoChange({
            [name]: !value
                ? null
                : _.map(value, (day) => {
                      return { day };
                  }),
            payout: null,
            rebatePeriodType: !value ? null : 'Daily',
        });
    };

    const onRebatePeriodTypeChange = (value, name) => {
        onRebateMultiInfoChange({
            [name]: value,
            payout: null,
        });
    };

    const onRebateRecurrenceDaysChange = (value, name) => {
        onRebateInfoChange(
            'recurrences',
            name === 'recurrences' && !value
                ? null
                : _.map(value, (day) => {
                      return { day };
                  }),
        );
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading] = useGetRebateFilterListAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col>
            <Row className="rebate-form__row">
                <Col span={8}>
                    <label>{getTranslation('Rebate Code')}</label>
                </Col>
                <Col span={16}>
                    <Form.Item>
                        <Input
                            disabled={isEdit}
                            onChange={(e) => onRebateInfoChange('code', e.target.value)}
                            value={rebateInfo.code}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row className="rebate-form__row">
                <Col span={8}>
                    <label>{getTranslation('Rebate Name')}</label>
                </Col>
                <Col span={16}>
                    <Form.Item>
                        <Input
                            onChange={(e) =>
                                onRebateContentChange('rebateName', e.target.value, langCode)
                            }
                            value={rebateContent.rebateName}
                            disabled={!isSupportedLanguage(langCode)}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row className="rebate-form__row">
                <Col span={8}>
                    <label>{getTranslation('Start Date')}</label>
                </Col>
                <Col span={16}>
                    <Form.Item>
                        <DatePicker
                            onChange={(value) =>
                                onRebateInfoChange(
                                    'startDateTime',
                                    value.utc().format(isEdit && 'YYYY-MM-DD HH:mm:ss'),
                                )
                            }
                            value={
                                isEdit
                                    ? moment(rebateInfo.startDateTime)
                                          .utcOffset(+0, true)
                                          .tz(location)
                                    : moment(rebateInfo.startDateTime).tz(location)
                            }
                            format="YYYY-MM-DD HH:mm:ss"
                            allowClear={false}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row className="rebate-form__row">
                <Col span={8}>
                    <label>{getTranslation('End Date')}</label>
                </Col>
                <Col span={16}>
                    <Form.Item>
                        <DatePicker
                            onChange={(value) => {
                                onRebateInfoChange(
                                    'endDateTime',
                                    value.utc().format(isEdit && 'YYYY-MM-DD HH:mm:ss'),
                                );
                            }}
                            value={
                                isEdit
                                    ? moment(rebateInfo.endDateTime)
                                          .utcOffset(+0, true)
                                          .tz(location)
                                    : moment(rebateInfo.endDateTime).tz(location)
                            }
                            format="YYYY-MM-DD HH:mm:ss"
                            allowClear={false}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row className="rebate-form__row">
                <Col span={8}>
                    <label>{getTranslation('Product')}</label>
                </Col>
                <Col span={16}>
                    <Form.Item>
                        <Select
                            onChange={(value) => onRebateInfoChange('product', value)}
                            value={rebateInfo.product}
                            loading={loading}
                        >
                            {_.map(filterOptions.products, (product) => (
                                <Select.Option key={product.id} value={product.productCode}>
                                    {product.productDesc.toLocaleUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row className="rebate-form__row">
                <Col span={8}>
                    <label>{getTranslation('Turnover Requirement')}</label>
                </Col>
                <Col span={16}>
                    <Form.Item>
                        <InputNumber
                            min={0}
                            onChange={(value) => onRebateInfoChange('turnoverCondition', value)}
                            value={rebateInfo.turnoverCondition}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row className="rebate-form__row">
                <Col span={8}>
                    <label>{getTranslation('Currency')}</label>
                </Col>
                <Col span={16}>
                    <Form.Item>
                        <Checkbox.Group
                            style={{ width: '100%' }}
                            onChange={(value) => onRebateCurrencyChange(value)}
                            value={_.uniq(
                                _.map(rebateInfo.currencies, (currency) => {
                                    return currency.currencyCode;
                                }),
                            )}
                        >
                            <Row style={{ flexFlow: 'row wrap' }}>
                                {_.map(CURRENCIES, (currency) => (
                                    <Col span={8} key={currency.currency}>
                                        <Checkbox
                                            value={currency.currency}
                                            disabled={
                                                !_.includes(supportedCurrency, currency.currency)
                                            }
                                        >
                                            {currency.currency}
                                        </Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>

            {rebateInfo.currencies && rebateInfo.currencies.length !== 0 && (
                <>
                    <Divider />
                    <Col>
                        <Tabs className="rebate__tabs" tabPosition="left">
                            {_.map(uniqueCurrencies, (currencyCode) => (
                                <TabPane tab={currencyCode} key={currencyCode}>
                                    <Tabs>
                                        {_.map(VIP_LEVELS, (vip) => {
                                            const currency = _.find(
                                                rebateInfo.currencies,
                                                (data) => {
                                                    return (
                                                        data.currencyCode === currencyCode &&
                                                        data.vipLevel === vip.level
                                                    );
                                                },
                                            );

                                            return (
                                                <TabPane
                                                    tab={vip.displayName}
                                                    key={vip.displayName}
                                                >
                                                    <Row className="rebate-form__row">
                                                        <Col span={6}>
                                                            <label>
                                                                {getTranslation(
                                                                    'Min Rebate Threshold',
                                                                )}
                                                            </label>
                                                        </Col>
                                                        <Col span={18}>
                                                            <Row
                                                                justify="space-around"
                                                                className="rebate-form__min-threshold-row"
                                                            >
                                                                <Form.Item>
                                                                    <InputNumber
                                                                        min={0}
                                                                        value={
                                                                            currency &&
                                                                            currency.minRebateThreshold
                                                                        }
                                                                        onChange={(value) =>
                                                                            onRebateCurrencyRelatedChange(
                                                                                value,
                                                                                currencyCode,
                                                                                'minRebateThreshold',
                                                                                vip.level,
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            !_.includes(
                                                                                supportedCurrency,
                                                                                currencyCode,
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                    <Row className="rebate-form__row">
                                                        <Col span={6}>
                                                            <label>
                                                                {getTranslation('Rebate %')}
                                                            </label>
                                                        </Col>
                                                        <Col span={18}>
                                                            <Form.Item>
                                                                <InputNumber
                                                                    min={0}
                                                                    onChange={(value) =>
                                                                        onRebateCurrencyRelatedChange(
                                                                            value,
                                                                            currencyCode,
                                                                            'rebatePercentage',
                                                                            vip.level,
                                                                        )
                                                                    }
                                                                    value={
                                                                        currency &&
                                                                        currency.rebatePercentage
                                                                    }
                                                                    disabled={
                                                                        !_.includes(
                                                                            supportedCurrency,
                                                                            currencyCode,
                                                                        )
                                                                    }
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            );
                                        })}
                                    </Tabs>
                                </TabPane>
                            ))}
                        </Tabs>
                    </Col>
                    <Divider />
                </>
            )}

            <Row className="rebate-form__row">
                <Col span={8}>{getTranslation('Recurrance')}</Col>
                <Col span={16}>
                    <Form.Item valuePropName="checked">
                        <Switch
                            onChange={(checked) => onRebateRecurrenceChange(checked, 'recurrences')}
                            checked={!!rebateInfo.recurrences}
                        />
                    </Form.Item>
                </Col>
            </Row>
            {rebateInfo.recurrences && (
                <>
                    <Row className="rebate-form__row">
                        <Col span={8}>{getTranslation('Rebate Type')}</Col>
                        <Col span={16}>
                            <Form.Item valuePropName="checked">
                                <Radio.Group
                                    onChange={(e) =>
                                        onRebatePeriodTypeChange(e.target.value, 'rebatePeriodType')
                                    }
                                    value={rebateInfo.rebatePeriodType}
                                >
                                    {_.map(REBATE_TYPE, (e) => (
                                        <Radio key={e} value={e}>
                                            {getTranslation(e)}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="rebate-form__row">
                        <Col span={8}>{getTranslation('Day')}</Col>
                        <Col span={16}>
                            <Form.Item valuePropName="checked">
                                <Checkbox.Group
                                    style={{ width: '100%' }}
                                    onChange={(value) =>
                                        onRebateRecurrenceDaysChange(value, 'days')
                                    }
                                    value={_.map(rebateInfo.recurrences, (recurrence) => {
                                        return recurrence.day;
                                    })}
                                >
                                    <Row style={{ flexFlow: 'row wrap' }}>
                                        {_.map(DAYS_IN_WEEK, (day) => (
                                            <Col span={8} key={day}>
                                                <Checkbox value={day}>
                                                    {getTranslation(day)}
                                                </Checkbox>
                                            </Col>
                                        ))}
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    {rebateInfo.rebatePeriodType === 'Weekly' && (
                        <Row className="rebate-form__row">
                            <Col span={8}>{getTranslation('Payout')}</Col>
                            <Col span={16}>
                                <Form.Item valuePropName="checked">
                                    <Radio.Group
                                        onChange={(e) =>
                                            onRebateInfoChange('payout', e.target.value)
                                        }
                                        value={rebateInfo.payout}
                                    >
                                        <Row style={{ flexFlow: 'row wrap' }}>
                                            {_.map(DAYS_IN_WEEK_FULL_VALUE, (day) => (
                                                <Col span={8} key={`payout-${day.display}`}>
                                                    <Radio key={day.display} value={day.value}>
                                                        {getTranslation(day.display)}
                                                    </Radio>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </Col>
    );
};

export default RebateFormInputs;
