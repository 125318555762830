import React from 'react';

import { Table } from 'antd';

import { getColumns } from './DangerLevelSettingMeta';

const DangerLevelSettingTable = ({ loading, tableData, handleDangerLevelSettingModal }) => {
    // ============================================
    // METHODS
    // ============================================

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns(handleDangerLevelSettingModal)}
            rowKey={(record, idx) => `danger-level-tag-${idx}`}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            dataSource={tableData}
            loading={loading}
            pagination={false}
        />
    );
};

export default DangerLevelSettingTable;
