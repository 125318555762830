import { MAX_TABLE_ROW_SELECT_LIMIT } from '../../common/constants/misc';
import { getTranslation } from '../../common/helpers/locale';
import { message } from 'antd';

export const ACTIONS = {
    FILE_DELETE: 'FILE_DELETE',
    FILE_DELETE_FAILURE: 'FILE_DELETE_FAILURE',
    FILE_DELETE_SUCCESS: 'FILE_DELETE_SUCCESS',
    FILE_LIST_GET: 'FILE_LIST_GET',
    FILE_LIST_GET_FAILURE: 'FILE_LIST_GET_FAILURE',
    FILE_LIST_GET_SUCCESS: 'FILE_LIST_GET_SUCCESS',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'FILES_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'FILES_TABLE_PAGE_SIZE_CHANGE',
    },
    RECORD_SELECT: 'FILE_RECORD_SELECT',
    REFRESH_TIME_PAUSE: 'FILE_LIST_REFRESH_TABLE_TIME_PAUSE',
    REFRESH_TIME_SET: 'FILE_LIST_REFRESH_TABLE_TIME_SET',
};

export const fileListGet = (dispatch) =>
    dispatch({
        type: ACTIONS.FILE_LIST_GET,
    });

export const fileListGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILE_LIST_GET_SUCCESS,
    });

export const fileListGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILE_LIST_GET_FAILURE,
    });

export const fileDelete = (dispatch) =>
    dispatch({
        type: ACTIONS.FILE_DELETE,
    });

export const fileDeleteSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.FILE_DELETE_SUCCESS,
    });

export const fileDeleteFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILE_DELETE_FAILURE,
    });

export const selectRecord = (dispatch, payload) =>
    payload.length <= MAX_TABLE_ROW_SELECT_LIMIT
        ? dispatch({
              payload,
              type: ACTIONS.RECORD_SELECT,
          })
        : message.warning(
              getTranslation('You cannot select more than {limit} rows', {
                  limit: MAX_TABLE_ROW_SELECT_LIMIT,
              }),
          );

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const setRefreshTableTime = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.REFRESH_TIME_SET,
    });
};

export const pauseRefreshTableTime = (dispatch) => {
    dispatch({
        type: ACTIONS.REFRESH_TIME_PAUSE,
    });
};
