import { useHistory } from 'react-router';
import React, { useEffect } from 'react';

import { Card, Divider, Form, Modal, Row, Steps, message } from 'antd';
import _ from 'lodash';

import { BONUS_STEP, FORM_ASPECT } from '../constants';
import { ButtonGreen, ButtonTransparent } from '../../../common/components/buttons/Buttons';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ROUTES } from '../../../common/constants/routing';
import {
    bonusDuplicateByLanguage,
    bonusFormClear,
    dropBonusLang,
    setBonusInfo,
    setBonusInfoByLanguage,
    setBonusLang,
    setCurrentStep,
    setMultiBonusInfo,
} from './createBonusActions';
import { editorDefaultStylingSizeSet, getStateProp } from '../../../common/helpers/misc';
import { formValidation } from '../helpers';
import { getTranslation } from '../../../common/helpers/locale';
import { useCreateBannerOperationAsyncEndpoint } from '../../../services/bonusManagementService';
import { useGetAvailableBonusCategoryAsyncEndpoint } from '../../../services/bonusCategoryService';
import { useGetBetListFilterOptionsAsyncEndpoint } from '../../../services/betService';
import { useStore } from '../../../store/StateProvider';
import BonusTabs from '../shared/BonusTabs';
const { Step } = Steps;

const CreateBonus = () => {
    const [state, dispatch] = useStore();
    const history = useHistory();
    const { platforms = [], products = [] } = getStateProp(state, 'bet.betList.filterOptions', []);
    const bonus = getStateProp(state, 'bonus.createBonus', {});
    const details = getStateProp(state, 'bonus.createBonus.details', []);
    const currentStep = getStateProp(state, 'bonus.createBonus.currentStep', 0);
    const selectedLanguage = _.map(details, (value) => {
        return value.languageCode;
    }).sort();
    const platformsArrary = _.map(platforms, (e) => {
        return e.platformCode;
    });
    const productsArrary = _.map(products, (e) => {
        return e.productCode;
    });

    // ============================================
    // METHODS
    // ============================================

    const submitForm = () => {
        if (formValidation(bonus, currentStep, bonus.isShowOnApp, FORM_ASPECT.CREATE)) {
            if (currentStep < BONUS_STEP.length - 1) {
                setCurrentStep(dispatch, currentStep + 1);
            } else {
                if (!bonus.isDepositEtoComplete && bonus.isPromoRule) {
                    message.warning(getTranslation('Please enable auto approve bonus'));
                } else if (
                    !bonus.isDepositBonus &&
                    (bonus.isETODeposit || bonus.isETODepositAndBonus)
                ) {
                    message.warning(getTranslation('Please enable deposit bonus'));
                } else {
                    handleCreateBanner();
                }
            }
        }
    };

    const resetForm = () => bonusFormClear(dispatch);

    const setBonusLanguage = (e) => {
        if (e.target.checked) {
            details.push({
                bonusDetail: '',
                imageInBase64: null,
                languageCode: e.target.value,
                termCondition: '',
            });

            setBonusLang(dispatch, details);
        } else {
            dropBonusLang(
                dispatch,
                _.reject(details, (content) => {
                    return content.languageCode === e.target.value;
                }),
            );
        }
    };

    const onContentChange = (name, value, langCode) => {
        setBonusInfoByLanguage(dispatch, {
            langCode,
            name,
            value: editorDefaultStylingSizeSet(value),
        });
    };

    const onBonusInfoChange = (name, value) => {
        let newCurrencies = bonus.currencies || [];
        if (name === 'isShowOnApp' && !value) {
            let newDetails = details;
            _.forEach(newDetails, (e) => {
                e.bonusDetail = '';
                e.imageInBase64 = null;
                e.termCondition = '';
                e.shortDetail = '';
            });
            setMultiBonusInfo(dispatch, {
                details: newDetails,
                [name]: value,
            });
        } else if (name === 'hasJoinButton') {
            _.forEach(newCurrencies, (e) => {
                e.bonusPercentage = 0;
                e.fixedBonusAmount = 0;
                e.maxBonusByPercentage = 0;
            });
            setMultiBonusInfo(dispatch, {
                currencies: newCurrencies,
                hasPromoCode: false,
                isDepositBonus: value,
                isDepositEtoComplete: value,
                isFixedBonus: value,
                minimumDeposits: [],
                [name]: value,
            });
        } else if (name === 'isFixedBonus') {
            if (!value && !bonus.isDepositBonus) {
                message.warning(
                    getTranslation(
                        'Please enable deposit bonus. If you wish to setup Bonus Amount By Percentage',
                    ),
                );
            } else {
                _.forEach(newCurrencies, (e) => {
                    e.bonusPercentage = 0;
                    e.fixedBonusAmount = 0;
                    e.maxBonusByPercentage = 0;
                });
                setMultiBonusInfo(dispatch, {
                    currencies: newCurrencies,
                    [name]: value,
                });
            }
        } else if (name === 'isDepositBonus') {
            setMultiBonusInfo(dispatch, {
                minimumDeposits: [],
                [name]: value,
            });
        } else if (name === 'isRepeatable') {
            setMultiBonusInfo(dispatch, {
                depositInterval: 0,
                [name]: value,
                repeatableCount: 0,
            });
        } else if (name === 'bonusConditionType') {
            if (
                !bonus.isDepositBonus &&
                (value === 'isETODeposit' || value === 'isETODepositAndBonus')
            ) {
                message.warning(getTranslation('Please enable deposit bonus'));
            } else {
                _.forEach(newCurrencies, (e) => {
                    e.etoDepositMultiplier = 0;
                    e.etoDepositBonusMultiplier = 0;
                    e.betAmount = 0;
                    e.winAmount = 0;
                    e.lossAmount = 0;
                });
                setMultiBonusInfo(dispatch, {
                    currencies: newCurrencies,
                    isBetAmount: value === 'isBetAmount' ? true : false,
                    isETODeposit: value === 'isETODeposit' ? true : false,
                    isETODepositAndBonus: value === 'isETODepositAndBonus' ? true : false,
                    isWinLoss: value === 'isWinLoss' ? true : false,
                    winLossType: value === 'isWinLoss' ? 'Win' : 'None',
                });
            }
        } else if (name === 'winLossType') {
            _.forEach(newCurrencies, (e) => {
                e.winAmount = 0;
                e.lossAmount = 0;
            });
            setMultiBonusInfo(dispatch, {
                currencies: newCurrencies,
                [name]: value,
            });
        } else if (name === 'checkingDateType') {
            setMultiBonusInfo(dispatch, {
                conditionDateFrom: null,
                conditionDateTo: null,
                duration: 0,
                [name]: value,
            });
        } else if (name === 'isPromoRule') {
            if (!bonus.isDepositEtoComplete && value) {
                message.warning(getTranslation('Please enable auto approve bonus'));
            } else {
                _.forEach(newCurrencies, (e) => {
                    e.etoDepositMultiplier = 0;
                    e.etoDepositBonusMultiplier = 0;
                    e.betAmount = 0;
                    e.winAmount = 0;
                    e.lossAmount = 0;
                });
                setMultiBonusInfo(dispatch, {
                    appliedPlatforms: value ? ['ALL'] : [],
                    appliedProducts: value ? ['ALL'] : [],
                    checkingDateType: 'Duration',
                    currencies: newCurrencies,
                    duration: 0,
                    isBetAmount: false,
                    isETODeposit: value ? true : false,
                    isETODepositAndBonus: false,
                    isWinLoss: false,
                    [name]: value,
                    winLossType: 'None',
                });
            }
        } else {
            setBonusInfo(dispatch, { name, value });
        }
    };

    const duplicateBonusTabs = (values) => {
        const { languageCode, ...rest } = _.find(details, (detail) => {
            return detail.languageCode === values.from;
        });

        const detailsCopyTo = _.find(details, (detail) => {
            return detail.languageCode === values.to;
        });

        const { languageCode: langCode, ...restCopyTo } = detailsCopyTo;

        if (_.every(restCopyTo, _.isEmpty)) {
            _.assign(detailsCopyTo, rest);
            bonusDuplicateByLanguage(dispatch, details);
            message.success('Copied!');
        } else {
            Modal.confirm({
                content: getTranslation(
                    'Destination copy to is not empty! Are you sure want to copy ?',
                ),
                icon: <ExclamationCircleOutlined />,
                onCancel() {
                    return false;
                },
                onOk() {
                    _.assign(detailsCopyTo, rest);
                    bonusDuplicateByLanguage(dispatch, details);
                    message.success('Copied!');
                },
                title: getTranslation('Are you sure want to copy ?'),
            });
        }
    };

    // ============================================
    // HOOKS
    // ============================================
    const [creating, handleCreateBanner, created] = useCreateBannerOperationAsyncEndpoint();
    const [getingList] = useGetAvailableBonusCategoryAsyncEndpoint();
    const [gettingBetList] = useGetBetListFilterOptionsAsyncEndpoint();

    useEffect(() => {
        if (created) {
            history.push(ROUTES.DASHBOARD.BONUS.BONUS_MANAGEMENT);
        }
    }, [created, history]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Create Bonus')} loading={gettingBetList}>
            <Form name="create-bonus" className="create-bonus__form">
                <Steps current={currentStep}>
                    {_.map(BONUS_STEP, (e) => (
                        <Step key={e.title} title={e.title} />
                    ))}
                </Steps>
                <Divider />
                <BonusTabs
                    setBonusLang={setBonusLanguage}
                    selectedLangs={selectedLanguage}
                    onContentChange={onContentChange}
                    onBonusInfoChange={onBonusInfoChange}
                    onDuplicateBonus={duplicateBonusTabs}
                    bonus={bonus}
                    isEdit={false}
                    categoryListLoading={getingList}
                    currentStep={currentStep}
                    platforms={platforms}
                    platformsArrary={platformsArrary}
                    products={products}
                    productsArrary={productsArrary}
                />
                <Row className="form__footer-row" justify="space-between">
                    <div>
                        {currentStep > 0 && (
                            <ButtonTransparent
                                label={getTranslation('Previous')}
                                onClick={() => setCurrentStep(dispatch, currentStep - 1)}
                            />
                        )}
                    </div>
                    <div>
                        <Row justify="end">
                            <ButtonGreen
                                label={getTranslation(
                                    currentStep < BONUS_STEP.length - 1 ? 'Next' : 'Submit',
                                )}
                                // htmlType="submit"
                                loading={creating}
                                style={{ marginRight: '5px' }}
                                onClick={submitForm}
                            />
                            <ButtonTransparent
                                label={getTranslation('Cancel')}
                                onClick={resetForm}
                            />
                        </Row>
                    </div>
                </Row>
            </Form>
        </Card>
    );
};

export default CreateBonus;
