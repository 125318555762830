import React from 'react';

import { Row } from 'antd';

import {
    AnnouncementStatusFilter,
    AnnouncementTaskNumberFilter,
    AnnouncementTitleFilter,
    ApplicantFilter,
    ContactMethodFilter,
    MessageTypeFilter,
} from '../../filters';
import { getStateProp } from '../../../../common/helpers/misc';
import { messageFilterSet } from './personalMessageActions';
import { useStore } from '../../../../store/StateProvider';

const PersonalMessageFilters = () => {
    const [state, dispatch] = useStore();

    const personalMessage = getStateProp(
        state,
        'announcement.announcementHistory.personalMessage',
        {},
    );
    const filters = getStateProp(personalMessage, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => messageFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <AnnouncementTaskNumberFilter
                value={getStateProp(filters, 'taskNo', '')}
                onFilterChange={setFilter}
            />
            <AnnouncementTitleFilter
                value={getStateProp(filters, 'title', '')}
                onFilterChange={setFilter}
            />
            <MessageTypeFilter
                value={getStateProp(filters, 'messageType')}
                onFilterChange={setFilter}
            />
            <AnnouncementStatusFilter
                value={getStateProp(filters, 'pmStatus')}
                onFilterChange={setFilter}
                name="pmStatus"
            />
            <ContactMethodFilter
                value={getStateProp(filters, 'contactMethod')}
                onFilterChange={setFilter}
            />
            <ApplicantFilter
                value={getStateProp(filters, 'applicant', '')}
                onFilterChange={setFilter}
            />
        </Row>
    );
};

export default PersonalMessageFilters;
