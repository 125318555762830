import { Switch } from 'react-router-dom';
import React from 'react';

import {
    Admin,
    Affiliate,
    Announcement,
    Balance,
    Banner,
    Bet,
    Bonus,
    ChangePassword,
    DashboardRoot,
    FileSystem,
    HotEvents,
    Layout,
    News,
    Payments,
    Players,
    PushNotification,
    Rebate,
    Referral,
    Reports,
    RewardsManagement,
    TransactionHistory,
    TransactionMonitoring,
    Vip,
    WhatsNew,
} from '..';
import { PrivateRoute } from '../../common/components';
import { ROUTES } from '../../common/constants/routing';

const Dashboard = () => {
    return (
        <Layout>
            <Switch>
                <PrivateRoute exact path={ROUTES.DASHBOARD.ROOT} component={DashboardRoot} />
                <PrivateRoute
                    path={ROUTES.DASHBOARD.ANNOUNCEMENT_SYSTEM.ROOT}
                    component={Announcement}
                />
                <PrivateRoute path={ROUTES.DASHBOARD.NEWS.ROOT} component={News} />
                <PrivateRoute path={ROUTES.DASHBOARD.REPORTS.ROOT} component={Reports} />
                <PrivateRoute
                    path={ROUTES.DASHBOARD.TRANSACTION_HISTORY.ROOT}
                    component={TransactionHistory}
                />
                <PrivateRoute path={ROUTES.DASHBOARD.PAYMENTS.ROOT} component={Payments} />
                <PrivateRoute path={ROUTES.DASHBOARD.PLAYERS.ROOT} component={Players} />
                <PrivateRoute
                    path={ROUTES.DASHBOARD.TRANSACTION_MONITORING}
                    component={TransactionMonitoring}
                />
                <PrivateRoute path={ROUTES.DASHBOARD.BALANCE.ROOT} component={Balance} />

                <PrivateRoute path={ROUTES.DASHBOARD.BET.ROOT} component={Bet} />
                <PrivateRoute path={ROUTES.DASHBOARD.ADMINISTRATION.ROOT} component={Admin} />
                <PrivateRoute path={ROUTES.DASHBOARD.BONUS.ROOT} component={Bonus} />
                <PrivateRoute path={ROUTES.DASHBOARD.BANNER.ROOT} component={Banner} />
                <PrivateRoute
                    path={ROUTES.DASHBOARD.CHANGE_PASSWORD}
                    component={ChangePassword}
                    hasAccessControl={true}
                />
                <PrivateRoute path={ROUTES.DASHBOARD.REFERRAL.ROOT} component={Referral} />
                <PrivateRoute
                    path={ROUTES.DASHBOARD.REWARDS_MANAGEMENT.ROOT}
                    component={RewardsManagement}
                />
                <PrivateRoute path={ROUTES.DASHBOARD.REBATE.ROOT} component={Rebate} />
                <PrivateRoute
                    path={ROUTES.DASHBOARD.PUSH_NOTIFICATION.ROOT}
                    component={PushNotification}
                />
                <PrivateRoute
                    path={ROUTES.DASHBOARD.WHATS_NEW.ROOT}
                    component={WhatsNew}
                    hasAccessControl={true}
                />
                <PrivateRoute path={ROUTES.DASHBOARD.FILE_SYSTEM} component={FileSystem} />
                <PrivateRoute path={ROUTES.DASHBOARD.VIP.ROOT} component={Vip} />
                <PrivateRoute path={ROUTES.DASHBOARD.AFFILIATE.ROOT} component={Affiliate} />
                <PrivateRoute
                    path={ROUTES.DASHBOARD.HOT_EVENTS}
                    component={HotEvents}
                    hasAccessControl={true}
                />
            </Switch>
        </Layout>
    );
};

export default Dashboard;
