import React from 'react';

import { Row } from 'antd';

import { AccountSetupTypeFilter, BankAccountNameFilter } from '../filters';
import {
    BankCardFilter,
    BankFilter,
    CurrencyFilter,
    OperatorFilter,
    PeriodFilter,
    TransactionNumberFilter,
} from '../../../common/components/filters';
import { filterSet } from './bankAccountStatementActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';

const BankAccountStatementFilters = () => {
    const [state, dispatch] = useStore();
    const bankAccountStatement = getStateProp(state, 'payments.bankAccountStatement');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => filterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <BankCardFilter
                onFilterChange={setFilter}
                value={getStateProp(bankAccountStatement, 'filters.bankCardNo')}
                name="bankCardNo"
            />
            <BankFilter
                onFilterChange={setFilter}
                value={getStateProp(bankAccountStatement, 'filters.bankName')}
                placeholder={getTranslation('Bank Account Name')}
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(bankAccountStatement, 'filters.dateFrom')}
                dateTo={getStateProp(bankAccountStatement, 'filters.dateTo')}
                dateFromName="dateFrom"
                dateToName="dateTo"
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(bankAccountStatement, 'filters.currencyCode')}
                name="currencyCode"
            />
            <BankAccountNameFilter
                onFilterChange={setFilter}
                value={getStateProp(bankAccountStatement, 'filters.cardHolderName')}
            />
            <TransactionNumberFilter
                onFilterChange={setFilter}
                value={getStateProp(bankAccountStatement, 'filters.transactionNo')}
                name="transactionNo"
            />
            <AccountSetupTypeFilter
                onFilterChange={setFilter}
                value={getStateProp(bankAccountStatement, 'filters.type')}
            />
            <OperatorFilter
                onFilterChange={setFilter}
                value={getStateProp(bankAccountStatement, 'filters.operator')}
                name="operator"
            />
        </Row>
    );
};

export default BankAccountStatementFilters;
