import React from 'react';

import { Card, Modal, Row, Spin } from 'antd';

import './PlayerDetailModal.less';
import { ButtonGray } from '../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../common/helpers/locale';

const PlayerDetailModal = ({ visible, onCancel, loading, detailPageUrl }) => {
    return (
        <Modal
            width={1120}
            title={getTranslation('Detail Page')}
            centered={false}
            closable={false}
            visible={visible}
            onCancel={onCancel}
            className="bet-list__player-detail-modal"
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGray label={getTranslation('Close')} onClick={onCancel} />
                </Row>,
            ]}
        >
            {loading ? (
                <Card style={{ border: 'none', height: 580, width: 1080 }} align="middle">
                    <Spin size="large" />
                </Card>
            ) : (
                <iframe
                    className="iframe-container"
                    title="Detail Page"
                    width="1080"
                    height="600"
                    src={detailPageUrl}
                    style={{ border: 'none' }}
                />
            )}
        </Modal>
    );
};

export default PlayerDetailModal;
