import React, { useCallback, useEffect } from 'react';

import { Col, Form, Input, Row, Select, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';

import './CryptocurrencyCorrectionForm.less';
import { ButtonGreen, ButtonTransparent } from '../../../common/components/buttons/Buttons';
import { CRYPTO_CORRECTION_TYPE, CRYPTO_CURRENCY_CODE } from './constants';
import { Loader } from '../../../common/components';
import {
    cryptoCurrencyCorrectionFormReset,
    cryptoCurrencyCorrectionInfoSet,
} from './cryptocurrencyCorrectionActions';
import { getFiatAmount, getIntOrFloat, getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useGetCryptoCurrencyTransactionAsyncEndpoint } from '../../../services/cryptoCurrencyCorrectionService';
import { useStore } from '../../../store/StateProvider';

const { Search } = Input;

const CryptocurrencyCorrectionForm = ({
    playerDetailsLoading,
    handleGetCryptoCurrencyRate,
    cryptoCurrencyRateLoading,
    cryptoCurrencyConrrectionLoading,
    operationSuccess,
    handleSubmitCryptoCurrencyCorrectionOperation,
}) => {
    const [form] = Form.useForm();
    const { resetFields, setFieldsValue } = form;
    const [state, dispatch] = useStore();

    const { cryptoCurrencyCorrectionInfo } = getStateProp(
        state,
        'balance.cryptoCurrencyCorrection',
        {},
    );
    const transactionNo = getStateProp(cryptoCurrencyCorrectionInfo, 'transactionNo', '');
    const transactionType = getStateProp(cryptoCurrencyCorrectionInfo, 'transactionType');
    const transactionNoSearchDisable = transactionType === undefined || transactionType === '';

    // ============================================
    // METHODS
    // ============================================

    const fiatAmount = getFiatAmount(
        getStateProp(cryptoCurrencyCorrectionInfo, 'cryptoAmt'),
        getStateProp(cryptoCurrencyCorrectionInfo, 'cryptoRate'),
        getStateProp(cryptoCurrencyCorrectionInfo, 'currencyCode'),
    );

    const resetForm = useCallback(() => {
        cryptoCurrencyCorrectionFormReset(dispatch);
        resetFields();
    }, [dispatch, resetFields]);

    const setCryptoCurrencyCorrectionInfo = (inputName, value) => {
        let newValue =
            inputName === 'cryptoRate' ? getIntOrFloat(value, true, 15).toFixed(15) : value;
        cryptoCurrencyCorrectionInfoSet(dispatch, { inputName, value: newValue });

        if (inputName === 'transactionNo' && value === '') {
            resetForm();
        }
    };

    const getCryptoRate = () => {
        const { currencyCode, cryptoCode, cryptoAmt } = cryptoCurrencyCorrectionInfo;

        if (currencyCode && cryptoCode && cryptoAmt) handleGetCryptoCurrencyRate();
        else
            message.warning(
                getTranslation(
                    'Please fill in crypto amount, currency, and cryptocurrency to get the rate',
                ),
            );
    };

    const submitForm = () => {
        !transactionType
            ? message.warning(getTranslation('Please select transaction type to correct'))
            : handleSubmitCryptoCurrencyCorrectionOperation();
    };

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (operationSuccess) {
            resetForm();
        }
    }, [operationSuccess, resetForm]);

    useEffect(() => {
        setFieldsValue({
            amount: _.round(fiatAmount) || 0,
            cryptoAddr: getStateProp(cryptoCurrencyCorrectionInfo, 'cryptoAddr'),
            cryptoAmt: getStateProp(cryptoCurrencyCorrectionInfo, 'cryptoAmt'),
            cryptoCode: getStateProp(cryptoCurrencyCorrectionInfo, 'cryptoCode'),
            cryptoRate: getStateProp(
                cryptoCurrencyCorrectionInfo,
                'cryptoRate',
                '0.000000000000000',
            ),
            currency: getStateProp(cryptoCurrencyCorrectionInfo, 'currencyCode'),
            memberLevel: getStateProp(cryptoCurrencyCorrectionInfo, 'memberLevel'),
            name: getStateProp(cryptoCurrencyCorrectionInfo, 'realName'),
            transactionNo: getStateProp(cryptoCurrencyCorrectionInfo, 'transactionNo'),
            transactionType: getStateProp(cryptoCurrencyCorrectionInfo, 'transactionType'),
            trustLevel: getStateProp(cryptoCurrencyCorrectionInfo, 'trustLevel'),
            userNameLike: getStateProp(cryptoCurrencyCorrectionInfo, 'userNameLike'),
        });
    }, [cryptoCurrencyCorrectionInfo, fiatAmount, setFieldsValue]);

    const [cryptoTransactionLoading] = useGetCryptoCurrencyTransactionAsyncEndpoint(
        getStateProp(cryptoCurrencyCorrectionInfo, 'transactionType'),
    );

    // ============================================
    // RENDER
    // ============================================

    const CryptoCurrencyCode = () => {
        return (
            <Form.Item name="cryptoCode">
                {transactionNo ? (
                    <Input
                        disabled
                        prefix={
                            (cryptoTransactionLoading || playerDetailsLoading) && (
                                <Loader size="small" style={{ padding: 0 }} />
                            )
                        }
                    />
                ) : (
                    <Select
                        allowClear
                        onChange={(value) => setCryptoCurrencyCorrectionInfo('cryptoCode', value)}
                    >
                        {_.map(CRYPTO_CURRENCY_CODE, (type) => (
                            <Select.Option value={type} key={type}>
                                {type.toLocaleUpperCase()}
                            </Select.Option>
                        ))}
                    </Select>
                )}
            </Form.Item>
        );
    };

    return (
        <Form
            form={form}
            name="cryptocurrency-correction"
            onFinish={submitForm}
            className="cryptocurrency-correction__form"
        >
            <Row>
                <Col span={12} className="cryptocurrency-correction__form-column">
                    <Row className="form__group">
                        <Col span={10}>
                            <label> {getTranslation('Transaction Type')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="transactionType">
                                <Select
                                    allowClear
                                    onChange={(value) =>
                                        setCryptoCurrencyCorrectionInfo('transactionType', value)
                                    }
                                >
                                    {_.map(CRYPTO_CORRECTION_TYPE, (type) => (
                                        <Select.Option value={type} key={type}>
                                            {type.toLocaleUpperCase()}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={12} className="cryptocurrency-correction__form-column">
                    <Row className="form__group">
                        <Col span={10}>
                            <label> {getTranslation('Transaction Code')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="transactionNo">
                                <Input
                                    prefix={<SearchOutlined />}
                                    placeholder={getTranslation('Search for Transaction Number')}
                                    onChange={(e) =>
                                        setCryptoCurrencyCorrectionInfo(
                                            'transactionNo',
                                            e.target.value,
                                        )
                                    }
                                    disabled={transactionNoSearchDisable}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col span={12} className="cryptocurrency-correction__form-column">
                    <Row className="form__group">
                        <Col span={10}>
                            <label>{getTranslation('Currency')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="currency">
                                <Input
                                    disabled
                                    prefix={
                                        (cryptoTransactionLoading || playerDetailsLoading) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label>{getTranslation('Crypto Amount')}</label>
                            <span className="form__required">*</span>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="cryptoAmt">
                                <Input
                                    onChange={(e) =>
                                        setCryptoCurrencyCorrectionInfo('cryptoAmt', e.target.value)
                                    }
                                    prefix={
                                        (cryptoTransactionLoading || playerDetailsLoading) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label>{getTranslation('Cryptocurrency')}</label>
                        </Col>
                        <Col span={12}>
                            <CryptoCurrencyCode />
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label>
                                {getTranslation('Exchange Rate')}
                                <span style={{ fontSize: '10px', paddingLeft: '1px' }}>
                                    ({getTranslation('per 1000 fiat amount')})
                                </span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="cryptoRate">
                                <Search
                                    prefix={
                                        (cryptoCurrencyRateLoading ||
                                            cryptoTransactionLoading ||
                                            playerDetailsLoading) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                    onSearch={getCryptoRate}
                                    size="small"
                                    style={{ padding: 0 }}
                                    enterButton
                                    onChange={(e) =>
                                        setCryptoCurrencyCorrectionInfo(
                                            'cryptoRate',
                                            e.target.value,
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label>{getTranslation('Fiat Amount')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="amount">
                                <Input
                                    disabled
                                    prefix={
                                        (cryptoTransactionLoading || playerDetailsLoading) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={12} className="cryptocurrency-correction__form-column">
                    <Row className="form__group">
                        <Col span={10}>
                            <label> {getTranslation('Username')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="userNameLike">
                                <Input
                                    prefix={
                                        cryptoTransactionLoading ? (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        ) : (
                                            <SearchOutlined />
                                        )
                                    }
                                    placeholder={getTranslation('Search for username')}
                                    onChange={(e) =>
                                        setCryptoCurrencyCorrectionInfo(
                                            'userNameLike',
                                            e.target.value,
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label>{getTranslation('Name')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="name">
                                <Input
                                    disabled
                                    prefix={
                                        (cryptoTransactionLoading || playerDetailsLoading) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label>{getTranslation('Member Level')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="memberLevel">
                                <Input
                                    disabled
                                    prefix={
                                        (cryptoTransactionLoading || playerDetailsLoading) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label> {getTranslation('Trust Level')} </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="trustLevel">
                                <Input
                                    disabled
                                    prefix={
                                        (cryptoTransactionLoading || playerDetailsLoading) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className="form__group">
                        <Col span={10}>
                            <label>{getTranslation('Receiving Wallet Address')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="cryptoAddr">
                                <Input
                                    onChange={(e) =>
                                        setCryptoCurrencyCorrectionInfo(
                                            'cryptoAddr',
                                            e.target.value,
                                        )
                                    }
                                    prefix={
                                        (cryptoTransactionLoading || playerDetailsLoading) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="form__footer-row" justify="end">
                <ButtonGreen
                    label="Submit"
                    className="form__submit-button"
                    loading={cryptoCurrencyConrrectionLoading}
                    htmlType="submit"
                />
                <ButtonTransparent
                    label="Cancel"
                    className="form__reset-button"
                    onClick={resetForm}
                />
            </Row>
        </Form>
    );
};

export default CryptocurrencyCorrectionForm;
