import { Col, Row } from 'antd';
import React from 'react';
import _ from 'lodash';

import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import {
    changeBonusRedemptionPageNumber,
    changeBonusRedemptionPageSize,
} from '../riskMonitorActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';

export const getColumns = () => {
    const columns = [
        {
            dataIndex: 'no',
            render: (value, item, index) => <span>{index + 1}</span>,
            title: 'No',
        },
        {
            dataIndex: 'ipAddress',
            title: getTranslation('Ip Address'),
        },
        {
            dataIndex: 'bonusCode',
            title: getTranslation('Bonus Code'),
        },
        {
            dataIndex: 'redemption',
            title: getTranslation('Duplicate Redemption'),
        },
        {
            dataIndex: 'userNames',
            render: (value, item) => (
                <Col>
                    {_.map(value, (e, idx) => (
                        <Row key={`${item.ipAddress + item.bonusCode + e + idx}`}>{e}</Row>
                    ))}
                </Col>
            ),
            title: getTranslation('Username'),
        },
        {
            dataIndex: 'riskLevel',
            render: (value) => (
                <span
                    className={
                        value && value.toLowerCase() === 'risk'
                            ? 'risk-monitor__risk-level--red'
                            : ''
                    }
                >
                    {value}
                </span>
            ),
            title: getTranslation('Risk Level'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changeBonusRedemptionPageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) =>
    changeBonusRedemptionPageSize(dispatch, pageSize);
