import React from 'react';

import { Col, Input, Modal, Row, Upload, message } from 'antd';
import _ from 'lodash';

import './RecipientsVerifyModal.less';
import {
    ButtonGreen,
    ButtonRed,
    ButtonTransparent,
} from '../../../../common/components/buttons/Buttons';
import { CONTACT_METHODS } from '../../constants';
import { accecptRecipientList, dropRecipientList, setRecipients } from '../messageCreateActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useDispatch } from '../../../../store/StateProvider';
import { useVerifyRecipientEndpoint } from '../../../../services/messageService';
import VerifiedResultTable from './table/VerifiedResultTable';

const { TextArea } = Input;

const RecipientsVerifyModal = ({ visible, onCancel, messageCreate, contactMethod }) => {
    const dispatch = useDispatch();
    const { recipientList, verifiedResults } = messageCreate;
    const recipientListArray = recipientList && recipientList.split(',');
    const validUsers = getStateProp(verifiedResults, 'valid', {});

    const verifiedUser = _.map(validUsers, (user) => {
        return user.userName;
    });

    // ============================================
    // METHODS
    // ============================================

    const handleManualInput = (e) => setRecipients(dispatch, e.target.value);

    const acceptRecipients = () => {
        message.success(getTranslation('Recipients Accepted'));
        accecptRecipientList(dispatch, _.toString(verifiedUser) || recipientList);
    };

    const dropRecipients = () => {
        message.warning(getTranslation('Recipients Dropped'));
        dropRecipientList(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleVerifyRecipient] = useVerifyRecipientEndpoint(recipientListArray);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            title={getTranslation('Verify Recipients')}
            centered
            width={700}
            closable={false}
            onCancel={onCancel}
            footer={null}
            className="message-recipients__verify-modal"
        >
            <Row>
                <Col span={3}>
                    <label className="bold-text">{getTranslation('Recipients')}:</label>
                </Col>
                <Col span={15}>
                    <TextArea rows={5} value={recipientList} onChange={handleManualInput} />
                </Col>
                <Col span={4}>
                    <Upload
                        accept=".txt, .csv"
                        showUploadList={false}
                        beforeUpload={(file) => {
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                let result = e.target.result.replace(/\r\n/g, ',');
                                setRecipients(dispatch, result);
                            };
                            reader.readAsText(file);

                            // Prevent upload
                            return false;
                        }}
                    >
                        <ButtonGreen
                            label={getTranslation('Upload')}
                            style={{ margin: 10, width: 65 }}
                        />
                    </Upload>
                    {contactMethod === CONTACT_METHODS.USERNAME && (
                        <ButtonGreen
                            label={getTranslation('Verify')}
                            style={{ margin: 10, width: 65 }}
                            onClick={handleVerifyRecipient}
                        />
                    )}
                </Col>
            </Row>

            <Row>
                <Col span={3} />
                <Col span={15} className="recipients-verify-remark">
                    *
                    {contactMethod === CONTACT_METHODS.USERNAME ? (
                        <span>
                            {getTranslation(
                                'If want to send by username please verify to ensure usernames you entered exist in the system and accept the recipients',
                            )}
                        </span>
                    ) : (
                        <span>
                            {getTranslation('Please accept your recipients after upload the file')}
                        </span>
                    )}
                </Col>
            </Row>

            <Row className="results-table__container">
                <VerifiedResultTable loading={loading} />
            </Row>
            <Row justify="end">
                <ButtonGreen className="action-button" onClick={acceptRecipients}>
                    {getTranslation('Accept Recipients')}
                </ButtonGreen>
                <ButtonRed className="action-button" onClick={dropRecipients}>
                    {getTranslation('Drop Recipients')}
                </ButtonRed>
                <ButtonTransparent
                    className="action-button"
                    label={getTranslation('Cancel')}
                    onClick={onCancel}
                />
            </Row>
        </Modal>
    );
};

export default RecipientsVerifyModal;
