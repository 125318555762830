import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { CONTACT_METHODS, MESSAGE_TYPES } from '../constants';
import { getTranslation } from '../../../common/helpers/locale';

const ContactMethodFilter = ({ value, onFilterChange, messageType }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('contactMethod', value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            placeholder={getTranslation('Contact Method')}
            className="filter"
            allowClear
        >
            {messageType === MESSAGE_TYPES.INBOX ? (
                <Select.Option key={CONTACT_METHODS.USERNAME} value={CONTACT_METHODS.USERNAME}>
                    {CONTACT_METHODS.USERNAME.toLocaleUpperCase()}
                </Select.Option>
            ) : (
                _.map(CONTACT_METHODS, (type) => (
                    <Select.Option key={type} value={type}>
                        {type.toLocaleUpperCase()}
                    </Select.Option>
                ))
            )}
        </Select>
    );
};

export default ContactMethodFilter;
