import React, { useCallback, useEffect, useState } from 'react';

import { AutoComplete, Col, Form, Input, Radio, Row, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';

import './RequestBonusForm.less';
import {
    ButtonBlue,
    ButtonGreen,
    ButtonTransparent,
} from '../../../common/components/buttons/Buttons';
import { REQUEST_SEARCH_PLAYER_INFO_ACTIONS } from './constants';
import { TURNOVER_ADJUST_ON, YES_NO_DISPLAY } from '../../../common/constants/misc';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    requestBonusAdjustmentInfoSet,
    requestBonusFormReset,
    updateBonusDetails,
} from './requestBonusActions';
import {
    useGetBonusCodeListOperationAsyncEndpoint,
    useGetCalculatedTurnoverAmountOperationAsyncEndpoint,
    useGetPlayerInfoAsyncEndpoint,
    useGetTransactionInfoAsyncEndpoint,
} from '../../../services/requestBonusService';
import { useStore } from '../../../store/StateProvider';
import Loader from '../../../common/components/Loader';

const { TextArea } = Input;

const RequestBonusForm = ({ loading, onSubmit, setUploadModalVisible }) => {
    const [form] = Form.useForm();
    const { setFieldsValue, resetFields } = form;

    const [state, dispatch] = useStore();
    const [userNameSearchEnable, setUserNameSearchEnable] = useState(false);
    const [depositSearchEnable, setDepositSearchEnable] = useState(false);
    const [turnoverDisable, setTurnoverDisable] = useState(false);
    const [turnoverAmountDisable, setTurnoverAmountDisable] = useState(true);

    const requestBonus = getStateProp(state, 'bonus.requestBonus', {});
    const langCode = getStateProp(state, 'country.language');

    const adjustmentInfo = getStateProp(requestBonus, 'adjustmentInfo', {});
    const bonusCodeList = getStateProp(requestBonus, 'bonusCodeList', []);
    const bonusCodeDetailList = getStateProp(requestBonus, 'bonusCodeDetailList', {});

    const actionSearchBy = getStateProp(adjustmentInfo, 'action');
    const bonusCode = getStateProp(adjustmentInfo, 'bonusCode');
    // ============================================
    // METHODS
    // ============================================

    const getBonusName = useCallback(
        (list) => {
            let bonusName;
            for (let i = 0, l = list.length; i < l; i++) {
                const item = list[i];
                bonusName = item.bonusName;
                if (item.languageCode === langCode) {
                    break;
                }
            }
            return bonusName;
        },
        [langCode],
    );

    const getTransactionCodeTitle = (actionSearchBy) => {
        if (
            actionSearchBy === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.BALANCE_ADDITION ||
            actionSearchBy === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.CRYPTOCURRENCY_CORRECTION ||
            actionSearchBy === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.DEPOSIT_CORRECTION
        ) {
            return 'Transaction Code';
        }
        return 'Deposit Transaction Code';
    };

    const setAdjustmentInfo = (inputName, value) => {
        requestBonusAdjustmentInfoSet(dispatch, { inputName, value });

        if (inputName === 'action') {
            handleGetBonusCodeList(
                value === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.USERNAME ? true : false,
            );

            updateBonusDetails(dispatch, {
                applicantRemarks: '',
                bonusCode: '',
                currencyCode: '',
                depositedAmount: '',
                memberLevel: '',
                transactionNo: '',
                turnoverAmount: '',
                userNameLike: '',
            });

            if (value === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.USERNAME) {
                setUserNameSearchEnable(true);
                setDepositSearchEnable(false);
            } else {
                setUserNameSearchEnable(false);
                setDepositSearchEnable(true);
            }
        }

        if (inputName === 'adjustOn') {
            setTurnOverInputDisable(value);
            requestBonusAdjustmentInfoSet(dispatch, { inputName: 'turnoverRequirement', value: 1 });
            requestBonusAdjustmentInfoSet(dispatch, { inputName: 'turnoverAmount', value: null });
        }
    };

    const validateBonusCode = (bonusCode, message) => {
        return () => {
            if (_.includes(bonusCodeList, bonusCode)) {
                return Promise.resolve();
            }
            return Promise.reject(message);
        };
    };

    const resetForm = () => {
        requestBonusFormReset(dispatch);
        setUserNameSearchEnable(false);
        setDepositSearchEnable(false);
        resetFields();
    };

    const setTurnOverInputDisable = (value) => {
        if (value === TURNOVER_ADJUST_ON.TURNOVER_AMOUNT) {
            setTurnoverDisable(true);
            setTurnoverAmountDisable(false);
        } else {
            setTurnoverDisable(false);
            setTurnoverAmountDisable(true);
        }
    };

    // ============================================
    // HOOKS
    // ============================================

    const [gettingPlayerInfo] = useGetPlayerInfoAsyncEndpoint();

    const [gettingTransaction] = useGetTransactionInfoAsyncEndpoint();

    const [
        getBonusCodesLoading,
        handleGetBonusCodeList,
    ] = useGetBonusCodeListOperationAsyncEndpoint();

    const [turnOverLoading] = useGetCalculatedTurnoverAmountOperationAsyncEndpoint();

    useEffect(() => {
        setFieldsValue({
            action: getStateProp(adjustmentInfo, 'action'),
            adjustOn: getStateProp(
                adjustmentInfo,
                'adjustOn',
                TURNOVER_ADJUST_ON.TURNOVER_REQUIREMENT,
            ),
            applicantRemarks: getStateProp(adjustmentInfo, 'applicantRemarks'),
            bonusAmount: getStateProp(adjustmentInfo, 'bonusAmount'),
            bonusCode: bonusCode,
            bonusName: getStateProp(adjustmentInfo, 'bonusName'),
            currencyCode: getStateProp(adjustmentInfo, 'currencyCode'),
            depositedAmount: getStateProp(adjustmentInfo, 'depositedAmount'),
            duration: getStateProp(adjustmentInfo, 'duration'),
            expiredDateReadable: getStateProp(adjustmentInfo, 'expiredDateReadable'),
            isPromoRule: getStateProp(adjustmentInfo, 'isPromoRule'),
            memberLevel: getStateProp(adjustmentInfo, 'memberLevel'),
            transactionNo: getStateProp(adjustmentInfo, 'transactionNo'),
            turnoverAmount: getStateProp(adjustmentInfo, 'turnoverAmount'),
            turnoverRequirement: getStateProp(adjustmentInfo, 'turnoverRequirement'),
            type: getStateProp(adjustmentInfo, 'type'),
            userNameLike: getStateProp(adjustmentInfo, 'userNameLike'),
        });
    }, [adjustmentInfo, bonusCode, setFieldsValue]);

    useEffect(() => {
        return () => requestBonusFormReset(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (bonusCode === '') {
            updateBonusDetails(dispatch, {
                bonusAmount: '',
                bonusName: '',
                duration: '',
                isPromoRule: null,
                turnoverRequirement: '',
            });
        } else {
            _.map(bonusCodeDetailList, (list) => {
                if (list.code === bonusCode) {
                    updateBonusDetails(dispatch, {
                        bonusAmount: '',
                        bonusName: getBonusName(list.details),
                        duration: list.duration,
                        isPromoRule: list.isPromoRule ? true : false,
                        turnoverRequirement: list.turnoverCondition,
                    });
                }
            });
        }
    }, [bonusCode, bonusCodeDetailList, dispatch, getBonusName]);

    useEffect(() => {
        let currentBonusAmount = getStateProp(adjustmentInfo, 'bonusAmount');
        let depositedAmount = getStateProp(adjustmentInfo, 'depositedAmount');

        if (bonusCode && !currentBonusAmount) {
            let bonusInfo = _.find(bonusCodeDetailList, ['code', bonusCode]);
            let userCurrencyInfo = _.find(bonusInfo.currencies, [
                'currencyCode',
                getStateProp(adjustmentInfo, 'currencyCode'),
            ]);

            if (!_.isEmpty(bonusInfo) && !_.isEmpty(userCurrencyInfo)) {
                console.log(bonusInfo.isFixedBonus, depositedAmount);
                if (bonusInfo.isFixedBonus) {
                    updateBonusDetails(dispatch, {
                        bonusAmount: userCurrencyInfo.fixedBonusAmount.toFixed(2),
                    });
                } else if (!bonusInfo.isFixedBonus && depositedAmount) {
                    let bonusAmountByPercentage =
                        (depositedAmount * userCurrencyInfo.bonusPercentage) / 100;

                    if (bonusAmountByPercentage > userCurrencyInfo.maxBonusByPercentage) {
                        bonusAmountByPercentage = userCurrencyInfo.maxBonusByPercentage;
                    }

                    updateBonusDetails(dispatch, {
                        bonusAmount: bonusAmountByPercentage.toFixed(2),
                    });
                }
            }
        }
    }, [adjustmentInfo, bonusCode, bonusCodeDetailList, dispatch]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form form={form} name="request-bonus" className="request-bonus-form" onFinish={onSubmit}>
            <Row className="request-bonus__search-selection">
                <Col span={24}>
                    <Row className="form__group">
                        <Col span={5}>
                            <label>
                                {getTranslation('Search By')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="action"
                                rules={[
                                    {
                                        message: getTranslation('Please select action!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    onChange={(value) => setAdjustmentInfo('action', value)}
                                >
                                    {_.map(REQUEST_SEARCH_PLAYER_INFO_ACTIONS, (action) => (
                                        <Select.Option value={action} key={action}>
                                            {getTranslation(action).toLocaleUpperCase()}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col span={12} className="bonus-request__form-column">
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Username')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="userNameLike"
                                rules={[
                                    {
                                        message: getTranslation('Please input username!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<SearchOutlined />}
                                    placeholder={getTranslation('Search for username')}
                                    onChange={(e) =>
                                        setAdjustmentInfo('userNameLike', e.target.value)
                                    }
                                    disabled={!userNameSearchEnable}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">{getTranslation('Member Level')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="memberLevel">
                                <Input
                                    disabled
                                    suffix={
                                        (gettingPlayerInfo || gettingTransaction) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation(getTransactionCodeTitle(actionSearchBy))}
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="transactionNo">
                                <Input
                                    prefix={<SearchOutlined />}
                                    placeholder={getTranslation('Search for Transaction Number')}
                                    onChange={(e) =>
                                        setAdjustmentInfo('transactionNo', e.target.value)
                                    }
                                    disabled={!depositSearchEnable}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Deposited Amount')}
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="depositedAmount">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Duration (days)')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="duration"
                                rules={[
                                    {
                                        message: getTranslation('Please input duration (days)!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) => setAdjustmentInfo('duration', e.target.value)}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Bonus Code')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="bonusCode"
                                rules={[
                                    {
                                        message: getTranslation('Please input bonus code!'),
                                        required: true,
                                    },
                                    ({ getFieldValue }) => ({
                                        validator: validateBonusCode(
                                            getFieldValue('bonusCode'),
                                            getTranslation('Bonus Code Invalid'),
                                        ),
                                    }),
                                ]}
                            >
                                <AutoComplete
                                    onChange={(value) => setAdjustmentInfo('bonusCode', value)}
                                    disabled={getBonusCodesLoading || _.isEmpty(bonusCodeList)}
                                    loading={getBonusCodesLoading}
                                >
                                    {_.map(bonusCodeList, (value) => (
                                        <AutoComplete.Option key={value}>
                                            {value}
                                        </AutoComplete.Option>
                                    ))}
                                </AutoComplete>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">{getTranslation('Bonus Name')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="bonusName">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">{getTranslation('Promo Rule')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="isPromoRule">
                                <Radio.Group
                                    style={{ display: 'flex', flexFlow: 'row wrap' }}
                                    disabled
                                >
                                    {_.map(YES_NO_DISPLAY, (e) => (
                                        <Radio key={e.value} value={e.value}>
                                            {getTranslation(e.display)}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={12} className="bonus-request__form-column">
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Bonus Amount')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="bonusAmount"
                                rules={[
                                    {
                                        message: getTranslation('Please input bonus amount!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) =>
                                        setAdjustmentInfo('bonusAmount', e.target.value)
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Adjust On')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="adjustOn">
                                <Select onChange={(value) => setAdjustmentInfo('adjustOn', value)}>
                                    {_.map(TURNOVER_ADJUST_ON, (method) => (
                                        <Select.Option value={method} key={method}>
                                            {method.toLocaleUpperCase()}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Turnover Requirement')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="turnoverRequirement">
                                <Input
                                    onChange={(e) =>
                                        setAdjustmentInfo('turnoverRequirement', e.target.value)
                                    }
                                    disabled={turnoverDisable}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Turnover Amount')}
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="turnoverAmount">
                                <Input
                                    disabled={turnoverAmountDisable}
                                    onChange={(e) =>
                                        setAdjustmentInfo('turnoverAmount', e.target.value)
                                    }
                                    suffix={
                                        turnOverLoading && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">{getTranslation('Currency')}</label>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="currencyCode">
                                <Input
                                    disabled
                                    suffix={
                                        (gettingPlayerInfo || gettingTransaction) && (
                                            <Loader size="small" style={{ padding: 0 }} />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="form__group">
                        <Col span={10}>
                            <label className="form__label">
                                {getTranslation('Remark')}
                                <span className="form__required">*</span>
                            </label>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="applicantRemarks"
                                rules={[
                                    {
                                        max: 128,
                                        message: getTranslation('Remark must less than 128 words!'),
                                    },
                                ]}
                            >
                                <TextArea
                                    onChange={(e) =>
                                        setAdjustmentInfo('applicantRemarks', e.target.value)
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="form__footer-row" justify="end">
                <ButtonBlue
                    className="form__submit-button"
                    label={getTranslation('Batch Uplaod')}
                    onClick={setUploadModalVisible}
                />
                <ButtonGreen
                    label="Submit"
                    className="form__submit-button"
                    loading={loading}
                    htmlType="submit"
                />
                <ButtonTransparent
                    label="Cancel"
                    className="form__reset-button"
                    onClick={resetForm}
                />
            </Row>
        </Form>
    );
};

export default RequestBonusForm;
