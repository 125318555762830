export const ACTIONS = {
    DANGER_LEVEL_FILTER_LIST_GET: 'DANGER_LEVEL_FILTER_LIST_GET',
    DANGER_LEVEL_FILTER_LIST_GET_FAILURE: 'DANGER_LEVEL_FILTER_LIST_GET_FAILURE',
    DANGER_LEVEL_FILTER_LIST_GET_SUCCESS: 'DANGER_LEVEL_FILTER_LIST_GET_SUCCESS',
    DANGER_LEVEL_GET: 'DANGER_LEVEL_GET',
    DANGER_LEVEL_GET_FAILURE: 'DANGER_LEVEL_GET_FAILURE',
    DANGER_LEVEL_GET_SUCCESS: 'DANGER_LEVEL_GET_SUCCESS',
    DANGER_LEVEL_SETTING_MODAL_SET: 'DANGER_LEVEL_SETTING_MODAL_SET',
    DANGER_LEVEL_SETTING_UPDATE: 'DANGER_LEVEL_SETTING_UPDATE',
    DANGER_LEVEL_SETTING_UPDATE_FAILURE: 'DANGER_LEVEL_SETTING_UPDATE_FAILURE',
    DANGER_LEVEL_SETTING_UPDATE_SUCCESS: 'DANGER_LEVEL_SETTING_UPDATE_SUCCESS',
    DANGER_LEVEL_TAG_ADD: 'DANGER_LEVEL_TAG_ADD',
    DANGER_LEVEL_TAG_DELETE: 'DANGER_LEVEL_TAG_DELETE',
    DANGER_LEVEL_TAG_DELETE_FAILURE: 'DANGER_LEVEL_TAG_DELETE_FAILURE',
    DANGER_LEVEL_TAG_DELETE_SUCCESS: 'DANGER_LEVEL_TAG_DELETE_SUCCESS',
    DANGER_LEVEL_TAG_EDIT: 'DANGER_LEVEL_TAG_EDIT',
    DANGER_LEVEL_TAG_GET: 'DANGER_LEVEL_TAG_GET',
    DANGER_LEVEL_TAG_GET_FAILURE: 'DANGER_LEVEL_TAG_GET_FAILURE',
    DANGER_LEVEL_TAG_GET_SUCCESS: 'DANGER_LEVEL_TAG_GET_SUCCESS',
    DANGER_LEVEL_TAG_MODAL_SET: 'DANGER_LEVEL_TAG_MODAL_SET',
    DANGER_LEVEL_TAG_UPDATE: 'DANGER_LEVEL_TAG_UPDATE',
    DANGER_LEVEL_TAG_UPDATE_FAILURE: 'DANGER_LEVEL_TAG_UPDATE_FAILURE',
    DANGER_LEVEL_TAG_UPDATE_SUCCESS: 'DANGER_LEVEL_TAG_UPDATE_SUCCESS',
    FILTERS_DROP_ALL: 'FILTERS_DROP_ALL',
    FILTER_SET: 'FILTER_SET',
    UPDATE_SELECTED_DANGER_LEVEL_SETTING: 'UPDATE_SELECTED_DANGER_LEVEL_SETTING',
};

export const filterSet = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });
};

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const getDangerLevels = (dispatch) =>
    dispatch({
        type: ACTIONS.DANGER_LEVEL_GET,
    });

export const getDangerLevelsFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DANGER_LEVEL_GET_FAILURE,
    });

export const getDangerLevelsSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DANGER_LEVEL_GET_SUCCESS,
    });

export const getDangerLevelFilterList = (dispatch) =>
    dispatch({
        type: ACTIONS.DANGER_LEVEL_FILTER_LIST_GET,
    });

export const getDangerLevelFilterListFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DANGER_LEVEL_FILTER_LIST_GET_FAILURE,
    });

export const getDangerLevelFilterListSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DANGER_LEVEL_FILTER_LIST_GET_SUCCESS,
    });

export const getDangerLevelTag = (dispatch) =>
    dispatch({
        type: ACTIONS.DANGER_LEVEL_TAG_GET,
    });

export const getDangerLevelTagFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DANGER_LEVEL_TAG_GET_FAILURE,
    });

export const getDangerLevelTagSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DANGER_LEVEL_TAG_GET_SUCCESS,
    });

export const addDangerLevelTag = (dispatch) =>
    dispatch({
        type: ACTIONS.DANGER_LEVEL_TAG_ADD,
    });

export const editDangerLevelTag = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DANGER_LEVEL_TAG_EDIT,
    });

export const updateDangerLevelTag = (dispatch) =>
    dispatch({
        type: ACTIONS.DANGER_LEVEL_TAG_UPDATE,
    });

export const updateDangerLevelTagFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DANGER_LEVEL_TAG_UPDATE_FAILURE,
    });

export const updateDangerLevelTagSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.DANGER_LEVEL_TAG_UPDATE_SUCCESS,
    });

export const setDangerLevelTagModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DANGER_LEVEL_TAG_MODAL_SET,
    });

export const deleteDangerLevelTag = (dispatch) =>
    dispatch({
        type: ACTIONS.DANGER_LEVEL_TAG_DELETE,
    });

export const deleteDangerLevelTagFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DANGER_LEVEL_TAG_DELETE_FAILURE,
    });

export const deleteDangerLevelTagSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DANGER_LEVEL_TAG_DELETE_SUCCESS,
    });

export const setDangerLevelSettingModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DANGER_LEVEL_SETTING_MODAL_SET,
    });

export const updateSelectedDangerLevelSetting = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.UPDATE_SELECTED_DANGER_LEVEL_SETTING,
    });

export const updateDangerLevelSetting = (dispatch) =>
    dispatch({
        type: ACTIONS.DANGER_LEVEL_SETTING_UPDATE,
    });

export const updateDangerLevelSettingFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DANGER_LEVEL_SETTING_UPDATE_FAILURE,
    });

export const updateDangerLevelSettingSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.DANGER_LEVEL_SETTING_UPDATE_SUCCESS,
    });
