import React from 'react';

import { Table } from 'antd';

import { getColumns } from './DangerLevelTagMeta';

const DangerLevelTagTable = ({
    loading,
    tableData,
    handleEditDangerLevelTag,
    handleDeleteDangerLevelTag,
}) => {
    // ============================================
    // METHODS
    // ============================================

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns(handleEditDangerLevelTag, handleDeleteDangerLevelTag)}
            rowKey={(record, idx) => `danger-level-tag-${idx}`}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            dataSource={tableData}
            loading={loading}
            pagination={false}
        />
    );
};

export default DangerLevelTagTable;
