import React from 'react';

import { Select } from 'antd';

import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useStore } from '../../../../store/StateProvider';

const SportTypesFilter = ({ value, onFilterChange, loading }) => {
    const [state] = useStore();
    const sportTypes = getStateProp(state, 'bet.betList.filterOptions.sportTypes') || [];

    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('sportTypes', value);

    // ============================================
    // RENDER
    // ============================================

    const options = sportTypes.map((types) => (
        <Select.Option key={types.value}>{types.text.toLocaleUpperCase()}</Select.Option>
    ));

    return (
        <Select
            className="filter"
            placeholder={getTranslation('Sport Type')}
            mode="multiple"
            value={value}
            allowClear={true}
            onChange={handleFilterChange}
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {loading ? <Select.Option></Select.Option> : options}
        </Select>
    );
};

export default SportTypesFilter;
