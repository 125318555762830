import React, { useState } from 'react';

import { Card, Divider, Form } from 'antd';

import { API } from '../../common/constants/api';
import { PAYMENT_ORDER_OPERATIONS } from '../../common/constants/misc';
import { TRANSACTION_MONITORING_TABLE_COLUMNS } from './constants';
import {
    closeVerifyModal,
    dropFilters,
    onRemarkModalClose,
    pauseRefreshTableTime,
    performTransactionMonitoringOperation,
    selectColumns,
    setRefreshTableTime,
} from './transactionMonitoringActions';
import {
    getColumns,
    transactionMonitoringRecordsExport,
} from './table/TransactionMonitoringTableMeta';
import { getStateProp } from '../../common/helpers/misc';
import { getTranslation } from '../../common/helpers/locale';
import { useExportAsyncEndpoint } from '../../services/hooks/useExport';
import {
    useGetTransactionMonitoringAsyncEndpoint,
    useTransactionMonitoringOperationAsyncEndpoint,
} from '../../services/transactionMonitoringService';
import { useStore } from '../../store/StateProvider';
import ActionButtons from './ActionButtons';
import ColumnSelectionModal from '../../common/components/column-selection/ColumnSelectionModal';
import RemarkModal from '../../common/components/RemarkModal';
import TableRefreshSelector from '../../common/components/TableRefreshSelector';
import TotalBalance from '../../common/components/TotalBalance';
import TransactionMonitoringFilters from './TransactionMonitoringFilters';
import TransactionMonitoringResultsTable from './table/TransactionMonitoringResultsTable';
import VerifyModal from './verification/VerifyModal';
import useInternetStatus from '../../services/hooks/useInternetStatus';

const { TRANSACTION_MONITORING } = API;

const TransactionMonitoring = () => {
    const [state, dispatch] = useStore();
    const verifyModalVisible = getStateProp(state, 'transactionMonitoring.verifyModalVisible');
    const transactionMonitoringRecord = getStateProp(
        state,
        'transactionMonitoring.operation.transactionMonitoringRecord',
        {},
    );
    const remarkModalVisible = getStateProp(state, 'transactionMonitoring.remarkModalVisible');
    const { applicantRemark, operatorRemark, auditorRemark } = getStateProp(
        state,
        'transactionMonitoring.remarks',
        {},
    );
    const filters = getStateProp(state, 'transactionMonitoring.filters');
    const { totalRecordCount } = getStateProp(state, 'transactionMonitoring.paging', {});
    const selectedColumns = getStateProp(
        state,
        'transactionMonitoring.selectedColumns',
        TRANSACTION_MONITORING_TABLE_COLUMNS,
    );

    const [columnSelectorModalVisible, setColumnSelectorModalVisible] = useState(false);

    const pageBalance = getStateProp(state, 'transactionMonitoring.pageDataTotal', []);
    const totalBalance = getStateProp(state, 'transactionMonitoring.dataTotal', []);
    const grandTotal = getStateProp(state, 'transactionMonitoring.grandTotal', 0);
    const pageTotal = getStateProp(state, 'transactionMonitoring.pageTotal', 0);

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetTransactionMonitoring();
    };

    const closeOperationModal = () => {
        performTransactionMonitoringOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.RELEASE,
        });
        closeVerifyModal(dispatch);
    };

    const setRefreshTime = (timeOut) => {
        setRefreshTableTime(dispatch, {
            timeOut,
        });
    };

    const onTimePause = () => pauseRefreshTableTime(dispatch);

    const onTimeStart = () => {
        setRefreshTableTime(dispatch, {
            timeOut: 15,
        });
    };

    const closeColumnSelectorModal = () => setColumnSelectorModalVisible(false);

    const openColumnSelectorModal = () => setColumnSelectorModalVisible(true);

    const closeRemarkModal = () => onRemarkModalClose(dispatch);

    // ============================================
    // HOOKS
    // ============================================

    const [isOnline] = useInternetStatus();

    const [loading, handleGetTransactionMonitoring] = useGetTransactionMonitoringAsyncEndpoint(
        isOnline,
    );

    const [operationLoading] = useTransactionMonitoringOperationAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        additionalCol: transactionMonitoringRecordsExport,
        dataMeta: getColumns(selectedColumns),
        filters,
        innerListKey: 'data',
        method: 'GET',
        subject: getTranslation('Transaction Monitoring'),
        totalRecordCount,
        url: `${TRANSACTION_MONITORING}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card
            title={getTranslation('Transaction Monitoring')}
            extra={
                <TableRefreshSelector
                    onTimeChange={setRefreshTime}
                    onTimePause={onTimePause}
                    onTimeStart={onTimeStart}
                    timeOut={getStateProp(state, 'transactionMonitoring.operation.refreshTime')}
                />
            }
        >
            <Form onFinish={handleGetTransactionMonitoring}>
                <TransactionMonitoringFilters />
                <ActionButtons
                    onDropFilters={fetchAll}
                    onExport={handleExportAction}
                    onColumnSelect={openColumnSelectorModal}
                />
            </Form>

            <TransactionMonitoringResultsTable loading={loading} />

            <Divider />

            <TotalBalance
                pageBalance={pageBalance}
                totalBalance={totalBalance}
                grandTotal={grandTotal}
                pageTotal={pageTotal}
            />

            <VerifyModal
                visible={verifyModalVisible}
                onCancel={closeOperationModal}
                transaction={transactionMonitoringRecord}
                loading={operationLoading}
            />
            <ColumnSelectionModal
                visible={columnSelectorModalVisible}
                onCancel={closeColumnSelectorModal}
                tableColumnsList={TRANSACTION_MONITORING_TABLE_COLUMNS}
                selectAction={selectColumns}
                selectedColumnsState="transactionMonitoring.selectedColumns"
            />
            <RemarkModal
                visible={remarkModalVisible}
                onCancel={closeRemarkModal}
                applicantRemark={applicantRemark}
                operatorRemark={operatorRemark}
                auditorRemark={auditorRemark}
            />
        </Card>
    );
};

export default TransactionMonitoring;
