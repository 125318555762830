import betListReducer from './bet-list/betListReducer';
import turnoverCheckReducer from './turnover-check/turnoverCheckReducer';

export default function betReducer(bet = {}, action) {
    const { betList, turnoverCheck } = bet;

    return {
        betList: betListReducer(betList, action),
        turnoverCheck: turnoverCheckReducer(turnoverCheck, action),
    };
}
