export const EWALLET_WITHDRAWAL_TABLE_COLUMNS = [
    {
        key: 'requestedAt',
        title: 'Request Time',
    },
    {
        key: 'verifiedAt',
        title: 'Audit Time',
    },
    {
        key: 'transactionNo',
        required: true,
        title: 'Task Transaction Number',
    },
    {
        key: 'playerUserName',
        required: true,
        title: 'Username',
    },
    {
        key: 'memberLevel',
        title: 'Member Level',
    },
    {
        key: 'trustLevel',
        title: 'Trust Level',
    },
    {
        key: 'affiliateUserName',
        title: 'Affiliate',
    },
    {
        key: 'referralUserName',
        title: 'Referral',
    },
    {
        key: 'amount',
        required: true,
        title: 'Withdrawal Amount',
    },
    {
        key: 'currencyCode',
        required: true,
        title: 'Currency',
    },
    {
        key: 'previousDepositAmount',
        title: 'Previous Deposit Amount',
    },
    { key: 'eWalletHolderName', title: 'Member E-Wallet Name' },
    { key: 'eWalletAccountNo', title: 'Member Account Number' },
    {
        key: 'eWalletName',
        title: "Member's Ewalllet",
    },
    {
        key: 'requesterUserName',
        required: true,
        title: 'Applicant',
    },
    {
        key: 'orderStatus',
        required: true,
        title: 'Status',
    },
    {
        key: 'processedAt',
        title: 'Process Time',
    },
    {
        key: 'withdrawalFee',
        title: 'Withdrawal Fee',
    },
    {
        key: 'transactionStatus',
        title: 'Transaction Status',
    },
    {
        key: 'operation',
        required: true,
        title: 'Operation',
    },
];
