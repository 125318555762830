import { Route, Switch } from 'react-router';
import React, { useState } from 'react';

import { Card, Row } from 'antd';

import './PlayerDetails.less';
import { BankCards, EWallet, HistoryMessages, MemberInformation, UserSessions } from './index';
import { ROUTES } from '../../../common/constants/routing';
import { getPlayerMenuIdByRoute } from '../helpers';
import { getTranslation } from '../../../common/helpers/locale';
import PlayerDetailsMenu from './PlayerDetailsMenu';

const PlayerDetails = (props) => {
    const { playerId } = props.match.params;

    const setInitialSelectedMenuItem = () => {
        const subroute = props.location.pathname.split(`${playerId}/`)[1];

        return subroute ? getPlayerMenuIdByRoute(subroute) : 'MEMBER_INFO';
    };

    const [selectedMenuItem, setSelectedMenuItem] = useState(setInitialSelectedMenuItem());

    // ============================================
    // METHODS
    // ============================================

    const renderSubRoute = (menuItemId) => {
        setSelectedMenuItem(menuItemId);

        const route = props.location.pathname.split(`${playerId}`)[0];

        props.history.push(
            `${route}${playerId}${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS[menuItemId]}`,
        );
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Player Full Details')} className="player-details__card">
            <PlayerDetailsMenu
                selectedItem={selectedMenuItem}
                onSelectionChanged={renderSubRoute}
            />
            <Row style={{ paddingLeft: 12, paddingRight: 12 }}>
                <Switch>
                    <Route
                        exact
                        path={ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.ROOT_MASKED}
                        component={MemberInformation}
                    />
                    <Route
                        path={`${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.ROOT_MASKED}${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.MEMBER_INFO}`}
                        component={MemberInformation}
                    />
                    <Route
                        path={`${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.ROOT_MASKED}${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.MESSAGES}`}
                        component={HistoryMessages}
                    />
                    <Route
                        path={`${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.ROOT_MASKED}${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.USER_SESSIONS}`}
                        component={UserSessions}
                    />
                    <Route
                        path={`${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.ROOT_MASKED}${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.BANK_CARDS}`}
                        component={BankCards}
                    />
                    <Route
                        path={`${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.ROOT_MASKED}${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.E_WALLET}`}
                        component={EWallet}
                    />

                    <Route
                        exact
                        path={ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.ROOT}
                        component={MemberInformation}
                    />
                    <Route
                        path={`${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.ROOT}${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.MEMBER_INFO}`}
                        component={MemberInformation}
                    />
                    <Route
                        path={`${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.ROOT}${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.MESSAGES}`}
                        component={HistoryMessages}
                    />
                    <Route
                        path={`${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.ROOT}${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.USER_SESSIONS}`}
                        component={UserSessions}
                    />
                    <Route
                        path={`${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.ROOT}${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.BANK_CARDS}`}
                        component={BankCards}
                    />
                    <Route
                        path={`${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.ROOT}${ROUTES.DASHBOARD.PLAYERS.PLAYER_DETAILS.E_WALLET}`}
                        component={EWallet}
                    />
                </Switch>
            </Row>
        </Card>
    );
};

export default PlayerDetails;
