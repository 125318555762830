import React from 'react';

import { Row, Select } from 'antd';
import _ from 'lodash';

import { InputGroupFilter, TextFilter } from '../filters';
import {
    REFEREE_DEPOSIT_CONDITION,
    REFERRAL_BIND_BANK_CARD,
    TOTAL_REFEREE_CONDITION,
} from '../constants';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { referralInquiryFilterSet } from './referralInquiryActions';
import { useStore } from '../../../store/StateProvider';

const ReferralInquiryFilters = () => {
    const [state, dispatch] = useStore();

    const referralInquiry = getStateProp(state, 'referral.referralInquiry');
    const filters = getStateProp(referralInquiry, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => referralInquiryFilterSet(dispatch, { filter, value });

    const setFilterGroup = (filter, value) => {
        const valueObject = getStateProp(filters, filter, {});
        referralInquiryFilterSet(dispatch, { filter, value: _.merge(valueObject, value) });
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <TextFilter
                value={getStateProp(filters, 'inviteCode', '')}
                onFilterChange={setFilter}
                name="inviteCode"
                placeholder={getTranslation('Referral Code')}
            />
            <TextFilter
                value={getStateProp(filters, 'userName', '')}
                onFilterChange={setFilter}
                name="userName"
                placeholder={getTranslation('Username')}
            />
            <InputGroupFilter
                value={getStateProp(filters, 'totalReferee')}
                placeholder={getTranslation('Total Referee')}
                onFilterChange={setFilterGroup}
                name="totalReferee"
                optionList={TOTAL_REFEREE_CONDITION}
            />
            <InputGroupFilter
                value={getStateProp(filters, 'totalDepositedReferee')}
                placeholder={getTranslation('Qualified Referee')}
                onFilterChange={setFilterGroup}
                name="totalDepositedReferee"
                optionList={REFEREE_DEPOSIT_CONDITION}
            />
            <TextFilter
                value={getStateProp(filters, 'referrer', '')}
                onFilterChange={setFilter}
                name="referrer"
                placeholder={getTranslation('Referrer')}
            />
            <Select
                value={getStateProp(filters, 'bindBankCard')}
                onChange={(value) => setFilter('bindBankCard', value)}
                allowClear
                placeholder={getTranslation('Bind Bank Card')}
                className="filter"
            >
                {_.map(REFERRAL_BIND_BANK_CARD, (types) => (
                    <Select.Option
                        value={types === 'YES' ? true : false}
                        key={types.toLocaleUpperCase()}
                    >
                        {types.toLocaleUpperCase()}
                    </Select.Option>
                ))}
            </Select>
        </Row>
    );
};

export default ReferralInquiryFilters;
