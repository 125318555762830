import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './TotalRegistrationModalMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const TotalRegistrationModalTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const { riskMonitor } = getStateProp(state, 'bonus');
    const tableData = getStateProp(riskMonitor, 'totalRegistrationList', []);
    const paging = getStateProp(riskMonitor, 'totalRegistrationPaging', {});

    // ============================================
    // METHODS
    // ============================================

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns()}
            rowKey={(record, idx) => `total-registration-${idx}`}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            dataSource={tableData}
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
        />
    );
};

export default TotalRegistrationModalTable;
