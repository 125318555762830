import React from 'react';

import { Card, Divider, Form } from 'antd';

import { API } from '../../../common/constants/api';
import { dropFilters } from './depositRecordActions';
import { getColumns } from './table/DepositRecordTableMeta';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useExportAsyncEndpoint } from '../../../services/hooks/useExport';
import { useGetDepositRecordsAsyncEndpoint } from '../../../services/depositRecordService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import DepositRecordFilters from './DepositRecordFilters';
import DepositRecordResultsTable from './table/DepositRecordResultsTable';
import TotalBalance from '../../../common/components/TotalBalance';
const { TRANSACTION_HISTORY } = API;

const DepositRecords = () => {
    const [state, dispatch] = useStore();
    const { depositRecords } = getStateProp(state, 'transactionHistory');
    const filters = getStateProp(depositRecords, 'filters');
    const { totalRecordCount } = getStateProp(depositRecords, 'paging', {});

    const pageBalance = getStateProp(depositRecords, 'pageDataTotal', []);
    const totalBalance = getStateProp(depositRecords, 'dataTotal', []);
    const grandTotal = getStateProp(depositRecords, 'grandTotal', 0);
    const pageTotal = getStateProp(depositRecords, 'pageTotal', 0);

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetDepositRecords();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [depositRecordLoading, handleGetDepositRecords] = useGetDepositRecordsAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        dataMeta: getColumns(),
        filters,
        innerListKey: 'data',
        method: 'GET',
        subject: getTranslation('Deposit Records'),
        totalRecordCount,
        url: `${TRANSACTION_HISTORY.DEPOSIT_RECORDS}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Deposit Records')}>
            <Form onFinish={handleGetDepositRecords}>
                <DepositRecordFilters />
                <ActionButtons onDropFilters={fetchAll} onExport={handleExportAction} />
            </Form>

            <DepositRecordResultsTable loading={depositRecordLoading} />

            <Divider />

            <TotalBalance
                pageBalance={pageBalance}
                totalBalance={totalBalance}
                grandTotal={grandTotal}
                pageTotal={pageTotal}
            />
        </Card>
    );
};

export default DepositRecords;
