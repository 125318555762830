import React from 'react';

import { ButtonGray } from '../../../../common/components/buttons/Buttons';
import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../depositRecordActions';
import { getStatusColor, sortTableByDataType } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';

export const getColumns = () => {
    const columns = [
        {
            dataIndex: 'requestedDateTime',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Request Time'),
        },
        {
            dataIndex: 'transactionNo',
            title: getTranslation('Task Transaction Number'),
        },
        {
            dataIndex: 'playerUserName',
            title: getTranslation('Username'),
        },
        {
            dataIndex: 'memberLevel',
            title: getTranslation('Member Level'),
        },
        {
            dataIndex: 'trustLevel',
            title: getTranslation('Trust Level'),
        },
        {
            dataIndex: 'currencyCode',
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'amount',
            title: getTranslation('Amount'),
        },
        {
            dataIndex: 'depositType',
            render: (value) => getTranslation(value),
            title: getTranslation('Deposit Type'),
        },
        {
            dataIndex: 'transactionMethod',
            render: (value) => getTranslation(value),
            title: getTranslation('Transaction Method'),
        },
        {
            dataIndex: 'recipient',
            title: getTranslation('Bank'),
        },
        {
            dataIndex: 'recipientId',
            title: getTranslation('Bank Account Number/Merchant ID'),
        },
        {
            dataIndex: 'applicant',
            title: getTranslation('Applicant'),
        },
        {
            dataIndex: 'orderStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>
                    {getTranslation(value).toLocaleUpperCase()}
                </span>
            ),
            title: getTranslation('Status'),
        },
        {
            dataIndex: 'processedDateTime',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Process Time'),
        },
        {
            dataIndex: 'paymentBankCardRemarks',
            title: getTranslation('System Remark'),
        },
        {
            dataIndex: 'transactionStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>
                    {getTranslation(value).toLocaleUpperCase()}
                </span>
            ),
            title: getTranslation('Transaction Status'),
        },
        {
            dataIndex: 'operatorName',
            render: (value) => <ButtonGray disabled label={value} className="action-btn__verify" />,
            title: getTranslation('Operator'),
            width: 80,
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
