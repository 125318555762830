import React from 'react';

import { Modal } from 'antd';

/*eslint import/namespace: ['error', { allowComputed: true }]*/
import * as Modals from '.';
import { getTranslation } from '../../../../common/helpers/locale';

const BonusCategoryModal = ({ bonusCategoryModal, onCancel }) => {
    const { isVisible, modalType } = bonusCategoryModal;
    const ModalContent = Modals[modalType.component];

    return (
        <Modal
            visible={isVisible}
            title={getTranslation(modalType.title)}
            centered
            className="bonus-category-modal"
            onCancel={onCancel}
            footer={null}
            width={modalType.width || 700}
        >
            <ModalContent onCancel={onCancel} />
        </Modal>
    );
};

export default BonusCategoryModal;
