import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { WALLET_PLATFORMS } from '../../../common/constants/misc';
import { getTranslation } from '../../../common/helpers/locale';

const TransferToFilter = ({ value, onFilterChange }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('transferTo', value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear
            placeholder={getTranslation('Transfer To')}
            className="filter"
        >
            {_.map(WALLET_PLATFORMS, (types) => (
                <Select.Option value={_.replace(types, ' ', '')} key={types}>
                    {_.replace(getTranslation(types), ' ', '')}
                </Select.Option>
            ))}
        </Select>
    );
};

export default TransferToFilter;
