import React from 'react';

import { Input } from 'antd';

import { getTranslation } from '../../../common/helpers/locale';

const PassageFilter = ({ value, onFilterChange }) => {
    return (
        <Input
            value={value}
            onChange={(e) => onFilterChange('passage', e.target.value)}
            placeholder={getTranslation('Passage')}
            className="filter"
        />
    );
};

export default PassageFilter;
