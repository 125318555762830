export const COUNTRIES = [
    { code: 'cn', name: 'China', prefix: '+86' },
    { code: 'my', name: 'Malaysia', prefix: '+60' },
    { code: 'id', name: 'Indonesia', prefix: '+62' },
    { code: 'th', name: 'Thailand', prefix: '+66' },
    { code: 'vn', name: 'Viet Nam', prefix: '+84' },
    { code: 'kr', name: 'Korea (Republic of)', prefix: '+82' },
    { code: 'jp', name: 'Japan', prefix: '+81' },
    { code: 'af', name: 'Afghanistan', prefix: '+93' },
    { code: 'ax', name: 'Åland Islands', prefix: '+358' },
    { code: 'al', name: 'Albania', prefix: '+355' },
    { code: 'dz', name: 'Algeria', prefix: '+213' },
    { code: 'as', name: 'American Samoa', prefix: '+1' },
    { code: 'ad', name: 'Andorra', prefix: '+376' },
    { code: 'ao', name: 'Angola', prefix: '+244' },
    { code: 'ai', name: 'Anguilla', prefix: '+1' },
    { code: 'aq', name: 'Antarctica', prefix: '+672' },
    { code: 'ag', name: 'Antigua and Barbuda', prefix: '+1' },
    { code: 'ar', name: 'Argentina', prefix: '+54' },
    { code: 'am', name: 'Armenia', prefix: '+374' },
    { code: 'aw', name: 'Aruba', prefix: '+297' },
    { code: 'au', name: 'Australia', prefix: '+61' },
    { code: 'at', name: 'Austria', prefix: '+43' },
    { code: 'az', name: 'Azerbaijan', prefix: '+994' },
    { code: 'bs', name: 'Bahamas', prefix: '+1' },
    { code: 'bh', name: 'Bahrain', prefix: '+973' },
    { code: 'bd', name: 'Bangladesh', prefix: '+880' },
    { code: 'bb', name: 'Barbados', prefix: '+1' },
    { code: 'by', name: 'Belarus', prefix: '+375' },
    { code: 'be', name: 'Belgium', prefix: '+32' },
    { code: 'bz', name: 'Belize', prefix: '+501' },
    { code: 'bj', name: 'Benin', prefix: '+229' },
    { code: 'bm', name: 'Bermuda', prefix: '+1' },
    { code: 'bt', name: 'Bhutan', prefix: '+975' },
    {
        code: 'bo',
        name: 'Bolivia (Plurinational State of)',
        prefix: '+591',
    },
    {
        code: 'bq',
        name: 'Bonaire, Sint Eustatius and Saba',
        prefix: '+599',
    },
    { code: 'ba', name: 'Bosnia and Herzegovina', prefix: '+387' },
    { code: 'bw', name: 'Botswana', prefix: '+267' },
    { code: 'bv', name: 'Bouvet Island', prefix: '+47' },
    { code: 'br', name: 'Brazil', prefix: '+55' },
    { code: 'io', name: 'British Indian Ocean Territory', prefix: '+246' },
    {
        code: 'um',
        name: 'United States Minor Outlying Islands',
        prefix: '+1',
    },
    { code: 'vg', name: 'Virgin Islands (British)', prefix: '+1' },
    { code: 'vi', name: 'Virgin Islands (U.S.)', prefix: '+1 340' },
    { code: 'bn', name: 'Brunei Darussalam', prefix: '+673' },
    { code: 'bg', name: 'Bulgaria', prefix: '+359' },
    { code: 'bf', name: 'Burkina Faso', prefix: '+226' },
    { code: 'bi', name: 'Burundi', prefix: '+257' },
    { code: 'kh', name: 'Cambodia', prefix: '+855' },
    { code: 'cm', name: 'Cameroon', prefix: '+237' },
    { code: 'ca', name: 'Canada', prefix: '+1' },
    { code: 'cv', name: 'Cabo Verde', prefix: '+238' },
    { code: 'ky', name: 'Cayman Islands', prefix: '+1' },
    { code: 'cf', name: 'Central African Republic', prefix: '+236' },
    { code: 'td', name: 'Chad', prefix: '+235' },
    { code: 'cl', name: 'Chile', prefix: '+56' },
    { code: 'cx', name: 'Christmas Island', prefix: '+61' },
    { code: 'cc', name: 'Cocos (Keeling) Islands', prefix: '+61' },
    { code: 'co', name: 'Colombia', prefix: '+57' },
    { code: 'km', name: 'Comoros', prefix: '+269' },
    { code: 'cg', name: 'Congo', prefix: '+242' },
    {
        code: 'cd',
        name: 'Congo (Democratic Republic of the)',
        prefix: '+243',
    },
    { code: 'ck', name: 'Cook Islands', prefix: '+682' },
    { code: 'cr', name: 'Costa Rica', prefix: '+506' },
    { code: 'hr', name: 'Croatia', prefix: '+385' },
    { code: 'cu', name: 'Cuba', prefix: '+53' },
    { code: 'cw', name: 'Curaçao', prefix: '+599' },
    { code: 'cy', name: 'Cyprus', prefix: '+357' },
    { code: 'cz', name: 'Czech Republic', prefix: '+420' },
    { code: 'dk', name: 'Denmark', prefix: '+45' },
    { code: 'dj', name: 'Djibouti', prefix: '+253' },
    { code: 'dm', name: 'Dominica', prefix: '+1' },
    { code: 'do', name: 'Dominican Republic', prefix: '+1' },
    { code: 'ec', name: 'Ecuador', prefix: '+593' },
    { code: 'eg', name: 'Egypt', prefix: '+20' },
    { code: 'sv', name: 'El Salvador', prefix: '+503' },
    { code: 'gq', name: 'Equatorial Guinea', prefix: '+240' },
    { code: 'er', name: 'Eritrea', prefix: '+291' },
    { code: 'ee', name: 'Estonia', prefix: '+372' },
    { code: 'et', name: 'Ethiopia', prefix: '+251' },
    { code: 'fk', name: 'Falkland Islands (Malvinas)', prefix: '+500' },
    { code: 'fo', name: 'Faroe Islands', prefix: '+298' },
    { code: 'fj', name: 'Fiji', prefix: '+679' },
    { code: 'fi', name: 'Finland', prefix: '+358' },
    { code: 'fr', name: 'France', prefix: '+33' },
    { code: 'gf', name: 'French Guiana', prefix: '+594' },
    { code: 'pf', name: 'French Polynesia', prefix: '+689' },
    { code: 'tf', name: 'French Southern Territories', prefix: '+262' },
    { code: 'ga', name: 'Gabon', prefix: '+241' },
    { code: 'gm', name: 'Gambia', prefix: '+220' },
    { code: 'ge', name: 'Georgia', prefix: '+995' },
    { code: 'de', name: 'Germany', prefix: '+49' },
    { code: 'gh', name: 'Ghana', prefix: '+233' },
    { code: 'gi', name: 'Gibraltar', prefix: '+350' },
    { code: 'gr', name: 'Greece', prefix: '+30' },
    { code: 'gl', name: 'Greenland', prefix: '+299' },
    { code: 'gd', name: 'Grenada', prefix: '+1' },
    { code: 'gp', name: 'Guadeloupe', prefix: '+590' },
    { code: 'gu', name: 'Guam', prefix: '+1' },
    { code: 'gt', name: 'Guatemala', prefix: '+502' },
    { code: 'gg', name: 'Guernsey', prefix: '+44' },
    { code: 'gn', name: 'Guinea', prefix: '+224' },
    { code: 'gw', name: 'Guinea-Bissau', prefix: '+245' },
    { code: 'gy', name: 'Guyana', prefix: '+592' },
    { code: 'ht', name: 'Haiti', prefix: '+509' },
    {
        code: 'hm',
        name: 'Heard Island and McDonald Islands',
        prefix: '+672',
    },
    { code: 'va', name: 'Holy See', prefix: '+379' },
    { code: 'hn', name: 'Honduras', prefix: '+504' },
    { code: 'hk', name: 'Hong Kong', prefix: '+852' },
    { code: 'hu', name: 'Hungary', prefix: '+36' },
    { code: 'is', name: 'Iceland', prefix: '+354' },
    { code: 'in', name: 'India', prefix: '+91' },
    { code: 'ci', name: "Côte d'Ivoire", prefix: '+225' },
    { code: 'ir', name: 'Iran (Islamic Republic of)', prefix: '+98' },
    { code: 'iq', name: 'Iraq', prefix: '+964' },
    { code: 'ie', name: 'Ireland', prefix: '+353' },
    { code: 'im', name: 'Isle of Man', prefix: '+44' },
    { code: 'il', name: 'Israel', prefix: '+972' },
    { code: 'it', name: 'Italy', prefix: '+39' },
    { code: 'jm', name: 'Jamaica', prefix: '+1' },
    { code: 'je', name: 'Jersey', prefix: '+44' },
    { code: 'jo', name: 'Jordan', prefix: '+962' },
    { code: 'kz', name: 'Kazakhstan', prefix: '+76' },
    { code: 'ke', name: 'Kenya', prefix: '+254' },
    { code: 'ki', name: 'Kiribati', prefix: '+686' },
    { code: 'kw', name: 'Kuwait', prefix: '+965' },
    { code: 'kg', name: 'Kyrgyzstan', prefix: '+996' },
    {
        code: 'la',
        name: "Lao People's Democratic Republic",
        prefix: '+856',
    },
    { code: 'lv', name: 'Latvia', prefix: '+371' },
    { code: 'lb', name: 'Lebanon', prefix: '+961' },
    { code: 'ls', name: 'Lesotho', prefix: '+266' },
    { code: 'lr', name: 'Liberia', prefix: '+231' },
    { code: 'ly', name: 'Libya', prefix: '+218' },
    { code: 'li', name: 'Liechtenstein', prefix: '+423' },
    { code: 'lt', name: 'Lithuania', prefix: '+370' },
    { code: 'lu', name: 'Luxembourg', prefix: '+352' },
    { code: 'mo', name: 'Macao', prefix: '+853' },
    {
        code: 'mk',
        name: 'Macedonia (the former Yugoslav Republic of)',
        prefix: '+389',
    },
    { code: 'mg', name: 'Madagascar', prefix: '+261' },
    { code: 'mw', name: 'Malawi', prefix: '+265' },
    { code: 'mv', name: 'Maldives', prefix: '+960' },
    { code: 'ml', name: 'Mali', prefix: '+223' },
    { code: 'mt', name: 'Malta', prefix: '+356' },
    { code: 'mh', name: 'Marshall Islands', prefix: '+692' },
    { code: 'mq', name: 'Martinique', prefix: '+596' },
    { code: 'mr', name: 'Mauritania', prefix: '+222' },
    { code: 'mu', name: 'Mauritius', prefix: '+230' },
    { code: 'yt', name: 'Mayotte', prefix: '+262' },
    { code: 'mx', name: 'Mexico', prefix: '+52' },
    {
        code: 'fm',
        name: 'Micronesia (Federated States of)',
        prefix: '+691',
    },
    { code: 'md', name: 'Moldova (Republic of)', prefix: '+373' },
    { code: 'mc', name: 'Monaco', prefix: '+377' },
    { code: 'mn', name: 'Mongolia', prefix: '+976' },
    { code: 'me', name: 'Montenegro', prefix: '+382' },
    { code: 'ms', name: 'Montserrat', prefix: '+1' },
    { code: 'ma', name: 'Morocco', prefix: '+212' },
    { code: 'mz', name: 'Mozambique', prefix: '+258' },
    { code: 'mm', name: 'Myanmar', prefix: '+95' },
    { code: 'na', name: 'Namibia', prefix: '+264' },
    { code: 'nr', name: 'Nauru', prefix: '+674' },
    { code: 'np', name: 'Nepal', prefix: '+977' },
    { code: 'nl', name: 'Netherlands', prefix: '+31' },
    { code: 'nc', name: 'New Caledonia', prefix: '+687' },
    { code: 'nz', name: 'New Zealand', prefix: '+64' },
    { code: 'ni', name: 'Nicaragua', prefix: '+505' },
    { code: 'ne', name: 'Niger', prefix: '+227' },
    { code: 'ng', name: 'Nigeria', prefix: '+234' },
    { code: 'nu', name: 'Niue', prefix: '+683' },
    { code: 'nf', name: 'Norfolk Island', prefix: '+672' },
    {
        code: 'kp',
        name: "Korea (Democratic People's Republic of)",
        prefix: '+850',
    },
    { code: 'mp', name: 'Northern Mariana Islands', prefix: '+1' },
    { code: 'no', name: 'Norway', prefix: '+47' },
    { code: 'om', name: 'Oman', prefix: '+968' },
    { code: 'pk', name: 'Pakistan', prefix: '+92' },
    { code: 'pw', name: 'Palau', prefix: '+680' },
    { code: 'ps', name: 'Palestine, State of', prefix: '+970' },
    { code: 'pa', name: 'Panama', prefix: '+507' },
    { code: 'pg', name: 'Papua New Guinea', prefix: '+675' },
    { code: 'py', name: 'Paraguay', prefix: '+595' },
    { code: 'pe', name: 'Peru', prefix: '+51' },
    { code: 'ph', name: 'Philippines', prefix: '+63' },
    { code: 'pn', name: 'Pitcairn', prefix: '+64' },
    { code: 'pl', name: 'Poland', prefix: '+48' },
    { code: 'pt', name: 'Portugal', prefix: '+351' },
    { code: 'pr', name: 'Puerto Rico', prefix: '+1' },
    { code: 'qa', name: 'Qatar', prefix: '+974' },
    { code: 're', name: 'Réunion', prefix: '+262' },
    { code: 'ro', name: 'Romania', prefix: '+40' },
    { code: 'ru', name: 'Russian Federation', prefix: '+7' },
    { code: 'rw', name: 'Rwanda', prefix: '+250' },
    { code: 'bl', name: 'Saint Barthélemy', prefix: '+590' },
    {
        code: 'sh',
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        prefix: '+290',
    },
    { code: 'kn', name: 'Saint Kitts and Nevis', prefix: '+1' },
    { code: 'lc', name: 'Saint Lucia', prefix: '+1' },
    { code: 'mf', name: 'Saint Martin (French part)', prefix: '+590' },
    { code: 'pm', name: 'Saint Pierre and Miquelon', prefix: '+508' },
    { code: 'vc', name: 'Saint Vincent and the Grenadines', prefix: '+1' },
    { code: 'ws', name: 'Samoa', prefix: '+685' },
    { code: 'sm', name: 'San Marino', prefix: '+378' },
    { code: 'st', name: 'Sao Tome and Principe', prefix: '+239' },
    { code: 'sa', name: 'Saudi Arabia', prefix: '+966' },
    { code: 'sn', name: 'Senegal', prefix: '+221' },
    { code: 'rs', name: 'Serbia', prefix: '+381' },
    { code: 'sc', name: 'Seychelles', prefix: '+248' },
    { code: 'sl', name: 'Sierra Leone', prefix: '+232' },
    { code: 'sg', name: 'Singapore', prefix: '+65' },
    { code: 'sx', name: 'Sint Maarten (Dutch part)', prefix: '+1' },
    { code: 'sk', name: 'Slovakia', prefix: '+421' },
    { code: 'si', name: 'Slovenia', prefix: '+386' },
    { code: 'sb', name: 'Solomon Islands', prefix: '+677' },
    { code: 'so', name: 'Somalia', prefix: '+252' },
    { code: 'za', name: 'South Africa', prefix: '+27' },
    {
        code: 'gs',
        name: 'South Georgia and the South Sandwich Islands',
        prefix: '+500',
    },
    { code: 'ss', name: 'South Sudan', prefix: '+211' },
    { code: 'es', name: 'Spain', prefix: '+34' },
    { code: 'lk', name: 'Sri Lanka', prefix: '+94' },
    { code: 'sd', name: 'Sudan', prefix: '+249' },
    { code: 'sr', name: 'Suriname', prefix: '+597' },
    { code: 'sj', name: 'Svalbard and Jan Mayen', prefix: '+47' },
    { code: 'sz', name: 'Swaziland', prefix: '+268' },
    { code: 'se', name: 'Sweden', prefix: '+46' },
    { code: 'ch', name: 'Switzerland', prefix: '+41' },
    { code: 'sy', name: 'Syrian Arab Republic', prefix: '+963' },
    { code: 'tw', name: 'Taiwan', prefix: '+886' },
    { code: 'tj', name: 'Tajikistan', prefix: '+992' },
    { code: 'tz', name: 'Tanzania, United Republic of', prefix: '+255' },
    { code: 'tl', name: 'Timor-Leste', prefix: '+670' },
    { code: 'tg', name: 'Togo', prefix: '+228' },
    { code: 'tk', name: 'Tokelau', prefix: '+690' },
    { code: 'to', name: 'Tonga', prefix: '+676' },
    { code: 'tt', name: 'Trinidad and Tobago', prefix: '+1' },
    { code: 'tn', name: 'Tunisia', prefix: '+216' },
    { code: 'tr', name: 'Turkey', prefix: '+90' },
    { code: 'tm', name: 'Turkmenistan', prefix: '+993' },
    { code: 'tc', name: 'Turks and Caicos Islands', prefix: '+1' },
    { code: 'tv', name: 'Tuvalu', prefix: '+688' },
    { code: 'ug', name: 'Uganda', prefix: '+256' },
    { code: 'ua', name: 'Ukraine', prefix: '+380' },
    { code: 'ae', name: 'United Arab Emirates', prefix: '+971' },
    {
        code: 'gb',
        name: 'United Kingdom of Great Britain and Northern Ireland',
        prefix: '+44',
    },
    { code: 'us', name: 'United States of America', prefix: '+1' },
    { code: 'uy', name: 'Uruguay', prefix: '+598' },
    { code: 'uz', name: 'Uzbekistan', prefix: '+998' },
    { code: 'vu', name: 'Vanuatu', prefix: '+678' },
    {
        code: 've',
        name: 'Venezuela (Bolivarian Republic of)',
        prefix: '+58',
    },
    { code: 'wf', name: 'Wallis and Futuna', prefix: '+681' },
    { code: 'eh', name: 'Western Sahara', prefix: '+212' },
    { code: 'ye', name: 'Yemen', prefix: '+967' },
    { code: 'zm', name: 'Zambia', prefix: '+260' },
    { code: 'zw', name: 'Zimbabwe', prefix: '+263' },
];
