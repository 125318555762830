import { Redirect, Switch } from 'react-router-dom';
import React from 'react';

import { PrivateRoute } from '../../../common/components';
import { ROUTES } from '../../../common/constants/routing';
import { useGetMenusAsyncEndpoint } from '../../../services/groupAuthoritySettingService';
import GroupAuthoritySettingEditor from './group-editor/GroupAuthoritySettingEditor';
import GroupAuthoritySettingList from './group-list/GroupAuthoritySettingList';

const GroupAuthoritySetting = () => {
    // ============================================
    // HOOKS
    // ============================================

    useGetMenusAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <>
            <Switch>
                <PrivateRoute
                    path={ROUTES.DASHBOARD.ADMINISTRATION.GROUP_AUTHORITY.LIST}
                    component={GroupAuthoritySettingList}
                />
                <PrivateRoute
                    path={ROUTES.DASHBOARD.ADMINISTRATION.GROUP_AUTHORITY.ADD}
                    component={GroupAuthoritySettingEditor}
                />
                <PrivateRoute
                    path={ROUTES.DASHBOARD.ADMINISTRATION.GROUP_AUTHORITY.EDIT}
                    component={GroupAuthoritySettingEditor}
                />
                <Redirect to={ROUTES.DASHBOARD.ADMINISTRATION.GROUP_AUTHORITY.LIST} />
            </Switch>
        </>
    );
};

export default GroupAuthoritySetting;
