import React from 'react';

import { Card, Form } from 'antd';

import { API } from '../../../common/constants/api';
import { dropFilters } from './referralRecordsActions';
import { getColumns } from './table/ReferralRecordsTableMeta';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useExportAsyncEndpoint } from '../../../services/hooks/useExport';
import { useGetReferralRecordsAsyncEndpoint } from '../../../services/referralRecordService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import ReferralRecordFilters from './ReferralRecordFilters';
import ReferralRecordResultsTable from './table/ReferralRecordResultsTable';

const { TRANSACTION_HISTORY } = API;

const ReferralRecords = () => {
    const [state, dispatch] = useStore();
    const { referralRecords } = getStateProp(state, 'transactionHistory');
    const filters = getStateProp(referralRecords, 'filters');
    const { totalRecordCount } = getStateProp(referralRecords, 'paging', {});

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetReferralRecords();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetReferralRecords] = useGetReferralRecordsAsyncEndpoint();

    const [handleExportAction] = useExportAsyncEndpoint({
        dataMeta: getColumns(),
        filters,
        method: 'GET',
        subject: getTranslation('Referral Records'),
        totalRecordCount,
        url: `${TRANSACTION_HISTORY.REFERRAL_RECORDS}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Referral Records')}>
            <Form onFinish={handleGetReferralRecords}>
                <ReferralRecordFilters />
                <ActionButtons onDropFilters={fetchAll} onExport={handleExportAction} />
            </Form>

            <ReferralRecordResultsTable loading={loading} />
        </Card>
    );
};

export default ReferralRecords;
