import { clearError } from '../common/actions/errorActions';
import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import { API } from '../common/constants/api';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import {
    turnoverRecordsGet,
    turnoverRecordsGetFailure,
    turnoverRecordsGetSuccess,
} from '../components/transaction-history/turnover-records/turnoverRecordsActions';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';

import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, TRANSACTION_HISTORY } = API;

export function useGetTurnOverRecordsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { turnoverRecords } = getStateProp(state, 'transactionHistory');
    const { pageNo, pageSize } = getStateProp(turnoverRecords, 'paging', {});
    const filters = getStateProp(turnoverRecords, 'filters', {});

    // ============================================
    // METHODS
    // ============================================
    const handleGetTurnoverRecords = useCallback(() => {
        turnoverRecordsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !_.every(filters, _.isEmpty),
        method: 'POST',
        options: {
            data: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${TRANSACTION_HISTORY.TURNOVER_RECORDS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            turnoverRecordsGetSuccess(dispatch, {
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        handleGetTurnoverRecords();
    }, [handleGetTurnoverRecords, pageNo, pageSize]);

    const errorMsg = useError(error, turnoverRecordsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetTurnoverRecords];
}
