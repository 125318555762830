export const ACTIONS = {
    FILTERS_DROP_ALL: 'REBATE_RECORDS_FILTERS_DROP_ALL',
    FILTER_SET: 'REBATE_RECORDS_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'REBATE_RECORDS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'REBATE_RECORDS_TABLE_PAGE_SIZE_CHANGE',
    },
    REBATE_RECORDS_GET: 'REBATE_RECORDS_GET',
    REBATE_RECORDS_GET_FAILURE: 'REBATE_RECORDS_GET_FAILURE',
    REBATE_RECORDS_GET_SUCCESS: 'REBATE_RECORDS_GET_SUCCESS',
};

export const rebateRecordsGet = (dispatch) =>
    dispatch({
        type: ACTIONS.REBATE_RECORDS_GET,
    });

export const rebateRecordsGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_RECORDS_GET_SUCCESS,
    });

export const rebateRecordsGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REBATE_RECORDS_GET_FAILURE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const rebateRecordsFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });
