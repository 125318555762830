import React, { useState } from 'react';

import { Card, Checkbox, Col, Row } from 'antd';
import _ from 'lodash';

import './MenusSelection.less';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useStore } from '../../../../store/StateProvider';

const MenusSelection = ({ menus, title, selectAction, loading }) => {
    const [state] = useStore();

    const groupAuthoritySetting = getStateProp(state, 'administration.groupAuthoritySetting');
    const selectedMenus = getStateProp(groupAuthoritySetting, 'groupAccessRecord.selectedMenus');

    const [allSelected, setAllSelected] = useState();

    // ============================================
    // METHODS
    // ============================================

    const onChange = (selectedColumnsList) => {
        selectAction(title, selectedColumnsList);
        setAllSelected(selectedColumnsList.length === menus.length);
    };

    const onSelectAllChange = (e) => {
        selectAction(
            title,
            e.target.checked
                ? menus.map((column) => {
                      return column.menuId;
                  })
                : null,
        );
        setAllSelected(e.target.checked);
    };
    // ============================================
    // RENDER
    // ============================================

    return (
        <Card className="menus-selection-card" loading={loading}>
            <Col>
                <Row className="menus-selection__select-all">
                    <Checkbox
                        checked={allSelected || _.size(selectedMenus[title]) === menus.length}
                        onChange={onSelectAllChange}
                    >
                        <span className="menus-selection__menu-tile">{getTranslation(title)}</span>
                    </Checkbox>
                </Row>

                <Row className="menu-selection__submenu-header">
                    <span className="menu-selection__system-unit-label">
                        {getTranslation('System Unit')}
                    </span>
                    <span className="menu-selection__authority-label">
                        {getTranslation('Authority')}
                    </span>
                </Row>

                <Checkbox.Group
                    className="menu-selection__submenu-checkbox-group"
                    onChange={onChange}
                    value={selectedMenus[title]}
                >
                    {menus.map((column) => {
                        return (
                            <Row className="menu-selection__submenu-row" key={column.menuId}>
                                <Checkbox value={column.menuId}>
                                    <span className="menus-selection__submenu-tile">
                                        {getTranslation(column.menuName)}
                                    </span>
                                </Checkbox>
                            </Row>
                        );
                    })}
                </Checkbox.Group>
            </Col>
        </Card>
    );
};

export default MenusSelection;
