export const ACTIONS = {
    FILTERS_DROP_ALL: 'VIP_HISTORY_FILTERS_DROP_ALL',
    FILTER_SET: 'VIP_HISTORY_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'VIP_HISTORY_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'VIP_HISTORY_TABLE_PAGE_SIZE_CHANGE',
    },
    VIP_HISTORY_GET: 'VIP_HISTORY_GET',
    VIP_HISTORY_GET_FAILURE: 'VIP_HISTORY_GET_FAILURE',
    VIP_HISTORY_GET_SUCCESS: 'VIP_HISTORY_GET_SUCCESS',
};

export const vipHistoryGet = (dispatch) =>
    dispatch({
        type: ACTIONS.VIP_HISTORY_GET,
    });

export const vipHistoryGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.VIP_HISTORY_GET_SUCCESS,
    });

export const vipHistoryGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.VIP_HISTORY_GET_FAILURE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const vipHistoryFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });
