import React, { useState } from 'react';

import { Table } from 'antd';
import { useVT } from 'virtualizedtableforantd4';
import ResizeObserver from 'rc-resize-observer';

import { getColumns, getPaginationOptions } from './BonusRecordTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const BonusRecordResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();
    const [tableWidth, setTableWidth] = useState(1800);

    const { bonusRecords } = getStateProp(state, 'transactionHistory');
    const tableData = getStateProp(bonusRecords, 'list', []);
    const paging = getStateProp(bonusRecords, 'paging', {});

    // ============================================
    // HOOKS
    // ============================================

    const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

    // ============================================
    // RENDER
    // ============================================

    return (
        <ResizeObserver
            onResize={({ width }) => {
                if (width > 1800) setTableWidth(width);
            }}
        >
            <Table
                columns={getColumns(tableWidth)}
                rowKey={(record) => record.transNo}
                size="small"
                align="left"
                scroll={{ x: tableWidth, y: 600 }}
                pagination={getPaginationOptions(paging, dispatch)}
                bordered
                dataSource={tableData}
                loading={loading}
                components={vt}
            />
        </ResizeObserver>
    );
};

export default BonusRecordResultsTable;
