export const ACTIONS = {
    FILTERS_DROP_ALL: 'REFERRAL_REPORT_FILTERS_DROP_ALL',
    FILTER_SET: 'REFERRAL_REPORT_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'REFERRAL_REPORT_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'REFERRAL_REPORT_TABLE_PAGE_SIZE_CHANGE',
    },
    REFERRAL_REPORT_GET: 'REFERRAL_REPORT_GET',
    REFERRAL_REPORT_GET_FAILURE: 'REFERRAL_REPORT_GET_FAILURE',
    REFERRAL_REPORT_GET_SUCCESS: 'REFERRAL_REPORT_GET_SUCCESS',

    TOP_TEN_REFERRER_GET: 'TOP_TEN_REFERRER_GET',
    TOP_TEN_REFERRER_GET_FAILURE: 'TOP_TEN_REFERRER_GET_FAILURE',
    TOP_TEN_REFERRER_GET_SUCCESS: 'TOP_TEN_REFERRER_GET_SUCCESS',
};

export const referralReportGet = (dispatch) =>
    dispatch({
        type: ACTIONS.REFERRAL_REPORT_GET,
    });

export const referralReportGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRAL_REPORT_GET_FAILURE,
    });

export const referralReportGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRAL_REPORT_GET_SUCCESS,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const referralReportFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const topTenReferrerGet = (dispatch) =>
    dispatch({
        type: ACTIONS.TOP_TEN_REFERRER_GET,
    });

export const topTenReferrerGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TOP_TEN_REFERRER_GET_FAILURE,
    });

export const topTenReferrerGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TOP_TEN_REFERRER_GET_SUCCESS,
    });
