import React from 'react';

import { Input } from 'antd';

import { getTranslation } from '../../helpers/locale';

const PromoCodeFilter = ({ value, onFilterChange }) => {
    return (
        <Input
            onChange={(e) => onFilterChange('promoCode', e.target.value)}
            value={value}
            className="filter"
            placeholder={getTranslation('Promo Code')}
        />
    );
};

export default PromoCodeFilter;
