export const ACTIONS = {
    EWALLET_CLEAR: 'EWALLET_CLEAR',
    EWALLET_GET: 'EWALLET_GET',
    EWALLET_GET_FAILURE: 'EWALLET_GET_FAILURE',
    EWALLET_GET_SUCCESS: 'EWALLET_GET_SUCCESS',
};

export const ewalletGet = (dispatch) =>
    dispatch({
        type: ACTIONS.EWALLET_GET,
    });

export const ewalletClear = (dispatch) =>
    dispatch({
        type: ACTIONS.EWALLET_CLEAR,
    });

export const ewalletGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.EWALLET_GET_SUCCESS,
    });

export const ewalletGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.EWALLET_GET_FAILURE,
    });
