import vipHistoryReducer from './vip-history/vipHistoryReducer';
import vipSetupReducer from './vip-setup/vipSetupReducer';

export default function vipReducer(vip = {}, action) {
    const { vipSetup, vipHistory } = vip;

    return {
        vipHistory: vipHistoryReducer(vipHistory, action),
        vipSetup: vipSetupReducer(vipSetup, action),
    };
}
