import announcementCreateReducer from './announcement-create/announcementCreateReducer';
import announcementHistoryReducer from './announcement-history/announcementHistoryReducer';
import announcementVerificationReducer from './announcement-verification/announcementVerificationReducer';
import messageCreateReducer from './message-create/messageCreateReducer';

export default function announcementReducer(announcement = {}, action) {
    const {
        announcementCreate,
        messageCreate,
        announcementVerification,
        announcementHistory,
    } = announcement;

    return {
        announcementCreate: announcementCreateReducer(announcementCreate, action),
        announcementHistory: announcementHistoryReducer(announcementHistory, action),
        announcementVerification: announcementVerificationReducer(announcementVerification, action),
        messageCreate: messageCreateReducer(messageCreate, action),
    };
}
