import React from 'react';

import { ButtonTransparent } from '../../../../common/components/buttons/Buttons';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../playerWinLossActions';
import { getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';

export const getColumns = (openModal) => {
    const columns = [
        {
            dataIndex: 'userName',
            render: (value, record) => (
                <ButtonTransparent
                    className="hyperlink-button"
                    onClick={() => openModal(record.userName)}
                >
                    {record.userName}
                </ButtonTransparent>
            ),
            title: getTranslation('Username'),
        },
        {
            dataIndex: 'currency',
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'totalRecords',
            title: getTranslation('Total Transaction'),
        },
        {
            dataIndex: 'totalTurnover',
            title: getTranslation('Total Turnover'),
        },
        {
            dataIndex: 'totalEffectiveTurnover',
            title: getTranslation('Total Effective Turonver'),
        },
        {
            dataIndex: 'totalWinLoss',
            title: getTranslation('Win/Loss'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
