import React from 'react';

import { Button, Popover, Row, Tabs } from 'antd';
import _ from 'lodash';

import './BonusTabs.less';
import { getLanguageByCode, getTranslation } from '../../../common/helpers/locale';
import BannerFormInputs from './BannerFormInputs';
import DuplicateTab from './DuplicateTab';
import LanguagesCheckboxes from '../../../common/components/LanguagesCheckboxes';

const { TabPane } = Tabs;

const BannerTabs = ({
    setBannerLang,
    selectedLangs,
    onContentChange,
    onBannerInfoChange,
    onDuplicateBanner,
    banner,
    isEdit,
    viewOnly,
}) => {
    const { details, ...rest } = banner;

    // ============================================
    // RENDER
    // ============================================

    const TabsExtraContent = (
        <Row>
            <Popover
                placement="bottom"
                title={getTranslation('Duplicate')}
                content={<DuplicateTab details={details} onDuplicateContent={onDuplicateBanner} />}
                trigger="click"
            >
                <Button style={{ marginRight: '5px' }}>{getTranslation('Duplicate')}</Button>
            </Popover>

            <Popover
                placement="bottom"
                title={getTranslation('Select Languages')}
                content={
                    <LanguagesCheckboxes onCheckedLanguages={setBannerLang} value={selectedLangs} />
                }
                trigger="click"
            >
                <Button>{getTranslation('Select Languages')}</Button>
            </Popover>
        </Row>
    );

    return (
        <Tabs tabBarExtraContent={viewOnly ? null : TabsExtraContent} className="banner__tabs">
            {_.map(selectedLangs, (value) => {
                const tabTitle = getLanguageByCode(value);
                const sortedContent = _.find(details, (content) => {
                    return content.languageCode === value;
                });

                return (
                    <TabPane tab={tabTitle} key={value}>
                        <BannerFormInputs
                            onContentChange={onContentChange}
                            onBannerInfoChange={onBannerInfoChange}
                            langCode={value}
                            bannerContent={sortedContent}
                            bannerInfo={rest}
                            isEdit={isEdit}
                            viewOnly={viewOnly}
                        />
                    </TabPane>
                );
            })}
        </Tabs>
    );
};

export default BannerTabs;
