export const ACTIONS = {
    ANNNOUNCEMENT_INIT: 'ANNNOUNCEMENT_INIT',
    ANNOUNCEMENT_ADD: 'ANNOUNCEMENT_ADD',
    ANNOUNCEMENT_ADD_FAILURE: 'ANNOUNCEMENT_ADD_FAILURE',
    ANNOUNCEMENT_ADD_SUCCESS: 'ANNOUNCEMENT_ADD_SUCCESS',
    ANNOUNCEMENT_CURRENCY_SET: 'ANNOUNCEMENT_CURRENCY_SET',
    ANNOUNCEMENT_DRAFT_CONTENT_SET: 'ANNOUNCEMENT_DRAFT_CONTENT_SET',
    ANNOUNCEMENT_DRAFT_INFO_SET: 'ANNOUNCEMENT_DRAFT_INFO_SET',
    ANNOUNCEMENT_DRAFT_LANGUAGE_DROP: 'ANNOUNCEMENT_DRAFT_LANGUAGE_DROP',
    ANNOUNCEMENT_DRAFT_LANGUAGE_SET: 'ANNOUNCEMENT_DRAFT_LANGUAGE_SET',
    ANNOUNCEMENT_DRAFT_RESET: 'ANNOUNCEMENT_DRAFT_RESET',
};

export const setAnnouncementInit = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ANNNOUNCEMENT_INIT,
    });

export const setAnnouncementDraftLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ANNOUNCEMENT_DRAFT_LANGUAGE_SET,
    });

export const dropAnnouncementDraftLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ANNOUNCEMENT_DRAFT_LANGUAGE_DROP,
    });

export const setAnnouncementDraftContent = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ANNOUNCEMENT_DRAFT_CONTENT_SET,
    });

export const setAnnouncementDraftInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ANNOUNCEMENT_DRAFT_INFO_SET,
    });

export const resetAnnouncementDraft = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ANNOUNCEMENT_DRAFT_RESET,
    });

export const addAnnouncement = (dispatch) =>
    dispatch({
        type: ACTIONS.ANNOUNCEMENT_ADD,
    });

export const addAnnouncementSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.ANNOUNCEMENT_ADD_SUCCESS,
    });

export const addAnnouncementFailure = (dispatch) =>
    dispatch({
        type: ACTIONS.ANNOUNCEMENT_ADD_FAILURE,
    });

export const setAnnouncementDraftCurrency = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ANNOUNCEMENT_CURRENCY_SET,
    });
