export const ACTIONS = {
    OPERATIONS_CHANGE_PLAYER_STATUS: 'OPERATIONS_CHANGE_PLAYER_STATUS',
    OPERATIONS_CLEAR: 'OPERATIONS_CLEAR',
    OPERATIONS_CREATE_OPERATOR: 'OPERATIONS_CREATE_OPERATOR',
    OPERATIONS_CREATE_PLAYER: 'OPERATIONS_CREATE_PLAYER',
};

export const clearOperation = (dispatch) =>
    dispatch({
        type: ACTIONS.OPERATIONS_CLEAR,
    });

export const createPlayerOperation = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OPERATIONS_CREATE_PLAYER,
    });

export const createChangeStatusOperation = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OPERATIONS_CHANGE_PLAYER_STATUS,
    });

export const createOperatorOperation = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OPERATIONS_CREATE_OPERATOR,
    });
