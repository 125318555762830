import React from 'react';

import _ from 'lodash';

import { LANGUAGES } from '../../../../common/constants/localization';
import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../bonusManagementActions';
import { findTitleByLanguage, getTranslation } from '../../../../common/helpers/locale';
import { sortTableByDataType } from '../../../../common/helpers/misc';
import ColumnActionButtons from '../ColumnActionButton';
import ViewRecordButton from '../operations/ViewRecordButton';

export const getColumns = (
    dispatch,
    openEditModal,
    handleGetBannerById,
    langCode,
    openViewModal,
    isViewOnly,
) => {
    const columns = [
        {
            dataIndex: 'code',
            render: (value, record) =>
                isViewOnly ? (
                    <ViewRecordButton
                        dispatch={dispatch}
                        handleGetBannerById={handleGetBannerById}
                        openViewModal={openViewModal}
                        bonus={record}
                    />
                ) : (
                    <span>{value}</span>
                ),
            title: getTranslation('Bonus Code'),
        },
        {
            dataIndex: 'bonusName',
            filterMultiple: false,
            filters: _.map(LANGUAGES, (language) => {
                return {
                    text: language.name,
                    value: language.value,
                };
            }),
            render: (value, record) => {
                return findTitleByLanguage(record.details, langCode, 'bonusName');
            },
            title: getTranslation('Bonus Name'),
        },
        {
            dataIndex: 'currencies',
            render: (value) => (
                <span>
                    {_.map(value, (currency, index) => {
                        return `${currency.currencyCode}${index + 1 !== value.length ? ',' : ''} `;
                    })}
                </span>
            ),
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'publishedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Created Date'),
        },
        {
            dataIndex: 'createdName',
            title: getTranslation('Created By'),
        },
        {
            dataIndex: 'activatedName',
            title: getTranslation('Activated/Approved By'),
        },
        {
            dataIndex: 'updatedName',
            title: getTranslation('Last Modified By'),
        },
        {
            dataIndex: 'updatedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Last Modified Date'),
        },
        {
            dataIndex: 'isShowOnApp',
            render: (value) => <span>{value ? getTranslation('Yes') : getTranslation('No')}</span>,
            title: getTranslation('Display in Client App'),
        },
        {
            dataIndex: 'hasJoinButton',
            render: (value) => <span>{value ? getTranslation('Yes') : getTranslation('No')}</span>,
            title: getTranslation('Show Join Button'),
        },
        {
            dataIndex: 'isRequireDeposit',
            render: (value) => <span>{value ? getTranslation('Yes') : getTranslation('No')}</span>,
            title: getTranslation('Require Deposit'),
        },
        {
            dataIndex: 'isRequireBankCard',
            render: (value) => <span>{value ? getTranslation('Yes') : getTranslation('No')}</span>,
            title: getTranslation('Require Bank Card'),
        },
        {
            dataIndex: 'isRequireBonusComplete',
            render: (value) => <span>{value ? getTranslation('Yes') : getTranslation('No')}</span>,
            title: getTranslation('Require Bonus Complete'),
        },
    ];

    !isViewOnly
        ? columns.push({
              dataIndex: 'action',
              render: (value, record) => (
                  <ColumnActionButtons
                      dispatch={dispatch}
                      bonus={record}
                      openEditModal={openEditModal}
                      handleGetBannerById={handleGetBannerById}
                  />
              ),
              title: getTranslation('Action'),
          })
        : columns.push({
              dataIndex: 'bonusStatus',
              title: getTranslation('Status'),
          });

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
