import React from 'react';

import { Row } from 'antd';

import { PeriodFilter } from '../../../common/components/filters';
import { getStateProp } from '../../../common/helpers/misc';
import { setFilters } from './dailyLoginManagementActions';
import { useStore } from '../../../store/StateProvider';
import CodeFilter from './filters/CodeFilter';
import StatusFilter from './filters/StatusFilter';

const DailyLoginManagementFilters = () => {
    const [state, dispatch] = useStore();
    const dailyLoginManagement = getStateProp(state, 'rewardsManagement.dailyLoginManagement');
    const { code, status, dateFrom, dateTo } = getStateProp(
        dailyLoginManagement,
        'filters',
        () => {},
    );

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => {
        setFilters(dispatch, { filter, value });
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <CodeFilter onFilterChange={setFilter} value={code} />
            <StatusFilter onFilterChange={setFilter} value={status} />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={dateFrom}
                dateFromName="dateFrom"
                dateTo={dateTo}
                dateToName="dateTo"
            />
        </Row>
    );
};

export default DailyLoginManagementFilters;
