export const ACTIONS = {
    EXPORT_TABLE: 'EXPORT_TABLE',
    EXPORT_TABLE_FAILURE: 'EXPORT_TABLE_FAILURE',
    EXPORT_TABLE_SUCCESS: 'EXPORT_TABLE_SUCCESS',
};

export const exportTableData = (dispatch) =>
    dispatch({
        type: ACTIONS.EXPORT_TABLE,
    });

export const exportTableDataSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.EXPORT_TABLE_SUCCESS,
    });

export const exportTableDataFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.EXPORT_TABLE_FAILURE,
    });
