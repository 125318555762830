import React, { useState } from 'react';

import { Table } from 'antd';
import { useVT } from 'virtualizedtableforantd4';
import ResizeObserver from 'rc-resize-observer';

import { getColumns, getPaginationOptions } from './ChannelsTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const ChannelsResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();
    const [tableWidth, setTableWidth] = useState(2500);

    const tableData = getStateProp(state, 'players.channels.list', []);
    const paging = getStateProp(state, 'players.channels.paging', {});

    // ============================================
    // HOOKS
    // ============================================

    const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

    // ============================================
    // RENDER
    // ============================================

    return (
        <ResizeObserver
            onResize={({ width }) => {
                if (width > 2500) setTableWidth(width);
            }}
        >
            <Table
                id="channels-table"
                columns={getColumns(paging.pageNo)}
                pagination={getPaginationOptions(paging, dispatch)}
                dataSource={tableData}
                rowKey={(record) => record.userId}
                loading={loading}
                size="small"
                align="left"
                bordered
                scroll={{ x: tableWidth, y: 600 }}
                components={vt}
            />
        </ResizeObserver>
    );
};

export default ChannelsResultsTable;
