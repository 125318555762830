import React from 'react';

import { Col, Row, Skeleton } from 'antd';

import { getStateProp } from '../../../../common/helpers/misc';
import {
    useGetPlayerByIdAsyncEndpoint,
    useGetWalletsAsyncEndpoint,
} from '../../../../services/playerService';
import { useStore } from '../../../../store/StateProvider';
import PlayerInformation from './PlayerInformation';
import WalletInformation from './WalletInformation';

const MemberInformation = (props) => {
    const { playerId } = props.match.params;
    const [state] = useStore();
    const userName = getStateProp(state, 'players.playerDetails.playerInfo.userName', null);
    const currencyCode = getStateProp(state, 'players.playerDetails.playerInfo.currencyCode', null);

    // ============================================
    // HOOKS
    // ============================================

    const [playerInfoLoading] = useGetPlayerByIdAsyncEndpoint(playerId);

    const [walletLoading, getWallets] = useGetWalletsAsyncEndpoint(userName);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col span={24}>
            {playerInfoLoading ? (
                <Row style={{ padding: 24 }}>
                    <Skeleton active paragraph={{ rows: 10 }} />
                </Row>
            ) : (
                <Row>
                    <PlayerInformation />
                </Row>
            )}

            <WalletInformation
                walletLoading={walletLoading}
                userName={userName}
                currencyCode={currencyCode}
                handleWalletRefresh={getWallets}
            />
        </Col>
    );
};

export default MemberInformation;
