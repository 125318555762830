export const ACTIONS = {
    CRYPTO_MERCHANT_CONFIGURATION_CRYPTO_TYPE_UPDATE:
        'CRYPTO_MERCHANT_CONFIGURATION_CRYPTO_TYPE_UPDATE',

    CRYPTO_MERCHANT_CONFIGURATION_RECORD_GET: 'CRYPTO_MERCHANT_CONFIGURATION_RECORD_GET',
    CRYPTO_MERCHANT_CONFIGURATION_RECORD_GET_FAILURE:
        'CRYPTO_MERCHANT_CONFIGURATION_RECORD_GET_FAILURE',
    CRYPTO_MERCHANT_CONFIGURATION_RECORD_GET_SUCCESS:
        'CRYPTO_MERCHANT_CONFIGURATION_RECORD_GET_SUCCESS',

    CRYPTO_MERCHANT_CONFIGURATION_UPDATE: 'CRYPTO_MERCHANT_CONFIGURATION_UPDATE',
    CRYPTO_MERCHANT_CONFIGURATION_UPDATE_FAILURE: 'CRYPTO_MERCHANT_CONFIGURATION_UPDATE_FAILURE',
    CRYPTO_MERCHANT_CONFIGURATION_UPDATE_SUCCESS: 'CRYPTO_MERCHANT_CONFIGURATION_UPDATE_SUCCESS',
};

export const cryptoMerchantConfigurationUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.CRYPTO_MERCHANT_CONFIGURATION_UPDATE,
    });

export const cryptoMerchantConfigurationUpdateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.CRYPTO_MERCHANT_CONFIGURATION_UPDATE_SUCCESS,
    });

export const cryptoMerchantConfigurationUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_MERCHANT_CONFIGURATION_UPDATE_FAILURE,
    });

export const cryptoMerchantConfigurationRecordGet = (dispatch) =>
    dispatch({
        type: ACTIONS.CRYPTO_MERCHANT_CONFIGURATION_RECORD_GET,
    });

export const cryptoMerchantConfigurationRecordGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_MERCHANT_CONFIGURATION_RECORD_GET_SUCCESS,
    });

export const cryptoMerchantConfigurationRecordGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_MERCHANT_CONFIGURATION_RECORD_GET_FAILURE,
    });

export const cryptoMerchantConfigurationCryptoTypeUpdate = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_MERCHANT_CONFIGURATION_CRYPTO_TYPE_UPDATE,
    });
