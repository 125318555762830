import React from 'react';

import { Row, Switch } from 'antd';

import './ActionButtons.less';
import { DropButton, SearchButton } from '../../../../common/components/buttons';
import { getTranslation } from '../../../../common/helpers/locale';

const ActionButtons = ({ onDropFilters, setShowSelectedBanners, showSelectedBanners }) => {
    return (
        <Row
            className="action-buttons__container sorting-banner__action-buttons"
            justify="space-between"
            style={{ marginTop: '15px' }}
        >
            <Row>
                {getTranslation('Show selected banners')}:
                <Switch
                    onChange={(value) => setShowSelectedBanners(value)}
                    checked={showSelectedBanners}
                />
            </Row>
            <Row>
                <SearchButton htmlType="submit" />
                <DropButton onDropFilters={onDropFilters} />
            </Row>
        </Row>
    );
};

export default ActionButtons;
