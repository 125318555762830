import React from 'react';

import { Modal, Row } from 'antd';

import './ConfirmModal.less';
import { ButtonGray, ButtonGreen } from '../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../common/helpers/locale';

const ConfirmModal = ({ onCancel, onConfirm, visible }) => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            title={getTranslation('Confirm to Transact?')}
            centered
            closable={false}
            className="deposit__confirm-modal"
            footer={null}
            onCancel={onCancel}
        >
            <Row key="actions" justify="space-between" align="middle">
                <ButtonGreen label={getTranslation('Approve')} onClick={onConfirm} />
                <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
            </Row>
        </Modal>
    );
};

export default ConfirmModal;
