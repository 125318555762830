import React, { useState } from 'react';

import { ButtonTransparent } from '../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../common/helpers/locale';
import { useGetTopTenReferrerReportAsyncEndpoint } from '../../../../services/referralService';
import TopTenReferrerModal from '../modal/TopTenReferrerModal';

const ViewButton = ({ id }) => {
    const [topTenReferrerModalVisible, setTopTenReferrerModalVisible] = useState(false);
    const [programId, setProgramId] = useState(null);

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetTopTenReferrer] = useGetTopTenReferrerReportAsyncEndpoint(programId);

    // ============================================
    // METHODS
    // ============================================

    const openViewModal = () => {
        setTopTenReferrerModalVisible(true);
        setProgramId(id);
        handleGetTopTenReferrer();
    };

    const closeViewModal = () => {
        setTopTenReferrerModalVisible(false);
        setProgramId(null);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <>
            <ButtonTransparent className="hyperlink-button" onClick={openViewModal}>
                {getTranslation('View')}
            </ButtonTransparent>

            <TopTenReferrerModal
                visible={topTenReferrerModalVisible}
                onCancel={closeViewModal}
                id={id}
                loading={loading}
            />
        </>
    );
};

export default ViewButton;
