import React from 'react';

import { Card, Form } from 'antd';

import './ReferralInquiry.less';
import { dropFilters } from './referralInquiryActions';
import { getTranslation } from '../../../common/helpers/locale';
import { useDispatch } from '../../../store/StateProvider';
import { useGetReferralInquiryAsyncEndpoint } from '../../../services/referralService';
import ActionButtons from './ActionButtons';
import ReferralInquiryFilters from './ReferralInquiryFilters';
import ReferralInquiryTable from './table/ReferralInquiryTable';

const ReferralInquiry = () => {
    const dispatch = useDispatch();
    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetReferralInquiry();
    };

    // ============================================
    // HOOKS
    // ============================================
    const [loading, handleGetReferralInquiry] = useGetReferralInquiryAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Referral Inquiry')}>
            <Form onFinish={handleGetReferralInquiry}>
                <ReferralInquiryFilters />
                <ActionButtons fetchAll={fetchAll} />
            </Form>
            <ReferralInquiryTable loading={loading} />
        </Card>
    );
};

export default ReferralInquiry;
