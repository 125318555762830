import React from 'react';

import { Card, Form } from 'antd';

import {
    bannerEditModalClose,
    bannerEditModalOpen,
    bannerViewModalClose,
    bannerViewModalOpen,
    dropFilters,
    setBannerDeselected,
} from './bannerManagementActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    useGetBannerByIdOperationAsyncEndpoint,
    useGetBannerListOperationAsyncEndpoint,
} from '../../../services/bannerManagementService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import BannerManagementFilters from './BannerManagementFilters';
import BannerManagementResultsTable from './table/BannerManagementResultsTable';
import EditBannerDescriptionModalContent from './modals/edit-banner-description/EditBannerDescriptionModalContent';

const BannerManagement = () => {
    const [state, dispatch] = useStore();

    const editModalVisible = getStateProp(state, 'bonus.bannerManagement.editModalVisible');
    const viewModalVisible = getStateProp(state, 'bonus.bannerManagement.viewModalVisible');

    // ============================================
    // METHODS
    // ============================================

    const openEditModal = () => bannerEditModalOpen(dispatch);

    const closeEditModal = () => {
        setBannerDeselected(dispatch);
        bannerEditModalClose(dispatch);
    };

    const openViewModal = () => bannerViewModalOpen(dispatch);

    const closeViewModal = () => {
        setBannerDeselected(dispatch);
        bannerViewModalClose(dispatch);
    };

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetBannerList();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [getListLoading, handleGetBannerList] = useGetBannerListOperationAsyncEndpoint();
    const [getBannerByIdLoading, handleGetBannerById] = useGetBannerByIdOperationAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Banner Management')}>
            <Form onFinish={handleGetBannerList}>
                <BannerManagementFilters />
                <ActionButtons onDropFilters={fetchAll} />
            </Form>

            <BannerManagementResultsTable
                loading={getListLoading}
                openEditModal={openEditModal}
                openViewModal={openViewModal}
                handleGetBannerById={handleGetBannerById}
            />

            <EditBannerDescriptionModalContent
                visible={editModalVisible || viewModalVisible}
                onCancel={viewModalVisible ? closeViewModal : closeEditModal}
                getBannerByIdLoading={getBannerByIdLoading}
                viewOnly={viewModalVisible}
            />
        </Card>
    );
};

export default BannerManagement;
