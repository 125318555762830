import React from 'react';

import { Input } from 'antd';

import { getTranslation } from '../../../common/helpers/locale';

const AnnouncementTitleFilter = ({ value, onFilterChange }) => {
    return (
        <Input
            value={value}
            onChange={(e) => onFilterChange('title', e.target.value)}
            placeholder={getTranslation('Title')}
            className="filter"
        />
    );
};

export default AnnouncementTitleFilter;
