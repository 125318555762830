import React, { useEffect, useState } from 'react';

import intl from 'react-intl-universal';

import {
    determineClientLanguage,
    getBrowserLocale,
    getCountryByLocale,
    getSupportedLocales,
} from '../../common/helpers/locale';
import { getCurrentLanguage, setLangCookies } from '../../common/helpers/api';
import { setCurrentCountry, setCurrentLanguage } from './actions';
import { useDispatch } from '../../store/StateProvider';

const IntlProvider = (props) => {
    const dispatch = useDispatch();
    const currentLanguage = getCurrentLanguage();
    const [initDone, setInitDone] = useState(false);

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        const language = currentLanguage || determineClientLanguage();

        intl.init({
            currentLocale: language,
            locales: getSupportedLocales(),
        }).then(() => {
            setCurrentCountry(dispatch, getCountryByLocale(getBrowserLocale()));
            setCurrentLanguage(dispatch, { language });
            setLangCookies(language);
            setInitDone(true);
        });
    }, [currentLanguage, dispatch]);

    // ============================================
    // RENDER
    // ============================================

    return initDone && <div>{props.children}</div>;
};

export default IntlProvider;
