export const ACTIONS = {
    FILTERS_DROP_ALL: 'PENDING_MODIFICATIONS_FILTERS_DROP_ALL',
    FILTER_SET: 'PENDING_MODIFICATIONS_FILTER_SET',
    MODIFICATIONS_GET: 'PENDING_MODIFICATIONS_GET',
    MODIFICATIONS_GET_FAILURE: 'PENDING_MODIFICATIONS_GET_FAILURE',
    MODIFICATIONS_GET_SUCCESS: 'PENDING_MODIFICATIONS_GET_SUCCESS',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'PENDING_MODIFICATIONS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'PENDING_MODIFICATIONS_TABLE_PAGE_SIZE_CHANGE',
    },
    VERIFY_MODAL_CLOSE: 'PENDING_MODIFICATIONS_VERIFY_MODAL_CLOSE',
    VERIFY_MODAL_OPEN: 'PENDING_MODIFICATIONS_VERIFY_MODAL_OPEN',
    VERIFY_REQUEST: 'PENDING_MODIFICATIONS_VERIFY_REQUEST',
    VERIFY_REQUEST_FAILURE: 'PENDING_MODIFICATIONS_VERIFY_REQUEST_FAILURE',
    VERIFY_REQUEST_SUCCESS: 'PENDING_MODIFICATIONS_VERIFY_REQUEST_SUCCESS',
};

export const modificationsFilterSet = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });
};

export const modificationsDropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const pendingModificationsGet = (dispatch) =>
    dispatch({
        type: ACTIONS.MODIFICATIONS_GET,
    });

export const pendingModificationsGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MODIFICATIONS_GET_SUCCESS,
    });

export const pendingModificationsGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MODIFICATIONS_GET_FAILURE,
    });

export const changeModificationsPageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changeModificationsPageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const openVerifyModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.VERIFY_MODAL_OPEN,
    });

export const closeVerifyModal = (dispatch) =>
    dispatch({
        type: ACTIONS.VERIFY_MODAL_CLOSE,
    });

export const verifyPendingModification = (dispatch) =>
    dispatch({
        type: ACTIONS.VERIFY_REQUEST,
    });

export const verifyPendingModificationSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.VERIFY_REQUEST_SUCCESS,
    });

export const verifyPendingModificationFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.VERIFY_REQUEST_FAILURE,
    });
