import { ACTIONS } from './activeEwalletActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../../common/constants/api';

export default function activeEwalletReducer(
    activeEwalletAccount = {
        modal: {
            type: null,
            visible: false,
        },
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        resetPageNo: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.ACTIVE_EWALLET_SETUP_MODAL_OPEN:
            return {
                ...activeEwalletAccount,
                modal: {
                    ...payload,
                    visible: true,
                },
            };
        case ACTIONS.ACTIVE_EWALLET_SETUP_MODAL_CLOSE:
            return {
                ...activeEwalletAccount,
                modal: {
                    type: null,
                    visible: false,
                },
            };
        case ACTIONS.FILTER_SET:
            return {
                ...activeEwalletAccount,
                filters: {
                    ...activeEwalletAccount.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };
        case ACTIONS.FILTER_DROP:
            return {
                ...activeEwalletAccount,
                filters: null,
                resetPageNo: true,
            };
        case ACTIONS.ACTIVE_EWALLET_GET_SUCCESS:
            return {
                ...activeEwalletAccount,
                ...payload,
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...activeEwalletAccount,
                paging: {
                    ...activeEwalletAccount.paging,
                    pageNo:
                        payload > activeEwalletAccount.paging.pageCount
                            ? activeEwalletAccount.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...activeEwalletAccount,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.ACTIVE_EWALLET_GET:
            const pageNo = activeEwalletAccount.resetPageNo
                ? TABLE_PAGE_NO_DEFAULT
                : activeEwalletAccount.paging.pageNo;
            return {
                ...activeEwalletAccount,
                paging: {
                    ...activeEwalletAccount.paging,
                    pageNo: pageNo,
                },
                resetPageNo: false,
            };
        default:
            return activeEwalletAccount;
    }
}
