import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

import { API } from '../common/constants/api';
import { clearError } from '../common/actions/errorActions';
import { getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import { message } from 'antd';
import {
    transactionAmountSettingGet,
    transactionAmountSettingGetFailure,
    transactionAmountSettingGetSuccess,
    transactionAmountSettingUpdate,
    transactionAmountSettingUpdateFailure,
    transactionAmountSettingUpdateSuccess,
} from '../components/payments/transaction-amount-setting/TransactionAmountSettingActions';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, PAYMENTS } = API;

export function useGetTransactionAmountSettingAsyncEndpoint(isEwallet) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const transactionAmountSetting = getStateProp(state, 'payments.transactionAmountSetting');
    const { pageNo, pageSize } = getStateProp(transactionAmountSetting, 'paging');
    const transactionAmountEndpoint = isEwallet
        ? PAYMENTS.TRANSACTION_AMOUNT_SETTING.EWALLET
        : PAYMENTS.TRANSACTION_AMOUNT_SETTING.ROOT;
    // ============================================
    // METHODS
    // ============================================

    const handleGetTransactionAmountSetting = useCallback(() => {
        transactionAmountSettingGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'POST',
        options: {
            data: {
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${transactionAmountEndpoint}/search`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            transactionAmountSettingGetSuccess(dispatch, {
                deposit: _.filter(response.data.data, (data) => {
                    return data.type === 'Deposit';
                }),
                fetchData: handleGetTransactionAmountSetting,
                paging: response.data.paging,
                withdrawal: _.filter(response.data.data, (data) => {
                    return data.type === 'Withdrawal';
                }),
            });
        }
    }, [dispatch, handleGetTransactionAmountSetting, response]);

    const errorMsg = useError(error, transactionAmountSettingGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetTransactionAmountSetting];
}

export function useUpdateTransactionAmountSettingAsyncEndpoint(isEwallet) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { deposit, withdrawal } = getStateProp(state, 'payments.transactionAmountSetting', {});
    const amountSetting = _.concat(deposit, withdrawal);
    const transactionAmountEndpoint = isEwallet
        ? PAYMENTS.TRANSACTION_AMOUNT_SETTING.EWALLET
        : PAYMENTS.TRANSACTION_AMOUNT_SETTING.ROOT;

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateTransactionAmountSetting = () => {
        transactionAmountSettingUpdate(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'POST',
        options: {
            data: {
                data: _.map(amountSetting, (setting) =>
                    _.pick(setting, ['id', 'minimumAmount', 'maximumAmount']),
                ),
            },
        },
        trigger,
        url: `${VERSION.V1}${transactionAmountEndpoint}/bulkUpdate`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            transactionAmountSettingUpdateSuccess(dispatch);
            message.success(getTranslation('Update Success!'));
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, transactionAmountSettingUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateTransactionAmountSetting, response];
}
