import React, { useState } from 'react';

import { Col, Modal, Row } from 'antd';

import { ButtonTransparent } from '../../../../common/components/buttons/Buttons';
import { ImgIcon } from '../../../../common/components';
import { getTranslation } from '../../../../common/helpers/locale';

const DepositReceiptView = ({ receipt, title = 'Bank Slip' }) => {
    const [receiptModalVisible, setReceiptModalVisible] = useState(false);

    const openReceiptModal = () => setReceiptModalVisible(true);
    const closeReceiptModal = () => setReceiptModalVisible(false);

    return (
        <Col>
            {receipt && (
                <ButtonTransparent className="hyperlink-button" onClick={openReceiptModal}>
                    {getTranslation('View')}
                </ButtonTransparent>
            )}

            <Modal
                visible={receiptModalVisible}
                onCancel={closeReceiptModal}
                title={getTranslation(title)}
                centered
                footer={
                    <Row key="actions" justify="end" align="middle">
                        <ButtonTransparent
                            label={getTranslation('Cancel')}
                            onClick={closeReceiptModal}
                        />
                    </Row>
                }
            >
                <ImgIcon icon={receipt} style={{ width: '100%' }} />
            </Modal>
        </Col>
    );
};

export default DepositReceiptView;
