import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Input, Row, Select } from 'antd';
import _ from 'lodash';

import './AddBankCardForm.less';
import { BANKS } from '../../../constants';
import { ButtonGreen } from '../../../../../../common/components/buttons/Buttons';
import { CURRENCIES } from '../../../../../../common/constants/localization';
import { equalityValidator, lengthValidator } from '../../../../../../common/helpers/validators';
import { getStateProp } from '../../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../../common/helpers/locale';
import { useAddBankCardAsyncEndpoint } from '../../../../../../services/playerService';
import { useStore } from '../../../../../../store/StateProvider';
import ImgIcon from '../../../../../../common/components/ImgIcon';

const AddBankCardForm = ({ onCancel, getPlayerInfo }) => {
    const [form] = Form.useForm();
    const { resetFields, setFieldsValue } = form;
    const [state] = useStore();
    const userName = getStateProp(state, 'players.playerDetails.playerInfo.userName', '');
    const realName = getStateProp(state, 'players.playerDetails.playerInfo.profile.realName', '');
    const currencyCode = getStateProp(
        state,
        'players.playerDetails.playerInfo.monetary.currencyCode',
    );
    const [formValues, setFormValues] = useState({});

    // ============================================
    // METHODS
    // ============================================

    const submitForm = (values) => {
        setFormValues(values);
        addBankCard();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [cardAdding, addBankCard, cardAdded] = useAddBankCardAsyncEndpoint(formValues);

    useEffect(() => {
        if (cardAdded) {
            resetFields();
            onCancel();
            getPlayerInfo();
        }
    }, [onCancel, cardAdded, resetFields, getPlayerInfo]);

    useEffect(() => {
        setFieldsValue({
            cardHolderName: realName,
        });
    }, [realName, setFieldsValue]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form
            form={form}
            name="add-bank-card"
            className="add-bank-card__form"
            onFinish={submitForm}
        >
            <Row>
                <Col span={8}>
                    <span className="bold-text">{getTranslation('Username')}:</span>
                </Col>
                <Col span={16}>
                    <span className="bold-text">{userName}</span>
                </Col>
            </Row>
            <Row className="add-bank-card__form__title">
                <span>{getTranslation('Add Bank Card')}</span>
            </Row>
            <Row className="form__group">
                <Col span={8} align="start">
                    <span className="add-bank-card__form__label">
                        {getTranslation('Bank Name')}
                        <span style={{ color: 'red' }}>*</span>:
                    </span>
                </Col>
                <Col span={16}>
                    <Form.Item
                        name="bankName"
                        rules={[
                            {
                                message: getTranslation('Please choose bank!'),
                                required: true,
                            },
                        ]}
                    >
                        <Select>
                            {_.map(BANKS[currencyCode], (bank) => (
                                <Select.Option value={bank.name} key={bank.name}>
                                    <Row>
                                        <Col span={2}>
                                            <ImgIcon icon={bank.icon} size={20} />
                                        </Col>
                                        <Col span={22} style={{ paddingLeft: '20px' }}>
                                            <span>{bank.name}</span>
                                        </Col>
                                    </Row>
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {currencyCode === CURRENCIES[3].currency && (
                <Row className="form__group">
                    <Col span={8} align="start">
                        <span className="add-bank-card__form__label">
                            {getTranslation('Province')}
                            <span style={{ color: 'red' }}>*</span>:
                        </span>
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            name="province"
                            rules={[
                                {
                                    message: getTranslation('Please fill in this field!'),
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            )}

            <Row className="form__group">
                <Col span={8} align="start">
                    <span className="add-bank-card__form__label">{getTranslation('City')}:</span>
                </Col>
                <Col span={16}>
                    <Form.Item name="city">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={8} align="start">
                    <span className="add-bank-card__form__label">{getTranslation('Area')}:</span>
                </Col>
                <Col span={16}>
                    <Form.Item name="area">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={8} align="start">
                    <span className="add-bank-card__form__label">{getTranslation('Branch')}:</span>
                </Col>
                <Col span={16}>
                    <Form.Item name="branchName">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={8} align="start">
                    <span className="add-bank-card__form__label">
                        {getTranslation('Bank Card Number')}
                        <span style={{ color: 'red' }}>*</span>:
                    </span>
                </Col>
                <Col span={16}>
                    <Form.Item
                        name="bankCardNo"
                        rules={[
                            {
                                message: getTranslation('Please fill in this field!'),
                                required: true,
                            },
                            ({ getFieldValue }) => ({
                                validator: lengthValidator(
                                    getFieldValue('bankCardNo'),
                                    6,
                                    19,
                                    getTranslation('Bank card number must be 6-19 digits!'),
                                ),
                            }),
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={8} align="start">
                    <span className="add-bank-card__form__label">
                        {getTranslation('Confirm Bank Card Number')}
                        <span style={{ color: 'red' }}>*</span>:
                    </span>
                </Col>
                <Col span={16}>
                    <Form.Item
                        name="confirmBankCardNo"
                        dependencies={['bankCardNo']}
                        rules={[
                            {
                                message: getTranslation('Please fill in this field!'),
                                required: true,
                            },
                            ({ getFieldValue }) => ({
                                validator: lengthValidator(
                                    getFieldValue('bankCardNo'),
                                    6,
                                    19,
                                    getTranslation('Bank card number must be 6-19 digits!'),
                                ),
                            }),
                            ({ getFieldValue }) => ({
                                validator: equalityValidator(
                                    getFieldValue('bankCardNo'),
                                    getTranslation('Bank Card No. inconsistent!'),
                                ),
                            }),
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={8} align="start">
                    <span className="add-bank-card__form__label">
                        {getTranslation('Card Holder Name')}
                    </span>
                </Col>
                <Col span={16}>
                    <Form.Item name="cardHolderName">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="end" className="form__group">
                <ButtonGreen htmlType="submit" loading={cardAdding}>
                    {getTranslation('Submit')}
                </ButtonGreen>
                <Button onClick={onCancel}>{getTranslation('Cancel')}</Button>
            </Row>
        </Form>
    );
};
export default AddBankCardForm;
