import React from 'react';

import { Form } from 'antd';
import {
    activatedMerchantSetupModalClose,
    activatedMerchantSetupModalOpen,
    merchantDropFilters,
    merchantFilterSet,
} from './activeMerchantActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { useGetActivatedMerchantAsyncEndpoint } from '../../../../services/merchantService';
import { useStore } from '../../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import ActiveMerchantResultsTable from './table/ActiveMerchantResultsTable';
import MerchantSetupFilters from '../MerchantSetupFilters';
import MerchantSetupModal from './MerchantSetupModal';

const ActiveMerchant = () => {
    const [state, dispatch] = useStore();
    const activeMerchant = getStateProp(state, 'payments.activeMerchant', {});
    const modalData = getStateProp(activeMerchant, 'modal');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => merchantFilterSet(dispatch, { filter, value });

    const onOpenModal = () => activatedMerchantSetupModalOpen(dispatch);

    const onCloseModal = () => activatedMerchantSetupModalClose(dispatch);

    const fetchAll = () => {
        merchantDropFilters(dispatch);
        handleGetActivatedMerchants();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetActivatedMerchants] = useGetActivatedMerchantAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <div>
            <Form onFinish={handleGetActivatedMerchants}>
                <MerchantSetupFilters setFilter={setFilter} merchant={activeMerchant} />
                <ActionButtons onOpenModal={onOpenModal} onDropFilters={fetchAll} />
            </Form>
            <ActiveMerchantResultsTable loading={loading} />
            {modalData.visible && (
                <MerchantSetupModal modalData={modalData} onCancel={onCloseModal} />
            )}
        </div>
    );
};

export default ActiveMerchant;
