import React from 'react';

import { Row } from 'antd';

import { ButtonLightBlue } from '../../../common/components/buttons/Buttons';
import { DropButton, SearchButton } from '../../../common/components/buttons';
import { getTranslation } from '../../../common/helpers/locale';

const ActionButtons = ({ openModal, loading, onDropFilters }) => {
    return (
        <Row align="middle" justify="end" className="action-buttons__container">
            <SearchButton htmlType="submit" />
            <DropButton onDropFilters={onDropFilters} />
            <ButtonLightBlue
                label={getTranslation('Manage Banner')}
                onClick={openModal}
                loading={loading}
            />
        </Row>
    );
};

export default ActionButtons;
