import React from 'react';

import { Card, Form, message } from 'antd';

import { API } from '../../../common/constants/api';
import { dropFilters } from './bankAccountStatementActions';
import { getColumns } from './table/BankAccountStatementTableMeta';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useExportAsyncEndpoint } from '../../../services/hooks/useExport';
import { useGetBankAccountStatementAsyncEndpoint } from '../../../services/bankAccountService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import BankAccountStatementFilters from './BankAccountStatementFilters';
import BankAccountStatementResultTable from './table/BankAccountStatementResultTable';

const { PAYMENTS } = API;

const BankAccountStatement = () => {
    const [state, dispatch] = useStore();
    const bankAccountStatement = getStateProp(state, 'payments.bankAccountStatement', {});
    const bankCardNo = getStateProp(bankAccountStatement, 'filters.bankCardNo');
    const transactionNo = getStateProp(bankAccountStatement, 'filters.transactionNo');
    const filters = getStateProp(bankAccountStatement, 'filters');
    const { totalRecordCount } = getStateProp(bankAccountStatement, 'paging', {});

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        dropFilters(dispatch);
        handleGetBankAccountStatement();
    };

    const searchBankAccountStatement = () => {
        bankCardNo || transactionNo
            ? handleGetBankAccountStatement()
            : message.warning(getTranslation('Please at least input bank card Or transaction no.'));
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetBankAccountStatement] = useGetBankAccountStatementAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        dataMeta: getColumns(),
        filters,
        innerListKey: 'paymentBankCardAccountStatementList',
        method: 'GET',
        subject: getTranslation('Bank Account Statement'),
        totalRecordCount,
        url: `${PAYMENTS.BANK_ACCOUNT.STATEMENT}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Bank Account Statement')}>
            <Form onFinish={searchBankAccountStatement}>
                <BankAccountStatementFilters />
                <ActionButtons
                    onExport={handleExportAction}
                    onSearch={searchBankAccountStatement}
                    onDropFilters={fetchAll}
                />
            </Form>

            <BankAccountStatementResultTable loading={loading} />
        </Card>
    );
};

export default BankAccountStatement;
