import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Input, Row } from 'antd';

import { ButtonGreen } from '../../../../../../common/components/buttons/Buttons';
import { MODIFICATION_TYPES } from '../../../../constants';
import { getTranslation } from '../../../../../../common/helpers/locale';
import { useCreateModificationAsyncEndpoint } from '../../../../../../services/playerService';

const BankCardHolderNameEditForm = ({ userId, bankCardInfo, onCancel }) => {
    const [form] = Form.useForm();
    const { resetFields } = form;
    const [formValues, setFormValues] = useState({});

    // ============================================
    // METHODS
    // ============================================
    const submitForm = (values) => {
        setFormValues(values);
        handleCreateModification();
    };

    // ============================================
    // HOOKS
    // ============================================
    const [
        loading,
        handleCreateModification,
        modificationCreated,
    ] = useCreateModificationAsyncEndpoint({
        bankCard: bankCardInfo,
        currentInfo: bankCardInfo.cardHolderName,
        newInfo: formValues.newCardHolderName,
        requesterRemark: formValues.remark,
        type: MODIFICATION_TYPES.CARD_HOLDER_NAME,
        userId,
    });

    useEffect(() => {
        if (modificationCreated) {
            onCancel();
            resetFields();
        }
        // eslint-disable-next-line
    }, [modificationCreated, resetFields]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form
            form={form}
            name="card-holder-name-edit-form"
            onFinish={submitForm}
            style={{ width: 430 }}
            initialValues={{ cardHolderName: bankCardInfo.cardHolderName }}
        >
            <Row className="form__group">
                <Col span={6}>
                    <span>{getTranslation('Card Holder Name')}:</span>
                </Col>
                <Col span={18}>
                    <Form.Item name="cardHolderName">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={6}>
                    <span>{getTranslation('New Card Holder Name')}:</span>
                </Col>
                <Col span={18}>
                    <Form.Item
                        name="newCardHolderName"
                        rules={[
                            {
                                message: getTranslation('Please input new card holder name!'),
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={6}>
                    <span>
                        {getTranslation('Remark')}
                        <span style={{ color: 'red' }}>*</span>:
                    </span>
                </Col>
                <Col span={18}>
                    <Form.Item
                        name="remark"
                        rules={[
                            {
                                message: getTranslation('Please fill in this field!'),
                                required: true,
                            },
                        ]}
                    >
                        <Input.TextArea rows={3} />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="end" className="form__group">
                <ButtonGreen htmlType="submit" loading={loading}>
                    {getTranslation('Submit')}
                </ButtonGreen>
                <Button onClick={onCancel}>{getTranslation('Cancel')}</Button>
            </Row>
        </Form>
    );
};

export default BankCardHolderNameEditForm;
