import React from 'react';

import { Button, Dropdown, Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import _ from 'lodash';

import './OperationsButton.less';
import { E_WALLET_MODAL_TYPES } from '../../constants';
import { getStateProp } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import { openEWalletModal } from '../../playerDetailsActions';
import { useStore } from '../../../../../store/StateProvider';

const OperationsButton = ({ dispatch, eWallet }) => {
    const [state] = useStore();

    const eWalletAccounts = getStateProp(
        state,
        'players.playerDetails.playerInfo.monetary.eWalletAccounts',
        {},
    );
    // ============================================
    // METHODS
    // ============================================

    const handleDeleteCard = () =>
        openEWalletModal(dispatch, {
            eWallet,
            modalType: E_WALLET_MODAL_TYPES.DELETE_E_WALLET,
        });

    const handleWithdrawalRequest = () =>
        openEWalletModal(dispatch, {
            eWallet,
            modalType: E_WALLET_MODAL_TYPES.WITHDRAWAL_REQUEST,
        });

    // ============================================
    // RENDER
    // ============================================

    const operationsMenu = (
        <Menu>
            {_.takeWhile(eWalletAccounts, ['status', 'Active']).length > 1 && (
                <Menu.Item key="0" onClick={handleDeleteCard}>
                    <span>{getTranslation('Delete Card')}</span>
                </Menu.Item>
            )}

            <Menu.Item key="1" onClick={handleWithdrawalRequest}>
                <span>{getTranslation('Withdrawal Request')}</span>
            </Menu.Item>
        </Menu>
    );

    return eWallet.status === 'Deleted' ? (
        <span>{`${getTranslation('Deleted By')} ${eWallet.deletedByName}`}</span>
    ) : (
        <Dropdown overlay={operationsMenu} trigger={['click']}>
            <Button className="btn__green action-btn__operations">
                {getTranslation('Operations')}
                <CaretDownOutlined />
            </Button>
        </Dropdown>
    );
};

export default OperationsButton;
