import React from 'react';

import { Card, Form, Modal, Row, message } from 'antd';
import _ from 'lodash';

import { ButtonGreen, ButtonTransparent } from '../../../../common/components/buttons/Buttons';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { REBATE_FORM_TYPE } from '../../constants';
import {
    dropRebateManagementLang,
    rebateManagementDuplicateByLanguage,
    setMultiRebateManagementInfo,
    setRebateManagementInfo,
    setRebateManagementInfoByLanguage,
    setRebateManagementLang,
} from '../rebateManagementActions';
import { editorDefaultStylingSizeSet, getStateProp } from '../../../../common/helpers/misc';
import { formValidation } from '../../helpers';
import { getTranslation } from '../../../../common/helpers/locale';
import { useStore } from '../../../../store/StateProvider';
import { useUpdateRebateManagementAsyncEndpoint } from '../../../../services/rebateService';
import RebateTabs from '../../shared/RebateTabs';

const EditRebateSetting = ({ onCancel, loading }) => {
    const [state, dispatch] = useStore();
    const selectedRebate = getStateProp(state, 'rebate.rebateManagement.selectedRebate', {});
    const details = getStateProp(selectedRebate, 'details', []);
    const selectedLanguage = _.map(details, (value) => {
        return value.languageCode;
    }).sort();

    // ============================================
    // METHOD
    // ============================================

    const onRebateInfoChange = (name, value) => {
        setRebateManagementInfo(dispatch, { name, value });
    };

    const onRebateMultiInfoChange = (payload) => {
        setMultiRebateManagementInfo(dispatch, payload);
    };

    const onContentChange = (name, value, langCode) => {
        if (name === 'imageInBase64' && value === null)
            setRebateManagementInfoByLanguage(dispatch, {
                langCode,
                name: 'imageUrl',
                value,
            });

        setRebateManagementInfoByLanguage(dispatch, {
            langCode,
            name,
            value: editorDefaultStylingSizeSet(value),
        });
    };

    const setRebateLanguage = (e) => {
        if (e.target.checked) {
            details.push({
                languageCode: e.target.value,
            });

            setRebateManagementLang(dispatch, details);
        } else {
            dropRebateManagementLang(
                dispatch,
                _.reject(details, (content) => {
                    return content.languageCode === e.target.value;
                }),
            );
        }
    };

    const submitForm = () => {
        formValidation(selectedRebate, REBATE_FORM_TYPE.EDIT) && handleUpdateRebate();
    };

    const duplicateRebateTabs = (values) => {
        const { languageCode, id, imageUrlCN, imageUrl, imageExtension, ...rest } = _.find(
            details,
            (detail) => {
                return detail.languageCode === values.from;
            },
        );

        const detailsCopyTo = _.find(details, (detail) => {
            return detail.languageCode === values.to;
        });

        const { languageCode: langCode, ...restCopyTo } = detailsCopyTo;

        if (_.every(restCopyTo, _.isEmpty)) {
            _.assign(detailsCopyTo, rest);
            rebateManagementDuplicateByLanguage(dispatch, details);
            message.success('Copied!');
        } else {
            Modal.confirm({
                content: getTranslation(
                    'Destination copy to is not empty! Are you sure want to copy ?',
                ),
                icon: <ExclamationCircleOutlined />,
                onCancel() {
                    return false;
                },
                onOk() {
                    _.assign(detailsCopyTo, rest);
                    rebateManagementDuplicateByLanguage(dispatch, details);
                    message.success('Copied!');
                },
                title: getTranslation('Are you sure want to copy ?'),
            });
        }
    };

    const [updating, handleUpdateRebate] = useUpdateRebateManagementAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Edit Rebate Setting')} loading={loading}>
            <Form name="update-rebate" className="update-rebate__form" onFinish={submitForm}>
                <RebateTabs
                    setRebateLanguage={setRebateLanguage}
                    selectedLangs={selectedLanguage}
                    rebateInfo={selectedRebate}
                    isEdit={true}
                    onRebateInfoChange={onRebateInfoChange}
                    onRebateContentChange={onContentChange}
                    onDuplicateRebate={duplicateRebateTabs}
                    onRebateMultiInfoChange={onRebateMultiInfoChange}
                />
                <Row className="form__footer-row" justify="end">
                    <ButtonGreen
                        label={getTranslation('Update')}
                        className="update-rebate__form__submit-button"
                        htmlType="submit"
                        style={{ marginRight: '5px' }}
                        loading={updating}
                    />

                    <ButtonTransparent
                        label={getTranslation('Cancel')}
                        className="update-rebate__form__reset-button"
                        onClick={onCancel}
                    />
                </Row>
            </Form>
        </Card>
    );
};

export default EditRebateSetting;
