import React from 'react';

import { Input } from 'antd';

const OperatorFilter = ({ value, onFilterChange, name, placeholder }) => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <Input
            value={value}
            onChange={(e) => onFilterChange(name, e.target.value)}
            placeholder={placeholder}
            className="filter"
        />
    );
};

export default OperatorFilter;
