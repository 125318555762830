export const ACTIONS = {
    AFFILIATE_GENERATOR: 'AFFILIATE_GENERATOR',
    AFFILIATE_GENERATOR_FAILURE: 'AFFILIATE_GENERATOR_FAILURE',
    AFFILIATE_GENERATOR_HISTORY_GET: 'AFFILIATE_GENERATOR_HISTORY_GET',
    AFFILIATE_GENERATOR_HISTORY_GET_FAILURE: 'AFFILIATE_GENERATOR_HISTORY_GET_FAILURE',
    AFFILIATE_GENERATOR_HISTORY_GET_SUCCESS: 'AFFILIATE_GENERATOR_HISTORY_GET_SUCCESS',
    AFFILIATE_GENERATOR_SUCCESS: 'AFFILIATE_GENERATOR_SUCCESS',
    CLOSE_TOTAL_RECORDS_GENERATED_MODAL: 'CLOSE_TOTAL_RECORDS_GENERATED_MODAL',
    FILTERS_DROP_ALL: 'AFFILIATE_GENERATOR_FILTERS_DROP_ALL',
    FILTER_SET: 'AFFILIATE_GENERATOR_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'AFFILIATE_GENERATOR_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'AFFILIATE_GENERATOR_TABLE_PAGE_SIZE_CHANGE',
    },
};

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const affiliateGeneratorFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const affiliateGeneratorDropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const affiliateGeneratorHistoryGet = (dispatch) =>
    dispatch({
        type: ACTIONS.AFFILIATE_GENERATOR_HISTORY_GET,
    });

export const affiliateGeneratorHistoryGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.AFFILIATE_GENERATOR_HISTORY_GET_SUCCESS,
    });

export const affiliateGeneratorHistoryGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.AFFILIATE_GENERATOR_HISTORY_GET_FAILURE,
    });

export const affiliateGenerator = (dispatch) =>
    dispatch({
        type: ACTIONS.AFFILIATE_GENERATOR,
    });

export const affiliateGeneratorSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.AFFILIATE_GENERATOR_SUCCESS,
    });

export const affiliateGeneratorFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.AFFILIATE_GENERATOR_FAILURE,
    });

export const closeTotalRecordsGeneratedModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CLOSE_TOTAL_RECORDS_GENERATED_MODAL,
    });
