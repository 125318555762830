import { ACTIONS } from './referrerReportActions';
import {
    REFEREE_DEPOSIT_CONDITION,
    REFEREE_WINLOSS_CONDITION,
    REFEREE_WITHDRAWAL_CONDITION,
    REFERRAL_CONVERSTION_RATE_CONDITION,
    REWARDS_EARNED_CONDITION,
    WEEKLY_REFEREE_CONDITION,
} from '../constants';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function referrerReportReducer(
    referrerReport = {
        filters: {
            conversionRate: { condition: REFERRAL_CONVERSTION_RATE_CONDITION.GREATER.value },
            refereeDeposit: { condition: REFEREE_DEPOSIT_CONDITION.GREATER.value },
            refereeWinLoss: { condition: REFEREE_WINLOSS_CONDITION.GREATER.value },
            refereeWithdraw: { condition: REFEREE_WITHDRAWAL_CONDITION.GREATER.value },
            rewardsEarned: { condition: REWARDS_EARNED_CONDITION.GREATER.value },
            totalRefereeWeekly: { condition: WEEKLY_REFEREE_CONDITION.GREATER.value },
        },
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        refereeReport: {
            paging: {
                pageNo: TABLE_PAGE_NO_DEFAULT,
                pageSize: TABLE_PAGE_SIZE_DEFAULT,
                totalRecordCount: 0,
            },
        },
        resetPageNo: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.FILTER_SET:
            return {
                ...referrerReport,
                filters: {
                    ...referrerReport.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };
        case ACTIONS.FILTERS_DROP_ALL:
            return {
                ...referrerReport,
                filters: {
                    conversionRate: {
                        condition: REFERRAL_CONVERSTION_RATE_CONDITION.GREATER.value,
                    },
                    refereeDeposit: { condition: REFEREE_DEPOSIT_CONDITION.GREATER.value },
                    refereeWinLoss: { condition: REFEREE_WINLOSS_CONDITION.GREATER.value },
                    refereeWithdraw: { condition: REFEREE_WITHDRAWAL_CONDITION.GREATER.value },
                    rewardsEarned: { condition: REWARDS_EARNED_CONDITION.GREATER.value },
                    totalRefereeWeekly: { condition: WEEKLY_REFEREE_CONDITION.GREATER.value },
                },
                resetPageNo: true,
            };

        case ACTIONS.REFERRER_REPORT_GET_SUCCESS:
            return {
                ...referrerReport,
                ...payload,
            };

        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...referrerReport,
                paging: {
                    ...referrerReport.paging,
                    pageNo:
                        payload > referrerReport.paging.pageCount
                            ? referrerReport.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...referrerReport,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.REFEREE_PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...referrerReport,
                refereeReport: {
                    ...referrerReport.refereeReport,
                    paging: {
                        ...referrerReport.refereeReport.paging,
                        pageNo:
                            payload > referrerReport.refereeReport.paging.pageCount
                                ? referrerReport.refereeReport.paging.pageCount - 1
                                : payload,
                    },
                },
            };
        case ACTIONS.REFEREE_PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_REFEREE_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...referrerReport,
                refereeReport: {
                    ...referrerReport.refereeReport,
                    paging: {
                        ...referrerReport.refereeReport.paging,
                        pageNo: 1,
                        pageSize:
                            payload < 1
                                ? TABLE_PAGE_SIZE_DEFAULT
                                : payload > MAX_REFEREE_PER_PAGE
                                ? MAX_REFEREE_PER_PAGE
                                : payload,
                    },
                },
            };

        case ACTIONS.REFERRER_REPORT_GET:
            const refereePageNo = referrerReport.resetPageNo
                ? TABLE_PAGE_NO_DEFAULT
                : referrerReport.paging.pageNo;
            return {
                ...referrerReport,
                paging: {
                    ...referrerReport.paging,
                    pageNo: refereePageNo,
                },
                resetPageNo: false,
            };

        case ACTIONS.REFEREE_REPORT_GET_SUCCESS:
            return {
                ...referrerReport,
                refereeReport: {
                    list: payload.list,
                    paging: {
                        ...referrerReport.refereeReport.paging,
                        ...payload.paging,
                    },
                },
            };

        default:
            return referrerReport;
    }
}
