import { ACTIONS } from './ewalletActions';

export default function ewalletReducer(ewallet = {}, { type, payload = null }) {
    switch (type) {
        case ACTIONS.EWALLET_GET_SUCCESS:
            return payload;
        case ACTIONS.EWALLET_CLEAR:
            return null;
        case ACTIONS.EWALLET_GET:
        default:
            return ewallet;
    }
}
