import { clearError } from '../common/actions/errorActions';
import { useCallback, useEffect, useState } from 'react';

import { API } from '../common/constants/api';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import { getCurrentUser } from '../common/helpers/api';
import { useDispatch, useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import {
    vipHistoryGet,
    vipHistoryGetFailure,
    vipHistoryGetSuccess,
} from '../components/vip/vip-history/vipHistoryActions';
import {
    vipRecordsGet,
    vipRecordsGetFailure,
    vipRecordsGetSuccess,
} from '../components/transaction-history/vip-records/vipRecordsActions';
import {
    vipSetupGet,
    vipSetupGetFailure,
    vipSetupGetSuccess,
    vipSetupUpdate,
    vipSetupUpdateFailure,
    vipSetupUpdateSuccess,
} from '../components/vip/vip-setup/vipSetupActions';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, VIP, TRANSACTION_HISTORY } = API;

export function useGetVipSetupByCurrencyAsyncEndpoint(currency) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetVipSetup = useCallback(() => {
        vipSetupGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'POST',
        options: {
            data: {
                currency,
            },
        },
        trigger,
        url: `${VERSION.V1}${VIP.SETUP.LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            vipSetupGetSuccess(dispatch, {
                list: response.data.data.levelSettings,
                setups: response.data.data.setups,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        handleGetVipSetup();
    }, [handleGetVipSetup, currency]);

    const errorMsg = useError(error, vipSetupGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetVipSetup];
}

export function useUpdateVipSetupAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { list, setups } = getStateProp(state, 'vip.vipSetup', {});

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateVipSetup = useCallback(() => {
        vipSetupUpdate(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                settingList: list,
                setups,
                updatedByUserId: getCurrentUser().id,
            },
        },
        trigger,
        url: `${VERSION.V1}${VIP.SETUP.UPDATE}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            vipSetupUpdateSuccess(dispatch, {
                list: response.data.data,
            });
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, vipSetupUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateVipSetup, response];
}

export function useGetVipRecordsAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { vipRecords } = getStateProp(state, 'transactionHistory');
    const { pageNo, pageSize } = getStateProp(vipRecords, 'paging', {});
    const filters = getStateProp(vipRecords, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetVipRecords = useCallback(() => {
        vipRecordsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${TRANSACTION_HISTORY.VIP_RECORDS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            vipRecordsGetSuccess(dispatch, {
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        handleGetVipRecords();
    }, [handleGetVipRecords, pageNo, pageSize]);

    const errorMsg = useError(error, vipRecordsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetVipRecords];
}

export function useGetVipHistoryAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const vipHistory = getStateProp(state, 'vip.vipHistory', {});
    const { pageNo, pageSize } = getStateProp(vipHistory, 'paging', {});
    const filters = getStateProp(vipHistory, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetVipHistory = useCallback(() => {
        vipHistoryGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'POST',
        options: {
            data: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${VIP.HISTORY}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            vipHistoryGetSuccess(dispatch, {
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        handleGetVipHistory();
    }, [handleGetVipHistory, pageNo, pageSize]);

    const errorMsg = useError(error, vipHistoryGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetVipHistory];
}
