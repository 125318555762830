import React from 'react';

import _ from 'lodash';

import { BANKING_METHODS } from '../constants';
import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize, selectRecord } from '../depositsActions';
import { getStatusColor, sortTableByDataType } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import BankCardTableView from './BankCardTableView';
import DepositReceiptView from './DepositReceiptView';
import VerifyButton from '../verification/VerifyButton';

export const getColumns = (dispatch, selectedColumns) => {
    const columns = [
        {
            dataIndex: 'requestedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Request Time'),
        },
        {
            dataIndex: 'bankingTransactedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Transaction time'),
        },
        {
            dataIndex: 'transactionNo',
            render: (value) => <span style={{ wordBreak: 'break-all' }}>{value}</span>,
            title: getTranslation('Task Transaction Number'),
        },
        {
            dataIndex: 'playerUserName',
            title: getTranslation('Username'),
        },
        {
            dataIndex: 'realName',
            title: getTranslation('Full Name'),
        },
        {
            dataIndex: 'memberLevel',
            title: getTranslation('Member Level'),
        },
        {
            dataIndex: 'trustLevel',
            title: getTranslation('Trust Level'),
        },
        {
            dataIndex: 'amount',
            title: getTranslation('Request Amount'),
        },
        {
            dataIndex: 'currencyCode',
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'transactionMethod',
            title: getTranslation('Transaction Method'),
        },
        {
            dataIndex: 'bankName',
            title: getTranslation('Bank'),
        },
        {
            dataIndex: 'cardHolderName',
            title: getTranslation('Bank Account Name'),
        },
        {
            dataIndex: 'bankCardNo',
            render: (value) => <span style={{ wordBreak: 'break-all' }}>{value}</span>,
            title: getTranslation('Bank Account Number'),
        },
        {
            dataIndex: 'requesterUserName',
            title: getTranslation('Applicant'),
        },
        {
            dataIndex: 'orderStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>
                    {getTranslation(value).toLocaleUpperCase()}
                </span>
            ),
            title: getTranslation('Status'),
        },
        {
            dataIndex: 'verifiedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Process Time'),
        },
        {
            dataIndex: 'refCode',
            title: getTranslation('Ref Code'),
        },
        {
            dataIndex: 'bankingMethod',
            render: (value) => getTranslation(BANKING_METHODS[value]),
            title: getTranslation('Banking  Method'),
        },
        {
            dataIndex: 'playerId',
            render: (value, record) => <BankCardTableView playerId={value} />,
            title: getTranslation('Bank Cards'),
        },
        {
            dataIndex: 'transactionStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>
                    {getTranslation(value).toLocaleUpperCase()}
                </span>
            ),
            title: getTranslation('Transaction Status'),
        },
        {
            dataIndex: 'imageURL',
            render: (value) => <DepositReceiptView receipt={value} />,
            title: getTranslation('Bank Slip'),
        },
        {
            dataIndex: 'operation',
            dataType: 'operation',
            render: (text, record) => <VerifyButton transaction={record} dispatch={dispatch} />,
            title: getTranslation('Operation'),
            width: 80,
        },
    ];

    return columns
        .filter((column) => _.map(selectedColumns, 'key').includes(column.dataIndex))
        .map((colProps) => ({
            ...colProps,
            sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
        }));
};

export const getBankCardColumns = (selectedColumns) => {
    const columns = [
        {
            dataIndex: 'bankName',
            key: 'bankName',
            title: getTranslation('Bank Name'),
        },
        {
            dataIndex: 'cardHolderName',
            key: 'cardHolderName',
            title: getTranslation('Card Holder Name'),
        },
        {
            dataIndex: 'bankCardNo',
            key: 'bankCardNo',
            title: getTranslation('Card No.'),
        },
    ];

    return columns
        .filter((column) => _.map(selectedColumns, 'key').includes(column.dataIndex))
        .map((colProps) => ({
            ...colProps,
            sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
        }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

export const getRowSelectionProps = (dispatch, selectedTransactionId) => ({
    columnWidth: 30,
    fixed: true,
    getCheckboxProps: (record) => ({
        disabled: record.transactionStatus === 'Success' || record.transactionStatus === 'Rejected',
    }),
    onChange: onSelectRecord(dispatch),
    selectedRowKeys: selectedTransactionId,
});

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);

const onSelectRecord = (dispatch) => (rowKeys) => selectRecord(dispatch, rowKeys);

export const depositRecordsExport = ({ onlyMeta }) => {
    if (onlyMeta) {
        return {
            operation: [
                {
                    dataIndex: 'verifierRealName',
                    title: getTranslation('Operation'),
                },
            ],
        };
    }

    let messageArray = [];
    return messageArray;
};
