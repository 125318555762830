import React from 'react';

import { Table } from 'antd';

import {
    getColumns,
    getPaginationOptions,
    getRowSelectionProps,
} from './CryptocurrencyDepositsTableMeta';
import { getStateProp } from '../../../../../common/helpers/misc';
import { useStore } from '../../../../../store/StateProvider';

const CryptocurrencyDepositResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const selectedTransactionId = getStateProp(
        state,
        'payments.cryptoDeposits.selectedTransactionId',
        [],
    );

    const tableData = getStateProp(state, 'payments.cryptoDeposits.list', []);
    const paging = getStateProp(state, 'payments.cryptoDeposits.paging', {});

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            dataSource={tableData}
            columns={getColumns(dispatch)}
            pagination={getPaginationOptions(paging, dispatch)}
            rowKey={(record) => record.transactionId}
            rowSelection={getRowSelectionProps(dispatch, selectedTransactionId)}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            loading={loading}
        />
    );
};

export default CryptocurrencyDepositResultsTable;
