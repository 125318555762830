export const ACTIONS = {
    ADD_BANK_CARD: 'ADD_BANK_CARD',
    ADD_BANK_CARD_FAILURE: 'ADD_BANK_CARD_FAILURE',
    ADD_BANK_CARD_SUCCESS: 'ADD_BANK_CARD_SUCCESS',
    ADD_E_WALLET: 'ADD_E_WALLET',
    ADD_E_WALLET_FAILURE: 'ADD_E_WALLET_FAILURE',
    ADD_E_WALLET_SUCCESS: 'ADD_E_WALLET_SUCCESS',
    BANK_CARDS_MODAL_CLOSE: 'BANK_CARDS_MODAL_CLOSE',
    BANK_CARDS_MODAL_OPEN: 'BANK_CARDS_MODAL_OPEN',
    DELETE_BANK_CARD: 'DELETE_BANK_CARD',
    DELETE_BANK_CARD_FAILURE: 'DELETE_BANK_CARD_FAILURE',
    DELETE_BANK_CARD_SUCCESS: 'DELETE_BANK_CARD_SUCCESS',
    DELETE_E_WALLET: 'DELETE_E_WALLET',
    DELETE_E_WALLET_FAILURE: 'DELETE_E_WALLET_FAILURE',
    DELETE_E_WALLET_SUCCESS: 'DELETE_E_WALLET_SUCCESS',
    E_WALLET_MODAL_CLOSE: 'E_WALLET_MODAL_CLOSE',
    E_WALLET_MODAL_OPEN: 'E_WALLET_MODAL_OPEN',
    E_WALLET_WITHDRAWAL_REQUEST: 'E_WALLET_WITHDRAWAL_REQUEST',
    E_WALLET_WITHDRAWAL_REQUEST_FAILURE: 'E_WALLET_WITHDRAWAL_REQUEST_FAILURE',
    E_WALLET_WITHDRAWAL_REQUEST_SUCCESS: 'E_WALLET_WITHDRAWAL_REQUEST_SUCCESS',
    HISTORY_MESSAGES: {
        GET: 'HISTORY_MESSAGES_GET',
        GET_FAILURE: 'HISTORY_MESSAGES_GET_FAILURE',
        GET_SUCCESS: 'HISTORY_MESSAGES_GET_SUCCESS',
        PAGINATION: {
            TABLE_PAGE_CHANGE: 'HISTORY_MESSAGES_TABLE_PAGE_CHANGE',
            TABLE_PAGE_SIZE_CHANGE: 'HISTORY_MESSAGES_TABLE_PAGE_SIZE_CHANGE',
        },
    },
    PLAYER_GET_BY_ID: 'PLAYER_GET_BY_ID',
    PLAYER_GET_BY_ID_FAILURE: 'PLAYER_GET_BY_ID_FAILURE',
    PLAYER_GET_BY_ID_SUCCESS: 'PLAYER_GET_BY_ID_SUCCESS',
    UPDATE_PLAYER_INFO: 'UPDATE_PLAYER_INFO',
    UPDATE_PLAYER_INFO_FAILURE: 'UPDATE_PLAYER_INFO_FAILURE',
    UPDATE_PLAYER_INFO_SUCCESS: 'UPDATE_PLAYER_INFO_SUCCESS',
    USER_SESSIONS: {
        GET_BY_USER_ID: 'USER_SESSIONS_GET_BY_USER_ID',
        GET_BY_USER_ID_FAILURE: 'USER_SESSIONS_GET_BY_USER_ID_FAILURE',
        GET_BY_USER_ID_SUCCESS: 'USER_SESSIONS_GET_BY_USER_ID_SUCCESS',
        PAGINATION: {
            TABLE_PAGE_CHANGE: 'USER_SESSIONS_TABLE_PAGE_CHANGE',
            TABLE_PAGE_SIZE_CHANGE: 'USER_SESSIONS_TABLE_PAGE_SIZE_CHANGE',
        },
    },
    WITHDRAWAL_REQUEST: 'WITHDRAWAL_REQUEST',
    WITHDRAWAL_REQUEST_FAILURE: 'WITHDRAWAL_REQUEST_FAILURE',
    WITHDRAWAL_REQUEST_SUCCESS: 'WITHDRAWAL_REQUEST_SUCCESS',
};

export const playerGetById = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYER_GET_BY_ID,
    });

export const playerGetByIdSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_GET_BY_ID_SUCCESS,
    });

export const playerGetByIdFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_GET_BY_ID_FAILURE,
    });

export const updatePlayerInfo = (dispatch) =>
    dispatch({
        type: ACTIONS.UPDATE_PLAYER_INFO,
    });

export const updatePlayerInfoSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.UPDATE_PLAYER_INFO_SUCCESS,
    });

export const updatePlayerInfoFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.UPDATE_PLAYER_INFO_FAILURE,
    });

export const userSessionsGetByUserId = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_SESSIONS.GET_BY_USER_ID,
    });

export const userSessionsGetByUserIdSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_SESSIONS.GET_BY_USER_ID_SUCCESS,
    });

export const userSessionsGetByUserIdFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_SESSIONS.GET_BY_USER_ID_FAILURE,
    });

export const changeSessionsPageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_SESSIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changeSessionsPageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_SESSIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const changeMessagesPageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.HISTORY_MESSAGES.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changeMessagesPageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.HISTORY_MESSAGES.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const openBankCardsModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANK_CARDS_MODAL_OPEN,
    });

export const closeBankCardsModal = (dispatch) =>
    dispatch({
        type: ACTIONS.BANK_CARDS_MODAL_CLOSE,
    });

export const addBankCard = (dispatch) =>
    dispatch({
        type: ACTIONS.ADD_BANK_CARD,
    });

export const addBankCardSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.ADD_BANK_CARD_SUCCESS,
    });

export const addBankCardFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ADD_BANK_CARD_FAILURE,
    });

export const deleteBankCard = (dispatch) =>
    dispatch({
        type: ACTIONS.DELETE_BANK_CARD,
    });

export const deleteBankCardSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.DELETE_BANK_CARD_SUCCESS,
    });

export const deleteBankCardFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DELETE_BANK_CARD_FAILURE,
    });

export const withdrawalRequest = (dispatch) =>
    dispatch({
        type: ACTIONS.WITHDRAWAL_REQUEST,
    });

export const withdrawalRequestSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.WITHDRAWAL_REQUEST_SUCCESS,
    });

export const withdrawalRequestFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WITHDRAWAL_REQUEST_FAILURE,
    });

export const openEWalletModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.E_WALLET_MODAL_OPEN,
    });

export const closeEWalletModal = (dispatch) =>
    dispatch({
        type: ACTIONS.E_WALLET_MODAL_CLOSE,
    });

export const addEWallet = (dispatch) =>
    dispatch({
        type: ACTIONS.ADD_E_WALLET,
    });

export const addEWalletSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.ADD_E_WALLET_SUCCESS,
    });

export const addEWalletFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ADD_E_WALLET_FAILURE,
    });

export const deleteEWallet = (dispatch) =>
    dispatch({
        type: ACTIONS.DELETE_E_WALLET,
    });

export const deleteEWalletSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.DELETE_E_WALLET_SUCCESS,
    });

export const deleteEWalletFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DELETE_E_WALLET_FAILURE,
    });

export const eWalletWithdrawalRequest = (dispatch) =>
    dispatch({
        type: ACTIONS.E_WALLET_WITHDRAWAL_REQUEST,
    });

export const eWalletWithdrawalRequestSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.E_WALLET_WITHDRAWAL_REQUEST_SUCCESS,
    });

export const eWalletWithdrawalRequestFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.E_WALLET_WITHDRAWAL_REQUEST_FAILURE,
    });
