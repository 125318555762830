import React, { useEffect, useState } from 'react';

import { Card, Divider, Row, Switch, Tabs, message } from 'antd';
import _ from 'lodash';

import { ButtonGreen, ButtonTransparent } from '../../../common/components/buttons/Buttons';
import { CURRENCIES } from '../../../common/constants/localization';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    useGetCurrencySettingAsyncEndpoint,
    useGetCurrencySettingFiltersOperationAsyncEndpoint,
    useUpdateCurrencySettingAsyncEndpoint,
} from '../../../services/adminService';
import { useStore } from '../../../store/StateProvider';
import AddCurrencySettingModal from './modal/AddCurrencySettingModal';
import CurrencySettingFilters from './CurrencySettingFilters';
import CurrencySettingResultTable from './table/CurrenySettingResultTable';

const CurrencySetting = () => {
    const [state] = useStore();
    const [activeTab, setActiveTab] = useState('THB');
    const [isEditMode, setEditMode] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const { effectiveDate } = getStateProp(state, 'administration.currencySetting.filters', {});
    const tableData = getStateProp(state, 'administration.currencySetting.settingsList', []);

    // ============================================
    // METHODS
    // ============================================

    const handleTabClick = (key) => {
        if (isEditMode) {
            message.warning('Please off the edit mode!');
        } else {
            setActiveTab(key);
        }
    };

    const resetForm = () => handleGetCurrencySetting();

    const formSubmit = () => {
        handleUpdateCurrencySetting();
    };

    const onModalClose = () => setModalVisible(false);

    // ============================================
    // HOOKS
    // ============================================

    const [
        filterListLoading,
        handleGetCurrencySettingFilterList,
    ] = useGetCurrencySettingFiltersOperationAsyncEndpoint();

    const [loading, handleGetCurrencySetting] = useGetCurrencySettingAsyncEndpoint({
        baseCurrency: activeTab,
        effectiveDate,
    });

    const [updating, handleUpdateCurrencySetting, updated] = useUpdateCurrencySettingAsyncEndpoint(
        {
            effectiveDate: effectiveDate,
            exchangeRates: tableData,
        },
        false,
    );

    useEffect(() => {
        if (updated) {
            handleGetCurrencySettingFilterList();
            setEditMode(false);
        }
    }, [handleGetCurrencySettingFilterList, updated]);
    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Currency Settings')}>
            <CurrencySettingFilters
                setModalVisible={setModalVisible}
                filterListLoading={filterListLoading}
            />

            <Tabs
                style={{ marginTop: '15px' }}
                type="card"
                onTabClick={handleTabClick}
                activeKey={activeTab}
                tabBarExtraContent={
                    <Switch
                        onChange={(value) => setEditMode(value)}
                        checkedChildren="Edit"
                        unCheckedChildren="Edit"
                        checked={isEditMode}
                    />
                }
            >
                {_.map(CURRENCIES, (currency) => (
                    <Tabs.TabPane tab={currency.currency} key={currency.currency}>
                        <CurrencySettingResultTable
                            loading={loading}
                            isEditMode={isEditMode}
                            tableData={tableData}
                            isAddNewRate={false}
                        />
                    </Tabs.TabPane>
                ))}
            </Tabs>
            {isEditMode && (
                <>
                    <Divider />

                    <Row justify="end" align="middle" className="action-buttons__container">
                        <ButtonGreen
                            label={getTranslation('Submit')}
                            loading={updating}
                            onClick={formSubmit}
                        />
                        <ButtonTransparent label={getTranslation('Cancel')} onClick={resetForm} />
                    </Row>
                </>
            )}

            <AddCurrencySettingModal
                visible={modalVisible}
                onCancel={onModalClose}
                handleGetCurrencySettingFilterList={handleGetCurrencySettingFilterList}
            />
        </Card>
    );
};

export default CurrencySetting;
