import React from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Divider, Form, Row, Select } from 'antd';
import _ from 'lodash';

import './DuplicateTab.less';
import { ButtonGreen } from '../../../common/components/buttons/Buttons';
import { getLanguageByCode, getTranslation } from '../../../common/helpers/locale';

const DuplicateTab = ({ details, onDuplicateContent }) => {
    // ============================================
    // METHODS
    // ============================================

    const submitForm = (values) => onDuplicateContent(values);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form name="duplicate-bonus" onFinish={submitForm}>
            <Row className="duplicate-popover__content">
                <Form.Item name="from">
                    <Select className="duplicate-popover__from">
                        {_.map(details, (detail) => (
                            <Select.Option key={detail.languageCode} value={detail.languageCode}>
                                {getLanguageByCode(detail.languageCode)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <ArrowRightOutlined className="duplicate-popover__arrow-icon" />
                <Form.Item name="to">
                    <Select className="duplicate-popover__to">
                        {_.map(details, (detail) => (
                            <Select.Option key={detail.languageCode} value={detail.languageCode}>
                                {getLanguageByCode(detail.languageCode)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Row>
            <Divider />
            <ButtonGreen className="duplicate-popover__action-btn" htmlType="submit">
                {getTranslation('Duplicate')}
            </ButtonGreen>
        </Form>
    );
};

export default DuplicateTab;
