import React from 'react';

import { Table } from 'antd';

import { getColumns } from './BankCardTableMeta';
import { getStateProp } from '../../../../../common/helpers/misc';
import { useStore } from '../../../../../store/StateProvider';

const BankCardResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();
    const tableData = getStateProp(
        state,
        'players.playerDetails.playerInfo.monetary.bankCards',
        [],
    );
    const userId = getStateProp(state, 'players.playerDetails.playerInfo.id', []);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            id="bank-cards-table"
            columns={getColumns(dispatch, userId)}
            pagination={false}
            dataSource={tableData}
            rowKey={(record) => record.id}
            loading={loading}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
        />
    );
};

export default BankCardResultsTable;
