import React from 'react';

import { Row } from 'antd';

import { BannerCodeFilter, BannerTypeFilter } from '../../filters';
import { availableBannerFilterSet } from '../bannerSortingActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const ActiveBannerFilters = () => {
    const [state, dispatch] = useStore();

    const { bannerSorting } = getStateProp(state, 'bonus');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => {
        if (
            filter === 'bannerType' &&
            getStateProp(bannerSorting, 'modalDetails.filters.code', '') !== ''
        ) {
            availableBannerFilterSet(dispatch, { filter: 'code', value: undefined });
        }

        availableBannerFilterSet(dispatch, { filter, value });
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <BannerTypeFilter
                onFilterChange={setFilter}
                value={getStateProp(bannerSorting, 'modalDetails.filters.bannerType')}
            />
            <BannerCodeFilter
                onFilterChange={setFilter}
                value={getStateProp(bannerSorting, 'modalDetails.filters.code', '')}
            />
        </Row>
    );
};

export default ActiveBannerFilters;
