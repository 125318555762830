import React from 'react';

import { Form } from 'antd';

import {
    deactivatedMerchantSetupModalClose,
    merchantDropFilters,
    merchantFilterSet,
} from './deactivatedMerchantActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { useGetDeactivatedMerchantAsyncEndpoint } from '../../../../services/merchantService';
import { useStore } from '../../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import DeactivatedMerchantResultsTable from './table/DeactivatedMerchantResultsTable';
import MerchantSetupFilters from '../MerchantSetupFilters';
import MerchantSetupModal from './MerchantSetupModal';

const DeactivatedMerchant = () => {
    const [state, dispatch] = useStore();
    const deactivatedMerchant = getStateProp(state, 'payments.deactivatedMerchant', {});
    const modalData = getStateProp(deactivatedMerchant, 'modal');

    // ============================================
    // METHODS
    // ============================================

    const onCloseModal = () => deactivatedMerchantSetupModalClose(dispatch);

    const setFilter = (filter, value) => merchantFilterSet(dispatch, { filter, value });

    const fetchAll = () => {
        merchantDropFilters(dispatch);
        handleGetDeactivatedMerchants();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetDeactivatedMerchants] = useGetDeactivatedMerchantAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <div>
            <Form onSubmit={handleGetDeactivatedMerchants}>
                <MerchantSetupFilters setFilter={setFilter} merchant={deactivatedMerchant} />
                <ActionButtons onDropFilters={fetchAll} />
            </Form>
            <DeactivatedMerchantResultsTable loading={loading} />
            {modalData.visible && (
                <MerchantSetupModal modalData={modalData} onCancel={onCloseModal} />
            )}
        </div>
    );
};

export default DeactivatedMerchant;
