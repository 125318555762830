import _ from 'lodash';
import intl from 'react-intl-universal';

import { COUNTRIES } from '../constants/countries';
import { CURRENCIES, LANGUAGES, SYSTEM_LANGUAGES } from '../constants/localization';
import { getCurrentLanguage, getUserCountry } from './api';
import { getStateProp } from './misc';

export const getCountryByLocale = (value) => {
    const foundCountry = _.find(COUNTRIES, (country) => value.indexOf(country.code) !== -1);
    return foundCountry || { code: 'my', name: 'Malaysia', prefix: '+60' };
};

export const getLocaleByCountry = (value) => {
    const foundLanguage = _.find(SYSTEM_LANGUAGES, (l) => l.country === value);
    return foundLanguage ? foundLanguage.value : foundLanguage;
};

export const getLocaleCodeByLocale = (value) => {
    const foundLanguage = _.find(SYSTEM_LANGUAGES, (l) => l.value === value);
    return foundLanguage ? foundLanguage.code : foundLanguage;
};

export const getSupportedLocales = () => ({
    'en-US': require('../../locales/en_US.json'),
    'zh-CN': require('../../locales/zh_CN.json'),
});

export const getBrowserLocale = () => {
    let navigatorLanguage;
    if (navigator.languages) navigatorLanguage = navigator.languages[0];
    else navigatorLanguage = navigator.language;
    return navigatorLanguage;
};

export const determineClientLanguage = () => {
    if (getCurrentLanguage()) return getCurrentLanguage();
    else return getBrowserLocale().indexOf('zh') !== -1 ? 'zh-CN' : 'en-US';
};

export const getTranslation = (sentence, params = {}) => {
    if (sentence) return intl.get(sentence, params).defaultMessage(sentence); //This is my {name} getTranslation('This is my {name}')
};

export const getPhonePrefixByCountry = (country) => {
    return _.get(
        _.find(COUNTRIES, (p) => p.code === country),
        'prefix',
        '+86',
    );
};

export const getLanguageByCode = (searchValue) => {
    const langCode = searchValue === 'th-TH' ? 'tr-TR' : searchValue;

    return _.find(LANGUAGES, (lang) => {
        return lang.value === langCode;
    }).name;
};

export const getCurrencyByCountry = (countryCode) => {
    switch (countryCode) {
        case 'cn':
            return 'CNY';
        case 'my':
            return 'MYR';
        case 'th':
            return 'THB';
        case 'id':
            return 'IDR';
        case 'vn':
            return 'VND';
        default:
            return 'USD';
    }
};

export const getCountryIdByCurrency = (currencyCode) => {
    return currencyCode
        ? _.find(CURRENCIES, (currency) => {
              return currency.currency === currencyCode;
          }).countryId
        : '';
};

export const findTitleByLanguage = (content, langCode, fieldName = 'title') => {
    if (!langCode) {
        let engArticle = _.find(content, (content) => {
            return content.languageCode === 'en-US' && content[fieldName];
        });

        return engArticle ? engArticle[fieldName] : content[0][fieldName];
    }

    const filteredContent = _.find(content, (article) => {
        if (article.languageCode === langCode) {
            return content;
        }
    });

    return getStateProp(filteredContent, fieldName, '');
};

export const isSupportedCurrencyCountry = (country) => {
    return _.includes(['cn', 'my', 'th', 'id', 'vn'], country);
};

export const getCountryCodeByCurrency = (currencyCode) => {
    return currencyCode
        ? _.find(CURRENCIES, (currency) => {
              return currency.currency === currencyCode;
          }).country
        : '';
};

export const getSupportedCountries = () => {
    const countries = getUserCountry();

    return _.every(countries, _.isEmpty)
        ? COUNTRIES
        : _.filter(COUNTRIES, (country) => _.includes(countries, country.code));
};

export const getSupportedLanguages = () => {
    const countries = getUserCountry();

    return _.every(countries, _.isEmpty)
        ? LANGUAGES
        : countries.length === 1 && !isSupportedCurrencyCountry(countries[0])
        ? [_.find(LANGUAGES, (language) => language.code === 'EN')]
        : _.filter(
              LANGUAGES,
              (language) => _.includes(countries, language.country) || language.code === 'EN',
          );
};

export const getSupportedCurrenciesByCountry = () => {
    const countries = getUserCountry();

    return _.every(countries, _.isEmpty)
        ? CURRENCIES
        : _.filter(CURRENCIES, (currency) => _.includes(countries, currency.country));
};
