import { useCallback, useEffect, useState } from 'react';

import { message } from 'antd';

import { API } from '../common/constants/api';
import { OPERATION_TIME_LIMIT, PAYMENT_ORDER_OPERATIONS } from '../common/constants/misc';
import {
    activatedMerchantGet,
    activatedMerchantGetFailure,
    activatedMerchantGetSuccess,
    activatedMerchantSetupModalClose,
    activatedMerchantUpdate,
    activatedMerchantUpdateFailure,
    activatedMerchantUpdateSuccess,
    merchantSetup,
    merchantSetupFailure,
    merchantSetupSuccess,
} from '../components/payments/merchant-setup/active-merchant/activeMerchantActions';
import { clearError } from '../common/actions/errorActions';
import {
    closeConfirmModal,
    closeVerifyModal,
    merchantDepositOperationSuccess,
    merchantDepositsGet,
    merchantDepositsGetFailure,
    merchantDepositsGetSuccess,
    merchantDepositsOperationFailure,
    performMerchantDepositOperation,
} from '../components/payments/merchants/merchantActions';
import {
    deactivatedMerchantGet,
    deactivatedMerchantGetFailure,
    deactivatedMerchantGetSuccess,
    deactivatedMerchantSetupModalClose,
    deactivatedMerchantUpdate,
    deactivatedMerchantUpdateFailure,
    deactivatedMerchantUpdateSuccess,
} from '../components/payments/merchant-setup/deactivated-merchant/deactivatedMerchantActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import {
    merchantConfiguration,
    merchantConfigurationFailure,
    merchantConfigurationGet,
    merchantConfigurationGetFailure,
    merchantConfigurationGetSuccess,
    merchantConfigurationPayServiceUpdate,
    merchantConfigurationPayServiceUpdateFailure,
    merchantConfigurationPayServiceUpdateSuccess,
    merchantConfigurationSuccess,
    onCloseConfirmModal,
} from '../components/payments/merchant-configuration/merchantConfigurationActions';
import { useNotification } from './hooks/useNotification';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, PAYMENTS } = API;

export function useGetMerchantDepositsAsyncEndpoint(isOnline) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const merchantDeposits = getStateProp(state, 'payments.merchantDeposits');
    const filters = getStateProp(merchantDeposits, 'filters', {});
    const { pageNo, pageSize, totalRecordCountNoFilter } = getStateProp(merchantDeposits, 'paging');
    const refreshTime = getStateProp(merchantDeposits, 'operation.refreshTime');
    const refreshTimePause = getStateProp(merchantDeposits, 'operation.refreshTimePause');

    // ============================================
    // METHODS
    // ============================================

    const handleGetMerchantDeposits = useCallback(() => {
        merchantDepositsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.MERCHANT_DEPOSITS.ROOT}`,
    });

    useEffect(() => {
        let timeInterval;
        if (response || isOnline)
            timeInterval = setTimeout(() => handleGetMerchantDeposits(), refreshTime * 1000);
        if (refreshTimePause) clearTimeout(timeInterval);
        return () => clearTimeout(timeInterval);
    }, [handleGetMerchantDeposits, isOnline, refreshTime, refreshTimePause, response]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);

            merchantDepositsGetSuccess(dispatch, {
                dataTotal: response.data.data[0].dataTotal,
                fetchData: handleGetMerchantDeposits,
                grandTotal: response.data.data[0].grandTotal,
                list: response.data.data[0].data,
                pageDataTotal: response.data.data[0].pageDataTotal,
                pageTotal: response.data.data[0].pageTotal,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetMerchantDeposits, response]);

    useEffect(() => {
        handleGetMerchantDeposits();
    }, [handleGetMerchantDeposits, pageNo, pageSize]);

    useNotification(
        totalRecordCountNoFilter,
        getStateProp(response, 'data.paging.totalRecordCountNoFilter', 0),
        'You have new merchant deposit record',
    );

    const errorMsg = useError(error, merchantDepositsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetMerchantDeposits];
}

export function useMerchantDepositOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();

    const merchantDeposits = getStateProp(state, 'payments.merchantDeposits');
    const refreshTable = getStateProp(merchantDeposits, 'fetchData', () => {});
    const operation = getStateProp(merchantDeposits, 'operation');
    const transactionIds = getStateProp(merchantDeposits, 'selectedTransactionId');
    const transactionId = getStateProp(operation, 'transaction.transactionId');
    const action = getStateProp(operation, 'action');
    const [timeOutId, setTimeOutId] = useState(null);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!action,
        method: 'POST',
        options: {
            data: {
                remark: '',
                transactionIds,
            },
        },
        trigger: action,
        url:
            action === 'reject'
                ? `${VERSION.V1}${PAYMENTS.MERCHANT_DEPOSITS.ROOT}/${action}`
                : `${VERSION.V1}${PAYMENTS.MERCHANT_DEPOSITS.ROOT}/${transactionId}/${action}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            merchantDepositOperationSuccess(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    useEffect(() => {
        if (response) {
            if (action === PAYMENT_ORDER_OPERATIONS.COMPLETE) {
                message.success(getTranslation('Transaction verified!'));
            } else if (action === PAYMENT_ORDER_OPERATIONS.REJECT) {
                message.success(getTranslation('Transaction rejected!'));
            }
        }
    }, [action, response]);

    useEffect(() => {
        if (response && action) {
            clearTimeout(timeOutId);
            if (action === PAYMENT_ORDER_OPERATIONS.ATTEND) {
                setTimeOutId(
                    setTimeout(() => {
                        performMerchantDepositOperation(dispatch, {
                            action: PAYMENT_ORDER_OPERATIONS.RELEASE,
                        });
                        closeVerifyModal(dispatch);
                        closeConfirmModal(dispatch);
                    }, OPERATION_TIME_LIMIT),
                );
            }
        }
    }, [response, action, dispatch, timeOutId]);

    const errorMsg = useError(error, merchantDepositsOperationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response];
}

export function useGetActivatedMerchantAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const filters = getStateProp(state, 'payments.activeMerchant.filters', {});
    const { pageNo, pageSize } = getStateProp(state, 'payments.activeMerchant.paging');

    // ============================================
    // METHODS
    // ============================================

    const handleGetActivatedMerchants = useCallback(() => {
        activatedMerchantGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                activatedStatus: 'Activated',
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.MERCHANTS.MERCHANT_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            activatedMerchantGetSuccess(dispatch, {
                fetchData: handleGetActivatedMerchants,
                list: response.data.data,
            });
        }
    }, [dispatch, handleGetActivatedMerchants, response]);

    useEffect(() => {
        handleGetActivatedMerchants();
    }, [handleGetActivatedMerchants, pageNo, pageSize]);

    const errorMsg = useError(error, activatedMerchantGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetActivatedMerchants];
}

export function useGetDeactivatedMerchantAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const filters = getStateProp(state, 'payments.deactivatedMerchant.filters', {});
    const { pageNo, pageSize } = getStateProp(state, 'payments.deactivatedMerchant.paging');

    // ============================================
    // METHODS
    // ============================================

    const handleGetDeactivatedMerchants = useCallback(() => {
        deactivatedMerchantGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                activatedStatus: 'Deactivated',
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.MERCHANTS.MERCHANT_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            deactivatedMerchantGetSuccess(dispatch, {
                fetchData: handleGetDeactivatedMerchants,
                list: response.data.data,
            });
        }
    }, [dispatch, handleGetDeactivatedMerchants, response]);

    useEffect(() => {
        handleGetDeactivatedMerchants();
    }, [handleGetDeactivatedMerchants, pageNo, pageSize]);

    const errorMsg = useError(error, deactivatedMerchantGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetDeactivatedMerchants];
}

export function useSetupMerchantAsyncEndpoint(merchantInfo) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const refreshTable = getStateProp(state, 'payments.activeMerchant.fetchData', () => {});

    // ============================================
    // METHODS
    // ============================================

    const handleAddMerchant = () => {
        setTrigger(+new Date());
        merchantSetup(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: { ...merchantInfo },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.MERCHANTS.MERCHANT_SETUP}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            merchantSetupSuccess(dispatch);
            activatedMerchantSetupModalClose(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, merchantSetupFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleAddMerchant];
}

export function useUpdateActivatedMerchantAsyncEndpoint(merchantInfo) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const refreshTable = getStateProp(state, 'payments.activeMerchant.fetchData', () => {});

    const merchantDetails = getStateProp(
        state,
        'payments.activeMerchant.modal.merchantDetails',
        {},
    );
    const provider = getStateProp(merchantDetails, 'providers[0]', []);
    const { gatewayId, isWebShow } = merchantDetails;
    const { memberLevels, trustLevels } = provider;

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateActivatedMerchant = () => {
        setTrigger(+new Date());
        activatedMerchantUpdate(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                ...merchantInfo,
                isWebShow,
                memberLevels,
                trustLevels,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.MERCHANTS.MERCHANT_SETUP}/${gatewayId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            activatedMerchantUpdateSuccess(dispatch);
            activatedMerchantSetupModalClose(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, activatedMerchantUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateActivatedMerchant, response];
}

export function useUpdateDeactivatedMerchantAsyncEndpoint(merchantInfo) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const refreshTable = getStateProp(state, 'payments.deactivatedMerchant.fetchData', () => {});
    const merchantDetails = getStateProp(
        state,
        'payments.deactivatedMerchant.modal.merchantDetails',
        {},
    );
    const provider = getStateProp(merchantDetails, 'providers[0]', []);
    const { gatewayId, isWebShow } = merchantDetails;
    const { memberLevels, trustLevels } = provider;

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateDeactivatedMerchant = () => {
        setTrigger(+new Date());
        deactivatedMerchantUpdate(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: { ...merchantInfo, isWebShow, memberLevels, trustLevels },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.MERCHANTS.MERCHANT_SETUP}/${gatewayId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            deactivatedMerchantUpdateSuccess(dispatch);
            deactivatedMerchantSetupModalClose(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, deactivatedMerchantUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateDeactivatedMerchant, response];
}

export function useGetMerchantConfigurationAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const filters = getStateProp(state, 'payments.merchantConfiguration.filters', {});
    const { pageNo, pageSize } = getStateProp(state, 'payments.merchantConfiguration.paging');

    // ============================================
    // METHODS
    // ============================================

    const handleGetMerchantsConfiguration = useCallback(() => {
        merchantConfigurationGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                activatedStatus: 'Activated',
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.MERCHANTS.MERCHANT_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            merchantConfigurationGetSuccess(dispatch, {
                fetchData: handleGetMerchantsConfiguration,
                list: response.data.data,
            });
        }
    }, [dispatch, handleGetMerchantsConfiguration, response]);

    useEffect(() => {
        handleGetMerchantsConfiguration();
    }, [handleGetMerchantsConfiguration, pageNo, pageSize]);

    const errorMsg = useError(error, merchantConfigurationGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetMerchantsConfiguration];
}

export function useUpdateMerchantConfigurationAsyncEndpoint(merchantConfigureInfo) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const merchantDetails = getStateProp(
        state,
        'payments.merchantConfiguration.modalData.merchantDetails',
        {},
    );
    const refreshTable = getStateProp(state, 'payments.merchantConfiguration.fetchData', () => {});
    const {
        gatewayId,
        gatewayName,
        displayName,
        submissionMethod,
        merchantDomain,
        merchantId,
        merchantKey,
        depositEndpoint,
        backendUrl,
        balanceLimitNotification,
        activatedStatus,
    } = merchantDetails;

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateActivatedMerchant = () => {
        setTrigger(+new Date());
        merchantConfiguration(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                ...merchantConfigureInfo,
                activatedStatus,
                backendUrl,
                balanceLimitNotification,
                depositEndpoint,
                displayName,
                gatewayName,
                merchantDomain,
                merchantId,
                merchantKey,
                submissionMethod,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.MERCHANTS.MERCHANT_SETUP}/${gatewayId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            merchantConfigurationSuccess(dispatch);
            onCloseConfirmModal(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, merchantConfigurationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateActivatedMerchant, response];
}

export function useUpdateMerchantConfigurationPayServiceAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const merchantDetails = getStateProp(
        state,
        'payments.merchantConfiguration.modalData.merchantDetails',
        {},
    );
    const selectedPayService = getStateProp(
        state,
        'payments.merchantConfiguration.modalData.selectedPayService',
        {},
    );
    const providerId = getStateProp(selectedPayService, 'providerId', '');
    const refreshTable = getStateProp(state, 'payments.merchantConfiguration.fetchData', () => {});
    const { gatewayId } = merchantDetails;

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateMerchantPayService = () => {
        merchantConfigurationPayServiceUpdate(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                ...selectedPayService,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.MERCHANTS.MERCHANT_SETUP}/${gatewayId}/providers/${providerId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            merchantConfigurationPayServiceUpdateSuccess(dispatch);
            refreshTable();
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, refreshTable, response]);

    const errorMsg = useError(error, merchantConfigurationPayServiceUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateMerchantPayService, error];
}
