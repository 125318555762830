export const ACTIONS = {
    FILTER_DROP: 'ACTIVE_MERCHANT_FILTER_DROP',
    FILTER_SET: 'ACTIVE_MERCHANT_FILTER_SET',
    MERCHANT_GET: 'ACTIVE_MERCHANT_GET',
    MERCHANT_GET_FAILURE: 'ACTIVE_MERCHANT_GET_FAILURE',
    MERCHANT_GET_SUCCESS: 'ACTIVE_MERCHANT_GET_SUCCESS',
    MERCHANT_SETUP: 'MERCHANT_SETUP',
    MERCHANT_SETUP_FAILURE: 'MERCHANT_SETUP_FAILURE',
    MERCHANT_SETUP_MODAL_CLOSE: 'ACTIVE_MERCHANT_SETUP_MODAL_CLOSE',
    MERCHANT_SETUP_MODAL_OPEN: 'ACTIVE_MERCHANT_SETUP_MODAL_OPEN',
    MERCHANT_SETUP_SUCCESS: 'MERCHANT_SETUP_SUCCESS',
    MERCHANT_UPDATE: 'ACTIVE_MERCHANT_UPDATE',
    MERCHANT_UPDATE_FAILURE: 'ACTIVE_MERCHANT_UPDATE_FAILURE',
    MERCHANT_UPDATE_SUCCESS: 'ACTIVE_MERCHANT_UPDATE_SUCCESS',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'ACTIVE_MERCHANT_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'ACTIVE_MERCHANT_TABLE_PAGE_SIZE_CHANGE',
    },
};

export const activatedMerchantSetupModalOpen = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MERCHANT_SETUP_MODAL_OPEN,
    });

export const activatedMerchantSetupModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.MERCHANT_SETUP_MODAL_CLOSE,
    });

export const activatedMerchantGet = (dispatch) =>
    dispatch({
        type: ACTIONS.MERCHANT_GET,
    });

export const activatedMerchantGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MERCHANT_GET_SUCCESS,
    });

export const activatedMerchantGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MERCHANT_GET_FAILURE,
    });

export const merchantFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const merchantDropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTER_DROP,
    });

export const merchantSetup = (dispatch) =>
    dispatch({
        type: ACTIONS.MERCHANT_SETUP,
    });

export const merchantSetupSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.MERCHANT_SETUP_SUCCESS,
    });

export const merchantSetupFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MERCHANT_SETUP_FAILURE,
    });

export const activatedMerchantUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.MERCHANT_UPDATE,
    });

export const activatedMerchantUpdateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.MERCHANT_UPDATE_SUCCESS,
    });

export const activatedMerchantUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MERCHANT_UPDATE_FAILURE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });
