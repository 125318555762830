import React, { useState } from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './BannerManagementRecordTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const BannerManagementResultsTable = ({
    loading,
    openEditModal,
    openViewModal,
    handleGetBannerById,
}) => {
    const [state, dispatch] = useStore();
    const [langCode, setLangCode] = useState(null);

    const { bannerManagement } = getStateProp(state, 'bonus');
    const tableData = getStateProp(bannerManagement, 'list', []);
    const paging = getStateProp(bannerManagement, 'paging', {});

    // ============================================
    // METHODS
    // ============================================

    const handleChange = (pagination, filters) => {
        filters.bannerName ? setLangCode(filters.bannerName[0]) : setLangCode(null);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns(
                dispatch,
                openEditModal,
                handleGetBannerById,
                langCode,
                openViewModal,
            )}
            rowKey={(record) => record.no}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            dataSource={tableData}
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
            onChange={handleChange}
        />
    );
};

export default BannerManagementResultsTable;
