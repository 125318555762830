import React from 'react';

import { Form } from 'antd';

import { API } from '../../../../common/constants/api';
import {
    closeMessageDeleteModal,
    messageFilterDrop,
    openMessageDeleteModal,
    setMessageDeselected,
} from './personalMessageActions';
import { getColumns } from './PersonalMessageTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useExportAsyncEndpoint } from '../../../../services/hooks/useExport';
import { useGetPersonalMessageHistoryAsyncEndpoint } from '../../../../services/messageService';
import { useStore } from '../../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import DeleteMessageModal from './modal/DeleteMessageModal';
import PersonalMessageFilters from './PersonalMessageFilters';
import PersonalMessageResultTable from './PersonalMessageResultTable';
import ViewMessageModal from './modal/ViewMessageModal';

const { ANNOUNCEMENT } = API;

const PersonalMessage = () => {
    const [state, dispatch] = useStore();
    const personalMessage = getStateProp(state, 'announcement.announcementHistory.personalMessage');
    const deleteModalVisible = getStateProp(personalMessage, 'deleteModalVisible');
    const viewModalVisible = getStateProp(personalMessage, 'viewModalVisible');
    const selectedMessage = getStateProp(personalMessage, 'selectedMessage', {});
    const filters = getStateProp(personalMessage, 'filters');
    const { totalRecordCount } = getStateProp(personalMessage, 'paging', {});

    // ============================================
    // METHODS
    // ============================================

    const onCloseDeleteModal = () => closeMessageDeleteModal(dispatch);

    const fetchAll = () => {
        messageFilterDrop(dispatch);
        handleGetPersonalMessageHistory();
    };

    const closeViewModal = () => setMessageDeselected(dispatch);

    const onOpenDeleteModal = () => {
        openMessageDeleteModal(dispatch, { messageDelete: selectedMessage });
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetPersonalMessageHistory] = useGetPersonalMessageHistoryAsyncEndpoint();

    const [handleExportAction] = useExportAsyncEndpoint({
        dataMeta: getColumns(),
        filters,
        mandatoryFilter: { IsHistory: true },
        method: 'GET',
        subject: getTranslation('Personal Message History'),
        totalRecordCount,
        url: `${ANNOUNCEMENT.PERSONAL_MESSAGE_LIST}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <>
            <Form onFinish={handleGetPersonalMessageHistory}>
                <PersonalMessageFilters />
                <ActionButtons onDropFilters={fetchAll} onExport={handleExportAction} />
            </Form>

            <PersonalMessageResultTable loading={loading} />

            <DeleteMessageModal visible={deleteModalVisible} onCancel={onCloseDeleteModal} />

            <ViewMessageModal
                visible={viewModalVisible}
                onCancel={closeViewModal}
                onOpenDeleteModal={onOpenDeleteModal}
            />
        </>
    );
};

export default PersonalMessage;
