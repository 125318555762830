export const ACTIONS = {
    ACTIVE_BANNERS_FILTERS_DROP: 'ACTIVE_BANNERS_FILTERS_DROP',
    ACTIVE_BANNERS_FILTERS_SET: 'ACTIVE_BANNERS_FILTERS_SET',
    ACTIVE_BANNERS_GET: 'ACTIVE_BANNERS_GET',
    ACTIVE_BANNERS_GET_FAILURE: 'ACTIVE_BANNERS_GET_FAILURE',
    ACTIVE_BANNERS_GET_SUCCESS: 'ACTIVE_BANNERS_GET_SUCCESS',
    ACTIVE_BANNERS_UPDATE: 'ACTIVE_BANNERS_UPDATE',
    ACTIVE_BANNERS_UPDATE_FAILURE: 'ACTIVE_BANNERS_UPDATE_FAILURE',
    ACTIVE_BANNERS_UPDATE_SUCCESS: 'ACTIVE_BANNERS_UPDATE_SUCCESS',
    AVAILABLE_BANNERS_FILTERS_DROP: 'AVAILABLE_BANNERS_FILTERS_DROP',
    AVAILABLE_BANNERS_FILTERS_SET: 'AVAILABLE_BANNERS_FILTERS_SET',
    AVAILABLE_BANNERS_GET: 'AVAILABLE_BANNERS_GET',
    AVAILABLE_BANNERS_GET_FAILURE: 'AVAILABLE_BANNERS_GET_FAILURE',
    AVAILABLE_BANNERS_GET_SUCCESS: 'AVAILABLE_BANNERS_GET_SUCCESS',
    BANNER_MANAGEMENT_MODAL_CLOSE: 'BANNER_MANAGEMENT_MODAL_CLOSE',
    BANNER_MANAGEMENT_MODAL_OPEN: 'BANNER_MANAGEMENT_MODAL_OPEN',
    BANNER_MANAGEMENT_SELECT: 'BANNER_MANAGEMENT_SELECT',
    BANNER_SORTING_UPDATE: 'BANNER_SORTING_UPDATE',
    PAGINATION: {
        ACTIVE_BANNER_TABLE_PAGE_CHANGE: 'ACTIVE_BANNER_TABLE_PAGE_CHANGE',
        ACTIVE_BANNER_TABLE_PAGE_SIZE_CHANGE: 'ACTIVE_BANNER_TABLE_PAGE_SIZE_CHANGE',
        AVAILABLE_BANNER_TABLE_PAGE_CHANGE: 'AVAILABLE_BANNER_TABLE_PAGE_CHANGE',
        AVAILABLE_BANNER_TABLE_PAGE_SIZE_CHANGE: 'AVAILABLE_BANNER_TABLE_PAGE_SIZE_CHANGE',
    },
};

//#region modal
export const modalOpen = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_MANAGEMENT_MODAL_OPEN,
    });

export const modalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.BANNER_MANAGEMENT_MODAL_CLOSE,
    });
//#end region

//#region select banner
export const onBannerSelect = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_MANAGEMENT_SELECT,
    });
//#end region

//#region get available banners
export const getAvailableBanners = (dispatch) =>
    dispatch({
        type: ACTIONS.AVAILABLE_BANNERS_GET,
    });

export const getAvailableBannersSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.AVAILABLE_BANNERS_GET_SUCCESS,
    });

export const getAvailableBannersFailure = (dispatch) =>
    dispatch({
        type: ACTIONS.AVAILABLE_BANNERS_GET_FAILURE,
    });
//#end region

//#region filter available banner
export const availableBannerFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.AVAILABLE_BANNERS_FILTERS_SET,
    });

export const availableBannerFilterDrop = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.AVAILABLE_BANNERS_FILTERS_DROP,
    });
//#end region

//#region update banner sorting info
export const updateBannerSorting = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BANNER_SORTING_UPDATE,
    });
//#end region

//#region update available banner table page info
export const changeAvailableBannerPageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.AVAILABLE_BANNER_TABLE_PAGE_SIZE_CHANGE,
    });

export const changeAvailableBannerPageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.AVAILABLE_BANNER_TABLE_PAGE_CHANGE,
    });
//#end region

//#region get active banners
export const getActiveBanners = (dispatch) =>
    dispatch({
        type: ACTIONS.ACTIVE_BANNERS_GET,
    });

export const getActiveBannersSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ACTIVE_BANNERS_GET_SUCCESS,
    });

export const getActiveBannersFailure = (dispatch) =>
    dispatch({
        type: ACTIONS.ACTIVE_BANNERS_GET_FAILURE,
    });
//#end region

//#region update active banner table page info
export const changeActiveBannerPageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.ACTIVE_BANNER_TABLE_PAGE_SIZE_CHANGE,
    });

export const changeActiveBannerPageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.ACTIVE_BANNER_TABLE_PAGE_CHANGE,
    });
//#end region

//#region submit active banner form
export const updateActiveBanners = (dispatch) =>
    dispatch({
        type: ACTIONS.ACTIVE_BANNERS_UPDATE,
    });

export const updateActiveBannersSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ACTIVE_BANNERS_UPDATE_SUCCESS,
    });

export const updateActiveBannersFailure = (dispatch) =>
    dispatch({
        type: ACTIONS.ACTIVE_BANNERS_UPDATE_FAILURE,
    });
//#end region

//#region filter active banner
export const activeBannerFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ACTIVE_BANNERS_FILTERS_SET,
    });

export const activeBannerFilterDrop = (dispatch) =>
    dispatch({
        type: ACTIONS.ACTIVE_BANNERS_FILTERS_DROP,
    });
//#end region
