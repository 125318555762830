import React from 'react';

import { Row } from 'antd';

import { PeriodFilter, UsernameFilter } from '../../../common/components/filters';
import { PlatformFilter, ProductFilter } from '../bet-list/filters';
import { getStateProp } from '../../../common/helpers/misc';
import { turnoverCheckFilterSet } from './turnoverCheckActions';
import { useStore } from '../../../store/StateProvider';

const TurnoverCheckFilter = ({ loading }) => {
    const [state, dispatch] = useStore();

    const turnoverCheck = getStateProp(state, 'bet.turnoverCheck');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => {
        if (filter === 'dateFrom' || filter === 'dateTo') {
            turnoverCheckFilterSet(dispatch, { filter: 'paidTimeDateFrom', value: undefined });
            turnoverCheckFilterSet(dispatch, { filter: 'paidTimeDateTo', value: undefined });
        }

        if (filter === 'paidTimeDateFrom' || filter === 'paidTimeDateTo') {
            turnoverCheckFilterSet(dispatch, { filter: 'dateFrom', value: undefined });
            turnoverCheckFilterSet(dispatch, { filter: 'dateTo', value: undefined });
        }
        turnoverCheckFilterSet(dispatch, { filter, value });
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(turnoverCheck, 'filters.userName')}
                name="userName"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(turnoverCheck, 'filters.dateFrom')}
                dateTo={getStateProp(turnoverCheck, 'filters.dateTo')}
                dateFromName="dateFrom"
                dateToName="dateTo"
            />

            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(turnoverCheck, 'filters.paidTimeDateFrom')}
                dateTo={getStateProp(turnoverCheck, 'filters.paidTimeDateTo')}
                dateFromName="paidTimeDateFrom"
                dateToName="paidTimeDateTo"
            />
            <PlatformFilter
                onFilterChange={setFilter}
                value={getStateProp(turnoverCheck, 'filters.platform')}
                loading={loading}
                name="platforms"
            />
            <ProductFilter
                onFilterChange={setFilter}
                value={getStateProp(turnoverCheck, 'filters.products')}
                loading={loading}
            />
        </Row>
    );
};

export default TurnoverCheckFilter;
