import { useCallback, useEffect, useState } from 'react';

import { message } from 'antd';

import { API } from '../common/constants/api';
import { OPERATION_TIME_LIMIT, PAYMENT_ORDER_OPERATIONS } from '../common/constants/misc';
import { clearError } from '../common/actions/errorActions';
import {
    closeConfirmModal,
    closeVerifyModal,
    depositOperationSuccess,
    depositsGet,
    depositsGetFailure,
    depositsGetSuccess,
    depositsOperationFailure,
    performDepositOperation,
} from '../components/payments/deposits/depositsActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import { useNotification } from './hooks/useNotification';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, PAYMENTS } = API;

export function useGetDepositsAsyncEndpoint(isOnline) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const deposits = getStateProp(state, 'payments.deposits');
    const { pageNo, pageSize, totalRecordCountNoFilter } = getStateProp(deposits, 'paging');
    const filters = getStateProp(deposits, 'filters', {});
    const refreshTime = getStateProp(deposits, 'operation.refreshTime');
    const refreshTimePause = getStateProp(deposits, 'operation.refreshTimePause');

    // ============================================
    // METHODS
    // ============================================

    const handleGetDeposits = useCallback(() => {
        depositsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${PAYMENTS.OFFLINE_DEPOSITS.ROOT}`,
    });

    useEffect(() => {
        let timeInterval;
        if (response || isOnline)
            timeInterval = setTimeout(() => handleGetDeposits(), refreshTime * 1000);
        if (refreshTimePause) clearTimeout(timeInterval);
        return () => clearTimeout(timeInterval);
    }, [handleGetDeposits, isOnline, refreshTime, refreshTimePause, response]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            depositsGetSuccess(dispatch, {
                dataTotal: response.data.data[0].dataTotal,
                fetchData: handleGetDeposits,
                grandTotal: response.data.data[0].grandTotal,
                list: response.data.data[0].data,
                pageDataTotal: response.data.data[0].pageDataTotal,
                pageTotal: response.data.data[0].pageTotal,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetDeposits, response]);

    useEffect(() => {
        handleGetDeposits();
    }, [handleGetDeposits, pageNo, pageSize]);

    const errorMsg = useError(error, depositsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    useNotification(
        totalRecordCountNoFilter,
        getStateProp(response, 'data.paging.totalRecordCountNoFilter', 0),
        'You have new deposit record',
    );

    return [loading, handleGetDeposits];
}

export function useDepositOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();

    const deposits = getStateProp(state, 'payments.deposits');

    const refreshTable = getStateProp(deposits, 'fetchData', () => {});
    const operation = getStateProp(deposits, 'operation');
    const transactionIds = getStateProp(deposits, 'selectedTransactionId');
    const transactionId = getStateProp(operation, 'transaction.transactionId');
    const action = getStateProp(operation, 'action');
    const [timeOutId, setTimeOutId] = useState(null);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!action,
        method: 'POST',
        options: {
            data: {
                remark: '',
                transactionIds,
            },
        },
        trigger: action,
        url:
            action === PAYMENT_ORDER_OPERATIONS.REJECT
                ? `${VERSION.V1}${PAYMENTS.OFFLINE_DEPOSITS.ROOT}/${action}`
                : `${VERSION.V1}${PAYMENTS.OFFLINE_DEPOSITS.ROOT}/${transactionId}/${action}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            depositOperationSuccess(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    useEffect(() => {
        if (response) {
            if (action === PAYMENT_ORDER_OPERATIONS.COMPLETE) {
                message.success(getTranslation('Transaction verified!'));
            } else if (action === PAYMENT_ORDER_OPERATIONS.REJECT) {
                message.success(getTranslation('Transaction rejected!'));
            }
        }
    }, [response, action]);

    useEffect(() => {
        if (response && action) {
            clearTimeout(timeOutId);
            if (action === PAYMENT_ORDER_OPERATIONS.ATTEND) {
                setTimeOutId(
                    setTimeout(() => {
                        performDepositOperation(dispatch, {
                            action: PAYMENT_ORDER_OPERATIONS.RELEASE,
                        });
                        closeConfirmModal(dispatch);
                        closeVerifyModal(dispatch);
                    }, OPERATION_TIME_LIMIT),
                );
            }
        }
    }, [response, action, dispatch, timeOutId]);

    const errorMsg = useError(error, depositsOperationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}
