import React from 'react';

import './BankAccountStatementTableSummary.less';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useStore } from '../../../../store/StateProvider';

const BankAccountStatementTableSummary = () => {
    const [state] = useStore();
    const bankAccountStatement = getStateProp(state, 'payments.bankAccountStatement', {});
    const { totalBalance, totalCashIn, totalCashOut, totalFee } = bankAccountStatement;

    return (
        <>
            <tr className="bank-account-statement-table__summary-row">
                <td colSpan={5} />
                <td>{getTranslation('Total Summary')}:</td>
                <td>{totalCashIn}</td>
                <td>{totalCashOut}</td>
                <td>{totalFee}</td>
                <td colSpan={3} />
            </tr>
            <tr className="bank-account-statement-table__summary-row">
                <td colSpan={5} />
                <td>{getTranslation('Total Balance')}:</td>
                <td>{totalBalance}</td>
                <td colSpan={5} />
            </tr>
        </>
    );
};

export default BankAccountStatementTableSummary;
