import React, { useEffect } from 'react';

import { Col, Input, Row, message } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import _ from 'lodash';

import './MessageForm.less';
import { ButtonGray, ButtonGreen } from '../../../common/components/buttons/Buttons';
import { ContactMethodFilter, MessageTypeFilter } from '../filters';
import { PeriodFilter } from '../../../common/components/filters';
import {
    closeVerifyModal,
    openVerifyModal,
    resetMessageDraftInfo,
    setMessageDraftInfo,
} from './messageCreateActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useCreateMessageAsyncEndpoint } from '../../../services/messageService';
import { useStore } from '../../../store/StateProvider';
import MessageEditor from './MessageEditor';
import RecipientsVerifyModal from './recipients-verification/RecipientsVerifyModal';

const { TextArea } = Input;

const MessageForm = () => {
    const [state, dispatch] = useStore();

    const messageCreate = getStateProp(state, 'announcement.messageCreate', {});

    const {
        messageType,
        contactMethod,
        durationEnd,
        durationStart,
        title,
        verifyRecipientModalVisible,
        acceptedRecipients,
        content,
    } = messageCreate;

    // ============================================
    // METHODS
    // ============================================

    const onInputChange = (name, value) => {
        name === 'messageType' && setMessageDraftInfo(dispatch, { name: 'content', value: null });
        setMessageDraftInfo(dispatch, { name, value });
    };

    const onEditorChange = (e) => {
        const regex = /<\s*img[^>]*>/g;
        const modifiedHtml = e.target.getContent().replace(regex, (match) => {
            if (match.includes('style')) {
                return match;
            } else {
                match = match.replace('/>', 'style="margin:auto; width:100%;display:block;" />');
                return match;
            }
        });

        setMessageDraftInfo(dispatch, {
            name: 'content',
            value: modifiedHtml,
        });
    };

    const onTextAreaChange = (value) => {
        setMessageDraftInfo(dispatch, {
            name: 'content',
            value: value,
        });
    };

    const resetForm = () => resetMessageDraftInfo(dispatch);

    const verifyRecipientModalOpen = () => openVerifyModal(dispatch);

    const verifyRecipientModalClose = () => {
        if (acceptedRecipients === null || acceptedRecipients === undefined) {
            message.warning(getTranslation('You not yet accept your receipients'));
        }
        closeVerifyModal(dispatch);
    };

    const formValidation = () => {
        const recipientsValidation = () => {
            if (acceptedRecipients === null || acceptedRecipients === undefined) {
                message.warning(getTranslation('You not yet accept your receipients'));
                return false;
            } else return true;
        };

        const messageTypeValidation = () => {
            if (messageType === undefined) {
                message.error(getTranslation('Please select message type'));
                return false;
            } else return true;
        };

        const contactMethodValidation = () => {
            if (contactMethod === undefined) {
                message.error(getTranslation('Please select contact method'));
                return false;
            } else return true;
        };

        const messageValidation = () => {
            if (_.replace(content, '<p></p>', '') && title) {
                return true;
            } else {
                message.error(getTranslation('Please input title & content'));
                return false;
            }
        };

        return (
            messageValidation() &&
            contactMethodValidation() &&
            recipientsValidation() &&
            messageTypeValidation()
        );
    };

    const submitForm = () => {
        if (formValidation()) {
            handleCreateMessage();
        }
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleCreateMessage, created] = useCreateMessageAsyncEndpoint(messageCreate);

    useEffect(() => {
        if (created) {
            resetMessageDraftInfo(dispatch);
        }
    }, [created, dispatch]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <div className="message-form">
            <Row className="form__group">
                <Col span={8}>
                    <label className="form__label">
                        {getTranslation('Message Type')}
                        <span className="form__required">*</span>
                    </label>
                </Col>
                <Col span={14} className="filters-container">
                    <MessageTypeFilter onFilterChange={onInputChange} value={messageType} />
                </Col>
            </Row>

            <Row className="form__group">
                <Col span={8}>
                    <label className="form__label">
                        {getTranslation('Contact Method')}
                        <span className="form__required">*</span>
                    </label>
                </Col>
                <Col span={14} className="filters-container">
                    <ContactMethodFilter
                        onFilterChange={onInputChange}
                        value={contactMethod}
                        messageType={messageType}
                    />
                </Col>
            </Row>

            <Row className="form__group">
                <Col span={8}>
                    <label>{getTranslation('Recipients')}: </label>
                </Col>
                <Col span={14}>
                    <Row gutter={16}>
                        <Col span={19}>
                            <Input placeholder={getTranslation('Recipients')} readOnly />
                        </Col>
                        <Col span={5}>
                            <ButtonGreen
                                icon={<FileExcelOutlined />}
                                label={getTranslation('Open')}
                                style={{ width: '100%' }}
                                onClick={verifyRecipientModalOpen}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="form__group">
                <Col span={8}>
                    <label className="form__label">
                        {getTranslation('Duration')}
                        <span className="form__required">*</span>
                    </label>
                </Col>
                <Col span={14}>
                    <PeriodFilter
                        onFilterChange={onInputChange}
                        dateFrom={durationStart}
                        dateFromName="durationStart"
                        dateTo={durationEnd}
                        dateToName="durationEnd"
                        onRefreshTable={() => {}}
                    />
                </Col>
            </Row>

            <Input
                placeholder="Title"
                className="message-title__input"
                onChange={(e) => onInputChange('title', e.target.value)}
                value={title}
            />

            {messageType === 'Inbox' ? (
                <MessageEditor onEditorChange={onEditorChange} content={content} />
            ) : (
                <TextArea
                    onChange={(e) => onTextAreaChange(e.target.value)}
                    value={content}
                    placeholder={getTranslation('Content')}
                    rows={4}
                />
            )}

            <Row className="action-buttons" justify="end" align="middle">
                <ButtonGreen
                    label={getTranslation('Add Message')}
                    onClick={submitForm}
                    loading={loading}
                />

                <ButtonGray label={getTranslation('Cancel')} onClick={resetForm} />
            </Row>

            <RecipientsVerifyModal
                visible={verifyRecipientModalVisible}
                onCancel={verifyRecipientModalClose}
                messageCreate={messageCreate}
                contactMethod={contactMethod}
            />
        </div>
    );
};

export default MessageForm;
