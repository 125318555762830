import React, { useEffect, useState } from 'react';

import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import _ from 'lodash';

import './EwalletSetupForm.less';
import { BANK_CARD_STATUSES } from '../constants';
import { PAYMENT_EWALLET_ACCOUNT_TYPES } from '../../../../common/constants/misc';
import { ewalletClear } from '../ewalletActions';
import { getCountryIdByCurrency, getTranslation } from '../../../../common/helpers/locale';
import { getStateProp } from '../../../../common/helpers/misc';
import { getUserCurrency } from '../../../../common/helpers/api';
import { lengthValidator } from '../../../../common/helpers/validators';
import { useGetEwalletAsyncEndpoint } from '../../../../services/ewalletService';
import { useStore } from '../../../../store/StateProvider';

const EwalletSetupForm = ({ selectedAccount, form }) => {
    const [state, dispatch] = useStore();
    const [currencyCode, setCurrencyCode] = useState(null);
    const ewalletList = getStateProp(state, 'payments.ewallet.list');
    const countryId = getCountryIdByCurrency(currencyCode);
    const operatorCurrency = getUserCurrency();

    const { setFieldsValue } = form;
    // ============================================
    // METHODS
    // ============================================

    const setCurrency = (value) => setCurrencyCode(value);

    const updateEwalletName = (ewalletId) => {
        setFieldsValue({
            ewalletName: _.find(ewalletList, (list) => {
                return list.eWalletId === ewalletId;
            }).eWalletName,
        });
    };

    // ============================================
    // HOOKS
    // ============================================

    const [gettingEwallet] = useGetEwalletAsyncEndpoint(countryId);

    useEffect(() => {
        if (!currencyCode || countryId === '') {
            ewalletClear(dispatch);
        }
    }, [countryId, currencyCode, dispatch]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row>
            <Col span={12} className="bank-card__form-column">
                <Row className="form__group">
                    <Col span={10}>
                        <label> {getTranslation('Currency')} </label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="currencyCode"
                            rules={[
                                {
                                    message: getTranslation('Please select currency!'),
                                    required: true,
                                },
                            ]}
                        >
                            <Select allowClear={true} onChange={setCurrency}>
                                {_.map(operatorCurrency, (currency) => (
                                    <Select.Option value={currency} key={currency}>
                                        {currency.toLocaleUpperCase()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {!_.isEmpty(selectedAccount) ? (
                    <Row className="form__group">
                        <Col span={10}>
                            <label>{getTranslation('E-Wallet')}</label>
                            <span className="form__required">*</span>
                        </Col>
                        <Col span={14}>
                            <Form.Item name="eWalletName">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                ) : (
                    <Row className="form__group">
                        <Col span={10}>
                            <label>{getTranslation('E-Wallet')}</label>
                            <span className="form__required">*</span>
                        </Col>
                        <Col span={14}>
                            <Form.Item
                                name="eWalletId"
                                rules={[
                                    {
                                        message: getTranslation('Please Select E-Wallet!'),
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    disabled={!ewalletList}
                                    loading={gettingEwallet}
                                    onChange={updateEwalletName}
                                >
                                    {_.map(ewalletList, (ewallet) => (
                                        <Select.Option
                                            value={ewallet.eWalletId}
                                            key={ewallet.eWalletId}
                                        >
                                            {ewallet.eWalletName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Account Name')}</label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="accountHolderName"
                            rules={[
                                {
                                    message: getTranslation('Please input account holder name!'),
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('E-Wallet Account Number')}</label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="eWalletAccountNo"
                            validateFirst
                            rules={[
                                {
                                    message: getTranslation(
                                        'Please input E-Wallet account number!',
                                    ),
                                    required: true,
                                },
                                ({ getFieldValue }) => ({
                                    validator: lengthValidator(
                                        getFieldValue('eWalletAccountNo'),
                                        6,
                                        19,
                                        getTranslation('Ewallet number must be 6-19 digits!'),
                                    ),
                                }),
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Opening Date')}</label>
                    </Col>
                    <Col span={14}>
                        <Form.Item name="openAt">
                            <DatePicker
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                disabled={!_.isEmpty(selectedAccount)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('U-Key Number')}</label>
                    </Col>
                    <Col span={14}>
                        <Form.Item name="uKey">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
            <Col span={12} className="bank-card__form-column">
                <Row className="form__group">
                    <Col span={10}>
                        <label>{getTranslation('Balance Limit Notification')}</label>
                    </Col>
                    <Col span={14}>
                        <Form.Item name="balanceLimitNotification">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label> {getTranslation('Type')} </label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="type"
                            rules={[
                                {
                                    message: getTranslation('Please select type!'),
                                    required: true,
                                },
                            ]}
                        >
                            <Select allowClear={true}>
                                {_.map(PAYMENT_EWALLET_ACCOUNT_TYPES, (type) => (
                                    <Select.Option value={type} key={type}>
                                        {getTranslation(type).toLocaleUpperCase()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label> {getTranslation('Status')} </label>
                        <span className="form__required">*</span>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="activatedStatus"
                            rules={[
                                {
                                    message: getTranslation('Please select status!'),
                                    required: true,
                                },
                            ]}
                        >
                            <Select allowClear={true}>
                                {_.map(BANK_CARD_STATUSES, (status) => (
                                    <Select.Option value={status} key={status}>
                                        {getTranslation(status).toLocaleUpperCase()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label> {getTranslation('Payment Method Acceptance')} </label>
                    </Col>
                    <Col span={14}>
                        <Form.Item name="paymentMethodAcceptance">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="form__group">
                    <Col span={10}>
                        <label> {getTranslation('Remark')} </label>
                    </Col>
                    <Col span={14}>
                        <Form.Item name="remarks">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default EwalletSetupForm;
