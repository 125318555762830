import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';

const ProductFilter = ({ onFilterChange, value }) => {
    const [state] = useStore();
    const products = getStateProp(state, 'reports.playerWinLoss.filterOptions.products', []);

    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('productCodes', value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            className="filter"
            placeholder={getTranslation('Product')}
            value={value}
            onChange={handleFilterChange}
            allowClear={true}
            mode="multiple"
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {_.map(products, (product) => (
                <Select.Option value={product.productCode} key={product.productCode}>
                    {product.productDesc.toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
};

export default ProductFilter;
