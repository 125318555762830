import React from 'react';

import { Card, Form } from 'antd';

import {
    ewalletConfigurationDropFilters,
    onCloseConfirmModal,
} from './ewalletConfigurationActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useGetEwalletConfigurationAsyncEndpoint } from '../../../services/ewalletService';

import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import EwalletConfigurationFilters from './EwalletConfigurationFilters';
import EwalletConfigurationResultsTable from './table/EwalletConfigurationResultsTable';
import ConfigurationModal from './modal/ConfigurationModal';

const EwalletAccountConfiguration = () => {
    const [state, dispatch] = useStore();
    const ewalletConfiguration = getStateProp(state, 'payments.ewalletConfiguration');
    const confirmModalVisible = getStateProp(ewalletConfiguration, 'confirmModalVisible');

    // ============================================
    // METHODS
    // ============================================

    const onCloseModal = () => onCloseConfirmModal(dispatch);

    const fetchAll = () => {
        ewalletConfigurationDropFilters(dispatch);
        handleGetEwalletConfiguration();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetEwalletConfiguration] = useGetEwalletConfigurationAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('E-Wallet Configuration')}>
            <Form onFinish={handleGetEwalletConfiguration}>
                <EwalletConfigurationFilters />
                <ActionButtons onDropFilters={fetchAll} />
            </Form>

            <EwalletConfigurationResultsTable loading={loading} />
            {confirmModalVisible && <ConfigurationModal onCancel={onCloseModal} />}
        </Card>
    );
};

export default EwalletAccountConfiguration;
