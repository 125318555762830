import React, { useMemo } from 'react';

import { Table } from 'antd';
import { useVT } from 'virtualizedtableforantd4';

import './PlayerWalletBalanceTable.less';
import { getColumns, getPaginationOptions, tableBodyWrapper } from './PlayerWalletBalanceTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const PlayerWalletBalanceTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const playerWalletBalance = getStateProp(state, 'reports.playerWalletBalance');
    const paging = getStateProp(playerWalletBalance, 'paging', {});
    const tableData = getStateProp(playerWalletBalance, 'list', []);
    const grandTotal = getStateProp(playerWalletBalance, 'grandTotal', []);
    const viewMode = getStateProp(playerWalletBalance, 'filters.viewMode');
    const reportType = getStateProp(playerWalletBalance, 'filters.reportType');

    // ============================================
    // HOOKS
    // ============================================

    const [vt, setVT] = useVT(() => ({ scroll: { y: 600 } }), []);

    useMemo(
        () =>
            setVT({ body: { wrapper: (props) => tableBodyWrapper(props, grandTotal, viewMode) } }),
        [grandTotal, setVT, viewMode],
    );
    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns(viewMode, reportType)}
            pagination={getPaginationOptions(paging, dispatch)}
            dataSource={tableData}
            rowKey={(value, index) => index}
            loading={loading}
            size="small"
            align="left"
            className="player-wallet-balance__table"
            scroll={{ y: 600 }}
            bordered
            components={vt}
        />
    );
};

export default PlayerWalletBalanceTable;
