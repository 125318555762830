import React from 'react';

import { ButtonBlue, ButtonOrange, ButtonRed } from '../../../common/components/buttons/Buttons';
import { DropButton, ExportButton, SearchButton } from '../../../common/components/buttons';
import { Row } from 'antd';
import { VERIFY_ACTION } from './constants';
import { getTranslation } from '../../../common/helpers/locale';

const ActionButtons = ({
    isBatchUpload = false,
    onDropFilters,
    onExport,
    onHandleBatchActionModal = () => {},
}) => {
    return (
        <Row align="middle" justify="end" className="action-buttons__container">
            {isBatchUpload && (
                <ButtonRed
                    label={getTranslation('Batch Reject')}
                    onClick={() => onHandleBatchActionModal(true, VERIFY_ACTION.REJECT)}
                />
            )}
            {isBatchUpload && (
                <ButtonOrange
                    label={getTranslation('Batch Approve')}
                    onClick={() => onHandleBatchActionModal(true, VERIFY_ACTION.SUBMIT)}
                />
            )}
            {isBatchUpload && (
                <ButtonBlue
                    label={getTranslation('Batch Complete')}
                    onClick={() => onHandleBatchActionModal(true, VERIFY_ACTION.COMPLETED)}
                />
            )}
            <SearchButton htmlType="submit" />
            <DropButton onDropFilters={onDropFilters} />
            <ExportButton onExport={onExport} />
        </Row>
    );
};

export default ActionButtons;
