import React from 'react';

import { Col, Form, Input, Row, Switch, Tabs } from 'antd';
import _ from 'lodash';

import { CURRENCIES } from '../../../common/constants/localization';
import { VIP_LEVELS } from '../constants';
import { getTranslation } from '../../../common/helpers/locale';
import { isOperatorHasFullAccess } from '../../../common/helpers/misc';
import DailyLoginSetupTable from './table/DailyLoginSetupTable';

const { TabPane } = Tabs;

const DailyLoginDetails = ({ dailyLoginSetup, onFormValueChange }) => {
    // ============================================
    // RENDER
    // ============================================

    const RepetitiveClaimSwitch = (
        <Row className="daily-login-setup__row-item">
            <span className="daily-login-setup__switch-label">
                {getTranslation('Rerun Daily Check In To Claim Reward')}
            </span>
            <Form.Item>
                <Switch
                    checkedChildren={getTranslation('On')}
                    unCheckedChildren={getTranslation('Off')}
                    checked={dailyLoginSetup.repetitiveClaim}
                    onChange={(checked) => onFormValueChange('repetitiveClaim', checked)}
                    disabled={!isOperatorHasFullAccess()}
                />
            </Form.Item>
        </Row>
    );

    return (
        <>
            <Row className="daily-login-setup__row-item">
                <Col span={3}>
                    <label>{getTranslation('Code')}</label>
                </Col>
                <Col span={9}>
                    <Form.Item>
                        <Input
                            value={dailyLoginSetup.code}
                            onChange={(e) => onFormValueChange('code', e.target.value)}
                            disabled={!isOperatorHasFullAccess()}
                        />
                    </Form.Item>
                </Col>
            </Row>
            {RepetitiveClaimSwitch}
            <Tabs tabPosition="left">
                {_.map(CURRENCIES, (currency) => (
                    <TabPane tab={currency.currency} key={currency.currency}>
                        <Tabs>
                            {_.map(VIP_LEVELS, (vip) => (
                                <TabPane tab={vip.displayName} key={vip.displayName}>
                                    <DailyLoginSetupTable
                                        currency={currency.currency}
                                        details={dailyLoginSetup.loginBonusDetails}
                                        onFormValueChange={onFormValueChange}
                                        vipLevel={vip.level}
                                    />
                                </TabPane>
                            ))}
                        </Tabs>
                    </TabPane>
                ))}
            </Tabs>
        </>
    );
};

export default DailyLoginDetails;
