export const ACTIONS = {
    ADD_LANGUAGE: 'ADD_LANGUAGE',
    FILTERS_DROP_ALL: 'REFERRAL_RECORDS_FILTERS_DROP_ALL',
    FILTER_SET: 'REFERRAL_RECORDS_FILTER_SET',
    GET_NOTIFICATION_INFO: 'GET_NOTIFICATION_INFO',
    GET_NOTIFICATION_INFO_BY_ID: 'GET_NOTIFICATION_INFO_BY_ID',
    GET_NOTIFICATION_INFO_BY_ID_FAILURE: 'GET_NOTIFICATION_INFO_BY_ID_FAILURE',
    GET_NOTIFICATION_INFO_BY_ID_SUCCESS: 'GET_NOTIFICATION_INFO_BY_ID_SUCCESS',
    GET_NOTIFICATION_INFO_FAILURE: 'GET_NOTIFICATION_INFO_FAILURE',
    GET_NOTIFICATION_INFO_SUCCESS: 'GET_NOTIFICATION_INFO_SUCCESS',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'AFFILIATE_RECORDS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'AFFILIATE_RECORDS_TABLE_PAGE_SIZE_CHANGE',
    },
    PUSH_NOTIFICATION_MODAL_CLOSE: 'PUSH_NOTIFICATION_MODAL_CLOSE',
    PUSH_NOTIFICATION_MODAL_OPEN: 'PUSH_NOTIFICATION_MODAL_OPEN',
    REMOVE_LANGUAGE: 'REMOVE_LANGUAGE',
    SEND_PUSH_NOTIFICATION_INFO: 'SEND_PUSH_NOTIFICATION_INFO',
    SEND_PUSH_NOTIFICATION_INFO_FAILURE: 'SEND_PUSH_NOTIFICATION_INFO_FAILURE',
    SEND_PUSH_NOTIFICATION_INFO_SUCCESS: 'SEND_PUSH_NOTIFICATION_INFO_SUCCESS',
    UPDATE_PUSH_NOTIFICATION_INFO: 'UPDATE_PUSH_NOTIFICATION_INFO',
    UPDATE_TARGET_INFO: 'UPDATE_TARGET_INFO',
    UPSERT_PUSH_NOTIFICATION_INFO: 'UPSERT_PUSH_NOTIFICATION_INFO',
    UPSERT_PUSH_NOTIFICATION_INFO_FAILURE: 'UPSERT_PUSH_NOTIFICATION_INFO_FAILURE',
    UPSERT_PUSH_NOTIFICATION_INFO_SUCCESS: 'UPSERT_PUSH_NOTIFICATION_INFO_SUCCESS',
};

export const getNotificationInfo = (dispatch) =>
    dispatch({
        type: ACTIONS.GET_NOTIFICATION_INFO,
    });

export const getNotificationInfoSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_NOTIFICATION_INFO_SUCCESS,
    });

export const getNotificationInfoFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_NOTIFICATION_INFO_FAILURE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const pushNotificationFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const openNotificationModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PUSH_NOTIFICATION_MODAL_OPEN,
    });

export const closeNotificationModal = (dispatch) =>
    dispatch({
        type: ACTIONS.PUSH_NOTIFICATION_MODAL_CLOSE,
    });

export const getNotificationInfoById = (dispatch) =>
    dispatch({
        type: ACTIONS.GET_NOTIFICATION_INFO_BY_ID,
    });

export const getNotificationInfoByIdSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_NOTIFICATION_INFO_BY_ID_SUCCESS,
    });

export const getNotificationInfoByIdFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_NOTIFICATION_INFO_BY_ID_FAILURE,
    });

export const addPushNotificationLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ADD_LANGUAGE,
    });

export const removePushNotificationLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REMOVE_LANGUAGE,
    });

export const updatePushNotificationInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.UPDATE_PUSH_NOTIFICATION_INFO,
    });

export const upsertPushNotification = (dispatch) =>
    dispatch({
        type: ACTIONS.UPSERT_PUSH_NOTIFICATION_INFO,
    });

export const upsertPushNotificationSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.UPSERT_PUSH_NOTIFICATION_INFO_SUCCESS,
    });

export const upsertPushNotificationFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.UPSERT_PUSH_NOTIFICATION_INFO_FAILURE,
    });

export const sendPushNotification = (dispatch) =>
    dispatch({
        type: ACTIONS.SEND_PUSH_NOTIFICATION_INFO,
    });

export const sendPushNotificationSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.SEND_PUSH_NOTIFICATION_INFO_SUCCESS,
    });

export const sendPushNotificationFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.SEND_PUSH_NOTIFICATION_INFO_FAILURE,
    });

export const updateTargetInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.UPDATE_TARGET_INFO,
    });
