import React, { useEffect, useState } from 'react';

import { Modal, Row } from 'antd';

import { ButtonGray, ButtonGreen } from '../../../common/components/buttons/Buttons';
import { confirmationModalClose } from './referralManagementAction';
import { getTranslation } from '../../../common/helpers/locale';
import { useDispatch } from '../../../store/StateProvider';
import { useSetStatusOperationAsyncEndpoint } from '../../../services/referralService';

const ConfirmationModal = ({
    confirmModalVisible,
    id,
    name,
    activeName,
    programStatus,
    isLoading,
}) => {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);

    // ============================================
    // METHODS
    // ============================================

    const closeEditModal = () => confirmationModalClose(dispatch);

    const updateStatus = () => handleSetReferralStatus();

    // ============================================
    // HOOKS
    // ============================================
    const [loading, handleSetReferralStatus] = useSetStatusOperationAsyncEndpoint(
        id,
        programStatus,
    );

    useEffect(() => {
        if (confirmModalVisible && isLoading === false) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [confirmModalVisible, isLoading, handleSetReferralStatus, activeName]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            centered
            closable={false}
            className="referral__confirm-modal"
            footer={
                <Row key="actions" justify="space-between" align="middle">
                    <ButtonGreen
                        label={getTranslation('Confirm')}
                        className="form__submit-button"
                        loading={loading}
                        onClick={updateStatus}
                    />
                    <ButtonGray
                        label={getTranslation('Cancel')}
                        className="form__reset-button"
                        onClick={closeEditModal}
                    />
                </Row>
            }
            onCancel={closeEditModal}
        >
            <Row>
                {
                    <label>
                        {activeName
                            ? getTranslation(
                                  'Are you sure you want to active {name} ? Activating this will result in deactivating {activeName}.',
                                  {
                                      activeName,
                                      name,
                                  },
                              )
                            : getTranslation('Are you sure you want to active {name} ?', {
                                  name,
                              })}
                    </label>
                }
            </Row>
        </Modal>
    );
};

export default ConfirmationModal;
