import React, { useState } from 'react';

import { Table } from 'antd';

import './NewsResultsTable.less';
import { getColumns, getPaginationOptions } from './NewsTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const NewsResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();
    const [langCode, setLangCode] = useState(null);

    const tableData = getStateProp(state, 'news.list', []);
    const paging = getStateProp(state, 'news.paging', {});

    // ============================================
    // METHODS
    // ============================================

    const handleChange = (pagination, filters) => {
        filters.title ? setLangCode(filters.title[0]) : setLangCode(null);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            id="news-table"
            columns={getColumns(dispatch, langCode)}
            pagination={getPaginationOptions(paging, dispatch)}
            dataSource={tableData}
            rowKey={(record) => record.id}
            loading={loading}
            className="news-table"
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            onChange={handleChange}
        />
    );
};

export default NewsResultsTable;
