import React from 'react';

import { Form } from 'antd';

import {
    activatedBankCardSetupModalClose,
    activatedBankCardSetupModalOpen,
    bankCardDropFilters,
    bankCardFilterSet,
} from './activeBankAccountActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { useGetActivatedBankCardsAsyncEndpoint } from '../../../../services/bankAccountService';
import { useStore } from '../../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import ActiveBankAccountResultsTable from './table/ActiveBankAccountResultsTable';
import ActiveBankCardSetupModal from './ActiveBankCardSetupModal';
import BankAccountSetupFilters from '../BankAccountSetupFilters';

const ActiveBankAccount = () => {
    const [state, dispatch] = useStore();

    const modalData = getStateProp(state, 'payments.activeBankAccount.modal');
    const activeBankAccount = getStateProp(state, 'payments.activeBankAccount');

    // ============================================
    // METHODS
    // ============================================

    const onOpenModal = () => activatedBankCardSetupModalOpen(dispatch);

    const onCloseModal = () => activatedBankCardSetupModalClose(dispatch);

    const setFilter = (filter, value) => bankCardFilterSet(dispatch, { filter, value });

    const refreshTable = getStateProp(state, 'payments.activeBankAccount.fetchData', () => {});

    const fetchAll = () => {
        bankCardDropFilters(dispatch);
        handleGetActivatedBankCards();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetActivatedBankCards] = useGetActivatedBankCardsAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <div>
            <Form onFinish={handleGetActivatedBankCards}>
                <BankAccountSetupFilters
                    setFilter={setFilter}
                    bankAccount={activeBankAccount}
                    refreshTable={refreshTable}
                />
                <ActionButtons onOpenModal={onOpenModal} onDropFilters={fetchAll} />
            </Form>
            <ActiveBankAccountResultsTable loading={loading} />
            {modalData.visible && (
                <ActiveBankCardSetupModal modalData={modalData} onCancel={onCloseModal} />
            )}
        </div>
    );
};

export default ActiveBankAccount;
