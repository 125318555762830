export const NOTIFICATION_STATUSES = {
    PENDING: 'Pending',
    VERIFIED: 'Verified',
};

export const TARGET_NOTIFICATION_TYPES = {
    CURRENCY: 'Currency',
    USERNAME: 'Username',
};

export const NOTIFICATION_TYPES = {
    GENERAL: 'General',
    TARGET: 'Target',
};
