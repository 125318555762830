export const ACTIONS = {
    DEACTIVATED_EWALLET_GET: 'DEACTIVATED_EWALLET_GET',
    DEACTIVATED_EWALLET_GET_FAILURE: 'DEACTIVATED_EWALLET_GET_FAILURE',
    DEACTIVATED_EWALLET_GET_SUCCESS: 'DEACTIVATED_EWALLET_GET_SUCCESS',
    DEACTIVATED_EWALLET_UPDATE_FORM_SUBMIT: 'DEACTIVATED_EWALLET_UPDATE_FORM_SUBMIT',
    DEACTIVATED_EWALLET_UPDATE_FORM_SUBMIT_FAILURE:
        'DEACTIVATED_EWALLET_UPDATE_FORM_SUBMIT_FAILURE',
    DEACTIVATED_EWALLET_UPDATE_FORM_SUBMIT_SUCCESS:
        'DEACTIVATED_EWALLET_UPDATE_FORM_SUBMIT_SUCCESS',
    DEACTIVATED_EWALLET_SETUP_MODAL_CLOSE: 'DEACTIVATED_EWALLET_SETUP_MODAL_CLOSE',
    DEACTIVATED_EWALLET_SETUP_MODAL_OPEN: 'DEACTIVATED_EWALLET_SETUP_MODAL_OPEN',
    FILTER_DROP: 'DEACTIVATED_EWALLET_FILTER_DROP',
    FILTER_SET: 'DEACTIVATED_EWALLET_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'DEACTIVATED_EWALLET_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'DEACTIVATED_EWALLET_TABLE_PAGE_SIZE_CHANGE',
    },
};

export const deactivatedEwalletSetupModalOpen = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DEACTIVATED_EWALLET_SETUP_MODAL_OPEN,
    });

export const deactivatedEwalletSetupModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.DEACTIVATED_EWALLET_SETUP_MODAL_CLOSE,
    });

export const deactivatedEwalletGet = (dispatch) =>
    dispatch({
        type: ACTIONS.DEACTIVATED_EWALLET_GET,
    });

export const deactivatedEwalletGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DEACTIVATED_EWALLET_GET_SUCCESS,
    });

export const deactivatedEwalletGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DEACTIVATED_EWALLET_GET_FAILURE,
    });

export const EwalletFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const EwalletDropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTER_DROP,
    });

export const deactivatedEwalletUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.DEACTIVATED_EWALLET_UPDATE_FORM_SUBMIT,
    });

export const deactivatedEwalletUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DEACTIVATED_EWALLET_UPDATE_FORM_SUBMIT_FAILURE,
    });

export const deactivatedEwalletUpdateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.DEACTIVATED_EWALLET_UPDATE_FORM_SUBMIT_SUCCESS,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });
