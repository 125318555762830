import React, { useEffect, useState } from 'react';

import { Col, Divider, Input, Modal, Row } from 'antd';

import {
    ButtonGray,
    ButtonGreen,
    ButtonOrange,
    ButtonRed,
} from '../../../../common/components/buttons/Buttons';
import { PROGRESS_STATUS, VERIFY_ACTION, VERIFY_STATUSES } from '../constants';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import {
    useBatchRejectManualBonusAsyncEndpoint,
    useBatchSubmittManualBonusAsyncEndpoint,
    useBatchUpdateManualBonusAsyncEndpoint,
} from '../../../../services/manualManagementService';
import { useStore } from '../../../../store/StateProvider';
import BatchActionTable from '../table/BatchActionTable';
import _ from 'lodash';

const { TextArea } = Input;

const BatchActionModal = ({ visible, onCancel, loading, onConfirm }) => {
    const [state] = useStore();

    const manualManagement = getStateProp(state, 'bonus.manualManagement');
    const bonusList = getStateProp(manualManagement, 'list', '');
    const batchAction = getStateProp(manualManagement, 'batchAction', '');
    const selectedBonusIds = getStateProp(manualManagement, 'selectedBonusIds', []);
    const title =
        batchAction === VERIFY_ACTION.COMPLETED
            ? getTranslation('Confirm to Complete Running Bonus?')
            : getTranslation(
                  `Confirm To ${
                      batchAction === VERIFY_ACTION.SUBMIT ? 'Approve' : _.capitalize(batchAction)
                  }`,
              );
    const [pendingList, setPendingList] = useState([]);
    const [rejectList, setRejectList] = useState([]);
    const [operatorRemark, setOperatorRemark] = useState('');

    // ============================================
    // METHODS
    // ============================================

    const handleCancel = () => {
        setOperatorRemark('');
        onCancel(false, 'Cancel');
    };

    const onHandleBatchAction = () => {
        let newPendingList = _.forEach(pendingList, (e) => {
            e.operatorRemarks = operatorRemark;
            e.playerId = e.userId;
        });
        if (batchAction === VERIFY_ACTION.REJECT) {
            handleBatchReject(newPendingList);
        } else if (batchAction === VERIFY_ACTION.SUBMIT) {
            handleBatchSubmit(newPendingList);
        } else if (batchAction === VERIFY_ACTION.COMPLETED) {
            handleBatchUpdate(newPendingList, batchAction);
        }
    };

    // ============================================
    // HOOKS
    // ============================================
    useEffect(() => {
        let newPendingList = [];
        let newRejectList = [];

        _.forEach(selectedBonusIds, (e) => {
            let info = _.find(bonusList, { id: e });
            if (
                (info.approvalStatus === VERIFY_STATUSES.SUCCESS &&
                    info.progressStatus === PROGRESS_STATUS.RUNNING &&
                    batchAction !== VERIFY_ACTION.COMPLETED) ||
                (info.approvalStatus === VERIFY_STATUSES.PENDING &&
                    batchAction === VERIFY_ACTION.COMPLETED)
            ) {
                newRejectList.push(info);
            } else {
                newPendingList.push(info);
            }
        });

        setPendingList(newPendingList);
        setRejectList(newRejectList);
    }, [batchAction, bonusList, selectedBonusIds]);

    const [rejecting, handleBatchReject] = useBatchRejectManualBonusAsyncEndpoint(
        setOperatorRemark,
    );
    const [submitting, handleBatchSubmit] = useBatchSubmittManualBonusAsyncEndpoint(
        setOperatorRemark,
    );
    const [updating, handleBatchUpdate] = useBatchUpdateManualBonusAsyncEndpoint(setOperatorRemark);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            title={title}
            closable={false}
            onCancel={handleCancel}
            width={800}
            footer={
                batchAction === VERIFY_ACTION.COMPLETED && pendingList.length
                    ? [
                          <Row key="actions" justify="end" align="middle">
                              <ButtonGreen
                                  label={getTranslation('Confirm')}
                                  loading={updating}
                                  onClick={onHandleBatchAction}
                              />
                              <ButtonGray label={getTranslation('Cancel')} onClick={handleCancel} />
                          </Row>,
                      ]
                    : batchAction === VERIFY_ACTION.REJECT && pendingList.length
                    ? [
                          <Row key="actions" justify="end" align="middle">
                              <ButtonRed
                                  label={getTranslation('Confirm Reject')}
                                  loading={rejecting}
                                  onClick={onHandleBatchAction}
                              />
                              <ButtonGray label={getTranslation('Cancel')} onClick={handleCancel} />
                          </Row>,
                      ]
                    : batchAction === VERIFY_ACTION.SUBMIT && pendingList.length
                    ? [
                          <Row key="actions" justify="end" align="middle">
                              <ButtonOrange
                                  label={getTranslation('Confirm Approve')}
                                  loading={submitting}
                                  onClick={onHandleBatchAction}
                              />
                              <ButtonGray label={getTranslation('Cancel')} onClick={handleCancel} />
                          </Row>,
                      ]
                    : [
                          <Row key="actions" justify="end" align="middle">
                              <ButtonGray label={getTranslation('Cancel')} onClick={handleCancel} />
                          </Row>,
                      ]
            }
        >
            <Row justify="center">
                {getTranslation(`You've selected {number} records`, {
                    number: selectedBonusIds.length,
                })}
            </Row>
            <br />
            <BatchActionTable dataSource={pendingList} />
            {rejectList && rejectList.length ? (
                <>
                    <Divider />
                    <Row justify="center">
                        {getTranslation(`{number} records cannot be processed`, {
                            number: rejectList.length,
                        })}
                    </Row>
                    <br />
                    <BatchActionTable dataSource={rejectList} />
                    <br />
                </>
            ) : (
                <br />
            )}

            <Row className="bonus__form-row">
                <Col span={5}>
                    <label>{getTranslation('Operator Remark *')}</label>
                </Col>
                <Col span={19}>
                    <TextArea
                        onChange={(e) => setOperatorRemark(e.target.value)}
                        value={operatorRemark}
                        disabled={!pendingList.length || rejecting || submitting || updating}
                    />
                </Col>
            </Row>
        </Modal>
    );
};

export default BatchActionModal;
