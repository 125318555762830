import { Redirect, Switch } from 'react-router-dom';
import React from 'react';

import { ROUTES } from '../../common/constants/routing';
import NewsCreate from './news-create/NewsCreate';
import NewsList from './news-list/NewsList';
import PrivateRoute from '../../common/components/PrivateRoute';

const News = () => {
    return (
        <Switch>
            <PrivateRoute exact path={ROUTES.DASHBOARD.NEWS.CREATE} component={NewsCreate} />
            <PrivateRoute path={ROUTES.DASHBOARD.NEWS.LIST} component={NewsList} />
            <Redirect to={ROUTES.DASHBOARD.NEWS.ROOT} />
        </Switch>
    );
};

export default News;
