import React from 'react';

import { Row } from 'antd';

import {
    CurrencyFilter,
    PeriodFilter,
    TransactionNumberFilter,
    UsernameFilter,
} from '../../../common/components/filters';
import { getStateProp } from '../../../common/helpers/misc';
import { turnoverRecordsFilterSet } from './turnoverRecordsActions';
import { useStore } from '../../../store/StateProvider';

const TurnoverRecordsFilters = () => {
    const [state, dispatch] = useStore();

    const { turnoverRecords } = getStateProp(state, 'transactionHistory');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => turnoverRecordsFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <TransactionNumberFilter
                name="transactionNo"
                onFilterChange={setFilter}
                value={getStateProp(turnoverRecords, 'filters.transactionNo')}
            />
            <UsernameFilter
                name="username"
                onFilterChange={setFilter}
                value={getStateProp(turnoverRecords, 'filters.username')}
            />
            <PeriodFilter
                dateFromName="dateFrom"
                dateToName="dateTo"
                onFilterChange={setFilter}
                dateFrom={getStateProp(turnoverRecords, 'filters.dateFrom')}
                dateTo={getStateProp(turnoverRecords, 'filters.dateTo')}
            />
            <CurrencyFilter
                name="currencyCode"
                onFilterChange={setFilter}
                value={getStateProp(turnoverRecords, 'filters.currencyCode')}
            />
        </Row>
    );
};

export default TurnoverRecordsFilters;
