import React from 'react';

import { Row } from 'antd';
import _ from 'lodash';

import {
    PROGRAM_TYPES,
    REFEREE_DEPOSIT_CONDITION,
    REFEREE_WINLOSS_CONDITION,
    REFEREE_WITHDRAWAL_CONDITION,
    REFERRAL_CONVERSTION_RATE_CONDITION,
    TOTAL_REFEREE_CONDITION,
} from '../constants';
import { PeriodFilter } from '../../../common/components/filters';
import { SingleSelectionFilter, TextFilter } from '../filters';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { referralReportFilterSet } from './referralReportActions';
import { useStore } from '../../../store/StateProvider';
import InputGroupFilter from '../filters/InputGroupFilter';

const ReferralReportFilters = () => {
    const [state, dispatch] = useStore();

    const { referralReport } = getStateProp(state, 'referral');
    const filters = getStateProp(referralReport, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => referralReportFilterSet(dispatch, { filter, value });

    const setFilterGroup = (filter, value) => {
        const valueObject = getStateProp(filters, filter, {});
        referralReportFilterSet(dispatch, { filter, value: _.merge(valueObject, value) });
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <TextFilter
                value={getStateProp(filters, 'programCode')}
                onFilterChange={setFilter}
                name="programCode"
                placeholder={getTranslation('Program Code')}
            />
            <TextFilter
                value={getStateProp(filters, 'programName')}
                onFilterChange={setFilter}
                name="programName"
                placeholder={getTranslation('Program Name')}
            />
            <SingleSelectionFilter
                selectionList={PROGRAM_TYPES}
                value={getStateProp(filters, 'programTypes')}
                onFilterChange={setFilter}
                name="programTypes"
                placeholder={getTranslation('Program Type')}
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(filters, 'activeDateFrom')}
                dateFromName="activeDateFrom"
                dateTo={getStateProp(filters, 'activeDateTo')}
                dateToName="activeDateTo"
                prefix="Active"
            />
            <InputGroupFilter
                value={getStateProp(filters, 'totalReferee')}
                placeholder={getTranslation('Total Referee')}
                onFilterChange={setFilterGroup}
                name="totalReferee"
                optionList={TOTAL_REFEREE_CONDITION}
            />
            <TextFilter
                value={getStateProp(filters, 'averageRefereePerDay')}
                onFilterChange={setFilter}
                name="averageRefereePerDay"
                placeholder={getTranslation('AVG of Referee')}
            />
            <InputGroupFilter
                value={getStateProp(filters, 'referralConversionRate')}
                placeholder={getTranslation('Referral Conversion Rate')}
                onFilterChange={setFilterGroup}
                name="referralConversionRate"
                optionList={REFERRAL_CONVERSTION_RATE_CONDITION}
            />
            <InputGroupFilter
                value={getStateProp(filters, 'refereeWithdrawal')}
                placeholder={getTranslation('Referee Withdrawal')}
                onFilterChange={setFilterGroup}
                name="refereeWithdrawal"
                optionList={REFEREE_WITHDRAWAL_CONDITION}
            />
            <InputGroupFilter
                value={getStateProp(filters, 'refereeDeposit')}
                placeholder={getTranslation('Referee Deposit')}
                onFilterChange={setFilterGroup}
                name="refereeDeposit"
                optionList={REFEREE_DEPOSIT_CONDITION}
            />
            <InputGroupFilter
                value={getStateProp(filters, 'refereeWinLoss')}
                placeholder={getTranslation('Referee win/loss')}
                onFilterChange={setFilterGroup}
                name="refereeWinLoss"
                optionList={REFEREE_WINLOSS_CONDITION}
            />
        </Row>
    );
};

export default ReferralReportFilters;
