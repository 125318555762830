import { useCallback, useEffect, useState } from 'react';

import { API } from '../common/constants/api';
import { CONTACT_METHODS, messageStatusMap } from '../components/announcement/constants';
import {
    addMessage,
    addMessageFailure,
    addMessageSuccess,
    verifyRecipient,
    verifyRecipientSuccess,
} from '../components/announcement/message-create/messageCreateActions';
import { clearError } from '../common/actions/errorActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import {
    messageGet,
    messageGetFailure,
    messageGetSuccess,
    messageUpdate,
    messageUpdateFailure,
    messageUpdateSuccess,
} from '../components/announcement/announcement-verification/personal-message/personalMessageActions';
import {
    messageHistoryGet,
    messageHistoryGetFailure,
    messageHistoryGetSuccess,
} from '../components/announcement/announcement-history/personal-message/personalMessageActions';
import { useDispatch, useStore } from '../store/StateProvider';
import { useToasterErrorMessage, useToasterSuccessMessage } from './hooks/useToasterMessage';
import { verifyPendingModificationFailure } from '../components/players/pending-modifications/pendingModificationsActions';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, IDENTITY, ANNOUNCEMENT } = API;

export function useVerifyRecipientEndpoint(personalMessageId) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);
    // ============================================
    // METHODS
    // ============================================

    const handleVerifyRecipient = () => {
        verifyRecipient(dispatch);
        setTrigger(+new Date());
    };

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                userNames: personalMessageId,
            },
        },
        trigger,
        url: `${VERSION.V1}${IDENTITY.VERIFY_USERNAME}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            verifyRecipientSuccess(dispatch, {
                verifiedResults: {
                    invalid: response.data.data.invalid,
                    valid: response.data.data.valid,
                },
            });
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, verifyPendingModificationFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleVerifyRecipient];
}

export function useGetPersonalMessageHistoryAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const personalMessage = getStateProp(state, 'announcement.announcementHistory.personalMessage');
    const { pageNo, pageSize } = getStateProp(personalMessage, 'paging');
    const filters = getStateProp(personalMessage, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetPersonalMessageHistory = useCallback(() => {
        messageHistoryGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                IsHistory: true,
                pageNo,
                pageSize,
                ...filterTrim(filters),
            },
        },
        trigger,
        url: `${VERSION.V1}${ANNOUNCEMENT.PERSONAL_MESSAGE_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            messageHistoryGetSuccess(dispatch, {
                fetchData: handleGetPersonalMessageHistory,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetPersonalMessageHistory, response]);

    useEffect(() => {
        handleGetPersonalMessageHistory();
    }, [handleGetPersonalMessageHistory, pageNo, pageSize]);

    const errorMsg = useError(error, messageHistoryGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetPersonalMessageHistory];
}

export function useGetPersonalMessageAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const personalMessage = getStateProp(
        state,
        'announcement.announcementVerification.personalMessage',
    );
    const { pageNo, pageSize } = getStateProp(personalMessage, 'paging');
    const filters = getStateProp(personalMessage, 'filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetPersonalMessage = useCallback(() => {
        messageGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                IsHistory: false,
                pageNo,
                pageSize,
                ...filterTrim(filters),
            },
        },
        trigger,
        url: `${VERSION.V1}${ANNOUNCEMENT.PERSONAL_MESSAGE_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            messageGetSuccess(dispatch, {
                fetchData: handleGetPersonalMessage,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetPersonalMessage, response]);

    useEffect(() => {
        handleGetPersonalMessage();
    }, [handleGetPersonalMessage, pageNo, pageSize]);

    const errorMsg = useError(error, messageGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetPersonalMessage];
}

export function useCreateMessageAsyncEndpoint(message) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    const { contactMethod, acceptedRecipients, ...rest } = message;

    const userNames = contactMethod === CONTACT_METHODS.USERNAME ? acceptedRecipients : '';
    const phoneNumbers = contactMethod === CONTACT_METHODS.MOBILE ? acceptedRecipients : '';

    // ============================================
    // METHODS
    // ============================================

    const handleCreateMessage = () => {
        addMessage(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                contactMethod,
                phoneNumbers,
                userNames,
                ...rest,
            },
        },
        trigger,
        url: `${VERSION.V1}${ANNOUNCEMENT.PERSONAL_MESSAGE_LIST}/`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            addMessageSuccess(dispatch);
        }
    }, [dispatch, response]);

    useToasterSuccessMessage(response, getTranslation('Message has been sent!'));

    const errorMsg = useError(error, addMessageFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleCreateMessage, response];
}

export function useUpdateMessageAsyncEndpoint(message, status) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);
    const { id, pmStatus, ...rest } = message;

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateMessage = () => {
        messageUpdate(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                ...rest,
                pmStatus: status,
            },
        },
        trigger,
        url: `${VERSION.V1}${ANNOUNCEMENT.PERSONAL_MESSAGE_LIST}/${id}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            messageUpdateSuccess(dispatch);
        }
    }, [dispatch, response]);

    useToasterSuccessMessage(response, getTranslation(messageStatusMap.get(status)));

    const errorMsg = useError(error, messageUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateMessage, response];
}
