import React, { useEffect, useState } from 'react';

import { Modal } from 'antd';

import { ANNOUNCEMENT_STATUSES } from '../../../constants';
import { closeAnnouncementVerifyModal } from '../globalAnnouncementActions';
import { getStateProp } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useStore } from '../../../../../store/StateProvider';
import { useUpdateAnnouncementAsyncEndpoint } from '../../../../../services/announcementService';
import AnnouncementVerificationForm from '../../verify-modal/AnnoucementVerificationForm';

const VerifyAnnouncementModal = ({ visible, onCancel }) => {
    const [state, dispatch] = useStore();
    const [operation, setOperation] = useState(null);

    const selectedAnnouncement = getStateProp(
        state,
        'announcement.announcementVerification.globalAnnouncement.selectedAnnouncement',
        {},
    );
    const refreshTable = getStateProp(
        state,
        'announcement.announcementVerification.globalAnnouncement.fetchData',
        () => {},
    );

    // ============================================
    // METHODS
    // ============================================

    const verifyAnnouncement = () => {
        setOperation(ANNOUNCEMENT_STATUSES.VERIFIED);
        handleUpdateAnnouncement();
    };

    const rejectAnnouncement = () => {
        setOperation(ANNOUNCEMENT_STATUSES.REJECTED);
        handleUpdateAnnouncement();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [
        verifying,
        handleUpdateAnnouncement,
        announcementUpdated,
    ] = useUpdateAnnouncementAsyncEndpoint(selectedAnnouncement, operation);

    useEffect(() => {
        if (announcementUpdated) {
            closeAnnouncementVerifyModal(dispatch);
            refreshTable();
        }
    }, [dispatch, announcementUpdated, refreshTable]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            width={1080}
            visible={visible}
            title={getTranslation('Verify Global Announcement')}
            className="announcement__verify-modal"
            onCancel={onCancel}
            closable={false}
            footer={null}
        >
            <AnnouncementVerificationForm
                onVerify={verifyAnnouncement}
                onCancel={onCancel}
                selectedAnnouncement={selectedAnnouncement}
                verifying={verifying && operation === ANNOUNCEMENT_STATUSES.VERIFIED}
                rejecting={verifying && operation === ANNOUNCEMENT_STATUSES.REJECTED}
                onReject={rejectAnnouncement}
            />
        </Modal>
    );
};

export default VerifyAnnouncementModal;
