import React from 'react';

import { StopOutlined } from '@ant-design/icons';

import { ButtonRed } from './Buttons';
import { getTranslation } from '../../../common/helpers/locale';

const RejectButton = ({ onReject }) => {
    return (
        <ButtonRed icon={<StopOutlined />} className="reject-button" onClick={onReject}>
            {getTranslation('Reject Selected')}
        </ButtonRed>
    );
};

export default RejectButton;
