import React from 'react';

import { Col, Form, Input, Modal, Row } from 'antd';

import './ConfirmModal.less';
import { ButtonGray, ButtonRed } from '../../../../../common/components/buttons/Buttons';
import { PAYMENT_ORDER_OPERATIONS } from '../../../../../common/constants/misc';
import { VERIFY_MODAL_TYPES } from '../constants';
import {
    closeConfirmRejectModal,
    cryptoWithdrawalOperation,
    withdrawalsRejectInfoSet,
} from '../cryptoWithdrawalActions';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useDispatch } from '../../../../../store/StateProvider';

const { TextArea } = Input;

const ConfirmRejectModal = ({ onCancel, visible }) => {
    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ============================================

    const confirmReject = () => {
        cryptoWithdrawalOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.REJECT,
        });

        closeConfirmRejectModal(dispatch);
    };

    const onValueChange = (name, value) => withdrawalsRejectInfoSet(dispatch, { name, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            title={getTranslation('Confirm to Reject?')}
            centered
            closable={false}
            className="deposit__confirm-modal"
            onCancel={() => onCancel(VERIFY_MODAL_TYPES.REJECT)}
            footer={
                <Row key="actions" justify="space-between" align="middle">
                    <ButtonRed
                        form="reject-crypto-withdrawal"
                        label={getTranslation('Reject')}
                        htmlType="submit"
                    />
                    <ButtonGray
                        label={getTranslation('Cancel')}
                        onClick={() => onCancel(VERIFY_MODAL_TYPES.REJECT)}
                    />
                </Row>
            }
        >
            <Form name="reject-crypto-withdrawal" onFinish={confirmReject}>
                <Row>
                    <Col span={6}>
                        <label className="modal-description">
                            {getTranslation('Remarks')} <span className="form__required">*</span> :
                        </label>
                    </Col>
                    <Col span={18}>
                        <Form.Item
                            name="remarks"
                            rules={[
                                {
                                    message: getTranslation('Please input reason !'),
                                    required: true,
                                },
                            ]}
                        >
                            <TextArea onChange={(e) => onValueChange('remarks', e.target.value)} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ConfirmRejectModal;
