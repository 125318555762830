import React from 'react';

import { Input } from 'antd';
import { REFERRAL_INFO_KEYS } from '../../../common/constants/misc';
import { getIntOrFloat } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';

export const getRefereeColumns = (currency, onDetailsChange, isDisabled) => {
    const onValueChange = (selectedTier, key, value) =>
        onDetailsChange(currency, 0, key, getIntOrFloat(value, true));

    const renderDetailsInput = (record, key) => {
        const value = record[key].toFixed(2);
        return (
            <Input
                justify="right"
                value={value}
                onChange={(e) => onValueChange(record.tierLevel, key, e.target.value)}
                disabled={isDisabled}
            />
        );
    };

    let columns = [
        {
            dataIndex: 'minDeposit',
            key: 'minDeposit',
            render: (value, record) => renderDetailsInput(record, REFERRAL_INFO_KEYS.MIN_DEPOSIT),
            title: getTranslation('Min Deposit ({currency})', { currency }),
        },
        {
            dataIndex: 'refereeAmount',
            key: 'refereeAmount',
            render: (value, record) =>
                renderDetailsInput(record, REFERRAL_INFO_KEYS.REFEREE_AMOUNT),
            title: getTranslation('Referee Rewards ({currency})', { currency }),
        },
        {
            dataIndex: 'refereeTurnoverRequired',
            key: 'refereeTurnoverRequired',
            render: (value, record) =>
                renderDetailsInput(record, REFERRAL_INFO_KEYS.REFEREE_TURNOVER_REQUIRED),
            title: getTranslation('Effective Turnover Required (Referee Rewards)'),
        },
        {
            dataIndex: 'refereeTurnoverAmount',
            key: 'refereeTurnoverAmount',
            title: getTranslation('Total Effective Turnover (Referee Rewards)'),
        },
    ];

    return columns;
};
