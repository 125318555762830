import React from 'react';

import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changeActiveBannerPageNumber, changeActiveBannerPageSize } from '../bannerSortingActions';
import { getTranslation } from '../../../../common/helpers/locale';
import ImgIcon from '../../../../common/components/ImgIcon';

export const getColumns = () => {
    const columns = [
        {
            dataIndex: 'code',
            title: getTranslation('Banner Code'),
        },
        {
            dataIndex: 'banner',
            render: (value, record) => {
                const imageUrl = record.activeBannerDetails[0]?.imageUrl;

                return imageUrl && <ImgIcon icon={imageUrl} style={{ maxWidth: '100%' }} />;
            },
            title: getTranslation('Banner'),
            width: 300,
        },
        {
            dataIndex: 'bannerType',
            title: getTranslation('Type'),
        },
        {
            dataIndex: 'sortingOrder',
            title: getTranslation('Sorting Order'),
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changeActiveBannerPageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) =>
    changeActiveBannerPageSize(dispatch, pageSize);
