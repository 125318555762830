import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions, getRowSelectionProps } from './RebateGeneratorTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const RebateGeneratorResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const { rebateGenerator } = getStateProp(state, 'rebate', {});
    const tableData = getStateProp(rebateGenerator, 'list', []);
    const paging = getStateProp(rebateGenerator, 'paging', {});
    const selectedId = getStateProp(rebateGenerator, 'selectedId', []);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns(dispatch)}
            rowKey={(record) => record.transactionNo}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            dataSource={tableData}
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
            rowSelection={getRowSelectionProps(dispatch, selectedId)}
        />
    );
};

export default RebateGeneratorResultsTable;
