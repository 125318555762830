import dailyLoginManagementReducer from './daily-login-management/dailyLoginManagementReducer';
import dailyLoginSetupReducer from './daily-login-setup/dailyLoginSetupReducer';

export default function rewardsManagementReducer(rewardsManagement = {}, action) {
    const { dailyLoginSetup, dailyLoginManagement } = rewardsManagement;

    return {
        dailyLoginManagement: dailyLoginManagementReducer(dailyLoginManagement, action),
        dailyLoginSetup: dailyLoginSetupReducer(dailyLoginSetup, action),
    };
}
