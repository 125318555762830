import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { CRYPTO_ORDER_STATUS } from '../cryptocurrency/withdrawal/constants';
import { getTranslation } from '../../../common/helpers/locale';

const CryptoOrderStatusFilter = ({ value, onFilterChange }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('orderStatus', value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear
            placeholder={getTranslation('Status')}
            className="filter"
        >
            {_.map(_.entries(CRYPTO_ORDER_STATUS), ([key, status]) => (
                <Select.Option value={status} key={key}>
                    {getTranslation(status).toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
};

export default CryptoOrderStatusFilter;
