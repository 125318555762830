import React from 'react';

import { Modal, Row } from 'antd';

import { ButtonGray, ButtonRed } from '../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../common/helpers/locale';

const DeleteConfirmModal = ({ visible, onCancel, handleDeleteFiles, deleting, selectedFiles }) => {
    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={getTranslation('Confirm to Delete?')}
            centered
            closable={false}
            footer={
                <Row key="actions" justify="space-between" align="middle">
                    <ButtonRed
                        label={getTranslation('Delete')}
                        loading={deleting}
                        onClick={handleDeleteFiles}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>
            }
        >
            <span>
                {getTranslation("You've selected {selectedRows} records", {
                    selectedRows: selectedFiles && selectedFiles.length,
                })}
            </span>
        </Modal>
    );
};

export default DeleteConfirmModal;
