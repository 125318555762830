import React from 'react';

import { Row } from 'antd';

import { BonusCodeFilter, DepositTransactionNoFilter, VerifyStatusFilter } from '../filters';
import {
    BonusNameFilter,
    CurrencyFilter,
    PeriodFilter,
    PromoCodeFilter,
    TransactionNumberFilter,
    UsernameFilter,
} from '../../../common/components/filters';
import { getStateProp } from '../../../common/helpers/misc';
import { manualManagementFilterSet } from './manualManagementActions';
import { useStore } from '../../../store/StateProvider';

const ManualManagementFilters = () => {
    const [state, dispatch] = useStore();

    const manualManagement = getStateProp(state, 'bonus.manualManagement');
    const bonusCodeList = getStateProp(manualManagement, 'bonusCodeList', []);

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => {
        if (filter === 'dateFrom' || filter === 'dateTo') {
            manualManagementFilterSet(dispatch, { filter: 'processDateFrom', value: null });
            manualManagementFilterSet(dispatch, { filter: 'processDateTo', value: null });
        }

        if (filter === 'processDateFrom' || filter === 'processDateTo') {
            manualManagementFilterSet(dispatch, { filter: 'dateFrom', value: null });
            manualManagementFilterSet(dispatch, { filter: 'dateTo', value: null });
        }

        manualManagementFilterSet(dispatch, { filter, value });
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(manualManagement, 'filters.userName')}
                name="userName"
            />
            <TransactionNumberFilter
                onFilterChange={setFilter}
                value={getStateProp(manualManagement, 'filters.transNo')}
                name="transNo"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(manualManagement, 'filters.dateFrom')}
                dateFromName="dateFrom"
                dateTo={getStateProp(manualManagement, 'filters.dateTo')}
                dateToName="dateTo"
                prefix="Request"
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(manualManagement, 'filters.currency')}
                name="currency"
            />
            <BonusNameFilter
                onFilterChange={setFilter}
                value={getStateProp(manualManagement, 'filters.bonusName')}
            />
            <DepositTransactionNoFilter
                onFilterChange={setFilter}
                value={getStateProp(manualManagement, 'filters.depositTransactionNo')}
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(manualManagement, 'filters.processDateFrom')}
                dateFromName="processDateFrom"
                dateTo={getStateProp(manualManagement, 'filters.processDateTo')}
                dateToName="processDateTo"
                prefix="Process"
            />
            <VerifyStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(manualManagement, 'filters.status')}
            />
            <BonusCodeFilter
                onFilterChange={setFilter}
                value={getStateProp(manualManagement, 'filters.bonusCode', '')}
                bonusCodeList={bonusCodeList}
            />
            <PromoCodeFilter
                onFilterChange={setFilter}
                value={getStateProp(manualManagement, 'filters.promoCode', '')}
            />
        </Row>
    );
};

export default ManualManagementFilters;
