import React from 'react';

import { Tabs } from 'antd';
import _ from 'lodash';

import { getLanguageByCode } from '../../../common/helpers/locale';
import NewsContentEditor from './NewsContentEditor';

const { TabPane } = Tabs;

const NewsTabs = ({ newsArticles, selectedLanguage, onContentChange }) => {
    // ============================================
    // RENDER
    // ============================================
    return (
        <Tabs className="news__form__tabs">
            {_.map(selectedLanguage, (value) => {
                const tabTitle = getLanguageByCode(value);
                const sortedContent = _.find(newsArticles, (content) => {
                    return content.languageCode === value;
                });

                return (
                    <TabPane tab={tabTitle} key={value}>
                        <NewsContentEditor
                            content={sortedContent}
                            langCode={value}
                            onContentChange={onContentChange}
                        />
                    </TabPane>
                );
            })}
        </Tabs>
    );
};

export default NewsTabs;
