import React from 'react';

import { Card } from 'antd';

import { getTranslation } from '../../../common/helpers/locale';
import {
    useDepositCorrectionOperation,
    useGetEwalletDetailsAsyncEndpoint,
    useGetPlayerDetailsAsyncEndpoint,
    useGetEwalletTransfersAsyncEndpoint,
} from '../../../services/ewalletCorrectionService';
import EwalletCorrectionForm from './EwalletCorrectionForm';

const EwalletCorrection = () => {
    // ============================================
    // HOOKS
    // ============================================

    const [operationLoading, operationSuccess] = useDepositCorrectionOperation();
    const [getPlayerDetailsLoading] = useGetPlayerDetailsAsyncEndpoint();
    const [getEwalletTransfersLoading] = useGetEwalletTransfersAsyncEndpoint();
    const [getEwalletLoading] = useGetEwalletDetailsAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('E-Wallet Correction')} className="ewallet-correction-card">
            <EwalletCorrectionForm
                operationLoading={operationLoading}
                operationSuccess={operationSuccess}
                playerDetailsLoading={getPlayerDetailsLoading}
                ewalletTransfersLoading={getEwalletTransfersLoading}
                getEwalletLoading={getEwalletLoading}
            />
        </Card>
    );
};

export default EwalletCorrection;
