import React from 'react';

import { Col, Row } from 'antd';
import _ from 'lodash';

import './TotalBalance.less';
import { CURRENCIES } from '../../common/constants/localization';
import { TOTAL_BALANCE_TYPES } from '../constants/misc';
import { getTranslation } from '../helpers/locale';

const TotalBalance = ({ pageBalance, totalBalance, grandTotal, pageTotal }) => {
    // ============================================
    // METHODS
    // ============================================

    const getTotalBalanceByCurrency = (currencyCode, type) => {
        const balance = _.find(
            type === TOTAL_BALANCE_TYPES.TOTAL_BALANCE ? totalBalance : pageBalance,
            (balance) => {
                return balance.currencyCode === currencyCode;
            },
        );

        if (typeof balance === 'undefined') return 0;

        return Number(balance.amount);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row>
            <Col className="page-balance">
                {_.map(CURRENCIES, (currency) => {
                    return (
                        <Row key={currency.currency}>
                            <span className="page-balance__label">
                                {`${getTranslation('Page Balance')} (${currency.currency})`}
                            </span>
                            {getTotalBalanceByCurrency(
                                currency.currency,
                                TOTAL_BALANCE_TYPES.PAGE_BALANCE,
                            ).toFixed(2)}
                        </Row>
                    );
                })}
                <Row>
                    <span className="page-balance__label">
                        {getTranslation('Total Page Balance')} (MYR)
                    </span>
                    {pageTotal.toFixed(2)}
                </Row>
            </Col>

            {totalBalance && (
                <Col className="page-balance">
                    {_.map(CURRENCIES, (currency) => {
                        return (
                            <Row key={currency.currency}>
                                <span className="page-balance__label">
                                    {`${getTranslation('Total Balance')} (${currency.currency})`}
                                </span>
                                {getTotalBalanceByCurrency(
                                    currency.currency,
                                    TOTAL_BALANCE_TYPES.TOTAL_BALANCE,
                                ).toFixed(2)}
                            </Row>
                        );
                    })}
                    <Row>
                        <span className="page-balance__label">
                            {getTranslation('Total Page Balance')} (MYR)
                        </span>
                        {grandTotal.toFixed(2)}
                    </Row>
                </Col>
            )}
        </Row>
    );
};

export default TotalBalance;
