export const ACTIONS = {
    ACTIVE_EWALLET_GET: 'ACTIVE_EWALLET_GET',
    ACTIVE_EWALLET_GET_FAILURE: 'ACTIVE_EWALLET_GET_FAILURE',
    ACTIVE_EWALLET_GET_SUCCESS: 'ACTIVE_EWALLET_GET_SUCCESS',
    EWALLET_SETUP_FORM_SUBMIT: 'EWALLET_SETUP_FORM_SUBMIT',
    EWALLET_SETUP_FORM_SUBMIT_FAILURE: 'EWALLET_SETUP_FORM_SUBMIT_FAILURE',
    EWALLET_SETUP_FORM_SUBMIT_SUCCESS: 'EWALLET_SETUP_FORM_SUBMIT_SUCCESS',
    EWALLET_UPDATE_FORM_SUBMIT: 'EWALLET_UPDATE_FORM_SUBMIT',
    EWALLET_UPDATE_FORM_SUBMIT_FAILURE: 'EWALLET_UPDATE_FORM_SUBMIT_FAILURE',
    EWALLET_UPDATE_FORM_SUBMIT_SUCCESS: 'EWALLET_UPDATE_FORM_SUBMIT_SUCCESS',
    ACTIVE_EWALLET_SETUP_MODAL_CLOSE: 'ACTIVE_EWALLET_SETUP_MODAL_CLOSE',
    ACTIVE_EWALLET_SETUP_MODAL_OPEN: 'ACTIVE_EWALLET_SETUP_MODAL_OPEN',
    FILTER_SET: 'FILTER_SET',
    FILTER_DROP: 'FILTER_DROP',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'ACTIVE_EWALLET_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'ACTIVE_EWALLET_TABLE_PAGE_SIZE_CHANGE',
    },
};

export const activatedEwalletSetupModalOpen = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ACTIVE_EWALLET_SETUP_MODAL_OPEN,
    });

export const activatedEwalletSetupModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.ACTIVE_EWALLET_SETUP_MODAL_CLOSE,
    });

export const activeEwalletGet = (dispatch) =>
    dispatch({
        type: ACTIONS.ACTIVE_EWALLET_GET,
    });

export const activeEwalletGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ACTIVE_EWALLET_GET_SUCCESS,
    });

export const activeEwalletGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ACTIVE_EWALLET_GET_FAILURE,
    });

export const ewalletFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const ewalletDropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTER_DROP,
    });

export const ewalletSetup = (dispatch) =>
    dispatch({
        type: ACTIONS.EWALLET_SETUP_FORM_SUBMIT,
    });

export const ewalletSetupFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.EWALLET_SETUP_FORM_SUBMIT_FAILURE,
    });

export const ewalletSetupSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.EWALLET_SETUP_FORM_SUBMIT_SUCCESS,
    });

export const activatedEwalletUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.EWALLET_UPDATE_FORM_SUBMIT,
    });

export const activatedEwalletUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.EWALLET_UPDATE_FORM_SUBMIT_FAILURE,
    });

export const activatedEwalletUpdateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.EWALLET_UPDATE_FORM_SUBMIT_SUCCESS,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });
