import React from 'react';

import { Row } from 'antd';

import {
    PeriodFilter,
    TransactionNumberFilter,
    TransactionStatusFilter,
    UsernameFilter,
} from '../../../common/components/filters';
import { SubmitByFilter } from '../filters';
import { affiliateRecordsFilterSet } from './affiliateRecordsActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';

const AffiliateRecordFilters = () => {
    const [state, dispatch] = useStore();

    const { affiliateRecords } = getStateProp(state, 'transactionHistory');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => affiliateRecordsFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(affiliateRecords, 'filters.playerUserName')}
                name="playerUserName"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(affiliateRecords, 'filters.requestedDateFrom')}
                dateFromName="requestedDateFrom"
                dateTo={getStateProp(affiliateRecords, 'filters.requestedDateTo')}
                dateToName="requestedDateTo"
            />
            <TransactionNumberFilter
                onFilterChange={setFilter}
                value={getStateProp(affiliateRecords, 'filters.transactionNo')}
                name="transactionNo"
            />
            <TransactionStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(affiliateRecords, 'filters.transactionStatuses')}
            />
            <SubmitByFilter
                onFilterChange={setFilter}
                value={getStateProp(affiliateRecords, 'filters.requesterUserName')}
                name="requesterUserName"
                placeholder={getTranslation('Applicant Username')}
            />
        </Row>
    );
};

export default AffiliateRecordFilters;
