import React from 'react';

import { Table } from 'antd';

import { getColumns, getPaginationOptions } from './ReferralRecordsTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const ReferralRecordResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const tableData = getStateProp(state, 'transactionHistory.referralRecords.list', []);
    const paging = getStateProp(state, 'transactionHistory.referralRecords.paging', {});

    // ============================================
    // RENDER
    // ============================================
    return (
        <Table
            columns={getColumns()}
            rowKey={(record) => record.transactionNo}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
            dataSource={tableData}
            loading={loading}
            pagination={getPaginationOptions(paging, dispatch)}
        />
    );
};

export default ReferralRecordResultsTable;
