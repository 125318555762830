import React from 'react';

import _ from 'lodash';

import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { TRANSACTION_STATUSES } from '../../../../common/constants/misc';
import { changePageNumber, changePageSize } from '../withdrawalsActions';
import { getStatusColor, sortTableByDataType } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import OperationButton from '../verification/OperationButton';
import ViewRecordButton from '../verification/ViewRecordButton';

export const getColumns = (dispatch, selectedColumns) => {
    const columns = [
        {
            dataIndex: 'requestedAt',
            dataType: 'date',
            render: (value) => RenderDateFormat(value),
            title: getTranslation('Request Time'),
        },
        {
            dataIndex: 'verifiedAt',
            dataType: 'date',
            render: (value) => RenderDateFormat(value),
            title: getTranslation('Audit Time'),
        },
        {
            dataIndex: 'transactionNo',
            render: (text, record) =>
                _.includes(
                    [TRANSACTION_STATUSES.SUCCESS, TRANSACTION_STATUSES.REJECTED],
                    record.transactionStatus,
                ) ? (
                    <ViewRecordButton transaction={record} dispatch={dispatch} />
                ) : (
                    <span style={{ wordBreak: 'break-all' }}>{text}</span>
                ),
            title: getTranslation('Task Transaction Number'),
        },
        { dataIndex: 'playerUserName', title: getTranslation('Username') },
        { dataIndex: 'memberLevel', title: getTranslation('Member Level') },
        { dataIndex: 'trustLevel', title: getTranslation('Trust Level') },
        { dataIndex: 'affiliateUserName', title: getTranslation('Affiliate') },
        { dataIndex: 'referralUserName', title: getTranslation('Referral') },
        { dataIndex: 'amount', title: getTranslation('Withdrawal Amount') },
        { dataIndex: 'currencyCode', title: getTranslation('Currency') },
        { dataIndex: 'previousDepositAmount', title: getTranslation('Previous Deposit Amount') },
        { dataIndex: 'cardHolderName', title: getTranslation('Member Account Name') },
        { dataIndex: 'bankCardNo', title: getTranslation('Member Account Number') },
        { dataIndex: 'bankName', title: getTranslation("Member's Bank") },
        { dataIndex: 'requesterUserName', title: getTranslation('Applicant') },
        {
            dataIndex: 'orderStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>
                    {getTranslation(value).toLocaleUpperCase()}
                </span>
            ),
            title: getTranslation('Status'),
        },
        {
            dataIndex: 'processedAt',
            dataType: 'date',
            render: (value) => RenderDateFormat(value),
            title: getTranslation('Process Time'),
        },
        {
            dataIndex: 'withdrawalFee',
            dataType: 'nanInt',
            render: (value) => <span>{!isNaN(parseInt(value)) ? value : 'NA'}</span>,
            title: getTranslation('Withdrawal Fee'),
        },
        {
            dataIndex: 'transactionStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>
                    {getTranslation(value).toLocaleUpperCase()}
                </span>
            ),
            title: getTranslation('Transaction Status'),
        },
        {
            dataIndex: 'operation',
            dataType: 'operation',
            render: (text, record) => <OperationButton transaction={record} dispatch={dispatch} />,
            title: getTranslation('Operation'),
            width: 80,
        },
    ];

    return columns
        .filter((column) => _.map(selectedColumns, 'key').includes(column.dataIndex))
        .map((colProps) => ({
            ...colProps,
            sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
        }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);

export const withdrawalRecordsExport = ({ onlyMeta }) => {
    if (onlyMeta) {
        return {
            operation: [
                {
                    dataIndex: 'processorUserName',
                    title: getTranslation('Operation'),
                },
            ],
        };
    }

    let messageArray = [];
    return messageArray;
};
