import { useHistory } from 'react-router-dom';
import React from 'react';

import { Card } from 'antd';

import { ButtonGreen } from '../../../../common/components/buttons/Buttons';
import { ROUTES } from '../../../../common/constants/routing';
import { getTranslation } from '../../../../common/helpers/locale';
import { resetGroupAccessRecord } from '../groupAuthoritySettingActions';
import { useDispatch } from '../../../../store/StateProvider';
import { useGetGroupAccessListAsyncEndpoint } from '../../../../services/groupAuthoritySettingService';
import DeleteGroupAuthorityRecordModal from './modal/DeleteGroupAuthorityRecordModal';
import GroupAuthoritySettingResultsTable from './GroupAuthoritySettingListResultTable';

const GroupAuthoritySettingList = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ============================================

    const handleAddGroup = () => {
        resetGroupAccessRecord(dispatch);
        history.push(ROUTES.DASHBOARD.ADMINISTRATION.GROUP_AUTHORITY.ADD);
    };

    // ============================================
    // HOOKS
    // ============================================

    const [groupAccessLoading] = useGetGroupAccessListAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Group Authority Setting')}>
            <ButtonGreen onClick={handleAddGroup}>{getTranslation('Add Group')}</ButtonGreen>

            <GroupAuthoritySettingResultsTable loading={groupAccessLoading} />

            <DeleteGroupAuthorityRecordModal />
        </Card>
    );
};

export default GroupAuthoritySettingList;
