export const NEWS_STATUSES = {
    PENDING: 'Pending',
    PUBLISHED: 'Published',
    REJECTED: 'Rejected',
};

export const NEWS_CATEGORIES = [
    {
        key: 1,
        label: 'Dota 2',
    },
    {
        key: 2,
        label: 'League of Legends',
    },
    {
        key: 3,
        label: 'CS:GO',
    },
    {
        key: 4,
        label: 'King of Glory',
    },
    {
        key: 5,
        label: 'Valorant',
    },
    {
        key: 6,
        label: 'StarCraft 2',
    },
    {
        key: 7,
        label: 'Call of Duty',
    },
    {
        key: 8,
        label: 'Arena of Valor',
    },
    {
        key: 9,
        label: 'Overwatch',
    },
    {
        key: 10,
        label: 'PUBG',
    },
    {
        key: 11,
        label: 'Rainbow Six',
    },
    {
        key: 12,
        label: 'Starcraft',
    },
    {
        key: 13,
        label: 'Street Fighter 5',
    },
    {
        key: 14,
        label: 'WarCraft 3',
    },
    {
        key: 15,
        label: 'Rocket League',
    },
    {
        key: 16,
        label: 'Hearthstone',
    },
    {
        key: 17,
        label: 'Artifact',
    },
    {
        key: 18,
        label: 'Gaming',
    },
    {
        key: 19,
        label: 'Mobile Legend',
    },
    {
        key: 20,
        label: 'FIFA',
    },
    {
        key: 21,
        label: 'NBA 2K18',
    },
    {
        key: 22,
        label: 'Heroes of the Storm',
    },
    {
        key: 23,
        label: 'Fortnite',
    },
    {
        key: 24,
        label: 'Game for Peace',
    },
    {
        key: 25,
        label: 'Quake',
    },
];
