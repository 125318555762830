export const ACTIONS = {
    ACTIVE_DAILY_LOGIN_GET: 'ACTIVE_DAILY_LOGIN_GET',
    ACTIVE_DAILY_LOGIN_GET_FAILURE: 'ACTIVE_DAILY_LOGIN_GET_FAILURE',
    ACTIVE_DAILY_LOGIN_GET_SUCCESS: 'ACTIVE_DAILY_LOGIN_GET_SUCCESS',
    CONFIRM_MODAL_CLOSE: 'CONFIRM_MODAL_CLOSE',
    DAILY_LOGIN_FILTER_DROP: 'DAILY_LOGIN_FILTER_DROP',
    DAILY_LOGIN_FILTER_SET: 'DAILY_LOGIN_FILTER_SET',
    DAILY_LOGIN_GET: 'DAILY_LOGIN_GET',
    DAILY_LOGIN_GET_BY_ID: 'DAILY_LOGIN_GET_BY_ID',
    DAILY_LOGIN_GET_BY_ID_FAILURE: 'DAILY_LOGIN_GET_BY_ID_FAILURE',
    DAILY_LOGIN_GET_BY_ID_SUCCESS: 'DAILY_LOGIN_GET_BY_ID_SUCCESS',
    DAILY_LOGIN_GET_FAILURE: 'DAILY_LOGIN_GET_FAILURE',
    DAILY_LOGIN_GET_SUCCESS: 'DAILY_LOGIN_GET_SUCCESS',
    DAILY_LOGIN_UPDATE: 'DAILY_LOGIN_UPDATE',
    DAILY_LOGIN_UPDATE_FAILURE: 'DAILY_LOGIN_UPDATE_FAILURE',
    DAILY_LOGIN_UPDATE_STATUS: 'DAILY_LOGIN_UPDATE_STATUS',
    DAILY_LOGIN_UPDATE_STATUS_FAILURE: 'DAILY_LOGIN_UPDATE_STATUS_FAILURE',
    DAILY_LOGIN_UPDATE_STATUS_SUCCESS: 'DAILY_LOGIN_UPDATE_STATUS_SUCCESS',
    DAILY_LOGIN_UPDATE_SUCCESS: 'DAILY_LOGIN_UPDATE_SUCCESS',
    EDIT_DAILY_LOGIN_INFO: 'EDIT_DAILY_LOGIN_INFO',
    EDIT_MODAL_CLOSE: 'DAILY_LOGIN_EDIT_MODAL_CLOSE',
    EDIT_MODAL_OPEN: 'DAILY_LOGIN_EDIT_MODAL_OPEN',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'DAILY_LOGIN_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'DAILY_LOGIN_TABLE_PAGE_SIZE_CHANGE',
    },
};

export const dailyLoginListGet = (dispatch) =>
    dispatch({
        type: ACTIONS.DAILY_LOGIN_GET,
    });

export const dailyLoginListGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DAILY_LOGIN_GET_SUCCESS,
    });

export const dailyLoginListGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DAILY_LOGIN_GET_FAILURE,
    });

export const dailyLoginEditModalOpen = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.EDIT_MODAL_OPEN,
    });

export const dailyLoginEditModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.EDIT_MODAL_CLOSE,
    });

export const dailyLoginEdit = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.EDIT_DAILY_LOGIN_INFO,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const setFilters = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DAILY_LOGIN_FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.DAILY_LOGIN_FILTER_DROP,
    });

export const dailyLoginByIdGet = (dispatch) =>
    dispatch({
        type: ACTIONS.DAILY_LOGIN_GET_BY_ID,
    });

export const dailyLoginByIdGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DAILY_LOGIN_GET_BY_ID_SUCCESS,
    });

export const dailyLoginByIdGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DAILY_LOGIN_GET_BY_ID_FAILURE,
    });

export const dailyLoginUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.DAILY_LOGIN_UPDATE,
    });

export const dailyLoginUpdateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.DAILY_LOGIN_UPDATE_SUCCESS,
    });

export const dailyLoginUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DAILY_LOGIN_UPDATE_FAILURE,
    });

export const dailyLoginStatusUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.DAILY_LOGIN_UPDATE_STATUS,
    });

export const dailyLoginStatusUpdateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.DAILY_LOGIN_UPDATE_STATUS_SUCCESS,
    });

export const dailyLoginStatusUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DAILY_LOGIN_UPDATE_STATUS_FAILURE,
    });

export const activeDailyLoginGet = (dispatch) =>
    dispatch({
        type: ACTIONS.ACTIVE_DAILY_LOGIN_GET,
    });

export const activeDailyLoginGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ACTIVE_DAILY_LOGIN_GET_SUCCESS,
    });

export const activeDailyLoginGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ACTIVE_DAILY_LOGIN_GET_FAILURE,
    });

export const dailyLoginConfirmModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_MODAL_CLOSE,
    });
