import React from 'react';

import { Input } from 'antd';

import { getTranslation } from '../../../common/helpers/locale';

const DomainFilter = ({ value, onFilterChange }) => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <Input
            value={value}
            onChange={(e) => onFilterChange('merchantDomain', e.target.value)}
            placeholder={getTranslation('Merchant Domain')}
            className="filter"
        />
    );
};

export default DomainFilter;
