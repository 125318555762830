import React from 'react';

import { Row } from 'antd';

import { ButtonLightBlue } from '../../../common/components/buttons/Buttons';
import { DropButton, ExportButton, SearchButton } from '../../../common/components/buttons';
import ColumnSelectionButton from '../../../common/components/buttons/ColumnSelectionButton';
import CreatePlayerButton from '../buttons/CreatePlayerButton';

const ActionButtons = ({
    onDropFilters,
    onExport,
    onCreateNewPlayer,
    onColumnSelect,
    onUpdatePlayerGameAccess,
}) => {
    return (
        <Row align="middle" justify="end" className="action-buttons__container">
            <SearchButton htmlType="submit" />
            <DropButton onDropFilters={onDropFilters} />
            <ExportButton onExport={onExport} />
            <CreatePlayerButton onCreateNewPlayer={onCreateNewPlayer} />
            <ColumnSelectionButton onColumnSelect={onColumnSelect} />
            <ButtonLightBlue label="Update game access" onClick={onUpdatePlayerGameAccess} />
        </Row>
    );
};

export default ActionButtons;
