import React from 'react';

import { Row } from 'antd';

import { CurrencyFilter, PeriodFilter, UsernameFilter } from '../../../common/components/filters';
import { RebateGeneratorStatusFilter } from '../filters';
import { getStateProp } from '../../../common/helpers/misc';
import { rebateGeneratorFilterSet } from './rebateGeneratorActions';
import { useStore } from '../../../store/StateProvider';

const RebateGeneratorFilters = () => {
    const [state, dispatch] = useStore();

    const { rebateGenerator } = getStateProp(state, 'rebate');

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => rebateGeneratorFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(rebateGenerator, 'filters.userName')}
                name="userName"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(rebateGenerator, 'filters.dateFrom')}
                dateFromName="dateFrom"
                dateTo={getStateProp(rebateGenerator, 'filters.dateTo')}
                dateToName="dateTo"
            />
            <CurrencyFilter
                name="allowCurrency"
                onFilterChange={setFilter}
                value={getStateProp(rebateGenerator, 'filters.allowCurrency')}
                mode="multiple"
            />
            <RebateGeneratorStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(rebateGenerator, 'filters.status')}
            />
        </Row>
    );
};

export default RebateGeneratorFilters;
