import React, { useEffect } from 'react';

import { Col, Form, Input, Modal, Row } from 'antd';

import './RejectModal.less';
import { ButtonGray, ButtonRed } from '../../../../common/components/buttons/Buttons';
import { PAYMENT_ORDER_OPERATIONS } from '../../../../common/constants/misc';
import {
    closeRejectModal,
    performWithdrawalsOperation,
    withdrawalsRejectInfoSet,
} from '../withdrawalsActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useStore } from '../../../../store/StateProvider';

const { TextArea } = Input;

const RejectModal = ({ visible, onCancel, operationPending }) => {
    const [state, dispatch] = useStore();
    const withdrawals = getStateProp(state, 'payments.withdrawals');
    const remarks = getStateProp(withdrawals, 'operation.transaction.processorRemarks');
    const isRecord = getStateProp(withdrawals, 'operation.isRecord', false);
    const [form] = Form.useForm();
    const { setFieldsValue } = form;

    // ============================================
    // METHODS
    // ============================================

    const onValueChange = (rejectInfo, value) =>
        withdrawalsRejectInfoSet(dispatch, { rejectInfo, value });

    const rejectWithdrawals = () => {
        performWithdrawalsOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.REJECT,
        });
        closeRejectModal(dispatch);
    };

    const closeModal = () => closeRejectModal(dispatch);

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        setFieldsValue({
            remarks: isRecord ? remarks : null,
        });
    }, [isRecord, remarks, setFieldsValue]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            title={getTranslation('Reject Withdrawal Request')}
            centered
            visible={visible}
            closable={false}
            width="700px"
            className="withdrawal__reject-modal"
            onCancel={isRecord ? closeModal : onCancel}
            getContainer={false} // solve the useForm not connected warning
            footer={[
                <Row key="actions" justify="end" align="middle">
                    {!isRecord && (
                        <ButtonRed
                            form="reject-withdrawal"
                            label={getTranslation('Reject')}
                            loading={operationPending}
                            htmlType="submit"
                        />
                    )}

                    <ButtonGray
                        label={getTranslation('Cancel')}
                        onClick={isRecord ? closeModal : onCancel}
                    />
                </Row>,
            ]}
        >
            <Form form={form} name="reject-withdrawal" onFinish={rejectWithdrawals}>
                <Row>
                    <Col span={6}>
                        <label className="modal-description">
                            {getTranslation('Remarks')} <span className="form__required">*</span> :
                        </label>
                    </Col>
                    <Col span={18}>
                        <Form.Item
                            name="remarks"
                            rules={[
                                {
                                    message: getTranslation('Please input reason !'),
                                    required: true,
                                },
                            ]}
                        >
                            <TextArea
                                onChange={(e) => onValueChange('remarks', e.target.value)}
                                disabled={isRecord}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default RejectModal;
