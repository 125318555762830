import React from 'react';

import { Card, Form } from 'antd';

import { API } from '../../../common/constants/api';
import { channelsDropFilters } from './channelsActions';
import { getColumns } from './table/ChannelsTableMeta';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useExportAsyncEndpoint } from '../../../services/hooks/useExport';
import { useGetChannelsAsyncEndpoint } from '../../../services/playerService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import ChannelsFilters from './ChannelsFilters';
import ChannelsResultsTable from './table/ChannelsResultsTable';

const { PLAYERS } = API;

const ChannelInquiry = () => {
    const [state, dispatch] = useStore();
    const filters = getStateProp(state, 'players.channels.filters');
    const { totalRecordCount } = getStateProp(state, 'players.channels.paging', {});

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        channelsDropFilters(dispatch);
        handleGetChannels();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetChannels] = useGetChannelsAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        dataMeta: getColumns(),
        filters,
        method: 'POST',
        subject: getTranslation('Channel Inquiry List'),
        totalRecordCount,
        url: `${PLAYERS.CHANNELS}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Channel Inquiry')}>
            <Form onFinish={handleGetChannels}>
                <ChannelsFilters />
                <ActionButtons onDropFilters={fetchAll} onExport={handleExportAction} />
            </Form>

            <ChannelsResultsTable loading={loading} />
        </Card>
    );
};

export default ChannelInquiry;
