import React from 'react';

import { Modal } from 'antd';

import { getTranslation } from '../../../common/helpers/locale';
import TotalRegistrationModalTable from './table/TotalRegistrationModalTable';

const TotalRegistrationModal = ({ loading, visible, onCancel }) => {
    return (
        <Modal
            width={780}
            visible={visible}
            title={getTranslation('Total Registration/ login')}
            onCancel={() => onCancel(false)}
            closable={false}
            footer={null}
        >
            <TotalRegistrationModalTable loading={loading} />
        </Modal>
    );
};

export default TotalRegistrationModal;
