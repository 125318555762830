export const ACTIONS = {
    ACCOUNT_CONFIGURATION_FORM_SUBMIT: 'ACCOUNT_CONFIGURATION_FORM_SUBMIT',
    ACCOUNT_CONFIGURATION_FORM_SUBMIT_FAILURE: 'ACCOUNT_CONFIGURATION_FORM_SUBMIT_FAILURE',
    ACCOUNT_CONFIGURATION_FORM_SUBMIT_SUCCESS: 'ACCOUNT_CONFIGURATION_FORM_SUBMIT_SUCCESS',
    ACCOUNT_CONFIGURATION_GET: 'ACCOUNT_CONFIGURATION_GET',
    ACCOUNT_CONFIGURATION_GET_FAILURE: 'ACCOUNT_CONFIGURATION_GET_FAILURE',
    ACCOUNT_CONFIGURATION_GET_SUCCESS: 'ACCOUNT_CONFIGURATION_GET_SUCCESS',
    CONFIRM_MODAL_CLOSE: 'ACCOUNT_CONFIGURATION_CONFIRM_MODAL_CLOSE',
    CONFIRM_MODAL_OPEN: 'ACCOUNT_CONFIGURATION_CONFIRM_MODAL_OPEN',
    FILTER_DROP: 'ACCOUNT_CONFIGURATION_FILTER_DROP',
    FILTER_SET: 'ACCOUNT_CONFIGURATION_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'ACCOUNT_CONFIGURATION_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'ACCOUNT_CONFIGURATION_TABLE_PAGE_SIZE_CHANGE',
    },
};

export const onOpenConfirmModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CONFIRM_MODAL_OPEN,
    });

export const onCloseConfirmModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_MODAL_CLOSE,
    });

export const bankCardConfigurationGet = (dispatch) =>
    dispatch({
        type: ACTIONS.ACCOUNT_CONFIGURATION_GET,
    });

export const bankCardConfigurationGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ACCOUNT_CONFIGURATION_GET_SUCCESS,
    });

export const bankCardConfigurationGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ACCOUNT_CONFIGURATION_GET_FAILURE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const bankCardConfigurationFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const bankCardConfigurationDropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTER_DROP,
    });

export const bankCardConfiguration = (dispatch) =>
    dispatch({
        type: ACTIONS.ACCOUNT_CONFIGURATION_FORM_SUBMIT,
    });

export const bankCardConfigurationFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ACCOUNT_CONFIGURATION_FORM_SUBMIT_FAILURE,
    });

export const bankCardConfigurationSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.ACCOUNT_CONFIGURATION_FORM_SUBMIT_SUCCESS,
    });
