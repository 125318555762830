import { Switch } from 'react-router-dom';
import React from 'react';

import { AffiliateGenerator, AffiliateVerification } from '.';
import { PrivateRoute } from '../../common/components';
import { ROUTES } from '../../common/constants/routing';

const Affiliate = () => {
    return (
        <Switch>
            <PrivateRoute
                path={ROUTES.DASHBOARD.AFFILIATE.AFFILIATE_GENERATOR}
                component={AffiliateGenerator}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.AFFILIATE.AFFILIATE_VERIFICATION}
                component={AffiliateVerification}
            />
        </Switch>
    );
};

export default Affiliate;
