import React from 'react';

import ImageUploader from 'react-images-upload';
import _ from 'lodash';

import './ImageEditor.less';

const ImageEditor = ({ onImageChanged, image }) => {
    // ============================================
    // METHODS
    // ============================================

    const getBase64 = (file) => {
        if (file)
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        return new Promise((resolve) => {
            resolve(null);
        });
    };

    const resolveImageToText = (files) => {
        const lastAddedFile = _.last(files);
        if (lastAddedFile) getBase64(_.last(files)).then((text) => onImageChanged(text));
        else onImageChanged(null);
    };

    const setImage = () => (image ? { defaultImages: [image] } : {});

    // ============================================
    // RENDER
    // ============================================

    return (
        <div className="image__editor">
            <ImageUploader
                withPreview
                withIcon={false}
                singleImage={true}
                onChange={resolveImageToText}
                imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                maxFileSize={204800}
                label="Max file size 200kb with ratio 16:9"
                labelClass="image__editor__btn__upload_label"
                buttonText={image ? 'Change image' : 'Choose image'}
                buttonClassName="image__editor__btn__upload"
                {...setImage()}
            />
        </div>
    );
};

export default ImageEditor;
