import { ACTIONS } from './referralManagementAction';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function referralManagementReducer(
    referralManagement = {
        modalPaging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        resetModalPageNo: false,
        resetPageNo: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.FILTER_SET:
            return {
                ...referralManagement,
                filters: {
                    ...referralManagement.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };
        case ACTIONS.FILTERS_DROP_ALL:
            return {
                ...referralManagement,
                filters: null,
                resetPageNo: true,
            };

        case ACTIONS.REFERRAL_MANAGEMENT_GET_SUCCESS:
            return {
                ...referralManagement,
                ...payload,
            };

        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...referralManagement,
                paging: {
                    ...referralManagement.paging,
                    pageNo:
                        payload > referralManagement.paging.pageCount
                            ? referralManagement.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...referralManagement,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.REFERRAL_MANAGEMENT_GET:
            const pageNo = referralManagement.resetPageNo
                ? TABLE_PAGE_NO_DEFAULT
                : referralManagement.paging.pageNo;
            return {
                ...referralManagement,
                paging: {
                    ...referralManagement.paging,
                    pageNo: pageNo,
                },
                resetPageNo: false,
            };

        case ACTIONS.REFERRAL_STATUS_SET:
            return {
                ...referralManagement,
                ...payload,
            };
        case ACTIONS.REFERRAL_STATUS_SET_SUCCESS:
            return {
                ...referralManagement,
                activeReferral: {},
                changeReferralstatus: {},
            };
        case ACTIONS.REFERRAL_EDIT_MODAL_OPEN:
            return {
                ...referralManagement,
                editModalvisible: true,
                selectedId: payload,
            };
        case ACTIONS.REFERRAL_EDIT_MODAL_CLOSE:
            return {
                ...referralManagement,
                editModalvisible: false,
                resetModalPageNo: true,
                selectedId: '',
                selectedReferral: {},
            };
        case ACTIONS.REFERRAL_MANAGEMENT_GET_BY_ID_SUCCESS:
            const modalPageNo = referralManagement.resetModalPageNo
                ? TABLE_PAGE_NO_DEFAULT
                : referralManagement.modalPaging.pageNo;
            return {
                ...referralManagement,
                modalPaging: {
                    ...referralManagement.modalPaging,
                    pageNo: modalPageNo,
                },
                resetModalPageNo: false,
                selectedReferral: payload,
            };
        case ACTIONS.REFERRAL_MANAGEMENT_EDIT:
            return {
                ...referralManagement,
                selectedReferral: {
                    ...referralManagement.selectedReferral,
                    ...payload,
                },
            };
        case ACTIONS.MODAL_PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...referralManagement,
                modalPaging: {
                    ...referralManagement.modalPaging,
                    pageNo:
                        payload > referralManagement.modalPaging.pageCount
                            ? referralManagement.modalPaging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.MODAL_PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MODAL_MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...referralManagement,
                modalPaging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MODAL_MAX_PER_PAGE
                            ? MODAL_MAX_PER_PAGE
                            : payload,
                },
            };
        case ACTIONS.REFERRAL_CONFIRMATION_MODAL_CLOSE:
            return {
                ...referralManagement,
                activeReferral: {},
                changeReferralstatus: {},
            };
        case ACTIONS.REFERRAL_MANAGEMENT_GET_ACTIVE_SUCCESS:
            return {
                ...referralManagement,
                activeReferral: payload,
            };
        default:
            return referralManagement;
    }
}
