import React from 'react';
import _ from 'lodash';

import './PushNotificationDetails.less';
import {
    ButtonGreen,
    ButtonLightBlue,
    ButtonTransparent,
} from '../../../../common/components/buttons/Buttons';
import { Col, Divider, Form, Input, Row, Tabs, message } from 'antd';
import { getLanguageByCode, getTranslation } from '../../../../common/helpers/locale';
import { getPushNotificationLang } from '../../helpers';
import { useDispatch } from '../../../../store/StateProvider';
import LanguagesCheckboxes from '../../../../common/components/LanguagesCheckboxes';

const { TabPane } = Tabs;
const { TextArea } = Input;

const PushNotificationDetails = ({
    pushNotificationInfo,
    submitting,
    selectNewsLanguages,
    isCreate,
    sending = false,
    notificationStatus = '',
    visible = false,
    handleUpdatePushNotification = () => {},
    handleUpsertPushNotification = () => {},
    handleSendPushNotification = () => {},
    resetPushNotificationInfo,
    status,
}) => {
    const dispatch = useDispatch();
    const selectedLanguage = getPushNotificationLang(pushNotificationInfo);

    // ============================================
    // METHODS
    // ============================================
    const submitForm = () => {
        let isEmpty = [];
        _.forEach(pushNotificationInfo, function(e) {
            if (!e.title || !e.content) {
                isEmpty.push(e.languageName);
            }
        });

        if (isEmpty.length > 1) {
            message.error(getTranslation('Please fill in the content in all languages'));
        } else if (isEmpty.length === 1) {
            message.error(
                getTranslation('Please fill in the content in {languageCode}', {
                    languageCode: isEmpty[0],
                }),
            );
        } else {
            handleUpsertPushNotification();
        }
    };

    const resetForm = () => {
        resetPushNotificationInfo(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form onFinish={submitForm}>
            <Row className="create-push-notification__form-row">
                <Col span={6}>
                    <label>{getTranslation('Languages')}</label>
                </Col>
                <Col span={16}>
                    <Form.Item>
                        <LanguagesCheckboxes
                            onCheckedLanguages={selectNewsLanguages}
                            value={selectedLanguage}
                            disabled={notificationStatus === 'Verified'}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Tabs className="create-push-notification__width--100" type="card" hideAdd>
                    {_.map(_.sortBy(pushNotificationInfo, ['languageCode']), (info) => {
                        return (
                            <TabPane
                                tab={
                                    info.languageName
                                        ? info.languageName
                                        : getLanguageByCode(info.languageCode)
                                }
                                key={info.languageCode}
                            >
                                <Row className="create-push-notification__form-item">
                                    <Col span={6}>
                                        <label>{getTranslation('Title')}</label>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="title" valuePropName="title">
                                            <Input
                                                value={info.title}
                                                onChange={(e) =>
                                                    handleUpdatePushNotification(
                                                        e.target.value,
                                                        info.languageCode,
                                                        'title',
                                                    )
                                                }
                                                disabled={notificationStatus === 'Verified'}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="create-push-notification__form-item">
                                    <Col span={6}>
                                        <label>{getTranslation('Message')}</label>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="content" valuePropName="content">
                                            <TextArea
                                                value={info.content}
                                                onChange={(e) =>
                                                    handleUpdatePushNotification(
                                                        e.target.value,
                                                        info.languageCode,
                                                        'content',
                                                    )
                                                }
                                                rows={4}
                                                disabled={notificationStatus === 'Verified'}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </TabPane>
                        );
                    })}
                </Tabs>
            </Row>

            <Divider />

            <Row className="create-push-notification__form-item" justify="space-between">
                <Col span={4}>
                    {!isCreate && (
                        <ButtonLightBlue
                            label={getTranslation('Send')}
                            className="create-push-notification__margin-right---5"
                            onClick={handleSendPushNotification}
                            loading={sending}
                        />
                    )}
                </Col>

                <Col span={8}>
                    <Row justify="end">
                        {((notificationStatus !== 'Verified' && visible) || isCreate) && (
                            <ButtonGreen
                                label={getTranslation(isCreate ? 'Submit' : 'Update')}
                                className="create-push-notification__margin-right---5"
                                htmlType="submit"
                                loading={submitting}
                            />
                        )}
                        <ButtonTransparent
                            label={getTranslation('Cancel')}
                            className="news__form__reset-button"
                            onClick={resetForm}
                        />
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};

export default PushNotificationDetails;
