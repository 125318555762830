import React, { useState } from 'react';

import { Col, Modal, Row } from 'antd';

import { ButtonTransparent } from '../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../common/helpers/locale';
import BankCardTableContent from './BankCardTableContent';

const BankCardTableView = ({ playerId }) => {
    const [bankCardListModalVisible, setBankCardListModalVisible] = useState(false);

    const openBankCardListModal = () => setBankCardListModalVisible(true);
    const closeBankCardListModal = () => setBankCardListModalVisible(false);

    return (
        <Col>
            {playerId && (
                <ButtonTransparent className="hyperlink-button" onClick={openBankCardListModal}>
                    {getTranslation('View')}
                </ButtonTransparent>
            )}

            <Modal
                visible={bankCardListModalVisible}
                onCancel={closeBankCardListModal}
                destroyOnClose={true}
                title={getTranslation('Bank Card')}
                centered
                footer={
                    <Row key="actions" justify="end" align="middle">
                        <ButtonTransparent
                            label={getTranslation('Cancel')}
                            onClick={closeBankCardListModal}
                        />
                    </Row>
                }
            >
                {bankCardListModalVisible && <BankCardTableContent playerId={playerId} />}
            </Modal>
        </Col>
    );
};

export default BankCardTableView;
