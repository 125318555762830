import React from 'react';

import { RenderDateFormat } from '../../../../../common/components';

import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../cryptoWithdrawalActions';
import { getStatusColor, sortTableByDataType } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import VerifyButton from '../verification/VerifyButton';

export const getColumns = (dispatch) => {
    const columns = [
        {
            dataIndex: 'createdAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Request Time'),
        },
        {
            dataIndex: 'auditedAt',
            dataType: 'date',
            render: (value) => RenderDateFormat(value),
            title: getTranslation('Audit Time'),
        },
        {
            dataIndex: 'playerUserName',
            title: getTranslation('Username'),
        },
        {
            dataIndex: 'transactionNo',
            title: getTranslation('Task Transaction Number'),
        },
        {
            dataIndex: 'transactionRef',
            title: getTranslation('GGPay Transaction Number'),
        },
        {
            dataIndex: 'shortId',
            title: getTranslation('Short Id'),
        },
        {
            dataIndex: 'affiliate',
            title: getTranslation('Affiliate'),
        },
        {
            dataIndex: 'referral',
            title: getTranslation('Referral'),
        },
        {
            dataIndex: 'amount',
            title: getTranslation('Withdrawal Amount'),
        },
        {
            dataIndex: 'currencyCode',
            title: getTranslation('Currency'),
        },

        {
            dataIndex: 'cryptoRate',
            render: (value) => <span>{value.toFixed(15).replace(/\.?0+$/, '')}</span>,
            title: getTranslation('Exchange Rate'),
        },
        {
            dataIndex: 'cryptoCode',
            title: getTranslation('Crypto Currency'),
        },
        {
            dataIndex: 'cryptoAmt',
            title: getTranslation('Withdrawal Crypto Amount'),
        },
        {
            dataIndex: 'cryptoAddr',
            render: (value, record) => (
                <div>
                    <span>{value}</span>
                    <br />
                    <span>
                        {record.withdrawStatus} {record.withdrawMessage}
                    </span>
                </div>
            ),
            title: getTranslation('Receiving Wallet Address'),
        },
        {
            dataIndex: 'processedAt',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Process Time'),
        },
        {
            dataIndex: 'orderStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>{value.toLocaleUpperCase()}</span>
            ),
            title: getTranslation('Status'),
        },
        {
            dataIndex: 'transactionStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>{value.toLocaleUpperCase()}</span>
            ),
            title: getTranslation('Transaction Status'),
        },
        {
            dataIndex: 'withdrawStatus',
            render: (value) => (
                <span style={{ color: getStatusColor(value) }}>{value.toLocaleUpperCase()}</span>
            ),
            title: getTranslation('GGPay Status'),
        },
        {
            dataIndex: 'verifierUserName',
            title: getTranslation('Operator'),
        },
        {
            dataIndex: 'operation',
            dataType: 'operationVerifier',
            render: (text, record) => <VerifyButton transaction={record} dispatch={dispatch} />,
            title: getTranslation('Operation'),
            width: 80,
        },
    ];

    return columns.map((colProps) => ({
        ...colProps,
        sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
    }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);

export const cryptocurrencyWithdrawalRecordsExport = ({ onlyMeta }) => {
    if (onlyMeta) {
        return {
            operationVerifier: [
                {
                    dataIndex: 'verifierRealName',
                    title: getTranslation('Operation'),
                },
            ],
        };
    }

    let messageArray = [];
    return messageArray;
};
