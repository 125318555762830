import React from 'react';

import { Row } from 'antd';

import '../styles/RowActionButton.less';
import { ButtonGreen, ButtonRed } from '../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../common/helpers/locale';
import { openMessageDeleteModal, openMessageVerifyModal } from './personalMessageActions';
import { useDispatch } from '../../../../store/StateProvider';

const RowActionButtons = ({ message }) => {
    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ============================================

    const openVerifyModal = () => openMessageVerifyModal(dispatch, message);

    const onOpenDeleteModal = () => {
        openMessageDeleteModal(dispatch, { messageDelete: message });
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row span={7} align="middle" className="row-buttons__container">
            <ButtonGreen type="primary" className="rowaction-button" onClick={openVerifyModal}>
                {getTranslation('Verify')}
            </ButtonGreen>
            <ButtonRed type="primary" className="rowaction-button" onClick={onOpenDeleteModal}>
                {getTranslation('Delete')}
            </ButtonRed>
        </Row>
    );
};

export default RowActionButtons;
