import React, { useState } from 'react';

import { Button, Dropdown, Menu, Row } from 'antd';
import { CaretDownOutlined, FormOutlined } from '@ant-design/icons';
import _ from 'lodash';

import './OperationsButton.less';
import { BONUS_CATEGORY_MODAL_TYPES, BONUS_CATEGORY_STATUSES } from '../../../constants';
import { getTranslation } from '../../../../../common/helpers/locale';
import { openBonusCategoryModal } from '../../bonusCategoryActions';
import { useUpdateBonusCategoryStatusAsyncEndpoint } from '../../../../../services/bonusCategoryService';

const OperationsButton = ({ dispatch, bonusCategory }) => {
    const { categoryStatus, id } = bonusCategory;
    const [status, setStatus] = useState(null);
    // ============================================
    // METHODS
    // ============================================

    const onSubmit = (e) => {
        setStatus(e.key);
        handleUpdateBonusCategoryStatus();
    };

    const onEditCategory = () =>
        openBonusCategoryModal(dispatch, {
            categoryAttending: bonusCategory,
            modalType: BONUS_CATEGORY_MODAL_TYPES.EDIT_CATEGORY,
        });

    // ============================================
    // HOOKS
    // ============================================

    const [
        updating,
        handleUpdateBonusCategoryStatus,
        updated,
    ] = useUpdateBonusCategoryStatusAsyncEndpoint(id, status);

    // ============================================
    // RENDER
    // ============================================

    const operationsMenu = (
        <Menu onClick={onSubmit}>
            {_.map(BONUS_CATEGORY_STATUSES, (status) => (
                <Menu.Item key={status}>
                    <span>{getTranslation(status)}</span>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Row>
            <Dropdown
                overlay={operationsMenu}
                trigger={['click']}
                disabled={updated ? status === 'Deleted' : categoryStatus === 'Deleted'}
            >
                <Button className="btn__green action-btn__operations" loading={updating}>
                    {getTranslation(updated ? status : categoryStatus)}
                    <CaretDownOutlined />
                </Button>
            </Dropdown>

            <FormOutlined className="action-icon" onClick={onEditCategory} />
        </Row>
    );
};

export default OperationsButton;
