export const BONUS_STATUSES = {
    ACTIVE: 'Active',
    CLOSED: 'Closed',
    INACTIVE: 'Inactive',
};

export const BANNER_STATUSES = {
    ACTIVE: 'Active',
    CLOSED: 'Closed',
    INACTIVE: 'Inactive',
};

export const BANNER_TYPES = {
    BANNER: 'Banner',
    BONUS: 'Bonus',
};

export const BONUS_CATEGORY_STATUSES = {
    ACTIVE: 'Active',
    DELETED: 'Deleted',
    INACTIVE: 'Inactive',
};

export const FORM_ASPECT = {
    CREATE: 'create',
    EDIT: 'edit',
};

export const BONUS_CATEGORY_MODAL_TYPES = {
    CATEGORY_SORTING: {
        component: 'SortingForm',
        title: 'Sorting In App',
    },
    CREATE_CATEGORY: {
        component: 'CategoryForm',
        title: 'Create Category',
        width: 1000,
    },
    EDIT_CATEGORY: {
        component: 'CategoryForm',
        title: 'Edit Category',
        width: 1000,
    },
};

export const RISK_LEVEL = {
    NORMAL: 'Normal',
    RISK: 'Risk',
};

export const BONUS_STEP = [
    {
        content: 'bonus-info',
        title: 'Bonus Information',
    },
    {
        content: 'bonus-setting',
        title: 'Bonus Setting',
    },
    {
        content: 'bonus-condition',
        title: 'Bonus Condition',
    },
];

export const INITIAL_CREATE_BONUS_DATA = {
    appliedPlatforms: ['ALL'],
    appliedProducts: ['ALL'],
    checkingDateType: 'Duration',
    currentStep: 0,
    hasJoinButton: true,
    isDepositBonus: true,
    isDepositEtoComplete: true,
    isETODeposit: true,
    isFixedBonus: true,
    isPromoRule: true,
    isRepeatable: true,
    isShowOnApp: true,
};
