import { useCallback, useEffect, useState } from 'react';

import { message } from 'antd';
import _ from 'lodash';

import { API } from '../common/constants/api';
import { DEBOUNCE_DELAY_SEC } from '../common/constants/misc';
import { REQUEST_SEARCH_PLAYER_INFO_ACTIONS } from '../components/bonus/request-bonus/constants';
import { clearError } from '../common/actions/errorActions';
import { getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import {
    requestBonus,
    requestBonusCodesGet,
    requestBonusCodesGetFailure,
    requestBonusCodesGetSuccess,
    requestBonusFailure,
    requestBonusPlayerInfoGetFailure,
    requestBonusPlayerInfoGetSuccess,
    requestBonusSuccess,
    requestBonusTurnover,
    requestBonusTurnoverFailure,
    requestBonusTurnoverSuccess,
    requestCheckPlayerRequestable,
    requestCheckPlayerRequestableFailure,
    requestCheckPlayerRequestableSuccess,
    uploadExcel,
    uploadExcelFailure,
    uploadExcelSuccess,
} from '../components/bonus/request-bonus/requestBonusActions';
import { useDebounce } from './hooks/useDebounce';
import { useDispatch, useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, PLAYERS, PAYMENTS, BONUS, PROMOTIONS, CRYPTO, TRANSACTION_MONITORING } = API;

export function useCreateBonusRequestOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const adjustmentInfo = getStateProp(state, 'bonus.requestBonus.adjustmentInfo');
    const {
        playerId,
        turnoverRequirement,
        memberLevel,
        transactionNo,
        depositedAmount,
        bonusCode,
        ...rest
    } = adjustmentInfo;

    const getBonusId = (bonusCode) => {
        const bonusCodeDetailList = getStateProp(
            state,
            'bonus.requestBonus.bonusCodeDetailList',
            [],
        );

        for (let i = 0; i < bonusCodeDetailList.length; i++) {
            if (bonusCode === bonusCodeDetailList[i].code) {
                return bonusCodeDetailList[i].id;
            }
        }
        return null;
    };

    // ============================================
    // HOOKS
    // ============================================

    const handleCreateBonusRequest = useCallback(() => {
        setTrigger(+new Date());
        requestBonus(dispatch);
    }, [dispatch]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                bonusId: getBonusId(bonusCode),
                depositAmount: depositedAmount,
                depositTransactionNo: transactionNo,
                memberLevel: memberLevel ? memberLevel : undefined,
                turnoverCondition: turnoverRequirement,
                userId: playerId,
                ...rest,
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS.ROOT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            requestBonusSuccess(dispatch);
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, requestBonusFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleCreateBonusRequest];
}

export function useGetBonusCodeListOperationAsyncEndpoint() {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);
    const [isUsername, setIsUsername] = useState(false);

    // ============================================
    // HOOKS
    // ============================================

    const handleGetBonusCodeList = useCallback(
        (isUsername) => {
            setTrigger(+new Date());
            setIsUsername(isUsername);
            requestBonusCodesGet(dispatch);
        },
        [dispatch],
    );

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'GET',
        options: {
            params: {
                isUsername,
            },
        },
        trigger,
        url: `${VERSION.V1}${PROMOTIONS.ACTIVE_CODES}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);

            const bonusCodeList = response.data.data.map((item) => {
                return item.code;
            });

            requestBonusCodesGetSuccess(dispatch, {
                bonusCodeDetailList: response.data.data,
                bonusCodeList,
            });
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, requestBonusCodesGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetBonusCodeList];
}

export function useGetPlayerInfoAsyncEndpoint() {
    const [state, dispatch] = useStore();

    const action = getStateProp(state, 'bonus.requestBonus.adjustmentInfo.action');

    const userNameLike = useDebounce(
        getStateProp(state, 'bonus.requestBonus.adjustmentInfo.userNameLike'),
        DEBOUNCE_DELAY_SEC,
    );

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () =>
            !!userNameLike && action === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.USERNAME,
        method: 'GET',
        trigger: userNameLike,
        url: `${VERSION.V1}${PLAYERS.GET_BY_USERNAME}/${userNameLike}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            if (response.data.data === null) {
                message.warning(getTranslation('User Not Found'));
            } else {
                requestBonusPlayerInfoGetSuccess(dispatch, {
                    currencyCode: getStateProp(response.data, 'data.monetary.currencyCode'),
                    depositedAmount: '0',
                    memberLevel: getStateProp(response.data, 'data.profile.memberLevel'),
                    playerId: getStateProp(response.data, 'data.id'),
                    userName: getStateProp(response.data, 'data.userName'),
                });
            }
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, requestBonusPlayerInfoGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useGetTransactionInfoAsyncEndpoint() {
    const [state, dispatch] = useStore();

    const transactionId = useDebounce(
        getStateProp(state, 'bonus.requestBonus.adjustmentInfo.transactionNo'),
        DEBOUNCE_DELAY_SEC,
    );

    const action = getStateProp(state, 'bonus.requestBonus.adjustmentInfo.action');

    const getURL = (action) => {
        switch (action) {
            case REQUEST_SEARCH_PLAYER_INFO_ACTIONS.CRYPTO_DEPOSIT:
                return `${VERSION.V1}${CRYPTO.DEPOSIT_INFO}/${transactionId}`;

            case REQUEST_SEARCH_PLAYER_INFO_ACTIONS.MERCHANT_DEPOSIT:
                return `${VERSION.V1}${PAYMENTS.MERCHANT_DEPOSITS.ROOT}/${transactionId}`;

            case REQUEST_SEARCH_PLAYER_INFO_ACTIONS.OFFLINE_DEPOSIT:
                return `${VERSION.V1}${PAYMENTS.OFFLINE_DEPOSITS.ROOT}/${transactionId}`;

            case REQUEST_SEARCH_PLAYER_INFO_ACTIONS.EWALLET_DEPOSIT:
                return `${VERSION.V1}${PAYMENTS.EWALLET.EWALLET_TRANSFERS}/${transactionId}`;

            case REQUEST_SEARCH_PLAYER_INFO_ACTIONS.BALANCE_ADDITION:
            case REQUEST_SEARCH_PLAYER_INFO_ACTIONS.CRYPTOCURRENCY_CORRECTION:
            case REQUEST_SEARCH_PLAYER_INFO_ACTIONS.DEPOSIT_CORRECTION:
            case REQUEST_SEARCH_PLAYER_INFO_ACTIONS.EWALLET_CORRECTION:
                return `${VERSION.V1}${TRANSACTION_MONITORING}`;

            default:
                return;
        }
    };

    const isParamNeed = (action) => {
        return (
            action === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.BALANCE_ADDITION ||
            action === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.CRYPTOCURRENCY_CORRECTION ||
            action === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.DEPOSIT_CORRECTION ||
            action === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.EWALLET_CORRECTION
        );
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!transactionId,
        method: 'GET',
        options: isParamNeed(action) && {
            params: {
                transactionNo: transactionId,
            },
        },
        trigger: transactionId,
        url: getURL(action),
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);

            const result =
                action === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.MERCHANT_DEPOSIT ||
                action === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.OFFLINE_DEPOSIT ||
                action === REQUEST_SEARCH_PLAYER_INFO_ACTIONS.EWALLET_DEPOSIT
                    ? response.data.data[0].data[0]
                    : isParamNeed(action) && _.isArray(response.data.data)
                    ? response.data.data[0].data[0]
                    : response.data.data;

            if (_.isEmpty(result) || (result && result.orderStatus !== 'Success')) {
                result && result.orderStatus !== 'Success'
                    ? message.warning(getTranslation('Transaction Incomplete'))
                    : message.warning(getTranslation('Transaction Not Found'));

                requestBonusPlayerInfoGetSuccess(dispatch, {
                    currencyCode: null,
                    depositedAmount: null,
                    memberLevel: null,
                    playerId: null,
                    userName: getStateProp(result, 'playerUserName'),
                    userNameLike: null,
                });
            } else {
                requestBonusPlayerInfoGetSuccess(dispatch, {
                    currencyCode: getStateProp(result, 'currencyCode'),
                    depositedAmount: getStateProp(result, 'amount'),
                    memberLevel: getStateProp(result, 'memberLevel'),
                    playerId: getStateProp(result, 'playerId'),
                    userName: getStateProp(result, 'playerUserName'),
                    userNameLike: getStateProp(result, 'playerUserName'),
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, response]);

    const errorMsg = useError(error, requestBonusPlayerInfoGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useGetCalculatedTurnoverAmountOperationAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const requestBonus = getStateProp(state, 'bonus.requestBonus', {});
    const adjustmentInfo = getStateProp(requestBonus, 'adjustmentInfo', {});
    const depositAmount = getStateProp(adjustmentInfo, 'depositedAmount', {});
    const bonusAmount = getStateProp(adjustmentInfo, 'bonusAmount', {});
    const turnoverRequirement = getStateProp(adjustmentInfo, 'turnoverRequirement', {});

    const handleGetCalculatedTurnoverAmountRequest = useCallback(() => {
        requestBonusTurnover(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                bonusAmount: parseFloat(bonusAmount),
                depositAmount: parseFloat(depositAmount),
                turnoverCondition: parseFloat(turnoverRequirement),
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS.CALCULATE_TURN_OVER}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            requestBonusTurnoverSuccess(dispatch, {
                turnoverAmount: getStateProp(response.data, 'data.turnoverAmount'),
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (
            !isNaN(parseFloat(depositAmount)) &&
            !isNaN(parseFloat(bonusAmount)) &&
            !isNaN(parseFloat(turnoverRequirement))
        ) {
            handleGetCalculatedTurnoverAmountRequest();
        }
    }, [depositAmount, bonusAmount, turnoverRequirement, handleGetCalculatedTurnoverAmountRequest]);

    const errorMsg = useError(error, requestBonusTurnoverFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useValidateJoinBonusAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const adjustmentInfo = getStateProp(state, 'bonus.requestBonus.adjustmentInfo');
    const { playerId, bonusCode } = adjustmentInfo;

    const getBonusId = (bonusCode) => {
        const bonusCodeDetailList = getStateProp(
            state,
            'bonus.requestBonus.bonusCodeDetailList',
            [],
        );

        for (let i = 0; i < bonusCodeDetailList.length; i++) {
            if (bonusCode === bonusCodeDetailList[i].code) {
                return bonusCodeDetailList[i].id;
            }
        }
        return null;
    };

    // ============================================
    // HOOKS
    // ============================================

    const handleValidateJoin = useCallback(() => {
        setTrigger(+new Date());
        requestCheckPlayerRequestable(dispatch);
    }, [dispatch]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                bonusId: getBonusId(bonusCode),
                userId: playerId,
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS.CHECK_REPEAT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            requestCheckPlayerRequestableSuccess(dispatch);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, requestCheckPlayerRequestableFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleValidateJoin];
}

export function useUploadExcelAsyncEndpoint() {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);
    const [selectedExcelBased64, setSelectedExcelBased64] = useState('');

    // ============================================
    // HOOKS
    // ============================================

    const handleUploadExcel = useCallback(
        (excelBased64) => {
            setSelectedExcelBased64(excelBased64);
            setTrigger(+new Date());
            uploadExcel(dispatch);
        },
        [dispatch],
    );

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger && !!selectedExcelBased64,
        method: 'POST',
        options: {
            data: {
                base64Excel: selectedExcelBased64,
            },
        },
        trigger,
        url: `${VERSION.V1}${BONUS.IMPORT}`,
    });

    useEffect(() => {
        if (response) {
            setSelectedExcelBased64('');
            clearError(dispatch);
            uploadExcelSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, uploadExcelFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUploadExcel];
}
