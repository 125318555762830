import { ACTIONS } from './PlayersListActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function playersListReducer(
    playersList = {
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        playersModalVisible: false,
        remarks: {
            paging: {
                pageNo: TABLE_PAGE_NO_DEFAULT,
                pageSize: TABLE_PAGE_SIZE_DEFAULT,
                totalRecordCount: 0,
            },
        },
        resetPageNo: false,
        status: {
            paging: {
                pageNo: TABLE_PAGE_NO_DEFAULT,
                pageSize: TABLE_PAGE_SIZE_DEFAULT,
                totalRecordCount: 0,
            },
        },
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.FILTER_SET:
            return {
                ...playersList,
                filters: {
                    ...playersList.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };

        case ACTIONS.FILTERS_DROP_ALL:
            return {
                ...playersList,
                filters: {},
                resetPageNo: true,
            };

        case ACTIONS.COLUMNS_SELECT:
            return {
                ...playersList,
                selectedColumns: payload,
            };

        case ACTIONS.PLAYERS_GET_SUCCESS:
            return {
                ...playersList,
                fetchData: payload.fetchData,
                list: payload.list,
                paging: {
                    ...playersList.paging,
                    ...payload.paging,
                },
            };

        case ACTIONS.PROHIBITIONS_GET_SUCCESS:
            return {
                ...playersList,
                prohibitions: {
                    list: payload.list,
                },
            };

        case ACTIONS.REMARKS_GET_SUCCESS:
            return {
                ...playersList,
                remarks: {
                    list: payload.list,
                    paging: {
                        ...playersList.remarks.paging,
                        ...payload.paging,
                    },
                },
            };

        case ACTIONS.STATUS_HISTORY_GET_SUCCESS:
            return {
                ...playersList,
                status: {
                    list: payload.list,
                    paging: {
                        ...playersList.status.paging,
                        ...payload.paging,
                    },
                },
            };

        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...playersList,
                paging: {
                    ...playersList.paging,
                    pageNo:
                        payload > playersList.paging.pageCount
                            ? playersList.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...playersList,
                paging: {
                    ...playersList.paging,
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.REMARKS_PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...playersList,
                remarks: {
                    ...playersList.remarks,
                    paging: {
                        ...playersList.remarks.paging,
                        pageNo:
                            payload > playersList.remarks.paging.pageCount
                                ? playersList.remarks.paging.pageCount - 1
                                : payload,
                    },
                },
            };
        case ACTIONS.REMARKS_PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_REMARKS_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...playersList,
                remarks: {
                    ...playersList.remarks,
                    paging: {
                        ...playersList.remarks.paging,
                        pageNo: 1,
                        pageSize:
                            payload < 1
                                ? TABLE_PAGE_SIZE_DEFAULT
                                : payload > MAX_REMARKS_PER_PAGE
                                ? MAX_REMARKS_PER_PAGE
                                : payload,
                    },
                },
            };

        case ACTIONS.STATUS_PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...playersList,
                status: {
                    ...playersList.status,
                    paging: {
                        ...playersList.status.paging,
                        pageNo:
                            payload > playersList.status.paging.pageCount
                                ? playersList.status.paging.pageCount - 1
                                : payload,
                    },
                },
            };
        case ACTIONS.STATUS_PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_STATUS_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...playersList,
                status: {
                    ...playersList.status,
                    paging: {
                        ...playersList.status.paging,
                        pageNo: 1,
                        pageSize:
                            payload < 1
                                ? TABLE_PAGE_SIZE_DEFAULT
                                : payload > MAX_STATUS_PER_PAGE
                                ? MAX_STATUS_PER_PAGE
                                : payload,
                    },
                },
            };

        case ACTIONS.WALLET_BALANCE_GET_SUCCESS:
        case ACTIONS.SABA_WALLET_BALANCE_GET_SUCCESS:
            return {
                ...playersList,
                ...payload,
            };

        case ACTIONS.PLAYERS_MODAL_OPEN:
            return {
                ...playersList,
                playersModal: {
                    isVisible: true,
                    modalType: payload.modalType,
                    playerAttending: {
                        ...payload.player,
                    },
                    prohibitionType: payload.prohibitionType,
                },
            };
        case ACTIONS.PLAYERS_MODAL_CLOSE:
            return {
                ...playersList,
                playersModal: {
                    isVisible: false,
                    modalType: null,
                    playerAttending: {},
                    prohibitionType: null,
                },
            };
        case ACTIONS.AFFILIATE_UPLINE_GET_SUCCESS:
            return {
                ...playersList,
                playersModal: {
                    ...playersList.playersModal,
                    affiliateInfo: payload,
                },
            };

        case ACTIONS.AFFILIATE_UPLINE_REMOVE:
            return {
                ...playersList,
                playersModal: {
                    ...playersList.playersModal,
                    affiliateInfo: undefined,
                },
            };

        case ACTIONS.PLAYERS_GET:
            const pageNo = playersList.resetPageNo
                ? TABLE_PAGE_NO_DEFAULT
                : playersList.paging.pageNo;
            return {
                ...playersList,
                paging: {
                    ...playersList.paging,
                    pageNo: pageNo,
                },
                resetPageNo: false,
            };

        case ACTIONS.GAME_PLATFORM_GET_SUCCESS:
            return {
                ...playersList,
                playersModal: {
                    ...playersList.playersModal,
                    gamePlatform: payload,
                },
            };

        case ACTIONS.PLAYER_GAME_ACCESS_GET_SUCCESS:
            return {
                ...playersList,
                playersModal: {
                    ...playersList.playersModal,
                    gameAccess: payload,
                },
            };
        case ACTIONS.PLAYER_DANGER_LEVEL_GET_SUCCESS:
            return {
                ...playersList,
                dangerLevels: payload,
            };
        case ACTIONS.PLAYER_DANGER_LEVEL_GET:
        case ACTIONS.PLAYER_GAME_ACCESS_GET:
        case ACTIONS.CHANGE_PASSWORD:
        case ACTIONS.CHANGE_PASSWORD_SUCCESS:
        case ACTIONS.CREATE_MODIFICATION:
        case ACTIONS.CREATE_MODIFICATION_SUCCESS:
        case ACTIONS.CREATE_PLAYER:
        case ACTIONS.CREATE_PLAYER_SUCCESS:
        case ACTIONS.CREATE_PROHIBITION:
        case ACTIONS.CREATE_PROHIBITION_SUCCESS:
        case ACTIONS.CREATE_REMARK:
        case ACTIONS.CREATE_REMARK_SUCCESS:
        case ACTIONS.PROHIBITIONS_GET:
        case ACTIONS.REMARKS_GET:
        case ACTIONS.STATUS_CHANGE:
        case ACTIONS.STATUS_CHANGE_SUCCESS:
        case ACTIONS.STATUS_HISTORY_GET:
        case ACTIONS.SABA_WALLET_BALANCE_GET:
        case ACTIONS.WALLET_BALANCE_GET:
        case ACTIONS.WALLET_TRANSFER:
        case ACTIONS.WALLET_TRANSFER_SUCCESS:
        case ACTIONS.GAME_PLATFORM_GET:
        default:
            return playersList;
    }
}
