import React from 'react';

import { Table } from 'antd';

import { getColumns } from './OverviewTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const OverviewResultsTable = ({ loading }) => {
    const [state] = useStore();
    const { overview } = getStateProp(state, 'reports');
    const tableData = getStateProp(overview, 'list', []);

    // ============================================
    // METHODS
    // ============================================

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            loading={loading}
            className="overview-table"
            columns={getColumns()}
            size="small"
            dataSource={tableData}
            scroll={{ x: true }}
            align="left"
            rowKey={(record, index) => index}
            bordered
        />
    );
};

export default OverviewResultsTable;
