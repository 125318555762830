import React from 'react';

import { Modal, Row } from 'antd';

import './VerifyModal.less';
import { ButtonGray, ButtonGreen } from '../../../../../common/components/buttons/Buttons';
import { PAYMENT_ORDER_OPERATIONS } from '../../../../../common/constants/misc';
import {
    cryptoWithdrawalCloseConfirmModal,
    cryptoWithdrawalCloseVerifyModal,
    cryptoWithdrawalOperation,
    openConfirmModal,
} from '../cryptoWithdrawalActions';
import { getStateProp } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useStore } from '../../../../../store/StateProvider';
import ConfirmModal from './ConfirmModal';

const VerifyModal = ({ onCancel, visible, transaction, operationPending }) => {
    const {
        playerUserName,
        transactionNo,
        cryptoCode,
        cryptoAmt,
        cryptoAddr,
        amount,
        currencyCode,
    } = transaction;

    const [state, dispatch] = useStore();
    const confirmModalVisible = getStateProp(
        state,
        'payments.cryptoWithdrawals.confirmModalVisible',
    );

    // ============================================
    // METHODS
    // ============================================

    const onOpenConfirmModal = () => openConfirmModal(dispatch);

    const verifyTransaction = () => {
        cryptoWithdrawalOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.COMPLETE,
            transaction,
        });
        cryptoWithdrawalCloseConfirmModal(dispatch);
        cryptoWithdrawalCloseVerifyModal(dispatch);
    };

    const onCloseModal = () => cryptoWithdrawalCloseConfirmModal(dispatch);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            title={getTranslation('Verify Crypto Withdrawal Request')}
            centered
            closable={false}
            className="crypto-withdrawal__verification-modal"
            onCancel={onCancel}
            footer={[
                <Row key="actions" justify="end" align="middle">
                    <ButtonGreen
                        label={getTranslation('Approve')}
                        loading={operationPending}
                        onClick={onOpenConfirmModal}
                    />
                    <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
                </Row>,
            ]}
            destroyOnClose={true}
        >
            <Row justify="start" className="deposit__name-modal">
                <p className="modal-description">{getTranslation('Username')}</p> :
                <p className="withdrawal-record">{playerUserName}</p>
            </Row>

            <Row justify="start" className="deposit__transaction-modal">
                <p className="modal-description">{getTranslation('Task Transaction Number')}</p> :
                <p className="withdrawal-record">{transactionNo}</p>
            </Row>
            <Row justify="start" className="deposit__amount-modal">
                <p className="modal-description">{getTranslation('Cryptocurrency')}</p> :
                <p className="withdrawal-record">{cryptoCode}</p>
            </Row>
            <Row justify="start" className="deposit__remark-modal">
                <p className="modal-description">{getTranslation('Crypto Amount')}</p> :
                <p className="withdrawal-record">{cryptoAmt}</p>
            </Row>
            <Row justify="start" className="deposit__remark-modal">
                <p className="modal-description">{getTranslation('Receiving Wallet Address')}</p> :
                <p className="withdrawal-record">{cryptoAddr}</p>
            </Row>
            <Row justify="start" className="deposit__remark-modal">
                <p className="modal-description">{getTranslation('Currency')}</p> :
                <p className="withdrawal-record">{currencyCode}</p>
            </Row>
            <Row justify="start" className="deposit__remark-modal">
                <p className="modal-description">{getTranslation('Amount')}</p> :
                <p className="withdrawal-record">{amount}</p>
            </Row>
            <ConfirmModal
                visible={confirmModalVisible}
                onCancel={onCloseModal}
                onConfirm={verifyTransaction}
            />
        </Modal>
    );
};

export default VerifyModal;
