import React, { useEffect, useState } from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import _ from 'lodash';

import './BalanceForm.less';
import { ButtonGreen } from '../../../../../common/components/buttons/Buttons';
import { WALLET_PLATFORMS } from '../../../../../common/constants/misc';
import { getStateProp } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import {
    useGetWalletsAsyncEndpoint,
    useWalletTransferAsyncEndpoint,
} from '../../../../../services/playerService';
import { useStore } from '../../../../../store/StateProvider';
import Loader from '../../../../../common/components/Loader';

const BalanceForm = ({ userId, userName, onCancel }) => {
    const [form] = Form.useForm();
    const { resetFields } = form;
    const [state] = useStore();
    const wallets = getStateProp(state, 'players.playersList.wallets', []);
    const [formValues, setFormValues] = useState({});

    // ============================================
    // METHODS
    // ============================================

    const submitForm = (values) => {
        setFormValues(values);
        handleSubmitTransfer();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [walletLoading, getWallets] = useGetWalletsAsyncEndpoint(userName);

    const [loading, success, handleSubmitTransfer] = useWalletTransferAsyncEndpoint(
        userId,
        formValues.fromWallet,
        formValues.toWallet,
        formValues.amount,
    );

    useEffect(() => {
        if (success) {
            resetFields();
            getWallets();
        }
    }, [success, resetFields, getWallets]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form form={form} name="balance-modification" onFinish={submitForm} style={{ width: 380 }}>
            <Row className="form__group">
                <Col span={8}>
                    <span className="form__group__label">{getTranslation('Username')}:</span>
                </Col>
                <Col span={16}>
                    <span className="username-text">{userName}</span>
                </Col>
            </Row>
            <Row className="form__group">
                <Col span={11}>
                    <Form.Item
                        name="fromWallet"
                        rules={[
                            {
                                message: getTranslation('Please select wallet!'),
                                required: true,
                            },
                        ]}
                    >
                        <Select placeholder={getTranslation('Select Wallet')}>
                            {_.map(WALLET_PLATFORMS, (platform) => (
                                <Select.Option key={platform} value={platform}>
                                    {platform}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={2} align="middle">
                    <ArrowRightOutlined className="arrow-icon" />
                </Col>
                <Col span={11}>
                    <Form.Item
                        name="toWallet"
                        rules={[
                            {
                                message: getTranslation('Please select wallet!'),
                                required: true,
                            },
                        ]}
                    >
                        <Select placeholder={getTranslation('Select Wallet')}>
                            {_.map(WALLET_PLATFORMS, (platform) => (
                                <Select.Option key={platform} value={platform}>
                                    {platform}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row className="form__group">
                <Form.Item
                    name="amount"
                    rules={[
                        {
                            message: getTranslation('Please input amount!'),
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder={getTranslation('Input Amount')} />
                </Form.Item>
            </Row>
            {walletLoading ? (
                <Loader />
            ) : (
                _.map(wallets, (wallet) => (
                    <Row className="form__group" justify="space-around" key={wallet.wallet}>
                        <Col span={12}>
                            <span className="form__group__label">{wallet.wallet}</span>
                        </Col>
                        <Col span={12} align="start">
                            <span className="username-text">{wallet.balance}</span>
                        </Col>
                    </Row>
                ))
            )}

            <Row justify="end" className="form__group">
                <ButtonGreen htmlType="submit" loading={loading}>
                    {getTranslation('Transfer')}
                </ButtonGreen>
                <Button onClick={onCancel}>{getTranslation('Cancel')}</Button>
            </Row>
        </Form>
    );
};

export default BalanceForm;
