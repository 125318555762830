import { ACTIONS } from './generalCreatePushNotificationActions';
import { getInitPushNotification } from '../../helpers';

export default function generalCreatePushNotificationReducer(
    generalCreatePushNotification = {
        pushNotificationInfo: JSON.parse(JSON.stringify(getInitPushNotification())),
        targetType: 'None',
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.CREATE_GENERAL_PUSH_NOTIFICATION_SUCCESS:
        case ACTIONS.RESET_GENERAL_PUSH_NOTIFICATION_INFO:
            return {
                ...generalCreatePushNotification,
                pushNotificationInfo: JSON.parse(JSON.stringify(getInitPushNotification())),
            };

        case ACTIONS.UPDATE_GENERAL_PUSH_NOTIFICATION_INFO:
        case ACTIONS.CREATE_GENERAL_PUSH_NOTIFICATION:
        case ACTIONS.ADD_LANGUAGE:
        case ACTIONS.REMOVE_LANGUAGE:
        default:
            return generalCreatePushNotification;
    }
}
