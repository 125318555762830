import React from 'react';

import { ButtonTransparent } from '../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../common/helpers/locale';
import { onRemarkModalOpen } from '../manualManagementActions';
import { useDispatch } from '../../../../store/StateProvider';

const ViewButton = ({ operatorRemark, applicantRemark }) => {
    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ============================================

    const openRemarkModal = () => onRemarkModalOpen(dispatch, { applicantRemark, operatorRemark });

    // ============================================
    // RENDER
    // ============================================

    return (
        <ButtonTransparent className="hyperlink-button" onClick={openRemarkModal}>
            {getTranslation('View')}
        </ButtonTransparent>
    );
};

export default ViewButton;
