import React, { Component } from 'react';

import Fallback from './Fallback';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { eventId: null, hasError: false };
        this.showReportDialog = this.showReportDialog.bind(this);
        this.setEventId = this.setEventId.bind(this);
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    showReportDialog() {
        if (process.env.NODE_ENV === 'production')
            this.props.logger.showReportDialog(this.state.eventId);
        else console.log('ErrorID: ', this.state.eventId);
    }

    setEventId(id) {
        this.setState({ eventId: id });
    }

    componentDidCatch(error, errorInfo) {
        if (process.env.NODE_ENV === 'production')
            this.props.logger.logError(error, errorInfo, this.setEventId);
        else console.log('ErrorInfo: ', errorInfo);
    }

    render() {
        if (this.state.hasError) {
            //render fallback UI
            return <Fallback onShowReport={this.showReportDialog} />;
        }

        //when there's not an error, render children untouched
        return this.props.children;
    }
}

export default ErrorBoundary;
