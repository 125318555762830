import activeBankAccountReducer from './bank-account-setup/active-bank-account/activeBankAccountReducer';
import activeEwalletReducer from './e-wallet-setup/active-e-wallet/activeEwalletReducer';
import activeMerchantReducer from './merchant-setup/active-merchant/activeMerchantReducer';
import bankAccountConfigurationReducer from './bank-account-configuration/bankAccountConfigurationReducer';
import bankAccountStatementReducer from './bank-account-statement/bankAccountStatementReducer';
import bankReducer from './bank-account-setup/bankReducer';
import cryptoDepositReducer from './cryptocurrency/deposit/cryptoDepositReducer';
import cryptoMerchantConfigurationReducer from './crypto-merchant-configuration/cryptoMerchantConfigurationReducer';
import cryptoWithdrawalReducer from './cryptocurrency/withdrawal/cryptoWithdrawalReducer';
import deactivatedBankAccountReducer from './bank-account-setup/deactivated-bank-account/deactivatedBankAccountReducer';
import deactivatedEwalletReducer from './e-wallet-setup/deactivated-e-wallet/DeactivatedEwalletReducer';
import deactivatedMerchantReducer from './merchant-setup/deactivated-merchant/deactivatedMerchantReducer';
import depositsReducer from './deposits/depositsReducer';
import eWalletReducer from './e-wallet-setup/ewalletReducer';
import ewalletConfigurationReducer from './e-wallet-configuration/ewalletConfigurationReducer';
import ewalletTransfersReducer from './deposits/EwalletTransfersReducer';
import ewalletWithdrawalsReducer from './ewallet-withdrawals/ewalletWithdrawalsReducer';
import merchantConfigurationReducer from './merchant-configuration/merchantConfigurationReducer';
import merchantsReducer from './merchants/merchantsReducer';
import transactionAmountSettingReducer from './transaction-amount-setting/TransactionAmountSettingReducer';
import withdrawalsReducer from './withdrawals/withdrawalsReducer';

export default function paymentReducer(payments = {}, action) {
    const {
        deposits,
        merchantDeposits,
        withdrawals,
        activeBankAccount,
        deactivatedBankAccount,
        bankAccountConfiguration,
        activeMerchant,
        deactivatedMerchant,
        cryptoDeposits,
        cryptoWithdrawals,
        merchantConfiguration,
        bank,
        bankAccountStatement,
        cryptoMerchant,
        transactionAmountSetting,
        ewallet,
        activeEwalletAccount,
        deactivatedEwalletAccount,
        ewalletConfiguration,
        ewalletTransfers,
        ewalletWithdrawals,
    } = payments;

    return {
        activeBankAccount: activeBankAccountReducer(activeBankAccount, action),
        activeEwalletAccount: activeEwalletReducer(activeEwalletAccount, action),
        activeMerchant: activeMerchantReducer(activeMerchant, action),
        bank: bankReducer(bank, action),
        bankAccountConfiguration: bankAccountConfigurationReducer(bankAccountConfiguration, action),
        bankAccountStatement: bankAccountStatementReducer(bankAccountStatement, action),
        cryptoDeposits: cryptoDepositReducer(cryptoDeposits, action),
        cryptoMerchant: cryptoMerchantConfigurationReducer(cryptoMerchant, action),
        cryptoWithdrawals: cryptoWithdrawalReducer(cryptoWithdrawals, action),
        deactivatedBankAccount: deactivatedBankAccountReducer(deactivatedBankAccount, action),
        deactivatedEwalletAccount: deactivatedEwalletReducer(deactivatedEwalletAccount, action),
        deactivatedMerchant: deactivatedMerchantReducer(deactivatedMerchant, action),
        deposits: depositsReducer(deposits, action),
        ewallet: eWalletReducer(ewallet, action),
        ewalletConfiguration: ewalletConfigurationReducer(ewalletConfiguration, action),
        ewalletTransfers: ewalletTransfersReducer(ewalletTransfers, action),
        ewalletWithdrawals: ewalletWithdrawalsReducer(ewalletWithdrawals, action),
        merchantConfiguration: merchantConfigurationReducer(merchantConfiguration, action),
        merchantDeposits: merchantsReducer(merchantDeposits, action),
        transactionAmountSetting: transactionAmountSettingReducer(transactionAmountSetting, action),
        withdrawals: withdrawalsReducer(withdrawals, action),
    };
}
