import React from 'react';

import { Card, Form } from 'antd';

import { API } from '../../../common/constants/api';
import { MODIFICATION_STATUSES } from '../../../components/players/constants';
import {
    getColumns,
    modificationsHistoryRecordsExport,
} from './table/ModificationsHistoryTableMeta';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { modificationsDropFilters } from './modificationsHistoryActions';
import { useExportAsyncEndpoint } from '../../../services/hooks/useExport';
import { useGetModificationsHistoryAsyncEndpoint } from '../../../services/playerService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import ModificationsHistoryFilters from './ModificationsHistoryFilters';
import ModificationsHistoryResultsTable from './table/ModificationsHistoryResultsTable';

const { PLAYERS } = API;

const ModificationsHistory = () => {
    const [state, dispatch] = useStore();
    const filters = getStateProp(state, 'players.modificationsHistory.filters');
    const { totalRecordCount } = getStateProp(state, 'players.modificationsHistory.paging', {});

    // ============================================
    // METHODS
    // ============================================

    const fetchAll = () => {
        modificationsDropFilters(dispatch);
        handleGetModifications();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetModifications] = useGetModificationsHistoryAsyncEndpoint();
    const [handleExportAction] = useExportAsyncEndpoint({
        additionalCol: modificationsHistoryRecordsExport,
        dataMeta: getColumns(),
        filters,
        mandatoryFilter: {
            status: [MODIFICATION_STATUSES.APPROVED, MODIFICATION_STATUSES.DECLINED],
        },
        method: 'POST',
        subject: getTranslation('Modifications History List'),
        totalRecordCount,
        url: `${PLAYERS.MODIFICATION_LIST}`,
    });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Modification History')}>
            <Form onFinish={handleGetModifications}>
                <ModificationsHistoryFilters />
                <ActionButtons onDropFilters={fetchAll} onExport={handleExportAction} />
            </Form>

            <ModificationsHistoryResultsTable loading={loading} />
        </Card>
    );
};

export default ModificationsHistory;
