import React from 'react';

import { Row } from 'antd';

import {
    CryptocurrencyFilter,
    CurrencyFilter,
    PeriodFilter,
    ReceivingWalletFilter,
    TransactionNumberFilter,
    TransactionRefFilter,
    UsernameFilter,
} from '../../../../common/components/filters';
import { cryptoDepositRecordFilterSet } from './cryptoDepositRecordActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const CryptoDepositRecordFilters = () => {
    const [state, dispatch] = useStore();
    const cryptoDepositRecord = getStateProp(
        state,
        'transactionHistory.cryptocurrencyRecords.cryptoDepositRecord',
    );

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => cryptoDepositRecordFilterSet(dispatch, { filter, value });

    return (
        <Row justify="start" align="middle" className="filters-container">
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoDepositRecord, 'filters.playerUserName')}
                name="playerUserName"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(cryptoDepositRecord, 'filters.dateFrom')}
                dateTo={getStateProp(cryptoDepositRecord, 'filters.dateTo')}
                dateFromName="dateFrom"
                dateToName="dateTo"
            />
            <TransactionNumberFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoDepositRecord, 'filters.transactionNo')}
                name="transactionNo"
            />
            <TransactionRefFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoDepositRecord, 'filters.transactionRef')}
            />
            <CryptocurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoDepositRecord, 'filters.cryptoCode')}
                name="cryptoCode"
            />
            <ReceivingWalletFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoDepositRecord, 'filters.cryptoAddr')}
                name="cryptoAddr"
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoDepositRecord, 'filters.currencyCode')}
                name="currencyCode"
            />
        </Row>
    );
};

export default CryptoDepositRecordFilters;
