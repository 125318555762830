import { ACTIONS } from './playerDetailsActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function playerDetailsReducer(
    playerDetails = {
        messages: {
            paging: {
                pageNo: TABLE_PAGE_NO_DEFAULT,
                pageSize: TABLE_PAGE_SIZE_DEFAULT,
                totalRecordCount: 0,
            },
        },
        playerInfo: {
            profile: {},
        },
        userSessions: {
            paging: {
                pageNo: TABLE_PAGE_NO_DEFAULT,
                pageSize: TABLE_PAGE_SIZE_DEFAULT,
                totalRecordCount: 0,
            },
        },
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.PLAYER_GET_BY_ID_SUCCESS:
            return {
                ...playerDetails,
                ...payload,
            };

        case ACTIONS.USER_SESSIONS.GET_BY_USER_ID_SUCCESS:
            return {
                ...playerDetails,
                userSessions: {
                    list: payload.list,
                    paging: {
                        ...playerDetails.userSessions.paging,
                        ...payload.paging,
                    },
                },
            };

        case ACTIONS.USER_SESSIONS.GET_SUCCESS:
            return {
                ...playerDetails,
                userSessions: {
                    list: payload.list,
                    paging: {
                        ...playerDetails.userSessions.paging,
                        ...payload.paging,
                    },
                },
            };

        case ACTIONS.USER_SESSIONS.FILTER_SET:
            return {
                ...playerDetails,
                filters: {
                    ...playerDetails.filters,
                    [payload.filter]: payload.value,
                },
            };

        case ACTIONS.FILTERS_DROP_ALL:
            return {
                ...playerDetails,
                filters: null,
            };

        case ACTIONS.USER_SESSIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...playerDetails,
                userSessions: {
                    ...playerDetails.userSessions,
                    paging: {
                        ...playerDetails.userSessions.paging,
                        pageNo:
                            payload > playerDetails.userSessions.paging.pageCount
                                ? playerDetails.userSessions.paging.pageCount - 1
                                : payload,
                    },
                },
            };
        case ACTIONS.USER_SESSIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_REMARKS_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...playerDetails,
                userSessions: {
                    ...playerDetails.userSessions,
                    paging: {
                        ...playerDetails.userSessions.paging,
                        pageNo: 1,
                        pageSize:
                            payload < 1
                                ? TABLE_PAGE_SIZE_DEFAULT
                                : payload > MAX_REMARKS_PER_PAGE
                                ? MAX_REMARKS_PER_PAGE
                                : payload,
                    },
                },
            };

        case ACTIONS.UPDATE_PLAYER_INFO_SUCCESS:
            return {
                ...playerDetails,
                playerInfo: {
                    ...playerDetails.playerInfo,
                    profile: {
                        ...playerDetails.playerInfo.profile,
                        ...payload,
                    },
                },
            };

        case ACTIONS.BANK_CARDS_MODAL_OPEN:
            return {
                ...playerDetails,
                bankCardsModal: {
                    cardAttending: { ...payload.card },
                    isVisible: true,
                    modalType: payload.modalType,
                },
            };
        case ACTIONS.BANK_CARDS_MODAL_CLOSE:
            return {
                ...playerDetails,
                bankCardsModal: {
                    cardAttending: {},
                    isVisible: false,
                    modalType: null,
                },
            };

        case ACTIONS.E_WALLET_MODAL_OPEN:
            return {
                ...playerDetails,
                eWalletModal: {
                    eWalletAttending: { ...payload.eWallet },
                    isVisible: true,
                    modalType: payload.modalType,
                },
            };
        case ACTIONS.E_WALLET_MODAL_CLOSE:
            return {
                ...playerDetails,
                eWalletModal: {
                    eWalletAttending: {},
                    isVisible: false,
                    modalType: null,
                },
            };

        case ACTIONS.ADD_E_WALLET:
        case ACTIONS.ADD_E_WALLET_SUCCESS:
        case ACTIONS.ADD_BANK_CARD:
        case ACTIONS.ADD_BANK_CARD_SUCCESS:
        case ACTIONS.DELETE_E_WALLET:
        case ACTIONS.DELETE_E_WALLET_SUCCESS:
        case ACTIONS.DELETE_BANK_CARD:
        case ACTIONS.DELETE_BANK_CARD_SUCCESS:
        case ACTIONS.PLAYER_GET_BY_ID:
        case ACTIONS.UPDATE_PLAYER_INFO:
        case ACTIONS.USER_SESSIONS.GET:
        case ACTIONS.USER_SESSIONS.GET_BY_USER_ID:
        case ACTIONS.WITHDRAWAL_REQUEST:
        case ACTIONS.WITHDRAWAL_REQUEST_SUCCESS:
        case ACTIONS.E_WALLET_WITHDRAWAL_REQUEST:
        case ACTIONS.E_WALLET_WITHDRAWAL_REQUEST_SUCCESS:
        default:
            return playerDetails;
    }
}
