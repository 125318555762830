import React from 'react';

import _ from 'lodash';

import { ButtonGray } from '../../../../common/components/buttons/Buttons';
import { RenderDateFormat } from '../../../../common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../common/constants/api';
import { changePageNumber, changePageSize } from '../withdrawalRecordsActions';
import { getStatusColor, sortTableByDataType } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import VerifyButton from '../verification/VerifyButton';

export const getColumns = (selectedColumns) => {
    const columns = [
        {
            dataIndex: 'requestedDateTime',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Request Time'),
        },
        {
            dataIndex: 'transactionNo',
            title: getTranslation('Task Transaction Number'),
        },
        {
            dataIndex: 'playerUserName',
            title: getTranslation('Username'),
        },
        {
            dataIndex: 'memberLevel',
            title: getTranslation('Member Level'),
        },
        {
            dataIndex: 'affiliate',
            title: getTranslation('Affiliate'),
        },
        {
            dataIndex: 'referral',
            title: getTranslation('Referral'),
        },
        {
            dataIndex: 'currencyCode',
            title: getTranslation('Currency'),
        },
        {
            dataIndex: 'amount',
            title: getTranslation('Amount'),
        },
        {
            dataIndex: 'bankName',
            title: getTranslation('Bank Name'),
        },
        {
            dataIndex: 'auditDateTime',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Audit Time'),
        },
        {
            dataIndex: 'processedDateTime',
            dataType: 'date',
            render: (date) => RenderDateFormat(date),
            title: getTranslation('Process Time'),
        },
        {
            dataIndex: 'auditorRemarks',
            title: getTranslation('Auditor Remark'),
        },
        {
            dataIndex: 'operatorRemarks',
            title: getTranslation('Operator Remark'),
        },
        {
            dataIndex: 'orderStatus',
            render: (value) => (
                <span
                    style={{
                        color: getStatusColor(value),
                    }}
                >
                    {getTranslation(value).toLocaleUpperCase()}
                </span>
            ),
            title: getTranslation('Status'),
        },
        {
            dataIndex: 'auditorName',
            render: (value) =>
                value && <ButtonGray disabled label={value} className="action-btn__verify" />,
            title: getTranslation('Audit'),
        },
        {
            dataIndex: 'operatorName',
            render: (text, record) => <VerifyButton withdrawalRecord={record} />,
            title: getTranslation('Operator'),
        },
    ];

    return columns
        .filter((column) => _.map(selectedColumns, 'key').includes(column.dataIndex))
        .map((colProps) => ({
            ...colProps,
            sorter: (r1, r2) => sortTableByDataType(r1, r2, colProps.dataIndex, colProps.dataType),
        }));
};

export const getPaginationOptions = (paging, dispatch) => {
    const { totalRecordCount, pageSize, pageNo } = paging;
    return {
        current: pageNo,
        onChange: onChangePageNo(dispatch),
        onShowSizeChange: onChangePageSize(dispatch),
        pageSize,
        pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
        total: totalRecordCount,
    };
};

const onChangePageNo = (dispatch) => (pageNo) => changePageNumber(dispatch, pageNo);

const onChangePageSize = (dispatch) => (_, pageSize) => changePageSize(dispatch, pageSize);
