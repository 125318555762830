import React from 'react';

import { Card } from 'antd';
import { getSelectedCurrencies, updateWhatsNewInfo } from '../helper';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { updateCreateWhatsNewInfo } from './createWhatsNewActions';
import { useStore } from '../../../store/StateProvider';
import WhatsNewDetails from '../WhatsNewDetails';

const CreateWhatsNew = () => {
    const [state, dispatch] = useStore();
    const { whatsNewInfo = {} } = getStateProp(state, 'whatsNew.createWhatsNew');
    const selectedCurrencies = getSelectedCurrencies(whatsNewInfo);
    // ============================================
    // METHODS
    // ============================================
    const handleUpdateCreateWhatsNewInfo = (value, key, currencyCode, languageCode) => {
        updateCreateWhatsNewInfo(
            dispatch,
            updateWhatsNewInfo(whatsNewInfo, value, key, currencyCode, languageCode),
        );
    };

    // ============================================
    // HOOKS
    // ============================================

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation(`Create What's New`)} className="create-whats-new">
            <WhatsNewDetails
                whatsNewInfo={whatsNewInfo}
                selectedCurrencies={selectedCurrencies}
                handleUpdateWhatsNewInfo={handleUpdateCreateWhatsNewInfo}
            />
        </Card>
    );
};

export default CreateWhatsNew;
