import { ACTIONS } from './menuActions';

export default function menuReducer(menu = {}, { type, payload = null }) {
    switch (type) {
        case ACTIONS.MENU_LIST_GET_SUCCESS:
            return payload;
        default:
            return menu;
    }
}
