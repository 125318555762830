import { useCallback, useEffect, useState } from 'react';

import { API } from '../common/constants/api';
import { clearError } from '../common/actions/errorActions';

import {
    createReferralProgram,
    createReferralProgramFailure,
    createReferralProgramSuccess,
} from '../components/referral/create-referral/createReferralActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import {
    getActiveReferral,
    getActiveReferralFailure,
    getActiveReferralSuccess,
    referralManagementGet,
    referralManagementGetById,
    referralManagementGetByIdFailure,
    referralManagementGetByIdSuccess,
    referralManagementGetFailure,
    referralManagementGetSuccess,
    referralManagementUpdate,
    referralManagementUpdateFailure,
    referralManagementUpdateSuccess,
    referralStatusSetFailure,
    referralStatusSetSuccess,
} from '../components/referral/referral-management/referralManagementAction';
import { getTranslation } from '../common/helpers/locale';
import {
    refereeReportGet,
    refereeReportGetFailure,
    refereeReportGetSuccess,
    referrerReportGet,
    referrerReportGetFailure,
    referrerReportGetSuccess,
} from '../components/referral/referrer-report/referrerReportActions';
import {
    referralInquiryGet,
    referralInquiryGetFailure,
    referralInquiryGetSuccess,
} from '../components/referral/inquiry/referralInquiryActions';
import {
    referralReportGet,
    referralReportGetFailure,
    referralReportGetSuccess,
    topTenReferrerGet,
    topTenReferrerGetFailure,
    topTenReferrerGetSuccess,
} from '../components/referral/referral-report/referralReportActions';
import { useDispatch, useStore } from '../store/StateProvider';
import { useToasterErrorMessage, useToasterSuccessMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, REFERRAL } = API;

export function useSetReferralProgramAsyncEndpoint(referralInfo) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);
    const [created, setCreated] = useState(false);

    // ============================================
    // METHODS
    // ============================================

    const handleCreateReferralProgram = () => {
        createReferralProgram(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: referralInfo,
        },
        trigger,
        url: `${VERSION.V1}${REFERRAL.REFERRAL}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            createReferralProgramSuccess(dispatch);
            setCreated(true);
        }
    }, [dispatch, response]);

    useToasterSuccessMessage(response, getTranslation('Referral program successfully added!'));

    const errorMsg = useError(error, createReferralProgramFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleCreateReferralProgram, created];
}

export function useGetReferralProgramAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { pageNo, pageSize } = getStateProp(state, 'referral.referralManagement.paging');
    const filters = getStateProp(state, 'referral.referralManagement.filters', {});

    // ============================================
    // METHODS
    // ============================================

    const handleGetReferral = useCallback(() => {
        referralManagementGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'GET',
        options: {
            params: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${REFERRAL.REFERRAL}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            referralManagementGetSuccess(dispatch, {
                fetchData: handleGetReferral,
                list: response.data.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetReferral, response]);

    useEffect(() => {
        if (pageNo && pageSize) handleGetReferral();
    }, [handleGetReferral, pageNo, pageSize]);

    const errorMsg = useError(error, referralManagementGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetReferral];
}

export function useSetStatusOperationAsyncEndpoint(id, programStatus) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { referralManagement } = getStateProp(state, 'referral');
    const refreshTable = getStateProp(referralManagement, 'fetchData', {});
    // ============================================
    // HOOKS
    // ============================================

    const handleSetReferralStatus = useCallback(() => {
        setTrigger(+new Date());
    }, []);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'PATCH',
        options: {
            data: {
                id,
                programStatus,
            },
        },
        trigger,
        url: `${VERSION.V1}${REFERRAL.STATUS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            referralStatusSetSuccess(dispatch);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    useToasterSuccessMessage(response, getTranslation('Referral status updated successfully'));

    const errorMsg = useError(error, referralStatusSetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleSetReferralStatus];
}

export function useGetReferralProgramByIdAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { selectedId } = getStateProp(state, 'referral.referralManagement');

    // ============================================
    // METHODS
    // ============================================

    const handleGetReferralById = useCallback(() => {
        referralManagementGetById(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'GET',
        options: {},
        trigger,
        url: `${VERSION.V1}${REFERRAL.REFERRAL}/${selectedId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            referralManagementGetByIdSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, referralManagementGetByIdFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetReferralById];
}

export function useUpdateReferralAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const [updated, setUpdated] = useState(false);

    const { referralManagement } = getStateProp(state, 'referral');
    const selectedReferral = getStateProp(referralManagement, 'selectedReferral');
    const selectedId = getStateProp(referralManagement, 'selectedId');
    const refreshTable = getStateProp(referralManagement, 'fetchData', {});
    // ============================================
    // HOOKS
    // ============================================

    const handleUpdateReferral = useCallback(() => {
        referralManagementUpdate(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'PUT',
        options: {
            data: selectedReferral,
        },
        trigger,
        url: `${VERSION.V1}${REFERRAL.REFERRAL}/${selectedId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            referralManagementUpdateSuccess(dispatch);
            setUpdated(true);
            refreshTable();
        }
    }, [dispatch, refreshTable, response]);

    useToasterSuccessMessage(response, getTranslation('Referral details updated successfully'));

    const errorMsg = useError(error, referralManagementUpdateFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleUpdateReferral, updated];
}

export function useGetActiveReferralProgramAsyncEndpoint() {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetActiveReferral = useCallback(() => {
        getActiveReferral(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${REFERRAL.ACTIVE_REFERRAL}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getActiveReferralSuccess(dispatch, {
                ...response.data.data,
                isLoading: loading,
            });
        }
    }, [dispatch, loading, response]);

    const errorMsg = useError(error, getActiveReferralFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetActiveReferral];
}

export function useGetReferralReportAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { referralReport } = getStateProp(state, 'referral');
    const { pageNo, pageSize } = getStateProp(referralReport, 'paging');
    const filters = getStateProp(referralReport, 'filters', {});
    const {
        totalReferee,
        referralConversionRate,
        refereeWithdrawal,
        refereeDeposit,
        refereeWinLoss,
        ...restFilter
    } = filters;

    // ============================================
    // METHODS
    // ============================================

    const handleGetReferralRecords = useCallback(() => {
        referralReportGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                [totalReferee.condition]: totalReferee.value,
                [referralConversionRate.condition]: referralConversionRate.value,
                [refereeWithdrawal.condition]: refereeWithdrawal.value,
                [refereeDeposit.condition]: refereeDeposit.value,
                [refereeWinLoss.condition]: refereeWinLoss.value,
                ...filterTrim(restFilter),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${REFERRAL.REFERRAL_REPORT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            referralReportGetSuccess(dispatch, {
                fetchData: handleGetReferralRecords,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetReferralRecords, response]);

    useEffect(() => {
        handleGetReferralRecords();
    }, [handleGetReferralRecords, pageNo, pageSize]);

    const errorMsg = useError(error, referralReportGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetReferralRecords];
}

export function useGetReferrerReportAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { referrerReport } = getStateProp(state, 'referral');
    const { pageNo, pageSize } = getStateProp(referrerReport, 'paging');
    const filters = getStateProp(referrerReport, 'filters', {});
    const {
        refereeWithdraw,
        conversionRate,
        refereeWinLoss,
        refereeDeposit,
        rewardsEarned,
        totalRefereeWeekly,
        ...restFilter
    } = filters;

    // ============================================
    // METHODS
    // ============================================

    const handleGetReferrerRecords = useCallback(() => {
        referrerReportGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                [refereeWithdraw.condition]: refereeWithdraw.value,
                [conversionRate.condition]: conversionRate.value,
                [refereeWinLoss.condition]: refereeWinLoss.value,
                [refereeDeposit.condition]: refereeDeposit.value,
                [rewardsEarned.condition]: rewardsEarned.value,
                [totalRefereeWeekly.condition]: totalRefereeWeekly.value,
                ...filterTrim(restFilter),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${REFERRAL.REFERRER_REPORT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            referrerReportGetSuccess(dispatch, {
                fetchData: handleGetReferrerRecords,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetReferrerRecords, response]);

    useEffect(() => {
        handleGetReferrerRecords();
    }, [handleGetReferrerRecords, pageNo, pageSize]);

    const errorMsg = useError(error, referrerReportGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetReferrerRecords];
}

export function useGetRefereeReportAsyncEndpoint(id, type) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const { referrerReport } = getStateProp(state, 'referral');
    const { pageNo, pageSize } = getStateProp(referrerReport, 'refereeReport.paging');
    const referrerId = type === 'totalDepositedReferee' ? id : undefined;
    const userId =
        type === 'totalReferee' || type === 'totalDepositedRefereeInquiry' ? id : undefined;
    const showDepositedReferee = type === 'totalDepositedRefereeInquiry' ? true : undefined;

    // ============================================
    // METHODS
    // ============================================

    const handleGetRefereeRecords = useCallback(() => {
        refereeReportGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                pageNo,
                pageSize,
                referrerIds: referrerId,
                showDepositedReferee: showDepositedReferee,
                userId: userId,
            },
        },
        trigger,
        url: `${VERSION.V1}${REFERRAL.REFEREE_REPORT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            refereeReportGetSuccess(dispatch, {
                fetchData: handleGetRefereeRecords,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetRefereeRecords, response]);

    useEffect(() => {
        if (pageNo && pageSize && id) handleGetRefereeRecords();
    }, [handleGetRefereeRecords, id, pageNo, pageSize]);

    const errorMsg = useError(error, refereeReportGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useGetTopTenReferrerReportAsyncEndpoint(programId) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetTopTenReferrer = useCallback(() => {
        topTenReferrerGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!programId,
        method: 'GET',
        options: {
            params: {
                programId,
                top10: true,
            },
        },
        trigger,
        url: `${VERSION.V1}${REFERRAL.REFERRER_REPORT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            topTenReferrerGetSuccess(dispatch, {
                fetchData: handleGetTopTenReferrer,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetTopTenReferrer, response]);

    const errorMsg = useError(error, topTenReferrerGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetTopTenReferrer];
}

export function useGetReferralInquiryAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const { pageNo, pageSize } = getStateProp(state, 'referral.referralInquiry.paging');
    const filters = getStateProp(state, 'referral.referralInquiry.filters', {});
    const { totalDepositedReferee, totalReferee, ...restFilter } = filters;

    // ============================================
    // METHODS
    // ============================================

    const handleGetReferralInquiry = useCallback(() => {
        referralInquiryGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => trigger,
        method: 'GET',
        options: {
            params: {
                [totalReferee.condition]: totalReferee.value,
                [totalDepositedReferee.condition]: totalDepositedReferee.value,
                ...filterTrim(restFilter),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${REFERRAL.REFERRAL_INQUIRY}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            referralInquiryGetSuccess(dispatch, {
                fetchData: handleGetReferralInquiry,
                list: response.data.data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetReferralInquiry, response]);

    useEffect(() => {
        if (pageNo && pageSize) handleGetReferralInquiry();
    }, [handleGetReferralInquiry, pageNo, pageSize]);

    const errorMsg = useError(error, referralInquiryGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetReferralInquiry];
}
