import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { PAYMENT_EWALLET_ACCOUNT_TYPES } from '../../../common/constants/misc';
import { getTranslation } from '../../../common/helpers/locale';

const EwalletSetupTypeFilter = ({ value, onFilterChange }) => {
    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('type', value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear={true}
            placeholder={getTranslation('Type')}
            className="filter"
        >
            {_.map(PAYMENT_EWALLET_ACCOUNT_TYPES, (types) => (
                <Select.Option value={types} key={types}>
                    {getTranslation(types).toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
};

export default EwalletSetupTypeFilter;
