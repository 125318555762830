import React, { useState } from 'react';

import { Col, Divider, Form, Input, Row, Select, Tabs } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import _ from 'lodash';

import {
    ButtonGreen,
    ButtonLightBlue,
    ButtonTransparent,
} from '../../../../common/components/buttons/Buttons';
import { TARGET_NOTIFICATION_TYPES } from '../../constants';
import {
    getLanguageByCode,
    getSupportedCurrenciesByCountry,
    getTranslation,
} from '../../../../common/helpers/locale';
import { getPushNotificationLang } from '../../helpers';
import { isSupportedLanguage } from '../../../../common/helpers/misc';
import { resetTargetPushNotificationInfo } from '../../create-push-notification/target/targetCreatePushNotificationActions';
import { useDispatch } from '../../../../store/StateProvider';
import LanguagesCheckboxes from '../../../../common/components/LanguagesCheckboxes';
import PlayerSearchModal from './PlayerSearchModal';

const { TextArea } = Input;
const TargetPushNotificationDetails = ({
    targetAspect,
    pushNotificationInfo,
    handleLanguageSet,
    handleUpdateInfoByLanguage,
    handleUpdateInfo,
    usernames = [],
    currency = [],
    creating,
    formSubmit,
    updating,
    sendMessage,
    sending,
    isEdit = false,
    status = null,
}) => {
    const dispatch = useDispatch();
    const [playerBatchSearchModalVisible, setPlayerBatchSearchModalVisible] = useState(false);
    const selectedLanguage = getPushNotificationLang(pushNotificationInfo);
    const aspect = _.capitalize(targetAspect);

    // ============================================
    // METHODS
    // ============================================

    const onPlayerSearchModalOpen = () => setPlayerBatchSearchModalVisible(true);

    const onPlayerSearchModalClose = () => setPlayerBatchSearchModalVisible(false);

    const setValidPlayers = (players) => {
        handleUpdateInfo({ userName: _.map(players, (player) => player.userName) }, 'userNames');
        handleUpdateInfo({ token: _.flatten(_.map(players, (player) => player.token)) }, 'tokens');
    };

    const resetForm = () => {
        resetTargetPushNotificationInfo(dispatch);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form onFinish={formSubmit}>
            <Row className="create-push-notification__form-row">
                <Col span={6}>
                    <label>{getTranslation('Target Type')}</label>
                </Col>
                <Col span={16}>
                    <Select
                        value={aspect}
                        onChange={(value) => handleUpdateInfo(value, 'targetType')}
                        disabled={isEdit}
                    >
                        {_.map(TARGET_NOTIFICATION_TYPES, (type) => (
                            <Select.Option key={type} value={type}>
                                {type.toLocaleUpperCase()}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
            </Row>
            {aspect === TARGET_NOTIFICATION_TYPES.USERNAME ? (
                <Row className="create-push-notification__form-row">
                    <Col span={6}>
                        <label>{getTranslation('Usernames')}</label>
                    </Col>
                    <Col span={16}>
                        <Row gutter={16}>
                            {status === 'Verified' ? (
                                <Col span={24}>{_.join(usernames, ',')}</Col>
                            ) : (
                                <>
                                    <Col span={19}>
                                        <Input
                                            placeholder={`${usernames.length} ${getTranslation(
                                                'users',
                                            )}`}
                                            readOnly
                                            style={{ cursor: 'initial' }}
                                        />
                                    </Col>
                                    <Col span={5}>
                                        <ButtonGreen
                                            icon={<FileExcelOutlined />}
                                            label={getTranslation('Open')}
                                            style={{ width: '100%' }}
                                            onClick={onPlayerSearchModalOpen}
                                        />
                                    </Col>
                                </>
                            )}
                        </Row>
                    </Col>
                </Row>
            ) : (
                <Row className="create-push-notification__form-row">
                    <Col span={6}>
                        <label>{getTranslation('Currency')}</label>
                    </Col>
                    <Col span={16}>
                        <Select
                            mode="multiple"
                            onChange={(value) => handleUpdateInfo(value, 'currency')}
                            value={currency}
                            disabled={status === 'Verified'}
                        >
                            {_.map(getSupportedCurrenciesByCountry(), (currency) => (
                                <Select.Option key={currency.currency} value={currency.currency}>
                                    {currency.currency}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
            )}

            <Row className="create-push-notification__form-row">
                <Col span={6}>
                    <label>{getTranslation('Languages')}</label>
                </Col>
                <Col span={16}>
                    <Form.Item>
                        <LanguagesCheckboxes
                            onCheckedLanguages={handleLanguageSet}
                            value={selectedLanguage}
                            disabled={status === 'Verified'}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Tabs className="create-push-notification__width--100" type="card" hideAdd>
                    {_.map(_.sortBy(pushNotificationInfo, ['languageCode']), (info) => {
                        return (
                            <Tabs.TabPane
                                tab={
                                    info.languageName
                                        ? info.languageName
                                        : getLanguageByCode(info.languageCode)
                                }
                                key={info.languageCode}
                            >
                                <Row className="create-push-notification__form-item">
                                    <Col span={6}>
                                        <label>{getTranslation('Title')}</label>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="title" valuePropName="title">
                                            <Input
                                                onChange={(e) =>
                                                    handleUpdateInfoByLanguage(
                                                        e.target.value,
                                                        info.languageCode,
                                                        'title',
                                                    )
                                                }
                                                value={info.title}
                                                disabled={
                                                    status === 'Verified' ||
                                                    !isSupportedLanguage(info.languageCode)
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="create-push-notification__form-item">
                                    <Col span={6}>
                                        <label>{getTranslation('Message')}</label>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="content" valuePropName="content">
                                            <TextArea
                                                rows={4}
                                                onChange={(e) =>
                                                    handleUpdateInfoByLanguage(
                                                        e.target.value,
                                                        info.languageCode,
                                                        'content',
                                                    )
                                                }
                                                value={info.content}
                                                disabled={
                                                    status === 'Verified' ||
                                                    !isSupportedLanguage(info.languageCode)
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Tabs.TabPane>
                        );
                    })}
                </Tabs>
            </Row>

            <Divider />

            <Row className="action-buttons" justify="space-between">
                <Col span={4}>
                    {isEdit && (
                        <ButtonLightBlue
                            label={getTranslation('Send')}
                            onClick={sendMessage}
                            loading={sending}
                        />
                    )}
                </Col>

                <Col span={8}>
                    <Row justify="end">
                        <ButtonGreen
                            label={getTranslation('Submit')}
                            htmlType="submit"
                            loading={creating || updating}
                            disabled={status === 'Verified'}
                        />

                        <ButtonTransparent label={getTranslation('Cancel')} onClick={resetForm} />
                    </Row>
                </Col>
            </Row>

            <PlayerSearchModal
                visible={playerBatchSearchModalVisible}
                onCancel={onPlayerSearchModalClose}
                setValidPlayers={setValidPlayers}
                isDownloadedApp={true}
                playerList={_.join(usernames, ',')}
            />
        </Form>
    );
};

export default TargetPushNotificationDetails;
