import { ACTIONS } from './fileSystemActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../common/constants/api';

export default function fileSystemReducer(
    fileSystem = {
        operation: {
            refreshTime: 15,
            refreshTimePause: false,
        },
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.FILE_LIST_GET_SUCCESS:
            return {
                ...fileSystem,
                ...payload,
            };

        case ACTIONS.RECORD_SELECT:
            return {
                ...fileSystem,
                selectedFiles: payload,
            };

        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...fileSystem,
                paging: {
                    ...fileSystem.paging,
                    pageNo:
                        payload > fileSystem.paging.pageCount
                            ? fileSystem.paging.pageCount - 1
                            : payload,
                },
            };

        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...fileSystem,
                paging: {
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };

        case ACTIONS.REFRESH_TIME_SET:
            return {
                ...fileSystem,
                operation: {
                    refreshTime: payload.timeOut,
                    refreshTimePause: false,
                },
            };
        case ACTIONS.REFRESH_TIME_PAUSE:
            return {
                ...fileSystem,
                operation: {
                    refreshTimePause: true,
                },
            };

        case ACTIONS.FILE_DELETE:
        case ACTIONS.FILE_DELETE_SUCCESS:
        case ACTIONS.FILE_LIST_GET:
        default:
            return fileSystem;
    }
}
