import React from 'react';

import { Row } from 'antd';

import {
    CryptocurrencyFilter,
    CurrencyFilter,
    OrderStatusFilter,
    PeriodFilter,
    ReceivingWalletFilter,
    TransactionNumberFilter,
    UsernameFilter,
} from '../../../../common/components/filters';

import { cryptoWithdrawalRecordFilterSet } from './cryptoWithdrawalRecordActions';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const CryptoWithdrawalRecordFilters = () => {
    const [state, dispatch] = useStore();
    const cryptoWithdrawalRecord = getStateProp(
        state,
        'transactionHistory.cryptocurrencyRecords.cryptoWithdrawalRecord',
    );

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) =>
        cryptoWithdrawalRecordFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoWithdrawalRecord, 'filters.playerUserName')}
                name="playerUserName"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(cryptoWithdrawalRecord, 'filters.dateFrom')}
                dateTo={getStateProp(cryptoWithdrawalRecord, 'filters.dateTo')}
                dateFromName="dateFrom"
                dateToName="dateTo"
            />
            <TransactionNumberFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoWithdrawalRecord, 'filters.transactionNo')}
                name="transactionNo"
            />
            <CryptocurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoWithdrawalRecord, 'filters.cryptoCode')}
                name="cryptoCode"
            />
            <OrderStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoWithdrawalRecord, 'filters.orderStatus')}
                name="orderStatus"
            />
            <ReceivingWalletFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoWithdrawalRecord, 'filters.cryptoAddr')}
                name="cryptoAddr"
            />
            <CurrencyFilter
                onFilterChange={setFilter}
                value={getStateProp(cryptoWithdrawalRecord, 'filters.currencyCode')}
                name="currencyCode"
            />
        </Row>
    );
};

export default CryptoWithdrawalRecordFilters;
