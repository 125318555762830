import React, { useState } from 'react';

import { Col, Form, Input, Row } from 'antd';
import _ from 'lodash';

import './CategoryForm.less';
import { ButtonGray, ButtonGreen } from '../../../../../common/components/buttons/Buttons';
import { LANGUAGES } from '../../../../../common/constants/localization';
import { getStateProp } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import {
    useCreateBonusCategoryAsyncEndpoint,
    useUpdateBonusCategoryAsyncEndpoint,
} from '../../../../../services/bonusCategoryService';

import { useStore } from '../../../../../store/StateProvider';

const CategoryForm = ({ onCancel }) => {
    const [state] = useStore();
    const [formValues, setFormValues] = useState(null);
    const categoryAttending = getStateProp(
        state,
        'bonus.bonusCategory.bonusCategoryModal.categoryAttending',
        {},
    );
    const { categoryNames, categoryStatus } = categoryAttending;

    const initialValues = {};

    // ============================================
    // METHODS
    // ============================================

    const convertArrayToObject = () => {
        return _.reduce(
            categoryNames,
            function(obj, val) {
                return _.assign(initialValues, { [val.languageCode]: val.name });
            },
            {},
        );
    };

    const submitForm = (values) => {
        setFormValues(
            _.map(values, (value, key) => {
                return _.isEmpty(categoryAttending)
                    ? {
                          languageCode: key,
                          name: value,
                      }
                    : {
                          id: _.find(categoryNames, (name) => name.languageCode === key).id,
                          languageCode: key,
                          name: value,
                      };
            }),
        );

        _.isEmpty(categoryAttending) ? handleCreateCategory() : handleUpdateCategory();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [creating, handleCreateCategory] = useCreateBonusCategoryAsyncEndpoint(formValues);
    const [updating, handleUpdateCategory] = useUpdateBonusCategoryAsyncEndpoint(formValues);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Form
            className="category-form"
            name="category-form"
            initialValues={convertArrayToObject()}
            onFinish={submitForm}
        >
            {_.map(LANGUAGES, (language) => (
                <Row className="category-form__row" key={language.code}>
                    <Col span={6}>{language.name}</Col>
                    <Col span={18}>
                        <Form.Item
                            name={[language.value]}
                            rules={[
                                {
                                    message: getTranslation('Category Name Cannot Be Empty'),
                                    required: true,
                                },
                            ]}
                        >
                            <Input disabled={categoryStatus === 'Deleted'} />
                        </Form.Item>
                    </Col>
                </Row>
            ))}

            <Row justify="end" className="category-form__btn-row">
                <ButtonGreen
                    label={getTranslation('Submit')}
                    htmlType="submit"
                    loading={creating || updating}
                    disabled={categoryStatus === 'Deleted'}
                />
                <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
            </Row>
        </Form>
    );
};

export default CategoryForm;
