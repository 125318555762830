export const ACTIONS = {
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'USER_AUTHORITY_SETTING_LIST_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'USER_AUTHORITY_SETTING_LIST_TABLE_PAGE_SIZE_CHANGE',
    },
    USER_AUTHORITY_DETAIL_GET: 'USER_AUTHORITY_DETAIL_GET',
    USER_AUTHORITY_DETAIL_GET_FAILURE: 'USER_AUTHORITY_DETAIL_GET_FAILURE',
    USER_AUTHORITY_DETAIL_GET_SUCCESS: 'USER_AUTHORITY_DETAIL_GET_SUCCESS',
    USER_AUTHORITY_SET: 'USER_AUTHORITY_SET',
    USER_AUTHORITY_SETTING_INFO_SET: 'USER_AUTHORITY_SETTING_INFO_SET',
    USER_AUTHORITY_SETTING_LIST_GET: 'USER_AUTHORITY_SETTING_LIST_GET',
    USER_AUTHORITY_SETTING_LIST_GET_FAILURE: 'USER_AUTHORITY_SETTING_LIST_GET_FAILURE',
    USER_AUTHORITY_SETTING_LIST_GET_SUCCESS: 'USER_AUTHORITY_SETTING_LIST_GET_SUCCESS',
    USER_AUTHORITY_SETTING_MODAL_CLOSE: 'USER_AUTHORITY_SETTING_MODAL_CLOSE',
    USER_AUTHORITY_SETTING_MODAL_OPEN: 'USER_AUTHORITY_SETTING_MODAL_OPEN',
    USER_AUTHORITY_SET_FAILURE: 'USER_AUTHORITY_SET_FAILURE',
    USER_AUTHORITY_SET_SUCCESS: 'USER_AUTHORITY_SET_SUCCESS',
    USER_CREATE: 'USER_CREATE',
    USER_CREATE_FAILURE: 'USER_CREATE_FAILURE',
    USER_CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
    USER_CURRENCY_CHANGE: 'USER_CURRENCY_CHANGE',
    USER_CURRENCY_CHANGE_FAILURE: 'USER_CURRENCY_CHANGE_FAILURE',
    USER_CURRENCY_CHANGE_SUCCESS: 'USER_CURRENCY_CHANGE_SUCCESS',
    USER_GROUP_GET: 'USER_GROUP_GET',
    USER_GROUP_GET_FAILURE: 'USER_GROUP_GET_FAILURE',
    USER_GROUP_GET_SUCCESS: 'USER_GROUP_GET_SUCCESS',
    USER_INDIVIDUAL_AUTHORITY_GET: 'USER_INDIVIDUAL_AUTHORITY_GET',
    USER_INDIVIDUAL_AUTHORITY_GET_FAILURE: 'USER_INDIVIDUAL_AUTHORITY_GET_FAILURE',
    USER_INDIVIDUAL_AUTHORITY_GET_SUCCESS: 'USER_INDIVIDUAL_AUTHORITY_GET_SUCCESS',
    USER_INDIVIDUAL_AUTHORITY_SET: 'USER_INDIVIDUAL_AUTHORITY_SET',
    USER_INDIVIDUAL_AUTHORITY_SETTING_INFO_SET: 'USER_INDIVIDUAL_AUTHORITY_SETTING_INFO_SET',
    USER_INDIVIDUAL_AUTHORITY_SET_FAILURE: 'USER_INDIVIDUAL_AUTHORITY_SET_FAILURE',
    USER_INDIVIDUAL_AUTHORITY_SET_SUCCESS: 'USER_INDIVIDUAL_AUTHORITY_SET',
    USER_PASSWORD_CHANGE: 'USER_PASSWORD_CHANGE',
    USER_PASSWORD_CHANGE_FAILURE: 'USER_PASSWORD_CHANGE_FAILURE',
    USER_PASSWORD_CHANGE_SUCCESS: 'USER_PASSWORD_CHANGE_SUCCESS',
    USER_STATUS_CHANGE: 'USER_STATUS_CHANGE',
    USER_STATUS_CHANGE_FAILURE: 'USER_STATUS_CHANGE_FAILURE',
    USER_STATUS_CHANGE_SUCCESS: 'USER_STATUS_CHANGE_SUCCESS',
};

export const openUserAuthoritySettingModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_AUTHORITY_SETTING_MODAL_OPEN,
    });

export const closeUserAuthoritySettingModal = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_AUTHORITY_SETTING_MODAL_CLOSE,
    });

export const userAuhtorityListGet = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_AUTHORITY_SETTING_LIST_GET,
    });

export const userAuhtorityListGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_AUTHORITY_SETTING_LIST_GET_SUCCESS,
    });

export const userAuthorityListGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_AUTHORITY_SETTING_LIST_GET_FAILURE,
    });

//#region
export const userCreate = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_CREATE,
    });

export const userCreateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_CREATE_FAILURE,
    });

export const userCreateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_CREATE_SUCCESS,
    });
//#endregion

//#region
export const userPasswordChange = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_PASSWORD_CHANGE,
    });

export const userPasswordChangeFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_PASSWORD_CHANGE_FAILURE,
    });

export const userPasswordChangeSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_PASSWORD_CHANGE_SUCCESS,
    });
//#endregion

//#region
export const userStatusChange = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_STATUS_CHANGE,
    });

export const userStatusChangeFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_STATUS_CHANGE_FAILURE,
    });

export const userStatusChangeSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_STATUS_CHANGE_SUCCESS,
    });
//#endregion

//#region
export const userGroupGet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_GROUP_GET,
    });

export const userGroupGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_GROUP_GET_FAILURE,
    });

export const userGroupGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_GROUP_GET_SUCCESS,
    });
//#endregion

export const userAuthoritySettingInfoSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_AUTHORITY_SETTING_INFO_SET,
    });

//#region
export const userAuthoritySet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_AUTHORITY_SET,
    });

export const userAuthoritySetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_AUTHORITY_SET_FAILURE,
    });

export const userAuthoritySetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_AUTHORITY_SET_SUCCESS,
    });
//#endregion

//#region
export const userAuthorityDetailGet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_AUTHORITY_DETAIL_GET,
    });

export const userAuthorityDetailGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_AUTHORITY_DETAIL_GET_FAILURE,
    });

export const userAuthorityDetailGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_AUTHORITY_DETAIL_GET_SUCCESS,
    });
//#endregion

//#region
export const userIndividualAuthorityDetailGet = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_INDIVIDUAL_AUTHORITY_GET,
    });

export const userIndividualAuthorityDetailGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_INDIVIDUAL_AUTHORITY_GET_FAILURE,
    });

export const userIndividualAuthorityDetailGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_INDIVIDUAL_AUTHORITY_GET_SUCCESS,
    });
//#endregion

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const userIndividualAuthoritySetting = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_INDIVIDUAL_AUTHORITY_SETTING_INFO_SET,
    });

//#region
export const userIndividualAuthoritySet = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_INDIVIDUAL_AUTHORITY_SET,
    });

export const userIndividualAuthoritySetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_INDIVIDUAL_AUTHORITY_SET_FAILURE,
    });

export const userIndividualAuthoritySetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_INDIVIDUAL_AUTHORITY_SET_SUCCESS,
    });
//#endregion

//#region
export const userCurrencyChange = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_CURRENCY_CHANGE,
    });

export const userCurrencyChangeFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_CURRENCY_CHANGE_FAILURE,
    });

export const userCurrencyChangeSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_CURRENCY_CHANGE_SUCCESS,
    });
//#endregion
