import React from 'react';

import { Card, Col, Input, Row } from 'antd';

import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useStore } from '../../../../store/StateProvider';
import PlayerWinLossDetailTable from './table/PlayerWinLossDetailTable';

const PlayerWinLossModalContent = ({ loading }) => {
    const [state] = useStore();
    const summary = getStateProp(state, 'reports.playerWinLoss.winLossDetail.summary', {});

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Customer Bet List')}>
            <PlayerWinLossDetailTable loading={loading} />

            <Row align="middle" justify="end" className="bet-list-summary">
                <Col span={3}>
                    <span className="summary-label">{getTranslation('Total Hold Stake')}</span>
                </Col>
                <Col span={5}>
                    <Input disabled className="input-summary" value={summary.totalHoldStake} />
                </Col>
                <Col span={3}>
                    <span className="summary-label">{getTranslation('Total Turnover')}</span>
                </Col>
                <Col span={5}>
                    <Input disabled className="input-summary" value={summary.totalTurnover} />
                </Col>
                <Col span={3}>
                    <span className="summary-label">{getTranslation('Total Paid Stake')}</span>
                </Col>
                <Col span={5}>
                    <Input disabled className="input-summary" value={summary.totalPaidStake} />
                </Col>
            </Row>
            <Row align="middle" justify="end" className="bet-list-summary">
                <Col span={3}>
                    <span className="summary-label">{getTranslation('Total Stake')}</span>
                </Col>
                <Col span={5}>
                    <Input disabled className="input-summary" value={summary.totalStake} />
                </Col>
                <Col span={3}>
                    <span className="summary-label">
                        {getTranslation('Total Effective Turnover')}
                    </span>
                </Col>
                <Col span={5}>
                    <Input
                        disabled
                        className="input-summary"
                        value={summary.totalEffectiveTurnover}
                    />
                </Col>
                <Col span={3}>
                    <span className="summary-label">{getTranslation('WinLoss')}</span>
                </Col>
                <Col span={5}>
                    <Input disabled className="input-summary" value={summary.totalWinLoss} />
                </Col>
            </Row>
        </Card>
    );
};

export default PlayerWinLossModalContent;
