import { ACTIONS as ACTIVE_BANK_ACCOUNT } from '../../components/payments/bank-account-setup/active-bank-account/activeBankAccountActions';
import { ACTIONS as ACTIVE_EWALLET } from '../../components/payments/e-wallet-setup/active-e-wallet/activeEwalletActions';
import { ACTIONS as ACTIVE_MERCHANT } from '../../components/payments/merchant-setup/active-merchant/activeMerchantActions';
import { ACTIONS as AFFILIATE_RECORDS } from '../../components/transaction-history/affiliate-records/affiliateRecordsActions';
import { ACTIONS as ANNOUNCEMENT_CREATE } from '../../components/announcement/announcement-create/announcementCreateActions';
import { ACTIONS as ANNOUNCEMENT_HISTORY } from '../../components/announcement/announcement-history/global-announcement/globalAnnouncementActions';
import { ACTIONS as ANNOUNCEMENT_VERIFY } from '../../components/announcement/announcement-verification/global-announcement/globalAnnouncementActions';
import { ACTIONS as BALANCE_MANAGEMENT } from '../../components/balance/balance-management/balanceManagementActions';
import { ACTIONS as BANK_ACCOUNT_CONFIGURATION } from '../../components/payments/bank-account-configuration/bankAccountConfigurationActions';
import { ACTIONS as BANK_ACCOUNT_STATEMENT } from '../../components/payments/bank-account-statement/bankAccountStatementActions';
import { ACTIONS as BANK_CARD } from '../../components/payments/bank-account-setup/bankActions';
import { ACTIONS as BANNER_CREATE } from '../../components/bonus/create-banner/createBannerActions';
import { ACTIONS as BANNER_MANAGEMENT } from '../../components/bonus/banner-management/bannerManagementActions';
import { ACTIONS as BANNER_SORTING } from '../../components/bonus/banner-sorting/bannerSortingActions';
import { ACTIONS as BET_LIST } from '../../components/bet/bet-list/betListActions';
import { ACTIONS as BONUS_CATEGORY } from '../../components/bonus/bonus-category/bonusCategoryActions';
import { ACTIONS as BONUS_CATEGORY_COMMON } from '../../components/bonus/shared/categoryActions';
import { ACTIONS as BONUS_CREATE } from '../../components/bonus/create-bonus/createBonusActions';
import { ACTIONS as BONUS_MANAGEMENT } from '../../components/bonus/bonus-management/bonusManagementActions';
import { ACTIONS as BONUS_RECORDS } from '../../components/transaction-history/bonus-records/bonusRecordsActions';
import { ACTIONS as BONUS_REQUEST } from '../../components/bonus/request-bonus/requestBonusActions';
import { ACTIONS as CHANGE_PASSWORD } from '../../components/profile/changePasswordActions';
import { ACTIONS as CHANNELS } from '../../components/players/channel-inquiry/channelsActions';
import { ACTIONS as CREATE_REFERRAL } from '../../components/referral/create-referral/createReferralActions';
import { ACTIONS as CRYPTO_CORRECTION } from '../../components/balance/cryptocurrency-correction/cryptocurrencyCorrectionActions';
import { ACTIONS as CRYPTO_DEPOSIT } from '../../components/payments/cryptocurrency/deposit/cryptoDepositActions';
import { ACTIONS as CRYPTO_DEPOSIT_RECORDS } from '../../components/transaction-history/cryptocurrency-records/deposits/cryptoDepositRecordActions';
import { ACTIONS as CRYPTO_MERCHANT_CONFIGURATION } from '../../components/payments/crypto-merchant-configuration/cryptoMerchantConfigurationActions';
import { ACTIONS as CRYPTO_WITHDRAWAL } from '../../components/payments/cryptocurrency/withdrawal/cryptoWithdrawalActions';
import { ACTIONS as CRYPTO_WITHDRAWAL_RECORDS } from '../../components/transaction-history/cryptocurrency-records/withdrawals/cryptoWithdrawalRecordActions';
import { ACTIONS as CURRENCY_SETTING } from '../../components/admin/currency-setting/currencySettingActions';
import { ACTIONS as DAILY_LOGIN_MANAGEMENT } from '../../components/rewards-management/daily-login-management/dailyLoginManagementActions';
import { ACTIONS as DAILY_LOGIN_SETUP } from '../../components/rewards-management/daily-login-setup/dailyLoginSetupActions';
import { ACTIONS as DEACTIVATED_EWALLET } from '../../components/payments/e-wallet-setup/deactivated-e-wallet/DeactivatedEwalletActions';
import { ACTIONS as DEACTIVATE_BANK_ACCOUNT } from '../../components/payments/bank-account-setup/deactivated-bank-account/deactivatedBankAccountActions';
import { ACTIONS as DEACTIVATE_MERCHANT } from '../../components/payments/merchant-setup/deactivated-merchant/deactivatedMerchantActions';
import { ACTIONS as DEPOSITS } from '../../components/payments/deposits/depositsActions';
import { ACTIONS as DEPOSIT_CORRECTION } from '../../components/balance/deposit-correction/depositCorrectionActions';
import { ACTIONS as DEPOSIT_RECORDS } from '../../components/transaction-history/deposit-records/depositRecordActions';
import { ACTIONS as ERROR } from '../../common/actions/errorActions';
import { ACTIONS as EWALLET } from '../../components/payments/e-wallet-setup/ewalletActions';
import { ACTIONS as EWALLET_CONFIGURATION } from '../../components/payments/e-wallet-configuration/ewalletConfigurationActions';
import { ACTIONS as EWALLET_CORRECTION } from '../../components/balance/ewallet-correction/ewalletCorrectionActions';
import { ACTIONS as EXPORT } from '../actions/exportActions';
import { ACTIONS as GENERAL_ACTIONS } from '../actions/generalActions';
import { ACTIONS as GENERAL_PUSH_NOTIFICAION } from '../../components/push-notification/create-push-notification/general/generalCreatePushNotificationActions';
import { ACTIONS as GROUP_AUTHORITY_SETTING } from '../../components/admin/group-setting/groupAuthoritySettingActions';
import { ACTIONS as HOT_EVENTS } from '../../components/hot-events/hotEventsAction';
import { ACTIONS as MANUAL_BONUS_MANAGEMENT } from '../../components/bonus/manual-management/manualManagementActions';
import { ACTIONS as MENUS } from '../../components/admin/menuActions';
import { ACTIONS as MERCHANT_CONFIGURATION } from '../../components/payments/merchant-configuration/merchantConfigurationActions';
import { ACTIONS as MERCHANT_DEPOSITS } from '../../components/payments/merchants/merchantActions';
import { ACTIONS as MESSAGE_CREATE } from '../../components/announcement/message-create/messageCreateActions';
import { ACTIONS as MESSAGE_HISTORY } from '../../components/announcement/announcement-history/personal-message/personalMessageActions';
import { ACTIONS as MESSAGE_VERIFY } from '../../components/announcement/announcement-verification/personal-message/personalMessageActions';
import { ACTIONS as MODIFICATIONS_HISTORY } from '../../components/players/modifications-history/modificationsHistoryActions';
import { ACTIONS as NEWS } from '../../components/news/newsActions';
import { ACTIONS as PENDING_MODIFICATIONS } from '../../components/players/pending-modifications/pendingModificationsActions';
import { ACTIONS as PLAYERS_LIST } from '../../components/players/players-list/PlayersListActions';
import { ACTIONS as PLAYER_DETAILS } from '../../components/players/player-details/playerDetailsActions';
import { ACTIONS as PLAYER_WALLET_BALANCE_REPORT } from '../../components/reports/player-wallet-balance/playerWalletBalanceActions';
import { ACTIONS as PLAYER_WIN_LOSS_REPORT } from '../../components/reports/player-win-loss/playerWinLossActions';
import { ACTIONS as PUSH_NOTIFICATION_MANAGEMENT } from '../../components/push-notification/push-notification-management/pushNotificationManagementAction';
import { ACTIONS as REBATE_GENERATOR } from '../../components/rebate/generator/rebateGeneratorActions';
import { ACTIONS as REBATE_MANAGEMENT } from '../../components/rebate/management/rebateManagementActions';
import { ACTIONS as REBATE_RECORDS } from '../../components/transaction-history/rebate-records/rebateRecordsActions';
import { ACTIONS as REBATE_SETTING } from '../../components/rebate/setting/rebateSettingActions';
import { ACTIONS as REFERRAL_MANAGEMENT } from '../../components/referral/referral-management/referralManagementAction';
import { ACTIONS as REGISTRATION_DETAILS } from '../../components/players/registration-details/registrationDetailsActions';
import { ACTIONS as TARGET_PUSH_NOTIFICAION } from '../../components/push-notification/create-push-notification/target/targetCreatePushNotificationActions';
import { ACTIONS as TRANSACTION_AMOUNT_SETTING } from '../../components/payments/transaction-amount-setting/TransactionAmountSettingActions';
import { ACTIONS as TRANSACTION_MONITORING } from '../../components/transaction-monitoring/transactionMonitoringActions';
import { ACTIONS as TRANSACTION_RECORDS } from '../../components/transaction-history/transaction-records/transactionRecordActions';
import { ACTIONS as TURNOVER_CHECK } from '../../components/bet/turnover-check/turnoverCheckActions';
import { ACTIONS as TURNOVER_RECORDS } from '../../components/transaction-history/turnover-records/turnoverRecordsActions';
import { ACTIONS as USER } from '../actions/userActions';
import { ACTIONS as USER_AUTHORITY } from '../../components/admin/user-setting/userAuthoritySettingActions';
import { ACTIONS as USER_SESSIONS } from '../../components/players/user-sessions/userSessionsActions';
import { ACTIONS as VIP_SETUP } from '../../components/vip/vip-setup/vipSetupActions';
import { ACTIONS as WALLET_TRANSFERS } from '../../components/transaction-history/wallet-transfers/walletActions';
import { ACTIONS as WITHDRAWALS } from '../../components/payments/withdrawals/withdrawalsActions';
import { ACTIONS as WITHDRAWAL_RECORDS } from '../../components/transaction-history/withdrawal-records/withdrawalRecordsActions';

export default function errorsReducer(error, { type, payload = null }) {
    switch (type) {
        case BANNER_CREATE.BANNER_CREATE_FAILURE:
        case BANNER_MANAGEMENT.BANNER_MANAGEMENT_BY_ID_GET_FAILURE:
        case BANNER_MANAGEMENT.BANNER_MANAGEMENT_LIST_GET_FAILURE:
        case BANNER_MANAGEMENT.BANNER_MANAGEMENT_STATUS_SET_FAILURE:
        case BANNER_SORTING.AVAILABLE_BANNERS_GET_FAILURE:
        case BANNER_SORTING.ACTIVE_BANNERS_GET_FAILURE:
        case BANNER_SORTING.ACTIVE_BANNERS_UPDATE_FAILURE:
        case EXPORT.EXPORT_TABLE_FAILURE:
        case USER.LOGIN_FAILURE:
        case USER.GET_PERMISSION_FAILURE:
        case CHANNELS.CHANNELS_GET_FAILURE:
        case DEPOSITS.DEPOSITS_GET_FAILURE:
        case DEPOSITS.OPERATION_FAILURE:
        case PLAYER_DETAILS.ADD_E_WALLET_FAILURE:
        case PLAYER_DETAILS.DELETE_E_WALLET_FAILURE:
        case PLAYER_DETAILS.ADD_BANK_CARD_FAILURE:
        case PLAYER_DETAILS.DELETE_BANK_CARD_FAILURE:
        case PLAYER_DETAILS.PLAYER_GET_BY_ID_FAILURE:
        case PLAYER_DETAILS.UPDATE_PLAYER_INFO_FAILURE:
        case PLAYER_DETAILS.USER_SESSIONS.GET_BY_USER_ID_FAILURE:
        case PLAYER_DETAILS.USER_SESSIONS.GET_FAILURE:
        case PLAYER_DETAILS.WITHDRAWAL_REQUEST_FAILURE:
        case PLAYERS_LIST.AFFILIATE_UPLINE_GET_FAILURE:
        case PLAYERS_LIST.CHANGE_PASSWORD_FAILURE:
        case PLAYERS_LIST.CREATE_MODIFICATION_FAILURE:
        case PLAYERS_LIST.CREATE_PLAYER_FAILURE:
        case PLAYERS_LIST.CREATE_REMARK_FAILURE:
        case PLAYERS_LIST.CREATE_PROHIBITION_FAILURE:
        case PLAYERS_LIST.PLAYERS_GET_FAILURE:
        case PLAYERS_LIST.PROHIBITIONS_GET_FAILURE:
        case PLAYERS_LIST.REMARKS_GET_FAILURE:
        case PLAYERS_LIST.STATUS_CHANGE_FAILURE:
        case PLAYERS_LIST.STATUS_HISTORY_GET_FAILURE:
        case PLAYERS_LIST.WALLET_BALANCE_GET_FAILURE:
        case PLAYERS_LIST.WALLET_TRANSFER_FAILURE:
        case REGISTRATION_DETAILS.REGISTRATION_DETAILS_GET_FAILURE:
        case MERCHANT_DEPOSITS.DEPOSITS_GET_FAILURE:
        case MERCHANT_DEPOSITS.OPERATION_FAILURE:
        case MODIFICATIONS_HISTORY.MODIFICATIONS_GET_FAILURE:
        case PENDING_MODIFICATIONS.MODIFICATIONS_GET_FAILURE:
        case PENDING_MODIFICATIONS.VERIFY_REQUEST_FAILURE:
        case USER_SESSIONS.USER_SESSIONS_GET_FAILURE:
        case WITHDRAWALS.WITHDRAWALS_GET_FAILURE:
        case WITHDRAWALS.OPERATION_FAILURE:
        case WITHDRAWALS.WITHDRAWALS_GET_BANK_CARDS_FAILURE:
        case DEPOSIT_RECORDS.DEPOSIT_RECORDS_GET_FAILURE:
        case TRANSACTION_RECORDS.TRANSACTION_RECORDS_GET_FAILURE:
        case WALLET_TRANSFERS.WALLET_TRANSFERS_GET_FAILURE:
        case WITHDRAWAL_RECORDS.OPERATION_FAILURE:
        case WITHDRAWAL_RECORDS.WITHDRAWAL_RECORDS_GET_FAILURE:
        case TRANSACTION_MONITORING.OPERATION_FAILURE:
        case TRANSACTION_MONITORING.TRANSACTION_MONITORING_GET_FAILURE:
        case BALANCE_MANAGEMENT.OPERATION_FAILURE:
        case BALANCE_MANAGEMENT.PLAYER_DETAILS_GET_FAILURE:
        case DEPOSIT_CORRECTION.BANK_CARD_DETAILS_GET_FAILURE:
        case DEPOSIT_CORRECTION.OPERATION_FAILURE:
        case DEPOSIT_CORRECTION.PLAYER_DETAILS_GET_FAILURE:
        case DEPOSIT_CORRECTION.TRANSACTION_GET_FAILURE:
        case BET_LIST.BET_LIST_GET_FAILURE:
        case BET_LIST.PLAYER_SINGLE_DETAIL_URL_GET_FAILURE:
        case TURNOVER_CHECK.TURNOVER_CHECK_GET_FAILURE:
        case NEWS.NEWS_CREATE_FAILURE:
        case NEWS.NEWS_DELETE_FAILURE:
        case NEWS.NEWS_GET_FAILURE:
        case NEWS.NEWS_UPDATE_FAILURE:
        case CRYPTO_WITHDRAWAL.CRYPTO_WITHDRAWAL_OPERATION_FAILURE:
        case CHANGE_PASSWORD.CHANGE_PASSWORD_OPERATION_FAILURE:
        case CRYPTO_WITHDRAWAL.CRYPTO_WITHDRAWALS_GET_FAILURE:
        case ACTIVE_BANK_ACCOUNT.BANK_CARD_GET_FAILURE:
        case ACTIVE_BANK_ACCOUNT.BANK_CARD_SETUP_FORM_SUBMIT_FAILURE:
        case ACTIVE_BANK_ACCOUNT.BANK_CARD_UPDATE_FORM_SUBMIT_FAILURE:
        case DEACTIVATE_BANK_ACCOUNT.BANK_CARD_GET_FAILURE:
        case DEACTIVATE_BANK_ACCOUNT.BANK_CARD_UPDATE_FORM_SUBMIT_FAILURE:
        case BANK_CARD.BANK_GET_FAILURE:
        case BANK_ACCOUNT_CONFIGURATION.ACCOUNT_CONFIGURATION_FORM_SUBMIT_FAILURE:
        case BANK_ACCOUNT_CONFIGURATION.ACCOUNT_CONFIGURATION_GET_FAILURE:
        case ACTIVE_MERCHANT.MERCHANT_GET_FAILURE:
        case ACTIVE_MERCHANT.MERCHANT_UPDATE_FAILURE:
        case ACTIVE_MERCHANT.MERCHANT_SETUP_FAILURE:
        case DEACTIVATE_MERCHANT.MERCHANT_GET_FAILURE:
        case DEACTIVATE_MERCHANT.MERCHANT_UPDATE_FAILURE:
        case MERCHANT_CONFIGURATION.MERCHANT_CONFIGURATION_FORM_SUBMIT_FAILURE:
        case MERCHANT_CONFIGURATION.MERCHANT_CONFIGURATION_GET_FAILURE:
        case MERCHANT_CONFIGURATION.MERCHANT_CONFIGURATION_PAY_SERVICE_UPDATE_FAILURE:
        case CRYPTO_MERCHANT_CONFIGURATION.CRYPTO_MERCHANT_CONFIGURATION_UPDATE_FAILURE:
        case CRYPTO_MERCHANT_CONFIGURATION.CRYPTO_MERCHANT_CONFIGURATION_RECORD_GET_FAILURE:
        case CRYPTO_DEPOSIT.CRYPTO_DEPOSITS_GET_FAILURE:
        case CRYPTO_DEPOSIT.CRYPTO_DEPOSIT_OPERATION_FAILURE:
        case BANK_ACCOUNT_STATEMENT.BANK_ACCOUNT_STATEMENT_GET_FAILURE:
        case CRYPTO_DEPOSIT_RECORDS.DEPOSIT_RECORDS_GET_FAILURE:
        case CRYPTO_WITHDRAWAL_RECORDS.WITHDRAWAL_RECORDS_GET_FAILURE:
        case CRYPTO_WITHDRAWAL_RECORDS.WITHDRAWAL_RECORDS_OPERATION_FAILURE:
        case BONUS_RECORDS.BONUS_RECORDS_GET_FAILURE:
        case CRYPTO_CORRECTION.CRYPTO_CURRENCY_CORRECTION_NEW_RATE_GET_FAILURE:
        case CRYPTO_CORRECTION.CRYPTO_CURRENCY_CORRECTION_OPERATION_FAILURE:
        case CRYPTO_CORRECTION.CRYPTO_CURRENCY_CORRECTION_TRANSACTION_GET_FAILURE:
        case CRYPTO_CORRECTION.CRYPTO_CURRENCY_CORRECTION_USERNAME_GET_FAILURE:
        case ANNOUNCEMENT_CREATE.ANNOUNCEMENT_ADD_FAILURE:
        case MESSAGE_CREATE.MESSAGE_ADD_FAILURE:
        case MESSAGE_CREATE.MESSAGE_VERIFY_USERNAMES_FAILURE:
        case ANNOUNCEMENT_VERIFY.ANNOUNCEMENT_GET_FAILURE:
        case ANNOUNCEMENT_VERIFY.ANNOUNCEMENT_UPDATE_FAILURE:
        case MESSAGE_VERIFY.MESSAGE_GET_FAILURE:
        case MESSAGE_VERIFY.MESSAGE_UPDATE_FAILURE:
        case ANNOUNCEMENT_HISTORY.ANNOUNCEMENT_GET_FAILURE:
        case MESSAGE_HISTORY.MESSAGE_GET_FAILURE:
        case CURRENCY_SETTING.CONVERSION_RATE_SET_FAILURE:
        case CURRENCY_SETTING.CURRENCY_HISTORY_RECORDS_GET_FAILURE:
        case CURRENCY_SETTING.CURRENCY_RECORDS_GET_FAILURE:
        case BONUS_CREATE.BONUS_CREATE_FAILURE:
        case BONUS_MANAGEMENT.BONUS_MANAGEMENT_BY_ID_GET_FAILURE:
        case BONUS_MANAGEMENT.BONUS_MANAGEMENT_LIST_GET_FAILURE:
        case BONUS_MANAGEMENT.BONUS_MANAGEMENT_STATUS_SET_FAILURE:
        case BONUS_MANAGEMENT.BONUS_MANAGEMENT_UPDATE_FAILURE:
        case BONUS_REQUEST.REQUEST_BONUS_FAILURE:
        case BONUS_REQUEST.GET_BONUS_CODES_FAILURE:
        case BONUS_REQUEST.REQUEST_BONUS_SEARCH_PLAYER_INFO_FAILURE:
        case BONUS_REQUEST.REQUEST_BONUS_TURNOVER_FAILURE:
        case BONUS_REQUEST.UPLOAD_EXCEL_FAILURE:
        case MANUAL_BONUS_MANAGEMENT.BONUS_MANUAL_MANAGEMENT_RECORDS_GET_FAILURE:
        case MANUAL_BONUS_MANAGEMENT.BONUS_MANUAL_MANAGEMENT_UPDATE_INFO_FAILURE:
        case MANUAL_BONUS_MANAGEMENT.BONUS_MANUAL_MANAGEMENT_VERIFY_SEND_FAILURE:
        case MANUAL_BONUS_MANAGEMENT.DEPOSIT_AMOUNT_GET_FAILURE:
        case MANUAL_BONUS_MANAGEMENT.TURNOVER_AMOUNT_CALCULATE_FAILURE:
        case MANUAL_BONUS_MANAGEMENT.BONUS_MANUAL_MANAGEMENT_BATCH_SUBMIT_FAILURE:
        case MANUAL_BONUS_MANAGEMENT.BONUS_MANUAL_MANAGEMENT_BATCH_UPDATE_FAILURE:
        case MANUAL_BONUS_MANAGEMENT.BONUS_MANUAL_MANAGEMENT_BATCH_REJECT_FAILURE:
        case USER_AUTHORITY.USER_AUTHORITY_SETTING_LIST_GET_FAILURE:
        case GROUP_AUTHORITY_SETTING.GROUP_ACCESS_LIST_GET_FAILURE:
        case GROUP_AUTHORITY_SETTING.GROUP_ACCESS_RECORD_CREATE_FAILURE:
        case GROUP_AUTHORITY_SETTING.GROUP_ACCESS_RECORD_GET_FAILURE:
        case GROUP_AUTHORITY_SETTING.GROUP_ACCESS_RECORD_UPDATE_FAILURE:
        case GROUP_AUTHORITY_SETTING.GROUP_ACCESS_RECORD_DELETE_FAILURE:
        case AFFILIATE_RECORDS.AFFILIATE_RECORDS_GET_FAILURE:
        case CREATE_REFERRAL.CREATE_REFERRAL_PROGRAM_FAILURE:
        case REFERRAL_MANAGEMENT.REFERRAL_MANAGEMENT_GET_FAILURE:
        case MENUS.MENU_LIST_GET_FAILURE:
        case BONUS_CATEGORY_COMMON.CATEGORY_LIST_GET_FAILURE:
        case BONUS_CATEGORY.BONUS_CATEGORY_ACTIVE_LIST_GET_FAILURE:
        case BONUS_CATEGORY.BONUS_CATEGORY_CREATE_FAILURE:
        case BONUS_CATEGORY.BONUS_CATEGORY_GET_FAILURE:
        case BONUS_CATEGORY.BONUS_CATEGORY_SORT_FAILURE:
        case BONUS_CATEGORY.BONUS_CATEGORY_UPDATE_FAILURE:
        case DAILY_LOGIN_SETUP.DAILY_LOGIN_SETUP_FAILURE:
        case DAILY_LOGIN_MANAGEMENT.DAILY_LOGIN_GET_FAILURE:
        case DAILY_LOGIN_MANAGEMENT.DAILY_LOGIN_GET_BY_ID_FAILURE:
        case DAILY_LOGIN_MANAGEMENT.DAILY_LOGIN_UPDATE_FAILURE:
        case DAILY_LOGIN_MANAGEMENT.DAILY_LOGIN_UPDATE_STATUS_FAILURE:
        case PLAYERS_LIST.PLAYER_MOBILE_VERIFY_FAILURE:
        case REBATE_SETTING.REBATE_CREATE_FAILURE:
        case REBATE_MANAGEMENT.REBATE_MANAGEMENT_STATUS_UPDATE_FAILURE:
        case REBATE_MANAGEMENT.REBATE_MANAGEMENT_UPDATE_FAILURE:
        case REBATE_MANAGEMENT.REBATE_MANAGEMENT_GET_BY_ID_FAILURE:
        case REBATE_MANAGEMENT.REBATE_MANAGEMENT_GET_FAILURE:
        case REBATE_GENERATOR.REBATE_GENERATOR_GET_FAILURE:
        case REBATE_GENERATOR.REBATE_GENERATOR_STATUS_SET_FAILURE:
        case PLAYERS_LIST.GAME_PLATFORM_GET_FAILURE:
        case PLAYERS_LIST.PLAYERS_GAME_ACCESS_UPDATE_FAILURE:
        case PLAYERS_LIST.PLAYER_GAME_ACCESS_GET_FAILURE:
        case GENERAL_ACTIONS.BATCH_PLAYER_SEARCH_FAILURE:
        case BET_LIST.EXPORT_BET_LIST_FAILURE:
        case VIP_SETUP.VIP_SETUP_GET_FAILURE:
        case VIP_SETUP.VIP_SETUP_UPDATE_FAILURE:
        case GENERAL_PUSH_NOTIFICAION.CREATE_GENERAL_PUSH_NOTIFICATION_FAILURE:
        case TARGET_PUSH_NOTIFICAION.CREATE_TARGET_PUSH_NOTIFICATION_FAILURE:
        case PUSH_NOTIFICATION_MANAGEMENT.GET_NOTIFICATION_INFO_BY_ID_FAILURE:
        case PUSH_NOTIFICATION_MANAGEMENT.GET_NOTIFICATION_INFO_FAILURE:
        case PUSH_NOTIFICATION_MANAGEMENT.SEND_PUSH_NOTIFICATION_INFO_FAILURE:
        case PUSH_NOTIFICATION_MANAGEMENT.UPSERT_PUSH_NOTIFICATION_INFO_FAILURE:
        case PLAYER_WALLET_BALANCE_REPORT.PLAYER_WALLET_BALANCE_GET_FAILURE:
        case PLAYER_WIN_LOSS_REPORT.FILTER_OPTION_GET_FAILURE:
        case PLAYER_WIN_LOSS_REPORT.PLAYER_WIN_LOSS_DETAILS_GET_FAILURE:
        case REBATE_RECORDS.REBATE_RECORDS_GET_FAILURE:
        case TRANSACTION_AMOUNT_SETTING.TRANSACTION_AMOUNT_SETTING_UPDATE_FAILURE:
        case TRANSACTION_AMOUNT_SETTING.TRANSACTION_AMOUNT_SETTING_GET_FAILURE:
        case HOT_EVENTS.HOT_EVENTS_CHATROOM_STATUS_UPDATE_FAILURE:
        case HOT_EVENTS.HOT_EVENTS_LIST_GET_FAILURE:
        case HOT_EVENTS.OPERATION_FAILURE:
        case ACTIVE_EWALLET.ACTIVE_EWALLET_GET_FAILURE:
        case ACTIVE_EWALLET.EWALLET_SETUP_FORM_SUBMIT_FAILURE:
        case DEACTIVATED_EWALLET.DEACTIVATED_EWALLET_GET_FAILURE:
        case DEACTIVATED_EWALLET.DEACTIVATED_EWALLET_UPDATE_FORM_SUBMIT_FAILURE:
        case EWALLET.EWALLET_GET_FAILURE:
        case EWALLET_CONFIGURATION.EWALLET_CONFIGURATION_FORM_SUBMIT_FAILURE:
        case EWALLET_CONFIGURATION.EWALLET_CONFIGURATION_GET_FAILURE:
        case EWALLET_CORRECTION.EWALLET_DETAILS_GET_FAILURE:
        case EWALLET_CORRECTION.PLAYER_DETAILS_GET_FAILURE:
        case EWALLET_CORRECTION.OPERATION_FAILURE:
        case EWALLET_CORRECTION.TRANSACTION_GET_FAILURE:
        case TURNOVER_RECORDS.TURNOVER_RECORDS_GET_FAILURE:
            return {
                ...error,
                type,
                ...payload,
            };
        case ERROR.CLEAR_ERROR:
            return {};
        default:
            return error;
    }
}
