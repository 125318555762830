import React from 'react';

import { ButtonGray, ButtonGreen } from '../../../../../common/components/buttons/Buttons';
import {
    PAYMENT_ORDER_OPERATIONS,
    TRANSACTION_STATUSES,
    WITHDRAWAL_ORDER_STATUSES,
} from '../../../../../common/constants/misc';
import {
    cryptoWithdrawalRecordOperation,
    onVerifyModalOpen,
} from '../cryptoWithdrawalRecordActions';
import { getCurrentUser } from '../../../../../common/helpers/api';
import { getStateProp } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import { useStore } from '../../../../../store/StateProvider';

const VerifyButton = ({ transaction, dispatch }) => {
    const [state] = useStore();
    const {
        attendantRealName,
        attendantUserName,
        auditorRealName,
        verifierRealName,
        orderStatus,
        transactionStatus,
    } = transaction;

    const transactionProcessed = [
        TRANSACTION_STATUSES.REJECTED,
        TRANSACTION_STATUSES.SUCCESS,
    ].includes(transactionStatus);

    const withdrawalProcessed = [
        WITHDRAWAL_ORDER_STATUSES.REJECTED,
        WITHDRAWAL_ORDER_STATUSES.SUCCESS,
        WITHDRAWAL_ORDER_STATUSES.PROCESSING,
    ].includes(orderStatus);

    const operatorName = getStateProp(
        state,
        'user.userName',
        getStateProp(getCurrentUser(), 'claims.userName', 'Anonymus'), //Temp fix
    );

    // ============================================
    // METHODS
    // ============================================
    const getLabel = () => {
        switch (transactionStatus) {
            case TRANSACTION_STATUSES.ATTENDING:
                return `${getTranslation('Reviewing')} ${attendantRealName}`;
            case TRANSACTION_STATUSES.REJECTED:
            case TRANSACTION_STATUSES.SUCCESS:
                return `${getTranslation('Verified')} ${verifierRealName}`;
            default:
                getTranslation('Verify');
        }
    };

    const openVerifyModal = () => {
        cryptoWithdrawalRecordOperation(dispatch, {
            action: PAYMENT_ORDER_OPERATIONS.ATTEND,
            transaction,
        });
        onVerifyModalOpen(dispatch, transaction);
    };

    return (attendantUserName !== operatorName &&
        transactionStatus === TRANSACTION_STATUSES.ATTENDING) ||
        transactionProcessed ||
        withdrawalProcessed ? (
        <ButtonGray disabled label={getLabel()} className="action-btn__verify" />
    ) : auditorRealName ? (
        ''
    ) : (
        <ButtonGreen
            label={getTranslation('Verify')}
            className="action-btn__verify"
            onClick={openVerifyModal}
        />
    );
};

export default VerifyButton;
