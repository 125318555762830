import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

import { API } from '../common/constants/api';
import { clearError } from '../common/actions/errorActions';
import {
    filterOptionGet,
    filterOptionGetFailure,
    filterOptionGetSuccess,
    playerWinLossDetailsGet,
    playerWinLossDetailsGetFailure,
    playerWinLossDetailsGetSuccess,
    playerWinLossExport,
    playerWinLossExportFailure,
    playerWinLossExportSuccess,
    playerWinLossReportGet,
    playerWinLossReportGetFailure,
    playerWinLossReportGetSuccess,
} from '../components/reports/player-win-loss/playerWinLossActions';
import { filterTrim, getStateProp } from '../common/helpers/misc';
import {
    overviewGet,
    overviewGetFailure,
    overviewGetSuccess,
} from '../components/reports/overview/overviewActions';
import {
    playerWalletBalanceGet,
    playerWalletBalanceGetFailure,
    playerWalletBalanceGetSuccess,
} from '../components/reports/player-wallet-balance/playerWalletBalanceActions';
import { useDispatch, useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import exportWarningModal from '../common/components/exportWarningModal';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, REPORTS, BET } = API;

export function useGetOverviewReportAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const overview = getStateProp(state, 'reports.overview');
    const { from, to, currency, ...restFilters } = getStateProp(overview, 'filters', {});

    // ============================================
    // METHODS
    // ============================================
    const handleGetOverviewReport = useCallback(() => {
        overviewGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => (!!from || !!to) && !!currency,
        method: 'POST',
        options: {
            data: {
                currency,
                from,
                to,
                ...restFilters,
            },
        },
        trigger,
        url: `${VERSION.V1}${REPORTS.OVERVIEW}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            overviewGetSuccess(dispatch, {
                baseCurrency: response.data.data.baseCurrency,
                exchangeRates: response.data.data.exchangeRates,
                fetchData: handleGetOverviewReport,
                list: response.data.data.data,
            });
        }
    }, [dispatch, handleGetOverviewReport, response]);

    const errorMsg = useError(error, overviewGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetOverviewReport];
}

export function useGetPlayerWalletBalanceAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const playerWalletBalance = getStateProp(state, 'reports.playerWalletBalance');
    const filters = getStateProp(playerWalletBalance, 'filters', {});
    const { pageNo, pageSize } = getStateProp(playerWalletBalance, 'paging');

    // ============================================
    // METHODS
    // ============================================
    const handleGetPlayerWalletBalance = useCallback(() => {
        playerWalletBalanceGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!filters,
        method: 'POST',
        options: {
            data: {
                ...filterTrim(filters),
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${REPORTS.PLAYER_WALLET_BALANCE}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            playerWalletBalanceGetSuccess(dispatch, {
                fetchData: handleGetPlayerWalletBalance,
                grandTotal: response.data.data[0].grandTotal,
                list: response.data.data[0].data,
                paging: response.data.paging,
            });
        }
    }, [dispatch, handleGetPlayerWalletBalance, response]);

    useEffect(() => {
        handleGetPlayerWalletBalance();
    }, [handleGetPlayerWalletBalance, pageNo, pageSize]);

    const errorMsg = useError(error, playerWalletBalanceGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetPlayerWalletBalance];
}

export function useGetPlayerWinLossFilterListAsyncEndpoint() {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetPlayerWinLossFilterList = useCallback(() => {
        filterOptionGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${REPORTS.PLAYER_WIN_LOSS.FILTER_LIST}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            filterOptionGetSuccess(dispatch, {
                platforms: response.data.data.platforms,
                products: response.data.data.products,
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        handleGetPlayerWinLossFilterList();
    }, [handleGetPlayerWinLossFilterList]);

    const errorMsg = useError(error, filterOptionGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}

export function useGetPlayerWinLossReportAsyncEndpoint(isExport) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const playerWinLoss = getStateProp(state, 'reports.playerWinLoss');
    const filters = getStateProp(playerWinLoss, 'filters', {});
    const { pageNo, pageSize } = getStateProp(playerWinLoss, 'paging');
    const { userName, ...restFilter } = filters;

    // ============================================
    // METHODS
    // ============================================
    const handleGetPlayerWinLoss = useCallback(() => {
        isExport ? playerWinLossExport(dispatch) : playerWinLossReportGet(dispatch);
        setTrigger(+new Date());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'POST',
        options: {
            data: {
                ...restFilter,
                isExport,
                pageNo,
                pageSize,
                userNames: userName && [userName],
            },
        },
        trigger,
        url: `${VERSION.V1}${REPORTS.PLAYER_WIN_LOSS.QUERY}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            if (isExport) {
                playerWinLossExportSuccess(dispatch);
                exportWarningModal(true);
            } else {
                playerWinLossReportGetSuccess(dispatch, {
                    fetchData: handleGetPlayerWinLoss,
                    list: response.data.data[0],
                    paging: response.data.paging,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, handleGetPlayerWinLoss, response]);

    useEffect(() => {
        handleGetPlayerWinLoss();
    }, [handleGetPlayerWinLoss, pageNo, pageSize]);

    const errorMsg = useError(
        error,
        isExport ? playerWinLossExportFailure : playerWinLossReportGetFailure,
    );
    useToasterErrorMessage(error, errorMsg);

    return [loading, handleGetPlayerWinLoss];
}

export function useGetPlayerWinLossDetailAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const filters = getStateProp(state, 'reports.playerWinLoss.modalDetail.filters', {});
    const { pageNo, pageSize } = getStateProp(
        state,
        'reports.playerWinLoss.winLossDetail.paging',
        {},
    );
    const { userName, platformCodes, productCodes, ...restFilter } = filters;

    // ============================================
    // METHODS
    // ============================================

    const handleGetPlayerWinLossDetails = useCallback(() => {
        playerWinLossDetailsGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !_.isEmpty(filters),
        method: 'POST',
        options: {
            data: {
                ...restFilter,
                pageNo,
                pageSize,
                platform: platformCodes,
                products: productCodes,
                userNames: userName && [userName],
            },
        },
        trigger,
        url: `${VERSION.V1}${BET.BET_LIST}/query`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            playerWinLossDetailsGetSuccess(dispatch, {
                list: response.data.data[0].data,
                paging: response.data.paging,
                summary: {
                    totalEffectiveTurnover: response.data.data[0].totalEffectiveTurnover,
                    totalHoldStake: response.data.data[0].totalHoldStake,
                    totalPaidStake: response.data.data[0].totalPaidStake,
                    totalStake: response.data.data[0].totalStake,
                    totalTurnover: response.data.data[0].totalTurnover,
                    totalWinLoss: response.data.data[0].totalWinLoss,
                },
            });
        }
    }, [dispatch, response]);

    useEffect(() => {
        handleGetPlayerWinLossDetails();
    }, [handleGetPlayerWinLossDetails, filters, pageNo, pageSize]);

    const errorMsg = useError(error, playerWinLossDetailsGetFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading];
}
