export const ACTIONS = {
    PLAYER_IP_CLOSE_MODAL: 'PLAYER_IP_CLOSE_MODAL',
    PLAYER_IP_GET: 'PLAYER_IP_GET',
    PLAYER_IP_GET_FAILURE: 'PLAYER_IP_GET_FAILURE',
    PLAYER_IP_GET_SUCCESS: 'PLAYER_IP_GET_SUCCESS',
    PLAYER_IP_OPEN_MODAL: 'PLAYER_IP_OPEN_MODAL',
    PLAYER_IP_PAGINATION: {
        TABLE_PAGE_CHANGE: 'PLAYER_IP_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'PLAYER_IP_TABLE_PAGE_SIZE_CHANGE',
    },
    PLAYER_IP_SET: 'PLAYER_IP_SET',
    REGISTRATION_DETAILS_GET: 'REGISTRATION_DETAILS_GET',
    REGISTRATION_DETAILS_GET_FAILURE: 'REGISTRATION_DETAILS_GET_FAILURE',
    REGISTRATION_DETAILS_GET_SUCCESS: 'REGISTRATION_DETAILS_GET_SUCCESS',
    SEARCH_IP_GET: 'SEARCH_IP_GET',
    SEARCH_IP_GET_FAILURE: 'SEARCH_IP_GET_FAILURE',
    SEARCH_IP_GET_SUCCESS: 'SEARCH_IP_GET_SUCCESS',
};

export const registrationDetailsGet = (dispatch) =>
    dispatch({
        type: ACTIONS.REGISTRATION_DETAILS_GET,
    });

export const registrationDetailsGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REGISTRATION_DETAILS_GET_SUCCESS,
    });

export const registrationDetailsGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REGISTRATION_DETAILS_GET_FAILURE,
    });

export const setPlayerIp = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_IP_SET,
    });

export const openPlayerIpModal = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYER_IP_OPEN_MODAL,
    });

export const closePlayerIpModal = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYER_IP_CLOSE_MODAL,
    });

export const playerIpGet = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYER_IP_GET,
    });

export const playerIpGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_IP_GET_SUCCESS,
    });

export const playerIpGetFailure = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYER_IP_GET_FAILURE,
    });
export const changePlayerIpPageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_IP_PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePlayerIpPageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_IP_PAGINATION.TABLE_PAGE_CHANGE,
    });

export const searchIpGet = (dispatch) =>
    dispatch({
        type: ACTIONS.SEARCH_IP_GET,
    });

export const searchIpGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.SEARCH_IP_GET_SUCCESS,
    });

export const searchIpGetFailure = (dispatch) =>
    dispatch({
        type: ACTIONS.SEARCH_IP_GET_FAILURE,
    });
