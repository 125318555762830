import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { Checkbox, Col, Form, Input, Row, Tabs, message } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import './Referral.less';
import { ButtonGray, ButtonTransparent } from '../../common/components/buttons/Buttons';
import {
    INITIAL_LAST_REFERRAL_LEVEL_TO,
    INITIAL_REFERRAL_DETAIL,
    REFERRAL_INFO_KEYS,
    REFERRAL_TYPE,
} from '../../common/constants/misc';
import { getIntOrFloat, isOperatorHasFullAccess } from '../../common/helpers/misc';
import { getReferralType, validateReferralPlan } from './misc';
import { getTranslation } from '../../common/helpers/locale';
import { useDispatch } from '../../store/StateProvider';
import ReferralContent from './table/ReferralContent';

const { TabPane } = Tabs;

const ReferralDetail = ({
    referralInfo,
    paging,
    setReferralInfo,
    created,
    onChangePageNo,
    onChangePageSize,
}) => {
    const dispatch = useDispatch();

    const { plans, code, name, isFixedPlan, isBonus, tierLevel } = referralInfo;
    const [tier, setTier] = useState(1);
    const [selectedReferralType, setSelectedReferralType] = useState([]);

    let newPlans = [];
    let newTier = 0;
    let newSelectedReferralType = [];

    // ============================================
    // METHODS
    // ============================================

    const onDetailsChange = (selectedCurrency, selectedTier, key, value) => {
        newPlans = plans;
        if (value || value === '' || value === 0) {
            _.map(newPlans, (x) => {
                if (x.currency === selectedCurrency) {
                    if (key === REFERRAL_INFO_KEYS.MIN_DEPOSIT) {
                        x[key] = value;
                    } else if (
                        key === REFERRAL_INFO_KEYS.REFEREE_AMOUNT ||
                        key === REFERRAL_INFO_KEYS.REFEREE_TURNOVER_REQUIRED
                    ) {
                        x[key] = value;
                        x[REFERRAL_INFO_KEYS.REFEREE_TURNOVER_AMOUNT] = (
                            x[REFERRAL_INFO_KEYS.REFEREE_AMOUNT] *
                            x[REFERRAL_INFO_KEYS.REFEREE_TURNOVER_REQUIRED]
                        ).toFixed(2);
                    } else {
                        _.map(x.details, (e) => {
                            if (e.tierLevel === selectedTier) {
                                e[key] = value;

                                if (
                                    key === REFERRAL_INFO_KEYS.BONUS_AMOUNT ||
                                    key === REFERRAL_INFO_KEYS.BONUS_TURNOVER_REQUIRED
                                ) {
                                    e[REFERRAL_INFO_KEYS.BONUS_TURNOVER_AMOUNT] = (
                                        e[REFERRAL_INFO_KEYS.BONUS_AMOUNT] *
                                        e[REFERRAL_INFO_KEYS.BONUS_TURNOVER_REQUIRED]
                                    ).toFixed(2);
                                }

                                if (
                                    key === REFERRAL_INFO_KEYS.REWARD_AMOUNT ||
                                    key === REFERRAL_INFO_KEYS.REWARD_TURNOVER_REQUIRED
                                ) {
                                    e[REFERRAL_INFO_KEYS.REWARD_TURNOVER_AMOUNT] = (
                                        e[REFERRAL_INFO_KEYS.REWARD_AMOUNT] *
                                        e[REFERRAL_INFO_KEYS.REWARD_TURNOVER_REQUIRED]
                                    ).toFixed(2);
                                }

                                let validateResult = validateReferralPlan(
                                    x.details,
                                    e,
                                    selectedTier,
                                    key,
                                );

                                if (!validateResult.validate) {
                                    message.error(validateResult.message);
                                }

                                if (
                                    key === REFERRAL_INFO_KEYS.REFERRAL_LEVEL_TO &&
                                    validateResult.validate &&
                                    x.details.length !== selectedTier
                                )
                                    x.details[selectedTier].referralLevelFrom =
                                        value !== '' ? parseInt(value) + 1 : 0;
                            }
                        });
                    }
                }
            });
        }
        setReferralInfo(dispatch, { plans: newPlans });
    };

    const onReferralTypeChange = (e) => {
        const isFixedPlanChange = _.includes(e, REFERRAL_TYPE.REWARDS);
        const isBonusChange = _.includes(e, REFERRAL_TYPE.BONUS);
        newSelectedReferralType = [];
        newPlans = plans;

        if (isFixedPlanChange) {
            newSelectedReferralType.push(REFERRAL_TYPE.REWARDS);
        } else {
            _.map(newPlans, (x) => {
                _.forIn(x.details, function(value) {
                    value[REFERRAL_INFO_KEYS.REWARD_AMOUNT] = 0;
                    value[REFERRAL_INFO_KEYS.REWARD_TURNOVER_REQUIRED] = 0;
                });
            });
        }

        if (isBonusChange) {
            newSelectedReferralType.push(REFERRAL_TYPE.BONUS);
        } else {
            _.map(newPlans, (x) => {
                _.forIn(x.details, function(value) {
                    value[REFERRAL_INFO_KEYS.BONUS_AMOUNT] = 0;
                    value[REFERRAL_INFO_KEYS.BONUS_TURNOVER_REQUIRED] = 0;
                });
            });
        }

        setSelectedReferralType(newSelectedReferralType);
        setReferralInfo(dispatch, { isBonus: isBonusChange, isFixedPlan: isFixedPlanChange });
    };

    const onTierChange = (value, method = '') => {
        let newValue = getIntOrFloat(value.toString());
        newValue = newValue && parseInt(newValue);
        newTier = tier;

        if (method === 'add' && newValue < 99) {
            newTier = newValue + 1;
        } else if (method === 'remove' && newValue > 1) {
            newTier = newValue - 1;
        } else if (newValue >= 0 && newValue < 100) {
            newTier = newValue;
        }

        setTier(newTier);

        if (newTier && plans.length) {
            newPlans = plans;
            _.map(newPlans, (x) => {
                if (x.details.length < newTier) {
                    x.details[x.details.length - 1].referralLevelTo = 0;
                    for (let i = x.details.length; i < newTier; i++) {
                        x.details.push({
                            referralLevelTo: i + 1 === newTier ? INITIAL_LAST_REFERRAL_LEVEL_TO : 0,
                            ...INITIAL_REFERRAL_DETAIL,
                            tierLevel: i + 1,
                        });
                    }
                } else if (x.details.length > newTier) {
                    x.details = _.slice(x.details, 0, newTier);
                    x.details[newTier - 1].referralLevelTo = INITIAL_LAST_REFERRAL_LEVEL_TO;
                }
            });
        }
        setReferralInfo(dispatch, { tierLevel: newTier });
    };

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (created) {
            setSelectedReferralType([]);
            setTier(1);
        }
    }, [created]);

    useEffect(() => {
        setSelectedReferralType(getReferralType(isFixedPlan, isBonus));
        setTier(tierLevel);
    }, [isFixedPlan, isBonus, tierLevel]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <>
            <Row className="row__form-item">
                <Col span={6}>
                    <label>{getTranslation('Referral Type')}</label>
                </Col>
                <Col span={12}>
                    <Form.Item>
                        <Checkbox.Group
                            style={{ width: '100%' }}
                            onChange={onReferralTypeChange}
                            value={selectedReferralType}
                            disabled={!isOperatorHasFullAccess()}
                        >
                            <Row style={{ flexFlow: 'row wrap' }}>
                                {_.map(_.values(REFERRAL_TYPE), (type) => (
                                    <Col span={6} key={type}>
                                        <Checkbox value={type}>{type}</Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row className="row__form-item">
                <Col span={6}>
                    <label>{getTranslation('Program Code')}</label>
                </Col>
                <Col span={9}>
                    <Form.Item name="code" valuePropName="code">
                        <Input
                            value={code}
                            onChange={(e) => setReferralInfo(dispatch, { code: e.target.value })}
                            disabled={!isOperatorHasFullAccess()}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="row__form-item">
                <Col span={6}>
                    <label>{getTranslation('Program Name')}</label>
                </Col>
                <Col span={9}>
                    <Form.Item name="name" valuePropName="name">
                        <Input
                            value={name}
                            onChange={(e) => setReferralInfo(dispatch, { name: e.target.value })}
                            disabled={!isOperatorHasFullAccess()}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="row__form-item">
                <Col span={6}>
                    <label>{getTranslation('Tier')}</label>
                </Col>
                <Col span={3}>
                    <Form.Item name="tier" valuePropName="tier">
                        <Input
                            value={tier}
                            maxLength={2}
                            onChange={(e) => onTierChange(e.target.value)}
                            disabled={!isOperatorHasFullAccess()}
                        />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <ButtonGray
                        icon={<PlusOutlined />}
                        label={getTranslation('Add').toLocaleUpperCase()}
                        className="add-button__form-item"
                        onClick={() => onTierChange(tier, 'add')}
                        disabled={!isOperatorHasFullAccess()}
                    />
                </Col>
                <Col span={3}>
                    <ButtonTransparent
                        icon={<MinusOutlined />}
                        label={getTranslation('Remove').toLocaleUpperCase()}
                        className="add-button__form-item"
                        onClick={() => onTierChange(tier, 'remove')}
                        disabled={!isOperatorHasFullAccess()}
                    />
                </Col>
            </Row>
            <Row>
                <Tabs className="tab_form" type="card" hideAdd>
                    {_.map(plans, (e) => {
                        return (
                            <TabPane tab={e.currency} key={e.currency}>
                                <ReferralContent
                                    selectedPlans={e}
                                    paging={paging}
                                    isBonus={isBonus}
                                    isFixedPlan={isFixedPlan}
                                    onDetailsChange={onDetailsChange}
                                    onChangePageNo={onChangePageNo}
                                    onChangePageSize={onChangePageSize}
                                />
                            </TabPane>
                        );
                    })}
                </Tabs>
            </Row>
        </>
    );
};

export default ReferralDetail;
