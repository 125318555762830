export const ACTIONS = {
    DELETE_MODAL_CLOSE: 'NEWS_DELETE_MODAL_CLOSE',
    DELETE_MODAL_OPEN: 'NEWS_DELETE_MODAL_OPEN',
    FILTERS_DROP_ALL: 'NEWS_FILTERS_DROP_ALL',
    FILTER_SET: 'NEWS_FILTER_SET',
    NEWS_ARTICLE_SAVE: 'NEWS_ARTICLE_SAVE', //
    NEWS_CREATE: 'NEWS_CREATE',
    NEWS_CREATE_FAILURE: 'NEWS_CREATE_FAILURE',
    NEWS_CREATE_SUCCESS: 'NEWS_CREATE_SUCCESS',
    NEWS_DELETE: 'NEWS_DELETE',
    NEWS_DELETE_FAILURE: 'NEWS_DELETE_FAILURE',
    NEWS_DELETE_SUCCESS: 'NEWS_DELETE_SUCCESS',
    NEWS_DRAFT_CLEAR: 'NEWS_DRAFT_CLEAR',
    NEWS_DRAFT_SAVE: 'NEWS_DRAFT_SAVE',
    NEWS_EDIT: 'NEWS_EDIT',
    NEWS_GET: 'NEWS_GET',
    NEWS_GET_FAILURE: 'NEWS_GET_FAILURE',
    NEWS_GET_SUCCESS: 'NEWS_GET_SUCCESS',
    NEWS_SET_LANGUAGE: 'NEWS_SET_LANGUAGE', //
    NEWS_UPDATE: 'NEWS_UPDATE',
    NEWS_UPDATE_FAILURE: 'NEWS_UPDATE_FAILURE',

    NEWS_UPDATE_SUCCESS: 'NEWS_UPDATE_SUCCESS',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'NEWS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'NEWS_TABLE_PAGE_SIZE_CHANGE',
    },
    RECORD_SELECT: 'NEWS_RECORD_SELECT',
};

export const newsGet = (dispatch) =>
    dispatch({
        type: ACTIONS.NEWS_GET,
    });

export const newsGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.NEWS_GET_SUCCESS,
    });

export const newsGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.NEWS_GET_FAILURE,
    });

export const openNewsEdit = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.NEWS_EDIT,
    });
};

export const newsUpdate = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.NEWS_UPDATE,
    });
};

export const newsUpdateSuccess = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.NEWS_UPDATE_SUCCESS,
    });
};

export const newsUpdateFailure = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.NEWS_UPDATE_FAILURE,
    });
};

export const newsAdd = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.NEWS_CREATE,
    });
};
export const newsAddSuccess = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.NEWS_CREATE_SUCCESS,
    });
};
export const newsAddFailure = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.NEWS_CREATE_FAILURE,
    });
};

export const newsDelete = (dispatch) =>
    dispatch({
        type: ACTIONS.NEWS_DELETE,
    });

export const newsDeleteSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.NEWS_DELETE_SUCCESS,
    });

export const newsDeleteFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.NEWS_DELETE_FAILURE,
    });

export const newsFilterSet = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });
};

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const openDeleteModal = (dispatch, payload) => {
    dispatch({ payload, type: ACTIONS.DELETE_MODAL_OPEN });
};

export const closeDeleteModal = (dispatch) => {
    dispatch({ type: ACTIONS.DELETE_MODAL_CLOSE });
};

// Ckw
export const setNewsLanguage = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.NEWS_SET_LANGUAGE,
    });

export const updateNewsArticle = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.NEWS_ARTICLE_SAVE,
    });
};

export const updateNewsInfo = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.NEWS_DRAFT_SAVE,
    });
};

export const clearNewsDraft = (dispatch) => {
    dispatch({ type: ACTIONS.NEWS_DRAFT_CLEAR });
};
