import affiliateGeneratorReducer from './affiliate-generator/affiliateGeneratorReducer';
import affiliateVerificationReducer from './affiliate-verification/affiliateVerificationReducer';

export default function affiliateReducer(affiliate = {}, action) {
    const { verification, generator } = affiliate;

    return {
        generator: affiliateGeneratorReducer(generator, action),
        verification: affiliateVerificationReducer(verification, action),
    };
}
