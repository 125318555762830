import React, { useEffect } from 'react';

import { Col, Row } from 'antd';

import { ButtonGray, ButtonRed } from '../../../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../../../common/helpers/locale';
import { useDeleteBankCardAsyncEndpoint } from '../../../../../../services/playerService';

const DeleteConfirmation = ({ card, onCancel, getPlayerInfo }) => {
    // ============================================
    // HOOKS
    // ============================================

    const [cardDeleting, deleteCard, cardDeleted] = useDeleteBankCardAsyncEndpoint(card.id);

    useEffect(() => {
        if (cardDeleted) {
            onCancel();
            getPlayerInfo();
        }
    }, [cardDeleted, getPlayerInfo, onCancel]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col>
            <Row style={{ marginBottom: 48, marginTop: 24 }}>
                <span>{getTranslation('Are you sure want to delete this Bank Card?')}</span>
            </Row>
            <Row justify="end" align="middle">
                <ButtonRed
                    label={getTranslation('Confirm')}
                    onClick={deleteCard}
                    loading={cardDeleting}
                />
                <ButtonGray
                    label={getTranslation('Cancel')}
                    onClick={onCancel}
                    style={{ marginLeft: 24 }}
                />
            </Row>
        </Col>
    );
};

export default DeleteConfirmation;
