import React from 'react';

import { Modal } from 'antd';

import '../../players-list/PlayersModal.less';
/*eslint import/namespace: ['error', { allowComputed: true }]*/
import * as Modals from './modals';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useStore } from '../../../../store/StateProvider';

const EWalletModal = ({ eWalletModal, onCancel, getPlayerInfo }) => {
    const { isVisible, modalType } = eWalletModal;
    const [state] = useStore();
    const eWalletAttending = getStateProp(
        state,
        'players.playerDetails.eWalletModal.eWalletAttending',
        {},
    );

    // ============================================
    // RENDER
    // ============================================

    const ModalContent = Modals[modalType.component];

    return (
        <Modal
            visible={isVisible}
            title={getTranslation(modalType.title)}
            centered
            className="players-modal"
            onCancel={onCancel}
            footer={null}
            width={modalType.width || 700}
        >
            <ModalContent
                onCancel={onCancel}
                eWallet={eWalletAttending}
                getPlayerInfo={getPlayerInfo}
            />
        </Modal>
    );
};

export default EWalletModal;
