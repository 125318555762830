import { Redirect, Switch } from 'react-router-dom';
import React from 'react';

import {
    AffiliateRecords,
    BonusRecords,
    CryptocurrencyRecords,
    DepositRecords,
    RebateRecords,
    ReferralRecords,
    RewardsRecords,
    TransactionRecords,
    TurnoverRecords,
    VipRecords,
    WalletTransfers,
    WithdrawalRecords,
} from '.';
import { PrivateRoute } from '../../common/components';
import { ROUTES } from '../../common/constants/routing';

const TransactionHistory = () => {
    return (
        <Switch>
            <PrivateRoute
                exact
                path={ROUTES.DASHBOARD.TRANSACTION_HISTORY.TRANSACTION_RECORDS}
                component={TransactionRecords}
            />
            <PrivateRoute
                exact
                path={ROUTES.DASHBOARD.TRANSACTION_HISTORY.DEPOSIT_RECORDS}
                component={DepositRecords}
            />
            <PrivateRoute
                exact
                path={ROUTES.DASHBOARD.TRANSACTION_HISTORY.WITHDRAWAL_RECORDS}
                component={WithdrawalRecords}
            />
            <PrivateRoute
                exact
                path={ROUTES.DASHBOARD.TRANSACTION_HISTORY.WALLET_TRANSFERS}
                component={WalletTransfers}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.TRANSACTION_HISTORY.CRYPTOCURRENCY_RECORDS.ROOT}
                component={CryptocurrencyRecords}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.TRANSACTION_HISTORY.BONUS_RECORDS}
                component={BonusRecords}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.TRANSACTION_HISTORY.AFFILIATE_RECORDS}
                component={AffiliateRecords}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.TRANSACTION_HISTORY.REFERRAL_RECORDS}
                component={ReferralRecords}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.TRANSACTION_HISTORY.REWARDS_RECORDS}
                component={RewardsRecords}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.TRANSACTION_HISTORY.REBATE_RECORDS}
                component={RebateRecords}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.TRANSACTION_HISTORY.VIP_RECORDS}
                component={VipRecords}
            />
            <PrivateRoute
                path={ROUTES.DASHBOARD.TRANSACTION_HISTORY.TURNOVER_RECORDS}
                component={TurnoverRecords}
                hasAccessControl
            />
            <Redirect to={ROUTES.DASHBOARD.TRANSACTION_HISTORY.TRANSACTION_RECORDS} />
        </Switch>
    );
};

export default TransactionHistory;
