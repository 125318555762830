import React, { useState } from 'react';

import { ButtonTransparent } from '../../../../common/components/buttons/Buttons';
import TotalRefereeModal from '../modal/TotalRefereeModal';

const ViewButton = ({ totalValue, id, userName, type }) => {
    const [totalRefereeModalVisible, setTotalRefereeModalVisible] = useState(false);
    const [paramId, setParamId] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const openViewModal = () => {
        setParamId(id);
        setTotalRefereeModalVisible(true);
    };

    const closeViewModal = () => {
        setTotalRefereeModalVisible(false);
        setParamId(null);
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <>
            <ButtonTransparent className="hyperlink-button" onClick={openViewModal}>
                {totalValue}
            </ButtonTransparent>

            <TotalRefereeModal
                visible={totalRefereeModalVisible}
                onCancel={closeViewModal}
                paramId={paramId}
                userName={userName}
                type={type}
            />
        </>
    );
};

export default ViewButton;
