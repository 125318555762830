import React from 'react';

import { ButtonBlue } from './Buttons';
import { getTranslation } from '../../helpers/locale';

const ColumnSelectionButton = ({ onColumnSelect }) => {
    return (
        <ButtonBlue type="primary" onClick={onColumnSelect}>
            {getTranslation('Column Selection')}
        </ButtonBlue>
    );
};

export default ColumnSelectionButton;
