export const ACTIONS = {
    BONUS_CREATE: 'BONUS_CREATE',
    BONUS_CREATE_FAILURE: 'BONUS_CREATE_FAILURE',
    BONUS_CREATE_SET_CURRENT_SETP: 'BONUS_CREATE_SET_CURRENT_SETP',
    BONUS_CREATE_SUCCESS: 'BONUS_CREATE_SUCCESS',
    BONUS_DUPLICATE: 'BONUS_DUPLICATE',
    BONUS_DUPLICATE_BY_LANGUAGE: 'BONUS_DUPLICATE_BY_LANGUAGE',
    BONUS_FORM_CLEAR: 'BONUS_FORM_CLEAR',
    BONUS_LANGUAGE_DROP: 'BONUS_LANGUAGE_DROP',
    BONUS_LANGUAGE_SET: 'BONUS_LANGUAGE_SET',
    SET_BONUS_INFO: 'SET_BONUS_INFO',
    SET_BONUS_INFO_BY_LANGUAGE: 'SET_BONUS_INFO_BY_LANGUAGE',
    SET_MULTI_BONUS_INFO: 'SET_MULTI_BONUS_INFO',
};

//#region Create Bonus
export const bonusCreateSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_CREATE_SUCCESS,
    });

export const bonusCreateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_CREATE_FAILURE,
    });

export const bonusCreate = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_CREATE,
    });
//#endregion

//#region Bonus Language Setting
export const setBonusLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_LANGUAGE_SET,
    });

export const dropBonusLang = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_LANGUAGE_DROP,
    });
//#endregion

//#region Bonus Info Update
export const setBonusInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.SET_BONUS_INFO,
    });

export const setMultiBonusInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.SET_MULTI_BONUS_INFO,
    });

export const setBonusInfoByLanguage = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.SET_BONUS_INFO_BY_LANGUAGE,
    });

export const bonusFormClear = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_FORM_CLEAR,
    });
//#endregion

//#region Bonus Duplicate
export const bonusDuplicate = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_DUPLICATE,
    });

export const bonusDuplicateByLanguage = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_DUPLICATE_BY_LANGUAGE,
    });
//#endregion

export const setCurrentStep = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.BONUS_CREATE_SET_CURRENT_SETP,
    });
};
