import React from 'react';

import { Row } from 'antd';

import { ButtonLightBlue } from '../../../common/components/buttons/Buttons';
import { DropButton, SearchButton } from '../../../common/components/buttons';

const ActionButtons = ({ onDropFilters, onEditDangerLevelTag }) => {
    return (
        <Row align="middle" justify="end" className="action-buttons__container">
            <ButtonLightBlue label="Edit Danger Level Tag" onClick={onEditDangerLevelTag} />
            <SearchButton htmlType="submit" />
            <DropButton onDropFilters={onDropFilters} />
        </Row>
    );
};

export default ActionButtons;
