import React from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { getTranslation } from '../../helpers/locale';
import { getUserCurrency } from '../../helpers/api';

const CurrencyFilter = ({
    value,
    onFilterChange,
    otherSelection = [],
    name,
    mode,
    allowClear = true,
    disabled,
    placeholder = 'Currency',
}) => {
    const CURRENCIES = getUserCurrency();

    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange(name, value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear={allowClear}
            placeholder={getTranslation(placeholder)}
            className="filter"
            mode={mode}
            disabled={disabled}
        >
            {_.map(_.concat(otherSelection, CURRENCIES), (currency) => (
                <Select.Option value={currency} key={currency}>
                    {currency.toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
};

export default CurrencyFilter;
