import React from 'react';

import { Card, Form } from 'antd';

import { dropFilters, rebateEditModalClose, rebateEditModalOpen } from './rebateManagementActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import {
    useGetRebateManagementByIdAsyncEndpoint,
    useGetRebateManagementListAsyncEndpoint,
} from '../../../services/rebateService';
import { useStore } from '../../../store/StateProvider';
import ActionButtons from './ActionButtons';
import EditRebateModal from './modal/EditRebateModal';
import RebateManagementFilters from './RebateManagementFilters';
import RebateManagementResultTable from './table/RebateManagementResultTable';

const RebateManagement = () => {
    const [state, dispatch] = useStore();
    const editModalVisible = getStateProp(state, 'rebate.rebateManagement.editModalVisible', false);

    // ============================================
    // METHODS
    // ============================================

    const openEditModal = () => rebateEditModalOpen(dispatch);

    const closeEditModal = () => {
        rebateEditModalClose(dispatch);
    };

    const onDropFilters = () => {
        dropFilters(dispatch);
        handleGetRebateManagement();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading, handleGetRebateManagement] = useGetRebateManagementListAsyncEndpoint();
    const [getRebateByIdLoading] = useGetRebateManagementByIdAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('Rebate Management')}>
            <Form onFinish={handleGetRebateManagement}>
                <RebateManagementFilters />
                <ActionButtons onDropFilters={onDropFilters} />
            </Form>
            <RebateManagementResultTable openEditModal={openEditModal} loading={loading} />

            <EditRebateModal
                onCancel={closeEditModal}
                visible={editModalVisible}
                loading={getRebateByIdLoading}
            />
        </Card>
    );
};

export default RebateManagement;
