import React from 'react';

import { Table } from 'antd';

import { getColumns } from './TopTenReferrerTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const TopTenReferrerResultsTable = ({ loading }) => {
    const [state] = useStore();

    const tableData = getStateProp(state, 'referral.referralReport.topTenReferrer.list', []);

    return (
        <Table
            id="referral-report-table"
            columns={getColumns()}
            size="small"
            scroll={{ x: true }}
            align="left"
            dataSource={tableData}
            rowKey={(record) => record.id}
            loading={loading}
            pagination={false}
            bordered
        />
    );
};

export default TopTenReferrerResultsTable;
