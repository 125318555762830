export const DEPOSIT_TABLE_COLUMNS = [
    {
        key: 'requestedAt',
        title: 'Request Time',
    },
    {
        key: 'bankingTransactedAt',
        title: 'Transaction Time',
    },
    {
        key: 'transactionNo',
        required: true,
        title: 'Task Transaction Number',
    },
    {
        key: 'playerUserName',
        title: 'Username',
    },
    {
        key: 'realName',
        required: true,
        title: 'Full Name',
    },
    {
        key: 'memberLevel',
        required: true,
        title: 'Member Level',
    },
    {
        key: 'trustLevel',
        required: true,
        title: 'Danger Level',
    },
    {
        key: 'amount',
        required: true,
        title: 'Request Amount',
    },
    {
        key: 'currencyCode',
        required: true,
        title: 'Currency',
    },
    {
        key: 'transactionMethod',
        required: true,
        title: 'Transaction Method',
    },
    {
        key: 'bankName',
        required: true,
        title: 'Bank',
    },
    {
        key: 'cardHolderName',
        title: 'Bank Account Name',
    },
    {
        key: 'bankCardNo',
        required: true,
        title: 'Bank Account Number',
    },
    {
        key: 'requesterUserName',
        title: 'Applicant',
    },
    {
        key: 'orderStatus',
        required: true,
        title: 'Status',
    },
    {
        key: 'verifiedAt',
        title: 'Process Time',
    },
    {
        key: 'refCode',
        title: 'System Remark',
    },
    {
        key: 'bankingMethod',
        title: 'Banking Method',
    },
    {
        key: 'playerId',
        title: 'Bank Cards',
    },
    {
        key: 'transactionStatus',
        title: 'Transaction Status',
    },
    {
        key: 'imageURL',
        title: 'Bank Slip',
    },
    {
        key: 'operation',
        required: true,
        title: 'Operation',
    },
];

export const EWALLET_TRANSFER_TABLE_COLUMNS = [
    {
        key: 'requestedAt',
        title: 'Request Time',
    },
    {
        key: 'bankingTransactedAt',
        title: 'Transaction Time',
    },
    {
        key: 'transactionNo',
        required: true,
        title: 'Task Transaction Number',
    },
    {
        key: 'playerUserName',
        title: 'Username',
    },
    {
        key: 'realName',
        required: true,
        title: 'Full Name',
    },
    {
        key: 'memberLevel',
        required: true,
        title: 'Member Level',
    },
    {
        key: 'trustLevel',
        required: true,
        title: 'Trust Level',
    },
    {
        key: 'amount',
        required: true,
        title: 'Request Amount',
    },
    {
        key: 'currencyCode',
        required: true,
        title: 'Currency',
    },

    {
        key: 'eWalletName',
        required: true,
        title: 'E-Wallet Name',
    },
    {
        key: 'accountHolderName',
        title: 'E-Wallet Account Name',
    },
    {
        key: 'eWalletAccountNo',
        required: true,
        title: 'E-Wallet Account Number',
    },
    {
        key: 'requesterUserName',
        title: 'Applicant',
    },
    {
        key: 'orderStatus',
        required: true,
        title: 'Status',
    },
    {
        key: 'verifiedAt',
        title: 'Process Time',
    },
    {
        key: 'transactionStatus',
        title: 'Transaction Status',
    },
    {
        key: 'transactionType',
        title: 'Transaction Type',
    },
    {
        key: 'imageURL',
        title: 'Transfer Slip',
    },
    {
        key: 'operation',
        required: true,
        title: 'Operation',
    },
];

export const BANKING_METHODS = {
    CashDepositMachine: 'Cash Deposit Machine',
    ATM: 'ATM',
    OnlineBank: 'Online Banking',
};

export const BANK_CARDS_COLUMNS = [
    {
        key: 'bankName',
        title: 'Bank Name',
    },
    {
        key: 'cardHolderName',
        title: 'Card Holder Name',
    },
    {
        key: 'bankCardNo',
        title: 'Card No.',
    },
];
