import { ACTIONS } from './ewalletWithdrawalsActions';
import {
    TABLE_PAGE_NO_DEFAULT,
    TABLE_PAGE_SIZE_DEFAULT,
    TABLE_PAGE_SIZE_OPTIONS,
} from '../../../common/constants/api';

export default function ewalletewalletWithdrawalsReducer(
    ewalletWithdrawals = {
        operation: {
            action: null,
            isRecord: false,
            refreshTime: 15,
            refreshTimePause: false,
        },
        paging: {
            pageNo: TABLE_PAGE_NO_DEFAULT,
            pageSize: TABLE_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        rejectModalVisible: false,
        resetPageNo: false,
        verifyModalVisible: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.FILTER_SET:
            return {
                ...ewalletWithdrawals,
                filters: {
                    ...ewalletWithdrawals.filters,
                    [payload.filter]: payload.value,
                },
                resetPageNo: true,
            };
        case ACTIONS.FILTERS_DROP_ALL:
            return {
                ...ewalletWithdrawals,
                filters: null,
                resetPageNo: true,
            };
        case ACTIONS.EWALLET_WITHDRAWALS_GET_SUCCESS:
            return {
                ...ewalletWithdrawals,
                ...payload,
            };
        case ACTIONS.REJECT_INFO_SET:
            return {
                ...ewalletWithdrawals,
                rejectInfo: {
                    ...ewalletWithdrawals.rejectInfo,
                    [payload.rejectInfo]: payload.value,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_CHANGE:
            return {
                ...ewalletWithdrawals,
                paging: {
                    ...ewalletWithdrawals.paging,
                    pageNo:
                        payload > ewalletWithdrawals.paging.pageCount
                            ? ewalletWithdrawals.paging.pageCount - 1
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE:
            const MAX_PER_PAGE = Number(
                TABLE_PAGE_SIZE_OPTIONS[TABLE_PAGE_SIZE_OPTIONS.length - 1],
            );
            return {
                ...ewalletWithdrawals,
                paging: {
                    ...ewalletWithdrawals.paging,
                    pageNo: 1,
                    pageSize:
                        payload < 1
                            ? TABLE_PAGE_SIZE_DEFAULT
                            : payload > MAX_PER_PAGE
                            ? MAX_PER_PAGE
                            : payload,
                },
            };
        case ACTIONS.OPERATION:
            return {
                ...ewalletWithdrawals,
                operation: {
                    ...ewalletWithdrawals.operation,
                    ...payload,
                },
            };
        case ACTIONS.EWALLET_WITHDRAWALS_GET_CARDS_SUCCESS:
            return {
                ...ewalletWithdrawals,
                ewalletCards: {
                    ...payload.list,
                },
            };
        case ACTIONS.VERIFY_INFO_SET:
            return {
                ...ewalletWithdrawals,
                verifyInfo: {
                    ...ewalletWithdrawals.verifyInfo,
                    [payload.verifyInfo]: payload.value,
                },
            };
        case ACTIONS.VERIFY_MODAL_OPEN:
            return {
                ...ewalletWithdrawals,
                verifyModalVisible: true,
            };
        case ACTIONS.VERIFY_MODAL_CLOSE:
            return {
                ...ewalletWithdrawals,
                operation: {
                    ...ewalletWithdrawals.operation,
                    isRecord: false,
                },
                verifyModalVisible: false,
            };
        case ACTIONS.REJECT_MODAL_OPEN:
            return {
                ...ewalletWithdrawals,
                rejectModalVisible: true,
            };
        case ACTIONS.REJECT_MODAL_CLOSE:
            return {
                ...ewalletWithdrawals,
                operation: {
                    ...ewalletWithdrawals.operation,
                    isRecord: false,
                },
                rejectModalVisible: false,
            };
        case ACTIONS.REFRESH_TIME_SET:
            return {
                ...ewalletWithdrawals,
                operation: {
                    ...ewalletWithdrawals.operation,
                    refreshTime: payload.timeOut,
                    refreshTimePause: false,
                },
            };
        case ACTIONS.REFRESH_TIME_PAUSE:
            return {
                ...ewalletWithdrawals,
                operation: {
                    refreshTimePause: true,
                },
            };
        case ACTIONS.COLUMNS_SELECT:
            return {
                ...ewalletWithdrawals,
                selectedColumns: payload,
            };
        case ACTIONS.EWALLET_WITHDRAWALS_GET:
            const pageNo = ewalletWithdrawals.resetPageNo
                ? TABLE_PAGE_NO_DEFAULT
                : ewalletWithdrawals.paging.pageNo;
            return {
                ...ewalletWithdrawals,
                paging: {
                    ...ewalletWithdrawals.paging,
                    pageNo: pageNo,
                },
                resetPageNo: false,
            };
        case ACTIONS.OPERATION_SUCCESS:
        case ACTIONS.OPERATION_FAILURE:
            return {
                ...ewalletWithdrawals,
                operation: {
                    ...ewalletWithdrawals.operation,
                    action: null,
                },
            };
        case ACTIONS.EWALLET_WITHDRAWALS_DANGER_LEVEL_GET_SUCCESS:
            return {
                ...ewalletWithdrawals,
                dangerLevels: payload,
            };
        case ACTIONS.EWALLET_WITHDRAWALS_DANGER_LEVEL_GET:
        default:
            return ewalletWithdrawals;
    }
}
