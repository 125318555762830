export const DAYS_IN_WEEK = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const DAYS_IN_WEEK_FULL_VALUE = [
    {
        display: 'Mon',
        value: 'Monday',
    },
    {
        display: 'Tue',
        value: 'Tuesday',
    },
    {
        display: 'Wed',
        value: 'Wednesday',
    },
    {
        display: 'Thu',
        value: 'Thursday',
    },
    {
        display: 'Fri',
        value: 'Friday',
    },
    {
        display: 'Sat',
        value: 'Saturday',
    },
    {
        display: 'Sun',
        value: 'Sunday',
    },
];

export const MIN_THRESHOLD = [
    {
        amount: 8,
        currency: 'THB',
    },
    {
        amount: 5,
        currency: 'IDR',
    },
    {
        amount: 6,
        currency: 'VND',
    },
    {
        amount: 1,
        currency: 'CNY',
    },
    {
        amount: 1,
        currency: 'MYR',
    },
    {
        amount: 1,
        currency: 'USD',
    },
];

export const REBATE_STATUSES = {
    ACTIVE: 'Active',
    CLOSED: 'Closed',
    INACTIVE: 'Inactive',
};

export const REBATE_FORM_TYPE = {
    CREATE: 'create',
    EDIT: 'edit',
};

export const REBATE_GENERATOR_STATUSES = {
    PENDING: 'Pending',
    REJECTED: 'Rejected',
    SUCCESS: 'Success',
};

export const REBATE_TYPE = {
    DAILY: 'Daily',
    WeekLy: 'Weekly',
};
