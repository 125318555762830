import React from 'react';

import { Modal, Row } from 'antd';

import './ConfirmModal.less';
import { ButtonGray, ButtonRed } from '../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../common/helpers/locale';

const ConfirmRejectModal = ({ loading, onCancel, visible, onReject }) => {
    // ============================================
    // METHODS
    // ============================================

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            visible={visible}
            title={getTranslation('Confirm to Reject?')}
            centered
            closable={false}
            className="affiliate-verification__confirm-modal"
            footer={null}
            onCancel={onCancel}
        >
            <Row key="actions" justify="space-between" align="middle">
                <ButtonRed
                    loading={loading}
                    label={getTranslation('Reject')}
                    onClick={() => onReject(false)}
                />
                <ButtonGray label={getTranslation('Cancel')} onClick={onCancel} />
            </Row>
        </Modal>
    );
};

export default ConfirmRejectModal;
