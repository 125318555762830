import React from 'react';

import { Select } from 'antd';

import './BetTypesFilter.less';
import { getStateProp } from '../../../../common/helpers/misc';
import { getTranslation } from '../../../../common/helpers/locale';
import { useStore } from '../../../../store/StateProvider';

const BetTypesFilter = ({ value, onFilterChange }) => {
    const [state] = useStore();
    const betTypes = getStateProp(state, 'bet.betList.filterOptions.betTypes') || [];

    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('betTypes', value);

    // ============================================
    // RENDER
    // ============================================

    const options = betTypes.map((types) => (
        <Select.Option key={types.value}>{types.text.toLocaleUpperCase()}</Select.Option>
    ));

    return (
        <Select
            className="filter bet-type-filter"
            placeholder={getTranslation('Bet Type')}
            mode="multiple"
            value={value}
            allowClear={true}
            onChange={handleFilterChange}
            showSearch
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {options}
        </Select>
    );
};

export default BetTypesFilter;
