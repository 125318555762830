import React from 'react';

import { Card, Modal, message } from 'antd';
import _ from 'lodash';

import { addLanguage, removeLanguage, updatePushNotification } from '../helpers';
import { getStateProp } from '../../../common/helpers/misc';
import { getSupportedCurrenciesByCountry, getTranslation } from '../../../common/helpers/locale';
import {
    useSendPushNotificationAsyncEndpoint,
    useUpsertPushNotificationAsyncEndpoint,
} from '../../../services/pushNotificationService';

import {
    addPushNotificationLang,
    closeNotificationModal,
    removePushNotificationLang,
    updatePushNotificationInfo,
    updateTargetInfo,
} from './pushNotificationManagementAction';
import { useStore } from '../../../store/StateProvider';
import PushNotificationDetails from '../shared/general/PushNotificationDetails';
import TargetPushNotificationDetails from '../shared/target/TargetPushNotificationDetails';

const PushNotificationModal = ({ visible, gettingDataById }) => {
    const [state, dispatch] = useStore();
    const { selectedPushNotification = {}, selectedId = '' } = getStateProp(
        state,
        'pushNotification.pushNotificationManagement',
    );
    const selectedCurrency = getStateProp(
        selectedPushNotification,
        'currencies.currencyCode',
        undefined,
    );
    const supportedCurrency = _.map(
        getSupportedCurrenciesByCountry(),
        (currency) => currency.currency,
    );

    // ============================================
    // METHODS
    // ============================================

    const selectNewsLanguages = (e) => {
        if (e.target.checked) {
            addPushNotificationLang(
                dispatch,
                addLanguage(selectedPushNotification.notificationDetails, e.target.value),
            );
        } else {
            removePushNotificationLang(
                dispatch,
                removeLanguage(selectedPushNotification.notificationDetails, e.target.value),
            );
        }
    };

    const handleUpdatePushNotification = (value, languageCode, key) => {
        updatePushNotificationInfo(
            dispatch,
            updatePushNotification(
                selectedPushNotification.notificationDetails,
                value,
                languageCode,
                key,
            ),
        );
    };

    const handleUpdateTargetAspect = (value, key) => {
        if (
            key === 'currency' &&
            _.difference(selectedCurrency, value)[0] &&
            !_.includes(supportedCurrency, _.difference(selectedCurrency, value)[0])
        ) {
            message.error(getTranslation('Restrict to delete not allowed currency'));
            return;
        }

        const payload =
            key === 'currency'
                ? { key: 'currencies', value: { currencyCode: value } }
                : { key, value };

        updateTargetInfo(dispatch, payload);
    };

    // ============================================
    // HOOKS
    // ============================================
    const [updating, handleUpsertPushNotification] = useUpsertPushNotificationAsyncEndpoint();
    const [sending, handleSendPushNotification] = useSendPushNotificationAsyncEndpoint(selectedId);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Modal
            width={1080}
            visible={visible}
            onCancel={() => closeNotificationModal(dispatch)}
            closable={false}
            footer={null}
        >
            <Card title={getTranslation('Push Notification')} loading={gettingDataById}>
                {selectedPushNotification.targetType === 'None' ? (
                    <PushNotificationDetails
                        pushNotificationInfo={selectedPushNotification.notificationDetails}
                        submitting={updating}
                        selectNewsLanguages={selectNewsLanguages}
                        isCreate={false}
                        sending={sending}
                        visible={visible}
                        notificationStatus={selectedPushNotification.notificationStatus}
                        handleUpdatePushNotification={handleUpdatePushNotification}
                        handleUpsertPushNotification={handleUpsertPushNotification}
                        handleSendPushNotification={handleSendPushNotification}
                        resetPushNotificationInfo={closeNotificationModal}
                    />
                ) : (
                    <TargetPushNotificationDetails
                        pushNotificationInfo={selectedPushNotification.notificationDetails}
                        targetAspect={selectedPushNotification.targetType}
                        currency={selectedCurrency}
                        handleLanguageSet={selectNewsLanguages}
                        handleUpdateInfoByLanguage={handleUpdatePushNotification}
                        handleUpdateInfo={handleUpdateTargetAspect}
                        usernames={
                            selectedPushNotification.userNames &&
                            selectedPushNotification.userNames.userName
                        }
                        isEdit={true}
                        formSubmit={handleUpsertPushNotification}
                        updating={updating}
                        sendMessage={handleSendPushNotification}
                        sending={sending}
                        status={selectedPushNotification.notificationStatus}
                    />
                )}
            </Card>
        </Modal>
    );
};

export default PushNotificationModal;
