import React, { useEffect, useState } from 'react';

import { Button, Checkbox, Col, Divider, Form, Row, message } from 'antd';
import _ from 'lodash';

import { ButtonGreen } from '../../../../../common/components/buttons/Buttons';
import { Loader } from '../../../../../common/components';
import { getStateProp } from '../../../../../common/helpers/misc';
import { getTranslation } from '../../../../../common/helpers/locale';
import {
    useGetGamePlatformAsyncEndpoint,
    useGetPlayerGameAccessAsyncEndpoint,
    useUpdatePlayerGamePlatformAsyncEndpoint,
} from '../../../../../services/playerService';
import { useStore } from '../../../../../store/StateProvider';

const UpdatePlayerGameAccess = ({ player, onCancel }) => {
    const [state] = useStore();
    const [formValues, setFormValues] = useState({});
    const gamePlatform = getStateProp(state, 'players.playersList.playersModal.gamePlatform', []);
    const gameAccess = getStateProp(
        state,
        'players.playersList.playersModal.gameAccess[0].gameAccess',
        [],
    );
    const [form] = Form.useForm();
    const { setFieldsValue } = form;

    // ============================================
    // METHODS
    // ============================================

    const submitForm = (values) => {
        if (values.gameAccess.length === gamePlatform.length) values.gameAccess = null;
        else if (values.gameAccess.length === 0) values.gameAccess = [];

        setFormValues({
            gameAccess: values.gameAccess,
            players: [player.id],
        });

        handleUpdatePlayerGamePlatform();
    };

    // ============================================
    // HOOKS
    // ============================================

    const [playerGameAccessLoading, response] = useGetPlayerGameAccessAsyncEndpoint(
        player.userName,
    );
    const [gamePlatformLoading] = useGetGamePlatformAsyncEndpoint();

    const [
        updating,
        handleUpdatePlayerGamePlatform,
        updated,
    ] = useUpdatePlayerGamePlatformAsyncEndpoint(formValues);

    useEffect(() => {
        if (response)
            setFieldsValue({
                gameAccess:
                    gameAccess === null
                        ? _.map(gamePlatform, (platform) => platform.code)
                        : gameAccess,
            });
    }, [gameAccess, gamePlatform, response, setFieldsValue]);

    useEffect(() => {
        if (updated) {
            message.success('Players Game Access Updated!');
            onCancel();
        }
    }, [onCancel, updated]);

    // ============================================
    // RENDER
    // ============================================

    return gamePlatformLoading || playerGameAccessLoading ? (
        <Loader />
    ) : (
        <Form onFinish={submitForm} form={form}>
            <Form.Item name="gameAccess" label={getTranslation('Game Access')}>
                <Checkbox.Group>
                    <Row style={{ flexFlow: 'row wrap' }}>
                        {_.map(gamePlatform, (game) => {
                            return (
                                <Col span={6} key={game.code}>
                                    <Checkbox value={game.code}>
                                        {game.desc.toLocaleUpperCase()}
                                    </Checkbox>
                                </Col>
                            );
                        })}
                    </Row>
                </Checkbox.Group>
            </Form.Item>
            <Divider />
            <Row justify="end" className="form__group">
                <ButtonGreen htmlType="submit" loading={updating}>
                    {getTranslation('Update')}
                </ButtonGreen>
                <Button onClick={onCancel}>{getTranslation('Cancel')}</Button>
            </Row>
        </Form>
    );
};

export default UpdatePlayerGameAccess;
