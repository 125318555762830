export const TRANSACTION_MONITORING_TABLE_COLUMNS = [
    {
        key: 'No',
        title: 'No',
    },
    {
        key: 'requestedDateTime',
        title: 'Request Time',
    },
    {
        key: 'transactionNo',
        required: true,
        title: 'Task Transaction Number',
    },
    {
        key: 'playerUserName',
        required: true,
        title: 'Username',
    },
    {
        key: 'memberLevel',
        title: 'Member Level',
    },
    {
        key: 'turnoverCondition',
        title: 'Turnover Requirement',
    },
    {
        key: 'turnoverRequired',
        title: 'Turnover Amount',
    },
    {
        key: 'affiliateUserName',
        title: 'Affiliate',
    },
    {
        key: 'referralUserName',
        title: 'Referral',
    },
    {
        key: 'currencyCode',
        required: true,
        title: 'Currency',
    },
    {
        key: 'cryptoCurrencyCode',
        title: 'Cryptocurrency',
    },
    {
        key: 'transactionType',
        required: true,
        title: 'Type',
    },
    {
        key: 'transactionDetails',
        required: true,
        title: 'Detail',
    },
    {
        key: 'amount',
        required: true,
        title: 'Amount',
    },
    {
        key: 'cryptoCurrencyAmount',
        title: 'Cryptocurrency Amount',
    },
    {
        key: 'bankName',
        required: true,
        title: 'Bank Name',
    },
    {
        key: 'auditedDateTime',
        title: 'Audit Time',
    },
    {
        key: 'processedDateTime',
        title: 'Process Time',
    },
    {
        key: 'remarks',
        title: 'Remarks',
    },
    {
        key: 'orderStatus',
        required: true,
        title: 'Status',
    },
    {
        key: 'requesterName',
        title: 'Applicant',
    },
    {
        key: 'auditorName',
        title: 'Auditor',
    },
    {
        key: 'operatorName',
        required: true,
        title: 'Operator',
    },
];
