export const ACTIONS = {
    FILTERS_DROP_ALL: 'OVERVIEW_FILTER_DROP_ALL',
    FILTER_SET: 'OVERVIEW_FILTER_SET',

    OVERVIEW_FILTER_DATE_TYPE_CHANGE: 'OVERVIEW_FILTER_DATE_TYPE_CHANGE',
    OVERVIEW_GET: 'OVERVIEW_GET',
    OVERVIEW_GET_FAILURE: 'OVERVIEW_GET_FAILURE',

    OVERVIEW_GET_SUCCESS: 'OVERVIEW_GET_SUCCESS',

    OVERVIEW_INIT: 'OVERVIEW_INIT',

    PAGINATION: {
        TABLE_PAGE_CHANGE: 'OVERVIEW_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'OVERVIEW_TABLE_PAGE_SIZE_CHANGE',
    },
};

export const overviewInit = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.OVERVIEW_INIT,
    });
};

export const overviewFilterSet = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });
};

export const dropFilters = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.FILTERS_DROP_ALL,
    });
};

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const overviewGet = (dispatch) =>
    dispatch({
        type: ACTIONS.OVERVIEW_GET,
    });

export const overviewGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OVERVIEW_GET_SUCCESS,
    });

export const overviewGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OVERVIEW_GET_FAILURE,
    });

export const overviewDateTypeChange = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OVERVIEW_FILTER_DATE_TYPE_CHANGE,
    });
