import React from 'react';

import { Card } from 'antd';

import { ButtonGreen } from '../../../common/components/buttons/Buttons';
import { USER_AUTHORITY_SETTING_MODAL_TYPES } from './constants';
import {
    closeUserAuthoritySettingModal,
    openUserAuthoritySettingModal,
} from './userAuthoritySettingActions';
import { getStateProp } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useGetMenusAsyncEndpoint } from '../../../services/groupAuthoritySettingService';
import {
    useGetUserAuthorityListAsyncEndpoint,
    useGetUserGroupFullListAsyncEndpoint,
} from '../../../services/adminService';
import { useStore } from '../../../store/StateProvider';
import UserAuthorityModal from './modal/UserAuthorityModal';
import UserAuthoritySettingTable from './table/UserAuthoritySettingTable';

const UserAuthoritySetting = () => {
    const [state, dispatch] = useStore();
    const userAuthoritySetting = getStateProp(state, 'administration.userAuthoritySetting', {});
    const modalDetails = getStateProp(userAuthoritySetting, 'modalDetails', {});
    const userGroupList = getStateProp(userAuthoritySetting, 'userGroupList');

    //============================================
    // METHODS
    // ============================================

    const handleOpenModal = (modalType, modalData = null) => {
        openUserAuthoritySettingModal(dispatch, { modalData, modalType });
    };

    const handleCloseModal = () => closeUserAuthoritySettingModal(dispatch);

    // ============================================
    // HOOKS
    // ============================================

    const [loading] = useGetUserAuthorityListAsyncEndpoint();
    const [getUserGroupListLoading] = useGetUserGroupFullListAsyncEndpoint(userGroupList);
    useGetMenusAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Card title={getTranslation('User Authority Setting')}>
            <ButtonGreen
                label={getTranslation('Add User')}
                onClick={() => handleOpenModal(USER_AUTHORITY_SETTING_MODAL_TYPES.ADD_USER)}
            />
            <UserAuthoritySettingTable openModal={handleOpenModal} loading={loading} />
            {modalDetails.isVisible && (
                <UserAuthorityModal
                    onCancel={handleCloseModal}
                    modalDetails={modalDetails}
                    getUserGroupListLoading={getUserGroupListLoading}
                    userGroupList={userGroupList}
                />
            )}
        </Card>
    );
};

export default UserAuthoritySetting;
