import React from 'react';

import { Row } from 'antd';

import { ApplicantFilter, PeriodFilter, UsernameFilter } from '../../../common/components/filters/';
import {
    CurrentInfoFilter,
    ModificationStatusFilter,
    ModificationTypeFilter,
    NewInfoFilter,
    TransactionCodeFilter,
} from '../filters';
import { getStateProp } from '../../../common/helpers/misc';
import { modificationsFilterSet } from './modificationsHistoryActions';
import { useStore } from '../../../store/StateProvider';

const ModificationsHistoryFilters = () => {
    const [state, dispatch] = useStore();

    // ============================================
    // METHODS
    // ============================================

    const setFilter = (filter, value) => modificationsFilterSet(dispatch, { filter, value });

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="start" align="middle" className="filters-container">
            <UsernameFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.modificationsHistory.filters.userNameLike')}
                name="userNameLike"
            />
            <PeriodFilter
                onFilterChange={setFilter}
                dateFrom={getStateProp(state, 'players.modificationsHistory.filters.createdAtFrom')}
                dateFromName="createdAtFrom"
                dateTo={getStateProp(state, 'players.modificationsHistory.filters.createdAtTo')}
                dateToName="createdAtTo"
            />
            <TransactionCodeFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.modificationsHistory.filters.transactionCode')}
            />
            <ModificationTypeFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.modificationsHistory.filters.type')}
            />
            <ModificationStatusFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.modificationsHistory.filters.status')}
            />
            <ApplicantFilter
                onFilterChange={setFilter}
                value={getStateProp(
                    state,
                    'players.modificationsHistory.filters.createdByNameLike',
                )}
                name="createdByNameLike"
            />
            <CurrentInfoFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.modificationsHistory.filters.currentInfo')}
            />
            <NewInfoFilter
                onFilterChange={setFilter}
                value={getStateProp(state, 'players.modificationsHistory.filters.newInfo')}
            />
        </Row>
    );
};

export default ModificationsHistoryFilters;
