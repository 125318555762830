import React from 'react';

import { Row } from 'antd';

import { FormOutlined } from '@ant-design/icons';

const SettingColumnActionButtons = ({ record, handleDangerLevelSettingModal }) => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <Row gutter={15} align="middle" justify="space-around">
            <FormOutlined
                className={
                    record.editable
                        ? 'danger-level__tag-modal__edit-btn--disabled'
                        : 'danger-level__tag-modal__edit-btn'
                }
                onClick={() => handleDangerLevelSettingModal(true, record)}
                disabled={record.editable}
            />
        </Row>
    );
};

export default SettingColumnActionButtons;
