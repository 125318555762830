export const ACTIONS = {
    BONUS_MANUAL_MANAGEMENT_BATCH_REJECT: 'BONUS_MANUAL_MANAGEMENT_BATCH_REJECT',
    BONUS_MANUAL_MANAGEMENT_BATCH_REJECT_FAILURE: 'BONUS_MANUAL_MANAGEMENT_BATCH_REJECT_FAILURE',
    BONUS_MANUAL_MANAGEMENT_BATCH_REJECT_SUCCESS: 'BONUS_MANUAL_MANAGEMENT_BATCH_REJECT_SUCCESS',
    BONUS_MANUAL_MANAGEMENT_BATCH_SUBMIT: 'BONUS_MANUAL_MANAGEMENT_BATCH_SUBMIT',
    BONUS_MANUAL_MANAGEMENT_BATCH_SUBMIT_FAILURE: 'BONUS_MANUAL_MANAGEMENT_BATCH_SUBMIT_FAILURE',
    BONUS_MANUAL_MANAGEMENT_BATCH_SUBMIT_SUCCESS: 'BONUS_MANUAL_MANAGEMENT_BATCH_SUBMIT_SUCCESS',
    BONUS_MANUAL_MANAGEMENT_BATCH_UPDATE: 'BONUS_MANUAL_MANAGEMENT_BATCH_UPDATE',
    BONUS_MANUAL_MANAGEMENT_BATCH_UPDATE_FAILURE: 'BONUS_MANUAL_MANAGEMENT_BATCH_UPDATE_FAILURE',
    BONUS_MANUAL_MANAGEMENT_BATCH_UPDATE_SUCCESS: 'BONUS_MANUAL_MANAGEMENT_BATCH_UPDATE_SUCCESS',
    BONUS_MANUAL_MANAGEMENT_FILTERS_DROP_ALL: 'BONUS_MANUAL_MANAGEMENT_FILTERS_DROP_ALL',
    BONUS_MANUAL_MANAGEMENT_FILTER_SET: 'BONUS_MANUAL_MANAGEMENT_FILTER_SET',
    BONUS_MANUAL_MANAGEMENT_GET_BONUS_CODE: 'BONUS_MANUAL_MANAGEMENT_GET_BONUS_CODE',
    BONUS_MANUAL_MANAGEMENT_GET_BONUS_CODE_FAILURE:
        'BONUS_MANUAL_MANAGEMENT_GET_BONUS_CODE_FAILURE',
    BONUS_MANUAL_MANAGEMENT_GET_BONUS_CODE_SUCCESS:
        'BONUS_MANUAL_MANAGEMENT_GET_BONUS_CODE_SUCCESS',
    BONUS_MANUAL_MANAGEMENT_RECORDS_GET: 'BONUS_MANUAL_MANAGEMENT_RECORDS_GET',
    BONUS_MANUAL_MANAGEMENT_RECORDS_GET_FAILURE: 'BONUS_MANUAL_MANAGEMENT_RECORDS_GET_FAILURE',
    BONUS_MANUAL_MANAGEMENT_RECORDS_GET_SUCCESS: 'BONUS_MANUAL_MANAGEMENT_RECORDS_GET_SUCCESS',
    BONUS_MANUAL_MANAGEMENT_RESET: 'BONUS_MANUAL_MANAGEMENT_RESET',
    BONUS_MANUAL_MANAGEMENT_UPDATE_INFO: 'BONUS_MANUAL_MANAGEMENT_UPDATE_INFO',
    BONUS_MANUAL_MANAGEMENT_UPDATE_INFO_FAILURE: 'BONUS_MANUAL_MANAGEMENT_UPDATE_INFO_FAILURE',
    BONUS_MANUAL_MANAGEMENT_UPDATE_INFO_SUCCESS: 'BONUS_MANUAL_MANAGEMENT_UPDATE_INFO_SUCCESS',
    BONUS_MANUAL_MANAGEMENT_VERIFY_ACTION_SET: 'BONUS_MANUAL_MANAGEMENT_VERIFY_ACTION_SET',
    BONUS_MANUAL_MANAGEMENT_VERIFY_SEND: 'BONUS_MANUAL_MANAGEMENT_VERIFY_SEND',
    BONUS_MANUAL_MANAGEMENT_VERIFY_SEND_FAILURE: 'BONUS_MANUAL_MANAGEMENT_VERIFY_SEND_FAILURE',
    BONUS_MANUAL_MANAGEMENT_VERIFY_SEND_SUCCESS: 'BONUS_MANUAL_MANAGEMENT_VERIFY_SEND_SUCCESS',
    DEPOSIT_AMOUNT_GET_FAILURE: 'BONUS_MANUAL_MANAGEMENT_DEPOSIT_AMOUNT_GET_FAILURE',
    DEPOSIT_AMOUNT_GET_SUCCESS: 'BONUS_MANUAL_MANAGEMENT_DEPOSIT_AMOUNT_GET_SUCCESS',
    PAGINATION: {
        BONUS_MANUAL_MANAGEMENT_TABLE_PAGE_CHANGE: 'BONUS_MANUAL_MANAGEMENT_TABLE_PAGE_CHANGE',
        BONUS_MANUAL_MANAGEMENT_TABLE_PAGE_SIZE_CHANGE:
            'BONUS_MANUAL_MANAGEMENT_TABLE_PAGE_SIZE_CHANGE',
    },
    REFRESH_TIME_PAUSE: 'BONUS_MANUAL_MANAGEMENT_REFRESH_TABLE_TIME_PAUSE',
    REFRESH_TIME_SET: 'BONUS_MANUAL_MANAGEMENT_REFRESH_TABLE_TIME_SET',
    REMARK_MODAL_CLOSE: 'BONUS_MANUAL_MANAGEMENT_REMARK_MODAL_CLOSE',
    REMARK_MODAL_OPEN: 'BONUS_MANUAL_MANAGEMENT_REMARK_MODAL_OPEN',
    SET_BATCH_ACTION_MODAL: 'SET_BATCH_ACTION_MODAL',
    SET_SELECTED_BONUS_IDS: 'SET_SELECTED_BONUS_IDS',
    TURNOVER_AMOUNT_CALCULATE: 'TURNOVER_AMOUNT_CALCULATE',
    TURNOVER_AMOUNT_CALCULATE_FAILURE: 'TURNOVER_AMOUNT_CALCULATE_FAILURE',
    TURNOVER_AMOUNT_CALCULATE_SUCCESS: 'TURNOVER_AMOUNT_CALCULATE_SUCCESS',
    UPDATE_SELECTED_VERIFY_BONUS_INFO: 'UPDATE_SELECTED_VERIFY_BONUS_INFO',
    VERIFY_MODAL_CLOSE: 'BONUS_MANUAL_MANAGEMENT_VERIFY_MODAL_CLOSE',
    VERIFY_MODAL_OPEN: 'BONUS_MANUAL_MANAGEMENT_VERIFY_MODAL_OPEN',
};

export const onVerifyModalOpen = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.VERIFY_MODAL_OPEN,
    });

export const onVerifyModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.VERIFY_MODAL_CLOSE,
    });

export const onRemarkModalOpen = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REMARK_MODAL_OPEN,
    });

export const onRemarkModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.REMARK_MODAL_CLOSE,
    });

export const manualManagementRecordsGet = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_RECORDS_GET,
    });

export const manualManagementRecordsGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_RECORDS_GET_SUCCESS,
    });

export const manualManagementRecordsGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_RECORDS_GET_FAILURE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.BONUS_MANUAL_MANAGEMENT_TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.BONUS_MANUAL_MANAGEMENT_TABLE_PAGE_CHANGE,
    });

export const transactionRecordsFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_FILTER_SET,
    });

export const manualManagementVerifyActionSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_VERIFY_ACTION_SET,
    });

export const manualManagementVerifySend = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_VERIFY_SEND,
    });

export const manualManagementVerifySendSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_VERIFY_SEND_SUCCESS,
    });

export const manualManagementVerifySendFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_VERIFY_SEND_FAILURE,
    });

export const updateSelectedVerifyBonusInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.UPDATE_SELECTED_VERIFY_BONUS_INFO,
    });

export const manualManagementFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_FILTERS_DROP_ALL,
    });

export const turnoverAmountGet = (dispatch) =>
    dispatch({
        type: ACTIONS.TURNOVER_AMOUNT_CALCULATE,
    });

export const turnoverAmountGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TURNOVER_AMOUNT_CALCULATE_SUCCESS,
    });

export const turnoverAmountGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TURNOVER_AMOUNT_CALCULATE_FAILURE,
    });

export const depositAmountGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DEPOSIT_AMOUNT_GET_SUCCESS,
    });

export const depositAmountGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.DEPOSIT_AMOUNT_GET_FAILURE,
    });

export const updateRewardInfo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_UPDATE_INFO,
    });

export const updateRewardInfoSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_UPDATE_INFO_SUCCESS,
    });

export const updateRewardInfoFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_UPDATE_INFO_FAILURE,
    });

export const setRefreshTableTime = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.REFRESH_TIME_SET,
    });
};

export const pauseRefreshTableTime = (dispatch) => {
    dispatch({
        type: ACTIONS.REFRESH_TIME_PAUSE,
    });
};

export const getBonusCode = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_GET_BONUS_CODE,
    });

export const getBonusCodeSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_GET_BONUS_CODE_SUCCESS,
    });

export const getBonusCodeFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_GET_BONUS_CODE_FAILURE,
    });

export const setSelectedBonusIds = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.SET_SELECTED_BONUS_IDS,
    });

export const setBatchActionModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.SET_BATCH_ACTION_MODAL,
    });

export const manualManagementBatchReject = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_BATCH_REJECT,
    });

export const manualManagementBatchRejectSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_BATCH_REJECT_SUCCESS,
    });

export const manualManagementBatchRejectFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_BATCH_REJECT_FAILURE,
    });

export const manualManagementBatchSubmit = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_BATCH_SUBMIT,
    });

export const manualManagementBatchSubmitSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_BATCH_SUBMIT_SUCCESS,
    });

export const manualManagementBatchSubmitFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_BATCH_SUBMIT_FAILURE,
    });

export const manualManagementBatchUpdate = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_BATCH_UPDATE,
    });

export const manualManagementBatchUpdateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_BATCH_UPDATE_SUCCESS,
    });

export const manualManagementBatchUpdateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_BATCH_UPDATE_FAILURE,
    });

export const manualManagementReset = (dispatch) =>
    dispatch({
        type: ACTIONS.BONUS_MANUAL_MANAGEMENT_RESET,
    });
