import React from 'react';

import { Table } from 'antd';

import { EWALLET_TRANSFER_TABLE_COLUMNS } from '../constants';
import { getColumns, getPaginationOptions, getRowSelectionProps } from './EwalletTransferTableMeta';
import { getStateProp } from '../../../../common/helpers/misc';
import { useStore } from '../../../../store/StateProvider';

const DepositResultsTable = ({ loading }) => {
    const [state, dispatch] = useStore();

    const ewalletTransfers = getStateProp(state, 'payments.ewalletTransfers');
    const tableData = getStateProp(ewalletTransfers, 'list', []);
    const paging = getStateProp(ewalletTransfers, 'paging', {});
    const selectedTransactionId = getStateProp(ewalletTransfers, 'selectedTransactionId', []);
    const selectedColumns = getStateProp(
        ewalletTransfers,
        'selectedColumns',
        EWALLET_TRANSFER_TABLE_COLUMNS,
    );

    // ============================================
    // RENDER
    // ============================================

    return (
        <Table
            columns={getColumns(dispatch, selectedColumns)}
            pagination={getPaginationOptions(paging, dispatch)}
            dataSource={tableData}
            rowSelection={getRowSelectionProps(dispatch, selectedTransactionId)}
            rowKey={(record) => record.transactionId}
            loading={loading}
            size="small"
            align="left"
            scroll={{ x: true }}
            bordered
        />
    );
};

export default DepositResultsTable;
