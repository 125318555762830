import { useEffect, useState } from 'react';

import { API } from '../common/constants/api';
import { bonusFormClear } from '../components/bonus/create-bonus/createBonusActions';
import { clearError } from '../common/actions/errorActions';
import { clearNewsDraft } from '../components/news/newsActions';
import { bannerFormClear } from '../components/bonus/create-banner/createBannerActions';
import { getEndTime, getStartTime, getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import {
    getUserPermissionFailure,
    getUserPermissionSuccess,
    userLoginFailure,
    userLoginSuccess,
} from '../common/actions/userActions';
import { isUserLoggedIn, setUserCookies } from '../common/helpers/api';
import { message } from 'antd';
import {
    passwordChange,
    passwordChangeFailure,
    passwordChangeSuccess,
} from '../components/profile/changePasswordActions';
import { rebateFormReset } from '../components/rebate/setting/rebateSettingActions';
import { resetAnnouncementDraft } from '../components/announcement/announcement-create/announcementCreateActions';
import { resetPushNotificationInfo } from '../components/push-notification/create-push-notification/general/generalCreatePushNotificationActions';
import { resetTargetPushNotificationInfo } from '../components/push-notification/create-push-notification/target/targetCreatePushNotificationActions';
import { useDispatch, useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, SIGN_IN, IDENTITY } = API;

export function useUserLoginAsyncEndpoint(fireRedirect) {
    const [state, dispatch] = useStore();
    const location = getStateProp(state, 'timezone.location');

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        method: 'POST',
        options: {
            data: state.user,
        },
        trigger: state.user,
        url: `${VERSION.V1}${IDENTITY.ROOT}${SIGN_IN}/${'operator'}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            // Reset form before login
            clearNewsDraft(dispatch);
            bonusFormClear(dispatch);
            resetAnnouncementDraft(dispatch, {
                durationEnd: getEndTime(location),
                durationStart: getStartTime(location),
            });
            rebateFormReset(dispatch, {
                endDateTime: getEndTime(location),
                startDateTime: getStartTime(location),
            });
            resetPushNotificationInfo(dispatch);
            resetTargetPushNotificationInfo(dispatch);
            bannerFormClear(dispatch);

            setUserCookies(response.data, 30);
            userLoginSuccess(dispatch, {
                ...response.data,
                userName: response.data.claims.userName,
            });
            fireRedirect(true);
        }
    }, [response, dispatch, fireRedirect, location]);

    const message = useError(error, userLoginFailure);
    useToasterErrorMessage(error, message);

    return [loading];
}

export function useGetUserPermissionAsyncEndpoint() {
    const dispatch = useDispatch();

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!isUserLoggedIn(),
        method: 'GET',
        trigger: isUserLoggedIn(),
        url: `${VERSION.V1}${IDENTITY.PERMISSION}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            localStorage.setItem('menus', JSON.stringify(response.data.data));
            getUserPermissionSuccess(dispatch);
        }
    }, [response, dispatch]);

    const message = useError(error, getUserPermissionFailure);

    useToasterErrorMessage(error, message);

    return [loading, response];
}

export function useChangePasswordOperation(formDetails) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);

    const userName = getStateProp(state, 'user.userName');

    // ============================================
    // METHODS
    // ============================================

    const handleChangePassword = () => {
        setTrigger(+new Date());
        passwordChange(dispatch);
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PUT',
        options: {
            data: {
                ...formDetails,
                userName,
            },
        },
        trigger,
        url: `${VERSION.V1}${IDENTITY.CHANGE_PASSWORD}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            passwordChangeSuccess(dispatch);
            message.success(getTranslation('Submitted'));
        }
    }, [dispatch, response]);

    const errorMsg = useError(error, passwordChangeFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleChangePassword];
}
