import React from 'react';

import { ButtonBlue } from '../../../../common/components/buttons/Buttons';
import { getTranslation } from '../../../../common/helpers/locale';
import { onOpenConfirmModal } from '../bankAccountConfigurationActions';
import { useDispatch } from '../../../../store/StateProvider';

const OperationButton = ({ accountDetails }) => {
    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ============================================

    const onConfirm = () => onOpenConfirmModal(dispatch, { accountDetails });

    // ============================================
    // RENDER
    // ============================================

    return (
        <ButtonBlue className="action-btn__verify" onClick={onConfirm}>
            {getTranslation('Edit')}
        </ButtonBlue>
    );
};

export default OperationButton;
