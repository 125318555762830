import _ from 'lodash';

import { ACTIONS } from './createBonusActions';
import { INITIAL_CREATE_BONUS_DATA } from '../constants';
import { getDefaultContent } from '../helpers';

export default function bonusReducer(
    createBonus = {
        details: getDefaultContent(),
        ...INITIAL_CREATE_BONUS_DATA,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.BONUS_LANGUAGE_SET:
        case ACTIONS.BONUS_LANGUAGE_DROP:
            return {
                ...createBonus,
                details: payload,
            };

        case ACTIONS.SET_BONUS_INFO_BY_LANGUAGE: {
            _.map(createBonus.details, (value) => {
                if (value.languageCode === payload.langCode) {
                    value[payload.name] = payload.value;
                }
                return value;
            });

            return {
                ...createBonus,
                details: [...createBonus.details],
            };
        }
        case ACTIONS.SET_BONUS_INFO:
            return {
                ...createBonus,
                [payload.name]: payload.value,
            };

        case ACTIONS.SET_MULTI_BONUS_INFO:
            return {
                ...createBonus,
                ...payload,
            };

        case ACTIONS.BONUS_FORM_CLEAR:
            return {
                details: getDefaultContent(),
                ...INITIAL_CREATE_BONUS_DATA,
            };

        case ACTIONS.BONUS_DUPLICATE:
            return payload;

        case ACTIONS.BONUS_DUPLICATE_BY_LANGUAGE:
            return {
                ...createBonus,
                details: payload,
            };

        case ACTIONS.BONUS_CREATE_SET_CURRENT_SETP:
            return {
                ...createBonus,
                currentStep: payload,
            };

        default:
            return createBonus;
    }
}
