import { message } from 'antd';

import { MAX_TABLE_ROW_SELECT_LIMIT } from '../../../../common/constants/misc';
import { getTranslation } from '../../../../common/helpers/locale';

export const ACTIONS = {
    CONFIRM_MODAL_CLOSE: 'CRYPTO_DEPOSITS_CONFIRM_MODAL_CLOSE',
    CONFIRM_MODAL_OPEN: 'CRYPTO_DEPOSITS_CONFIRM_MODAL_OPEN',
    CONFIRM_REJECT_MODAL_CLOSE: 'CRYPTO_DEPOSITS_CONFIRM_REJECT_MODAL_CLOSE',
    CONFIRM_REJECT_MODAL_OPEN: 'CRYPTO_DEPOSITS_CONFIRM_REJECT_MODAL_OPEN',
    CRYPTO_DEPOSITS_GET: 'CRYPTO_DEPOSITS_GET',
    CRYPTO_DEPOSITS_GET_FAILURE: 'CRYPTO_DEPOSITS_GET_FAILURE',
    CRYPTO_DEPOSITS_GET_SUCCESS: 'CRYPTO_DEPOSITS_GET_SUCCESS',
    CRYPTO_DEPOSIT_OPERATION: 'CRYPTO_DEPOSIT_OPERATION',
    CRYPTO_DEPOSIT_OPERATION_FAILURE: 'CRYPTO_DEPOSIT_OPERATION_FAILURE',
    CRYPTO_DEPOSIT_OPERATION_SUCCESS: 'CRYPTO_DEPOSIT_OPERATION_SUCCESS',
    FILTERS_DROP_ALL: 'CRYPTO_DEPOSITS_FILTERS_DROP_ALL',
    FILTER_SET: 'CRYPTO_DEPOSITS_FILTER_SET',
    PAGINATION: {
        TABLE_PAGE_CHANGE: 'CRYPTO_DEPOSITS_TABLE_PAGE_CHANGE',
        TABLE_PAGE_SIZE_CHANGE: 'CRYPTO_DEPOSITS_TABLE_PAGE_SIZE_CHANGE',
    },
    RECORD_SELECT: 'CRYPTO_DEPOSITS_RECORD_SELECT',
    REFRESH_TIME_PAUSE: 'CRYPTO_DEPOSIT_REFRESH_TABLE_TIME_PAUSE',
    REFRESH_TIME_SET: 'CRYPTO_DEPOSIT_REFRESH_TABLE_TIME_SET',
    VERIFY_MODAL_CLOSE: 'CRYPTO_DEPOSITS_VERIFY_MODAL_CLOSE',
    VERIFY_MODAL_OPEN: 'CRYPTO_DEPOSITS_VERIFY_MODAL_OPEN',
};

export const selectRecord = (dispatch, payload) =>
    payload.length <= MAX_TABLE_ROW_SELECT_LIMIT
        ? dispatch({
              payload,
              type: ACTIONS.RECORD_SELECT,
          })
        : message.warning(
              getTranslation('You cannot select more than {limit} rows', {
                  limit: MAX_TABLE_ROW_SELECT_LIMIT,
              }),
          );

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.FILTERS_DROP_ALL,
    });
export const depositsFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.FILTER_SET,
    });

export const openVerifyModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.VERIFY_MODAL_OPEN,
    });

export const cryptoDepositCloseVerifyModal = (dispatch) =>
    dispatch({
        type: ACTIONS.VERIFY_MODAL_CLOSE,
    });

export const openConfirmModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_MODAL_OPEN,
    });

export const cryptoDepositCloseConfirmModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_MODAL_CLOSE,
    });

export const openConfirmRejectModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_REJECT_MODAL_OPEN,
    });

export const closeConfirmRejectModal = (dispatch) =>
    dispatch({
        type: ACTIONS.CONFIRM_REJECT_MODAL_CLOSE,
    });

export const cryptoDepositsGet = (dispatch) =>
    dispatch({
        type: ACTIONS.CRYPTO_DEPOSITS_GET,
    });

export const cryptoDepositsGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_DEPOSITS_GET_SUCCESS,
    });

export const cryptoDepositsGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_DEPOSITS_GET_FAILURE,
    });

export const cryptoDepositOperation = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_DEPOSIT_OPERATION,
    });

export const cryptoDepositOperationSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.CRYPTO_DEPOSIT_OPERATION_SUCCESS,
    });

export const cryptoDepositOperationFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CRYPTO_DEPOSIT_OPERATION_FAILURE,
    });

export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TABLE_PAGE_CHANGE,
    });

export const setCryptoDepositRefreshTableTime = (dispatch, payload) => {
    dispatch({
        payload,
        type: ACTIONS.REFRESH_TIME_SET,
    });
};

export const pauseCryptoDepositRefreshTableTime = (dispatch) => {
    dispatch({
        type: ACTIONS.REFRESH_TIME_PAUSE,
    });
};
