import { Redirect } from 'react-router-dom';
import React, { useState } from 'react';

import { Button, Form, Input, Layout, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import './Login.less';
import { ICONS } from '../../common/constants/icons';
import { ImgIcon } from '../../common/components';
import { ROUTES } from '../../common/constants/routing';
import { getTranslation } from '../../common/helpers/locale';
import { useDispatch } from '../../store/StateProvider';
import { useUserLoginAsyncEndpoint } from '../../services/userService';
import { userLogin } from '../../common/actions/userActions';
import packageJson from '../../../package.json';

const Login = () => {
    const dispatch = useDispatch();
    const [fireRedirect, setFireRedirect] = useState(false);

    // ============================================
    // METHODS
    // ============================================

    const login = (values) => {
        userLogin(dispatch, { password: values.password, userName: values.userName });
    };

    // ============================================
    // HOOKS
    // ============================================

    const [loading] = useUserLoginAsyncEndpoint(setFireRedirect);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Layout>
            <Layout.Content className="login__container">
                <Row className="login__content">
                    <ImgIcon icon={ICONS.LOGO} width={200} />

                    <Form name="login-form" className="login__form" onFinish={login}>
                        <Form.Item
                            name="userName"
                            rules={[{ message: 'Username is required!', required: true }]}
                        >
                            <Input
                                prefix={<UserOutlined className="login__input-icon" />}
                                className="login__input"
                                placeholder="Username"
                                onPressEnter={login}
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ message: 'Password required!', required: true }]}
                        >
                            <Input.Password
                                prefix={<ImgIcon icon={ICONS.KEY} width={14} />}
                                visibilityToggle
                                className="login__input"
                                placeholder="Password"
                                onPressEnter={login}
                            />
                        </Form.Item>
                        <Button className="login__btn" loading={loading} htmlType="submit">
                            {getTranslation('Login')}
                        </Button>
                    </Form>

                    <p className="login__app-version">
                        <span>App version: {packageJson.version}</span>
                    </p>
                </Row>
            </Layout.Content>
            {fireRedirect && <Redirect to={ROUTES.DASHBOARD.ROOT} />}
        </Layout>
    );
};

export default Login;
