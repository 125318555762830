import React, { useRef } from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { TRANSACTION_STATUSES } from '../../constants/misc';
import { getTranslation } from '../../helpers/locale';

const TransactionStatusFilter = ({ value, onFilterChange }) => {
    const selectElement = useRef(null);

    // ============================================
    // METHODS
    // ============================================

    const handleFilterChange = (value) => onFilterChange('transactionStatuses', value);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Select
            value={value}
            onChange={handleFilterChange}
            allowClear
            placeholder={getTranslation('Transaction Status')}
            className="filter"
            ref={selectElement}
        >
            {_.map(_.entries(TRANSACTION_STATUSES), ([key, status]) => (
                <Select.Option value={status} key={key}>
                    {getTranslation(status).toLocaleUpperCase()}
                </Select.Option>
            ))}
        </Select>
    );
};

export default TransactionStatusFilter;
