import React from 'react';

import { Col, Form, Radio } from 'antd';
import _ from 'lodash';

import { PAYMENT_GATEWAY_WEB_SHOW } from '../../../../../common/constants/misc';

const RadioButton = ({ name }) => {
    return (
        <Form.Item name={name}>
            <Radio.Group style={{ display: 'flex', flexFlow: 'row wrap' }}>
                {_.map(PAYMENT_GATEWAY_WEB_SHOW, (value, index) => {
                    return (
                        <Col span={12} key={value}>
                            <Radio value={value}>{index}</Radio>
                        </Col>
                    );
                })}
            </Radio.Group>
        </Form.Item>
    );
};

export default RadioButton;
