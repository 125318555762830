import balanceManagementReducer from './balance-management/balanceManagementReducer';
import cryptoCurrencyCorrectionReducer from './cryptocurrency-correction/cryptocurrencyCorrectionReducer';
import depositCorrectionReducer from './deposit-correction/depositCorrectionReducer';
import ewalletCorrectionReducer from './ewallet-correction/ewalletCorrectionReducer';

export default function balanceReducer(balance = {}, action) {
    const {
        balanceManagement,
        depositCorrection,
        cryptoCurrencyCorrection,
        ewalletCorrection,
    } = balance;

    return {
        balanceManagement: balanceManagementReducer(balanceManagement, action),
        cryptoCurrencyCorrection: cryptoCurrencyCorrectionReducer(cryptoCurrencyCorrection, action),
        depositCorrection: depositCorrectionReducer(depositCorrection, action),
        ewalletCorrection: ewalletCorrectionReducer(ewalletCorrection, action),
    };
}
