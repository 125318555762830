export const ACTIONS = {
    BONUS_CODE_REDEMPTION_MODAL_VISIBLE: 'BONUS_CODE_REDEMPTION_MODAL_VISIBLE',
    GET_BONUS_IP: 'GET_BONUS_IP',
    GET_BONUS_IP_FAILURE: 'GET_BONUS_IP_FAILURE',
    GET_BONUS_IP_SUCCESS: 'GET_BONUS_IP_SUCCESS',
    GET_BONUS_REDDEM: 'GET_BONUS_REDDEM',
    GET_BONUS_REDDEM_FAILURE: 'GET_BONUS_REDDEM_FAILURE',
    GET_BONUS_REDDEM_SUCCESS: 'GET_BONUS_REDDEM_SUCCESS',
    GET_BONUS_REGISTRATION: 'GET_BONUS_REGISTRATION',
    GET_BONUS_REGISTRATION_FAILURE: 'GET_BONUS_REGISTRATION_FAILURE',
    GET_BONUS_REGISTRATION_SUCCESS: 'GET_BONUS_REGISTRATION_SUCCESS',
    PAGINATION: {
        BONUS_REDEMPTION_TABLE_PAGE_CHANGE: 'BONUS_REDEMPTION_TABLE_PAGE_CHANGE',
        BONUS_REDEMPTION_TABLE_PAGE_SIZE_CHANGE: 'BONUS_REDEMPTION_TABLE_PAGE_SIZE_CHANGE',
        RESET_BONUS_REDEMPTION_TABLE_PAGE: 'RESET_BONUS_REDEMPTION_TABLE_PAGE',
        RESET_TOTAL_REGISTRATION_TABLE_PAGE: 'RESET_TOTAL_REGISTRATION_TABLE_PAGE',
        RIKS_MONITOR_TABLE_PAGE_CHANGE: 'RIKS_MONITOR_TABLE_PAGE_CHANGE',
        RIKS_MONITOR_TABLE_PAGE_SIZE_CHANGE: 'RIKS_MONITOR_TABLE_PAGE_SIZE_CHANGE',
        TOTAL_REGISTRATION_TABLE_PAGE_CHANGE: 'TOTAL_REGISTRATION_TABLE_PAGE_CHANGE',
        TOTAL_REGISTRATION_TABLE_PAGE_SIZE_CHANGE: 'TOTAL_REGISTRATION_TABLE_PAGE_SIZE_CHANGE',
    },
    RIKS_MONITOR_FILTERS_DROP_ALL: 'RIKS_MONITOR_FILTERS_DROP_ALL',
    RIKS_MONITOR_FILTER_SET: 'RIKS_MONITOR_FILTER_SET',
    TOTAL_REGISTRATION_MODAL_VISIBLE: 'TOTAL_REGISTRATION_MODAL_VISIBLE',
};

export const filterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.RIKS_MONITOR_FILTER_SET,
    });

export const dropFilters = (dispatch) =>
    dispatch({
        type: ACTIONS.RIKS_MONITOR_FILTERS_DROP_ALL,
    });

//#region banner change page
export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.RIKS_MONITOR_TABLE_PAGE_SIZE_CHANGE,
    });

export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.RIKS_MONITOR_TABLE_PAGE_CHANGE,
    });

//#region banner change page
export const changeTotalRegistrationPageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TOTAL_REGISTRATION_TABLE_PAGE_SIZE_CHANGE,
    });

export const changeTotalRegistrationPageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.TOTAL_REGISTRATION_TABLE_PAGE_CHANGE,
    });

//#region banner change page
export const changeBonusRedemptionPageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.BONUS_REDEMPTION_TABLE_PAGE_SIZE_CHANGE,
    });

export const changeBonusRedemptionPageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.BONUS_REDEMPTION_TABLE_PAGE_CHANGE,
    });

export const setBonusCodeRedemptionModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BONUS_CODE_REDEMPTION_MODAL_VISIBLE,
    });

export const setTotalRegistrationModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TOTAL_REGISTRATION_MODAL_VISIBLE,
    });

export const resetTotalRegistrationPage = (dispatch) =>
    dispatch({
        type: ACTIONS.PAGINATION.RESET_TOTAL_REGISTRATION_TABLE_PAGE,
    });

export const resetBonusRedemptionPage = (dispatch) =>
    dispatch({
        type: ACTIONS.PAGINATION.RESET_BONUS_REDEMPTION_TABLE_PAGE,
    });

export const getBonusIp = (dispatch) =>
    dispatch({
        type: ACTIONS.GET_BONUS_IP,
    });

export const getBonusIpSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_BONUS_IP_SUCCESS,
    });

export const getBonusIpFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_BONUS_IP_FAILURE,
    });

export const getBonusRedeem = (dispatch) =>
    dispatch({
        type: ACTIONS.GET_BONUS_REDDEM,
    });

export const getBonusRedeemSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_BONUS_REDDEM_SUCCESS,
    });

export const getBonusRedeemFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_BONUS_REDDEM_FAILURE,
    });

export const getBonusRegistration = (dispatch) =>
    dispatch({
        type: ACTIONS.GET_BONUS_REGISTRATION,
    });

export const getBonusRegistrationSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_BONUS_REGISTRATION_SUCCESS,
    });

export const getBonusRegistrationFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_BONUS_REGISTRATION_FAILURE,
    });
