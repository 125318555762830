import React from 'react';

import { Col, Input, Row } from 'antd';
import _ from 'lodash';

import { AMOUNT_SETTING } from './constants';
import { getIntOrFloat } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { transactionAmountSettingSet } from './TransactionAmountSettingActions';
import { useDispatch } from '../../../store/StateProvider';

const DepositAmountSetting = ({ depositAmountSetting }) => {
    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ===========================================

    const onChange = (value, id, aspect) => {
        _.find(depositAmountSetting, (setting) => setting.id === id)[aspect] = getIntOrFloat(
            value,
            true,
        );
        transactionAmountSettingSet(dispatch, { deposit: depositAmountSetting });
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col>
            <Row className="amount-setting__header" justify="space-between">
                <Col span={6}>
                    <label>{getTranslation('Currency')}</label>
                </Col>
                <Col span={8}>
                    <label>{getTranslation('Minimum')}</label>
                </Col>
                <Col span={8}>
                    <label>{getTranslation('Maximum')}</label>
                </Col>
            </Row>
            {_.map(depositAmountSetting, (setting) => (
                <Row style={{ padding: '10px' }} justify="space-between" key={setting.id}>
                    <Col span={6}>{setting.currencyCode}</Col>
                    <Col span={8}>
                        <Input
                            value={setting.minimumAmount.toFixed(2)}
                            onChange={(e) =>
                                onChange(e.target.value, setting.id, AMOUNT_SETTING.MINIMUM_AMOUNT)
                            }
                        />
                    </Col>
                    <Col span={8}>
                        <Input
                            value={setting.maximumAmount.toFixed(2)}
                            onChange={(e) =>
                                onChange(e.target.value, setting.id, AMOUNT_SETTING.MAXIMUM_AMOUNT)
                            }
                        />
                    </Col>
                </Row>
            ))}
        </Col>
    );
};

export default DepositAmountSetting;
